import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useNavigate, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { getEnterpriseList } from "../../../store/ApiSlice/enterpriseSlice";
import { getAttributes } from "../../../store/ApiSlice/attributeSlice";
import { Autocomplete, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, MenuList, TextField } from '@mui/material';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { PiSignOut, PiUser } from "react-icons/pi";
import { useContext } from "react";
import { ThemeContext } from "../../../ThemeContext";
import ThemeToggle from "../../../ThemeToggle";
import vyanBlackLogo from '../../../image/Black-vyan-logo.svg'
import vyanWhiteLogo from '../../../image/white-vyan-logo.svg'
import { ReactComponent as HomeIcon } from '../../../image/Home-v4.svg'
import { ReactComponent as FilterIcon } from '../../../image/Filter-v2.svg'
import { ReactComponent as CanvasIcon } from '../../../image/Canvas-v4.svg'
import { ReactComponent as PlanningIcon } from '../../../image/Planning-v3.svg'
import { ReactComponent as ProcessIcon } from '../../../image/Process-v2.svg'
import { ReactComponent as ModelingIcon } from '../../../image/Modeling-v4.svg'
import { ReactComponent as ModelIcon } from '../../../image/Model-v3.svg'
import { ReactComponent as ForcastProfileIcon } from '../../../image/Forecast-Profile-v3.svg'
import { ReactComponent as SystemAdministrationIcon } from '../../../image/System-Administration-v2.svg'
import { ReactComponent as EnterpriseIcon } from '../../../image/Enterprise-v2.svg'
import { ReactComponent as RoleIcon } from '../../../image/Role-v1.svg'
import { ReactComponent as UserIcon } from '../../../image/User-v3.svg'
import { ReactComponent as MeasureProfileIcon } from '../../../image/Measure-Data-Profile-v2.svg'
import { ReactComponent as AdministratorIcon } from '../../../image/Administrator-v1.svg'
import { ReactComponent as MultiEnterpriseIcon } from '../../../image/Multienterprise-v1.svg'
import useSocket from "../../../socket/SocketComponent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeDefaultEnterprise } from "../../../store/ApiSlice/userSlice";
import { setExpandedMenu } from "../../../store/ApiSlice/authSlice";
const drawerWidth = 300;

const openedMixin = (theme, isDarkTheme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: isDarkTheme ? "#191919" : "whitesmoke",
  color: isDarkTheme ? "darkgray" : "black",
});

const closedMixin = (theme, isDarkTheme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: isDarkTheme ? "#191919" : "whitesmoke",
  color: isDarkTheme ? "darkgray" : "black",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isDarkTheme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, isDarkTheme),
    "& .MuiDrawer-paper": openedMixin(theme, isDarkTheme),
  }),
  ...(!open && {
    ...closedMixin(theme, isDarkTheme),
    "& .MuiDrawer-paper": closedMixin(theme, isDarkTheme),
  }),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
  nested: {
    // paddingLeft: theme.spacing(4),
  },
}));

export default function MiniDrawer() {
  const { isDarkTheme } = useContext(ThemeContext);
  const { enterpriseData } = useSelector((state) => state?.enterprise)
  const { expandedMenu } = useSelector((state) => state?.auth)
  const { userOffline } = useSocket();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const profileData = localStorage.getItem("accessToken")
  const userProfile = JSON.parse(profileData)
  const [open, setOpen] = React.useState(true);
  const [defaultEnterprise, setDefaultEnterprise] = useState({});
  const [enterpriseModel, setEnterpriseModel] = useState(false);
  const [activeItem, setActiveItem] = React.useState(null);
  const dispatch = useDispatch();
  const BASE_IMAGE_URL = window.location.hostname === 'app.vyan.ai'
    ? 'https://app.vyan.ai'
    : 'http://localhost:4000';

  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    setDefaultEnterprise({
      ...defaultEnterprise,
      enterpriseUID: userProfile?.data?.enterprise?._id
    })
  }, [enterpriseModel])

  const handleListItemClick = (text, path) => {
    navigate(path)
    if (text === "Dimensions") {
      navigate(path);
    } else if (text === "Attributes") {
      navigate(path);
    } else if (text === "Enterprise") {
      navigate(path);
      dispatch(getEnterpriseList())
    } else if (text === "Model") {
      navigate(path);
    } else if (text === "Home") {
      navigate("/");
    } else if (text === "User") {
      navigate(path);
    } else if (text === "Measures") {
      navigate(path);
    } else if (text === "Canvas") {
      navigate(path);
    } else if (text === "Forecast Profile") {
      navigate(path)
    } else if (text === "Measure Data Profile") {
      navigate(path)
    } else if (text === "Process") {
      navigate(path)
    } else if (text === "Filter") {
      navigate(path)
    } else if (text === "Master Data") {
      navigate(path)
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDialog = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userId = localStorage.getItem("accessToken");
  const user = JSON.parse(userId)
  const handleLogout = () => {
    navigate("/login");
    userOffline(user?.userId)
    localStorage.removeItem("accessToken");
  };

  React.useEffect(() => {
    dispatch(getEnterpriseList())
    dispatch(getAttributes(""))
  }, []);



  const menu = [
    {
      icon: <HomeIcon />,
      title: "Home",
      path: '/'
    },
    {
      icon: <PlanningIcon />,
      title: "Planning",
      items: [
        {
          icon: <CanvasIcon />,
          title: "Canvas",
          path: '/canvas'
        },
        {
          icon: <FilterIcon />,
          title: "Filter",
          path: '/filter'
        },
        {
          icon: <ProcessIcon />,
          title: "Process",
          path: '/process'
        },
        {
          icon: <ForcastProfileIcon />,
          title: "Forecast Profile",
          path: '/profile'
        },
        {
          icon: <MeasureProfileIcon />,
          title: "Measure Data Profile",
          path: '/MeasureDataProfile'
        },
      ]
    },
    {
      icon: <ModelIcon />,
      title: "Models",
      path: '/model'
    },
    {
      icon: <SystemAdministrationIcon />,
      title: "System Administration",
      items: [
        ...(userProfile?.data?.superAdmin === true ? [
          {
            icon: <AdministratorIcon />,
            title: "Super Administrator",
            path: '/super-addministrator'
          },
          {
            icon: <EnterpriseIcon />,
            title: "Enterprise",
            path: '/enterprise'
          },
          {
            icon: <MultiEnterpriseIcon />,
            title: "Multi-Enterprise User",
            path: '/multi-enterprise-user'
          }
        ] : []),
        {
          icon: <RoleIcon />,
          title: "Role",
          path: '/role'
        },
        {
          icon: <UserIcon />,
          title: "User",
          path: '/users'
        },
      ],
    },
  ]

  const getCombinedPermissions = (roles) => {
    const permissionsMap = roles?.reduce((acc, role) => {
      return role?.roleUID?.permissions?.reduce((permAcc, permission) => {
        const object = permission?.object;
        const editAllowed = permission?.editAllowed;

        if (!permAcc[object]) {
          permAcc[object] = editAllowed;
        } else {
          permAcc[object] = permAcc[object] || editAllowed;
        }

        return permAcc;
      }, acc);
    }, {});

    return Object.keys(permissionsMap).map(object => ({
      object,
      editAllowed: permissionsMap[object]
    }));
  };

  const hasPermission = (permissions, object) => {
    const permission = permissions?.find(p => p.object === object);
    return permission && permission?.editAllowed;
  };

  const getFilteredMenu = (menu, permissions) => {
    return menu?.reduce((acc, menuItem) => {
      if (menuItem?.items) {
        const filteredItems = menuItem?.items?.filter(subItem => hasPermission(permissions, subItem.title));

        if (filteredItems?.length > 0) {
          acc?.push({ ...menuItem, items: filteredItems });
        }
      } else {
        acc?.push(menuItem);
      }
      return acc;
    }, []);
  };

  let filteredMenu;
  if (user?.data?.role && user?.data?.role?.length > 0) {
    const combinedPermissions = getCombinedPermissions(user?.data?.role);
    filteredMenu = getFilteredMenu(menu, combinedPermissions);
  }

  const sidebarmenu = (user?.data?.role?.length > 0 && user?.data?.role) ? filteredMenu : menu
  const handleClickMenu = (title) => {
    dispatch(setExpandedMenu(title));
  };

  const appStyles = {
    backgroundColor: isDarkTheme ? '#191919' : 'whitesmoke',
    color: isDarkTheme ? '#fff' : '#000',
  };

  const handleDefaultEnterprise = () => {
    setEnterpriseModel(!enterpriseModel);
    setAnchorEl(null);
  }

  const handleSubmit = async () => {
    const body = {
      enterpriseUID: defaultEnterprise?.enterpriseUID
    }
    const responce = await dispatch(changeDefaultEnterprise(body))
    if (responce?.meta?.requestStatus === "fulfilled") {
      toast.success(responce?.payload?.message)
    } else if (responce?.meta?.requestStatus === "rejected") {
      toast.error(responce?.error?.message);
    }
  }
  return (
    // <Box sx={{ display: "flex" }}>
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "black",
          color: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        open={open}
        style={appStyles}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              color: isDarkTheme ? "white" : "black",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!open && (isDarkTheme ? (
              <img src={vyanWhiteLogo} alt="vyan logo" style={{ width: "134px" }} />
            ) : (
              <img src={vyanBlackLogo} alt="vyan logo" style={{ width: "134px" }} />
            ))}
          </Box>
        </Toolbar>
        <Box sx={{
          display: "flex",
          alignItems: "center"
        }}>
          <Box
            open={open}
            sx={{ padding: "0px 30px" }}
          >
            <Box>
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <Avatar
                  id="avatar-menu"
                  sx={{ bgcolor: "lightblue", color: "black", cursor: "pointer" }}
                  aria-controls={openDialog ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDialog ? "true" : undefined}
                  onClick={handleClick}
                  src={userProfile?.data?.photo ? `${BASE_IMAGE_URL}/uploads/${userProfile?.data?.photo}` : undefined}
                >
                  {userProfile?.data?.photo
                    ? "" // If a photo is available, don't show initials
                    : userProfile?.data?.userName
                      ? userProfile?.data?.userName?.split(" ")?.map(word => word[0])?.join("") // If no photo, show initials
                      : ""}
                </Avatar>

              </div>

              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                onClose={handleClose}
                open={openDialog}
                slotProps={{ paper: { sx: { width: '240px', marginTop: "4px" } } }}
              >
                <Box sx={{ p: '16px 20px ' }}>
                  <Typography variant="subtitle1">{userProfile?.data?.userName}</Typography>
                  <Typography color="text.secondary" variant="body2">
                    {userProfile?.data?.email}
                  </Typography>
                </Box>
                <Divider />
                <MenuList disablePadding sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>
                  <MenuItem>
                    <ListItemIcon>
                      <ThemeToggle />
                    </ListItemIcon>
                  </MenuItem>
                </MenuList>
                <Divider />
                <MenuList disablePadding sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>
                  <MenuItem onClick={() => {
                    navigate("/user-account")
                  }}>
                    <ListItemIcon>
                      <PiUser />
                    </ListItemIcon>
                    User Data
                  </MenuItem>
                  <MenuItem onClick={handleDefaultEnterprise}>
                    <ListItemIcon>
                      <PiSignOut />
                    </ListItemIcon>
                    Default Enterprise
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <PiSignOut />
                    </ListItemIcon>
                    Sign out
                  </MenuItem>
                </MenuList>
              </Popover>
            </Box>
          </Box>
        </Box>
      </AppBar>
      <Drawer variant="permanent" open={open} isDarkTheme={isDarkTheme}>
        <DrawerHeader sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 7px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {open && (isDarkTheme ? (
              <img src={vyanWhiteLogo} alt="vyan logo" style={{ width: "134px" }} />
            ) : (
              <img src={vyanBlackLogo} alt="vyan logo" style={{ width: "134px" }} />
            ))}
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: isDarkTheme ? "white" : "black" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: isDarkTheme ? "white" : "black" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sidebarmenu?.map((data) =>
            !data?.items ? (
              <ListItem
                key={data?.title}
                button
                onClick={() => {
                  handleListItemClick(data?.title, data?.path)
                  dispatch(setExpandedMenu(data?.title));
                }}
                sx={{
                  borderRadius: "8px",
                  padding: "5px 20px",
                  margin: "10px 0",
                }}
              >
                <ListItemIcon>
                  {data?.icon}
                </ListItemIcon>
                <ListItemText
                  primary={data?.title}
                  primaryTypographyProps={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}
                />
              </ListItem>
            ) : (
              <div key={data.title}>
                <ListItem
                  button
                  onClick={() => handleClickMenu(data.title)}
                  sx={{
                    borderRadius: "8px",
                    padding: "5px 20px",
                    margin: "10px 0",
                  }}
                >
                  <ListItemIcon>
                    {data?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={data?.title}
                    primaryTypographyProps={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}
                  />
                  {expandedMenu === data?.title ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                </ListItem>
                <Collapse in={expandedMenu === data?.title} timeout="auto" unmountOnExit
                  sx={{
                    padding: "0px 16px",
                    transition: 'height 0.5s ease-in-out, opacity 0.5s ease-in-out',
                    opacity: expandedMenu === data?.title ? 1 : 0,
                    height: expandedMenu === data?.title ? 'auto' : 0
                  }}>
                  {data?.items?.map((row) => (
                    <ListItem
                      button
                      key={row.title}
                      onClick={() => handleListItemClick(row?.title, row?.path)}
                      sx={{
                        borderRadius: "6px",
                        padding: "5px 16px",
                        margin: "5px 0",
                      }}
                    >
                      <ListItemIcon>
                        {row?.icon}
                      </ListItemIcon>
                      <ListItemText primary={row?.title} primaryTypographyProps={{ fontSize: '12px', fontFamily: "'Poppins', sans-serif" }} />
                    </ListItem>
                  ))}
                </Collapse>
              </div>
            )
          )}
        </List>
      </Drawer>

      <Dialog open={enterpriseModel} onClose={handleDefaultEnterprise}>
        <DialogTitle>Default Enterprise</DialogTitle>
        <DialogContent sx={{ width: "432px" }}>
          <FormControl fullWidth style={{ paddingBottom: "25px", marginTop: "13px" }}>
            <Autocomplete
              id="demo-simple-select-readonly"
              labelId="enterprise"
              name="enterpriseUID"
              fullWidth
              options={userProfile?.data?.multiEnterprise?.length > 0 ? userProfile?.data?.multiEnterprise : []}
              getOptionLabel={(option) => option.enterpriseName}
              value={defaultEnterprise?.enterpriseUID ?
                userProfile?.data?.multiEnterprise?.find
                  ((item) => item?.enterpriseUID === defaultEnterprise?.enterpriseUID)
                || null : null}
              onChange={(event, newValue) => {
                setDefaultEnterprise({
                  ...defaultEnterprise,
                  enterpriseUID: newValue?.enterpriseUID
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enterprise"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </FormControl>

          <DialogActions>
            <Button onClick={handleDefaultEnterprise}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
