import React, { useState } from "react";
import { Toolbar, Typography, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import DimensionListModel from "./DimensionListModel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const TimeDimensionsTable = ({
  selectedDimensions,
  setSelectedDimensions,
  dimensionAttributes,
  setDimensionAttributes,
  isView,
  isEdit,
  formValues, setTimeDimensionData,
  timeDimensionData,
  modelEnterprise
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
    useState(false);

  const [showfPopup, setShowfPopup] = useState(false);
  const [showsPopup, setShowsPopup] = useState(false);
  const [checkedDimensions, setCheckedDimensions] = useState([]);
  // const [dimensionValues, setDimensionValues] = useState({
  //   periodType: "",
  //   baseLevel: "",
  //   level: "",
  //   displaySettings: "",
  // });
  const handleDeleteClick = () => {
    setIsDeleteDimensionModalOpen(true);
  };
  const handlefAddClick = () => {
    setShowfPopup(true);
  };

  const handlefPopupClose = () => {
    setShowfPopup(false);
  };

  const handlesAddClick = () => {
    setShowsPopup(true);
  };

  const handlesPopupClose = () => {
    setShowsPopup(false);
  };

  const handleTableRowSelect = (event) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(id)
      ? selectedRows.filter((n) => n !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedIds);
  };
  // const handleAddDimension = () => {
  //   const { periodType, baseLevel, level, displaySettings } = dimensionValues;
  //   const newDimension = {
  //     periodType,
  //     baseLevel,
  //     level,
  //     displaySettings,
  //   };

  //   // Reset the state to clear the text fields
  //   setDimensionValues({
  //     periodType: "",
  //     baseLevel: "",
  //     level: "",
  //     displaySettings: "",
  //   });
  // };

  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   setDimensionValues((prevValues) => ({
  //     ...prevValues,
  //     [id]: value,
  //   }));
  // };

  // const [checked, setChecked] = useState(false);

  const handleDeleteSubmit = () => {
    let newFilteredRows = selectedDimensions.filter((item) => {
      return !selectedRows.includes(item._id);
    });

    let removedDimensions = selectedDimensions.filter((item) => {
      return selectedRows.includes(item._id);
    });

    let primaryKeysAttributeOfRemovedDimensions = [];
    removedDimensions.forEach((dimension) => {
      if (dimension.primaryKey && dimension.primaryKey.length)
        primaryKeysAttributeOfRemovedDimensions = [
          ...primaryKeysAttributeOfRemovedDimensions,
          ...dimension.primaryKey,
        ];
    });
    let idsOfRemovedAttributes = primaryKeysAttributeOfRemovedDimensions.map(
      (item) => item._id
    );

    let leftAttributes = dimensionAttributes.filter((attrib) => {
      return !idsOfRemovedAttributes.includes(attrib._id);
    });
    setDimensionAttributes([...leftAttributes]);
    setSelectedDimensions(newFilteredRows);
    setIsDeleteDimensionModalOpen(false);
  };

  const handleDeleteDimensionCloseModal = () => {
    setIsDeleteDimensionModalOpen(false);
  };

  const renderModalContent = () => {
    return (
      <Dialog
        open={isDeleteDimensionModalOpen}
        onClose={handleDeleteDimensionCloseModal}
      >
        <DialogTitle>Remove Dimension</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
            Are you sure for Remove this dimension?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDimensionCloseModal} sx={{textTransform: "capitalize"}}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteSubmit();
            }}
            color="primary" sx={{textTransform: "capitalize"}}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const [dimensionValues, setDimensionValues] = useState({
    periodType: "",
    baseLevel: "",
    level: "",
    displaySettings: "",
    enabled: false,
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setDimensionValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };
  const handleCancelDimension = () => {
    setShowfPopup(false)
  }
  const handleAddDimension = () => {
    setShowfPopup(false)
    setTimeDimensionData((prevData) => [
      ...prevData,
      {
        "Period Type": dimensionValues.periodType,
        "Base Level": dimensionValues.baseLevel,
        Level: dimensionValues.level,
        "Display Settings": dimensionValues.displaySettings,
        enabled: dimensionValues?.enabled,
      },
    ]);
    setShowsPopup(false);
    // Reset form values
    // setDimensionValues({
    //   periodType: "",
    //   baseLevel: "",
    //   level: "",
    //   displaySettings: "",
    // });

  };
  const handleCheckboxChange = (index) => {
    const updatedFields = [...timeDimensionData];
    updatedFields[index] = {...updatedFields[index]};
    updatedFields[index].enabled = !updatedFields[index].enabled;
    setTimeDimensionData(updatedFields);
  };
  return (
    <>
      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />
      <Toolbar>
        <Typography variant="h6">Time Dimension</Typography>
      </Toolbar>
      <Grid item>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "26px",
          }}
        >
          <Button
            disabled={isView}
            title="Add new dimension"
            variant="text"
            onClick={handlefAddClick}
          >
            Add
          </Button>
          <Button
            disabled={isView}
            title="Remove dimension"
            variant="text"
            onClick={handleDeleteClick}
          >
            Remove
          </Button>
        </Typography>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ width: "98%", margin: "auto", marginBottom: "25px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="tableHeader">
              <TableCell>Select</TableCell>
              <TableCell align="left">Period Type</TableCell>
              <TableCell align="left">Base Level</TableCell>
              <TableCell align="left">Level</TableCell>
              <TableCell align="left">Display Settings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeDimensionData?.map((data, index) => {
              return (
                <>
                  <TableRow key={index}>
                    <TableCell>
                      {/* <Checkbox /> */}
                      <Checkbox
                        checked={data?.enabled}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </TableCell>
                    <TableCell align="left">{data["Period Type"]}</TableCell>
                    <TableCell align="left">{data["Base Level"]}</TableCell>
                    <TableCell align="left">{data?.Level}</TableCell>
                    <TableCell align="left">{data["Period Type"] === "Day" ? "MM/DD/YYYY" : data["Display Settings"]}</TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {showfPopup && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure the popup is on top of other elements
          }}
        >
          <Box sx={{ backgroundColor: "#fff", width: "30%" }}>
            <DialogTitle>Create Time Dimension</DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important", margin: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="periodType"
                    label="Period Type"
                    variant="outlined"
                    fullWidth
                    value={dimensionValues.periodType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="baseLevel"
                    label="Base Level"
                    variant="outlined"
                    fullWidth
                    value={dimensionValues.baseLevel}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="level"
                    label="Level"
                    variant="outlined"
                    fullWidth
                    value={dimensionValues.level}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="displaySettings"
                    label="Display Settings"
                    variant="outlined"
                    fullWidth
                    value={dimensionValues.displaySettings}
                    onChange={handleChange}
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    marginTop: "25px",
                  }}
                >
                  <Button title="Remove dimension" variant="text" onClick={handleCancelDimension} sx={{textTransform: "capitalize"}}>
                    Cancel
                  </Button>
                  <Button
                    title="Add new dimension"
                    variant="text"
                    onClick={handleAddDimension} sx={{textTransform: "capitalize"}}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </DialogContent>
          </Box>
        </Box>
      )}

      {showsPopup && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure the popup is on top of other elements
          }}
        >
          <Box sx={{ backgroundColor: "#fff", width: "30%" }}>
            <DialogTitle>Create Time Dimension</DialogTitle>
            <DialogContent
              sx={{ paddingTop: "10px !important", margin: "auto" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="elementname"
                    label="Element Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="periodType"
                    label="Period Type"
                    variant="outlined"
                    fullWidth
                    value={dimensionValues.periodType}
                    onChange={handleChange}
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    marginTop: "25px",
                  }}
                >
                  <Button
                    disabled={isView}
                    title="Remove dimension"
                    variant="text"
                    onClick={handlesPopupClose} sx={{textTransform: "capitalize"}}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isView}
                    onClick={handleAddDimension}
                    title="Add new dimension"
                    variant="text" sx={{textTransform: "capitalize"}}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </DialogContent>
          </Box>
        </Box>
      )}

      {isModalOpen && (
        <DimensionListModel
          selectedDimensions={selectedDimensions}
          setSelectedDimensions={setSelectedDimensions}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          checkedDimensions={checkedDimensions}
          setCheckedDimensions={setCheckedDimensions}
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          formValues={formValues}
          modelEnterprise={modelEnterprise}
        />
      )}
      {renderModalContent()}
    </>
  );
};

export default TimeDimensionsTable;
