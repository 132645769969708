import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { API_BASE_URL } from "../../../helper/constants";
import moment from "moment";

const AttributesListModel = ({
  dimensionAttributes,
  setDimensionAttributes,
  isModalOpen,
  setModalOpen,
  selectedAttributes,
  setSelectedAttributes,
  formValues,
  newData,
  isEdit,
  formValueData,
  modelEnterprise
}) => {
  const [allAttributes, setAllAttributes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const isAttributeSelected = (id) => {
    return selectedAttributes?.length > 0 && selectedAttributes?.find((item) => item._id === id) ? true : false;
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedAttributes([...selectedAttributes, item]);
    } else {
      setSelectedAttributes((prevObjects) =>
        prevObjects.filter((attr) => attr._id != item._id)
      );
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    const uniqueObjects = new Map();
    [...selectedAttributes, ...dimensionAttributes].forEach((obj) => {
      uniqueObjects.set(obj._id, obj);
    });
    let uniqueAttributesArray = Array.from(uniqueObjects.values());
    setDimensionAttributes(uniqueAttributesArray);
    setModalOpen(false);
    setSelectedAttributes([]);
  };

  const filteredAttributes = allAttributes?.filter(
    (attr) => searchTerm?.length ? attr?.name?.toLowerCase().includes(searchTerm.toLowerCase()) : attr
  );

  useEffect(() => {
    const fetchAttributes = async () => {
      const userId = localStorage.getItem("accessToken");
      let enterpriseId = modelEnterprise?.enterpriseUID
      let modelId = modelEnterprise?.modelUID
      const baseURL = `${API_BASE_URL}/attributes/${enterpriseId}/${modelId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };
      let response = await axios.get(baseURL, config);
      let allAttr = response.data.data;
      const toRemoveIds = new Map(
        dimensionAttributes.map((obj) => [obj._id, true])
      );
      // allAttr = allAttr.filter(
      //   (obj) =>
      //     obj.enterpriseUID._id === formValues?.enterpriseName &&
      //     obj.modelUID?.id === formValues?.modelName &&
      //     !toRemoveIds.has(obj._id)
      // );
      setAllAttributes(allAttr);
    };
    if (modelEnterprise?.enterpriseName && modelEnterprise?.modelName) {
      fetchAttributes();
    }
  }, [modelEnterprise?.enterpriseName, modelEnterprise?.modelName]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Attributes</DialogTitle>
      <DialogContent>
        {/* Serach Bar is not complete  */}
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          onChange={handleSearch}
          value={searchTerm}
          margin="normal"
        />

        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Length</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>CreatedOn</TableCell>
                <TableCell>ChangedOn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAttributes.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <Checkbox
                      value={item._id}
                      checked={isAttributeSelected(item._id)}
                      onChange={(event) => handleCheckboxChange(event, item)}
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.length}</TableCell>
                  <TableCell>{item.notes}</TableCell>
                  <TableCell>{moment(item.createdOn).format('MM/DD/YYYY')}</TableCell>
                  <TableCell>{moment(item.changedOn).format('MM/DD/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" sx={{ textTransform: "capitalize" }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AttributesListModel;
