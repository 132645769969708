import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Dialog,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { getModelListAsync } from '../store/ApiSlice/dimensionSlice';
import { useSelector } from 'react-redux';
import { getMeasureList } from "../store/ApiSlice/measureSlice";
import { createDimensionFilteredmasterData, createViewAggregated, deleteHomeWidget, getCanvasList, getFilterDetail, getHomePageWidget } from '../store/ApiSlice/canvasSlice';
import { MdMoreVert } from 'react-icons/md';
import DeleteModal from '../components/commonComponent/DeleteComponent/DeleteModal';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-data-grid/lib/styles.css';
import RGL, { WidthProvider } from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { setWidgetLayout } from '../store/ApiSlice/widgetSlice';
import '../../src/components/page/page.css';
import * as ExcelJS from 'exceljs';
import EditWidget from './EditWidget';
import { getScenarios } from '../store/ApiSlice/processSlice';
import { getEnterpriseList, setIsAddWidgetsOpenModal } from '../store/ApiSlice/enterpriseSlice';
import { getEnterModelList } from '../store/ApiSlice/modelSlice';
import { toast } from 'react-toastify';
import { FiMessageCircle, FiPlus } from "react-icons/fi";
import AddWidget from './AddWidget';
import { getAttributes } from '../store/ApiSlice/attributeSlice';
import { PivotViewComponent, PivotFieldListComponent, FieldList, Inject as PivotInject, DrillThrough } from '@syncfusion/ej2-react-pivotview';
import { DashboardLayoutComponent, PanelsDirective, PanelDirective } from "@syncfusion/ej2-react-layouts";
import { IoMdClose } from "react-icons/io";
import { FiGrid, FiHash, FiFilter, FiSliders } from "react-icons/fi";
import { IoAddCircleOutline, IoStatsChartOutline } from "react-icons/io5";
import Select from 'react-select';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject as ChartInject,
  StackingColumnSeries,
  LineSeries,
  Category,
  Tooltip,
  Legend,
} from "@syncfusion/ej2-react-charts";
import EditcanvasWidget from '../components/page/EditcanvasWidget';
import PivotToolbar from './component/pivotTable';
const ReactGridLayout = WidthProvider(RGL);



export default function Home(props) {
  const user = JSON.parse(localStorage.getItem("accessToken"))
  const { measureData } = useSelector((state) => state.measure)
  const { enterpriseData, isAddWidgetsOpenModal } = useSelector((state) => state.enterprise)
  const { modalData } = useSelector((state) => state.modelList)
  const { canvasData } = useSelector((state) => state.canvas)
  const [newActiveWidgetLoader, setNewActiveWidgetLoader] = useState({})
  const [loader, setLoader] = useState(false);
  const [openWidgetModal, setOpenWidgetModal] = useState({})
  const [deleteWidget, setDeleteWidget] = useState(false)
  const [widgetId, setWidgetId] = useState("")
  const [selectedRows, setSelectedRows] = useState([]);
  const { homePageWidgetData, filterDetail } = useSelector(
    (state) => state.canvas
  );
  const [formValues, setFormValues] = useState({
    pages: []
  });
  const { widgetLayout } = useSelector((state) => state.widget)
  const [widgetGroupData, setWidgetGroupData] = useState([])
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fullScreenWidget, setFullScreenWidget] = useState(null);
  const [editWidgetModal, setEditWidgetModal] = useState(false)
  const [CanvasOpenModal, setCanvasOpenModal] = useState(false)
  // const [isAddWidgetsOpenModal, setIsAddWidgetsOpenModal] = useState(false)
  const [updatedId, setUpdatedId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [canvasList, setCanvasList] = useState([])
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeType, setChangeType] = useState()
  const [chartData, setChartData] = useState({
    tableData: [],
    value: {}
  })

  const [widgetSubType, setWidgetSubType] = useState('');
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [dimensionOptions, setDimensionOptions] = useState([]);
  const [selectedDimension, setSelectedDimension] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  };

  const [columns, setColumns] = useState(["Month"]);
  const [rows, setRows] = useState(["Product", "3 Metrics"]);
  const [isRowModalOpen, setRowModalOpen] = useState(false);
  const [isColumnModalOpen, setColumnModalOpen] = useState(false);
  const [newRowName, setNewRowName] = useState("");
  const [newColumnName, setNewColumnName] = useState("");

  const addRow = () => {
    setRowModalOpen(true);
  };

  const addColumn = () => {
    setColumnModalOpen(true);
  };

  const handleAddRow = () => {
    if (newRowName.trim() !== "") {
      setRows([...rows, newRowName.trim()]);
      setNewRowName("");
      setRowModalOpen(false);
    }
  };

  const handleAddColumn = () => {
    if (newColumnName.trim() !== "") {
      setColumns([...columns, newColumnName.trim()]);
      setNewColumnName("");
      setColumnModalOpen(false);
    }
  };

  const removeRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const removeColumn = (index) => {
    setColumns(columns.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const storedCanvasList = localStorage.getItem("canvasList");
    setCanvasList(storedCanvasList ? JSON.parse(storedCanvasList) : []);
  }, []);


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id } = useParams()

  const widgetIdData = id !== undefined ? homePageWidgetData?.filter((item) => item?.widgetUID?._id === id) : homePageWidgetData

  const dispatch = useDispatch();

  useEffect(() => {

    if (widgetGroupData?.length > 0 && (widgetLayout?.length === 0 || widgetGroupData?.length !== widgetLayout?.length)) {
      const widgetsCount = widgetGroupData?.length;
      const layouts = [];
      let defaultY = 0;
      let defaultX = 0;
      const defaultW = widgetsCount > 3 ? 3 : Math.floor(12 / widgetsCount);

      for (let index = 0; index < widgetsCount; index++) {
        if (index % 4 === 0 && index !== 0) {
          defaultY += 5;
          defaultX = 0;
        }

        layouts.push({
          w: defaultW,
          h: 5,
          x: defaultX,
          y: defaultY,
          i: index.toString(),
          moved: false,
          static: false,
        });


        defaultX += defaultW;
      }
      dispatch(setWidgetLayout(layouts));
    }
  }, [widgetGroupData]);

  const handleFullScreenToggle = (groupIndex) => {

    const widgetsCount = widgetIdData?.length;
    const defaultW = widgetsCount > 3 ? 3 : widgetsCount === 1 ? 4 : Math.floor(12 / widgetsCount);

    if (fullScreenWidget === groupIndex) {
      setFullScreenWidget(null);
      setTimeout(() => {
        const newLayout = widgetLayout?.map((l, i) =>
          i === groupIndex ? { ...l, w: defaultW, h: 5 } : l
        );
        dispatch(setWidgetLayout(newLayout));
      }, 0);

    } else {
      setFullScreenWidget(groupIndex);
      setTimeout(() => {
        const newLayout = widgetLayout?.map((l, i) =>
          i === groupIndex ? { ...l, w: 12, h: 6 } : l
        );
        dispatch(setWidgetLayout(newLayout));
      }, 0);
    }
  };

  const getWidgetLayoutFromLocalStorage = () => {
    const layoutJSON = localStorage.getItem('widgetLayout');
    return layoutJSON ? JSON.parse(layoutJSON) : [];
  };


  useEffect(() => {
    const savedLayout = getWidgetLayoutFromLocalStorage();
    if (savedLayout?.length > 0) {
      dispatch(setWidgetLayout(savedLayout));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHomePageWidget())
    dispatch(getModelListAsync());
    dispatch(getMeasureList());
    dispatch(getEnterpriseList())
    dispatch(getFilterDetail())
    dispatch(getCanvasList())
    dispatch(getEnterModelList())
    dispatch(getScenarios())
    dispatch(getAttributes())
  }, []);


  const processPageData = async () => {
    let newPageData = [];

    if (widgetIdData?.length > 0) {

      for (const PageItem of widgetIdData) {

        let pageTab = {};

        if (PageItem?.widgetUID) {
          const findFilterId = filterDetail.find(filter => filter?._id === PageItem?.widgetUID?.filter);
          const widgetType = PageItem?.widgetUID?.widgetType === "Chart" ? PageItem?.widgetUID?.chart : PageItem?.widgetUID?.worksheet;
          const timeName = widgetType?.timePeriods?.periodType;

          let payload, result;
          if (PageItem?.widgetUID?.widgetSubType === "Measure Data" || PageItem?.widgetUID?.widgetType === "Chart") {
            payload = {
              ...(widgetType?.measures?.length > 0 && { measures: widgetType?.measures }),
              timeFields: { [timeName]: widgetType?.timePeriods?.periodValues },
              aggregationFields: widgetType?.attributes?.map(attr => attr?.attributeName),
              ...(PageItem?.widgetUID?.scenario?.length > 0 && { scenario: PageItem?.widgetUID?.scenario }),
              ...(PageItem?.widgetUID?.cycleID?.length > 0 && { cycleID: PageItem?.widgetUID?.cycleID }),
              ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId.attributes.map(attr => ({
                  attribute: attr?.attributeUID?.name,
                  operator: attr?.operator,
                  values: attr?.values,
                }))
              })
            };
            console.log("payload------->", payload)
            result = await dispatch(createViewAggregated({ payload }));
          } else {
            payload = {
              dimensionUID: PageItem?.widgetUID?.worksheet?.dimension,
              attributes: PageItem?.widgetUID?.worksheet?.attributes?.map(item => item?.attributeName),
              ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId?.attributes?.map(item => ({
                  attribute: item?.attributeUID?.name,
                  operator: item?.operator,
                  values: item?.values,
                }))
              }),
              widgetUID: PageItem?.widgetUID?.worksheet?._id,
              scenario: PageItem?.widgetUID?.scenario?.map(item => item),
            };
            result = await dispatch(createDimensionFilteredmasterData({ payload }));
          }
          pageTab = {
            [PageItem?.widgetUID?.widgetName]: [{
              ...PageItem,
              getResul: result?.payload?.data,
            }]
          };
        }
        newPageData.push(pageTab);
      }
      setWidgetGroupData(newPageData);
    }
  };


  useEffect(() => {
    processPageData()
    if (!widgetIdData?.length) {
      setWidgetGroupData([])
    }
  }, [homePageWidgetData])

  const widgetpageTab = widgetGroupData?.length > 0
    ? widgetGroupData?.reduce((acc, item) => {
      if (item) {
        return {
          ...acc,
          ...item
        };
      }
      return acc;
    }, {})
    : {};
  const groupedWidgets = widgetpageTab

  const generateExcel = (datas) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    if (datas?.length > 0) {
      // Add headers
      const header = Object.keys(datas[0])?.filter(key => key !== "_id");
      worksheet.addRow(header);

      // Add rows
      datas.forEach(obj => {
        const row = header?.map(col => obj[col] !== undefined ? obj[col] : '');
        worksheet.addRow(row);
      });
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const fileName = 'measure_data.xlsx';
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };


  const handleDelete = () => {
    return (
      <>
        <DeleteModal
          isModalOpenDelete={deleteWidget}
          setModalOpenDelete={setDeleteWidget}
          attriId={widgetId}
          setSelectedRows={setSelectedRows}
          title="Widget"
          deleteAPI={deleteHomeWidget}
          getAPI={getHomePageWidget}
        />
      </>
    )
  }

  const handleOpenCanvas = () => {
    setCanvasOpenModal(true);
    setUpdatedId(null)
    handleClose()
  };

  const handleOpenAddWidgets = () => {
    dispatch(setIsAddWidgetsOpenModal(true));
    setAnchorEl(null);
  };

  const handleDimensionFieldChange = (e, fieldName) => {
    const { value } = e.target
    if (fieldName === 'enterpriseName') {
      const selectedData = enterpriseData.find((data) => data._id === value);
      setFormValues({
        ...formValues,
        [fieldName]: selectedData?.enterpriseName,
        [`enterpriseUID`]: value
      });
    }
    else if (fieldName === 'modelName') {
      const selectedData = modalData?.find((data) => data.modelUID === value);
      setFormValues({
        ...formValues,
        [fieldName]: selectedData?.modelName,
        [`modelUID`]: value
      });
    } else if (fieldName === 'canvasUID') {
      const selectedData = canvasData?.find((data) => data._id === value);
      setFormValues({
        ...formValues,
        [fieldName]: value,
        [`canvasName`]: selectedData?.name
      });
    }
  }

  const handleSubmit = () => {
    if (!formValues?.enterpriseUID) {
      toast.error("Please select enterprise");
      return;
    }
    if (!formValues?.modelUID) {
      toast.error("Please select model");
      return;
    }
    if (!formValues?.canvasUID) {
      toast.error("Please select canvas");
      return;
    }

    setCanvasList(prevList => {
      const updatedList = [...prevList, formValues];
      localStorage.setItem("canvasList", JSON.stringify(updatedList));
      return updatedList;
    });
    setCanvasOpenModal(false)
    setFormValues({})

  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: '1px solid #EAEAEA',
      borderRadius: '0.5rem',
      boxShadow: state.isFocused ? '1px 0px 4px 1px rgba(247, 142, 107, 0.8)' : '0 1px 2px -1px rgb(0 0 0 / 0.1)',
      fontSize: '0.875rem',
      outline: 'none',
      '&:hover': {
        outline: 'none',
      },
    }),
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleWidgetSubTypeChange = (e) => {
    setWidgetSubType(e.target.value);
  };

  const maxRowsPerColumn = 5;
  const columnWidth = 3;

  const updatedLayout = homePageWidgetData?.map((item, index) => ({
    i: `widget-${index}`,
    x: Math.floor(index / maxRowsPerColumn) * columnWidth, // Column-wise positioning
    y: index % maxRowsPerColumn, // Stack items vertically within the column
    w: columnWidth, // Column width (adjust as needed)
    h: 1, // Row height (1 row per item)
  }));

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        avatarRef.current &&
        !avatarRef.current.contains(event.target)
      ) {
        setAnchorEl(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let pivotObj;


  // const dashboardObj = useRef(null);

  const cellSpacing = [5, 5];
  const uniquePanels = new Set();

  useEffect(() => {
    // Wait for the component to be available in the DOM
    if (!dashboardObj.current) return;

    setTimeout(() => {
      const element = document.getElementById("default_dashboard");
      if (element) {
        console.log("Dashboard Loaded after delay");
      } else {
        console.warn("Dashboard element not found.");
      }
    }, 500);
  }, [homePageWidgetData]);

  useEffect(() => {
    return () => {
      const pivotElement = document.querySelector('#pivot-container');
      if (pivotElement) {
        pivotElement.innerHTML = ''; // Clear the content safely
      }
    };
  }, []);

  const pivotRef = useRef(null);

  useEffect(() => {
    return () => {
      if (pivotRef.current) {
        pivotRef.current.destroy(); // Ensures cleanup before unmounting
      }
    };
  }, []);

  const dashboardObj = useRef(null);
  const pivotRefs = useRef({});

  // Initialize refs object for each pivot table
  useEffect(() => {
    homePageWidgetData?.forEach((item, index) => {
      Object.entries(groupedWidgets).forEach(([groupName, widgets]) => {
        widgets.forEach((widgetItem, widgetIndex) => {
          const widgetKey = widgetItem?._id || `widget-${index}-${groupName}-${widgetIndex}`;
          if (!pivotRefs.current[widgetKey]) {
            pivotRefs.current[widgetKey] = React.createRef();
          }
        });
      });
    });
  }, [homePageWidgetData, groupedWidgets]);

  // Cleanup function for pivot tables
  const cleanupPivotTable = (widgetKey) => {
    if (pivotRefs.current[widgetKey]?.current) {
      try {
        pivotRefs.current[widgetKey].current.destroy();
      } catch (error) {
        console.warn(`Failed to cleanup pivot table ${widgetKey}:`, error);
      }
    }
  };

  // Cleanup all pivot tables when component unmounts
  useEffect(() => {
    return () => {
      Object.keys(pivotRefs.current).forEach(cleanupPivotTable);
    };
  }, []);

  const getPanelDimensions = (panel) => {
    return {
      row: panel.row,
      col: panel.col,
      sizeX: panel.sizeX,
      sizeY: panel.sizeY,
    };
  };

  // Helper function to check panel overlap
  const checkPanelOverlap = (panel1, panel2) => {
    const p1Right = panel1.col + panel1.sizeX;
    const p1Bottom = panel1.row + panel1.sizeY;
    const p2Right = panel2.col + panel2.sizeX;
    const p2Bottom = panel2.row + panel2.sizeY;

    return !(
      panel1.col >= p2Right ||
      p1Right <= panel2.col ||
      panel1.row >= p2Bottom ||
      p1Bottom <= panel2.row
    );
  };

  const handleDragStop = (args) => {
    if (!args || !args.element || !dashboardObj.current) {
      return;
    }

    try {
      const dashboardElement = dashboardObj.current;
      const panels = dashboardElement.panels || [];
      const draggedPanel = panels.find(panel => panel.id === args.element.id);

      if (!draggedPanel) {
        console.warn('Dragged panel not found');
        return;
      }

      // Get the new position of the dragged panel
      const newPosition = getPanelDimensions(draggedPanel);

      // Check if the new position is valid
      let isValidPosition = true;
      let adjustedPosition = { ...newPosition };

      // Check boundaries
      if (newPosition.col < 0) adjustedPosition.col = 0;
      if (newPosition.row < 0) adjustedPosition.row = 0;

      // Get dashboard bounds
      const dashboardColumns = dashboardElement.columns || 5;
      const maxCol = dashboardColumns - newPosition.sizeX;

      if (newPosition.col > maxCol) {
        adjustedPosition.col = maxCol;
      }

      // Check for overlaps with other panels
      panels.forEach(panel => {
        if (panel.id !== draggedPanel.id) {
          if (checkPanelOverlap(adjustedPosition, getPanelDimensions(panel))) {
            isValidPosition = false;

            // Try to find nearest valid position
            const alternativePositions = [
              { row: panel.row + panel.sizeY, col: panel.col }, // Below
              { row: panel.row, col: panel.col + panel.sizeX }, // Right
              { row: panel.row - draggedPanel.sizeY, col: panel.col }, // Above
              { row: panel.row, col: panel.col - draggedPanel.sizeX }, // Left
            ];

            for (const pos of alternativePositions) {
              const testPosition = { ...adjustedPosition, ...pos };
              let isValidAlternative = true;

              // Check if alternative position is within bounds
              if (testPosition.col < 0 || testPosition.col > maxCol || testPosition.row < 0) {
                continue;
              }

              // Check if alternative position overlaps with any other panel
              panels.forEach(otherPanel => {
                if (otherPanel.id !== draggedPanel.id) {
                  if (checkPanelOverlap(testPosition, getPanelDimensions(otherPanel))) {
                    isValidAlternative = false;
                  }
                }
              });

              if (isValidAlternative) {
                adjustedPosition = testPosition;
                isValidPosition = true;
                break;
              }
            }
          }
        }
      });

      // Update panel position if valid
      if (isValidPosition) {
        dashboardElement.updatePanel(draggedPanel.id, {
          row: adjustedPosition.row,
          col: adjustedPosition.col
        });

        // Optional: Save the new layout to your state management system
        const newLayout = panels.map(panel => ({
          id: panel.id,
          ...getPanelDimensions(panel)
        }));

        // You can dispatch this to your state management system
        // dispatch(updateDashboardLayout(newLayout));

        // Or save to local storage
        localStorage.setItem('dashboardLayout', JSON.stringify(newLayout));
      } else {
        // Revert to original position if no valid position found
        dashboardElement.updatePanel(draggedPanel.id, {
          row: draggedPanel.row,
          col: draggedPanel.col
        });
      }

    } catch (error) {
      console.error('Error in handleDragStop:', error);
      // Optionally show user feedback
      // toast.error('Failed to update dashboard layout');
    }
  };

  useEffect(() => {
    if (isModalOpen && chartData?.value?.widgetUID?._id) {
      setFormValues({
        widgetName: chartData?.value?.widgetUID?.widgetName,
        widgetType: chartData?.value?.widgetUID?.widgetType,
        widgetSubType: chartData?.value?.widgetUID?.widgetSubType,

      })
    }
  }, chartData?.value?.widgetUID?._id)

  const transformData = (data, dynamicScenarios) => {
    const attributeMap = chartData?.value?.widgetUID?.worksheet?.attributes?.reduce((acc, attr) => {
      acc[attr.attributeName] = attr.attributeUID;
      return acc;
    }, {});

    let groupedData = {};
    if (!Array.isArray(data)) {
      return; // or set data to an empty array: data = []
    }

    data?.forEach((entry) => {
      const productId = entry[Object.keys(attributeMap)[0]];

      if (!groupedData[productId]) {
        groupedData[productId] = {};
      }

      Object.keys(entry).forEach((key) => {
        if (key.startsWith("M")) {  // If it's a month key (e.g., M1 2024)
          if (!groupedData[productId][key]) {
            groupedData[productId][key] = { month: key };
          }

          // Initialize dynamic scenarios only if they aren't already present
          dynamicScenarios.forEach((scenario) => {
            if (!groupedData[productId][key][scenario]) {
              groupedData[productId][key][scenario] = 0;  // Initialize all dynamic scenarios as 0
            }
          });

          const value = entry[key] ? parseFloat(entry[key]) : 0;
          const scenario = entry["Scenario"];  // This should match exactly with "Baseline", "New Customer", "Upside"


          // Ensure the scenario exists in the groupedData object for this month
          if (groupedData[productId][key][scenario] !== undefined) {
            groupedData[productId][key][scenario] += value;  // Add the value to the corresponding scenario
          }
        }
      });
    });


    let stackedData = [];
    let lineData = [];

    Object.keys(groupedData).forEach((productId) => {
      Object.values(groupedData[productId]).forEach((dataPoint) => {
        const row = { productId, month: dataPoint.month };

        // Push each dynamic scenario value into the stackedData
        dynamicScenarios.forEach((scenario) => {
          row[scenario] = dataPoint[scenario];
        });

        stackedData.push(row);

        // Sample line calculation (sum of all scenario values)
        lineData.push({
          x: `${productId} - ${dataPoint.month}`,
          y: dynamicScenarios.reduce((sum, scenario) => sum + dataPoint[scenario], 0),
        });
      });
    });

    return { stackedData, lineData };
  }

  const dynamicScenarios = chartData?.value?.widgetUID?.scenario;
  const { stackedData } = transformData(chartData?.tableData, dynamicScenarios)
  const transformedData = stackedData?.filter((item) => item?.[chartData?.value?.widgetUID?.worksheet?.timePeriods?.periodType?.toLowerCase()] !== "Measure Name")
  const toCamelCase = (str) => {
    return str
      .split(' ')  // Split the string by spaces
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join('');
  };

  return (
    <>

      <div className='w-full'>
        <div className="relative">
          {/* <div className="flex justify-end items-center">
            <button
              ref={avatarRef}
              id="dropdownInformationButton"
              onClick={handleClick}
              className="font-semibold rounded-full text-sm p-3 flex items-center gap-2 shadow-md hover:shadow-lg transition-all duration-300 transform active:scale-95"
              type="button"
              style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
            >
              <FiPlus className="w-5 h-5 text-black" />
            </button>
          </div> */}
          <PivotToolbar />

          {open && (
            <div
              ref={dropdownRef}
              id="dropdownInformation"
              className="absolute right-0 mt-2 z-[1] w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600"
            >
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                <li>
                  <button
                    className="w-full text-left px-4 py-2 flex items-center gap-2 text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all rounded-b-lg"
                  >
                    Models
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleOpenCanvas}
                    className="w-full text-left px-4 py-2 flex items-center gap-2 text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all rounded-t-lg"
                  >
                    Canvas Pages
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleOpenAddWidgets}
                    className="w-full text-left px-4 py-2 flex items-center gap-2 text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all"
                  >
                    Widget
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div id="default_target" className="control-section">
          <DashboardLayoutComponent
            id="default_dashboard"
            ref={dashboardObj}
            columns={5}
            cellSpacing={cellSpacing}
            allowResizing={true}
            allowDragging={true}
            allowFloating={true}
            enableRtl={false}
            dragStart={(args) => console.log("Drag Started: ", args)}
            dragStop={handleDragStop}
          >
            <PanelsDirective>
              {
                // (() => {
                //   const uniquePanels = new Set();

                //   return 
                homePageWidgetData?.map((item, index) => {
                  return Object.entries(groupedWidgets).map(([groupName, widgets], groupIndex) => {
                    return widgets
                      .filter((widgetItem) => {
                        const key = widgetItem?._id || `widget-${index}-${groupIndex}`;
                        if (uniquePanels.has(key)) return false;
                        uniquePanels.add(key);
                        return true;
                      })
                      .map((widgetItem, widgetIndex) => {
                        const widgetKey = widgetItem?._id || `widget-${index}-${groupIndex}-${widgetIndex}`;

                        const pivotData = widgetItem?.getResul || [];
                        const rowDefinitions = widgetItem?.widgetUID?.worksheet?.attributes?.map((attr) => ({
                          name: attr?.attributeName,
                          caption: attr?.attributeName // Adding caption to ensure proper display
                        })) || [];

                        return (
                          <PanelDirective
                            key={widgetKey}
                            id={widgetKey}
                            sizeX={2}
                            sizeY={1}
                            row={Math.floor(widgetIndex / 3)}
                            col={widgetIndex % 3}
                            content={() => (
                              <div className="e-panel-container e-panel px-6">
                                <div className="flex justify-end items-center py-[31px] pb-[34px]">
                                  <div className="relative">
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenDropdown(openDropdown === widgetKey ? null : widgetKey);
                                      }}
                                      className="p-1 text-black rounded-full hover:bg-gray-200"
                                    >
                                      <MdMoreVert className="cursor-pointer z-10" />
                                    </button>

                                    {openDropdown === widgetKey && (
                                      <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                                        <ul className="py-1 text-sm text-gray-700">
                                          <li className="px-4 py-1 cursor-pointer hover:bg-gray-100 text-[10px]"
                                            onClick={() => {
                                              toggleModal();
                                              setChartData({
                                                tableData: widgetItem?.getResul,
                                                value: widgetItem
                                              });
                                              setChangeType("grid");
                                              setOpenDropdown(null)
                                            }}>Edit</li>
                                          <li className="px-4 py-1 cursor-pointer hover:bg-gray-100 text-[10px]"
                                            onClick={() => {
                                              setWidgetId(widgetItem?.widgetUID?._id)
                                              setDeleteWidget(true)
                                              setOpenDropdown(null)
                                            }}>Delete</li>
                                          {/* <li className="px-4 py-1 cursor-pointer hover:bg-gray-100 text-[10px]"
                                            onClick={() => {
                                              copyToClipboard(widgetItem?.getResul)
                                              setOpenDropdown(null)
                                            }}>Copy Data</li> */}
                                          <li className="px-4 py-1 cursor-pointer hover:bg-gray-100 text-[10px]"
                                            onClick={() => {
                                              generateExcel(widgetItem?.getResul)
                                              setOpenDropdown(null)
                                            }}
                                          >Download Data</li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>

                                </div>
                                <div className="relative">
                                  <PivotViewComponent
                                    id={`pivot-table-${widgetKey}`}
                                    allowEditing={true}
                                    editSettings={{
                                      allowEditing: true,
                                      allowAdding: true,
                                      allowDeleting: true,
                                    }}
                                    dataSourceSettings={{
                                      dataSource: widgetItem?.getResul || [],
                                      expandAll: false,
                                      columns: [{ name: "Scenario" }],
                                      values: widgetItem?.widgetUID?.worksheet?.timePeriods?.periodValues?.map((time) => ({
                                        name: time,
                                        caption: time,
                                        allowEditing: true,
                                      })),
                                      rows: widgetItem?.widgetUID?.worksheet?.attributes?.map((attr) => ({
                                        name: attr?.attributeName,
                                      })),
                                      filters: [],
                                      showGrandTotals: false,
                                      showSubTotals: false
                                    }}
                                    showFieldList={false}
                                    className="table-scroll"
                                    onClick={(event) => event.stopPropagation()}
                                  >
                                    <PivotInject services={[DrillThrough]} />
                                  </PivotViewComponent>
                                </div>
                              </div>
                            )}
                          />
                        );
                      })
                  })
                }
                )
                // })()
              }
            </PanelsDirective>
          </DashboardLayoutComponent>
        </div>
      </div >
      <Dialog open={deleteWidget} onClose={() => setDeleteWidget(false)}>
        {handleDelete()}
      </Dialog>

      <Dialog
        open={editWidgetModal}
        onClose={() => {
          setEditWidgetModal(false)
          setWidgetId()
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: 'auto',
            width: '600px',
          }}
        >
          <EditWidget
            widgetId={widgetId}
            editWidgetModal={editWidgetModal}
            setEditWidgetModal={setEditWidgetModal}
          />
        </Box>
      </Dialog>

      {CanvasOpenModal && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-[600px] p-6">
            <div className="space-y-5">
              {/* Enterprise Name */}
              <div className="w-full">
                <label htmlFor="enterpriseName" className="block text-sm font-medium text-gray-700 mb-1">Enterprise Name</label>
                <select className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                  id="enterpriseName"
                  name="enterpriseName"
                  value={
                    formValues?.enterpriseUID
                  }
                  onChange={(e) => handleDimensionFieldChange(e, "enterpriseName")}
                  disabled={!user?.data?.superAdmin}
                >
                  <option selected value="" disabled>Select an enterprise</option>
                  {enterpriseData?.map(option => (
                    <option key={option._id} value={option._id}>{option.enterpriseName}</option>
                  ))}
                </select>
              </div>

              {/* Model Name */}
              <div className="w-full">
                <label htmlFor="modalName" className="block text-sm font-medium text-gray-700 mb-1">Model Name</label>
                <select className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                  id="modalName"
                  name="modalName"
                  value={formValues?.modelUID || ""}
                  onChange={(e) => handleDimensionFieldChange(e, "modelName")}
                >
                  <option value="" disabled>Select a model</option>  {/* Default option */}
                  {modalData?.filter(item => item?.enterpriseUID?._id === formValues?.enterpriseUID)?.map(option => (
                    <option key={option.modelUID} value={option.modelUID}>{option.modelName}</option>
                  ))}
                </select>
              </div>

              {/* Canvas Name */}
              <div className="w-full">
                <label htmlFor="canvasUID" className="block text-sm font-medium text-gray-700 mb-1">Canvas Name</label>
                <select className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                  id="canvasUID"
                  name="canvasUID"
                  value={formValues?.canvasUID || ""}
                  onChange={(e) => handleDimensionFieldChange(e, "canvasUID")}
                  disabled={!user?.data?.superAdmin}
                >
                  <option selected value="" disabled>Select a canvas</option>
                  {canvasData?.filter(item => item?.enterpriseUID?._id === formValues?.enterpriseUID)
                    ?.filter(item => item?.modelUID?.modelUID === formValues?.modelUID)
                    .map(option => (
                      <option key={option._id} value={option._id}>{option.name}</option>
                    ))}
                </select>
              </div>

              {/* Dialog Actions */}
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-1 text-base text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50 transition duration-300"
                  onClick={() => {
                    setCanvasOpenModal(false)
                    setFormValues({})
                  }}
                >
                  Cancel
                </button>
                <button className="px-4 py-1 text-base bg-[#FF6C46] text-white rounded-lg hover:bg-black transition duration-300"
                  onClick={handleSubmit}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {isAddWidgetsOpenModal && (
        <AddWidget />
      )} */}

      {isModalOpen && (
        <EditcanvasWidget
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          changeType={changeType}
          setChangeType={setChangeType}
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          chartData={chartData}
          setChartData={setChartData}
          toCamelCase={toCamelCase}
          transformedData={transformedData}
          formValues={formValues}
          setFormValues={setFormValues}
          addColumn={addColumn}
          columns={columns}
          setColumns={setColumns}
          removeColumn={removeColumn}
          addRow={addRow}
          rows={rows}
          setRows={setRows}
          removeRow={removeRow}
          isRowModalOpen={isRowModalOpen}
          newRowName={newRowName}
          setNewRowName={setNewRowName}
          setRowModalOpen={setRowModalOpen}
          handleAddRow={handleAddRow}
          isColumnModalOpen={isColumnModalOpen}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
          setColumnModalOpen={setColumnModalOpen}
          handleAddColumn={handleAddColumn}
        />
      )}
    </>
  );
}
