import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createScenario, getAllParentScenarioList, getAllScenarioList } from '../../../store/ApiSlice/scenarioListSlice';
import { toast } from 'react-toastify';

const CreateScenario = ({
    isCreateUpdateModalOpen,
    setIsCreateUpdateModalOpen,
    isEdit,
    selectedRows,
    formValues,
    updatedId
}) => {
    const { parentScenarioData, scenarioData } = useSelector((state) => state?.scenario);
    const [scenarioFormValue, setScenarioFormValue] = useState({})
    const dispatch = useDispatch()
    const handleCloseModal = () => {
        setIsCreateUpdateModalOpen(false);
    };

    const selectScenario = scenarioData?.find((item) => item?._id === updatedId)

    useEffect(() => {
        if (isEdit && selectScenario) {
            setScenarioFormValue({
                scenarioName: selectScenario?.scenarioName,
                parentScenario: selectScenario?.parentScenario,
                scenarioNotes: selectScenario?.scenarioNotes,
            })
        }
    }, [isEdit, selectScenario])

    const handleCreateScenario = async () => {
        if (scenarioFormValue?.scenarioName === "" || scenarioFormValue?.scenarioName === undefined) {
            toast.error("Please enter scenario name.")
            return
        } else if (scenarioFormValue?.parentScenario === "" || scenarioFormValue?.parentScenario === undefined) {
            toast.error("Please select parent scenario.")
            return
        } else {
            const payload = {
                enterpriseUID: formValues?.enterpriseUID,
                modelUID: formValues?.modelUID,
                scenarioObj: { ...scenarioFormValue }
            }
            const result = await dispatch(createScenario({ payload, isEdit, updatedId }))
            if (result?.meta?.requestStatus === "fulfilled") {
                dispatch((getAllScenarioList({ formValues })));
                dispatch((getAllParentScenarioList({ formValues })));
                setScenarioFormValue({});
                setIsCreateUpdateModalOpen(false);
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
                setIsCreateUpdateModalOpen(true);
            }
        }
    }

    return (
        <>
            <Dialog open={isCreateUpdateModalOpen} onClose={handleCloseModal} fullWidth maxWidth={"md"}>
                <DialogTitle sx={{ fontSize: "16px" }}>
                    Scenario
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{ paddingBottom: "25px", marginTop: "10px" }}>
                        <TextField
                            id="Scenario Name"
                            name="scenarioName"
                            variant="outlined"
                            fullWidth
                            label="Scenario Name"
                            value={scenarioFormValue?.scenarioName}
                            onChange={(e) => {
                                setScenarioFormValue({
                                    ...scenarioFormValue,
                                    [e.target.name]: e.target.value
                                })
                            }}
                            placeholder="Enter scenario name"
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Parent Scenario Name"
                            name="parentScenario"
                            options={parentScenarioData?.length > 0 ? parentScenarioData : []}
                            getOptionLabel={(option) => option}
                            value={
                                scenarioFormValue?.parentScenario
                                    ? parentScenarioData?.find(
                                        (option) =>
                                            option === scenarioFormValue?.parentScenario
                                    ) || null
                                    : null
                            }
                            onChange={(e, newValue) => {
                                setScenarioFormValue({
                                    ...scenarioFormValue,
                                    ["parentScenario"]: newValue
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Parent Scenario Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <TextField
                            id="Scenario Notes"
                            name="scenarioNotes"
                            variant="outlined"
                            fullWidth
                            label="Scenario Notes"
                            value={scenarioFormValue?.scenarioNotes}
                            onChange={(e) => {
                                setScenarioFormValue({
                                    ...scenarioFormValue,
                                    [e.target.name]: e.target.value
                                })
                            }}
                            placeholder="Enter scenario notes"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleCreateScenario} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateScenario
