import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllScenarioList, promoteDeleteScenarioList, promoteScenarioList } from '../../../store/ApiSlice/scenarioListSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const PromoteDeleteScenario = ({
    promoteDeleteScenario,
    setPromoteDeleteScenario,
    attriId,
    setAttriId,
    formValues,
    promoteScenario,
    setPromoteScenario
}) => {
    const { parentScenarioData, scenarioData } = useSelector((state) => state?.scenario);
    const selectScenario = scenarioData?.find((item) => item?._id === attriId)
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        setPromoteDeleteScenario(false);
        setPromoteScenario(false)
    };

    const handleDelete = async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        const payload = {
            enterpriseUID: formValues?.enterpriseUID,
            modelUID: formValues?.modelUID,
            scenarioObj: {
                scenarioName: selectScenario?.scenarioName,
                parentScenario: selectScenario?.parentScenario,
            }
        }
        let response
        if (promoteScenario) {
            response = await dispatch(promoteScenarioList({ config, payload }))
        } else {
            response = await dispatch(promoteDeleteScenarioList({ config, payload }))
        }
        if (response?.meta?.requestStatus === "fulfilled") {
            setPromoteDeleteScenario(false);
            setPromoteScenario(false)
            dispatch((getAllScenarioList({ formValues })));
        } else if (response?.meta?.requestStatus === "rejected") {
            toast.warn(
                response?.payload?.data?.error
            );
        }
        handleCloseModal()
    }

    const renderModalContent = () => {
        return (
            <>
                <DialogTitle>{promoteScenario ? "Promote Scanerio" : "Promote and Delete"}</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography>
                        This Is Irreversible. Are You Sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button color="primary" onClick={handleDelete} sx={{ textTransform: "capitalize" }}>
                        {promoteScenario ? "Promote Scanerio" : "Promote and Delete"}
                    </Button>
                </DialogActions>
            </>
        );
    };

    return (
        <>
            <Dialog open={promoteDeleteScenario || promoteScenario} onClose={handleCloseModal}>
                {renderModalContent()}
            </Dialog>
        </>
    )
}

export default PromoteDeleteScenario
