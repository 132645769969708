import { Autocomplete, Box, Button, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, styled, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { CommonAutoComplete } from '../commonComponent/commonSelect/CommonSelect'
import { CommonNumberInput } from '../commonComponent/input/CommonNumberInput'
import { MdMoreVert } from 'react-icons/md'

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    }
}));

const PrescriptiveTab = ({
    formValues,
    selectedProfile,
    handleChangeProfile,
    isView,
    profileMeasure,
    handleAddOptimizationMeasure,
    optimizationMeasure,
    handleChangeOptimizationMeasure,
    anchorOptimizationMeasureEl,
    setAnchorOptimizationMeasureEl,
    deleteOptimization,
    handleClick,
    handleremove,
    deleteConstraintsMeasure,
    setAnchorConstraintsMeasureEl,
    prescriptiveconstraintsMeasure,
    anchorConstraintsMeasureEl,
    handleAddConstraintsMeasure,
    setPrescriptiveConstraintsMeasure,
    prescriptiveOptimal,
    anchorPrescriptiveEnsembleEl,
    setAnchorPrescriptiveEnsembleEl,
    handleChangePrescriptive,
    deletePrescriptiveEnsemble,
    handleAddPrescriptive
}) => {
    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Basic Settings
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={2.4}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Optimal Forecast Measure"
                                name="optimalForecastMeasureUID"
                                value={formValues?.optimalForecastMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Optimal Forecast Discrepancy Rank Measure"
                                name="optimalForecastOverallDiscrepancyRankMeasureUID"
                                value={formValues?.optimalForecastOverallDiscrepancyRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Optimal Forecast Quality Rank Measure"
                                name="optimalForecastOverallQualityRankMeasureUID"
                                value={formValues?.optimalForecastOverallQualityRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                        <CommonNumberInput
                            name="maxRuntime"
                            label="Max Runtime (minutes)"
                            value={formValues?.maxRuntime}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter max run time"
                            disabled={isView}
                        />
                    </Grid>
                    <Grid item xs={2.4} sx={{

                        '& .MuiFormControlLabel-root': {
                            marginTop: '11px',
                            gap: '10px',
                            paddingLeft: "20px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="explainResults"
                                    name="explainResults"
                                    checked={formValues?.explainResults || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "explainResults", e);
                                    }}
                                />
                            }
                            label="Explain Results"
                        />
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Objectives
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddOptimizationMeasure}>
                    +
                </Button>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>

                    {optimizationMeasure?.length > 0 && optimizationMeasure?.map((item, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="objective" shrink>Optimization Objectives</InputLabel>
                                        <Select
                                            labelId="objective"
                                            name="optimizationObjective"
                                            id="demo-simple-select-readonly"
                                            value={item?.optimizationObjective || ""}
                                            fullWidth
                                            onChange={(e, newValue) => {
                                                handleChangeOptimizationMeasure(index,
                                                    newValue,
                                                    "optimizationObjective",
                                                    e);
                                            }}
                                            label="Error"
                                            variant="outlined"
                                        >
                                            <MenuItem value="Volume">Volume</MenuItem>
                                            <MenuItem value="Revenue">Revenue</MenuItem>
                                            <MenuItem value="Margin">Margin</MenuItem>
                                            <MenuItem value="Cash Flow">Cash Flow</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sx={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Objective Measure"
                                            name="objectiveMeasure"
                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                            getOptionLabel={(option) => option?.measureName}
                                            value={
                                                item?.objectiveMeasure
                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                        (row) =>
                                                            row?._id ==
                                                            item?.objectiveMeasure
                                                    ) || null : [])
                                                    : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleChangeOptimizationMeasure(index,
                                                    newValue,
                                                    "objectiveMeasure",
                                                    e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Objective Measure"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sx={{ width: "100%", flex: 1, display: "flex", alignItems: "center" }}>
                                    <TextField
                                        type="number"
                                        id="weight"
                                        name="weight"
                                        variant="outlined"
                                        fullWidth
                                        label="Objective Weight"
                                        value={item?.weight || ""}
                                        onChange={(e, newValue) => {
                                            handleChangeOptimizationMeasure(index,
                                                newValue,
                                                "weight",
                                                e);
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Enter weight"
                                        // onBlur={validateOne}
                                        disabled={isView}
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === "Backspace" ||
                                                (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                                                (e.key !== "-" &&
                                                    e.key !== "e" &&
                                                    e.keyCode >= 47 &&
                                                    e.keyCode <= 58)
                                            ) {
                                            } else {
                                                e.preventDefault();
                                            }
                                        }}
                                        onFocus={(e) =>
                                            e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                    e.preventDefault();
                                                },
                                                { passive: false }
                                            )
                                        }
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deleteOptimization ? "long-menu" : undefined}
                                        aria-expanded={deleteOptimization ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "optimizationObjective")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorOptimizationMeasureEl}
                                        open={deleteOptimization}
                                        onClose={() => { setAnchorOptimizationMeasureEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("optimizationObjective") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })
                    }
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Decisions
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddPrescriptive}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {prescriptiveOptimal?.length > 0 && prescriptiveOptimal?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={4} sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Desisions"
                                        name="prescriptiveOptimalForecastDecisions"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            item?.prescriptiveOptimalForecastDecisions
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        item?.prescriptiveOptimalForecastDecisions
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangePrescriptive(index, newValue, "prescriptiveOptimalForecastDecisions", e)
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Desisions"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deletePrescriptiveEnsemble ? "long-menu" : undefined}
                                        aria-expanded={deletePrescriptiveEnsemble ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "prescriptiveOptimal")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorPrescriptiveEnsembleEl}
                                        open={deletePrescriptiveEnsemble}
                                        onClose={() => { setAnchorPrescriptiveEnsembleEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("prescriptiveOptimal") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Constraints
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddConstraintsMeasure}>
                    +
                </Button>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {prescriptiveconstraintsMeasure?.map((item, index) => {
                        return (
                            <Grid item xs={4} sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Constraint Measure"
                                    name="constraintsMeasure"
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option?.measureName}
                                    value={
                                        item?.constraintsMeasure
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) =>
                                                    row?._id ==
                                                    item?.constraintsMeasure
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        const updatedFormValues = [...prescriptiveconstraintsMeasure];
                                        updatedFormValues[index]["constraintsMeasure"] = newValue?._id;
                                        setPrescriptiveConstraintsMeasure(updatedFormValues);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Constraint Measure"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={deleteConstraintsMeasure ? "long-menu" : undefined}
                                    aria-expanded={deleteConstraintsMeasure ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, index, "prescriptiveConstraints")}
                                    size="small"
                                >
                                    <MdMoreVert />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorConstraintsMeasureEl}
                                    open={deleteConstraintsMeasure}
                                    onClose={() => { setAnchorConstraintsMeasureEl(null) }}
                                >
                                    <MenuItem onClick={() => { handleremove("prescriptiveConstraints") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                </Menu>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>

            {/* <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Basic KPI Measures
            </Typography>{" "}

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {formValues?.isCheckedbasicKPIError && (
                        <>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="error"
                                        name="error"
                                        variant="outlined"
                                        fullWidth
                                        label="Error"
                                        value={"Error"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Error"
                                        name="optimalForecastErrorMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastErrorMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastErrorMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastErrorMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Error"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Moving Average Error"
                                        name="optimalForecastMovingAverageErrorMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastMovingAverageErrorMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastMovingAverageErrorMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastMovingAverageErrorMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Moving Average Error"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Relative Error"
                                        name="optimalForecastRelativeErrorMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastRelativeErrorMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastRelativeErrorMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastRelativeErrorMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Relative Error"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Error Rank"
                                        name="optimalForecastErrorRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastErrorRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastErrorRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastErrorRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Error Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Accuracy Rank"
                                        name="optimalForecastAccuracyRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastAccuracyRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastAccuracyRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastAccuracyRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Accuracy Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Error FVA"
                                        name="optimalForecastErrorFVAMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastErrorFVAMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastErrorFVAMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastErrorFVAMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Error FVA"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {formValues?.isCheckedbasicKPIBias && (
                        <>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Bias"
                                        name="Bias"
                                        variant="outlined"
                                        fullWidth
                                        label="Bias"
                                        value={"Bias"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Bias"
                                        name="optimalForecastBiasMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastBiasMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastBiasMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastBiasMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Bias"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Moving Average Bias"
                                        name="optimalForecastMovingAverageBiasMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastMovingAverageBiasMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastMovingAverageBiasMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastMovingAverageBiasMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Moving Average Bias"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Relative Bias"
                                        name="optimalForecastRelativeBiasMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastRelativeBiasMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastRelativeBiasMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastRelativeBiasMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Relative Bias"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Bias Rank"
                                        name="optimalForecastBiasRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastBiasRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastBiasRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastBiasRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Bias Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Neutrality Rank"
                                        name="optimalForecastNeutralityRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastNeutralityRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastNeutralityRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastNeutralityRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Neutrality Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Bias FVA"
                                        name="optimalForecastBiasFVAMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastBiasFVAMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastBiasFVAMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastBiasFVAMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Bias FVA"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {formValues?.isCheckedbasicKPIChurn && (
                        <>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Churn"
                                        name="Churn"
                                        variant="outlined"
                                        fullWidth
                                        label="Churn"
                                        value={"Churn"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Churn"
                                        name="optimalForecastChurnMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastChurnMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastChurnMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastChurnMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Churn"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Moving Average Churn"
                                        name="optimalForecastMovingAverageChurnMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastMovingAverageChurnMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastMovingAverageChurnMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastMovingAverageChurnMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Moving Average Churn"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Relative Churn"
                                        name="optimalForecastRelativeChurnMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastRelativeChurnMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastRelativeChurnMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastRelativeChurnMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Relative Churn"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Churn Rank"
                                        name="optimalForecastChurnRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastChurnRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastChurnRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastChurnRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Churn Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast  Stability Rank"
                                        name="optimalForecastStabilityRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastStabilityRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastStabilityRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastStabilityRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast  Stability Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Optimal Forecast Churn FVA"
                                        name="optimalForecastChurnFVAMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.optimalForecastChurnFVAMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.optimalForecastChurnFVAMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "optimalForecastChurnFVAMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Optimal Forecast Churn FVA"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Container> */}
        </>
    )
}

export default PrescriptiveTab
