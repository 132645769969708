import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '../page/pageLayout.css';
import { useDispatch } from 'react-redux';
import { getSchemaData } from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import { PivotViewComponent, Inject, FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting, GroupingBar, VirtualScroll, DrillThrough, Grouping } from '@syncfusion/ej2-react-pivotview';
let toolbarOptions = ['Grid', 'Chart','FieldList'];

const MyGridComponent = ({
    loader,
    setLoader,
    finalData,
    aggregatedViewData,
    gridRef,
    processPageData,
    canvasFindData,
    timeDimensionData,
    schemaData,
    pivotObj = false,
    selectedWidgetId,
    keyIndex,
    pageFilter
}) => {
    
    const { dimensionFilteredData, filterDetail, layout } = useSelector((state) => state.canvas)
    const { measureData, measureList } = useSelector((state) => state.measure)
    const { scenarioList } = useSelector((state) => state.process);
    const pendingLayout = JSON.parse(localStorage.getItem("pending"));
    const findGroupName = pendingLayout?.[finalData?.widgetUID?.widgetName] || [];
    const droppingLayout = JSON.parse(localStorage.getItem("dropped"));
    const findDroppedGroupName = droppingLayout?.[finalData?.widgetUID?.widgetName] || [];
    const viewData = finalData?.widgetUID?.widgetSubType === "Measure Data" ? aggregatedViewData : dimensionFilteredData?.data
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState();
    const [newRowData, setNewRowData] = useState([])
    const attributeNames = finalData?.widgetUID?.worksheet?.attributes?.length > 0
        ? finalData.widgetUID.worksheet.attributes.map(item => item.attributeName)
        : [];

    const periodValues =
        finalData?.widgetUID?.worksheet?.timePeriods?.periodValues?.length > 0
            ? finalData.widgetUID.worksheet.timePeriods.periodValues
            : [];

    const mergeMeasureData = [...attributeNames, ...periodValues];

    const formatTimePeriods = (timePeriods) => {
        return finalData?.widgetUID?.widgetSubType === "Measure Data" && `Time Periods (${timePeriods})`;
    };
    const timePeriodsFormatted = formatTimePeriods(finalData?.widgetUID?.worksheet?.timePeriods?.periodType);
    const formValues = {
        enterpriseUID: canvasFindData?.enterpriseUID?._id,
        modelUID: canvasFindData?.modelUID?._id
    }

    useEffect(() => {
        const dataWidget = findGroupName?.length > 0 && findGroupName.filter(item => item.field === timePeriodsFormatted);
        const dataMeasureWidget = findDroppedGroupName?.length > 0 && findDroppedGroupName.filter(item => item.field === "Measure Name");

        const dataWithIds = (viewData?.length > 0 ? viewData : []).map(item => ({ ...item }));

        const reorderData = [
            ...(finalData?.widgetUID?.cycleID?.length > 0 ? ["Cycle ID"] : []),
            ...attributeNames,
            ...(finalData?.widgetUID?.widgetSubType === 'Measure Data' ? ["Measure Name"] : []),
            "Scenario",
            ...periodValues
        ];

        function reorderObjectKeys(obj, order) {
            const sorted = {};
            order.forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    sorted[key] = obj[key];
                }
            });
            Object.keys(obj).forEach(key => {
                if (!sorted.hasOwnProperty(key)) {
                    sorted[key] = obj[key];
                }
            });
            return sorted;
        }

        const data = dataWithIds.map(item => reorderObjectKeys(item, reorderData));

        const columnData = dataWidget?.length > 0 && data?.reduce((acc, item) => {

            if (item["Measure Name"] && dataMeasureWidget?.length > 0) {
                const scenario = item["Measure Name"];
                const dynamicAttributes = attributeNames?.reduce((accum, attr) => {
                    accum[attr] = item[attr];
                    return accum;
                }, {});

                for (const key in item) {
                    if (key !== "Scenario" && key !== "Measure Name" && !attributeNames?.includes(key)) {
                        const newObj = {
                            ...dynamicAttributes, // Spread the dynamic attributes here
                            "Scenario": "Baseline",
                            "Time Period": key,
                            [scenario]: item[key]
                        };

                        // Check if the key already exists in the accumulator
                        const existingIndex = acc.findIndex(obj => obj["Time Period"] === key);
                        if (existingIndex > -1) {
                            acc[existingIndex][scenario] = item[key]; // Update existing entry
                        } else {
                            acc.push(newObj); // Add new entry
                        }
                    }
                }
            }
            // if (dataWidget?.length > 0) {
            //     const itemKeys = Object.keys(item);

            //     // Ensure that timePeriods is an array before proceeding
            //     const timePeriods = finalData?.widgetUID?.worksheet?.timePeriods?.periodValues || [];

            //     // Ensure that filteredKeys only contains keys that are not in timePeriods
            //     const filteredKeys = itemKeys.filter(key => !timePeriods.includes(key));

            //     if (Array.isArray(timePeriods) && timePeriods.length > 0) {
            //         // Iterate over each time period
            //         timePeriods.forEach(row => {
            //             // Build newObj using filtered keys and their corresponding values
            //             const newObj = {
            //                 ...filteredKeys.reduce((acc, key) => {
            //                     if (item[key] !== undefined) { // Ensure item[key] exists
            //                         acc[key] = item[key];
            //                     }
            //                     return acc;
            //                 }, {}),
            //                 "Time Period": row
            //             };

            //             // Push the newObj into acc (accumulator)
            //             acc.push(newObj);
            //         });
            //     }
            // }

            return acc;
        }, []);

        let tableData = dataWidget?.length > 0 ? columnData?.map((item, index) => ({ id: index, ...item })) : data?.map((item, index) => ({ id: index, ...item }));

        if (JSON.stringify(tableData) !== JSON.stringify(rowData)) {
            setRowData(tableData);
        }

    }, [viewData, aggregatedViewData, dimensionFilteredData?.data]);

    const uniqueFields =
        rowData?.length > 0 &&
        rowData?.reduce((fields, obj) => {
            Object.keys(obj)?.forEach((key) => {
                if (!fields?.includes(key)) {
                    fields?.push(key);
                }
            });
            return fields;
        }, []);


    const measureNameColumn = {
        headerName: 'Measure Name',
        field: 'Measure Name',
    };

    const combinedArray = [
        ...(finalData?.widgetUID?.widgetSubType === 'Measure Data'
            ? [measureNameColumn]
            : []),
        {
            headerName: 'Scenario',
            field: 'Scenario',
        },
        ...(Array.isArray(mergeMeasureData) && mergeMeasureData.length > 0
            ? mergeMeasureData.map((item) => ({
                headerName: item,
                field: item,
            }))
            : []),
    ];

    const otherColumns = uniqueFields?.length > 0 &&
        uniqueFields?.filter((key) => key !== 'Aggregation Mode')
            ?.filter((key) => key !== '_id')?.map((key) => ({
                headerName: key,
                field: key,
            }));


    const columnDefs = [];

    if (otherColumns?.length > 0) {

        columnDefs.push(otherColumns[0]);

        columnDefs.push(...otherColumns.slice(1));
    }

    const mergeArrays = (findGroupName, findDroppedGroupName) => {
        const droppedFields = findGroupName?.length > 0 ? findGroupName.map(col => col.field) : [];
        const droppedPeriodFields = findGroupName?.length > 0 ? findGroupName.filter(col => col.field === timePeriodsFormatted).map(col => col.field) : [];
        let newColumnDefs = (columnDefs?.length > 0 ? columnDefs : combinedArray)?.filter(col => !droppedFields.includes(col.field));

        const droppedMeasureFields = findDroppedGroupName?.length > 0 ? findDroppedGroupName.map(col => col.field) : [];
        const droppedMeasurePeriodFields = findDroppedGroupName?.length > 0 ? findDroppedGroupName.filter(col => col.field == measureNameColumn?.headerName).map(col => col.field) : [];
        let newMeasureColumnDefs = (columnDefs?.length > 0 ? columnDefs : combinedArray)?.filter(col => !droppedMeasureFields?.includes(col.field));

        if (finalData?.widgetUID?.widgetSubType === "Measure Data" && (droppedPeriodFields?.length > 0 || droppedMeasurePeriodFields?.length > 0)) {
            let data = []
            let newMergePeriodData = []
            let newMergeMeasureData = []
            if (findGroupName?.length > 0 && droppedPeriodFields?.length > 0) {
                let newData = newColumnDefs?.filter((item) => item?.headerName !== "Time Period")
                data = findGroupName.map((item) => ({ ...item }));
                let timePeriodIndex = data?.findIndex((item) => item.field == timePeriodsFormatted)
                if (timePeriodIndex !== -1) {
                    data[timePeriodIndex] = {
                        ...data[timePeriodIndex],
                        headerName: finalData?.widgetUID?.worksheet?.timePeriods?.periodType,
                        field: "Time Period",
                        minWidth: 60
                    };
                }
                newColumnDefs = newData
                newMergePeriodData = [...data, ...newColumnDefs]
            }
            if (findDroppedGroupName?.length > 0 && droppedMeasurePeriodFields?.length > 0) {
                let newData = (droppedPeriodFields?.length > 0 ? newMergePeriodData : newMeasureColumnDefs)?.filter((item) => item?.headerName !== "Measure Name")
                data = findDroppedGroupName?.filter((item) => item?.field !== "Measure Name")?.filter((item) => item?.field !== timePeriodsFormatted)?.map((item) => ({ ...item }));
                const measures = finalData?.widgetUID?.worksheet?.measures;

                if (measures && measures.length > 0) {
                    measures.forEach((measure, index) => {
                        data.push({
                            headerName: measure.measureName,
                            field: measure.measureName,
                            minWidth: 60
                        });
                    });
                }
                newMeasureColumnDefs = newData
                newMergeMeasureData = [...newMeasureColumnDefs, ...data]
            }
            return [...newMergeMeasureData, ...data];
        } else {
            return [...findGroupName, ...newColumnDefs]
        }
    }

    const mergedArray = mergeArrays(findGroupName, findDroppedGroupName);

    const mergedData = [...mergedArray];

    const uniqueArray =
        mergedData?.length > 0 &&
        mergedData?.filter(
            (item, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.field === item.field && t.headerName === item.headerName
                )
        );

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', color: "black" }), []);

    const isEditableBasedOnPeriod = (findTime, periodType) => {
        const currentDate = new Date();
        const periodStart = new Date(findTime?.["Period Start"]);

        switch (periodType) {
            case "Month":
                return (
                    periodStart.getFullYear() < currentDate.getFullYear() ||
                    (periodStart.getFullYear() === currentDate.getFullYear() &&
                        periodStart.getMonth() < currentDate.getMonth())
                );
            case "Day":
                return periodStart < currentDate;
            case "Year":
                return periodStart.getFullYear() < currentDate.getFullYear();
            case "Week":
            case "SYSWeek":
                const startOfWeek = new Date(currentDate);
                const dayOfWeek = currentDate.getDay();
                const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
                startOfWeek.setDate(currentDate.getDate() - diffToMonday);

                return periodStart < startOfWeek;
            case "Quarter":
                const currentQuarterStartMonth = Math.floor(currentDate.getMonth() / 3) * 3;
                const startOfCurrentQuarter = new Date(currentDate.getFullYear(), currentQuarterStartMonth, 1);
                return periodStart < startOfCurrentQuarter;
            default:
                return false;
        }
    };


    const columnDefsss = (uniqueArray?.length > 0 ? uniqueArray : combinedArray)
        .filter((col) => col.field !== "id")
        .map((col) => ({
            ...col,
            editable: (params) => {
                const findMeasure = measureList?.find((item) => item?.measureName === params.data?.["Measure Name"]);
                const basePrimaryKey = findMeasure?.baselevelUID?.primaryKey?.map((item) => item?.name)
                const dataFields = ["Time Priod Name", "Scenario Name"];
                for (let key of finalData?.widgetUID?.worksheet?.attributes) {
                    dataFields.push(key.attributeName)
                }
                const periodType = finalData?.widgetUID?.worksheet?.timePeriods?.periodType;
                const baseLevelTimePriod = findMeasure?.baselevelUID?.["Period Type"]

                const findTime = schemaData?.find((item) => item["Time Period Name"] === params?.colDef.field);
                const isSameLevel = dataFields?.every(key => basePrimaryKey.includes(key)) && periodType === baseLevelTimePriod

                if (findMeasure?.measureName === params.data?.["Measure Name"] && !["SUM", "AVERAGE"].includes(findMeasure?.aggregationMode) && !isSameLevel) {
                    toast.error(`${params.data?.["Measure Name"]} is not editable because aggregation mode is not Sum or Average.`);
                    return false;
                }

                // if (params.data?.["Measure Name"] === "Sales History") {
                //     toast.error("Sales History is not editable.");
                //     return false;
                // }

                if (findMeasure?.measureName === params.data?.["Measure Name"]) {
                    if (findMeasure?.editable === "All Editable") {
                        return true;
                    }

                    if (findMeasure?.aggregationMode === "COUNT") {
                        if (!isSameLevel && findMeasure?.editable === "All Editable") {
                            return true;
                        } else if (findMeasure?.editable === "Editable in the Past" && !isSameLevel) {
                            if (isEditableBasedOnPeriod(findTime, periodType)) {
                                return true;
                            } else {
                                toast.error(`${params.data?.["Measure Name"]} is not editable for current or future time periods.`);
                                return false;
                            }
                        } else if (findMeasure?.editable === "Editable in the Current or Future Period" && !isSameLevel) {
                            if (!isEditableBasedOnPeriod(findTime, periodType)) {
                                return true;
                            } else {
                                toast.error(`${params.data?.["Measure Name"]} is not editable for past periods.`);
                                return false;
                            }
                        }
                        return false;
                    }

                    if (findMeasure?.editable === "Not Editable" || findMeasure?.editable === "System Editable") {
                        toast.error(`${params.data?.["Measure Name"]} is not editable.`);
                        return false;
                    }

                    if (findMeasure?.editable === "Editable in the Past") {
                        if (isEditableBasedOnPeriod(findTime, periodType)) {
                            return true;
                        } else {
                            toast.error(`${params.data?.["Measure Name"]} is not editable for current or future time periods.`);
                            return false;
                        }
                    }

                    if (findMeasure?.editable === "Editable in the Current or Future Period") {
                        if (!isEditableBasedOnPeriod(findTime, periodType)) {
                            return true;
                        } else {
                            toast.error(`${params.data?.["Measure Name"]} is not editable for past periods.`);
                            return false;
                        }
                    }
                }

                return true;
            }
        }));

    function transformColumns(data, columnDefsss) {
        const hasLagID = data?.some(item => item.headerName === "Lag ID");
        const hasTimePeriods = data?.some(item => item.headerName === "Time Periods (Month)");

        let filteredColumns = [];
        let newColumns = [];

        if (hasLagID && hasTimePeriods) {

            filteredColumns = columnDefsss?.filter(col =>
                col.field !== "Lag ID" && !periodValues?.includes(col?.field)
            );
            const findFirstRecord = data?.length > 0 && data?.[0]
            if (hasLagID && findFirstRecord?.headerName === "Lag ID") {
                finalData?.widgetUID?.lagID?.forEach((row) => {
                    const timePeriodsFormattedData = periodValues?.map((item) => ({
                        field: item
                    }))

                    newColumns.push({
                        headerName: `Lag ID ${row}`,
                        children: timePeriodsFormattedData
                    });
                })
            } else if (hasTimePeriods && findFirstRecord?.headerName === timePeriodsFormatted) {
                finalData?.widgetUID?.worksheet?.timePeriods?.periodValues?.forEach((row) => {
                    const timePeriodsFormattedData = finalData?.widgetUID.lagID?.map((item) => ({
                        field: row,
                        headerName: `Lag ID ${item}`,
                        "Lag ID": item
                    }))

                    newColumns.push({
                        headerName: row,
                        children: timePeriodsFormattedData
                    });
                })
            }
            return [...filteredColumns, ...newColumns];
        } else {
            return columnDefsss
        }
    }


    const pivotRef = useRef(null);
    const fieldListRef = useRef(null);

    useEffect(() => {
        if (pivotRef.current && fieldListRef.current) {
            fieldListRef.current.engineModule = pivotRef.current.engineModule;
        }
    }, []);

    useEffect(() => {
        if (timeDimensionData && Object.keys(timeDimensionData).length > 0) {
            dispatch(getSchemaData({ timeDimensionData }));
        }
    }, [timeDimensionData])

    const findTime = timeDimensionData?.timeSchemaFields?.find(
        (data) => data['Period Type'] === finalData?.widgetUID?.filterInfo?.timeAttribute[0]?.timePeriodType);

    const pivotColumnData = finalData?.widgetUID?.pivotTable?.layoutSetting?.column?.flatMap((item) => {
        const data = measureList?.find((row) => row?.measureName === item?.fieldName);
        const periodTypedata = (findTime && findTime["Period Type"]) && findTime["Period Type"] === item?.fieldName
        if (periodTypedata) {
            return []
        }

        return [{
            fieldName: data?.measureName === item?.fieldName ? `Measure Name` : item?.fieldName,
            caption: item?.fieldName,
            order: item?.order,
            showSubTotals: false
        }];
    });


    const pivotRowData = finalData?.widgetUID?.pivotTable?.layoutSetting?.row?.flatMap((item) => {
        const data = measureList?.find((row) => row?.measureName === item?.fieldName);
        const periodTypedata = (findTime && findTime["Period Type"]) && findTime["Period Type"] === item?.fieldName
        if (periodTypedata) {
            return [];
        }

        return [{
            fieldName: data?.measureName === item?.fieldName ? `Measure Name` : item?.fieldName,
            caption: item?.fieldName,
            order: item?.order,
            showSubTotals: false
        }];
    });

    const measureRows = pivotRowData?.filter((item) => item?.fieldName === "Measure Name");
    const measureColumn = pivotColumnData?.filter((item) => item?.fieldName === "Measure Name");
    const uniqueMeasures = measureRows?.map((item) => item["Measure Name"]);
    const uniqueMeasuresColumn = measureColumn?.map((item) => item["Measure Name"]);

    const groupedMeasureRow = uniqueMeasures?.length
        ? [{ fieldName: "Measure Name", caption: "Measure Name", measures: uniqueMeasures.join(", ") }]
        : [];
    const groupedMeasureColumn = uniqueMeasuresColumn?.length
        ? [{ fieldName: "Measure Name", caption: "Measure Name", measures: uniqueMeasures.join(", ") }]
        : [];

    const nonMeasureRows = pivotRowData?.filter((item) => item?.fieldName !== "Measure Name");
    const nonMeasureColumn = pivotColumnData?.filter((item) => item?.fieldName !== "Measure Name");
    const scenarioName = [
        { fieldName: "Scenario", caption: "Scenario" }
    ]
    const finalPivotRows = [...groupedMeasureRow, ...nonMeasureRows];
    const finalPivotColumn = [...groupedMeasureColumn, ...nonMeasureColumn, ...scenarioName];
    const [chartSetting,setChartSetting] = useState(null);
    const [displayOption, setDisplayOption] = useState({ view: 'Table' });

    useEffect(() => {
        
        if (finalData.widgetUID.widgetType === "Chart") {
            
            setChartSetting({ title: 'Sales Analysis', chartSeries: { type: "Column" } });
            setDisplayOption({ view: 'Chart' });
        } else if (finalData.widgetUID.widgetType === "Pivot Table") {
            if (finalData?.widgetUID?.classicLayout) {
                if (pivotObj) {
                    pivotObj.gridSettings.layout = 'Tabular';
                }
            } else {
                if (pivotObj) {
                    pivotObj.gridSettings.layout = "Compact";
                }
            }
            setChartSetting({ title: 'Sales Analysis', chartSeries: { type: "Column" } });
            setDisplayOption({ view: 'Table' });
        }

    }, [finalData, selectedWidgetId, pivotObj]);

    
    const timeAttributeValues = useMemo(()=>{
        // console.log("finalData?.widgetUID?.widgetName", finalData?.widgetUID?.widgetName)
        // console.log("finalData?.widgetUID", finalData?.widgetUID)
        // console.log("aggregatedViewData", aggregatedViewData)
        if (finalData?.widgetUID?.filterInfo){
            return finalData?.widgetUID?.filterInfo?.timeAttribute[0]?.values || [];
        } else if (pageFilter){
            return pageFilter?.timeAttribute?.values || []
        }

        return []
    }, [finalData, pageFilter])
    
    return (
        <div 
            // style={containerStyle}
        >
            <div
                style={{ borderCollapse: 'collapse', zIndex: '9999999999999', fontSize: "12px" }}
            >
                {gridRef && 
                <PivotViewComponent
                    id={`PivotTable-${keyIndex}`}
                    height={400}
                    width={'100%'}
                    dataSourceSettings={{
                        dataSource: aggregatedViewData,
                        values: (timeAttributeValues ?? [])?.map((field) => ({
                            name: field,
                            caption: field,
                            allowEditing: true
                        })),
                        rows: finalPivotRows?.map(item => ({
                            name: item.fieldName,
                            caption: item.caption
                        })),
                        columns: finalPivotColumn?.map(item => ({
                            name: item.fieldName,
                            caption: item.caption
                        })),
                        filters: [],
                        showGrandTotals: false,
                        showRowGrandTotals: false,
                        showColumnGrandTotals: false,
                        showValueTotals: false,
                        showSubTotals: false,
                    }}
                    gridSettings={{
                        allowResizing: true,
                        allowAutoResizing: true,
                        expandAll: true,
                        rowHeight: 40,
                        emptyCellsTextContent: "",
                    }}
                    ref={(scope) => { pivotObj = scope; }}
                    queryCellInfo={(args) => {
                        const span = args.cell.querySelector('.e-cellvalue');
                        if (span) {
                            // Read the text content of the span
                            const cellValue = span.textContent.trim(); // trim() to remove any unwanted spaces
                            if (cellValue === "null" || cellValue === null){
                                span.textContent = '-';
                            }
                        }
                    }}
                        showFieldList={true}

                    showToolbar={true}
                    displayOption={{ view: 'Both' }}
                    toolbar={toolbarOptions}
                    // allowCalculatedField={true}

                >
                        <Inject services={[FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting, GroupingBar, Grouping, VirtualScroll, DrillThrough]} />

                    </PivotViewComponent>

                }
            </div>
        </div>
    );
};

export default MyGridComponent;
