import { AppBar, Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MdMoreVert } from 'react-icons/md';
import { getRole } from '../../store/ApiSlice/roleSlice';
import { createUserDetail, getUserList } from '../../store/ApiSlice/userSlice';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';

const CreateUserList = ({
  setIsCreate,
  isEdit,
  selectedRows
}) => {
  const { enterpriseData } = useSelector((state) => state?.enterprise);
  const { roleData } = useSelector((state) => state.role)
  const { userData } = useSelector((state) => state.user)
  const [userListFormValue, setUserListFormValue] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [assignmentData, setAssignmentData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataIndex, setDataIndex] = useState();
  const deleteAddignMentData = Boolean(anchorEl);
  const userDatas = localStorage.getItem("accessToken")
  const userProfile = JSON.parse(userDatas)
  const dispatch = useDispatch()
  const roleEnterPrise = roleData?.filter((item) => item?.enterpriseUID?._id === userListFormValue?.enterpriseName)
  const selectedData = userData?.find((item) => item?._id === selectedRows)
  const BASE_IMAGE_URL = window.location.hostname === 'app.vyan.ai'
    ? 'https://app.vyan.ai'
    : 'http://localhost:4000';

  useEffect(() => {
    if (selectedData && isEdit) {
      const formattedData = {
        phoneNumber: selectedData?.workPhone?.countryCode ? selectedData?.workPhone?.countryCode?.replace('+', '') + selectedData?.workPhone?.phoneNumber?.toString() : "",
        countryCode: selectedData?.workPhone?.countryCode ? selectedData?.workPhone?.countryCode?.replace('+', '') : ""
      };
      const formattedMobileData = {
        phoneNumber: selectedData?.mobilePhone?.countryCode ? selectedData?.mobilePhone?.countryCode?.replace('+', '') + selectedData?.mobilePhone?.phoneNumber?.toString() : "",
        countryCode: selectedData?.mobilePhone?.countryCode ? selectedData?.mobilePhone?.countryCode?.replace('+', '') : ""
      };
      setUserListFormValue({
        enterpriseName: selectedData?.enterprise?._id,
        dateFormat: selectedData?.dateFormat,
        validFrom: moment(selectedData?.validFrom).format("YYYY-MM-DD"),
        validTo: moment(selectedData?.validTo, "DD-MM-YYYY").format("YYYY-MM-DD"),
        email: selectedData?.email,
        firstName: selectedData?.firstName,
        lastName: selectedData?.lastName,
        middleName: selectedData?.middleName,
        locked: selectedData?.locked,
        userId: selectedData?.userID,
        suffix: selectedData?.suffix,
        workPhone: {
          countryCode: formattedData?.countryCode,
          phoneNumber: formattedData?.phoneNumber
        },
        mobilePhone: {
          countryCode: formattedMobileData?.countryCode,
          phoneNumber: formattedMobileData?.phoneNumber
        },
        ...(selectedData?.photo && ({
          photo: selectedData?.photo,
        })),
        streetAddress: selectedData?.streetAddress,
        city: selectedData?.city,
        country: selectedData?.country,
        title: selectedData?.title,
        department: selectedData?.department,
        function: selectedData?.functions,
        note: selectedData?.notes
      })
      setAssignmentData(selectedData?.role?.map((item) => ({
        assignMentData: item?.roleUID?._id,
        roleName: item?.roleName
      })))
    }
  }, [selectedData, isEdit])

  useEffect(() => {
    dispatch(getUserList())
    dispatch(getRole())
  }, [])

  const handleAdd = () => {
    setAssignmentData([...assignmentData, { assignMentData: "", allwed: "" }]);
  };
  const handleChangeObjectAssignMent = (e, index) => {
    const { name, value } = e.target
    const updatedFormValues = [...assignmentData];
    if (name === "allowed") {
      updatedFormValues[index][name] = value;
      setAssignmentData(updatedFormValues);
    } else {
      const findRole = roleData?.find((item) => item?._id === value)
      updatedFormValues[index][name] = value;
      updatedFormValues[index]["roleName"] = findRole?.roleName;
      setAssignmentData(updatedFormValues);
    }

  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, index, type) => {
    setAnchorEl(event.currentTarget);
    setDataIndex(index);
  };

  const handleremove = (type) => {
    const updatedField = [...assignmentData];
    updatedField.splice(dataIndex, 1);
    setAssignmentData(updatedField);
    setAnchorEl(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUploadImage = async () => {
    if (typeof userListFormValue?.photo === "object") {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const file = userListFormValue.photo;
      const formdata = new FormData()
      formdata.append('file', userListFormValue?.photo);
      try {
        const response = await axios.post(`${API_BASE_URL}/upload`, formdata);
        handleSubmit(response?.data?.data?.file_path)
      } catch (error) {
        toast.error('Only .png, .jpeg, and .jpg files are allowed!')
      }
    } else {
      handleSubmit("")
    }

  }
  const handleChange = (newValue, fieldName, e) => {
    if (fieldName === "enterpriseName") {
      setUserListFormValue({
        ...userListFormValue,
        [fieldName]: newValue?._id,
      });
    } else if (fieldName === "locked") {
      const { name, checked } = e.target
      setUserListFormValue({
        ...userListFormValue,
        [name]: checked
      })
    } else if (fieldName === "photo") {
      const imageUrl = e.target.files[0];
      const objectUrl = URL.createObjectURL(imageUrl)
      setUserListFormValue({
        ...userListFormValue,
        [fieldName]: imageUrl,
      })
    }
    else {
      const { name, value } = e.target
      setUserListFormValue({
        ...userListFormValue,
        [fieldName]: value
      })
    }
  }

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (validatePassword(value)) {
      setPasswordError(''); // Clear error if valid
    } else {
      setPasswordError('Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.');
    }
    handleChange(value, 'password', e); // Update form state
  };

  const handleCancel = () => {
    setIsCreate(false);
  };

  const handleSubmit = async (data) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    const countryDialCode = `${userListFormValue?.workPhone?.countryCode}`;
    let phoneWithoutCountryCode = userListFormValue?.workPhone?.phoneNumber;
    if (userListFormValue?.workPhone?.phoneNumber?.startsWith(countryDialCode)) {
      phoneWithoutCountryCode = userListFormValue?.workPhone?.phoneNumber?.slice(countryDialCode?.length).trim();
    }
    const mobileCountryCode = `${userListFormValue?.mobilePhone?.countryCode}`;
    let numberWithOutCountryCode = userListFormValue?.mobilePhone?.phoneNumber;
    if (userListFormValue?.mobilePhone?.phoneNumber?.startsWith(mobileCountryCode)) {
      numberWithOutCountryCode = userListFormValue?.mobilePhone?.phoneNumber?.slice(mobileCountryCode?.length).trim();
    }

    if (userListFormValue?.enterpriseName === "" || !userListFormValue?.enterpriseName) {
      toast.error("Please select enterprise");
    } else if (userListFormValue?.firstName === "" || !userListFormValue?.firstName) {
      toast.error("Please enter first name");
    } else if (userListFormValue?.userId === "" || !userListFormValue?.userId) {
      toast.error("Please enter userId");
    } else if (userListFormValue?.email === "" || !userListFormValue?.email) {
      toast.error("Please enter Email");
    } else if (!emailRegex.test(userListFormValue?.email)) {
      toast.error("Please enter a valid email address");
    } else if (!isEdit && (userListFormValue?.password === "" || !userListFormValue?.password)) {
      toast.error("Please enter password");
    } else if (!isEdit && !passwordRegex.test(userListFormValue?.password)) {
      toast.error("Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.");
    } else if (!assignmentData?.length > 0) {
      toast.error("Please select role")
    } else {
      const payload = {
        enterprise: userListFormValue?.enterpriseName,
        email: userListFormValue?.email,
        firstName: userListFormValue?.firstName,
        middleName: userListFormValue?.middleName,
        lastName: userListFormValue?.lastName,
        suffix: userListFormValue?.suffix,
        userID: userListFormValue?.userId,
        locked: userListFormValue?.locked,
        userName: `${userListFormValue?.firstName || ''}` +
          (userListFormValue?.middleName ? ` ${userListFormValue?.middleName}` : '') +
          (userListFormValue?.lastName ? ` ${userListFormValue?.lastName}` : ''),
        password: userListFormValue?.password,
        ...(userListFormValue?.workPhone?.countryCode &&
          userListFormValue?.workPhone?.countryCode !== "+undefined" &&
          phoneWithoutCountryCode && phoneWithoutCountryCode !== "undefined" && ({
            workPhone: {
              countryCode: `+${userListFormValue?.workPhone?.countryCode}`,
              phoneNumber: phoneWithoutCountryCode
            },
          })),
        ...(userListFormValue?.mobilePhone?.countryCode &&
          userListFormValue?.mobilePhone?.countryCode !== "+undefined" &&
          numberWithOutCountryCode && numberWithOutCountryCode !== "undefined" && ({
            mobilePhone: {
              countryCode: `+${userListFormValue?.mobilePhone?.countryCode}`,
              phoneNumber: numberWithOutCountryCode
            },
          })),
        role: assignmentData?.map((item) => ({
          roleName: item?.roleName,
          roleUID: item?.assignMentData
        })),
        streetAddress: userListFormValue?.streetAddress,
        city: userListFormValue?.city,
        country: userListFormValue?.country,
        ...(data && ({
          photo: data,
        })),
        title: userListFormValue?.title,
        department: userListFormValue?.department,
        functions: userListFormValue?.function,
        dateFormat: userListFormValue?.dateFormat,
        validFrom: moment(userListFormValue?.validFrom).format("DD-MM-YYYY"),
        validTo: moment(userListFormValue?.validTo).format("DD-MM-YYYY"),
        notes: userListFormValue?.note
      }

      const result = await dispatch(
        createUserDetail({ payload, isEdit, selectedRows })
      );
      if (result?.meta?.requestStatus == "fulfilled") {
        // toast.success(
        //   result?.payload?.message
        // );
        dispatch(getUserList());
        setUserListFormValue({});
        setIsCreate(false);
      } else if (result?.meta?.requestStatus === "rejected") {
        toast.error(result?.error?.message);
        setIsCreate(true);
      }
    }
  }

  useEffect(() => {
    return () => {
      if (userListFormValue?.photo) {
        URL.revokeObjectURL(userListFormValue.photo); // Clean up
      }
    };
  }, [userListFormValue?.photo]);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <AppBar position="static" sx={{ backgroundColor: "white" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
              {/* {getPageHeading()} */}
              User
            </Typography>
          </Toolbar>
        </AppBar>
        <Typography variant="h6" component="h6" style={{
          marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
        }}>
          General Data
        </Typography>{" "}
        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="demo-simple-select-readonly"
                  labelId="Enterprise Name"
                  name="enterpriseName"
                  options={enterpriseData}
                  getOptionLabel={(option) => option.enterpriseName}
                  value={
                    userListFormValue?.enterpriseName
                      ? enterpriseData.find(
                        (option) =>
                          option._id === userListFormValue.enterpriseName
                      ) || null
                      : null
                  }
                  onChange={(e, newValue) => {
                    handleChange(newValue, "enterpriseName", e);
                  }}
                  disabled={!userProfile?.data?.multiEnterprise?.length && isEdit && userProfile?.data?.superAdmin === false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enterprise Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Date Format</InputLabel>
                <Select
                  id="type"
                  label="Date Format"
                  name="dateFormat"
                  value={userListFormValue?.dateFormat || ""}
                  onChange={(e, newValue) => {
                    handleChange(newValue, "dateFormat", e);
                  }}
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: "16px" }}
                >
                  <MenuItem value="" disabled selected>
                    Select Date Format Type
                  </MenuItem>
                  <MenuItem value="MM/DD/YY">MM/DD/YY</MenuItem>
                  <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                  <MenuItem value="MM-DD-YY">MM-DD-YY</MenuItem>
                  <MenuItem value="MM-DD-YYYY">MM-DD-YYYY</MenuItem>
                  <MenuItem value="DD/MM/YY">DD/MM/YY</MenuItem>
                  <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                  <MenuItem value="DD-MM-YY">DD-MM-YY</MenuItem>
                  <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="User ID"
                name='userId'
                value={userListFormValue?.userId}
                onChange={(e, newValue) => {
                  handleChange(newValue, "userId", e);
                }}
                placeholder="Enter user Id"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="validFrom"
                name="validFrom"
                label="Valid From"
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={userListFormValue?.validFrom || ''}
                onChange={(e, newValue) => {
                  handleChange(newValue, "validFrom", e);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='firstName'
                label="First Name"
                value={userListFormValue?.firstName}
                onChange={(e, newValue) => {
                  handleChange(newValue, "firstName", e);
                }}
                placeholder="Enter first name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='date'
                id="validTo"
                name="validTo"
                variant="outlined"
                label="Valid To"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: userListFormValue?.validFrom,
                }}
                value={userListFormValue?.validTo || ''}
                onChange={(e, newValue) => {
                  handleChange(newValue, "validTo", e);
                }}

              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='middleName'
                label="Middle Name"
                value={userListFormValue?.middleName}
                onChange={(e, newValue) => {
                  handleChange(newValue, "middleName", e);
                }}
                placeholder="Enter middle name"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="locaked"
                    name="locked"
                    checked={userListFormValue?.locked || false}
                    onChange={(e, newValue) => {
                      handleChange(newValue, "locked", e);
                    }}
                    style={{
                      padding: "0px !important",
                      height: "54px",
                      margin: "0px 19px"
                    }}
                  />
                }
                label="Locked"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='lastName'
                label="Last Name"
                value={userListFormValue?.lastName}
                onChange={(e, newValue) => {
                  handleChange(newValue, "lastName", e);
                }}
                placeholder="Enter last name"
              />
            </Grid>
            {!isEdit && (
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'} // Toggle input type based on state
                  value={userListFormValue?.password}
                  onChange={handlePasswordChange}
                  placeholder="Enter password"
                  error={!!passwordError} // Display error if password does not meet criteria
                  helperText={passwordError} // Show validation message
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Suffix"
                id='suffix'
                name='suffix'
                value={userListFormValue?.suffix || ''}
                onChange={(e, newValue) => {
                  handleChange(newValue, "suffix", e);
                }}
                placeholder="Enter suffix"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="User Name"
                value={
                  `${userListFormValue?.firstName || ''}` +
                  (userListFormValue?.middleName ? ` ${userListFormValue?.middleName}` : '') +
                  (userListFormValue?.lastName ? ` ${userListFormValue?.lastName}` : '')
                }
                placeholder="Enter user name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                id='email'
                name='email'
                label="E-mail"
                value={userListFormValue?.email}
                onChange={(e, newValue) => {
                  handleChange(newValue, "email", e);
                }}
                placeholder="Enter email"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <label htmlFor="phoneInput" style={{ display: "block", marginBottom: "0.5rem" }}>
                Work Phone Number
              </label>
              <PhoneInput
                country={'us'}
                name="phoneInput"
                value={userListFormValue?.workPhone?.phoneNumber}
                onChange={(phone, countryData) => {
                  const countryDialCode = `+${countryData?.dialCode}`;
                  let phoneWithoutCountryCode = phone;
                  if (phone.startsWith(countryDialCode)) {
                    phoneWithoutCountryCode = phone.slice(countryDialCode.length).trim();
                  }

                  setUserListFormValue({
                    ...userListFormValue,
                    workPhone: {
                      phoneNumber: phoneWithoutCountryCode,
                      countryCode: countryData?.dialCode
                    }
                  });
                }}
                inputStyle={{ width: "100%", height: "50px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <label htmlFor="phoneInput" style={{ display: "block", marginBottom: "0.5rem" }}>
                Mobile Phone Number
              </label>
              <PhoneInput
                country={'us'}
                name="phoneInput"
                value={userListFormValue?.mobilePhone?.phoneNumber}
                onChange={(phone, countryData) => {
                  const countryDialCode = `+${countryData?.dialCode}`;
                  let phoneWithoutCountryCode = phone;
                  if (phone.startsWith(countryDialCode)) {
                    phoneWithoutCountryCode = phone.slice(countryDialCode.length).trim();
                  }

                  setUserListFormValue({
                    ...userListFormValue,
                    mobilePhone: {
                      phoneNumber: phoneWithoutCountryCode,
                      countryCode: countryData?.dialCode
                    }
                  });
                }}
                inputStyle={{ width: "100%", height: "50px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='streetAddress'
                label="Street Address"
                value={userListFormValue?.streetAddress}
                onChange={(e, newValue) => {
                  handleChange(newValue, "streetAddress", e);
                }}
                placeholder="Enter street address"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='city'
                label="City"
                value={userListFormValue?.city}
                onChange={(e, newValue) => {
                  handleChange(newValue, "city", e);
                }}
                placeholder="Enter city"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='country'
                label="Country"
                value={userListFormValue?.country}
                onChange={(e, newValue) => {
                  handleChange(newValue, "country", e);
                }}
                placeholder="Enter country"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                name='photo'
                onChange={(e, newValue) => {
                  handleChange(newValue, "photo", e);
                }}
                style={{ height: "57px" }}
              />
            </Grid>
          </Grid>

          <img
            type='file'
            src={typeof userListFormValue?.photo === "object" ?
              URL.createObjectURL(userListFormValue?.photo) :
              `${BASE_IMAGE_URL}/uploads/${userListFormValue?.photo}`}
            style={{ height: "100px", width: "100px" }}
            alt='profile'
          />

          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='title'
                label="Title"
                value={userListFormValue?.title}
                onChange={(e, newValue) => {
                  handleChange(newValue, "title", e);
                }}
                placeholder="Enter title"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                name='department'
                label="Department"
                value={userListFormValue?.department}
                onChange={(e, newValue) => {
                  handleChange(newValue, "department", e);
                }}
                placeholder="Enter department"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Function"
                name='function'
                value={userListFormValue?.function}
                onChange={(e, newValue) => {
                  handleChange(newValue, "function", e);
                }}
                placeholder="Enter function"
              />
            </Grid>
          </Grid>
        </Container>

        <hr
          style={{
            borderTop: "2px solid #1976d2",
            width: "100%",
            margin: "20px auto",
          }}
        />
        <Typography variant="h6" component="h6" style={{
          marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
        }}>
          Role Assignment
        </Typography>{" "}
        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button variant="contained" onClick={handleAdd}>
              +
            </Button>
          </Box>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            {
              assignmentData?.map((item, index) => {
                return (
                  <>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel>Role</InputLabel>
                        <Select
                          id="type"
                          label="Role"
                          name="assignMentData"
                          value={item?.assignMentData}
                          onChange={(e) => {
                            handleChangeObjectAssignMent(e, index);
                          }}
                          fullWidth
                          variant="outlined"
                        >
                          {
                            roleEnterPrise?.length > 0 ? (roleEnterPrise?.map((item, index) => (
                              <MenuItem value={item?._id}>{item?.roleName}</MenuItem>
                            ))) : (
                              <MenuItem value="">No Role Available</MenuItem>
                            )
                          }
                        </Select>
                      </FormControl>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={deleteAddignMentData ? "long-menu" : undefined}
                        aria-expanded={deleteAddignMentData ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, index)}
                        size="small"
                      >
                        <MdMoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={deleteAddignMentData}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleremove}>Delete</MenuItem>
                      </Menu>
                    </Grid>
                  </>
                )
              })
            }
          </Grid>
        </Container>

        <hr
          style={{
            borderTop: "2px solid #1976d2",
            width: "100%",
            margin: "20px auto",
          }}
        />
        <Typography variant="h6" component="h6" style={{
          marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
        }}>
          Administrative Info
        </Typography>{" "}
        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <TextField
                id="note"
                name="note"
                label="Note"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                value={userListFormValue?.note}
                onChange={(e, newValue) => {
                  handleChange(newValue, "note", e);
                }}
                placeholder="Enter note"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                type='date'
                id="createdOn"
                variant="outlined"
                label="Created On"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(userListFormValue?.createdOn).format("DD-MM-YYYY")}
                onChange={(e, newValue) => {
                  handleChange(newValue, "createdOn", e);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='date'
                id="changedOn"
                variant="outlined"
                label="Changed On"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(userListFormValue?.changedOn).format("DD-MM-YYYY")}
                onChange={(e, newValue) => {
                  handleChange(newValue, "changedOn", e);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <TextField
                type='date'
                id="createdBy"
                variant="outlined"
                label="Created By"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={userListFormValue?.createdBy}
                onChange={(e, newValue) => {
                  handleChange(newValue, "createdBy", e);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='date'
                id="changedBy"
                variant="outlined"
                label="Changed By"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={userListFormValue?.changedBy}
                onChange={(e, newValue) => {
                  handleChange(newValue, "changedBy", e);
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "30px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            // disabled={isView}
            onClick={handleCancel}
            title="Cancel"
            style={{
              padding: "6px 15px",
              borderRadius: "2px",
              cursor: "pointer",
              marginRight: "10px",
              fontFamily: "'Poppins', sans-serif"
            }}
          >
            Cancel
          </Button>
          <Button
            // disabled={isView}
            onClick={handleUploadImage}
            title="Create Profile"
            style={{
              padding: "7px 15px",
              borderRadius: "2px",
              cursor: "pointer",
              fontFamily: "'Poppins', sans-serif"
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateUserList
