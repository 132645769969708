import React from "react";
import Button from "@mui/material/Button";

function CustomButton({ handleCancel, handleSubmit }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: "30px",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <Button
        onClick={handleCancel}
        title="Cancel"
        style={{
          padding: "6px 15px",
          borderRadius: "2px",
          cursor: "pointer",
          marginRight: "10px",
          fontFamily: "'Poppins', sans-serif"
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSubmit}
        title="Create Profile"
        style={{
          padding: "7px 15px",
          borderRadius: "2px",
          cursor: "pointer",
          fontFamily: "'Poppins', sans-serif"
        }}
      >
        Save
      </Button>
    </div>
  );
}

export default CustomButton;
