import React,{ useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCycleIdList, getCyclePeriodicityList, getScrenarisoswithEnterpriseAndModel } from "../../store/ApiSlice/widgetSlice";
import { getLagIdList } from "../../store/ApiSlice/dimensionSlice";
import {Box, Button,  Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputBase, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, alpha, styled } from '@mui/material'
import { Select as MuiSelect } from '@mui/material';
import {  FaSearch } from 'react-icons/fa';
import Paper from '@mui/material/Paper';
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { IoIosCloseCircleOutline, IoMdCloseCircle } from "react-icons/io";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: "1px solid black",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


const SystemAttributesValues = (props) => {

    const {
        items,
        selectedFilterDetail,
        systemAttributeList,
        handleOnChangesSystemAttribute,
        rowIndex
    } = props;

    const dispatch = useDispatch();
    const { cycleIDList, cyclePeriodicityList, screnarisosList, pending } = useSelector((state) => state.widget);
    const { dimensionLagIDdata } = useSelector((state) => state.dimension)
    const [openModal, setOpenModal] = useState(false);
    const [systemAttributeValueList, setSystemAttributeValueList] = useState([]);
    const [search, setSearch] = useState("");
    const [attributePage, setAttributePage] = useState(0);
    const [rowsAttributePerPage, setRowsAttributePerPage] = useState(10);
    const [attributeValueData, setAttributeValueData] = useState({ attValues: {} })

    
    useEffect(() => {
        
        if (cycleIDList.length && items.attributes === "cycleIds") {
            if (items?.cyclePeriodicity !== ""){
                const filterData = (cycleIDList ?? []).filter((item) => item["Cycle Periodicity"] === items?.cyclePeriodicity)
                setSystemAttributeValueList(filterData.map((item) => item["Cycle ID"]));
            }else{
                setSystemAttributeValueList((cycleIDList ?? []).map((item) => item["Cycle ID"]));
            }
        } else if (screnarisosList.length > 0 && items.attributes === "scenario") {
            setSystemAttributeValueList(screnarisosList)
        } 
        else if (dimensionLagIDdata.length > 0 && items.attributes === "lagIds") {
            setSystemAttributeValueList(dimensionLagIDdata.map((item) => item["Lag ID"]));
        }
    }, [cycleIDList, cyclePeriodicityList, screnarisosList, dimensionLagIDdata, items]);

    const handleFilterValues = (attValues) =>{
        const updatedData = Object.fromEntries(
            Object.entries(attValues).filter(([key, value]) => value !== false)
        );
        return updatedData
    }
    useEffect(()=>{
        setAttributeValueData({ attValues: { ...handleFilterValues(items.attValues) } })
    }, [items, openModal]);

    const fetchCycleIdList = (filterDetails) => {
        dispatch(getCycleIdList({ canvasFindData: filterDetails }));
    }

    const fetchCyclePeriodicityList = (filterDetails) => {
        dispatch(getCyclePeriodicityList({ canvasFindData: filterDetails }));
    }

    const fetchCycleScrenarisosList = (filterDetails) => {
        dispatch(getScrenarisoswithEnterpriseAndModel({ canvasFindData: filterDetails }));
    }

    const fetchLagIdList = (filterDetails) => {
        dispatch(getLagIdList({ canvasFindData: filterDetails }));
    }

    const getModelUID = (selectedFilterDetail) => {
        if ( selectedFilterDetail?.modelUID?.modalUID?.id){
            return  selectedFilterDetail?.modelUID?.modalUID?.id
        } 
        if (selectedFilterDetail?.modelUID?.modalUID){
            return selectedFilterDetail?.modelUID?.modalUID
        }

        if (selectedFilterDetail?.modelUID) {
            return selectedFilterDetail?.modelUID;
        } 

        if (selectedFilterDetail?.modalUID?.modalUID){
            return selectedFilterDetail?.modalUID?.modalUID;
        } 

        return ""
    }

    const handleGetValues = (attributes) => {
        
  
        const updatedPayload = {
            enterpriseUID:{
                _id: selectedFilterDetail?.enterpriseUID?._id || selectedFilterDetail?.enterpriseUID
            },
            modelUID:{
                modalUID: getModelUID(selectedFilterDetail)
            }
        }
        if (isEmpty(updatedPayload.modelUID?.modalUID)) {
            toast.error("Please select Model Name");
            return;
        }
        setOpenModal(true);

        switch (attributes) {
            case "cycleIds":
                fetchCycleIdList(updatedPayload);
                break;
            case "cyclePeriodicity":
                fetchCyclePeriodicityList(updatedPayload);
                break;
            case "scenario":
                fetchCycleScrenarisosList(updatedPayload);
                break;

            case "lagIds":
                fetchLagIdList(updatedPayload);
                break;
            default:
                console.log("Attributes are not selected...")
        }

    }

    const handleChangeRowsAttributePerPage = (event) => {
        setRowsAttributePerPage(parseInt(event.target.value, 10));
        setAttributePage(0);
    };

    const handleChangeAttributePage = (event, newPage) => {
        setAttributePage(newPage)
    }

    const handleCheckboxChange = (item) => {
        const updatedFormValues = { ...attributeValueData };
        updatedFormValues.attValues[item] = !updatedFormValues.attValues[item];
        setAttributeValueData(updatedFormValues);
    };

    const handleSubmit = (index,data) => {
        const updatedFormValues = [...systemAttributeList];
        updatedFormValues[index]["attValues"] = { ...handleFilterValues(data.attValues) };

        handleOnChangesSystemAttribute(updatedFormValues);
        handleCloseModal();
        setSystemAttributeValueList([]);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setSystemAttributeValueList([]);
        setAttributeValueData({ attValues:{}});
    }
    
    const handleOpenModal = () => {
        if (items.attributes === ""){
            toast.error("Please first select the Attribute.");
            return
        }
        
        handleGetValues(items.attributes);
    }

    const handleRemoveValue = (key, index,items) => {
        const updatedFormValues = [...systemAttributeList];
        const filteredAttValues = Object.entries(attributeValueData.attValues)
            .filter(([k, v]) => k !== key)
            .reduce((acc, [k, v]) => {
                acc[k] = v; 
                return acc;
            }, {});

        if (items.attributes === "scenario" && Object.keys(filteredAttValues).length === 0 ){
            toast.error("At least one values needed for scenario ID's.");
        }else {
            setAttributeValueData({
                attValues: filteredAttValues,  // Only update attValues
            });
            updatedFormValues[index]["attValues"] = { ...filteredAttValues };
            handleOnChangesSystemAttribute(updatedFormValues);
        }
    }

    return <>
        <Box
            sx={{
                cursor: "pointer",
                border: "1px solid gray",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "20px",
                padding: `${!items?.attValues ? "8px" : "15px"}`,
                maxWidth:"500px"
            }}
            fullWidth
            onClick={() => handleOpenModal()}
        >
            {Object.entries(items?.attValues).map(([key, value]) => (
                value === true && (
                     <Chip
                        label={key}
                        variant="outlined"
                        onDelete={(e) => {
                            e.stopPropagation();
                            handleRemoveValue(key, rowIndex, items)
                        }}
                        sx={{ fontSize: '10px' }} 
                    />
                )
            ))}
        </Box>
        {openModal && (
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                sx={{
                    zIndex:"99999"
                }}
            >
                <DialogTitle sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Attribute Values</DialogTitle>
                {items.attributes !== "cyclePeriodicity" &&
                    <DialogContent sx={{ marginTop: "10px !important", width: '500px' }}>
                        <Search sx={{ marginBottom: "31px" }}>
                            <SearchIconWrapper>
                                <FaSearch />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                        </Search>

                        <TableContainer component={Paper}>
                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <Table aria-label="simple table">
                                    {/* <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox 
                                                onChange={(e) => handleHeaderCheckboxChange(e, i)} 
                                            />
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif" }}>
                                            {findAttribute?.name}</TableCell>
                                    </TableRow>
                                </TableHead> */}
                                    {search ? (
                                        systemAttributeValueList?.filter((item) => {
                                            if (typeof item === 'string') {
                                                return item?.toLowerCase().includes(
                                                    typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                );
                                            } else if (typeof item === 'number') {
                                                return item?.toString().includes(search?.toString());
                                            }
                                            return false;
                                        })?.length === 0 ? (
                                            <TableBody>
                                                <TableCell colSpan={2} style={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif" }}>No data found</TableCell>
                                            </TableBody>
                                        ) : (
                                            systemAttributeValueList?.filter((item) => {
                                                if (typeof item === 'string') {
                                                    return item?.toLowerCase().includes(
                                                        typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                    );
                                                } else if (typeof item === 'number') {
                                                    return item.toString()?.includes(search?.toString());
                                                }
                                                return false;
                                            })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => {
                                                return (
                                                    <TableBody key={index}>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={attributeValueData?.attValues[item] || false}
                                                                onChange={(e) => {
                                                                    handleCheckboxChange(item);
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{item}</TableCell>
                                                    </TableBody>
                                                )
                                            })
                                        )
                                    ) : (
                                        systemAttributeValueList?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                            <TableBody key={index}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={attributeValueData?.attValues[item] || false}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(item);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>{item}</TableCell>
                                            </TableBody>
                                        ))
                                    )}
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={search ? systemAttributeValueList?.filter((item) => {
                                    if (typeof item === 'string') {
                                        return item?.toLowerCase().includes(
                                            typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                        );
                                    } else if (typeof item === 'number') {
                                        return item?.toString().includes(search?.toString());
                                    }
                                    return false;
                                })?.length : systemAttributeValueList?.length}
                                rowsPerPage={rowsAttributePerPage}
                                page={attributePage}
                                onPageChange={(event, newPage) => handleChangeAttributePage(event, newPage)}
                                onRowsPerPageChange={handleChangeRowsAttributePerPage}
                            />
                        </TableContainer>

                        <DialogActions >
                            <Button onClick={() => {
                                handleCloseModal()
                                setSearch("")
                            }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", textTransform: "capitalize" }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleSubmit(rowIndex, attributeValueData)
                                    setSearch("")
                                }}
                                sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", textTransform: "capitalize" }}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </DialogContent>}
            </Dialog>
        )}
    </>
}

export default SystemAttributesValues;