import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, alpha, styled, InputBase, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAttributeValue } from '../../store/ApiSlice/canvasSlice';
import { useDispatch } from 'react-redux';
import { FaRegTrashAlt, FaSearch } from 'react-icons/fa';
import { getAttributes } from '../../store/ApiSlice/attributeSlice';
import { FiPlus } from 'react-icons/fi';
const selectStyle = "w-[150px] px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: "1px solid black",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const WidgetFilter = ({
    setSelectedAttributes,
    selectedAttributes,
    selectedValue,
    setSelectedValue,
    canvasFindData,
    openModalRow,
    setOpenModalRow,
    attributeValueData,
    setAttributeValueData
}) => {
    const { attribute } = useSelector((state) => state.attribute)
    const { attributeValue, filterDetail } = useSelector((state) => state?.canvas)
    const [valueModal, setValueModal] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [attributePage, setAttributePage] = useState(0);
    const [rowsAttributePerPage, setRowsAttributePerPage] = useState(10);
    const [search, setSearch] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAttributes())
    }, [])
    const handleAddField = () => {
        setSelectedAttributes([...selectedAttributes, {
            attributes: '',
            operator: 'Equal To',
            attValues: {},
        }]);
    };

    const handleRemoveField = (rowIndex) => {
        const filterList = selectedAttributes.filter((_,index)=> index !== rowIndex);
        setSelectedAttributes(filterList);
    };

    const handleStepChangeValue = (newValue, fieldname, event, index, data) => {
        const { name, value, checked } = event.target;
        if (fieldname == "attributes") {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][fieldname] = newValue;
            setSelectedAttributes(updatedFormValues);
        }
        else {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][name] = value;
            setSelectedAttributes(updatedFormValues);
        }
    };

    const handleClickValue = (rowIndex, data) => {
        setValueModal(true)
        setOpenModalRow(rowIndex)
        if (Object.keys(data?.attValues).length > 0) {
            setAttributeValueData({ attValues: data.attValues });
        }
    }
    const handleCloseValue = () => {
        setValueModal(false)
        setAttributeValueData({ attValues: {} })
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleCheckboxChange = (newValue, item) => {
        const updatedFormValues = { ...attributeValueData };
        updatedFormValues.attValues[item] = !updatedFormValues.attValues[item];
        setAttributeValueData(updatedFormValues);
    };

    const handleHeaderCheckboxChange = (event, index) => {
        const isChecked = event.target.checked;
        const updatedFormValues = { ...attributeValueData };
        attributeValue.forEach((item) => {
            updatedFormValues.attValues[item] = isChecked;
        });
        setAttributeValueData(updatedFormValues)
    };

    const handleSubmit = (index) => {

        const updatedFormValues = [...selectedAttributes];
        updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
        setSelectedAttributes(updatedFormValues);
        handleCloseValue();
    };

    const handleChangeRowsAttributePerPage = (event) => {
        setRowsAttributePerPage(parseInt(event.target.value, 10));
        setAttributePage(0);
    };

    const handleChangeAttributePage = (event, newPage) => {
        setAttributePage(newPage)
    }

    //Function used to selected values 
    const handleRemoveValue = (key, selectedIndex) => {

        const attributeValueData = selectedAttributes[selectedIndex].attValues
        const filteredAttValues = Object.entries(attributeValueData)
            .filter(([k, v]) => k !== key)
            .reduce((acc, [k, v]) => {
                acc[k] = v;
                return acc;
            }, {});
        const updatedData = selectedAttributes.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    attValues: filteredAttValues
                }
            }
            return item
        })
        setSelectedAttributes(updatedData);
    }


    const AttributeEnterpriseData = attribute?.filter((item) => item?.enterpriseUID?._id === canvasFindData?.enterpriseUID?._id)
        ?.filter((item) => item?.modelUID?.modalUID === canvasFindData?.modelUID?.modalUID)
    return (
        <>
            <div className='mr-2'>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px", paddingBottom: "10px" }}>
                    {/* <div>
                    <Button
                        variant='contained'
                        onClick={handleAddField}
                        style={{
                            background: "white",
                            border: "1px solid #e0e0e0",
                            color: "black",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "white",
                                boxShadow: "none"
                            },
                        }}
                    >
                        Save
                    </Button>
                </div> */}
                    {/* <div style={{ gap: "10px", display: "flex" }}>
                        <Button
                            variant='contained'
                            onClick={handleAddField}
                            style={{
                                background: "white",
                                border: "1px solid #e0e0e0",
                                color: "black",
                                boxShadow: "none",
                                "&:hover": {
                                    backgroundColor: "white",
                                    boxShadow: "none"
                                },
                            }}
                        >
                            Add
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => handleRemoveField()}
                            style={{
                                background: "white",
                                border: "1px solid #e0e0e0",
                                color: "black",
                                boxShadow: "none",
                                "&:hover": {
                                    backgroundColor: "white",
                                    boxShadow: "none"
                                },
                            }}
                        >
                            Remove
                        </Button>
                    </div> */}
                </div>
                <div className='border  mt-3 border-[#E9EAEB] rounded-xl p-4 my-4'>
                    <div className='flex justify-between items-center'>
                        <h3>Hierarchy Attributes</h3>
                        <button
                            onClick={()=>handleAddField()}
                            className="font-semibold  text-sm p-2 flex items-center"
                            type="button"
                            // style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
                        >
                            <FiPlus className="w-5 h-5 text-black" />
                        </button>
                        </div>
                        <div>
                            <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'>Attribute</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Operator</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Value</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                            {selectedAttributes?.map((row, i) => {
                                const findAttribute = AttributeEnterpriseData.find(item => item?._id === row.attributes);
                                return <>
                                    <TableBody>
                                        <TableCell className=''>
                                            <select
                                                id='attributes'
                                                name='attributes'
                                                value={row?.attributes || ""}
                                                className={selectStyle}
                                                onChange={(e) => handleStepChangeValue(e.target.value, "attributes", e, i, row)}
                                            >
                                                <option value="">Select Attribute</option>
                                                {AttributeEnterpriseData.map((option) => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </TableCell>
                                        <TableCell className=''>
                                            <select
                                                id='operator'
                                                name='operator'
                                                value={row?.operator || ""}
                                                className={selectStyle}
                                                onChange={(e) => handleStepChangeValue(e.target.value, "operator", e, i, row)}
                                            >
                                                <option value="">Select Operator</option>
                                                <option value="Equal To">Equal To</option>
                                                <option value="Not Equal To">Not Equal To</option>
                                            </select>
                                        </TableCell>
                                       
                                        <TableCell className="w-[200px] font-medium text-left text-xs text-[#414651] px-2.5 py-4 cursor-pointer" onClick={() => {
                                            handleClickValue(i, row);
                                            dispatch(getAttributeValue({ row }));
                                        }}
                                        >
                                            <div className={`border 
                                            border-[#EAEAEA] 
                                            rounded-lg 
                                            text-sm 
                                            shadow-sm
                                            bg-white
                                            appearance-none p-[8px] 
                                            flex
                                            flex-wrap
                                            ga-2
                                            ${Object.entries(row.attValues).some(([_, value]) => value === true) ? '' : 'h-[41px]'}`}>
                                                {row?.attValues &&
                                                    Object.entries(row.attValues)
                                                        .filter(([_, value]) => value === true)
                                                        .map(([key]) => (
                                                            <Chip
                                                                label={key}
                                                                variant="outlined"
                                                                onDelete={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRemoveValue(key, i)
                                                                }}
                                                                sx={{ fontSize: '10px' }} 
                                                            />
                                                        ))}
                                            </div>
                                        </TableCell>
                                        <TableCell className=''>
                                            {selectedAttributes.length >  1 &&
                                            <FaRegTrashAlt 
                                                className='text-red-500 text-[20px] cursor-pointer' 
                                                onClick={() => handleRemoveField(i)}
                                            />}         
                                        </TableCell>
                                        {valueModal && openModalRow === i && (
                                            <div className="z-10 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                <div className="bg-white rounded-lg shadow-lg w-[500px] p-4">
                                                    <h2 className="text-sm font-semibold font-poppins">Attribute Values</h2>
                                                    <div className="mt-2">
                                                        <div className="relative flex items-center border border-gray-300 rounded-lg px-3 py-2 mb-4">
                                                            <FaSearch className="text-gray-400 mr-2" />
                                                            <input
                                                                type="text"
                                                                placeholder="Search..."
                                                                className="w-full outline-none"
                                                                name='search'
                                                                value={search}
                                                                onChange={(e) => handleSearch(e)}
                                                            />
                                                        </div>

                                                        <div className="max-h-[500px] overflow-auto border rounded-lg">
                                                            <table className="w-full border-collapse">
                                                                <thead className="border-b">
                                                                    <tr>
                                                                        <th className="px-3 py-2">
                                                                            <input type="checkbox" onChange={(e) => handleHeaderCheckboxChange(e, i)} />
                                                                        </th>
                                                                        <th className="px-3 py-2 text-left text-xs font-semibold font-poppins">{findAttribute?.name}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {search ? (
                                                                        attributeValue?.filter((item) => {
                                                                            if (typeof item === "string") {
                                                                                return item?.toLowerCase().includes(
                                                                                    typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                                );
                                                                            } else if (typeof item === "number") {
                                                                                return item.toString()?.includes(search?.toString());
                                                                            }
                                                                            return false;
                                                                        })?.length === 0 ? (
                                                                            <tr>
                                                                                <td colSpan={2} className="text-center py-4 font-poppins">No data found</td>
                                                                            </tr>
                                                                        ) : (
                                                                            attributeValue?.filter((item) => {
                                                                                if (typeof item === "string") {
                                                                                    return item?.toLowerCase().includes(
                                                                                        typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                                    );
                                                                                } else if (typeof item === "number") {
                                                                                    return item.toString()?.includes(search?.toString());
                                                                                }
                                                                                return false;
                                                                            })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                                <tr key={index} className="border-t">
                                                                                    <td className="px-3 py-2 text-center">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={attributeValueData?.attValues[item] || false}
                                                                                            onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="px-3 py-2 text-xs">{item}</td>
                                                                                </tr>
                                                                            ))
                                                                        )
                                                                    ) : (
                                                                        attributeValue?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                            <tr key={index} className="border-t">
                                                                                <td className="px-3 py-2 text-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={attributeValueData?.attValues[item] || false}
                                                                                        onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                                                                    />
                                                                                </td>
                                                                                <td className="px-3 py-2 text-xs">{item}</td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="flex justify-between items-center mt-4 text-sm">
                                                            <span className='text-xs'>Total: {search ? attributeValue?.filter((item) => {
                                                                if (typeof item === "string") {
                                                                    return item?.toLowerCase().includes(
                                                                        typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                    );
                                                                } else if (typeof item === "number") {
                                                                    return item?.toString().includes(search?.toString());
                                                                }
                                                                return false;
                                                            })?.length : attributeValue?.length}</span>

                                                            <div className="flex items-center">
                                                                <button className="px-2 text-xs py-1 border rounded-md mr-2" onClick={(e) => handleChangeAttributePage(e, attributePage - 1)} disabled={attributePage === 0}>
                                                                    Prev
                                                                </button>
                                                                <span>{attributePage + 1}</span>
                                                                <button className="px-2 py-1 text-xs border rounded-md ml-2" onClick={(e) => handleChangeAttributePage(e, attributePage + 1)} disabled={(attributePage + 1) * rowsAttributePerPage >= attributeValue?.length}>
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-end space-x-2 mt-4">
                                                        <button
                                                            className="px-4 py-2 border rounded-md text-sm"
                                                            onClick={() => {
                                                                handleCloseValue();
                                                                setSearch("");
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm"
                                                            onClick={() => {
                                                                handleSubmit(i);
                                                                setSearch("");
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </TableBody>
                                </>
                            })}
                            </Table>
                        </div>
                    {/* <table className="w-full rounded-xl p-4 border-collapse">
                        <tbody>
                            {selectedAttributes?.map((row, i) => {
                                const findAttribute = AttributeEnterpriseData.find(item => item?._id === row.attributes);
                                return (
                                    <tr className="border-t" key={i}>
                                        <td className="font-medium text-left text-xs text-[#414651] border border-[#E9EAEB] px-2.5 py-4">
                                            <select
                                                id='attributes'
                                                name='attributes'
                                                value={row?.attributes || ""}
                                                className={`
                                                px-3 
                                                py-2 
                                                border 
                                                border-[#EAEAEA] 
                                                rounded-lg 
                                                text-sm 
                                                shadow-sm
                                                bg-white
                                                appearance-none
                                                focus:outline-none 
                                                focus:shadow-custom-focus
                                                disabled:bg-gray-50 
                                                disabled:text-gray-500 
                                                disabled:cursor-not-allowed
                                                w-full
                                              `}
                                                onChange={(e) => handleStepChangeValue(e.target.value, "attributes", e, i, row)}
                                            >
                                                <option value="">Select Attribute</option>
                                                {AttributeEnterpriseData.map((option) => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td className="font-medium text-left text-xs text-[#414651] border-r border-[#E9EAEB] px-2.5 py-4">
                                            <select
                                                id='operator'
                                                name='operator'
                                                value={row?.operator || ""}
                                                className={`
                                                w-[75%] 
                                                px-3 
                                                py-2 
                                                border 
                                                border-[#EAEAEA] 
                                                rounded-lg 
                                                text-sm 
                                                shadow-sm
                                                bg-white
                                                appearance-none
                                                focus:outline-none 
                                                focus:shadow-custom-focus
                                                disabled:bg-gray-50 
                                                disabled:text-gray-500 
                                                disabled:cursor-not-allowed
                                              `}
                                                onChange={(e) => handleStepChangeValue(e.target.value, "operator", e, i, row)}
                                            >
                                                <option value="">Select Operator</option>
                                                <option value="Equal To">Equal To</option>
                                                <option value="Not Equal To">Not Equal To</option>
                                            </select>
                                        </td>
                                        <td className="font-medium text-left text-xs text-[#414651] px-2.5 py-4 cursor-pointer" onClick={() => {
                                            handleClickValue(i, row);
                                            dispatch(getAttributeValue({ row }));
                                        }}
                                        >
                                            <div className={`border 
                                            border-[#EAEAEA] 
                                            rounded-lg 
                                            text-sm 
                                            shadow-sm
                                            bg-white
                                            appearance-none p-[8px] 
                                            ${Object.entries(row.attValues).some(([_, value]) => value === true) ? '' : 'h-[41px]'}`}>
                                                {row?.attValues &&
                                                    Object.entries(row.attValues)
                                                        .filter(([_, value]) => value === true)
                                                        .map(([key]) => (
                                                            <span
                                                                key={key}
                                                                className="text-xs bg-[bisque] p-[5px] m-[3px] rounded-[5px] inline-block"
                                                            >
                                                                {key}
                                                            </span>
                                                        ))}
                                            </div>
                                        </td>
                                        {valueModal && openModalRow === i && (
                                            <div className="z-10 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                <div className="bg-white rounded-lg shadow-lg w-[500px] p-4">
                                                    <h2 className="text-sm font-semibold font-poppins">Attribute Values</h2>
                                                    <div className="mt-2">
                                                        <div className="relative flex items-center border border-gray-300 rounded-lg px-3 py-2 mb-4">
                                                            <FaSearch className="text-gray-400 mr-2" />
                                                            <input
                                                                type="text"
                                                                placeholder="Search..."
                                                                className="w-full outline-none"
                                                                name='search'
                                                                value={search}
                                                                onChange={(e) => handleSearch(e)}
                                                            />
                                                        </div>

                                                        <div className="max-h-[500px] overflow-auto border rounded-lg">
                                                            <table className="w-full border-collapse">
                                                                <thead className="border-b">
                                                                    <tr>
                                                                        <th className="px-3 py-2">
                                                                            <input type="checkbox" onChange={(e) => handleHeaderCheckboxChange(e, i)} />
                                                                        </th>
                                                                        <th className="px-3 py-2 text-left text-xs font-semibold font-poppins">{findAttribute?.name}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {search ? (
                                                                        attributeValue?.filter((item) => {
                                                                            if (typeof item === "string") {
                                                                                return item?.toLowerCase().includes(
                                                                                    typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                                );
                                                                            } else if (typeof item === "number") {
                                                                                return item.toString()?.includes(search?.toString());
                                                                            }
                                                                            return false;
                                                                        })?.length === 0 ? (
                                                                            <tr>
                                                                                <td colSpan={2} className="text-center py-4 font-poppins">No data found</td>
                                                                            </tr>
                                                                        ) : (
                                                                            attributeValue?.filter((item) => {
                                                                                if (typeof item === "string") {
                                                                                    return item?.toLowerCase().includes(
                                                                                        typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                                    );
                                                                                } else if (typeof item === "number") {
                                                                                    return item.toString()?.includes(search?.toString());
                                                                                }
                                                                                return false;
                                                                            })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                                <tr key={index} className="border-t">
                                                                                    <td className="px-3 py-2 text-center">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={attributeValueData?.attValues[item] || false}
                                                                                            onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="px-3 py-2 text-xs">{item}</td>
                                                                                </tr>
                                                                            ))
                                                                        )
                                                                    ) : (
                                                                        attributeValue?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                            <tr key={index} className="border-t">
                                                                                <td className="px-3 py-2 text-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={attributeValueData?.attValues[item] || false}
                                                                                        onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                                                                    />
                                                                                </td>
                                                                                <td className="px-3 py-2 text-xs">{item}</td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="flex justify-between items-center mt-4 text-sm">
                                                            <span className='text-xs'>Total: {search ? attributeValue?.filter((item) => {
                                                                if (typeof item === "string") {
                                                                    return item?.toLowerCase().includes(
                                                                        typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                    );
                                                                } else if (typeof item === "number") {
                                                                    return item?.toString().includes(search?.toString());
                                                                }
                                                                return false;
                                                            })?.length : attributeValue?.length}</span>

                                                            <div className="flex items-center">
                                                                <button className="px-2 text-xs py-1 border rounded-md mr-2" onClick={(e) => handleChangeAttributePage(e, attributePage - 1)} disabled={attributePage === 0}>
                                                                    Prev
                                                                </button>
                                                                <span>{attributePage + 1}</span>
                                                                <button className="px-2 py-1 text-xs border rounded-md ml-2" onClick={(e) => handleChangeAttributePage(e, attributePage + 1)} disabled={(attributePage + 1) * rowsAttributePerPage >= attributeValue?.length}>
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-end space-x-2 mt-4">
                                                        <button
                                                            className="px-4 py-2 border rounded-md text-sm"
                                                            onClick={() => {
                                                                handleCloseValue();
                                                                setSearch("");
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm"
                                                            onClick={() => {
                                                                handleSubmit(i);
                                                                setSearch("");
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table> */}
                </div>
            </div>
        </>
    )
}

export default WidgetFilter
