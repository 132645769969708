import { Box, Button, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle'
import CreateProfile from './CreateProfile'
import ProfileMaterialList from './ProfileMaterialList'
import DeleteProfile from './DeleteProfile'
import { ThemeContext } from '../../ThemeContext'
import { useSelector } from 'react-redux'
import { getProfileData } from '../../store/ApiSlice/profileSlice'
import { useDispatch } from 'react-redux'

const ProfileDetail = () => {
    const { profileData } = useSelector(
        (state) => state.profile
    );
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProfileData())
    }, [])
    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }
    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };
    return (
        <>
            {!isCreate && !isView && (
                <>
                    <Box sx={{ display: "flex" }}>
                        <MiniDrawer />
                        <Box component="main" sx={{
                            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                            background: isDarkTheme ? "whitesmoke" : "white",
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "60px",
                                paddingBottom: "20px",
                                position: "relative"
                            }}>
                                <h3 className="heading">Forecast Profiles</h3>
                                <div>
                                    <Tooltip title="Add Profile">
                                        <Button
                                            onClick={() => {
                                                handleCreateUpdate(false)
                                            }}
                                            className="awesome-button"
                                            sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                        >
                                            +
                                        </Button>
                                    </Tooltip>
                                    <DeleteProfile
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        setModalDeleteOpen={setModalDeleteOpen}
                                        isModalDeleteOpen={isModalDeleteOpen}
                                        updatedId={updatedId}
                                    />
                                </div>
                            </div>
                            <ProfileMaterialList
                                handleTableRowSelect={handleTableRowSelect}
                                setUpdatedId={setUpdatedId}
                                handleCreateUpdate={handleCreateUpdate}
                                setModalDeleteOpen={setModalDeleteOpen}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {(isCreate || isView) && (
                <CreateProfile
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isView={isView}
                    setIsView={setIsView}
                    selectedRows={updatedId}
                />
            )}
        </>
    )
}

export default ProfileDetail
