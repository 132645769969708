import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Autocomplete, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createUpdateModel, getEnterModelList, getModelList } from "../../store/ApiSlice/modelSlice";
import { useSelector } from "react-redux";
import { fetchData, getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import { getModelListAsync } from "../../store/ApiSlice/dimensionSlice";

const CreateEntermodel = ({
  data,
  selectedRows,
  isCreateUpdateModalOpen,
  setIsCreateUpdateModalOpen,
  isEdit,
  setDimensionAttributes,
  dimensionAttributes,
  updatedId,
  modalType
}) => {
  const dispatch = useDispatch();
  const { enterpriseData, userData } = useSelector((state) => state?.enterprise)

  let editRow = data?.find((item) => item.id === updatedId);

  let initialVal = {
    enterpriseUID: "",
    enterPriseName: "",
    modelUID: "",
    modelName: "",
    userUID: "",
    modelAdministratorUserName: "",
    notes: "",
    createdOn: "",
    createdBy: "",
    changedOn: "",
    changedBy: "",
  };
  let initialFormValues = {
    enterpriseUID:
      isEdit && editRow?.enterpriseUID?._id ? editRow.enterpriseUID?._id : "",
    enterPriseName:
      isEdit && editRow?.enterpriseUID?.enterpriseName ? editRow.enterpriseUID?.enterpriseName : "",
    modelName: isEdit && editRow?.modelName ? editRow.modelName : "",
    reference: isEdit && editRow?.reference ? editRow.reference : "",
    notes: isEdit && editRow?.notes ? editRow.notes : "",
  };

  const [validations, setValidations] = useState(initialVal);
  const [modelFormValue, setModelFormValue] = useState(initialFormValues);
  const findReferenceDimension = data?.length > 0 && data?.filter((item) => item?.reference === true)
  const findSourceModel = data?.length > 0 && data?.filter((item) => item?.enterpriseUID?._id === modelFormValue?.enterpriseUID)

  const handleCloseModal = () => {
    setIsCreateUpdateModalOpen(false);
  };

  const handleAttributeFieldChange = (newValue, fieldName, e) => {
    if (fieldName === "referenceModelUID" || fieldName === "sourceModelUID") {
      setModelFormValue((prevValues) => ({
        ...prevValues,
        [fieldName]: newValue?.id,
      }));
    } else if (fieldName === "reference") {
      const { checked } = e.target
      setModelFormValue({
        ...modelFormValue,
        [fieldName]: checked
      });
    } else if (fieldName === "enterpriseUID") {
      const selectedData = enterpriseData?.find((data) => data._id === newValue?._id);
      setModelFormValue((prevValues) => ({
        ...prevValues,
        [fieldName]: newValue?._id,
        ["enterPriseName"]: selectedData?.enterpriseName
      }));
    }
    else {
      const { value } = e.target
      setModelFormValue({
        ...modelFormValue,
        [fieldName]: value
      });
    }
  };

  const saveEnterprise = async () => {
    if (validateAll()) {
      return;
    } else {
      const updatedFormValues = {
        enterpriseUID: modelFormValue?.enterpriseUID,
        modelName: modelFormValue?.modelName,
        notes: modelFormValue?.notes,
        reference: modelFormValue?.reference ? modelFormValue?.reference : false,
        ...(modalType === "createModal" && !isEdit && ({
          type: "Create From Scratch",
        })),
        ...(modalType === "copyFromReference" && !isEdit && ({
          type: "Copy From Reference",
          referenceModelUID: modelFormValue?.referenceModelUID
        })),
        ...(modalType === "copyFrom" && !isEdit && ({
          type: "Copy From Source",
          sourceModelUID: modelFormValue?.sourceModelUID
        })),
      };

      const result = await dispatch(createUpdateModel({ updatedFormValues, isEdit, userId, updatedId }));
      if (result?.payload?.status) {
        setDimensionAttributes([...dimensionAttributes, result?.payload?.data]);
        const successMessage = isEdit
          ? "Model updated successfully"
          : "Model created successfully";
        // toast.success(successMessage);
        handleCloseModal()
      } else {
        toast.error("Failed to create model");
      }
      dispatch(getEnterModelList())
    }
  };

  useEffect(() => {
    dispatch(getEnterpriseList())
    dispatch(getModelListAsync())
  }, []);
  const validateAll = () => {
    const { enterpriseUID } = modelFormValue;
    const validations = { enterpriseUID: "" };
    let isValid = false;
    if (!enterpriseUID) {
      validations.enterpriseUID = "Enterprise Name is required";
      isValid = true;
    }
    setValidations(validations);
    return isValid;
  };

  const userId = localStorage.getItem("accessToken");

  useEffect(() => {
    setModelFormValue((prevValues) => ({
      ...prevValues,
      createdBy: userData?.user?.name,
      changedBy: userData?.user?.name,
    }));
  }, [isEdit]);
  return (
    <Dialog open={isCreateUpdateModalOpen} onClose={handleCloseModal}>
      <DialogTitle>
        {
          modalType === "createModal" ? (isEdit ? "Edit Model" : "Create New") :
            modalType === "copyFromReference" ? (isEdit ? "Edit Reference Model" : "Copy Reference") :
              modalType === "copyFrom" ? (isEdit ? "Edit Copy From Model" : "Copy Other") : ""
        }
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "10px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                labelId="enterpriseUID"
                id="demo-simple-select-readonly"
                name='enterpriseUID'
                label="Reference Model"
                options={enterpriseData?.length > 0 ? enterpriseData : []}
                getOptionLabel={(option) => option?.enterpriseName}
                value={modelFormValue?.enterpriseUID ?
                  enterpriseData?.length > 0 && (enterpriseData?.find(option => option?._id === modelFormValue?.enterpriseUID)
                    || null) : null}
                fullWidth
                onChange={(e, newValue) => {
                  handleAttributeFieldChange(newValue, "enterpriseUID", e);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enterprise"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>

          </Grid>

          <Grid item xs={6}>
            <TextField
              id="modelName"
              label="Model Name"
              variant="outlined"
              fullWidth
              value={modelFormValue.modelName}
              onChange={(e, newValue) => {
                handleAttributeFieldChange(newValue, "modelName", e);
              }}
              onBlur={validateAll}
            />
          </Grid>

          {(modalType === "copyFromReference" && !isEdit) ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  labelId="referenceModelUID"
                  id="demo-simple-select-readonly"
                  name='referenceModelUID'
                  label="Reference Model"
                  options={findReferenceDimension?.length > 0 ? findReferenceDimension : []}
                  getOptionLabel={(option) => option.modelName}
                  value={modelFormValue?.referenceModelUID ?
                    findReferenceDimension?.length > 0 && (findReferenceDimension?.find(option => option?.id === modelFormValue?.referenceModelUID)
                      || null) : null}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleAttributeFieldChange(newValue, "referenceModelUID", e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reference Model"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </>
          ) : ("")}

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="reference"
                  name="reference"
                  checked={modelFormValue?.reference || false}
                  onChange={(e, newValue) => {
                    handleAttributeFieldChange(newValue, "reference", e);
                  }}
                  style={{
                    padding: "0px !important",
                    height: "54px",
                    margin: "0px 19px"
                  }}
                />
              }
              label="Reference"
            />
          </Grid>

          {(modalType === "copyFrom" && !isEdit) ? (
            <Grid item xs={12}>
              <Autocomplete
                labelId="sourceModelUID"
                id="demo-simple-select-readonly"
                name='sourceModelUID'
                label="Source Model"
                options={findSourceModel?.length > 0 ? findSourceModel : []}
                getOptionLabel={(option) => option.modelName}
                value={modelFormValue?.sourceModelUID ?
                  findSourceModel?.length > 0 && (findSourceModel?.find(option => option?.id === modelFormValue?.sourceModelUID)
                    || null) : null}
                fullWidth
                onChange={(e, newValue) => {
                  handleAttributeFieldChange(newValue, "sourceModelUID", e);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source Model"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          ) : ("")}

          <Grid item xs={12}>
            <TextField
              id="notes"
              label="Notes"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              value={modelFormValue.notes}
              onChange={(e, newValue) => {
                handleAttributeFieldChange(newValue, "notes", e);
              }}
              onBlur={validateAll}
            // helperText={type}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button onClick={saveEnterprise} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default CreateEntermodel;
