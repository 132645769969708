import { Box } from '@mui/material'
import React, { useContext } from 'react'
import MiniDrawer from './components/common/drawer/DrawerToggle'
import { ThemeContext } from './ThemeContext';
import TableauEmbed from './TableauEmbed';

const ChartTablauDemo = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const tableauUrl = 'http://public.tableau.com/views/RegionalSampleWorkbook/Stocks';
    return (
        <>
            <Box sx={{ display: "flex" }}>
                <MiniDrawer />
                <Box component="main" sx={{
                    flexGrow: 1, p: 3, overflow: "auto", width
                        : "100%", height: "100vh",
                    background: isDarkTheme ? "whitesmoke" : "white",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "40px",
                    }}>
                        <h3>Tablau Demo</h3>
                    </div>
                    <TableauEmbed url={tableauUrl} />
                </Box>
            </Box>
        </>
    )
}

export default ChartTablauDemo
