import { IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useMemo } from 'react';
import { MdMoreVert } from 'react-icons/md'

const MeasureViewData = ({
    reorderedFields,
    tableData,
    viewPageSize,
    viewpage,
    setViewPageSize,
    setViewPage,
    totalPage,
    handleButtonAction,
    selectIndex,
    setSelectIndex,
    handleEditRowMenu,
    handleDeleteMenu,
    actionMenu
}) => {

    const columns = useMemo(() => {
        // Map reorderedFields to create columns based on field names
        const mappedFields = reorderedFields && reorderedFields.length > 0
            ? reorderedFields.map((field) => ({
                accessorKey: field.name,
                header: field.name,
                enableSorting: true,
                enableColumnOrdering: false,
                Cell: ({ row }) => (
                    <p>{row?.original[field?.name]}</p>
                ),
            }))
            : [];

        // Define the "view" column separately
        const viewColumn = {
            accessorKey: "view",
            header: "View",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                return (
                    <>
                        <IconButton size="small" onClick={(event) => handleButtonAction(event, row?.index)}>
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={actionMenu}
                            open={Boolean(selectIndex)}
                            onClose={() => setSelectIndex(false)}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleEditRowMenu(row?.original);
                                    setSelectIndex(false);
                                }}
                                sx={{ fontSize: "12px" }}
                            >
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleDeleteMenu(row?.original?._id);
                                    setSelectIndex(false);
                                }}
                                sx={{ fontSize: "12px" }}
                            >
                                Delete
                            </MenuItem>
                        </Menu>
                    </>
                );
            },
        };

        // Return columns with the view column added at the end

        return mappedFields.length > 0 ? [...mappedFields, viewColumn] : [];
    }, [reorderedFields, actionMenu, selectIndex, handleButtonAction, handleEditRowMenu, handleDeleteMenu]);



    const table = useMaterialReactTable({
        columns: columns,
        data: tableData?.length > 0 ? tableData : [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enablePagination: false,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px"
            }
        },

    });
    return (
        <>
            <MaterialReactTable table={table} />
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalPage}
                rowsPerPage={viewPageSize}
                page={viewpage}
                onPageChange={(event, newPage) => setViewPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setViewPageSize(parseInt(event.target.value, 10));
                    setViewPage(0);
                }}
            />
        </>
    )
}

export default MeasureViewData
