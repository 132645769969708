import React from 'react'
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { deleteMeasureList, getMeasureList, getMeasurePaginationList } from '../../store/ApiSlice/measureSlice';
import { toast } from "react-toastify";

const DeleteMeasure = ({
  selectedRows,
  setSelectedRows,
  setModalDeleteOpen,
  isModalDeleteOpen,
  updatedId,
}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModalDeleteOpen(false);
  };

  const handleDelete = async () => {
    const response = await dispatch(deleteMeasureList({ attriId: updatedId }))
    if (response?.meta?.requestStatus === "fulfilled") {
      setModalDeleteOpen(false);
      // toast.success(response?.payload?.data?.message);
      dispatch(getMeasurePaginationList({ page: 1, itemsPerPage: 10, search: "" }));
      dispatch(getMeasureList());
    } else if (response?.meta?.requestStatus === "rejected") {
      toast.warn(
        response?.payload?.data?.error
      );
    }
    handleCloseModal()
  }

  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Delete Measure</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography>
            This is irreversible. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button color="primary" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </>
    );
  };
  return (
    <>
      <Dialog open={isModalDeleteOpen} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default DeleteMeasure
