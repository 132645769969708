import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getMultiEnterpriseList } from '../../store/ApiSlice/multiEnterpriseSlice';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from 'moment';

const MultiEnterpriseUserListMaterialList = ({
    handleTableRowSelect,
    setUpdatedId,
    handleCreateUpdate,
    setModalDeleteOpen
}) => {
    const { multiEnterpriseData } = useSelector(state => state.multiEnterprise);
    const [openedMenus, setOpenedMenus] = useState({});
    const dispatch = useDispatch()

    const handleClick = (rowId) => (event) => {
        event.stopPropagation();
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    useEffect(() => {
        dispatch(getMultiEnterpriseList())
    }, [])

    const columns = useMemo(() => [
        {
            accessorKey: "enterpriseName",
            enableColumnOrdering: false,
            header: "Enterprise",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.enterprise?.enterpriseName}</p>
                </>
            )
        },
        {
            accessorKey: "userName",
            enableColumnOrdering: false,
            header: "User Name",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.user?.userName || row?.original?.user?.name}</p>
                </>
            )
        },
        {
            accessorKey: "createdOn",
            enableColumnOrdering: false, //disable column ordering for this column,
            header: "Created On",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{moment(row?.original?.createdOn).format('MM/DD/YYYY')} </p>
                </>
            )
        },
        {
            accessorKey: "changedOn",
            enableColumnOrdering: false, //disable column ordering for this column,
            header: "Changed On",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{moment(row?.original?.changedOn).format('MM/DD/YYYY')} </p>
                </>
            )
        },
        {
            accessorKey: "view",
            header: "",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                return (
                    <>
                        <IconButton onClick={handleClick(row.original._id)} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={openedMenus[row.original._id]}
                            open={Boolean(openedMenus[row.original._id])}
                            onClose={() => { handleClose(row.original._id) }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    handleCreateUpdate(true, row?.original?._id);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Edit Enterprise user
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setModalDeleteOpen(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Delete Enterprise user
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        }
    ])

    const table = useMaterialReactTable({
        columns: columns.filter(Boolean),
        data: multiEnterpriseData || [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableColumnPinning: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            }
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                handleClose(row.original._id);
                handleCreateUpdate(true, row?.original?._id);
            }, // Row click event
            sx: {
                cursor: 'pointer', // Add a pointer cursor to indicate clickable rows
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                '&:hover': {
                    backgroundColor: '#f5f5f5', // Optional: Add hover effect for clarity
                },
            },
        }),
    });
    return <MaterialReactTable table={table} />
}

export default MultiEnterpriseUserListMaterialList
