import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MdFullscreen, MdFullscreenExit, MdMoreVert, MdKeyboardArrowDown, MdFilterList } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { createDimensionFilteredmasterData, createHomeWidget, createViewAggregated, deleteWidget, getAllFilterAttributeDetail, getCanvasList, getFilterDetail, getFilterWithEnterprise, getHomePageWidget, getPageList, getPrimaryTimeDimension, getSchemaData, getWorkBookDetail, setDropped, setLayout, setLayoutData } from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import 'react-data-grid/lib/styles.css';
import { fetchDimensions } from '../../store/ApiSlice/dimensionSlice';
import RGL, { WidthProvider } from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import MyGridComponent from './MyGridComponent';
import { getScenarios } from '../../store/ApiSlice/processSlice';
import '../page/page.css'
import { setPending } from '../../store/ApiSlice/widgetSlice';
import CanvasMore from '../canvas/shared/CanvasMore';
import ChartMain from '../canvas/shared/Chart-Main';
import { getMeasureList, getMeasureListWithEnterprise } from '../../store/ApiSlice/measureSlice';
import * as ExcelJS from 'exceljs';
import Loader from '../loader/Loader';
import { FiGrid, FiPlus } from "react-icons/fi";
import PivotTable from './PivotTable';
import EditcanvasWidget from './EditcanvasWidget';
import { getAttributeOrEnterPrise } from '../../store/ApiSlice/attributeSlice';
import PageFilter from './PageFilter';
import { IoAdd, IoSettingsOutline, IoStatsChartOutline } from 'react-icons/io5';

import {
    ChartComponent,
    LineSeries,
    SeriesCollectionDirective,
    StackingColumnSeries,
    Legend,
    Category,
    Inject as ChartInject,
    SeriesDirective,
    Tooltip as ChartToolTip,
} from '@syncfusion/ej2-react-charts';
import { FaFilter } from 'react-icons/fa';

const ReactGridLayout = WidthProvider(RGL);

const PageMaterailList = (props) => {
    const {
        newPageID,
        handleCreateUpdate,
        handleTableRowSelect,
        setAttriId,
        setModalOpenDelete,
        setSettingLayoutData,
        finalData,
        loader,
        setLoader,
        setEditWorkSheet,
        setIsCreateWidget,
        value,
        setValue,
        formValues,
        setFormValues,
        setOpenModal,
        setOpenWorkbookName,
        setGeneralModel,
        activeTab,
        setActiveTab,
        processPageData,
        widgetGroupData,
        setWidgetGroupData,
        newActiveWidgetLoader,
        setNewActiveWidgetLoader,
        setEditWidget,
        pageLoader,
        setPageLoader,
        openedWorkbookName
    } = props
    const { canvasData, pageData, layout, timeDimensionData, filterDetail, workBookData, schemaData } = useSelector((state) => state.canvas)
    const { id } = useParams();
    const chartRefs = useRef([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElWidget, setAnchorElWidget] = useState(null);
    const openMenu = Boolean(anchorElWidget);
    const [sheetValue, setSheetValue] = useState();
    const [openedMenus, setOpenedMenus] = useState({});
    const [openedWorksheet, setOpenedWorkSheet] = useState({})
    const dispatch = useDispatch();
    const canvasFindData = canvasData?.find((item) => item?._id == id)
    const findPageData = pageData
        .filter(item => item?.canvasUID?._id === canvasFindData?._id)
        .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
    const [deleteWorkBook, setDeleteWorkBook] = useState(false)
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [fullScreenWidget, setFullScreenWidget] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isOpenWidgetModel, setIsOpenWidgetModel] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [changeType, setChangeType] = useState()
    const [chartData, setChartData] = useState({
        tableData: [],
        value: {}
    })
    const [columns, setColumns] = useState(["Month"]);
    const [rows, setRows] = useState(["Product"]);
    const [isRowModalOpen, setRowModalOpen] = useState(false);
    const [isColumnModalOpen, setColumnModalOpen] = useState(false);
    const [newRowName, setNewRowName] = useState("");
    const [newColumnName, setNewColumnName] = useState("");
    const [pageFilter, setPageFilter] = useState(false)
    const navigate = useNavigate()

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const [activeTableLayoutTab, setActiveTableLayoutTab] = useState("grid");
    const [selectedWidgetLayoutIndex, setSelectedWidgetLayout] = useState();
    const tabs = [
        { id: "grid", icon: <FiGrid size={16} />, tooltip: "Grid" },
        { id: "chart", icon: <IoStatsChartOutline size={16} />, tooltip: "Chart" },
    ];



    useEffect(() => {
        const currentPage = findPageData.find(item => item?._id === newPageID);
        if (layout?.length === 0 && currentPage?.widgets?.length > 0) {
            const widgetsCount = currentPage.widgets.length;
            const layouts = [];
            let defaultY = 0;
            let defaultX = 0;
            const defaultW = widgetsCount > 3 ? 3 : Math.floor(12 / widgetsCount);
            for (let index = 0; index < widgetsCount; index++) {
                if (index % 4 === 0 && index !== 0) {
                    defaultY += 5;
                    defaultX = 0;
                }

                layouts.push({
                    w: defaultW,
                    minW: 4,
                    h: 2, // reduce initial height of the grid
                    x: defaultX,
                    y: defaultY,
                    i: index.toString(),
                    moved: false,
                    static: false,
                });

                defaultX += defaultW;
            }
            dispatch(setLayout(layouts));
        }
    }, [layout, findPageData, newPageID]);

    const handleUpdateWorkSheet = () => {
        setEditWorkSheet(true)
        setOpenModal(true)
        setOpenedWorkSheet({ id: null })
        setOpenWorkbookName({ pageId: value ? value : newPageID, workbookType: "Worksheet Group" })
    }

    useEffect(() => {
        dispatch(getWorkBookDetail())
        dispatch(getCanvasList())
        dispatch(getMeasureList())
        dispatch(getHomePageWidget())
    }, [])


    const handleCreateWidget = (item) => {
        setIsCreateWidget(true)
        setEditWorkSheet(false)
        setFormValues({})
        setOpenModal(false)
        setOpenWorkbookName({ pageId: value ? value : newPageID, workbook: item, workbookType: "Worksheet Group" })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setActiveTab({ pageUID: value ? value : newPageID, worksheetID: "" })
    };

    const handleChangeSheet = (event, newValue) => {
        setSheetValue(newValue);
        setActiveTab({ pageUID: value ? value : newPageID, worksheetID: newValue })
        dispatch(setDropped([]))
        dispatch(setPending([]))
    };

    const handleCloseButton = (item) => {
        setAnchorEl(null);
    };

    const handleCloseWidget = () => {
        setAnchorElWidget(null)
    }

    const getScenarioData = async () => {
        const body = {
            enterpriseName: canvasFindData?.enterpriseUID?.enterpriseName,
            modelName: canvasFindData?.modelUID?.modelName,
        };
        const result = await dispatch(getScenarios({ body }))
        // if (result?.meta?.requestStatus === 'fulfilled') {
        //     console.log("resultresult", result);
        // } else if (result?.meta?.requestStatus === 'rejected') {
        //     console.log("resultresult", result);
        // }
    };

    useEffect(() => {
        if (canvasFindData) {
            const data = {
                enterpriseUID: canvasFindData?.enterpriseUID?._id,
                modelUID: canvasFindData?.modelUID?.modalUID
            }
            dispatch(getPrimaryTimeDimension({ canvasFindData }))
            dispatch(getAllFilterAttributeDetail({ canvasFindData }))
            dispatch(getPageList({ canvasFindData }))
            dispatch(fetchDimensions({ enterpriseId: canvasFindData?.enterpriseUID?._id, modelId: canvasFindData?.modelUID?.modalUID }))
            dispatch(getFilterDetail())
            dispatch(getFilterWithEnterprise({ canvasFindData }))
            dispatch(getMeasureListWithEnterprise({ formValues: data }))
            dispatch(getAttributeOrEnterPrise({ enterPriseId: canvasFindData?.enterpriseUID?._id, modalId: canvasFindData?.modelUID?.modalUID }))
            getScenarioData()
        }
    }, [canvasFindData]);

    useEffect(() => {
        if (timeDimensionData && Object.keys(timeDimensionData).length > 0) {
            dispatch(getSchemaData({ timeDimensionData }));
        }
    }, [timeDimensionData?._id]);


    const onDrop = (ev) => {
        ev.preventDefault();
        // addNewItem();
    };

    const handleClickWorkSheetMenu = (rowId) => (event) => {
        setOpenedWorkSheet((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    }

    const handleCloseWorkSheetMenu = (id) => {
        setOpenedWorkSheet(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    const handleClickMenu = (rowId) => (event) => {
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    }

    const handleCloseMenu = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    const workSheetId = activeTab?.worksheetID ? activeTab?.worksheetID : finalData?.widgetUID?._id;
    useEffect(() => {
        const datas = workBookData?.find((item) => (item?.worksheet?._id || item?._id) === workSheetId);
        if (datas) {
            setSettingLayoutData({ widgetUID: datas });
        }
    }, [activeTab?.worksheetID, workBookData, workSheetId, newPageID]);

    useEffect(() => {
        processPageData();
    }, [newPageID]);

    const handleDeleteWorkBook = () => {
        setDeleteWorkBook(true)
    }

    const handleCloseDeleteWorkBook = () => {
        setDeleteWorkBook(false)
    }

    const handleDelete = async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        dispatch(deleteWidget({ attriId: finalData?.widgetUID?._id, config }))
            .then((response) => {
                if (response?.meta.requestStatus === 'fulfilled') {
                    dispatch(getPageList({ canvasFindData }))
                    // toast.success("Widget Deleted Successfully ");
                    dispatch(getWorkBookDetail());
                    handleCloseDeleteWorkBook()
                    setTimeout(() => {
                        dispatch(getPageList({ canvasFindData }))
                        processPageData();
                    }, [500])
                }
            })
            .catch((error) => {
                toast.error(error?.message || "An error occurred while deleting canvas.");
            });
    };


    const handleOpenWidget = (event) => {
        setAnchorElWidget(event.currentTarget)
    }

    const getLayoutFromLocalStorage = () => {
        const layoutJSON = localStorage.getItem('layout');
        return layoutJSON ? JSON.parse(layoutJSON) : [];
    };

    const saveLayoutToLocalStorage = (layout) => {
        localStorage.setItem('layout', JSON.stringify(layout));
    };
    const gridApi = useRef(null)

    useEffect(() => {
        const savedLayout = getLayoutFromLocalStorage();
        if (savedLayout.length > 0) {
            dispatch(setLayout(savedLayout));
        }
    }, [dispatch]);

    const handleLayoutChange = (newLayout) => {
        if (!isInitialRender) {
            if (JSON.stringify(newLayout) !== JSON.stringify(layout)) {
                dispatch(setLayout(newLayout));
                saveLayoutToLocalStorage(newLayout);
            }

            if (gridApi.current) {
                gridApi.current.sizeColumnsToFit();
            }
        }
        setIsInitialRender(false);
    };

    const [gridSizes, setGridSizes] = useState(
        findPageData.reduce((acc, _, index) => {
            acc[`widget-${index}`] = { width: 1000, height: 320 };
            return acc;
        }, {})
    );

    const handleResizeStop = (layout, oldItem, newItem) => {
        setGridSizes((prevSizes) => ({
            ...prevSizes,
            [newItem.i]: { width: newItem.w * 100, height: newItem.h * 80 },
        }));

        setTimeout(() => {
            const chartIndex = parseInt(newItem.i.split("-")[1], 10);
            if (chartRefs.current[chartIndex]) {
                chartRefs.current[chartIndex].refresh();
            }
        }, 100);
    };

    const handleNewActiveChart = async (widgetName, widgetInfo) => {

        setNewActiveWidgetLoader({
            ...newActiveWidgetLoader,
            [widgetName]: true
        })

        const updateData = await Promise.all(widgetGroupData?.map(async (widgetInfoItem) => {
            if (widgetInfoItem?.PageID === newPageID) {
                let newData = {};

                for (const [groupName, groupData] of Object.entries(widgetInfoItem?.pageTab || {})) {
                    if (widgetName === groupName) {
                        const newDataArray = await Promise.all(
                            widgetInfoItem?.pageTab[groupName]?.map(async (data) => {
                                if (data?.widgetUID?._id && (data?.widgetUID?._id === widgetInfo?.widgetUID?._id)) {
                                    const defaultActiveWidgets = widgetInfo;
                                    const findFilterId = filterDetail?.find(filter => filter?._id === defaultActiveWidgets?.widgetUID?.filterInfo?.filterRefID);
                                    const widgetType = defaultActiveWidgets?.widgetUID?.pivotTable;
                                    const timeName = defaultActiveWidgets?.widgetUID?.filterInfo?.timeAttribute?.periodType;
                                    let result;
                                    
                                    if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                                        const payload = {
                                            ...(widgetType?.measures?.length > 0 && {
                                                measures: widgetType?.measures,
                                            }),
                                            timeFields: {
                                                [timeName]: defaultActiveWidgets?.widgetUID?.filterInfo?.timeAttribute?.values,
                                            },
                                            aggregationFields: widgetType?.attributes?.map(
                                                (attr) => attr?.attributeName
                                            ),
                                            ...(defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.scenario?.length > 0 && {
                                                scenario: defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.scenario,
                                            }),
                                            ...(defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.cycleIds?.length > 0 && {
                                                cycleID: defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.cycleIds,
                                            }),
                                            ...(defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.lagIds?.length > 0 && {
                                                lagID: defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.lagIds,
                                            }),
                                            ...(findFilterId?.attributes?.length > 0 && {
                                                additionalFilters: findFilterId.attributes.map((attr) => ({
                                                    attribute: attr?.attributeUID?.name,
                                                    operator: attr?.operator,
                                                    values: attr?.values,
                                                })),
                                            }),
                                        };
                                        
                                        result = await dispatch(createViewAggregated({ payload }));
                                        if (defaultActiveWidgets?.widgetUID?.widgetType === "Chart") {
                                            setLoader({
                                                ...loader,
                                                [groupName]: false
                                            });
                                        } else if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                                            setLoader(false);
                                        }
                                    }
                                    // else {
                                    //     const payload = {
                                    //         dimensionUID: defaultActiveWidgets?.widgetUID?.worksheet?.dimension,
                                    //         attributes: defaultActiveWidgets?.widgetUID?.worksheet?.attributes?.map((item) => item?.attributeName),
                                    //         ...(findFilterId?.attributes?.length > 0 && {
                                    //             additionalFilters: findFilterId?.attributes?.map((item) => ({
                                    //                 attribute: item?.attributeUID?.name,
                                    //                 operator: item?.operator,
                                    //                 values: item?.values
                                    //             }))
                                    //         }),
                                    //         widgetUID: defaultActiveWidgets?.widgetUID?.worksheet?._id,
                                    //         scenario: defaultActiveWidgets?.widgetUID?.scenario?.map((item) => item),
                                    //     }
                                    //     result = await dispatch(createDimensionFilteredmasterData({ payload }));
                                    //     setLoader(false);
                                    // }
                                    return { ...data, getResul: result?.payload?.data };
                                } else {
                                    const { getResul, ...rest } = data;
                                    return rest;
                                }
                            })
                        );

                        newData[groupName] = newDataArray;
                    } else {
                        newData[groupName] = groupData;
                    }
                }

                return {
                    ...widgetInfoItem,
                    pageTab: newData,
                };
            } else {
                return widgetInfoItem;
            }
        }));

        setNewActiveWidgetLoader({
            ...newActiveWidgetLoader,
            [widgetName]: false
        })

        setWidgetGroupData(updateData);
    };

    const handleFullScreenToggle = (groupIndex) => {

        const currentPage = findPageData.find(item => item?._id === newPageID);
        const widgetsCount = currentPage.widgets.length;
        const defaultW = widgetsCount > 3 ? 3 : widgetsCount === 1 ? 4 : Math.floor(12 / widgetsCount);

        if (fullScreenWidget === groupIndex) {
            setFullScreenWidget(null);
            setTimeout(() => {
                const newLayout = layout?.map((l, i) =>
                    i === groupIndex ? { ...l, w: defaultW, h: 5 } : l
                );
                dispatch(setLayout(newLayout));
            }, 0);

        } else {
            setFullScreenWidget(groupIndex);
            setTimeout(() => {
                const newLayout = layout?.map((l, i) =>
                    i === groupIndex ? { ...l, w: 12, h: 6 } : l
                );
                dispatch(setLayout(newLayout));
            }, 0);
        }
    };

    const generateExcel = (datas) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        if (datas?.length > 0) {
            // Add headers
            const header = Object.keys(datas[0])?.filter(key => key !== "_id");
            worksheet.addRow(header);

            // Add rows
            datas.forEach(obj => {
                const row = header?.map(col => obj[col] !== undefined ? obj[col] : '');
                worksheet.addRow(row);
            });
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const fileName = 'measure_data.xlsx';
            const url = window.URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.style.display = 'none'; // Hide the element
            a.href = url;
            a.download = fileName;

            document.body.appendChild(a); // Append to DOM
            a.click(); // Trigger download
            document.body.removeChild(a); // Remove from DOM

            window.URL.revokeObjectURL(url); // Cleanup
        }).catch((err) => console.error("Error generating Excel file:", err));
    };


    useEffect(() => {
        const updateLayoutState = () => {
            const activepageData = widgetGroupData.find((PageInfo) => PageInfo.PageID === newPageID);

            let updatedLayoutData = [...layout].filter(item => item !== null);  // Filter out null values

            if (activepageData) {
                Object.keys(activepageData?.pageTab).forEach((groupName, groupIndex) => {
                    const widgets = activepageData.pageTab[groupName];
                    const activeWidgetsData = widgets.find((widgetItem) => widgetItem.getResul);

                    // Ensure the widget has default layout properties
                    const defaultWidgetLayout = {
                        w: 3,    // Default width (adjust according to your grid settings)
                        h: 5,    // Default height
                        minW: 3, // Default minimum width
                        moved: false,
                        static: false,
                    };

                    if (activeWidgetsData) {

                        let currentX = groupIndex % 4;  // Auto-set x position (0, 1, 2, 3 for 4 items per row)
                        let currentY = Math.floor(groupIndex / 4) * 5;  // Auto-set y position, increments by 5 for new rows

                        updatedLayoutData[groupIndex] = {
                            ...defaultWidgetLayout, // Default layout properties
                            ...updatedLayoutData[groupIndex], // Preserve existing properties
                            x: updatedLayoutData[groupIndex]?.x !== undefined ? updatedLayoutData[groupIndex].x : currentX,
                            y: updatedLayoutData[groupIndex]?.y !== undefined ? updatedLayoutData[groupIndex].y : currentY,
                            widgetId: activeWidgetsData?.widgetUID?._id,
                            pageId: newPageID,
                        };
                    }
                });

                // Filter out any remaining null entries again after processing
                updatedLayoutData = updatedLayoutData.filter(item => item !== null);

                if (!isInitialRender) {
                    if (JSON.stringify(updatedLayoutData) !== JSON.stringify(layout)) {
                        dispatch(setLayout(updatedLayoutData));
                        saveLayoutToLocalStorage(updatedLayoutData);
                    }

                    if (gridApi.current) {
                        gridApi.current.sizeColumnsToFit();
                    }
                }
                setIsInitialRender(false);
            }
        };

        updateLayoutState();
    }, [widgetGroupData, layout, newPageID, findPageData]);

    const gridRef = useRef()

    const copyToClipboard = () => {
        if (!gridRef.current) return;

        const gridApi = gridRef.current.api;

        const columnDefs = gridApi.getColumnDefs();
        const headers = columnDefs.map(col => col.headerName || col.field).join('\t');

        const selectedRows = gridApi.getSelectedRows();
        const allRows = [];
        gridApi.forEachNode((node) => {
            allRows.push(node.data);
        });

        const dataToCopy = selectedRows.length > 0 ? selectedRows : allRows;

        if (dataToCopy.length === 0) {
            toast.error('No data available to copy');
            return;
        }

        const rowData = dataToCopy.map(row =>
            columnDefs.map(col => row[col.field]).join('\t')
        ).join('\n');

        const clipboardData = `${headers}\n${rowData}`;

        navigator.clipboard.writeText(clipboardData)
            .then(() => {
                toast.success('Data copied to clipboard');
            })
            .catch(err => {
                toast.error('Could not copy text:');
            });
    };

    setTimeout(() => {
        setPageLoader(false)
    }, 5000)


    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    };

    const addRow = () => {
        setRowModalOpen(true);
    };

    const addColumn = () => {
        setColumnModalOpen(true);
    };

    const handleAddRow = () => {
        if (newRowName.trim() !== "") {
            setRows([...rows, newRowName.trim()]);
            setNewRowName("");
            setRowModalOpen(false);
        }
    };

    const handleAddColumn = () => {
        if (newColumnName.trim() !== "") {
            setColumns([...columns, newColumnName.trim()]);
            setNewColumnName("");
            setColumnModalOpen(false);
        }
    };

    const removeRow = (index) => {
        setRows(rows.filter((_, i) => i !== index));
    };

    const removeColumn = (index) => {
        setColumns(columns.filter((_, i) => i !== index));
    };

    const transformData = (data, dynamicScenarios) => {
        const attributeMap = chartData?.value?.widgetUID?.pivotTable?.attributes?.reduce((acc, attr) => {
            acc[attr.attributeName] = attr.attributeUID;
            return acc;
        }, {});

        let groupedData = {};
        if (!Array.isArray(data)) {
            return; // or set data to an empty array: data = []
        }

        data?.forEach((entry) => {
            const productId = entry[Object.keys(attributeMap)[0]];

            if (!groupedData[productId]) {
                groupedData[productId] = {};
            }

            Object.keys(entry).forEach((key) => {
                if (key.startsWith("M")) {  // If it's a month key (e.g., M1 2024)
                    if (!groupedData[productId][key]) {
                        groupedData[productId][key] = { month: key };
                    }

                    // Initialize dynamic scenarios only if they aren't already present
                    dynamicScenarios.forEach((scenario) => {
                        if (!groupedData[productId][key][scenario]) {
                            groupedData[productId][key][scenario] = 0;  // Initialize all dynamic scenarios as 0
                        }
                    });

                    const value = entry[key] ? parseFloat(entry[key]) : 0;
                    const scenario = entry["Scenario"];  // This should match exactly with "Baseline", "New Customer", "Upside"


                    // Ensure the scenario exists in the groupedData object for this month
                    if (groupedData[productId][key][scenario] !== undefined) {
                        groupedData[productId][key][scenario] += value;  // Add the value to the corresponding scenario
                    }
                }
            });
        });


        let stackedData = [];
        let lineData = [];

        Object.keys(groupedData).forEach((productId) => {
            Object.values(groupedData[productId]).forEach((dataPoint) => {
                const row = { productId, month: dataPoint.month };

                // Push each dynamic scenario value into the stackedData
                dynamicScenarios.forEach((scenario) => {
                    row[scenario] = dataPoint[scenario];
                });

                stackedData.push(row);

                // Sample line calculation (sum of all scenario values)
                lineData.push({
                    x: `${productId} - ${dataPoint.month}`,
                    y: dynamicScenarios.reduce((sum, scenario) => sum + dataPoint[scenario], 0),
                });
            });
        });

        return { stackedData, lineData };
    }

    const dynamicScenarios = chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.scenario;

    const { stackedData } = transformData(chartData?.tableData, dynamicScenarios)
    const transformedData = stackedData?.filter((item) => item?.[chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.timeAttribute?.toLowerCase()] !== "Measure Name")
    const toCamelCase = (str) => {
        return str
            .split(' ')  // Split the string by spaces
            .map((word, index) =>
                index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join('');
    };

    const [selectedWidgetId, _selectedWidgetId] = useState("");


    const initiallyUpdatedWidgetGroupData = useMemo(() => {
        if (widgetGroupData.length > 0) {

            function addIsGridSelected(pageTab) {
                for (const key in pageTab) {
                    (pageTab[key] ?? []).forEach((item) => {
                        item.isGridSelected = true;
                    });
                }
                return pageTab;
            }

            const result = widgetGroupData.map((item) => {
                return {
                    ...item,
                    pageTab: addIsGridSelected(item.pageTab),
                };
            });

            return result;
        }

        return widgetGroupData;

    }, [widgetGroupData]);



    const updatedWidgetGroupData = useMemo(() => {
        if (initiallyUpdatedWidgetGroupData.length > 0 || selectedWidgetId !== "") {

            function addIsGridSelected(pageTab, selectedId, chartData) {
                for (const key in pageTab) {
                    pageTab[key].forEach((item) => {
                        if (item._id === selectedId) {
                            item.isGridSelected = !item.isGridSelected;
                            item.widgetUID = {
                                ...item.widgetUID,
                                widgetType: !item.isGridSelected ? "Chart" : "Pivot Table"
                            };
                        } else {
                            item.isGridSelected = item.isGridSelected;
                        }
                    });
                }
                return pageTab;
            }

            const updatedData = initiallyUpdatedWidgetGroupData.map((item) => {
                return {
                    ...item,
                    pageTab: addIsGridSelected(item.pageTab, selectedWidgetId, chartData)
                };
            });

            return updatedData;
        }

        return initiallyUpdatedWidgetGroupData;
    }, [initiallyUpdatedWidgetGroupData, selectedWidgetId, chartData]);

    useEffect(() => {
        if (selectedWidgetId) {
            _selectedWidgetId("");
        }
    }, [selectedWidgetId])

    return (
        <>
            <Box>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value ? value : newPageID}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            aria-label="scrollable auto tabs example"
                        >
                            {
                                findPageData?.map((item, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            value={item?._id} onClick={() => {
                                                setActiveTab({ pageUID: "", worksheetID: "" })
                                                setValue(item?._id)
                                                handleCloseButton(item);
                                                setAnchorEl(null);
                                                setGeneralModel(false)
                                                setFormValues({})
                                                setSettingLayoutData(item?.widgets?.[0])
                                                dispatch(setDropped([]))
                                                dispatch(setPending([]))
                                            }}
                                            label={
                                                <CanvasMore
                                                    item={item}
                                                    newPageID={newPageID}
                                                    handleClickMenu={handleClickMenu}
                                                    openedMenus={openedMenus}
                                                    handleCloseMenu={handleCloseMenu}
                                                    handleCreateWidget={handleCreateWidget}
                                                    handleCreateUpdate={handleCreateUpdate}
                                                    handleTableRowSelect={handleTableRowSelect}
                                                    setModalOpenDelete={setModalOpenDelete}
                                                    setAttriId={setAttriId}
                                                    handleOpenWidget={handleOpenWidget}
                                                    getScenarioData={getScenarioData}
                                                    setDropped={setDropped}
                                                    anchorElWidget={anchorElWidget}
                                                    openMenu={openMenu}
                                                    handleCloseWidget={handleCloseWidget}
                                                    setGeneralModel={setGeneralModel}
                                                    setAnchorElWidget={setAnchorElWidget}
                                                    setOpenedMenus={setOpenedMenus}
                                                    formValues={formValues}
                                                    setFormValues={setFormValues}
                                                />
                                            }
                                        />
                                    )
                                })
                            }
                        </Tabs>
                    </Box>
                    <div className="flex justify-end items-center pt-6 gap-3">
                        <Tooltip title="Add Page Filter">
                            <button
                                id="dropdownInformationButton"
                                onClick={() => {
                                    setPageFilter(true)
                                }}
                                className='flex items-center min-w[150px] gap-2 text-sm cursor-pointer rounded-md py-2 px-3 shadow-sm border border-[#E9EAEB] bg-[#FFFFFF] text-[#181D27]'
                            >
                                <FaFilter size={18} />
                            </button>
                        </Tooltip>
                        <button
                            id="dropdownInformationButton"
                            onClick={() => {
                                setIsOpenWidgetModel(true)
                            }}
                            className='flex items-center gap-2 text-sm cursor-pointer rounded-md py-2 px-3 shadow-sm border border-[#E9EAEB] bg-[#FFFFFF] text-[#181D27]'
                        >
                            <IoAdd />
                            <p>Add a Widget</p>
                        </button>
                    </div>

                    {isOpenWidgetModel && (
                        <PivotTable
                            isSidebarOpen={isSidebarOpen}
                            setIsOpenWidgetModel={setIsOpenWidgetModel}
                            processPageData={processPageData}
                            openedWorkbookName={openedWorkbookName}
                            newPageID={newPageID}
                            value={value}
                        />
                    )}

                    {updatedWidgetGroupData?.length > 0 ? (
                        <TabContext value={value ? value : newPageID}>
                            <div>
                                {findPageData?.map((data, index) => {
                                    return (
                                        <>
                                            {data?.widgets?.length > 0 ? (
                                                <Box>
                                                    <TabPanel key={data?._id} value={data?._id} sx={{ color: 'black' }}>
                                                        <div>
                                                            <ReactGridLayout
                                                                {...props}
                                                                onDrop={onDrop}
                                                                className={`layout ${isOpenWidgetModel ? "w-[60%]" : ""}`}
                                                                layout={layout}
                                                                cols={12}
                                                                width={1200}
                                                                minHeight="400px"
                                                                onLayoutChange={handleLayoutChange}
                                                                onResizeStop={handleResizeStop}
                                                                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                                            >
                                                                {(() => {
                                                                    const activepageData = updatedWidgetGroupData.find((PageInfo) => PageInfo.PageID == data?._id)

                                                                    const groupedWidgets = activepageData?.pageTab;
                                                                    return activepageData ? Object.keys(groupedWidgets).map((groupName, groupIndex) => {
                                                                        const widgets = groupedWidgets[groupName];
                                                                        const activeWidgetsData = widgets.find((widgetItem) => widgetItem.getResul);
                                                                        
                                                                        const widgetKey = `widget-${index}`;
                                                                        const size = gridSizes[widgetKey] || { width: 600, height: 320 };

                                                                        return (
                                                                            <div key={groupIndex}
                                                                                data-grid={fullScreenWidget === groupIndex ?
                                                                                    { x: 0, y: 0, w: 12, h: 6 } :
                                                                                    layout[groupIndex]}
                                                                                style={{
                                                                                    border: "1px solid #e0e0e0",
                                                                                    // boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                                                                                    marginBottom: "10px",
                                                                                    zIndex: "9",
                                                                                    background: "white",
                                                                                    padding: "10px",
                                                                                    borderRadius: "10px",
                                                                                }}>
                                                                                <Box sx={{
                                                                                    overflowY: "auto",
                                                                                    height: "100%", position: "relative", paddingRight: "6px",
                                                                                    '&::-webkit-scrollbar': {
                                                                                        width: '4px',
                                                                                    },
                                                                                    '&::-webkit-scrollbar-track': {
                                                                                        background: 'none',
                                                                                    },
                                                                                    '&::-webkit-scrollbar-thumb': {
                                                                                        background: 'lightblue',
                                                                                        borderRadius: '6px',
                                                                                    },
                                                                                }}>

                                                                                    {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: "9" }}>
                                                                                        <Box sx={{ margin: "0px 12px" }}>
                                                                                            <div style={{ fontSize: "12px", textTransform: "none", fontFamily: "'Poppins', sans-serif" }}>

                                                                                            </div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Tooltip title={fullScreenWidget !== groupIndex ? "Full Screen" : "Shrink To Original"}>
                                                                                                <IconButton onClick={() => handleFullScreenToggle(groupIndex)} sx={{ cursor: "pointer" }}>
                                                                                                    {fullScreenWidget === groupIndex ? <MdFullscreenExit /> : <MdFullscreen />}
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                    </Box> */}
                                                                                    <Box sx={{
                                                                                        backgroundColor: "white",
                                                                                        color: "black"
                                                                                    }}
                                                                                        key={`${groupIndex}-index`}
                                                                                    >
                                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: "9", position: 'absolute', right: 0 }}>
                                                                                            <Box sx={{ margin: "0px 12px" }}>
                                                                                                <div style={{ fontSize: "20px", textTransform: "none", fontFamily: "'Poppins', sans-serif" }}>

                                                                                                </div>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Tooltip title={fullScreenWidget !== groupIndex ? "Full Screen" : "Shrink To Original"}>
                                                                                                    <IconButton onClick={() => handleFullScreenToggle(groupIndex)} sx={{ cursor: "pointer", fontSize: '20px' }}>
                                                                                                        {fullScreenWidget === groupIndex ? <MdFullscreenExit /> : <MdFullscreen />}
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            {widgets?.map((data, index) => {
                                                                                                const isSelected = data?.widgetUID?._id?.includes(activeWidgetsData?.widgetUID?._id);
                                                                                                return <>
                                                                                                    <div className="fle w-full h-full" key={`widgets-${groupIndex}-${index}`}>
                                                                                                        <div className="relative group flex items-center w-full h-full">
                                                                                                            <Tooltip title="Create Attribute">
                                                                                                                <IconButton
                                                                                                                    onClick={(e) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        handleClickWorkSheetMenu(data?.widgetUID?._id)(e);
                                                                                                                    }}
                                                                                                                    size="small"
                                                                                                                    sx={{
                                                                                                                        color: isSelected ? 'black' : 'black',
                                                                                                                        backgroundolor: 'transparent',
                                                                                                                        position: "absolute",
                                                                                                                        top: '2px',
                                                                                                                        right: '25px'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <IoSettingsOutline
                                                                                                                        cursor="pointer"
                                                                                                                        style={{ zIndex: "9", fontSize: '16px' }}
                                                                                                                    />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                            <Menu
                                                                                                                anchorEl={openedWorksheet[data?.widgetUID?._id]}
                                                                                                                open={Boolean(openedWorksheet[data?.widgetUID?._id])}
                                                                                                                onClose={() => handleCloseWorkSheetMenu(data?.widgetUID?._id)}
                                                                                                            >
                                                                                                                <MenuItem
                                                                                                                    onClick={() => {
                                                                                                                        toggleModal();
                                                                                                                        setChartData({
                                                                                                                            tableData: data?.getResul,
                                                                                                                            value: data
                                                                                                                        });
                                                                                                                        setChangeType("grid");
                                                                                                                        setIsSidebarOpen(true)
                                                                                                                        handleCloseWorkSheetMenu(data?.widgetUID?._id)
                                                                                                                    }}
                                                                                                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                >
                                                                                                                    Edit {activeWidgetsData?.widgetUID?.widgetType}
                                                                                                                </MenuItem>
                                                                                                                <MenuItem
                                                                                                                    onClick={() => {
                                                                                                                        handleDeleteWorkBook();
                                                                                                                        setSettingLayoutData(data)
                                                                                                                        handleCloseWorkSheetMenu(data?.widgetUID?._id)
                                                                                                                    }}
                                                                                                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                >
                                                                                                                    Delete {activeWidgetsData?.widgetUID?.widgetType}
                                                                                                                </MenuItem>

                                                                                                                <MenuItem
                                                                                                                    onClick={() => {
                                                                                                                        copyToClipboard()
                                                                                                                        handleCloseWorkSheetMenu(data?.widgetUID?._id)
                                                                                                                    }}
                                                                                                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                >
                                                                                                                    Copy Data
                                                                                                                </MenuItem>
                                                                                                                <MenuItem
                                                                                                                    onClick={() => {
                                                                                                                        handleCloseWorkSheetMenu(data?.widgetUID?._id)
                                                                                                                        generateExcel(activeWidgetsData?.getResul)
                                                                                                                    }}
                                                                                                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                >
                                                                                                                    Download Data
                                                                                                                </MenuItem>
                                                                                                                <MenuItem
                                                                                                                    onClick={() => {
                                                                                                                        dispatch(createHomeWidget(activeWidgetsData?.widgetUID?._id))
                                                                                                                        handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?._id)
                                                                                                                        dispatch(getHomePageWidget())
                                                                                                                        navigate(`/widget/${activeWidgetsData?.widgetUID?._id}`);
                                                                                                                    }}
                                                                                                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                >
                                                                                                                    Add To Home Page
                                                                                                                </MenuItem>
                                                                                                            </Menu>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            })}
                                                                                        </Box>


                                                                                        <div key={groupIndex}>
                                                                                            {/* {activeWidgetsData?.widgetUID?.widgetType === "Pivot Table" && ( */}
                                                                                            <Tabs
                                                                                                value={activeWidgetsData?.widgetUID?._id}
                                                                                                onChange={handleChangeSheet}
                                                                                                variant="scrollable"
                                                                                                scrollButtons="auto"
                                                                                                aria-label="scrollable auto tabs example"
                                                                                                sx={{
                                                                                                    "& .MuiTabs-indicator": {
                                                                                                        backgroundColor: 'transparent',
                                                                                                    },
                                                                                                    borderBottom: "2px solid",
                                                                                                    padding: "10px 10px",
                                                                                                }}
                                                                                            >
                                                                                                {widgets?.map((data) => {
                                                                                                    const isSelected = data?.widgetUID?._id?.includes(activeWidgetsData?.widgetUID?._id);

                                                                                                    return (
                                                                                                        <Tab
                                                                                                            key={data?.widgetUID?._id}
                                                                                                            onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                handleNewActiveChart(groupName, data)
                                                                                                            }}

                                                                                                            label={
                                                                                                                <div className="fle w-full h-full">
                                                                                                                    <div className="relative group flex items-center w-full h-full">
                                                                                                                        <span className='font-bold text-[16px]' > {data?.widgetUID?.widgetName}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            value={(data?.widgetUID?._id) || data?.widgetUID?._id}
                                                                                                            sx={{
                                                                                                                '&.MuiTab-root': {
                                                                                                                    minHeight: 'auto',
                                                                                                                    padding: '0px',
                                                                                                                    color: "#000000"
                                                                                                                },
                                                                                                            }
                                                                                                            }

                                                                                                        />
                                                                                                    )
                                                                                                })}
                                                                                                {/* <Tab

                                                                                                    label={
                                                                                                        <div className="flex bg-[#FAFAFA] border border-gray-300 rounded-md shadow-sm">
                                                                                                            {!activeWidgetsData.isGridSelected &&
                                                                                                                <div className="relative group flex items-center">
                                                                                                                    <button
                                                                                                                        className={`flex items-center justify-center w-[45px] h-[40px] transition-all text-blue-600 font-medium shadow-sm`}
                                                                                                                        onClick={() => {
                                                                                                                            _selectedWidgetId(activeWidgetsData._id);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <FiGrid size={16} />
                                                                                                                    </button>
                                                                                                                </div>}
                                                                                                            {activeWidgetsData.isGridSelected &&
                                                                                                                <div className="relative group flex items-center">
                                                                                                                    <button
                                                                                                                        className={`flex items-center justify-center w-[45px] h-[40px] transition-all text-blue-600 font-medium shadow-sm`}
                                                                                                                        onClick={() => {
                                                                                                                            _selectedWidgetId(activeWidgetsData._id);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <IoStatsChartOutline size={16} />
                                                                                                                    </button>
                                                                                                                </div>}
                                                                                                        </div>
                                                                                                    }
                                                                                                    sx={{
                                                                                                        '&.MuiTab-root': {
                                                                                                            height: '45px'
                                                                                                        },
                                                                                                    }
                                                                                                    }
                                                                                                ></Tab> */}
                                                                                            </Tabs>
                                                                                            {/* )} */}

                                                                                            <TabContext
                                                                                                key={activeWidgetsData?.widgetUID?._id}
                                                                                                value={activeWidgetsData?.widgetUID?._id}
                                                                                            >
                                                                                                {widgets?.map((widgetData, i) => {
                                                                                                    return (
                                                                                                        <TabPanel
                                                                                                            key={widgetData?.widgetUID?._id}
                                                                                                            value={widgetData?.widgetUID?._id}
                                                                                                            sx={{ color: 'black', textAlign: "start" }}
                                                                                                        >

                                                                                                            {/* {activeWidgetsData?.widgetUID?.widgetType === "Pivot Table" && activeWidgetsData.isGridSelected && ( */}
                                                                                                            <Box sx={{ height: "100%", width: "100%", overflow: "auto" }}>
                                                                                                                <MyGridComponent
                                                                                                                    loader={newActiveWidgetLoader?.[groupName]}
                                                                                                                    setLoader={setLoader}
                                                                                                                    finalData={activeWidgetsData}
                                                                                                                    aggregatedViewData={activeWidgetsData?.getResul}
                                                                                                                    gridRef={gridRef}
                                                                                                                    processPageData={processPageData}
                                                                                                                    canvasFindData={canvasFindData}
                                                                                                                    timeDimensionData={timeDimensionData}
                                                                                                                    schemaData={schemaData}
                                                                                                                    selectedWidgetId={selectedWidgetId}
                                                                                                                    keyIndex={widgetData?.widgetUID?._id}
                                                                                                                    pageFilter={data.filter}
                                                                                                                />
                                                                                                            </Box>
                                                                                                            {/* )} */}

                                                                                                        </TabPanel>
                                                                                                    )
                                                                                                })}
                                                                                            </TabContext>
                                                                                        </div>
                                                                                    </Box>
                                                                                </Box>
                                                                            </div>
                                                                        );
                                                                    }) : null
                                                                })()}
                                                            </ReactGridLayout>
                                                        </div>
                                                    </TabPanel>
                                                </Box>
                                            ) : (
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}>
                                                    {/* <Loader /> */}
                                                    {/* <img src={Nodata} alt='no data available' /> */}
                                                </div>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                        </TabContext >
                    ) : (
                        <>
                            {pageLoader ? (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "115px"
                                }}>
                                    <Loader />
                                </div>
                            ) : (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    transform: "translate(-50%, 50%)",
                                    top: 0,
                                    left: "50%"
                                }}>
                                    {/* <Loader /> */}
                                    {/* <img src={Nodata} alt='no data available' /> */}
                                </div>
                            )}
                        </>
                    )}
                </Box>
            </Box>

            {deleteWorkBook && (
                <Dialog open={deleteWorkBook} onClose={handleCloseDeleteWorkBook}>
                    <DialogTitle>Delete Widget</DialogTitle>
                    <DialogContent style={{ width: "500px", margin: "2px" }}>
                        <Typography variant="h6" component="h2">
                            This Is Irreversible. Are You Sure?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteWorkBook} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                        <Button
                            onClick={() => {
                                handleDelete();
                            }}
                            color="primary" sx={{ textTransform: "capitalize" }}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {isModalOpen && changeType && (
                <EditcanvasWidget
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    changeType={changeType}
                    setChangeType={setChangeType}
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                    chartData={chartData}
                    setChartData={setChartData}
                    toCamelCase={toCamelCase}
                    transformedData={transformedData}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    addColumn={addColumn}
                    columns={columns}
                    setColumns={setColumns}
                    removeColumn={removeColumn}
                    addRow={addRow}
                    rows={rows}
                    setRows={setRows}
                    removeRow={removeRow}
                    isRowModalOpen={isRowModalOpen}
                    newRowName={newRowName}
                    setNewRowName={setNewRowName}
                    setRowModalOpen={setRowModalOpen}
                    handleAddRow={handleAddRow}
                    isColumnModalOpen={isColumnModalOpen}
                    newColumnName={newColumnName}
                    setNewColumnName={setNewColumnName}
                    setColumnModalOpen={setColumnModalOpen}
                    handleAddColumn={handleAddColumn}
                    setIsOpenWidgetModel={setIsModalOpen}
                    processPageData={processPageData}
                    openedWorkbookName={openedWorkbookName}
                    newPageID={newPageID}
                    value={value}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
            )}

            {pageFilter && (
                <PageFilter
                    pageFilter={pageFilter}
                    setPageFilter={setPageFilter}
                    canvasFindData={canvasFindData}
                    newPageID={newPageID}
                />
            )}
        </>
    )
}

export default PageMaterailList;