import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteProcessList, getProcessList, terminateProcessList } from '../../store/ApiSlice/processSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const TerminateProcess = ({
  selectedRows,
  setSelectedRows,
  setModalDeleteOpenTerminate,
  isModalDeleteOpenTerminate,
  updatedId
}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModalDeleteOpenTerminate(false);
  };

  const handleTerminate = async () => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    const response = await dispatch(terminateProcessList({ processId: updatedId, config }))
    if (response?.meta?.requestStatus === "fulfilled") {
      setModalDeleteOpenTerminate(false);
      // toast.success(response?.payload?.message);
      dispatch(getProcessList());
    } else if (response?.meta?.requestStatus === "rejected") {
      toast.warn(
        response?.payload?.data?.error
          ? response?.payload?.data?.error
          : "Process is not executing."
      );
    }
    handleCloseModal()
  }

  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Terminate Process Detail</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography>
            This is irreversible. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} sx={{fontFamily: "'Poppins', sans-serif"}}>Cancel</Button>
          <Button color="primary" onClick={handleTerminate} sx={{fontFamily: "'Poppins', sans-serif"}}>
            Terminate
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <Dialog open={isModalDeleteOpenTerminate} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default TerminateProcess
