import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dimensionReducer from './ApiSlice/dimensionSlice';
import enterpriseSlice from './ApiSlice/enterpriseSlice';
import modelSlice from './ApiSlice/modelSlice';
import attributeSlice from './ApiSlice/attributeSlice';
import authSlice from './ApiSlice/authSlice';
import measureSlice from './ApiSlice/measureSlice';
import canvasSlice from './ApiSlice/canvasSlice';
import profileSlice from './ApiSlice/profileSlice';
import processSlice from './ApiSlice/processSlice';
import measuredataslice from './ApiSlice/measuredataslice';
import widgetSlice from './ApiSlice/widgetSlice.js';
import roleSlice from './ApiSlice/roleSlice.js';
import superAdministratorSlice from './ApiSlice/superAdministratorSlice.js';
import userSlice from './ApiSlice/userSlice.js';
import multiEnterpriseSlice from './ApiSlice/multiEnterpriseSlice.js';

// const store = combineReducers({
//   reducer: {
//     dimension: dimensionReducer,
//     enterprise: enterpriseSlice,
//     modelList: modelSlice,
//     attribute: attributeSlice,
//     auth: authSlice,
//     measure: measureSlice,
//     canvas: canvasSlice,
//     profile: profileSlice,
//     process: processSlice,
//     measureprofile: measuredataslice
//   }
// });

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: [
//     "dimension",
//     "enterprise",
//     "modelList",
//     "attribute",
//     "auth",
//     "measure",
//     "canvas",
//     "profile",
//     "process",
//     "measureprofile"
//   ],
// };

// const persistedReducer = persistReducer(persistConfig, reducers);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       immutableCheck: false,
//       serializableCheck: false,
//     }),
//   devTools: true,
// });

// export const persistor = persistStore(store);
const rootReducer = combineReducers({
  dimension: dimensionReducer,
  enterprise: enterpriseSlice,
  modelList: modelSlice,
  attribute: attributeSlice,
  auth: authSlice,
  measure: measureSlice,
  canvas: canvasSlice,
  profile: profileSlice,
  process: processSlice,
  measureprofile: measuredataslice,
  widget: widgetSlice,
  role: roleSlice,
  superAdministrator: superAdministratorSlice,
  user: userSlice,
  multiEnterprise: multiEnterpriseSlice
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: true,
});
export default store;
