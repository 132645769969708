import React, { useState } from "react";
import { Toolbar, Typography, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Select, MenuItem } from "@mui/material";
import CreateAttribute from "../CreateTimeAttribute";
import AttributesListModel from "./AttributesListModel";
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import moment from "moment/moment";

const TimeAttribute = ({
    dimensionAttributes,
    setDimensionAttributes,
    isView,
    formValues,
    refAttributeMapping,
    setRefAttributeMapping,
    dimenstionData,
    setAssignedAttributedData,
    assignedAttributedData,
    modelEnterprise
}) => {
    const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
        useState(false);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const handleKeyChange = (event, row, keyToUpdate) => {
        let newValue = event.target.checked;
        setDimensionAttributes((prevArray) =>
            prevArray.map((obj, index) => {
                if (row._id === obj._id) {
                    if (keyToUpdate === "primaryKey") {
                        return {
                            ...obj,
                            [keyToUpdate]: newValue,
                            required: newValue ? true : false,
                        };
                    } else {
                        return { ...obj, [keyToUpdate]: newValue };
                    }
                } else {
                    return obj;
                }
            })
        );
    };

    const handleDeleteSubmit = () => {
        let newFilteredRows = dimensionAttributes.filter((item) => {
            return !selectedRows.includes(item._id);
        });
        setDimensionAttributes(newFilteredRows);
        setIsDeleteDimensionModalOpen(false);
    };
    const handleDeleteDimensionCloseModal = () => {
        setIsDeleteDimensionModalOpen(false);
    };

    const renderModalContent = () => {
        return (
            <Dialog
                open={isDeleteDimensionModalOpen}
                onClose={handleDeleteDimensionCloseModal}
            >
                <DialogTitle>Remove Attribute</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography variant="h6" component="h2">
                        Are You Sure For Remove This Attribute?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDimensionCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDeleteSubmit();
                        }}
                        color="primary" sx={{ textTransform: "capitalize" }}
                    >
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const referenceDimFields =
        dimenstionData && dimenstionData.fields ? dimenstionData.fields : [];

    // const handleReferenceAttribute = (event, currentAttribute) => {
    //     let mappingExists = refAttributeMapping.find(
    //         (item) => item.mainAttribute === currentAttribute._id
    //     );
    //     if (mappingExists) {
    //         let mappings = refAttributeMapping.map((mapping) => {
    //             if (mappingExists.mainAttribute === currentAttribute._id) {
    //                 mapping.refAttribute = event.target.value;
    //             }
    //             return mapping;
    //         });
    //         setRefAttributeMapping([...mappings]);
    //     } else {
    //         let mapping = {
    //             mainAttribute: currentAttribute._id,
    //             refAttribute: event.target.value,
    //         };
    //         setRefAttributeMapping((preMapping) => [...preMapping, mapping]);
    //     }
    // };
    const handleReferenceAttribute = (event, currentAttribute) => {
        const mappingIndex = refAttributeMapping.findIndex((item) => item.mainAttribute === currentAttribute._id);
        if (mappingIndex !== -1) {
            const updatedMappings = [...refAttributeMapping];
            updatedMappings[mappingIndex].refAttribute = event.target.value;
            setRefAttributeMapping(updatedMappings);
        } else {
            setRefAttributeMapping([
                ...refAttributeMapping,
                { mainAttribute: currentAttribute._id, refAttribute: event.target.value },
            ]);
        }
    };

    // const getRefAttributeId = (row) => {
    //     let mapping = timeDimensionData.find(
    //         (item) => item["Period Type"] === row._id
    //     );
    //     return mapping 
    // };

    const getRefAttributeId = (row) => {
        const mapping = refAttributeMapping.find((item) => item.mainAttribute === row._id);
        return mapping ? mapping.refAttribute : ''; // Return the _id if found, otherwise an empty string
    };
    const [isChecked, setIsChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleCheckboxChange = (index) => {
        const updatedData = [...assignedAttributedData];
        const updatedRow = { ...updatedData[index] };
        updatedRow.checked = !updatedRow.checked;

        // Update primaryKey based on the checked state
        updatedRow.primaryKey = updatedRow.checked;

        updatedData[index] = updatedRow;
        setAssignedAttributedData(updatedData);
        setIsChecked(updatedRow.checked);
        setDisabled(updatedRow.checked);


    };




    return (
        <>
            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Toolbar>
                <Typography variant="h6">Time Attributes</Typography>
            </Toolbar>
            {/* <Grid item>
                <Typography
                    variant="h6"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        marginRight: "26px",
                    }}
                >
                    <Button
                        disabled={isView}
                        title="Add new attribute in dimension"
                        variant="text"
                        onClick={handleAddClick}
                    >
                        Add
                    </Button>
                    <Button
                        disabled={isView}
                        title="Create new attribute and add in dimension"
                        variant="text"
                        onClick={handleNewClick}
                    >
                        New
                    </Button>
                    <Button
                        disabled={isView}
                        title="Delete attribute from dimension"
                        variant="text"
                        onClick={handleDeleteClick}
                    >
                        Remove
                    </Button>
                </Typography>
            </Grid> */}

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {!isView && <TableCell align="left">Select</TableCell>}
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Primary Key</TableCell>
                            <TableCell align="left">Required</TableCell>
                            {formValues.type === "REFERENCE" && (
                                <TableCell align="left">Reference Attribute</TableCell>
                            )}

                            <TableCell align="left">Created On</TableCell>
                            <TableCell align="left">Changed On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assignedAttributedData.map((row, index) => (
                            <>
                                <TableRow
                                    key={row.name}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    {!isView && (
                                        <TableCell component="th" scope="row">
                                            {/* <Checkbox
                                                checked={row.required ? true : false}
                                                disabled={row.primaryKey || isView ? true : false}
                                                value={row.required ? true : false}
                                                onChange={(event) => {
                                                    handleKeyChange(event, row, "required");
                                                }}
                                            /> */}

                                            <Checkbox
                                                checked={row.required ? true : false}
                                                disabled={(row.primaryKey || !row.required) || isView ? true : false}
                                                value={row.required ? true : false}
                                                onChange={(event) => {
                                                    if (!row.primaryKey) {
                                                        handleKeyChange(event, row, "required");
                                                    }
                                                }}
                                            />

                                        </TableCell>
                                    )}
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.type}</TableCell>

                                    {!isView && (
                                        <TableCell align="left">
                                            {/* <Checkbox
                                                type="checkbox"
                                                // checked={row.checked || false}
                                                checked={index === 0 ? true : (row.checked || false)}
                                                onChange={() => handleCheckboxChange(index)}
                                                disabled={row.disabled || false}
                                            /> */}
                                            <Checkbox
                                                type="checkbox"
                                                checked={index === 0 ? true : (row.checked || false)}
                                                // onChange={(event) => {
                                                //     if (index !== 0) {
                                                //         handleCheckboxChange(index, event.target.checked);
                                                //     }
                                                // }}
                                                disabled
                                            />
                                            
                                            {/* <Checkbox
                                                checked={row.required ? true : false}
                                                disabled={(row.primaryKey || !row.required) || isView ? true : false}
                                                value={row.required ? true : false}
                                                onChange={(event) => {
                                                    if (!row.primaryKey) {
                                                        handleKeyChange(event, row, "required");
                                                    }
                                                }}
                                            /> */}

                                        </TableCell>
                                    )}


                                    {/* <TableCell align="left">
                                        <Checkbox
                                            checked={row.primaryKey ? false : true}
                                            disabled={isView ? true : false}
                                            value={row.primaryKey ? false : true}
                                            onChange={(event) => {
                                                handleKeyChange(event, row, "primaryKey");
                                            }}
                                            
                                            
                                        />
                                    </TableCell> */}
                                    <TableCell align="left">
                                        {/* <Checkbox
                                            checked={row.required ? true : false}
                                            disabled={row.primaryKey || isView ? true : false}
                                            value={row.required ? true : false}
                                            onChange={(event) => {
                                                handleKeyChange(event, row, "required");
                                            }}
                                        /> */}
                                        {/* <Checkbox
                                            checked={row.required ? true : false}
                                            disabled={(row.primaryKey || !row.required) || isView ? true : false}
                                            value={row.required ? true : false}
                                            onChange={(event) => {
                                                if (!row.primaryKey) {
                                                    handleKeyChange(event, row, "required");
                                                }
                                            }}
                                        /> */}

                                        <Checkbox
                                            checked={row.required ? true : false}
                                            disabled={(row.primaryKey || !row.required) || isView ? true : false}
                                            value={row.required ? true : false}
                                            onChange={(event) => {
                                                if (!row.primaryKey) {
                                                    handleKeyChange(event, row, "required");
                                                }
                                            }}
                                        />

                                    </TableCell>
                                    {formValues.type === "REFERENCE" && (
                                        <TableCell align="left">
                                            <Select
                                                id="type"
                                                name="type"
                                                value={getRefAttributeId(row)}
                                                onChange={(event) => {
                                                    handleReferenceAttribute(event, row);
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                style={{ marginBottom: "16px" }}
                                                disabled={isView}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Type
                                                </MenuItem>
                                                {referenceDimFields.map((item) => (
                                                    <MenuItem value={item._id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                    )}

                                    <TableCell align="left">
                                        {moment(row.createdOn).format("DD-MM-YYYY")}
                                    </TableCell>
                                    <TableCell align="left">
                                        {moment(row.changedOn).format("DD-MM-YYYY")}
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
                {!assignedAttributedData.length && (
                    <div className="centeredText">No attributes selected yet</div>
                )}
            </TableContainer>

            {isModalOpen && (
                <AttributesListModel
                    dimensionAttributes={dimensionAttributes}
                    setDimensionAttributes={setDimensionAttributes}
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    formValues={formValues}
                    modelEnterprise={modelEnterprise}
                />
            )}

            {isCreateUpdateModalOpen && (
                <CreateAttribute
                    isCreateUpdateModalOpen={isCreateUpdateModalOpen}
                    setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
                    isEdit={false}
                    data={[]}
                    selectedRows={[]}
                    dimensionAttributes={dimensionAttributes}
                    setDimensionAttributes={setDimensionAttributes}
                    dataValues={formValues}
                    modelEnterprise={modelEnterprise}
                />
            )}
            {renderModalContent()}
        </>
    );
};

export default TimeAttribute;
