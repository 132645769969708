import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from '../../../ThemeContext';
import { getAllPlanningObject, getModelListAsync, getPlanningObjects } from '../../../store/ApiSlice/dimensionSlice';
import { getEnterpriseList } from '../../../store/ApiSlice/enterpriseSlice';
import axios from 'axios';
import { API_BASE_URL } from '../../../helper/constants';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import MiniDrawer from '../../common/drawer/DrawerToggle';
import PlanningObjectMaterialList from './PlanningObjectMaterialList';
import DeletePlanningObject from '../DeletePlanningObject';
import CreatePlanningObject from './CreatePlanningObject';
import * as XLSX from "xlsx";
import Loader from '../../loader/Loader';

const PlanningObjectsList = () => {
    const userEnterprise = JSON.parse(localStorage.getItem("accessToken"))
    const { dimensionData } = useSelector((state) => state?.dimension);
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const { isDarkTheme } = useContext(ThemeContext);
    const [planningObjectID, setPlanningObjectID] = useState(null)
    const [isModalOpenDelete, setModalOpenDelete] = useState();
    const [formValues, setFormValues] = useState({
        enterpriseUID: userEnterprise?.data?.enterprise?._id,
        modelUID: "",
        modelName: ""
    })
    const [modalName, setModalName] = useState([]);
    const [selectEnterprise, setSelectEnterprise] = useState(true)
    const [createPlanningObjectModel, setCreatePlanningObjectModel] = useState(false);
    const [deletePlanningObject, setDeletePlanningObject] = useState(false);
    const [isEditPlanningObject, setIsEditPlanningObject] = useState(false);
    const [deleteMassObject, setDeleteMassObject] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getModelListAsync())
        dispatch(getEnterpriseList())
    }, [])

    const handleCloseModals = () => {
        setOpenModal(false);
    };

    const handleCreateUpdatePlanningObject = async (edit, id) => {
        setPlanningObjectID(id);
        setCreatePlanningObjectModel(true);
        setIsEditPlanningObject(edit);
    };

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = []
            if (formValues.enterpriseUID) {
                filteredData = response.data?.enterpriseList?.length > 0 && response.data?.enterpriseList?.filter(
                    (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (formValues.enterpriseUID) {
            getModelList();
        }
    }, [formValues.enterpriseUID]);

    const handleClick = async () => {
        if (formValues.enterpriseUID === "" || formValues.enterpriseUID === undefined) {
            toast.error("Please select enterprise.")
            return
        } else if (formValues.modelUID === "" || formValues.modelUID === undefined) {
            toast.error("Please select model.")
            return
        } else if (formValues.dimensionUID === "" || formValues.dimensionUID === undefined) {
            toast.error("Please select base level.")
            return
        } else {
            const payload = {
                enterpriseUID: formValues.enterpriseUID,
                modelUID: formValues?.modelUID,
                dimensionUID: formValues?.dimensionUID
            }
            const result = await dispatch(getAllPlanningObject({ payload }))
            if (result?.meta?.requestStatus === "fulfilled") {
                setSelectEnterprise(false)
                dispatch(getPlanningObjects({ page: 1, limit: 10, id: formValues?.dimensionUID }));
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
                setSelectEnterprise(true);
            }
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileInput = document.getElementById("file-input");
        const file1 = fileInput.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
        };
    };

    const handleUploadFile = async (selectedData) => {
        if (selectedData !== null) {
            try {
                setLoader(true)
                const fileInput = document.getElementById("file-input");
                const file = fileInput.files[0];
                // Read the content of the Excel file
                const reader = new FileReader();
                reader.onload = function (e) {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    const userId = localStorage.getItem("accessToken");
                    const config = {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
                        },
                    };
                    const updatedData = {
                        enterpriseUID: formValues?.enterpriseUID,
                        modelUID: formValues.modelUID,
                        dimensionUID: formValues?.dimensionUID,
                        documentsArray: jsonData,
                    };

                    axios
                        .post(
                            `${API_BASE_URL}/planning-object/upload`,
                            updatedData,
                            config
                        )
                        .then((data) => {
                            if (data.status) {
                                if (data?.data?.data?.erroredItems?.length > 0) {
                                    // setErrorMessage(data?.data?.data)
                                    downloadErrorExcel(data?.data?.data.erroredItems);
                                    setOpenModal(false)
                                    setLoader(false)
                                    // setOpenErrorModal(true)
                                } else {
                                    // toast.success("Successfully data uploaded");
                                    handleCloseModals();
                                    setLoader(false)
                                }
                            }
                            // Handle successful upload
                        })
                        .catch((error) => {
                            setLoader(false)

                            if (
                                error?.response &&
                                error?.response?.data &&
                                error?.response?.data?.data
                            ) {
                                setLoader(false)
                                toast.error(error.response.data.data);
                            } else {
                                setLoader(false)
                                toast.error("Failed to upload data. Please try again later.");
                            }
                            handleCloseModals();
                        });
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                setLoader(false)
            }
        }
    };
    const downloadErrorExcel = (erroredItems) => {
        const transformedData = erroredItems.map(item => {
            const combined = { ...item.item };
            for (const [key, value] of Object.entries(item.errors)) {
                combined[`${key}`] = value;
            }
            return combined;
        });
        const worksheet = XLSX.utils.json_to_sheet(transformedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Errors");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'errored_items.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    return (
        <>
            <Dialog
                open={selectEnterprise}
                onClose={() => setSelectEnterprise(false)}
                fullWidth
                maxWidth={"md"}
            >
                <DialogContent sx={{ paddingTop: "10px !important", marginTop: "30px" }}>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Enterprise Name"
                            name="enterpriseUID"
                            options={
                                enterpriseData?.length > 0
                                  ? enterpriseData
                                  : userEnterprise?.data?.enterprise
                                    ? [userEnterprise.data.enterprise]
                                    : [] // Always an array
                              }
                            getOptionLabel={(option) => option.enterpriseName}
                            // value={
                            //     formValues?.enterpriseUID
                            //         ? enterpriseData.find(
                            //             (option) =>
                            //                 option._id === formValues.enterpriseUID
                            //         ) || null
                            //         : null
                            // }
                            value={
                                enterpriseData?.find((option) => option?._id === formValues.enterpriseUID) ||
                                (userEnterprise?.data?.enterprise?._id === formValues.enterpriseUID
                                  ? userEnterprise?.data?.enterprise
                                  : null)
                              }
                            onChange={(e, newValue) => {

                                setFormValues({
                                    ...formValues,
                                    ["enterpriseName"]: newValue?.enterpriseName,
                                    ["enterpriseUID"]: newValue?._id,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Enterprise Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Model Name"
                            name="modelUID"
                            options={modalName}
                            getOptionLabel={(option) => option.modelName}
                            value={
                                formValues?.modelUID
                                    ? modalName.find(
                                        (option) =>
                                            option.modelUID === formValues.modelUID
                                    ) || null
                                    : null
                            }
                            onChange={(e, newValue) => {
                                setFormValues({
                                    ...formValues,
                                    ["modelName"]: newValue?.modelName,
                                    ["modelUID"]: newValue?._id,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Model Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Base Level"
                            name="dimensionUID"
                            options={dimensionData?.length > 0 ?
                                dimensionData?.filter((item) => item?.enterpriseUID?._id === formValues?.enterpriseUID)
                                    ?.filter((item) => item?.entermodelUID?.modelUID === formValues?.modelUID)
                                    ?.filter((item) => item?.type === "LEVEL") : []}
                            getOptionLabel={(option) => option.name}
                            value={
                                formValues?.dimensionUID
                                    ? dimensionData.find(
                                        (option) =>
                                            option._id === formValues.dimensionUID
                                    ) || null
                                    : null
                            }
                            onChange={(e, newValue) => {
                                setFormValues({
                                    ...formValues,
                                    ["dimensionName"]: newValue?.name,
                                    ["dimensionUID"]: newValue?._id,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Base Level"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectEnterprise(false)} sx={{textTransform: "capitalize"}}>Cancel</Button>
                    <Button onClick={handleClick} color="primary" sx={{textTransform: "capitalize"}}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Box sx={{ display: "flex" }}>
                <MiniDrawer />
                <Box component="main" sx={{
                    flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                    background: isDarkTheme ? "whitesmoke" : "white",
                }}> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: "20px",
                        }}
                    >
                        <h3 className="heading">Planning Objects</h3>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <Tooltip title="Add Planning Object">
                                <Button
                                    onClick={() => {
                                        setCreatePlanningObjectModel(true);
                                    }}
                                    className="awesome-button"
                                    sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                >
                                    +
                                </Button>
                            </Tooltip>
                            <Button variant='contained' sx={{ textTransform: "capitalize" }}
                                onClick={() => {
                                    if (!isModalOpenDelete?.length > 0) {
                                        toast.error("Please select planning objects.")
                                        return
                                    } else {
                                        setDeleteMassObject(true)
                                    }
                                }}
                            >
                                Delete Selected Objects
                            </Button>
                            <Button variant='contained' sx={{ textTransform: "capitalize" }}>
                                Download Template
                            </Button>
                            <Button variant='contained' sx={{ textTransform: "capitalize" }}>
                                Download Planning Objects
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ textTransform: "capitalize" }}
                                onClick={() => {
                                    setOpenModal(true)
                                }}
                            >
                                Upload Planning Objects
                            </Button>
                        </Box>
                        {deletePlanningObject && (
                            <DeletePlanningObject
                                planningObjectID={planningObjectID}
                                setPlanningObjectID={setPlanningObjectID}
                                setDeletePlanningObject={setDeletePlanningObject}
                                formValues={formValues}
                            // getDimensions={getDimensions}
                            />
                        )}
                    </div>
                    <PlanningObjectMaterialList
                        setModalOpenDelete={setModalOpenDelete}
                        formValues={formValues}
                        setPlanningObjectID={setPlanningObjectID}
                        setCreatePlanningObjectModel={setCreatePlanningObjectModel}
                        handleCreateUpdatePlanningObject={handleCreateUpdatePlanningObject}
                        setDeletePlanningObject={setDeletePlanningObject}
                        setDeleteMassObject={setDeleteMassObject}
                        deleteMassObject={deleteMassObject}
                    />
                {/* </Box>
            </Box> */}
            {createPlanningObjectModel && (
                <CreatePlanningObject
                    formValues={formValues}
                    planningObjectID={planningObjectID}
                    createPlanningObjectModel={createPlanningObjectModel}
                    setCreatePlanningObjectModel={setCreatePlanningObjectModel}
                    isEditPlanningObject={isEditPlanningObject}
                    setIsEditPlanningObject={setIsEditPlanningObject}
                />
            )}
            <Dialog
                open={openModal}
                onClose={handleCloseModals}
                sx={{ padding: "20px" }}
            >
                <DialogTitle>Upload File</DialogTitle>
                <DialogContent>
                    <input
                        id="file-input"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                </DialogContent>
                <DialogActions sx={{ gap: "10px" }}>
                    <Button
                        onClick={handleCloseModals}
                        sx={{
                            color: "#8280bd",
                            margin: "0 4px",
                            border: "1px solid #8280bd",
                            textTransform: "capitalize"
                        }}
                    >
                        Cancel
                    </Button>
                    {loader ? <Loader /> : (
                        <Button
                            onClick={handleUploadFile}
                            variant="contained"
                            autoFocus
                            className="awesome-button"
                            sx={{textTransform: "capitalize"}}
                        >
                            Upload
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PlanningObjectsList
