import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AttributeList from "./components/attributes/AttributeList";
import { ToastContainer } from "react-toastify";
import DimensionList from "./components/dimensions/DimensionList";
import Login from "./components/login/Login";
import RegisterForm from "./components/signUp/signUp";
import Home from "./pages/home";
import PrivateRoute from "./privateRoute/privateRoute";
import "./App.css";
import EnterpriseList from "./components/enterprise/enterpriseList";
import NewEnterprise from "./components/enterprise/CreateEnterprise";
import EntermodelList from "./components/entermodel/entermodelList";
import UserList from "./components/users/userList";
import MeasureList from "./components/measure/measureList";
import CanvasList from "./components/canvas/CanvasList";
import PageName from "./components/page/PageName";
import MyGridComponent from "./components/page/MyGridComponent";
import ProfileDetail from "./components/profile/ProfileDetail";
import ProcessList from "./components/process/ProcessList";
import FilterList from "./components/filter/FilterList";
import MeasureDataProfileList from "./components/measuredataprofile/MeasureDataProfileList";
import ChartTablauDemo from "./ChartTablauDemo";
import RoleList from "./components/role/RoleList";
import SuperAdministratorList from "./components/systemAdministrator/SuperAdministratorList";
import MultiEnterpriseUserList from "./components/multi_Enterprise/MultiEnterpriseUserList";
import Modeltab from "./components/modeltab/Modeltab";
import UserAccount from "./components/users/UserAccount";
import ForgotPassword from "./components/login/ForgotPassword";
import ChangePassword from "./components/login/ChangePassword";

const App = () => {

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/demo"
          element={
            <PrivateRoute>
              <ChartTablauDemo />
            </PrivateRoute>
          }
        />
        <Route
          path="/dimensions"
          element={
            <PrivateRoute>
              <DimensionList />
            </PrivateRoute>
          }
        />
        <Route
          path="/attributes"
          element={
            <PrivateRoute>
              <AttributeList />
            </PrivateRoute>
          }
        />
        <Route
          path="/enterprise"
          element={
            <PrivateRoute>
              <EnterpriseList />
            </PrivateRoute>
          }
        />
        <Route
          path="/model"
          element={
            <PrivateRoute>
              <EntermodelList />
            </PrivateRoute>
          }
        />
        <Route
          path="/CreateMeasure"
          element={
            <PrivateRoute>
              <MeasureList />
            </PrivateRoute>
          }
        />
        <Route
          path="/Canvas"
          element={
            <PrivateRoute>
              <CanvasList />
            </PrivateRoute>
          }
        />
        <Route
          path="/Canvas/:id"
          element={
            <PrivateRoute>
              <PageName />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-account"
          element={
            <PrivateRoute>
              <UserAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/role"
          element={
            <PrivateRoute>
              <RoleList />
            </PrivateRoute>
          }
        />
        <Route
          path="/multi-enterprise-user"
          element={
            <PrivateRoute>
              <MultiEnterpriseUserList />
            </PrivateRoute>
          }
        />
        <Route
          path="/super-addministrator"
          element={
            <PrivateRoute>
              <SuperAdministratorList />
            </PrivateRoute>
          }
        />
        <Route
          path="/enterprise/new"
          element={
            <PrivateRoute>
              <NewEnterprise />
            </PrivateRoute>
          }
        />
        <Route
          path="/grid-table"
          element={
            <PrivateRoute>
              <MyGridComponent />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfileDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/MeasureDataProfile"
          element={
            <PrivateRoute>
              <MeasureDataProfileList />
            </PrivateRoute>
          }
        />
        <Route
          path="/process"
          element={
            <PrivateRoute>
              <ProcessList />
            </PrivateRoute>
          }
        />
        <Route
          path="/filter"
          element={
            <PrivateRoute>
              <FilterList />
            </PrivateRoute>
          }
        />
        <Route
          path="/widget/:id"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="/login" exact element={<Login />} />
        <Route path="/signup" exact element={<RegisterForm />} />
        <Route path="/change-password" exact element={<ChangePassword />} />
        <Route path="/reset-password" exact element={<ForgotPassword />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Router>
  );
};

export default App;
