import React, { useEffect, useRef } from 'react';

const loadTableauScript = () => {
  return new Promise((resolve, reject) => {
    if (window.tableau) {
      resolve(); // Tableau API is already loaded
    } else {
      const script = document.createElement('script');
      script.src = 'https://public.tableau.com/javascripts/api/tableau-2.min.js';
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    }
  });
};

const TableauEmbed = ({ url }) => {
  const vizRef = useRef(null);

  useEffect(() => {
    loadTableauScript().then(() => {
      const { tableau } = window;
      const vizUrl = url;
      const options = {
        hideTabs: true,
        width: '100%',
        height: '100%',
        onFirstInteractive: () => {
          console.log('Tableau visualization is interactive.');
        },
      };

      const viz = new tableau.Viz(vizRef.current, vizUrl, options);

      return () => {
        viz.dispose(); // Clean up the viz on unmount
      };
    }).catch((error) => {
      console.error('Failed to load Tableau script:', error);
    });
  }, [url]);

  return <div ref={vizRef} style={{ width: '100%', height: '600px' }}></div>;
};

export default TableauEmbed;
