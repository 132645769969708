import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    styled,
    Switch,
    Tab,
    TextField,
    Toolbar,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../helper/constants";
import { useDispatch } from "react-redux";
import {
    createProfile,
    getProfileData,
    getProfileMeasure,
    getProfilePrimaryTimeDimension,
} from "../../store/ApiSlice/profileSlice";
import { toast } from "react-toastify";
import { MdMoreVert } from "react-icons/md";
import CustomButton from "../common/CustomButton";
import profileFieldData from "../../profileFieldData.json"
import { CommonNumberInput } from "../commonComponent/input/CommonNumberInput";
import { CommonAutoComplete } from "../commonComponent/commonSelect/CommonSelect";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import OverviewTab from "./OverviewTab";
import ExtrapolativeTab from "./ExtrapolativeTab";
import PredictiveTab from "./PredictiveTab";
import PrescriptiveTab from "./PrescriptiveTab";
import AdministrativeTab from "./AdministrativeTab";
import AiEnsembleTab from "./AiEnsembleTab";
import { error } from "highcharts";

const CreateProfile = ({
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows,
}) => {
    const { profileData, profileMeasure, timeData } = useSelector(
        (state) => state.profile
    );
    const [value, setValue] = useState('1');
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const [formValues, setFormValues] = useState({
        initialSeedType: "Random",
        hierarchicalForecastingAttributes: [],
        timeBucketAggregationPeriods: []
    });
    const [modalName, setModalName] = useState([]);
    const [forcastData, setForcastData] = useState([
        {
            humanforecasts: "",
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorMeasureEl, setAnchorMeasureEl] = useState(null);
    const [anchorOptimizationMeasureEl, setAnchorOptimizationMeasureEl] = useState(null);
    const [anchorBasicKPIl, setAnchorBasicKPIEl] = useState(null);
    const [anchorExtrapolativeBasicKPIEl, setAnchorExtrapolativeBasicEl] = useState(null);
    const [anchorPredictiveBasicKPIEl, setAnchorPredictiveBasicEl] = useState(null);
    const [anchorPredictiveAdditionalKPIEl, setAnchorPredictiveAdditionalKPIEl] = useState(null);
    const [anchorConstraintsMeasureEl, setAnchorConstraintsMeasureEl] = useState(null);
    const [anchorAiEnsembleEl, setAnchorAiEnsembleEl] = useState(null);
    const [dataIndex, setDataIndex] = useState();
    const [driverBasedMeasure, setDriverBasedMeasure] = useState([{
        measure: "",
        categoricalDriver: false,
        importanceMeasure: "",
        contributionMeasure: "",
        offset: 0,
        uncertaintyDistribution: "",
        lowerBoundMeasure: "",
        upperBoundMeasure: "",
        stepSizeMeasure: ""
    }])
    const [optimizationMeasure, setOptimizationMeasure] = useState([])
    const [basicKPI, setBasicKPI] = useState([{
        StatisticalForecastModels: "",
    }])

    const [predictiveBasicKPI, setPredictiveBasicKPI] = useState([{
        predictiveKPI: "",
        bestPredictiveKPI: "",
        bestPredictiveforecastMovingAverageKPI: "",
        bestPredictiveforecastRelativeKPI: "",
        bestPredictiveforecastKPIRank: "",
        bestPredictiveforecastMirrorKPIRank: "",
        bestPredictiveforecastKPIFVA: "",
    }])

    const [aiEnsembleBasicKPI, setAiEnsembleBasicKPI] = useState([{
        aiEnsembleKPI: "",
        bestAiEnsembleKPI: "",
        bestAiEnsembleMovingAverageKPI: "",
        bestAiEnsembleRelativeKPI: "",
        bestAiEnsembleKPIRank: "",
        bestAiEnsembleMirrorKPIRank: "",
        bestAiEnsembleKPIFVA: "",
        weightParameterMeasure: ""
    }])

    const [predictiveAdditionalKPI, setPredictiveAdditionalKPI] = useState([])
    const [prescriptiveconstraintsMeasure, setPrescriptiveConstraintsMeasure] = useState([{
        constraintsMeasure: "",
    }])
    const [prescriptiveOptimal, setPrescriptiveOptimal] = useState([
        {
            prescriptiveOptimalForecastDecisions: ""
        }
    ])
    const [anchorPrescriptiveEnsembleEl, setAnchorPrescriptiveEnsembleEl] = useState(null);
    const deleteForCast = Boolean(anchorEl);
    const deleteDemandDriven = Boolean(anchorMeasureEl);
    const deleteOptimization = Boolean(anchorOptimizationMeasureEl);
    const deletebasicKPI = Boolean(anchorBasicKPIl);
    const deleteExtrapolativeBasicKPI = Boolean(anchorExtrapolativeBasicKPIEl);
    const deletePredictiveBasicKPI = Boolean(anchorPredictiveBasicKPIEl);
    const deletePredictiveAdditionalKPI = Boolean(anchorPredictiveAdditionalKPIEl);
    const deleteConstraintsMeasure = Boolean(anchorConstraintsMeasureEl);
    const deleteAiEnsemble = Boolean(anchorAiEnsembleEl);
    const deletePrescriptiveEnsemble = Boolean(anchorPrescriptiveEnsembleEl);
    const dispatch = useDispatch();

    const selectedProfile = profileData?.find(
        (item) => item?._id === selectedRows
    );

    useEffect(() => {
        if (selectedProfile && isEdit) {
            setFormValues({
                name: selectedProfile?.name,
                notes: selectedProfile?.notes,
                enterpriseName: selectedProfile?.enterpriseUID?.enterpriseName,
                enterpriseUID: selectedProfile?.enterpriseUID?._id,
                modelName: selectedProfile?.modelUID?.modelName,
                modelUID: selectedProfile?.modelUID?.modalUID,
                statisticalForecasting: selectedProfile?.statisticalForecasting,
                hierarchicalForecasting: selectedProfile?.hierarchicalForecasting,
                timeBucketAggregation: selectedProfile?.timeBucketAggregation,
                driverBasedForecasting: selectedProfile?.driverBasedForecasting,
                aiBlendForecasting: selectedProfile?.aiBlendForecasting,
                forecastabilityAnalysis: selectedProfile?.forecastabilityAnalysis,
                prescriptiveOptimalForecasting: selectedProfile?.prescriptiveOptimalForecasting,
                outlierCorrection: selectedProfile?.outlierCorrection,
                humanForecast: selectedProfile?.humanForecast,
                type: selectedProfile?.type,
                timePeriod: selectedProfile?.periodicity,
                frequency: selectedProfile?.frequency,
                historyHorizon: selectedProfile?.historyHorizon,
                validationHorizon: selectedProfile?.validationHorizon,
                defaultValue: selectedProfile?.defaultValue !== null && parseFloat(selectedProfile?.defaultValue?.replace('%', '')),
                movingAverageHorizon: selectedProfile?.movingAverageHorizon,
                historyBaseLevel: selectedProfile?.historyBaseLevel?._id,
                forecastBaseLevel: selectedProfile?.forecastBaseLevel?._id,
                naiveForecastMeasureUID: selectedProfile?.naiveForecastMeasureUID?._id,
                forecastHorizon: selectedProfile?.forecastHorizon,
                ...(selectedProfile?.hierarchicalForecastingAttributes?.length > 0 && ({
                    hierarchicalForecastingAttributes: selectedProfile?.hierarchicalForecastingAttributes?.map((item) => item?._id)
                })),
                ...(selectedProfile?.timeBucketAggregationPeriods?.length > 0 && ({
                    timeBucketAggregationPeriods: selectedProfile?.timeBucketAggregationPeriods
                })),
                baseHistoryMeasureUID: selectedProfile?.baseHistoryMeasureUID?._id,
                ...(selectedProfile?.outlierCorrection && ({
                    correctedHistoryMeasureUID:
                        selectedProfile?.correctedHistoryMeasureUID?._id,
                })),
                errorKPI: selectedProfile?.errorKPI,
                biasKPI: selectedProfile?.biasKPI,
                // basicKPIs
                isCheckedbasicKPIError: selectedProfile?.basicKPIs?.error?.isActive,
                isCheckedbasicKPIBias: selectedProfile?.basicKPIs?.bias?.isActive,
                isCheckedbasicKPIChurn: selectedProfile?.basicKPIs?.churn?.isActive,
                ...(selectedProfile?.basicKPIs?.error?.isActive && ({
                    errorMethod: selectedProfile?.basicKPIs?.error?.errorMethod,
                    ...(selectedProfile?.basicKPIs?.error?.errorMethod === "wMAPE" && ({
                        weightErrorMeasureUID: selectedProfile?.basicKPIs?.error?.weightMeasureUID?._id,
                    })),
                    defaultErrorValue: Number(selectedProfile?.basicKPIs?.error?.defaultValue),
                    movingAverageErrorHorizon: Number(selectedProfile?.basicKPIs?.error?.movingAverageHorizon),
                    naiveForecastErrorMeasureUID: selectedProfile?.basicKPIs?.error?.naiveForecastErrorMeasureUID?._id,
                    naiveForecastMovingAverageErrorMeasureUID: selectedProfile?.basicKPIs.error.naiveForecastMovingAverageErrorMeasureUID?._id,
                    ...(selectedProfile?.forecastabilityAnalysis && ({
                        ...(selectedProfile?.basicKPIs?.error?.forecastabilityBandUpperBoundMeasureUID && ({
                            forecastabilityBandErrorUpperBoundMeasureUID: selectedProfile?.basicKPIs?.error?.forecastabilityBandUpperBoundMeasureUID?._id,
                        })),
                        ...(selectedProfile?.basicKPIs?.error?.forecastabilityBandLowerBoundMeasureUID && ({
                            forecastabilityBandErrorLowerBoundMeasureUID: selectedProfile?.basicKPIs?.error?.forecastabilityBandLowerBoundMeasureUID?._id,
                        }))
                    })),
                })),
                ...(selectedProfile?.basicKPIs?.bias?.isActive && ({
                    biasMethod: selectedProfile?.basicKPIs?.bias?.biasMethod,
                    ...(selectedProfile?.basicKPIs?.bias?.biasMethod === "wMPE" && ({
                        weightBiasMeasureUID: selectedProfile?.basicKPIs?.bias?.weightMeasureUID?._id,
                    })),
                    defaultBiasValue: Number(selectedProfile?.basicKPIs?.bias?.defaultValue),
                    movingAverageBiasHorizon: Number(selectedProfile?.basicKPIs?.bias?.movingAverageHorizon),
                    naiveForecastBiasMeasureUID: selectedProfile?.basicKPIs?.bias?.naiveForecastBiasMeasureUID?._id,
                    naiveForecastMovingAverageBiasMeasureUID: selectedProfile?.basicKPIs?.bias?.naiveForecastMovingAverageBiasMeasureUID?._id,
                    ...(selectedProfile?.forecastabilityAnalysis && ({
                        ...(selectedProfile?.basicKPIs?.bias?.forecastabilityBandUpperBoundMeasureUID && ({
                            forecastabilityBandBiasUpperBoundMeasureUID: selectedProfile?.basicKPIs?.bias?.forecastabilityBandUpperBoundMeasureUID?._id,

                        })),
                        ...(selectedProfile?.basicKPIs?.bias?.forecastabilityBandLowerBoundMeasureUID && ({
                            forecastabilityBandBiasLowerBoundMeasureUID: selectedProfile?.basicKPIs?.bias?.forecastabilityBandLowerBoundMeasureUID?._id,
                        }))
                    })),
                })),
                ...(selectedProfile?.basicKPIs?.churn?.isActive && ({
                    churnMethod: selectedProfile?.basicKPIs?.churn?.churnMethod,
                    ...(selectedProfile?.basicKPIs?.churn?.churnMethod === "wMAPC" && ({
                        weightChurnMeasureUID: selectedProfile?.basicKPIs?.churn?.weightMeasureUID?._id,
                    })),
                    defaultChurnValue: Number(selectedProfile?.basicKPIs?.churn?.defaultValue),
                    movingAverageChurnHorizon: Number(selectedProfile?.basicKPIs?.churn?.movingAverageHorizon),
                    naiveForecastChurnMeasureUID: selectedProfile?.basicKPIs?.churn?.naiveForecastChurnMeasureUID?._id,
                    naiveForecastMovingAverageChurnMeasureUID: selectedProfile?.basicKPIs?.churn?.naiveForecastMovingAverageChurnMeasureUID?._id,
                    ...(selectedProfile?.forecastabilityAnalysis && ({
                        ...(selectedProfile?.basicKPIs?.churn?.forecastabilityBandUpperBoundMeasureUID && ({
                            forecastabilityBandChurnUpperBoundMeasureUID: selectedProfile?.basicKPIs?.churn?.forecastabilityBandUpperBoundMeasureUID?._id,

                        })),
                        ...(selectedProfile?.basicKPIs?.churn?.forecastabilityBandLowerBoundMeasureUID && ({
                            forecastabilityBandChurnLowerBoundMeasureUID: selectedProfile?.basicKPIs?.churn?.forecastabilityBandLowerBoundMeasureUID?._id,
                        }))
                    })),
                })),
                // statisticalForecasting
                ...(selectedProfile?.statisticalForecasting && ({
                    ...(selectedProfile?.statisticalForecastKPIs.error.isActive && ({
                        statisticalForecastErrorMeasureUID: selectedProfile?.statisticalForecastKPIs?.error?.statisticalForecastErrorMeasureUID?._id,
                        statisticalForecastMovingAverageErrorMeasureUID: selectedProfile?.statisticalForecastKPIs.error?.statisticalForecastMovingAverageErrorMeasureUID?._id,
                        statisticalForecastRelativeErrorMeasureUID: selectedProfile?.statisticalForecastKPIs?.error?.statisticalForecastRelativeErrorMeasureUID?._id,
                        statisticalForecastErrorRankMeasureUID: selectedProfile?.statisticalForecastKPIs?.error?.statisticalForecastErrorRankMeasureUID?._id,
                        statisticalForecastAccuracyRankMeasureUID: selectedProfile?.statisticalForecastKPIs?.error?.statisticalForecastAccuracyRankMeasureUID?._id,
                        statisticalForecastErrorFVAMeasureUID: selectedProfile?.statisticalForecastKPIs?.error?.statisticalForecastErrorFVAMeasureUID?._id

                    })),
                    ...(selectedProfile?.statisticalForecastKPIs?.bias?.isActive && ({
                        statisticalForecastBiasMeasureUID: selectedProfile?.statisticalForecastKPIs?.bias?.statisticalForecastBiasMeasureUID?._id,
                        statisticalForecastMovingAverageBiasMeasureUID: selectedProfile?.statisticalForecastKPIs?.bias?.statisticalForecastMovingAverageBiasMeasureUID?._id,
                        statisticalForecastRelativeBiasMeasureUID: selectedProfile?.statisticalForecastKPIs?.bias?.statisticalForecastRelativeBiasMeasureUID?._id,
                        statisticalForecastBiasRankMeasureUID: selectedProfile?.statisticalForecastKPIs?.bias?.statisticalForecastBiasRankMeasureUID?._id,
                        statisticalForecastNeutralityRankMeasureUID: selectedProfile?.statisticalForecastKPIs?.bias?.statisticalForecastNeutralityRankMeasureUID?._id,
                        statisticalForecastBiasFVAMeasureUID: selectedProfile?.statisticalForecastKPIs?.bias?.statisticalForecastBiasFVAMeasureUID?._id
                    })),
                    ...(selectedProfile?.statisticalForecastKPIs?.churn?.isActive && ({
                        statisticalForecastChurnMeasureUID: selectedProfile?.statisticalForecastKPIs?.churn?.statisticalForecastChurnMeasureUID?._id,
                        statisticalForecastMovingAverageChurnMeasureUID: selectedProfile?.statisticalForecastKPIs?.churn?.statisticalForecastMovingAverageChurnMeasureUID?._id,
                        statisticalForecastRelativeChurnMeasureUID: selectedProfile?.statisticalForecastKPIs?.churn?.statisticalForecastRelativeChurnMeasureUID?._id,
                        statisticalForecastChurnRankMeasureUID: selectedProfile?.statisticalForecastKPIs?.churn?.statisticalForecastChurnRankMeasureUID?._id,
                        statisticalForecastStabilityRankMeasureUID: selectedProfile?.statisticalForecastKPIs?.churn?.statisticalForecastStabilityRankMeasureUID?._id,
                        statisticalForecastChurnFVAMeasureUID: selectedProfile?.statisticalForecastKPIs?.churn?.statisticalForecastChurnFVAMeasureUID?._id
                    })),
                })),
                statisticalForecastMeasureUID: selectedProfile?.statisticalForecastBasicSettings?.statisticalForecastMeasureUID?._id,
                statisticalForecastDiscrepancyRankMeasureUID: selectedProfile?.statisticalForecastBasicSettings?.statisticalForecastDiscrepancyRankMeasureUID?._id,
                statisticalForecastQualityRankMeasureUID: selectedProfile?.statisticalForecastBasicSettings?.statisticalForecastQualityRankMeasureUID?._id,

                //driver based 
                ...(selectedProfile?.driverBasedForecasting && ({
                    driverBasedForecastMeasureUID: selectedProfile?.driverBasedForecastBasicSettings.driverBasedForecastMeasureUID._id,
                    baselineDemandMeasureUID: selectedProfile?.driverBasedForecastBasicSettings?.baselineDemandMeasureUID?._id,
                    driverBasedForecastOverallDiscrepancyRankMeasureUID: selectedProfile?.driverBasedForecastBasicSettings?.driverBasedForecastOverallDiscrepancyRankMeasureUID?._id,
                    driverBasedForecastOverallQualityRankMeasureUID: selectedProfile?.driverBasedForecastBasicSettings?.driverBasedForecastOverallQualityRankMeasureUID?._id,
                    driverSensitivityAnalysis: selectedProfile?.driverBasedForecastBasicSettings?.driverSensitivityAnalysis,
                    automatedWhatIfSimulation: selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation,
                    ...(selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && ({
                        noOfIterations: Number(selectedProfile?.driverBasedForecastBasicSettings?.noOfIterations),
                        initialSeedType: selectedProfile?.driverBasedForecastBasicSettings?.initialSeedType,
                        ...(selectedProfile?.driverBasedForecastBasicSettings?.initialSeedType === "Fixed" && ({
                            initialSeedValue: Number(selectedProfile?.driverBasedForecastBasicSettings?.initialSeedValue)
                        }))
                    }))
                })),
                ...(selectedProfile?.driverBasedForecasting && ({
                    ...(selectedProfile?.driverBasedForecastBasicKPIs?.error?.isActive && ({
                        driverBasedForecastErrorMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.error?.driverBasedForecastErrorMeasureUID?._id,
                        driverBasedForecastMovingAverageErrorMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs.error?.driverBasedForecastMovingAverageErrorMeasureUID?._id,
                        driverBasedForecastRelativeErrorMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.error?.driverBasedForecastRelativeErrorMeasureUID?._id,
                        driverBasedForecastErrorRankMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.error?.driverBasedForecastErrorRankMeasureUID?._id,
                        driverBasedForecastAccuracyRankMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.error?.driverBasedForecastAccuracyRankMeasureUID?._id,
                        driverBasedForecastErrorFVAMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.error?.driverBasedForecastErrorFVAMeasureUID?._id

                    })),
                    ...(selectedProfile?.driverBasedForecastBasicKPIs?.bias?.isActive && ({
                        driverBasedForecastBiasMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.bias?.driverBasedForecastBiasMeasureUID?._id,
                        driverBasedForecastMovingAverageBiasMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.bias?.driverBasedForecastMovingAverageBiasMeasureUID?._id,
                        driverBasedForecastRelativeBiasMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.bias?.driverBasedForecastRelativeBiasMeasureUID?._id,
                        driverBasedForecastBiasRankMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.bias?.driverBasedForecastBiasRankMeasureUID?._id,
                        driverBasedForecastNeutralityRankMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.bias?.driverBasedForecastNeutralityRankMeasureUID?._id,
                        driverBasedForecastBiasFVAMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.bias?.driverBasedForecastBiasFVAMeasureUID?._id
                    })),
                    ...(selectedProfile?.driverBasedForecastBasicKPIs?.churn?.isActive && ({
                        driverBasedForecastChurnMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.churn?.driverBasedForecastChurnMeasureUID?._id,
                        driverBasedForecastMovingAverageChurnMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.churn?.driverBasedForecastMovingAverageChurnMeasureUID?._id,
                        driverBasedForecastRelativeChurnMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.churn?.driverBasedForecastRelativeChurnMeasureUID?._id,
                        driverBasedForecastChurnRankMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.churn?.driverBasedForecastChurnRankMeasureUID?._id,
                        driverBasedForecastStabilityRankMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.churn?.driverBasedForecastStabilityRankMeasureUID?._id,
                        driverBasedForecastChurnFVAMeasureUID: selectedProfile?.driverBasedForecastBasicKPIs?.churn?.driverBasedForecastChurnFVAMeasureUID?._id
                    })),
                })),
                //Prescriptive 
                ...(selectedProfile?.prescriptiveOptimalForecasting && ({
                    prescriptiveOptimalForecastMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicSettings?.prescriptiveOptimalForecastMeasureUID?._id,
                    prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicSettings?.prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID?._id,
                    prescriptiveOptimalForecastOverallQualityRankMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicSettings?.prescriptiveOptimalForecastOverallQualityRankMeasureUID?._id,
                    maxRuntime: Number(selectedProfile?.prescriptiveOptimalForecastBasicSettings?.maxRuntime),
                    explainResults: selectedProfile?.prescriptiveOptimalForecastBasicSettings.explainResults,
                })),
                ...(selectedProfile?.prescriptiveOptimalForecasting && ({
                    ...(selectedProfile?.prescriptiveOptimalForecastBasicKPIs.error.isActive && ({
                        prescriptiveOptimalForecastErrorMeasure: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.error?.prescriptiveOptimalForecastErrorMeasureUID?._id,
                        prescriptiveOptimalForecastMovingAverageErrorMeasure: selectedProfile?.prescriptiveOptimalForecastBasicKPIs.error?.prescriptiveOptimalForecastMovingAverageErrorMeasureUID?._id,
                        prescriptiveOptimalForecastRelativeErrorMeasure: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.error?.prescriptiveOptimalForecastRelativeErrorMeasureUID?._id,
                        prescriptiveOptimalForecastErrorRankMeasure: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.error?.prescriptiveOptimalForecastErrorRankMeasureUID?._id,
                        prescriptiveOptimalForecastAccuracyRankMeasure: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.error?.prescriptiveOptimalForecastAccuracyRankMeasureUID?._id,
                        prescriptiveOptimalForecastErrorFVAMeasure: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.error?.prescriptiveOptimalForecastErrorFVAMeasureUID?._id

                    })),
                    ...(selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.isActive && ({
                        prescriptiveOptimalForecastBiasMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.prescriptiveOptimalForecastBiasMeasureUID?._id,
                        prescriptiveOptimalForecastMovingAverageBiasMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.prescriptiveOptimalForecastMovingAverageBiasMeasureUID?._id,
                        prescriptiveOptimalForecastRelativeBiasMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.prescriptiveOptimalForecastRelativeBiasMeasureUID?._id,
                        prescriptiveOptimalForecastBiasRankMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.prescriptiveOptimalForecastBiasRankMeasureUID?._id,
                        prescriptiveOptimalForecastNeutralityRankMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.prescriptiveOptimalForecastNeutralityRankMeasureUID?._id,
                        prescriptiveOptimalForecastBiasFVAMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.bias?.prescriptiveOptimalForecastBiasFVAMeasureUID?._id
                    })),
                    ...(selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.isActive && ({
                        prescriptiveOptimalForecastChurnMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.prescriptiveOptimalForecastChurnMeasureUID?._id,
                        prescriptiveOptimalForecastMovingAverageChurnMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.prescriptiveOptimalForecastMovingAverageChurnMeasureUID?._id,
                        prescriptiveOptimalForecastRelativeChurnMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.prescriptiveOptimalForecastRelativeChurnMeasureUID?._id,
                        prescriptiveOptimalForecastChurnRankMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.prescriptiveOptimalForecastChurnRankMeasureUID?._id,
                        prescriptiveOptimalForecastStabilityRankMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.prescriptiveOptimalForecastStabilityRankMeasureUID?._id,
                        prescriptiveOptimalForecastChurnFVAMeasureUID: selectedProfile?.prescriptiveOptimalForecastBasicKPIs?.churn?.prescriptiveOptimalForecastChurnFVAMeasureUID?._id
                    })),
                })),

                //aiBlendForecasting
                ...(selectedProfile?.aiBlendForecasting && ({
                    aiBlendForecastMeasureUID: selectedProfile?.aiBlendForecastBasicSettings?.aiBlendForecastMeasureUID?._id,
                    aiForecastOverallDiscrepancyRankMeasureUID: selectedProfile?.aiBlendForecastBasicSettings?.aiForecastOverallDiscrepancyRankMeasureUID?._id,
                    aiForecastOverallQualityRankMeasureUID: selectedProfile?.aiBlendForecastBasicSettings?.aiForecastOverallQualityRankMeasureUID?._id,
                    humanForecast: selectedProfile?.aiBlendForecastBasicSettings?.humanForecast,
                })),
                ...(selectedProfile?.aiBlendForecasting && ({
                    ...(selectedProfile?.aiBlendForecastBasicKPIs.error.isActive && ({
                        aiBlendForecastErrorMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.error?.aiBlendForecastErrorMeasureUID?._id,
                        aiBlendForecastMovingAverageErrorMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs.error?.aiBlendForecastMovingAverageErrorMeasureUID?._id,
                        aiBlendForecastRelativeErrorMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.error?.aiBlendForecastRelativeErrorMeasureUID?._id,
                        aiBlendForecastErrorRankMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.error?.aiBlendForecastErrorRankMeasureUID?._id,
                        aiBlendForecastAccuracyRankMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.error?.aiBlendForecastAccuracyRankMeasureUID?._id,
                        aiBlendForecastErrorFVAMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.error?.aiBlendForecastErrorFVAMeasureUID?._id,
                        weightParameterMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.error?.aiBlendForecastErrorFVAMeasureUID?._id
                    })),
                    ...(selectedProfile?.aiBlendForecastBasicKPIs?.bias?.isActive && ({
                        aiBlendForecastBiasMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.aiBlendForecastBiasMeasureUID?._id,
                        aiBlendForecastMovingAverageBiasMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.aiBlendForecastMovingAverageBiasMeasureUID?._id,
                        aiBlendForecastRelativeBiasMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.aiBlendForecastRelativeBiasMeasureUID?._id,
                        aiBlendForecastBiasRankMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.aiBlendForecastBiasRankMeasureUID?._id,
                        aiBlendForecastNeutralityRankMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.aiBlendForecastNeutralityRankMeasureUID?._id,
                        aiBlendForecastBiasFVAMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.aiBlendForecastBiasFVAMeasureUID?._id,
                        weightParameterBiasMeasure: selectedProfile?.aiBlendForecastBasicKPIs?.bias?.weightParameterMeasureUID?._id
                    })),
                    ...(selectedProfile?.aiBlendForecastBasicKPIs?.churn?.isActive && ({
                        aiBlendForecastChurnMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.aiBlendForecastChurnMeasureUID?._id,
                        aiBlendForecastMovingAverageChurnMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.aiBlendForecastMovingAverageChurnMeasureUID?._id,
                        aiBlendForecastRelativeChurnMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.aiBlendForecastRelativeChurnMeasureUID?._id,
                        aiBlendForecastChurnRankMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.aiBlendForecastChurnRankMeasureUID?._id,
                        aiBlendForecastStabilityRankMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.aiBlendForecastStabilityRankMeasureUID?._id,
                        aiBlendForecastChurnFVAMeasureUID: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.aiBlendForecastChurnFVAMeasureUID?._id,
                        weightParameterChurnMeasure: selectedProfile?.aiBlendForecastBasicKPIs?.churn?.weightParameterMeasureUID?._id
                    })),
                })),
            });

            if (selectedProfile?.prescriptiveOptimalForecastConstraints?.length > 0) {
                setPrescriptiveConstraintsMeasure(
                    selectedProfile?.prescriptiveOptimalForecastConstraints?.map((item) => ({
                        constraintsMeasure: item?._id,
                    }))
                );
            }
            if (selectedProfile?.prescriptiveOptimalForecastDecisions?.length > 0) {
                setPrescriptiveOptimal(
                    selectedProfile?.prescriptiveOptimalForecastDecisions?.map((item) => ({
                        prescriptiveOptimalForecastDecisions: item?._id,
                    }))
                );
            }
            if (selectedProfile?.prescriptiveOptimalForecastOptimizationObjectives?.length > 0) {
                setOptimizationMeasure(
                    selectedProfile?.prescriptiveOptimalForecastOptimizationObjectives?.map((item) => ({
                        optimizationObjective: item?.objectiveType,
                        objectiveMeasure: item?.objectiveMeasureUID?._id,
                        weight: item?.objectiveWeight,
                    }))
                );
            }
            if (selectedProfile?.driverBasedForecastAdditionalKPIs?.length > 0) {
                setPredictiveAdditionalKPI(
                    selectedProfile?.driverBasedForecastAdditionalKPIs?.map((item) => ({
                        predictiveAdditionalKPI: item?.predictiveKPIName,
                        KPIMeasure: item?.kpiMeasureUID?._id,
                    }))
                );
            }
            if (selectedProfile?.aiBlendForecastHumanForecasts.length > 0) {
                setForcastData(
                    selectedProfile?.aiBlendForecastHumanForecasts?.map((item) => ({
                        humanforecasts: item?._id,
                    }))
                );
            }
            if (selectedProfile?.driverBasedForecastDrivers?.length > 0) {
                setDriverBasedMeasure(
                    selectedProfile?.driverBasedForecastDrivers?.map((item) => ({
                        measure: item?.driverMeasureUID?._id,
                        categoricalDriver: item?.categoricalDriver,
                        importanceMeasure: item?.driverImportanceMeasureUID?._id,
                        contributionMeasure: item?.driverContributionMeasureUID?._id,
                        offset: Number(item?.offset),
                        ...(!item?.categoricalDriver && {
                            lowerBoundMeasure: item?.driverLowerBoundMeasureUID?._id,
                            upperBoundMeasure: item?.driverUpperBoundMeasureUID?._id,
                            stepSizeMeasure: item?.driverStepSizeMeasureUID?._id,
                        }),
                        ...(selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && ({
                            uncertaintyDistribution: item?.uncertaintyDistribution,
                        })),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Normal") && ({
                            meanMeasureUID: item?.meanMeasureUID?._id,
                            standardDeviationMeasureUID: item?.standardDeviationMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Beta") && ({
                            alpha1MeasureUID: item?.alpha1MeasureUID?._id,
                            alpha2MeasureUID: item?.alpha2MeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Exponential") && ({
                            betaMeasureUID: item?.betaMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "PERT") && ({
                            maximumMeasureUID: item?.maximumMeasureUID?._id,
                            minimumMeasureUID: item?.minimumMeasureUID?._id,
                            mostLikelyMeasureUID: item?.mostLikelyMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Trangular") && ({
                            bottomPercentageMeasureUID: item?.bottomPercentageMeasureUID?._id,
                            topPercentageMeasureUID: item?.topPercentageMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Lognormal") && ({
                            meanMeasureUID: item?.meanMeasureUID?._id,
                            standardDeviationMeasureUID: item?.standardDeviationMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Weibull") && ({
                            alphaMeasureUID: item?.alphaMeasureUID?._id,
                            betaMeasureUID: item?.betaMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Uniform") && ({
                            minimumMeasureUID: item?.minimumMeasureUID?._id,
                            maximumMeasureUID: item?.maximumMeasureUID?._id
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Gamma") && ({
                            alphaMeasureUID: item?.alphaMeasureUID?._id,
                            betaMeasureUID: item?.betaMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "General") && ({
                            minimumMeasureUID: item?.minimumMeasureUID?._id,
                            maximumMeasureUID: item?.maximumMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Bernoulli") && ({
                            probabilityMeasureUID: item?.probabilityMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Bionomial") && ({
                            probabilityMeasureUID: item?.probabilityMeasureUID?._id,
                            countMeasureUID: item?.countMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Histogram") && ({
                            probabilityMeasureUID: item?.probabilityMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Pareto") && ({
                            thetaMeasureUID: item?.thetaMeasureUID?._id,
                            alphaMeasureUID: item?.alphaMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Student's") && ({
                            degreesOfFreedomMeasureUID: item?.degreesOfFreedomMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Poisson") && ({
                            lambdaMeasureUID: item?.lambdaMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Geometric") && ({
                            probabilityMeasureUID: item?.probabilityMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Hypergeometric") && ({
                            sampleSizeMeasureUID: item?.sampleSizeMeasureUID?._id,
                            populationMeasureUID: item?.populationMeasureUID?._id,
                            populationSizeMeasureUID: item?.populationSizeMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Negative Bionomial") && ({
                            probabilityOfASingleSuccessMeasureUID: item?.probabilityOfASingleSuccessMeasureUID?._id,
                            noOfSuccessesMeasureUID: item?.noOfSuccessesMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Discrete") && ({
                            possibleValuesMeasureUID: item?.possibleValuesMeasureUID?._id,
                            probabilityWeightMeasureUID: item?.probabilityWeightMeasureUID?._id,
                        }))),
                        ...(((selectedProfile?.driverBasedForecastBasicSettings?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Discrete Uniform") && ({
                            possibleValuesMeasureUID: item?.possibleValuesMeasureUID?._id,
                        }))),
                    }))
                );
            }
            if (selectedProfile?.statisticalForecastModels?.length > 0) {
                setBasicKPI(selectedProfile?.statisticalForecastModels?.map((item) => ({
                    StatisticalForecastModels: item
                })))
            }
        }
    }, [profileData, isEdit, selectedRows, selectedProfile, enterpriseData]);

    const handleChangeProfile = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            const selectedData = enterpriseData?.length > 0 && enterpriseData?.filter(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id,
            });
        } else if (fieldName === "modelName") {
            const selectedData = modalName?.length > 0 && modalName?.filter(
                (data) => data?.modalUID === newValue?.modalUID
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID,
            });
        } else if (
            fieldName === "baseHistoryMeasureUID" ||
            fieldName === "forecastBaseLevel" ||
            fieldName === "historyBaseLevel" ||
            fieldName === "correctedHistoryMeasureUID" ||
            fieldName === "weightErrorMeasureUID" ||
            fieldName === "weightBiasMeasureUID" ||
            fieldName === "weightChurnMeasureUID" ||
            fieldName === "statisticalForecastMeasureUID" ||
            fieldName === "statisticalForecastDiscrepancyRankMeasureUID" ||
            fieldName === "statisticalForecastQualityRankMeasureUID" ||
            //naive forecast measures
            fieldName === "naiveForecastMeasureUID" ||
            fieldName === "naiveForecastErrorMeasureUID" ||
            fieldName === "naiveForecastBiasMeasureUID" ||
            fieldName === "naiveForecastChurnMeasureUID" ||
            fieldName === "naiveForecastMovingAverageErrorMeasureUID" ||
            fieldName === "naiveForecastMovingAverageBiasMeasureUID" ||
            fieldName === "naiveForecastMovingAverageChurnMeasureUID" ||
            fieldName === "naiveForecastMovingAverageChurnUID" ||
            fieldName === "forecastabilityBandErrorUpperBoundMeasureUID" ||
            fieldName === "forecastabilityBandBiasUpperBoundMeasureUID" ||
            fieldName === "forecastabilityBandChurnUpperBoundMeasureUID" ||
            fieldName === "forecastabilityBandErrorLowerBoundMeasureUID" ||
            fieldName === "forecastabilityBandBiasLowerBoundMeasureUID" ||
            fieldName === "forecastabilityBandChurnLowerBoundMeasureUID" ||
            //optimal forecast measures
            fieldName === "prescriptiveOptimalForecastMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastOverallQualityRankMeasureUID" ||
            fieldName === "optimalForecastMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastErrorMeasure" ||
            fieldName === "prescriptiveOptimalForecastMovingAverageErrorMeasure" ||
            fieldName === "prescriptiveOptimalForecastRelativeErrorMeasure" ||
            fieldName === "prescriptiveOptimalForecastErrorRankMeasure" ||
            fieldName === "prescriptiveOptimalForecastAccuracyRankMeasure" ||
            fieldName === "prescriptiveOptimalForecastErrorFVAMeasure" ||
            fieldName === "prescriptiveOptimalForecastBiasMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastMovingAverageBiasMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastRelativeBiasMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastBiasRankMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastNeutralityRankMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastBiasFVAMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastChurnMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastMovingAverageChurnMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastRelativeChurnMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastChurnRankMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastStabilityRankMeasureUID" ||
            fieldName === "prescriptiveOptimalForecastChurnFVAMeasureUID" ||
            fieldName === "optimalForecastQualityRankMeasureUID" ||
            fieldName === "optimalForecastWeightMeasureUID" ||
            fieldName === "optimalLagForecastWeightMeasureUID" ||
            // driver based forecast input measures
            fieldName === "driverBasedForecastMeasureUID" ||
            fieldName === "baselineDemandMeasureUID" ||
            fieldName === "driverBasedForecastOverallDiscrepancyRankMeasureUID" ||
            fieldName === "driverBasedForecastOverallQualityRankMeasureUID" ||
            fieldName === "driverBasedForecastErrorMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageErrorMeasureUID" ||
            fieldName === "driverBasedForecastRelativeErrorMeasureUID" ||
            fieldName === "driverBasedForecastErrorFVAMeasureUID" ||
            fieldName === "driverBasedForecastBiasMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageBiasMeasureUID" ||
            fieldName === "driverBasedForecastRelativeBiasMeasureUID" ||
            fieldName === "driverBasedForecastBiasFVAMeasureUID" ||
            fieldName === "driverBasedForecastChurnMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageChurnMeasureUID" ||
            fieldName === "driverBasedForecastRelativeChurnMeasureUID" ||
            fieldName === "driverBasedForecastChurnFVAMeasureUID" ||
            //  driverBasedForecasting forecast error measures
            fieldName === "hazardRateMeasureUID" ||
            fieldName === "slopeMeasureUID" ||
            fieldName === "elasticityMeasureUID" ||
            fieldName === "crossElasticityMeasureUID" ||
            //forecastablity bands
            fieldName === "movingAverageErrorUpperBoundMeasureUID" ||
            fieldName === "movingAverageErrorLowerBoundMeasureUID" ||
            fieldName === "movingAverageBiasUpperBoundMeasureUID" ||
            fieldName === "movingAverageBiasLowerBoundMeasureUID" ||
            fieldName === "movingAverageChurnUpperBoundMeasureUID" ||
            fieldName === "movingAverageChurnLowerBoundMeasureUID" ||
            //Statistical
            //ERROR
            fieldName === "statisticalForecastErrorMeasureUID" ||
            fieldName === "statisticalForecastMovingAverageErrorMeasureUID" ||
            fieldName === "statisticalForecastRelativeErrorMeasureUID" ||
            fieldName === "statisticalForecastErrorRankMeasureUID" ||
            fieldName === "statisticalForecastAccuracyRankMeasureUID" ||
            fieldName === "statisticalForecastErrorFVAMeasureUID" ||
            //BIAS
            fieldName === "statisticalForecastBiasMeasureUID" ||
            fieldName === "statisticalForecastMovingAverageBiasMeasureUID" ||
            fieldName === "statisticalForecastRelativeBiasMeasureUID" ||
            fieldName === "statisticalForecastBiasRankMeasureUID" ||
            fieldName === "statisticalForecastNeutralityRankMeasureUID" ||
            fieldName === "statisticalForecastBiasFVAMeasureUID" ||
            //CHURN
            fieldName === "statisticalForecastChurnMeasureUID" ||
            fieldName === "statisticalForecastMovingAverageChurnMeasureUID" ||
            fieldName === "statisticalForecastRelativeChurnMeasureUID" ||
            fieldName === "statisticalForecastChurnRankMeasureUID" ||
            fieldName === "statisticalForecastStabilityRankMeasureUID" ||
            fieldName === "statisticalForecastChurnFVAMeasureUID" ||
            //Driver based
            //Error
            fieldName === "driverBasedForecastErrorMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageErrorMeasureUID" ||
            fieldName === "driverBasedForecastRelativeErrorMeasureUID" ||
            fieldName === "driverBasedForecastErrorRankMeasureUID" ||
            fieldName === "driverBasedForecastAccuracyRankMeasureUID" ||
            fieldName === "driverBasedForecastErrorFVAMeasureUID" ||
            //Bias
            fieldName === "driverBasedForecastBiasMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageBiasMeasureUID" ||
            fieldName === "driverBasedForecastRelativeBiasMeasureUID" ||
            fieldName === "driverBasedForecastBiasRankMeasureUID" ||
            fieldName === "driverBasedForecastNeutralityRankMeasureUID" ||
            fieldName === "driverBasedForecastBiasFVAMeasureUID" ||
            //Churn
            fieldName === "driverBasedForecastChurnMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageChurnMeasureUID" ||
            fieldName === "driverBasedForecastRelativeChurnMeasureUID" ||
            fieldName === "driverBasedForecastChurnRankMeasureUID" ||
            fieldName === "driverBasedForecastStabilityRankMeasureUID" ||
            fieldName === "driverBasedForecastChurnFVAMeasureUID" ||
            //AI Blend
            fieldName === "aiBlendForecastMeasureUID" ||
            fieldName === "aiForecastOverallDiscrepancyRankMeasureUID" ||
            fieldName === "aiForecastOverallQualityRankMeasureUID" ||
            //Error
            fieldName === "aiBlendForecastErrorMeasureUID" ||
            fieldName === "aiBlendForecastMovingAverageErrorMeasureUID" ||
            fieldName === "aiBlendForecastRelativeErrorMeasureUID" ||
            fieldName === "aiBlendForecastErrorRankMeasureUID" ||
            fieldName === "aiBlendForecastAccuracyRankMeasureUID" ||
            fieldName === "aiBlendForecastErrorFVAMeasureUID" ||
            fieldName === "weightParameterMeasureUID" ||
            //Bias
            fieldName === "aiBlendForecastBiasMeasureUID" ||
            fieldName === "aiBlendForecastMovingAverageBiasMeasureUID" ||
            fieldName === "aiBlendForecastRelativeBiasMeasureUID" ||
            fieldName === "aiBlendForecastBiasRankMeasureUID" ||
            fieldName === "aiBlendForecastNeutralityRankMeasureUID" ||
            fieldName === "aiBlendForecastBiasFVAMeasureUID" ||
            fieldName === "weightParameterBiasMeasure" ||
            //Churn
            fieldName === "aiBlendForecastChurnMeasureUID" ||
            fieldName === "aiBlendForecastMovingAverageChurnMeasureUID" ||
            fieldName === "aiBlendForecastRelativeChurnMeasureUID" ||
            fieldName === "aiBlendForecastChurnRankMeasureUID" ||
            fieldName === "aiBlendForecastStabilityRankMeasureUID" ||
            fieldName === "aiBlendForecastChurnFVAMeasureUID" ||
            fieldName === "weightParameterChurnMeasure"
        ) {
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
            });

        } else if (fieldName === "timePeriod") {
            const selectedData = timeData?.timeSchemaFields?.find(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Period Type"],
                timePeriodId: newValue && newValue?._id,
            });
        } else if (fieldName === "frequency") {
            const selectedData = timeData?.timeSchemaFields?.find(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Period Type"],
                frequencyId: newValue && newValue?._id,
            });
        } else if (fieldName === "notes") {
            const { name, value } = e.target;
            const words = value?.split(" ");

            if (words.length < 10) {
                e.target.value = words?.slice(0, 100)?.join(" ");
            }
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        } else if (
            fieldName === "statisticalForecasting" ||
            fieldName === "hierarchicalForecasting" ||
            fieldName === "timeBucketAggregation" ||
            fieldName === "prescriptiveOptimalForecasting" ||
            fieldName === "driverBasedForecasting" ||
            fieldName === "aiBlendForecasting" ||
            fieldName === "outlierCorrection" ||
            fieldName === "humanForecast" ||
            fieldName === "driverSensitivityAnalysis" ||
            fieldName === "automatedWhatIfSimulation" ||
            fieldName === "isCheckedbasicKPIError" ||
            fieldName === "isCheckedbasicKPIBias" ||
            fieldName === "isCheckedbasicKPIChurn" ||
            fieldName === "forecastabilityAnalysis" ||
            fieldName === "explainResults"
        ) {
            const { checked } = e.target;
            setFormValues((prevValues) => ({
                ...prevValues,
                [fieldName]: checked,
            }));
        }
        else {
            const { name, value } = e.target;
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }

    };


    const handleChangehierarchicalForecasting = (id, event, type) => {
        if (type === "hierachicalForecasting") {
            const isChecked = event.target.checked;

            setFormValues((prev) => {
                const currentValues = prev.hierarchicalForecastingAttributes || []; // Ensure it's an array
                const updatedValues = isChecked
                    ? [...currentValues, id] // Add the new value if checked
                    : currentValues.filter((value) => value !== id); // Remove the value if unchecked

                return {
                    ...prev,
                    hierarchicalForecastingAttributes: updatedValues, // Update the state
                };
            });
        } else {
            const isChecked = event.target.checked;
            setFormValues((prev) => {
                const currentValues = prev.timeBucketAggregationPeriods || []; // Ensure it's an array
                const updatedValues = isChecked
                    ? [...currentValues, id] // Add the new value if checked
                    : currentValues.filter((value) => value !== id); // Remove the value if unchecked

                return {
                    ...prev,
                    timeBucketAggregationPeriods: updatedValues, // Update the state
                };
            });
        }

    };

    useEffect(() => {
        if (formValues?.automatedWhatIfSimulation) {
            setFormValues({
                ...formValues,
                noOfIterations: 1000
            })
        }
    }, [formValues?.automatedWhatIfSimulation])

    const handleAdd = () => {
        setForcastData([...forcastData, { humanforecasts: "" }]);
    };
    const handleAddPrescriptive = () => {
        setPrescriptiveOptimal([...prescriptiveOptimal, { prescriptiveOptimalForecastDecisions: "" }]);
    };

    const handleAddOptimizationMeasure = () => {
        setOptimizationMeasure([...optimizationMeasure, { optimizationObjective: "", objectiveMeasure: "", weight: "" }]);
    };

    const handleAddbasicKPI = () => {
        setBasicKPI([
            ...basicKPI, {
                StatisticalForecastModels: "",
            }]);
    };

    const handleAddPredictivebasicKPI = () => {
        setPredictiveBasicKPI([
            ...predictiveBasicKPI, {
                predictiveKPI: "",
                bestPredictiveKPI: "",
                bestPredictiveforecastMovingAverageKPI: "",
                bestPredictiveforecastRelativeKPI: "",
                bestPredictiveforecastKPIRank: "",
                bestPredictiveforecastMirrorKPIRank: "",
                bestPredictiveforecastKPIFVA: "",
            }]);
    };

    const handleAddPredictiveAdditionalKPI = () => {
        setPredictiveAdditionalKPI([
            ...predictiveAdditionalKPI, {
                predictiveAdditionalKPI: "",
                KPIMeasure: ""
            }]);
    };

    const handleAddAiEnsembleKPI = () => {
        setAiEnsembleBasicKPI([
            ...aiEnsembleBasicKPI, {
                aiEnsembleKPI: "",
                bestAiEnsembleKPI: "",
                bestAiEnsembleMovingAverageKPI: "",
                bestAiEnsembleRelativeKPI: "",
                bestAiEnsembleKPIRank: "",
                bestAiEnsembleMirrorKPIRank: "",
                bestAiEnsembleKPIFVA: "",
                weightParameterMeasure: ""
            }]);
    };

    const handleAddConstraintsMeasure = () => {
        setPrescriptiveConstraintsMeasure([
            ...prescriptiveconstraintsMeasure, {
                constraintsMeasure: ""
            }]);
    };

    const handleAddDriverMeasure = () => {
        setDriverBasedMeasure([...driverBasedMeasure, {
            measure: "",
            categoricalDriver: false,
            importanceMeasure: "",
            contributionMeasure: "",
            offset: 0,
            uncertaintyDistribution: "",
            lowerBoundMeasure: "",
            upperBoundMeasure: "",
            stepSizeMeasure: ""
        }])
    }

    const handleChangeDriverBasedMeasure = (index, newValue, type, event) => {
        const updatedFormValues = [...driverBasedMeasure];
        if (type === "categoricalDriver") {
            const { checked } = event.target
            updatedFormValues[index][type] = checked;
            setDriverBasedMeasure(updatedFormValues);
        } else if (type === "offset" || type === "uncertaintyDistribution") {
            const { value } = event.target
            updatedFormValues[index][type] = value;
            setDriverBasedMeasure(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setDriverBasedMeasure(updatedFormValues);
        }
    };

    const handleChangeForeCastProfile = (index, newValue, type, event) => {
        const updatedFormValues = [...forcastData];
        updatedFormValues[index][type] = newValue?._id;
        setForcastData(updatedFormValues);
    };

    const handleChangePrescriptive = (index, newValue, type, event) => {
        const updatedFormValues = [...prescriptiveOptimal];
        updatedFormValues[index][type] = newValue?._id;
        setPrescriptiveOptimal(updatedFormValues);
    };

    const handleChangeOptimizationMeasure = (index, newValue, type, e) => {
        const updatedFormValues = [...optimizationMeasure];
        if (type === "objectiveMeasure") {
            updatedFormValues[index][type] = newValue?._id;
            setOptimizationMeasure(updatedFormValues);
        }
        const { name, value } = e.target
        updatedFormValues[index][name] = value;
        setOptimizationMeasure(updatedFormValues);
    };

    const handleChangeBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...basicKPI];
        updatedFormValues[index][type] = newValue;
        setBasicKPI(updatedFormValues);
    };

    const handleChangePredictiveBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...predictiveBasicKPI];
        if (type === "predictiveKPI") {
            const { name, value } = e.target
            updatedFormValues[index][name] = value;
            setPredictiveBasicKPI(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setPredictiveBasicKPI(updatedFormValues);
        }
    };

    const handleChangePredictiveAdditionalBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...predictiveAdditionalKPI];
        if (type === "predictiveAdditionalKPI") {
            const { name, value } = e.target
            updatedFormValues[index][name] = value;
            setPredictiveAdditionalKPI(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setPredictiveAdditionalKPI(updatedFormValues);
        }
    };

    const handleChangeAiEnsembleBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...aiEnsembleBasicKPI];
        if (type === "aiEnsembleKPI") {
            const { name, value } = e.target
            updatedFormValues[index][name] = value;
            setPredictiveAdditionalKPI(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setPredictiveAdditionalKPI(updatedFormValues);
        }
    };

    const handleClick = (event, index, type) => {
        if (type === "humanforecasts") {
            setAnchorEl(event.currentTarget);
            setDataIndex(index);
        } else if (type === "demandDriven") {
            setAnchorMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "optimizationObjective") {
            setAnchorOptimizationMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "basicKPI") {
            setAnchorBasicKPIEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "extrapolativeBasicKPI") {
            setAnchorExtrapolativeBasicEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "predictiveBasicKPI") {
            setAnchorPredictiveBasicEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "predictiveAdditionalKPIMeasure") {
            setAnchorPredictiveAdditionalKPIEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "prescriptiveConstraints") {
            setAnchorConstraintsMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "aiEnsembleKPIMeasure") {
            setAnchorAiEnsembleEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "prescriptiveOptimal") {
            setAnchorPrescriptiveEnsembleEl(event.currentTarget)
            setDataIndex(index);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleremove = (type) => {
        if (type === "humanforecasts") {
            const updatedField = [...forcastData];
            updatedField.splice(dataIndex, 1);
            setForcastData(updatedField);
            setAnchorEl(null);
        } else if (type === "demandDriven") {
            const updatedField = [...driverBasedMeasure];
            updatedField.splice(dataIndex, 1);
            setDriverBasedMeasure(updatedField);
            setAnchorMeasureEl(null);
        } else if (type === "optimizationObjective") {
            const updatedField = [...optimizationMeasure];
            updatedField.splice(dataIndex, 1);
            setOptimizationMeasure(updatedField);
            setAnchorOptimizationMeasureEl(null);
        } else if (type === "basicKPI") {
            const updatedField = [...basicKPI];
            updatedField.splice(dataIndex, 1);
            setBasicKPI(updatedField);
            setAnchorBasicKPIEl(null);
        } else if (type === "predictiveBasicKPI") {
            const updatedField = [...predictiveBasicKPI];
            updatedField.splice(dataIndex, 1);
            setPredictiveBasicKPI(updatedField);
            setAnchorPredictiveBasicEl(null);
        } else if (type === "predictiveAdditionalKPIMeasure") {
            const updatedField = [...predictiveAdditionalKPI];
            updatedField.splice(dataIndex, 1);
            setPredictiveAdditionalKPI(updatedField);
            setAnchorPredictiveAdditionalKPIEl(null);
        } else if (type === "prescriptiveConstraints") {
            const updatedField = [...prescriptiveconstraintsMeasure];
            updatedField.splice(dataIndex, 1);
            setPrescriptiveConstraintsMeasure(updatedField);
            setAnchorConstraintsMeasureEl(null);
        } else if (type === "aiEnsembleKPIMeasure") {
            const updatedField = [...aiEnsembleBasicKPI];
            updatedField.splice(dataIndex, 1);
            setAiEnsembleBasicKPI(updatedField);
            setAnchorAiEnsembleEl(null);
        } else if (type === "prescriptiveOptimal") {
            const updatedField = [...prescriptiveOptimal];
            updatedField.splice(dataIndex, 1);
            setPrescriptiveOptimal(updatedField);
            setAnchorPrescriptiveEnsembleEl(null);
        }
    };

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.length > 0 && response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === formValues.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (formValues.enterpriseName) {
                filteredData = filteredData?.length > 0 && filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (formValues.enterpriseName) {
            getModelList();
        }
    }, [formValues.enterpriseName]);

    useEffect(() => {
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            dispatch(getProfilePrimaryTimeDimension({ formValues }));
            dispatch(getProfileMeasure({ formValues }));
        }
    }, [formValues?.enterpriseUID && formValues?.modelUID]);

    const handleCancel = () => {
        setIsCreate(false);
    };

    const validation = () => {
        if (formValues?.name === "" || formValues?.name === undefined) {
            toast.error("Please enter profile name");
            return true;
        } else if (
            formValues?.enterpriseUID === "" ||
            formValues?.enterpriseUID === undefined
        ) {
            toast.error("Please select enterprise");
            return true;
        } else if (
            formValues?.modelUID === "" ||
            formValues?.modelUID === undefined
        ) {
            toast.error("Please select model");
            return true;
        }
        return false;
    };
    const handleSubmit = async () => {
        if (validation()) {
            return;
        } else {
            //     let humanForecasts = [];

            //     if (forcastData?.length > 0) {
            //         humanForecasts = forcastData?.length > 0 && forcastData
            //             .filter(
            //                 (item) =>
            //                     item?.humanforecasts !== "" && item?.humanforecasts !== undefined
            //             )
            //             .map((item) => item?.humanforecasts);
            //     }
            const payload = {
                name: formValues?.name,
                enterpriseUID: formValues?.enterpriseUID,
                modelUID: formValues?.modelUID,
                outlierCorrection: formValues?.outlierCorrection || false,
                statisticalForecasting: formValues?.statisticalForecasting || false,
                hierarchicalForecasting: formValues?.hierarchicalForecasting || false,
                timeBucketAggregation: formValues?.timeBucketAggregation || false,
                driverBasedForecasting: formValues?.driverBasedForecasting || false,
                prescriptiveOptimalForecasting: formValues?.prescriptiveOptimalForecasting || false,
                aiBlendForecasting: formValues?.aiBlendForecasting || false,
                periodicity: formValues?.timePeriod,
                frequency: formValues?.frequency,
                historyHorizon: Number(formValues?.historyHorizon),
                forecastHorizon: Number(formValues?.forecastHorizon),
                validationHorizon: Number(formValues?.validationHorizon),
                forecastBaseLevel: formValues?.forecastBaseLevel,
                historyBaseLevel: formValues?.historyBaseLevel,
                baseHistoryMeasureUID: formValues?.baseHistoryMeasureUID,
                correctedHistoryMeasureUID: formValues?.correctedHistoryMeasureUID,
                forecastabilityAnalysis: formValues?.forecastabilityAnalysis,
                naiveForecastMeasureUID: formValues?.naiveForecastMeasureUID,
                ...(formValues?.hierarchicalForecasting && formValues?.hierarchicalForecastingAttributes?.length > 0 && ({
                    hierarchicalForecastingAttributes: formValues?.hierarchicalForecastingAttributes
                })),
                ...(formValues?.timeBucketAggregation && formValues?.timeBucketAggregationPeriods?.length > 0 && ({
                    timeBucketAggregationPeriods: formValues?.timeBucketAggregationPeriods
                })),
                basicKPIs: {
                    ...(formValues?.isCheckedbasicKPIError && ({
                        error: {
                            isActive: formValues?.isCheckedbasicKPIError,
                            errorMethod: formValues?.errorMethod,
                            ...(formValues?.errorMethod === "wMAPE" && ({
                                weightMeasureUID: formValues?.weightErrorMeasureUID
                            })),
                            defaultValue: Number(formValues?.defaultErrorValue),
                            movingAverageHorizon: Number(formValues?.movingAverageErrorHorizon),
                            naiveForecastErrorMeasureUID: formValues?.naiveForecastErrorMeasureUID,
                            naiveForecastMovingAverageErrorMeasureUID: formValues?.naiveForecastMovingAverageErrorMeasureUID,
                            ...(formValues?.forecastabilityAnalysis && ({
                                ...(formValues?.forecastabilityBandErrorUpperBoundMeasureUID && ({
                                    forecastabilityBandUpperBoundMeasureUID: formValues?.forecastabilityBandErrorUpperBoundMeasureUID,
                                })),
                                ...(formValues?.forecastabilityBandErrorLowerBoundMeasureUID && ({
                                    forecastabilityBandLowerBoundMeasureUID: formValues?.forecastabilityBandErrorLowerBoundMeasureUID
                                }))
                            }))
                        }
                    })),
                    ...(formValues?.isCheckedbasicKPIBias && ({
                        bias: {
                            isActive: formValues?.isCheckedbasicKPIBias,
                            biasMethod: formValues?.biasMethod,
                            ...(formValues?.biasMethod === "wMPE" && ({
                                weightMeasureUID: formValues?.weightBiasMeasureUID
                            })),
                            defaultValue: Number(formValues?.defaultBiasValue),
                            movingAverageHorizon: Number(formValues?.movingAverageBiasHorizon),
                            naiveForecastBiasMeasureUID: formValues?.naiveForecastBiasMeasureUID,
                            naiveForecastMovingAverageBiasMeasureUID: formValues?.naiveForecastMovingAverageBiasMeasureUID,
                            ...(formValues?.forecastabilityAnalysis && ({
                                ...(formValues?.forecastabilityBandBiasUpperBoundMeasureUID && ({
                                    forecastabilityBandUpperBoundMeasureUID: formValues?.forecastabilityBandBiasUpperBoundMeasureUID,
                                })),
                                ...(formValues?.forecastabilityBandBiasLowerBoundMeasureUID && ({
                                    forecastabilityBandLowerBoundMeasureUID: formValues?.forecastabilityBandBiasLowerBoundMeasureUID
                                }))
                            }))
                        }
                    })),
                    ...(formValues?.isCheckedbasicKPIChurn && ({
                        churn: {
                            isActive: formValues?.isCheckedbasicKPIChurn,
                            churnMethod: formValues?.churnMethod,
                            ...(formValues?.churnMethod === "wMAPC" && ({
                                weightMeasureUID: formValues?.weightChurnMeasureUID
                            })),
                            defaultValue: Number(formValues?.defaultChurnValue),
                            movingAverageHorizon: Number(formValues?.movingAverageChurnHorizon),
                            naiveForecastChurnMeasureUID: formValues?.naiveForecastChurnMeasureUID,
                            naiveForecastMovingAverageChurnMeasureUID: formValues?.naiveForecastMovingAverageChurnMeasureUID,
                            ...(formValues?.forecastabilityAnalysis && ({
                                ...(formValues?.forecastabilityBandChurnUpperBoundMeasureUID && ({
                                    forecastabilityBandUpperBoundMeasureUID: formValues?.forecastabilityBandChurnUpperBoundMeasureUID,
                                })),
                                ...(formValues?.forecastabilityBandChurnLowerBoundMeasureUID && ({
                                    forecastabilityBandLowerBoundMeasureUID: formValues?.forecastabilityBandChurnLowerBoundMeasureUID
                                }))
                            }))
                        }
                    })),
                },
                statisticalForecastBasicSettings: {
                    statisticalForecastMeasureUID: formValues?.statisticalForecastMeasureUID,
                    statisticalForecastDiscrepancyRankMeasureUID: formValues?.statisticalForecastDiscrepancyRankMeasureUID,
                    statisticalForecastQualityRankMeasureUID: formValues?.statisticalForecastQualityRankMeasureUID,
                },
                ...(basicKPI?.length > 0 && ({
                    statisticalForecastModels: basicKPI?.map((item) => item.StatisticalForecastModels)
                })),
                ...(formValues?.statisticalForecasting && ({
                    statisticalForecastKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                statisticalForecastErrorMeasureUID: formValues?.statisticalForecastErrorMeasureUID,
                                statisticalForecastMovingAverageErrorMeasureUID: formValues?.statisticalForecastMovingAverageErrorMeasureUID,
                                statisticalForecastRelativeErrorMeasureUID: formValues?.statisticalForecastRelativeErrorMeasureUID,
                                statisticalForecastErrorRankMeasureUID: formValues?.statisticalForecastErrorRankMeasureUID,
                                statisticalForecastAccuracyRankMeasureUID: formValues?.statisticalForecastAccuracyRankMeasureUID,
                                statisticalForecastErrorFVAMeasureUID: formValues?.statisticalForecastErrorFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                statisticalForecastBiasMeasureUID: formValues?.statisticalForecastBiasMeasureUID,
                                statisticalForecastMovingAverageBiasMeasureUID: formValues?.statisticalForecastMovingAverageBiasMeasureUID,
                                statisticalForecastRelativeBiasMeasureUID: formValues?.statisticalForecastRelativeBiasMeasureUID,
                                statisticalForecastBiasRankMeasureUID: formValues?.statisticalForecastBiasRankMeasureUID,
                                statisticalForecastNeutralityRankMeasureUID: formValues?.statisticalForecastNeutralityRankMeasureUID,
                                statisticalForecastBiasFVAMeasureUID: formValues?.statisticalForecastBiasFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                statisticalForecastChurnMeasureUID: formValues?.statisticalForecastChurnMeasureUID,
                                statisticalForecastMovingAverageChurnMeasureUID: formValues?.statisticalForecastMovingAverageChurnMeasureUID,
                                statisticalForecastRelativeChurnMeasureUID: formValues?.statisticalForecastRelativeChurnMeasureUID,
                                statisticalForecastChurnRankMeasureUID: formValues?.statisticalForecastChurnRankMeasureUID,
                                statisticalForecastStabilityRankMeasureUID: formValues?.statisticalForecastStabilityRankMeasureUID,
                                statisticalForecastChurnFVAMeasureUID: formValues?.statisticalForecastChurnFVAMeasureUID
                            }
                        })),
                    }
                })),
                ...(formValues?.driverBasedForecasting && ({
                    driverBasedForecastBasicSettings: {
                        driverBasedForecastMeasureUID: formValues?.driverBasedForecastMeasureUID,
                        baselineDemandMeasureUID: formValues?.baselineDemandMeasureUID,
                        driverBasedForecastOverallDiscrepancyRankMeasureUID: formValues?.driverBasedForecastOverallDiscrepancyRankMeasureUID,
                        driverBasedForecastOverallQualityRankMeasureUID: formValues?.driverBasedForecastOverallQualityRankMeasureUID,
                        driverSensitivityAnalysis: formValues?.driverSensitivityAnalysis,
                        automatedWhatIfSimulation: formValues?.automatedWhatIfSimulation,
                        ...(formValues?.automatedWhatIfSimulation && ({
                            noOfIterations: Number(formValues?.noOfIterations),
                            initialSeedType: formValues?.initialSeedType,
                            ...(formValues?.initialSeedType === "Fixed" && ({
                                initialSeedValue: Number(formValues?.initialSeedValue)
                            }))
                        })),
                    },
                    ...(driverBasedMeasure?.length > 0 && ({
                        driverBasedForecastDrivers: driverBasedMeasure?.map((item) => ({
                            ...(item?.measure && ({
                                driverMeasureUID: item?.measure,
                            })),
                            categoricalDriver: item?.categoricalDriver,
                            ...(item?.offset && ({
                                offset: Number(item?.offset),
                            })),
                            ...(item?.importanceMeasure && ({
                                driverImportanceMeasureUID: item?.importanceMeasure,
                            })),
                            ...(item?.contributionMeasure && ({
                                driverContributionMeasureUID: item?.contributionMeasure,
                            })),
                            ...((formValues?.automatedWhatIfSimulation) && ({
                                uncertaintyDistribution: item?.uncertaintyDistribution,
                            })),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Normal") && ({
                                meanMeasureUID: item?.meanMeasureUID,
                                standardDeviationMeasureUID: item?.standardDeviationMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Beta") && ({
                                alpha1MeasureUID: item?.alpha1MeasureUID,
                                alpha2MeasureUID: item?.alpha2MeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Exponential") && ({
                                betaMeasureUID: item?.betaMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "PERT") && ({
                                maximumMeasureUID: item?.maximumMeasureUID,
                                minimumMeasureUID: item?.minimumMeasureUID,
                                mostLikelyMeasureUID: item?.mostLikelyMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Trangular") && ({
                                bottomPercentageMeasureUID: item?.bottomPercentageMeasureUID,
                                topPercentageMeasureUID: item?.topPercentageMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Lognormal") && ({
                                meanMeasureUID: item?.meanMeasureUID,
                                standardDeviationMeasureUID: item?.standardDeviationMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Weibull") && ({
                                alphaMeasureUID: item?.alphaMeasureUID,
                                betaMeasureUID: item?.betaMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Uniform") && ({
                                minimumMeasureUID: item?.minimumMeasureUID,
                                maximumMeasureUID: item?.maximumMeasureUID
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Gamma") && ({
                                alphaMeasureUID: item?.alphaMeasureUID,
                                betaMeasureUID: item?.betaMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "General") && ({
                                minimumMeasureUID: item?.minimumMeasureUID,
                                maximumMeasureUID: item?.maximumMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Bernoulli") && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Bionomial") && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                                countMeasureUID: item?.countMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Histogram") && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Pareto") && ({
                                thetaMeasureUID: item?.thetaMeasureUID,
                                alphaMeasureUID: item?.alphaMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Student's") && ({
                                degreesOfFreedomMeasureUID: item?.degreesOfFreedomMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Poisson") && ({
                                lambdaMeasureUID: item?.lambdaMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Geometric") && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Hypergeometric") && ({
                                sampleSizeMeasureUID: item?.sampleSizeMeasureUID,
                                populationMeasureUID: item?.populationMeasureUID,
                                populationSizeMeasureUID: item?.populationSizeMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Negative Bionomial") && ({
                                probabilityOfASingleSuccessMeasureUID: item?.probabilityOfASingleSuccessMeasureUID,
                                noOfSuccessesMeasureUID: item?.noOfSuccessesMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Discrete") && ({
                                possibleValuesMeasureUID: item?.possibleValuesMeasureUID,
                                probabilityWeightMeasureUID: item?.probabilityWeightMeasureUID,
                            }))),
                            ...(((formValues?.automatedWhatIfSimulation && item?.uncertaintyDistribution === "Discrete Uniform") && ({
                                possibleValuesMeasureUID: item?.possibleValuesMeasureUID,
                            }))),
                            ...((item?.lowerBoundMeasure && !item?.categoricalDriver) && ({
                                driverLowerBoundMeasureUID: item?.lowerBoundMeasure,
                            })),
                            ...((item?.upperBoundMeasure && !item?.categoricalDriver) && ({
                                driverUpperBoundMeasureUID: item?.upperBoundMeasure,
                            })),
                            ...((item?.stepSizeMeasure && !item?.categoricalDriver) && ({
                                driverStepSizeMeasureUID: item?.stepSizeMeasure,
                            })),

                        })),
                    })),
                    driverBasedForecastBasicKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                driverBasedForecastErrorMeasureUID: formValues?.driverBasedForecastErrorMeasureUID,
                                driverBasedForecastMovingAverageErrorMeasureUID: formValues?.driverBasedForecastMovingAverageErrorMeasureUID,
                                driverBasedForecastRelativeErrorMeasureUID: formValues?.driverBasedForecastRelativeErrorMeasureUID,
                                driverBasedForecastErrorRankMeasureUID: formValues?.driverBasedForecastErrorRankMeasureUID,
                                driverBasedForecastAccuracyRankMeasureUID: formValues?.driverBasedForecastAccuracyRankMeasureUID,
                                driverBasedForecastErrorFVAMeasureUID: formValues?.driverBasedForecastErrorFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                driverBasedForecastBiasMeasureUID: formValues?.driverBasedForecastBiasMeasureUID,
                                driverBasedForecastMovingAverageBiasMeasureUID: formValues?.driverBasedForecastMovingAverageBiasMeasureUID,
                                driverBasedForecastRelativeBiasMeasureUID: formValues?.driverBasedForecastRelativeBiasMeasureUID,
                                driverBasedForecastBiasRankMeasureUID: formValues?.driverBasedForecastBiasRankMeasureUID,
                                driverBasedForecastNeutralityRankMeasureUID: formValues?.driverBasedForecastNeutralityRankMeasureUID,
                                driverBasedForecastBiasFVAMeasureUID: formValues?.driverBasedForecastBiasFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                driverBasedForecastChurnMeasureUID: formValues?.driverBasedForecastChurnMeasureUID,
                                driverBasedForecastMovingAverageChurnMeasureUID: formValues?.driverBasedForecastMovingAverageChurnMeasureUID,
                                driverBasedForecastRelativeChurnMeasureUID: formValues?.driverBasedForecastRelativeChurnMeasureUID,
                                driverBasedForecastChurnRankMeasureUID: formValues?.driverBasedForecastChurnRankMeasureUID,
                                driverBasedForecastStabilityRankMeasureUID: formValues?.driverBasedForecastStabilityRankMeasureUID,
                                driverBasedForecastChurnFVAMeasureUID: formValues?.driverBasedForecastChurnFVAMeasureUID
                            }
                        })),
                    },
                    ...(predictiveAdditionalKPI.length > 0 && ({
                        driverBasedForecastAdditionalKPIs: predictiveAdditionalKPI?.map((item) => ({
                            predictiveKPIName: item.predictiveAdditionalKPI,
                            kpiMeasureUID: item.KPIMeasure,
                        }))
                    })),
                })),
                ...(formValues?.prescriptiveOptimalForecasting && ({
                    prescriptiveOptimalForecastBasicSettings: {
                        prescriptiveOptimalForecastMeasureUID: formValues?.prescriptiveOptimalForecastMeasureUID,
                        prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID: formValues?.prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID,
                        prescriptiveOptimalForecastOverallQualityRankMeasureUID: formValues?.prescriptiveOptimalForecastOverallQualityRankMeasureUID,
                        maxRuntime: Number(formValues?.maxRuntime),
                        explainResults: formValues?.explainResults,
                    },
                    ...(optimizationMeasure.length > 0 &&
                        ({
                            prescriptiveOptimalForecastOptimizationObjectives: optimizationMeasure?.map((item) => ({
                                objectiveType: item?.optimizationObjective,
                                objectiveMeasureUID: item?.objectiveMeasure,
                                objectiveWeight: Number(item?.weight)
                            }))
                        })
                    ),
                    ...(prescriptiveconstraintsMeasure?.length > 0 && ({
                        prescriptiveOptimalForecastConstraints: prescriptiveconstraintsMeasure?.map((item) => item?.constraintsMeasure)
                    })),
                    ...(prescriptiveOptimal?.length > 0 && ({
                        prescriptiveOptimalForecastDecisions: prescriptiveOptimal?.map((item) => item?.prescriptiveOptimalForecastDecisions)
                    })),
                    prescriptiveOptimalForecastBasicKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                prescriptiveOptimalForecastErrorMeasureUID: formValues?.prescriptiveOptimalForecastErrorMeasure,
                                prescriptiveOptimalForecastMovingAverageErrorMeasureUID: formValues?.prescriptiveOptimalForecastMovingAverageErrorMeasure,
                                prescriptiveOptimalForecastRelativeErrorMeasureUID: formValues?.prescriptiveOptimalForecastRelativeErrorMeasure,
                                prescriptiveOptimalForecastErrorRankMeasureUID: formValues?.prescriptiveOptimalForecastErrorRankMeasure,
                                prescriptiveOptimalForecastAccuracyRankMeasureUID: formValues?.prescriptiveOptimalForecastAccuracyRankMeasure,
                                prescriptiveOptimalForecastErrorFVAMeasureUID: formValues?.prescriptiveOptimalForecastErrorFVAMeasure
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                prescriptiveOptimalForecastBiasMeasureUID: formValues?.prescriptiveOptimalForecastBiasMeasureUID,
                                prescriptiveOptimalForecastMovingAverageBiasMeasureUID: formValues?.prescriptiveOptimalForecastMovingAverageBiasMeasureUID,
                                prescriptiveOptimalForecastRelativeBiasMeasureUID: formValues?.prescriptiveOptimalForecastRelativeBiasMeasureUID,
                                prescriptiveOptimalForecastBiasRankMeasureUID: formValues?.prescriptiveOptimalForecastBiasRankMeasureUID,
                                prescriptiveOptimalForecastNeutralityRankMeasureUID: formValues?.prescriptiveOptimalForecastNeutralityRankMeasureUID,
                                prescriptiveOptimalForecastBiasFVAMeasureUID: formValues?.prescriptiveOptimalForecastBiasFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                prescriptiveOptimalForecastChurnMeasureUID: formValues?.prescriptiveOptimalForecastChurnMeasureUID,
                                prescriptiveOptimalForecastMovingAverageChurnMeasureUID: formValues?.prescriptiveOptimalForecastMovingAverageChurnMeasureUID,
                                prescriptiveOptimalForecastRelativeChurnMeasureUID: formValues?.prescriptiveOptimalForecastRelativeChurnMeasureUID,
                                prescriptiveOptimalForecastChurnRankMeasureUID: formValues?.prescriptiveOptimalForecastChurnRankMeasureUID,
                                prescriptiveOptimalForecastStabilityRankMeasureUID: formValues?.prescriptiveOptimalForecastStabilityRankMeasureUID,
                                prescriptiveOptimalForecastChurnFVAMeasureUID: formValues?.prescriptiveOptimalForecastChurnFVAMeasureUID
                            }
                        })),
                    }
                })),
                ...(formValues?.aiBlendForecasting && ({
                    aiBlendForecastBasicSettings: {
                        aiBlendForecastMeasureUID: formValues?.aiBlendForecastMeasureUID,
                        aiForecastOverallDiscrepancyRankMeasureUID: formValues?.aiForecastOverallDiscrepancyRankMeasureUID,
                        aiForecastOverallQualityRankMeasureUID: formValues?.aiForecastOverallQualityRankMeasureUID,
                        humanForecast: formValues?.humanForecast || false,
                    },
                    aiBlendForecastBasicKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                aiBlendForecastErrorMeasureUID: formValues?.aiBlendForecastErrorMeasureUID,
                                aiBlendForecastMovingAverageErrorMeasureUID: formValues?.aiBlendForecastMovingAverageErrorMeasureUID,
                                aiBlendForecastRelativeErrorMeasureUID: formValues?.aiBlendForecastRelativeErrorMeasureUID,
                                aiBlendForecastErrorRankMeasureUID: formValues?.aiBlendForecastErrorRankMeasureUID,
                                aiBlendForecastAccuracyRankMeasureUID: formValues?.aiBlendForecastAccuracyRankMeasureUID,
                                aiBlendForecastErrorFVAMeasureUID: formValues?.aiBlendForecastErrorFVAMeasureUID,
                                weightParameterMeasureUID: formValues?.weightParameterMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                aiBlendForecastBiasMeasureUID: formValues?.aiBlendForecastBiasMeasureUID,
                                aiBlendForecastMovingAverageBiasMeasureUID: formValues?.aiBlendForecastMovingAverageBiasMeasureUID,
                                aiBlendForecastRelativeBiasMeasureUID: formValues?.aiBlendForecastRelativeBiasMeasureUID,
                                aiBlendForecastBiasRankMeasureUID: formValues?.aiBlendForecastBiasRankMeasureUID,
                                aiBlendForecastNeutralityRankMeasureUID: formValues?.aiBlendForecastNeutralityRankMeasureUID,
                                aiBlendForecastBiasFVAMeasureUID: formValues?.aiBlendForecastBiasFVAMeasureUID,
                                weightParameterMeasureUID: formValues?.weightParameterBiasMeasure
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                aiBlendForecastChurnMeasureUID: formValues?.aiBlendForecastChurnMeasureUID,
                                aiBlendForecastMovingAverageChurnMeasureUID: formValues?.aiBlendForecastMovingAverageChurnMeasureUID,
                                aiBlendForecastRelativeChurnMeasureUID: formValues?.aiBlendForecastRelativeChurnMeasureUID,
                                aiBlendForecastChurnRankMeasureUID: formValues?.aiBlendForecastChurnRankMeasureUID,
                                aiBlendForecastStabilityRankMeasureUID: formValues?.aiBlendForecastStabilityRankMeasureUID,
                                aiBlendForecastChurnFVAMeasureUID: formValues?.aiBlendForecastChurnFVAMeasureUID,
                                weightParameterMeasureUID: formValues?.weightParameterChurnMeasure
                            }
                        })),
                    },
                    ...(formValues?.humanForecast && forcastData?.length > 0 && ({
                        aiBlendForecastHumanForecasts: forcastData?.map((item) => item?.humanforecasts)
                    }))
                })),
                notes: formValues?.notes
            }

            const result = await dispatch(
                createProfile({ payload, isEdit, selectedRows })
            );
            if (result?.meta?.requestStatus === "fulfilled") {
                // toast.success(
                //     isEdit
                //         ? "Profile updated successfully"
                //         : "Profile created successfully"
                // );
                dispatch(getProfileData());
                setFormValues({});
                setForcastData([
                    {
                        humanforecasts: "",
                    },
                ]);
                setIsCreate(false);
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
                setIsCreate(true);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <AppBar position="static" sx={{ backgroundColor: "white" }}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                        {/* {getPageHeading()} */}
                        Forecast Profile
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            onClick={handleCancel}
                            title="Cancel"
                            style={{
                                padding: "6px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                marginRight: "10px",
                                fontFamily: "'Poppins', sans-serif"
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            title="Create new dimension with selected attributes"
                            style={{
                                padding: "7px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                fontFamily: "'Poppins', sans-serif"
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label="Overview" value="1" sx={{ textTransform: "none" }} />
                        {formValues?.statisticalForecasting && (
                            <Tab label="Statistical Forecasting" value="2" sx={{ textTransform: "none" }} />
                        )}
                        {formValues?.driverBasedForecasting && (
                            <Tab label="Driver Based Forecasting" value="3" sx={{ textTransform: "none" }} />)}
                        {formValues?.prescriptiveOptimalForecasting && (
                            <Tab label="Prescriptive Forecast Optimization" value="4" sx={{ textTransform: "none" }} />)}
                        {formValues?.aiBlendForecasting && (
                            <Tab label="AI Blend Forecasting" value="5" sx={{ textTransform: "none" }} />)}
                        <Tab label="Administrative Info" value="6" sx={{ textTransform: "none" }} />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <OverviewTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        enterpriseData={enterpriseData}
                        modalName={modalName}
                        timeData={timeData}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        handleChangehierarchicalForecasting={handleChangehierarchicalForecasting}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <ExtrapolativeTab
                        formValues={formValues}
                        handleChangeProfile={handleChangeProfile}
                        profileMeasure={profileMeasure}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        basicKPI={basicKPI}
                        handleChangeBasicKPI={handleChangeBasicKPI}
                        handleAddbasicKPI={handleAddbasicKPI}
                        setBasicKPI={setBasicKPI}
                        deletebasicKPI={deletebasicKPI}
                        anchorBasicKPIl={anchorBasicKPIl}
                        setAnchorBasicKPIEl={setAnchorBasicKPIEl}
                    />
                </TabPanel>
                <TabPanel value="3">
                    <PredictiveTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        driverBasedMeasure={driverBasedMeasure}
                        deleteDemandDriven={deleteDemandDriven}
                        anchorMeasureEl={anchorMeasureEl}
                        setAnchorMeasureEl={setAnchorMeasureEl}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        handleChangeDriverBasedMeasure={handleChangeDriverBasedMeasure}
                        handleAddDriverMeasure={handleAddDriverMeasure}
                        handleAddPredictivebasicKPI={handleAddPredictivebasicKPI}
                        deletePredictiveBasicKPI={deletePredictiveBasicKPI}
                        predictiveBasicKPI={predictiveBasicKPI}
                        anchorPredictiveBasicKPIEl={anchorPredictiveBasicKPIEl}
                        setAnchorPredictiveBasicEl={setAnchorPredictiveBasicEl}
                        handleChangePredictiveBasicKPI={handleChangePredictiveBasicKPI}
                        predictiveAdditionalKPI={predictiveAdditionalKPI}
                        setPredictiveAdditionalKPI={setPredictiveAdditionalKPI}
                        handleChangePredictiveAdditionalBasicKPI={handleChangePredictiveAdditionalBasicKPI}
                        anchorPredictiveAdditionalKPIEl={anchorPredictiveAdditionalKPIEl}
                        setAnchorPredictiveAdditionalKPIEl={setAnchorPredictiveAdditionalKPIEl}
                        deletePredictiveAdditionalKPI={deletePredictiveAdditionalKPI}
                        handleAddPredictiveAdditionalKPI={handleAddPredictiveAdditionalKPI}
                    />
                </TabPanel>
                <TabPanel value="4">
                    <PrescriptiveTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        handleAddOptimizationMeasure={handleAddOptimizationMeasure}
                        optimizationMeasure={optimizationMeasure}
                        handleChangeOptimizationMeasure={handleChangeOptimizationMeasure}
                        anchorOptimizationMeasureEl={anchorOptimizationMeasureEl}
                        setAnchorOptimizationMeasureEl={setAnchorOptimizationMeasureEl}
                        deleteOptimization={deleteOptimization}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        deleteConstraintsMeasure={deleteConstraintsMeasure}
                        prescriptiveconstraintsMeasure={prescriptiveconstraintsMeasure}
                        anchorConstraintsMeasureEl={anchorConstraintsMeasureEl}
                        setAnchorConstraintsMeasureEl={setAnchorConstraintsMeasureEl}
                        handleAddConstraintsMeasure={handleAddConstraintsMeasure}
                        setPrescriptiveConstraintsMeasure={setPrescriptiveConstraintsMeasure}
                        prescriptiveOptimal={prescriptiveOptimal}
                        anchorPrescriptiveEnsembleEl={anchorPrescriptiveEnsembleEl}
                        setAnchorPrescriptiveEnsembleEl={setAnchorPrescriptiveEnsembleEl}
                        handleChangePrescriptive={handleChangePrescriptive}
                        deletePrescriptiveEnsemble={deletePrescriptiveEnsemble}
                        handleAddPrescriptive={handleAddPrescriptive}
                    />
                </TabPanel>
                <TabPanel value="5">
                    <AiEnsembleTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        aiEnsembleBasicKPI={aiEnsembleBasicKPI}
                        anchorAiEnsembleEl={anchorAiEnsembleEl}
                        setAnchorAiEnsembleEl={setAnchorAiEnsembleEl}
                        deleteAiEnsemble={deleteAiEnsemble}
                        handleAddAiEnsembleKPI={handleAddAiEnsembleKPI}
                        handleChangeAiEnsembleBasicKPI={handleChangeAiEnsembleBasicKPI}
                        handleAdd={handleAdd}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        forcastData={forcastData}
                        handleChangeForeCastProfile={handleChangeForeCastProfile}
                        deleteForCast={deleteForCast}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                    />
                </TabPanel>
                <TabPanel value="6">
                    <AdministrativeTab
                        formValues={formValues}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                    />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default CreateProfile;