import { Autocomplete, Box, Input, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle'
import { ThemeContext } from '../../ThemeContext';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CardHeader from '@mui/material/CardHeader';
import { useDispatch } from 'react-redux';
import { getUserList, updateUserInfo } from '../../store/ApiSlice/userSlice';
import { useSelector } from 'react-redux';
import { getEnterpriseList } from '../../store/ApiSlice/enterpriseSlice';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

const UserAccount = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const profileData = localStorage.getItem("accessToken")
    const userProfile = JSON.parse(profileData)
    const formattedData = {
        phoneNumber: userProfile?.data?.workPhone?.countryCode ? userProfile?.data?.workPhone?.countryCode?.replace('+', '') + userProfile?.data?.workPhone?.phoneNumber?.toString() : "",
        countryCode: userProfile?.data?.workPhone?.countryCode ? userProfile?.data?.workPhone?.countryCode?.replace('+', '') : ""
    };
    const formattedMobileData = {
        phoneNumber: userProfile?.data?.mobilePhone?.countryCode ? userProfile?.data?.mobilePhone?.countryCode?.replace('+', '') + userProfile?.data?.mobilePhone?.phoneNumber?.toString() : "",
        countryCode: userProfile?.data?.mobilePhone?.countryCode ? userProfile?.data?.mobilePhone?.countryCode?.replace('+', '') : ""
    };
    const [formvalues, setFormValues] = useState({
        email: userProfile?.data?.email,
        department: userProfile?.data?.department,
        title: userProfile?.data?.title,
        functions: userProfile?.data?.functions,
        dateFormat: userProfile?.data?.dateFormat,
        photo: userProfile?.data?.photo,
        country: userProfile?.data?.country,
        city: userProfile?.data?.city,
        streetAddress: userProfile?.data?.streetAddress,
        workPhone: {
            countryCode: formattedData?.countryCode,
            phoneNumber: formattedData?.phoneNumber
        },
        mobilePhone: {
            countryCode: formattedMobileData?.countryCode,
            phoneNumber: formattedMobileData?.phoneNumber
        },
    })

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserList())
        dispatch(getEnterpriseList())
    }, [])
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setFormValues({
            ...formvalues,
            photo: file
        })
    };

    const handleUploadImage = async () => {
        if (typeof formvalues?.photo === "object") {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const file = formvalues.photo;
            const formdata = new FormData()
            formdata.append('file', formvalues?.photo);
            try {
                const response = await axios.post(`${API_BASE_URL}/upload`, formdata);
                handleUpdateUserInfo(response?.data?.data?.file_path)
            } catch (error) {
                toast.error('Only .png, .jpeg, and .jpg files are allowed!')
            }
        } else {
            handleUpdateUserInfo("")
        }
    }

    const handleUpdateUserInfo = async (data) => {
        const countryDialCode = `${formvalues?.workPhone?.countryCode}`;
        let phoneWithoutCountryCode = formvalues?.workPhone?.phoneNumber;
        if (formvalues?.workPhone?.phoneNumber?.startsWith(countryDialCode)) {
            phoneWithoutCountryCode = formvalues?.workPhone?.phoneNumber?.slice(countryDialCode?.length).trim();
        }
        const mobileCountryCode = `${formvalues?.mobilePhone?.countryCode}`;
        let numberWithOutCountryCode = formvalues?.mobilePhone?.phoneNumber;
        if (formvalues?.mobilePhone?.phoneNumber?.startsWith(mobileCountryCode)) {
            numberWithOutCountryCode = formvalues?.mobilePhone?.phoneNumber?.slice(mobileCountryCode?.length).trim();
        }
        const body = {
            ...(formvalues?.workPhone?.countryCode &&
                formvalues?.workPhone?.countryCode !== "+undefined" &&
                phoneWithoutCountryCode && phoneWithoutCountryCode !== "undefined" && ({
                    workPhone: {
                        countryCode: `+${formvalues?.workPhone?.countryCode}`,
                        phoneNumber: phoneWithoutCountryCode
                    },
                })),
            ...(formvalues?.mobilePhone?.countryCode &&
                formvalues?.mobilePhone?.countryCode !== "+undefined" &&
                numberWithOutCountryCode && numberWithOutCountryCode !== "undefined" && ({
                    mobilePhone: {
                        countryCode: `+${formvalues?.mobilePhone?.countryCode}`,
                        phoneNumber: numberWithOutCountryCode
                    },
                })),
            ...(formvalues?.streetAddress && ({
                streetAddress: formvalues?.streetAddress,
            })),
            ...(formvalues?.city && ({
                city: formvalues?.city,
            })),
            ...(formvalues?.country && ({
                country: formvalues?.country,
            })),
            ...(data && ({
                photo: data
            })),
            ...(formvalues?.title && ({
                title: formvalues?.title
            })),
            ...(formvalues?.department && ({
                department: formvalues?.department,
            })),
            ...(formvalues?.functions && ({
                functions: formvalues?.functions,
            })),
            ...(formvalues?.dateFormat && ({
                dateFormat: formvalues?.dateFormat
            }))
        }

        const responce = await dispatch(updateUserInfo(body))
        if (responce?.meta?.requestStatus === "fulfilled") {
            // toast.success(responce?.payload?.message)
        } else if (responce?.meta?.requestStatus === "rejected") {
            toast.error(responce?.error?.message);
        }
    }
    return (
        <>
            <Stack spacing={3}>
                <div>
                    <h3 className="heading">User Data</h3>
                </div>
                <Grid container spacing={3}>
                    <Grid lg={4} md={6} xs={12}>
                        <Card sx={{ height: "253px" }}>
                            <CardContent>
                                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                                    <div>
                                        <Avatar src={typeof formvalues?.photo === "object" ? URL.createObjectURL(formvalues?.photo) : `http://localhost:4000/uploads/${formvalues?.photo}`} sx={{ height: '80px', width: '80px' }} />
                                    </div>
                                    <Stack spacing={1} sx={{ textAlign: 'center' }}>
                                        <Typography variant="h5">{userProfile?.data?.userName}</Typography>
                                        <Typography color="text.secondary" variant="body2">
                                            {formvalues?.city} {formvalues?.country}
                                        </Typography>
                                        <Typography color="text.secondary" variant="body2">
                                            {userProfile?.data?.timezone}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                            <Divider />
                            <CardActions sx={{ justifyContent: "center" }}>
                                <label htmlFor="file-upload" style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                    <Input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        sx={{ display: 'none' }}
                                    />
                                    <Button
                                        component="span"
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            color: '#6720C2',
                                            borderColor: '#6720C2',
                                            '&:hover': {
                                                borderColor: '#6720C2',
                                                backgroundColor: '#f0e7ff'
                                            },
                                            fontWeight: 'medium',
                                            padding: '6px 16px',
                                            borderRadius: '9999px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid lg={8} md={6} xs={12}>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <Card sx={{ borderRadius: "10px" }}>
                                <CardHeader subheader="The information can be edited" title="Profile" sx={{
                                    '&::MuiCardHeader-title': {
                                        fontWeight: 600,
                                        fontFamily: `'Poppins', sans-serif`
                                    }
                                }} />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth required>
                                                <InputLabel>First name</InputLabel>
                                                <OutlinedInput value={userProfile?.data?.firstName} label="First name" name="firstName" />
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth required>
                                                <InputLabel>Last name</InputLabel>
                                                <OutlinedInput value={userProfile?.data?.lastName} label="Last name" name="lastName" />
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth required>
                                                <InputLabel>Email address</InputLabel>
                                                <OutlinedInput value={formvalues?.email} label="Email address" name="email" disabled={!userProfile?.data?.superAdmin} />
                                            </FormControl>
                                        </Grid>
                                        {userProfile?.data?.enterprise && (
                                            <Grid md={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Enterprise Name"
                                                        name="enterpriseName"
                                                        options={
                                                            enterpriseData?.length > 0
                                                                ? enterpriseData
                                                                : userProfile?.data?.enterprise
                                                                    ? [userProfile.data.enterprise]
                                                                    : []
                                                        }
                                                        getOptionLabel={(option) => option.enterpriseName}
                                                        // value={
                                                        //     userProfile?.data?.enterprise?._id
                                                        //         ? enterpriseData.find(
                                                        //             (option) =>
                                                        //                 option._id === userProfile?.data?.enterprise?._id
                                                        //         ) || null
                                                        //         : null
                                                        // }
                                                        value={
                                                            enterpriseData?.length > 0
                                                                ? enterpriseData?.find((option) => option._id === userProfile?.data?.enterprise?._id) || null
                                                                : userProfile?.data?.enterprise?.enterpriseName
                                                        }
                                                        disabled={!userProfile?.data?.superAdmin}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Enterprise Name"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {formvalues?.phoneNumber && (
                                            <Grid md={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Phone number</InputLabel>
                                                    <OutlinedInput label="Phone number" value={userProfile?.data?.phoneNumber} name="phone" type="tel" />
                                                </FormControl>
                                            </Grid>
                                        )}

                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Department</InputLabel>
                                                <OutlinedInput
                                                    label="Department"
                                                    value={formvalues?.department}
                                                    name="department"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            department: e.target.value
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Title</InputLabel>
                                                <OutlinedInput
                                                    label="Title"
                                                    value={formvalues?.title}
                                                    name="title"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            title: e.target.value
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Functions</InputLabel>
                                                <OutlinedInput
                                                    label="Functions"
                                                    value={formvalues?.functions}
                                                    name="functions"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            functions: e.target.value
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Date Format</InputLabel>
                                                <Select
                                                    id="type"
                                                    label="Date Format"
                                                    name="dateFormat"
                                                    value={formvalues?.dateFormat || ""}
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            dateFormat: e.target.value
                                                        })
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <MenuItem value="" disabled selected>
                                                        Select Date Format Type
                                                    </MenuItem>
                                                    <MenuItem value="MM/DD/YY">MM/DD/YY</MenuItem>
                                                    <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                                                    <MenuItem value="MM-DD-YY">MM-DD-YY</MenuItem>
                                                    <MenuItem value="MM-DD-YYYY">MM-DD-YYYY</MenuItem>
                                                    <MenuItem value="DD/MM/YY">DD/MM/YY</MenuItem>
                                                    <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                                                    <MenuItem value="DD-MM-YY">DD-MM-YY</MenuItem>
                                                    <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <label htmlFor="phoneInput" style={{ display: "block", marginBottom: "0.5rem" }}>
                                                Work Phone Number
                                            </label>
                                            <PhoneInput
                                                country={'us'}
                                                value={formvalues?.workPhone?.phoneNumber}
                                                onChange={(phone, countryData) => {
                                                    const countryDialCode = `+${countryData?.dialCode}`;
                                                    let phoneWithoutCountryCode = phone;
                                                    if (phone.startsWith(countryDialCode)) {
                                                        phoneWithoutCountryCode = phone.slice(countryDialCode.length).trim();
                                                    }

                                                    setFormValues({
                                                        ...formvalues,
                                                        workPhone: {
                                                            phoneNumber: phoneWithoutCountryCode,
                                                            countryCode: countryData?.dialCode
                                                        }
                                                    });
                                                }}
                                                inputStyle={{ width: "100%", height: "50px" }}
                                            />
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <label htmlFor="phoneInput" style={{ display: "block", marginBottom: "0.5rem" }}>
                                                Mobile Phone Number
                                            </label>
                                            <PhoneInput
                                                country={'us'}
                                                value={formvalues?.mobilePhone?.phoneNumber}
                                                onChange={(phone, countryData) => {
                                                    const countryDialCode = `+${countryData?.dialCode}`;
                                                    let phoneWithoutCountryCode = phone;
                                                    if (phone.startsWith(countryDialCode)) {
                                                        phoneWithoutCountryCode = phone.slice(countryDialCode.length).trim();
                                                    }

                                                    setFormValues({
                                                        ...formvalues,
                                                        mobilePhone: {
                                                            phoneNumber: phoneWithoutCountryCode,
                                                            countryCode: countryData?.dialCode
                                                        }
                                                    });
                                                }}
                                                inputStyle={{ width: "100%", height: "50px" }}
                                            />
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>State</InputLabel>
                                                <OutlinedInput
                                                    label="State"
                                                    value={formvalues?.streetAddress}
                                                    name="streetAddress"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            streetAddress: e.target.value
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>City</InputLabel>
                                                <OutlinedInput
                                                    label="City"
                                                    value={formvalues?.city}
                                                    name="city"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            city: e.target.value
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid md={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Country</InputLabel>
                                                <OutlinedInput
                                                    label="Country"
                                                    value={formvalues?.country}
                                                    name="country"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setFormValues({
                                                            ...formvalues,
                                                            country: e.target.value
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions sx={{ justifyContent: 'flex-end' }}>
                                    <Button variant="contained" sx={{ textTransform: "none", fontFamily: "'Poppins', sans-serif" }} onClick={handleUploadImage}>Save details</Button>
                                </CardActions>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

export default UserAccount
