import { Box, Button, FormControl, InputLabel, MenuItem, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CommonInput from '../components/commonComponent/input/CommonInput';
import { Select as MuiSelect } from '@mui/material';
import { CycleIdData, MeasureDataFirst, MeasureDataSecond } from '../components/canvas/shared/MeasureData';
import { useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { fetchDimensions } from '../store/ApiSlice/dimensionSlice';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { createWorkBookDetail, getHomePageWidget, getPrimaryTimeDimension, getSchemaData, getWorkBookDetail, setDropped } from '../store/ApiSlice/canvasSlice';
import { getCycleIdList, setChartPending, setPending } from '../store/ApiSlice/widgetSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CommonSelect } from '../components/commonComponent/commonSelect/CommonSelect';

export const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '100%',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#0366d6' : 'white',
        color: state.isSelected ? 'white' : 'black',
    }),
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

const EditWidget = ({
    widgetId,
    setEditWidgetModal,
    editWidgetModal
}) => {
    const { measureData, measurePaginationList } = useSelector((state) => state.measure)
    const { allDimensions } = useSelector((state) => state.dimension)
    const { scenarioList } = useSelector((state) => state.process);
    const { schemaData, timeDimensionData, filterDetail, dropped, layoutData } = useSelector((state) => state.canvas)
    const { cycleIDList, pending, chartPending } = useSelector((state) => state.widget);
    const [droppedLayout, setDroppedLayout] = useState([])
    const [pendingLayout, setPendingLayout] = useState([])
    const [timePeriodData, setTimePeriodData] = useState([]);
    const widgetType = widgetId?.widgetType === "Chart" ? widgetId?.chart : widgetId?.worksheet
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPrimaryTimeDimension({ canvasFindData }))
        dispatch(fetchDimensions({ enterpriseId: formValues?.enterpriseUID, modelId: formValues?.modelUID }))
        dispatch(getSchemaData({ timeDimensionData }))
        dispatch(getCycleIdList({ canvasFindData }))
    }, [])

    const findperiodType =
        schemaData?.length > 0 &&
        schemaData?.filter(
            (item) => item['Period Base Level'] === widgetType?.timePeriods?.periodType
        );

    const validFromDate =
        findperiodType &&
        findperiodType?.find(
            (option) =>
                moment(option['Period Start']).format('YYYY-MM-DD') ===
                moment(widgetType?.timePeriods?.from).format('YYYY-MM-DD')
        );

    const validToDate =
        findperiodType &&
        findperiodType?.find(
            (option) =>
                moment(option['Period End']).format('YYYY-MM-DD') ===
                moment(widgetType?.timePeriods?.to)?.format('YYYY-MM-DD')
        );
    const findTime = timeDimensionData?.timeSchemaFields?.find(
        (data) => data['Period Type'] === widgetType?.timePeriods?.periodType
    );

    const findCycleId =
        widgetId?.cycleID?.length > 0 &&
        cycleIDList?.filter((item) =>
            widgetId?.cycleID?.some((row) => row === item['Cycle ID'])
        );
    const findScenario =
        scenarioList?.length > 0 &&
        scenarioList?.filter((item) =>
            widgetId?.scenario?.map(
                (row) => row === item['Scenario Name']
            )
        );

    const [formValues, setFormValues] = useState({
        widgetName: widgetId?.widgetName,
        widgetType: widgetId?.widgetType,
        enterpriseUID: widgetId?.enterpriseUID,
        modelUID: widgetId?.modelUID,
        measures: widgetType?.measures,
        attributes: widgetType?.attributes,
        timePeriod: findTime?._id,
        timePeriodName: widgetType?.timePeriods?.periodType,
        workbookNotes: widgetId?.notes,
        filter: widgetId?.filter,
        ...((widgetId?.widgetSubType === 'Measure Data' || widgetId?.widgetType === "Chart") && ({
            validFrom: validFromDate['Time Period Name'],
            validTo: validToDate['Time Period Name'],
            startDate: moment(widgetType?.timePeriods?.from).format('YYYY-MM-DD'),
            endDate: moment(widgetType?.timePeriods?.to).format('YYYY-MM-DD'),
        })),
        ...(widgetId?.widgetType === "Chart" && ({
            ...widgetId?.chart?.chartSettings,
            type: widgetId?.chart?.chartSettings?.type,
            orientation: widgetId?.chart?.chartSettings?.orientation,
            measureChartType: widgetType?.chartSettings?.measure
        })),
        ...(widgetId?.widgetType === "Worksheet" && ({
            widgetGroup: widgetId?.widgetGroup,
            widgetSubType: widgetId?.widgetSubType,
            dimension: widgetType?.worksheet?.dimension
        })),
        scenario:
            findScenario?.length > 0 &&
            findScenario?.map((item) => ({
                _id: item?._id,
                scenario: item['Scenario Name'],
            })),
        cycleId:
            findCycleId?.length > 0 &&
            findCycleId?.map((item) => ({
                _id: item?._id,
                cyclePeriodicity: item['Cycle ID'],
            })),
    })

    const findMeasure = measurePaginationList?.filter((item) => item?.enterpriseUID === formValues?.enterpriseUID
        && item?.modelUID === formValues?.modelUID)
    const findDiemnsion = allDimensions?.find((item) => item?._id === formValues?.dimension)
    const findMeasureLagId = measureData?.filter((item) =>
        Array.isArray(formValues?.measures) &&
        formValues.measures.some((row) => row?.measureUID === item?._id)
    );
    const removeDuplicates = (array) => {
        const map = new Map();
        return (
            array?.length > 0 &&
            array?.reduce((acc, item) => {
                const key = JSON.stringify(item.name);
                if (!map.has(key)) {
                    map.set(key, true);
                    acc.push(item);
                }
                return acc;
            }, [])
        );
    };
    const uniqueItems = removeDuplicates(
        findMeasureLagId?.flatMap((item) => item?.baselevelUID?.fields || [])
    );
    const findDiemnsionAttribute =
        formValues?.widgetSubType === 'Dimension Data'
            ? findDiemnsion?.fields
            : uniqueItems;
    const findLagId =
        findMeasureLagId?.length > 0 &&
        (formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') &&
        findMeasureLagId?.filter((row) =>
            row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
        );
    const findTimePeriod = schemaData?.length > 0 && formValues?.timePeriodName && schemaData?.filter((item) => item["Period Base Level"] === formValues?.timePeriodName);
    const canvasFindData = {
        enterpriseUID: {
            _id: formValues?.enterpriseUID
        },
        modelUID: {
            modalUID: formValues?.modelUID
        }
    }
    const now = new Date();
    const startOfMonth = moment(
        new Date(now.getFullYear(), now.getMonth(), 1)
    ).format('YYYY-DD-MM');
    const endOfMonth = moment(
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
    ).format('YYYY-MM-DD');
    const findCycleIdMonth =
        findLagId?.length > 0 && cycleIDList?.length > 0
            ? cycleIDList?.filter((item) => {
                const validFromDate = moment(item['Cycle Start']).format(
                    'YYYY-MM-DD'
                );
                const validToDate = moment(item['Cycle End'], 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                );
                return startOfMonth <= validFromDate && endOfMonth >= validToDate;
            })
            : [];


    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value
        })
    }

    const handleChangeAttribute = (data, type) => {

        const selectedAttribute = data?.length > 0 && data?.map((item) => {
            if (type === "measures") {
                return {
                    measureName: item?.label,
                    measureUID: item?.value,
                    enterpriseUID: item?.enterpriseUID,
                    modelUID: item?.modelUID,
                    baselevelUID: item?.baselevelUID,
                }
            } else if (type === 'attributes') {
                const dataItem = findDiemnsion?.primaryKey?.find(
                    (row) => row?._id === item?.value
                );
                return {
                    attributeName: item?.label,
                    attributeUID: item?.value,
                    ...(dataItem && {
                        isFixed: true,
                    }),
                };
            } else if (type === 'scenario') {
                return {
                    _id: item?.value,
                    scenario: item?.label,
                };
            } else if (type === 'cycleId') {
                return {
                    _id: item?.value,
                    cyclePeriodicity: item?.label,
                    isFixed: true,
                };
            }
        })
        setFormValues((prevState) => ({
            ...prevState,
            [type]: selectedAttribute
        }))

    }

    const attributeNames =
        formValues?.attributes?.length > 0
            ? formValues?.attributes.map((item) => item.attributeName)
            : [];

    const formatTimePeriods = (timePeriods) => {
        return (
            (formValues?.widgetSubType === 'Measure Data' ||
                formValues?.widgetType === 'Chart') &&
            `Time Periods (${timePeriods})`
        );
    };
    const timePeriodsFormatted = formatTimePeriods(formValues?.timePeriodName);

    const mergeLayoutData = [
        ...((formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') &&
            findLagId?.length > 0
            ? ['Cycle ID']
            : []),
        ...attributeNames,
        ...(formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart'
            ? ['Measure Name']
            : []),
        ...(formValues?.widgetType === 'Chart' ? [timePeriodsFormatted] : []),
        'Scenario',
    ];

    const otherColumns =
        mergeLayoutData?.length > 0 &&
        mergeLayoutData
            .filter((key) => key !== 'Aggregation Mode')
            .filter((key) => key !== '_id')
            .map((key, index) => ({
                id: index,
                headerName: key,
                field: key,
                done: false,
                editable: true,
                width: 150,
            }));

    const columnDefs = [];

    if (otherColumns?.length > 0) {
        columnDefs.push(otherColumns[0]);

        columnDefs.push(...otherColumns.slice(1));
    }

    useEffect(() => {
        // if (formValues?.widgetType === "Worksheet") {
        let columnDefsMap = {};
        columnDefs?.forEach((def) => {
            columnDefsMap[def.id] = def;
        });
        let widgetLayoutType = formValues?.widgetType === "Worksheet" ? pending : chartPending
        let sortedColumnDefs =
            widgetLayoutType?.length > 0
                ? widgetLayoutType
                    ?.map((item) => columnDefsMap[item.id])
                    .filter((def) => def !== undefined)
                : columnDefs;
        setPendingLayout(sortedColumnDefs);
        const defaultItem = {
            id: pendingLayout?.length,
            headerName: timePeriodsFormatted,
            field: timePeriodsFormatted,
            done: timePeriodsFormatted,
            editable: timePeriodsFormatted,
            width: 150,
        };
        if (formValues?.widgetType === 'Worksheet') {
            if (dropped?.length > 0) {
                setDroppedLayout(dropped);
            } else {
                setDroppedLayout([...dropped, defaultItem]);
            }
        }
    }, [
        formValues,
        formValues?.attributes,
        formValues?.cycleId,
        pending,
    ]);

    const handleDragStart = (e, id, from) => {
        e.dataTransfer.setData('text/plain', id);
        e.dataTransfer.setData('from', from);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, target) => {
        e.preventDefault();
        const id = e.dataTransfer.getData('text/plain');
        const from = e.dataTransfer.getData('from');
        const itemId = parseInt(id, 10);

        if (from === 'pending' && target === 'dropped') {
            const item = pendingLayout.find((item) => item.id === itemId);
            if (item) {
                setPendingLayout(pendingLayout.filter((item) => item.id !== itemId));
                setDroppedLayout([...droppedLayout, item]);
            }
        } else if (from === 'dropped' && target === 'pending') {
            const item = droppedLayout.find((item) => item.id === itemId);
            if (item) {
                setDroppedLayout(droppedLayout.filter((item) => item.id !== itemId));
                setPendingLayout([...pendingLayout, item]);
            }
        }
    };
    const handleReorder = (e, index, target) => {
        e.preventDefault();

        const id = e.dataTransfer.getData('text/plain');
        const from = e.dataTransfer.getData('from');
        const itemId = parseInt(id, 10);

        let sourceArray, setSourceArray;

        if (from === 'pending') {
            sourceArray = [...pendingLayout];
            setSourceArray = setPendingLayout;
        } else if (from === 'dropped') {
            sourceArray = [...droppedLayout];
            setSourceArray = setDroppedLayout;
        } else {
            return;
        }

        const item = sourceArray?.find((item) => item.id === itemId);

        if (item) {
            sourceArray =
                sourceArray?.length > 0
                    ? sourceArray?.filter((item) => item.id !== itemId)
                    : [];
            const targetArray = [...sourceArray];
            const targetIndex = Math?.max(0, Math?.min(index, targetArray?.length));
            targetArray?.splice(targetIndex, 0, item);
            setSourceArray(targetArray);
        }
    };

    const droppedFields =
        droppedLayout?.length > 0 && droppedLayout?.map((col) => col?.field);
    const data =
        droppedLayout?.length > 0
            ? pendingLayout?.filter((row) => !droppedFields?.includes(row.field))
            : pendingLayout?.filter((row) => !layoutData?.includes(row.field));

    const dataTimePeriod = () => {
        const data =
            findTimePeriod?.length > 0 &&
            findTimePeriod?.filter((item) => {
                const validFromDate = moment(item['Period Start']).format('YYYY-MM-DD');
                const validToDate = moment(item['Period End']).format('YYYY-MM-DD');
                const startDate = moment(formValues?.startDate).format(
                    'YYYY-MM-DD'
                );
                const endDate = moment(formValues?.endDate).format('YYYY-MM-DD');
                return startDate <= validFromDate && endDate >= validToDate;
            });
        setTimePeriodData(data);
    };

    useEffect(() => {
        if (formValues?.validFrom && formValues?.validTo) {
            dataTimePeriod();
        }
    }, [formValues?.validFrom, formValues?.validTo]);

    const validation = () => {
        const findLagId = findMeasureLagId?.filter((row) =>
            row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
        );
        if (findLagId?.length > 0 && !formValues?.cycleId) {
            toast.error('Please select cycle Id');
            return true;
        } else if (
            formValues?.widgetName === '' ||
            formValues?.widgetName === undefined
        ) {
            toast.error('Please enter widget name');
            return true;
        } else if (
            formValues?.widgetType === 'Worksheet' &&
            (formValues?.widgetGroup === '' || formValues?.widgetGroup === undefined)
        ) {
            toast.error('Please enter widget group');
            return true;
        } else if (
            formValues?.widgetType === 'Worksheet' &&
            (formValues?.widgetSubType === '' ||
                formValues?.widgetSubType === undefined)
        ) {
            toast.error('Please select widget type');
            return true;
        } else if (
            formValues?.widgetSubType === 'Dimension Data' &&
            (formValues?.dimension === '' ||
                formValues?.dimension === undefined)
        ) {
            toast.error('Please select dimension');
            return true;
        } else if (
            (formValues?.widgetSubType === 'Measure Data' ||
                formValues?.widgetType === 'Chart') &&
            !formValues?.measures
        ) {
            toast.error('Please select measure');
            return true;
        } else if (!formValues?.attributes) {
            toast.error('Please select attribute');
            return true;
        }
        return false;
    };

    const handleSubmitWorkbook = async () => {
        if (validation()) {
            return;
        } else {
            const widgetkey =
                formValues?.widgetType === 'Worksheet' ? 'worksheet' : 'chart';
            const initialColors =
                formValues?.measures?.length > 0 &&
                formValues?.measures?.reduce((acc, item) => {
                    if (item?.measureName) {
                        acc[item.measureName] = formValues
                            ? formValues[item.measureName]
                            : 'rgba(0, 100, 200, 0.3)';
                    }
                    return acc;
                }, {});

            const payload = {
                widgetName: formValues?.widgetName,
                enterpriseUID: widgetId?.enterpriseUID,
                modelUID: widgetId?.modelUID,
                canvasUID: widgetId?.canvasUID,
                pageUID: widgetId?.pageUID,
                notes: formValues?.workbookNotes,
                widgetType: formValues?.widgetType,
                widgetGroup: formValues?.widgetType === "Worksheet" ? formValues?.widgetGroup : formValues?.widgetName,
                ...(formValues?.widgetType === 'Worksheet' && {
                    widgetSubType: formValues?.widgetSubType,
                }),
                ...(formValues?.filter !== null && {
                    filter: formValues?.filter,
                }),
                [widgetkey]: {
                    ...(formValues?.widgetSubType === 'Dimension Data' && {
                        dimension: formValues?.dimension,
                    }),
                    ...((formValues?.widgetSubType === 'Measure Data' ||
                        formValues?.widgetType === 'Chart') &&
                        formValues?.measures?.length > 0 && {
                        measures: formValues?.measures?.map((item) => ({
                            measureName: item?.measureName,
                            measureUID: item?.measureUID,
                            baselevelUID: item?.baselevelUID,
                            enterpriseUID: item?.enterpriseUID,
                            modelUID: item?.modelUID,
                        })),
                    }),
                    ...(formValues?.attributes?.length > 0 && {
                        attributes: formValues?.attributes?.map((item) => ({
                            attributeName: item?.attributeName,
                            attributeUID: item?.attributeUID,
                        })),
                    }),
                    ...((formValues?.widgetSubType === 'Measure Data' ||
                        formValues?.widgetType === 'Chart') &&
                        timePeriodData?.length > 0 && {
                        timePeriods: {
                            periodType: formValues?.timePeriodName,
                            periodValues: timePeriodData?.map((item) => {
                                return item['Time Period Name'];
                            }),
                            ...(formValues?.startDate && {
                                from: moment(formValues?.startDate)?.format(
                                    'YYYY-MM-DD'
                                ),
                            }),
                            ...(formValues?.endDate && {
                                to: moment(formValues?.endDate)?.format('YYYY-MM-DD'),
                            }),
                        },
                    }),
                    ...(formValues?.widgetType === 'Chart' && {
                        chartSettings: {
                            type: formValues?.type,
                            orientation: formValues?.orientation ? formValues?.orientation : "vertical",
                            ...((formValues?.type === 'arearange' ||
                                formValues?.type === 'spline' ||
                                formValues?.type === 'bar' ||
                                formValues?.type === 'area' ||
                                formValues?.type === 'scatter' ||
                                formValues?.type === 'areaspline' ||
                                formValues?.type === 'combination'
                            ) && {
                                ...initialColors,
                            }),
                            ...(formValues?.type === 'arearange' && {
                                areaShadeColor: formValues?.areaShadeColor,
                            }),
                            ...(formValues?.type === 'combination' && {
                                measure: {
                                    ...formValues?.measureChartType,
                                },
                            }),
                        },
                    }),
                },
                scenario:
                    formValues?.scenario?.length > 0 &&
                    formValues?.scenario?.map((item) => item?.scenario),
                ...(formValues?.cycleId?.length > 0 &&
                    findLagId?.length > 0 && {
                    cycleID:
                        formValues?.cycleId?.length > 0 &&
                        formValues?.cycleId?.map((item) => item?.cyclePeriodicity),
                }),
                startCoordinates: {
                    x: 9,
                    y: 4,
                },
                endCoordinates: {
                    x: 0,
                    y: 0,
                },
            };
            const result = await dispatch(
                createWorkBookDetail({
                    payload,
                    openModal: editWidgetModal,
                    updatedId: widgetId?._id,
                })
            );
            if (result?.meta?.requestStatus === 'fulfilled') {
                if (droppedLayout?.length > 0) {
                    dispatch(setDropped(droppedLayout));
                }
                if (result?.payload?.data?.widgetType === "Worksheet") {
                    dispatch(setPending(pendingLayout));
                } else if (result?.payload?.data?.widgetType === "Chart") {
                    dispatch(setChartPending(pendingLayout))
                }
                dispatch(getWorkBookDetail());
                dispatch(getHomePageWidget())
                setEditWidgetModal(false)
                // toast.success('widget updated successfully');
            } else if (result?.meta?.requestStatus === 'rejected') {
                toast.error(result?.error?.message);
            }
        }
    }


    return (
        <>
            <Box
                sx={{
                    boxShadow: 3,
                }}
            >
                <div style={{ padding: '10px 10px' }}>Edit Widget</div>
                <Box sx={{ padding: '20px' }}>
                    <FormControl
                        fullWidth
                        style={{ paddingBottom: '25px', marginTop: '5px' }}
                    >
                        <CommonInput
                            name="widgetName"
                            value={formValues?.widgetName || ''}
                            label="Widget Name"
                            style={{ padding: 0 }}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {formValues?.widgetType === 'Worksheet' && (
                        <FormControl
                            fullWidth
                            style={{ paddingBottom: '25px', marginTop: '5px' }}
                        >
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={formValues?.widgetType || ''}
                                label="Widget Type"
                                style={{ padding: 0 }}
                            />
                        </FormControl>
                    )}
                    {formValues?.widgetType === 'Chart' && (
                        <FormControl
                            fullWidth
                            style={{ paddingBottom: '25px', marginTop: '5px' }}
                        >
                            <InputLabel id="type">Chart Type</InputLabel>
                            <MuiSelect
                                labelId="type"
                                name="type"
                                id="demo-simple-select-readonly"
                                defaultValue={formValues?.type}
                                value={formValues?.type || ''}
                                fullWidth
                                onChange={handleChange}
                                label="Chart Type"
                                variant="outlined"
                            >
                                <MenuItem value="bar">Bar Chart</MenuItem>
                                <MenuItem value="spline">Line Chart</MenuItem>
                                <MenuItem value="area">Area Chart</MenuItem>
                                <MenuItem value="pie">Pie Chart</MenuItem>
                                <MenuItem value="scatter">Scatter Plot</MenuItem>
                                <MenuItem value="bubble">Bubble Chart</MenuItem>
                                <MenuItem value="combination">Combination Chart</MenuItem>
                                <MenuItem value="areaspline">Area Spline</MenuItem>
                                <MenuItem value="heatmap">Heat Map</MenuItem>
                                <MenuItem value="treemap">Tree Map</MenuItem>
                                <MenuItem value="arearange">Area Range and Line Chart</MenuItem>
                            </MuiSelect>
                        </FormControl>
                    )}
                    {(formValues?.type === 'bar' ||
                        formValues?.type === 'spline' ||
                        formValues?.type === 'combination') && (
                            <>
                                <FormControl
                                    fullWidth
                                    style={{ paddingBottom: '25px', marginTop: '5px' }}
                                >
                                    <InputLabel id="orientation">Orientation</InputLabel>
                                    <MuiSelect
                                        labelId="orientation"
                                        name="orientation"
                                        id="demo-simple-select-readonly"
                                        defaultValue={formValues?.orientation || "vertical"}
                                        value={formValues?.orientation}
                                        fullWidth
                                        onChange={handleChange}
                                        label="Orientation"
                                        variant="outlined"
                                    >
                                        <MenuItem value="vertical">Vertical</MenuItem>
                                        <MenuItem value="horizontal">Horizontal</MenuItem>
                                    </MuiSelect>
                                </FormControl>
                            </>
                        )}

                    {formValues?.widgetType === 'Worksheet' && (
                        <>
                            <FormControl
                                fullWidth
                                style={{ paddingBottom: '25px', marginTop: '5px' }}
                            >
                                <CommonInput
                                    name="widgetGroup"
                                    value={formValues?.widgetGroup || ''}
                                    label="Widget Group"
                                    onChange={handleChange}
                                    style={{ padding: 0 }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                style={{ paddingBottom: '25px', marginTop: '5px' }}
                            >
                                <InputLabel id="type">Widget Sub-type</InputLabel>
                                <MuiSelect
                                    labelId="type"
                                    name="widgetSubType"
                                    id="demo-simple-select-readonly"
                                    defaultValue={formValues?.widgetSubType}
                                    value={formValues?.widgetSubType || ''}
                                    fullWidth
                                    onChange={handleChange}
                                    label="Widget Sub-type"
                                    variant="outlined"
                                >
                                    <MenuItem value="Dimension Data">Dimension Data</MenuItem>
                                    <MenuItem value="Measure Data">Measure Data</MenuItem>
                                </MuiSelect>
                            </FormControl>
                        </>
                    )}
                    {(formValues?.widgetSubType === 'Measure Data' ||
                        formValues?.widgetType === 'Chart') && (
                            <>
                                <MeasureDataFirst
                                    findMeasure={findMeasure}
                                    selectedOptions={formValues}
                                    handleChangeAttribute={handleChangeAttribute}
                                    widgetType={formValues?.widgetType}
                                />
                                {formValues?.widgetType === 'Chart' &&
                                    formValues?.type === 'combination' &&
                                    formValues?.measures?.length > 0 &&
                                    formValues?.measures?.map((item) => {
                                        return (
                                            <>
                                                <FormControl
                                                    fullWidth
                                                    style={{ paddingBottom: '25px', marginTop: '5px' }}
                                                >
                                                    <InputLabel id="Select Chart Type">
                                                        Select {item?.measureName} Chart Type
                                                    </InputLabel>
                                                    <MuiSelect
                                                        labelId="type"
                                                        name={item?.measureName}
                                                        id="demo-simple-select-readonly"
                                                        defaultValue={formValues?.measureChartType?.[item?.measureName] || ""}
                                                        value={formValues?.measureChartType?.[item?.measureName] || ""}
                                                        fullWidth
                                                        onChange={(e) => {
                                                            setFormValues((prevState) => ({
                                                                ...prevState,
                                                                measureChartType: {
                                                                    ...prevState.measureChartType,
                                                                    [item?.measureName]: e.target.value,
                                                                },
                                                            }));
                                                        }}
                                                        label="Select Chart Type"
                                                        variant="outlined"
                                                    >
                                                        <MenuItem value="bar">Bar Chart</MenuItem>
                                                        <MenuItem value="spline">Line Chart</MenuItem>
                                                    </MuiSelect>
                                                </FormControl>
                                            </>
                                        );
                                    })}

                                {formValues?.widgetType === 'Chart' &&
                                    (formValues?.type === 'arearange' ||
                                        formValues?.type === 'spline' ||
                                        formValues?.type === 'bar' ||
                                        formValues?.type === 'area' ||
                                        formValues?.type === 'scatter' ||
                                        formValues?.type === 'areaspline' ||
                                        formValues?.type === 'combination'
                                    ) && (
                                        <>
                                            {formValues?.type === 'arearange' && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        style={{ paddingBottom: '25px', marginTop: '5px' }}
                                                    >
                                                        <Typography id="areaShadeColor">
                                                            Select Area Shade Color
                                                        </Typography>
                                                        <SketchPicker
                                                            color={formValues?.areaShadeColor}
                                                            onChangeComplete={(newColor) => {
                                                                setFormValues((prevState) => ({
                                                                    ...prevState,
                                                                    areaShadeColor: newColor.hex,
                                                                }));
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {formValues?.measures?.length > 0 &&
                                                    formValues?.measures?.map((item) => {
                                                        return (
                                                            <>
                                                                <div>
                                                                    <FormControl
                                                                        fullWidth
                                                                        style={{
                                                                            paddingBottom: '25px',
                                                                            marginTop: '5px',
                                                                        }}
                                                                    >
                                                                        <Typography id={item?.measureName}>
                                                                            {item?.measureName}
                                                                        </Typography>
                                                                        <SketchPicker
                                                                            color={formValues?.[item?.measureName]}
                                                                            onChangeComplete={(newColor) => {
                                                                                setFormValues((prevState) => ({
                                                                                    ...prevState,
                                                                                    [item?.measureName]: newColor.hex,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                            </Box>
                                        </>
                                    )}
                            </>
                        )}

                    <hr
                        style={{
                            borderTop: '2px solid #1976d2',
                            width: '100%',
                            margin: '20px auto',
                        }}
                    />

                    {formValues?.widgetSubType === 'Dimension Data' && (
                        <FormControl
                            fullWidth
                            style={{ paddingBottom: '25px', marginTop: '5px' }}
                        >
                            <Typography sx={{ padding: '0 0 15px 0' }}>Dimension</Typography>
                            <CommonSelect
                                labelId="Dimension"
                                name="dimension"
                                options={allDimensions}
                                value={formValues?.dimension}
                                setSelectedOptions={setFormValues}
                            />
                        </FormControl>
                    )}
                    <FormControl
                        fullWidth
                        style={{ paddingBottom: '25px', marginTop: '5px' }}
                    >
                        <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Attributes</Typography>
                        <Select
                            options={
                                findDiemnsionAttribute?.length > 0
                                    ? findDiemnsionAttribute?.map((item) => ({
                                        value: item?._id,
                                        label: item?.name,
                                        isFixed: false,
                                    }))
                                    : []
                            }
                            value={
                                formValues?.attributes?.length > 0 &&
                                formValues?.attributes?.map((option) => ({
                                    value: option?.attributeUID,
                                    label: option?.attributeName,
                                    isFixed: option.isFixed,
                                }))
                            }
                            onChange={(data) => handleChangeAttribute(data, 'attributes')}
                            placeholder="Select Attribute"
                            isClearable={
                                formValues.attributes?.length > 0 &&
                                formValues?.attributes?.some((v) => v.isFixed)
                            }
                            isSearchable={true}
                            isMulti
                            className="text-black"
                            styles={customStyles}
                        />
                    </FormControl>

                    {(formValues?.widgetSubType === 'Measure Data' ||
                        formValues?.widgetType === 'Chart') && (
                            <>
                                <MeasureDataSecond
                                    findTimePeriod={findTimePeriod}
                                    selectedOptions={formValues}
                                    setSelectedOptions={setFormValues}
                                    handleChangePage={handleChange}
                                    timeDimensionData={timeDimensionData}
                                    formValues={formValues}
                                />
                            </>
                        )}

                    {(formValues?.widgetSubType === 'Measure Data' ||
                        formValues?.widgetType === 'Chart') &&
                        findLagId?.length > 0 && (
                            <>
                                <CycleIdData
                                    findCycleIdMonth={findCycleIdMonth}
                                    selectedOptions={formValues}
                                    handleChangeAttribute={handleChangeAttribute}
                                />
                            </>
                        )}

                    <FormControl
                        fullWidth
                        style={{ paddingBottom: '25px', marginTop: '5px' }}
                    >
                        <CommonSelect
                            labelId="Filter"
                            name="filter"
                            options={filterDetail}
                            value={formValues?.filter}
                            setSelectedOptions={setFormValues}
                            formValues={formValues}
                        />
                    </FormControl>

                    <hr
                        style={{
                            borderTop: '2px solid #1976d2',
                            width: '100%',
                            margin: '20px auto',
                        }}
                    />
                    <FormControl fullWidth sx={{ paddingBottom: '15px' }}>
                        <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Scenario</Typography>
                        <Select
                            options={
                                scenarioList?.length > 0
                                    ? scenarioList?.map((item) => ({
                                        value: item?._id,
                                        label: item['Scenario Name'],
                                    }))
                                    : []
                            }
                            value={
                                formValues?.scenario?.length > 0 &&
                                formValues?.scenario?.map((option) => ({
                                    value: option?._id,
                                    label: option?.scenario,
                                }))
                            }
                            onChange={(data) => handleChangeAttribute(data, 'scenario')}
                            placeholder="Select Scenario"
                            isSearchable={true}
                            isMulti
                            className="text-black"
                            styles={customStyles}
                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        style={{ paddingBottom: '25px', marginTop: '5px' }}
                    >
                        <CommonInput
                            name="workbookNotes"
                            value={formValues?.workbookNotes || ''}
                            label="Note"
                            style={{ padding: 0 }}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <hr
                        style={{
                            borderTop: '2px solid #1976d2',
                            width: '100%',
                            margin: '20px auto',
                        }}
                    />

                    <FormControl
                        fullWidth
                        style={{ paddingBottom: '25px', marginTop: '5px' }}
                    >
                        <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Layout</Typography>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Box sx={{ width: '400px' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: "12px" }}>
                                    {formValues?.widgetType === 'Chart'
                                        ? 'Attribute Sequence'
                                        : 'Row Axis'}{' '}
                                    :
                                </Typography>
                                <Box
                                    onDrop={(e) => handleDrop(e, 'pending')}
                                    onDragOver={(e) => handleDragOver(e)}
                                    sx={{
                                        border: '1px solid black',
                                        overflowY: 'scroll',
                                        height: '240px',
                                    }}
                                >
                                    {pendingLayout?.length > 0 &&
                                        (formValues?.widgetType === 'Worksheet'
                                            ? data
                                            : pendingLayout
                                        )?.map((item, index) => (
                                            <div
                                                key={item.id}
                                                draggable
                                                onDragStart={(e) =>
                                                    handleDragStart(e, item.id, 'pending')
                                                }
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={(e) => handleReorder(e, index, 0)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '10px',
                                                    gap: '10px',
                                                    cursor: 'move',
                                                }}
                                            >
                                                {item?.field}
                                            </div>
                                        ))}
                                </Box>
                            </Box>

                            {formValues?.widgetType === 'Worksheet' && (
                                <Box sx={{ width: '400px' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: "12px" }}>
                                        Column Axis:
                                    </Typography>
                                    <Box
                                        onDrop={(e) => handleDrop(e, 'dropped')}
                                        onDragOver={(e) => handleDragOver(e)}
                                        sx={{
                                            border: '1px solid black',
                                            overflowY: 'scroll',
                                            height: '240px',
                                        }}
                                    >
                                        {droppedLayout?.length > 0 &&
                                            droppedLayout?.map((item, index) => (
                                                <div
                                                    key={index}
                                                    draggable
                                                    onDragStart={(e) =>
                                                        handleDragStart(e, item.id, 'dropped')
                                                    }
                                                    onDragOver={(e) => handleDragOver(e)}
                                                    onDrop={(e) => handleReorder(e, index, 0)}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '10px',
                                                        gap: '10px',
                                                        cursor: 'move',
                                                    }}
                                                >
                                                    <div>
                                                        {/* <Checkbox /> */}
                                                        {item?.field}
                                                    </div>
                                                </div>
                                            ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </FormControl>

                    <Box
                        sx={{
                            paddingBottom: '20px',
                            display: 'flex',
                            justifyContent: 'end',
                            gap: '10px',
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => {
                                setEditWidgetModal(false);
                                setFormValues({});
                                setFormValues({
                                });
                                dispatch(setDropped([]));
                            }} sx={{textTransform: "capitalize"}}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleSubmitWorkbook} sx={{textTransform: "capitalize"}}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default EditWidget
