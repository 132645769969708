import { Container, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import CustomButton from '../common/CustomButton';
import profileFieldData from "../../profileFieldData.json"

const AdministrativeTab = ({
    formValues,
    handleChangeProfile,
    isView,
    handleSubmit,
    handleCancel
}) => {
    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Administrative Info
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                        <TextField
                            id="notes"
                            name="notes"
                            variant="outlined"
                            fullWidth
                            label="Notes"
                            value={formValues.notes}
                            multiline
                            rows={6}
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "notes", e);
                            }}
                            placeholder="Enter Notes"
                            disabled={isView}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.administrative?.map((field, index) => (
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id={field.name}
                                name={field.name}
                                variant="outlined"
                                label={field?.label}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formValues?.[field.name]}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, field.name, e);
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* <CustomButton
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
            /> */}
        </>
    )
}

export default AdministrativeTab
