import React, { useMemo, useState } from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'; // Assuming you're using Syncfusion DateRangePickerComponent
import './index.css';

const CustomDateRangePicker = (props) => {
    const { startDate, endDate, onDateRangeChange } = props;
    console.log("startDate, endDate,", { startDate, endDate, })
    const defaultDateRange = useMemo(()=>{
        const start= new Date(startDate) || new Date(); 
        const end = new Date(endDate) ||  new Date(); 
        return [start, end];
    }, [startDate, endDate]) 


    return (
        <div className='control-pane'>
            <div className='control-section'>
                <div className='daterangepicker-control-section'>
                    <DateRangePickerComponent
                        value={defaultDateRange}  // Set the default date range
                        change={onDateRangeChange}
                        format='dd/MM/yyyy' 
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomDateRangePicker;
