import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { API_BASE_URL } from '../../helper/constants'
import { AppBar, Autocomplete, Box, Button, Card, CardContent, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputBase, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography, alpha, styled } from '@mui/material'
import Paper from '@mui/material/Paper';
import { FaRegTrashAlt, FaSearch } from 'react-icons/fa';
import { Select as MuiSelect } from '@mui/material';
import { createFilterDetail, getAttributeValue, getFilterDetail } from '../../store/ApiSlice/canvasSlice'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi'
import { getCycleIdList, getCyclePeriodicityList, getScrenarisoswithEnterpriseAndModel } from '../../store/ApiSlice/widgetSlice'
import { getLagIdList } from '../../store/ApiSlice/dimensionSlice'
import SystemAttributesValues from '../SystemAttributeValues'
import { generateFilterPayload, generateSystemAttributeInitialPayload } from '../SystemAttributeValues/generatePayload.utils'
import CustomDateRangePicker from '../CustomDateRangePicker'
import { IoMdCloseCircle } from 'react-icons/io'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: "1px solid black",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const timePeriodType = [{
    label: "Day",
    value: "Day"
},
{
    label: "Sys Week",
    value: "SysWeek"
},

{
    label: "Week",
    value: "Week"
},

{
    label: "Month",
    value: "Month"
},

{
    label: "Quarter",
    value: "Quarter"
},

{
    label: "Year",
    value: "Year"
}

]

const cyclePeriodicity = [
    "Day",
    "SysWeek",
    "Month",
    "Week",
    "Quarter",
    "Year"
];

const systemAttributeOptionList = [{
    label: "Scenario ID",
    value: "scenario"
},
{
    label: "Cycle ID",
    value: "cycleIds"
},
{
    label: "Lag ID",
    value: "lagIds"
},
]

const CreateFilter = ({
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows
}) => {
    const userData = JSON.parse(localStorage.getItem("accessToken"))
    const { enterpriseData } = useSelector((state) => state?.enterprise);

    const { attributeValue, filterDetail, timeDimensionData } = useSelector((state) => state?.canvas)
    const { cyclePeriodicityList } = useSelector((state) => state?.widget)
    const [attributeValueList, setAttributeValueList] = useState([]);
    const { attribute } = useSelector((state) => state.attribute)

    const [filterFormValue, setFilterFormValue] = useState({
        enterpriseUID: userData?.data?.enterprise?._id,
        enterpriseName: userData?.data?.enterprise?.enterpriseName,
    })
    const [valueModal, setValueModal] = useState(false);
    const [valueModalSystemAtt, setValueModalSystemAtt] = useState(false);
    const [modalName, setModalName] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [attributePage, setAttributePage] = useState(0);
    const [rowsAttributePerPage, setRowsAttributePerPage] = useState(10);
    const [search, setSearch] = useState()
    const [selectedValue, setSelectedValue] = useState({})
    const [selectedAttributes, setSelectedAttributes] = useState([
        {
            id: uuidv4(),
            attributes: '',
            operator: 'Equal To',
            attValues: {
                Baseline: true
            },
        }
    ]);

    const [timeAttribute, setTimeAttribute] = useState([
        {
            id: uuidv4(),
            timePeriod: "",
            operator: "In Between",
            startDate: "",
            endDate: ""
        }
    ]);

    const [openModalRow, setOpenModalRow] = useState(null);
    const [attributeValueData, setAttributeValueData] = useState({ attValues: {} })
    const dispatch = useDispatch();
    const AttributeEnterpriseData = attribute?.filter((item) => item?.enterpriseUID?._id === filterFormValue?.enterpriseUID && (item?.modelUID?.modalUID === filterFormValue?.modelUID || item?.modelName === filterFormValue?.modelName))
    const selectedFilterDetail = filterDetail?.find((item) => item?._id === selectedRows);

    const [systemAttributeList, setSystemAttributeList] = useState([{
        id: uuidv4(),
        attributes: "scenario",
        cyclePeriodicity: "",
        operator: "In Between",
        attValues: {
            Baseline: true
        }
    }]);

    useEffect(() => {

        if (selectedFilterDetail && isEdit) {
            setFilterFormValue({
                filterName: selectedFilterDetail?.name,
                filterNotes: selectedFilterDetail?.notes,
                enterpriseUID: selectedFilterDetail?.enterpriseUID?._id || userData?.data?.enterprise?._id,
                enterpriseName: selectedFilterDetail?.enterpriseUID?.enterpriseName || userData?.data?.enterprise?.enterpriseName,
                modelUID: selectedFilterDetail?.modelUID?.modalUID,
                modelName: selectedFilterDetail?.modelUID?.modelName
            })
            setSelectedAttributes(selectedFilterDetail?.attributes?.map((item) => ({
                id: uuidv4(),
                attributes: item?.attributeUID?._id,
                operator: item?.operator,
                attValues: item?.values?.reduce((acc, value) => {
                    acc[value] = true;
                    return acc;
                }, {})
            })));

            const mapTimeArr = (selectedFilterDetail?.timeAttributes ?? []).map((item) => {
                return {
                    id: item._id,
                    timePeriod: item.timeAttribute,
                    operator: item.operator,
                    startDate: "",
                    endDate: ""
                }
            });
            setTimeAttribute(mapTimeArr);
            if (selectedFilterDetail?.systemAttributes) {
                const data = generateSystemAttributeInitialPayload(selectedFilterDetail?.systemAttributes);
                setSystemAttributeList(data)
            }
        }
    }, [filterDetail, isEdit, selectedFilterDetail])

    useEffect(() => {
        if (attributeValue.length > 0) {

            setAttributeValueList(attributeValue);
        }
    }, [attributeValue]);
    // useEffect(() => {
    //     if (enterpriseData?.length === 0) {
    //         setFilterFormValue({
    //             ...filterFormValue,
    //             [`enterpriseName`]: userData?.data?.enterprise?.enterpriseName,
    //             [`enterpriseUID`]: userData?.data?.enterprise?._id
    //         });
    //     }
    // }, [enterpriseData])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    const handleChangeRowsAttributePerPage = (event) => {
        setRowsAttributePerPage(parseInt(event.target.value, 10));
        setAttributePage(0);
    };

    const handleChangeAttributePage = (event, newPage) => {
        setAttributePage(newPage)
    }

    const handleCancel = () => {
        setIsCreate(false);
    };

    const handleChangeFilter = (newValue, fieldName, e) => {
        if (fieldName === 'enterpriseName') {
            const selectedData = enterpriseData?.filter((data) => data?._id === newValue?._id);

            setFilterFormValue({
                ...filterFormValue,
                [fieldName]: selectedData[0]?.enterpriseName || userData?.data?.enterprise?.enterpriseName,
                [`enterpriseUID`]: newValue?._id
            });
        } else if (fieldName === 'modelName') {
            const selectedData = modalName?.filter((data) => data?.modalUID === newValue?.modalUID);

            setFilterFormValue({
                ...filterFormValue,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID
            });
        } else if (fieldName === 'filterAttribute') {
            const selectedData = AttributeEnterpriseData?.filter((data) => data?._id === newValue?._id);

            setFilterFormValue({
                ...filterFormValue,
                [fieldName]: selectedData[0]?.name,
                [`attributeUID`]: newValue?._id
            });
        }
        else {
            const { name, value } = e.target;
            setFilterFormValue((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    }

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === filterFormValue.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (filterFormValue.enterpriseName) {
                filteredData = filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === filterFormValue.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (filterFormValue.enterpriseName) {
            getModelList();
        }
    }, [filterFormValue.enterpriseName]);


    useEffect(() => {

        if (filterFormValue?.enterpriseUID && filterFormValue?.modelUID) {
            const payload = {
                enterpriseUID: { _id: filterFormValue?.enterpriseUID },
                modelUID: { modalUID: filterFormValue?.modelUID },
            }

            dispatch(getCyclePeriodicityList({ canvasFindData: payload }))
        }

    }, [filterFormValue])




    const handleClickValue = (rowIndex, data) => {
        setValueModal(true);
        setOpenModalRow(rowIndex)
        if (Object.keys(data?.attValues).length > 0) {
            setAttributeValueData({ attValues: data.attValues });
        }
    }

    const handleClickSystemValueAtt = (rowIndex, data) => {
        setValueModalSystemAtt(true);
        setOpenModalRow(rowIndex)
    }
    const handleCloseValue = () => {
        setValueModal(false);
        setValueModalSystemAtt(false);
        setAttributeValueData({ attValues: {} });
        setAttributeValueList([]);

    }

    const validation = () => {
        if (filterFormValue?.filterName == "" || filterFormValue?.filterName == undefined) {
            toast.error("Please enter filter name")
            return true;
        } else if (filterFormValue?.enterpriseUID == "" || filterFormValue?.enterpriseUID == undefined) {
            toast.error("Please select enterPrise")
            return true;
        } else if (filterFormValue?.modelUID == "" || filterFormValue?.modelUID == undefined) {
            toast.error("Please select model")
            return true;
        } else {
            const hasInvalidAttribute = selectedAttributes.some(item => !item.attributes || item.attributes.trim() === '');
            const hasInvalidOperator = selectedAttributes.some(item => !item.operator || item.operator.trim() === '');
            const hasInvalidAttValues = selectedAttributes.some(item => {
                if (!item.attValues || Object.keys(item.attValues).length === 0) {
                    return true;
                }
                return false;
            });
            if (hasInvalidAttribute) {
                toast.error("Please select attribute")
                return true;
            } else if (hasInvalidOperator) {
                toast.error("Please select operator")
                return true;
            } else if (hasInvalidAttValues) {
                toast.error("Please select attribute values")
                return true;
            }
        }
        return false
    }

    const handleSubmitFilter = async () => {
        if (validation()) {
            return;
        } else {
            const payload = {
                enterpriseUID: filterFormValue?.enterpriseUID,
                modelUID: filterFormValue?.modelUID,
                name: filterFormValue?.filterName,
                notes: filterFormValue?.filterNotes,
                attributes: selectedAttributes?.map((item) => ({
                    attributeUID: item?.attributes,
                    values: Object.entries(item?.attValues)
                        .filter(([key, value]) => value === true)
                        .map(([key, value]) => key),
                    operator: item?.operator
                }))
            }
            const result = await dispatch(createFilterDetail({ payload, isEdit, selectedRows }));
            if (result?.meta?.requestStatus === 'fulfilled') {
                setFilterFormValue({});
                setSelectedAttributes([])
                setIsCreate(false)
                // toast.success(isEdit ? "Filter updated successfully" : "Filter created successfully");
            } else if (result?.meta?.requestStatus === 'rejected') {
                toast.error(result?.error?.message)
            }
        }
    }

    const handleCreateFilter = async () => {
        // if (validation()) {
        //     return;
        // }
        const filterData = generateFilterPayload(systemAttributeList, timeAttribute);

        const filterAttributes = selectedAttributes.filter((item) => item.attributes !== "");
        let payload = {
            enterpriseUID: filterFormValue?.enterpriseUID,
            modelUID: filterFormValue?.modelUID,
            name: filterFormValue?.filterName,
            notes: filterFormValue?.filterNotes,
            ...(filterAttributes.length > 0 && {
                attributes: selectedAttributes?.map((item) => ({
                    attributeUID: item?.attributes,
                    values: Object.entries(item?.attValues)
                        .filter(([key, value]) => value === true)
                        .map(([key, value]) => key),
                    operator: item?.operator
                })),
            }),
            timeAttributes: filterData.timeAttributes[0],
            systemAttributes: filterData.systemAttributes
        }

        // Generate the payload
        const result = await dispatch(createFilterDetail({ payload, isEdit, selectedRows }));
        if (result?.meta?.requestStatus === 'fulfilled') {
            setFilterFormValue({});
            setSelectedAttributes([]);
            setIsCreate(false);
            setSystemAttributeList([]);
            setTimeAttribute([]);
            // toast.success(isEdit ? "Filter updated successfully" : "Filter created successfully");
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message)
        }

    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleAddField = () => {
        setSelectedAttributes([...selectedAttributes, {
            id: uuidv4(),
            attributes: '',
            operator: 'Equal To',
            attValues: {},
        }]);
    };

    const handleStepChangeValue = (newValue, fieldname, event, index, data) => {
        const { name, value, checked } = event.target;
        if (fieldname == "attributes") {

            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][fieldname] = newValue?._id;
            // updatedFormValues[index]["attValues"] = { attValues: {}}
            setSelectedAttributes(updatedFormValues);
        }
        else {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][name] = value;
            setSelectedAttributes(updatedFormValues);
        }
    };

    const handleCheckboxChange = (newValue, item) => {
        const updatedFormValues = { ...attributeValueData };
        updatedFormValues.attValues[item] = !updatedFormValues.attValues[item];
        setAttributeValueData(updatedFormValues);
    };

    const handleHeaderCheckboxChange = (event, index) => {
        const isChecked = event.target.checked;
        const updatedFormValues = { ...attributeValueData };
        attributeValueList.forEach((item) => {
            updatedFormValues.attValues[item] = isChecked;
        });
        setAttributeValueData(updatedFormValues)
    };

    const handleSubmit = (index, isHierarchyAttributes = true) => {
        if (isHierarchyAttributes) {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
            setSelectedAttributes(updatedFormValues);
            handleCloseValue();
        } else {
            const updatedFormValues = [...systemAttributeList];
            updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
            setSystemAttributeList(updatedFormValues);
        }
        handleCloseValue();

    };


    const handleRemoveField = () => {
        const updatedFormValues = [...selectedAttributes];
        updatedFormValues.splice(selectedValue, 1);
        setSelectedAttributes(updatedFormValues);
    };

    const labelTransparentStyle = {
        '& .MuiInputLabel-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
        }
    };

    const handleRemoveAttributes = (currentId) => {
        const updatedValues = (selectedAttributes ?? []).filter((item) => item.id !== currentId)
        setSelectedAttributes(updatedValues);
    }

    const onChangeTimePeriod = (e, selectedIndex) => {
        const { value, name } = e.target;
        setTimeAttribute(prevTimeAttribute =>
            prevTimeAttribute.map((item, index) =>
                index === selectedIndex ? { ...item, [name]: value } : item
            )
        );
    };

    const onDateRangeChange = (e, selectedIndex) => {
        const startDate = e.value[0];
        const endDate = e.value[1];
    

        setTimeAttribute(prevTimeAttribute =>
            prevTimeAttribute.map((item, index) =>
                index === selectedIndex ? { ...item, 
                    startDate: startDate,
                    endDate: endDate
                 } : item
            )
        );

        console.log("timeAttribute", timeAttribute)
    };

    const handleAddTimeAttribute = () => {
        setTimeAttribute((prev) => [
            ...prev,
            {
                id: uuidv4(),
                timePeriod: "",
                operator: "In Between",
                startDate: "",
                endDate: ""
            }
        ]);
    }

    const handleRemoveTimeAttribute = (removeItem) => {
        const updatedData = timeAttribute.filter((item) => item.id !== removeItem);
        setTimeAttribute(updatedData)
    }


    const handleAddSystemAttributes = () => {

        const existingAttributes = new Set(systemAttributeList.map(item => item.attributes));
        const filterData = systemAttributeOptionList.filter(item => !existingAttributes.has(item.value));

        const hasScenario = filterData.some(item => item.value === "scenario");

        const newAttribute = hasScenario
            ? {
                id: uuidv4(),
                attributes: "scenario",
                cyclePeriodicity: "",
                operator: "In Between",
                attValues: {
                    Baseline: true
                }
            }
            : {
                id: uuidv4(),
                attributes: filterData[0]?.value || "",
                cyclePeriodicity: "",
                operator: "In Between",
                attValues: {}
            };
        // Add the new attribute to the system attribute list
        setSystemAttributeList(prev => [...prev, newAttribute]);
    };


    const handleRemoveSystemAttributes = (removeItemID) => {
        const updatedData = systemAttributeList.filter((item) => item.id !== removeItemID);
        setSystemAttributeList(updatedData);
    }

    const handleSystemAttributeValueChange = (e, ItemId) => {
        const { name, value } = e.target;

        if (name === 'attributes') {
            setSystemAttributeList(prev =>
                prev.map((item) =>
                    item.id === ItemId
                        ? {
                            ...item,
                            [name]: value,
                            cyclePeriodicity: "",
                            operator: "",
                            attValues: {}
                        }
                        : item
                ),
            );
        } else {
            setSystemAttributeList(prev =>
                prev.map((item) =>
                    item.id === ItemId
                        ? { ...item, [name]: value }
                        : item
                ),
            );
        }
    }

    const handleOnChangesSystemAttribute = (updatedData) => {
        setSystemAttributeList(updatedData)
    }

    //Function used to selected values 
    const handleRemoveValue = (key, selectedIndex) => {
        const attributeValueData = selectedAttributes[selectedIndex].attValues
        const filteredAttValues = Object.entries(attributeValueData)
            .filter(([k, v]) => k !== key)
            .reduce((acc, [k, v]) => {
                acc[k] = v; 
                return acc;
            }, {});
        const updatedData = selectedAttributes.map((item,index)=>{
            if (index === selectedIndex){
                return {
                    ...item,
                    attValues: filteredAttValues
                }
            }
            return item
        })
        setSelectedAttributes(updatedData);
    }


    return (
        <>
            <div>
                <Card className='mt-3'>
                    <CardContent>
                        <h2 className='mb-3'>Overview</h2>
                        <div className='mt-3 flex justify-start items-center gap-4' >
                            <div className='flex items-center gap-3 flex-1'>
                                <label
                                    htmlFor="cyclePeriodicity"
                                    className="block text-[14px] font-semibold text-gray-700 mb-2 text-nowrap"
                                >
                                    Filter Name
                                </label>
                                <TextField
                                    id="filterName"
                                    name="filterName"
                                    hiddenLabel
                                    fullWidth
                                    label="Filter Name"
                                    value={filterFormValue?.filterName}
                                    onChange={(e, newValue) => {
                                        handleChangeFilter(newValue, "filterName", e);
                                    }}
                                    placeholder="Enter filter name"
                                    sx={labelTransparentStyle}
                                />
                            </div>
                            <div className='flex items-center gap-3 w-96'>
                                <label
                                    htmlFor="cyclePeriodicity"
                                    className="block text-[14px] font-semibold text-gray-700 mb-2 text-nowrap"
                                >
                                    Enterprise Name
                                </label>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Enterprise Name"
                                        name="enterpriseName"
                                        sx={labelTransparentStyle}
                                        options={
                                            enterpriseData?.length > 0
                                                ? enterpriseData
                                                : userData?.data?.enterprise
                                                    ? [userData.data.enterprise]
                                                    : []
                                        }
                                        getOptionLabel={(option) => option.enterpriseName}
                                        value={
                                            enterpriseData?.length > 0
                                                ? enterpriseData?.find((option) => option?.enterpriseName === filterFormValue.enterpriseName) || null
                                                : userData?.data?.enterprise?.enterpriseName === filterFormValue.enterpriseName
                                                    ? userData.data.enterprise
                                                    : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeFilter(newValue, "enterpriseName", e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Enterprise Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div className='flex items-center gap-3 w-96'>
                                <label
                                    htmlFor="cyclePeriodicity"
                                    className="block text-[14px] font-semibold text-gray-700 mb-2 text-nowrap"
                                >
                                    Model Name
                                </label>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Model Name"
                                        name="modelName"
                                        options={modalName}
                                        getOptionLabel={(option) => option.modelName}
                                        value={filterFormValue?.modelName ?
                                            modalName.find(option => option.modelName === filterFormValue.modelName)
                                            || null : null}
                                        onChange={(e, newValue) => {
                                            handleChangeFilter(newValue, "modelName", e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Model Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        sx={labelTransparentStyle}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card className='mt-3'>
                    <CardContent>
                        <div class="flex justify-between items-center">
                            <h2 className='mb-3'>Hierarchy Attributes</h2>
                            <button
                                onClick={() => handleAddField()}
                                className="font-semibold text-sm p-2 flex items-center gap-2"
                                type="button"
                            >
                                <FiPlus className="w-5 h-5 text-black" />
                            </button>
                        </div>

                        <div className='mb-3'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'>Attribute</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Operator</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Value</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {selectedAttributes?.length > 0 && selectedAttributes?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((row, i) => {
                                    const findAttribute = AttributeEnterpriseData.find(item => item?._id === row.attributes);
                                    return (

                                        <TableBody>
                                            <TableCell style={{ width: "300px" }}>
                                                <Autocomplete
                                                    id="demo-simple-select-readonly"
                                                    labelId={`attributes${i}`}
                                                    name="attributes"
                                                    options={AttributeEnterpriseData}
                                                    getOptionLabel={(option) => option.name}
                                                    value={row?.attributes ?
                                                        AttributeEnterpriseData?.find(option => option._id === row?.attributes)
                                                        || null : null}
                                                    onChange={(e, newValue) => {
                                                        handleStepChangeValue(newValue, "attributes", e, i, row);
                                                    }}
                                                    sx={{ fontSize: "12px" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Attribute"
                                                            variant="outlined"
                                                            fullWidth
                                                            style={{ width: "300px", fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell style={{ width: "240px" }}>
                                                <FormControl style={{ width: "240px" }}>
                                                    <InputLabel id="operator" sx={{ fontSize: "12px" }}>Operator</InputLabel>
                                                    <MuiSelect
                                                        labelId="Value"
                                                        name='operator'
                                                        id="demo-simple-select-readonly"
                                                        value={row?.operator}
                                                        onChange={(e, newValue) => {
                                                            handleStepChangeValue(newValue, "operator", e, i, row);
                                                        }}
                                                        variant="outlined"
                                                        sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                    >
                                                        <MenuItem value="Equal To" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Equal To</MenuItem>
                                                        <MenuItem value="Not Equal To" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Not Equal To</MenuItem>
                                                    </MuiSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        cursor: "pointer",
                                                        border: "1px solid gray",
                                                        borderRadius: "4px",
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        alignItems: "center",
                                                        flexWrap: "wrap",
                                                        gap: "20px",
                                                        padding: `${!row?.attValues ? "20px" : "15px"}`
                                                    }}
                                                    fullWidth
                                                    onClick={() => {
                                                        handleClickValue(i, row)
                                                        dispatch(getAttributeValue({ row }))
                                                    }}
                                                >
                                                    {Object.entries(row?.attValues).map(([key, value]) => (
                                                        value === true && (
                                                            <Chip
                                                                label={key}
                                                                variant="outlined"
                                                                onDelete={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRemoveValue(key, i)
                                                                }}
                                                                sx={{ fontSize: '10px' }} 
                                                            />
                                                        )
                                                    ))}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ width: "50px" }}>
                                                {selectedAttributes.length > 1 &&
                                                    <FaRegTrashAlt className='text-[16px] cursor-pointer' onClick={() => handleRemoveAttributes(row.id)} />}
                                            </TableCell>
                                            {valueModal && (

                                                <Dialog
                                                    open={valueModal && openModalRow === i}
                                                    onClose={handleCloseValue}
                                                >
                                                    <DialogTitle sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Attribute Values</DialogTitle>
                                                    <DialogContent sx={{ marginTop: "10px !important", width: '500px' }}>
                                                        <Search sx={{ marginBottom: "31px" }}>
                                                            <SearchIconWrapper>
                                                                <FaSearch />
                                                            </SearchIconWrapper>
                                                            <StyledInputBase
                                                                placeholder="Search…"
                                                                inputProps={{ 'aria-label': 'search' }}
                                                                onChange={(e) => { handleSearch(e) }}
                                                            />
                                                        </Search>

                                                        <TableContainer component={Paper}>
                                                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                                                <Table aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Checkbox onChange={(e) => handleHeaderCheckboxChange(e, i)} />
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif" }}>{findAttribute?.name}</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    {search ? (
                                                                        attributeValueList?.filter((item) => {
                                                                            if (typeof item === 'string') {
                                                                                return item?.toLowerCase().includes(
                                                                                    typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                                                );
                                                                            } else if (typeof item === 'number') {
                                                                                return item?.toString().includes(search?.toString());
                                                                            }
                                                                            return false;
                                                                        })?.length === 0 ? (
                                                                            <TableBody>
                                                                                <TableCell colSpan={2} style={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif" }}>No data found</TableCell>
                                                                            </TableBody>
                                                                        ) : (
                                                                            attributeValueList?.filter((item) => {
                                                                                if (typeof item === 'string') {
                                                                                    return item?.toLowerCase().includes(
                                                                                        typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                                                    );
                                                                                } else if (typeof item === 'number') {
                                                                                    return item.toString()?.includes(search?.toString());
                                                                                }
                                                                                return false;
                                                                            })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => {
                                                                                return (
                                                                                    <TableBody key={index}>
                                                                                        <TableCell>
                                                                                            <Checkbox
                                                                                                checked={attributeValueData?.attValues[item] || false}
                                                                                                onChange={(e) => {
                                                                                                    handleCheckboxChange(e.target.checked, item);
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell>{item}</TableCell>
                                                                                    </TableBody>
                                                                                )
                                                                            })
                                                                        )
                                                                    ) : (
                                                                        attributeValueList?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                            <TableBody key={index}>
                                                                                <TableCell>
                                                                                    <Checkbox
                                                                                        checked={attributeValueData?.attValues[item] || false}
                                                                                        onChange={(e) => {
                                                                                            handleCheckboxChange(e.target.checked, item);
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>{item}</TableCell>
                                                                            </TableBody>
                                                                        ))
                                                                    )}
                                                                </Table>
                                                            </div>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                                component="div"
                                                                count={search ? attributeValueList?.filter((item) => {
                                                                    if (typeof item === 'string') {
                                                                        return item?.toLowerCase().includes(
                                                                            typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                                        );
                                                                    } else if (typeof item === 'number') {
                                                                        return item?.toString().includes(search?.toString());
                                                                    }
                                                                    return false;
                                                                })?.length : attributeValueList?.length}
                                                                rowsPerPage={rowsAttributePerPage}
                                                                page={attributePage}
                                                                onPageChange={(event, newPage) => handleChangeAttributePage(event, newPage)}
                                                                onRowsPerPageChange={handleChangeRowsAttributePerPage}
                                                            />
                                                        </TableContainer>

                                                        <DialogActions >
                                                            <Button onClick={() => {
                                                                handleCloseValue()
                                                                setSearch()
                                                            }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", textTransform: "capitalize" }}>
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    handleSubmit(i)
                                                                    setSearch()
                                                                }}
                                                                sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", textTransform: "capitalize" }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </DialogActions>
                                                    </DialogContent>
                                                </Dialog>
                                            )}
                                        </TableBody>
                                    )
                                })}
                            </Table>
                        </div>
                    </CardContent>

                </Card>

                <Card className='mt-3'>
                    <CardContent>
                        <div class="flex justify-between items-center ">
                            <h2 className='mb-3'>Time Attributes</h2>
                        </div>

                        <div>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'> Time Period Type</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Logical Operator</TableCell>
                                        {/* <TableCell className='font-semibold text-[14px]'>Date Range</TableCell> */}
                                        <TableCell className='font-semibold text-[14px]'>From</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>To</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                {timeAttribute.map((item, index) => {
                                    return <>
                                        <TableBody>
                                            <TableCell>
                                                <FormControl style={{ width: "100%" }}>
                                                    <InputLabel id="operator" sx={{ fontSize: "12px" }}>Operator</InputLabel>
                                                    <MuiSelect
                                                        labelId="Value"
                                                        name="timePeriod"
                                                        id="demo-simple-select-readonly"
                                                        value={item?.timePeriod || ""}
                                                        defaultValue={item?.timePeriod}
                                                        onChange={(e) => onChangeTimePeriod(e, index)}
                                                        variant="outlined"
                                                        sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                    >
                                                        <MenuItem selected sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Select Time Period Type</MenuItem>
                                                        {(cyclePeriodicityList ?? []).map((optionItem) => <MenuItem value={optionItem} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>{optionItem}</MenuItem>)}
                                                    </MuiSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl style={{ width: "100%" }}>
                                                    <InputLabel id="operator" sx={{ fontSize: "12px" }}>Operator</InputLabel>
                                                    <MuiSelect
                                                        labelId="Value"
                                                        name="operator"
                                                        id="demo-simple-select-readonly"
                                                        value={item?.operator || ""}
                                                        defaultValue={item?.operator}
                                                        onChange={(e) => onChangeTimePeriod(e, index)}
                                                        variant="outlined"
                                                        sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                    >
                                                        <MenuItem selected sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Select Operator</MenuItem>
                                                        <MenuItem value="In Between" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>In Between</MenuItem>
                                                    </MuiSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="date"
                                                    id="attributeStartDate"
                                                    name="startDate"
                                                    value={item.startDate}
                                                    onChange={(e) => onChangeTimePeriod(e, index)}
                                                    className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                />
                                                {/* <CustomDateRangePicker 
                                                    onDateRangeChange={(e) => onDateRangeChange(e,index)}
                                                    startDate={item.startDate}
                                                    endDate={item.endDate}
                                                /> */}
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="date"
                                                    id="attributeEndDate"
                                                    name="endDate"
                                                    value={item.endDate}
                                                    onChange={(e) => onChangeTimePeriod(e, index)}
                                                    min={item.startDate || ""}
                                                    disabled={!item.startDate}
                                                    className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: "50px" }}>
                                                {timeAttribute.length > 1 &&
                                                    <FaRegTrashAlt className='text-[16px] cursor-pointer' onClick={() => handleRemoveTimeAttribute(item.id)} />}
                                            </TableCell>

                                        </TableBody>
                                    </>
                                })}

                            </Table>
                        </div>
                    </CardContent>
                </Card>

                <Card className="mt-3">
                    <CardContent>
                        <div class="flex justify-between items-center ">
                            <h2 className="mb-3">System Attributes</h2>
                            <button
                                onClick={() => handleAddSystemAttributes()}
                                className="font-semibold text-sm p-2 flex items-center gap-2"
                                type="button"
                                disabled={systemAttributeList?.length === 3}
                            >
                                <FiPlus className="w-5 h-5 text-black" />
                            </button>
                        </div>
                        <div>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'>Attribute</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Cycle Periodicity</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Value</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {systemAttributeList.map((systemAttItem, i) => {
                                    // Create a filtered list of system attribute options excluding already selected values
                                    const availableOptions = systemAttributeOptionList.filter(option =>
                                        !systemAttributeList.some(item => item.attributes === option.value && item.id !== systemAttItem.id)
                                    );

                                    return <>
                                        <TableBody>
                                            <TableCell className=''>
                                                <FormControl style={{ minWidth: "300px" }}>
                                                    <InputLabel id="operator" sx={{ fontSize: "12px" }}>
                                                        System Attribute
                                                    </InputLabel>
                                                    <MuiSelect
                                                        labelId="Value"
                                                        name="attributes"
                                                        value={systemAttItem.attributes || ""}
                                                        onChange={(e) => handleSystemAttributeValueChange(e, systemAttItem.id)}
                                                        variant="outlined"
                                                        sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                    >
                                                        <MenuItem sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                                                            Select Attribute
                                                        </MenuItem>
                                                        {availableOptions.map((attributeItem) =>
                                                            <MenuItem value={attributeItem.value} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                                                                {attributeItem.label}
                                                            </MenuItem>)}
                                                    </MuiSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className='flex-1'>
                                                {systemAttItem.attributes === "cycleIds" ?
                                                    <FormControl style={{ minWidth: "300px" }}>
                                                        <InputLabel id="operator" sx={{ fontSize: "12px" }}>
                                                            Cycle Periodicity
                                                        </InputLabel>
                                                        <MuiSelect
                                                            labelId="Value"
                                                            name="cyclePeriodicity"
                                                            value={systemAttItem?.cyclePeriodicity || ""}
                                                            onChange={(e) => handleSystemAttributeValueChange(e, systemAttItem.id)}
                                                            variant="outlined"
                                                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                        >
                                                            <MenuItem sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                                                                Select Cycle Periodicity
                                                            </MenuItem>
                                                            {cyclePeriodicity.map((item) => (
                                                                <MenuItem key={item} value={item} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                                                                    {item}
                                                                </MenuItem>
                                                            ))}
                                                        </MuiSelect>
                                                    </FormControl> : "-"}
                                            </TableCell>
                                            <TableCell className='flex-1'>
                                                <SystemAttributesValues
                                                    items={systemAttItem}
                                                    selectedFilterDetail={selectedFilterDetail || filterFormValue}
                                                    systemAttributeList={systemAttributeList}
                                                    handleOnChangesSystemAttribute={handleOnChangesSystemAttribute}
                                                    rowIndex={i}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {systemAttributeList.length > 1 && systemAttItem.attributes !== "scenario" && 
                                                    <FaRegTrashAlt className='text-[16px] cursor-pointer' onClick={() => handleRemoveSystemAttributes(systemAttItem.id)} />}
                                            </TableCell>
                                        </TableBody>
                                    </>
                                })}
                            </Table>
                        </div>
                    </CardContent>
                </Card>
                <Card className='mt-3'>
                    <CardContent>
                        <h2 className='mb-3'>Filter Notes</h2>
                        <div>
                            <TextField
                                id="filterNotes"
                                name="filterNotes"
                                variant="outlined"
                                fullWidth
                                label="Filter Notes"
                                multiline
                                rows={6}
                                value={filterFormValue.filterNotes}
                                onChange={(e, newValue) => {
                                    handleChangeFilter(newValue, "filterNotes", e);
                                }}
                                placeholder="Enter Filter Notes"
                                // onBlur={validateOne}
                                disabled={isView}
                            />
                        </div>
                    </CardContent>
                </Card>
                {/* <DateRangePickerWithButtons/> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: "30px",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        onClick={handleCancel}
                        title="Cancel"
                        style={{
                            padding: "6px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            marginRight: "10px",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "12px",
                            textTransform: "capitalize"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // onClick={handleSubmitFilter}
                        onClick={() => handleCreateFilter()}
                        title="Create new dimension with selected attributes"
                        style={{
                            padding: "7px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "12px",
                            textTransform: "capitalize"
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div >
        </>
    )
}

export default CreateFilter;

