import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";


const initialState = {
    loading: false,
    error: null,
    success: false,
    measureProfileDataList: [],
}

export const getMeasureProfileList = createAsyncThunk(
    'measureprofile/getMeasureProfileList',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/measuredata_profile`, config);
        return response.data;
    }
);

export const createMeasureProfile = createAsyncThunk(
    'measureprofile/createMeasureProfile',
    async ({ payload, isEdit, selectedRows }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/measuredata_profile`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/measuredata_profile/${selectedRows}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteMeasureProfile = createAsyncThunk('measureprofile/deleteMeasureProfile',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/measuredata_profile/${attriId}`, config)
            return responce
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

const measuredataSlice = createSlice({
    name: "measureprofile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMeasureProfileList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getMeasureProfileList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.measureProfileDataList = action?.payload?.data;
            })
            .addCase(getMeasureProfileList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createMeasureProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createMeasureProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(createMeasureProfile.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteMeasureProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteMeasureProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(deleteMeasureProfile.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
    }
})

export default measuredataSlice.reducer;