import React, { useEffect, useMemo, useState } from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { API_BASE_URL } from "../../../helper/constants";
import moment from "moment";
import { Box, Button, Collapse, IconButton, TextField, Typography } from "@mui/material";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchDimensions, getModelListAsync, setAllDimensions } from '../../../store/ApiSlice/dimensionSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';

export default function PlanningDimensionModel({
    setShowfPopup,
    showfPopup,
    selectedDimensions,
    setSelectedDimensions,
    checkedDimensions,
    setCheckedDimensions,
    dimensionAttributes,
    setDimensionAttributes,
    formValues,
    newData,
    isEdit,
    isView,
    selectedRows,
    setSelectedRows,
    handleTableRowSelect,
    selectedAttributes,
    setSelectedAttributes
}) {
    const dispatch = useDispatch()
    const { allDimensions } = useSelector((state) => state.dimension)
    const [searchTerm, setSearchTerm] = useState("");
    const [expandedRow, setExpandedRow] = useState({});
    const isDimensionSelected = (id) => {
        return checkedDimensions?.find((item) => item?._id === id) ? true : false;
    };

    const isAttributeSelected = (attributeId, dimensionId) => {
        const dimension = checkedDimensions?.find((dimension) => dimension?._id === dimensionId);
        return dimension?.fields?.some((attribute) => attribute?._id === attributeId);
    }

    const handleKeyChange = (event, row, keyToUpdate) => {
        let newValue = event.target.checked;
        setDimensionAttributes((prevArray) =>
            prevArray.map((obj, index) => {
                if (row._id === obj._id) {
                    if (keyToUpdate === "primaryKey") {
                        return {
                            ...obj,
                            [keyToUpdate]: newValue,
                            required: newValue ? true : false,
                        };
                    } else {
                        return { ...obj, [keyToUpdate]: newValue };
                    }
                } else {
                    return obj;
                }
            })
        );
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCloseModal = () => {
        setShowfPopup(false);
        setCheckedDimensions([]);
        setSelectedAttributes([]);
    };

    const handleCheckboxChange = (event, row, item) => {
        const { checked } = event.target;
        setCheckedDimensions((prevCheckedDimensions) => {
            const existingRowIndex = prevCheckedDimensions?.findIndex(dim => dim?._id === row?._id);

            if (checked) {
                if (existingRowIndex !== -1) {
                    const existingRow = prevCheckedDimensions[existingRowIndex];
                    if (!existingRow.fields.some(field => field?._id === item?._id)) {
                        const updatedFields = [...existingRow.fields, item];
                        const updatedRow = { ...existingRow, fields: updatedFields };
                        return prevCheckedDimensions.map((dim, index) =>
                            index === existingRowIndex ? updatedRow : dim
                        );
                    }
                    return prevCheckedDimensions;
                } else {
                    const newRow = { ...row, fields: [item] };
                    return [...prevCheckedDimensions, newRow];
                }
            } else {
                if (existingRowIndex !== -1) {
                    const existingRow = prevCheckedDimensions[existingRowIndex];
                    const updatedFields = existingRow?.fields?.filter(field => field?._id !== item?._id);
                    if (updatedFields.length === 0) {
                        return prevCheckedDimensions.filter((dim, index) => index !== existingRowIndex);
                    }
                    const updatedRow = { ...existingRow, fields: updatedFields };
                    return prevCheckedDimensions.map((dim, index) =>
                        index === existingRowIndex ? updatedRow : dim
                    );
                }
                return prevCheckedDimensions;
            }
        });
    };

    useEffect(() => {
        let enterpriseId = isEdit ? newData?.enterpriseName : newData?.enterpriseUID
        let modelId = isEdit ? newData?.modelName : newData?.modelUID
        dispatch(fetchDimensions({ enterpriseId, modelId }))
    }, []);

    const [dataAttr, setDataAttr] = useState([])
    useMemo(() => {
        if (selectedDimensions?.length > 0) {
            const updatedField = selectedDimensions?.map((item) => {
                const selectedDimension = selectedDimensions.filter(row => row._id === item._id);
                if (selectedDimension) {
                    return {
                        ...item,
                        fields: item.fields.map(data => {
                            const isSelected = selectedDimension.some((row) => row?.fields?.some((field) => field?._id === data?._id));
                            return {
                                ...data,
                                isSelected: isSelected,
                            };
                        }),
                    };
                }
                return {
                    ...item,
                    fields: item.fields.map(data => ({
                        ...data,
                        isSelected: false,
                    })),
                };
            })
            setDataAttr(updatedField)
        }
    }, [showfPopup]);


    const handleSubmit = () => {
        const filteredData = allDimensions?.map((dimension) => ({
            ...dimension,
            fields: dimension?.fields?.filter((field) => field?.isSelected),
        }))
            .filter((dimension) => dimension?.fields?.length > 0);
        const mergeFieldsArray = (selectedFields, filteredFields) => {
            const mergedFields = [...selectedFields];

            filteredFields.forEach(field => {
                if (!mergedFields.some(f => f?._id === field?._id)) {
                    mergedFields.push(field);
                }
            });

            return mergedFields;
        };

        const updatedDimensions = selectedDimensions?.map(dim => {
            const match = filteredData?.find(f => f?._id === dim?._id);
            return match ? { ...dim, fields: mergeFieldsArray(dim?.fields, match?.fields) } : dim;
        }) || [];

        filteredData.forEach(newDim => {
            if (!selectedDimensions?.some(dim => dim?._id === newDim?._id)) {
                updatedDimensions?.push(newDim);
            }
        });
        
        setSelectedDimensions([...updatedDimensions])
        setDimensionAttributes(updatedDimensions)
        setShowfPopup(false);
    };

    const handleCheckboxChanges = (event, row, item) => {
        const isChecked = event.target.checked;
        const updatedField = allDimensions?.map((r) => {
            if (r?._id === row?._id) {
                return {
                    ...r,
                    fields: item
                }
            } else {
                return r;
            }
        })

        dispatch(setAllDimensions(updatedField));
    };

    const handleFirstCheckboxChange = (event, row) => {
        const isChecked = event.target.checked;
        const updatedFields = row.fields.map((field) => ({
            ...field,
            isSelected: isChecked,
        }));
        handleCheckboxChanges(event, row, updatedFields);
    };

    const handleSecondCheckboxChange = (event, row, item) => {
        const isChecked = event.target.checked;
        const updatedFields = row.fields.map((field) =>
            field._id === item._id ? { ...field, isSelected: isChecked } : field
        );
        handleCheckboxChanges(event, row, updatedFields);
    };


    return (
        <>
            <Dialog
                open={showfPopup}
                onClose={handleCloseModal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>Planning</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearch}
                        margin="normal"
                    />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Select</TableCell>
                                    <TableCell>Dimension Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allDimensions?.filter((data) => ["SIMPLE", "COMPOUND", "SYSTEM", "REFERENCE"].includes(data?.type))
                                    .map((row) => (
                                        <RowDimensionModal
                                            row={row}
                                            isDimensionSelected={isDimensionSelected}
                                            handleCheckboxChange={handleCheckboxChange}
                                            expandedRow={expandedRow}
                                            isView={isView}
                                            isAttributeSelected={isAttributeSelected}
                                            handleKeyChange={handleKeyChange}
                                            selectedDimensions={selectedDimensions}
                                            handleFirstCheckboxChange={handleFirstCheckboxChange}
                                            handleSecondCheckboxChange={handleSecondCheckboxChange}
                                            dataAttr={dataAttr}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const RowDimensionModal = (props) => {
    const { row,
        isDimensionSelected,
        handleCheckboxChange,
        expandedRow,
        isView,
        isAttributeSelected,
        handleKeyChange,
        selectedDimensions,
        handleFirstCheckboxChange,
        handleSecondCheckboxChange,
        dataAttr
    } = props
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} colSpan={6}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Checkbox
                        value={row._id}
                        checked={row.fields.every((field) => field.isSelected)}
                        onChange={(event) => handleFirstCheckboxChange(event, row)}
                        disabled={dataAttr?.some((item) => item?._id == row?._id && item?.fields?.every((field) => field?.isSelected))}
                    />
                </TableCell>
                <TableCell>{row.name} - ({row.type})</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Attribute
                            </Typography>
                        </Box>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Key</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    row?.fields?.map((item) => {
                                        const isPrimaryKey = expandedRow?.primaryKey?.some(id => id?._id === item?._id);
                                        const selectedId = selectedDimensions?.some((data) =>
                                            data?.fields?.some((rows) => rows?._id === item?._id)
                                        );
                                        const attrData = dataAttr?.find((items) => items?.fields?.some((field) => field?._id === item?._id));
                                        const fieldsArray = attrData?.fields ?? [];
                                        const finalDataAttr = fieldsArray.find((field) => field?._id === item?._id);
                                        return (
                                            <TableRow key={item?._id}>
                                                {!isView && (
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={finalDataAttr?._id === item?._id ? finalDataAttr?.isSelected : item?.isSelected || false}
                                                            onChange={(event) => handleSecondCheckboxChange(event, row, item)}
                                                            disabled={finalDataAttr?._id === item?._id}
                                                        />
                                                    </TableCell>
                                                )}
                                                <TableCell align="left">{item?.name}</TableCell>
                                                <TableCell align="left">{item?.type}</TableCell>
                                                <TableCell align="left">
                                                    <Checkbox
                                                        checked={isPrimaryKey ? true : false}
                                                        disabled={isView}
                                                        onChange={(event) => {
                                                            handleKeyChange(event, item, "primaryKey");
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}
