import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react'
import { toast } from 'react-toastify';

export const PageModel = ({
    openPageModal,
    setOpenPageModal,
    formValues,
    handleDimensionFieldChange,
    canvasFindData,
    handleSubmitPage,
}) => {
    return (
        <>
            <Dialog open={openPageModal} onClose={() => setOpenPageModal(false)}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <DialogTitle>Pages</DialogTitle>
                </div>
                <DialogContent sx={{ marginTop: '10px !important' }}>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="pageName"
                            name="pageName"
                            variant="outlined"
                            fullWidth
                            label="Page Name"
                            value={formValues?.pageName}
                            onChange={(e, newValue) => {
                                handleDimensionFieldChange(newValue, 'pageName', e);
                            }}
                            placeholder="Enter page name"
                            disabled={false}
                            sx={{ fontFamily: "'Poppins', sans-serif" }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="canvasName"
                            name="canvasName"
                            variant="outlined"
                            fullWidth
                            label="Canvas Name"
                            value={canvasFindData?.name}
                            disabled={true}
                            sx={{ fontFamily: "'Poppins', sans-serif" }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="enterpriseName"
                            name="enterpriseName"
                            variant="outlined"
                            fullWidth
                            label="Enterprise Name"
                            value={canvasFindData?.enterpriseUID?.enterpriseName}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="modelName"
                            name="modelName"
                            variant="outlined"
                            fullWidth
                            label="Model Name"
                            value={canvasFindData?.modelUID?.modelName}
                            disabled={true}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="notes"
                            name="notes"
                            variant="outlined"
                            fullWidth
                            label="Note"
                            value={formValues?.notes}
                            onChange={(e, newValue) => {
                                handleDimensionFieldChange(newValue, 'notes', e);
                            }}
                            placeholder="Enter notes"
                            disabled={false}
                        />
                    </FormControl>
                    <DialogActions>
                        <Button onClick={() => setOpenPageModal(false)}>Cancel</Button>
                        <Button onClick={handleSubmitPage}>Submit</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ProcessModel = ({
    openProcessModel,
    handleCloseProcessModel,
    findProcessEnterpriseData,
    processName,
    handleRunProcessField,
    today,
    setProcessStepDetail,
    getScenarioData
}) => {
    return (
        <>
            <Dialog
                open={openProcessModel}
                onClose={handleCloseProcessModel}
                sx={{ padding: '20px' }}
                fullWidth
                maxWidth='xs'
            >
                <DialogTitle>Run Process</DialogTitle>
                <DialogContent sx={{ paddingTop: '10px !important' }}>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="processName"
                            name="processName"
                            options={findProcessEnterpriseData}
                            getOptionLabel={(option) => option.name}
                            value={
                                processName?.processName
                                    ? findProcessEnterpriseData?.find(
                                        (option) => option?._id === processName?.processName
                                    ) || null
                                    : null
                            }
                            onChange={(event, newValue) => {
                                handleRunProcessField(newValue, 'processName', event);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Process Name"
                                    variant="outlined"
                                    fullWidth
                                // style={{ width: '300px' }}
                                />
                            )}
                        />
                    </FormControl>
                    {processName?.type === "Later" && (
                        <>
                            <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                                <TextField
                                    id="selectDate"
                                    label="Select Date"
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: today,
                                    }}
                                    value={processName?.selectDate}
                                    // onChange={(e) => setFormValues({ ...formValues, selectDate: e.target.value })}
                                    onChange={(event, newValue) => {
                                        handleRunProcessField(newValue, 'selectDate', event);
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                                <TextField
                                    id="selectTime"
                                    label="Select Time"
                                    variant="outlined"
                                    fullWidth
                                    type="time"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 1, // Allow input of seconds
                                    }}
                                    value={processName?.selectTime}
                                    placeholder="HH:MM AM/PM"
                                    // onChange={(e) => {
                                    //   setFormValues({ ...formValues, selectTime: e.target.value });
                                    // }}
                                    onChange={(event, newValue) => {
                                        handleRunProcessField(newValue, 'selectTime', event);
                                    }}
                                />
                            </FormControl>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseProcessModel}>Cancel</Button>
                    <Button
                        onClick={() => {
                            // handleTerminate()
                            if (processName?.processName) {
                                setProcessStepDetail(true)
                                // handleCloseProcessModel()
                                getScenarioData()
                            } else {
                                toast.error("Please select process.")
                            }
                        }}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const ProcessStepModel = ({
    processStepDetail,
    setProcessStepDetail,
    processName,
    handleStepChangeValue,
    modalName,
    measureProfileDataList,
    profileData,
    filterDetail,
    scenarioList,
    setOpenProcessModel,
    handleTerminate,
    setProcessLater,
    canvasFindData
}) => {
    return (
        <>
            <Dialog
                open={processStepDetail}
                onClose={() => setProcessStepDetail(false)}
                sx={{ padding: '20px' }}
                fullWidth
                maxWidth='xs'
            >
                <DialogTitle>Process Detail</DialogTitle>
                <DialogContent sx={{ paddingTop: '10px !important' }}>
                    {processName?.steps?.map((data, index) => {
                        return (
                            <>
                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <InputLabel id={`stepType${index}`}>Step Type</InputLabel>
                                    <Select
                                        labelId={`stepType${index}`}
                                        name='stepType'
                                        value={data.stepType}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "stepType", e);
                                        }}
                                        label="Step Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="System Based">System Based</MenuItem>
                                        <MenuItem value="Manual">Manual</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <TextField
                                        name="stepName"
                                        variant="outlined"
                                        fullWidth
                                        label="Step Name"
                                        value={data.stepName}
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "stepName", e);
                                        }}
                                        placeholder="Enter step name"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Model Name"
                                        name="modelUID"
                                        options={modalName}
                                        getOptionLabel={(option) => option.modelName}
                                        value={data?.modelUID ?
                                            modalName.find(option => option.modalUID === data.modelUID)
                                            || null : null}
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "modelUID", e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Model Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <InputLabel id="operatorType">Operator Type</InputLabel>
                                    <Select
                                        labelId="operatorType"
                                        name='operatorType'
                                        id="demo-simple-select-readonly"
                                        value={data.operatorType}
                                        fullWidth
                                        // onChange={(event, newValue) => handleStepChangeValue(index, newValue, "operatorType", event)}
                                        label="Operator Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="AI Forecasting">AI Forecasting</MenuItem>
                                        <MenuItem value="Override Value Prediction">Override Value Prediction</MenuItem>
                                        <MenuItem value="Demand Shaping Optimization">Demand Shaping Optimization</MenuItem>
                                        <MenuItem value="Inventory Optimization">Inventory Optimization</MenuItem>
                                        <MenuItem value="Measure Data">Measure Data</MenuItem>
                                        <MenuItem value="Supply Optimization">Supply Optimization</MenuItem>
                                    </Select>
                                </FormControl>

                                {data?.operatorType === "Measure Data" && (
                                    <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Measure Profile"
                                            name="measureProfile"
                                            options={measureProfileDataList}
                                            getOptionLabel={(option) => option.name}
                                            value={data?.measureProfile ?
                                                measureProfileDataList.find(option => option._id === data.measureProfile)
                                                || null : null}
                                            // onChange={(e, newValue) => {
                                            //   handleStepChangeValue(index, newValue, "measureProfile", e);
                                            // }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Measure Profile"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}

                                {data?.operatorType == "AI Forecasting" && (
                                    <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Profile Name"
                                            name="profile"
                                            options={profileData}
                                            getOptionLabel={(option) => option.name}
                                            value={data?.profile ?
                                                profileData.find(option => option._id === data.profile)
                                                || null : null}
                                            // onChange={(e, newValue) => {
                                            //   handleStepChangeValue(index, newValue, "profile", e);
                                            // }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Profile Name"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Filter"
                                        name="filter"
                                        options={
                                            filterDetail?.length > 0 ?
                                                filterDetail?.filter((item) => item?.enterpriseUID?._id === canvasFindData?.enterpriseUID?._id)
                                                    ?.filter((item) => item?.modelUID?.modelUID === canvasFindData?.modelUID.modelUID) : []
                                        }
                                        getOptionLabel={(option) => option.name}
                                        value={data?.filter ?
                                            filterDetail.find(option => option._id === data.filter)
                                            || null : null}
                                        // onChange={(e, newValue) => {
                                        //   handleStepChangeValue(index, newValue, "filter", e);
                                        // }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Filter Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <div>
                                        <Autocomplete
                                            // id="demo-simple-select-readonly"
                                            labelId="scenario"
                                            name="scenario"
                                            id="tags-standard"
                                            options={scenarioList}
                                            getOptionLabel={(option) => option?.["Scenario Name"]}
                                            value={data?.scenario ?
                                                scenarioList.find(option => option?.["Scenario Name"] === data?.scenario)
                                                || null : null}
                                            // onChange={(e, newValue) => {
                                            //   handleStepChangeValue(index, newValue, "scenario", e);
                                            // }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Scenario Name"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                </FormControl>
                            </>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setProcessStepDetail(false)
                        setOpenProcessModel(true);
                    }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleTerminate("Immediate")
                            setProcessStepDetail(false)
                            setOpenProcessModel(false)
                        }}
                    >
                        Run Immediate
                    </Button>
                    <Button
                        onClick={() => {
                            setProcessStepDetail(false)
                            setProcessLater(true)
                        }}
                    >
                        Run Later
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const ProcessLaterModel = ({
    processLater,
    setProcessLater,
    today,
    processName,
    handleRunProcessField,
    setProcessStepDetail,
    setOpenProcessModel,
    handleTerminate
}) => {
    return (
        <>
            <Dialog
                open={processLater}
                onClose={() => setProcessLater(false)}
                sx={{ padding: '20px' }}
                fullWidth
                maxWidth='xs'
            >
                <DialogTitle>Run Process Later</DialogTitle>
                <DialogContent sx={{ paddingTop: '10px !important' }}>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="selectDate"
                            label="Select Date"
                            variant="outlined"
                            fullWidth
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: today,
                            }}
                            value={processName?.selectDate}
                            // onChange={(e) => setFormValues({ ...formValues, selectDate: e.target.value })}
                            onChange={(event, newValue) => {
                                handleRunProcessField(newValue, 'selectDate', event);
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                        <TextField
                            id="selectTime"
                            label="Select Time"
                            variant="outlined"
                            fullWidth
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 1, // Allow input of seconds
                            }}
                            value={processName?.selectTime}
                            placeholder="HH:MM AM/PM"
                            // onChange={(e) => {
                            //   setFormValues({ ...formValues, selectTime: e.target.value });
                            // }}
                            onChange={(event, newValue) => {
                                handleRunProcessField(newValue, 'selectTime', event);
                            }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setProcessStepDetail(true)
                        setProcessLater(false)
                        setOpenProcessModel(false);
                    }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleTerminate("Later")
                            setProcessLater(false)
                            setProcessStepDetail(false)
                            setOpenProcessModel(false)
                        }}
                    >
                        Run Later
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
} 
