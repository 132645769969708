import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Typography, Box, Dialog, DialogTitle, Autocomplete } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changePassword, makeUserLogin } from "../../store/ApiSlice/authSlice";
import vyanLogo from '../../image/Black-vyan-logo.svg'
import { useSelector } from "react-redux";
import BackGroundImage from '../../image/login-background-image.jpg'
import { getUserList } from "../../store/ApiSlice/userSlice";

const ChangePassword = () => {
    // const { userOnline } = useSocket();
    const [formValue, setFormValue] = useState({})
    const [showPassword, setshowPassword] = useState(false)
    const [changepassword, setChangePassword] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = localStorage.getItem("accessToken");
    const userProfile = JSON.parse(user)

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        if (formValue?.currentPassword === "" || !formValue?.currentPassword) {
            toast.error("Please enter your current password");
        } else if (!passwordRegex.test(formValue?.currentPassword)) {
            toast.error("Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.");
        } else if (formValue?.latestPassword === "" || !formValue?.latestPassword) {
            toast.error("Please enter your latest password");
        } else if (!passwordRegex.test(formValue?.latestPassword)) {
            toast.error("Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.");
        } else {
            const body = {
                userUID: userProfile?.userId,
                currentPassword: formValue?.currentPassword,
                latestPassword: formValue?.latestPassword
            }

            let response = await dispatch(changePassword(body));
            if (response.meta.requestStatus === 'fulfilled') {
                toast.success(response?.payload.message)
                navigate("/login")
            } else if (response.meta.requestStatus === 'rejected') {
                toast.error(response?.error?.message)
            }
        }
    }

    const eye = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
    const eyeclose = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>

    return (
        <>
            <div style={{
                backgroundImage: `url(${BackGroundImage})`,
                height: '100vh',
                objectFit: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>

                <Container>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                        <Box sx={{
                            padding: '40px',
                            width: '80%',
                            height: '300px'
                        }}>
                            <img src={vyanLogo} alt=""
                                style={{
                                    width: '80%',
                                    height: '300px'
                                }} />
                        </Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: '100%',
                        }}>
                            <Box
                                sx={{
                                    width: '70%'
                                }}
                            >
                                <form noValidate autoComplete="off" onSubmit={handleChangePassword}>
                                    <Typography sx={{ fontWeight: 'bolder', }} gutterBottom>
                                        Current Password
                                    </Typography>

                                    <div style={{ position: "relative" }}>
                                        <TextField
                                            placeholder="Current Password"
                                            onChange={(e) => {
                                                setFormValue({
                                                    ...formValue,
                                                    currentPassword: e.target.value
                                                })
                                            }}
                                            required
                                            variant="outlined"
                                            color="secondary"
                                            type={showPassword ? "text" : "password"}
                                            value={formValue?.currentPassword}
                                            sx={{ mb: 2, width: "100%", '& input': { padding: '10px' } }}
                                        />
                                        {!showPassword && <div style={{ position: "absolute", top: "12px", right: "12px", cursor: "pointer" }} onClick={() => setshowPassword(true)}>
                                            {eye}
                                        </div>}
                                        {showPassword && <div style={{ position: "absolute", top: "12px", right: "12px", cursor: "pointer" }} onClick={() => setshowPassword(false)}>
                                            {eyeclose}
                                        </div>}
                                    </div>

                                    <Typography sx={{ fontWeight: 'bolder', }} gutterBottom>
                                        Latest Password
                                    </Typography>

                                    <div style={{ position: "relative" }}>
                                        <TextField
                                            placeholder="Latest Password"
                                            onChange={(e) => {
                                                setFormValue({
                                                    ...formValue,
                                                    latestPassword: e.target.value
                                                })
                                            }}
                                            required
                                            variant="outlined"
                                            color="secondary"
                                            type={changepassword ? "text" : "password"}
                                            value={formValue?.latestPassword}
                                            sx={{ mb: 2, width: "100%", '& input': { padding: '10px' } }}
                                        />
                                        {!changepassword && <div style={{ position: "absolute", top: "12px", right: "12px", cursor: "pointer" }} onClick={() => setChangePassword(true)}>
                                            {eye}
                                        </div>}
                                        {changepassword && <div style={{ position: "absolute", top: "12px", right: "12px", cursor: "pointer" }} onClick={() => setChangePassword(false)}>
                                            {eyeclose}
                                        </div>}
                                    </div>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        type="submit"
                                        fullWidth
                                    >
                                        Change Password
                                    </Button>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default ChangePassword;
