import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../ThemeContext';
import { Box, Button, Tooltip } from '@mui/material';
import MiniDrawer from '../common/drawer/DrawerToggle';
import CreateSuperAdministrator from './CreateSuperAdministrator';
import SuperAdministratorMaterialList from './SuperAdministratorMaterialList';
import { useDispatch } from 'react-redux';
import { getSuperAdministrator } from '../../store/ApiSlice/superAdministratorSlice';
import DeleteSuperAdministrator from './DeleteSuperAdministrator';
import { FiPlus } from "react-icons/fi";

const SuperAdministratorList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const dispatch = useDispatch()
    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }
    const handleTableRowSelect = (event, row) => {
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    useEffect(() => {
        dispatch(getSuperAdministrator())
    }, [])
    return (
        <>
            {!isCreate && (
                <>
                    {/* <Box sx={{ display: "flex" }}>
                        <MiniDrawer />
                        <Box component="main" sx={{
                            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                            background: isDarkTheme ? "whitesmoke" : "white",
                        }}> */}
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "20px",
                        position: "relative"
                    }}>
                        <h3 className="heading">Super Administrator</h3>
                        <div>
                            <div className="relative group">
                                <button
                                    onClick={() => handleCreateUpdate(false)}
                                    className="font-semibold rounded-full text-sm p-2 flex items-center gap-2 shadow-md hover:shadow-lg transition-all duration-300 transform active:scale-95"
                                    type="button"
                                    style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
                                >
                                    <FiPlus className="w-5 h-5 text-black" />
                                </button>
                                <div className="absolute bottom-full right-0 transform -translate-x-0 mb-1 w-max bg-gray-800 text-white text-[10px] font-normal py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition">
                                    Add Super Administrator
                                </div>
                            </div>
                            <DeleteSuperAdministrator
                                setModalDeleteOpen={setModalDeleteOpen}
                                isModalDeleteOpen={isModalDeleteOpen}
                                updatedId={updatedId}
                            />
                        </div>
                    </div>
                    <SuperAdministratorMaterialList
                        handleTableRowSelect={handleTableRowSelect}
                        setUpdatedId={setUpdatedId}
                        handleCreateUpdate={handleCreateUpdate}
                        setModalDeleteOpen={setModalDeleteOpen}
                    />
                    {/* </Box>
                    </Box> */}
                </>
            )}
            {(isCreate) && (
                <CreateSuperAdministrator
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    selectedRows={updatedId}
                />
            )}
        </>
    )
}

export default SuperAdministratorList
