import { Box, Button, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle'
import { ThemeContext } from '../../ThemeContext';
import RolematerialList from './RolematerialList';
import CreateRole from './CreateRole';
import { getRole } from '../../store/ApiSlice/roleSlice';
import { useDispatch } from 'react-redux';
import RoleDelete from './RoleDelete';
import { FiPlus } from "react-icons/fi";

const RoleList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const dispatch = useDispatch()

    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }
    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };
    useEffect(() => {
        dispatch(getRole())
    }, [])
    return (
        <>
            {!isCreate && !isView && (
                <>
                    {/* <Box sx={{ display: "flex" }}>
                        <MiniDrawer />
                        <Box component="main" sx={{
                            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                            background: isDarkTheme ? "whitesmoke" : "white",
                        }}> */}
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "20px",
                        position: "relative"
                    }}>
                        <h3 className="heading">Role</h3>
                        <div>
                            <div className="relative group">
                                <button
                                    onClick={() => handleCreateUpdate(false)}
                                    className="font-semibold rounded-full text-sm p-2 flex items-center gap-2 shadow-md hover:shadow-lg transition-all duration-300 transform active:scale-95"
                                    type="button"
                                    style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
                                >
                                    <FiPlus className="w-5 h-5 text-black" />
                                </button>
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-max bg-gray-800 text-white text-[10px] font-normal py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition">
                                    Add Role
                                </div>
                            </div>
                            <RoleDelete
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                setModalDeleteOpen={setModalDeleteOpen}
                                isModalDeleteOpen={isModalDeleteOpen}
                                updatedId={updatedId}
                            />
                        </div>
                    </div>
                    <RolematerialList
                        handleTableRowSelect={handleTableRowSelect}
                        setUpdatedId={setUpdatedId}
                        handleCreateUpdate={handleCreateUpdate}
                        setModalDeleteOpen={setModalDeleteOpen}
                    />
                    {/* </Box>
                    </Box> */}
                </>
            )}
            {(isCreate || isView) && (
                <CreateRole
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    selectedRows={updatedId}
                />
            )}
        </>
    )
}

export default RoleList
