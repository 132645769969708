import axios from "axios";
import { baseUrl } from "./url";

const Axios = axios.create({
    baseURL: baseUrl,
})

export const api = async (url, type, data, headers = {}, options = {}) => {
    let requestTypes = ['get', 'post', 'put', 'patch', 'delete']
    let requestType = requestTypes.find((reqType) => { return reqType === type })
    let reqHeaders = headers;
    let authToken = localStorage.getItem('accessToken');
    if (authToken) {
        reqHeaders['authorization'] = `Bearer ${JSON.parse(authToken).token}`
    }
    if (requestType) {
        try {
            const response = await Axios({ method: requestType, url: url, data: data, headers: reqHeaders, ...options })
            return response
        } catch (error) {
            return Promise.reject(error.response.data || error)
        }
    } else {
        return Promise.reject("invalid request type")
    }
}
