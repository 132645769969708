import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const DeleteModal = ({
    isModalOpenDelete,
    setModalOpenDelete,
    attriId,
    setSelectedRows,
    title,
    deleteAPI,
    getAPI
}) => {
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        setModalOpenDelete(false);
        setSelectedRows([]);
    };
    const handleDelete = async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };

        dispatch(deleteAPI({ attriId, config }))
            .then((response) => {
                if (response?.meta.requestStatus === 'fulfilled') {
                    // toast.success(`${title} Deleted Successfully`);
                    dispatch(getAPI());
                    handleCloseModal();
                }
            })
            .catch((error) => {
                toast.error(error?.message || `An error occurred while deleting ${title}.`);
            });

        handleCloseModal();
    };
    const renderModalContent = () => {
        return (
            <>
                <DialogTitle>Delete {title}</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography>
                        This Is Irreversible. Are You Sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDelete();
                        }}
                        color="primary" sx={{ textTransform: "capitalize" }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };
    return (
        <>
            <Dialog open={isModalOpenDelete} onClose={handleCloseModal}>
                {renderModalContent()}
            </Dialog>
        </>
    )
}

export default DeleteModal
