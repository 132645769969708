// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awesome-button {
  /* background: white !important; */
  border-radius: 3px;
  font-weight: bold;
  border: 0;
  color: black !important;
  height: 35px;
  padding: 0 10px;
  /* margin:0 5px ; */
  /* box-shadow: 0 3px 5px 2px rgba(41, 46, 156, 0.3); */
}

.awesome-button-disabled {
}
.homeAnc{
    text-decoration: none;
}
.awesome-button:hover {
  height: 40px;
}
.heading {
  /* margin-left: 15px; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tableHeader {
  background: white;
  color: black;
  padding: 15px;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
}
.tableHeader th {
  color: black;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tableRows tr {
  transition: background-color 0.2s;
}
.tableMain th {
  padding: 1px;
}
.tableRows tr:hover {
  background-color: #d5dce2;
}
.cancelButton {
  background: #e57373;
  border-radius: 3px;
  border: 0;
  font-weight: bold;
  color: black;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
}

.cancelButton:hover {
  background: #d32f2f;
}
`, "",{"version":3,"sources":["webpack://./src/style/common.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,iBAAiB;EACjB,SAAS;EACT,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,sDAAsD;AACxD;;AAEA;AACA;AACA;IACI,qBAAqB;AACzB;AACA;EACE,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,gDAAgD;AAClD;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;;AAEA;EACE,iCAAiC;AACnC;AACA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kDAAkD;AACpD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".awesome-button {\n  /* background: white !important; */\n  border-radius: 3px;\n  font-weight: bold;\n  border: 0;\n  color: black !important;\n  height: 35px;\n  padding: 0 10px;\n  /* margin:0 5px ; */\n  /* box-shadow: 0 3px 5px 2px rgba(41, 46, 156, 0.3); */\n}\n\n.awesome-button-disabled {\n}\n.homeAnc{\n    text-decoration: none;\n}\n.awesome-button:hover {\n  height: 40px;\n}\n.heading {\n  /* margin-left: 15px; */\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n\n.tableHeader {\n  background: white;\n  color: black;\n  padding: 15px;\n  text-align: left;\n  font-size: 18px;\n  cursor: pointer;\n}\n.tableHeader th {\n  color: black;\n  font-size: 16px;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n\n.tableRows tr {\n  transition: background-color 0.2s;\n}\n.tableMain th {\n  padding: 1px;\n}\n.tableRows tr:hover {\n  background-color: #d5dce2;\n}\n.cancelButton {\n  background: #e57373;\n  border-radius: 3px;\n  border: 0;\n  font-weight: bold;\n  color: black;\n  height: 48px;\n  padding: 0 30px;\n  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);\n}\n\n.cancelButton:hover {\n  background: #d32f2f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
