import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import { RiDraggable } from 'react-icons/ri';
import { FaEye } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    getMeasureListWithEnterprise,
    getMeasurePaginationList,
} from '../../store/ApiSlice/measureSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import {
    createFilterDetail,
    createWorkBookDetail,
    getPageList,
    getPrimaryTimeDimension,
    getSchemaData,
    getWorkBookDetail,
} from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import { getAttributeOrEnterPrise } from '../../store/ApiSlice/attributeSlice';
import { getEnterModelList } from '../../store/ApiSlice/modelSlice';
import WidgetFilter from './WidgetFilter';
import TimePeriodFilter from '../canvas/shared/TimePeriodFilter';
import PageFilter from '../canvas/shared/PageFilter';
import { CycleIdData } from '../canvas/shared/MeasureData';
import { getCycleIdList } from '../../store/ApiSlice/widgetSlice';
import { ChevronRight } from '@mui/icons-material';
import MultiSelectDropdown from '../canvas/shared/MultiSelectDropdown';
import { components } from "react-select";
import { FixedSizeList as List } from "react-window";
import { getLagIdList } from '../../store/ApiSlice/dimensionSlice';
import { debounce } from 'lodash';
import { Chip, FormControlLabel, styled, Switch } from '@mui/material';
import Loader from '../loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { generateFilterPayload } from '../SystemAttributeValues/generatePayload.utils';


export const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '100%',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        fontSize: '12px',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 9999,
        fontSize: '12px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#0366d6' : 'white',
        color: state.isSelected ? 'white' : 'black',
        fontSize: '12px',
    }),
    multiValue: (base, state) => {
        return state.data.isFixed
            ? { ...base, backgroundColor: 'red', fontSize: '12px' }
            : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? {
                ...base,
                fontWeight: 'bold',
                color: 'white',
                paddingRight: 6,
                fontSize: '12px',
            }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

const customStylesCycleId = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? "1px solid #000" : "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "none",
        fontSize: "14px",
        padding: "5px",
        height: "40px",
        alignContent: "center",
        "&:hover": {
            border: "1px solid #000",
        },
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0 10px",
    }),
    singleValue: (base) => ({
        ...base,
        color: "#000",
        fontWeight: "400",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "#000",
        padding: "8px",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    menu: (base) => ({
        ...base,
        zIndex: 9999,
    }),
};

const cyclePeriodicity = [
    "Day",
    "SysWeek",
    "Month",
    "Week",
    "Quarter",
    "Year"
]

const systemDefaultAttributes = [
    "Scenario Name",
    "Time Period Name",
    "Period Base Level",
    "Period Start",
    "Period End",
    "Cycle Periodicity",
    "Cycle Start",
    "Cycle End",
    "Cycle Notes",
];

const WidgetType = [
    {
        label: "Pivot Table",
        value: "Pivot Table"
    }
]

const PivotTable = ({
    isModalOpen,
    changeType,
    isSidebarOpen,
    chartData,
    setChartData,
    setIsOpenWidgetModel,
    processPageData,
    openedWorkbookName,
    newPageID,
    value,
}) => {
    const { id } = useParams();
    const [newFilter, setNewFilter] = useState(false);
    const [viewWidgetFilter, setViewWidgetFilter] = useState(false);
    const [newPageFilter, setNewPageFilter] = useState(false);
    const { modalData } = useSelector((state) => state.modelList);
    const { scenarioList } = useSelector((state) => state.process);
    const { attribute } = useSelector((state) => state.attribute);
    const {
        canvasData,
        filterEnterpriseDetail,
        pageData,
        layout,
        timeDimensionData,
        schemaData,
        filterDetail,
        workBookData,
    } = useSelector((state) => state.canvas);
    const canvasFindData = canvasData?.find((item) => item?._id == id);
    
    const [isRowModalOpen, setRowModalOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [isColumnModalOpen, setColumnModalOpen] = useState(false);
    const [columns, setColumns] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [selectedAttributes, setSelectedAttributes] = useState([{
        attributes: "",
        operator: 'Equal To',
        attValues: {},
    }]);
    const [selectedPageAttributes, setSelectedPageAttributes] = useState([]);
    const baselineScenario =
        scenarioList?.length > 0
            ? scenarioList?.find((item) => item?.scenarioName === 'Baseline')
            : [];
    const [selectedMeasures, setSelectedMeasures] = useState({
        measure: [],
        attribute: [],
        dimension:[],
        scenario: [
            {
                _id: baselineScenario._id,
                scenario: baselineScenario?.scenarioName,
            },
        ],
        rowScenario: [
            {
                _id: baselineScenario._id,
                scenario: baselineScenario?.scenarioName,
            },
        ],
    });
    const [selectedValue, setSelectedValue] = useState({});
    const [openModalRow, setOpenModalRow] = useState(null);
    const [attributeValueData, setAttributeValueData] = useState({
        attValues: {},
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [timePeriodData, setTimePeriodData] = useState([]);
    const listInnerRef = useRef();
    const [lastList, setLastList] = useState(false);
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [userList, setUserList] = useState([]);
    const [formValues, setFormValues] = useState({
        widgetType: 'Worksheet',
        widgetSubType: 'Measure Data',
        measure: [],
        logicalOperator: "In Between",
        logicalOperatorRows: "In Between",
        pageLogicalOperator: "In Between",
        chartType: "bar",
        startDate :"",
        endDate:""
    });
    const [selectedDimensions, setSelectedDimensions] = useState([]);

    const { measureData, measurePaginationList, measureList } = useSelector(
        (state) => state.measure
    );
    const { cycleIDList, pending } = useSelector((state) => state.widget);
    const { dimensionLagIDdata, allDimensions } = useSelector(
        (state) => state.dimension
    );
    const [search, setSearch] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [isToggledWidgetSystemAttribute, setIsToggledWidgetSystemAttribute] = useState(false);
    const [toggleClassicLayout, setToggleClassicLayout] = useState(false);
    const [toggleDefaultChart, setDefaultChart] = useState(false);
    const [loading, setLoading] = useState(false);

    const [systemAttributeList, setSystemAttributeList] = useState([{
        id: uuidv4(),
        attributes: "scenario",
        cyclePeriodicity: "",
        operator: "In Between",
        attValues: {
            Baseline: true
        }
    }]);
    

    const handleToggle = (event) => {
        setIsToggled(event.target.checked);
    };
    const handleToggleSystemAttribute = (event) => {
        setIsToggledWidgetSystemAttribute(event.target.checked);
    };

    const dispatch = useDispatch();

    const addColumn = () => setColumnModalOpen(true);

    const removeRow = (index, data) => {
        const removeMeasure = selectedMeasures?.measure?.filter(
            (item) => item?._id !== data?._id
        );

        const removeAttribute = selectedMeasures?.attribute?.filter(
            (item) => item?._id !== data?._id
        );
        const removescenario = selectedMeasures?.scenario?.filter(
            (item) => item?._id !== data?._id
        );
        setSelectedMeasures({
            ...selectedMeasures,
            measure: removeMeasure,
            attribute: removeAttribute,
            scenario: removescenario,
        });
        setRows(rows.filter((_, i) => i !== index));
    };

    const removeColumn = (index, data) => {
        const removeMeasure = selectedMeasures?.measure?.filter(
            (item) => item?._id !== data?._id
        );
        const removeAttribute = selectedMeasures?.attribute?.filter(
            (item) => item?.attributeUID !== data?.attributeUID
        );
        const removescenario = selectedMeasures?.scenario?.filter(
            (item) => item?._id !== data?._id
        );
        setSelectedMeasures({
            ...selectedMeasures,
            measure: removeMeasure,
            attribute: removeAttribute,
            scenario: removescenario,
        });

        setColumns(columns.filter((_, i) => i !== index));
    };

    const toggleMeasure = (e, data, fieldName) => {
        if (fieldName === 'attribute') {
            setSelectedMeasures((prev) => {
                if (e.target.checked) {
                    return {
                        ...prev,
                        attribute: [
                            ...prev.attribute,
                            {
                                attributeUID: data?._id,
                                _id: data?._id,
                                attributeName: data?.name,
                            },
                        ], // Add measure to array
                    };
                } else {
                    return {
                        ...prev,
                        attribute: prev.attribute.filter((m) => m._id !== data._id), // Remove measure
                    };
                }
            });
        } else if (fieldName === 'measure') {
            setSelectedMeasures((prev) => {
                if (e.target.checked) {
                    return {
                        ...prev,
                        measure: [
                            ...prev.measure,
                            {
                                measureUID: data?._id,
                                _id: data?._id,
                                measureName: data?.measureName,
                                enterpriseUID: data?.enterpriseUID,
                                modelUID: data?.modelUID,
                                baselevelUID: data?.baselevelUID?._id,
                            },
                        ], // Add measure to array
                    };
                } else {
                    return {
                        ...prev,
                        measure: prev.measure.filter(
                            (m) => m.measureName !== data.measureName
                        ), // Remove measure
                    };
                }
            });
        } else if (fieldName === 'scenario') {
            setSelectedMeasures((prev) => {
                if (e.target.checked) {
                    return {
                        ...prev,
                        scenario: [
                            ...prev.scenario,
                            {
                                _id: data?._id,
                                scenario: data?.scenarioName,
                            },
                        ], // Add measure to array
                    };
                } else {
                    return {
                        ...prev,
                        scenario: prev.scenario.filter(
                            (m) => m.scenario !== data.scenarioName
                        ), // Remove measure
                    };
                }
            });
        }
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return; // If dropped outside

        const sourceList = source.droppableId === 'rows' ? rows : columns;
        const destinationList = destination.droppableId === 'rows' ? rows : columns;
        const setSourceList = source.droppableId === 'rows' ? setRows : setColumns;
        const setDestinationList =
            destination.droppableId === 'rows' ? setRows : setColumns;

        // If dragging within the same list
        if (source.droppableId === destination.droppableId) {
            const reorderedList = [...sourceList];
            const [movedItem] = reorderedList.splice(source.index, 1);
            reorderedList.splice(destination.index, 0, movedItem);
            setSourceList(reorderedList);
        } else {
            // Moving between lists
            const newSourceList = [...sourceList];
            const newDestinationList = [...destinationList];
            const [movedItem] = newSourceList.splice(source.index, 1);
            newDestinationList.splice(destination.index, 0, movedItem);
            setSourceList(newSourceList);
            setDestinationList(newDestinationList);
        }
    };

    // const data = {
    //     enterpriseUID: canvasFindData?.enterpriseUID?._id,
    //     modelUID: canvasFindData?.modelUID?.modalUID
    // }
    // useEffect(() => {
    //     dispatch(getMeasureListWithEnterprise({ formValues: data }))
    // }, [])
    const [debouncedSearch, setDebouncedSearch] = useState("");
    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearch(search);
        }, 500); // Wait for 500ms before updating

        handler();
        return () => handler.cancel(); // Cleanup
    }, [search]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const fetchData = async () => {
        setLoading(true);  // Start the loader when the fetch begins

        try {
            const fetchedData = await dispatch(
                getMeasurePaginationList({
                    page: currPage,
                    itemsPerPage: 10,
                    search: debouncedSearch,
                    enterpriseUID: canvasFindData?.enterpriseUID?._id,
                    modelUID: canvasFindData?.modelUID?.modalUID,
                })
            );

            const newData = fetchedData?.payload?.data || [];

            setPrevPage(currPage);
            if (debouncedSearch) {
                setUserList(fetchedData?.payload?.data);
            } else {
                setUserList((prevList) => [...prevList, ...newData]);
            }
        } catch (error) {
            // Handle error if needed
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);  // Hide loader after the data fetch is complete
        }
    };


    useEffect(() => {
        setLastList(false); // Reset pagination status
        fetchData();
    }, [debouncedSearch, currPage]);

    useEffect(() => {
        setCurrPage(1); // Reset to first page when search changes
    }, [debouncedSearch]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setCurrPage(currPage + 1);
            }
        }
    };

    const findMeasure = measureList?.filter(
        (item) =>
            item?.enterpriseUID === canvasFindData?.enterpriseUID?._id &&
            item?.modelUID === canvasFindData?.modelUID?.modalUID
    );

    const findMeasureLagId = measureList?.filter(
        (item) =>
            Array.isArray(selectedMeasures?.measure) &&
            selectedMeasures.measure.some((row) => row?.measureUID === item?._id)
    );

    const [selectedOptions, setSelectedOptions] = useState({
        attributes: [],
        validFrom: '',
    });
    const findLagId =
        findMeasureLagId?.length > 0 &&
        formValues?.widgetSubType === 'Measure Data' &&
        findMeasureLagId?.filter((row) => row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID'));
    const now = new Date();
    const startOfMonth = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-DD-MM');
    const endOfMonth = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');

    const findDiemnsion = allDimensions?.find(
        (item) => item?._id === selectedOptions?.dimension
    );

    const findCycleIdMonth =
        findLagId?.length > 0 && cycleIDList?.length > 0
            ? cycleIDList?.filter((item) => {
                const validFromDate = moment(item['Cycle Start']).format(
                    'YYYY-MM-DD'
                );
                const validToDate = moment(item['Cycle End'], 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                );
                return startOfMonth <= validFromDate && endOfMonth >= validToDate;
            })
            : [];

    const handleChangeAttribute = (data, type) => {
        if (type === 'filter' || type === 'dimension') {
            setSelectedOptions((prevState) => ({
                ...prevState,
                [type]: data?.value,
            }));
        } else if (type === 'cycleId') {
            const cycleIDPeriodicity = cycleIDList?.filter(
                (item) => item['Cycle Periodicity'] === formValues?.cyclePeriodicity
            );
            const selectedIds = data?.map((item) => item.value);
            const selectedLabels = data?.map((item) => item.label);
            if (selectedIds.length < selectedOptions?.cycleId?.length) {
                const newRangeSelected = selectedOptions?.cycleId?.filter((item) =>
                    selectedIds?.includes(item?._id)
                );
                setSelectedOptions((prevState) => ({
                    ...prevState,
                    cycleId: newRangeSelected,
                }));
            } else if (selectedIds?.length >= 2) {
                // Handle multi-selection
                const sortedCycleList = [...cycleIDPeriodicity]?.sort(
                    (a, b) => new Date(a['Cycle Start']) - new Date(b['Cycle Start'])
                );

                const startIndex = sortedCycleList?.findIndex(
                    (item) => item['Cycle ID'] === selectedLabels[0]
                );
                const endIndex = sortedCycleList?.findIndex(
                    (item) =>
                        item['Cycle ID'] === selectedLabels[selectedLabels?.length - 1]
                );
                const [start, end] =
                    startIndex < endIndex
                        ? [startIndex, endIndex]
                        : [endIndex, startIndex];
                const rangeSelected = sortedCycleList?.slice(start, end + 1);

                // const uniqueCycles = Array.from(
                //     new Map(rangeSelected?.map(item => [`${item["Cycle Start"]}-${item["Cycle End"]}`, item])).values()
                // );

                const selectedCycleData =
                    rangeSelected?.length > 0 &&
                    rangeSelected?.map((item) => ({
                        _id: item?._id,
                        cyclePeriodicity: item['Cycle ID'],
                        isFixed: true,
                    }));

                setSelectedOptions((prevState) => ({
                    ...prevState,
                    cycleId: selectedCycleData,
                }));
            } else if (selectedIds.length === 1) {
                // Handle single selection
                const singleSelection =
                    data?.length > 0 &&
                    data?.map((item) => ({
                        _id: item?.value,
                        cyclePeriodicity: item?.label,
                        isFixed: true,
                    }));
                setSelectedOptions((prevState) => ({
                    ...prevState,
                    cycleId: singleSelection,
                }));
            }
        } else if (type === 'lagID') {
            const selectedIds = data?.map((option) => option.value);
            const selectedLabels = data?.map((item) => item.label);
            if (selectedIds?.length < selectedOptions?.lagID?.length) {
                const newRangeSelected = selectedOptions?.lagID?.filter((item) =>
                    selectedIds?.includes(item?._id)
                );
                setSelectedOptions((prevState) => ({
                    ...prevState,
                    lagID: newRangeSelected,
                }));
            } else if (selectedIds?.length >= 2) {
                const sortedSelected = [...dimensionLagIDdata]?.sort((a, b) => a - b);
                const startIndex = sortedSelected?.findIndex(
                    (item) => item['Lag ID'] === selectedLabels[0]
                );
                const endIndex = sortedSelected?.findIndex(
                    (item) =>
                        item['Lag ID'] === selectedLabels[selectedLabels?.length - 1]
                );
                const [start, end] =
                    startIndex < endIndex
                        ? [startIndex, endIndex]
                        : [endIndex, startIndex];
                const rangeSelected = sortedSelected?.slice(start, end + 1);

                const selectedCycleData =
                    rangeSelected?.length > 0 &&
                    rangeSelected?.map((item) => ({
                        _id: item?._id,
                        lagID: item['Lag ID'],
                    }));
                setSelectedOptions((prevState) => ({
                    ...prevState,
                    lagID: selectedCycleData,
                }));
            } else if (selectedIds.length === 1) {
                const singleSelection =
                    data?.length > 0 &&
                    data?.map((item) => ({
                        _id: item?.value,
                        lagID: item?.label,
                    }));
                setSelectedOptions((prevState) => ({
                    ...prevState,
                    lagID: singleSelection,
                }));
            }
        } else {
            const selectedAttribute =
                data?.length > 0 &&
                data?.map((item) => {
                    if (type === 'attributes') {
                        const dataItem = findDiemnsion?.primaryKey?.find(
                            (row) => row?._id === item?.value
                        );
                        return {
                            attributeName: item?.label,
                            attributeUID: item?.value,
                            ...(dataItem && {
                                isFixed: true,
                            }),
                        };
                    } else if (type === 'scenario') {
                        return {
                            _id: item?.value,
                            scenario: item?.label,
                        };
                    } else if (type === 'cycleId') {
                        return {
                            _id: item?.value,
                            cyclePeriodicity: item?.label,
                            isFixed: true,
                        };
                    } else if (type === 'lagID') {
                        return {
                            _id: item?.value,
                            lagID: item?.label,
                        };
                    } else if (type === 'measures') {
                        return {
                            measureName: item?.label,
                            measureUID: item?.value,
                            enterpriseUID: item?.enterpriseUID,
                            modelUID: item?.modelUID,
                            baselevelUID: item?.baselevelUID,
                        };
                    } else if (type === 'yAxis') {
                        return {
                            measureName: item?.label,
                            measureUID: item?.value,
                        };
                    } else if (type === 'xAxis') {
                        return {
                            attributeName: item?.label,
                            attributeUID: item?.value,
                        };
                    }
                    // else if (type === 'iterationID') {
                    //   return {
                    //     iterationID: item?.label,
                    //     id: item?.value,
                    //   };
                    // } else if (type === 'iterationType') {
                    //   return {
                    //     iterationType: item?.label,
                    //     id: item?.value,
                    //   };
                    // } else if (type === 'iterationDriver') {
                    //   return {
                    //     iterationDriver: item?.label,
                    //     id: item?.value,
                    //   };
                    // }
                    return null;
                });
            setSelectedOptions((prevState) => ({
                ...prevState,
                [type]: selectedAttribute,
            }));
        }
    };

    useEffect(() => {
        dispatch(getCycleIdList({ canvasFindData }))
        dispatch(getLagIdList({ canvasFindData }))
    }, [])
    const cycleIDPeriodicity = cycleIDList?.filter((item) => item["Cycle Periodicity"] === formValues?.cyclePeriodicity)
    const memoizedOptions = useMemo(
        () => cycleIDPeriodicity.map((item) => ({
            value: item?._id,
            label: item['Cycle ID'],
        })),
        [cycleIDPeriodicity]
    );

    const cycleIDRowPeriodicity = cycleIDList?.filter((item) => item["Cycle Periodicity"] === formValues?.rowCyclePeriodicity)
    const memoizedRowOptions = useMemo(
        () => cycleIDRowPeriodicity.map((item) => ({
            value: item?._id,
            label: item['Cycle ID'],
        })),
        [cycleIDRowPeriodicity]
    );

    const removeDuplicates = (array) => {
        const map = new Map();
        return (
            array?.length > 0 &&
            array?.reduce((acc, item) => {
                const key = JSON.stringify(item.name);
                if (!map.has(key)) {
                    map.set(key, true);
                    acc.push(item);
                }
                return acc;
            }, [])
        );
    };

    const uniqueItems = removeDuplicates(
        findMeasureLagId?.flatMap((item) => item?.baselevelUID?.fields || [])
    );

    const findDiemnsionAttribute = uniqueItems;
    const isTabOpenPassKey = formValues?.timePeriodNameRows
    const findTimePeriod =
        schemaData?.length > 0 &&
        isTabOpenPassKey &&
        schemaData?.filter(
            (item) => item['Period Base Level'] === isTabOpenPassKey
        );
    const isModelOpenValidFrom = selectedMeasures?.pageValidFrom
    const isModelOpenValidTo = selectedMeasures?.pageValidTo
    const isModelOpenStartDate = selectedMeasures?.pageStartDate
    const isModelOpenEndDate = selectedMeasures?.pageEndDate


    const validToOptions =
        findTimePeriod?.length > 0 &&
        findTimePeriod?.filter((option) => {
            if (!isModelOpenStartDate) return false;
            // if (option["Time Period Name"] === selectedMeasures.validFrom) return false;
            const optionStartDate = option['Period Start'];
            return (
                moment(optionStartDate).format('YYYY-MM-DD') >=
                moment(isModelOpenStartDate).format('YYYY-MM-DD')
            );
        });

    const [query, setQuery] = useState('');
    const filteredOptions = Array.isArray(findTimePeriod)
        ? findTimePeriod?.filter((option) =>
            option?.['Time Period Name']
                ?.toLowerCase()
                ?.includes(query.toLowerCase())
        )
        : [];
    const filteredValidToOptions = Array.isArray(validToOptions)
        ? validToOptions?.filter((option) =>
            option?.['Time Period Name']
                ?.toLowerCase()
                ?.includes(query.toLowerCase())
        )
        : [];

    const dataTimePeriod = () => {
        const data =
            findTimePeriod?.length > 0 &&
            findTimePeriod?.filter((item) => {
                const validFromDate = moment(item['Period Start']).format('YYYY-MM-DD');
                const validToDate = moment(item['Period End']).format('YYYY-MM-DD');
                const startDate = moment(isModelOpenStartDate).format(
                    'YYYY-MM-DD'
                );
                const endDate = moment(isModelOpenEndDate).format('YYYY-MM-DD');
                return startDate <= validFromDate && endDate >= validToDate;
            });
        setTimePeriodData(data);
    };

    useEffect(() => {
        if ((isModelOpenValidFrom && isModelOpenValidTo) || chartData?.value) {
            dataTimePeriod();
        }
    }, [isModelOpenValidFrom, isModelOpenValidTo, chartData?.value]);

    const validation = () => {
        const findLagId = findMeasureLagId?.filter((row) =>
            row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
        );
        if (formValues?.widgetName === '' || formValues?.widgetName === undefined) {
            toast.error('Please enter widget name');
            return true;
        } else if (!selectedMeasures?.measure?.length > 0 && formValues.widgetSubType !== "Dimension Data") {
            toast.error('Please select measure.');
            return true;
        } else if (!selectedMeasures?.attribute?.length > 0) {
            toast.error('Please select attributes.');
            return true;
        } else if ((formValues?.timePeriodRows === "" || formValues?.timePeriodRows === undefined) && formValues.widgetSubType !== "Dimension Data") {
            toast.error('Please select time period type.');
            return true;
        }
        // else if (formValues?.pageTimePeriodName === "" || formValues?.pageTimePeriodName === undefined) {
        //     toast.error("Please select widget filter time period type")
        //     return
        // } else if (selectedMeasures?.pageStartDate === "" || selectedMeasures?.pageStartDate === undefined) {
        //     toast.error("Please select widget filter time period from date")
        //     return
        // } else if (selectedMeasures?.pageEndDate === "" || selectedMeasures?.pageEndDate === undefined) {
        //     toast.error("Please select widget filter time period to date")
        //     return
        // } 
        // else if (findLagId?.length > 0 && (!selectedOptions?.lagID?.length || !selectedOptions?.lagID)) {
        //     toast.error('Please select lag ID');
        //     return true;
        // } else if (findLagId?.length > 0 && !selectedOptions?.cycleId) {
        //     toast.error('Please select cycle Id');
        //     return true;
        // }
        return false;
    };

    const handleSubmit = async () => {
        const findFilterName = filterDetail?.find(
            (item) => item?._id === formValues?.filter
        );
        if (validation()) {
            return;
        } else {
            // const body = {
            //     enterpriseUID: canvasFindData?.enterpriseUID?._id,
            //     modelUID: canvasFindData?.modelUID?._id,
            //     name: newFilter ? formValues?.filterName : findFilterName?.name,
            //     attributes: selectedAttributes?.map((item) => ({
            //         attributeUID: item?.attributes,
            //         values: Object.entries(item?.attValues)
            //             .filter(([key, value]) => value === true)
            //             .map(([key, value]) => key),
            //         operator: item?.operator,
            //     })),
            // };
            // const responce = await dispatch(
            //     createFilterDetail({
            //         payload: body,
            //         isEdit: !newFilter,
            //         selectedRows: formValues?.filter,
            //     })
            // );
            // if (responce?.meta?.requestStatus === 'fulfilled') {
            const filterSystemAtt = generateFilterPayload(systemAttributeList, []);
            let payload = {
                enterpriseUID: canvasFindData?.enterpriseUID?._id,
                modelUID: canvasFindData?.modelUID?.modalUID,
                canvasUID: canvasFindData?._id,
                pageUID: formValues?.pageUID,
                notes: formValues?.workbookNotes,
                widgetName: formValues?.widgetName,
                widgetType: formValues.widgetType || "Pivot Table",
                widgetGroup: formValues?.widgetName,
                widgetSubType: formValues?.widgetSubType,
                classicLayout: toggleClassicLayout,
                defaultChart: toggleDefaultChart,
                isWidgetFilterActive:isToggled || false,
                ...(isToggled && {
                    filterInfo: {
                        ...((formValues?.filter !== "" || formValues?.filter !== undefined) && ({
                            filterRefID: formValues?.filter,
                        })),
                        ...(selectedAttributes?.length > 0 && ({
                            attributes: selectedAttributes?.map((item) => ({
                                attributeUID: item?.attributes,
                                values: Object.entries(item?.attValues)
                                    .filter(([key, value]) => value === true)
                                    .map(([key, value]) => key),
                                operator: item?.operator,
                            }))
                        })),
                        timeAttribute: {
                            // timeAttribute: formValues?.pageTimePeriodName,
                            periodType: formValues?.timePeriodNameRows,
                            operator: formValues?.pageLogicalOperator,
                            // values: timePeriodData?.map((item) => {
                            //     return item['Time Period Name'];
                            // }),
                            // from: selectedMeasures?.pageStartDate || formValues.startDate,
                            // to: selectedMeasures?.pageEndDate || formValues.endDate
                            from: moment(selectedMeasures?.pageStartDate || formValues.startDate).format('DD-MM-YYYY'),
                            to: moment(selectedMeasures?.pageEndDate || formValues.endDate).format('DD-MM-YYYY')
                        },
                        ...(isToggledWidgetSystemAttribute && ({
                            systemAttributes: {
                                scenario:
                                    selectedMeasures?.scenario?.length > 0 &&
                                    selectedMeasures?.scenario?.map((item) => item?.scenario),
                                // ...(findLagId?.length > 0 && ({
                                cyclePeriodicity: formValues?.cyclePeriodicity,
                                cycleIds: selectedOptions?.cycleId?.length > 0 ? selectedOptions?.cycleId?.map((item) => item?.cyclePeriodicity) : [],
                                ...(selectedOptions?.lagID?.length > 0 && ({
                                    lagIds: selectedOptions?.lagID?.map((item) => item?.lagID)
                                }))
                                // }))
                            }
                        })),
                        ...(isToggled && ({
                            systemAttributes: filterSystemAtt.systemAttributes
                        }))

                    }
                }),
                pivotTable: {
                    ...(formValues?.widgetSubType === 'Dimension Data' && {
                        dimension: selectedMeasures?.dimension,
                    }),
                    ...((formValues?.widgetSubType === 'Measure Data' ||
                        formValues?.widgetType === 'Chart') &&
                        selectedMeasures?.measure?.length > 0 && {
                        measures: selectedMeasures?.measure?.map((item) => ({
                            measureName: item?.measureName,
                            measureUID: item?.measureUID,
                            baselevelUID: item?.baselevelUID,
                            enterpriseUID: item?.enterpriseUID,
                            modelUID: item?.modelUID,
                        })),
                    }),
                    ...(selectedMeasures?.attribute?.length > 0 && {
                        attributes: selectedMeasures?.attribute?.map((item) => ({
                            attributeName: item?.attributeName,
                            attributeUID: item?.attributeUID,
                        })),
                    }),
                    layoutSetting: {
                        row:
                            rows?.length > 0
                                ? rows?.map((item, index) => ({
                                    order: index + 1,
                                    fieldName:
                                        item?.measureName ||
                                        item?.attributeName ||
                                        item?.timePeriodNameRows ||
                                        item?.scenario,
                                }))
                                : [],
                        column:
                            columns?.length > 0
                                ? columns?.map((item, index) => ({
                                    order: index + 1,
                                    fieldName:
                                        item?.measureName ||
                                        item?.attributeName ||
                                        item?.timePeriodNameRows ||
                                        item?.scenario,
                                }))
                                : [],
                    },
                    chartType: formValues?.chartType,
                    timePeriodType: columns[0]?.timePeriodNameRows || ""
                },
                coordinates: {
                    x: 0,
                    y: 0,
                    w: 4,
                    h: 5,
                    i: 0,
                    minW: 4,
                    moved: false,
                    static: false,
                },
            };
            console.log("payload.........123", payload)
            // if (!isToggled){
            //     delete payload.filterInfo
            //     const pageFilter = (canvasFindData.pages ?? []).find((item) => item?.pageUID?._id === payload.pageUID);
                
            //     if (pageFilter?.pageUID?.filter){
            //         payload.filterInfo = pageFilter?.pageUID?.filter
            //     }
            // }

            console.log("payload.........", payload)
            const result = await dispatch(
                createWorkBookDetail({
                    payload,
                    openModal: chartData?.value ? true : false,
                    updatedId: chartData?.value?.widgetUID?._id,
                })
            );
            if (result?.meta?.requestStatus === 'fulfilled') {
                dispatch(getWorkBookDetail());
                setIsOpenWidgetModel(false)
                if (isModalOpen && changeType) {
                    setChartData({
                        ...chartData,
                        value: {
                            widgetUID: result?.payload?.data,
                        },
                    });
                }
                setFormValues({});
                setSelectedMeasures({
                    measure: [],
                    attribute: [],
                });
                setSelectedAttributes([]);
                // setIsOpenWidgetModel(false)
                processPageData(result?.payload?.data);
            } else if (result?.meta?.requestStatus === 'rejected') {
                toast.error(result?.error?.message);
            }
        }
    };


    useEffect(() => {
        dispatch(getPrimaryTimeDimension({ canvasFindData }));
        dispatch(getSchemaData({ timeDimensionData }));
        dispatch(getEnterModelList());
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            dispatch(getMeasureListWithEnterprise({ formValues }));
            dispatch(
                getAttributeOrEnterPrise({
                    enterPriseId: formValues?.enterpriseUID,
                    modalId: formValues?.modelUID,
                })
            );
        }

    }, [canvasFindData, chartData?.value]);

    useEffect(() => {
        if (chartData?.value && schemaData?.length > 0) {
            const findScenario =
                scenarioList?.length > 0 &&
                scenarioList?.filter((item) =>
                    chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.scenario?.map(
                        (row) => row === item?.scenarioName
                    )
                );
            const findType = schemaData?.length > 0 &&
                schemaData?.find(
                    (item) =>
                        item['Time Period Name'] ===
                        chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.values[0]
                );
            const findperiodType =
                schemaData?.length > 0 &&
                schemaData?.filter(
                    (item) =>
                        item['Period Base Level'] ===
                        findType['Period Base Level']
                );
            const validFromDate =
                findperiodType &&
                findperiodType?.find(
                    (option) =>
                        moment(option['Period Start']).format('YYYY-MM-DD') ===
                        moment(
                            chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.from
                        ).format('YYYY-MM-DD')
                );

            const validToDate =
                findperiodType &&
                findperiodType?.find(
                    (option) =>
                        moment(option['Period End']).format('YYYY-MM-DD') ===
                        moment(
                            chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.to
                        )?.format('YYYY-MM-DD')
                );
            const findTime = timeDimensionData?.timeSchemaFields?.find(
                (data) =>
                    data['Period Type'] === chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.timePeriodType);

            const findLagId =
                chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.lagIds?.length > 0 &&
                dimensionLagIDdata?.filter((item) =>
                    chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.lagIds?.some((row) => row == item['Lag ID'])
                );
            const findCycleId =
                chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.cycleIds?.length > 0 &&
                cycleIDList?.filter((item) =>
                    chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.cycleIds?.some((row) => row === item['Cycle ID'])
                );
            const findColumnScenario =
                scenarioList?.length > 0 &&
                scenarioList?.filter((item) =>
                    chartData?.value?.widgetUID?.pivotTable?.layoutSetting?.column?.some(
                        (row) => row?.fieldName === item?.scenarioName
                    ));
            const findRowScenario =
                scenarioList?.length > 0 &&
                scenarioList?.filter((item) =>
                    chartData?.value?.widgetUID?.pivotTable?.layoutSetting?.row?.some(
                        (row) => row?.fieldName === item?.scenarioName
                    ));
            const mergeScenario = [...findColumnScenario, ...findRowScenario]
            setFormValues({
                widgetName: chartData?.value?.widgetUID?.widgetName,
                widgetType: chartData?.value?.widgetUID?.widgetType,
                widgetSubType: chartData?.value?.widgetUID?.widgetSubType,
                widgetGroup: chartData?.value?.widgetUID?.widgetName,
                filter: chartData?.value?.widgetUID?.filterInfo?.filterRefID,
                timePeriodRows: findTime?._id,
                pageTimePeriod: timeDimensionData?.timeSchemaFields?.find((data) => data['Period Type'] === chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.timeAttribute)?._id,
                timePeriodNameRows: findTime["Period Type"],
                pageTimePeriodName: chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.timeAttribute,
                pageLogicalOperator: chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.operator,
                enterpriseUID: chartData?.value?.widgetUID?.enterpriseUID,
                modelUID: chartData?.value?.widgetUID?.modelUID,
                canvasUID: chartData?.value?.widgetUID?.canvasUID,
                pageUID: chartData?.value?.widgetUID?.pageUID,
                cyclePeriodicity: findCycleId?.[0]?.["Cycle Periodicity"],
            });
            setSelectedMeasures({
                scenario:
                    findScenario?.length > 0 &&
                    findScenario?.map((item) => ({
                        _id: item?._id,
                        scenario: item?.scenarioName,
                    })),
                rowScenario:
                    mergeScenario?.length > 0 &&
                    mergeScenario?.map((item) => ({
                        _id: item?._id,
                        scenario: item?.scenarioName,
                    })),
                measure: chartData?.value?.widgetUID?.pivotTable?.measures,
                attribute: chartData?.value?.widgetUID?.pivotTable?.attributes,
                pageValidFrom: validFromDate?.["Time Period Name"],
                pageStartDate: moment(chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.from).format('YYYY-MM-DD'),
                pageEndDate: moment(chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.to).format('YYYY-MM-DD'),
            });
            setSelectedOptions({
                cycleId:
                    findCycleId?.length > 0 &&
                    findCycleId?.map((item) => ({
                        _id: item?._id,
                        cyclePeriodicity: item['Cycle ID'],
                    })),
                lagID:
                    findLagId?.length > 0 &&
                    findLagId?.map((item) => ({
                        _id: item?._id,
                        lagID: item['Lag ID'],
                    }))
            })
            if (chartData?.value?.widgetUID?.filterInfo?.attributes?.length > 0) {
                setSelectedAttributes(
                    chartData?.value?.widgetUID?.filterInfo?.attributes?.map((item) => ({
                        attributes: item?.attributeUID,
                        operator: item?.operator,
                        attValues: item?.values?.reduce((acc, value) => {
                            acc[value] = true;
                            return acc;
                        }, {}),
                    }))
                );
            }
            const pivotColumnData =
                chartData?.value?.widgetUID?.pivotTable?.layoutSetting?.column?.map((item) => {
                    const data = measureList?.find(
                        (row) => row?.measureName === item?.fieldName
                    );
                    const attributeData = attribute?.find(
                        (attr) => attr?.name === item?.fieldName
                    );

                    // Return the correct object based on condition
                    if (data?.measureName === item?.fieldName) {
                        return {
                            measureName: item?.fieldName,
                            measureUID: data?._id,
                            _id: data?._id,
                        };
                    } else if (attributeData?.name === item?.fieldName) {
                        return {
                            attributeName: item?.fieldName,
                            attributeUID: attributeData?._id,
                            _id: attributeData?._id,
                        };
                    } else if (findTime?.["Period Type"] === item?.fieldName) {
                        return {
                            timePeriodNameRows: findTime["Period Type"],
                            timePeriodRows: findTime?._id,
                            _id: findTime?._id,
                        };
                    }

                    return null;
                })?.filter(Boolean)

            const pivotRowData =
                chartData?.value?.widgetUID?.pivotTable?.layoutSetting?.row?.map((item) => {
                    const data = measureList?.find(
                        (row) => row?.measureName === item?.fieldName
                    );
                    const attributeData = attribute?.find(
                        (attr) => attr?.name === item?.fieldName
                    );

                    // Return the correct object based on condition
                    if (data?.measureName === item?.fieldName) {
                        return {
                            measureName: item?.fieldName,
                            measureUID: data?._id,
                            _id: data?._id,
                        };
                    } else if (attributeData?.name === item?.fieldName) {
                        return {
                            attributeName: item?.fieldName,
                            attributeUID: attributeData?._id,
                            _id: attributeData?._id,
                        };
                    } else if (findTime?.["Period Type"] === item?.fieldName) {
                        return {
                            timePeriodNameRows: findTime["Period Type"],
                            timePeriodRows: findTime?._id,
                            _id: findTime?._id,
                        };
                    }

                    return null;
                })?.filter(Boolean)
            setRows(pivotRowData);
            setColumns(pivotColumnData);
        } else {
            setFormValues({
                ...formValues,
                enterpriseUID: canvasFindData?.enterpriseUID?._id,
                modelUID: canvasFindData?.modelUID?.modalUID,
                canvasUID: id,
                pageUID: value ? value : newPageID,
            });
        }
    }, [chartData?.value, schemaData?.length > 0]);

    useEffect(() => {
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            const data = {
                enterpriseUID: {
                    _id: canvasFindData?.enterpriseUID?._id,
                },
                modelUID: canvasFindData?.modelUID?.modalUID,
            };
            dispatch(getPageList({ canvasFindData }));
            dispatch(getMeasureListWithEnterprise({ formValues }));
            dispatch(
                getAttributeOrEnterPrise({
                    enterPriseId: formValues?.enterpriseUID,
                    modalId: formValues?.modelUID,
                })
            );
        }
    }, [formValues?.enterpriseUID, formValues?.modelUID]);

    const AttributeEnterpriseData = attribute
        ?.filter((item) => item?.enterpriseUID?._id === canvasFindData?.enterpriseUID?._id)
        ?.filter((item) => item?.modelUID?.modalUID === canvasFindData?.modelUID?.modalUID);

    const filteredAttributes = AttributeEnterpriseData?.filter((attr) =>
        attr.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const attributesToUse =
        Array.isArray(findDiemnsionAttribute) && findDiemnsionAttribute.length > 0
            ? findDiemnsionAttribute
            : filteredAttributes;


    const handleChangePage = (e) => {
        const { name, value } = e.target;
        if (name === 'timePeriod') {
            const selectedData = timeDimensionData?.timeSchemaFields?.find(
                (data) => data._id === value
            );
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
                [`timePeriodName`]: selectedData['Period Type'],
            }));
        } else if (name === 'widgetSubType') {
            setFormValues({ ...formValues, [name]: value });
            setSelectedOptions({
                attributes: [],
                validFrom: '',
            });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    useEffect(()=>{
        if (formValues.widgetSubType === "Dimension Data" && isColumnModalOpen){
            setActiveTab('attributes');
        }
    }, [formValues, isColumnModalOpen]);

    // Toggle the selected state for a dimension
    const toggleDimension = (e, dimension) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add the dimension to the selected list if checked
            // setSelectedDimensions((prevSelected) => [...prevSelected, dimension]);
            setSelectedMeasures((prev) => ({
                ...prev,
                dimension: [
                    ...prev.dimension,
                    {
                        _id: dimension?._id,
                    },
                ],
            }));
        } else {
            // Remove the dimension from the selected list if unchecked
            // setSelectedDimensions((prevSelected) =>
            //     prevSelected.filter((dim) => dim.name !== dimension.name)
            // );
            setSelectedMeasures((prev) => ({
                ...prev,
                dimension: prev.dimension.filter((dim) => dim._id !== dimension._id),
            }));
        }
    };

    useEffect(()=>{
        if (formValues.widgetSubType === "Dimension Data"){
            const filterData = rows.filter((item) => !Object.keys(item).includes("measureUID")); 
            setRows(filterData)
            setColumns([]);
        }
    }, [formValues, isColumnModalOpen]);


    //Function used to selected values 
    const handleRemoveValue = (key, selectedIndex) => {
        
    const attributeValueData = selectedAttributes[selectedIndex].attValues
        const filteredAttValues = Object.entries(attributeValueData)
            .filter(([k, v]) => k !== key)
            .reduce((acc, [k, v]) => {
                acc[k] = v;
                return acc;
            }, {});
        const updatedData = selectedAttributes.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    attValues: filteredAttValues
                }
            }
            return item
        })
        setSelectedAttributes(updatedData);
    }


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        }
    }));
    
    return (
        <>
            <div
                className={`absolute right-0 ${isSidebarOpen ? 'top-[136px]' : 'top-[195px]'
                    } h-full w-[607px] bg-white shadow-lg z-auto p-6 overflow-y-auto custom-scrollbar`}
                style={{
                    height: isSidebarOpen ? 'calc(100% - 136px)' : 'calc(100% - 195px)',
                }}
            >
                <div>
                    <div className="flex justify-end space-x-4">
                        <button
                            className="px-4 py-1 text-base text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50 transition duration-300"
                            onClick={() => {
                                setIsOpenWidgetModel(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-1 text-base bg-[#335CFF] text-white rounded-lg transition duration-300"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
                <div className='flex justify-between items-center gap-2 mt-3'>
                    <div className="mb-5 flex items-center gap-2">
                        <label
                            htmlFor="modelName"
                            className="block text-[14px] font-semibold text-gray-700"
                        >
                            Model
                        </label>
                        <select
                            id="modalName"
                            name="modalName"
                            value={formValues?.modelUID}
                            fullWidth
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    modelUID: e.target.value,
                                });
                            }}
                            variant="outlined"
                            placeholder="Select Time Period"
                            className={`w-[110px]
                            px-3 
                            py-2 
                            border 
                            border-[#EAEAEA] 
                            rounded-lg 
                            text-sm 
                            shadow-sm
                            bg-white
                            appearance-none
                            focus:outline-none 
                            focus:shadow-custom-focus
                            disabled:bg-gray-50 
                            disabled:text-gray-500 
                            disabled:cursor-not-allowed
                        `}
                            disabled
                        >
                            <option selected>Select Model</option>
                            {modalData
                                ?.filter(
                                    (item) =>
                                        item?.enterpriseUID?._id ===
                                        canvasFindData?.enterpriseUID?._id
                                )
                                ?.map((option) => (
                                    <option key={option.modelUID} value={option.modelUID}>
                                        {option.modelName}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="mb-5  flex items-center gap-2">
                        <label
                            htmlFor="canvasUID"
                            className="block text-[14px] font-semibold text-gray-700"
                        >
                            Canvas
                        </label>
                        <select
                            id="canvasUID"
                            name="canvasUID"
                            value={formValues?.canvasUID}
                            fullWidth
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    canvasUID: e.target.value,
                                });
                            }}
                            variant="outlined"
                            placeholder="Select Time Period"
                            className={`
                            w-[150px]
                            px-3 
                            py-2 
                            border 
                            border-[#EAEAEA] 
                            rounded-lg 
                            text-sm 
                            shadow-sm
                            bg-white
                            appearance-none
                            focus:outline-none 
                            focus:shadow-custom-focus
                            disabled:bg-gray-50 
                            disabled:text-gray-500 
                            disabled:cursor-not-allowed
                        `}
                            disabled
                        >
                            <option selected>Select Canvas</option>
                            {canvasData
                                ?.filter(
                                    (item) => item?.enterpriseUID?._id === formValues?.enterpriseUID
                                )
                                ?.filter(
                                    (item) => item?.modelUID?.modelUID === formValues?.modelUID
                                )
                                .map((option) => (
                                    <option key={option._id} value={option._id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="mb-5 flex items-center gap-2">
                        <label
                            htmlFor="pageUID"
                            className="block text-[14px] font-semibold text-gray-700"
                        >
                            Page
                        </label>
                        <select
                            id="pageUID"
                            name="pageUID"
                            value={formValues?.pageUID}
                            fullWidth
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    pageUID: e.target.value,
                                });
                            }}
                            variant="outlined"
                            placeholder="Select Time Period"
                            className={`
                            w-[150px]
                            px-3 
                            py-2 
                            border 
                            border-[#EAEAEA] 
                            rounded-lg 
                            text-sm 
                            shadow-sm
                            bg-white
                            appearance-none
                            focus:outline-none 
                            focus:shadow-custom-focus
                            disabled:bg-gray-50 
                            disabled:text-gray-500 
                            disabled:cursor-not-allowed
                        `}
                            disabled
                        >
                            <option selected>Select Page</option>
                            {pageData
                                ?.filter(
                                    (item) => item?.enterpriseUID?._id === formValues?.enterpriseUID
                                )
                                ?.filter(
                                    (item) => item?.modelUID?.modelUID === formValues?.modelUID
                                )
                                .map((option) => (
                                    <option key={option._id} value={option._id}>
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className='my-2'>
                    <div className="mb-5 w-full flex items-center gap-2">
                        <label

                            className="block text-[14px] font-semibold text-gray-700 text-nowrap"
                        >
                            Widget Name
                        </label>
                        <input
                            type="text"
                            id="widgetName"
                            name="widgetName"
                            value={formValues?.widgetName}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    widgetName: e.target.value,
                                });
                            }}
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            placeholder="Widget Name"
                        />
                    </div>
                </div>

                <div className='flex items-center gap-3'>
                    <div className="mb-5 flex items-center gap-2">
                        <label className="block text-[14px] font-semibold text-gray-700 text-nowrap">
                            Widget Type
                        </label>
                        <select
                            className="w-[120px] px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            id="widgetType"
                            name="widgetType"
                            value={formValues?.widgetType}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    widgetType: e.target.value,
                                });
                            }}
                        >
                            <option value="" selected>
                                Widget Type
                            </option>
                            {WidgetType.map((item, index) => <option value={item.value} key={index + 1}>{item.label}</option>)}
                        </select>
                    </div>

                    <div className="mb-5 flex items-center gap-2">
                        <label className="block  text-[14px] font-semibold w-max text-gray-700">
                            Widget Sub-type
                        </label>
                        <select
                            className="w-[120px] px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            id="widgetSubType"
                            name="widgetSubType"
                            value={formValues?.widgetSubType}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    widgetSubType: e.target.value,
                                });
                            }}
                        >
                            <option value="" selected>
                                Select Widget Sub Type
                            </option>
                            <option value="Measure Data">Measure Data</option>
                            <option value="Dimension Data">Dimension Data</option>
                        </select>
                    </div>

                    {formValues.widgetType === "Pivot Table" && <>
                        <div className='flex gap-6 items-center mb-5'>
                            <label
                                htmlFor=" Widget System Attributes"
                                className="block text-sm font-medium w-max text-gray-700"
                            >
                                Classic Layout
                            </label>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        checked={toggleClassicLayout}
                                        onChange={(e) => setToggleClassicLayout(e.target.checked)}
                                        name="showClassicLayout"
                                    />
                                }
                                label=""
                            />
                        </div></>}

                </div>
                {formValues.widgetSubType !== "Dimension Data" && 
                <div className='flex items-center gap-3'>
                    <div className="mb-5 flex items-center gap-2">
                        <label
                            htmlFor="chartType"
                            className="block text-[14px] font-semibold text-gray-700 mb-2 text-nowrap"
                        >
                            Chart Type
                        </label>
                        <select
                            id="chartType"
                            name="chartType"
                            value={formValues?.chartType}
                            fullWidth
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    chartType: e.target.value,
                                });
                            }}
                            variant="outlined"
                            placeholder="Select Chart Type"
                            className={`
                            w-full 
                            px-3 
                            py-2 
                            border 
                            border-[#EAEAEA] 
                            rounded-lg 
                            text-sm 
                            shadow-sm
                            bg-white
                            appearance-none
                            focus:outline-none 
                            focus:shadow-custom-focus
                            disabled:bg-gray-50 
                            disabled:text-gray-500 
                            disabled:cursor-not-allowed
                        `}
                        >
                            <option selected>Select Chart Type</option>
                            <option value="bar">Bar Chart</option>
                            <option value="tornado">Tornado Chart</option>
                            <option value="spider">Spider Chart</option>
                            <option value="spline">Line Chart</option>
                            <option value="area">Area Chart</option>
                            <option value="pie">Pie Chart</option>
                            <option value="scatter">Scatter Plot</option>
                            <option value="bubble">Bubble Chart</option>
                            <option value="combination">Combination Chart</option>
                            <option value="areaspline">Area Spline</option>
                            <option value="heatmap">Heat Map</option>
                            <option value="treemap">Tree Map</option>
                            <option value="arearange">Area Range and Line Chart</option>
                        </select>
                    </div>
                    <div className='flex gap-6 items-center mb-5'>
                        <label
                            htmlFor=" Widget System Attributes"
                            className="block text-[14px] font-semibold text-gray-700"
                        >
                            Chart as Default
                        </label>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    checked={toggleDefaultChart}
                                    onChange={(e) => setDefaultChart(e.target.checked)}
                                    name="showClassicLayout"
                                />
                            }
                            label=""
                        />
                    </div>
                </div>}

                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="mb-5">
                        <Droppable droppableId="rows">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <button
                                        className="px-4 mb-3 py-1 text-base text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50 transition duration-300"
                                        onClick={() => {
                                            addColumn();
                                            setActiveTab("measures")
                                        }}
                                    >
                                        {/* <IoAddCircleOutline size={20} className="text-black" /> */}
                                        Define Pivot Table
                                    </button>
                                    <div className="flex items-start text-gray-700 font-medium gap-x-[35px]">
                                        {/* <span className="mt-3 text-[14px] gray-700 font-semibold">
                                            Rows
                                        </span> */}
                                        <label
                                            className="block text-[14px] font-semibold text-gray-700"
                                        >
                                            Rows
                                        </label>
                                        <div className='flex items-start  gap-3 flex-wrap'>
                                            {rows?.length > 0
                                                ? rows?.map((row, index) => (
                                                    <Draggable
                                                        key={row?._id || index}
                                                        draggableId={row?._id?.toString() || index}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                key={index}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="flex justify-between items-center  relative z-[2] w-[225px] px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                            >
                                                                <div className="flex items-center gap-2">
                                                                    <RiDraggable className="text-[#616060] cursor-move" />
                                                                    <span className="text-[10px]">
                                                                        {row?.measureName ||
                                                                            row?.attributeName ||
                                                                            row?.scenario ||
                                                                            row?.timePeriodNameRows}
                                                                    </span>
                                                                </div>
                                                                <button
                                                                    onClick={() => removeRow(index, row)}
                                                                    className="text-gray-500"
                                                                >
                                                                    <IoMdClose />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                                : []}
                                        </div>
                                    </div>

                                </div>
                            )}
                        </Droppable>
                    </div>

                    <Droppable droppableId="columns">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <div className="flex items-center text-gray-700 font-medium gap-2">
                                    {/* <span className="mt-3 text-[14px] gray-700 font-semibold">
                                        Columns
                                    </span> */}
                                    <label
                                        className="block text-[14px] font-semibold text-gray-700"
                                    >
                                        Columns
                                    </label>
                                    {columns?.length > 0
                                        ? columns?.map((column, index) => (
                                            <Draggable
                                                key={column?._id || index}
                                                draggableId={column?._id?.toString() || index}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        key={index}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="flex justify-between items-center relative z-[2] w-[225px] px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            <RiDraggable className="text-[#616060] cursor-move" />
                                                            <span className="text-[10px]">
                                                                {column?.attributeName ||
                                                                    column?.measureName ||
                                                                    column?.scenario ||
                                                                    column?.timePeriodNameRows}
                                                            </span>
                                                        </div>
                                                        <button
                                                            onClick={() => removeColumn(index, column)}
                                                            className="text-gray-500"
                                                        >
                                                            <IoMdClose />
                                                        </button>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                        : []}
                                </div>

                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="border-b border-[#E9EAEB] my-4"></div>
                <div className="">
                    <div className='flex gap-6 items-center mb-2'>
                        <label
                            htmlFor="filter"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Widget Filter
                        </label>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    checked={isToggled}
                                    onChange={handleToggle}
                                    name="widgetFilter"
                                />
                            }
                            label=""
                        />
                    </div>
                    {isToggled && (
                        <div className="relative">
                            <div className="flex items-center gap-3">
                                <select
                                    id="filter"
                                    name="filter"
                                    value={formValues?.filter}
                                    onChange={(selectedOption) => {
                                        setFormValues((prev) => ({
                                            ...prev,
                                            filter: selectedOption.target.value,
                                        }));
                                        const findFilter = filterEnterpriseDetail?.find(
                                            (item) => item?._id === selectedOption.target.value
                                        );
                                        setSelectedAttributes(
                                            findFilter?.attributes?.map((item) => ({
                                                attributes: item?.attributeUID?._id,
                                                operator: item?.operator,
                                                attValues: item?.values?.reduce((acc, value) => {
                                                    acc[value] = true;
                                                    return acc;
                                                }, {}),
                                            }))
                                        );
                                    }}
                                    className={`
                                    w-full 
                                    px-3 
                                    py-2 
                                    border 
                                    border-[#EAEAEA] 
                                    rounded-lg 
                                    text-sm 
                                    shadow-sm
                                    bg-white
                                    appearance-none
                                    focus:outline-none 
                                    focus:shadow-custom-focus
                                    disabled:bg-gray-50 
                                    disabled:text-gray-500 
                                    disabled:cursor-not-allowed
                                `}
                                >
                                    <option selected>Select Filter</option>
                                    {filterEnterpriseDetail?.map((option) => (
                                        <option key={option._id} value={option._id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    variant="default"
                                    className="px-4 py-2 bg-gray-300 rounded-lg"
                                    onClick={() => {
                                        setNewFilter(true);
                                    }}
                                >
                                    +
                                </button>
                                <button
                                    variant="default"
                                    className="px-4 py-3 bg-gray-300 rounded-lg"
                                    onClick={() => {
                                        setViewWidgetFilter(true);
                                    }}
                                    disabled={!formValues?.pageTimePeriodName && !selectedMeasures?.pageStartDate && !selectedMeasures?.pageEndDate || selectedAttributes?.length === 0}
                                >
                                    <FaEye />
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {/* <div className="border-b border-[#E9EAEB] mb-4"></div>

                <div className='flex gap-6 items-center mb-2'>
                    <label
                        htmlFor=" Widget System Attributes"
                        className="block text-sm font-medium text-gray-700 mb-2"
                    >
                        Widget System Attributes
                    </label>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={isToggledWidgetSystemAttribute}
                                onChange={handleToggleSystemAttribute}
                                name="widgetSystemAttribute"
                            />
                        }
                        label=""
                    />
                </div> */}
                {/* {formValues.widgetType === "Pivot Table" && <>
                <div className='flex gap-6 items-center mb-2'>
                    <label
                        htmlFor=" Widget System Attributes"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Classic Layout
                    </label>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={toggleClassicLayout}
                                onChange={(e) => setToggleClassicLayout(e.target.checked)}
                                name="showClassicLayout"
                            />
                        }
                        label=""
                    />
                    </div></>} */}

                {/* <div className="border-b border-[#E9EAEB] mb-4"></div> */}
                {/* <div className='flex gap-6 items-center mb-2'>
                    <label
                        htmlFor=" Widget System Attributes"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Chart as Default
                    </label>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={toggleDefaultChart}
                                onChange={(e) => setDefaultChart(e.target.checked)}
                                name="showClassicLayout"
                            />
                        }
                        label=""
                    />
                </div> */}

                {isToggledWidgetSystemAttribute && (
                    <>
                        {/* <div className='flex items-center gap-3'> */}
                        <div className='flex items-center gap-3'>
                            <div className="pt-3 w-full">
                                <label
                                    htmlFor="Scenario ID"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Scenario ID
                                </label>
                                <div className="relative">
                                    <Select
                                        options={
                                            scenarioList?.length > 0
                                                ? scenarioList?.map((item) => ({
                                                    value: item?._id,
                                                    label: item?.scenarioName,
                                                }))
                                                : []
                                        }
                                        value={
                                            selectedMeasures?.scenario?.length > 0 &&
                                            selectedMeasures?.scenario?.map((option) => ({
                                                value: option?._id,
                                                label: option?.scenario,
                                            }))
                                        }
                                        onChange={(data) => {
                                            const selectedScenario = data?.map((item) => ({
                                                _id: item?.value,
                                                scenario: item?.label
                                            }))
                                            setSelectedMeasures({
                                                ...selectedMeasures,
                                                scenario: selectedScenario
                                            })
                                        }}
                                        placeholder="Select Scenario"
                                        isSearchable={true}
                                        isMulti
                                        className="text-black"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            {/* {findLagId?.length > 0 && ( */}
                            <div className="pt-3 w-full">
                                <label
                                    htmlFor="lagID"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Lag ID
                                </label>
                                <MultiSelectDropdown
                                    options={dimensionLagIDdata?.map((item) => ({
                                        value: item?._id,
                                        label: item['Lag ID'],
                                    }))}
                                    value={
                                        selectedOptions?.lagID?.length > 0 &&
                                        selectedOptions?.lagID?.map((option) => ({
                                            value: option?._id,
                                            label: option?.lagID,
                                        }))
                                    }
                                    onChange={(data) => handleChangeAttribute(data, 'lagID')}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                    styles={customStylesCycleId}
                                    cyclePeriodicity="month"
                                />
                            </div>
                            {/* )} */}
                        </div>
                        {/* {findLagId?.length > 0 && ( */}
                        <div className='flex items-center gap-3'>
                            <div className="relative w-full pt-3">
                                <label
                                    htmlFor="cyclePeriodicity"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Cycle Periodicity
                                </label>
                                <select
                                    id="cyclePeriodicity"
                                    name="cyclePeriodicity"
                                    value={formValues?.cyclePeriodicity || ""}
                                    // defaultValue={formValues?.cyclePeriodicity}
                                    fullWidth
                                    onChange={handleChangePage}
                                    variant="outlined"
                                    placeholder="Select Cycle Periodicity"
                                    className={`
                                                w-full 
                                                px-3 
                                                py-2 
                                                border 
                                                border-[#EAEAEA] 
                                                rounded-lg 
                                                text-sm 
                                                shadow-sm
                                                bg-white
                                                appearance-none
                                                focus:outline-none 
                                                focus:shadow-custom-focus
                                                disabled:bg-gray-50 
                                                disabled:text-gray-500 
                                                disabled:cursor-not-allowed
                                    `}
                                >
                                    <option selected>Select Cycle Periodicity</option>
                                    {cyclePeriodicity?.length > 0 &&
                                        cyclePeriodicity?.map(
                                            (item) =>
                                            (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            )
                                        )}
                                </select>
                            </div>

                            {formValues?.widgetSubType === "Measure Data" && (
                                <div className="pt-3 w-full">
                                    <label
                                        htmlFor="cycleID"
                                        className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                        Cycle ID
                                    </label>
                                    <MultiSelectDropdown
                                        components={{ MenuList: CustomMenuList }}
                                        options={memoizedOptions}
                                        value={
                                            selectedOptions?.cycleId?.length > 0 &&
                                            selectedOptions?.cycleId?.map((option) => ({
                                                value: option?._id,
                                                label: option?.cyclePeriodicity,
                                            }))
                                        }
                                        onChange={(data) => handleChangeAttribute(data, 'cycleId')}
                                        isSelectAll={true}
                                        menuPlacement={"bottom"}
                                        styles={customStylesCycleId}
                                        cyclePeriodicity={formValues?.cyclePeriodicity}
                                    />
                                </div>
                            )}
                        </div>
                        {/* )} */}
                    </>
                )}
                {isRowModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold">Add New Row</h2>
                            <input
                                type="text"
                                placeholder="Enter row name"
                                className="border p-2 w-full mt-2 rounded"
                            />
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={() => setRowModalOpen(false)}
                                    className="px-4 py-2 bg-gray-300 rounded-lg mr-2"
                                >
                                    Cancel
                                </button>
                                <button className="px-4 py-2 bg-blue-600 text-white rounded-lg">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isColumnModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-2xl h-[500px] flex flex-col">
                            {/* <h2 className="text-lg font-semibold mb-4">Add New Column</h2> */}

                            <div className="flex border-b mb-4">
                                {formValues.widgetSubType !== "Dimension Data" && 
                                <button
                                    className={`px-4 py-2 ml-4 ${activeTab === 'measures'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => {
                                        setActiveTab('measures')
                                        setSearch('')
                                    }}
                                >
                                    Measures
                                </button>}
                                <button
                                    className={`px-4 py-2 ${activeTab === 'attributes'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => {
                                        setActiveTab('attributes')
                                        setSearch('')
                                    }}
                                >
                                    Attributes
                                </button>
                                {formValues.widgetSubType !== "Dimension Data" && 
                                <button
                                    className={`px-4 py-2 ml-4 ${activeTab === 'timeAttributes'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => {
                                        setActiveTab('timeAttributes')
                                        setSearch('')
                                        setSearchQuery('')
                                    }}
                                >
                                    Time Attributes
                                </button>}
                                {formValues.widgetSubType === "Dimension Data" && 
                                    <button
                                        className={`px-4 py-2 ml-4 ${activeTab === 'dimensionAttributes'
                                            ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                            : 'text-gray-500'
                                            }`}
                                        onClick={() => {
                                            setActiveTab('dimensionAttributes')
                                            setSearch('')
                                            setSearchQuery('')
                                        }}
                                    >
                                        Dimension Attributes
                                    </button>
                                }
                            </div>

                            <div
                                onScroll={onScroll}
                                ref={listInnerRef}
                                className="flex-grow overflow-y-auto custom-scrollbar mb-5"
                            >
                                {activeTab === 'attributes' && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="border p-2 w-full rounded mb-4"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <div className="border rounded-lg overflow-hidden">
                                            <table className="w-full border-collapse">
                                                <thead className="bg-gray-100">
                                                    <tr>
                                                        <th className="p-2 text-left">
                                                            <input
                                                                type="checkbox"
                                                            />
                                                        </th>
                                                        <th className="p-2 text-left text-xs font-semibold">
                                                            Attributes
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(attributesToUse) &&
                                                        attributesToUse
                                                            ?.filter((attr) =>
                                                                attr?.name?.toLowerCase().includes(searchQuery.toLowerCase()) &&
                                                                !systemDefaultAttributes.includes(attr?.name))
                                                            .map((attr, index) => (
                                                                <tr key={index} className="border-t">
                                                                    <td className="p-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedMeasures?.attribute?.some(
                                                                                (m) => m.attributeUID === attr._id
                                                                            )}
                                                                            onChange={(e) => toggleMeasure(e, attr, 'attribute')}
                                                                        />
                                                                    </td>
                                                                    <td className="p-2 text-[10px] font-medium text-gray-700">
                                                                        {attr?.name}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'measures' && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => handleSearchChange(e)}
                                            className="border p-2 w-full rounded mb-4"
                                        />
                                        <div className="border rounded-lg overflow-hidden">
                                            <table className="w-full border-collapse">
                                                <thead className="bg-gray-100">
                                                    <tr>
                                                        <th className="p-2 text-left">
                                                            <input type="checkbox" />
                                                        </th>
                                                        <th className="p-2 text-left text-xs font-semibold">
                                                            Measures
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`${loading ? 'bg-gray-100' : ''}`}>
                                                    {loading && <div className='absolute top-[50%] left-[50%]'>
                                                        <Loader />
                                                    </div>}
                                                    {userList?.map((measure, index) => (<>
                                                        <tr key={index} className="border-t">
                                                            <td className="p-2">
                                                                <input
                                                                    id={`checkbox-${index}`} // Give a unique id to each checkbox
                                                                    type="checkbox"
                                                                    checked={selectedMeasures?.measure?.some(
                                                                        (m) => m.measureName === measure.measureName
                                                                    )}
                                                                    onChange={(e) => toggleMeasure(e, measure, 'measure')}
                                                                />
                                                            </td>
                                                            <td className="p-2 text-[10px] font-medium text-gray-700">
                                                                <label htmlFor={`checkbox-${index}`} className="cursor-pointer">
                                                                    {measure?.measureName}
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    </>))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'timeAttributes' && (
                                    <>
                                        {/* <TimePeriodFilter
                                            formValues={formValues}
                                            selectedMeasures={selectedMeasures}
                                            handleTimePeriod={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    timePeriodRows: e.target.value,
                                                });
                                                const selectedData =
                                                    timeDimensionData?.timeSchemaFields?.find(
                                                        (data) => data._id === e.target.value
                                                    );
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    timePeriodRows: e.target.value,
                                                    [`timePeriodNameRows`]: selectedData['Period Type'],
                                                }));
                                            }}
                                            timePeriod={formValues?.timePeriodRows}
                                            handleLogicalOperator={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    logicalOperatorRows: e.target.value,
                                                });
                                            }}
                                            logicalOperator={formValues?.logicalOperatorRows}
                                            handleValidFrom={(newValue) => {
                                                setSelectedMeasures((prevState) => ({
                                                    ...prevState,
                                                    validFromRows: newValue
                                                        ? newValue['Time Period Name']
                                                        : '',
                                                    startDateRows: newValue ? newValue['Period Start'] : '',
                                                }));
                                            }}
                                            validFrom={selectedMeasures?.validFromRows}
                                            handleValidTo={(newValue) => {
                                                setSelectedMeasures((prevState) => ({
                                                    ...prevState,
                                                    validToRows: newValue
                                                        ? newValue['Time Period Name']
                                                        : '',
                                                    endDateRows: newValue ? newValue['Period End'] : '',
                                                }));
                                            }}
                                            validTo={selectedMeasures?.validToRows}
                                            timeDimensionData={timeDimensionData}
                                            query={query}
                                            setQuery={setQuery}
                                            filteredOptions={filteredOptions}
                                            filteredValidToOptions={filteredValidToOptions}
                                        /> */}
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                className="border p-2 w-full rounded mb-4"
                                            // value={searchQuery}
                                            // onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <div className="border rounded-lg overflow-hidden">
                                                <table className="w-full border-collapse">
                                                    <thead className="bg-gray-100">
                                                        <tr>
                                                            <th className="p-2 text-left">
                                                                <input
                                                                    type="checkbox"
                                                                />
                                                            </th>
                                                            <th className="p-2 text-left text-xs font-semibold">
                                                                Time Attribute
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {timeDimensionData?.timeSchemaFields?.length > 0
                                                            ? timeDimensionData?.timeSchemaFields?.map((attr, index) =>
                                                                attr?.enabled === true && (
                                                                    <tr key={index} className="border-t">
                                                                        <td className="p-2">
                                                                            <input
                                                                                type="radio"
                                                                                value={attr._id} // Ensure the value is set
                                                                                checked={formValues?.timePeriodRows === attr._id}
                                                                                onChange={(e) => {
                                                                                    setFormValues((prevValues) => ({
                                                                                        ...prevValues,
                                                                                        timePeriodRows: attr._id, // Store single value
                                                                                        timePeriodNameRows: attr['Period Type'], // Store name directly
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="p-2 text-[10px] font-medium text-gray-700"> {attr['Period Type']}</td>
                                                                    </tr>
                                                                )
                                                            )
                                                            : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {(activeTab === 'dimensionAttributes' && 
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => handleSearchChange(e)}
                                            className="border p-2 w-full rounded mb-4"
                                        />
                                        <div className="border rounded-lg overflow-hidden">
                                            <table className="w-full border-collapse">
                                                <thead className="bg-gray-100">
                                                    <tr>
                                                        <th className="p-2 text-left">
                                                            <input type="checkbox" />
                                                        </th>
                                                        <th className="p-2 text-left text-xs font-semibold">
                                                            Dimensions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`${loading ? 'bg-gray-100' : ''}`}>
                                                    {loading && <div className='absolute top-[50%] left-[50%]'>
                                                        <Loader />
                                                    </div>}
                                                    {allDimensions?.map((dimensions, index) => (<>
                                                        <tr key={index} className="border-t">
                                                            <td className="p-2">
                                                                <input
                                                                    id={`checkbox-${index}`} // Give a unique id to each checkbox
                                                                    type="checkbox"
                                                                    checked={(selectedMeasures?.dimension ?? []).some(
                                                                        (dim) => dim._id === dimensions._id
                                                                    )}
                                                                    onChange={(e) => toggleDimension(e, dimensions)}
                                                                />
                                                            </td>
                                                            <td className="p-2 text-[10px] font-medium text-gray-700">
                                                                <label htmlFor={`checkbox-${index}`} className="cursor-pointer">
                                                                    {dimensions?.name}
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    </>))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={() => setColumnModalOpen(false)}
                                    className="px-4 py-2 bg-gray-300 rounded-lg mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                                    onClick={() => {
                                        setRows((prevRows) => {
                                            let newData = [];

                                            // Keep only selected measures, scenarios, attributes, and time periods
                                            const updatedRows = prevRows?.filter(
                                                (row) =>
                                                    selectedMeasures?.measure?.some((item) => item.measureUID === row.measureUID) ||
                                                    selectedMeasures?.attribute?.some((item) => item.attributeUID === row.attributeUID) ||
                                                    formValues?.timePeriodRows === row?.timePeriodRows
                                            );

                                            if (selectedMeasures?.attribute) {
                                                const newAttributes = selectedMeasures.attribute.filter(
                                                    (item) =>
                                                        !updatedRows.some((row) => row.attributeUID === item.attributeUID) &&
                                                        !columns.some((col) => col.attributeUID === item.attributeUID) // Ensure it's not in columns
                                                );
                                                newData.push(...newAttributes);
                                            }

                                            if (selectedMeasures?.measure) {
                                                const measureData = selectedMeasures.measure.filter(
                                                    (item) =>
                                                        !updatedRows.some((row) => row.measureUID === item.measureUID) &&
                                                        !columns.some((col) => col.measureUID === item.measureUID) // Ensure it's not in columns
                                                );
                                                newData.push(...measureData);
                                            }



                                            return [...updatedRows, ...newData];
                                        });

                                        setColumns((prevColumns) => {
                                            let newColumnData = [];

                                            // Keep only selected measures, scenarios, attributes, and time periods
                                            const updatedColumns = prevColumns.filter(
                                                (col) =>
                                                    selectedMeasures?.attribute?.some((item) => item.attributeUID === col.attributeUID) ||
                                                    selectedMeasures?.measure?.some((item) => item.measureUID === col.measureUID) ||
                                                    formValues?.timePeriodRows === col?.timePeriodRows
                                            );

                                            if (formValues?.timePeriodRows) {
                                                if (
                                                    !updatedColumns.some((col) => col.timePeriodRows === formValues.timePeriodRows) &&
                                                    !rows.some((row) => row.timePeriodRows === formValues.timePeriodRows) // Ensure it's not in rows
                                                ) {
                                                    newColumnData.push({
                                                        _id: formValues.timePeriodRows,
                                                        timePeriodRows: formValues.timePeriodRows,
                                                        timePeriodNameRows: formValues?.timePeriodNameRows,
                                                    });
                                                }
                                            }

                                            return [...updatedColumns, ...newColumnData];
                                        });

                                        setColumnModalOpen(false);
                                    }}

                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {newPageFilter && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-2xl h-[500px] flex flex-col relative">
                            <div className="flex border-b mb-4">
                                <button
                                    className={`px-4 py-2 ${activeTab === 'attributes'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => setActiveTab('attributes')}
                                >
                                    Attributes
                                </button>
                                <button
                                    className={`px-4 py-2 ${activeTab === 'timeAttributes'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => setActiveTab('timeAttributes')}
                                >
                                    Time Attributes
                                </button>
                                {/* <button
                                    className={`px-4 py-2 ml-4 ${activeTab === 'measures'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => setActiveTab('measures')}
                                >
                                    Measures
                                </button> */}
                                <button
                                    className={`px-4 py-2 ${activeTab === 'scenario'
                                        ? 'border-b-2 text-xs border-blue-600 font-semibold'
                                        : 'text-gray-500'
                                        }`}
                                    onClick={() => setActiveTab('scenario')}
                                >
                                    Scenario
                                </button>
                            </div>
                            {activeTab === 'attributes' && (
                                <div className="flex-grow overflow-y-auto custom-scrollbar mb-5">
                                    {newPageFilter ? (
                                        <WidgetFilter
                                            selectedAttributes={selectedPageAttributes}
                                            setSelectedAttributes={setSelectedPageAttributes}
                                            selectedValue={selectedValue}
                                            setSelectedValue={setSelectedValue}
                                            canvasFindData={canvasFindData}
                                            openModalRow={openModalRow}
                                            setOpenModalRow={setOpenModalRow}
                                            attributeValueData={attributeValueData}
                                            setAttributeValueData={setAttributeValueData}
                                        />
                                    ) : (
                                        <WidgetFilter
                                            selectedAttributes={selectedAttributes}
                                            setSelectedAttributes={setSelectedAttributes}
                                            selectedValue={selectedValue}
                                            setSelectedValue={setSelectedValue}
                                            canvasFindData={canvasFindData}
                                            openModalRow={openModalRow}
                                            setOpenModalRow={setOpenModalRow}
                                            attributeValueData={attributeValueData}
                                            setAttributeValueData={setAttributeValueData}
                                        />
                                    )}
                                </div>
                            )}
                            {activeTab === 'timeAttributes' && (
                                <>
                                    <TimePeriodFilter
                                        formValues={formValues}
                                        selectedMeasures={selectedMeasures}
                                        handleTimePeriod={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                timePeriod: e.target.value,
                                            });
                                            const selectedData =
                                                timeDimensionData?.timeSchemaFields?.find(
                                                    (data) => data._id === e.target.value
                                                );
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                timePeriod: e.target.value,
                                                [`timePeriodName`]: selectedData['Period Type'],
                                            }));
                                        }}
                                        timePeriod={formValues?.timePeriod}
                                        handleLogicalOperator={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                logicalOperator: e.target.value,
                                            });
                                        }}
                                        logicalOperator={formValues?.logicalOperator}
                                        handleValidFrom={(newValue) => {
                                            setSelectedMeasures((prevState) => ({
                                                ...prevState,
                                                validFrom: newValue ? newValue['Time Period Name'] : '',
                                                startDate: newValue ? newValue['Period Start'] : '',
                                            }));
                                        }}
                                        validFrom={selectedMeasures?.validFrom}
                                        handleValidTo={(newValue) => {
                                            setSelectedMeasures((prevState) => ({
                                                ...prevState,
                                                validTo: newValue ? newValue['Time Period Name'] : '',
                                                endDate: newValue ? newValue['Period End'] : '',
                                            }));
                                        }}
                                        validTo={selectedMeasures?.validTo}
                                        timeDimensionData={timeDimensionData}
                                        query={query}
                                        setQuery={setQuery}
                                        filteredOptions={filteredOptions}
                                        filteredValidToOptions={filteredValidToOptions}
                                    />
                                </>
                            )}

                            {activeTab === 'measures' && (
                                <div
                                    onScroll={onScroll}
                                    ref={listInnerRef}
                                    className="flex-grow overflow-y-auto custom-scrollbar mb-5"
                                >
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        className="border p-2 w-full rounded mb-4"
                                    />
                                    <div className="border rounded-lg overflow-hidden">
                                        <table className="w-full border-collapse">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th className="p-2 text-left">
                                                        <input type="checkbox" />
                                                    </th>
                                                    <th className="p-2 text-left text-xs font-semibold">
                                                        Measures
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userList?.map((measure, index) => (
                                                    <tr key={index} className="border-t">
                                                        <td className="p-2">
                                                            <input
                                                                type="checkbox"
                                                                checked={formValues?.measure?.some(
                                                                    (m) => m.measureName === measure.measureName
                                                                )}
                                                                onChange={(e) => {
                                                                    setFormValues((prev) => {
                                                                        if (e.target.checked) {
                                                                            return {
                                                                                ...prev,
                                                                                measure: [
                                                                                    ...prev.measure,
                                                                                    {
                                                                                        measureUID: measure?._id,
                                                                                        _id: measure?._id,
                                                                                        measureName: measure?.measureName,
                                                                                        enterpriseUID:
                                                                                            measure?.enterpriseUID,
                                                                                        modelUID: measure?.modelUID,
                                                                                        baselevelUID:
                                                                                            measure?.baselevelUID?._id,
                                                                                    },
                                                                                ], // Add measure to array
                                                                            };
                                                                        } else {
                                                                            return {
                                                                                ...prev,
                                                                                measure: prev.measure.filter(
                                                                                    (m) =>
                                                                                        m.measureName !==
                                                                                        measure.measureName
                                                                                ), // Remove measure
                                                                            };
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="p-2 text-xs">
                                                            {measure?.measureName}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'scenario' && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        className="border p-2 w-full rounded mb-4"
                                    />
                                    <div className="border rounded-lg overflow-hidden">
                                        <table className="w-full border-collapse">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th className="p-2 text-left">
                                                        <input type="checkbox" />
                                                    </th>
                                                    <th className="p-2 text-left text-xs font-semibold">
                                                        Scenario
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scenarioList?.map((item, index) => (
                                                    <tr key={index} className="border-t">
                                                        <td className="p-2">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedMeasures?.scenario?.some(
                                                                    (m) => m.scenario === item.scenarioName
                                                                )}
                                                                onChange={(e) =>
                                                                    toggleMeasure(e, item, 'scenario')
                                                                }
                                                            />
                                                        </td>
                                                        <td className="p-2 text-xs">
                                                            {item?.scenarioName}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-end mt-4 p-6 absolute bottom-0 right-0 bg-white w-full">
                                <button
                                    onClick={() => {
                                        setNewPageFilter(false);
                                        setNewFilter(false);
                                    }}
                                    className="px-4 py-2 bg-gray-300 rounded-lg mr-2"
                                >
                                    Cancel
                                </button>
                                <button className="px-4 py-2 bg-blue-600 text-white rounded-lg">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {newFilter && (
                    <PageFilter
                        selectedAttributes={selectedAttributes}
                        setSelectedAttributes={setSelectedAttributes}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        canvasFindData={canvasFindData}
                        openModalRow={openModalRow}
                        setOpenModalRow={setOpenModalRow}
                        attributeValueData={attributeValueData}
                        setAttributeValueData={setAttributeValueData}
                        formValues={formValues}
                        selectedMeasures={selectedMeasures}
                        setFormValues={setFormValues}
                        timeDimensionData={timeDimensionData}
                        setSelectedMeasures={setSelectedMeasures}
                        query={query}
                        setQuery={setQuery}
                        filteredOptions={filteredOptions}
                        filteredValidToOptions={filteredValidToOptions}
                        search={search}
                        setSearch={setSearch}
                        setNewPageFilter={setNewPageFilter}
                        setNewFilter={setNewFilter}
                        onScroll={onScroll}
                        listInnerRef={listInnerRef}
                        userList={userList}
                        scenarioList={scenarioList}
                        toggleMeasure={toggleMeasure}
                        systemAttributeList={systemAttributeList}
                        setSystemAttributeList={setSystemAttributeList}
                    />
                )}
                {viewWidgetFilter && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-2xl h-[700px] overflow-y-auto flex flex-col">
                            <div className="flex border-b mb-4">
                                <p>Widget Filters</p>
                            </div>
                            <div className='bg-gray-100 rounded-[5px] text-sm mb-5 p-3'>
                                Attributes List
                            </div>
                            <div
                                className="flex-grow overflow-y-auto custom-scrollbar"
                            >
                                <div className="border rounded-lg overflow-hidden">
                                    <table className="w-full border-collapse">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Attributes
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Logical Operator
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Attributes value
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedAttributes?.length > 0
                                                ? selectedAttributes?.map((attr, index) => (
                                                    <tr key={index} className="border-t">
                                                        <td className="p-2 text-xs">
                                                            {AttributeEnterpriseData?.find((item) => item?._id === attr?.attributes)?.name}
                                                        </td>
                                                        <td className="p-2 text-xs">{attr?.operator}</td>
                                                        <td>
                                                            <div
                                                                className={`text-sm bg-white appearance-none p-[8px] 
                                                                ${Object.values(attr.attValues).includes(true) ? '' : 'h-[41px]'}`}
                                                            >
                                                                {attr?.attValues &&
                                                                    Object.entries(attr.attValues)
                                                                        .filter(([, value]) => value === true)
                                                                        .map(([key]) => (
                                                                            <Chip
                                                                                label={key}
                                                                                variant="outlined"
                                                                                onDelete={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleRemoveValue(key, index)
                                                                                }}
                                                                                sx={{ fontSize: '10px' }} 
                                                                            />
                                                                        ))}
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))
                                                : []}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bg-gray-100 rounded-[5px] text-sm mb-5 p-3 mt-12'>
                                Time Attributes
                            </div>
                            <div
                                className="flex-grow mb-12"
                            >
                                <div className="border rounded-lg overflow-hidden">
                                    <table className="w-full border-collapse">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Time Period
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Logical Operator
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    From
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    To
                                                </th>
                                            </tr>
                                        </thead>
                                        {/* startDate :"",
                                        endDate:"" */}
                                        <tbody>
                                            <tr className="border-t">
                                                <td className="p-2 text-xs">
                                                    {formValues?.pageTimePeriodName}
                                                </td>
                                                <td className="p-2 text-xs">
                                                    {formValues?.pageLogicalOperator}
                                                </td>
                                                <td className="p-2 text-xs">
                                                    {/* {selectedMeasures?.pageValidFrom} */}
                                                    {formValues?.startDate || "-"}
                                                </td>
                                                <td className="p-2 text-xs">
                                                    {/* {selectedMeasures?.pageValidTo} */}
                                                    {formValues?.endDate || "-"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bg-gray-100 rounded-[5px] text-sm mb-5 p-3 mt-12'>
                                System Attributes
                            </div>
                            <div className="flex-grow mb-12">
                                <div className="border rounded-lg overflow-hidden">
                                    <table className="w-full border-collapse">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Attribute
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    Periodicity
                                                </th>
                                                <th className="p-2 text-left text-xs font-semibold">
                                                    value
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(systemAttributeList ?? []).map((item) => <tr className="border-t">
                                                <td className="p-2 text-xs">
                                                    {/* {AttributeEnterpriseData?.find((item) => item?._id === attr?.attributes)?.name} */}
                                                    {item.attributes}
                                                </td>

                                                <td className="p-2 text-xs">{item?.cyclePeriodicity}</td>
                                                <td>
                                                    <div
                                                        className={`text-sm bg-white appearance-none p-[8px] 
                                                                ${Object.values(item.attValues).includes(true) ? '' : 'h-[41px]'}`}
                                                    >
                                                        {item?.attValues &&
                                                            Object.entries(item.attValues)
                                                                .filter(([, value]) => value === true)
                                                                .map(([key]) => (
                                                                    <span
                                                                        key={key}
                                                                        className="text-xs bg-[bisque] p-[5px] m-[3px] rounded-[5px] inline-block"
                                                                    >
                                                                        {key}
                                                                    </span>
                                                                ))}
                                                    </div>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={() => setViewWidgetFilter(false)}
                                    className="px-4 py-2 bg-gray-300 rounded-lg mr-2"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PivotTable;

const CustomMenuList = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const height = 35; // Height of each option
    const value = getValue();

    return (
        <List
            height={maxHeight}
            itemCount={options.length}
            itemSize={height}
            width="100%"
        >
            {({ index, style }) => (
                <div style={style}>
                    <components.Option {...props} data={options[index]}>
                        {children[index]}
                    </components.Option>
                </div>
            )}
        </List>
    );
};
