import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { API_BASE_URL } from "../../../helper/constants";

const DimensionListModel = ({
  selectedDimensions,
  setSelectedDimensions,
  isModalOpen,
  setModalOpen,
  checkedDimensions,
  setCheckedDimensions,
  dimensionAttributes,
  setDimensionAttributes,
  formValues,
  newData,
  isEdit,
  modelEnterprise
}) => {
  const [allDimensions, setAllDimensions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const isDimensionSelected = (id) => {
    return checkedDimensions.find((item) => item._id === id) ? true : false;
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setCheckedDimensions([...checkedDimensions, item]);
    } else {
      setCheckedDimensions((prevObjects) =>
        prevObjects.filter((attr) => attr._id != item._id)
      );
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    const uniqueObjects = new Map();
    [...checkedDimensions, ...selectedDimensions].forEach((obj) => {
      uniqueObjects.set(obj._id, obj);
    });
    let uniqueDimensionsArray = Array.from(uniqueObjects.values());

    setSelectedDimensions(uniqueDimensionsArray);

    let primaryKeyAttributes = [];
    uniqueDimensionsArray.forEach((dimension) => {
      let primaryKeyAttribFromSelected = dimension.primaryKey?.length > 0 && dimension.primaryKey.map((attrib) => {
        if (typeof attrib === "object") {
          // Create a new object with the desired properties
          let modifiedAttrib = {
            ...attrib, // Copy existing properties
            primaryKey: true,
            required: true,
            // fromCompound: true
          };
          return modifiedAttrib;
        }
        return attrib;
      });
      if (primaryKeyAttribFromSelected?.length > 0) {
        primaryKeyAttributes = [
          ...primaryKeyAttributes,
          ...primaryKeyAttribFromSelected,
        ];
      }
    });

    let mergedAttributes = [...dimensionAttributes, ...primaryKeyAttributes];
    const uniqueAttributes = mergedAttributes.filter((obj, index, self) => {
      return self.findIndex((o) => o._id === obj._id) === index;
    });
    setDimensionAttributes([...uniqueAttributes]);
    setModalOpen(false);
    setCheckedDimensions([]);
  };

  useEffect(() => {
    const fetchDimensions = async () => {
      const userId = localStorage.getItem("accessToken");
      let enterpriseId = modelEnterprise?.enterpriseUID
      let modelId = modelEnterprise?.modelUID
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
        },
      };
      const baseURL = `${API_BASE_URL}/dimensions/withinEnterprise/${enterpriseId}/${modelId}`;
      // const baseURL = `${API_BASE_URL}/dimensions/${enterpriseId}/${modelId}`;
      let response = await axios.get(baseURL, config);
      let allDimens = response.data.data;

      const toRemoveIds = new Set(selectedDimensions.map(obj => obj._id));

      // allDimens = allDimens?.filter(dime => (
      //   dime.entermodelUID?.id === formValues?.modelName &&
      //   dime.enterpriseUID?._id === formValues?.enterpriseName &&
      //   !toRemoveIds.has(dime._id)
      // ));
      setAllDimensions(allDimens);
    };
    fetchDimensions();
  }, []);

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Dimensions</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          margin="normal"
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allDimensions.filter((item) => ["SIMPLE", "COMPOUND", "REFERENCE"].includes(item?.type))
                .map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Checkbox
                        value={item._id}
                        checked={isDimensionSelected(item._id)}
                        onChange={(event) => handleCheckboxChange(event, item)}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.description}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" sx={{ textTransform: "capitalize" }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DimensionListModel;
