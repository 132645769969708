import React, { useEffect, useMemo, useState } from 'react'
import { IoMdClose } from "react-icons/io";
import { FiGrid, FiFilter, FiMenu } from "react-icons/fi";
import { IoStatsChartOutline } from "react-icons/io5";
import { PivotViewComponent, Inject as PivotInject, DrillThrough } from '@syncfusion/ej2-react-pivotview';
import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    Inject as ChartInject,
    StackingColumnSeries,
    LineSeries,
    Category,
    Tooltip,
    Legend,
} from "@syncfusion/ej2-react-charts";
import { BiCode } from "react-icons/bi";
import { MdOutlineFormatPaint, MdOutlineTableChart } from "react-icons/md";
import { TbSum } from "react-icons/tb";
import { RiArrowUpDownFill } from "react-icons/ri";
import { FiShuffle } from "react-icons/fi";
import PivotTable from './PivotTable';
import { getMeasureListWithEnterprise } from '../../store/ApiSlice/measureSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAttributeOrEnterPrise } from '../../store/ApiSlice/attributeSlice';
import { enableRipple } from "@syncfusion/ej2-base";

enableRipple(false);

const EditcanvasWidget = ({
    isModalOpen,
    setIsModalOpen,
    changeType,
    isSidebarOpen,
    setChangeType,
    toggleSidebar,
    chartData,
    setChartData,
    toCamelCase,
    transformedData,
    formValues,
    setFormValues,
    addColumn,
    columns,
    setColumns,
    removeColumn,
    addRow,
    rows,
    setRows,
    removeRow,
    isRowModalOpen,
    newRowName,
    setNewRowName,
    setRowModalOpen,
    handleAddRow,
    isColumnModalOpen,
    newColumnName,
    setNewColumnName,
    setColumnModalOpen,
    handleAddColumn,
    setIsOpenWidgetModel,
    processPageData,
    openedWorkbookName,
    newPageID,
    value,
    setIsSidebarOpen,
    pageFilter,
    setPageFilter
}) => {
    const { measureList } = useSelector((state) => state.measure)
    const { scenarioList } = useSelector((state) => state.process);
    const { timeDimensionData, schemaData } = useSelector((state) => state.canvas);
    const dispatch = useDispatch()
    useEffect(() => {
        setFormValues({
            widgetName: chartData?.value?.widgetUID?.widgetName,
            widgetType: chartData?.value?.widgetUID?.widgetType,
            widgetSubType: chartData?.value?.widgetUID?.widgetSubType,
            ...chartData?.value?.widgetUID
        })
    }, [isModalOpen])

    const [activeTab, setActiveTab] = useState("grid");
    const tabs = [
        { id: "grid", icon: <FiGrid size={16} />, tooltip: "Grid" },
        { id: "chart", icon: <IoStatsChartOutline size={16} />, tooltip: "Chart" },
        { id: "code", icon: <BiCode size={16} />, tooltip: "Code" },
        { id: "table", icon: <MdOutlineTableChart size={16} />, tooltip: "Table" },
    ];

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return; // If dropped outside

        const sourceList = source.droppableId === "columns" ? columns : rows;
        const destinationList = destination.droppableId === "columns" ? columns : rows;
        const setSourceList = source.droppableId === "columns" ? setColumns : setRows;
        const setDestinationList = destination.droppableId === "columns" ? setColumns : setRows;

        // If dragging within the same list
        if (source.droppableId === destination.droppableId) {
            const reorderedList = [...sourceList];
            const [movedItem] = reorderedList.splice(source.index, 1);
            reorderedList.splice(destination.index, 0, movedItem);
            setSourceList(reorderedList);
        } else {
            // Moving between lists
            const newSourceList = [...sourceList];
            const newDestinationList = [...destinationList];
            const [movedItem] = newSourceList.splice(source.index, 1);
            newDestinationList.splice(destination.index, 0, movedItem);
            setSourceList(newSourceList);
            setDestinationList(newDestinationList);
        }
    };

    useEffect(() => {
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            dispatch(getMeasureListWithEnterprise({ formValues }))
            dispatch(getAttributeOrEnterPrise({ enterPriseId: formValues?.enterpriseUID, modalId: formValues?.modelUID }))
        }
    }, [formValues?.enterpriseUID, formValues?.modelUID])

    const findType = schemaData?.length > 0 &&
        schemaData?.find(
            (item) =>
                item['Time Period Name'] ===
                chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.values[0]
        );

    const findTime = timeDimensionData?.timeSchemaFields?.find((data) => data['Period Type'] === chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.timePeriodType);

    const pivotColumnData = chartData?.value?.widgetUID?.pivotTable?.layoutSetting?.column?.flatMap((item) => {
        const data = measureList?.find((row) => row?.measureName === item?.fieldName);
        const periodTypedata = findTime["Period Type"] === item?.fieldName
        if (periodTypedata) {
            return [];
        }

        return [{
            fieldName: data?.measureName === item?.fieldName ? `Measure Name` : item?.fieldName,
            caption: item?.fieldName,
            order: item?.order,
            showSubTotals: false
        }];
    });

    const pivotRowData = chartData?.value?.widgetUID?.pivotTable?.layoutSetting?.row?.flatMap((item, index) => {
        const data = measureList?.find((row) => row?.measureName === item?.fieldName);
        const periodTypedata = findTime["Period Type"] === item?.fieldName
        if (periodTypedata) {
            return [];
        }

        return [{
            fieldName: data?.measureName === item?.fieldName ? `Measure Name` : item?.fieldName,
            caption: item?.fieldName,
            order: item?.order,
            showSubTotals: false
        }];
    });

    const measureRows = pivotRowData?.filter((item) => item.fieldName === "Measure Name");
    const measureColumn = pivotColumnData?.filter((item) => item?.fieldName === "Measure Name");
    const uniqueMeasures = measureRows.map((item) => item["Measure Name"]);
    const uniqueMeasuresColumn = measureColumn?.map((item) => item["Measure Name"]);

    const groupedMeasureRow = uniqueMeasures.length
        ? [{ fieldName: "Measure Name", caption: "Measure Name" }]
        : [];
    const groupedMeasureColumn = uniqueMeasuresColumn?.length
        ? [{ fieldName: "Measure Name", caption: "Measure Name" }]
        : [];

    const nonMeasureRows = pivotRowData.filter((item) => item.fieldName !== "Measure Name");
    const nonMeasureColumn = pivotColumnData?.filter((item) => item?.fieldName !== "Measure Name");
    const scenarioName = [
        { fieldName: "Scenario", caption: "Scenario" }
    ]
    const finalPivotRows = [...groupedMeasureRow, ...nonMeasureRows];
    const finalPivotColumn = [...groupedMeasureColumn, ...nonMeasureColumn, ...scenarioName];



    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999999] p-5">
                <div className="bg-white rounded-lg shadow-lg max-h-full w-full relative h-full overflow-y-auto custom-scrollbar">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-xl font-semibold">TopLine KPIs</h3>
                        <button onClick={() => {
                            setIsModalOpen(!isModalOpen)
                            setChangeType()
                            setIsSidebarOpen(false)
                        }} className="text-gray-400 hover:text-gray-600">
                            <IoMdClose />
                        </button>
                    </div>
                    <div className="p-4 bg-white shadow-sm flex items-center justify-between rounded-lg">
                        <div className="flex items-center gap-8">
                            <div className="flex items-center gap-4 text-gray-600">
                                {/* <button className="flex items-center gap-2 text-[#181D27] font-semibold hover:text-[#335CFF] px-3 py-1 rounded-md">
                                    <FiMenu size={20} />
                                    Default View
                                    <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                                </button> */}
                                <div className="flex bg-[#FAFAFA] border border-gray-300 rounded-md shadow-sm">
                                    {tabs.map((tab, index) => (
                                        <div key={tab.id} onClick={() => setChangeType(tab.id)} className="relative group flex items-center">
                                            <button
                                                onClick={() => setActiveTab(tab.id)}
                                                className={`flex items-center justify-center w-[52px] h-[40px] transition-all 
                                                    ${activeTab === tab.id
                                                        ? `text-blue-600 bg-white font-medium shadow-sm
                                                            ${index === 0 ? "rounded-l-md" : ""}
                                                            ${index === tabs.length - 1 ? "rounded-r-md" : ""}`
                                                        : "text-black hover:bg-gray-200"}
                                                `}
                                            >
                                                {tab.icon}
                                            </button>
                                            {index !== tabs.length - 1 && (
                                                <div className="h-10 w-[1px] bg-gray-300"></div>
                                            )}
                                            <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap px-2 py-1 text-xs text-white bg-gray-700 rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                                                {tab.tooltip}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex items-center space-x-2 ml-3">
                                    <button className="p-2 rounded-md text-gray-600 hover:bg-gray-200">
                                        <FiFilter size={16} />
                                    </button>
                                    <button className="p-2 rounded-md hover:bg-gray-200 text-gray-600">
                                        <RiArrowUpDownFill size={16} />
                                    </button>
                                    <button className="p-2 rounded-md text-gray-600 hover:bg-gray-200">
                                        <TbSum size={16} />
                                    </button>
                                    <button className={`flex items-center gap-2 font-semibold px-3 py-2.5 rounded-md leading-5 ${isSidebarOpen ? "bg-[#E6F0FE] text-[#2F80ED]" : ""}`} onClick={toggleSidebar}>
                                        <FiShuffle size={16} className="mr-1" />
                                        Pivot
                                    </button>
                                    <button className="p-2 rounded-md text-gray-600 hover:bg-gray-200">
                                        <MdOutlineFormatPaint size={16} />
                                    </button>
                                    <button className="p-2 rounded-md text-gray-600 hover:bg-gray-200">
                                        Format
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        {changeType === "chart" ? (
                            <div className={`p-4 h-[calc(100vh-200px)] ${isSidebarOpen ? 'w-[calc(100%-507px)]' : 'w-full'}`}>
                                <ChartComponent
                                    // ref={(el) => (chartRefs.current[groupIndex] = el)}
                                    width="100%"
                                    height="100%"
                                    primaryXAxis={{
                                        valueType: "Category",
                                        labelIntersectAction: "Rotate45",
                                    }}
                                    tooltip={{ enable: true }}
                                    legendSettings={{ visible: false }}
                                >
                                    <ChartInject services={[StackingColumnSeries, LineSeries, Category, Tooltip, Legend]} />
                                    <SeriesCollectionDirective>
                                        {chartData?.value?.widgetUID?.filterInfo?.systemAttributes?.scenario?.map((item, index) => {
                                            return (
                                                <SeriesDirective
                                                    key={index}
                                                    dataSource={transformedData}
                                                    xName={chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.timeAttribute?.toLowerCase()}
                                                    yName={item}
                                                    name={item}
                                                    type="StackingColumn"
                                                />
                                            )
                                        })}
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>
                        ) : changeType === "grid" ? (
                            <div className={`p-4 ${isSidebarOpen ? 'w-[calc(100%-507px)]' : 'w-full'}`}>
                                <PivotViewComponent
                                    // dataSourceSettings={dataSourceSettings}
                                    showFieldList={false} // ✅ Hides field list popup
                                    showGroupingBar={false}
                                    gridSettings={{
                                        allowResizing: true,
                                        allowAutoResizing: true,
                                        rowHeight: 40,
                                        emptyCellsTextContent: "" // ✅ Removes 'null' text
                                    }}
                                    width="100%"
                                    height="400px"
                                    className="table-scroll"
                                    dataSourceSettings={{
                                        dataSource: chartData?.tableData || [],
                                        // expandAll: false,
                                        values: chartData?.value?.widgetUID?.filterInfo?.timeAttributes[0]?.values?.map((time) => ({
                                            name: time,
                                            caption: time,
                                            allowEditing: true,
                                        })),
                                        rows: finalPivotRows.map((item) => ({
                                            name: item.fieldName,
                                            caption: item.caption
                                        })),
                                        columns: finalPivotColumn.map((item) => ({
                                            name: item.fieldName,
                                            caption: item.caption
                                        })),
                                        filters: [],
                                        showGrandTotals: false,
                                        showRowGrandTotals: false,
                                        showColumnGrandTotals: false,
                                        showValueTotals: false,
                                        showSubTotals: false,
                                    }}

                                />
                            </div>
                        ) : (
                            ""
                        )}

                        {isSidebarOpen && (
                            <PivotTable
                                isModalOpen={isModalOpen}
                                changeType={changeType}
                                isSidebarOpen={isSidebarOpen}
                                chartData={chartData}
                                setChartData={setChartData}
                                setIsOpenWidgetModel={chartData ? setIsSidebarOpen : setIsOpenWidgetModel}
                                processPageData={processPageData}
                                openedWorkbookName={openedWorkbookName}
                                newPageID={newPageID}
                                value={value}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditcanvasWidget
