import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  getCycleIdList,
  setChartPending,
  setPending,
} from '../store/ApiSlice/widgetSlice.js';
import { SketchPicker } from 'react-color';
import {
  createFilterDetail,
  createHomeWidgetDetail,
  createWorkBookDetail,
  getCanvasList,
  getFilterWithEnterprise,
  getHomePageWidget,
  getPageList,
  getPrimaryTimeDimension,
  getSchemaData,
  getWorkBookDetail,
  setDropped,
  setLayout,
} from '../store/ApiSlice/canvasSlice.js';
import {
  fetchDimensions,
  getLagIdList,
} from '../store/ApiSlice/dimensionSlice.js';
import { CycleIdData, MeasureDataFirst, MeasureDataSecond } from '../components/canvas/shared/MeasureData.jsx';
import WidgetFilter from '../components/page/WidgetFilter.js';
import { getScenarios } from '../store/ApiSlice/processSlice.js';
import { getMeasureListWithEnterprise } from '../store/ApiSlice/measureSlice.js';
import { setIsAddWidgetsOpenModal } from '../store/ApiSlice/enterpriseSlice.js';

let idCounter = 0;

const getId = () => {
  idCounter++;

  return idCounter.toString();
};

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '12px',
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    zIndex: 9999,
    fontSize: '12px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#0366d6' : 'white',
    color: state.isSelected ? 'white' : 'black',
    fontSize: '12px',
  }),
  multiValue: (base, state) => {
    return state.data.isFixed
      ? { ...base, backgroundColor: 'red', fontSize: '12px' }
      : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? {
        ...base,
        fontWeight: 'bold',
        color: 'white',
        paddingRight: 6,
        fontSize: '12px',
      }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const AddWidget = (props) => {
  const {
    newPageID,
    editWorkSheet,
    setEditWorkSheet,
    setLoader,
    isCreateWidget,
    value,
    openModal,
    openedWorkbookName,
    // setGeneralModel,
    finalData,
    setActiveTab,
    processPageData,
    widgetGroupData,
    setWidgetGroupData,
    newActiveWidgetLoader,
    setNewActiveWidgetLoader,
  } = props;
  const id = '6787522ec7d598f868344238';
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("accessToken"))
  const { scenarioList } = useSelector((state) => state.process);
  const { modalData } = useSelector((state) => state.modelList);
  const { dimensionLagIDdata } = useSelector((state) => state.dimension);
  const [selectedOptions, setSelectedOptions] = useState({
    attributes: [],
    validFrom: '',
  });
  const [formValues, setFormValues] = useState({
    measure: {},
    widgetSubType: "Measure Data",
    widgetType: "Worksheet"
  });

  const [droppedLayout, setDroppedLayout] = useState([]);
  const [pendingLayout, setPendingLayout] = useState([]);
  const { allDimensions, dimensionData } = useSelector((state) => state.dimension);
  const { measureData, measurePaginationList, measureList } = useSelector(
    (state) => state.measure
  );
  const [timePeriodData, setTimePeriodData] = useState([]);
  const {
    layoutData,
    aggregatedViewData,
    canvasData,
    filterDetail,
    filterEnterpriseDetail,
    layout,
    timeDimensionData,
    schemaData,
    dimensionFilteredData,
    pageData
  } = useSelector((state) => state.canvas);
  const [newFilter, setNewFilter] = useState(false);
  const { cycleIDList, pending } = useSelector((state) => state.widget);
  const [selectedAttributes, setSelectedAttributes] = useState([
    {
      attributes: '',
      operator: 'Equal To',
      attValues: {},
    },
  ]);
  const [selectedValue, setSelectedValue] = useState({});
  const [openModalRow, setOpenModalRow] = useState(null);
  const [attributeValueData, setAttributeValueData] = useState({
    attValues: {},
  });

  useEffect(() => {
    dispatch(getScenarios())
  }, [])

  const canvasFindData = {
    enterpriseUID: {
      _id: user?.data?.enterprise?._id
    },
    modelUID: {
      modalUID: formValues?.modelUID
    }
  };


  const viewData =
    finalData?.widgetUID?.widgetSubType === 'Measure Data'
      ? aggregatedViewData?.data
      : dimensionFilteredData?.data;


  const findMeasure = measureList?.filter(
    (item) =>
      item?.enterpriseUID === canvasFindData?.enterpriseUID?._id &&
      item?.modelUID === canvasFindData?.modelUID?.modalUID
  );
  const findTimePeriod =
    schemaData?.length > 0 &&
    formValues?.timePeriodName &&
    schemaData?.filter(
      (item) => item['Period Base Level'] === formValues?.timePeriodName
    );

  const findDiemnsion = allDimensions?.find(
    (item) => item?._id === selectedOptions?.dimension
  );
  const findMeasureLagId = measureList?.filter(
    (item) =>
      Array.isArray(selectedOptions?.measures) &&
      selectedOptions.measures.some((row) => row?.measureUID === item?._id)
  );

  const removeDuplicates = (array) => {
    const map = new Map();
    return (
      array?.length > 0 &&
      array?.reduce((acc, item) => {
        const key = JSON.stringify(item.name);
        if (!map.has(key)) {
          map.set(key, true);
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };

  const uniqueItems = removeDuplicates(
    findMeasureLagId?.flatMap((item) => item?.baselevelUID?.fields || [])
  );

  const findDiemnsionAttribute =
    formValues?.widgetSubType === 'Dimension Data'
      ? findDiemnsion?.fields
      : uniqueItems;

  const findLagId =
    findMeasureLagId?.length > 0 &&
    (formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart') &&
    findMeasureLagId?.filter((row) =>
      row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
    );
  const now = new Date();
  const startOfMonth = moment(
    new Date(now.getFullYear(), now.getMonth(), 1)
  ).format('YYYY-DD-MM');
  const endOfMonth = moment(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  ).format('YYYY-MM-DD');
  const findCycleIdMonth =
    findLagId?.length > 0 && cycleIDList?.length > 0
      ? cycleIDList?.filter((item) => {
        const validFromDate = moment(item['Cycle Start']).format(
          'YYYY-MM-DD'
        );
        const validToDate = moment(item['Cycle End'], 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        );
        return startOfMonth <= validFromDate && endOfMonth >= validToDate;
      })
      : [];

  const dataTimePeriod = () => {
    const data =
      findTimePeriod?.length > 0 &&
      findTimePeriod?.filter((item) => {
        const validFromDate = moment(item['Period Start']).format('YYYY-MM-DD');
        const validToDate = moment(item['Period End']).format('YYYY-MM-DD');
        const startDate = moment(selectedOptions?.startDate).format(
          'YYYY-MM-DD'
        );
        const endDate = moment(selectedOptions?.endDate).format('YYYY-MM-DD');
        return startDate <= validFromDate && endDate >= validToDate;
      });
    setTimePeriodData(data);
  };

  useEffect(() => {
    if (editWorkSheet) {
      const widgetTypeData =
        finalData?.widgetUID?.widgetType === 'Worksheet'
          ? finalData?.widgetUID?.worksheet?.timePeriods
          : finalData?.widgetUID?.chart?.timePeriods;
      const findperiodType =
        schemaData?.length > 0 &&
        schemaData?.filter(
          (item) => item['Period Base Level'] == widgetTypeData?.periodType
        );
      const validFromDate =
        findperiodType &&
        findperiodType?.find(
          (option) =>
            moment(option['Period Start']).format('YYYY-MM-DD') ===
            moment(widgetTypeData?.from).format('YYYY-MM-DD')
        );
      const validToDate =
        findperiodType &&
        findperiodType?.find(
          (option) =>
            moment(option['Period End']).format('YYYY-MM-DD') ===
            moment(widgetTypeData?.to)?.format('YYYY-MM-DD')
        );
      const findTime = timeDimensionData?.timeSchemaFields?.find(
        (data) => data['Period Type'] === widgetTypeData?.periodType
      );
      const findScenario =
        scenarioList?.length > 0 &&
        scenarioList?.filter((item) =>
          finalData?.widgetUID?.scenario?.map(
            (row) => row === item?.scenarioName
          )
        );
      const findCycleId =
        finalData?.widgetUID?.cycleID?.length > 0 &&
        cycleIDList?.filter((item) =>
          finalData?.widgetUID?.cycleID?.some((row) => row === item['Cycle ID'])
        );

      const findLagId =
        finalData?.widgetUID?.lagID?.length > 0 &&
        dimensionLagIDdata?.filter((item) =>
          finalData?.widgetUID?.lagID?.some((row) => row == item['Lag ID'])
        );

      const initialColors =
        selectedOptions?.measures?.length > 0 &&
        selectedOptions?.measures?.reduce((acc, item) => {
          if (item?.measureName) {
            acc[item.measureName] = finalData?.widgetUID?.chart?.chartSettings
              ? finalData?.widgetUID?.chart?.chartSettings[item.measureName]
              : 'rgba(0, 100, 200, 0.3)';
          }
          return acc;
        }, {});
      const initialMeasure =
        selectedOptions?.measures?.length > 0 &&
        selectedOptions?.measures?.reduce((acc, item) => {
          if (item?.measureName) {
            acc[item.measureName] = finalData?.widgetUID?.chart?.chartSettings
              ?.measure
              ? finalData?.widgetUID?.chart?.chartSettings?.measure[
              item.measureName
              ]
              : '';
          }
          return acc;
        }, {});

      setSelectedOptions({
        attributes: (
          finalData?.widgetUID?.worksheet?.attributes ||
          finalData?.widgetUID?.chart?.attributes
        )?.map((item) => ({
          attributeName: item?.attributeName,
          attributeUID: item?.attributeUID,
        })),
        measures: (
          finalData?.widgetUID?.worksheet?.measures ||
          finalData?.widgetUID?.chart?.measures
        )?.map((item) => ({
          measureName: item?.measureName,
          measureUID: item?.measureUID,
          enterpriseUID: item?.enterpriseUID,
          modelUID: item?.modelUID,
          baselevelUID: item?.baselevelUID,
        })),
        filter: finalData?.widgetUID?.filterInfo?.filterRefID,
        scenario:
          findScenario?.length > 0 &&
          findScenario?.map((item) => ({
            _id: item?._id,
            scenario: item?.scenarioName,
          })),
        cycleId:
          findCycleId?.length > 0 &&
          findCycleId?.map((item) => ({
            _id: item?._id,
            cyclePeriodicity: item['Cycle ID'],
          })),
        lagID:
          findLagId?.length > 0 &&
          findLagId?.map((item) => ({
            _id: item?._id,
            lagID: item['Lag ID'],
          })),
        dimension: finalData?.widgetUID?.worksheet?.dimension,
        ...((finalData?.widgetUID?.widgetSubType === 'Measure Data' ||
          finalData?.widgetUID?.widgetType === 'Chart') && {
          validFrom: validFromDate['Time Period Name'],
          validTo: validToDate['Time Period Name'],
          startDate: moment(widgetTypeData?.from).format('YYYY-MM-DD'),
          endDate: moment(widgetTypeData?.to).format('YYYY-MM-DD'),
        }),
      });
      if (finalData?.widgetUID?.filterInfo?.attributes?.length > 0) {
        setSelectedAttributes(
          finalData?.widgetUID?.filterInfo?.attributes?.map((item) => ({
            attributes: item?.attributeUID,
            operator: item?.operator,
            attValues: item?.values?.reduce((acc, value) => {
              acc[value] = true;
              return acc;
            }, {}),
          }))
        );
      }
      setFormValues({
        widgetType: finalData?.widgetUID?.widgetType,
        widgetName: finalData?.widgetUID?.widgetName,
        workbookNotes: finalData?.widgetUID?.notes,
        widgetGroup: finalData?.widgetUID?.widgetGroup,
        widgetSubType: finalData?.widgetUID?.widgetSubType,
        timePeriod: findTime?._id,
        timePeriodName: widgetTypeData?.periodType,
        cyclePeriodicity: findCycleId?.[0]?.['Cycle Periodicity'],
        ...(finalData?.widgetUID?.widgetType === 'Chart' && {
          chartType: finalData?.widgetUID?.chart?.chartSettings?.type,
          orientation: finalData?.widgetUID?.chart?.chartSettings?.orientation,
          ...((finalData?.widgetUID?.chart?.chartSettings?.type ===
            'arearange' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'spline' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'bar' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'area' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'scatter' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'areaspline' ||
            finalData?.widgetUID?.chart?.chartSettings?.type ===
            'combination') && {
            ...initialColors,
          }),
          ...(finalData?.widgetUID?.chart?.chartSettings?.type ===
            'arearange' && {
            areaShadeColor:
              finalData?.widgetUID?.chart?.chartSettings?.areaShadeColor,
          }),
          measure: {
            ...initialMeasure,
          },
        }),
      });
    } else if (!editWorkSheet && isCreateWidget && openModal) {
      const baselineScenario = scenarioList.find(
        (item) => item?.scenarioName === 'Baseline'
      );
      setSelectedOptions({
        attributes: [],
        measures: [],
        validFrom: '',
        validTo: '',
        scenario: [
          {
            _id: baselineScenario._id,
            scenario: baselineScenario?.scenarioName,
          },
        ],
      });
      setFormValues({
        widgetName: finalData?.widgetUID?.widgetName,
        workbookNotes: finalData?.widgetUID?.notes,
        widgetGroup: '',
      });
    }
  }, [editWorkSheet, !editWorkSheet, finalData, schemaData, viewData]);

  const attributeNames =
    selectedOptions?.attributes?.length > 0
      ? selectedOptions?.attributes.map((item) => item.attributeName)
      : [];

  const formatTimePeriods = (timePeriods) => {
    return (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      `Time Periods (${timePeriods})`
    );
  };
  const timePeriodsFormatted = formatTimePeriods(formValues?.timePeriodName);

  const mergeLayoutData = [
    ...((formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart') &&
      findLagId?.length > 0
      ? ['Cycle ID']
      : []),
    ...((formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart') &&
      dimensionLagIDdata?.length > 0 &&
      selectedOptions?.lagID?.length > 0
      ? ['Lag ID']
      : []),
    ...attributeNames,
    ...(formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart'
      ? ['Measure Name']
      : []),
    ...(formValues?.widgetType === 'Chart' ? [timePeriodsFormatted] : []),
    'Scenario',
  ];

  const otherColumns =
    mergeLayoutData?.length > 0 &&
    mergeLayoutData
      .filter((key) => key !== 'Aggregation Mode')
      .filter((key) => key !== '_id')
      .map((key, index) => ({
        id: index,
        headerName: key,
        field: key,
        done: false,
        editable: true,
        width: 150,
      }));

  const columnDefs = [];

  if (otherColumns?.length > 0) {
    columnDefs.push(otherColumns[0]);

    columnDefs.push(...otherColumns.slice(1));
  }
  const pendingLayoutData = JSON.parse(localStorage.getItem('pending'));
  const findGroupName =
    pendingLayoutData?.[finalData?.widgetUID?.widgetName] || [];

  useEffect(() => {
    let columnDefsMap = {};
    columnDefs?.forEach((def) => {
      columnDefsMap[def.id] = def;
    });

    let sortedColumnDefs =
      columnDefs?.length > 0
        ? columnDefs
          ?.map((item) => columnDefsMap[item.id])
          .filter((def) => def !== undefined)
        : columnDefs;

    const defaultItem = {
      id: pendingLayout?.length,
      headerName: timePeriodsFormatted,
      field: timePeriodsFormatted,
      done: timePeriodsFormatted,
      editable: timePeriodsFormatted,
      width: 150,
    };

    const droppedLayoutData = JSON.parse(localStorage.getItem('dropped'));
    const findDroppedGroupName =
      droppedLayoutData?.[finalData?.widgetUID?.widgetName] || [];
    const abcdata = [...findGroupName, ...sortedColumnDefs];
    const uniqueAbcdata = abcdata?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.field === item.field)
    );

    if (!openModal) {
      setPendingLayout(sortedColumnDefs);
      setDroppedLayout([defaultItem]);
    } else {
      const layoutCheck = uniqueAbcdata?.filter(
        (item) => item?.headerName === defaultItem?.headerName
      );
      const finalLayoutData =
        uniqueAbcdata?.length > 0
          ? uniqueAbcdata?.filter((item) =>
            sortedColumnDefs?.some((row) => row?.field === item?.field)
          )
          : [];
      setPendingLayout(
        finalLayoutData?.length > 0 ? finalLayoutData : sortedColumnDefs
      );
      setDroppedLayout(
        findDroppedGroupName?.length > 0
          ? findDroppedGroupName
          : layoutCheck?.length > 0
            ? []
            : [defaultItem]
      );
    }
  }, [
    formValues,
    selectedOptions?.attributes,
    selectedOptions?.cycleId,
    selectedOptions?.lagID,
    pending,
  ]);

  const handleDragStart = (e, id, from) => {
    e.dataTransfer.setData('text/plain', id);
    e.dataTransfer.setData('from', from);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, target) => {
    e.preventDefault();
    const id = e.dataTransfer.getData('text/plain');
    const from = e.dataTransfer.getData('from');
    const itemId = parseInt(id, 10);

    // Handle move from "pending" to "dropped"
    if (from === 'pending' && target === 'dropped') {
      const item = pendingLayout?.find((item) => item.id === itemId);
      if (item) {
        setPendingLayout((prevPending) =>
          prevPending?.filter((item) => item.id !== itemId)
        ); // Remove from pending
        setDroppedLayout((prevDropped) => {
          // Check if the item is already in the droppedLayout before adding
          if (!prevDropped?.some((droppedItem) => droppedItem.id === itemId)) {
            return [...prevDropped, item]; // Add to droppedLayout if not present
          }
          return prevDropped;
        });
      }
    }

    // Handle move from "dropped" to "pending"
    else if (from === 'dropped' && target === 'pending') {
      const item = droppedLayout?.find((item) => item.id === itemId);
      if (item) {
        setDroppedLayout((prevDropped) =>
          prevDropped?.filter((item) => item?.id !== itemId)
        ); // Remove from dropped
        setPendingLayout((prevPending) => {
          // Check if the item is already in the pendingLayout before adding
          if (!prevPending?.some((pendingItem) => pendingItem?.id === itemId)) {
            return [...prevPending, item]; // Add to pendingLayout if not present
          }
          return prevPending;
        });
      }
    }
  };

  const handleReorder = (e, index, target) => {
    e.preventDefault();

    const id = e.dataTransfer.getData('text/plain');
    const from = e.dataTransfer.getData('from');
    const itemId = parseInt(id, 10);

    let sourceArray, setSourceArray;

    if (from === 'pending') {
      sourceArray = pendingLayout;
      setSourceArray = setPendingLayout;
    } else if (from === 'dropped') {
      sourceArray = droppedLayout;
      setSourceArray = setDroppedLayout;
    } else {
      return;
    }

    const item = sourceArray?.find((item) => item.id === itemId);
    if (item) {
      const updatedArray = [
        ...sourceArray?.filter((item) => item?.id !== itemId),
      ]; // Remove item
      updatedArray?.splice(index, 0, item); // Insert at new index

      setSourceArray(updatedArray);
    }
  };

  useEffect(() => {
    if (selectedOptions?.validFrom && selectedOptions?.validTo) {
      dataTimePeriod();
    }
  }, [selectedOptions?.validFrom, selectedOptions?.validTo]);

  const getScenarioData = async () => {
    const modelName = modalData?.find((item) => item?.modelUID === formValues?.modelUID)
    const body = {
      enterpriseName: user?.data?.enterprise?.enterpriseName,
      modelName: modelName?.modelName,
    };
    const result = await dispatch(getScenarios({ body }))
    // if (result?.meta?.requestStatus === 'fulfilled') {
    //   console.log("resultresult", result);
    // } else if (result?.meta?.requestStatus === 'rejected') {
    //   console.log("resultresult", result);
    // }
  };

  useEffect(() => {
    dispatch(getWorkBookDetail());
    if (canvasFindData?.enterpriseUID?._id && canvasFindData?.modelUID?.modalUID) {
      dispatch(getPrimaryTimeDimension({ canvasFindData }));
      dispatch(getLagIdList({ canvasFindData }));
      dispatch(getCycleIdList({ canvasFindData }));
      dispatch(getFilterWithEnterprise({ canvasFindData }));
      dispatch(
        fetchDimensions({
          enterpriseId: canvasFindData?.enterpriseUID?._id,
          modelId: canvasFindData?.modelUID?.modalUID,
        })
      );
      dispatch(getPageList({ canvasFindData }))
      const data = {
        enterpriseUID: user?.data?.enterprise?._id,
        modelUID: formValues?.modelUID
      }
      dispatch(getMeasureListWithEnterprise({ formValues: data }))
      getScenarioData()
    }
  }, [canvasFindData?.enterpriseUID?._id, canvasFindData?.modelUID?.modalUID]);

  useEffect(() => {
    if (timeDimensionData && Object.keys(timeDimensionData).length > 0) {
      dispatch(getSchemaData({ timeDimensionData }));
    }
  }, [timeDimensionData])

  useEffect(() => {
    if (formValues?.widgetSubType === 'Dimension Data') {
      const defaultSelectedOptions =
        findDiemnsion?.primaryKey?.length > 0 &&
        findDiemnsion?.primaryKey?.map((item) => ({
          value: item?._id,
          label: item?.name,
        }));
      setSelectedOptions({
        ...selectedOptions,
        attributes:
          (defaultSelectedOptions?.length > 0 &&
            defaultSelectedOptions?.map((item) => ({
              attributeName: item?.label,
              attributeUID: item?.value,
              isFixed: true,
            }))) ||
          [],
      });
    }
  }, [findDiemnsion?.primaryKey]);

  useEffect(() => {
    if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      selectedOptions?.measures &&
      !selectedOptions?.cycleId &&
      editWorkSheet
    ) {
      const findCycleId = cycleIDList?.filter((item) =>
        finalData?.widgetUID?.cycleID?.some((row) => row === item['Cycle ID'])
      );
      const mergeArrays = (arr1, arr2) => {
        const combinedArray = [...arr1, ...arr2];
        const uniqueArray =
          combinedArray?.length > 0 &&
          combinedArray?.filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t._id === item._id &&
                  t['Scenario Name'] === item['Scenario Name'] &&
                  t['Cycle ID'] === item['Cycle ID'] &&
                  t['Cycle Periodicity'] === item['Cycle Periodicity'] &&
                  t['Cycle Start'] === item['Cycle Start'] &&
                  t['Cycle End'] === item['Cycle End']
              )
          );
        return uniqueArray;
      };

      const mergedArray = mergeArrays(findCycleId, findCycleIdMonth);
      setSelectedOptions({
        ...selectedOptions,
        cycleId:
          mergedArray?.length > 0 &&
          mergedArray?.map((item) => ({
            _id: item?._id,
            cyclePeriodicity: item['Cycle ID'],
          })),
      });
    }
  }, [
    formValues?.widgetSubType,
    selectedOptions?.measures,
    selectedOptions?.cycleId,
  ]);

  useEffect(() => {
    const baselineScenario = scenarioList.find(
      (item) => item?.scenarioName === 'Baseline'
    );

    if (baselineScenario) {
      setSelectedOptions((prevState) => ({
        ...prevState,
        scenario: [
          {
            _id: baselineScenario._id,
            scenario: baselineScenario?.scenarioName,
          },
        ],
      }));
    }
  }, [formValues?.widgetSubType, scenarioList]);

  const handleChangeAttribute = (data, type) => {
    if (type === 'filter' || type === 'dimension') {
      setSelectedOptions((prevState) => ({
        ...prevState,
        [type]: data?.value,
      }));
    } else if (type === 'cycleId') {
      const cycleIDPeriodicity = cycleIDList?.filter(
        (item) => item['Cycle Periodicity'] === formValues?.cyclePeriodicity
      );
      const selectedIds = data?.map((item) => item.value);
      const selectedLabels = data?.map((item) => item.label);
      if (selectedIds.length < selectedOptions?.cycleId?.length) {
        const newRangeSelected = selectedOptions?.cycleId?.filter((item) =>
          selectedIds?.includes(item?._id)
        );
        setSelectedOptions((prevState) => ({
          ...prevState,
          cycleId: newRangeSelected,
        }));
      } else if (selectedIds?.length >= 2) {
        // Handle multi-selection
        const sortedCycleList = [...cycleIDPeriodicity]?.sort(
          (a, b) => new Date(a['Cycle Start']) - new Date(b['Cycle Start'])
        );
        const startIndex = sortedCycleList?.findIndex(
          (item) => item['Cycle ID'] === selectedLabels[0]
        );
        const endIndex = sortedCycleList?.findIndex(
          (item) =>
            item['Cycle ID'] === selectedLabels[selectedLabels?.length - 1]
        );
        const [start, end] =
          startIndex < endIndex
            ? [startIndex, endIndex]
            : [endIndex, startIndex];
        const rangeSelected = sortedCycleList?.slice(start, end + 1);

        const selectedCycleData =
          rangeSelected?.length > 0 &&
          rangeSelected?.map((item) => ({
            _id: item?._id,
            cyclePeriodicity: item['Cycle ID'],
            isFixed: true,
          }));

        setSelectedOptions((prevState) => ({
          ...prevState,
          cycleId: selectedCycleData,
        }));
      } else if (selectedIds.length === 1) {
        // Handle single selection
        const singleSelection =
          data?.length > 0 &&
          data?.map((item) => ({
            _id: item?.value,
            cyclePeriodicity: item?.label,
            isFixed: true,
          }));
        setSelectedOptions((prevState) => ({
          ...prevState,
          cycleId: singleSelection,
        }));
      }
    } else if (type === 'lagID') {
      const selectedIds = data?.map((option) => option.value);
      const selectedLabels = data?.map((item) => item.label);
      if (selectedIds?.length < selectedOptions?.lagID?.length) {
        const newRangeSelected = selectedOptions?.lagID?.filter((item) =>
          selectedIds?.includes(item?._id)
        );
        setSelectedOptions((prevState) => ({
          ...prevState,
          lagID: newRangeSelected,
        }));
      } else if (selectedIds?.length >= 2) {
        const sortedSelected = [...dimensionLagIDdata]?.sort((a, b) => a - b);
        const startIndex = sortedSelected?.findIndex(
          (item) => item['Lag ID'] === selectedLabels[0]
        );
        const endIndex = sortedSelected?.findIndex(
          (item) =>
            item['Lag ID'] === selectedLabels[selectedLabels?.length - 1]
        );
        const [start, end] =
          startIndex < endIndex
            ? [startIndex, endIndex]
            : [endIndex, startIndex];
        const rangeSelected = sortedSelected?.slice(start, end + 1);

        const selectedCycleData =
          rangeSelected?.length > 0 &&
          rangeSelected?.map((item) => ({
            _id: item?._id,
            lagID: item['Lag ID'],
          }));
        setSelectedOptions((prevState) => ({
          ...prevState,
          lagID: selectedCycleData,
        }));
      } else if (selectedIds.length === 1) {
        const singleSelection =
          data?.length > 0 &&
          data?.map((item) => ({
            _id: item?.value,
            lagID: item?.label,
          }));
        setSelectedOptions((prevState) => ({
          ...prevState,
          lagID: singleSelection,
        }));
      }
    } else {
      const selectedAttribute =
        data?.length > 0 &&
        data?.map((item) => {
          if (type === 'attributes') {
            const dataItem = findDiemnsion?.primaryKey?.find(
              (row) => row?._id === item?.value
            );
            return {
              attributeName: item?.label,
              attributeUID: item?.value,
              ...(dataItem && {
                isFixed: true,
              }),
            };
          } else if (type === 'scenario') {
            return {
              _id: item?.value,
              scenario: item?.label,
            };
          } else if (type === 'cycleId') {
            return {
              _id: item?.value,
              cyclePeriodicity: item?.label,
              isFixed: true,
            };
          } else if (type === 'lagID') {
            return {
              _id: item?.value,
              lagID: item?.label,
            };
          } else if (type === 'measures') {
            return {
              measureName: item?.label,
              measureUID: item?.value,
              enterpriseUID: item?.enterpriseUID,
              modelUID: item?.modelUID,
              baselevelUID: item?.baselevelUID,
            };
          } else if (type === 'yAxis') {
            return {
              measureName: item?.label,
              measureUID: item?.value,
            };
          } else if (type === 'xAxis') {
            return {
              attributeName: item?.label,
              attributeUID: item?.value,
            };
          }
          // else if (type === 'iterationID') {
          //   return {
          //     iterationID: item?.label,
          //     id: item?.value,
          //   };
          // } else if (type === 'iterationType') {
          //   return {
          //     iterationType: item?.label,
          //     id: item?.value,
          //   };
          // } else if (type === 'iterationDriver') {
          //   return {
          //     iterationDriver: item?.label,
          //     id: item?.value,
          //   };
          // }
          return null;
        });

      setSelectedOptions((prevState) => ({
        ...prevState,
        [type]: selectedAttribute,
      }));
    }
  };

  const handleChangePage = (e) => {
    const { name, value } = e.target;
    if (name === 'timePeriod') {
      const selectedData = timeDimensionData?.timeSchemaFields?.find(
        (data) => data._id === value
      );
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        [`timePeriodName`]: selectedData['Period Type'],
      }));
    } else if (name === 'widgetSubType') {
      setFormValues({ ...formValues, [name]: value });
      setSelectedOptions({
        attributes: [],
        validFrom: '',
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validation = () => {
    const findLagId = findMeasureLagId?.filter((row) =>
      row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
    );
    if (findLagId?.length > 0 && !selectedOptions?.cycleId) {
      toast.error('Please select cycle Id');
      return true;
    } else if (
      formValues?.widgetName === '' ||
      formValues?.widgetName === undefined
    ) {
      toast.error('Please enter widget name');
      return true;
    } else if (
      formValues?.widgetType === 'Worksheet' &&
      (formValues?.widgetGroup === '' || formValues?.widgetGroup === undefined)
    ) {
      toast.error('Please enter widget group');
      return true;
    } else if (
      formValues?.widgetType === 'Worksheet' &&
      (formValues?.widgetSubType === '' ||
        formValues?.widgetSubType === undefined)
    ) {
      toast.error('Please select widget type');
      return true;
    } else if (
      formValues?.widgetSubType === 'Dimension Data' &&
      (selectedOptions?.dimension === '' ||
        selectedOptions?.dimension === undefined)
    ) {
      toast.error('Please select dimension');
      return true;
    } else if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      !selectedOptions?.measures
    ) {
      toast.error('Please select measure');
      return true;
    } else if (!selectedOptions?.attributes) {
      toast.error('Please select attribute');
      return true;
    } else if (
      newFilter ? formValues?.filterName === '' : !selectedOptions?.filter
    ) {
      toast.error('Please select filter');
      return true;
    } else if (
      findLagId?.length > 0 &&
      (!selectedOptions?.lagID?.length || !selectedOptions?.lagID)
    ) {
      toast.error('Please select lag ID');
      return true;
    }
    return false;
  };

  function calculateN(index) {
    const remainder = index % 3;
    if (remainder === 1) {
      return 0;
    } else if (remainder === 2) {
      return 1.5;
    } else {
      return 3;
    }
  }

  const addNewItem = () => {
    const newItem = {
      x: layout?.length % calculateN(layout?.length) || 0,
      y: Math.floor(layout.length / 5),
      w: 3,
      h: 5,
      minW: 3,
      i: getId(),
      moved: false,
      static: false,
    };

    const updatedLayout = [...layout, newItem];
    dispatch(setLayout(updatedLayout));
  };

  const handleSubmitWorkbook = async () => {
    if (validation()) {
      return;
    } else {
      const findFilterName = filterDetail?.find(
        (item) => item?._id === selectedOptions?.filter
      );
      const body = {
        enterpriseUID: canvasFindData?.enterpriseUID?._id,
        modelUID: formValues?.modelUID,
        name: newFilter ? formValues?.filterName : findFilterName?.name,
        attributes: selectedAttributes?.map((item) => ({
          attributeUID: item?.attributes,
          values: Object.entries(item?.attValues)
            .filter(([key, value]) => value === true)
            .map(([key, value]) => key),
          operator: item?.operator,
        })),
      };
      const responce = await dispatch(
        createFilterDetail({
          payload: body,
          isEdit: !newFilter,
          selectedRows: selectedOptions?.filter,
        })
      );
      if (responce?.meta?.requestStatus === 'fulfilled') {
        const widgetkey =
          formValues?.widgetType === 'Worksheet' ? 'worksheet' : 'chart';
        const initialColors =
          selectedOptions?.measures?.length > 0 &&
          selectedOptions?.measures?.reduce((acc, item) => {
            if (item?.measureName) {
              acc[item.measureName] = formValues
                ? formValues[item.measureName]
                : 'rgba(0, 100, 200, 0.3)';
            }
            return acc;
          }, {});
        const payload = {
          widgetName: formValues?.widgetName,
          enterpriseUID: canvasFindData?.enterpriseUID?._id,
          modelUID: formValues?.modelUID,
          canvasUID: formValues?.canvasUID,
          pageUID: formValues?.pageUID,
          notes: formValues?.workbookNotes,
          widgetType: formValues?.widgetType,
          widgetGroup:
            formValues?.widgetType === 'Worksheet'
              ? formValues?.widgetGroup
              : formValues?.widgetName,
          ...(formValues?.widgetType === 'Worksheet' && {
            widgetSubType: formValues?.widgetSubType,
          }),
          ...(selectedOptions?.filter !== null && {
            // filter: selectedOptions?.filter,
            filterInfo: {
              filterRefID: responce?.payload?.data?._id,
              attributes: selectedAttributes?.map((item) => ({
                attributeUID: item?.attributes,
                values: Object.entries(item?.attValues)
                  .filter(([key, value]) => value === true)
                  .map(([key, value]) => key),
                operator: item?.operator,
              })),
            },
          }),
          [widgetkey]: {
            ...(formValues?.widgetSubType === 'Dimension Data' && {
              dimension: selectedOptions?.dimension,
            }),
            ...((formValues?.widgetSubType === 'Measure Data' ||
              formValues?.widgetType === 'Chart') &&
              selectedOptions?.measures?.length > 0 && {
              measures: selectedOptions?.measures?.map((item) => ({
                measureName: item?.measureName,
                measureUID: item?.measureUID,
                baselevelUID: item?.baselevelUID,
                enterpriseUID: item?.enterpriseUID,
                modelUID: item?.modelUID,
              })),
            }),
            ...(selectedOptions?.attributes?.length > 0 && {
              attributes: selectedOptions?.attributes?.map((item) => ({
                attributeName: item?.attributeName,
                attributeUID: item?.attributeUID,
              })),
            }),

            ...((formValues?.widgetSubType === 'Measure Data' ||
              formValues?.widgetType === 'Chart') &&
              timePeriodData?.length > 0 && {
              timePeriods: {
                periodType: formValues?.timePeriodName,
                periodValues: timePeriodData?.map((item) => {
                  return item['Time Period Name'];
                }),
                ...(selectedOptions?.startDate && {
                  from: moment(selectedOptions?.startDate)?.format(
                    'YYYY-MM-DD'
                  ),
                }),
                ...(selectedOptions?.endDate && {
                  to: moment(selectedOptions?.endDate)?.format('YYYY-MM-DD'),
                }),
              },
            }),
            ...(formValues?.widgetType === 'Chart' && {
              chartSettings: {
                type: formValues?.chartType,
                orientation: formValues?.orientation
                  ? formValues?.orientation
                  : 'vertical',
                ...((formValues?.chartType === 'arearange' ||
                  formValues?.chartType === 'spline' ||
                  formValues?.chartType === 'bar' ||
                  formValues?.chartType === 'area' ||
                  formValues?.chartType === 'scatter' ||
                  formValues?.chartType === 'areaspline' ||
                  formValues?.chartType === 'combination') && {
                  ...initialColors,
                }),
                ...(formValues?.chartType === 'arearange' && {
                  areaShadeColor: formValues?.areaShadeColor,
                }),
                ...(formValues?.chartType === 'combination' && {
                  measure: {
                    ...formValues?.measure,
                  },
                }),
              },
            }),
          },
          scenario:
            selectedOptions?.scenario?.length > 0 &&
            selectedOptions?.scenario?.map((item) => item?.scenario),
          ...(selectedOptions?.cycleId?.length > 0 &&
            findLagId?.length > 0 && {
            cycleID:
              selectedOptions?.cycleId?.length > 0 &&
              selectedOptions?.cycleId?.map((item) => item?.cyclePeriodicity),
          }),
          ...(selectedOptions?.lagID?.length > 0 &&
            findLagId?.length > 0 && {
            lagID:
              selectedOptions?.lagID?.length > 0 &&
              selectedOptions?.lagID?.map((item) => item?.lagID),
          }),
          coordinates: {
            x: 0,
            y: 0,
            w: 4,
            h: 5,
            i: 0,
            minW: 4,
            moved: false,
            static: false
          }
        };
        const result = await dispatch(
          createWorkBookDetail({
            payload,
            openModal,
            updatedId: finalData?.widgetUID?._id,
          })
        );
        if (result?.meta?.requestStatus === 'fulfilled') {
          const responce = await dispatch(createHomeWidgetDetail(result?.payload?.data?._id))
          if (responce?.meta?.requestStatus === 'fulfilled') {
            // setActiveTab({
            //   pageUID: result?.payload?.data?.pageUID,
            //   worksheetID: (
            //     result?.payload?.data?.worksheet || result?.payload?.data
            //   )?._id,
            // });
            dispatch(getWorkBookDetail());
            dispatch(getHomePageWidget())
            // processPageData(result?.payload?.data);
            dispatch(setIsAddWidgetsOpenModal(false));
            setSelectedAttributes([
              {
                attributes: '',
                operator: 'Equal To',
                attValues: {},
              },
            ]);
          } else if (responce?.meta?.requestStatus === 'rejected') {
            toast.error(responce?.error?.message);
          }
        } else if (result?.meta?.requestStatus === 'rejected') {
          toast.error(result?.error?.message);
        }
      } else if (responce?.meta?.requestStatus === 'rejected') {
        toast.error(responce?.error?.message);
      }
    }
  };

  const droppedFields =
    droppedLayout?.length > 0 && droppedLayout?.map((col) => col?.field);
  const data =
    droppedLayout?.length > 0
      ? pendingLayout?.filter((row) => !droppedFields?.includes(row.field))
      : pendingLayout?.filter((row) => !layoutData?.includes(row.field));

  useEffect(() => {
    const now = new Date();
    let startOfMonths;
    if (
      formValues?.timePeriodName === 'Month' ||
      formValues?.timePeriodName === 'Day' ||
      formValues?.timePeriodName === 'Quarter'
    ) {
      startOfMonths = moment(
        new Date(now.getFullYear(), now.getMonth(), 1)
      ).format('YYYY-MM-DD');
    }
    if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      formValues?.timePeriod &&
      findTimePeriod?.length > 0
    ) {
      const findvalidFrom =
        findTimePeriod?.length > 0 &&
        findTimePeriod?.find((item) => {
          const validStartFromDate = moment(item['Period Start']).format(
            'YYYY-MM-DD'
          );
          return startOfMonths === validStartFromDate;
        });
      setSelectedOptions({
        ...selectedOptions,
        validFrom: findvalidFrom && findvalidFrom['Time Period Name'],
        startDate: findvalidFrom && findvalidFrom['Period Start'],
      });
    }
  }, [formValues?.timePeriod, startOfMonth, endOfMonth]);

  const findEnterpriseModel = modalData?.filter((item) => item?.enterpriseUID?._id === user?.data?.enterprise?._id)
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999999]">
        <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-2xl max-h-[90vh] overflow-y-auto overflow-x-auto custom-scrollbar">
          <div className="p-0 pb-[17px]">General</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5">
            <div>
              <CommonInput
                name="widgetName"
                value={formValues?.widgetName || ''}
                label="Widget Name"
                onChange={handleChangePage}
                placeholder="Widget Name"
              />
            </div>

            <div>
              <CommonInput
                name="widgetName"
                value={formValues?.widgetType || 'Worksheet'}
                label="Widget Type"
                placeholder="Widget Type"
                readOnly
              />
            </div>

            <div>
              <CommonInput
                name="widgetGroup"
                value={formValues?.widgetGroup || ''}
                label="Widget Group"
                onChange={handleChangePage}
                placeholder="Widget Group"
              />
            </div>

            <div>
              <CommonSelect
                name="widgetSubType"
                label="Widget Sub-type"
                value={formValues?.widgetSubType || ''}
                onChange={handleChangePage}
                options={[
                  { value: 'Dimension Data', label: 'Dimension Data' },
                  { value: 'Measure Data', label: 'Measure Data' },
                ]}
              />
            </div>
          </div>
          <div className='mb-5'>
            <CommonSelect
              name="modelUID"
              label="Model"
              value={formValues?.modelUID || ''}
              onChange={handleChangePage}
              options={findEnterpriseModel?.map((item) => ({
                value: item.modelUID, label: item?.modelName
              }))}
            />
          </div>
          {formValues?.widgetSubType === "Measure Data" && (
            <MeasureDataFirst
              findMeasure={findMeasure}
              selectedOptions={selectedOptions}
              handleChangeAttribute={handleChangeAttribute}
              widgetType={formValues?.widgetType}
              canvasFindData={canvasFindData}
            />
          )}
          {formValues?.widgetSubType === "Dimension Data" && (
            <div className='mb-5'>
              <CommonSelect
                name="dimension"
                label="Dimension"
                value={selectedOptions?.dimension || ''}
                onChange={handleChangePage}
                options={(canvasFindData?.enterpriseUID?._id && formValues?.modelUID) ? dimensionData?.filter((item) => item?.enterpriseUID?._id === canvasFindData?.enterpriseUID?._id)
                  ?.filter((item) => item?.entermodelUID?.modelUID === formValues?.modelUID)
                  ?.map((item) => ({
                    value: item._id, label: item?.name
                  })) : []}
              />
            </div>
          )}
          <div className='mb-5'>
            <label
              htmlFor="attributes"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Attributes
            </label>
            <Select
              options={
                findDiemnsionAttribute?.length > 0
                  ? findDiemnsionAttribute?.map((item) => ({
                    value: item?._id,
                    label: item?.name,
                    isFixed: false,
                  }))
                  : []
              }
              value={
                selectedOptions?.attributes?.length > 0 &&
                selectedOptions?.attributes?.map((option) => ({
                  value: option?.attributeUID,
                  label: option?.attributeName,
                  isFixed: option.isFixed,
                }))
              }
              onChange={(data) => handleChangeAttribute(data, 'attributes')}
              placeholder="Select Attribute"
              isClearable={
                selectedOptions.attributes?.length > 0 &&
                selectedOptions?.attributes?.some((v) => v.isFixed)
              }
              isSearchable={true}
              isMulti
              className="text-black"
              styles={customStyles}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5">

            <div>
              <CommonSelect
                name="canvasUID"
                label="canvas"
                value={formValues?.canvasUID || ''}
                onChange={handleChangePage}
                options={(user?.data?.enterprise && formValues?.modelUID) ? canvasData?.filter((item) => item?.enterpriseUID?._id === user?.data?.enterprise?._id)
                  ?.filter((item) => item?.modelUID?.modelUID === formValues?.modelUID)
                  ?.map((item) => ({
                    value: item._id, label: item?.name
                  })) : []
                }
              />
            </div>
            <div>
              <CommonSelect
                name="pageUID"
                label="Page"
                value={formValues?.pageUID || ''}
                onChange={handleChangePage}
                options={pageData?.filter((item) => item?.canvasUID?._id === formValues?.canvasUID)
                  ?.map((item) => ({
                    value: item._id, label: item?.name
                  }))
                }
              />
            </div>
          </div>
          <div className="grid">
            <div>
              <MeasureDataSecond
                findTimePeriod={findTimePeriod}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                handleChangePage={handleChangePage}
                timeDimensionData={timeDimensionData}
                formValues={formValues}
                readOnly={false}
              />
            </div>
            <div>
              {(formValues?.widgetSubType === 'Measure Data' ||
                formValues?.widgetType === 'Chart') &&
                findLagId?.length > 0 && (
                  <>
                    <CycleIdData
                      formValues={formValues}
                      handleChangePage={handleChangePage}
                      findCycleIdMonth={findCycleIdMonth}
                      selectedOptions={selectedOptions}
                      handleChangeAttribute={handleChangeAttribute}
                      timeDimensionData={timeDimensionData}
                    />
                  </>
                )}
            </div>
            <div className="w-full p-0">
              <div className="grid grid-cols-12 gap-4 items-center">
                <div className="col-span-10">
                  <div className="w-full">
                    {newFilter ? (
                      <Input
                        id="filterName"
                        name="filterName"
                        className="w-full"
                        placeholder="Enter filter name"
                        value={formValues?.filterName}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <div className="relative w-full">
                        <label
                          htmlFor="filter"
                          className="block text-sm font-medium text-gray-700 mb-2"
                        >
                          Filter
                        </label>
                        <div className="relative">
                          <select
                            id="filter"
                            name="filter"
                            value={selectedOptions?.filter}
                            onChange={(selectedOption) => {
                              setSelectedOptions(prev => ({
                                ...prev,
                                filter: selectedOption.target.value
                              }));
                              const findFilter = filterEnterpriseDetail?.find((item) => item?._id === selectedOption.target.value)
                              setSelectedAttributes(findFilter?.attributes?.map((item) => ({
                                attributes: item?.attributeUID?._id,
                                operator: item?.operator,
                                attValues: item?.values?.reduce((acc, value) => {
                                  acc[value] = true;
                                  return acc;
                                }, {})
                              })))
                            }}
                            className={`
                              w-full 
                              px-3 
                              py-2 
                              border 
                              border-[#EAEAEA] 
                              rounded-lg 
                              text-sm 
                              shadow-sm
                              bg-white
                              appearance-none
                              focus:outline-none 
                              focus:shadow-custom-focus
                              disabled:bg-gray-50 
                              disabled:text-gray-500 
                              disabled:cursor-not-allowed
                            `}
                          >
                            <option selected>Select Filter</option>
                            {filterEnterpriseDetail?.map((option) => (
                              <option key={option._id} value={option._id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                            ▼
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-2 pt-6">
                  <Button
                    variant="default"
                    className="capitalize"
                    onClick={() => {
                      setNewFilter(true);
                      setSelectedOptions({
                        ...selectedOptions,
                        filter: ""
                      });
                      setSelectedAttributes([{
                        attributes: '',
                        operator: 'Equal To',
                        attValues: {},
                      }]);
                    }}
                  >
                    New
                  </Button>
                </div>

                <div className="col-span-12">
                  <button
                    className="px-4 py-2 text-blue-600 hover:text-blue-800 cursor-pointer"
                    onClick={() => {
                      setNewFilter(false);
                      setSelectedOptions({
                        ...selectedOptions,
                        filter: "",
                      });
                      setSelectedAttributes([{
                        attributes: '',
                        operator: 'Equal To',
                        attValues: {},
                      }]);
                      setFormValues({
                        ...formValues,
                        filterName: "",
                      });
                    }}
                  >
                    If User wants to use an existing filter, Please click here.
                  </button>
                </div>

                <div className="col-span-12">
                  <WidgetFilter
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    canvasFindData={canvasFindData}
                    openModalRow={openModalRow}
                    setOpenModalRow={setOpenModalRow}
                    attributeValueData={attributeValueData}
                    setAttributeValueData={setAttributeValueData}
                  />
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div className="w-full h-0.5 bg-blue-600 my-5" />
              <label className="block text-base mb-2.5 font-poppins">Scenario</label>
              <Select
                options={scenarioList?.map((item) => ({
                  value: item?._id,
                  label: item?.scenarioName,
                }))}
                value={selectedOptions?.scenario?.map((option) => ({
                  value: option?._id,
                  label: option?.scenario,
                }))}
                onChange={(data) => handleChangeAttribute(data, 'scenario')}
                placeholder="Select Scenario"
                isSearchable={true}
                isMulti
                className="text-black"
                styles={customStyles}
              />
            </div>

            {/* Notes Section */}
            <div className="mb-6">
              <label className="block text-base mb-2 font-poppins">Note</label>
              <input
                type="text"
                name="workbookNotes"
                value={formValues?.workbookNotes || ''}
                onChange={handleChangePage}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => {
                  dispatch(setIsAddWidgetsOpenModal(false));
                  setFormValues({});
                  setSelectedOptions({
                    attributes: [],
                    measure: [],
                  });
                  dispatch(setDropped([]));
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitWorkbook}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AddWidget;

const CommonInput = ({
  name,
  value,
  label,
  onChange,
  disabled = false,
  readOnly = false,
  style,
  placeholder = '',
  type = 'text',
}) => {
  return (
    <div className="relative w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-2"
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        className={`
          w-full 
          px-3 
          py-2 
          border 
          border-[#EAEAEA] 
          rounded-lg 
          text-sm 
          shadow-sm
          placeholder:text-gray-400
          focus:outline-none 
          focus:shadow-custom-focus
          disabled:bg-gray-50 
          disabled:text-gray-500 
          disabled:cursor-not-allowed
          ${readOnly ? 'bg-gray-100 text-gray-600 cursor-default' : ''}
        `}
        style={style}
      />
    </div>
  );
};

const CommonSelect = ({
  name,
  value,
  label,
  onChange,
  options = [],
  disabled = false,
  readOnly = false,
  style,
}) => {
  return (
    <div className="relative w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-2"
      >
        {label}
      </label>
      <div className="relative">
        <select
          id={name}
          name={name}
          value={value}
          onChange={(e) => {
            if (e.target.value !== "") {
              onChange(e);
            }
          }}
          disabled={disabled || readOnly}
          className={`
            w-full 
            px-3 
            py-2 
            border 
            border-[#EAEAEA] 
            rounded-lg 
            text-sm 
            shadow-sm
            bg-white
            appearance-none
            focus:outline-none 
            focus:shadow-custom-focus
            disabled:bg-gray-50 
            disabled:text-gray-500 
            disabled:cursor-not-allowed
            ${readOnly ? 'bg-gray-100 text-gray-600 cursor-default' : ''}
          `}
          style={style}
        >
          <option selected>Select {label}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
          ▼
        </div>
      </div>
    </div>
  );
};
