import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteProcessList, getExcuteProcess, getProcessList, terminateProcessList } from '../../store/ApiSlice/processSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select, TextField, Typography } from '@mui/material';
import useSocket from '../../socket/SocketComponent';
import moment from 'moment';
import * as ExcelJS from 'exceljs';


const RunProcess = ({
    selectedRows,
    setSelectedRows,
    setIsRunProcess,
    isRunProcess,
    updatedId,
    isExcuteProcess
}) => {
    const today = new Date().toISOString().split('T')[0];
    const userIdData = localStorage.getItem("accessToken");
    const user = JSON.parse(userIdData)
    const { userOnline, measureDataResult, excuteProcessDatas, processStatus } = useSocket();
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({})
    const handleCloseModal = () => {
        setIsRunProcess(false);
        setFormValues({})
    };
    const convertTo12HourFormat = (time) => {
        if (!time) return ''; // Return an empty string if time is undefined or empty

        let [hours, minutes, seconds] = time?.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12; // Convert to 12-hour format

        return `${hours}:${minutes}:${seconds} ${period}`;
    };
    const formatDateTime = (dateTime) => {
        return moment(dateTime, 'h:mm:ss A')?.format('HH:mm:ss A');
    };
    const handleTerminate = async () => {
        const userId = localStorage.getItem("accessToken");
        const fomatTime = convertTo12HourFormat(formValues?.selectTime)
        const formattedDateTime = formatDateTime(fomatTime);
        const body = {
            processUID: updatedId,
            whenExecuted: isExcuteProcess === "Immediate" ? "Immediate" : "Later",
            ...(isExcuteProcess === "Later" && {
                executedOn: `${moment(formValues?.selectDate)?.format("DD-MM-YYYY")} ${formattedDateTime}`
            })
        }

        const response = await dispatch(getExcuteProcess(body))
        if (response?.meta?.requestStatus === "fulfilled") {
            setIsRunProcess(false);
            // toast.success(response?.payload?.message);
            processStatus(user?.userId);
            dispatch(getProcessList());
            setFormValues({})
            handleCloseModal()
        } else if (response?.meta?.requestStatus === "rejected") {
            toast.warn(
                response?.payload?.data?.error
                    ? response?.payload?.data?.error
                    : "Process is not executing."
            );
        }
    }

    useEffect(() => {
        if (measureDataResult?.downloadedDatas?.length > 0) {
            generateExcel(measureDataResult);
        }
    }, [measureDataResult]);


    const generateExcel = (datas) => {
        const downloadedDatas = datas?.downloadedDatas || {};
        const workbook = new ExcelJS.Workbook();
        Object.entries(downloadedDatas).forEach(([fieldName, fieldData]) => {
            Object.entries(fieldData).forEach(([sheet, value]) => {
                const worksheet = workbook.addWorksheet(sheet);

                if (value.length > 0) {
                    // Add headers
                    const header = Object.keys(value[0]).filter(key => key !== "_id");
                    worksheet.addRow(header);

                    // Add rows
                    value.forEach(obj => {
                        const row = header.map(col => obj[col] !== undefined ? obj[col] : '');
                        worksheet.addRow(row);
                    });
                }
            });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const fileName = 'measure_data.xlsx';
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };


    const renderModalContent = () => {
        return (
            <>
                <DialogTitle sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }} >Run {isExcuteProcess === "Immediate" ? `Immediate` : "Schedule For Later"} Process</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    {isExcuteProcess === "Immediate" ?
                        (
                            <FormControlLabel
                                control={
                                    <Radio
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        id="runImmediately"
                                        name="runImmediately"
                                        checked={true}
                                        style={{
                                            padding: "0px !important",
                                            height: "54px",
                                            margin: "0px 19px"
                                        }}
                                    />
                                }
                                label="Run immediately"
                            />
                        ) :
                        (
                            <Grid container spacing={2} sx={{ paddingTop: "24px" }}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="selectDate"
                                            label="Select Date"
                                            variant="outlined"
                                            fullWidth
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                min: today,
                                            }}
                                            value={formValues?.selectDate}
                                            onChange={(e) => setFormValues({ ...formValues, selectDate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="selectTime"
                                            label="Select Time"
                                            variant="outlined"
                                            fullWidth
                                            type="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 1, // Allow input of seconds
                                            }}
                                            value={formValues?.selectTime}
                                            placeholder="HH:MM AM/PM"
                                            onChange={(e) => {
                                                setFormValues({ ...formValues, selectTime: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )
                    }


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} sx={{fontFamily: "'Poppins', sans-serif", textTransform: "capitalize"}}>Cancel</Button>
                    <Button color="primary" onClick={handleTerminate} sx={{fontFamily: "'Poppins', sans-serif", textTransform: "capitalize"}}>
                        Proceed
                    </Button>
                </DialogActions>
            </>
        );
    };

    return (
        <>
            <Dialog open={isRunProcess} onClose={handleCloseModal}>
                {renderModalContent()}
            </Dialog>
        </>
    )
}

export default RunProcess
