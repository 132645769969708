
export const columnNames = [
"Attribute name",
"Type",
"Length",
"Notes",
"ChangedOn",
"CreatedOn",
"enterpriseName",
"modelName"
] 
export const options = ["SIMPLE", "COMPOUND", "REFERENCE", "TIME", "LEVEL"]

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
