import React, { useEffect, useMemo, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import moment from "moment";
import { ColumnDirective, ColumnMenu, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Edit, Group } from '@syncfusion/ej2-react-grids';
import { Filter, Inject, Page, Sort, Resize } from '@syncfusion/ej2-react-grids';
import { createPortal } from "react-dom";

const EnterpriseMaterialList = ({
  selectedRows,
  data,
  handleTableRowSelect,
  setModalOpenDelete,
  handleCreateUpdate,
  setAttriId,
}) => {
  const userData = JSON.parse(localStorage.getItem("accessToken"))
  const [openedMenus, setOpenedMenus] = useState({});

  const handleClick = (rowId) => (event) => {
    event.stopPropagation();
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  };

  const handleMenuClick = (id) => {
    setOpenedMenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleCloseMenu = (id) => {
    setOpenedMenus((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const [targetElement, setTargetElement] = useState(null);

  useEffect(() => {
    // Set the target element after the component mounts
    const element = document.getElementById('portal-target');
    if (element) setTargetElement(element);
  }, []); // Ensure this element exists in your HTML

  const columns = useMemo(() => [
    { field: 'enterpriseName', headerText: 'Enterprise Name', textAlign: 'Left' },
    { field: 'type', headerText: 'Type', textAlign: 'Left' },
    { field: 'status', headerText: 'Status', textAlign: 'Left' },
    { field: 'notes', headerText: 'Notes', textAlign: 'Left' },
    {
      field: 'validFrom',
      headerText: 'Valid From',
      textAlign: 'Left',
      template: (props) => (
        <p>{moment(props.validFrom).format('DD-MM-YYYY')}</p>
      )
    },
    {
      field: 'validTo',
      headerText: 'Valid To',
      textAlign: 'Left',
      template: (props) => (
        <p>{moment(props.validTo).format('DD-MM-YYYY')}</p>
      )
    },
    {
      field: 'view',
      headerText: '',
      template: (props) => {
        return (
          <>
            <button
              className="text-gray-500 !text-right hover:text-gray-900 focus:outline-none"
              onClick={() => handleMenuClick(props._id)}
            >
              <MdMoreVert />
            </button>
            {openedMenus[props._id] && targetElement && createPortal(
              <div
                className="absolute mt-2 z-[999] right-[181px] top-[239px] bg-white shadow-lg rounded-md border border-gray-200 w-48"
                onClick={(e) => e.stopPropagation()} // Prevent click from closing the menu
              >
                <ul className="py-2 text-sm font-medium">
                  <li
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCreateUpdate(true, props._id); // Handle edit action
                      handleTableRowSelect("", props);
                      handleMenuClick(props._id)
                    }}
                  >
                    Edit Details
                  </li>
                  <li
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      setModalOpenDelete(true);
                      setAttriId(props._id);
                      handleMenuClick(props._id) // Handle delete action
                    }}
                  >
                    Delete
                  </li>
                </ul>
              </div>, targetElement
            )}
          </>
        );
      }
    }
  ], [openedMenus, handleCreateUpdate, handleTableRowSelect, setModalOpenDelete, setAttriId])

  const groupOptions = { showGroupedColumn: false };
  const filterSettings = { type: 'CheckBox' };

  return (
    <div id="portal-target">
      <GridComponent
        dataSource={data} allowPaging={true} allowGrouping={false} allowSorting={true} showColumnMenu={true} allowFiltering={true} groupSettings={groupOptions} filterSettings={filterSettings}
      >
        <ColumnsDirective>
          {columns.map((col, index) => (
            <ColumnDirective
              key={index}
              field={col.field}
              headerText={col.headerText}
              textAlign={col.textAlign}
              template={col.template}
              allowSorting={true}
            />
          ))}
        </ColumnsDirective>
        <Inject services={[Sort, ColumnMenu, Filter, Page, Group, Edit, Resize]} />
      </GridComponent>
    </div>
  );
};

export default EnterpriseMaterialList;
