import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../helper/constants";
import axios from "axios";


const initialState = {
    loading: false,
    error: null,
    success: false,
    profileData: [],
    timeData: [],
    profileMeasure: [],
}

export const getProfilePrimaryTimeDimension = createAsyncThunk(
    'profile/getProfilePrimaryTimeDimension',
    async ({ formValues }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/dimensions/primaryTimeDimension/${formValues?.enterpriseUID}/${formValues?.modelUID}`, config);
        return response.data;
    }
);

export const getProfileData = createAsyncThunk(
    'profile/getProfileData',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/forecast_profile`, config);
        return response.data;
    }
);

export const getProfileMeasure = createAsyncThunk(
    'profile/getProfileMeasure',
    async ({ formValues }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/measure/list/${formValues?.enterpriseUID}/${formValues?.modelUID}`, config);
        return response.data;
    }
);

export const createProfile = createAsyncThunk(
    'profile/createProfile',
    async ({ payload, isEdit, selectedRows }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/forecast_profile`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/forecast_profile/${selectedRows}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteProfile = createAsyncThunk('profile/deleteProfile',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/forecast_profile/${attriId}`, config)
            return responce
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

export const copyProfile = createAsyncThunk(
    'profile/copyProfile',
    async ({ sourceProfileUID, name }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        try {
            const response = await axios.post(
                `${API_BASE_URL}/forecast_profile/copy`,
                { sourceProfileUID, name },
                config
            );
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfilePrimaryTimeDimension.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getProfilePrimaryTimeDimension.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.timeData = action?.payload?.data;
            })
            .addCase(getProfilePrimaryTimeDimension.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getProfileData.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getProfileData.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.profileData = action?.payload?.data;
            })
            .addCase(getProfileData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getProfileMeasure.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getProfileMeasure.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.profileMeasure = action?.payload?.data?.response;
            })
            .addCase(getProfileMeasure.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(createProfile.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(deleteProfile.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(copyProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(copyProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(copyProfile.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            });
    }
})

export default profileSlice.reducer;