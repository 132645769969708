export const dataSource = {
    "data": [
        {
            "university": "Massachusetts Institute of Technology (MIT)",
            "year": 2017,
            "rank_display": 1,
            "score": 100,
            "link": "https://www.topuniversities.com/universities/massachusetts-institute-technology-mit",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/massachusetts-institute-of-technology-mit_410_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 3730,
            "size": "M",
            "faculty_count": 3065
        },
        {
            "university": "Stanford University",
            "year": 2017,
            "rank_display": 2,
            "score": 98.7,
            "link": "https://www.topuniversities.com/universities/stanford-university",
            "country": "United States",
            "city": "Stanford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stanford-university_573_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3879,
            "size": "L",
            "faculty_count": 4725
        },
        {
            "university": "Harvard University",
            "year": 2017,
            "rank_display": 3,
            "score": 98.3,
            "link": "https://www.topuniversities.com/universities/harvard-university",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/harvard-university_253_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5877,
            "size": "L",
            "faculty_count": 4646
        },
        {
            "university": "University of Cambridge",
            "year": 2017,
            "rank_display": 4,
            "score": 97.2,
            "link": "https://www.topuniversities.com/universities/university-cambridge",
            "country": "United Kingdom",
            "city": "Cambridge",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cambridge_95_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 4,
            "international_students": 7925,
            "size": "L",
            "faculty_count": 5800
        },
        {
            "university": "California Institute of Technology (Caltech)",
            "year": 2017,
            "rank_display": 5,
            "score": 96.9,
            "link": "https://www.topuniversities.com/universities/california-institute-technology-caltech",
            "country": "United States",
            "city": "Pasadena",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/california-institute-of-technology-caltech_94_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 692,
            "size": "S",
            "faculty_count": 968
        },
        {
            "university": "University of Oxford",
            "year": 2017,
            "rank_display": 6,
            "score": 96.8,
            "link": "https://www.topuniversities.com/universities/university-oxford",
            "country": "United Kingdom",
            "city": "Oxford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oxford_478_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 8442,
            "size": "L",
            "faculty_count": 6708
        },
        {
            "university": "UCL",
            "year": 2017,
            "rank_display": 7,
            "score": 95.6,
            "link": "https://www.topuniversities.com/universities/ucl",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ucl_592560cf2aeae70239af4bf1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 21824,
            "size": "XL",
            "faculty_count": 7195
        },
        {
            "university": "ETH Zurich - Swiss Federal Institute of Technology",
            "year": 2017,
            "rank_display": 8,
            "score": 94.2,
            "link": "https://www.topuniversities.com/universities/eth-zurich-swiss-federal-institute-technology",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eth-zurich-swiss-federal-institute-of-technology_201_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7733,
            "size": "L",
            "faculty_count": 2719
        },
        {
            "university": "Imperial College London",
            "year": 2017,
            "rank_display": 9,
            "score": 94.1,
            "link": "https://www.topuniversities.com/universities/imperial-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/imperial-college-london_592560cf2aeae70239af4be8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11143,
            "size": "L",
            "faculty_count": 8000
        },
        {
            "university": "University of Chicago",
            "year": 2017,
            "rank_display": 10,
            "score": 93,
            "link": "https://www.topuniversities.com/universities/university-chicago",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chicago_120_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4696,
            "size": "L",
            "faculty_count": 2703
        },
        {
            "university": "Princeton University",
            "year": 2017,
            "rank_display": 11,
            "score": 92.8,
            "link": "https://www.topuniversities.com/universities/princeton-university",
            "country": "United States",
            "city": "Princeton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/princeton-university_508_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1912,
            "size": "M",
            "faculty_count": 1050
        },
        {
            "university": "National University of Singapore (NUS)",
            "year": 2017,
            "rank_display": 12,
            "score": 91.5,
            "link": "https://www.topuniversities.com/universities/national-university-singapore-nus",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-singapore-nus_443_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7551,
            "size": "XL",
            "faculty_count": 4288
        },
        {
            "university": "Nanyang Technological University, Singapore (NTU)",
            "year": 2017,
            "rank_display": 13,
            "score": 91.4,
            "link": "https://www.topuniversities.com/universities/nanyang-technological-university-singapore-ntu",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanyang-technological-university-singapore-ntu_592560cf2aeae70239af4c32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6091,
            "size": "L",
            "faculty_count": 3812
        },
        {
            "university": "EPFL",
            "year": 2017,
            "rank_display": 14,
            "score": 91.1,
            "link": "https://www.topuniversities.com/universities/epfl",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/epfl-ecole-polytechnique-federale-de-lausanne_592560cf2aeae70239af4b34_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6426,
            "size": "M",
            "faculty_count": 1767
        },
        {
            "university": "Yale University",
            "year": 2017,
            "rank_display": 15,
            "score": 90.9,
            "link": "https://www.topuniversities.com/universities/yale-university",
            "country": "United States",
            "city": "New Haven",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/yale-university_684_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 2537,
            "size": "M",
            "faculty_count": 5391
        },
        {
            "university": "Cornell University",
            "year": 2017,
            "rank_display": 16,
            "score": 90.1,
            "link": "https://www.topuniversities.com/universities/cornell-university",
            "country": "United States",
            "city": "Ithaca",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/cornell-university_143_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Johns Hopkins University",
            "year": 2017,
            "rank_display": 17,
            "score": 89.3,
            "link": "https://www.topuniversities.com/universities/johns-hopkins-university",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/johns-hopkins-university_305_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 5070,
            "size": "L",
            "faculty_count": 4855
        },
        {
            "university": "University of Pennsylvania",
            "year": 2017,
            "rank_display": 18,
            "score": 89,
            "link": "https://www.topuniversities.com/universities/university-pennsylvania",
            "country": "United States",
            "city": "Philadelphia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pennsylvania_495_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4636,
            "size": "L",
            "faculty_count": 5154
        },
        {
            "university": "The University of Edinburgh",
            "year": 2017,
            "rank_display": 19,
            "score": 88.9,
            "link": "https://www.topuniversities.com/universities/university-edinburgh",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-edinburgh_180_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 14637,
            "size": "XL",
            "faculty_count": 4832
        },
        {
            "university": "Columbia University",
            "year": 2017,
            "rank_display": 20,
            "score": 88.6,
            "link": "https://www.topuniversities.com/universities/columbia-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/columbia-university_138_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 11252,
            "size": "L",
            "faculty_count": 7087
        },
        {
            "university": "King's College London",
            "year": 2017,
            "rank_display": 21,
            "score": 87.7,
            "link": "https://www.topuniversities.com/universities/kings-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kings-college-london_357_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 15075,
            "size": "L",
            "faculty_count": 4216
        },
        {
            "university": "The Australian National University",
            "year": 2017,
            "rank_display": 22,
            "score": 87.3,
            "link": "https://www.topuniversities.com/universities/australian-national-university",
            "country": "Australia",
            "city": "Canberra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-australian-national-university_40_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6496,
            "size": "L",
            "faculty_count": 1763
        },
        {
            "university": "University of Michigan-Ann Arbor",
            "year": 2017,
            "rank_display": 23,
            "score": 86.4,
            "link": "https://www.topuniversities.com/universities/university-michigan-ann-arbor",
            "country": "United States",
            "city": "Ann Arbor",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-michigan_403_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 7341,
            "size": "XL",
            "faculty_count": 7132
        },
        {
            "university": "Tsinghua University",
            "year": 2017,
            "rank_display": 24,
            "score": 86,
            "link": "https://www.topuniversities.com/universities/tsinghua-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tsinghua-university_626_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5420,
            "size": "XL",
            "faculty_count": 6174
        },
        {
            "university": "Duke University",
            "year": 2017,
            "rank_display": 24,
            "score": 86,
            "link": "https://www.topuniversities.com/universities/duke-university",
            "country": "United States",
            "city": "Durham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/duke-university_168_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3445,
            "size": "L",
            "faculty_count": 5673
        },
        {
            "university": "Northwestern University",
            "year": 2017,
            "rank_display": 26,
            "score": 85.7,
            "link": "https://www.topuniversities.com/universities/northwestern-university",
            "country": "United States",
            "city": "Evanston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northwestern-university_592560cf2aeae70239af4c50_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3846,
            "size": "L",
            "faculty_count": 3652
        },
        {
            "university": "The University of Hong Kong",
            "year": 2017,
            "rank_display": 27,
            "score": 85.4,
            "link": "https://www.topuniversities.com/universities/university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-hong-kong_268_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 8311,
            "size": "L",
            "faculty_count": 2944
        },
        {
            "university": "University of California, Berkeley (UCB)",
            "year": 2017,
            "rank_display": 28,
            "score": 85.2,
            "link": "https://www.topuniversities.com/universities/university-california-berkeley-ucb",
            "country": "United States",
            "city": "Berkeley",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-berkeley-ucb_84_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 9428,
            "size": "XL",
            "faculty_count": 3701
        },
        {
            "university": "The University of Manchester",
            "year": 2017,
            "rank_display": 29,
            "score": 84.8,
            "link": "https://www.topuniversities.com/universities/university-manchester",
            "country": "United Kingdom",
            "city": "Manchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-manchester_389_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 16079,
            "size": "XL",
            "faculty_count": 4889
        },
        {
            "university": "McGill University",
            "year": 2017,
            "rank_display": 30,
            "score": 84.1,
            "link": "https://www.topuniversities.com/universities/mcgill-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcgill-university_592560cf2aeae70239af4c10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 10827,
            "size": "L",
            "faculty_count": 3876
        },
        {
            "university": "University of California, Los Angeles (UCLA)",
            "year": 2017,
            "rank_display": 31,
            "score": 84,
            "link": "https://www.topuniversities.com/universities/university-california-los-angeles-ucla",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-los-angeles-ucla_87_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6140,
            "size": "XL",
            "faculty_count": 4487
        },
        {
            "university": "University of Toronto",
            "year": 2017,
            "rank_display": 32,
            "score": 83.8,
            "link": "https://www.topuniversities.com/universities/university-toronto",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-toronto_619_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 8,
            "international_students": 27536,
            "size": "XL",
            "faculty_count": 9881
        },
        {
            "university": "Ecole normale supérieure, Paris",
            "year": 2017,
            "rank_display": 33,
            "score": 79.9,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-paris",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-paris_176_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 374,
            "size": "S",
            "faculty_count": 178
        },
        {
            "university": "The University of Tokyo",
            "year": 2017,
            "rank_display": 34,
            "score": 82.6,
            "link": "https://www.topuniversities.com/universities/university-tokyo",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tokyo_615_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3983,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Seoul National University",
            "year": 2017,
            "rank_display": 35,
            "score": 82.1,
            "link": "https://www.topuniversities.com/universities/seoul-national-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/graduate-school-of-business-seoul-national-university_553_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1914,
            "size": "L",
            "faculty_count": 3859
        },
        {
            "university": "The Hong Kong University of Science and Technology",
            "year": 2017,
            "rank_display": 36,
            "score": 81.8,
            "link": "https://www.topuniversities.com/universities/hong-kong-university-science-technology",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-university-of-science-and-technology_269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3033,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "Kyoto University",
            "year": 2017,
            "rank_display": 37,
            "score": 81.7,
            "link": "https://www.topuniversities.com/universities/kyoto-university",
            "country": "Japan",
            "city": "Kyoto",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyoto-university_328_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2863,
            "size": "L",
            "faculty_count": 3911
        },
        {
            "university": "The London School of Economics and Political Science (LSE)",
            "year": 2017,
            "rank_display": 37,
            "score": 81.7,
            "link": "https://www.topuniversities.com/universities/london-school-economics-political-science-lse",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/london-school-of-economics-and-political-science-lse_362_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8274,
            "size": "M",
            "faculty_count": 1172
        },
        {
            "university": "Peking University",
            "year": 2017,
            "rank_display": 39,
            "score": 81.3,
            "link": "https://www.topuniversities.com/universities/peking-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/peking-university_50_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5436,
            "size": "XL",
            "faculty_count": 5302
        },
        {
            "university": "University of California, San Diego (UCSD)",
            "year": 2017,
            "rank_display": 40,
            "score": 80.6,
            "link": "https://www.topuniversities.com/universities/university-california-san-diego-ucsd",
            "country": "United States",
            "city": "San Diego",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-san-diego-ucsd_89_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 8567,
            "size": "XL",
            "faculty_count": 4545
        },
        {
            "university": "University of Bristol",
            "year": 2017,
            "rank_display": 41,
            "score": 79.6,
            "link": "https://www.topuniversities.com/universities/university-bristol",
            "country": "United Kingdom",
            "city": "Bristol",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bristol_69_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 7483,
            "size": "L",
            "faculty_count": 20311
        },
        {
            "university": "The University of Melbourne",
            "year": 2017,
            "rank_display": 42,
            "score": 79.5,
            "link": "https://www.topuniversities.com/universities/university-melbourne",
            "country": "Australia",
            "city": "Parkville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-melbourne_399_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 19,
            "international_students": 21406,
            "size": "S",
            "faculty_count": 4835
        },
        {
            "university": "Fudan University",
            "year": 2017,
            "rank_display": 43,
            "score": 79.4,
            "link": "https://www.topuniversities.com/universities/fudan-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/fudan-university_218_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5322,
            "size": "L",
            "faculty_count": 4526
        },
        {
            "university": "The Chinese University of Hong Kong (CUHK)",
            "year": 2017,
            "rank_display": 44,
            "score": 78.1,
            "link": "https://www.topuniversities.com/universities/chinese-university-hong-kong-cuhk",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-chinese-university-of-hong-kong-cuhk_123_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6062,
            "size": "L",
            "faculty_count": 2201
        },
        {
            "university": "University of British Columbia",
            "year": 2017,
            "rank_display": 45,
            "score": 78,
            "link": "https://www.topuniversities.com/universities/university-british-columbia",
            "country": "Canada",
            "city": "Vancouver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-british-columbia_70_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 15399,
            "size": "XL",
            "faculty_count": 6201
        },
        {
            "university": "The University of Sydney",
            "year": 2017,
            "rank_display": 46,
            "score": 77.9,
            "link": "https://www.topuniversities.com/universities/university-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sydney_592560cf2aeae70239af4cd0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 25940,
            "size": "XL",
            "faculty_count": 3571
        },
        {
            "university": "New York University (NYU)",
            "year": 2017,
            "rank_display": 46,
            "score": 77.9,
            "link": "https://www.topuniversities.com/universities/new-york-university-nyu",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/new-york-university-nyu_448_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 16218,
            "size": "XL",
            "faculty_count": 7874
        },
        {
            "university": "KAIST - Korea Advanced Institute of Science & Technology",
            "year": 2017,
            "rank_display": 46,
            "score": 77.9,
            "link": "https://www.topuniversities.com/universities/kaist-korea-advanced-institute-science-technology",
            "country": "South Korea",
            "city": "Daejeon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kaist-korea-advanced-institute-of-science-technology_324_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 869,
            "size": "M",
            "faculty_count": 1307
        },
        {
            "university": "The University of New South Wales (UNSW Sydney)",
            "year": 2017,
            "rank_display": 49,
            "score": 77.8,
            "link": "https://www.topuniversities.com/universities/university-new-south-wales-unsw-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-new-south-wales-unsw-sydney_447_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 21603,
            "size": "XL",
            "faculty_count": 3219
        },
        {
            "university": "Brown University",
            "year": 2017,
            "rank_display": 49,
            "score": 77.8,
            "link": "https://www.topuniversities.com/universities/brown-university",
            "country": "United States",
            "city": "Providence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brown-university_72_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2002,
            "size": "M",
            "faculty_count": 1389
        },
        {
            "university": "The University of Queensland",
            "year": 2017,
            "rank_display": 51,
            "score": 77.6,
            "link": "https://www.topuniversities.com/universities/university-queensland",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-queensland_515_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 22241,
            "size": "XL",
            "faculty_count": 3513
        },
        {
            "university": "The University of Warwick",
            "year": 2017,
            "rank_display": 51,
            "score": 77.6,
            "link": "https://www.topuniversities.com/universities/university-warwick",
            "country": "United Kingdom",
            "city": "Coventry",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-warwick_664_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10459,
            "size": "L",
            "faculty_count": 2634
        },
        {
            "university": "University of Wisconsin-Madison",
            "year": 2017,
            "rank_display": 53,
            "score": 75.7,
            "link": "https://www.topuniversities.com/universities/university-wisconsin-madison",
            "country": "United States",
            "city": "Madison",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wisconsin-madison_678_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5737,
            "size": "XL",
            "faculty_count": 5166
        },
        {
            "university": "Ecole Polytechnique",
            "year": 2017,
            "rank_display": 53,
            "score": 69.9,
            "link": "https://www.topuniversities.com/universities/ecole-polytechnique",
            "country": "France",
            "city": "Palaiseau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-polytechnique_592560cf2aeae70239af4b36_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1248,
            "size": "S",
            "faculty_count": 633
        },
        {
            "university": "City University of Hong Kong",
            "year": 2017,
            "rank_display": 55,
            "score": 75.4,
            "link": "https://www.topuniversities.com/universities/city-university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-hong-kong_592560cf2aeae70239af4b05_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5331,
            "size": "M",
            "faculty_count": 1483
        },
        {
            "university": "Tokyo Institute of Technology (Tokyo Tech)",
            "year": 2017,
            "rank_display": 56,
            "score": 74.9,
            "link": "https://www.topuniversities.com/universities/tokyo-institute-technology-tokyo-tech",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-institute-of-technology_592560cf2aeae70239af4ceb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1622,
            "size": "M",
            "faculty_count": 1492
        },
        {
            "university": "University of Amsterdam",
            "year": 2017,
            "rank_display": 57,
            "score": 74.3,
            "link": "https://www.topuniversities.com/universities/university-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-amsterdam_18_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7969,
            "size": "XL",
            "faculty_count": 6000
        },
        {
            "university": "Carnegie Mellon University",
            "year": 2017,
            "rank_display": 58,
            "score": 74.2,
            "link": "https://www.topuniversities.com/universities/carnegie-mellon-university",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/carnegie-mellon-university_101_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5739,
            "size": "L",
            "faculty_count": 1534
        },
        {
            "university": "University of Washington",
            "year": 2017,
            "rank_display": 59,
            "score": 73.9,
            "link": "https://www.topuniversities.com/universities/university-washington",
            "country": "United States",
            "city": "Seattle",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-washington_592560cf2aeae70239af4d1e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 7362,
            "size": "XL",
            "faculty_count": 2796
        },
        {
            "university": "Technical University of Munich",
            "year": 2017,
            "rank_display": 60,
            "score": 72.4,
            "link": "https://www.topuniversities.com/universities/technical-university-munich",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-munich_599_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11447,
            "size": "XL",
            "faculty_count": 6663
        },
        {
            "university": "Shanghai Jiao Tong University",
            "year": 2017,
            "rank_display": 61,
            "score": 72.2,
            "link": "https://www.topuniversities.com/universities/shanghai-jiao-tong-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-jiao-tong-university_556_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6371,
            "size": "XL",
            "faculty_count": 4386
        },
        {
            "university": "Delft University of Technology",
            "year": 2017,
            "rank_display": 62,
            "score": 72.1,
            "link": "https://www.topuniversities.com/universities/delft-university-technology",
            "country": "Netherlands",
            "city": "Delft",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/delft-university-of-technology_155_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6104,
            "size": "L",
            "faculty_count": 2041
        },
        {
            "university": "Osaka University",
            "year": 2017,
            "rank_display": 63,
            "score": 71.7,
            "link": "https://www.topuniversities.com/universities/osaka-university",
            "country": "Japan",
            "city": "Osaka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/osaka-university_472_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2268,
            "size": "L",
            "faculty_count": 3127
        },
        {
            "university": "University of Glasgow",
            "year": 2017,
            "rank_display": 63,
            "score": 71.7,
            "link": "https://www.topuniversities.com/universities/university-glasgow",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-glasgow_592560cf2aeae70239af4b6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 11065,
            "size": "L",
            "faculty_count": 3314
        },
        {
            "university": "Monash University",
            "year": 2017,
            "rank_display": 65,
            "score": 71.6,
            "link": "https://www.topuniversities.com/universities/monash-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/monash-university_412_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 31049,
            "size": "XL",
            "faculty_count": 3852
        },
        {
            "university": "University of Illinois at Urbana-Champaign",
            "year": 2017,
            "rank_display": 66,
            "score": 71.4,
            "link": "https://www.topuniversities.com/universities/university-illinois-urbana-champaign",
            "country": "United States",
            "city": "Champaign",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-at-urbana-champaign_2090_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 9987,
            "size": "XL",
            "faculty_count": 2748
        },
        {
            "university": "University of Texas at Austin",
            "year": 2017,
            "rank_display": 67,
            "score": 70.7,
            "link": "https://www.topuniversities.com/universities/university-texas-austin",
            "country": "United States",
            "city": "Austin",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-at-austin_604_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 4953,
            "size": "XL",
            "faculty_count": 2887
        },
        {
            "university": "Ludwig-Maximilians-Universität München",
            "year": 2017,
            "rank_display": 68,
            "score": 70.2,
            "link": "https://www.topuniversities.com/universities/ludwig-maximilians-universitat-munchen",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ludwig-maximilians-universitt-mnchen_420_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5958,
            "size": "XL",
            "faculty_count": 3914
        },
        {
            "university": "National Taiwan University (NTU)",
            "year": 2017,
            "rank_display": 68,
            "score": 70.2,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-ntu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-ntu_592560cf2aeae70239af4c3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5058,
            "size": "L",
            "faculty_count": 2939
        },
        {
            "university": "University of Copenhagen",
            "year": 2017,
            "rank_display": 68,
            "score": 70.2,
            "link": "https://www.topuniversities.com/universities/university-copenhagen",
            "country": "Denmark",
            "city": "Copenhagen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-copenhagen_141_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4135,
            "size": "XL",
            "faculty_count": 7293
        },
        {
            "university": "Georgia Institute of Technology",
            "year": 2017,
            "rank_display": 71,
            "score": 69.8,
            "link": "https://www.topuniversities.com/universities/georgia-institute-technology",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgia-institute-of-technology_225_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 1267
        },
        {
            "university": "Ruprecht-Karls-Universität Heidelberg",
            "year": 2017,
            "rank_display": 72,
            "score": 69.7,
            "link": "https://www.topuniversities.com/universities/ruprecht-karls-universitat-heidelberg",
            "country": "Germany",
            "city": "Heidelberg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruprecht-karls-universitaet-heidelberg_259_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4048,
            "size": "L",
            "faculty_count": 4100
        },
        {
            "university": "Lund University",
            "year": 2017,
            "rank_display": 73,
            "score": 69.1,
            "link": "https://www.topuniversities.com/universities/lund-university",
            "country": "Sweden",
            "city": "Lund",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lund-university_371_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6682,
            "size": "L",
            "faculty_count": 3040
        },
        {
            "university": "Durham University",
            "year": 2017,
            "rank_display": 74,
            "score": 68.8,
            "link": "https://www.topuniversities.com/universities/durham-university",
            "country": "United Kingdom",
            "city": "Durham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/durham-university_170_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6174,
            "size": "L",
            "faculty_count": 1775
        },
        {
            "university": "Tohoku University",
            "year": 2017,
            "rank_display": 75,
            "score": 68.7,
            "link": "https://www.topuniversities.com/universities/tohoku-university",
            "country": "Japan",
            "city": "Sendai City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tohoku-university_611_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1872,
            "size": "L",
            "faculty_count": 3323
        },
        {
            "university": "University of Nottingham",
            "year": 2017,
            "rank_display": 75,
            "score": 68.7,
            "link": "https://www.topuniversities.com/universities/university-nottingham",
            "country": "United Kingdom",
            "city": "Nottingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-nottingham_592560cf2aeae70239af4c4e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9659,
            "size": "XL",
            "faculty_count": 3386
        },
        {
            "university": "University of St Andrews",
            "year": 2017,
            "rank_display": 77,
            "score": 68.2,
            "link": "https://www.topuniversities.com/universities/university-st-andrews",
            "country": "United Kingdom",
            "city": "St. Andrews",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st-andrews_570_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4377,
            "size": "M",
            "faculty_count": 1208
        },
        {
            "university": "University of North Carolina, Chapel Hill",
            "year": 2017,
            "rank_display": 78,
            "score": 68.1,
            "link": "https://www.topuniversities.com/universities/university-north-carolina-chapel-hill",
            "country": "United States",
            "city": "Chapel Hill",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-north-carolina-chapel-hill_424_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2749,
            "size": "XL",
            "faculty_count": 4646
        },
        {
            "university": "KU Leuven",
            "year": 2017,
            "rank_display": 79,
            "score": 67.9,
            "link": "https://www.topuniversities.com/universities/ku-leuven",
            "country": "Belgium",
            "city": "Leuven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ku-leuven_592560cf2aeae70239af4aec_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 8485,
            "size": "XL",
            "faculty_count": 2677
        },
        {
            "university": "University of Zurich",
            "year": 2017,
            "rank_display": 80,
            "score": 67.8,
            "link": "https://www.topuniversities.com/universities/university-zurich",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-zurich_693_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5044,
            "size": "L",
            "faculty_count": 3761
        },
        {
            "university": "The University of Auckland",
            "year": 2017,
            "rank_display": 81,
            "score": 67.3,
            "link": "https://www.topuniversities.com/universities/university-auckland",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-auckland_592560cf2aeae70239af4aa8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9924,
            "size": "L",
            "faculty_count": 2316
        },
        {
            "university": "University of Birmingham",
            "year": 2017,
            "rank_display": 82,
            "score": 67.2,
            "link": "https://www.topuniversities.com/universities/university-birmingham",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-birmingham_59_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10457,
            "size": "XL",
            "faculty_count": 3586
        },
        {
            "university": "Pohang University of Science And Technology (POSTECH)",
            "year": 2017,
            "rank_display": 83,
            "score": 67,
            "link": "https://www.topuniversities.com/universities/pohang-university-science-technology-postech",
            "country": "South Korea",
            "city": "Pohang",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pohang-university-of-science-and-technology-postech_592560cf2aeae70239af4c7b_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 87,
            "size": "S",
            "faculty_count": 743
        },
        {
            "university": "The University of Sheffield",
            "year": 2017,
            "rank_display": 84,
            "score": 66.9,
            "link": "https://www.topuniversities.com/universities/university-sheffield",
            "country": "United Kingdom",
            "city": "Sheffield",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sheffield_592560cf2aeae70239af4cb1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 11090,
            "size": "L",
            "faculty_count": 3463
        },
        {
            "university": "Universidad de Buenos Aires (UBA)",
            "year": 2017,
            "rank_display": 85,
            "score": 65.8,
            "link": "https://www.topuniversities.com/universities/universidad-de-buenos-aires-uba",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-buenos-aires-uba_78_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 29516,
            "size": "XL",
            "faculty_count": 16103
        },
        {
            "university": "University of California, Davis",
            "year": 2017,
            "rank_display": 85,
            "score": 65.8,
            "link": "https://www.topuniversities.com/universities/university-california-davis",
            "country": "United States",
            "city": "Davis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-davis_592560cf2aeae70239af4ad8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8414,
            "size": "XL",
            "faculty_count": 3420
        },
        {
            "university": "University of Southampton",
            "year": 2017,
            "rank_display": 87,
            "score": 65.6,
            "link": "https://www.topuniversities.com/universities/university-southampton",
            "country": "United Kingdom",
            "city": "Southampton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southampton_567_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8417,
            "size": "L",
            "faculty_count": 2468
        },
        {
            "university": "The Ohio State University",
            "year": 2017,
            "rank_display": 88,
            "score": 65.4,
            "link": "https://www.topuniversities.com/universities/ohio-state-university",
            "country": "United States",
            "city": "Columbus",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-ohio-state-university_465_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7029,
            "size": "XL",
            "faculty_count": 6388
        },
        {
            "university": "Boston University",
            "year": 2017,
            "rank_display": 89,
            "score": 65.2,
            "link": "https://www.topuniversities.com/universities/boston-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-university_592560cf2aeae70239af4ac2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8159,
            "size": "L",
            "faculty_count": 2642
        },
        {
            "university": "Rice University",
            "year": 2017,
            "rank_display": 90,
            "score": 65,
            "link": "https://www.topuniversities.com/universities/rice-university",
            "country": "United States",
            "city": "Houston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rice-university_524_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2278,
            "size": "M",
            "faculty_count": 996
        },
        {
            "university": "University of Helsinki",
            "year": 2017,
            "rank_display": 91,
            "score": 64.7,
            "link": "https://www.topuniversities.com/universities/university-helsinki",
            "country": "Finland",
            "city": "Helsinki",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-helsinki_260_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1254,
            "size": "L",
            "faculty_count": 2855
        },
        {
            "university": "Purdue University",
            "year": 2017,
            "rank_display": 92,
            "score": 64.5,
            "link": "https://www.topuniversities.com/universities/purdue-university",
            "country": "United States",
            "city": "West Lafayette",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/purdue-university_510_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 8034,
            "size": "XL",
            "faculty_count": 2622
        },
        {
            "university": "University of Leeds",
            "year": 2017,
            "rank_display": 93,
            "score": 64.2,
            "link": "https://www.topuniversities.com/universities/university-leeds",
            "country": "United Kingdom",
            "city": "Leeds",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leeds_338_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 11584,
            "size": "XL",
            "faculty_count": 3593
        },
        {
            "university": "University of Alberta",
            "year": 2017,
            "rank_display": 94,
            "score": 64,
            "link": "https://www.topuniversities.com/universities/university-alberta",
            "country": "Canada",
            "city": "Edmonton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-alberta_15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9689,
            "size": "XL",
            "faculty_count": 3645
        },
        {
            "university": "Pennsylvania State University",
            "year": 2017,
            "rank_display": 95,
            "score": 63.6,
            "link": "https://www.topuniversities.com/universities/pennsylvania-state-university",
            "country": "United States",
            "city": "University Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/pennsylvania-state-university_494_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6943,
            "size": "XL",
            "faculty_count": 4207
        },
        {
            "university": "University of Geneva",
            "year": 2017,
            "rank_display": 95,
            "score": 63.6,
            "link": "https://www.topuniversities.com/universities/university-geneva",
            "country": "Switzerland",
            "city": "Geneva",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-geneva_221_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6547,
            "size": "L",
            "faculty_count": 1814
        },
        {
            "university": "KTH Royal Institute of Technology",
            "year": 2017,
            "rank_display": 97,
            "score": 63.1,
            "link": "https://www.topuniversities.com/universities/kth-royal-institute-technology",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kth-royal-institute-of-technology-_592560cf2aeae70239af4c9b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3057,
            "size": "L",
            "faculty_count": 3600
        },
        {
            "university": "Uppsala University",
            "year": 2017,
            "rank_display": 98,
            "score": 62.8,
            "link": "https://www.topuniversities.com/universities/uppsala-university",
            "country": "Sweden",
            "city": "Uppsala",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/uppsala-university_643_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8401,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Korea University",
            "year": 2017,
            "rank_display": 98,
            "score": 62.8,
            "link": "https://www.topuniversities.com/universities/korea-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/korea-university_325_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3325,
            "size": "L",
            "faculty_count": 4026
        },
        {
            "university": "Trinity College Dublin, The University of Dublin",
            "year": 2017,
            "rank_display": 98,
            "score": 62.8,
            "link": "https://www.topuniversities.com/universities/trinity-college-dublin-university-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/trinity-college-dublin-the-university-of-dublin_167_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5593,
            "size": "L",
            "faculty_count": 1383
        },
        {
            "university": "KIT, Karlsruhe Institute of Technology",
            "year": 2017,
            "rank_display": 98,
            "score": 62.8,
            "link": "https://www.topuniversities.com/universities/kit-karlsruhe-institute-technology",
            "country": "Germany",
            "city": "Karlsruhe",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kit-karlsruhe-institute-of-technology_310_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5030,
            "size": "L",
            "faculty_count": 2080
        },
        {
            "university": "Leiden University",
            "year": 2017,
            "rank_display": 102,
            "score": 62.7,
            "link": "https://www.topuniversities.com/universities/leiden-university",
            "country": "Netherlands",
            "city": "Leiden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leiden-university_340_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6064,
            "size": "XL",
            "faculty_count": 2069
        },
        {
            "university": "The University of Western Australia",
            "year": 2017,
            "rank_display": 102,
            "score": 62.7,
            "link": "https://www.topuniversities.com/universities/university-western-australia",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-australia_673_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9017,
            "size": "L",
            "faculty_count": 1539
        },
        {
            "university": "Utrecht University",
            "year": 2017,
            "rank_display": 104,
            "score": 62.4,
            "link": "https://www.topuniversities.com/universities/utrecht-university",
            "country": "Netherlands",
            "city": "Utrecht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/utrecht-university_646_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4454,
            "size": "XL",
            "faculty_count": 3254
        },
        {
            "university": "University of Science and Technology of China",
            "year": 2017,
            "rank_display": 104,
            "score": 62.4,
            "link": "https://www.topuniversities.com/universities/university-science-technology-china",
            "country": "China (Mainland)",
            "city": "Hefei",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-of-china_122_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1019,
            "size": "L",
            "faculty_count": 2708
        },
        {
            "university": "Sungkyunkwan University(SKKU)",
            "year": 2017,
            "rank_display": 106,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/sungkyunkwan-universityskku",
            "country": "South Korea",
            "city": "Suwon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sungkyunkwan-universityskku_592560cf2aeae70239af4cc9_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3821,
            "size": "L",
            "faculty_count": 3313
        },
        {
            "university": "Washington University in St. Louis",
            "year": 2017,
            "rank_display": 106,
            "score": 62.2,
            "link": "https://www.topuniversities.com/universities/washington-university-st-louis",
            "country": "United States",
            "city": "St. Louis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-university-in-st.-louis_668_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3535,
            "size": "L",
            "faculty_count": 3193
        },
        {
            "university": "Lomonosov Moscow State University",
            "year": 2017,
            "rank_display": 108,
            "score": 62.1,
            "link": "https://www.topuniversities.com/universities/lomonosov-moscow-state-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lomonosov-moscow-state-university-_592560cf2aeae70239af4c27_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5476,
            "size": "XL",
            "faculty_count": 6575
        },
        {
            "university": "Technical University of Denmark",
            "year": 2017,
            "rank_display": 109,
            "score": 61.8,
            "link": "https://www.topuniversities.com/universities/technical-university-denmark",
            "country": "Denmark",
            "city": "Kongens Lyngby",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-denmark_592560cf2aeae70239af4cd8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2495,
            "size": "M",
            "faculty_count": 2219
        },
        {
            "university": "Zhejiang University",
            "year": 2017,
            "rank_display": 110,
            "score": 61.6,
            "link": "https://www.topuniversities.com/universities/zhejiang-university",
            "country": "China (Mainland)",
            "city": "Hangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/zhejiang-university_592560cf2aeae70239af4d37_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 9177,
            "size": "XL",
            "faculty_count": 6065
        },
        {
            "university": "The Hong Kong Polytechnic University",
            "year": 2017,
            "rank_display": 111,
            "score": 61.4,
            "link": "https://www.topuniversities.com/universities/hong-kong-polytechnic-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-polytechnic-university_267_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5774,
            "size": "L",
            "faculty_count": 2822
        },
        {
            "university": "Yonsei University",
            "year": 2017,
            "rank_display": 112,
            "score": 60.5,
            "link": "https://www.topuniversities.com/universities/yonsei-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yonsei-university_688_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4274,
            "size": "L",
            "faculty_count": 3605
        },
        {
            "university": "University of Oslo",
            "year": 2017,
            "rank_display": 113,
            "score": 60,
            "link": "https://www.topuniversities.com/universities/university-oslo",
            "country": "Norway",
            "city": "Oslo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oslo_473_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2560,
            "size": "L",
            "faculty_count": 2600
        },
        {
            "university": "University of Groningen",
            "year": 2017,
            "rank_display": 113,
            "score": 60,
            "link": "https://www.topuniversities.com/universities/university-groningen",
            "country": "Netherlands",
            "city": "Groningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-groningen_243_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 9397,
            "size": "XL",
            "faculty_count": 3724
        },
        {
            "university": "Nanjing University",
            "year": 2017,
            "rank_display": 115,
            "score": 59.6,
            "link": "https://www.topuniversities.com/universities/nanjing-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanjing-university_430_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2513,
            "size": "XL",
            "faculty_count": 3040
        },
        {
            "university": "Nagoya University",
            "year": 2017,
            "rank_display": 115,
            "score": 59.6,
            "link": "https://www.topuniversities.com/universities/nagoya-university",
            "country": "Japan",
            "city": "Nagoya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nagoya-university_427_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1879,
            "size": "L",
            "faculty_count": 2512
        },
        {
            "university": "Aarhus University",
            "year": 2017,
            "rank_display": 117,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/aarhus-university",
            "country": "Denmark",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aarhus-university_592560cf2aeae70239af4a8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2911,
            "size": "L",
            "faculty_count": 683
        },
        {
            "university": "University of California, Santa Barbara (UCSB)",
            "year": 2017,
            "rank_display": 118,
            "score": 59.4,
            "link": "https://www.topuniversities.com/universities/university-california-santa-barbara-ucsb",
            "country": "United States",
            "city": "Santa Barbara",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-barbara-ucsb_91_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 4309,
            "size": "L",
            "faculty_count": 1597
        },
        {
            "university": "Wageningen University & Research",
            "year": 2017,
            "rank_display": 119,
            "score": 59.1,
            "link": "https://www.topuniversities.com/universities/wageningen-university-research",
            "country": "Netherlands",
            "city": "Wageningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/wageningen-university_659_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4131,
            "size": "L",
            "faculty_count": 2767
        },
        {
            "university": "Universidade de São Paulo",
            "year": 2017,
            "rank_display": 120,
            "score": 59,
            "link": "https://www.topuniversities.com/universities/universidade-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-de-so-paulo_550_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1858,
            "size": "XL",
            "faculty_count": 4943
        },
        {
            "university": "Humboldt-Universität zu Berlin",
            "year": 2017,
            "rank_display": 121,
            "score": 58.9,
            "link": "https://www.topuniversities.com/universities/humboldt-universitat-zu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/humboldt-universitt-zu-berlin_272_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5239,
            "size": "XL",
            "faculty_count": 1967
        },
        {
            "university": "Eindhoven University of Technology",
            "year": 2017,
            "rank_display": 121,
            "score": 58.9,
            "link": "https://www.topuniversities.com/universities/eindhoven-university-technology",
            "country": "Netherlands",
            "city": "Eindhoven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eindhoven-university-of-technology_592560cf2aeae70239af4b3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1872,
            "size": "M",
            "faculty_count": 1145
        },
        {
            "university": "Freie Universitaet Berlin",
            "year": 2017,
            "rank_display": 123,
            "score": 58.7,
            "link": "https://www.topuniversities.com/universities/freie-universitaet-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/freie-universitaet-berlin_215_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 6659,
            "size": "XL",
            "faculty_count": 1389
        },
        {
            "university": "Queen Mary University of London",
            "year": 2017,
            "rank_display": 123,
            "score": 58.7,
            "link": "https://www.topuniversities.com/universities/queen-mary-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queen-mary-university-of-london_592560cf2aeae70239af4bea_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8625,
            "size": "L",
            "faculty_count": 2119
        },
        {
            "university": "The University of Adelaide",
            "year": 2017,
            "rank_display": 125,
            "score": 58.6,
            "link": "https://www.topuniversities.com/universities/university-adelaide",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-adelaide_10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10107,
            "size": "L",
            "faculty_count": 1757
        },
        {
            "university": "Université de Montréal",
            "year": 2017,
            "rank_display": 126,
            "score": 58.5,
            "link": "https://www.topuniversities.com/universities/universite-de-montreal",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montral_417_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7625,
            "size": "XL",
            "faculty_count": 5533
        },
        {
            "university": "University of York",
            "year": 2017,
            "rank_display": 127,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/university-york",
            "country": "United Kingdom",
            "city": "York",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-york_689_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5098,
            "size": "L",
            "faculty_count": 2209
        },
        {
            "university": "Universidad Nacional Autónoma de México  (UNAM)",
            "year": 2017,
            "rank_display": 128,
            "score": 58.3,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-autonoma-de-mexico-unam",
            "country": "Mexico",
            "city": "Mexico City",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-autnoma-de-mxico-unam_425_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5110,
            "size": "XL",
            "faculty_count": 16381
        },
        {
            "university": "Lancaster University",
            "year": 2017,
            "rank_display": 129,
            "score": 57.9,
            "link": "https://www.topuniversities.com/universities/lancaster-university",
            "country": "United Kingdom",
            "city": "Lancaster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lancaster-university_335_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5557,
            "size": "L",
            "faculty_count": 1440
        },
        {
            "university": "Hokkaido University",
            "year": 2017,
            "rank_display": 130,
            "score": 57.8,
            "link": "https://www.topuniversities.com/universities/hokkaido-university",
            "country": "Japan",
            "city": "Sapporo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hokkaido-university_266_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1984,
            "size": "L",
            "faculty_count": 2476
        },
        {
            "university": "Ghent University",
            "year": 2017,
            "rank_display": 131,
            "score": 57.6,
            "link": "https://www.topuniversities.com/universities/ghent-university",
            "country": "Belgium",
            "city": "Ghent",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ghent-university_228_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4609,
            "size": "XL",
            "faculty_count": 5537
        },
        {
            "university": "University of Maryland, College Park",
            "year": 2017,
            "rank_display": 131,
            "score": 57.6,
            "link": "https://www.topuniversities.com/universities/university-maryland-college-park",
            "country": "United States",
            "city": "College Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-college-park_393_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4659,
            "size": "XL",
            "faculty_count": 3798
        },
        {
            "university": "Universiti Malaya (UM)",
            "year": 2017,
            "rank_display": 133,
            "score": 57.1,
            "link": "https://www.topuniversities.com/universities/universiti-malaya-um",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-malaya-um_383_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3340,
            "size": "L",
            "faculty_count": 2386
        },
        {
            "university": "Aalto University",
            "year": 2017,
            "rank_display": 133,
            "score": 57.1,
            "link": "https://www.topuniversities.com/universities/aalto-university",
            "country": "Finland",
            "city": "Espoo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalto-university_261_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2202,
            "size": "L",
            "faculty_count": 1396
        },
        {
            "university": "Kyushu University",
            "year": 2017,
            "rank_display": 135,
            "score": 56.8,
            "link": "https://www.topuniversities.com/universities/kyushu-university",
            "country": "Japan",
            "city": "Fukuoka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyushu-university_331_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2125,
            "size": "L",
            "faculty_count": 2761
        },
        {
            "university": "University of Southern California",
            "year": 2017,
            "rank_display": 136,
            "score": 56.7,
            "link": "https://www.topuniversities.com/universities/university-southern-california",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-california_569_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9107,
            "size": "XL",
            "faculty_count": 3116
        },
        {
            "university": "University of Minnesota Twin Cities",
            "year": 2017,
            "rank_display": 137,
            "score": 56.4,
            "link": "https://www.topuniversities.com/universities/university-minnesota-twin-cities",
            "country": "United States",
            "city": "Minneapolis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-minnesota_408_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5630,
            "size": "XL",
            "faculty_count": 5087
        },
        {
            "university": "University of Lausanne",
            "year": 2017,
            "rank_display": 138,
            "score": 56.3,
            "link": "https://www.topuniversities.com/universities/university-lausanne",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lausanne_592560cf2aeae70239af4bd3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3026,
            "size": "L",
            "faculty_count": 1478
        },
        {
            "university": "Chalmers University of Technology",
            "year": 2017,
            "rank_display": 139,
            "score": 56.1,
            "link": "https://www.topuniversities.com/universities/chalmers-university-technology",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/chalmers-university-of-technology_592560cf2aeae70239af4af7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2502,
            "size": "M",
            "faculty_count": 2500
        },
        {
            "university": "Cardiff University",
            "year": 2017,
            "rank_display": 140,
            "score": 55.7,
            "link": "https://www.topuniversities.com/universities/cardiff-university",
            "country": "United Kingdom",
            "city": "Cardiff",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cardiff-university_592560cf2aeae70239af4ae6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7819,
            "size": "L",
            "faculty_count": 3350
        },
        {
            "university": "Université Pierre et Marie Curie (UPMC)",
            "year": 2017,
            "rank_display": 141,
            "score": 57.5,
            "link": "https://www.topuniversities.com/universities/universite-pierre-et-marie-curie-upmc",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-pierre-et-marie-curie-upmc_487_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "University of Basel",
            "year": 2017,
            "rank_display": 141,
            "score": 55.6,
            "link": "https://www.topuniversities.com/universities/university-basel",
            "country": "Switzerland",
            "city": "Basel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-basel_46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2875,
            "size": "M",
            "faculty_count": 1036
        },
        {
            "university": "University of Aberdeen",
            "year": 2017,
            "rank_display": 141,
            "score": 55.6,
            "link": "https://www.topuniversities.com/universities/university-aberdeen",
            "country": "United Kingdom",
            "city": "Aberdeen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-aberdeen_592560cf2aeae70239af4a8b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5544,
            "size": "L",
            "faculty_count": 1175
        },
        {
            "university": "Erasmus University Rotterdam",
            "year": 2017,
            "rank_display": 144,
            "score": 55.5,
            "link": "https://www.topuniversities.com/universities/erasmus-university-rotterdam",
            "country": "Netherlands",
            "city": "Rotterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/erasmus-university-rotterdam_190_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5932,
            "size": "L",
            "faculty_count": 2699
        },
        {
            "university": "University of Pittsburgh",
            "year": 2017,
            "rank_display": 145,
            "score": 55.1,
            "link": "https://www.topuniversities.com/universities/university-pittsburgh",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pittsburgh_500_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2590,
            "size": "L",
            "faculty_count": 4746
        },
        {
            "university": "RWTH Aachen University",
            "year": 2017,
            "rank_display": 146,
            "score": 55,
            "link": "https://www.topuniversities.com/universities/rwth-aachen-university",
            "country": "Germany",
            "city": "Aachen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rwth-aachen-university_4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 12477,
            "size": "XL",
            "faculty_count": 2431
        },
        {
            "university": "Pontificia Universidad Católica de Chile (UC)",
            "year": 2017,
            "rank_display": 147,
            "score": 54.7,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-cat%C3%B3lica-de-chile-uc",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-de-chile-uc_107_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1072,
            "size": "L",
            "faculty_count": 2343
        },
        {
            "university": "The Hebrew University of Jerusalem",
            "year": 2017,
            "rank_display": 148,
            "score": 54.2,
            "link": "https://www.topuniversities.com/universities/hebrew-university-jerusalem",
            "country": "Israel",
            "city": "Jerusalem",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hebrew-university-of-jerusalem_256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1176,
            "size": "L",
            "faculty_count": 2295
        },
        {
            "university": "McMaster University",
            "year": 2017,
            "rank_display": 149,
            "score": 54.1,
            "link": "https://www.topuniversities.com/universities/mcmaster-university",
            "country": "Canada",
            "city": "Hamilton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcmaster-university_397_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5386,
            "size": "L",
            "faculty_count": 3819
        },
        {
            "university": "Emory University",
            "year": 2017,
            "rank_display": 149,
            "score": 54.1,
            "link": "https://www.topuniversities.com/universities/emory-university",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/emory-university_185_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2264,
            "size": "L",
            "faculty_count": 2725
        },
        {
            "university": "National Tsing Hua University",
            "year": 2017,
            "rank_display": 151,
            "score": 53.9,
            "link": "https://www.topuniversities.com/universities/national-tsing-hua-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-tsing-hua-university_442_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1551,
            "size": "L",
            "faculty_count": 1265
        },
        {
            "university": "University of Waterloo",
            "year": 2017,
            "rank_display": 152,
            "score": 53.8,
            "link": "https://www.topuniversities.com/universities/university-waterloo",
            "country": "Canada",
            "city": "Waterloo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waterloo_670_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 10269,
            "size": "XL",
            "faculty_count": 1908
        },
        {
            "university": "Indian Institute of Science",
            "year": 2017,
            "rank_display": 152,
            "score": 53.8,
            "link": "https://www.topuniversities.com/universities/indian-institute-science",
            "country": "India",
            "city": "Bangalore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-science-iisc-bangalore_22878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5,
            "size": "S",
            "faculty_count": 446
        },
        {
            "university": "Université catholique de Louvain (UCLouvain)",
            "year": 2017,
            "rank_display": 154,
            "score": 53.4,
            "link": "https://www.topuniversities.com/universities/universite-catholique-de-louvain-uclouvain",
            "country": "Belgium",
            "city": "Louvain-la-Neuve",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-catholique-de-louvain-uclouvain_592560cf2aeae70239af4aed_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4524,
            "size": "L",
            "faculty_count": 1315
        },
        {
            "university": "University of Vienna",
            "year": 2017,
            "rank_display": 155,
            "score": 53.3,
            "link": "https://www.topuniversities.com/universities/university-vienna",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-vienna_652_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 14514,
            "size": "XL",
            "faculty_count": 3637
        },
        {
            "university": "University of California, Irvine",
            "year": 2017,
            "rank_display": 156,
            "score": 53.2,
            "link": "https://www.topuniversities.com/universities/university-california-irvine",
            "country": "United States",
            "city": "Irvine",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-irvine_86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 9323,
            "size": "XL",
            "faculty_count": 2775
        },
        {
            "university": "University of Liverpool",
            "year": 2017,
            "rank_display": 157,
            "score": 53.1,
            "link": "https://www.topuniversities.com/universities/university-liverpool",
            "country": "United Kingdom",
            "city": "Liverpool",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-liverpool_350_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9500,
            "size": "L",
            "faculty_count": 2771
        },
        {
            "university": "Dartmouth College",
            "year": 2017,
            "rank_display": 158,
            "score": 53,
            "link": "https://www.topuniversities.com/universities/dartmouth-college",
            "country": "United States",
            "city": "Hanover",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dartmouth-college_592560cf2aeae70239af4b1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 997,
            "size": "M",
            "faculty_count": 836
        },
        {
            "university": "University of Bath",
            "year": 2017,
            "rank_display": 159,
            "score": 52.7,
            "link": "https://www.topuniversities.com/universities/university-bath",
            "country": "United Kingdom",
            "city": "Bath",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bath_47_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5326,
            "size": "L",
            "faculty_count": 1339
        },
        {
            "university": "Universitat de Barcelona",
            "year": 2017,
            "rank_display": 160,
            "score": 52.6,
            "link": "https://www.topuniversities.com/universities/universitat-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-barcelona_592560cf2aeae70239af4aaf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 6979,
            "size": "XL",
            "faculty_count": 3002
        },
        {
            "university": "Texas A&M University",
            "year": 2017,
            "rank_display": 160,
            "score": 52.6,
            "link": "https://www.topuniversities.com/universities/texas-am-university",
            "country": "United States",
            "city": "Galveston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/texas-am-university_592560cf2aeae70239af4ce2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 6621,
            "size": "XL",
            "faculty_count": 3049
        },
        {
            "university": "Michigan State University",
            "year": 2017,
            "rank_display": 160,
            "score": 52.6,
            "link": "https://www.topuniversities.com/universities/michigan-state-university",
            "country": "United States",
            "city": "East Lansing",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/michigan-state-university_402_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5795,
            "size": "XL",
            "faculty_count": 3008
        },
        {
            "university": "Albert-Ludwigs-Universitaet Freiburg",
            "year": 2017,
            "rank_display": 163,
            "score": 52.2,
            "link": "https://www.topuniversities.com/universities/albert-ludwigs-universitaet-freiburg",
            "country": "Germany",
            "city": "Freiburg im Breisgau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/albert-ludwigs-universitaet-freiburg_216_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3883,
            "size": "L",
            "faculty_count": 1993
        },
        {
            "university": "Technische Universität Berlin (TU Berlin)",
            "year": 2017,
            "rank_display": 164,
            "score": 52.1,
            "link": "https://www.topuniversities.com/universities/technische-universitat-berlin-tu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-berlin-tu-berlin_595_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6464,
            "size": "L",
            "faculty_count": 2331
        },
        {
            "university": "The University of Exeter",
            "year": 2017,
            "rank_display": 164,
            "score": 52.1,
            "link": "https://www.topuniversities.com/universities/university-exeter",
            "country": "United Kingdom",
            "city": "Exeter",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-exeter_204_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7188,
            "size": "L",
            "faculty_count": 2393
        },
        {
            "university": "CentraleSupélec",
            "year": 2017,
            "rank_display": 164,
            "score": 52.3,
            "link": "https://www.topuniversities.com/universities/centralesupelec",
            "country": "France",
            "city": "Saint-Aubin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/centralesuplec_173_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": "",
            "international_students": 1068,
            "size": "S",
            "faculty_count": 552
        },
        {
            "university": "Eberhard Karls Universität Tübingen",
            "year": 2017,
            "rank_display": 167,
            "score": 51.9,
            "link": "https://www.topuniversities.com/universities/eberhard-karls-universitat-tubingen",
            "country": "Germany",
            "city": "Tübingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eberhard-karls-universitt-tbingen_629_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2872,
            "size": "L",
            "faculty_count": 4517
        },
        {
            "university": "Newcastle University",
            "year": 2017,
            "rank_display": 168,
            "score": 51.8,
            "link": "https://www.topuniversities.com/universities/newcastle-university",
            "country": "United Kingdom",
            "city": "Newcastle upon Tyne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/newcastle-university_592560cf2aeae70239af4c45_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8100,
            "size": "L",
            "faculty_count": 2766
        },
        {
            "university": "University of Otago",
            "year": 2017,
            "rank_display": 169,
            "score": 51.3,
            "link": "https://www.topuniversities.com/universities/university-otago",
            "country": "New Zealand",
            "city": "Dunedin",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-otago_474_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3585,
            "size": "L",
            "faculty_count": 1333
        },
        {
            "university": "University of Colorado Boulder",
            "year": 2017,
            "rank_display": 170,
            "score": 51.2,
            "link": "https://www.topuniversities.com/universities/university-colorado-boulder",
            "country": "United States",
            "city": "Boulder",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-boulder_137_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2441,
            "size": "XL",
            "faculty_count": 2946
        },
        {
            "university": "Hanyang University",
            "year": 2017,
            "rank_display": 171,
            "score": 50.6,
            "link": "https://www.topuniversities.com/universities/hanyang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hanyang-university_250_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3455,
            "size": "L",
            "faculty_count": 3164
        },
        {
            "university": "University of Virginia",
            "year": 2017,
            "rank_display": 172,
            "score": 50.5,
            "link": "https://www.topuniversities.com/universities/university-virginia",
            "country": "United States",
            "city": "Charlottesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-virginia_656_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2201,
            "size": "L",
            "faculty_count": 2731
        },
        {
            "university": "Maastricht University",
            "year": 2017,
            "rank_display": 173,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/maastricht-university",
            "country": "Netherlands",
            "city": "Maastricht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/maastricht-university_377_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10425,
            "size": "L",
            "faculty_count": 1418
        },
        {
            "university": "National Chiao Tung University",
            "year": 2017,
            "rank_display": 174,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/national-chiao-tung-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-chiao-tung-university_437_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1420,
            "size": "M",
            "faculty_count": 1152
        },
        {
            "university": "University of Reading",
            "year": 2017,
            "rank_display": 175,
            "score": 50,
            "link": "https://www.topuniversities.com/universities/university-reading",
            "country": "United Kingdom",
            "city": "Reading",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-reading_517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5007,
            "size": "L",
            "faculty_count": 1325
        },
        {
            "university": "University College Dublin",
            "year": 2017,
            "rank_display": 176,
            "score": 49.7,
            "link": "https://www.topuniversities.com/universities/university-college-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-dublin_166_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6118,
            "size": "L",
            "faculty_count": 1836
        },
        {
            "university": "University of Göttingen",
            "year": 2017,
            "rank_display": 177,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/university-gottingen",
            "country": "Germany",
            "city": "Göttingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gttingen_234_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3875,
            "size": "XL",
            "faculty_count": 3717
        },
        {
            "university": "École Normale Supérieure de Lyon",
            "year": 2017,
            "rank_display": 177,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-de-lyon",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cole-normale-suprieure-de-lyon_175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 233,
            "size": "S",
            "faculty_count": 387
        },
        {
            "university": "University of Bergen",
            "year": 2017,
            "rank_display": 177,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/university-bergen",
            "country": "Norway",
            "city": "Bergen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bergen_53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 832,
            "size": "M",
            "faculty_count": 1634
        },
        {
            "university": "University of Twente",
            "year": 2017,
            "rank_display": 177,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/university-twente",
            "country": "Netherlands",
            "city": "Enschede",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-twente_635_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3872,
            "size": "L",
            "faculty_count": 1177
        },
        {
            "university": "University of Bern",
            "year": 2017,
            "rank_display": 181,
            "score": 49.3,
            "link": "https://www.topuniversities.com/universities/university-bern",
            "country": "Switzerland",
            "city": "Bern",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bern_55_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1776,
            "size": "M",
            "faculty_count": 1212
        },
        {
            "university": "Vrije Universiteit Brussel (VUB)",
            "year": 2017,
            "rank_display": 182,
            "score": 48.9,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-brussel-vub",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-brussel-vub_74_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2994,
            "size": "L",
            "faculty_count": 1815
        },
        {
            "university": "Politecnico di Milano",
            "year": 2017,
            "rank_display": 183,
            "score": 48.8,
            "link": "https://www.topuniversities.com/universities/politecnico-di-milano",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-milano_592560cf2aeae70239af4c1c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7452,
            "size": "XL",
            "faculty_count": 1659
        },
        {
            "university": "Technische Universität Wien",
            "year": 2017,
            "rank_display": 183,
            "score": 48.8,
            "link": "https://www.topuniversities.com/universities/vienna-university-technology",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vienna-university-of-technology_653_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2515,
            "size": "M",
            "faculty_count": 761
        },
        {
            "university": "University of Florida",
            "year": 2017,
            "rank_display": 185,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/university-florida",
            "country": "United States",
            "city": "Gainesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florida_212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4143,
            "size": "XL",
            "faculty_count": 6222
        },
        {
            "university": "University of Rochester",
            "year": 2017,
            "rank_display": 185,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/university-rochester",
            "country": "United States",
            "city": "Rochester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rochester_592560cf2aeae70239af4c96_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3644,
            "size": "M",
            "faculty_count": 1853
        },
        {
            "university": "Indian Institute of Technology Delhi (IITD)",
            "year": 2017,
            "rank_display": 185,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-delhi-iitd",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-delhi-iitd_282_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 100,
            "size": "M",
            "faculty_count": 843
        },
        {
            "university": "University of Illinois at Chicago (UIC)",
            "year": 2017,
            "rank_display": 187,
            "score": 48.4,
            "link": "https://www.topuniversities.com/universities/university-illinois-chicago-uic",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-chicago-uic_592560cf2aeae70239af4b9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3425,
            "size": "L",
            "faculty_count": 2808
        },
        {
            "university": "University of Sussex",
            "year": 2017,
            "rank_display": 187,
            "score": 48.4,
            "link": "https://www.topuniversities.com/universities/university-sussex",
            "country": "United Kingdom",
            "city": "Brighton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-sussex_592560cf2aeae70239af4ccd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6350,
            "size": "L",
            "faculty_count": 1320
        },
        {
            "university": "King Fahd University of Petroleum & Minerals",
            "year": 2017,
            "rank_display": 189,
            "score": 48.3,
            "link": "https://www.topuniversities.com/universities/king-fahd-university-petroleum-minerals",
            "country": "Saudi Arabia",
            "city": "Dhahran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-fahd-university-of-petroleum-minerals_779_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 782,
            "size": "M",
            "faculty_count": 1103
        },
        {
            "university": "Radboud University",
            "year": 2017,
            "rank_display": 190,
            "score": 48.2,
            "link": "https://www.topuniversities.com/universities/radboud-university",
            "country": "Netherlands",
            "city": "Nijmegen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/radboud-university_452_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2781,
            "size": "L",
            "faculty_count": 2074
        },
        {
            "university": "Universidade Estadual de Campinas (Unicamp)",
            "year": 2017,
            "rank_display": 191,
            "score": 48,
            "link": "https://www.topuniversities.com/universities/universidade-estadual-de-campinas-unicamp",
            "country": "Brazil",
            "city": "Campinas",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-estadual-de-campinas-unicamp_96_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 944,
            "size": "L",
            "faculty_count": 1875
        },
        {
            "university": "University of Cape Town",
            "year": 2017,
            "rank_display": 191,
            "score": 48,
            "link": "https://www.topuniversities.com/universities/university-cape-town",
            "country": "South Africa",
            "city": "Cape Town",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/uct-graduate-school-of-business_592560cf2aeae70239af4ae5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3204,
            "size": "L",
            "faculty_count": 1503
        },
        {
            "university": "University of Technology Sydney",
            "year": 2017,
            "rank_display": 193,
            "score": 47.8,
            "link": "https://www.topuniversities.com/universities/university-technology-sydney",
            "country": "Australia",
            "city": "Haymarket",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-technology-sydney_592560cf2aeae70239af4ccf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8653,
            "size": "L",
            "faculty_count": 2183
        },
        {
            "university": "Queen's University Belfast",
            "year": 2017,
            "rank_display": 195,
            "score": 47.5,
            "link": "https://www.topuniversities.com/universities/queens-university-belfast",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-belfast_592560cf2aeae70239af4c86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7397,
            "size": "L",
            "faculty_count": 1951
        },
        {
            "university": "Stockholm University",
            "year": 2017,
            "rank_display": 196,
            "score": 47.2,
            "link": "https://www.topuniversities.com/universities/stockholm-university",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/stockholm-university_577_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7567,
            "size": "L",
            "faculty_count": 2325
        },
        {
            "university": "University of Calgary",
            "year": 2017,
            "rank_display": 196,
            "score": 47.2,
            "link": "https://www.topuniversities.com/universities/university-calgary",
            "country": "Canada",
            "city": "Calgary",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-calgary_592560cf2aeae70239af4ad5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6791,
            "size": "XL",
            "faculty_count": 2668
        },
        {
            "university": "Western University",
            "year": 2017,
            "rank_display": 198,
            "score": 47.1,
            "link": "https://www.topuniversities.com/universities/western-university",
            "country": "Canada",
            "city": "London",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-ontario_674_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 6314,
            "size": "L",
            "faculty_count": 1311
        },
        {
            "university": "Vrije Universiteit Amsterdam",
            "year": 2017,
            "rank_display": 199,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-amsterdam_658_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4059,
            "size": "L",
            "faculty_count": 2598
        },
        {
            "university": "Universidad de Chile",
            "year": 2017,
            "rank_display": 200,
            "score": 46.7,
            "link": "https://www.topuniversities.com/universities/universidad-de-chile",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-chile_121_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1697,
            "size": "XL",
            "faculty_count": 2412
        },
        {
            "university": "Waseda University",
            "year": 2017,
            "rank_display": 201,
            "score": 46.5,
            "link": "https://www.topuniversities.com/universities/waseda-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/waseda-university_592560cf2aeae70239af4d1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7209,
            "size": "XL",
            "faculty_count": 3833
        },
        {
            "university": "Case Western Reserve University",
            "year": 2017,
            "rank_display": 202,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/case-western-reserve-university",
            "country": "United States",
            "city": "Cleveland",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/case-western-reserve-university_102_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2864,
            "size": "M",
            "faculty_count": 1639
        },
        {
            "university": "Universitat Autònoma de Barcelona",
            "year": 2017,
            "rank_display": 203,
            "score": 46.3,
            "link": "https://www.topuniversities.com/universities/universitat-autonoma-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-autnoma-de-barcelona_592560cf2aeae70239af4ab0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 4069,
            "size": "XL",
            "faculty_count": 2132
        },
        {
            "university": "Vanderbilt University",
            "year": 2017,
            "rank_display": 203,
            "score": 46.3,
            "link": "https://www.topuniversities.com/universities/vanderbilt-university",
            "country": "United States",
            "city": "Nashville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/vanderbilt-university_649_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 2022,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "University of Notre Dame",
            "year": 2017,
            "rank_display": 203,
            "score": 46.3,
            "link": "https://www.topuniversities.com/universities/university-notre-dame",
            "country": "United States",
            "city": "Notre Dame",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-notre-dame_458_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1484,
            "size": "L",
            "faculty_count": 1545
        },
        {
            "university": "Université Grenoble Alpes",
            "year": 2017,
            "rank_display": 206,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/universite-grenoble-alpes",
            "country": "France",
            "city": "Grenoble",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-grenoble-alpes_592560cf2aeae70239af5ab2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8880,
            "size": "XL",
            "faculty_count": 3088
        },
        {
            "university": "Tecnológico de Monterrey",
            "year": 2017,
            "rank_display": 206,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/tecnologico-de-monterrey",
            "country": "Mexico",
            "city": "Monterrey",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/instituto-tecnolgico-y-de-estudios-superiores-de-monterrey_592560cf2aeae70239af4cd6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3514,
            "size": "XL",
            "faculty_count": 5894
        },
        {
            "university": "Alma Mater Studiorum - University of Bologna",
            "year": 2017,
            "rank_display": 208,
            "score": 46,
            "link": "https://www.topuniversities.com/universities/alma-mater-studiorum-university-bologna",
            "country": "Italy",
            "city": "Bologna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/alma-mater-studiorum-university-of-bologna_592560cf2aeae70239af4ac0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 5536,
            "size": "XL",
            "faculty_count": 2885
        },
        {
            "university": "University of Antwerp",
            "year": 2017,
            "rank_display": 209,
            "score": 45.8,
            "link": "https://www.topuniversities.com/universities/university-antwerp",
            "country": "Belgium",
            "city": "Antwerp",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-antwerp_21_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2688,
            "size": "L",
            "faculty_count": 1704
        },
        {
            "university": "Universidad Autónoma de Madrid",
            "year": 2017,
            "rank_display": 210,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/universidad-autonoma-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-autnoma-de-madrid_379_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2470,
            "size": "L",
            "faculty_count": 2869
        },
        {
            "university": "Technische Universität Dresden",
            "year": 2017,
            "rank_display": 210,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/technische-universitat-dresden",
            "country": "Germany",
            "city": "Dresden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-dresden_598_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5167,
            "size": "XL",
            "faculty_count": 4636
        },
        {
            "university": "Tel Aviv University",
            "year": 2017,
            "rank_display": 212,
            "score": 45.6,
            "link": "https://www.topuniversities.com/universities/tel-aviv-university",
            "country": "Israel",
            "city": "Tel Aviv",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tel-aviv-university_592560cf2aeae70239af4cde_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1294,
            "size": "L",
            "faculty_count": 1048
        },
        {
            "university": "Technion - Israel Institute of Technology",
            "year": 2017,
            "rank_display": 213,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/technion-israel-institute-technology",
            "country": "Israel",
            "city": "Haifa",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/technion-israel-institute-of-technology_600_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "L",
            "faculty_count": 1.616
        },
        {
            "university": "University of Canterbury",
            "year": 2017,
            "rank_display": 214,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/university-canterbury",
            "country": "New Zealand",
            "city": "Christchurch",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canterbury_97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2816,
            "size": "L",
            "faculty_count": ""
        },
        {
            "university": "Georgetown University",
            "year": 2017,
            "rank_display": 214,
            "score": 45.4,
            "link": "https://www.topuniversities.com/universities/georgetown-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgetown-university_224_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3286,
            "size": "L",
            "faculty_count": 2009
        },
        {
            "university": "Keio University",
            "year": 2017,
            "rank_display": 216,
            "score": 45.1,
            "link": "https://www.topuniversities.com/universities/keio-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/keio-university_592560cf2aeae70239af4bbe_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3101,
            "size": "XL",
            "faculty_count": 4028
        },
        {
            "university": "Universite libre de Bruxelles",
            "year": 2017,
            "rank_display": 216,
            "score": 45.1,
            "link": "https://www.topuniversities.com/universities/universite-libre-de-bruxelles",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universite-libre-de-bruxelles_75_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 8073,
            "size": "L",
            "faculty_count": 1101
        },
        {
            "university": "University of Wollongong",
            "year": 2017,
            "rank_display": 218,
            "score": 44.7,
            "link": "https://www.topuniversities.com/universities/university-wollongong",
            "country": "Australia",
            "city": "Wollongong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wollongong_680_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4779,
            "size": "L",
            "faculty_count": 1410
        },
        {
            "university": "Indian Institute of Technology Bombay (IITB)",
            "year": 2017,
            "rank_display": 219,
            "score": 44.6,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-bombay-iitb",
            "country": "India",
            "city": "Mumbai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-bombay-iitb_281_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 116,
            "size": "M",
            "faculty_count": 1015
        },
        {
            "university": "Sciences Po",
            "year": 2017,
            "rank_display": 220,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/sciences-po",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sciences-po-_592560cf2aeae70239af4c64_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4841,
            "size": "M",
            "faculty_count": 1808
        },
        {
            "university": "Université Paris-Sorbonne (Paris IV)",
            "year": 2017,
            "rank_display": 221,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/universite-paris-sorbonne-paris-iv",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-sorbonne-paris-iv_484_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "Arizona State University",
            "year": 2017,
            "rank_display": 222,
            "score": 44.2,
            "link": "https://www.topuniversities.com/universities/arizona-state-university",
            "country": "United States",
            "city": "Phoenix",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/arizona-state-university_592560cf2aeae70239af4a9c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8430,
            "size": "XL",
            "faculty_count": 2869
        },
        {
            "university": "Sapienza University of Rome",
            "year": 2017,
            "rank_display": 223,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/sapienza-university-rome",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sapienza-university-of-rome_592560cf2aeae70239af4c97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 5960,
            "size": "XL",
            "faculty_count": 3287
        },
        {
            "university": "Queen's University at Kingston",
            "year": 2017,
            "rank_display": 223,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/queens-university-kingston",
            "country": "Canada",
            "city": "Kingston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-at-kingston_513_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3858,
            "size": "L",
            "faculty_count": 1287
        },
        {
            "university": "University of Tsukuba",
            "year": 2017,
            "rank_display": 225,
            "score": 43.8,
            "link": "https://www.topuniversities.com/universities/university-tsukuba",
            "country": "Japan",
            "city": "Tsukuba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tsukuba_592560cf2aeae70239af4cf7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2358,
            "size": "L",
            "faculty_count": 2073
        },
        {
            "university": "Simon Fraser University",
            "year": 2017,
            "rank_display": 226,
            "score": 43.6,
            "link": "https://www.topuniversities.com/universities/simon-fraser-university",
            "country": "Canada",
            "city": "Burnaby",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/simon-fraser-university_562_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 8051,
            "size": "L",
            "faculty_count": 1193
        },
        {
            "university": "King Saud University",
            "year": 2017,
            "rank_display": 227,
            "score": 43.4,
            "link": "https://www.topuniversities.com/universities/king-saud-university",
            "country": "Saudi Arabia",
            "city": "Riyadh",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-saud-university_1175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3896,
            "size": "XL",
            "faculty_count": 4216
        },
        {
            "university": "Université Paris 1 Panthéon-Sorbonne",
            "year": 2017,
            "rank_display": 228,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/universite-paris-1-pantheon-sorbonne",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-1-panthon-sorbonne_482_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7257,
            "size": "XL",
            "faculty_count": 1679
        },
        {
            "university": "Victoria University of Wellington",
            "year": 2017,
            "rank_display": 228,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/victoria-university-wellington",
            "country": "New Zealand",
            "city": "Kelburn, Wellington",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/victoria-university-of-wellington_592560cf2aeae70239af4d0f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 3668,
            "size": "L",
            "faculty_count": 1124
        },
        {
            "university": "American University of Beirut (AUB)",
            "year": 2017,
            "rank_display": 228,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/american-university-beirut-aub",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-beirut-aub_780_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1724,
            "size": "M",
            "faculty_count": 964
        },
        {
            "university": "Rheinische Friedrich-Wilhelms-Universität Bonn",
            "year": 2017,
            "rank_display": 231,
            "score": 43,
            "link": "https://www.topuniversities.com/universities/rheinische-friedrich-wilhelms-universitat-bonn",
            "country": "Germany",
            "city": "Bonn",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rheinische-friedrich-wilhelms-universitt-bonn_592560cf2aeae70239af4ac1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3276,
            "size": "L",
            "faculty_count": 4207
        },
        {
            "university": "Universität Hamburg",
            "year": 2017,
            "rank_display": 232,
            "score": 42.9,
            "link": "https://www.topuniversities.com/universities/universitat-hamburg",
            "country": "Germany",
            "city": "Hamburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-hamburg_592560cf2aeae70239af4b79_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3626,
            "size": "L",
            "faculty_count": 2032
        },
        {
            "university": "The University of Arizona",
            "year": 2017,
            "rank_display": 233,
            "score": 42.8,
            "link": "https://www.topuniversities.com/universities/university-arizona",
            "country": "United States",
            "city": "Tucson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-arizona_26_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3526,
            "size": "XL",
            "faculty_count": 5724
        },
        {
            "university": "University of Turku",
            "year": 2017,
            "rank_display": 234,
            "score": 42.7,
            "link": "https://www.topuniversities.com/universities/university-turku",
            "country": "Finland",
            "city": "Turku",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turku_592560cf2aeae70239af4cfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 604,
            "size": "L",
            "faculty_count": 1505
        },
        {
            "university": "Royal Holloway University of London",
            "year": 2017,
            "rank_display": 235,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/royal-holloway-university-london",
            "country": "United Kingdom",
            "city": "Egham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/royal-holloway-university-of-london_534_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.705,
            "size": "M",
            "faculty_count": 831
        },
        {
            "university": "Al-Farabi Kazakh National University",
            "year": 2017,
            "rank_display": 236,
            "score": 42.5,
            "link": "https://www.topuniversities.com/universities/al-farabi-kazakh-national-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/al-farabi-kazakh-national-university_2146_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3054,
            "size": "L",
            "faculty_count": 4047
        },
        {
            "university": "Loughborough University",
            "year": 2017,
            "rank_display": 237,
            "score": 42.3,
            "link": "https://www.topuniversities.com/universities/loughborough-university",
            "country": "United Kingdom",
            "city": "Loughborough",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/loughborough-university_592560cf2aeae70239af4bf3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4540,
            "size": "L",
            "faculty_count": 1445
        },
        {
            "university": "Tufts University",
            "year": 2017,
            "rank_display": 238,
            "score": 42.2,
            "link": "https://www.topuniversities.com/universities/tufts-university",
            "country": "United States",
            "city": "Medford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tufts-university_630_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1798,
            "size": "M",
            "faculty_count": 1459
        },
        {
            "university": "Complutense University of Madrid",
            "year": 2017,
            "rank_display": 239,
            "score": 42.1,
            "link": "https://www.topuniversities.com/universities/complutense-university-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/complutense-university-of-madrid_592560cf2aeae70239af4bff_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 7295,
            "size": "XL",
            "faculty_count": 4778
        },
        {
            "university": "University of Leicester",
            "year": 2017,
            "rank_display": 239,
            "score": 42.1,
            "link": "https://www.topuniversities.com/universities/university-leicester",
            "country": "United Kingdom",
            "city": "Leicester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leicester_339_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4919,
            "size": "L",
            "faculty_count": 1518
        },
        {
            "university": "National Cheng Kung University (NCKU)",
            "year": 2017,
            "rank_display": 241,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/national-cheng-kung-university-ncku",
            "country": "Taiwan",
            "city": "Tainan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-cheng-kung-university-ncku_435_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2656,
            "size": "L",
            "faculty_count": 2079
        },
        {
            "university": "Université Paris-Saclay",
            "year": 2017,
            "rank_display": 241,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/universite-paris-saclay",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-saclay_592560cf2aeae70239af4c6e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 7649,
            "size": "XL",
            "faculty_count": 8010
        },
        {
            "university": "National Taiwan University of Science and Technology (Taiwan Tech)",
            "year": 2017,
            "rank_display": 243,
            "score": 41.7,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-science-technology-taiwan-tech",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-of-science-and-technology-taiwan-tech_590_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1537,
            "size": "M",
            "faculty_count": 1005
        },
        {
            "university": "University of Dundee",
            "year": 2017,
            "rank_display": 244,
            "score": 41.6,
            "link": "https://www.topuniversities.com/universities/university-dundee",
            "country": "United Kingdom",
            "city": "Dundee",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/-university-of-dundee_592560cf2aeae70239af4b2c_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 3025,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "The University of Newcastle, Australia (UON)",
            "year": 2017,
            "rank_display": 245,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/university-newcastle-australia-uon",
            "country": "Australia",
            "city": "Callaghan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-newcastle-australia-uon_449_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1747,
            "size": "L",
            "faculty_count": 1135
        },
        {
            "university": "University of Navarra",
            "year": 2017,
            "rank_display": 245,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/university-navarra",
            "country": "Spain",
            "city": "Pamplona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-navarra_592560cf2aeae70239af4c40_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2978,
            "size": "M",
            "faculty_count": 1175
        },
        {
            "university": "Macquarie University",
            "year": 2017,
            "rank_display": 247,
            "score": 41.2,
            "link": "https://www.topuniversities.com/universities/macquarie-university",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/macquarie-university_378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8621,
            "size": "L",
            "faculty_count": 1766
        },
        {
            "university": "Technical University of Darmstadt",
            "year": 2017,
            "rank_display": 247,
            "score": 41.2,
            "link": "https://www.topuniversities.com/universities/technical-university-darmstadt",
            "country": "Germany",
            "city": "Darmstadt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-darmstadt_628_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3979,
            "size": "L",
            "faculty_count": 804
        },
        {
            "university": "Indian Institute of Technology Madras (IITM)",
            "year": 2017,
            "rank_display": 249,
            "score": 41,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-madras-iitm",
            "country": "India",
            "city": "Chennai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-madras-iitm_286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 94,
            "size": "M",
            "faculty_count": 884
        },
        {
            "university": "National University of Ireland Galway",
            "year": 2017,
            "rank_display": 249,
            "score": 41,
            "link": "https://www.topuniversities.com/universities/national-university-ireland-galway",
            "country": "Ireland",
            "city": "Galway",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-ireland-galway_220_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3208,
            "size": "L",
            "faculty_count": 1835
        },
        {
            "university": "University of Massachusetts Amherst",
            "year": 2017,
            "rank_display": 251,
            "score": 40.8,
            "link": "https://www.topuniversities.com/universities/university-massachusetts-amherst",
            "country": "United States",
            "city": "Amherst",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-massachusetts-amherst_592560cf2aeae70239af4c0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3696,
            "size": "L",
            "faculty_count": 1852
        },
        {
            "university": "Chulalongkorn University",
            "year": 2017,
            "rank_display": 252,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/chulalongkorn-university",
            "country": "Thailand",
            "city": "Bangkok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chulalongkorn-university_592560cf2aeae70239af4b01_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 985,
            "size": "XL",
            "faculty_count": 3354
        },
        {
            "university": "RMIT University",
            "year": 2017,
            "rank_display": 252,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/rmit-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/rmit-university_592560cf2aeae70239af4c95_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 11743,
            "size": "XL",
            "faculty_count": 1859
        },
        {
            "university": "SOAS University of London",
            "year": 2017,
            "rank_display": 252,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/soas-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/soas-university-of-london-_592560cf2aeae70239af4bf0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.398,
            "size": "S",
            "faculty_count": 300
        },
        {
            "university": "University of East Anglia (UEA)",
            "year": 2017,
            "rank_display": 252,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/university-east-anglia-uea",
            "country": "United Kingdom",
            "city": "Norwich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-east-anglia-uea_637_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3541,
            "size": "L",
            "faculty_count": 1206
        },
        {
            "university": "University of Miami",
            "year": 2017,
            "rank_display": 252,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/university-miami",
            "country": "United States",
            "city": "Miami",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-miami_401_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2646,
            "size": "L",
            "faculty_count": 2565
        },
        {
            "university": "Beijing Normal University",
            "year": 2017,
            "rank_display": 257,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/beijing-normal-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-normal-university_869_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3288,
            "size": "L",
            "faculty_count": 2098
        },
        {
            "university": "Saint Petersburg State University",
            "year": 2017,
            "rank_display": 258,
            "score": 40.1,
            "link": "https://www.topuniversities.com/universities/saint-petersburg-state-university",
            "country": "Russia",
            "city": "Saint Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-petersburg-state-university_592560cf2aeae70239af4cc0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4982,
            "size": "L",
            "faculty_count": 3054
        },
        {
            "university": "Norwegian University of Science And Technology",
            "year": 2017,
            "rank_display": 259,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/norwegian-university-science-technology",
            "country": "Norway",
            "city": "Trondheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/norwegian-university-of-science-and-technology_457_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.95,
            "size": "XL",
            "faculty_count": 3.132
        },
        {
            "university": "Université de Strasbourg",
            "year": 2017,
            "rank_display": 260,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universite-de-strasbourg",
            "country": "France",
            "city": "Strasbourg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-strasbourg_578_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 9.111,
            "size": "XL",
            "faculty_count": 2.544
        },
        {
            "university": "University of Surrey",
            "year": 2017,
            "rank_display": 261,
            "score": 39.5,
            "link": "https://www.topuniversities.com/universities/university-surrey",
            "country": "United Kingdom",
            "city": "Guildford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-surrey_584_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5556,
            "size": "L",
            "faculty_count": 1221
        },
        {
            "university": "Université Diderot  Paris 7",
            "year": 2017,
            "rank_display": 262,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/universite-diderot-paris-7",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-diderot-paris-7_592560cf2aeae70239af4c6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 11387,
            "size": "XL",
            "faculty_count": 3755
        },
        {
            "university": "Universität Stuttgart",
            "year": 2017,
            "rank_display": 263,
            "score": 39.3,
            "link": "https://www.topuniversities.com/universities/universitat-stuttgart",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-stuttgart_580_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.028,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "Goethe-University Frankfurt am Main",
            "year": 2017,
            "rank_display": 264,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/goethe-university-frankfurt-am-main",
            "country": "Germany",
            "city": "Frankfurt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-frankfurt-am-main_214_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6.749,
            "size": "XL",
            "faculty_count": 3.623
        },
        {
            "university": "University of Gothenburg",
            "year": 2017,
            "rank_display": 264,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/university-gothenburg",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gothenburg_233_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5652,
            "size": "L",
            "faculty_count": 2680
        },
        {
            "university": "Kyung Hee University",
            "year": 2017,
            "rank_display": 264,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/kyung-hee-university",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyung-hee-university_592560cf2aeae70239af4bcd_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4715,
            "size": "L",
            "faculty_count": 3435
        },
        {
            "university": "ENS Paris-Saclay",
            "year": 2017,
            "rank_display": 264,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/ens-paris-saclay",
            "country": "France",
            "city": "Cachan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-de-cachan_1878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 298,
            "size": "S",
            "faculty_count": 289
        },
        {
            "university": "Ecole des Ponts ParisTech",
            "year": 2017,
            "rank_display": 268,
            "score": 39.1,
            "link": "https://www.topuniversities.com/universities/ecole-des-ponts-paristech",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-des-ponts-paristech_187_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 735,
            "size": "S",
            "faculty_count": 338
        },
        {
            "university": "Universidad Nacional de Colombia",
            "year": 2017,
            "rank_display": 269,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-de-colombia_592560cf2aeae70239af4b0b_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 335,
            "size": "XL",
            "faculty_count": 3055
        },
        {
            "university": "Universiti Putra Malaysia (UPM)",
            "year": 2017,
            "rank_display": 270,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/universiti-putra-malaysia-upm",
            "country": "Malaysia",
            "city": "Seri Kembangan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-putra-malaysia-upm_385_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5040,
            "size": "L",
            "faculty_count": 2174
        },
        {
            "university": "University of California, Riverside",
            "year": 2017,
            "rank_display": 271,
            "score": 38.7,
            "link": "https://www.topuniversities.com/universities/university-california-riverside",
            "country": "United States",
            "city": "Riverside",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-riverside_88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.034,
            "size": "L",
            "faculty_count": 1.497
        },
        {
            "university": "Universidad de los Andes",
            "year": 2017,
            "rank_display": 272,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/universidad-de-los-andes",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-los-andes_592560cf2aeae70239af4bf6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 13,
            "international_students": 151,
            "size": "L",
            "faculty_count": 1223
        },
        {
            "university": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            "year": 2017,
            "rank_display": 272,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/friedrich-alexander-universitat-erlangen-nurnberg",
            "country": "Germany",
            "city": "Erlangen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/friedrich-alexander-universitt-erlangen-nrnberg_191_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 4943,
            "size": "XL",
            "faculty_count": 1107
        },
        {
            "university": "University of Strathclyde",
            "year": 2017,
            "rank_display": 272,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/university-strathclyde",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-strathclyde_579_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5397,
            "size": "L",
            "faculty_count": 1532
        },
        {
            "university": "Wuhan University",
            "year": 2017,
            "rank_display": 275,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/wuhan-university",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/wuhan-university_889_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3682,
            "size": "XL",
            "faculty_count": 3828
        },
        {
            "university": "Queensland University of Technology (QUT)",
            "year": 2017,
            "rank_display": 276,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/queensland-university-technology-qut",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/queensland-university-of-technology-qut_592560cf2aeae70239af4c88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6403,
            "size": "XL",
            "faculty_count": 2028
        },
        {
            "university": "North Carolina State University",
            "year": 2017,
            "rank_display": 277,
            "score": 38,
            "link": "https://www.topuniversities.com/universities/north-carolina-state-university",
            "country": "United States",
            "city": "Raleigh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/north-carolina-state-university_592560cf2aeae70239af4c2a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 3156,
            "size": "L",
            "faculty_count": 1944
        },
        {
            "university": "Harbin Institute of Technology",
            "year": 2017,
            "rank_display": 278,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/harbin-institute-technology",
            "country": "China (Mainland)",
            "city": "Harbin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/harbin-institute-of-technology_877_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2102,
            "size": "XL",
            "faculty_count": 3215
        },
        {
            "university": "Hong Kong Baptist University",
            "year": 2017,
            "rank_display": 278,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/hong-kong-baptist-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hong-kong-baptist-university_893_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4704,
            "size": "M",
            "faculty_count": 980
        },
        {
            "university": "Universidad Carlos III de Madrid (UC3M)",
            "year": 2017,
            "rank_display": 280,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/universidad-carlos-iii-de-madrid-uc3m",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-carlos-iii-de-madrid-uc3m_1509_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.11,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "Birkbeck, University of London",
            "year": 2017,
            "rank_display": 280,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/birkbeck-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/birkbeck-university-of-london_1636_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3.214,
            "size": "M",
            "faculty_count": 585
        },
        {
            "university": "Linköping University",
            "year": 2017,
            "rank_display": 282,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/linkoping-university",
            "country": "Sweden",
            "city": "Linköping",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/linkping-university_348_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.514,
            "size": "L",
            "faculty_count": 1.471
        },
        {
            "university": "Mahidol University",
            "year": 2017,
            "rank_display": 283,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/mahidol-university",
            "country": "Thailand",
            "city": "Nakhon Pathom",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/mahidol-university_381_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1415,
            "size": "L",
            "faculty_count": 3850
        },
        {
            "university": "Universitat Pompeu Fabra (Barcelona)",
            "year": 2017,
            "rank_display": 283,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/universitat-pompeu-fabra",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-pompeu-fabra_504_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1633,
            "size": "L",
            "faculty_count": 785
        },
        {
            "university": "University College Cork",
            "year": 2017,
            "rank_display": 283,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/university-college-cork",
            "country": "Ireland",
            "city": "Cork",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-cork_142_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 3002,
            "size": "L",
            "faculty_count": 1619
        },
        {
            "university": "King Abdulaziz University (KAU)",
            "year": 2017,
            "rank_display": 283,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/king-abdulaziz-university-kau",
            "country": "Saudi Arabia",
            "city": "Jeddah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-abdulaziz-university-kau_1172_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6821,
            "size": "XL",
            "faculty_count": 4054
        },
        {
            "university": "Dalhousie University",
            "year": 2017,
            "rank_display": 283,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/dalhousie-university",
            "country": "Canada",
            "city": "Halifax",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dalhousie-university_151_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4483,
            "size": "L",
            "faculty_count": 1150
        },
        {
            "university": "University of South Australia",
            "year": 2017,
            "rank_display": 288,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/university-south-australia",
            "country": "Australia",
            "city": "Magill",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-south-australia_566_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.886,
            "size": "L",
            "faculty_count": 1.524
        },
        {
            "university": "Universiti Teknologi Malaysia",
            "year": 2017,
            "rank_display": 288,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-malaysia",
            "country": "Malaysia",
            "city": "Skudai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-malaysia_387_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4956,
            "size": "L",
            "faculty_count": 1869
        },
        {
            "university": "University of St.Gallen (HSG)",
            "year": 2017,
            "rank_display": 288,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/university-stgallen-hsg",
            "country": "Switzerland",
            "city": "St. Gallen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st.gallen-hsg_571_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 2.39,
            "size": "M",
            "faculty_count": 444
        },
        {
            "university": "Indiana University Bloomington",
            "year": 2017,
            "rank_display": 291,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/indiana-university-bloomington",
            "country": "United States",
            "city": "Bloomington",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/indiana-university-bloomington_289_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3722,
            "size": "XL",
            "faculty_count": 3837
        },
        {
            "university": "University of Ottawa",
            "year": 2017,
            "rank_display": 291,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/university-ottawa",
            "country": "Canada",
            "city": "Ottawa",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-ottawa_475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 8885,
            "size": "XL",
            "faculty_count": 1677
        },
        {
            "university": "Novosibirsk State University",
            "year": 2017,
            "rank_display": 291,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/novosibirsk-state-university",
            "country": "Russia",
            "city": "Novosibirsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/novosibirsk-state-university_462_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1623,
            "size": "M",
            "faculty_count": 1218
        },
        {
            "university": "Westfälische Wilhelms-Universität Münster",
            "year": 2017,
            "rank_display": 294,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/westfalische-wilhelms-universitat-munster",
            "country": "Germany",
            "city": "Münster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/westflische-wilhelms-universitt-mnster_421_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.845,
            "size": "XL",
            "faculty_count": 4.23
        },
        {
            "university": "Umea University",
            "year": 2017,
            "rank_display": 294,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/umea-university",
            "country": "Sweden",
            "city": "Umeå",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/umea-university_639_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.186,
            "size": "L",
            "faculty_count": 1.966
        },
        {
            "university": "University of California, Santa Cruz",
            "year": 2017,
            "rank_display": 296,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/university-california-santa-cruz",
            "country": "United States",
            "city": "Santa Cruz",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-cruz_92_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 2.13,
            "size": "L",
            "faculty_count": 1.045
        },
        {
            "university": "Sun Yat-sen University",
            "year": 2017,
            "rank_display": 297,
            "score": 37,
            "link": "https://www.topuniversities.com/universities/sun-yat-sen-university",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sun-yat-sen-university_888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3127,
            "size": "XL",
            "faculty_count": 7002
        },
        {
            "university": "Hiroshima University",
            "year": 2017,
            "rank_display": 297,
            "score": 37,
            "link": "https://www.topuniversities.com/universities/hiroshima-university",
            "country": "Japan",
            "city": "Higashihiroshima City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hiroshima-university_264_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.68,
            "size": "L",
            "faculty_count": 2.366
        },
        {
            "university": "Universität Innsbruck",
            "year": 2017,
            "rank_display": 299,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/universitat-innsbruck",
            "country": "Austria",
            "city": "Innsbruck",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-innsbruck_592560cf2aeae70239af4ba7_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 6323,
            "size": "L",
            "faculty_count": 1769
        },
        {
            "university": "Boston College",
            "year": 2017,
            "rank_display": 299,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/boston-college",
            "country": "United States",
            "city": "Newton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-college_723_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.471,
            "size": "L",
            "faculty_count": 1.048
        },
        {
            "university": "Rutgers University–New Brunswick",
            "year": 2017,
            "rank_display": 301,
            "score": 36.8,
            "link": "https://www.topuniversities.com/universities/rutgers-university-new-brunswick",
            "country": "United States",
            "city": "New Brunswick",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rutgers-university-new-brunswick_538_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7965,
            "size": "XL",
            "faculty_count": 5009
        },
        {
            "university": "Charles University",
            "year": 2017,
            "rank_display": 302,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/charles-university",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/charles-university_117_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9080,
            "size": "XL",
            "faculty_count": 5019
        },
        {
            "university": "Universiti Kebangsaan Malaysia (UKM)",
            "year": 2017,
            "rank_display": 302,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/universiti-kebangsaan-malaysia-ukm",
            "country": "Malaysia",
            "city": "Bangi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-kebangsaan-malaysia-ukm_384_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2410,
            "size": "L",
            "faculty_count": 2829
        },
        {
            "university": "Indian Institute of Technology Kanpur (IITK)",
            "year": 2017,
            "rank_display": 302,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kanpur-iitk",
            "country": "India",
            "city": "Kanpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kanpur-iitk_592560cf2aeae70239af4ba5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 29,
            "size": "M",
            "faculty_count": 551
        },
        {
            "university": "Politecnico di Torino",
            "year": 2017,
            "rank_display": 305,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/politecnico-di-torino",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-torino_633_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 5.369,
            "size": "XL",
            "faculty_count": 1.05
        },
        {
            "university": "Curtin University",
            "year": 2017,
            "rank_display": 306,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/curtin-university",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/curtin-university_150_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4565,
            "size": "L",
            "faculty_count": 1698
        },
        {
            "university": "Bauman Moscow State Technical University",
            "year": 2017,
            "rank_display": 306,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/bauman-moscow-state-technical-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bauman-moscow-state-technical-university_592560cf2aeae70239af505c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2370,
            "size": "L",
            "faculty_count": 4349
        },
        {
            "university": "Universidad Austral",
            "year": 2017,
            "rank_display": 308,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/universidad-austral",
            "country": "Argentina",
            "city": "Pilar",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-austral_38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 334,
            "size": "S",
            "faculty_count": 663
        },
        {
            "university": "National Yang Ming University",
            "year": 2017,
            "rank_display": 308,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/national-yang-ming-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-yang-ming-university_444_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 237,
            "size": "S",
            "faculty_count": 965
        },
        {
            "university": "National Taiwan Normal University",
            "year": 2017,
            "rank_display": 310,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/national-taiwan-normal-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-normal-university_440_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.499,
            "size": "M",
            "faculty_count": 1.374
        },
        {
            "university": "Pontificia Universidad Católica Argentina",
            "year": 2017,
            "rank_display": 310,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-argentina",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-argentina_592560cf2aeae70239af5486_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 4,
            "international_students": 1063,
            "size": "L",
            "faculty_count": 3805
        },
        {
            "university": "Rensselaer Polytechnic Institute",
            "year": 2017,
            "rank_display": 310,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/rensselaer-polytechnic-institute",
            "country": "United States",
            "city": "Troy",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rensselaer-polytechnic-institute_522_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.594,
            "size": "M",
            "faculty_count": 639
        },
        {
            "university": "Indian Institute of Technology Kharagpur (IIT-KGP)",
            "year": 2017,
            "rank_display": 313,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kharagpur-iit-kgp",
            "country": "India",
            "city": "Kharagpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kharagpur-iit-kgp_285_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 27,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "City, University of London",
            "year": 2017,
            "rank_display": 314,
            "score": 35.8,
            "link": "https://www.topuniversities.com/universities/city-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-london_354_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 7.362,
            "size": "L",
            "faculty_count": 970
        },
        {
            "university": "Nankai University",
            "year": 2017,
            "rank_display": 315,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/nankai-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nankai-university_882_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.488,
            "size": "XL",
            "faculty_count": 2.846
        },
        {
            "university": "Tongji University",
            "year": 2017,
            "rank_display": 315,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/tongji-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tongji-university_617_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5095,
            "size": "XL",
            "faculty_count": 2785
        },
        {
            "university": "Université de Liège",
            "year": 2017,
            "rank_display": 315,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/universite-de-liege",
            "country": "Belgium",
            "city": "Arlon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-lige_592560cf2aeae70239af4bd9_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.381,
            "size": "L",
            "faculty_count": 1.619
        },
        {
            "university": "Xi’an Jiaotong University",
            "year": 2017,
            "rank_display": 318,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/xian-jiaotong-university",
            "country": "China (Mainland)",
            "city": "Xi'an",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xian-jiaotong-university_683_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2266,
            "size": "XL",
            "faculty_count": 3729
        },
        {
            "university": "Tampere University of Technology",
            "year": 2017,
            "rank_display": 319,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/tampere-university-technology",
            "country": "Finland",
            "city": "Tampere",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tampere-university-of-technology_1269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "M",
            "faculty_count": 665
        },
        {
            "university": "Ben-Gurion University of The Negev",
            "year": 2017,
            "rank_display": 320,
            "score": 35.4,
            "link": "https://www.topuniversities.com/universities/ben-gurion-university-negev",
            "country": "Israel",
            "city": "Be'er Sheva",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ben-gurion-university-of-the-negev_592560cf2aeae70239af4ab7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.755,
            "size": "L",
            "faculty_count": 1.06
        },
        {
            "university": "Universidade Federal do Rio de Janeiro",
            "year": 2017,
            "rank_display": 321,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/universidade-federal-do-rio-de-janeiro",
            "country": "Brazil",
            "city": "Rio de Janeiro",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-do-rio-de-janeiro_525_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 555,
            "size": "XL",
            "faculty_count": 3.983
        },
        {
            "university": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
            "year": 2017,
            "rank_display": 321,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-catalunya-barcelonatech-upc",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-catalunya_103_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3101,
            "size": "L",
            "faculty_count": 2485
        },
        {
            "university": "University of Porto",
            "year": 2017,
            "rank_display": 323,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/university-porto",
            "country": "Portugal",
            "city": "Porto",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-porto_760_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3783,
            "size": "L",
            "faculty_count": 2028
        },
        {
            "university": "University of Waikato",
            "year": 2017,
            "rank_display": 324,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/university-waikato",
            "country": "New Zealand",
            "city": "Hamilton",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waikato_660_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.27,
            "size": "M",
            "faculty_count": 595
        },
        {
            "university": "Universitas Indonesia",
            "year": 2017,
            "rank_display": 325,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/universitas-indonesia",
            "country": "Indonesia",
            "city": "Depok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universitas-indonesia_290_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1412,
            "size": "XL",
            "faculty_count": 4210
        },
        {
            "university": "University of Victoria (UVic)",
            "year": 2017,
            "rank_display": 325,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/university-victoria-uvic",
            "country": "Canada",
            "city": "Victoria",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-victoria-uvic_592560cf2aeae70239af4d0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 6.135,
            "size": "L",
            "faculty_count": 865
        },
        {
            "university": "Université de Montpellier",
            "year": 2017,
            "rank_display": 327,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/universite-de-montpellier",
            "country": "France",
            "city": "Montpellier",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montpellier_592560e69988f300e2321d99_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6.229,
            "size": "XL",
            "faculty_count": 2.464
        },
        {
            "university": "Heriot-Watt University",
            "year": 2017,
            "rank_display": 327,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/heriot-watt-university",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/heriot-watt-university_592560cf2aeae70239af4d5f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3536,
            "size": "M",
            "faculty_count": 806
        },
        {
            "university": "Tokyo Medical and Dental University (TMDU)",
            "year": 2017,
            "rank_display": 329,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/tokyo-medical-dental-university-tmdu",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-medical-and-dental-university-tmdu_592560cf2aeae70239af4e8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 325,
            "size": "S",
            "faculty_count": 930
        },
        {
            "university": "University of Lisbon",
            "year": 2017,
            "rank_display": 330,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/university-lisbon",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lisbon_1475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8.546,
            "size": "XL",
            "faculty_count": 3.389
        },
        {
            "university": "Universiti Sains Malaysia (USM)",
            "year": 2017,
            "rank_display": 330,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/universiti-sains-malaysia-usm",
            "country": "Malaysia",
            "city": "Gelugor",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-sains-malaysia-usm_386_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4198,
            "size": "L",
            "faculty_count": 2249
        },
        {
            "university": "Tilburg University",
            "year": 2017,
            "rank_display": 330,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/tilburg-university",
            "country": "Netherlands",
            "city": "Tilburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tilburg-university_592560cf2aeae70239af4ce5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.189,
            "size": "M",
            "faculty_count": 745
        },
        {
            "university": "Essex, University of",
            "year": 2017,
            "rank_display": 330,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/essex-university",
            "country": "United Kingdom",
            "city": "Colchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-essex_592560cf2aeae70239af4b4c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6.124,
            "size": "L",
            "faculty_count": 1.044
        },
        {
            "university": "Yeshiva University",
            "year": 2017,
            "rank_display": 330,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/yeshiva-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-katz-school-at-yeshiva-university_685_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 467,
            "size": "S",
            "faculty_count": 471
        },
        {
            "university": "Ewha Womans University",
            "year": 2017,
            "rank_display": 335,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/ewha-womans-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ewha-womans-university_592560cf2aeae70239af4b38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.105,
            "size": "L",
            "faculty_count": 2.436
        },
        {
            "university": "Griffith University",
            "year": 2017,
            "rank_display": 336,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/griffith-university",
            "country": "Australia",
            "city": "Nathan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/griffith-university_242_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5875,
            "size": "XL",
            "faculty_count": 1918
        },
        {
            "university": "Gwangju Institute of Science and Technology (GIST)",
            "year": 2017,
            "rank_display": 337,
            "score": 34.3,
            "link": "https://www.topuniversities.com/universities/gwangju-institute-science-technology-gist",
            "country": "South Korea",
            "city": "Gwangju",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gwangju-institute-of-science-and-technology-gist_718_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 122,
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "Università di Padova",
            "year": 2017,
            "rank_display": 338,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universita-di-padova",
            "country": "Italy",
            "city": "Padua",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitdi-padova_480_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2654,
            "size": "XL",
            "faculty_count": 2806
        },
        {
            "university": "University of Jyväskylä",
            "year": 2017,
            "rank_display": 338,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/university-jyvaskyla",
            "country": "Finland",
            "city": "Jyväskylä",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-jyvskyl_306_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 339,
            "size": "M",
            "faculty_count": 1.573
        },
        {
            "university": "Massey University",
            "year": 2017,
            "rank_display": 340,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/massey-university",
            "country": "New Zealand",
            "city": "Palmerston North",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/massey-university_395_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4836,
            "size": "M",
            "faculty_count": 1209
        },
        {
            "university": "James Cook University",
            "year": 2017,
            "rank_display": 340,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/james-cook-university",
            "country": "Australia",
            "city": "Townsville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/james-cook-university_833_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.169,
            "size": "M",
            "faculty_count": 2940
        },
        {
            "university": "University at Buffalo SUNY",
            "year": 2017,
            "rank_display": 342,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/university-buffalo-suny",
            "country": "United States",
            "city": "Buffalo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-at-buffalo-suny_1888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4.409,
            "size": "L",
            "faculty_count": 1.53
        },
        {
            "university": "Lincoln University",
            "year": 2017,
            "rank_display": 343,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/lincoln-university",
            "country": "New Zealand",
            "city": "Lincoln",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/lincoln-university-_592560cf2aeae70239af4ef3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 898,
            "size": "S",
            "faculty_count": 643
        },
        {
            "university": "University of Hawaiʻi at Mānoa",
            "year": 2017,
            "rank_display": 343,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/university-hawaii-manoa",
            "country": "United States",
            "city": "Honolulu",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hawaii-at-maoa_255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.586,
            "size": "L",
            "faculty_count": 1.64
        },
        {
            "university": "Brunel University London",
            "year": 2017,
            "rank_display": 345,
            "score": 33.8,
            "link": "https://www.topuniversities.com/universities/brunel-university-london",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/brunel-university-london_592560cf2aeae70239af4acc_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 5.305,
            "size": "L",
            "faculty_count": 860
        },
        {
            "university": "L.N. Gumilyov Eurasian National University (ENU)",
            "year": 2017,
            "rank_display": 345,
            "score": 33.8,
            "link": "https://www.topuniversities.com/universities/ln-gumilyov-eurasian-national-university-enu",
            "country": "Kazakhstan",
            "city": "Nur-Sultan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/l.n.-gumilyov-eurasian-national-university-enu_592560cf2aeae70239af52e8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1.077,
            "size": "L",
            "faculty_count": 3.35
        },
        {
            "university": "University of Cologne",
            "year": 2017,
            "rank_display": 347,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/university-cologne",
            "country": "Germany",
            "city": "Cologne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cologne_592560cf2aeae70239af4bc6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4890,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "University of Tartu",
            "year": 2017,
            "rank_display": 347,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/university-tartu",
            "country": "Estonia",
            "city": "Tartu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tartu_592560cf2aeae70239af4d08_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1400,
            "size": "M",
            "faculty_count": 1605
        },
        {
            "university": "University Ulm",
            "year": 2017,
            "rank_display": 347,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-ulm",
            "country": "Germany",
            "city": "Ulm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-ulm_638_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1289,
            "size": "M",
            "faculty_count": 1473
        },
        {
            "university": "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
            "year": 2017,
            "rank_display": 350,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/moscow-institute-physics-technology-mipt-moscow-phystech",
            "country": "Russia",
            "city": "Dolgoprudny",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-institute-of-physics-and-technology-mipt-moscow-phystech_15270_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1153,
            "size": "M",
            "faculty_count": 1110
        },
        {
            "university": "MGIMO University",
            "year": 2017,
            "rank_display": 350,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/mgimo-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-state-institute-of-international-relations-mgimo-university_2156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 1517,
            "size": "M",
            "faculty_count": 1739
        },
        {
            "university": "Universität Konstanz",
            "year": 2017,
            "rank_display": 352,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/universitat-konstanz",
            "country": "Germany",
            "city": "Konstanz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-konstanz_323_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.239,
            "size": "M",
            "faculty_count": 1.039
        },
        {
            "university": "Universidad de Belgrano",
            "year": 2017,
            "rank_display": 352,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/universidad-de-belgrano",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-belgrano_51_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 758,
            "size": "M",
            "faculty_count": 810
        },
        {
            "university": "Belarusian State University",
            "year": 2017,
            "rank_display": 354,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/belarusian-state-university",
            "country": "Belarus",
            "city": "Minsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/belarusian-state-university_14128_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4264,
            "size": "L",
            "faculty_count": 4173
        },
        {
            "university": "Deakin University",
            "year": 2017,
            "rank_display": 355,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/deakin-university",
            "country": "Australia",
            "city": "Geelong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/download-48x48.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 10684,
            "size": "XL",
            "faculty_count": 1851
        },
        {
            "university": "Université Paris-Dauphine, Université PSL",
            "year": 2017,
            "rank_display": 356,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/universite-paris-dauphine-universite-psl",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-dauphine-universit-psl_592560cf2aeae70239af4c68_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 12,
            "international_students": 2039,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "Aston University",
            "year": 2017,
            "rank_display": 358,
            "score": 32.7,
            "link": "https://www.topuniversities.com/universities/aston-university",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aston-university_29_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1.719,
            "size": "M",
            "faculty_count": 730
        },
        {
            "university": "University of Witwatersrand",
            "year": 2017,
            "rank_display": 359,
            "score": 32.6,
            "link": "https://www.topuniversities.com/universities/university-witwatersrand",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-witwatersrand_679_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.885,
            "size": "L",
            "faculty_count": 1.626
        },
        {
            "university": "Oxford Brookes University",
            "year": 2017,
            "rank_display": 359,
            "score": 32.6,
            "link": "https://www.topuniversities.com/universities/oxford-brookes-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/oxford-brookes-university_592560cf2aeae70239af4c61_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 15,
            "international_students": 3.439,
            "size": "L",
            "faculty_count": 1471
        },
        {
            "university": "Virginia Polytechnic Institute and State University",
            "year": 2017,
            "rank_display": 361,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/virginia-polytechnic-institute-state-university",
            "country": "United States",
            "city": "Blacksburg",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/virginia-polytechnic-institute-and-state-university_592560cf2aeae70239af4d15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.477,
            "size": "XL",
            "faculty_count": 2.802
        },
        {
            "university": "Northeastern University",
            "year": 2017,
            "rank_display": 361,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/northeastern-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northeastern-university_454_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7.595,
            "size": "L",
            "faculty_count": 1.723
        },
        {
            "university": "George Washington University",
            "year": 2017,
            "rank_display": 363,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/george-washington-university",
            "country": "United States",
            "city": "Ashburn",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/george-washington-university_223_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4.024,
            "size": "L",
            "faculty_count": 2.016
        },
        {
            "university": "Universität Jena",
            "year": 2017,
            "rank_display": 363,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/universitat-jena",
            "country": "Germany",
            "city": "Jena",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-jena_592560cf2aeae70239af4bb5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2.307,
            "size": "L",
            "faculty_count": 3.974
        },
        {
            "university": "The American University in Cairo",
            "year": 2017,
            "rank_display": 365,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/american-university-cairo",
            "country": "Egypt",
            "city": "Cairo",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/the-american-university-in-cairo_36_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 213,
            "size": "M",
            "faculty_count": 578
        },
        {
            "university": "University of Warsaw",
            "year": 2017,
            "rank_display": 366,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/university-warsaw",
            "country": "Poland",
            "city": "Warsaw",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-warsaw_663_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2892,
            "size": "XL",
            "faculty_count": 3658
        },
        {
            "university": "Universidade Nova de Lisboa",
            "year": 2017,
            "rank_display": 366,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/universidade-nova-de-lisboa",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-nova-de-lisboa_460_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.427,
            "size": "L",
            "faculty_count": 1.72
        },
        {
            "university": "University of Kent",
            "year": 2017,
            "rank_display": 366,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/university-kent",
            "country": "United Kingdom",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kent_315_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.515,
            "size": "L",
            "faculty_count": 4000
        },
        {
            "university": "Kobe University",
            "year": 2017,
            "rank_display": 369,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/kobe-university",
            "country": "Japan",
            "city": "Kobe City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kobe-university_320_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.216,
            "size": "L",
            "faculty_count": 1.986
        },
        {
            "university": "University of Milan",
            "year": 2017,
            "rank_display": 370,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/university-milan",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milan_1873_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3269,
            "size": "XL",
            "faculty_count": 2516
        },
        {
            "university": "University of Tasmania",
            "year": 2017,
            "rank_display": 370,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/university-tasmania",
            "country": "Australia",
            "city": "Hobart",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tasmania_592560cf2aeae70239af4cd5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5260,
            "size": "L",
            "faculty_count": 982
        },
        {
            "university": "Université Laval",
            "year": 2017,
            "rank_display": 372,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/universite-laval",
            "country": "Canada",
            "city": "Québec",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/laval-university_337_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3.673,
            "size": "L",
            "faculty_count": 2.29
        },
        {
            "university": "University of Kansas",
            "year": 2017,
            "rank_display": 373,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/university-kansas",
            "country": "United States",
            "city": "Lawrence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kansas_309_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2.014,
            "size": "L",
            "faculty_count": 3.357
        },
        {
            "university": "University of the Philippines",
            "year": 2017,
            "rank_display": 374,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/university-philippines",
            "country": "Philippines",
            "city": "Quezon City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-philippines_498_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 292,
            "size": "XL",
            "faculty_count": 4.48
        },
        {
            "university": "Aalborg University",
            "year": 2017,
            "rank_display": 374,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/aalborg-university",
            "country": "Denmark",
            "city": "Aalborg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalborg-university_1262_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.474,
            "size": "L",
            "faculty_count": 1.716
        },
        {
            "university": "University of Colorado, Denver",
            "year": 2017,
            "rank_display": 374,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/university-colorado-denver",
            "country": "United States",
            "city": "Denver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-denver_2116_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 1.274,
            "size": "L",
            "faculty_count": 5.319
        },
        {
            "university": "Université Paris Descartes",
            "year": 2017,
            "rank_display": 377,
            "score": "",
            "link": "https://www.topuniversities.com/universities/universite-paris-descartes",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-descartes_486_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 11387,
            "size": "XL",
            "faculty_count": 3755
        },
        {
            "university": "Tomsk State University",
            "year": 2017,
            "rank_display": 377,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/tomsk-state-university",
            "country": "Russia",
            "city": "Tomsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tomsk-state-university_616_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2828,
            "size": "M",
            "faculty_count": 1742
        },
        {
            "university": "University of Tromsø The Arctic University of Norway",
            "year": 2017,
            "rank_display": 377,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-tromso-arctic-university-norway",
            "country": "Norway",
            "city": "Tromsø",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-troms-the-arctic-university-of-norway_625_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.134,
            "size": "L",
            "faculty_count": 2.107
        },
        {
            "university": "Dublin City University",
            "year": 2017,
            "rank_display": 380,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/dublin-city-university",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/dublin-city-university_592560cf2aeae70239af4b27_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.415,
            "size": "L",
            "faculty_count": 1.257
        },
        {
            "university": "University of Texas Dallas",
            "year": 2017,
            "rank_display": 380,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/university-texas-dallas",
            "country": "United States",
            "city": "Richardson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-dallas_592560cf2aeae70239af5355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 4.828,
            "size": "L",
            "faculty_count": 1.14
        },
        {
            "university": "University of Eastern Finland",
            "year": 2017,
            "rank_display": 382,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/university-eastern-finland",
            "country": "Finland",
            "city": "Kuopio",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-eastern-finland_592560cf2aeae70239af4bcb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 669,
            "size": "M",
            "faculty_count": 1.207
        },
        {
            "university": "V. N. Karazin Kharkiv National University",
            "year": 2017,
            "rank_display": 382,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/v-n-karazin-kharkiv-national-university",
            "country": "Ukraine",
            "city": "Kharkiv",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/v.-n.-karazin-kharkiv-national-university_19511_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3.954,
            "size": "L",
            "faculty_count": 2.091
        },
        {
            "university": "American University",
            "year": 2017,
            "rank_display": 384,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/american-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university_17_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.305,
            "size": "M",
            "faculty_count": 1.091
        },
        {
            "university": "University of Stirling",
            "year": 2017,
            "rank_display": 385,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/university-stirling",
            "country": "United Kingdom",
            "city": "Stirling",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-stirling_575_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.563,
            "size": "M",
            "faculty_count": 728
        },
        {
            "university": "Colorado State University",
            "year": 2017,
            "rank_display": 386,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/colorado-state-university",
            "country": "United States",
            "city": "Fort Collins",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/colorado-state-university_136_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.547,
            "size": "L",
            "faculty_count": 3.115
        },
        {
            "university": "Chung-Ang University (CAU)",
            "year": 2017,
            "rank_display": 386,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/chung-ang-university-cau",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chung-ang-university-cau_1042_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3.099,
            "size": "L",
            "faculty_count": 2.65
        },
        {
            "university": "La Trobe University",
            "year": 2017,
            "rank_display": 386,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/la-trobe-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/la-trobe-university_592560cf2aeae70239af4bd1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 9.456,
            "size": "L",
            "faculty_count": 1.252
        },
        {
            "university": "Beijing Institute of Technology",
            "year": 2017,
            "rank_display": 389,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/beijing-institute-technology",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-institute-of-technology_868_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.115,
            "size": "XL",
            "faculty_count": 2.811
        },
        {
            "university": "Stony Brook University, State University of New York",
            "year": 2017,
            "rank_display": 390,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/stony-brook-university-state-university-new-york",
            "country": "United States",
            "city": "Stony Brook",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stony-brook-university-state-university-of-new-york_592560cf2aeae70239af4ccb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6.198,
            "size": "L",
            "faculty_count": 2.316
        },
        {
            "university": "Swansea University",
            "year": 2017,
            "rank_display": 390,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/swansea-university",
            "country": "United Kingdom",
            "city": "Swansea",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/swansea-university_592560cf2aeae70239af4cce_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 3.641,
            "size": "L",
            "faculty_count": 3290
        },
        {
            "university": "University of Southern Denmark (SDU)",
            "year": 2017,
            "rank_display": 390,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/university-southern-denmark-sdu",
            "country": "Denmark",
            "city": "Odense",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-denmark_592560cf2aeae70239af4c53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2511,
            "size": "L",
            "faculty_count": 1760
        },
        {
            "university": "University of Iowa",
            "year": 2017,
            "rank_display": 393,
            "score": 30.6,
            "link": "https://www.topuniversities.com/universities/university-iowa",
            "country": "United States",
            "city": "Iowa City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-iowa_592560cf2aeae70239af4baa_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.049,
            "size": "L",
            "faculty_count": 2.924
        },
        {
            "university": "Qatar University",
            "year": 2017,
            "rank_display": 393,
            "score": 30.6,
            "link": "https://www.topuniversities.com/universities/qatar-university",
            "country": "Qatar",
            "city": "Doha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/qatar-university_2537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3197,
            "size": "M",
            "faculty_count": 1163
        },
        {
            "university": "Stellenbosch University",
            "year": 2017,
            "rank_display": 395,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/stellenbosch-university",
            "country": "South Africa",
            "city": "Stellenbosch",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/stellenbosch-university_574_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 1.776,
            "size": "L",
            "faculty_count": 934
        },
        {
            "university": "Washington State University",
            "year": 2017,
            "rank_display": 395,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/washington-state-university",
            "country": "United States",
            "city": "Pullman",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-state-university_666_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.731,
            "size": "L",
            "faculty_count": 1.935
        },
        {
            "university": "National Sun Yat-sen University",
            "year": 2017,
            "rank_display": 395,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/national-sun-yat-sen-university",
            "country": "Taiwan",
            "city": "Kaohsiung City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-sun-yat-sen-university_439_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 757,
            "size": "M",
            "faculty_count": 735
        },
        {
            "university": "National Technical University of Athens",
            "year": 2017,
            "rank_display": 395,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/national-technical-university-athens",
            "country": "Greece",
            "city": "Athens",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-technical-university-of-athens_592560cf2aeae70239af4aa3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 846,
            "size": "L",
            "faculty_count": 689
        },
        {
            "university": "Indian Institute of Technology Roorkee (IITR)",
            "year": 2017,
            "rank_display": 399,
            "score": 30.4,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-roorkee-iitr",
            "country": "India",
            "city": "Roorkee",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-roorkee-iitr_287_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 202,
            "size": "M",
            "faculty_count": 499
        },
        {
            "university": "Leibniz University Hannover",
            "year": 2017,
            "rank_display": 400,
            "score": 30.3,
            "link": "https://www.topuniversities.com/universities/leibniz-university-hannover",
            "country": "Germany",
            "city": "Hanover",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leibniz-universitt-hannover_249_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4.157,
            "size": "L",
            "faculty_count": 2.54
        },
        {
            "university": "National Research Tomsk Polytechnic University",
            "year": 2017,
            "rank_display": 400,
            "score": 30.3,
            "link": "https://www.topuniversities.com/universities/national-research-tomsk-polytechnic-university",
            "country": "Russia",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-tomsk-polytechnic-university_14158_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.757,
            "size": "M",
            "faculty_count": 1.55
        },
        {
            "university": "Massachusetts Institute of Technology (MIT)",
            "year": 2018,
            "rank_display": 1,
            "score": 100,
            "link": "https://www.topuniversities.com/universities/massachusetts-institute-technology-mit",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/massachusetts-institute-of-technology-mit_410_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 3730,
            "size": "M",
            "faculty_count": 3065
        },
        {
            "university": "Stanford University",
            "year": 2018,
            "rank_display": 2,
            "score": 98.7,
            "link": "https://www.topuniversities.com/universities/stanford-university",
            "country": "United States",
            "city": "Stanford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stanford-university_573_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3879,
            "size": "L",
            "faculty_count": 4725
        },
        {
            "university": "Harvard University",
            "year": 2018,
            "rank_display": 3,
            "score": 98.4,
            "link": "https://www.topuniversities.com/universities/harvard-university",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/harvard-university_253_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5877,
            "size": "L",
            "faculty_count": 4646
        },
        {
            "university": "California Institute of Technology (Caltech)",
            "year": 2018,
            "rank_display": 4,
            "score": 97.7,
            "link": "https://www.topuniversities.com/universities/california-institute-technology-caltech",
            "country": "United States",
            "city": "Pasadena",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/california-institute-of-technology-caltech_94_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 692,
            "size": "S",
            "faculty_count": 968
        },
        {
            "university": "University of Cambridge",
            "year": 2018,
            "rank_display": 5,
            "score": 95.6,
            "link": "https://www.topuniversities.com/universities/university-cambridge",
            "country": "United Kingdom",
            "city": "Cambridge",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cambridge_95_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 4,
            "international_students": 7925,
            "size": "L",
            "faculty_count": 5800
        },
        {
            "university": "University of Oxford",
            "year": 2018,
            "rank_display": 6,
            "score": 95.3,
            "link": "https://www.topuniversities.com/universities/university-oxford",
            "country": "United Kingdom",
            "city": "Oxford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oxford_478_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 8442,
            "size": "L",
            "faculty_count": 6708
        },
        {
            "university": "UCL",
            "year": 2018,
            "rank_display": 7,
            "score": 94.6,
            "link": "https://www.topuniversities.com/universities/ucl",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ucl_592560cf2aeae70239af4bf1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 21824,
            "size": "XL",
            "faculty_count": 7195
        },
        {
            "university": "Imperial College London",
            "year": 2018,
            "rank_display": 8,
            "score": 93.7,
            "link": "https://www.topuniversities.com/universities/imperial-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/imperial-college-london_592560cf2aeae70239af4be8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11143,
            "size": "L",
            "faculty_count": 8000
        },
        {
            "university": "University of Chicago",
            "year": 2018,
            "rank_display": 9,
            "score": 93.5,
            "link": "https://www.topuniversities.com/universities/university-chicago",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chicago_120_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4696,
            "size": "L",
            "faculty_count": 2703
        },
        {
            "university": "ETH Zurich - Swiss Federal Institute of Technology",
            "year": 2018,
            "rank_display": 10,
            "score": 93.3,
            "link": "https://www.topuniversities.com/universities/eth-zurich-swiss-federal-institute-technology",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eth-zurich-swiss-federal-institute-of-technology_201_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7733,
            "size": "L",
            "faculty_count": 2719
        },
        {
            "university": "Nanyang Technological University, Singapore (NTU)",
            "year": 2018,
            "rank_display": 11,
            "score": 92.2,
            "link": "https://www.topuniversities.com/universities/nanyang-technological-university-singapore-ntu",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanyang-technological-university-singapore-ntu_592560cf2aeae70239af4c32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6091,
            "size": "L",
            "faculty_count": 3812
        },
        {
            "university": "EPFL",
            "year": 2018,
            "rank_display": 12,
            "score": 91.2,
            "link": "https://www.topuniversities.com/universities/epfl",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/epfl-ecole-polytechnique-federale-de-lausanne_592560cf2aeae70239af4b34_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6426,
            "size": "M",
            "faculty_count": 1767
        },
        {
            "university": "Princeton University",
            "year": 2018,
            "rank_display": 13,
            "score": 91,
            "link": "https://www.topuniversities.com/universities/princeton-university",
            "country": "United States",
            "city": "Princeton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/princeton-university_508_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1912,
            "size": "M",
            "faculty_count": 1050
        },
        {
            "university": "Cornell University",
            "year": 2018,
            "rank_display": 14,
            "score": 90.7,
            "link": "https://www.topuniversities.com/universities/cornell-university",
            "country": "United States",
            "city": "Ithaca",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/cornell-university_143_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "National University of Singapore (NUS)",
            "year": 2018,
            "rank_display": 15,
            "score": 90.5,
            "link": "https://www.topuniversities.com/universities/national-university-singapore-nus",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-singapore-nus_443_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7551,
            "size": "XL",
            "faculty_count": 4288
        },
        {
            "university": "Yale University",
            "year": 2018,
            "rank_display": 16,
            "score": 90.4,
            "link": "https://www.topuniversities.com/universities/yale-university",
            "country": "United States",
            "city": "New Haven",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/yale-university_684_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 2537,
            "size": "M",
            "faculty_count": 5391
        },
        {
            "university": "Johns Hopkins University",
            "year": 2018,
            "rank_display": 17,
            "score": 89.8,
            "link": "https://www.topuniversities.com/universities/johns-hopkins-university",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/johns-hopkins-university_305_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 5070,
            "size": "L",
            "faculty_count": 4855
        },
        {
            "university": "Columbia University",
            "year": 2018,
            "rank_display": 18,
            "score": 88.9,
            "link": "https://www.topuniversities.com/universities/columbia-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/columbia-university_138_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 11252,
            "size": "L",
            "faculty_count": 7087
        },
        {
            "university": "University of Pennsylvania",
            "year": 2018,
            "rank_display": 19,
            "score": 88.7,
            "link": "https://www.topuniversities.com/universities/university-pennsylvania",
            "country": "United States",
            "city": "Philadelphia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pennsylvania_495_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4636,
            "size": "L",
            "faculty_count": 5154
        },
        {
            "university": "The Australian National University",
            "year": 2018,
            "rank_display": 20,
            "score": 87.1,
            "link": "https://www.topuniversities.com/universities/australian-national-university",
            "country": "Australia",
            "city": "Canberra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-australian-national-university_40_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6496,
            "size": "L",
            "faculty_count": 1763
        },
        {
            "university": "Duke University",
            "year": 2018,
            "rank_display": 21,
            "score": 87,
            "link": "https://www.topuniversities.com/universities/duke-university",
            "country": "United States",
            "city": "Durham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/duke-university_168_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3445,
            "size": "L",
            "faculty_count": 5673
        },
        {
            "university": "University of Michigan-Ann Arbor",
            "year": 2018,
            "rank_display": 21,
            "score": 87,
            "link": "https://www.topuniversities.com/universities/university-michigan-ann-arbor",
            "country": "United States",
            "city": "Ann Arbor",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-michigan_403_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 7341,
            "size": "XL",
            "faculty_count": 7132
        },
        {
            "university": "King's College London",
            "year": 2018,
            "rank_display": 23,
            "score": 86.9,
            "link": "https://www.topuniversities.com/universities/kings-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kings-college-london_357_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 15075,
            "size": "L",
            "faculty_count": 4216
        },
        {
            "university": "The University of Edinburgh",
            "year": 2018,
            "rank_display": 23,
            "score": 86.9,
            "link": "https://www.topuniversities.com/universities/university-edinburgh",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-edinburgh_180_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 14637,
            "size": "XL",
            "faculty_count": 4832
        },
        {
            "university": "Tsinghua University",
            "year": 2018,
            "rank_display": 25,
            "score": 85.6,
            "link": "https://www.topuniversities.com/universities/tsinghua-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tsinghua-university_626_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5420,
            "size": "XL",
            "faculty_count": 6174
        },
        {
            "university": "The University of Hong Kong",
            "year": 2018,
            "rank_display": 26,
            "score": 85.5,
            "link": "https://www.topuniversities.com/universities/university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-hong-kong_268_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 8311,
            "size": "L",
            "faculty_count": 2944
        },
        {
            "university": "University of California, Berkeley (UCB)",
            "year": 2018,
            "rank_display": 27,
            "score": 84.9,
            "link": "https://www.topuniversities.com/universities/university-california-berkeley-ucb",
            "country": "United States",
            "city": "Berkeley",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-berkeley-ucb_84_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 9428,
            "size": "XL",
            "faculty_count": 3701
        },
        {
            "university": "Northwestern University",
            "year": 2018,
            "rank_display": 28,
            "score": 84.8,
            "link": "https://www.topuniversities.com/universities/northwestern-university",
            "country": "United States",
            "city": "Evanston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northwestern-university_592560cf2aeae70239af4c50_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3846,
            "size": "L",
            "faculty_count": 3652
        },
        {
            "university": "The University of Tokyo",
            "year": 2018,
            "rank_display": 28,
            "score": 84.8,
            "link": "https://www.topuniversities.com/universities/university-tokyo",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tokyo_615_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3983,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "The Hong Kong University of Science and Technology",
            "year": 2018,
            "rank_display": 30,
            "score": 84.3,
            "link": "https://www.topuniversities.com/universities/hong-kong-university-science-technology",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-university-of-science-and-technology_269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3033,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "University of Toronto",
            "year": 2018,
            "rank_display": 31,
            "score": 84,
            "link": "https://www.topuniversities.com/universities/university-toronto",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-toronto_619_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 8,
            "international_students": 27536,
            "size": "XL",
            "faculty_count": 9881
        },
        {
            "university": "McGill University",
            "year": 2018,
            "rank_display": 32,
            "score": 83.9,
            "link": "https://www.topuniversities.com/universities/mcgill-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcgill-university_592560cf2aeae70239af4c10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 10827,
            "size": "L",
            "faculty_count": 3876
        },
        {
            "university": "University of California, Los Angeles (UCLA)",
            "year": 2018,
            "rank_display": 33,
            "score": 83.6,
            "link": "https://www.topuniversities.com/universities/university-california-los-angeles-ucla",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-los-angeles-ucla_87_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6140,
            "size": "XL",
            "faculty_count": 4487
        },
        {
            "university": "The University of Manchester",
            "year": 2018,
            "rank_display": 34,
            "score": 83,
            "link": "https://www.topuniversities.com/universities/university-manchester",
            "country": "United Kingdom",
            "city": "Manchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-manchester_389_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 16079,
            "size": "XL",
            "faculty_count": 4889
        },
        {
            "university": "The London School of Economics and Political Science (LSE)",
            "year": 2018,
            "rank_display": 35,
            "score": 81.8,
            "link": "https://www.topuniversities.com/universities/london-school-economics-political-science-lse",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/london-school-of-economics-and-political-science-lse_362_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8274,
            "size": "M",
            "faculty_count": 1172
        },
        {
            "university": "Seoul National University",
            "year": 2018,
            "rank_display": 36,
            "score": 81.5,
            "link": "https://www.topuniversities.com/universities/seoul-national-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/graduate-school-of-business-seoul-national-university_553_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1914,
            "size": "L",
            "faculty_count": 3859
        },
        {
            "university": "Kyoto University",
            "year": 2018,
            "rank_display": 36,
            "score": 81.5,
            "link": "https://www.topuniversities.com/universities/kyoto-university",
            "country": "Japan",
            "city": "Kyoto",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyoto-university_328_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2863,
            "size": "L",
            "faculty_count": 3911
        },
        {
            "university": "University of California, San Diego (UCSD)",
            "year": 2018,
            "rank_display": 38,
            "score": 80.8,
            "link": "https://www.topuniversities.com/universities/university-california-san-diego-ucsd",
            "country": "United States",
            "city": "San Diego",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-san-diego-ucsd_89_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 8567,
            "size": "XL",
            "faculty_count": 4545
        },
        {
            "university": "Peking University",
            "year": 2018,
            "rank_display": 38,
            "score": 80.8,
            "link": "https://www.topuniversities.com/universities/peking-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/peking-university_50_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5436,
            "size": "XL",
            "faculty_count": 5302
        },
        {
            "university": "Fudan University",
            "year": 2018,
            "rank_display": 40,
            "score": 80.6,
            "link": "https://www.topuniversities.com/universities/fudan-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/fudan-university_218_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5322,
            "size": "L",
            "faculty_count": 4526
        },
        {
            "university": "The University of Melbourne",
            "year": 2018,
            "rank_display": 41,
            "score": 80.4,
            "link": "https://www.topuniversities.com/universities/university-melbourne",
            "country": "Australia",
            "city": "Parkville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-melbourne_399_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 19,
            "international_students": 21406,
            "size": "S",
            "faculty_count": 4835
        },
        {
            "university": "KAIST - Korea Advanced Institute of Science & Technology",
            "year": 2018,
            "rank_display": 41,
            "score": 80.4,
            "link": "https://www.topuniversities.com/universities/kaist-korea-advanced-institute-science-technology",
            "country": "South Korea",
            "city": "Daejeon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kaist-korea-advanced-institute-of-science-technology_324_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 869,
            "size": "M",
            "faculty_count": 1307
        },
        {
            "university": "Ecole normale supérieure, Paris",
            "year": 2018,
            "rank_display": 43,
            "score": 79.9,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-paris",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-paris_176_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 374,
            "size": "S",
            "faculty_count": 178
        },
        {
            "university": "University of Bristol",
            "year": 2018,
            "rank_display": 44,
            "score": 79.5,
            "link": "https://www.topuniversities.com/universities/university-bristol",
            "country": "United Kingdom",
            "city": "Bristol",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bristol_69_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 7483,
            "size": "L",
            "faculty_count": 20311
        },
        {
            "university": "The University of New South Wales (UNSW Sydney)",
            "year": 2018,
            "rank_display": 45,
            "score": 78.9,
            "link": "https://www.topuniversities.com/universities/university-new-south-wales-unsw-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-new-south-wales-unsw-sydney_447_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 21603,
            "size": "XL",
            "faculty_count": 3219
        },
        {
            "university": "The Chinese University of Hong Kong (CUHK)",
            "year": 2018,
            "rank_display": 46,
            "score": 78.8,
            "link": "https://www.topuniversities.com/universities/chinese-university-hong-kong-cuhk",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-chinese-university-of-hong-kong-cuhk_123_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6062,
            "size": "L",
            "faculty_count": 2201
        },
        {
            "university": "The University of Queensland",
            "year": 2018,
            "rank_display": 47,
            "score": 78.6,
            "link": "https://www.topuniversities.com/universities/university-queensland",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-queensland_515_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 22241,
            "size": "XL",
            "faculty_count": 3513
        },
        {
            "university": "Carnegie Mellon University",
            "year": 2018,
            "rank_display": 47,
            "score": 78.6,
            "link": "https://www.topuniversities.com/universities/carnegie-mellon-university",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/carnegie-mellon-university_101_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5739,
            "size": "L",
            "faculty_count": 1534
        },
        {
            "university": "City University of Hong Kong",
            "year": 2018,
            "rank_display": 49,
            "score": 78.4,
            "link": "https://www.topuniversities.com/universities/city-university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-hong-kong_592560cf2aeae70239af4b05_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5331,
            "size": "M",
            "faculty_count": 1483
        },
        {
            "university": "The University of Sydney",
            "year": 2018,
            "rank_display": 50,
            "score": 78,
            "link": "https://www.topuniversities.com/universities/university-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sydney_592560cf2aeae70239af4cd0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 25940,
            "size": "XL",
            "faculty_count": 3571
        },
        {
            "university": "University of British Columbia",
            "year": 2018,
            "rank_display": 51,
            "score": 77.9,
            "link": "https://www.topuniversities.com/universities/university-british-columbia",
            "country": "Canada",
            "city": "Vancouver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-british-columbia_70_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 15399,
            "size": "XL",
            "faculty_count": 6201
        },
        {
            "university": "New York University (NYU)",
            "year": 2018,
            "rank_display": 52,
            "score": 77.3,
            "link": "https://www.topuniversities.com/universities/new-york-university-nyu",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/new-york-university-nyu_448_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 16218,
            "size": "XL",
            "faculty_count": 7874
        },
        {
            "university": "Brown University",
            "year": 2018,
            "rank_display": 53,
            "score": 76.5,
            "link": "https://www.topuniversities.com/universities/brown-university",
            "country": "United States",
            "city": "Providence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brown-university_72_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2002,
            "size": "M",
            "faculty_count": 1389
        },
        {
            "university": "Delft University of Technology",
            "year": 2018,
            "rank_display": 54,
            "score": 76.1,
            "link": "https://www.topuniversities.com/universities/delft-university-technology",
            "country": "Netherlands",
            "city": "Delft",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/delft-university-of-technology_155_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6104,
            "size": "L",
            "faculty_count": 2041
        },
        {
            "university": "University of Wisconsin-Madison",
            "year": 2018,
            "rank_display": 55,
            "score": 75.8,
            "link": "https://www.topuniversities.com/universities/university-wisconsin-madison",
            "country": "United States",
            "city": "Madison",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wisconsin-madison_678_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5737,
            "size": "XL",
            "faculty_count": 5166
        },
        {
            "university": "Tokyo Institute of Technology (Tokyo Tech)",
            "year": 2018,
            "rank_display": 56,
            "score": 74.8,
            "link": "https://www.topuniversities.com/universities/tokyo-institute-technology-tokyo-tech",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-institute-of-technology_592560cf2aeae70239af4ceb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1622,
            "size": "M",
            "faculty_count": 1492
        },
        {
            "university": "The University of Warwick",
            "year": 2018,
            "rank_display": 57,
            "score": 74.4,
            "link": "https://www.topuniversities.com/universities/university-warwick",
            "country": "United Kingdom",
            "city": "Coventry",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-warwick_664_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10459,
            "size": "L",
            "faculty_count": 2634
        },
        {
            "university": "University of Amsterdam",
            "year": 2018,
            "rank_display": 58,
            "score": 74.3,
            "link": "https://www.topuniversities.com/universities/university-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-amsterdam_18_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7969,
            "size": "XL",
            "faculty_count": 6000
        },
        {
            "university": "Ecole Polytechnique",
            "year": 2018,
            "rank_display": 59,
            "score": 69.9,
            "link": "https://www.topuniversities.com/universities/ecole-polytechnique",
            "country": "France",
            "city": "Palaiseau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-polytechnique_592560cf2aeae70239af4b36_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1248,
            "size": "S",
            "faculty_count": 633
        },
        {
            "university": "Monash University",
            "year": 2018,
            "rank_display": 60,
            "score": 73.1,
            "link": "https://www.topuniversities.com/universities/monash-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/monash-university_412_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 31049,
            "size": "XL",
            "faculty_count": 3852
        },
        {
            "university": "University of Washington",
            "year": 2018,
            "rank_display": 61,
            "score": 72.9,
            "link": "https://www.topuniversities.com/universities/university-washington",
            "country": "United States",
            "city": "Seattle",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-washington_592560cf2aeae70239af4d1e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 7362,
            "size": "XL",
            "faculty_count": 2796
        },
        {
            "university": "Shanghai Jiao Tong University",
            "year": 2018,
            "rank_display": 62,
            "score": 72.5,
            "link": "https://www.topuniversities.com/universities/shanghai-jiao-tong-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-jiao-tong-university_556_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6371,
            "size": "XL",
            "faculty_count": 4386
        },
        {
            "university": "Osaka University",
            "year": 2018,
            "rank_display": 63,
            "score": 72.1,
            "link": "https://www.topuniversities.com/universities/osaka-university",
            "country": "Japan",
            "city": "Osaka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/osaka-university_472_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2268,
            "size": "L",
            "faculty_count": 3127
        },
        {
            "university": "Technical University of Munich",
            "year": 2018,
            "rank_display": 64,
            "score": 72,
            "link": "https://www.topuniversities.com/universities/technical-university-munich",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-munich_599_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11447,
            "size": "XL",
            "faculty_count": 6663
        },
        {
            "university": "University of Glasgow",
            "year": 2018,
            "rank_display": 65,
            "score": 71.6,
            "link": "https://www.topuniversities.com/universities/university-glasgow",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-glasgow_592560cf2aeae70239af4b6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 11065,
            "size": "L",
            "faculty_count": 3314
        },
        {
            "university": "Ludwig-Maximilians-Universität München",
            "year": 2018,
            "rank_display": 66,
            "score": 70.8,
            "link": "https://www.topuniversities.com/universities/ludwig-maximilians-universitat-munchen",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ludwig-maximilians-universitt-mnchen_420_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5958,
            "size": "XL",
            "faculty_count": 3914
        },
        {
            "university": "University of Texas at Austin",
            "year": 2018,
            "rank_display": 67,
            "score": 70.6,
            "link": "https://www.topuniversities.com/universities/university-texas-austin",
            "country": "United States",
            "city": "Austin",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-at-austin_604_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 4953,
            "size": "XL",
            "faculty_count": 2887
        },
        {
            "university": "Ruprecht-Karls-Universität Heidelberg",
            "year": 2018,
            "rank_display": 68,
            "score": 70.4,
            "link": "https://www.topuniversities.com/universities/ruprecht-karls-universitat-heidelberg",
            "country": "Germany",
            "city": "Heidelberg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruprecht-karls-universitaet-heidelberg_259_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4048,
            "size": "L",
            "faculty_count": 4100
        },
        {
            "university": "University of Illinois at Urbana-Champaign",
            "year": 2018,
            "rank_display": 69,
            "score": 70.3,
            "link": "https://www.topuniversities.com/universities/university-illinois-urbana-champaign",
            "country": "United States",
            "city": "Champaign",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-at-urbana-champaign_2090_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 9987,
            "size": "XL",
            "faculty_count": 2748
        },
        {
            "university": "Georgia Institute of Technology",
            "year": 2018,
            "rank_display": 70,
            "score": 70.1,
            "link": "https://www.topuniversities.com/universities/georgia-institute-technology",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgia-institute-of-technology_225_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 1267
        },
        {
            "university": "KU Leuven",
            "year": 2018,
            "rank_display": 71,
            "score": 69.4,
            "link": "https://www.topuniversities.com/universities/ku-leuven",
            "country": "Belgium",
            "city": "Leuven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ku-leuven_592560cf2aeae70239af4aec_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 8485,
            "size": "XL",
            "faculty_count": 2677
        },
        {
            "university": "Pohang University of Science And Technology (POSTECH)",
            "year": 2018,
            "rank_display": 71,
            "score": 69.4,
            "link": "https://www.topuniversities.com/universities/pohang-university-science-technology-postech",
            "country": "South Korea",
            "city": "Pohang",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pohang-university-of-science-and-technology-postech_592560cf2aeae70239af4c7b_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 87,
            "size": "S",
            "faculty_count": 743
        },
        {
            "university": "University of Zurich",
            "year": 2018,
            "rank_display": 73,
            "score": 69.2,
            "link": "https://www.topuniversities.com/universities/university-zurich",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-zurich_693_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5044,
            "size": "L",
            "faculty_count": 3761
        },
        {
            "university": "University of Copenhagen",
            "year": 2018,
            "rank_display": 73,
            "score": 69.2,
            "link": "https://www.topuniversities.com/universities/university-copenhagen",
            "country": "Denmark",
            "city": "Copenhagen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-copenhagen_141_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4135,
            "size": "XL",
            "faculty_count": 7293
        },
        {
            "university": "Universidad de Buenos Aires (UBA)",
            "year": 2018,
            "rank_display": 75,
            "score": 69.1,
            "link": "https://www.topuniversities.com/universities/universidad-de-buenos-aires-uba",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-buenos-aires-uba_78_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 29516,
            "size": "XL",
            "faculty_count": 16103
        },
        {
            "university": "National Taiwan University (NTU)",
            "year": 2018,
            "rank_display": 76,
            "score": 69,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-ntu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-ntu_592560cf2aeae70239af4c3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5058,
            "size": "L",
            "faculty_count": 2939
        },
        {
            "university": "Tohoku University",
            "year": 2018,
            "rank_display": 76,
            "score": 69,
            "link": "https://www.topuniversities.com/universities/tohoku-university",
            "country": "Japan",
            "city": "Sendai City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tohoku-university_611_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1872,
            "size": "L",
            "faculty_count": 3323
        },
        {
            "university": "Durham University",
            "year": 2018,
            "rank_display": 78,
            "score": 68.5,
            "link": "https://www.topuniversities.com/universities/durham-university",
            "country": "United Kingdom",
            "city": "Durham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/durham-university_170_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6174,
            "size": "L",
            "faculty_count": 1775
        },
        {
            "university": "Lund University",
            "year": 2018,
            "rank_display": 78,
            "score": 68.5,
            "link": "https://www.topuniversities.com/universities/lund-university",
            "country": "Sweden",
            "city": "Lund",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lund-university_371_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6682,
            "size": "L",
            "faculty_count": 3040
        },
        {
            "university": "University of North Carolina, Chapel Hill",
            "year": 2018,
            "rank_display": 80,
            "score": 67.8,
            "link": "https://www.topuniversities.com/universities/university-north-carolina-chapel-hill",
            "country": "United States",
            "city": "Chapel Hill",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-north-carolina-chapel-hill_424_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2749,
            "size": "XL",
            "faculty_count": 4646
        },
        {
            "university": "Boston University",
            "year": 2018,
            "rank_display": 81,
            "score": 67.2,
            "link": "https://www.topuniversities.com/universities/boston-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-university_592560cf2aeae70239af4ac2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8159,
            "size": "L",
            "faculty_count": 2642
        },
        {
            "university": "The University of Sheffield",
            "year": 2018,
            "rank_display": 82,
            "score": 67,
            "link": "https://www.topuniversities.com/universities/university-sheffield",
            "country": "United Kingdom",
            "city": "Sheffield",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sheffield_592560cf2aeae70239af4cb1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 11090,
            "size": "L",
            "faculty_count": 3463
        },
        {
            "university": "The University of Auckland",
            "year": 2018,
            "rank_display": 82,
            "score": 67,
            "link": "https://www.topuniversities.com/universities/university-auckland",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-auckland_592560cf2aeae70239af4aa8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9924,
            "size": "L",
            "faculty_count": 2316
        },
        {
            "university": "University of Birmingham",
            "year": 2018,
            "rank_display": 84,
            "score": 66.8,
            "link": "https://www.topuniversities.com/universities/university-birmingham",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-birmingham_59_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10457,
            "size": "XL",
            "faculty_count": 3586
        },
        {
            "university": "University of Nottingham",
            "year": 2018,
            "rank_display": 84,
            "score": 66.8,
            "link": "https://www.topuniversities.com/universities/university-nottingham",
            "country": "United Kingdom",
            "city": "Nottingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-nottingham_592560cf2aeae70239af4c4e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9659,
            "size": "XL",
            "faculty_count": 3386
        },
        {
            "university": "The Ohio State University",
            "year": 2018,
            "rank_display": 86,
            "score": 66.1,
            "link": "https://www.topuniversities.com/universities/ohio-state-university",
            "country": "United States",
            "city": "Columbus",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-ohio-state-university_465_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7029,
            "size": "XL",
            "faculty_count": 6388
        },
        {
            "university": "Zhejiang University",
            "year": 2018,
            "rank_display": 87,
            "score": 65.9,
            "link": "https://www.topuniversities.com/universities/zhejiang-university",
            "country": "China (Mainland)",
            "city": "Hangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/zhejiang-university_592560cf2aeae70239af4d37_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 9177,
            "size": "XL",
            "faculty_count": 6065
        },
        {
            "university": "Trinity College Dublin, The University of Dublin",
            "year": 2018,
            "rank_display": 88,
            "score": 65.7,
            "link": "https://www.topuniversities.com/universities/trinity-college-dublin-university-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/trinity-college-dublin-the-university-of-dublin_167_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5593,
            "size": "L",
            "faculty_count": 1383
        },
        {
            "university": "Rice University",
            "year": 2018,
            "rank_display": 89,
            "score": 65.6,
            "link": "https://www.topuniversities.com/universities/rice-university",
            "country": "United States",
            "city": "Houston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rice-university_524_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2278,
            "size": "M",
            "faculty_count": 996
        },
        {
            "university": "Korea University",
            "year": 2018,
            "rank_display": 90,
            "score": 65.5,
            "link": "https://www.topuniversities.com/universities/korea-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/korea-university_325_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3325,
            "size": "L",
            "faculty_count": 4026
        },
        {
            "university": "University of Alberta",
            "year": 2018,
            "rank_display": 90,
            "score": 65.5,
            "link": "https://www.topuniversities.com/universities/university-alberta",
            "country": "Canada",
            "city": "Edmonton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-alberta_15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9689,
            "size": "XL",
            "faculty_count": 3645
        },
        {
            "university": "University of St Andrews",
            "year": 2018,
            "rank_display": 92,
            "score": 65.3,
            "link": "https://www.topuniversities.com/universities/university-st-andrews",
            "country": "United Kingdom",
            "city": "St. Andrews",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st-andrews_570_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4377,
            "size": "M",
            "faculty_count": 1208
        },
        {
            "university": "The University of Western Australia",
            "year": 2018,
            "rank_display": 93,
            "score": 65.2,
            "link": "https://www.topuniversities.com/universities/university-western-australia",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-australia_673_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9017,
            "size": "L",
            "faculty_count": 1539
        },
        {
            "university": "Pennsylvania State University",
            "year": 2018,
            "rank_display": 93,
            "score": 65.2,
            "link": "https://www.topuniversities.com/universities/pennsylvania-state-university",
            "country": "United States",
            "city": "University Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/pennsylvania-state-university_494_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6943,
            "size": "XL",
            "faculty_count": 4207
        },
        {
            "university": "The Hong Kong Polytechnic University",
            "year": 2018,
            "rank_display": 95,
            "score": 65,
            "link": "https://www.topuniversities.com/universities/hong-kong-polytechnic-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-polytechnic-university_267_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5774,
            "size": "L",
            "faculty_count": 2822
        },
        {
            "university": "Lomonosov Moscow State University",
            "year": 2018,
            "rank_display": 95,
            "score": 65,
            "link": "https://www.topuniversities.com/universities/lomonosov-moscow-state-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lomonosov-moscow-state-university-_592560cf2aeae70239af4c27_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5476,
            "size": "XL",
            "faculty_count": 6575
        },
        {
            "university": "University of Science and Technology of China",
            "year": 2018,
            "rank_display": 97,
            "score": 64.9,
            "link": "https://www.topuniversities.com/universities/university-science-technology-china",
            "country": "China (Mainland)",
            "city": "Hefei",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-of-china_122_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1019,
            "size": "L",
            "faculty_count": 2708
        },
        {
            "university": "University of Geneva",
            "year": 2018,
            "rank_display": 98,
            "score": 64.7,
            "link": "https://www.topuniversities.com/universities/university-geneva",
            "country": "Switzerland",
            "city": "Geneva",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-geneva_221_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6547,
            "size": "L",
            "faculty_count": 1814
        },
        {
            "university": "KTH Royal Institute of Technology",
            "year": 2018,
            "rank_display": 98,
            "score": 64.7,
            "link": "https://www.topuniversities.com/universities/kth-royal-institute-technology",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kth-royal-institute-of-technology-_592560cf2aeae70239af4c9b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3057,
            "size": "L",
            "faculty_count": 3600
        },
        {
            "university": "Washington University in St. Louis",
            "year": 2018,
            "rank_display": 100,
            "score": 64.6,
            "link": "https://www.topuniversities.com/universities/washington-university-st-louis",
            "country": "United States",
            "city": "St. Louis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-university-in-st.-louis_668_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3535,
            "size": "L",
            "faculty_count": 3193
        },
        {
            "university": "University of Leeds",
            "year": 2018,
            "rank_display": 101,
            "score": 64.5,
            "link": "https://www.topuniversities.com/universities/university-leeds",
            "country": "United Kingdom",
            "city": "Leeds",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leeds_338_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 11584,
            "size": "XL",
            "faculty_count": 3593
        },
        {
            "university": "University of Southampton",
            "year": 2018,
            "rank_display": 102,
            "score": 63.9,
            "link": "https://www.topuniversities.com/universities/university-southampton",
            "country": "United Kingdom",
            "city": "Southampton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southampton_567_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8417,
            "size": "L",
            "faculty_count": 2468
        },
        {
            "university": "University of Helsinki",
            "year": 2018,
            "rank_display": 102,
            "score": 63.9,
            "link": "https://www.topuniversities.com/universities/university-helsinki",
            "country": "Finland",
            "city": "Helsinki",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-helsinki_260_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1254,
            "size": "L",
            "faculty_count": 2855
        },
        {
            "university": "Eindhoven University of Technology",
            "year": 2018,
            "rank_display": 104,
            "score": 63.6,
            "link": "https://www.topuniversities.com/universities/eindhoven-university-technology",
            "country": "Netherlands",
            "city": "Eindhoven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eindhoven-university-of-technology_592560cf2aeae70239af4b3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1872,
            "size": "M",
            "faculty_count": 1145
        },
        {
            "university": "Purdue University",
            "year": 2018,
            "rank_display": 105,
            "score": 63.2,
            "link": "https://www.topuniversities.com/universities/purdue-university",
            "country": "United States",
            "city": "West Lafayette",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/purdue-university_510_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 8034,
            "size": "XL",
            "faculty_count": 2622
        },
        {
            "university": "Yonsei University",
            "year": 2018,
            "rank_display": 106,
            "score": 62.3,
            "link": "https://www.topuniversities.com/universities/yonsei-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yonsei-university_688_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4274,
            "size": "L",
            "faculty_count": 3605
        },
        {
            "university": "KIT, Karlsruhe Institute of Technology",
            "year": 2018,
            "rank_display": 107,
            "score": 61.9,
            "link": "https://www.topuniversities.com/universities/kit-karlsruhe-institute-technology",
            "country": "Germany",
            "city": "Karlsruhe",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kit-karlsruhe-institute-of-technology_310_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5030,
            "size": "L",
            "faculty_count": 2080
        },
        {
            "university": "Sungkyunkwan University(SKKU)",
            "year": 2018,
            "rank_display": 108,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/sungkyunkwan-universityskku",
            "country": "South Korea",
            "city": "Suwon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sungkyunkwan-universityskku_592560cf2aeae70239af4cc9_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3821,
            "size": "L",
            "faculty_count": 3313
        },
        {
            "university": "Utrecht University",
            "year": 2018,
            "rank_display": 109,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/utrecht-university",
            "country": "Netherlands",
            "city": "Utrecht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/utrecht-university_646_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4454,
            "size": "XL",
            "faculty_count": 3254
        },
        {
            "university": "The University of Adelaide",
            "year": 2018,
            "rank_display": 109,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/university-adelaide",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-adelaide_10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10107,
            "size": "L",
            "faculty_count": 1757
        },
        {
            "university": "Leiden University",
            "year": 2018,
            "rank_display": 109,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/leiden-university",
            "country": "Netherlands",
            "city": "Leiden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leiden-university_340_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6064,
            "size": "XL",
            "faculty_count": 2069
        },
        {
            "university": "Uppsala University",
            "year": 2018,
            "rank_display": 112,
            "score": 61.4,
            "link": "https://www.topuniversities.com/universities/uppsala-university",
            "country": "Sweden",
            "city": "Uppsala",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/uppsala-university_643_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8401,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "University of Groningen",
            "year": 2018,
            "rank_display": 113,
            "score": 61.3,
            "link": "https://www.topuniversities.com/universities/university-groningen",
            "country": "Netherlands",
            "city": "Groningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-groningen_243_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 9397,
            "size": "XL",
            "faculty_count": 3724
        },
        {
            "university": "Universiti Malaya (UM)",
            "year": 2018,
            "rank_display": 114,
            "score": 60.8,
            "link": "https://www.topuniversities.com/universities/universiti-malaya-um",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-malaya-um_383_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3340,
            "size": "L",
            "faculty_count": 2386
        },
        {
            "university": "Nanjing University",
            "year": 2018,
            "rank_display": 114,
            "score": 60.8,
            "link": "https://www.topuniversities.com/universities/nanjing-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanjing-university_430_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2513,
            "size": "XL",
            "faculty_count": 3040
        },
        {
            "university": "Technical University of Denmark",
            "year": 2018,
            "rank_display": 116,
            "score": 60.7,
            "link": "https://www.topuniversities.com/universities/technical-university-denmark",
            "country": "Denmark",
            "city": "Kongens Lyngby",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-denmark_592560cf2aeae70239af4cd8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2495,
            "size": "M",
            "faculty_count": 2219
        },
        {
            "university": "Nagoya University",
            "year": 2018,
            "rank_display": 116,
            "score": 60.7,
            "link": "https://www.topuniversities.com/universities/nagoya-university",
            "country": "Japan",
            "city": "Nagoya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nagoya-university_427_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1879,
            "size": "L",
            "faculty_count": 2512
        },
        {
            "university": "University of California, Davis",
            "year": 2018,
            "rank_display": 118,
            "score": 60.4,
            "link": "https://www.topuniversities.com/universities/university-california-davis",
            "country": "United States",
            "city": "Davis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-davis_592560cf2aeae70239af4ad8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8414,
            "size": "XL",
            "faculty_count": 3420
        },
        {
            "university": "Aarhus University",
            "year": 2018,
            "rank_display": 119,
            "score": 59.3,
            "link": "https://www.topuniversities.com/universities/aarhus-university",
            "country": "Denmark",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aarhus-university_592560cf2aeae70239af4a8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2911,
            "size": "L",
            "faculty_count": 683
        },
        {
            "university": "Humboldt-Universität zu Berlin",
            "year": 2018,
            "rank_display": 120,
            "score": 59.2,
            "link": "https://www.topuniversities.com/universities/humboldt-universitat-zu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/humboldt-universitt-zu-berlin_272_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5239,
            "size": "XL",
            "faculty_count": 1967
        },
        {
            "university": "Universidade de São Paulo",
            "year": 2018,
            "rank_display": 121,
            "score": 59.1,
            "link": "https://www.topuniversities.com/universities/universidade-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-de-so-paulo_550_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1858,
            "size": "XL",
            "faculty_count": 4943
        },
        {
            "university": "Hokkaido University",
            "year": 2018,
            "rank_display": 122,
            "score": 59,
            "link": "https://www.topuniversities.com/universities/hokkaido-university",
            "country": "Japan",
            "city": "Sapporo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hokkaido-university_266_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1984,
            "size": "L",
            "faculty_count": 2476
        },
        {
            "university": "Universidad Nacional Autónoma de México  (UNAM)",
            "year": 2018,
            "rank_display": 122,
            "score": 59,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-autonoma-de-mexico-unam",
            "country": "Mexico",
            "city": "Mexico City",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-autnoma-de-mxico-unam_425_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5110,
            "size": "XL",
            "faculty_count": 16381
        },
        {
            "university": "Wageningen University & Research",
            "year": 2018,
            "rank_display": 124,
            "score": 58.7,
            "link": "https://www.topuniversities.com/universities/wageningen-university-research",
            "country": "Netherlands",
            "city": "Wageningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/wageningen-university_659_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4131,
            "size": "L",
            "faculty_count": 2767
        },
        {
            "university": "Ghent University",
            "year": 2018,
            "rank_display": 125,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/ghent-university",
            "country": "Belgium",
            "city": "Ghent",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ghent-university_228_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4609,
            "size": "XL",
            "faculty_count": 5537
        },
        {
            "university": "Freie Universitaet Berlin",
            "year": 2018,
            "rank_display": 125,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/freie-universitaet-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/freie-universitaet-berlin_215_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 6659,
            "size": "XL",
            "faculty_count": 1389
        },
        {
            "university": "Queen Mary University of London",
            "year": 2018,
            "rank_display": 127,
            "score": 58.3,
            "link": "https://www.topuniversities.com/universities/queen-mary-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queen-mary-university-of-london_592560cf2aeae70239af4bea_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8625,
            "size": "L",
            "faculty_count": 2119
        },
        {
            "university": "Kyushu University",
            "year": 2018,
            "rank_display": 128,
            "score": 58.2,
            "link": "https://www.topuniversities.com/universities/kyushu-university",
            "country": "Japan",
            "city": "Fukuoka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyushu-university_331_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2125,
            "size": "L",
            "faculty_count": 2761
        },
        {
            "university": "University of Maryland, College Park",
            "year": 2018,
            "rank_display": 129,
            "score": 58.1,
            "link": "https://www.topuniversities.com/universities/university-maryland-college-park",
            "country": "United States",
            "city": "College Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-college-park_393_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4659,
            "size": "XL",
            "faculty_count": 3798
        },
        {
            "university": "Université de Montréal",
            "year": 2018,
            "rank_display": 130,
            "score": 57.7,
            "link": "https://www.topuniversities.com/universities/universite-de-montreal",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montral_417_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7625,
            "size": "XL",
            "faculty_count": 5533
        },
        {
            "university": "Université Pierre et Marie Curie (UPMC)",
            "year": 2018,
            "rank_display": 131,
            "score": 57.5,
            "link": "https://www.topuniversities.com/universities/universite-pierre-et-marie-curie-upmc",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-pierre-et-marie-curie-upmc_487_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "University of Southern California",
            "year": 2018,
            "rank_display": 132,
            "score": 57.2,
            "link": "https://www.topuniversities.com/universities/university-southern-california",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-california_569_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9107,
            "size": "XL",
            "faculty_count": 3116
        },
        {
            "university": "Chalmers University of Technology",
            "year": 2018,
            "rank_display": 133,
            "score": 57.1,
            "link": "https://www.topuniversities.com/universities/chalmers-university-technology",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/chalmers-university-of-technology_592560cf2aeae70239af4af7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2502,
            "size": "M",
            "faculty_count": 2500
        },
        {
            "university": "University of California, Santa Barbara (UCSB)",
            "year": 2018,
            "rank_display": 134,
            "score": 56.9,
            "link": "https://www.topuniversities.com/universities/university-california-santa-barbara-ucsb",
            "country": "United States",
            "city": "Santa Barbara",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-barbara-ucsb_91_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 4309,
            "size": "L",
            "faculty_count": 1597
        },
        {
            "university": "University of York",
            "year": 2018,
            "rank_display": 135,
            "score": 56.3,
            "link": "https://www.topuniversities.com/universities/university-york",
            "country": "United Kingdom",
            "city": "York",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-york_689_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5098,
            "size": "L",
            "faculty_count": 2209
        },
        {
            "university": "Lancaster University",
            "year": 2018,
            "rank_display": 135,
            "score": 56.3,
            "link": "https://www.topuniversities.com/universities/lancaster-university",
            "country": "United Kingdom",
            "city": "Lancaster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lancaster-university_335_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5557,
            "size": "L",
            "faculty_count": 1440
        },
        {
            "university": "Cardiff University",
            "year": 2018,
            "rank_display": 137,
            "score": 56,
            "link": "https://www.topuniversities.com/universities/cardiff-university",
            "country": "United Kingdom",
            "city": "Cardiff",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cardiff-university_592560cf2aeae70239af4ae6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7819,
            "size": "L",
            "faculty_count": 3350
        },
        {
            "university": "Aalto University",
            "year": 2018,
            "rank_display": 137,
            "score": 56,
            "link": "https://www.topuniversities.com/universities/aalto-university",
            "country": "Finland",
            "city": "Espoo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalto-university_261_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2202,
            "size": "L",
            "faculty_count": 1396
        },
        {
            "university": "Pontificia Universidad Católica de Chile (UC)",
            "year": 2018,
            "rank_display": 137,
            "score": 56,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-cat%C3%B3lica-de-chile-uc",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-de-chile-uc_107_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1072,
            "size": "L",
            "faculty_count": 2343
        },
        {
            "university": "McMaster University",
            "year": 2018,
            "rank_display": 140,
            "score": 55.8,
            "link": "https://www.topuniversities.com/universities/mcmaster-university",
            "country": "Canada",
            "city": "Hamilton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcmaster-university_397_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5386,
            "size": "L",
            "faculty_count": 3819
        },
        {
            "university": "RWTH Aachen University",
            "year": 2018,
            "rank_display": 141,
            "score": 55.6,
            "link": "https://www.topuniversities.com/universities/rwth-aachen-university",
            "country": "Germany",
            "city": "Aachen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rwth-aachen-university_4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 12477,
            "size": "XL",
            "faculty_count": 2431
        },
        {
            "university": "University of Pittsburgh",
            "year": 2018,
            "rank_display": 142,
            "score": 55.5,
            "link": "https://www.topuniversities.com/universities/university-pittsburgh",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pittsburgh_500_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2590,
            "size": "L",
            "faculty_count": 4746
        },
        {
            "university": "University of Oslo",
            "year": 2018,
            "rank_display": 142,
            "score": 55.5,
            "link": "https://www.topuniversities.com/universities/university-oslo",
            "country": "Norway",
            "city": "Oslo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oslo_473_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2560,
            "size": "L",
            "faculty_count": 2600
        },
        {
            "university": "Technische Universität Berlin (TU Berlin)",
            "year": 2018,
            "rank_display": 144,
            "score": 55.2,
            "link": "https://www.topuniversities.com/universities/technische-universitat-berlin-tu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-berlin-tu-berlin_595_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6464,
            "size": "L",
            "faculty_count": 2331
        },
        {
            "university": "The Hebrew University of Jerusalem",
            "year": 2018,
            "rank_display": 145,
            "score": 54.7,
            "link": "https://www.topuniversities.com/universities/hebrew-university-jerusalem",
            "country": "Israel",
            "city": "Jerusalem",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hebrew-university-of-jerusalem_256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1176,
            "size": "L",
            "faculty_count": 2295
        },
        {
            "university": "University of Lausanne",
            "year": 2018,
            "rank_display": 146,
            "score": 54.5,
            "link": "https://www.topuniversities.com/universities/university-lausanne",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lausanne_592560cf2aeae70239af4bd3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3026,
            "size": "L",
            "faculty_count": 1478
        },
        {
            "university": "Emory University",
            "year": 2018,
            "rank_display": 147,
            "score": 54.4,
            "link": "https://www.topuniversities.com/universities/emory-university",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/emory-university_185_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2264,
            "size": "L",
            "faculty_count": 2725
        },
        {
            "university": "Erasmus University Rotterdam",
            "year": 2018,
            "rank_display": 147,
            "score": 54.4,
            "link": "https://www.topuniversities.com/universities/erasmus-university-rotterdam",
            "country": "Netherlands",
            "city": "Rotterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/erasmus-university-rotterdam_190_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5932,
            "size": "L",
            "faculty_count": 2699
        },
        {
            "university": "University of Basel",
            "year": 2018,
            "rank_display": 149,
            "score": 54.3,
            "link": "https://www.topuniversities.com/universities/university-basel",
            "country": "Switzerland",
            "city": "Basel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-basel_46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2875,
            "size": "M",
            "faculty_count": 1036
        },
        {
            "university": "Michigan State University",
            "year": 2018,
            "rank_display": 149,
            "score": 54.3,
            "link": "https://www.topuniversities.com/universities/michigan-state-university",
            "country": "United States",
            "city": "East Lansing",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/michigan-state-university_402_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5795,
            "size": "XL",
            "faculty_count": 3008
        },
        {
            "university": "University of Otago",
            "year": 2018,
            "rank_display": 151,
            "score": 54.2,
            "link": "https://www.topuniversities.com/universities/university-otago",
            "country": "New Zealand",
            "city": "Dunedin",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-otago_474_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3585,
            "size": "L",
            "faculty_count": 1333
        },
        {
            "university": "University of Waterloo",
            "year": 2018,
            "rank_display": 152,
            "score": 53.7,
            "link": "https://www.topuniversities.com/universities/university-waterloo",
            "country": "Canada",
            "city": "Waterloo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waterloo_670_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 10269,
            "size": "XL",
            "faculty_count": 1908
        },
        {
            "university": "Université catholique de Louvain (UCLouvain)",
            "year": 2018,
            "rank_display": 153,
            "score": 53.6,
            "link": "https://www.topuniversities.com/universities/universite-catholique-de-louvain-uclouvain",
            "country": "Belgium",
            "city": "Louvain-la-Neuve",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-catholique-de-louvain-uclouvain_592560cf2aeae70239af4aed_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4524,
            "size": "L",
            "faculty_count": 1315
        },
        {
            "university": "University of Vienna",
            "year": 2018,
            "rank_display": 154,
            "score": 53.3,
            "link": "https://www.topuniversities.com/universities/university-vienna",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-vienna_652_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 14514,
            "size": "XL",
            "faculty_count": 3637
        },
        {
            "university": "Hanyang University",
            "year": 2018,
            "rank_display": 155,
            "score": 53.2,
            "link": "https://www.topuniversities.com/universities/hanyang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hanyang-university_250_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3455,
            "size": "L",
            "faculty_count": 3164
        },
        {
            "university": "Universitat de Barcelona",
            "year": 2018,
            "rank_display": 156,
            "score": 52.9,
            "link": "https://www.topuniversities.com/universities/universitat-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-barcelona_592560cf2aeae70239af4aaf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 6979,
            "size": "XL",
            "faculty_count": 3002
        },
        {
            "university": "École Normale Supérieure de Lyon",
            "year": 2018,
            "rank_display": 157,
            "score": 52.8,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-de-lyon",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cole-normale-suprieure-de-lyon_175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 233,
            "size": "S",
            "faculty_count": 387
        },
        {
            "university": "The University of Exeter",
            "year": 2018,
            "rank_display": 158,
            "score": 52.7,
            "link": "https://www.topuniversities.com/universities/university-exeter",
            "country": "United Kingdom",
            "city": "Exeter",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-exeter_204_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7188,
            "size": "L",
            "faculty_count": 2393
        },
        {
            "university": "University of Aberdeen",
            "year": 2018,
            "rank_display": 158,
            "score": 52.7,
            "link": "https://www.topuniversities.com/universities/university-aberdeen",
            "country": "United Kingdom",
            "city": "Aberdeen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-aberdeen_592560cf2aeae70239af4a8b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5544,
            "size": "L",
            "faculty_count": 1175
        },
        {
            "university": "University of Bath",
            "year": 2018,
            "rank_display": 160,
            "score": 52.6,
            "link": "https://www.topuniversities.com/universities/university-bath",
            "country": "United Kingdom",
            "city": "Bath",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bath_47_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5326,
            "size": "L",
            "faculty_count": 1339
        },
        {
            "university": "National Tsing Hua University",
            "year": 2018,
            "rank_display": 161,
            "score": 52.4,
            "link": "https://www.topuniversities.com/universities/national-tsing-hua-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-tsing-hua-university_442_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1551,
            "size": "L",
            "faculty_count": 1265
        },
        {
            "university": "Newcastle University",
            "year": 2018,
            "rank_display": 161,
            "score": 52.4,
            "link": "https://www.topuniversities.com/universities/newcastle-university",
            "country": "United Kingdom",
            "city": "Newcastle upon Tyne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/newcastle-university_592560cf2aeae70239af4c45_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8100,
            "size": "L",
            "faculty_count": 2766
        },
        {
            "university": "University of Minnesota Twin Cities",
            "year": 2018,
            "rank_display": 163,
            "score": 52.2,
            "link": "https://www.topuniversities.com/universities/university-minnesota-twin-cities",
            "country": "United States",
            "city": "Minneapolis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-minnesota_408_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5630,
            "size": "XL",
            "faculty_count": 5087
        },
        {
            "university": "University of California, Irvine",
            "year": 2018,
            "rank_display": 164,
            "score": 52.1,
            "link": "https://www.topuniversities.com/universities/university-california-irvine",
            "country": "United States",
            "city": "Irvine",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-irvine_86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 9323,
            "size": "XL",
            "faculty_count": 2775
        },
        {
            "university": "University of Bergen",
            "year": 2018,
            "rank_display": 164,
            "score": 52.1,
            "link": "https://www.topuniversities.com/universities/university-bergen",
            "country": "Norway",
            "city": "Bergen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bergen_53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 832,
            "size": "M",
            "faculty_count": 1634
        },
        {
            "university": "Eberhard Karls Universität Tübingen",
            "year": 2018,
            "rank_display": 164,
            "score": 52.1,
            "link": "https://www.topuniversities.com/universities/eberhard-karls-universitat-tubingen",
            "country": "Germany",
            "city": "Tübingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eberhard-karls-universitt-tbingen_629_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2872,
            "size": "L",
            "faculty_count": 4517
        },
        {
            "university": "University of Bern",
            "year": 2018,
            "rank_display": 167,
            "score": 51.9,
            "link": "https://www.topuniversities.com/universities/university-bern",
            "country": "Switzerland",
            "city": "Bern",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bern_55_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1776,
            "size": "M",
            "faculty_count": 1212
        },
        {
            "university": "University College Dublin",
            "year": 2018,
            "rank_display": 168,
            "score": 51.5,
            "link": "https://www.topuniversities.com/universities/university-college-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-dublin_166_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6118,
            "size": "L",
            "faculty_count": 1836
        },
        {
            "university": "Dartmouth College",
            "year": 2018,
            "rank_display": 169,
            "score": 51.3,
            "link": "https://www.topuniversities.com/universities/dartmouth-college",
            "country": "United States",
            "city": "Hanover",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dartmouth-college_592560cf2aeae70239af4b1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 997,
            "size": "M",
            "faculty_count": 836
        },
        {
            "university": "Politecnico di Milano",
            "year": 2018,
            "rank_display": 170,
            "score": 50.9,
            "link": "https://www.topuniversities.com/universities/politecnico-di-milano",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-milano_592560cf2aeae70239af4c1c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7452,
            "size": "XL",
            "faculty_count": 1659
        },
        {
            "university": "Albert-Ludwigs-Universitaet Freiburg",
            "year": 2018,
            "rank_display": 171,
            "score": 50.8,
            "link": "https://www.topuniversities.com/universities/albert-ludwigs-universitaet-freiburg",
            "country": "Germany",
            "city": "Freiburg im Breisgau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/albert-ludwigs-universitaet-freiburg_216_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3883,
            "size": "L",
            "faculty_count": 1993
        },
        {
            "university": "Indian Institute of Technology Delhi (IITD)",
            "year": 2018,
            "rank_display": 172,
            "score": 50.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-delhi-iitd",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-delhi-iitd_282_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 100,
            "size": "M",
            "faculty_count": 843
        },
        {
            "university": "King Fahd University of Petroleum & Minerals",
            "year": 2018,
            "rank_display": 173,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/king-fahd-university-petroleum-minerals",
            "country": "Saudi Arabia",
            "city": "Dhahran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-fahd-university-of-petroleum-minerals_779_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 782,
            "size": "M",
            "faculty_count": 1103
        },
        {
            "university": "University of Virginia",
            "year": 2018,
            "rank_display": 173,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/university-virginia",
            "country": "United States",
            "city": "Charlottesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-virginia_656_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2201,
            "size": "L",
            "faculty_count": 2731
        },
        {
            "university": "University of Liverpool",
            "year": 2018,
            "rank_display": 173,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/university-liverpool",
            "country": "United Kingdom",
            "city": "Liverpool",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-liverpool_350_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9500,
            "size": "L",
            "faculty_count": 2771
        },
        {
            "university": "University of Technology Sydney",
            "year": 2018,
            "rank_display": 176,
            "score": 50.1,
            "link": "https://www.topuniversities.com/universities/university-technology-sydney",
            "country": "Australia",
            "city": "Haymarket",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-technology-sydney_592560cf2aeae70239af4ccf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8653,
            "size": "L",
            "faculty_count": 2183
        },
        {
            "university": "CentraleSupélec",
            "year": 2018,
            "rank_display": 177,
            "score": 52.3,
            "link": "https://www.topuniversities.com/universities/centralesupelec",
            "country": "France",
            "city": "Saint-Aubin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/centralesuplec_173_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": "",
            "international_students": 1068,
            "size": "S",
            "faculty_count": 552
        },
        {
            "university": "University of Florida",
            "year": 2018,
            "rank_display": 178,
            "score": 49.8,
            "link": "https://www.topuniversities.com/universities/university-florida",
            "country": "United States",
            "city": "Gainesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florida_212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4143,
            "size": "XL",
            "faculty_count": 6222
        },
        {
            "university": "University of Twente",
            "year": 2018,
            "rank_display": 179,
            "score": 49.7,
            "link": "https://www.topuniversities.com/universities/university-twente",
            "country": "Netherlands",
            "city": "Enschede",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-twente_635_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3872,
            "size": "L",
            "faculty_count": 1177
        },
        {
            "university": "Indian Institute of Technology Bombay (IITB)",
            "year": 2018,
            "rank_display": 179,
            "score": 49.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-bombay-iitb",
            "country": "India",
            "city": "Mumbai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-bombay-iitb_281_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 116,
            "size": "M",
            "faculty_count": 1015
        },
        {
            "university": "University of Göttingen",
            "year": 2018,
            "rank_display": 181,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/university-gottingen",
            "country": "Germany",
            "city": "Göttingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gttingen_234_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3875,
            "size": "XL",
            "faculty_count": 3717
        },
        {
            "university": "Technische Universität Wien",
            "year": 2018,
            "rank_display": 182,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/vienna-university-technology",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vienna-university-of-technology_653_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2515,
            "size": "M",
            "faculty_count": 761
        },
        {
            "university": "University of Colorado Boulder",
            "year": 2018,
            "rank_display": 182,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/university-colorado-boulder",
            "country": "United States",
            "city": "Boulder",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-boulder_137_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2441,
            "size": "XL",
            "faculty_count": 2946
        },
        {
            "university": "Universidade Estadual de Campinas (Unicamp)",
            "year": 2018,
            "rank_display": 182,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/universidade-estadual-de-campinas-unicamp",
            "country": "Brazil",
            "city": "Campinas",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-estadual-de-campinas-unicamp_96_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 944,
            "size": "L",
            "faculty_count": 1875
        },
        {
            "university": "Vrije Universiteit Brussel (VUB)",
            "year": 2018,
            "rank_display": 182,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-brussel-vub",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-brussel-vub_74_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2994,
            "size": "L",
            "faculty_count": 1815
        },
        {
            "university": "University of Rochester",
            "year": 2018,
            "rank_display": 186,
            "score": 49.3,
            "link": "https://www.topuniversities.com/universities/university-rochester",
            "country": "United States",
            "city": "Rochester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rochester_592560cf2aeae70239af4c96_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3644,
            "size": "M",
            "faculty_count": 1853
        },
        {
            "university": "Universidad Autónoma de Madrid",
            "year": 2018,
            "rank_display": 187,
            "score": 49.2,
            "link": "https://www.topuniversities.com/universities/universidad-autonoma-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-autnoma-de-madrid_379_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2470,
            "size": "L",
            "faculty_count": 2869
        },
        {
            "university": "University of Reading",
            "year": 2018,
            "rank_display": 188,
            "score": 49.1,
            "link": "https://www.topuniversities.com/universities/university-reading",
            "country": "United Kingdom",
            "city": "Reading",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-reading_517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5007,
            "size": "L",
            "faculty_count": 1325
        },
        {
            "university": "Alma Mater Studiorum - University of Bologna",
            "year": 2018,
            "rank_display": 188,
            "score": 49.1,
            "link": "https://www.topuniversities.com/universities/alma-mater-studiorum-university-bologna",
            "country": "Italy",
            "city": "Bologna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/alma-mater-studiorum-university-of-bologna_592560cf2aeae70239af4ac0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 5536,
            "size": "XL",
            "faculty_count": 2885
        },
        {
            "university": "Indian Institute of Science",
            "year": 2018,
            "rank_display": 190,
            "score": 49,
            "link": "https://www.topuniversities.com/universities/indian-institute-science",
            "country": "India",
            "city": "Bangalore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-science-iisc-bangalore_22878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5,
            "size": "S",
            "faculty_count": 446
        },
        {
            "university": "University of Cape Town",
            "year": 2018,
            "rank_display": 191,
            "score": 48.9,
            "link": "https://www.topuniversities.com/universities/university-cape-town",
            "country": "South Africa",
            "city": "Cape Town",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/uct-graduate-school-of-business_592560cf2aeae70239af4ae5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3204,
            "size": "L",
            "faculty_count": 1503
        },
        {
            "university": "Scuola Normale Superiore",
            "year": 2018,
            "rank_display": 192,
            "score": "",
            "link": "https://www.topuniversities.com/universities/scuola-normale-superiore",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/scuola-normale-superiore-di-pisa_25824_small.jpg",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 69,
            "size": "S",
            "faculty_count": 150
        },
        {
            "university": "Keio University",
            "year": 2018,
            "rank_display": 192,
            "score": 48.5,
            "link": "https://www.topuniversities.com/universities/keio-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/keio-university_592560cf2aeae70239af4bbe_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3101,
            "size": "XL",
            "faculty_count": 4028
        },
        {
            "university": "Sant'Anna - Scuola Universitaria Superiore Pisa",
            "year": 2018,
            "rank_display": 192,
            "score": "",
            "link": "https://www.topuniversities.com/universities/santanna-scuola-universitaria-superiore-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/santanna-scuola-universitaria-superiore-pisa_592560cf2aeae70239af4d74_small.jpg",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 131,
            "size": "S",
            "faculty_count": 174
        },
        {
            "university": "Technische Universität Dresden",
            "year": 2018,
            "rank_display": 195,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/technische-universitat-dresden",
            "country": "Germany",
            "city": "Dresden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-dresden_598_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5167,
            "size": "XL",
            "faculty_count": 4636
        },
        {
            "university": "Universitat Autònoma de Barcelona",
            "year": 2018,
            "rank_display": 195,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/universitat-autonoma-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-autnoma-de-barcelona_592560cf2aeae70239af4ab0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 4069,
            "size": "XL",
            "faculty_count": 2132
        },
        {
            "university": "Stockholm University",
            "year": 2018,
            "rank_display": 195,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/stockholm-university",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/stockholm-university_577_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7567,
            "size": "L",
            "faculty_count": 2325
        },
        {
            "university": "Texas A&M University",
            "year": 2018,
            "rank_display": 195,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/texas-am-university",
            "country": "United States",
            "city": "Galveston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/texas-am-university_592560cf2aeae70239af4ce2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 6621,
            "size": "XL",
            "faculty_count": 3049
        },
        {
            "university": "Tecnológico de Monterrey",
            "year": 2018,
            "rank_display": 199,
            "score": 48,
            "link": "https://www.topuniversities.com/universities/tecnologico-de-monterrey",
            "country": "Mexico",
            "city": "Monterrey",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/instituto-tecnolgico-y-de-estudios-superiores-de-monterrey_592560cf2aeae70239af4cd6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3514,
            "size": "XL",
            "faculty_count": 5894
        },
        {
            "university": "Maastricht University",
            "year": 2018,
            "rank_display": 200,
            "score": 47.9,
            "link": "https://www.topuniversities.com/universities/maastricht-university",
            "country": "Netherlands",
            "city": "Maastricht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/maastricht-university_377_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10425,
            "size": "L",
            "faculty_count": 1418
        },
        {
            "university": "Universidad de Chile",
            "year": 2018,
            "rank_display": 201,
            "score": 47.8,
            "link": "https://www.topuniversities.com/universities/universidad-de-chile",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-chile_121_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1697,
            "size": "XL",
            "faculty_count": 2412
        },
        {
            "university": "Queen's University Belfast",
            "year": 2018,
            "rank_display": 202,
            "score": 47.4,
            "link": "https://www.topuniversities.com/universities/queens-university-belfast",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-belfast_592560cf2aeae70239af4c86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7397,
            "size": "L",
            "faculty_count": 1951
        },
        {
            "university": "Waseda University",
            "year": 2018,
            "rank_display": 203,
            "score": 47.2,
            "link": "https://www.topuniversities.com/universities/waseda-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/waseda-university_592560cf2aeae70239af4d1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7209,
            "size": "XL",
            "faculty_count": 3833
        },
        {
            "university": "Radboud University",
            "year": 2018,
            "rank_display": 204,
            "score": 47.1,
            "link": "https://www.topuniversities.com/universities/radboud-university",
            "country": "Netherlands",
            "city": "Nijmegen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/radboud-university_452_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2781,
            "size": "L",
            "faculty_count": 2074
        },
        {
            "university": "Tel Aviv University",
            "year": 2018,
            "rank_display": 205,
            "score": 47,
            "link": "https://www.topuniversities.com/universities/tel-aviv-university",
            "country": "Israel",
            "city": "Tel Aviv",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tel-aviv-university_592560cf2aeae70239af4cde_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1294,
            "size": "L",
            "faculty_count": 1048
        },
        {
            "university": "Universite libre de Bruxelles",
            "year": 2018,
            "rank_display": 205,
            "score": 47,
            "link": "https://www.topuniversities.com/universities/universite-libre-de-bruxelles",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universite-libre-de-bruxelles_75_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 8073,
            "size": "L",
            "faculty_count": 1101
        },
        {
            "university": "National Chiao Tung University",
            "year": 2018,
            "rank_display": 207,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/national-chiao-tung-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-chiao-tung-university_437_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1420,
            "size": "M",
            "faculty_count": 1152
        },
        {
            "university": "University of Illinois at Chicago (UIC)",
            "year": 2018,
            "rank_display": 207,
            "score": 46.9,
            "link": "https://www.topuniversities.com/universities/university-illinois-chicago-uic",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-chicago-uic_592560cf2aeae70239af4b9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3425,
            "size": "L",
            "faculty_count": 2808
        },
        {
            "university": "Arizona State University",
            "year": 2018,
            "rank_display": 209,
            "score": 46.7,
            "link": "https://www.topuniversities.com/universities/arizona-state-university",
            "country": "United States",
            "city": "Phoenix",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/arizona-state-university_592560cf2aeae70239af4a9c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8430,
            "size": "XL",
            "faculty_count": 2869
        },
        {
            "university": "Western University",
            "year": 2018,
            "rank_display": 210,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/western-university",
            "country": "Canada",
            "city": "London",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-ontario_674_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 6314,
            "size": "L",
            "faculty_count": 1311
        },
        {
            "university": "University of Antwerp",
            "year": 2018,
            "rank_display": 210,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/university-antwerp",
            "country": "Belgium",
            "city": "Antwerp",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-antwerp_21_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2688,
            "size": "L",
            "faculty_count": 1704
        },
        {
            "university": "Vanderbilt University",
            "year": 2018,
            "rank_display": 212,
            "score": 46.5,
            "link": "https://www.topuniversities.com/universities/vanderbilt-university",
            "country": "United States",
            "city": "Nashville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/vanderbilt-university_649_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 2022,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Case Western Reserve University",
            "year": 2018,
            "rank_display": 213,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/case-western-reserve-university",
            "country": "United States",
            "city": "Cleveland",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/case-western-reserve-university_102_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2864,
            "size": "M",
            "faculty_count": 1639
        },
        {
            "university": "University of Canterbury",
            "year": 2018,
            "rank_display": 214,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/university-canterbury",
            "country": "New Zealand",
            "city": "Christchurch",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canterbury_97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2816,
            "size": "L",
            "faculty_count": ""
        },
        {
            "university": "Sapienza University of Rome",
            "year": 2018,
            "rank_display": 215,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/sapienza-university-rome",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sapienza-university-of-rome_592560cf2aeae70239af4c97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 5960,
            "size": "XL",
            "faculty_count": 3287
        },
        {
            "university": "University of Notre Dame",
            "year": 2018,
            "rank_display": 216,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/university-notre-dame",
            "country": "United States",
            "city": "Notre Dame",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-notre-dame_458_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1484,
            "size": "L",
            "faculty_count": 1545
        },
        {
            "university": "University of Calgary",
            "year": 2018,
            "rank_display": 217,
            "score": 45.8,
            "link": "https://www.topuniversities.com/universities/university-calgary",
            "country": "Canada",
            "city": "Calgary",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-calgary_592560cf2aeae70239af4ad5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6791,
            "size": "XL",
            "faculty_count": 2668
        },
        {
            "university": "Vrije Universiteit Amsterdam",
            "year": 2018,
            "rank_display": 218,
            "score": 45.6,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-amsterdam_658_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4059,
            "size": "L",
            "faculty_count": 2598
        },
        {
            "university": "Victoria University of Wellington",
            "year": 2018,
            "rank_display": 219,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/victoria-university-wellington",
            "country": "New Zealand",
            "city": "Kelburn, Wellington",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/victoria-university-of-wellington_592560cf2aeae70239af4d0f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 3668,
            "size": "L",
            "faculty_count": 1124
        },
        {
            "university": "Sciences Po",
            "year": 2018,
            "rank_display": 220,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/sciences-po",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sciences-po-_592560cf2aeae70239af4c64_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4841,
            "size": "M",
            "faculty_count": 1808
        },
        {
            "university": "King Saud University",
            "year": 2018,
            "rank_display": 221,
            "score": 44.5,
            "link": "https://www.topuniversities.com/universities/king-saud-university",
            "country": "Saudi Arabia",
            "city": "Riyadh",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-saud-university_1175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3896,
            "size": "XL",
            "faculty_count": 4216
        },
        {
            "university": "National Cheng Kung University (NCKU)",
            "year": 2018,
            "rank_display": 222,
            "score": 44.3,
            "link": "https://www.topuniversities.com/universities/national-cheng-kung-university-ncku",
            "country": "Taiwan",
            "city": "Tainan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-cheng-kung-university-ncku_435_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2656,
            "size": "L",
            "faculty_count": 2079
        },
        {
            "university": "Universität Hamburg",
            "year": 2018,
            "rank_display": 223,
            "score": 44.2,
            "link": "https://www.topuniversities.com/universities/universitat-hamburg",
            "country": "Germany",
            "city": "Hamburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-hamburg_592560cf2aeae70239af4b79_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3626,
            "size": "L",
            "faculty_count": 2032
        },
        {
            "university": "The University of Newcastle, Australia (UON)",
            "year": 2018,
            "rank_display": 224,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/university-newcastle-australia-uon",
            "country": "Australia",
            "city": "Callaghan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-newcastle-australia-uon_449_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1747,
            "size": "L",
            "faculty_count": 1135
        },
        {
            "university": "Technion - Israel Institute of Technology",
            "year": 2018,
            "rank_display": 224,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/technion-israel-institute-technology",
            "country": "Israel",
            "city": "Haifa",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/technion-israel-institute-of-technology_600_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "L",
            "faculty_count": 1.616
        },
        {
            "university": "Queen's University at Kingston",
            "year": 2018,
            "rank_display": 224,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/queens-university-kingston",
            "country": "Canada",
            "city": "Kingston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-at-kingston_513_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3858,
            "size": "L",
            "faculty_count": 1287
        },
        {
            "university": "Georgetown University",
            "year": 2018,
            "rank_display": 227,
            "score": 43.8,
            "link": "https://www.topuniversities.com/universities/georgetown-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgetown-university_224_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3286,
            "size": "L",
            "faculty_count": 2009
        },
        {
            "university": "University of Sussex",
            "year": 2018,
            "rank_display": 228,
            "score": 43.7,
            "link": "https://www.topuniversities.com/universities/university-sussex",
            "country": "United Kingdom",
            "city": "Brighton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-sussex_592560cf2aeae70239af4ccd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6350,
            "size": "L",
            "faculty_count": 1320
        },
        {
            "university": "Universiti Putra Malaysia (UPM)",
            "year": 2018,
            "rank_display": 229,
            "score": 43.6,
            "link": "https://www.topuniversities.com/universities/universiti-putra-malaysia-upm",
            "country": "Malaysia",
            "city": "Seri Kembangan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-putra-malaysia-upm_385_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5040,
            "size": "L",
            "faculty_count": 2174
        },
        {
            "university": "Universiti Kebangsaan Malaysia (UKM)",
            "year": 2018,
            "rank_display": 230,
            "score": 43.4,
            "link": "https://www.topuniversities.com/universities/universiti-kebangsaan-malaysia-ukm",
            "country": "Malaysia",
            "city": "Bangi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-kebangsaan-malaysia-ukm_384_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2410,
            "size": "L",
            "faculty_count": 2829
        },
        {
            "university": "The University of Arizona",
            "year": 2018,
            "rank_display": 230,
            "score": 43.4,
            "link": "https://www.topuniversities.com/universities/university-arizona",
            "country": "United States",
            "city": "Tucson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-arizona_26_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3526,
            "size": "XL",
            "faculty_count": 5724
        },
        {
            "university": "University of Wollongong",
            "year": 2018,
            "rank_display": 232,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/university-wollongong",
            "country": "Australia",
            "city": "Wollongong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wollongong_680_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4779,
            "size": "L",
            "faculty_count": 1410
        },
        {
            "university": "Complutense University of Madrid",
            "year": 2018,
            "rank_display": 233,
            "score": 43.2,
            "link": "https://www.topuniversities.com/universities/complutense-university-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/complutense-university-of-madrid_592560cf2aeae70239af4bff_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 7295,
            "size": "XL",
            "faculty_count": 4778
        },
        {
            "university": "Loughborough University",
            "year": 2018,
            "rank_display": 234,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/loughborough-university",
            "country": "United Kingdom",
            "city": "Loughborough",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/loughborough-university_592560cf2aeae70239af4bf3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4540,
            "size": "L",
            "faculty_count": 1445
        },
        {
            "university": "American University of Beirut (AUB)",
            "year": 2018,
            "rank_display": 235,
            "score": 43,
            "link": "https://www.topuniversities.com/universities/american-university-beirut-aub",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-beirut-aub_780_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1724,
            "size": "M",
            "faculty_count": 964
        },
        {
            "university": "Université Grenoble Alpes",
            "year": 2018,
            "rank_display": 236,
            "score": 42.9,
            "link": "https://www.topuniversities.com/universities/universite-grenoble-alpes",
            "country": "France",
            "city": "Grenoble",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-grenoble-alpes_592560cf2aeae70239af5ab2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8880,
            "size": "XL",
            "faculty_count": 3088
        },
        {
            "university": "Al-Farabi Kazakh National University",
            "year": 2018,
            "rank_display": 236,
            "score": 42.9,
            "link": "https://www.topuniversities.com/universities/al-farabi-kazakh-national-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/al-farabi-kazakh-national-university_2146_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3054,
            "size": "L",
            "faculty_count": 4047
        },
        {
            "university": "University of Leicester",
            "year": 2018,
            "rank_display": 238,
            "score": 42.8,
            "link": "https://www.topuniversities.com/universities/university-leicester",
            "country": "United Kingdom",
            "city": "Leicester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leicester_339_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4919,
            "size": "L",
            "faculty_count": 1518
        },
        {
            "university": "Rheinische Friedrich-Wilhelms-Universität Bonn",
            "year": 2018,
            "rank_display": 239,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/rheinische-friedrich-wilhelms-universitat-bonn",
            "country": "Germany",
            "city": "Bonn",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rheinische-friedrich-wilhelms-universitt-bonn_592560cf2aeae70239af4ac1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3276,
            "size": "L",
            "faculty_count": 4207
        },
        {
            "university": "Saint Petersburg State University",
            "year": 2018,
            "rank_display": 240,
            "score": 42.5,
            "link": "https://www.topuniversities.com/universities/saint-petersburg-state-university",
            "country": "Russia",
            "city": "Saint Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-petersburg-state-university_592560cf2aeae70239af4cc0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4982,
            "size": "L",
            "faculty_count": 3054
        },
        {
            "university": "Macquarie University",
            "year": 2018,
            "rank_display": 240,
            "score": 42.5,
            "link": "https://www.topuniversities.com/universities/macquarie-university",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/macquarie-university_378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8621,
            "size": "L",
            "faculty_count": 1766
        },
        {
            "university": "Université Paris-Saclay",
            "year": 2018,
            "rank_display": 242,
            "score": 42.3,
            "link": "https://www.topuniversities.com/universities/universite-paris-saclay",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-saclay_592560cf2aeae70239af4c6e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 7649,
            "size": "XL",
            "faculty_count": 8010
        },
        {
            "university": "Tufts University",
            "year": 2018,
            "rank_display": 243,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/tufts-university",
            "country": "United States",
            "city": "Medford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tufts-university_630_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1798,
            "size": "M",
            "faculty_count": 1459
        },
        {
            "university": "National University of Ireland Galway",
            "year": 2018,
            "rank_display": 243,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/national-university-ireland-galway",
            "country": "Ireland",
            "city": "Galway",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-ireland-galway_220_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3208,
            "size": "L",
            "faculty_count": 1835
        },
        {
            "university": "Simon Fraser University",
            "year": 2018,
            "rank_display": 245,
            "score": 41.8,
            "link": "https://www.topuniversities.com/universities/simon-fraser-university",
            "country": "Canada",
            "city": "Burnaby",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/simon-fraser-university_562_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 8051,
            "size": "L",
            "faculty_count": 1193
        },
        {
            "university": "Chulalongkorn University",
            "year": 2018,
            "rank_display": 245,
            "score": 41.8,
            "link": "https://www.topuniversities.com/universities/chulalongkorn-university",
            "country": "Thailand",
            "city": "Bangkok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chulalongkorn-university_592560cf2aeae70239af4b01_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 985,
            "size": "XL",
            "faculty_count": 3354
        },
        {
            "university": "RMIT University",
            "year": 2018,
            "rank_display": 247,
            "score": 41.6,
            "link": "https://www.topuniversities.com/universities/rmit-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/rmit-university_592560cf2aeae70239af4c95_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 11743,
            "size": "XL",
            "faculty_count": 1859
        },
        {
            "university": "Queensland University of Technology (QUT)",
            "year": 2018,
            "rank_display": 247,
            "score": 41.6,
            "link": "https://www.topuniversities.com/universities/queensland-university-technology-qut",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/queensland-university-of-technology-qut_592560cf2aeae70239af4c88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6403,
            "size": "XL",
            "faculty_count": 2028
        },
        {
            "university": "University of Massachusetts Amherst",
            "year": 2018,
            "rank_display": 249,
            "score": 41.5,
            "link": "https://www.topuniversities.com/universities/university-massachusetts-amherst",
            "country": "United States",
            "city": "Amherst",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-massachusetts-amherst_592560cf2aeae70239af4c0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3696,
            "size": "L",
            "faculty_count": 1852
        },
        {
            "university": "University of Tsukuba",
            "year": 2018,
            "rank_display": 250,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/university-tsukuba",
            "country": "Japan",
            "city": "Tsukuba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tsukuba_592560cf2aeae70239af4cf7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2358,
            "size": "L",
            "faculty_count": 2073
        },
        {
            "university": "Novosibirsk State University",
            "year": 2018,
            "rank_display": 250,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/novosibirsk-state-university",
            "country": "Russia",
            "city": "Novosibirsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/novosibirsk-state-university_462_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1623,
            "size": "M",
            "faculty_count": 1218
        },
        {
            "university": "University of Miami",
            "year": 2018,
            "rank_display": 252,
            "score": 41.2,
            "link": "https://www.topuniversities.com/universities/university-miami",
            "country": "United States",
            "city": "Miami",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-miami_401_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2646,
            "size": "L",
            "faculty_count": 2565
        },
        {
            "university": "Universiti Teknologi Malaysia",
            "year": 2018,
            "rank_display": 253,
            "score": 41.1,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-malaysia",
            "country": "Malaysia",
            "city": "Skudai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-malaysia_387_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4956,
            "size": "L",
            "faculty_count": 1869
        },
        {
            "university": "Goethe-University Frankfurt am Main",
            "year": 2018,
            "rank_display": 254,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/goethe-university-frankfurt-am-main",
            "country": "Germany",
            "city": "Frankfurt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-frankfurt-am-main_214_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6.749,
            "size": "XL",
            "faculty_count": 3.623
        },
        {
            "university": "Universidad Nacional de Colombia",
            "year": 2018,
            "rank_display": 254,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-de-colombia_592560cf2aeae70239af4b0b_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 335,
            "size": "XL",
            "faculty_count": 3055
        },
        {
            "university": "Universidad de los Andes",
            "year": 2018,
            "rank_display": 256,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/universidad-de-los-andes",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-los-andes_592560cf2aeae70239af4bf6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 13,
            "international_students": 151,
            "size": "L",
            "faculty_count": 1223
        },
        {
            "university": "Kyung Hee University",
            "year": 2018,
            "rank_display": 256,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/kyung-hee-university",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyung-hee-university_592560cf2aeae70239af4bcd_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4715,
            "size": "L",
            "faculty_count": 3435
        },
        {
            "university": "Beijing Normal University",
            "year": 2018,
            "rank_display": 256,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/beijing-normal-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-normal-university_869_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3288,
            "size": "L",
            "faculty_count": 2098
        },
        {
            "university": "Royal Holloway University of London",
            "year": 2018,
            "rank_display": 259,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/royal-holloway-university-london",
            "country": "United Kingdom",
            "city": "Egham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/royal-holloway-university-of-london_534_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.705,
            "size": "M",
            "faculty_count": 831
        },
        {
            "university": "Norwegian University of Science And Technology",
            "year": 2018,
            "rank_display": 259,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/norwegian-university-science-technology",
            "country": "Norway",
            "city": "Trondheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/norwegian-university-of-science-and-technology_457_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.95,
            "size": "XL",
            "faculty_count": 3.132
        },
        {
            "university": "Universität Stuttgart",
            "year": 2018,
            "rank_display": 259,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/universitat-stuttgart",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-stuttgart_580_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.028,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "Curtin University",
            "year": 2018,
            "rank_display": 262,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/curtin-university",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/curtin-university_150_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4565,
            "size": "L",
            "faculty_count": 1698
        },
        {
            "university": "North Carolina State University",
            "year": 2018,
            "rank_display": 263,
            "score": 40.3,
            "link": "https://www.topuniversities.com/universities/north-carolina-state-university",
            "country": "United States",
            "city": "Raleigh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/north-carolina-state-university_592560cf2aeae70239af4c2a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 3156,
            "size": "L",
            "faculty_count": 1944
        },
        {
            "university": "National Taiwan University of Science and Technology (Taiwan Tech)",
            "year": 2018,
            "rank_display": 264,
            "score": 40.2,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-science-technology-taiwan-tech",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-of-science-and-technology-taiwan-tech_590_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1537,
            "size": "M",
            "faculty_count": 1005
        },
        {
            "university": "Indian Institute of Technology Madras (IITM)",
            "year": 2018,
            "rank_display": 264,
            "score": 40.2,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-madras-iitm",
            "country": "India",
            "city": "Chennai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-madras-iitm_286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 94,
            "size": "M",
            "faculty_count": 884
        },
        {
            "university": "University of Surrey",
            "year": 2018,
            "rank_display": 264,
            "score": 40.2,
            "link": "https://www.topuniversities.com/universities/university-surrey",
            "country": "United Kingdom",
            "city": "Guildford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-surrey_584_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5556,
            "size": "L",
            "faculty_count": 1221
        },
        {
            "university": "Universiti Sains Malaysia (USM)",
            "year": 2018,
            "rank_display": 264,
            "score": 40.2,
            "link": "https://www.topuniversities.com/universities/universiti-sains-malaysia-usm",
            "country": "Malaysia",
            "city": "Gelugor",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-sains-malaysia-usm_386_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4198,
            "size": "L",
            "faculty_count": 2249
        },
        {
            "university": "King Abdulaziz University (KAU)",
            "year": 2018,
            "rank_display": 267,
            "score": 40.1,
            "link": "https://www.topuniversities.com/universities/king-abdulaziz-university-kau",
            "country": "Saudi Arabia",
            "city": "Jeddah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-abdulaziz-university-kau_1172_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6821,
            "size": "XL",
            "faculty_count": 4054
        },
        {
            "university": "University of Dundee",
            "year": 2018,
            "rank_display": 267,
            "score": 40.1,
            "link": "https://www.topuniversities.com/universities/university-dundee",
            "country": "United Kingdom",
            "city": "Dundee",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/-university-of-dundee_592560cf2aeae70239af4b2c_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 3025,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Université Paris 1 Panthéon-Sorbonne",
            "year": 2018,
            "rank_display": 269,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/universite-paris-1-pantheon-sorbonne",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-1-panthon-sorbonne_482_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7257,
            "size": "XL",
            "faculty_count": 1679
        },
        {
            "university": "University of Navarra",
            "year": 2018,
            "rank_display": 270,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/university-navarra",
            "country": "Spain",
            "city": "Pamplona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-navarra_592560cf2aeae70239af4c40_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2978,
            "size": "M",
            "faculty_count": 1175
        },
        {
            "university": "Ecole des Ponts ParisTech",
            "year": 2018,
            "rank_display": 270,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/ecole-des-ponts-paristech",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-des-ponts-paristech_187_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 735,
            "size": "S",
            "faculty_count": 338
        },
        {
            "university": "Technical University of Darmstadt",
            "year": 2018,
            "rank_display": 272,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/technical-university-darmstadt",
            "country": "Germany",
            "city": "Darmstadt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-darmstadt_628_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3979,
            "size": "L",
            "faculty_count": 804
        },
        {
            "university": "University of East Anglia (UEA)",
            "year": 2018,
            "rank_display": 274,
            "score": 39.5,
            "link": "https://www.topuniversities.com/universities/university-east-anglia-uea",
            "country": "United Kingdom",
            "city": "Norwich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-east-anglia-uea_637_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3541,
            "size": "L",
            "faculty_count": 1206
        },
        {
            "university": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
            "year": 2018,
            "rank_display": 275,
            "score": 39.4,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-catalunya-barcelonatech-upc",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-catalunya_103_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3101,
            "size": "L",
            "faculty_count": 2485
        },
        {
            "university": "University of Turku",
            "year": 2018,
            "rank_display": 276,
            "score": 39.3,
            "link": "https://www.topuniversities.com/universities/university-turku",
            "country": "Finland",
            "city": "Turku",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turku_592560cf2aeae70239af4cfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 604,
            "size": "L",
            "faculty_count": 1505
        },
        {
            "university": "University of Strathclyde",
            "year": 2018,
            "rank_display": 277,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/university-strathclyde",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-strathclyde_579_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5397,
            "size": "L",
            "faculty_count": 1532
        },
        {
            "university": "Universitas Indonesia",
            "year": 2018,
            "rank_display": 277,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/universitas-indonesia",
            "country": "Indonesia",
            "city": "Depok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universitas-indonesia_290_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1412,
            "size": "XL",
            "faculty_count": 4210
        },
        {
            "university": "University of South Australia",
            "year": 2018,
            "rank_display": 279,
            "score": 39.1,
            "link": "https://www.topuniversities.com/universities/university-south-australia",
            "country": "Australia",
            "city": "Magill",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-south-australia_566_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.886,
            "size": "L",
            "faculty_count": 1.524
        },
        {
            "university": "Dalhousie University",
            "year": 2018,
            "rank_display": 279,
            "score": 39.1,
            "link": "https://www.topuniversities.com/universities/dalhousie-university",
            "country": "Canada",
            "city": "Halifax",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dalhousie-university_151_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4483,
            "size": "L",
            "faculty_count": 1150
        },
        {
            "university": "Universidad Carlos III de Madrid (UC3M)",
            "year": 2018,
            "rank_display": 281,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/universidad-carlos-iii-de-madrid-uc3m",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-carlos-iii-de-madrid-uc3m_1509_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.11,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "Wuhan University",
            "year": 2018,
            "rank_display": 282,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/wuhan-university",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/wuhan-university_889_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3682,
            "size": "XL",
            "faculty_count": 3828
        },
        {
            "university": "Rutgers University–New Brunswick",
            "year": 2018,
            "rank_display": 283,
            "score": 38.6,
            "link": "https://www.topuniversities.com/universities/rutgers-university-new-brunswick",
            "country": "United States",
            "city": "New Brunswick",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rutgers-university-new-brunswick_538_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7965,
            "size": "XL",
            "faculty_count": 5009
        },
        {
            "university": "University of Gothenburg",
            "year": 2018,
            "rank_display": 283,
            "score": 38.6,
            "link": "https://www.topuniversities.com/universities/university-gothenburg",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gothenburg_233_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5652,
            "size": "L",
            "faculty_count": 2680
        },
        {
            "university": "University College Cork",
            "year": 2018,
            "rank_display": 283,
            "score": 38.6,
            "link": "https://www.topuniversities.com/universities/university-college-cork",
            "country": "Ireland",
            "city": "Cork",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-cork_142_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 3002,
            "size": "L",
            "faculty_count": 1619
        },
        {
            "university": "Universität Innsbruck",
            "year": 2018,
            "rank_display": 286,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/universitat-innsbruck",
            "country": "Austria",
            "city": "Innsbruck",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-innsbruck_592560cf2aeae70239af4ba7_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 6323,
            "size": "L",
            "faculty_count": 1769
        },
        {
            "university": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            "year": 2018,
            "rank_display": 287,
            "score": 38.4,
            "link": "https://www.topuniversities.com/universities/friedrich-alexander-universitat-erlangen-nurnberg",
            "country": "Germany",
            "city": "Erlangen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/friedrich-alexander-universitt-erlangen-nrnberg_191_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 4943,
            "size": "XL",
            "faculty_count": 1107
        },
        {
            "university": "Linköping University",
            "year": 2018,
            "rank_display": 287,
            "score": 38.4,
            "link": "https://www.topuniversities.com/universities/linkoping-university",
            "country": "Sweden",
            "city": "Linköping",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/linkping-university_348_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.514,
            "size": "L",
            "faculty_count": 1.471
        },
        {
            "university": "University of Ottawa",
            "year": 2018,
            "rank_display": 289,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/university-ottawa",
            "country": "Canada",
            "city": "Ottawa",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-ottawa_475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 8885,
            "size": "XL",
            "faculty_count": 1677
        },
        {
            "university": "National Taiwan Normal University",
            "year": 2018,
            "rank_display": 289,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/national-taiwan-normal-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-normal-university_440_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.499,
            "size": "M",
            "faculty_count": 1.374
        },
        {
            "university": "Bauman Moscow State Technical University",
            "year": 2018,
            "rank_display": 291,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/bauman-moscow-state-technical-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bauman-moscow-state-technical-university_592560cf2aeae70239af505c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2370,
            "size": "L",
            "faculty_count": 4349
        },
        {
            "university": "University of Waikato",
            "year": 2018,
            "rank_display": 292,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/university-waikato",
            "country": "New Zealand",
            "city": "Hamilton",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waikato_660_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.27,
            "size": "M",
            "faculty_count": 595
        },
        {
            "university": "Indian Institute of Technology Kanpur (IITK)",
            "year": 2018,
            "rank_display": 293,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kanpur-iitk",
            "country": "India",
            "city": "Kanpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kanpur-iitk_592560cf2aeae70239af4ba5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 29,
            "size": "M",
            "faculty_count": 551
        },
        {
            "university": "Deakin University",
            "year": 2018,
            "rank_display": 293,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/deakin-university",
            "country": "Australia",
            "city": "Geelong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/download-48x48.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 10684,
            "size": "XL",
            "faculty_count": 1851
        },
        {
            "university": "Université Paris-Sorbonne (Paris IV)",
            "year": 2018,
            "rank_display": 293,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/universite-paris-sorbonne-paris-iv",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-sorbonne-paris-iv_484_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "Università di Padova",
            "year": 2018,
            "rank_display": 296,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universita-di-padova",
            "country": "Italy",
            "city": "Padua",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitdi-padova_480_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2654,
            "size": "XL",
            "faculty_count": 2806
        },
        {
            "university": "SOAS University of London",
            "year": 2018,
            "rank_display": 296,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/soas-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/soas-university-of-london-_592560cf2aeae70239af4bf0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.398,
            "size": "S",
            "faculty_count": 300
        },
        {
            "university": "Universitat Pompeu Fabra (Barcelona)",
            "year": 2018,
            "rank_display": 296,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/universitat-pompeu-fabra",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-pompeu-fabra_504_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1633,
            "size": "L",
            "faculty_count": 785
        },
        {
            "university": "Ewha Womans University",
            "year": 2018,
            "rank_display": 299,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/ewha-womans-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ewha-womans-university_592560cf2aeae70239af4b38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.105,
            "size": "L",
            "faculty_count": 2.436
        },
        {
            "university": "Hong Kong Baptist University",
            "year": 2018,
            "rank_display": 299,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/hong-kong-baptist-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hong-kong-baptist-university_893_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4704,
            "size": "M",
            "faculty_count": 980
        },
        {
            "university": "University of California, Santa Cruz",
            "year": 2018,
            "rank_display": 301,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/university-california-santa-cruz",
            "country": "United States",
            "city": "Santa Cruz",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-cruz_92_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 2.13,
            "size": "L",
            "faculty_count": 1.045
        },
        {
            "university": "University of Porto",
            "year": 2018,
            "rank_display": 301,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/university-porto",
            "country": "Portugal",
            "city": "Porto",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-porto_760_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3783,
            "size": "L",
            "faculty_count": 2028
        },
        {
            "university": "Université de Strasbourg",
            "year": 2018,
            "rank_display": 303,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/universite-de-strasbourg",
            "country": "France",
            "city": "Strasbourg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-strasbourg_578_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 9.111,
            "size": "XL",
            "faculty_count": 2.544
        },
        {
            "university": "Indiana University Bloomington",
            "year": 2018,
            "rank_display": 304,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/indiana-university-bloomington",
            "country": "United States",
            "city": "Bloomington",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/indiana-university-bloomington_289_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3722,
            "size": "XL",
            "faculty_count": 3837
        },
        {
            "university": "University of Lisbon",
            "year": 2018,
            "rank_display": 305,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/university-lisbon",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lisbon_1475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8.546,
            "size": "XL",
            "faculty_count": 3.389
        },
        {
            "university": "Université Diderot  Paris 7",
            "year": 2018,
            "rank_display": 305,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/universite-diderot-paris-7",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-diderot-paris-7_592560cf2aeae70239af4c6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 11387,
            "size": "XL",
            "faculty_count": 3755
        },
        {
            "university": "Politecnico di Torino",
            "year": 2018,
            "rank_display": 307,
            "score": 37,
            "link": "https://www.topuniversities.com/universities/politecnico-di-torino",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-torino_633_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 5.369,
            "size": "XL",
            "faculty_count": 1.05
        },
        {
            "university": "Birkbeck, University of London",
            "year": 2018,
            "rank_display": 308,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/birkbeck-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/birkbeck-university-of-london_1636_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3.214,
            "size": "M",
            "faculty_count": 585
        },
        {
            "university": "Westfälische Wilhelms-Universität Münster",
            "year": 2018,
            "rank_display": 308,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/westfalische-wilhelms-universitat-munster",
            "country": "Germany",
            "city": "Münster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/westflische-wilhelms-universitt-mnster_421_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.845,
            "size": "XL",
            "faculty_count": 4.23
        },
        {
            "university": "Indian Institute of Technology Kharagpur (IIT-KGP)",
            "year": 2018,
            "rank_display": 308,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kharagpur-iit-kgp",
            "country": "India",
            "city": "Kharagpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kharagpur-iit-kgp_285_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 27,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "Universidade Federal do Rio de Janeiro",
            "year": 2018,
            "rank_display": 311,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/universidade-federal-do-rio-de-janeiro",
            "country": "Brazil",
            "city": "Rio de Janeiro",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-do-rio-de-janeiro_525_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 555,
            "size": "XL",
            "faculty_count": 3.983
        },
        {
            "university": "Heriot-Watt University",
            "year": 2018,
            "rank_display": 312,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/heriot-watt-university",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/heriot-watt-university_592560cf2aeae70239af4d5f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3536,
            "size": "M",
            "faculty_count": 806
        },
        {
            "university": "University of Tasmania",
            "year": 2018,
            "rank_display": 313,
            "score": 36.5,
            "link": "https://www.topuniversities.com/universities/university-tasmania",
            "country": "Australia",
            "city": "Hobart",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tasmania_592560cf2aeae70239af4cd5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5260,
            "size": "L",
            "faculty_count": 982
        },
        {
            "university": "University of Tartu",
            "year": 2018,
            "rank_display": 314,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/university-tartu",
            "country": "Estonia",
            "city": "Tartu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tartu_592560cf2aeae70239af4d08_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1400,
            "size": "M",
            "faculty_count": 1605
        },
        {
            "university": "Charles University",
            "year": 2018,
            "rank_display": 314,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/charles-university",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/charles-university_117_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9080,
            "size": "XL",
            "faculty_count": 5019
        },
        {
            "university": "Tongji University",
            "year": 2018,
            "rank_display": 316,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/tongji-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tongji-university_617_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5095,
            "size": "XL",
            "faculty_count": 2785
        },
        {
            "university": "Massey University",
            "year": 2018,
            "rank_display": 316,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/massey-university",
            "country": "New Zealand",
            "city": "Palmerston North",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/massey-university_395_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4836,
            "size": "M",
            "faculty_count": 1209
        },
        {
            "university": "University at Buffalo SUNY",
            "year": 2018,
            "rank_display": 318,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/university-buffalo-suny",
            "country": "United States",
            "city": "Buffalo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-at-buffalo-suny_1888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4.409,
            "size": "L",
            "faculty_count": 1.53
        },
        {
            "university": "Sun Yat-sen University",
            "year": 2018,
            "rank_display": 319,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/sun-yat-sen-university",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sun-yat-sen-university_888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3127,
            "size": "XL",
            "faculty_count": 7002
        },
        {
            "university": "Lincoln University",
            "year": 2018,
            "rank_display": 319,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/lincoln-university",
            "country": "New Zealand",
            "city": "Lincoln",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/lincoln-university-_592560cf2aeae70239af4ef3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 898,
            "size": "S",
            "faculty_count": 643
        },
        {
            "university": "Université de Liège",
            "year": 2018,
            "rank_display": 319,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/universite-de-liege",
            "country": "Belgium",
            "city": "Arlon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-lige_592560cf2aeae70239af4bd9_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.381,
            "size": "L",
            "faculty_count": 1.619
        },
        {
            "university": "Hiroshima University",
            "year": 2018,
            "rank_display": 322,
            "score": 35.8,
            "link": "https://www.topuniversities.com/universities/hiroshima-university",
            "country": "Japan",
            "city": "Higashihiroshima City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hiroshima-university_264_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.68,
            "size": "L",
            "faculty_count": 2.366
        },
        {
            "university": "University of California, Riverside",
            "year": 2018,
            "rank_display": 323,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/university-california-riverside",
            "country": "United States",
            "city": "Riverside",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-riverside_88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.034,
            "size": "L",
            "faculty_count": 1.497
        },
        {
            "university": "Tomsk State University",
            "year": 2018,
            "rank_display": 323,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/tomsk-state-university",
            "country": "Russia",
            "city": "Tomsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tomsk-state-university_616_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2828,
            "size": "M",
            "faculty_count": 1742
        },
        {
            "university": "University of Milan",
            "year": 2018,
            "rank_display": 325,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/university-milan",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milan_1873_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3269,
            "size": "XL",
            "faculty_count": 2516
        },
        {
            "university": "Harbin Institute of Technology",
            "year": 2018,
            "rank_display": 325,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/harbin-institute-technology",
            "country": "China (Mainland)",
            "city": "Harbin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/harbin-institute-of-technology_877_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2102,
            "size": "XL",
            "faculty_count": 3215
        },
        {
            "university": "Yeshiva University",
            "year": 2018,
            "rank_display": 325,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/yeshiva-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-katz-school-at-yeshiva-university_685_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 467,
            "size": "S",
            "faculty_count": 471
        },
        {
            "university": "Griffith University",
            "year": 2018,
            "rank_display": 325,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/griffith-university",
            "country": "Australia",
            "city": "Nathan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/griffith-university_242_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5875,
            "size": "XL",
            "faculty_count": 1918
        },
        {
            "university": "National Yang Ming University",
            "year": 2018,
            "rank_display": 329,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/national-yang-ming-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-yang-ming-university_444_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 237,
            "size": "S",
            "faculty_count": 965
        },
        {
            "university": "ENS Paris-Saclay",
            "year": 2018,
            "rank_display": 330,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/ens-paris-saclay",
            "country": "France",
            "city": "Cachan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-de-cachan_1878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 298,
            "size": "S",
            "faculty_count": 289
        },
        {
            "university": "Bandung Institute of Technology (ITB)",
            "year": 2018,
            "rank_display": 331,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/bandung-institute-technology-itb",
            "country": "Indonesia",
            "city": "Bandung",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bandung-institute-of-technology-itb_41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 613,
            "size": "L",
            "faculty_count": 2228
        },
        {
            "university": "University of Cologne",
            "year": 2018,
            "rank_display": 331,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/university-cologne",
            "country": "Germany",
            "city": "Cologne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cologne_592560cf2aeae70239af4bc6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4890,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "Universidad Austral",
            "year": 2018,
            "rank_display": 331,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/universidad-austral",
            "country": "Argentina",
            "city": "Pilar",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-austral_38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 334,
            "size": "S",
            "faculty_count": 663
        },
        {
            "university": "Belarusian State University",
            "year": 2018,
            "rank_display": 334,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/belarusian-state-university",
            "country": "Belarus",
            "city": "Minsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/belarusian-state-university_14128_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4264,
            "size": "L",
            "faculty_count": 4173
        },
        {
            "university": "Mahidol University",
            "year": 2018,
            "rank_display": 334,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/mahidol-university",
            "country": "Thailand",
            "city": "Nakhon Pathom",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/mahidol-university_381_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1415,
            "size": "L",
            "faculty_count": 3850
        },
        {
            "university": "L.N. Gumilyov Eurasian National University (ENU)",
            "year": 2018,
            "rank_display": 336,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/ln-gumilyov-eurasian-national-university-enu",
            "country": "Kazakhstan",
            "city": "Nur-Sultan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/l.n.-gumilyov-eurasian-national-university-enu_592560cf2aeae70239af52e8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1.077,
            "size": "L",
            "faculty_count": 3.35
        },
        {
            "university": "Universidad de Belgrano",
            "year": 2018,
            "rank_display": 337,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/universidad-de-belgrano",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-belgrano_51_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 758,
            "size": "M",
            "faculty_count": 810
        },
        {
            "university": "Umea University",
            "year": 2018,
            "rank_display": 338,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/umea-university",
            "country": "Sweden",
            "city": "Umeå",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/umea-university_639_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.186,
            "size": "L",
            "faculty_count": 1.966
        },
        {
            "university": "Gwangju Institute of Science and Technology (GIST)",
            "year": 2018,
            "rank_display": 339,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/gwangju-institute-science-technology-gist",
            "country": "South Korea",
            "city": "Gwangju",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gwangju-institute-of-science-and-technology-gist_718_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 122,
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "Boston College",
            "year": 2018,
            "rank_display": 339,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/boston-college",
            "country": "United States",
            "city": "Newton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-college_723_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.471,
            "size": "L",
            "faculty_count": 1.048
        },
        {
            "university": "University of Hawaiʻi at Mānoa",
            "year": 2018,
            "rank_display": 341,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/university-hawaii-manoa",
            "country": "United States",
            "city": "Honolulu",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hawaii-at-maoa_255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.586,
            "size": "L",
            "faculty_count": 1.64
        },
        {
            "university": "Universität Jena",
            "year": 2018,
            "rank_display": 341,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/universitat-jena",
            "country": "Germany",
            "city": "Jena",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-jena_592560cf2aeae70239af4bb5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2.307,
            "size": "L",
            "faculty_count": 3.974
        },
        {
            "university": "City, University of London",
            "year": 2018,
            "rank_display": 343,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/city-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-london_354_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 7.362,
            "size": "L",
            "faculty_count": 970
        },
        {
            "university": "Xi’an Jiaotong University",
            "year": 2018,
            "rank_display": 344,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/xian-jiaotong-university",
            "country": "China (Mainland)",
            "city": "Xi'an",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xian-jiaotong-university_683_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2266,
            "size": "XL",
            "faculty_count": 3729
        },
        {
            "university": "Nankai University",
            "year": 2018,
            "rank_display": 344,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/nankai-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nankai-university_882_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.488,
            "size": "XL",
            "faculty_count": 2.846
        },
        {
            "university": "Northeastern University",
            "year": 2018,
            "rank_display": 346,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/northeastern-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northeastern-university_454_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7.595,
            "size": "L",
            "faculty_count": 1.723
        },
        {
            "university": "Brunel University London",
            "year": 2018,
            "rank_display": 346,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/brunel-university-london",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/brunel-university-london_592560cf2aeae70239af4acc_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 5.305,
            "size": "L",
            "faculty_count": 860
        },
        {
            "university": "University of Victoria (UVic)",
            "year": 2018,
            "rank_display": 346,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/university-victoria-uvic",
            "country": "Canada",
            "city": "Victoria",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-victoria-uvic_592560cf2aeae70239af4d0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 6.135,
            "size": "L",
            "faculty_count": 865
        },
        {
            "university": "Essex, University of",
            "year": 2018,
            "rank_display": 347,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/essex-university",
            "country": "United Kingdom",
            "city": "Colchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-essex_592560cf2aeae70239af4b4c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6.124,
            "size": "L",
            "faculty_count": 1.044
        },
        {
            "university": "Universiti Brunei Darussalam (UBD)",
            "year": 2018,
            "rank_display": 349,
            "score": 33.8,
            "link": "https://www.topuniversities.com/universities/universiti-brunei-darussalam-ubd",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-brunei-darussalam-ubd_22212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 635,
            "size": "S",
            "faculty_count": 475
        },
        {
            "university": "Qatar University",
            "year": 2018,
            "rank_display": 349,
            "score": 33.8,
            "link": "https://www.topuniversities.com/universities/qatar-university",
            "country": "Qatar",
            "city": "Doha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/qatar-university_2537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3197,
            "size": "M",
            "faculty_count": 1163
        },
        {
            "university": "Kobe University",
            "year": 2018,
            "rank_display": 351,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/kobe-university",
            "country": "Japan",
            "city": "Kobe City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kobe-university_320_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.216,
            "size": "L",
            "faculty_count": 1.986
        },
        {
            "university": "Ben-Gurion University of The Negev",
            "year": 2018,
            "rank_display": 352,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/ben-gurion-university-negev",
            "country": "Israel",
            "city": "Be'er Sheva",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ben-gurion-university-of-the-negev_592560cf2aeae70239af4ab7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.755,
            "size": "L",
            "faculty_count": 1.06
        },
        {
            "university": "George Washington University",
            "year": 2018,
            "rank_display": 352,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/george-washington-university",
            "country": "United States",
            "city": "Ashburn",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/george-washington-university_223_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4.024,
            "size": "L",
            "faculty_count": 2.016
        },
        {
            "university": "Université Paris-Dauphine, Université PSL",
            "year": 2018,
            "rank_display": 355,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/universite-paris-dauphine-universite-psl",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-dauphine-universit-psl_592560cf2aeae70239af4c68_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 12,
            "international_students": 2039,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
            "year": 2018,
            "rank_display": 355,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/moscow-institute-physics-technology-mipt-moscow-phystech",
            "country": "Russia",
            "city": "Dolgoprudny",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-institute-of-physics-and-technology-mipt-moscow-phystech_15270_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1153,
            "size": "M",
            "faculty_count": 1110
        },
        {
            "university": "Tilburg University",
            "year": 2018,
            "rank_display": 357,
            "score": 33.3,
            "link": "https://www.topuniversities.com/universities/tilburg-university",
            "country": "Netherlands",
            "city": "Tilburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tilburg-university_592560cf2aeae70239af4ce5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.189,
            "size": "M",
            "faculty_count": 745
        },
        {
            "university": "University of Jyväskylä",
            "year": 2018,
            "rank_display": 357,
            "score": 33.3,
            "link": "https://www.topuniversities.com/universities/university-jyvaskyla",
            "country": "Finland",
            "city": "Jyväskylä",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-jyvskyl_306_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 339,
            "size": "M",
            "faculty_count": 1.573
        },
        {
            "university": "University Ulm",
            "year": 2018,
            "rank_display": 357,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-ulm",
            "country": "Germany",
            "city": "Ulm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-ulm_638_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1289,
            "size": "M",
            "faculty_count": 1473
        },
        {
            "university": "La Trobe University",
            "year": 2018,
            "rank_display": 360,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/la-trobe-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/la-trobe-university_592560cf2aeae70239af4bd1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 9.456,
            "size": "L",
            "faculty_count": 1.252
        },
        {
            "university": "Oxford Brookes University",
            "year": 2018,
            "rank_display": 361,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/oxford-brookes-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/oxford-brookes-university_592560cf2aeae70239af4c61_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 15,
            "international_students": 3.439,
            "size": "L",
            "faculty_count": 1471
        },
        {
            "university": "Universidade Nova de Lisboa",
            "year": 2018,
            "rank_display": 361,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/universidade-nova-de-lisboa",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-nova-de-lisboa_460_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.427,
            "size": "L",
            "faculty_count": 1.72
        },
        {
            "university": "Stellenbosch University",
            "year": 2018,
            "rank_display": 361,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/stellenbosch-university",
            "country": "South Africa",
            "city": "Stellenbosch",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/stellenbosch-university_574_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 1.776,
            "size": "L",
            "faculty_count": 934
        },
        {
            "university": "University of Witwatersrand",
            "year": 2018,
            "rank_display": 364,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/university-witwatersrand",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-witwatersrand_679_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.885,
            "size": "L",
            "faculty_count": 1.626
        },
        {
            "university": "Rensselaer Polytechnic Institute",
            "year": 2018,
            "rank_display": 364,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/rensselaer-polytechnic-institute",
            "country": "United States",
            "city": "Troy",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rensselaer-polytechnic-institute_522_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.594,
            "size": "M",
            "faculty_count": 639
        },
        {
            "university": "Pontificia Universidad Católica Argentina",
            "year": 2018,
            "rank_display": 364,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-argentina",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-argentina_592560cf2aeae70239af5486_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 4,
            "international_students": 1063,
            "size": "L",
            "faculty_count": 3805
        },
        {
            "university": "Tokyo Medical and Dental University (TMDU)",
            "year": 2018,
            "rank_display": 367,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/tokyo-medical-dental-university-tmdu",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-medical-and-dental-university-tmdu_592560cf2aeae70239af4e8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 325,
            "size": "S",
            "faculty_count": 930
        },
        {
            "university": "University of Tromsø The Arctic University of Norway",
            "year": 2018,
            "rank_display": 367,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/university-tromso-arctic-university-norway",
            "country": "Norway",
            "city": "Tromsø",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-troms-the-arctic-university-of-norway_625_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.134,
            "size": "L",
            "faculty_count": 2.107
        },
        {
            "university": "James Cook University",
            "year": 2018,
            "rank_display": 367,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/james-cook-university",
            "country": "Australia",
            "city": "Townsville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/james-cook-university_833_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.169,
            "size": "M",
            "faculty_count": 2940
        },
        {
            "university": "University of the Philippines",
            "year": 2018,
            "rank_display": 367,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/university-philippines",
            "country": "Philippines",
            "city": "Quezon City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-philippines_498_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 292,
            "size": "XL",
            "faculty_count": 4.48
        },
        {
            "university": "Virginia Polytechnic Institute and State University",
            "year": 2018,
            "rank_display": 367,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/virginia-polytechnic-institute-state-university",
            "country": "United States",
            "city": "Blacksburg",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/virginia-polytechnic-institute-and-state-university_592560cf2aeae70239af4d15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.477,
            "size": "XL",
            "faculty_count": 2.802
        },
        {
            "university": "University of St.Gallen (HSG)",
            "year": 2018,
            "rank_display": 372,
            "score": 32.8,
            "link": "https://www.topuniversities.com/universities/university-stgallen-hsg",
            "country": "Switzerland",
            "city": "St. Gallen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st.gallen-hsg_571_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 2.39,
            "size": "M",
            "faculty_count": 444
        },
        {
            "university": "Aston University",
            "year": 2018,
            "rank_display": 373,
            "score": 32.5,
            "link": "https://www.topuniversities.com/universities/aston-university",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aston-university_29_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1.719,
            "size": "M",
            "faculty_count": 730
        },
        {
            "university": "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
            "year": 2018,
            "rank_display": 373,
            "score": 32.5,
            "link": "https://www.topuniversities.com/universities/national-research-nuclear-university-mephi-moscow-engineering-physics-institute",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-nuclear-university-mephi-moscow-engineering-physics-institute_15207_small.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1590,
            "size": "M",
            "faculty_count": 998
        },
        {
            "university": "University of Kent",
            "year": 2018,
            "rank_display": 373,
            "score": 32.5,
            "link": "https://www.topuniversities.com/universities/university-kent",
            "country": "United Kingdom",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kent_315_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.515,
            "size": "L",
            "faculty_count": 4000
        },
        {
            "university": "MGIMO University",
            "year": 2018,
            "rank_display": 373,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/mgimo-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-state-institute-of-international-relations-mgimo-university_2156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 1517,
            "size": "M",
            "faculty_count": 1739
        },
        {
            "university": "Universitat Politècnica de València",
            "year": 2018,
            "rank_display": 373,
            "score": 32.5,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-valencia",
            "country": "Spain",
            "city": "València",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-valncia_648_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.589,
            "size": "L",
            "faculty_count": 2.645
        },
        {
            "university": "Université Laval",
            "year": 2018,
            "rank_display": 378,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/universite-laval",
            "country": "Canada",
            "city": "Québec",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/laval-university_337_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3.673,
            "size": "L",
            "faculty_count": 2.29
        },
        {
            "university": "Aalborg University",
            "year": 2018,
            "rank_display": 379,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/aalborg-university",
            "country": "Denmark",
            "city": "Aalborg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalborg-university_1262_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.474,
            "size": "L",
            "faculty_count": 1.716
        },
        {
            "university": "Tampere University of Technology",
            "year": 2018,
            "rank_display": 380,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/tampere-university-technology",
            "country": "Finland",
            "city": "Tampere",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tampere-university-of-technology_1269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "M",
            "faculty_count": 665
        },
        {
            "university": "Université de Montpellier",
            "year": 2018,
            "rank_display": 381,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/universite-de-montpellier",
            "country": "France",
            "city": "Montpellier",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montpellier_592560e69988f300e2321d99_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6.229,
            "size": "XL",
            "faculty_count": 2.464
        },
        {
            "university": "HSE University",
            "year": 2018,
            "rank_display": 382,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/hse-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/logo-3-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4535,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "Stony Brook University, State University of New York",
            "year": 2018,
            "rank_display": 382,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/stony-brook-university-state-university-new-york",
            "country": "United States",
            "city": "Stony Brook",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stony-brook-university-state-university-of-new-york_592560cf2aeae70239af4ccb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6.198,
            "size": "L",
            "faculty_count": 2.316
        },
        {
            "university": "University of Southern Denmark (SDU)",
            "year": 2018,
            "rank_display": 384,
            "score": 31.6,
            "link": "https://www.topuniversities.com/universities/university-southern-denmark-sdu",
            "country": "Denmark",
            "city": "Odense",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-denmark_592560cf2aeae70239af4c53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2511,
            "size": "L",
            "faculty_count": 1760
        },
        {
            "university": "Universität Konstanz",
            "year": 2018,
            "rank_display": 385,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/universitat-konstanz",
            "country": "Germany",
            "city": "Konstanz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-konstanz_323_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.239,
            "size": "M",
            "faculty_count": 1.039
        },
        {
            "university": "National Research Tomsk Polytechnic University",
            "year": 2018,
            "rank_display": 386,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/national-research-tomsk-polytechnic-university",
            "country": "Russia",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-tomsk-polytechnic-university_14158_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.757,
            "size": "M",
            "faculty_count": 1.55
        },
        {
            "university": "University of Kansas",
            "year": 2018,
            "rank_display": 386,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/university-kansas",
            "country": "United States",
            "city": "Lawrence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kansas_309_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2.014,
            "size": "L",
            "faculty_count": 3.357
        },
        {
            "university": "Universität Mannheim",
            "year": 2018,
            "rank_display": 388,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/universitat-mannheim",
            "country": "Germany",
            "city": "Mannheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-mannheim_592560cf2aeae70239af4c0b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 1.29,
            "size": "M",
            "faculty_count": 307
        },
        {
            "university": "National Sun Yat-sen University",
            "year": 2018,
            "rank_display": 388,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/national-sun-yat-sen-university",
            "country": "Taiwan",
            "city": "Kaohsiung City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-sun-yat-sen-university_439_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 757,
            "size": "M",
            "faculty_count": 735
        },
        {
            "university": "United Arab Emirates University",
            "year": 2018,
            "rank_display": 390,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/united-arab-emirates-university",
            "country": "United Arab Emirates",
            "city": "Al Ain",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/united-arab-emirates-university_778_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1238,
            "size": "M",
            "faculty_count": 919
        },
        {
            "university": "Dublin City University",
            "year": 2018,
            "rank_display": 391,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/dublin-city-university",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/dublin-city-university_592560cf2aeae70239af4b27_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.415,
            "size": "L",
            "faculty_count": 1.257
        },
        {
            "university": "University of Utah",
            "year": 2018,
            "rank_display": 391,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/university-utah",
            "country": "United States",
            "city": "Salt Lake City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-utah_645_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.134,
            "size": "L",
            "faculty_count": 3.345
        },
        {
            "university": "National Central University",
            "year": 2018,
            "rank_display": 391,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/national-central-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-central-university_434_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.098,
            "size": "M",
            "faculty_count": 985
        },
        {
            "university": "University of Colorado, Denver",
            "year": 2018,
            "rank_display": 394,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/university-colorado-denver",
            "country": "United States",
            "city": "Denver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-denver_2116_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 1.274,
            "size": "L",
            "faculty_count": 5.319
        },
        {
            "university": "Illinois Institute of Technology",
            "year": 2018,
            "rank_display": 395,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/illinois-institute-technology",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/illinois-institute-of-technology_277_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.33,
            "size": "M",
            "faculty_count": 402
        },
        {
            "university": "The American University in Cairo",
            "year": 2018,
            "rank_display": 395,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/american-university-cairo",
            "country": "Egypt",
            "city": "Cairo",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/the-american-university-in-cairo_36_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 213,
            "size": "M",
            "faculty_count": 578
        },
        {
            "university": "HUFS - Hankuk (Korea) University of Foreign Studies",
            "year": 2018,
            "rank_display": 397,
            "score": 30.6,
            "link": "https://www.topuniversities.com/universities/hufs-hankuk-korea-university-foreign-studies",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hufs-hankuk-korea-university-of-foreign-studies_1054_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.933,
            "size": "L",
            "faculty_count": 2.353
        },
        {
            "university": "Goldsmiths, University of London",
            "year": 2018,
            "rank_display": 398,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/goldsmiths-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/goldsmiths-university-of-london_355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.492,
            "size": "M",
            "faculty_count": 683
        },
        {
            "university": "Taipei Medical University (TMU)",
            "year": 2018,
            "rank_display": 398,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/taipei-medical-university-tmu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taipei-medical-university_2134_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 614,
            "size": "M",
            "faculty_count": 999
        },
        {
            "university": "Johannes Gutenberg Universität Mainz",
            "year": 2018,
            "rank_display": 398,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/johannes-gutenberg-universitat-mainz",
            "country": "Germany",
            "city": "Mainz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-gutenberg-universitt-mainz_382_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.605,
            "size": "XL",
            "faculty_count": 3.473
        },
        {
            "university": "Massachusetts Institute of Technology (MIT)",
            "year": 2019,
            "rank_display": 1,
            "score": 100,
            "link": "https://www.topuniversities.com/universities/massachusetts-institute-technology-mit",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/massachusetts-institute-of-technology-mit_410_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 3730,
            "size": "M",
            "faculty_count": 3065
        },
        {
            "university": "Stanford University",
            "year": 2019,
            "rank_display": 2,
            "score": 98.6,
            "link": "https://www.topuniversities.com/universities/stanford-university",
            "country": "United States",
            "city": "Stanford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stanford-university_573_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3879,
            "size": "L",
            "faculty_count": 4725
        },
        {
            "university": "Harvard University",
            "year": 2019,
            "rank_display": 3,
            "score": 98.5,
            "link": "https://www.topuniversities.com/universities/harvard-university",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/harvard-university_253_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5877,
            "size": "L",
            "faculty_count": 4646
        },
        {
            "university": "California Institute of Technology (Caltech)",
            "year": 2019,
            "rank_display": 4,
            "score": 97.2,
            "link": "https://www.topuniversities.com/universities/california-institute-technology-caltech",
            "country": "United States",
            "city": "Pasadena",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/california-institute-of-technology-caltech_94_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 692,
            "size": "S",
            "faculty_count": 968
        },
        {
            "university": "University of Oxford",
            "year": 2019,
            "rank_display": 5,
            "score": 96.8,
            "link": "https://www.topuniversities.com/universities/university-oxford",
            "country": "United Kingdom",
            "city": "Oxford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oxford_478_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 8442,
            "size": "L",
            "faculty_count": 6708
        },
        {
            "university": "University of Cambridge",
            "year": 2019,
            "rank_display": 6,
            "score": 95.6,
            "link": "https://www.topuniversities.com/universities/university-cambridge",
            "country": "United Kingdom",
            "city": "Cambridge",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cambridge_95_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 4,
            "international_students": 7925,
            "size": "L",
            "faculty_count": 5800
        },
        {
            "university": "ETH Zurich - Swiss Federal Institute of Technology",
            "year": 2019,
            "rank_display": 7,
            "score": 95.3,
            "link": "https://www.topuniversities.com/universities/eth-zurich-swiss-federal-institute-technology",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eth-zurich-swiss-federal-institute-of-technology_201_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7733,
            "size": "L",
            "faculty_count": 2719
        },
        {
            "university": "Imperial College London",
            "year": 2019,
            "rank_display": 8,
            "score": 93.3,
            "link": "https://www.topuniversities.com/universities/imperial-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/imperial-college-london_592560cf2aeae70239af4be8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11143,
            "size": "L",
            "faculty_count": 8000
        },
        {
            "university": "University of Chicago",
            "year": 2019,
            "rank_display": 9,
            "score": 93.2,
            "link": "https://www.topuniversities.com/universities/university-chicago",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chicago_120_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4696,
            "size": "L",
            "faculty_count": 2703
        },
        {
            "university": "UCL",
            "year": 2019,
            "rank_display": 10,
            "score": 92.9,
            "link": "https://www.topuniversities.com/universities/ucl",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ucl_592560cf2aeae70239af4bf1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 21824,
            "size": "XL",
            "faculty_count": 7195
        },
        {
            "university": "National University of Singapore (NUS)",
            "year": 2019,
            "rank_display": 11,
            "score": 92,
            "link": "https://www.topuniversities.com/universities/national-university-singapore-nus",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-singapore-nus_443_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7551,
            "size": "XL",
            "faculty_count": 4288
        },
        {
            "university": "Nanyang Technological University, Singapore (NTU)",
            "year": 2019,
            "rank_display": 12,
            "score": 91.3,
            "link": "https://www.topuniversities.com/universities/nanyang-technological-university-singapore-ntu",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanyang-technological-university-singapore-ntu_592560cf2aeae70239af4c32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6091,
            "size": "L",
            "faculty_count": 3812
        },
        {
            "university": "Princeton University",
            "year": 2019,
            "rank_display": 13,
            "score": 90.9,
            "link": "https://www.topuniversities.com/universities/princeton-university",
            "country": "United States",
            "city": "Princeton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/princeton-university_508_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1912,
            "size": "M",
            "faculty_count": 1050
        },
        {
            "university": "Cornell University",
            "year": 2019,
            "rank_display": 14,
            "score": 90.5,
            "link": "https://www.topuniversities.com/universities/cornell-university",
            "country": "United States",
            "city": "Ithaca",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/cornell-university_143_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Yale University",
            "year": 2019,
            "rank_display": 15,
            "score": 89.6,
            "link": "https://www.topuniversities.com/universities/yale-university",
            "country": "United States",
            "city": "New Haven",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/yale-university_684_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 2537,
            "size": "M",
            "faculty_count": 5391
        },
        {
            "university": "Columbia University",
            "year": 2019,
            "rank_display": 16,
            "score": 88.5,
            "link": "https://www.topuniversities.com/universities/columbia-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/columbia-university_138_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 11252,
            "size": "L",
            "faculty_count": 7087
        },
        {
            "university": "Tsinghua University",
            "year": 2019,
            "rank_display": 17,
            "score": 87.2,
            "link": "https://www.topuniversities.com/universities/tsinghua-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tsinghua-university_626_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5420,
            "size": "XL",
            "faculty_count": 6174
        },
        {
            "university": "The University of Edinburgh",
            "year": 2019,
            "rank_display": 18,
            "score": 86.9,
            "link": "https://www.topuniversities.com/universities/university-edinburgh",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-edinburgh_180_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 14637,
            "size": "XL",
            "faculty_count": 4832
        },
        {
            "university": "University of Pennsylvania",
            "year": 2019,
            "rank_display": 19,
            "score": 86.5,
            "link": "https://www.topuniversities.com/universities/university-pennsylvania",
            "country": "United States",
            "city": "Philadelphia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pennsylvania_495_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4636,
            "size": "L",
            "faculty_count": 5154
        },
        {
            "university": "University of Michigan-Ann Arbor",
            "year": 2019,
            "rank_display": 20,
            "score": 86.4,
            "link": "https://www.topuniversities.com/universities/university-michigan-ann-arbor",
            "country": "United States",
            "city": "Ann Arbor",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-michigan_403_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 7341,
            "size": "XL",
            "faculty_count": 7132
        },
        {
            "university": "Johns Hopkins University",
            "year": 2019,
            "rank_display": 21,
            "score": 85.9,
            "link": "https://www.topuniversities.com/universities/johns-hopkins-university",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/johns-hopkins-university_305_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 5070,
            "size": "L",
            "faculty_count": 4855
        },
        {
            "university": "EPFL",
            "year": 2019,
            "rank_display": 22,
            "score": 85.8,
            "link": "https://www.topuniversities.com/universities/epfl",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/epfl-ecole-polytechnique-federale-de-lausanne_592560cf2aeae70239af4b34_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6426,
            "size": "M",
            "faculty_count": 1767
        },
        {
            "university": "The University of Tokyo",
            "year": 2019,
            "rank_display": 23,
            "score": 85.3,
            "link": "https://www.topuniversities.com/universities/university-tokyo",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tokyo_615_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3983,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "The Australian National University",
            "year": 2019,
            "rank_display": 24,
            "score": 84.4,
            "link": "https://www.topuniversities.com/universities/australian-national-university",
            "country": "Australia",
            "city": "Canberra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-australian-national-university_40_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6496,
            "size": "L",
            "faculty_count": 1763
        },
        {
            "university": "The University of Hong Kong",
            "year": 2019,
            "rank_display": 25,
            "score": 84.3,
            "link": "https://www.topuniversities.com/universities/university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-hong-kong_268_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 8311,
            "size": "L",
            "faculty_count": 2944
        },
        {
            "university": "Duke University",
            "year": 2019,
            "rank_display": 26,
            "score": 83.9,
            "link": "https://www.topuniversities.com/universities/duke-university",
            "country": "United States",
            "city": "Durham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/duke-university_168_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3445,
            "size": "L",
            "faculty_count": 5673
        },
        {
            "university": "University of California, Berkeley (UCB)",
            "year": 2019,
            "rank_display": 27,
            "score": 83.2,
            "link": "https://www.topuniversities.com/universities/university-california-berkeley-ucb",
            "country": "United States",
            "city": "Berkeley",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-berkeley-ucb_84_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 9428,
            "size": "XL",
            "faculty_count": 3701
        },
        {
            "university": "University of Toronto",
            "year": 2019,
            "rank_display": 28,
            "score": 83,
            "link": "https://www.topuniversities.com/universities/university-toronto",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-toronto_619_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 8,
            "international_students": 27536,
            "size": "XL",
            "faculty_count": 9881
        },
        {
            "university": "The University of Manchester",
            "year": 2019,
            "rank_display": 29,
            "score": 82.9,
            "link": "https://www.topuniversities.com/universities/university-manchester",
            "country": "United Kingdom",
            "city": "Manchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-manchester_389_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 16079,
            "size": "XL",
            "faculty_count": 4889
        },
        {
            "university": "Peking University",
            "year": 2019,
            "rank_display": 30,
            "score": 82.6,
            "link": "https://www.topuniversities.com/universities/peking-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/peking-university_50_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5436,
            "size": "XL",
            "faculty_count": 5302
        },
        {
            "university": "King's College London",
            "year": 2019,
            "rank_display": 31,
            "score": 82.5,
            "link": "https://www.topuniversities.com/universities/kings-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kings-college-london_357_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 15075,
            "size": "L",
            "faculty_count": 4216
        },
        {
            "university": "University of California, Los Angeles (UCLA)",
            "year": 2019,
            "rank_display": 32,
            "score": 81.9,
            "link": "https://www.topuniversities.com/universities/university-california-los-angeles-ucla",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-los-angeles-ucla_87_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6140,
            "size": "XL",
            "faculty_count": 4487
        },
        {
            "university": "McGill University",
            "year": 2019,
            "rank_display": 33,
            "score": 81.7,
            "link": "https://www.topuniversities.com/universities/mcgill-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcgill-university_592560cf2aeae70239af4c10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 10827,
            "size": "L",
            "faculty_count": 3876
        },
        {
            "university": "Northwestern University",
            "year": 2019,
            "rank_display": 34,
            "score": 81.5,
            "link": "https://www.topuniversities.com/universities/northwestern-university",
            "country": "United States",
            "city": "Evanston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northwestern-university_592560cf2aeae70239af4c50_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3846,
            "size": "L",
            "faculty_count": 3652
        },
        {
            "university": "Kyoto University",
            "year": 2019,
            "rank_display": 35,
            "score": 81.2,
            "link": "https://www.topuniversities.com/universities/kyoto-university",
            "country": "Japan",
            "city": "Kyoto",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyoto-university_328_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2863,
            "size": "L",
            "faculty_count": 3911
        },
        {
            "university": "Seoul National University",
            "year": 2019,
            "rank_display": 36,
            "score": 80.6,
            "link": "https://www.topuniversities.com/universities/seoul-national-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/graduate-school-of-business-seoul-national-university_553_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1914,
            "size": "L",
            "faculty_count": 3859
        },
        {
            "university": "The Hong Kong University of Science and Technology",
            "year": 2019,
            "rank_display": 37,
            "score": 80.5,
            "link": "https://www.topuniversities.com/universities/hong-kong-university-science-technology",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-university-of-science-and-technology_269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3033,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "The London School of Economics and Political Science (LSE)",
            "year": 2019,
            "rank_display": 38,
            "score": 80.2,
            "link": "https://www.topuniversities.com/universities/london-school-economics-political-science-lse",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/london-school-of-economics-and-political-science-lse_362_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8274,
            "size": "M",
            "faculty_count": 1172
        },
        {
            "university": "The University of Melbourne",
            "year": 2019,
            "rank_display": 39,
            "score": 80.1,
            "link": "https://www.topuniversities.com/universities/university-melbourne",
            "country": "Australia",
            "city": "Parkville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-melbourne_399_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 19,
            "international_students": 21406,
            "size": "S",
            "faculty_count": 4835
        },
        {
            "university": "KAIST - Korea Advanced Institute of Science & Technology",
            "year": 2019,
            "rank_display": 40,
            "score": 78.7,
            "link": "https://www.topuniversities.com/universities/kaist-korea-advanced-institute-science-technology",
            "country": "South Korea",
            "city": "Daejeon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kaist-korea-advanced-institute-of-science-technology_324_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 869,
            "size": "M",
            "faculty_count": 1307
        },
        {
            "university": "University of California, San Diego (UCSD)",
            "year": 2019,
            "rank_display": 41,
            "score": 78.6,
            "link": "https://www.topuniversities.com/universities/university-california-san-diego-ucsd",
            "country": "United States",
            "city": "San Diego",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-san-diego-ucsd_89_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 8567,
            "size": "XL",
            "faculty_count": 4545
        },
        {
            "university": "The University of Sydney",
            "year": 2019,
            "rank_display": 42,
            "score": 78.3,
            "link": "https://www.topuniversities.com/universities/university-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sydney_592560cf2aeae70239af4cd0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 25940,
            "size": "XL",
            "faculty_count": 3571
        },
        {
            "university": "New York University (NYU)",
            "year": 2019,
            "rank_display": 43,
            "score": 77.7,
            "link": "https://www.topuniversities.com/universities/new-york-university-nyu",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/new-york-university-nyu_448_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 16218,
            "size": "XL",
            "faculty_count": 7874
        },
        {
            "university": "Fudan University",
            "year": 2019,
            "rank_display": 44,
            "score": 77.6,
            "link": "https://www.topuniversities.com/universities/fudan-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/fudan-university_218_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5322,
            "size": "L",
            "faculty_count": 4526
        },
        {
            "university": "The University of New South Wales (UNSW Sydney)",
            "year": 2019,
            "rank_display": 45,
            "score": 77,
            "link": "https://www.topuniversities.com/universities/university-new-south-wales-unsw-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-new-south-wales-unsw-sydney_447_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 21603,
            "size": "XL",
            "faculty_count": 3219
        },
        {
            "university": "Carnegie Mellon University",
            "year": 2019,
            "rank_display": 46,
            "score": 76.6,
            "link": "https://www.topuniversities.com/universities/carnegie-mellon-university",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/carnegie-mellon-university_101_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5739,
            "size": "L",
            "faculty_count": 1534
        },
        {
            "university": "University of British Columbia",
            "year": 2019,
            "rank_display": 47,
            "score": 75.8,
            "link": "https://www.topuniversities.com/universities/university-british-columbia",
            "country": "Canada",
            "city": "Vancouver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-british-columbia_70_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 15399,
            "size": "XL",
            "faculty_count": 6201
        },
        {
            "university": "The University of Queensland",
            "year": 2019,
            "rank_display": 48,
            "score": 75.7,
            "link": "https://www.topuniversities.com/universities/university-queensland",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-queensland_515_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 22241,
            "size": "XL",
            "faculty_count": 3513
        },
        {
            "university": "The Chinese University of Hong Kong (CUHK)",
            "year": 2019,
            "rank_display": 49,
            "score": 75.6,
            "link": "https://www.topuniversities.com/universities/chinese-university-hong-kong-cuhk",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-chinese-university-of-hong-kong-cuhk_123_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6062,
            "size": "L",
            "faculty_count": 2201
        },
        {
            "university": "Université PSL",
            "year": 2019,
            "rank_display": 50,
            "score": 75.1,
            "link": "https://www.topuniversities.com/universities/universite-psl",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-psl_592560e69988f300e2321dfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4922,
            "size": "L",
            "faculty_count": 3914
        },
        {
            "university": "University of Bristol",
            "year": 2019,
            "rank_display": 51,
            "score": 74.9,
            "link": "https://www.topuniversities.com/universities/university-bristol",
            "country": "United Kingdom",
            "city": "Bristol",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bristol_69_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 7483,
            "size": "L",
            "faculty_count": 20311
        },
        {
            "university": "Delft University of Technology",
            "year": 2019,
            "rank_display": 52,
            "score": 73.3,
            "link": "https://www.topuniversities.com/universities/delft-university-technology",
            "country": "Netherlands",
            "city": "Delft",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/delft-university-of-technology_155_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6104,
            "size": "L",
            "faculty_count": 2041
        },
        {
            "university": "University of Wisconsin-Madison",
            "year": 2019,
            "rank_display": 53,
            "score": 73.2,
            "link": "https://www.topuniversities.com/universities/university-wisconsin-madison",
            "country": "United States",
            "city": "Madison",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wisconsin-madison_678_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5737,
            "size": "XL",
            "faculty_count": 5166
        },
        {
            "university": "The University of Warwick",
            "year": 2019,
            "rank_display": 54,
            "score": 72.9,
            "link": "https://www.topuniversities.com/universities/university-warwick",
            "country": "United Kingdom",
            "city": "Coventry",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-warwick_664_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10459,
            "size": "L",
            "faculty_count": 2634
        },
        {
            "university": "City University of Hong Kong",
            "year": 2019,
            "rank_display": 55,
            "score": 72.1,
            "link": "https://www.topuniversities.com/universities/city-university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-hong-kong_592560cf2aeae70239af4b05_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5331,
            "size": "M",
            "faculty_count": 1483
        },
        {
            "university": "Brown University",
            "year": 2019,
            "rank_display": 56,
            "score": 72,
            "link": "https://www.topuniversities.com/universities/brown-university",
            "country": "United States",
            "city": "Providence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brown-university_72_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2002,
            "size": "M",
            "faculty_count": 1389
        },
        {
            "university": "University of Amsterdam",
            "year": 2019,
            "rank_display": 57,
            "score": 71.1,
            "link": "https://www.topuniversities.com/universities/university-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-amsterdam_18_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7969,
            "size": "XL",
            "faculty_count": 6000
        },
        {
            "university": "Tokyo Institute of Technology (Tokyo Tech)",
            "year": 2019,
            "rank_display": 58,
            "score": 71,
            "link": "https://www.topuniversities.com/universities/tokyo-institute-technology-tokyo-tech",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-institute-of-technology_592560cf2aeae70239af4ceb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1622,
            "size": "M",
            "faculty_count": 1492
        },
        {
            "university": "Monash University",
            "year": 2019,
            "rank_display": 59,
            "score": 70.4,
            "link": "https://www.topuniversities.com/universities/monash-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/monash-university_412_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 31049,
            "size": "XL",
            "faculty_count": 3852
        },
        {
            "university": "Shanghai Jiao Tong University",
            "year": 2019,
            "rank_display": 59,
            "score": 70.4,
            "link": "https://www.topuniversities.com/universities/shanghai-jiao-tong-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-jiao-tong-university_556_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6371,
            "size": "XL",
            "faculty_count": 4386
        },
        {
            "university": "Technical University of Munich",
            "year": 2019,
            "rank_display": 61,
            "score": 70,
            "link": "https://www.topuniversities.com/universities/technical-university-munich",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-munich_599_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11447,
            "size": "XL",
            "faculty_count": 6663
        },
        {
            "university": "Ludwig-Maximilians-Universität München",
            "year": 2019,
            "rank_display": 62,
            "score": 69.4,
            "link": "https://www.topuniversities.com/universities/ludwig-maximilians-universitat-munchen",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ludwig-maximilians-universitt-mnchen_420_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5958,
            "size": "XL",
            "faculty_count": 3914
        },
        {
            "university": "University of Texas at Austin",
            "year": 2019,
            "rank_display": 63,
            "score": 68.7,
            "link": "https://www.topuniversities.com/universities/university-texas-austin",
            "country": "United States",
            "city": "Austin",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-at-austin_604_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 4953,
            "size": "XL",
            "faculty_count": 2887
        },
        {
            "university": "Ruprecht-Karls-Universität Heidelberg",
            "year": 2019,
            "rank_display": 64,
            "score": 68,
            "link": "https://www.topuniversities.com/universities/ruprecht-karls-universitat-heidelberg",
            "country": "Germany",
            "city": "Heidelberg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruprecht-karls-universitaet-heidelberg_259_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4048,
            "size": "L",
            "faculty_count": 4100
        },
        {
            "university": "Ecole Polytechnique",
            "year": 2019,
            "rank_display": 65,
            "score": 69.9,
            "link": "https://www.topuniversities.com/universities/ecole-polytechnique",
            "country": "France",
            "city": "Palaiseau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-polytechnique_592560cf2aeae70239af4b36_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1248,
            "size": "S",
            "faculty_count": 633
        },
        {
            "university": "University of Washington",
            "year": 2019,
            "rank_display": 66,
            "score": 67.8,
            "link": "https://www.topuniversities.com/universities/university-washington",
            "country": "United States",
            "city": "Seattle",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-washington_592560cf2aeae70239af4d1e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 7362,
            "size": "XL",
            "faculty_count": 2796
        },
        {
            "university": "Osaka University",
            "year": 2019,
            "rank_display": 67,
            "score": 67.7,
            "link": "https://www.topuniversities.com/universities/osaka-university",
            "country": "Japan",
            "city": "Osaka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/osaka-university_472_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2268,
            "size": "L",
            "faculty_count": 3127
        },
        {
            "university": "Zhejiang University",
            "year": 2019,
            "rank_display": 68,
            "score": 67.5,
            "link": "https://www.topuniversities.com/universities/zhejiang-university",
            "country": "China (Mainland)",
            "city": "Hangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/zhejiang-university_592560cf2aeae70239af4d37_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 9177,
            "size": "XL",
            "faculty_count": 6065
        },
        {
            "university": "Georgia Institute of Technology",
            "year": 2019,
            "rank_display": 69,
            "score": 67.4,
            "link": "https://www.topuniversities.com/universities/georgia-institute-technology",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgia-institute-of-technology_225_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 1267
        },
        {
            "university": "University of Glasgow",
            "year": 2019,
            "rank_display": 69,
            "score": 67.4,
            "link": "https://www.topuniversities.com/universities/university-glasgow",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-glasgow_592560cf2aeae70239af4b6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 11065,
            "size": "L",
            "faculty_count": 3314
        },
        {
            "university": "University of Illinois at Urbana-Champaign",
            "year": 2019,
            "rank_display": 71,
            "score": 67,
            "link": "https://www.topuniversities.com/universities/university-illinois-urbana-champaign",
            "country": "United States",
            "city": "Champaign",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-at-urbana-champaign_2090_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 9987,
            "size": "XL",
            "faculty_count": 2748
        },
        {
            "university": "National Taiwan University (NTU)",
            "year": 2019,
            "rank_display": 72,
            "score": 66.3,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-ntu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-ntu_592560cf2aeae70239af4c3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5058,
            "size": "L",
            "faculty_count": 2939
        },
        {
            "university": "Universidad de Buenos Aires (UBA)",
            "year": 2019,
            "rank_display": 73,
            "score": 66.2,
            "link": "https://www.topuniversities.com/universities/universidad-de-buenos-aires-uba",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-buenos-aires-uba_78_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 29516,
            "size": "XL",
            "faculty_count": 16103
        },
        {
            "university": "Durham University",
            "year": 2019,
            "rank_display": 74,
            "score": 65.7,
            "link": "https://www.topuniversities.com/universities/durham-university",
            "country": "United Kingdom",
            "city": "Durham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/durham-university_170_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6174,
            "size": "L",
            "faculty_count": 1775
        },
        {
            "university": "Sorbonne University",
            "year": 2019,
            "rank_display": 75,
            "score": 64.8,
            "link": "https://www.topuniversities.com/universities/sorbonne-university",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sorbonne-university_5a6afcfecb4de709397114fe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "The University of Sheffield",
            "year": 2019,
            "rank_display": 75,
            "score": 64.8,
            "link": "https://www.topuniversities.com/universities/university-sheffield",
            "country": "United Kingdom",
            "city": "Sheffield",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sheffield_592560cf2aeae70239af4cb1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 11090,
            "size": "L",
            "faculty_count": 3463
        },
        {
            "university": "Tohoku University",
            "year": 2019,
            "rank_display": 77,
            "score": 64.3,
            "link": "https://www.topuniversities.com/universities/tohoku-university",
            "country": "Japan",
            "city": "Sendai City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tohoku-university_611_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1872,
            "size": "L",
            "faculty_count": 3323
        },
        {
            "university": "University of Zurich",
            "year": 2019,
            "rank_display": 78,
            "score": 63.6,
            "link": "https://www.topuniversities.com/universities/university-zurich",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-zurich_693_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5044,
            "size": "L",
            "faculty_count": 3761
        },
        {
            "university": "University of Birmingham",
            "year": 2019,
            "rank_display": 79,
            "score": 63.5,
            "link": "https://www.topuniversities.com/universities/university-birmingham",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-birmingham_59_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10457,
            "size": "XL",
            "faculty_count": 3586
        },
        {
            "university": "University of Copenhagen",
            "year": 2019,
            "rank_display": 79,
            "score": 63.5,
            "link": "https://www.topuniversities.com/universities/university-copenhagen",
            "country": "Denmark",
            "city": "Copenhagen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-copenhagen_141_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4135,
            "size": "XL",
            "faculty_count": 7293
        },
        {
            "university": "KU Leuven",
            "year": 2019,
            "rank_display": 81,
            "score": 63.4,
            "link": "https://www.topuniversities.com/universities/ku-leuven",
            "country": "Belgium",
            "city": "Leuven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ku-leuven_592560cf2aeae70239af4aec_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 8485,
            "size": "XL",
            "faculty_count": 2677
        },
        {
            "university": "University of Nottingham",
            "year": 2019,
            "rank_display": 82,
            "score": 63.1,
            "link": "https://www.topuniversities.com/universities/university-nottingham",
            "country": "United Kingdom",
            "city": "Nottingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-nottingham_592560cf2aeae70239af4c4e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9659,
            "size": "XL",
            "faculty_count": 3386
        },
        {
            "university": "Pohang University of Science And Technology (POSTECH)",
            "year": 2019,
            "rank_display": 83,
            "score": 63,
            "link": "https://www.topuniversities.com/universities/pohang-university-science-technology-postech",
            "country": "South Korea",
            "city": "Pohang",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pohang-university-of-science-and-technology-postech_592560cf2aeae70239af4c7b_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 87,
            "size": "S",
            "faculty_count": 743
        },
        {
            "university": "University of North Carolina, Chapel Hill",
            "year": 2019,
            "rank_display": 83,
            "score": 63,
            "link": "https://www.topuniversities.com/universities/university-north-carolina-chapel-hill",
            "country": "United States",
            "city": "Chapel Hill",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-north-carolina-chapel-hill_424_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2749,
            "size": "XL",
            "faculty_count": 4646
        },
        {
            "university": "The University of Auckland",
            "year": 2019,
            "rank_display": 85,
            "score": 62.8,
            "link": "https://www.topuniversities.com/universities/university-auckland",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-auckland_592560cf2aeae70239af4aa8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9924,
            "size": "L",
            "faculty_count": 2316
        },
        {
            "university": "Korea University",
            "year": 2019,
            "rank_display": 86,
            "score": 62.7,
            "link": "https://www.topuniversities.com/universities/korea-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/korea-university_325_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3325,
            "size": "L",
            "faculty_count": 4026
        },
        {
            "university": "Rice University",
            "year": 2019,
            "rank_display": 87,
            "score": 62.6,
            "link": "https://www.topuniversities.com/universities/rice-university",
            "country": "United States",
            "city": "Houston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rice-university_524_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2278,
            "size": "M",
            "faculty_count": 996
        },
        {
            "university": "Universiti Malaya (UM)",
            "year": 2019,
            "rank_display": 87,
            "score": 62.6,
            "link": "https://www.topuniversities.com/universities/universiti-malaya-um",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-malaya-um_383_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3340,
            "size": "L",
            "faculty_count": 2386
        },
        {
            "university": "The Ohio State University",
            "year": 2019,
            "rank_display": 89,
            "score": 62.4,
            "link": "https://www.topuniversities.com/universities/ohio-state-university",
            "country": "United States",
            "city": "Columbus",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-ohio-state-university_465_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7029,
            "size": "XL",
            "faculty_count": 6388
        },
        {
            "university": "Lomonosov Moscow State University",
            "year": 2019,
            "rank_display": 90,
            "score": 62.3,
            "link": "https://www.topuniversities.com/universities/lomonosov-moscow-state-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lomonosov-moscow-state-university-_592560cf2aeae70239af4c27_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5476,
            "size": "XL",
            "faculty_count": 6575
        },
        {
            "university": "The University of Western Australia",
            "year": 2019,
            "rank_display": 91,
            "score": 62.2,
            "link": "https://www.topuniversities.com/universities/university-western-australia",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-australia_673_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9017,
            "size": "L",
            "faculty_count": 1539
        },
        {
            "university": "Lund University",
            "year": 2019,
            "rank_display": 92,
            "score": 62.1,
            "link": "https://www.topuniversities.com/universities/lund-university",
            "country": "Sweden",
            "city": "Lund",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lund-university_371_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6682,
            "size": "L",
            "faculty_count": 3040
        },
        {
            "university": "Boston University",
            "year": 2019,
            "rank_display": 93,
            "score": 62,
            "link": "https://www.topuniversities.com/universities/boston-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-university_592560cf2aeae70239af4ac2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8159,
            "size": "L",
            "faculty_count": 2642
        },
        {
            "university": "University of Leeds",
            "year": 2019,
            "rank_display": 93,
            "score": 62,
            "link": "https://www.topuniversities.com/universities/university-leeds",
            "country": "United Kingdom",
            "city": "Leeds",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leeds_338_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 11584,
            "size": "XL",
            "faculty_count": 3593
        },
        {
            "university": "Pennsylvania State University",
            "year": 2019,
            "rank_display": 95,
            "score": 61.9,
            "link": "https://www.topuniversities.com/universities/pennsylvania-state-university",
            "country": "United States",
            "city": "University Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/pennsylvania-state-university_494_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6943,
            "size": "XL",
            "faculty_count": 4207
        },
        {
            "university": "University of Southampton",
            "year": 2019,
            "rank_display": 96,
            "score": 61.6,
            "link": "https://www.topuniversities.com/universities/university-southampton",
            "country": "United Kingdom",
            "city": "Southampton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southampton_567_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8417,
            "size": "L",
            "faculty_count": 2468
        },
        {
            "university": "University of St Andrews",
            "year": 2019,
            "rank_display": 97,
            "score": 61.4,
            "link": "https://www.topuniversities.com/universities/university-st-andrews",
            "country": "United Kingdom",
            "city": "St. Andrews",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st-andrews_570_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4377,
            "size": "M",
            "faculty_count": 1208
        },
        {
            "university": "University of Science and Technology of China",
            "year": 2019,
            "rank_display": 98,
            "score": 60.8,
            "link": "https://www.topuniversities.com/universities/university-science-technology-china",
            "country": "China (Mainland)",
            "city": "Hefei",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-of-china_122_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1019,
            "size": "L",
            "faculty_count": 2708
        },
        {
            "university": "Eindhoven University of Technology",
            "year": 2019,
            "rank_display": 99,
            "score": 59.8,
            "link": "https://www.topuniversities.com/universities/eindhoven-university-technology",
            "country": "Netherlands",
            "city": "Eindhoven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eindhoven-university-of-technology_592560cf2aeae70239af4b3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1872,
            "size": "M",
            "faculty_count": 1145
        },
        {
            "university": "Purdue University",
            "year": 2019,
            "rank_display": 100,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/purdue-university",
            "country": "United States",
            "city": "West Lafayette",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/purdue-university_510_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 8034,
            "size": "XL",
            "faculty_count": 2622
        },
        {
            "university": "Sungkyunkwan University(SKKU)",
            "year": 2019,
            "rank_display": 100,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/sungkyunkwan-universityskku",
            "country": "South Korea",
            "city": "Suwon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sungkyunkwan-universityskku_592560cf2aeae70239af4cc9_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3821,
            "size": "L",
            "faculty_count": 3313
        },
        {
            "university": "University of California, Davis",
            "year": 2019,
            "rank_display": 100,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/university-california-davis",
            "country": "United States",
            "city": "Davis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-davis_592560cf2aeae70239af4ad8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8414,
            "size": "XL",
            "faculty_count": 3420
        },
        {
            "university": "Washington University in St. Louis",
            "year": 2019,
            "rank_display": 100,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/washington-university-st-louis",
            "country": "United States",
            "city": "St. Louis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-university-in-st.-louis_668_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3535,
            "size": "L",
            "faculty_count": 3193
        },
        {
            "university": "KTH Royal Institute of Technology",
            "year": 2019,
            "rank_display": 104,
            "score": 59.3,
            "link": "https://www.topuniversities.com/universities/kth-royal-institute-technology",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kth-royal-institute-of-technology-_592560cf2aeae70239af4c9b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3057,
            "size": "L",
            "faculty_count": 3600
        },
        {
            "university": "Trinity College Dublin, The University of Dublin",
            "year": 2019,
            "rank_display": 104,
            "score": 59.3,
            "link": "https://www.topuniversities.com/universities/trinity-college-dublin-university-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/trinity-college-dublin-the-university-of-dublin_167_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5593,
            "size": "L",
            "faculty_count": 1383
        },
        {
            "university": "The Hong Kong Polytechnic University",
            "year": 2019,
            "rank_display": 106,
            "score": 59.1,
            "link": "https://www.topuniversities.com/universities/hong-kong-polytechnic-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-polytechnic-university_267_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5774,
            "size": "L",
            "faculty_count": 2822
        },
        {
            "university": "Yonsei University",
            "year": 2019,
            "rank_display": 107,
            "score": 58.9,
            "link": "https://www.topuniversities.com/universities/yonsei-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yonsei-university_688_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4274,
            "size": "L",
            "faculty_count": 3605
        },
        {
            "university": "University of Geneva",
            "year": 2019,
            "rank_display": 108,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/university-geneva",
            "country": "Switzerland",
            "city": "Geneva",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-geneva_221_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6547,
            "size": "L",
            "faculty_count": 1814
        },
        {
            "university": "University of Alberta",
            "year": 2019,
            "rank_display": 109,
            "score": 58.1,
            "link": "https://www.topuniversities.com/universities/university-alberta",
            "country": "Canada",
            "city": "Edmonton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-alberta_15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9689,
            "size": "XL",
            "faculty_count": 3645
        },
        {
            "university": "University of Helsinki",
            "year": 2019,
            "rank_display": 110,
            "score": 57.9,
            "link": "https://www.topuniversities.com/universities/university-helsinki",
            "country": "Finland",
            "city": "Helsinki",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-helsinki_260_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1254,
            "size": "L",
            "faculty_count": 2855
        },
        {
            "university": "Nagoya University",
            "year": 2019,
            "rank_display": 111,
            "score": 57.3,
            "link": "https://www.topuniversities.com/universities/nagoya-university",
            "country": "Japan",
            "city": "Nagoya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nagoya-university_427_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1879,
            "size": "L",
            "faculty_count": 2512
        },
        {
            "university": "Technical University of Denmark",
            "year": 2019,
            "rank_display": 112,
            "score": 57.1,
            "link": "https://www.topuniversities.com/universities/technical-university-denmark",
            "country": "Denmark",
            "city": "Kongens Lyngby",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-denmark_592560cf2aeae70239af4cd8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2495,
            "size": "M",
            "faculty_count": 2219
        },
        {
            "university": "Universidad Nacional Autónoma de México  (UNAM)",
            "year": 2019,
            "rank_display": 113,
            "score": 56.8,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-autonoma-de-mexico-unam",
            "country": "Mexico",
            "city": "Mexico City",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-autnoma-de-mxico-unam_425_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5110,
            "size": "XL",
            "faculty_count": 16381
        },
        {
            "university": "The University of Adelaide",
            "year": 2019,
            "rank_display": 114,
            "score": 56.6,
            "link": "https://www.topuniversities.com/universities/university-adelaide",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-adelaide_10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10107,
            "size": "L",
            "faculty_count": 1757
        },
        {
            "university": "University of Southern California",
            "year": 2019,
            "rank_display": 115,
            "score": 56.2,
            "link": "https://www.topuniversities.com/universities/university-southern-california",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-california_569_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9107,
            "size": "XL",
            "faculty_count": 3116
        },
        {
            "university": "KIT, Karlsruhe Institute of Technology",
            "year": 2019,
            "rank_display": 116,
            "score": 55.9,
            "link": "https://www.topuniversities.com/universities/kit-karlsruhe-institute-technology",
            "country": "Germany",
            "city": "Karlsruhe",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kit-karlsruhe-institute-of-technology_310_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5030,
            "size": "L",
            "faculty_count": 2080
        },
        {
            "university": "Uppsala University",
            "year": 2019,
            "rank_display": 117,
            "score": 55.8,
            "link": "https://www.topuniversities.com/universities/uppsala-university",
            "country": "Sweden",
            "city": "Uppsala",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/uppsala-university_643_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8401,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Universidade de São Paulo",
            "year": 2019,
            "rank_display": 118,
            "score": 55.5,
            "link": "https://www.topuniversities.com/universities/universidade-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-de-so-paulo_550_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1858,
            "size": "XL",
            "faculty_count": 4943
        },
        {
            "university": "Queen Mary University of London",
            "year": 2019,
            "rank_display": 119,
            "score": 55.4,
            "link": "https://www.topuniversities.com/universities/queen-mary-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queen-mary-university-of-london_592560cf2aeae70239af4bea_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8625,
            "size": "L",
            "faculty_count": 2119
        },
        {
            "university": "University of Groningen",
            "year": 2019,
            "rank_display": 120,
            "score": 55.2,
            "link": "https://www.topuniversities.com/universities/university-groningen",
            "country": "Netherlands",
            "city": "Groningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-groningen_243_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 9397,
            "size": "XL",
            "faculty_count": 3724
        },
        {
            "university": "Humboldt-Universität zu Berlin",
            "year": 2019,
            "rank_display": 121,
            "score": 55.1,
            "link": "https://www.topuniversities.com/universities/humboldt-universitat-zu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/humboldt-universitt-zu-berlin_272_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5239,
            "size": "XL",
            "faculty_count": 1967
        },
        {
            "university": "Leiden University",
            "year": 2019,
            "rank_display": 122,
            "score": 55,
            "link": "https://www.topuniversities.com/universities/leiden-university",
            "country": "Netherlands",
            "city": "Leiden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leiden-university_340_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6064,
            "size": "XL",
            "faculty_count": 2069
        },
        {
            "university": "Nanjing University",
            "year": 2019,
            "rank_display": 122,
            "score": 55,
            "link": "https://www.topuniversities.com/universities/nanjing-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanjing-university_430_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2513,
            "size": "XL",
            "faculty_count": 3040
        },
        {
            "university": "Utrecht University",
            "year": 2019,
            "rank_display": 124,
            "score": 54.5,
            "link": "https://www.topuniversities.com/universities/utrecht-university",
            "country": "Netherlands",
            "city": "Utrecht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/utrecht-university_646_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4454,
            "size": "XL",
            "faculty_count": 3254
        },
        {
            "university": "Wageningen University & Research",
            "year": 2019,
            "rank_display": 125,
            "score": 54.4,
            "link": "https://www.topuniversities.com/universities/wageningen-university-research",
            "country": "Netherlands",
            "city": "Wageningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/wageningen-university_659_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4131,
            "size": "L",
            "faculty_count": 2767
        },
        {
            "university": "Kyushu University",
            "year": 2019,
            "rank_display": 126,
            "score": 54.1,
            "link": "https://www.topuniversities.com/universities/kyushu-university",
            "country": "Japan",
            "city": "Fukuoka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyushu-university_331_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2125,
            "size": "L",
            "faculty_count": 2761
        },
        {
            "university": "University of Maryland, College Park",
            "year": 2019,
            "rank_display": 126,
            "score": 54.1,
            "link": "https://www.topuniversities.com/universities/university-maryland-college-park",
            "country": "United States",
            "city": "College Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-college-park_393_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4659,
            "size": "XL",
            "faculty_count": 3798
        },
        {
            "university": "Chalmers University of Technology",
            "year": 2019,
            "rank_display": 128,
            "score": 53.6,
            "link": "https://www.topuniversities.com/universities/chalmers-university-technology",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/chalmers-university-of-technology_592560cf2aeae70239af4af7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2502,
            "size": "M",
            "faculty_count": 2500
        },
        {
            "university": "Hokkaido University",
            "year": 2019,
            "rank_display": 128,
            "score": 53.6,
            "link": "https://www.topuniversities.com/universities/hokkaido-university",
            "country": "Japan",
            "city": "Sapporo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hokkaido-university_266_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1984,
            "size": "L",
            "faculty_count": 2476
        },
        {
            "university": "Freie Universitaet Berlin",
            "year": 2019,
            "rank_display": 130,
            "score": 53.4,
            "link": "https://www.topuniversities.com/universities/freie-universitaet-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/freie-universitaet-berlin_215_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 6659,
            "size": "XL",
            "faculty_count": 1389
        },
        {
            "university": "Lancaster University",
            "year": 2019,
            "rank_display": 131,
            "score": 53,
            "link": "https://www.topuniversities.com/universities/lancaster-university",
            "country": "United Kingdom",
            "city": "Lancaster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lancaster-university_335_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5557,
            "size": "L",
            "faculty_count": 1440
        },
        {
            "university": "Pontificia Universidad Católica de Chile (UC)",
            "year": 2019,
            "rank_display": 132,
            "score": 52.9,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-cat%C3%B3lica-de-chile-uc",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-de-chile-uc_107_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1072,
            "size": "L",
            "faculty_count": 2343
        },
        {
            "university": "University of California, Santa Barbara (UCSB)",
            "year": 2019,
            "rank_display": 132,
            "score": 52.9,
            "link": "https://www.topuniversities.com/universities/university-california-santa-barbara-ucsb",
            "country": "United States",
            "city": "Santa Barbara",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-barbara-ucsb_91_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 4309,
            "size": "L",
            "faculty_count": 1597
        },
        {
            "university": "University of York",
            "year": 2019,
            "rank_display": 134,
            "score": 52.4,
            "link": "https://www.topuniversities.com/universities/university-york",
            "country": "United Kingdom",
            "city": "York",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-york_689_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5098,
            "size": "L",
            "faculty_count": 2209
        },
        {
            "university": "University of Oslo",
            "year": 2019,
            "rank_display": 135,
            "score": 51.9,
            "link": "https://www.topuniversities.com/universities/university-oslo",
            "country": "Norway",
            "city": "Oslo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oslo_473_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2560,
            "size": "L",
            "faculty_count": 2600
        },
        {
            "university": "University of Pittsburgh",
            "year": 2019,
            "rank_display": 136,
            "score": 51.8,
            "link": "https://www.topuniversities.com/universities/university-pittsburgh",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pittsburgh_500_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2590,
            "size": "L",
            "faculty_count": 4746
        },
        {
            "university": "CentraleSupélec",
            "year": 2019,
            "rank_display": 137,
            "score": 52.3,
            "link": "https://www.topuniversities.com/universities/centralesupelec",
            "country": "France",
            "city": "Saint-Aubin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/centralesuplec_173_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": "",
            "international_students": 1068,
            "size": "S",
            "faculty_count": 552
        },
        {
            "university": "Ghent University",
            "year": 2019,
            "rank_display": 138,
            "score": 51.3,
            "link": "https://www.topuniversities.com/universities/ghent-university",
            "country": "Belgium",
            "city": "Ghent",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ghent-university_228_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4609,
            "size": "XL",
            "faculty_count": 5537
        },
        {
            "university": "University of Bern",
            "year": 2019,
            "rank_display": 139,
            "score": 51.1,
            "link": "https://www.topuniversities.com/universities/university-bern",
            "country": "Switzerland",
            "city": "Bern",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bern_55_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1776,
            "size": "M",
            "faculty_count": 1212
        },
        {
            "university": "Aalto University",
            "year": 2019,
            "rank_display": 140,
            "score": 50.8,
            "link": "https://www.topuniversities.com/universities/aalto-university",
            "country": "Finland",
            "city": "Espoo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalto-university_261_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2202,
            "size": "L",
            "faculty_count": 1396
        },
        {
            "university": "Aarhus University",
            "year": 2019,
            "rank_display": 141,
            "score": 50.7,
            "link": "https://www.topuniversities.com/universities/aarhus-university",
            "country": "Denmark",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aarhus-university_592560cf2aeae70239af4a8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2911,
            "size": "L",
            "faculty_count": 683
        },
        {
            "university": "Michigan State University",
            "year": 2019,
            "rank_display": 141,
            "score": 50.7,
            "link": "https://www.topuniversities.com/universities/michigan-state-university",
            "country": "United States",
            "city": "East Lansing",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/michigan-state-university_402_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5795,
            "size": "XL",
            "faculty_count": 3008
        },
        {
            "university": "Newcastle University",
            "year": 2019,
            "rank_display": 141,
            "score": 50.7,
            "link": "https://www.topuniversities.com/universities/newcastle-university",
            "country": "United Kingdom",
            "city": "Newcastle upon Tyne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/newcastle-university_592560cf2aeae70239af4c45_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8100,
            "size": "L",
            "faculty_count": 2766
        },
        {
            "university": "RWTH Aachen University",
            "year": 2019,
            "rank_display": 144,
            "score": 50.4,
            "link": "https://www.topuniversities.com/universities/rwth-aachen-university",
            "country": "Germany",
            "city": "Aachen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rwth-aachen-university_4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 12477,
            "size": "XL",
            "faculty_count": 2431
        },
        {
            "university": "Cardiff University",
            "year": 2019,
            "rank_display": 145,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/cardiff-university",
            "country": "United Kingdom",
            "city": "Cardiff",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cardiff-university_592560cf2aeae70239af4ae6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7819,
            "size": "L",
            "faculty_count": 3350
        },
        {
            "university": "McMaster University",
            "year": 2019,
            "rank_display": 146,
            "score": 50.1,
            "link": "https://www.topuniversities.com/universities/mcmaster-university",
            "country": "Canada",
            "city": "Hamilton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcmaster-university_397_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5386,
            "size": "L",
            "faculty_count": 3819
        },
        {
            "university": "Technische Universität Berlin (TU Berlin)",
            "year": 2019,
            "rank_display": 147,
            "score": 49.9,
            "link": "https://www.topuniversities.com/universities/technische-universitat-berlin-tu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-berlin-tu-berlin_595_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6464,
            "size": "L",
            "faculty_count": 2331
        },
        {
            "university": "Emory University",
            "year": 2019,
            "rank_display": 148,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/emory-university",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/emory-university_185_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2264,
            "size": "L",
            "faculty_count": 2725
        },
        {
            "university": "Université de Montréal",
            "year": 2019,
            "rank_display": 149,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/universite-de-montreal",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montral_417_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7625,
            "size": "XL",
            "faculty_count": 5533
        },
        {
            "university": "University of Lausanne",
            "year": 2019,
            "rank_display": 149,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/university-lausanne",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lausanne_592560cf2aeae70239af4bd3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3026,
            "size": "L",
            "faculty_count": 1478
        },
        {
            "university": "Hanyang University",
            "year": 2019,
            "rank_display": 151,
            "score": 49.4,
            "link": "https://www.topuniversities.com/universities/hanyang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hanyang-university_250_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3455,
            "size": "L",
            "faculty_count": 3164
        },
        {
            "university": "École Normale Supérieure de Lyon",
            "year": 2019,
            "rank_display": 153,
            "score": 49.2,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-de-lyon",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cole-normale-suprieure-de-lyon_175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 233,
            "size": "S",
            "faculty_count": 387
        },
        {
            "university": "The Hebrew University of Jerusalem",
            "year": 2019,
            "rank_display": 154,
            "score": 49.1,
            "link": "https://www.topuniversities.com/universities/hebrew-university-jerusalem",
            "country": "Israel",
            "city": "Jerusalem",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hebrew-university-of-jerusalem_256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1176,
            "size": "L",
            "faculty_count": 2295
        },
        {
            "university": "The University of Exeter",
            "year": 2019,
            "rank_display": 154,
            "score": 49.1,
            "link": "https://www.topuniversities.com/universities/university-exeter",
            "country": "United Kingdom",
            "city": "Exeter",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-exeter_204_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7188,
            "size": "L",
            "faculty_count": 2393
        },
        {
            "university": "Politecnico di Milano",
            "year": 2019,
            "rank_display": 156,
            "score": 48.7,
            "link": "https://www.topuniversities.com/universities/politecnico-di-milano",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-milano_592560cf2aeae70239af4c1c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7452,
            "size": "XL",
            "faculty_count": 1659
        },
        {
            "university": "University of Minnesota Twin Cities",
            "year": 2019,
            "rank_display": 156,
            "score": 48.7,
            "link": "https://www.topuniversities.com/universities/university-minnesota-twin-cities",
            "country": "United States",
            "city": "Minneapolis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-minnesota_408_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5630,
            "size": "XL",
            "faculty_count": 5087
        },
        {
            "university": "University of Bath",
            "year": 2019,
            "rank_display": 158,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/university-bath",
            "country": "United Kingdom",
            "city": "Bath",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bath_47_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5326,
            "size": "L",
            "faculty_count": 1339
        },
        {
            "university": "Universidad Autónoma de Madrid",
            "year": 2019,
            "rank_display": 159,
            "score": 48.5,
            "link": "https://www.topuniversities.com/universities/universidad-autonoma-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-autnoma-de-madrid_379_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2470,
            "size": "L",
            "faculty_count": 2869
        },
        {
            "university": "University of Basel",
            "year": 2019,
            "rank_display": 160,
            "score": 48.4,
            "link": "https://www.topuniversities.com/universities/university-basel",
            "country": "Switzerland",
            "city": "Basel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-basel_46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2875,
            "size": "M",
            "faculty_count": 1036
        },
        {
            "university": "University of Technology Sydney",
            "year": 2019,
            "rank_display": 160,
            "score": 48.4,
            "link": "https://www.topuniversities.com/universities/university-technology-sydney",
            "country": "Australia",
            "city": "Haymarket",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-technology-sydney_592560cf2aeae70239af4ccf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8653,
            "size": "L",
            "faculty_count": 2183
        },
        {
            "university": "Indian Institute of Technology Bombay (IITB)",
            "year": 2019,
            "rank_display": 162,
            "score": 48.2,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-bombay-iitb",
            "country": "India",
            "city": "Mumbai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-bombay-iitb_281_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 116,
            "size": "M",
            "faculty_count": 1015
        },
        {
            "university": "National Tsing Hua University",
            "year": 2019,
            "rank_display": 163,
            "score": 48,
            "link": "https://www.topuniversities.com/universities/national-tsing-hua-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-tsing-hua-university_442_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1551,
            "size": "L",
            "faculty_count": 1265
        },
        {
            "university": "University of Waterloo",
            "year": 2019,
            "rank_display": 163,
            "score": 48,
            "link": "https://www.topuniversities.com/universities/university-waterloo",
            "country": "Canada",
            "city": "Waterloo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waterloo_670_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 10269,
            "size": "XL",
            "faculty_count": 1908
        },
        {
            "university": "University of Liverpool",
            "year": 2019,
            "rank_display": 164,
            "score": 47.9,
            "link": "https://www.topuniversities.com/universities/university-liverpool",
            "country": "United Kingdom",
            "city": "Liverpool",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-liverpool_350_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9500,
            "size": "L",
            "faculty_count": 2771
        },
        {
            "university": "Université catholique de Louvain (UCLouvain)",
            "year": 2019,
            "rank_display": 165,
            "score": 47.9,
            "link": "https://www.topuniversities.com/universities/universite-catholique-de-louvain-uclouvain",
            "country": "Belgium",
            "city": "Louvain-la-Neuve",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-catholique-de-louvain-uclouvain_592560cf2aeae70239af4aed_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4524,
            "size": "L",
            "faculty_count": 1315
        },
        {
            "university": "Universitat de Barcelona",
            "year": 2019,
            "rank_display": 166,
            "score": 47.8,
            "link": "https://www.topuniversities.com/universities/universitat-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-barcelona_592560cf2aeae70239af4aaf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 6979,
            "size": "XL",
            "faculty_count": 3002
        },
        {
            "university": "Sant'Anna - Scuola Universitaria Superiore Pisa",
            "year": 2019,
            "rank_display": 167,
            "score": "",
            "link": "https://www.topuniversities.com/universities/santanna-scuola-universitaria-superiore-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/santanna-scuola-universitaria-superiore-pisa_592560cf2aeae70239af4d74_small.jpg",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 131,
            "size": "S",
            "faculty_count": 174
        },
        {
            "university": "Eberhard Karls Universität Tübingen",
            "year": 2019,
            "rank_display": 168,
            "score": 47.6,
            "link": "https://www.topuniversities.com/universities/eberhard-karls-universitat-tubingen",
            "country": "Germany",
            "city": "Tübingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eberhard-karls-universitt-tbingen_629_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2872,
            "size": "L",
            "faculty_count": 4517
        },
        {
            "university": "University of California, Irvine",
            "year": 2019,
            "rank_display": 169,
            "score": 47.5,
            "link": "https://www.topuniversities.com/universities/university-california-irvine",
            "country": "United States",
            "city": "Irvine",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-irvine_86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 9323,
            "size": "XL",
            "faculty_count": 2775
        },
        {
            "university": "Indian Institute of Science",
            "year": 2019,
            "rank_display": 170,
            "score": 47.1,
            "link": "https://www.topuniversities.com/universities/indian-institute-science",
            "country": "India",
            "city": "Bangalore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-science-iisc-bangalore_22878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5,
            "size": "S",
            "faculty_count": 446
        },
        {
            "university": "University of Bergen",
            "year": 2019,
            "rank_display": 171,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/university-bergen",
            "country": "Norway",
            "city": "Bergen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bergen_53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 832,
            "size": "M",
            "faculty_count": 1634
        },
        {
            "university": "Indian Institute of Technology Delhi (IITD)",
            "year": 2019,
            "rank_display": 172,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-delhi-iitd",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-delhi-iitd_282_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 100,
            "size": "M",
            "faculty_count": 843
        },
        {
            "university": "University of Aberdeen",
            "year": 2019,
            "rank_display": 172,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/university-aberdeen",
            "country": "United Kingdom",
            "city": "Aberdeen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-aberdeen_592560cf2aeae70239af4a8b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5544,
            "size": "L",
            "faculty_count": 1175
        },
        {
            "university": "University of Twente",
            "year": 2019,
            "rank_display": 172,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/university-twente",
            "country": "Netherlands",
            "city": "Enschede",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-twente_635_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3872,
            "size": "L",
            "faculty_count": 1177
        },
        {
            "university": "Scuola Normale Superiore",
            "year": 2019,
            "rank_display": 175,
            "score": "",
            "link": "https://www.topuniversities.com/universities/scuola-normale-superiore",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/scuola-normale-superiore-di-pisa_25824_small.jpg",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 69,
            "size": "S",
            "faculty_count": 150
        },
        {
            "university": "University of Vienna",
            "year": 2019,
            "rank_display": 175,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/university-vienna",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-vienna_652_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 14514,
            "size": "XL",
            "faculty_count": 3637
        },
        {
            "university": "University of Otago",
            "year": 2019,
            "rank_display": 175,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/university-otago",
            "country": "New Zealand",
            "city": "Dunedin",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-otago_474_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3585,
            "size": "L",
            "faculty_count": 1333
        },
        {
            "university": "Tecnológico de Monterrey",
            "year": 2019,
            "rank_display": 178,
            "score": 46.2,
            "link": "https://www.topuniversities.com/universities/tecnologico-de-monterrey",
            "country": "Mexico",
            "city": "Monterrey",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/instituto-tecnolgico-y-de-estudios-superiores-de-monterrey_592560cf2aeae70239af4cd6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3514,
            "size": "XL",
            "faculty_count": 5894
        },
        {
            "university": "Erasmus University Rotterdam",
            "year": 2019,
            "rank_display": 179,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/erasmus-university-rotterdam",
            "country": "Netherlands",
            "city": "Rotterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/erasmus-university-rotterdam_190_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5932,
            "size": "L",
            "faculty_count": 2699
        },
        {
            "university": "Queen's University Belfast",
            "year": 2019,
            "rank_display": 180,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/queens-university-belfast",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-belfast_592560cf2aeae70239af4c86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7397,
            "size": "L",
            "faculty_count": 1951
        },
        {
            "university": "Alma Mater Studiorum - University of Bologna",
            "year": 2019,
            "rank_display": 180,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/alma-mater-studiorum-university-bologna",
            "country": "Italy",
            "city": "Bologna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/alma-mater-studiorum-university-of-bologna_592560cf2aeae70239af4ac0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 5536,
            "size": "XL",
            "faculty_count": 2885
        },
        {
            "university": "University of Florida",
            "year": 2019,
            "rank_display": 180,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/university-florida",
            "country": "United States",
            "city": "Gainesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florida_212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4143,
            "size": "XL",
            "faculty_count": 6222
        },
        {
            "university": "Dartmouth College",
            "year": 2019,
            "rank_display": 183,
            "score": 45.8,
            "link": "https://www.topuniversities.com/universities/dartmouth-college",
            "country": "United States",
            "city": "Hanover",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dartmouth-college_592560cf2aeae70239af4b1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 997,
            "size": "M",
            "faculty_count": 836
        },
        {
            "university": "Universiti Kebangsaan Malaysia (UKM)",
            "year": 2019,
            "rank_display": 184,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/universiti-kebangsaan-malaysia-ukm",
            "country": "Malaysia",
            "city": "Bangi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-kebangsaan-malaysia-ukm_384_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2410,
            "size": "L",
            "faculty_count": 2829
        },
        {
            "university": "University of Rochester",
            "year": 2019,
            "rank_display": 184,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/university-rochester",
            "country": "United States",
            "city": "Rochester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rochester_592560cf2aeae70239af4c96_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3644,
            "size": "M",
            "faculty_count": 1853
        },
        {
            "university": "Case Western Reserve University",
            "year": 2019,
            "rank_display": 186,
            "score": 45.4,
            "link": "https://www.topuniversities.com/universities/case-western-reserve-university",
            "country": "United States",
            "city": "Cleveland",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/case-western-reserve-university_102_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2864,
            "size": "M",
            "faculty_count": 1639
        },
        {
            "university": "Albert-Ludwigs-Universitaet Freiburg",
            "year": 2019,
            "rank_display": 186,
            "score": 45.4,
            "link": "https://www.topuniversities.com/universities/albert-ludwigs-universitaet-freiburg",
            "country": "Germany",
            "city": "Freiburg im Breisgau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/albert-ludwigs-universitaet-freiburg_216_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3883,
            "size": "L",
            "faculty_count": 1993
        },
        {
            "university": "Vrije Universiteit Brussel (VUB)",
            "year": 2019,
            "rank_display": 188,
            "score": 45.2,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-brussel-vub",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-brussel-vub_74_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2994,
            "size": "L",
            "faculty_count": 1815
        },
        {
            "university": "King Fahd University of Petroleum & Minerals",
            "year": 2019,
            "rank_display": 189,
            "score": 45.1,
            "link": "https://www.topuniversities.com/universities/king-fahd-university-petroleum-minerals",
            "country": "Saudi Arabia",
            "city": "Dhahran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-fahd-university-of-petroleum-minerals_779_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 782,
            "size": "M",
            "faculty_count": 1103
        },
        {
            "university": "University of Colorado Boulder",
            "year": 2019,
            "rank_display": 190,
            "score": 45,
            "link": "https://www.topuniversities.com/universities/university-colorado-boulder",
            "country": "United States",
            "city": "Boulder",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-boulder_137_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2441,
            "size": "XL",
            "faculty_count": 2946
        },
        {
            "university": "Technische Universität Dresden",
            "year": 2019,
            "rank_display": 191,
            "score": 44.9,
            "link": "https://www.topuniversities.com/universities/technische-universitat-dresden",
            "country": "Germany",
            "city": "Dresden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-dresden_598_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5167,
            "size": "XL",
            "faculty_count": 4636
        },
        {
            "university": "University of Virginia",
            "year": 2019,
            "rank_display": 192,
            "score": 44.8,
            "link": "https://www.topuniversities.com/universities/university-virginia",
            "country": "United States",
            "city": "Charlottesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-virginia_656_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2201,
            "size": "L",
            "faculty_count": 2731
        },
        {
            "university": "Universitat Autònoma de Barcelona",
            "year": 2019,
            "rank_display": 193,
            "score": 44.7,
            "link": "https://www.topuniversities.com/universities/universitat-autonoma-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-autnoma-de-barcelona_592560cf2aeae70239af4ab0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 4069,
            "size": "XL",
            "faculty_count": 2132
        },
        {
            "university": "University College Dublin",
            "year": 2019,
            "rank_display": 193,
            "score": 44.7,
            "link": "https://www.topuniversities.com/universities/university-college-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-dublin_166_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6118,
            "size": "L",
            "faculty_count": 1836
        },
        {
            "university": "University of Reading",
            "year": 2019,
            "rank_display": 195,
            "score": 44.4,
            "link": "https://www.topuniversities.com/universities/university-reading",
            "country": "United Kingdom",
            "city": "Reading",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-reading_517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5007,
            "size": "L",
            "faculty_count": 1325
        },
        {
            "university": "Vanderbilt University",
            "year": 2019,
            "rank_display": 195,
            "score": 44.4,
            "link": "https://www.topuniversities.com/universities/vanderbilt-university",
            "country": "United States",
            "city": "Nashville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/vanderbilt-university_649_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 2022,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "University of Göttingen",
            "year": 2019,
            "rank_display": 197,
            "score": 44.3,
            "link": "https://www.topuniversities.com/universities/university-gottingen",
            "country": "Germany",
            "city": "Göttingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gttingen_234_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3875,
            "size": "XL",
            "faculty_count": 3717
        },
        {
            "university": "Keio University",
            "year": 2019,
            "rank_display": 198,
            "score": 44.1,
            "link": "https://www.topuniversities.com/universities/keio-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/keio-university_592560cf2aeae70239af4bbe_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3101,
            "size": "XL",
            "faculty_count": 4028
        },
        {
            "university": "Technische Universität Wien",
            "year": 2019,
            "rank_display": 199,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/vienna-university-technology",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vienna-university-of-technology_653_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2515,
            "size": "M",
            "faculty_count": 761
        },
        {
            "university": "Stockholm University",
            "year": 2019,
            "rank_display": 200,
            "score": 43.9,
            "link": "https://www.topuniversities.com/universities/stockholm-university",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/stockholm-university_577_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7567,
            "size": "L",
            "faculty_count": 2325
        },
        {
            "university": "University of Cape Town",
            "year": 2019,
            "rank_display": 200,
            "score": 43.9,
            "link": "https://www.topuniversities.com/universities/university-cape-town",
            "country": "South Africa",
            "city": "Cape Town",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/uct-graduate-school-of-business_592560cf2aeae70239af4ae5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3204,
            "size": "L",
            "faculty_count": 1503
        },
        {
            "university": "Universiti Putra Malaysia (UPM)",
            "year": 2019,
            "rank_display": 202,
            "score": 43.8,
            "link": "https://www.topuniversities.com/universities/universiti-putra-malaysia-upm",
            "country": "Malaysia",
            "city": "Seri Kembangan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-putra-malaysia-upm_385_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5040,
            "size": "L",
            "faculty_count": 2174
        },
        {
            "university": "Texas A&M University",
            "year": 2019,
            "rank_display": 203,
            "score": 43.5,
            "link": "https://www.topuniversities.com/universities/texas-am-university",
            "country": "United States",
            "city": "Galveston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/texas-am-university_592560cf2aeae70239af4ce2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 6621,
            "size": "XL",
            "faculty_count": 3049
        },
        {
            "university": "Radboud University",
            "year": 2019,
            "rank_display": 204,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/radboud-university",
            "country": "Netherlands",
            "city": "Nijmegen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/radboud-university_452_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2781,
            "size": "L",
            "faculty_count": 2074
        },
        {
            "university": "Universidade Estadual de Campinas (Unicamp)",
            "year": 2019,
            "rank_display": 204,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/universidade-estadual-de-campinas-unicamp",
            "country": "Brazil",
            "city": "Campinas",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-estadual-de-campinas-unicamp_96_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 944,
            "size": "L",
            "faculty_count": 1875
        },
        {
            "university": "Complutense University of Madrid",
            "year": 2019,
            "rank_display": 206,
            "score": 43.2,
            "link": "https://www.topuniversities.com/universities/complutense-university-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/complutense-university-of-madrid_592560cf2aeae70239af4bff_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 7295,
            "size": "XL",
            "faculty_count": 4778
        },
        {
            "university": "Universiti Sains Malaysia (USM)",
            "year": 2019,
            "rank_display": 207,
            "score": 43,
            "link": "https://www.topuniversities.com/universities/universiti-sains-malaysia-usm",
            "country": "Malaysia",
            "city": "Gelugor",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-sains-malaysia-usm_386_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4198,
            "size": "L",
            "faculty_count": 2249
        },
        {
            "university": "National Chiao Tung University",
            "year": 2019,
            "rank_display": 208,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/national-chiao-tung-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-chiao-tung-university_437_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1420,
            "size": "M",
            "faculty_count": 1152
        },
        {
            "university": "Universidad de Chile",
            "year": 2019,
            "rank_display": 208,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/universidad-de-chile",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-chile_121_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1697,
            "size": "XL",
            "faculty_count": 2412
        },
        {
            "university": "Waseda University",
            "year": 2019,
            "rank_display": 208,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/waseda-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/waseda-university_592560cf2aeae70239af4d1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7209,
            "size": "XL",
            "faculty_count": 3833
        },
        {
            "university": "Maastricht University",
            "year": 2019,
            "rank_display": 211,
            "score": 42.5,
            "link": "https://www.topuniversities.com/universities/maastricht-university",
            "country": "Netherlands",
            "city": "Maastricht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/maastricht-university_377_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10425,
            "size": "L",
            "faculty_count": 1418
        },
        {
            "university": "Arizona State University",
            "year": 2019,
            "rank_display": 212,
            "score": 42.3,
            "link": "https://www.topuniversities.com/universities/arizona-state-university",
            "country": "United States",
            "city": "Phoenix",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/arizona-state-university_592560cf2aeae70239af4a9c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8430,
            "size": "XL",
            "faculty_count": 2869
        },
        {
            "university": "University of Notre Dame",
            "year": 2019,
            "rank_display": 212,
            "score": 42.3,
            "link": "https://www.topuniversities.com/universities/university-notre-dame",
            "country": "United States",
            "city": "Notre Dame",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-notre-dame_458_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1484,
            "size": "L",
            "faculty_count": 1545
        },
        {
            "university": "University of Illinois at Chicago (UIC)",
            "year": 2019,
            "rank_display": 214,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/university-illinois-chicago-uic",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-chicago-uic_592560cf2aeae70239af4b9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3425,
            "size": "L",
            "faculty_count": 2808
        },
        {
            "university": "The University of Newcastle, Australia (UON)",
            "year": 2019,
            "rank_display": 214,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/university-newcastle-australia-uon",
            "country": "Australia",
            "city": "Callaghan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-newcastle-australia-uon_449_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1747,
            "size": "L",
            "faculty_count": 1135
        },
        {
            "university": "Western University",
            "year": 2019,
            "rank_display": 214,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/western-university",
            "country": "Canada",
            "city": "London",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-ontario_674_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 6314,
            "size": "L",
            "faculty_count": 1311
        },
        {
            "university": "Sapienza University of Rome",
            "year": 2019,
            "rank_display": 217,
            "score": 41.8,
            "link": "https://www.topuniversities.com/universities/sapienza-university-rome",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sapienza-university-of-rome_592560cf2aeae70239af4c97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 5960,
            "size": "XL",
            "faculty_count": 3287
        },
        {
            "university": "Loughborough University",
            "year": 2019,
            "rank_display": 218,
            "score": 41.4,
            "link": "https://www.topuniversities.com/universities/loughborough-university",
            "country": "United Kingdom",
            "city": "Loughborough",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/loughborough-university_592560cf2aeae70239af4bf3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4540,
            "size": "L",
            "faculty_count": 1445
        },
        {
            "university": "University of Wollongong",
            "year": 2019,
            "rank_display": 218,
            "score": 41.4,
            "link": "https://www.topuniversities.com/universities/university-wollongong",
            "country": "Australia",
            "city": "Wollongong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wollongong_680_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4779,
            "size": "L",
            "faculty_count": 1410
        },
        {
            "university": "Al-Farabi Kazakh National University",
            "year": 2019,
            "rank_display": 220,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/al-farabi-kazakh-national-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/al-farabi-kazakh-national-university_2146_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3054,
            "size": "L",
            "faculty_count": 4047
        },
        {
            "university": "Sciences Po",
            "year": 2019,
            "rank_display": 221,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/sciences-po",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sciences-po-_592560cf2aeae70239af4c64_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4841,
            "size": "M",
            "faculty_count": 1808
        },
        {
            "university": "Victoria University of Wellington",
            "year": 2019,
            "rank_display": 221,
            "score": 41,
            "link": "https://www.topuniversities.com/universities/victoria-university-wellington",
            "country": "New Zealand",
            "city": "Kelburn, Wellington",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/victoria-university-of-wellington_592560cf2aeae70239af4d0f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 3668,
            "size": "L",
            "faculty_count": 1124
        },
        {
            "university": "Universität Hamburg",
            "year": 2019,
            "rank_display": 223,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/universitat-hamburg",
            "country": "Germany",
            "city": "Hamburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-hamburg_592560cf2aeae70239af4b79_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3626,
            "size": "L",
            "faculty_count": 2032
        },
        {
            "university": "University of Antwerp",
            "year": 2019,
            "rank_display": 223,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/university-antwerp",
            "country": "Belgium",
            "city": "Antwerp",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-antwerp_21_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2688,
            "size": "L",
            "faculty_count": 1704
        },
        {
            "university": "University of Leicester",
            "year": 2019,
            "rank_display": 223,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/university-leicester",
            "country": "United Kingdom",
            "city": "Leicester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leicester_339_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4919,
            "size": "L",
            "faculty_count": 1518
        },
        {
            "university": "Georgetown University",
            "year": 2019,
            "rank_display": 226,
            "score": 40.8,
            "link": "https://www.topuniversities.com/universities/georgetown-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgetown-university_224_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3286,
            "size": "L",
            "faculty_count": 2009
        },
        {
            "university": "University of Sussex",
            "year": 2019,
            "rank_display": 227,
            "score": 40.7,
            "link": "https://www.topuniversities.com/universities/university-sussex",
            "country": "United Kingdom",
            "city": "Brighton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-sussex_592560cf2aeae70239af4ccd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6350,
            "size": "L",
            "faculty_count": 1320
        },
        {
            "university": "Universiti Teknologi Malaysia",
            "year": 2019,
            "rank_display": 228,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-malaysia",
            "country": "Malaysia",
            "city": "Skudai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-malaysia_387_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4956,
            "size": "L",
            "faculty_count": 1869
        },
        {
            "university": "University of Calgary",
            "year": 2019,
            "rank_display": 229,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/university-calgary",
            "country": "Canada",
            "city": "Calgary",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-calgary_592560cf2aeae70239af4ad5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6791,
            "size": "XL",
            "faculty_count": 2668
        },
        {
            "university": "Tel Aviv University",
            "year": 2019,
            "rank_display": 230,
            "score": 40.3,
            "link": "https://www.topuniversities.com/universities/tel-aviv-university",
            "country": "Israel",
            "city": "Tel Aviv",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tel-aviv-university_592560cf2aeae70239af4cde_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1294,
            "size": "L",
            "faculty_count": 1048
        },
        {
            "university": "King Abdulaziz University (KAU)",
            "year": 2019,
            "rank_display": 231,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/king-abdulaziz-university-kau",
            "country": "Saudi Arabia",
            "city": "Jeddah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-abdulaziz-university-kau_1172_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6821,
            "size": "XL",
            "faculty_count": 4054
        },
        {
            "university": "University of Canterbury",
            "year": 2019,
            "rank_display": 231,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/university-canterbury",
            "country": "New Zealand",
            "city": "Christchurch",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canterbury_97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2816,
            "size": "L",
            "faculty_count": ""
        },
        {
            "university": "Vrije Universiteit Amsterdam",
            "year": 2019,
            "rank_display": 231,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-amsterdam_658_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4059,
            "size": "L",
            "faculty_count": 2598
        },
        {
            "university": "National Cheng Kung University (NCKU)",
            "year": 2019,
            "rank_display": 234,
            "score": 39.4,
            "link": "https://www.topuniversities.com/universities/national-cheng-kung-university-ncku",
            "country": "Taiwan",
            "city": "Tainan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-cheng-kung-university-ncku_435_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2656,
            "size": "L",
            "faculty_count": 2079
        },
        {
            "university": "Saint Petersburg State University",
            "year": 2019,
            "rank_display": 235,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/saint-petersburg-state-university",
            "country": "Russia",
            "city": "Saint Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-petersburg-state-university_592560cf2aeae70239af4cc0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4982,
            "size": "L",
            "faculty_count": 3054
        },
        {
            "university": "Royal Holloway University of London",
            "year": 2019,
            "rank_display": 236,
            "score": 39.1,
            "link": "https://www.topuniversities.com/universities/royal-holloway-university-london",
            "country": "United Kingdom",
            "city": "Egham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/royal-holloway-university-of-london_534_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.705,
            "size": "M",
            "faculty_count": 831
        },
        {
            "university": "American University of Beirut (AUB)",
            "year": 2019,
            "rank_display": 237,
            "score": 39,
            "link": "https://www.topuniversities.com/universities/american-university-beirut-aub",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-beirut-aub_780_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1724,
            "size": "M",
            "faculty_count": 964
        },
        {
            "university": "Tufts University",
            "year": 2019,
            "rank_display": 237,
            "score": 39,
            "link": "https://www.topuniversities.com/universities/tufts-university",
            "country": "United States",
            "city": "Medford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tufts-university_630_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1798,
            "size": "M",
            "faculty_count": 1459
        },
        {
            "university": "Queen's University at Kingston",
            "year": 2019,
            "rank_display": 239,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/queens-university-kingston",
            "country": "Canada",
            "city": "Kingston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-at-kingston_513_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3858,
            "size": "L",
            "faculty_count": 1287
        },
        {
            "university": "Universite libre de Bruxelles",
            "year": 2019,
            "rank_display": 239,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/universite-libre-de-bruxelles",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universite-libre-de-bruxelles_75_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 8073,
            "size": "L",
            "faculty_count": 1101
        },
        {
            "university": "Université Paris-Saclay",
            "year": 2019,
            "rank_display": 239,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/universite-paris-saclay",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-saclay_592560cf2aeae70239af4c6e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 7649,
            "size": "XL",
            "faculty_count": 8010
        },
        {
            "university": "University of Navarra",
            "year": 2019,
            "rank_display": 242,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/university-navarra",
            "country": "Spain",
            "city": "Pamplona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-navarra_592560cf2aeae70239af4c40_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2978,
            "size": "M",
            "faculty_count": 1175
        },
        {
            "university": "University of Miami",
            "year": 2019,
            "rank_display": 242,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/university-miami",
            "country": "United States",
            "city": "Miami",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-miami_401_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2646,
            "size": "L",
            "faculty_count": 2565
        },
        {
            "university": "Novosibirsk State University",
            "year": 2019,
            "rank_display": 244,
            "score": 38.7,
            "link": "https://www.topuniversities.com/universities/novosibirsk-state-university",
            "country": "Russia",
            "city": "Novosibirsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/novosibirsk-state-university_462_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1623,
            "size": "M",
            "faculty_count": 1218
        },
        {
            "university": "Queensland University of Technology (QUT)",
            "year": 2019,
            "rank_display": 244,
            "score": 38.7,
            "link": "https://www.topuniversities.com/universities/queensland-university-technology-qut",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/queensland-university-of-technology-qut_592560cf2aeae70239af4c88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6403,
            "size": "XL",
            "faculty_count": 2028
        },
        {
            "university": "The University of Arizona",
            "year": 2019,
            "rank_display": 246,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/university-arizona",
            "country": "United States",
            "city": "Tucson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-arizona_26_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3526,
            "size": "XL",
            "faculty_count": 5724
        },
        {
            "university": "Technion - Israel Institute of Technology",
            "year": 2019,
            "rank_display": 247,
            "score": 38.4,
            "link": "https://www.topuniversities.com/universities/technion-israel-institute-technology",
            "country": "Israel",
            "city": "Haifa",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/technion-israel-institute-of-technology_600_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "L",
            "faculty_count": 1.616
        },
        {
            "university": "University of Surrey",
            "year": 2019,
            "rank_display": 248,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/university-surrey",
            "country": "United Kingdom",
            "city": "Guildford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-surrey_584_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5556,
            "size": "L",
            "faculty_count": 1221
        },
        {
            "university": "Università di Padova",
            "year": 2019,
            "rank_display": 249,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universita-di-padova",
            "country": "Italy",
            "city": "Padua",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitdi-padova_480_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2654,
            "size": "XL",
            "faculty_count": 2806
        },
        {
            "university": "Curtin University",
            "year": 2019,
            "rank_display": 250,
            "score": 38,
            "link": "https://www.topuniversities.com/universities/curtin-university",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/curtin-university_150_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4565,
            "size": "L",
            "faculty_count": 1698
        },
        {
            "university": "Macquarie University",
            "year": 2019,
            "rank_display": 250,
            "score": 38,
            "link": "https://www.topuniversities.com/universities/macquarie-university",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/macquarie-university_378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8621,
            "size": "L",
            "faculty_count": 1766
        },
        {
            "university": "RMIT University",
            "year": 2019,
            "rank_display": 250,
            "score": 38,
            "link": "https://www.topuniversities.com/universities/rmit-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/rmit-university_592560cf2aeae70239af4c95_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 11743,
            "size": "XL",
            "faculty_count": 1859
        },
        {
            "university": "Technical University of Darmstadt",
            "year": 2019,
            "rank_display": 253,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/technical-university-darmstadt",
            "country": "Germany",
            "city": "Darmstadt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-darmstadt_628_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3979,
            "size": "L",
            "faculty_count": 804
        },
        {
            "university": "Universidad Carlos III de Madrid (UC3M)",
            "year": 2019,
            "rank_display": 253,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/universidad-carlos-iii-de-madrid-uc3m",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-carlos-iii-de-madrid-uc3m_1509_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.11,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "Rheinische Friedrich-Wilhelms-Universität Bonn",
            "year": 2019,
            "rank_display": 255,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/rheinische-friedrich-wilhelms-universitat-bonn",
            "country": "Germany",
            "city": "Bonn",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rheinische-friedrich-wilhelms-universitt-bonn_592560cf2aeae70239af4ac1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3276,
            "size": "L",
            "faculty_count": 4207
        },
        {
            "university": "King Saud University",
            "year": 2019,
            "rank_display": 256,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/king-saud-university",
            "country": "Saudi Arabia",
            "city": "Riyadh",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-saud-university_1175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3896,
            "size": "XL",
            "faculty_count": 4216
        },
        {
            "university": "National Taiwan University of Science and Technology (Taiwan Tech)",
            "year": 2019,
            "rank_display": 257,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-science-technology-taiwan-tech",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-of-science-and-technology-taiwan-tech_590_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1537,
            "size": "M",
            "faculty_count": 1005
        },
        {
            "university": "Wuhan University",
            "year": 2019,
            "rank_display": 257,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/wuhan-university",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/wuhan-university_889_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3682,
            "size": "XL",
            "faculty_count": 3828
        },
        {
            "university": "University of Massachusetts Amherst",
            "year": 2019,
            "rank_display": 259,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/university-massachusetts-amherst",
            "country": "United States",
            "city": "Amherst",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-massachusetts-amherst_592560cf2aeae70239af4c0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3696,
            "size": "L",
            "faculty_count": 1852
        },
        {
            "university": "National University of Ireland Galway",
            "year": 2019,
            "rank_display": 260,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/national-university-ireland-galway",
            "country": "Ireland",
            "city": "Galway",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-ireland-galway_220_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3208,
            "size": "L",
            "faculty_count": 1835
        },
        {
            "university": "Universität Stuttgart",
            "year": 2019,
            "rank_display": 260,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/universitat-stuttgart",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-stuttgart_580_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.028,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "University of Tsukuba",
            "year": 2019,
            "rank_display": 260,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/university-tsukuba",
            "country": "Japan",
            "city": "Tsukuba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tsukuba_592560cf2aeae70239af4cf7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2358,
            "size": "L",
            "faculty_count": 2073
        },
        {
            "university": "Ecole des Ponts ParisTech",
            "year": 2019,
            "rank_display": 263,
            "score": 37,
            "link": "https://www.topuniversities.com/universities/ecole-des-ponts-paristech",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-des-ponts-paristech_187_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 735,
            "size": "S",
            "faculty_count": 338
        },
        {
            "university": "Indian Institute of Technology Madras (IITM)",
            "year": 2019,
            "rank_display": 264,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-madras-iitm",
            "country": "India",
            "city": "Chennai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-madras-iitm_286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 94,
            "size": "M",
            "faculty_count": 884
        },
        {
            "university": "Kyung Hee University",
            "year": 2019,
            "rank_display": 264,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/kyung-hee-university",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyung-hee-university_592560cf2aeae70239af4bcd_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4715,
            "size": "L",
            "faculty_count": 3435
        },
        {
            "university": "Simon Fraser University",
            "year": 2019,
            "rank_display": 264,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/simon-fraser-university",
            "country": "Canada",
            "city": "Burnaby",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/simon-fraser-university_562_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 8051,
            "size": "L",
            "faculty_count": 1193
        },
        {
            "university": "University of South Australia",
            "year": 2019,
            "rank_display": 264,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/university-south-australia",
            "country": "Australia",
            "city": "Magill",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-south-australia_566_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.886,
            "size": "L",
            "faculty_count": 1.524
        },
        {
            "university": "University of Strathclyde",
            "year": 2019,
            "rank_display": 268,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/university-strathclyde",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-strathclyde_579_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5397,
            "size": "L",
            "faculty_count": 1532
        },
        {
            "university": "University of East Anglia (UEA)",
            "year": 2019,
            "rank_display": 269,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/university-east-anglia-uea",
            "country": "United Kingdom",
            "city": "Norwich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-east-anglia-uea_637_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3541,
            "size": "L",
            "faculty_count": 1206
        },
        {
            "university": "University of Gothenburg",
            "year": 2019,
            "rank_display": 269,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/university-gothenburg",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gothenburg_233_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5652,
            "size": "L",
            "faculty_count": 2680
        },
        {
            "university": "Chulalongkorn University",
            "year": 2019,
            "rank_display": 271,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/chulalongkorn-university",
            "country": "Thailand",
            "city": "Bangkok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chulalongkorn-university_592560cf2aeae70239af4b01_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 985,
            "size": "XL",
            "faculty_count": 3354
        },
        {
            "university": "Universidad de los Andes",
            "year": 2019,
            "rank_display": 272,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/universidad-de-los-andes",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-los-andes_592560cf2aeae70239af4bf6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 13,
            "international_students": 151,
            "size": "L",
            "faculty_count": 1223
        },
        {
            "university": "University of Dundee",
            "year": 2019,
            "rank_display": 272,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/university-dundee",
            "country": "United Kingdom",
            "city": "Dundee",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/-university-of-dundee_592560cf2aeae70239af4b2c_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 3025,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "University of Waikato",
            "year": 2019,
            "rank_display": 274,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/university-waikato",
            "country": "New Zealand",
            "city": "Hamilton",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waikato_660_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.27,
            "size": "M",
            "faculty_count": 595
        },
        {
            "university": "Universidad Nacional de Colombia",
            "year": 2019,
            "rank_display": 275,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-de-colombia_592560cf2aeae70239af4b0b_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 335,
            "size": "XL",
            "faculty_count": 3055
        },
        {
            "university": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
            "year": 2019,
            "rank_display": 275,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-catalunya-barcelonatech-upc",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-catalunya_103_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3101,
            "size": "L",
            "faculty_count": 2485
        },
        {
            "university": "Hong Kong Baptist University",
            "year": 2019,
            "rank_display": 277,
            "score": 35.8,
            "link": "https://www.topuniversities.com/universities/hong-kong-baptist-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hong-kong-baptist-university_893_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4704,
            "size": "M",
            "faculty_count": 980
        },
        {
            "university": "Tomsk State University",
            "year": 2019,
            "rank_display": 277,
            "score": 35.8,
            "link": "https://www.topuniversities.com/universities/tomsk-state-university",
            "country": "Russia",
            "city": "Tomsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tomsk-state-university_616_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2828,
            "size": "M",
            "faculty_count": 1742
        },
        {
            "university": "Dalhousie University",
            "year": 2019,
            "rank_display": 279,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/dalhousie-university",
            "country": "Canada",
            "city": "Halifax",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dalhousie-university_151_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4483,
            "size": "L",
            "faculty_count": 1150
        },
        {
            "university": "North Carolina State University",
            "year": 2019,
            "rank_display": 279,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/north-carolina-state-university",
            "country": "United States",
            "city": "Raleigh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/north-carolina-state-university_592560cf2aeae70239af4c2a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 3156,
            "size": "L",
            "faculty_count": 1944
        },
        {
            "university": "Goethe-University Frankfurt am Main",
            "year": 2019,
            "rank_display": 279,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/goethe-university-frankfurt-am-main",
            "country": "Germany",
            "city": "Frankfurt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-frankfurt-am-main_214_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6.749,
            "size": "XL",
            "faculty_count": 3.623
        },
        {
            "university": "Universität Innsbruck",
            "year": 2019,
            "rank_display": 279,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/universitat-innsbruck",
            "country": "Austria",
            "city": "Innsbruck",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-innsbruck_592560cf2aeae70239af4ba7_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 6323,
            "size": "L",
            "faculty_count": 1769
        },
        {
            "university": "Indian Institute of Technology Kanpur (IITK)",
            "year": 2019,
            "rank_display": 283,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kanpur-iitk",
            "country": "India",
            "city": "Kanpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kanpur-iitk_592560cf2aeae70239af4ba5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 29,
            "size": "M",
            "faculty_count": 551
        },
        {
            "university": "Rutgers University–New Brunswick",
            "year": 2019,
            "rank_display": 283,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/rutgers-university-new-brunswick",
            "country": "United States",
            "city": "New Brunswick",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rutgers-university-new-brunswick_538_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7965,
            "size": "XL",
            "faculty_count": 5009
        },
        {
            "university": "Harbin Institute of Technology",
            "year": 2019,
            "rank_display": 285,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/harbin-institute-technology",
            "country": "China (Mainland)",
            "city": "Harbin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/harbin-institute-of-technology_877_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2102,
            "size": "XL",
            "faculty_count": 3215
        },
        {
            "university": "University of Turku",
            "year": 2019,
            "rank_display": 285,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/university-turku",
            "country": "Finland",
            "city": "Turku",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turku_592560cf2aeae70239af4cfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 604,
            "size": "L",
            "faculty_count": 1505
        },
        {
            "university": "University of Tasmania",
            "year": 2019,
            "rank_display": 287,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/university-tasmania",
            "country": "Australia",
            "city": "Hobart",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tasmania_592560cf2aeae70239af4cd5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5260,
            "size": "L",
            "faculty_count": 982
        },
        {
            "university": "SOAS University of London",
            "year": 2019,
            "rank_display": 288,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/soas-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/soas-university-of-london-_592560cf2aeae70239af4bf0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.398,
            "size": "S",
            "faculty_count": 300
        },
        {
            "university": "Université Grenoble Alpes",
            "year": 2019,
            "rank_display": 289,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/universite-grenoble-alpes",
            "country": "France",
            "city": "Grenoble",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-grenoble-alpes_592560cf2aeae70239af5ab2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8880,
            "size": "XL",
            "faculty_count": 3088
        },
        {
            "university": "University of Ottawa",
            "year": 2019,
            "rank_display": 289,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/university-ottawa",
            "country": "Canada",
            "city": "Ottawa",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-ottawa_475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 8885,
            "size": "XL",
            "faculty_count": 1677
        },
        {
            "university": "Tongji University",
            "year": 2019,
            "rank_display": 291,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/tongji-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tongji-university_617_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5095,
            "size": "XL",
            "faculty_count": 2785
        },
        {
            "university": "Beijing Normal University",
            "year": 2019,
            "rank_display": 292,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/beijing-normal-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-normal-university_869_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3288,
            "size": "L",
            "faculty_count": 2098
        },
        {
            "university": "National Yang Ming University",
            "year": 2019,
            "rank_display": 292,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/national-yang-ming-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-yang-ming-university_444_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 237,
            "size": "S",
            "faculty_count": 965
        },
        {
            "university": "Universitas Indonesia",
            "year": 2019,
            "rank_display": 292,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/universitas-indonesia",
            "country": "Indonesia",
            "city": "Depok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universitas-indonesia_290_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1412,
            "size": "XL",
            "faculty_count": 4210
        },
        {
            "university": "Indian Institute of Technology Kharagpur (IIT-KGP)",
            "year": 2019,
            "rank_display": 295,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kharagpur-iit-kgp",
            "country": "India",
            "city": "Kharagpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kharagpur-iit-kgp_285_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 27,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "Sun Yat-sen University",
            "year": 2019,
            "rank_display": 295,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/sun-yat-sen-university",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sun-yat-sen-university_888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3127,
            "size": "XL",
            "faculty_count": 7002
        },
        {
            "university": "Université Diderot  Paris 7",
            "year": 2019,
            "rank_display": 295,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/universite-diderot-paris-7",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-diderot-paris-7_592560cf2aeae70239af4c6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 11387,
            "size": "XL",
            "faculty_count": 3755
        },
        {
            "university": "Universitat Pompeu Fabra (Barcelona)",
            "year": 2019,
            "rank_display": 298,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/universitat-pompeu-fabra",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-pompeu-fabra_504_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1633,
            "size": "L",
            "faculty_count": 785
        },
        {
            "university": "Bauman Moscow State Technical University",
            "year": 2019,
            "rank_display": 299,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/bauman-moscow-state-technical-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bauman-moscow-state-technical-university_592560cf2aeae70239af505c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2370,
            "size": "L",
            "faculty_count": 4349
        },
        {
            "university": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            "year": 2019,
            "rank_display": 299,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/friedrich-alexander-universitat-erlangen-nurnberg",
            "country": "Germany",
            "city": "Erlangen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/friedrich-alexander-universitt-erlangen-nrnberg_191_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 4943,
            "size": "XL",
            "faculty_count": 1107
        },
        {
            "university": "Université Paris 1 Panthéon-Sorbonne",
            "year": 2019,
            "rank_display": 299,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/universite-paris-1-pantheon-sorbonne",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-1-panthon-sorbonne_482_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7257,
            "size": "XL",
            "faculty_count": 1679
        },
        {
            "university": "Heriot-Watt University",
            "year": 2019,
            "rank_display": 302,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/heriot-watt-university",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/heriot-watt-university_592560cf2aeae70239af4d5f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3536,
            "size": "M",
            "faculty_count": 806
        },
        {
            "university": "Linköping University",
            "year": 2019,
            "rank_display": 302,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/linkoping-university",
            "country": "Sweden",
            "city": "Linköping",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/linkping-university_348_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.514,
            "size": "L",
            "faculty_count": 1.471
        },
        {
            "university": "University of Hawaiʻi at Mānoa",
            "year": 2019,
            "rank_display": 302,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/university-hawaii-manoa",
            "country": "United States",
            "city": "Honolulu",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hawaii-at-maoa_255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.586,
            "size": "L",
            "faculty_count": 1.64
        },
        {
            "university": "ENS Paris-Saclay",
            "year": 2019,
            "rank_display": 305,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/ens-paris-saclay",
            "country": "France",
            "city": "Cachan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-de-cachan_1878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 298,
            "size": "S",
            "faculty_count": 289
        },
        {
            "university": "Birkbeck, University of London",
            "year": 2019,
            "rank_display": 306,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/birkbeck-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/birkbeck-university-of-london_1636_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3.214,
            "size": "M",
            "faculty_count": 585
        },
        {
            "university": "University of Cologne",
            "year": 2019,
            "rank_display": 306,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/university-cologne",
            "country": "Germany",
            "city": "Cologne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cologne_592560cf2aeae70239af4bc6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4890,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "National Taiwan Normal University",
            "year": 2019,
            "rank_display": 308,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/national-taiwan-normal-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-normal-university_440_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.499,
            "size": "M",
            "faculty_count": 1.374
        },
        {
            "university": "Deakin University",
            "year": 2019,
            "rank_display": 309,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/deakin-university",
            "country": "Australia",
            "city": "Geelong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/download-48x48.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 10684,
            "size": "XL",
            "faculty_count": 1851
        },
        {
            "university": "Universitat Politècnica de València",
            "year": 2019,
            "rank_display": 310,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-valencia",
            "country": "Spain",
            "city": "València",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-valncia_648_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.589,
            "size": "L",
            "faculty_count": 2.645
        },
        {
            "university": "Yeshiva University",
            "year": 2019,
            "rank_display": 310,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/yeshiva-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-katz-school-at-yeshiva-university_685_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 467,
            "size": "S",
            "faculty_count": 471
        },
        {
            "university": "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
            "year": 2019,
            "rank_display": 312,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/moscow-institute-physics-technology-mipt-moscow-phystech",
            "country": "Russia",
            "city": "Dolgoprudny",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-institute-of-physics-and-technology-mipt-moscow-phystech_15270_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1153,
            "size": "M",
            "faculty_count": 1110
        },
        {
            "university": "University at Buffalo SUNY",
            "year": 2019,
            "rank_display": 313,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/university-buffalo-suny",
            "country": "United States",
            "city": "Buffalo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-at-buffalo-suny_1888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4.409,
            "size": "L",
            "faculty_count": 1.53
        },
        {
            "university": "Xi’an Jiaotong University",
            "year": 2019,
            "rank_display": 313,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/xian-jiaotong-university",
            "country": "China (Mainland)",
            "city": "Xi'an",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xian-jiaotong-university_683_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2266,
            "size": "XL",
            "faculty_count": 3729
        },
        {
            "university": "Gwangju Institute of Science and Technology (GIST)",
            "year": 2019,
            "rank_display": 315,
            "score": 33.3,
            "link": "https://www.topuniversities.com/universities/gwangju-institute-science-technology-gist",
            "country": "South Korea",
            "city": "Gwangju",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gwangju-institute-of-science-and-technology-gist_718_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 122,
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "Khalifa University of Science and Technology",
            "year": 2019,
            "rank_display": 315,
            "score": 36.5,
            "link": "https://www.topuniversities.com/universities/khalifa-university-science-technology",
            "country": "United Arab Emirates",
            "city": "Abu Dhabi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/khalifa-university-of-science-and-technology_592560e19988f300e2320ee2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 652,
            "size": "S",
            "faculty_count": ""
        },
        {
            "university": "Charles University",
            "year": 2019,
            "rank_display": 317,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/charles-university",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/charles-university_117_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9080,
            "size": "XL",
            "faculty_count": 5019
        },
        {
            "university": "Lincoln University",
            "year": 2019,
            "rank_display": 317,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/lincoln-university",
            "country": "New Zealand",
            "city": "Lincoln",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/lincoln-university-_592560cf2aeae70239af4ef3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 898,
            "size": "S",
            "faculty_count": 643
        },
        {
            "university": "Ewha Womans University",
            "year": 2019,
            "rank_display": 319,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/ewha-womans-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ewha-womans-university_592560cf2aeae70239af4b38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.105,
            "size": "L",
            "faculty_count": 2.436
        },
        {
            "university": "Tilburg University",
            "year": 2019,
            "rank_display": 319,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/tilburg-university",
            "country": "Netherlands",
            "city": "Tilburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tilburg-university_592560cf2aeae70239af4ce5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.189,
            "size": "M",
            "faculty_count": 745
        },
        {
            "university": "Hiroshima University",
            "year": 2019,
            "rank_display": 321,
            "score": 32.8,
            "link": "https://www.topuniversities.com/universities/hiroshima-university",
            "country": "Japan",
            "city": "Higashihiroshima City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hiroshima-university_264_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.68,
            "size": "L",
            "faculty_count": 2.366
        },
        {
            "university": "University of Tartu",
            "year": 2019,
            "rank_display": 321,
            "score": 32.8,
            "link": "https://www.topuniversities.com/universities/university-tartu",
            "country": "Estonia",
            "city": "Tartu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tartu_592560cf2aeae70239af4d08_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1400,
            "size": "M",
            "faculty_count": 1605
        },
        {
            "university": "Indiana University Bloomington",
            "year": 2019,
            "rank_display": 323,
            "score": 32.7,
            "link": "https://www.topuniversities.com/universities/indiana-university-bloomington",
            "country": "United States",
            "city": "Bloomington",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/indiana-university-bloomington_289_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3722,
            "size": "XL",
            "faculty_count": 3837
        },
        {
            "university": "Universiti Brunei Darussalam (UBD)",
            "year": 2019,
            "rank_display": 323,
            "score": 32.7,
            "link": "https://www.topuniversities.com/universities/universiti-brunei-darussalam-ubd",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-brunei-darussalam-ubd_22212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 635,
            "size": "S",
            "faculty_count": 475
        },
        {
            "university": "University of Milan",
            "year": 2019,
            "rank_display": 325,
            "score": 32.5,
            "link": "https://www.topuniversities.com/universities/university-milan",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milan_1873_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3269,
            "size": "XL",
            "faculty_count": 2516
        },
        {
            "university": "Northeastern University",
            "year": 2019,
            "rank_display": 326,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/northeastern-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northeastern-university_454_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7.595,
            "size": "L",
            "faculty_count": 1.723
        },
        {
            "university": "Universität Jena",
            "year": 2019,
            "rank_display": 326,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/universitat-jena",
            "country": "Germany",
            "city": "Jena",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-jena_592560cf2aeae70239af4bb5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2.307,
            "size": "L",
            "faculty_count": 3.974
        },
        {
            "university": "University of Porto",
            "year": 2019,
            "rank_display": 328,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/university-porto",
            "country": "Portugal",
            "city": "Porto",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-porto_760_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3783,
            "size": "L",
            "faculty_count": 2028
        },
        {
            "university": "Griffith University",
            "year": 2019,
            "rank_display": 329,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/griffith-university",
            "country": "Australia",
            "city": "Nathan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/griffith-university_242_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5875,
            "size": "XL",
            "faculty_count": 1918
        },
        {
            "university": "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
            "year": 2019,
            "rank_display": 329,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/national-research-nuclear-university-mephi-moscow-engineering-physics-institute",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-nuclear-university-mephi-moscow-engineering-physics-institute_15207_small.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1590,
            "size": "M",
            "faculty_count": 998
        },
        {
            "university": "Westfälische Wilhelms-Universität Münster",
            "year": 2019,
            "rank_display": 329,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/westfalische-wilhelms-universitat-munster",
            "country": "Germany",
            "city": "Münster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/westflische-wilhelms-universitt-mnster_421_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.845,
            "size": "XL",
            "faculty_count": 4.23
        },
        {
            "university": "Brunel University London",
            "year": 2019,
            "rank_display": 332,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/brunel-university-london",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/brunel-university-london_592560cf2aeae70239af4acc_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 5.305,
            "size": "L",
            "faculty_count": 860
        },
        {
            "university": "Massey University",
            "year": 2019,
            "rank_display": 332,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/massey-university",
            "country": "New Zealand",
            "city": "Palmerston North",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/massey-university_395_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4836,
            "size": "M",
            "faculty_count": 1209
        },
        {
            "university": "Qatar University",
            "year": 2019,
            "rank_display": 332,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/qatar-university",
            "country": "Qatar",
            "city": "Doha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/qatar-university_2537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3197,
            "size": "M",
            "faculty_count": 1163
        },
        {
            "university": "University Ulm",
            "year": 2019,
            "rank_display": 332,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-ulm",
            "country": "Germany",
            "city": "Ulm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-ulm_638_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1289,
            "size": "M",
            "faculty_count": 1473
        },
        {
            "university": "University of California, Santa Cruz",
            "year": 2019,
            "rank_display": 336,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/university-california-santa-cruz",
            "country": "United States",
            "city": "Santa Cruz",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-cruz_92_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 2.13,
            "size": "L",
            "faculty_count": 1.045
        },
        {
            "university": "University of Jyväskylä",
            "year": 2019,
            "rank_display": 336,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/university-jyvaskyla",
            "country": "Finland",
            "city": "Jyväskylä",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-jyvskyl_306_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 339,
            "size": "M",
            "faculty_count": 1.573
        },
        {
            "university": "Nankai University",
            "year": 2019,
            "rank_display": 338,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/nankai-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nankai-university_882_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.488,
            "size": "XL",
            "faculty_count": 2.846
        },
        {
            "university": "Umea University",
            "year": 2019,
            "rank_display": 338,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/umea-university",
            "country": "Sweden",
            "city": "Umeå",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/umea-university_639_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.186,
            "size": "L",
            "faculty_count": 1.966
        },
        {
            "university": "Universität Mannheim",
            "year": 2019,
            "rank_display": 338,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/universitat-mannheim",
            "country": "Germany",
            "city": "Mannheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-mannheim_592560cf2aeae70239af4c0b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 1.29,
            "size": "M",
            "faculty_count": 307
        },
        {
            "university": "University College Cork",
            "year": 2019,
            "rank_display": 338,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/university-college-cork",
            "country": "Ireland",
            "city": "Cork",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-cork_142_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 3002,
            "size": "L",
            "faculty_count": 1619
        },
        {
            "university": "Virginia Polytechnic Institute and State University",
            "year": 2019,
            "rank_display": 338,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/virginia-polytechnic-institute-state-university",
            "country": "United States",
            "city": "Blacksburg",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/virginia-polytechnic-institute-and-state-university_592560cf2aeae70239af4d15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.477,
            "size": "XL",
            "faculty_count": 2.802
        },
        {
            "university": "Aalborg University",
            "year": 2019,
            "rank_display": 343,
            "score": 31.6,
            "link": "https://www.topuniversities.com/universities/aalborg-university",
            "country": "Denmark",
            "city": "Aalborg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalborg-university_1262_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.474,
            "size": "L",
            "faculty_count": 1.716
        },
        {
            "university": "HSE University",
            "year": 2019,
            "rank_display": 343,
            "score": 31.6,
            "link": "https://www.topuniversities.com/universities/hse-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/logo-3-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4535,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "George Washington University",
            "year": 2019,
            "rank_display": 345,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/george-washington-university",
            "country": "United States",
            "city": "Ashburn",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/george-washington-university_223_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4.024,
            "size": "L",
            "faculty_count": 2.016
        },
        {
            "university": "Rensselaer Polytechnic Institute",
            "year": 2019,
            "rank_display": 345,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/rensselaer-polytechnic-institute",
            "country": "United States",
            "city": "Troy",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rensselaer-polytechnic-institute_522_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.594,
            "size": "M",
            "faculty_count": 639
        },
        {
            "university": "University of Utah",
            "year": 2019,
            "rank_display": 345,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/university-utah",
            "country": "United States",
            "city": "Salt Lake City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-utah_645_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.134,
            "size": "L",
            "faculty_count": 3.345
        },
        {
            "university": "Université de Strasbourg",
            "year": 2019,
            "rank_display": 348,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/universite-de-strasbourg",
            "country": "France",
            "city": "Strasbourg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-strasbourg_578_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 9.111,
            "size": "XL",
            "faculty_count": 2.544
        },
        {
            "university": "Stony Brook University, State University of New York",
            "year": 2019,
            "rank_display": 349,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/stony-brook-university-state-university-new-york",
            "country": "United States",
            "city": "Stony Brook",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stony-brook-university-state-university-of-new-york_592560cf2aeae70239af4ccb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6.198,
            "size": "L",
            "faculty_count": 2.316
        },
        {
            "university": "United Arab Emirates University",
            "year": 2019,
            "rank_display": 350,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/united-arab-emirates-university",
            "country": "United Arab Emirates",
            "city": "Al Ain",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/united-arab-emirates-university_778_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1238,
            "size": "M",
            "faculty_count": 919
        },
        {
            "university": "City, University of London",
            "year": 2019,
            "rank_display": 351,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/city-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-london_354_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 7.362,
            "size": "L",
            "faculty_count": 970
        },
        {
            "university": "Kobe University",
            "year": 2019,
            "rank_display": 352,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/kobe-university",
            "country": "Japan",
            "city": "Kobe City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kobe-university_320_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.216,
            "size": "L",
            "faculty_count": 1.986
        },
        {
            "university": "Tokyo Medical and Dental University (TMDU)",
            "year": 2019,
            "rank_display": 352,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/tokyo-medical-dental-university-tmdu",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-medical-and-dental-university-tmdu_592560cf2aeae70239af4e8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 325,
            "size": "S",
            "faculty_count": 930
        },
        {
            "university": "Belarusian State University",
            "year": 2019,
            "rank_display": 354,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/belarusian-state-university",
            "country": "Belarus",
            "city": "Minsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/belarusian-state-university_14128_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4264,
            "size": "L",
            "faculty_count": 4173
        },
        {
            "university": "MGIMO University",
            "year": 2019,
            "rank_display": 355,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/mgimo-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-state-institute-of-international-relations-mgimo-university_2156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 1517,
            "size": "M",
            "faculty_count": 1739
        },
        {
            "university": "Essex, University of",
            "year": 2019,
            "rank_display": 355,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/essex-university",
            "country": "United Kingdom",
            "city": "Colchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-essex_592560cf2aeae70239af4b4c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6.124,
            "size": "L",
            "faculty_count": 1.044
        },
        {
            "university": "University of Kent",
            "year": 2019,
            "rank_display": 355,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/university-kent",
            "country": "United Kingdom",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kent_315_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.515,
            "size": "L",
            "faculty_count": 4000
        },
        {
            "university": "University of Lisbon",
            "year": 2019,
            "rank_display": 355,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/university-lisbon",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lisbon_1475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8.546,
            "size": "XL",
            "faculty_count": 3.389
        },
        {
            "university": "Bandung Institute of Technology (ITB)",
            "year": 2019,
            "rank_display": 359,
            "score": 30.4,
            "link": "https://www.topuniversities.com/universities/bandung-institute-technology-itb",
            "country": "Indonesia",
            "city": "Bandung",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bandung-institute-of-technology-itb_41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 613,
            "size": "L",
            "faculty_count": 2228
        },
        {
            "university": "University of Victoria (UVic)",
            "year": 2019,
            "rank_display": 359,
            "score": 30.4,
            "link": "https://www.topuniversities.com/universities/university-victoria-uvic",
            "country": "Canada",
            "city": "Victoria",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-victoria-uvic_592560cf2aeae70239af4d0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 6.135,
            "size": "L",
            "faculty_count": 865
        },
        {
            "university": "Universidade Federal do Rio de Janeiro",
            "year": 2019,
            "rank_display": 361,
            "score": 30.3,
            "link": "https://www.topuniversities.com/universities/universidade-federal-do-rio-de-janeiro",
            "country": "Brazil",
            "city": "Rio de Janeiro",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-do-rio-de-janeiro_525_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 555,
            "size": "XL",
            "faculty_count": 3.983
        },
        {
            "university": "Taipei Medical University (TMU)",
            "year": 2019,
            "rank_display": 362,
            "score": 30.2,
            "link": "https://www.topuniversities.com/universities/taipei-medical-university-tmu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taipei-medical-university_2134_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 614,
            "size": "M",
            "faculty_count": 999
        },
        {
            "university": "Norwegian University of Science And Technology",
            "year": 2019,
            "rank_display": 363,
            "score": 30.1,
            "link": "https://www.topuniversities.com/universities/norwegian-university-science-technology",
            "country": "Norway",
            "city": "Trondheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/norwegian-university-of-science-and-technology_457_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.95,
            "size": "XL",
            "faculty_count": 3.132
        },
        {
            "university": "Oxford Brookes University",
            "year": 2019,
            "rank_display": 363,
            "score": 30.1,
            "link": "https://www.topuniversities.com/universities/oxford-brookes-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/oxford-brookes-university_592560cf2aeae70239af4c61_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 15,
            "international_students": 3.439,
            "size": "L",
            "faculty_count": 1471
        },
        {
            "university": "Graz University of Technology",
            "year": 2019,
            "rank_display": 363,
            "score": 30.1,
            "link": "https://www.topuniversities.com/universities/graz-university-technology",
            "country": "Austria",
            "city": "Graz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/graz-university-of-technology_1239_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1473,
            "size": "M",
            "faculty_count": 1427
        },
        {
            "university": "Tampere University of Technology",
            "year": 2019,
            "rank_display": 366,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/tampere-university-technology",
            "country": "Finland",
            "city": "Tampere",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tampere-university-of-technology_1269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "M",
            "faculty_count": 665
        },
        {
            "university": "Universidad Austral",
            "year": 2019,
            "rank_display": 367,
            "score": 29.9,
            "link": "https://www.topuniversities.com/universities/universidad-austral",
            "country": "Argentina",
            "city": "Pilar",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-austral_38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 334,
            "size": "S",
            "faculty_count": 663
        },
        {
            "university": "University of Kansas",
            "year": 2019,
            "rank_display": 367,
            "score": 29.9,
            "link": "https://www.topuniversities.com/universities/university-kansas",
            "country": "United States",
            "city": "Lawrence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kansas_309_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2.014,
            "size": "L",
            "faculty_count": 3.357
        },
        {
            "university": "James Cook University",
            "year": 2019,
            "rank_display": 369,
            "score": 29.7,
            "link": "https://www.topuniversities.com/universities/james-cook-university",
            "country": "Australia",
            "city": "Townsville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/james-cook-university_833_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.169,
            "size": "M",
            "faculty_count": 2940
        },
        {
            "university": "Pontificia Universidad Católica Argentina",
            "year": 2019,
            "rank_display": 369,
            "score": 29.7,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-argentina",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-argentina_592560cf2aeae70239af5486_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 4,
            "international_students": 1063,
            "size": "L",
            "faculty_count": 3805
        },
        {
            "university": "University of Tromsø The Arctic University of Norway",
            "year": 2019,
            "rank_display": 369,
            "score": 29.7,
            "link": "https://www.topuniversities.com/universities/university-tromso-arctic-university-norway",
            "country": "Norway",
            "city": "Tromsø",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-troms-the-arctic-university-of-norway_625_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.134,
            "size": "L",
            "faculty_count": 2.107
        },
        {
            "university": "Ruhr-Universität Bochum",
            "year": 2019,
            "rank_display": 372,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/ruhr-universitat-bochum",
            "country": "Germany",
            "city": "Bochum",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruhr-universitt-bochum_537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 35,
            "international_students": 6.564,
            "size": "XL",
            "faculty_count": 1.232
        },
        {
            "university": "National Research Tomsk Polytechnic University",
            "year": 2019,
            "rank_display": 373,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/national-research-tomsk-polytechnic-university",
            "country": "Russia",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-tomsk-polytechnic-university_14158_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.757,
            "size": "M",
            "faculty_count": 1.55
        },
        {
            "university": "University of Connecticut",
            "year": 2019,
            "rank_display": 373,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/university-connecticut",
            "country": "United States",
            "city": "Storrs",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-connecticut_140_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3.765,
            "size": "L",
            "faculty_count": 1.328
        },
        {
            "university": "University of St.Gallen (HSG)",
            "year": 2019,
            "rank_display": 375,
            "score": 29.3,
            "link": "https://www.topuniversities.com/universities/university-stgallen-hsg",
            "country": "Switzerland",
            "city": "St. Gallen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st.gallen-hsg_571_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 2.39,
            "size": "M",
            "faculty_count": 444
        },
        {
            "university": "American University of Sharjah",
            "year": 2019,
            "rank_display": 376,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/american-university-sharjah",
            "country": "United Arab Emirates",
            "city": "Sharjah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-sharjah_1201_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.199,
            "size": "S",
            "faculty_count": 371
        },
        {
            "university": "Boston College",
            "year": 2019,
            "rank_display": 376,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/boston-college",
            "country": "United States",
            "city": "Newton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-college_723_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.471,
            "size": "L",
            "faculty_count": 1.048
        },
        {
            "university": "University of Oulu",
            "year": 2019,
            "rank_display": 376,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/university-oulu",
            "country": "Finland",
            "city": "Oulu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oulu_476_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 579,
            "size": "M",
            "faculty_count": 883
        },
        {
            "university": "University of Southern Denmark (SDU)",
            "year": 2019,
            "rank_display": 376,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/university-southern-denmark-sdu",
            "country": "Denmark",
            "city": "Odense",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-denmark_592560cf2aeae70239af4c53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2511,
            "size": "L",
            "faculty_count": 1760
        },
        {
            "university": "Mahidol University",
            "year": 2019,
            "rank_display": 380,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/mahidol-university",
            "country": "Thailand",
            "city": "Nakhon Pathom",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/mahidol-university_381_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1415,
            "size": "L",
            "faculty_count": 3850
        },
        {
            "university": "Aston University",
            "year": 2019,
            "rank_display": 381,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/aston-university",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aston-university_29_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1.719,
            "size": "M",
            "faculty_count": 730
        },
        {
            "university": "Indian Institute of Technology Roorkee (IITR)",
            "year": 2019,
            "rank_display": 381,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-roorkee-iitr",
            "country": "India",
            "city": "Roorkee",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-roorkee-iitr_287_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 202,
            "size": "M",
            "faculty_count": 499
        },
        {
            "university": "University of Witwatersrand",
            "year": 2019,
            "rank_display": 381,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/university-witwatersrand",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-witwatersrand_679_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.885,
            "size": "L",
            "faculty_count": 1.626
        },
        {
            "university": "Universidad de Belgrano",
            "year": 2019,
            "rank_display": 384,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/universidad-de-belgrano",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-belgrano_51_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 758,
            "size": "M",
            "faculty_count": 810
        },
        {
            "university": "University of California, Riverside",
            "year": 2019,
            "rank_display": 384,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/university-california-riverside",
            "country": "United States",
            "city": "Riverside",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-riverside_88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.034,
            "size": "L",
            "faculty_count": 1.497
        },
        {
            "university": "University of the Philippines",
            "year": 2019,
            "rank_display": 384,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/university-philippines",
            "country": "Philippines",
            "city": "Quezon City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-philippines_498_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 292,
            "size": "XL",
            "faculty_count": 4.48
        },
        {
            "university": "HUFS - Hankuk (Korea) University of Foreign Studies",
            "year": 2019,
            "rank_display": 387,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/hufs-hankuk-korea-university-foreign-studies",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hufs-hankuk-korea-university-of-foreign-studies_1054_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.933,
            "size": "L",
            "faculty_count": 2.353
        },
        {
            "university": "Politecnico di Torino",
            "year": 2019,
            "rank_display": 387,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/politecnico-di-torino",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-torino_633_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 5.369,
            "size": "XL",
            "faculty_count": 1.05
        },
        {
            "university": "Swinburne University of Technology",
            "year": 2019,
            "rank_display": 387,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/swinburne-university-technology",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/swinburne-university-of-technology_759_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4000,
            "size": "L",
            "faculty_count": 946
        },
        {
            "university": "Wake Forest University",
            "year": 2019,
            "rank_display": 387,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/wake-forest-university",
            "country": "United States",
            "city": "Winston-Salem",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wake-forest-university_661_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 805,
            "size": "M",
            "faculty_count": 2.276
        },
        {
            "university": "Gadjah Mada University",
            "year": 2019,
            "rank_display": 391,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/gadjah-mada-university",
            "country": "Indonesia",
            "city": "Yogyakarta",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gadjah-mada-university_219_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 738,
            "size": "XL",
            "faculty_count": 4728
        },
        {
            "university": "Université de Liège",
            "year": 2019,
            "rank_display": 391,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/universite-de-liege",
            "country": "Belgium",
            "city": "Arlon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-lige_592560cf2aeae70239af4bd9_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.381,
            "size": "L",
            "faculty_count": 1.619
        },
        {
            "university": "Washington State University",
            "year": 2019,
            "rank_display": 391,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/washington-state-university",
            "country": "United States",
            "city": "Pullman",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-state-university_666_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.731,
            "size": "L",
            "faculty_count": 1.935
        },
        {
            "university": "L.N. Gumilyov Eurasian National University (ENU)",
            "year": 2019,
            "rank_display": 394,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/ln-gumilyov-eurasian-national-university-enu",
            "country": "Kazakhstan",
            "city": "Nur-Sultan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/l.n.-gumilyov-eurasian-national-university-enu_592560cf2aeae70239af52e8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1.077,
            "size": "L",
            "faculty_count": 3.35
        },
        {
            "university": "University of Warsaw",
            "year": 2019,
            "rank_display": 394,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/university-warsaw",
            "country": "Poland",
            "city": "Warsaw",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-warsaw_663_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2892,
            "size": "XL",
            "faculty_count": 3658
        },
        {
            "university": "Goldsmiths, University of London",
            "year": 2019,
            "rank_display": 396,
            "score": 28.3,
            "link": "https://www.topuniversities.com/universities/goldsmiths-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/goldsmiths-university-of-london_355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.492,
            "size": "M",
            "faculty_count": 683
        },
        {
            "university": "Chung-Ang University (CAU)",
            "year": 2019,
            "rank_display": 397,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/chung-ang-university-cau",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chung-ang-university-cau_1042_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3.099,
            "size": "L",
            "faculty_count": 2.65
        },
        {
            "university": "La Trobe University",
            "year": 2019,
            "rank_display": 397,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/la-trobe-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/la-trobe-university_592560cf2aeae70239af4bd1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 9.456,
            "size": "L",
            "faculty_count": 1.252
        },
        {
            "university": "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
            "year": 2019,
            "rank_display": 397,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/pakistan-institute-engineering-applied-sciences-pieas",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pakistan-institute-of-engineering-and-applied-sciences-pieas_592560cf2aeae70239af532f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "Universität Konstanz",
            "year": 2019,
            "rank_display": 397,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/universitat-konstanz",
            "country": "Germany",
            "city": "Konstanz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-konstanz_323_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.239,
            "size": "M",
            "faculty_count": 1.039
        },
        {
            "university": "University of Colorado, Denver",
            "year": 2019,
            "rank_display": 397,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/university-colorado-denver",
            "country": "United States",
            "city": "Denver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-denver_2116_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 1.274,
            "size": "L",
            "faculty_count": 5.319
        },
        {
            "university": "Université Laval",
            "year": 2019,
            "rank_display": 402,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/universite-laval",
            "country": "Canada",
            "city": "Québec",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/laval-university_337_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3.673,
            "size": "L",
            "faculty_count": 2.29
        },
        {
            "university": "National Sun Yat-sen University",
            "year": 2019,
            "rank_display": 402,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/national-sun-yat-sen-university",
            "country": "Taiwan",
            "city": "Kaohsiung City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-sun-yat-sen-university_439_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 757,
            "size": "M",
            "faculty_count": 735
        },
        {
            "university": "Peter the Great St. Petersburg Polytechnic University",
            "year": 2019,
            "rank_display": 404,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/peter-great-st-petersburg-polytechnic-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/peter-the-great-st.-petersburg-polytechnic-university_592560cf2aeae70239af52ed_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5.121,
            "size": "L",
            "faculty_count": 2.355
        },
        {
            "university": "Stellenbosch University",
            "year": 2019,
            "rank_display": 405,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/stellenbosch-university",
            "country": "South Africa",
            "city": "Stellenbosch",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/stellenbosch-university_574_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 1.776,
            "size": "L",
            "faculty_count": 934
        },
        {
            "university": "Universidade Nova de Lisboa",
            "year": 2019,
            "rank_display": 405,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/universidade-nova-de-lisboa",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-nova-de-lisboa_460_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.427,
            "size": "L",
            "faculty_count": 1.72
        },
        {
            "university": "Ben-Gurion University of The Negev",
            "year": 2019,
            "rank_display": 407,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/ben-gurion-university-negev",
            "country": "Israel",
            "city": "Be'er Sheva",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ben-gurion-university-of-the-negev_592560cf2aeae70239af4ab7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.755,
            "size": "L",
            "faculty_count": 1.06
        },
        {
            "university": "Johannes Gutenberg Universität Mainz",
            "year": 2019,
            "rank_display": 407,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/johannes-gutenberg-universitat-mainz",
            "country": "Germany",
            "city": "Mainz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-gutenberg-universitt-mainz_382_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.605,
            "size": "XL",
            "faculty_count": 3.473
        },
        {
            "university": "Universidad Externado de Colombia",
            "year": 2019,
            "rank_display": 407,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/universidad-externado-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-externado-de-colombia_795_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 12,
            "international_students": 142,
            "size": "M",
            "faculty_count": 1.019
        },
        {
            "university": "University of Coimbra",
            "year": 2019,
            "rank_display": 407,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/university-coimbra",
            "country": "Portugal",
            "city": "Coimbra",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-coimbra_592560cf2aeae70239af4b07_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.477,
            "size": "L",
            "faculty_count": 1.74
        },
        {
            "university": "Jagiellonian University",
            "year": 2019,
            "rank_display": 411,
            "score": 27.6,
            "link": "https://www.topuniversities.com/universities/jagiellonian-university",
            "country": "Poland",
            "city": "Krakow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/jagiellonian-university_300_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2907,
            "size": "XL",
            "faculty_count": 4089
        },
        {
            "university": "Tulane University",
            "year": 2019,
            "rank_display": 412,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/tulane-university",
            "country": "United States",
            "city": "New Orleans",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tulane-university_631_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.172,
            "size": "M",
            "faculty_count": 1.733
        },
        {
            "university": "Universidad de Zaragoza",
            "year": 2019,
            "rank_display": 412,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/universidad-de-zaragoza",
            "country": "Spain",
            "city": "Huesca",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-zaragoza_691_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.216,
            "size": "L",
            "faculty_count": 2.834
        },
        {
            "university": "Ural Federal University - UrFU",
            "year": 2019,
            "rank_display": 412,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/ural-federal-university-urfu",
            "country": "Russia",
            "city": "Ekaterinburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ural-federal-university-urfu_592560cf2aeae70239af505a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5.089,
            "size": "L",
            "faculty_count": 5.009
        },
        {
            "university": "Huazhong University of Science and Technology",
            "year": 2019,
            "rank_display": 415,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/huazhong-university-science-technology",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/huazhong-university-of-science-and-technology_592560cf2aeae70239af5256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.796,
            "size": "XL",
            "faculty_count": 4.014
        },
        {
            "university": "National Central University",
            "year": 2019,
            "rank_display": 415,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/national-central-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-central-university_434_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.098,
            "size": "M",
            "faculty_count": 985
        },
        {
            "university": "National University of Sciences And Technology (NUST) Islamabad",
            "year": 2019,
            "rank_display": 417,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/national-university-sciences-technology-nust-islamabad",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-sciences-and-technology-nust-islamabad_698_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 563,
            "size": "M",
            "faculty_count": 1.608
        },
        {
            "university": "University of Stirling",
            "year": 2019,
            "rank_display": 417,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/university-stirling",
            "country": "United Kingdom",
            "city": "Stirling",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-stirling_575_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.563,
            "size": "M",
            "faculty_count": 728
        },
        {
            "university": "The University of Tennessee, Knoxville",
            "year": 2019,
            "rank_display": 419,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/university-tennessee-knoxville",
            "country": "United States",
            "city": "Knoxville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tennessee-knoxville_603_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 17,
            "international_students": 1156,
            "size": "XL",
            "faculty_count": 1700
        },
        {
            "university": "The American University in Cairo",
            "year": 2019,
            "rank_display": 420,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/american-university-cairo",
            "country": "Egypt",
            "city": "Cairo",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/the-american-university-in-cairo_36_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 213,
            "size": "M",
            "faculty_count": 578
        },
        {
            "university": "Universidad de Palermo (UP)",
            "year": 2019,
            "rank_display": 420,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/universidad-de-palermo",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-palermo-up_592560cf2aeae70239af54de_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.049,
            "size": "M",
            "faculty_count": 833
        },
        {
            "university": "Dublin City University",
            "year": 2019,
            "rank_display": 422,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/dublin-city-university",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/dublin-city-university_592560cf2aeae70239af4b27_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.415,
            "size": "L",
            "faculty_count": 1.257
        },
        {
            "university": "Shanghai University",
            "year": 2019,
            "rank_display": 422,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/shanghai-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-university_557_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.195,
            "size": "XL",
            "faculty_count": 4.156
        },
        {
            "university": "University of Pisa",
            "year": 2019,
            "rank_display": 422,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/university-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pisa_499_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 1.507,
            "size": "XL",
            "faculty_count": 1.796
        },
        {
            "university": "Université de Montpellier",
            "year": 2019,
            "rank_display": 422,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/universite-de-montpellier",
            "country": "France",
            "city": "Montpellier",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montpellier_592560e69988f300e2321d99_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6.229,
            "size": "XL",
            "faculty_count": 2.464
        },
        {
            "university": "Illinois Institute of Technology",
            "year": 2019,
            "rank_display": 426,
            "score": 26.9,
            "link": "https://www.topuniversities.com/universities/illinois-institute-technology",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/illinois-institute-of-technology_277_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.33,
            "size": "M",
            "faculty_count": 402
        },
        {
            "university": "University of Trento",
            "year": 2019,
            "rank_display": 426,
            "score": 26.9,
            "link": "https://www.topuniversities.com/universities/university-trento",
            "country": "Italy",
            "city": "Trento",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-trento_623_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 738,
            "size": "L",
            "faculty_count": 1.066
        },
        {
            "university": "University of Texas Dallas",
            "year": 2019,
            "rank_display": 426,
            "score": 26.9,
            "link": "https://www.topuniversities.com/universities/university-texas-dallas",
            "country": "United States",
            "city": "Richardson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-dallas_592560cf2aeae70239af5355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 4.828,
            "size": "L",
            "faculty_count": 1.14
        },
        {
            "university": "Brandeis University",
            "year": 2019,
            "rank_display": 429,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/brandeis-university",
            "country": "United States",
            "city": "Waltham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brandeis-university_66_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.554,
            "size": "M",
            "faculty_count": 611
        },
        {
            "university": "Chang Gung University",
            "year": 2019,
            "rank_display": 429,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/chang-gung-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chang-gung-university_1187_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 381,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "The University of Georgia",
            "year": 2019,
            "rank_display": 431,
            "score": 26.7,
            "link": "https://www.topuniversities.com/universities/university-georgia",
            "country": "United States",
            "city": "Athens",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-georgia_227_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.879,
            "size": "XL",
            "faculty_count": 2.406
        },
        {
            "university": "Aberystwyth University",
            "year": 2019,
            "rank_display": 432,
            "score": 26.5,
            "link": "https://www.topuniversities.com/universities/aberystwyth-university",
            "country": "United Kingdom",
            "city": "Aberystwyth",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aberystwyth-university_8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.392,
            "size": "M",
            "faculty_count": 516
        },
        {
            "university": "Dongguk University",
            "year": 2019,
            "rank_display": 432,
            "score": 26.5,
            "link": "https://www.topuniversities.com/universities/dongguk-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/dongguk-university_1048_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.27,
            "size": "L",
            "faculty_count": 2.668
        },
        {
            "university": "Sharif University of Technology",
            "year": 2019,
            "rank_display": 432,
            "score": 26.5,
            "link": "https://www.topuniversities.com/universities/sharif-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sharif-university-of-technology_592560cf2aeae70239af4d42_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 198,
            "size": "M",
            "faculty_count": 495
        },
        {
            "university": "Bangor University",
            "year": 2019,
            "rank_display": 435,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/bangor-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bangor-university_42_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.196,
            "size": "M",
            "faculty_count": 740
        },
        {
            "university": "Sogang University",
            "year": 2019,
            "rank_display": 435,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/sogang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sogang-university_565_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.429,
            "size": "M",
            "faculty_count": 1.017
        },
        {
            "university": "Swansea University",
            "year": 2019,
            "rank_display": 435,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/swansea-university",
            "country": "United Kingdom",
            "city": "Swansea",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/swansea-university_592560cf2aeae70239af4cce_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 3.641,
            "size": "L",
            "faculty_count": 3290
        },
        {
            "university": "University of Iowa",
            "year": 2019,
            "rank_display": 435,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/university-iowa",
            "country": "United States",
            "city": "Iowa City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-iowa_592560cf2aeae70239af4baa_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.049,
            "size": "L",
            "faculty_count": 2.924
        },
        {
            "university": "Kazan (Volga region) Federal University",
            "year": 2019,
            "rank_display": 439,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/kazan-volga-region-federal-university",
            "country": "Russia",
            "city": "Kazan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kazan-volga-region-federal-university_313_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 8.375,
            "size": "L",
            "faculty_count": 3.847
        },
        {
            "university": "University of Delaware",
            "year": 2019,
            "rank_display": 439,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/university-delaware",
            "country": "United States",
            "city": "Newark",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-delaware_154_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.337,
            "size": "L",
            "faculty_count": 1.272
        },
        {
            "university": "Wayne State University",
            "year": 2019,
            "rank_display": 439,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/wayne-state-university",
            "country": "United States",
            "city": "Detroit",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wayne-state-university_592560cf2aeae70239af51d1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.278,
            "size": "L",
            "faculty_count": 2.699
        },
        {
            "university": "Universiti Teknologi Brunei",
            "year": 2019,
            "rank_display": 442,
            "score": 26.1,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-brunei",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-brunei_592560e49988f300e23219b2_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 139
        },
        {
            "university": "Bond University",
            "year": 2019,
            "rank_display": 443,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/bond-university",
            "country": "Australia",
            "city": "Gold Coast",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/bond-university_715_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1.302,
            "size": "S",
            "faculty_count": 325
        },
        {
            "university": "Tianjin University",
            "year": 2019,
            "rank_display": 443,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/tianjin-university",
            "country": "China (Mainland)",
            "city": "Nankai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tianjin-university_609_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.425,
            "size": "L",
            "faculty_count": 3.147
        },
        {
            "university": "University of Macau",
            "year": 2019,
            "rank_display": 443,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/university-macau",
            "country": "Macau SAR",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-macau_14378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.921,
            "size": "M",
            "faculty_count": 650
        },
        {
            "university": "National Technical University of Athens",
            "year": 2019,
            "rank_display": 446,
            "score": 25.9,
            "link": "https://www.topuniversities.com/universities/national-technical-university-athens",
            "country": "Greece",
            "city": "Athens",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-technical-university-of-athens_592560cf2aeae70239af4aa3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 846,
            "size": "L",
            "faculty_count": 689
        },
        {
            "university": "RUDN University",
            "year": 2019,
            "rank_display": 446,
            "score": 25.9,
            "link": "https://www.topuniversities.com/universities/rudn-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rudn-university_1503_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 8319,
            "size": "L",
            "faculty_count": 3890
        },
        {
            "university": "King Khalid University",
            "year": 2019,
            "rank_display": 448,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/king-khalid-university",
            "country": "Saudi Arabia",
            "city": "Abha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-khalid-university_1174_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.171,
            "size": "XL",
            "faculty_count": 3.544
        },
        {
            "university": "Koç University",
            "year": 2019,
            "rank_display": 448,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/koc-university",
            "country": "Turkey",
            "city": "Istanbul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ko-university_592560cf2aeae70239af4d7e_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 368,
            "size": "M",
            "faculty_count": 560
        },
        {
            "university": "Colorado State University",
            "year": 2019,
            "rank_display": 450,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/colorado-state-university",
            "country": "United States",
            "city": "Fort Collins",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/colorado-state-university_136_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.547,
            "size": "L",
            "faculty_count": 3.115
        },
        {
            "university": "Oregon State University",
            "year": 2019,
            "rank_display": 450,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/oregon-state-university",
            "country": "United States",
            "city": "Corvallis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/oregon-state-university_592560cf2aeae70239af4dc3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.769,
            "size": "L",
            "faculty_count": 2.147
        },
        {
            "university": "Sultan Qaboos University",
            "year": 2019,
            "rank_display": 450,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/sultan-qaboos-university",
            "country": "Oman",
            "city": "Muscat",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sultan-qaboos-university_2530_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 248,
            "size": "M",
            "faculty_count": 916
        },
        {
            "university": "Christian-Albrechts-University zu Kiel",
            "year": 2019,
            "rank_display": 453,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/christian-albrechts-university-zu-kiel",
            "country": "Germany",
            "city": "Kiel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/christian-albrechts-university-zu-kiel_318_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.161,
            "size": "L",
            "faculty_count": 2.889
        },
        {
            "university": "Aix-Marseille University",
            "year": 2019,
            "rank_display": 453,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/aix-marseille-university",
            "country": "France",
            "city": "Marseille",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aix-marseille-university_592560cf2aeae70239af51e1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8.651,
            "size": "XL",
            "faculty_count": 4.069
        },
        {
            "university": "University of Maryland, Baltimore County",
            "year": 2019,
            "rank_display": 453,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/university-maryland-baltimore-county",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-baltimore-county_592560cf2aeae70239af52af_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 1.074,
            "size": "M",
            "faculty_count": 834
        },
        {
            "university": "Bilkent University",
            "year": 2019,
            "rank_display": 456,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/bilkent-university",
            "country": "Turkey",
            "city": "Ankara",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bilkent-university_58_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 790,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "Hitotsubashi University",
            "year": 2019,
            "rank_display": 456,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/hitotsubashi-university",
            "country": "Japan",
            "city": "Kunitachi City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hitotsubashi-university-business-school-school-of-international-corporate-strategy_592560cf2aeae70239af4b8d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 802,
            "size": "M",
            "faculty_count": 571
        },
        {
            "university": "Johannes Kepler University Linz",
            "year": 2019,
            "rank_display": 456,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/johannes-kepler-university-linz",
            "country": "Austria",
            "city": "Linz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-kepler-university-linz_349_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 774,
            "size": "M",
            "faculty_count": 897
        },
        {
            "university": "The Catholic University of Korea",
            "year": 2019,
            "rank_display": 456,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/catholic-university-korea",
            "country": "South Korea",
            "city": "Gyeonggi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-catholic-university-of-korea-_592560cf2aeae70239af4ed7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 370,
            "size": "M",
            "faculty_count": 1.668
        },
        {
            "university": "Yokohama City University",
            "year": 2019,
            "rank_display": 460,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/yokohama-city-university",
            "country": "Japan",
            "city": "Yokohama City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yokohama-city-university_592560cf2aeae70239af4d32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 108,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Clark University",
            "year": 2019,
            "rank_display": 461,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/clark-university",
            "country": "United States",
            "city": "Worcester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/clark-university_820_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 853,
            "size": "S",
            "faculty_count": 276
        },
        {
            "university": "Universität des Saarlandes",
            "year": 2019,
            "rank_display": 461,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/universitat-des-saarlandes",
            "country": "Germany",
            "city": "Saarbrücken",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-des-saarlandes_541_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 2.939,
            "size": "L",
            "faculty_count": 2.823
        },
        {
            "university": "University of Saskatchewan",
            "year": 2019,
            "rank_display": 461,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/university-saskatchewan",
            "country": "Canada",
            "city": "Saskatoon",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-saskatchewan_1755_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.382,
            "size": "L",
            "faculty_count": 1.415
        },
        {
            "university": "Auckland University of Technology (AUT)",
            "year": 2019,
            "rank_display": 464,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/auckland-university-technology-aut",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/auckland-university-of-technology-aut_592560cf2aeae70239af4ef7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8.926,
            "size": "L",
            "faculty_count": 1255
        },
        {
            "university": "Beijing Institute of Technology",
            "year": 2019,
            "rank_display": 464,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/beijing-institute-technology",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-institute-of-technology_868_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.115,
            "size": "XL",
            "faculty_count": 2.811
        },
        {
            "university": "Chiba University",
            "year": 2019,
            "rank_display": 464,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/chiba-university",
            "country": "Japan",
            "city": "Chiba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chiba-university_119_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 736,
            "size": "L",
            "faculty_count": 1.79
        },
        {
            "university": "Concordia University",
            "year": 2019,
            "rank_display": 464,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/concordia-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/concordia-university_592560cf2aeae70239af4b0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 11.717,
            "size": "XL",
            "faculty_count": 2282
        },
        {
            "university": "Satbayev University",
            "year": 2019,
            "rank_display": 464,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/satbayev-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kazakh-national-research-technical-university-after-k.i.satpayev_592560cf2aeae70239af52e7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 131,
            "size": "M",
            "faculty_count": 1.281
        },
        {
            "university": "Universidade Federal de São Paulo",
            "year": 2019,
            "rank_display": 464,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/universidade-federal-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-de-so-paulo_2750_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 153,
            "size": "L",
            "faculty_count": 2.451
        },
        {
            "university": "Universidad Politécnica de Madrid (UPM)",
            "year": 2019,
            "rank_display": 470,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/universidad-politecnica-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politcnica-de-madrid_1507_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5.319,
            "size": "XL",
            "faculty_count": 2.486
        },
        {
            "university": "University of Szeged",
            "year": 2019,
            "rank_display": 470,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/university-szeged",
            "country": "Hungary",
            "city": "Szeged",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-szeged_592560cf2aeae70239af4cd1_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 4.285,
            "size": "L",
            "faculty_count": 1.965
        },
        {
            "university": "Florida State University",
            "year": 2019,
            "rank_display": 472,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/florida-state-university",
            "country": "United States",
            "city": "Tallahassee",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/florida-state-university_211_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 1.618,
            "size": "XL",
            "faculty_count": 2.236
        },
        {
            "university": "Indian Institute of Technology Guwahati (IITG)",
            "year": 2019,
            "rank_display": 472,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-guwahati-iitg",
            "country": "India",
            "city": "Guwahati",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-guwahati-iitg_283_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 42,
            "size": "M",
            "faculty_count": 410
        },
        {
            "university": "University of Naples - Federico II",
            "year": 2019,
            "rank_display": 472,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/university-naples-federico-ii",
            "country": "Italy",
            "city": "Naples",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-naples-federico-ii_432_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 128,
            "size": "XL",
            "faculty_count": 2.299
        },
        {
            "university": "Jilin University",
            "year": 2019,
            "rank_display": 475,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/jilin-university",
            "country": "China (Mainland)",
            "city": "Changchun",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/jilin-university_304_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1.57,
            "size": "XL",
            "faculty_count": 7.078
        },
        {
            "university": "The National University of Science and Technology MISIS",
            "year": 2019,
            "rank_display": 476,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/national-university-science-technology-misis",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-national-university-of-science-and-technology-misis_14956_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.605,
            "size": "M",
            "faculty_count": 915
        },
        {
            "university": "Xiamen University",
            "year": 2019,
            "rank_display": 476,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/xiamen-university",
            "country": "China (Mainland)",
            "city": "Xiamen",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xiamen-university_891_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.443,
            "size": "XL",
            "faculty_count": 3.106
        },
        {
            "university": "Flinders University",
            "year": 2019,
            "rank_display": 478,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/flinders-university",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/flinders-university_592560cf2aeae70239af4b53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 5.981,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "Institut National des Sciences Appliquées de Lyon (INSA)",
            "year": 2019,
            "rank_display": 478,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/institut-national-des-sciences-appliquees-de-lyon-insa",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/institut-national-des-sciences-appliques-de-lyon-insa_2461_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1.795,
            "size": "M",
            "faculty_count": 625
        },
        {
            "university": "Auezov South Kazakhstan University (SKU)",
            "year": 2019,
            "rank_display": 480,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/auezov-south-kazakhstan-university-sku",
            "country": "Kazakhstan",
            "city": "Shymkent",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/auezov-south-kazakhstan-state-university-sksu_592560cf2aeae70239af52eb_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.22,
            "size": "L",
            "faculty_count": 2.902
        },
        {
            "university": "Abai Kazakh National Pedagogical University",
            "year": 2019,
            "rank_display": 481,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/abai-kazakh-national-pedagogical-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/abai-kazakh-national-pedagogical-university_592560cf2aeae70239af55d7_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 7,
            "international_students": 333,
            "size": "L",
            "faculty_count": 1.994
        },
        {
            "university": "UCSI University",
            "year": 2019,
            "rank_display": 481,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/ucsi-university",
            "country": "Malaysia",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ucsi-university_592560e09988f300e2320ce5_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1.597,
            "size": "M",
            "faculty_count": 744
        },
        {
            "university": "Universidad de Alcalá",
            "year": 2019,
            "rank_display": 481,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/universidad-de-alcala",
            "country": "Spain",
            "city": "Alcalá de Henares",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-alcal_1511_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 4.56,
            "size": "L",
            "faculty_count": 2.694
        },
        {
            "university": "University of Eastern Finland",
            "year": 2019,
            "rank_display": 481,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/university-eastern-finland",
            "country": "Finland",
            "city": "Kuopio",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-eastern-finland_592560cf2aeae70239af4bcb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 669,
            "size": "M",
            "faculty_count": 1.207
        },
        {
            "university": "V. N. Karazin Kharkiv National University",
            "year": 2019,
            "rank_display": 481,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/v-n-karazin-kharkiv-national-university",
            "country": "Ukraine",
            "city": "Kharkiv",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/v.-n.-karazin-kharkiv-national-university_19511_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3.954,
            "size": "L",
            "faculty_count": 2.091
        },
        {
            "university": "York University",
            "year": 2019,
            "rank_display": 481,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/york-university",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/york-university_592560cf2aeae70239af4d39_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 12.285,
            "size": "XL",
            "faculty_count": 1.508
        },
        {
            "university": "University of Delhi",
            "year": 2019,
            "rank_display": 487,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/university-delhi",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-delhi_156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 322,
            "size": "L",
            "faculty_count": 1.171
        },
        {
            "university": "Vilnius University",
            "year": 2019,
            "rank_display": 488,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/vilnius-university",
            "country": "Lithuania",
            "city": "Vilnius",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vilnius-university-_592560cf2aeae70239af4d7f_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.29,
            "size": "L",
            "faculty_count": 2.461
        },
        {
            "university": "Iowa State University",
            "year": 2019,
            "rank_display": 489,
            "score": 24,
            "link": "https://www.topuniversities.com/universities/iowa-state-university",
            "country": "United States",
            "city": "Ames",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/iowa-state-university_294_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.441,
            "size": "L",
            "faculty_count": 1.656
        },
        {
            "university": "University of Bordeaux",
            "year": 2019,
            "rank_display": 489,
            "score": 24,
            "link": "https://www.topuniversities.com/universities/university-bordeaux",
            "country": "France",
            "city": "Talence",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bordeaux_1286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5.643,
            "size": "XL",
            "faculty_count": 3.195
        },
        {
            "university": "Beihang University (former BUAA)",
            "year": 2019,
            "rank_display": 491,
            "score": 23.9,
            "link": "https://www.topuniversities.com/universities/beihang-university-former-buaa",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beihang-university-former-buaa_870_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.575,
            "size": "XL",
            "faculty_count": 2.529
        },
        {
            "university": "UNESP",
            "year": 2019,
            "rank_display": 491,
            "score": 23.9,
            "link": "https://www.topuniversities.com/universities/unesp",
            "country": "Brazil",
            "city": "SÃ£o Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/unesp_492_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.491,
            "size": "XL",
            "faculty_count": 2.794
        },
        {
            "university": "Università Cattolica del Sacro Cuore",
            "year": 2019,
            "rank_display": 491,
            "score": "",
            "link": "https://www.topuniversities.com/universities/universita-cattolica-del-sacro-cuore",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitcattolica-del-sacro-cuore_406_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1849,
            "size": "L",
            "faculty_count": 3944
        },
        {
            "university": "University of New Mexico",
            "year": 2019,
            "rank_display": 494,
            "score": 23.8,
            "link": "https://www.topuniversities.com/universities/university-new-mexico",
            "country": "United States",
            "city": "Albuquerque",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-new-mexico_592560cf2aeae70239af4c41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 870,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "University of Granada",
            "year": 2019,
            "rank_display": 495,
            "score": 23.7,
            "link": "https://www.topuniversities.com/universities/university-granada",
            "country": "Spain",
            "city": "Granada",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-granada_235_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5.611,
            "size": "XL",
            "faculty_count": 2.682
        },
        {
            "university": "University of Hohenheim",
            "year": 2019,
            "rank_display": 495,
            "score": 23.7,
            "link": "https://www.topuniversities.com/universities/university-hohenheim",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hohenheim_592560cf2aeae70239af4fcd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1.219,
            "size": "M",
            "faculty_count": 401
        },
        {
            "university": "University of Oklahoma",
            "year": 2019,
            "rank_display": 495,
            "score": 23.7,
            "link": "https://www.topuniversities.com/universities/university-oklahoma",
            "country": "United States",
            "city": "Norman",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oklahoma_467_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.418,
            "size": "L",
            "faculty_count": 1.709
        },
        {
            "university": "Amirkabir University of Technology",
            "year": 2019,
            "rank_display": 498,
            "score": 23.6,
            "link": "https://www.topuniversities.com/universities/amirkabir-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/amirkabir-university-of-technology_592560cf2aeae70239af4e54_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 235,
            "size": "M",
            "faculty_count": 525
        },
        {
            "university": "Western Sydney University",
            "year": 2019,
            "rank_display": 498,
            "score": 23.6,
            "link": "https://www.topuniversities.com/universities/western-sydney-university",
            "country": "Australia",
            "city": "Milperra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/western-sydney-university_848_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 22,
            "international_students": 7.123,
            "size": "XL",
            "faculty_count": 1.62
        },
        {
            "university": "Singapore Management University",
            "year": 2019,
            "rank_display": 500,
            "score": 23.5,
            "link": "https://www.topuniversities.com/universities/singapore-management-university",
            "country": "Singapore",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/singapore-management-university_1177_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.04,
            "size": "M",
            "faculty_count": 590
        },
        {
            "university": "Technische Universität Braunschweig",
            "year": 2019,
            "rank_display": 500,
            "score": 23.5,
            "link": "https://www.topuniversities.com/universities/technische-universitat-braunschweig",
            "country": "Germany",
            "city": "Braunschweig",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-braunschweig_596_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.941,
            "size": "L",
            "faculty_count": 2.398
        },
        {
            "university": "Saint Joseph University of Beirut (USJ)",
            "year": 2019,
            "rank_display": 500,
            "score": 23.5,
            "link": "https://www.topuniversities.com/universities/saint-joseph-university-beirut-usj",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-joseph-university-of-beirut-usj_1130_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 9,
            "international_students": 955,
            "size": "M",
            "faculty_count": 941
        },
        {
            "university": "University of Science and Technology Beijing",
            "year": 2019,
            "rank_display": 500,
            "score": 23.5,
            "link": "https://www.topuniversities.com/universities/university-science-technology-beijing",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-beijing_2004_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 818,
            "size": "L",
            "faculty_count": 1.605
        },
        {
            "university": "Massachusetts Institute of Technology (MIT)",
            "year": 2020,
            "rank_display": 1,
            "score": 100,
            "link": "https://www.topuniversities.com/universities/massachusetts-institute-technology-mit",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/massachusetts-institute-of-technology-mit_410_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 3730,
            "size": "M",
            "faculty_count": 3065
        },
        {
            "university": "Stanford University",
            "year": 2020,
            "rank_display": 2,
            "score": 98.4,
            "link": "https://www.topuniversities.com/universities/stanford-university",
            "country": "United States",
            "city": "Stanford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stanford-university_573_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3879,
            "size": "L",
            "faculty_count": 4725
        },
        {
            "university": "Harvard University",
            "year": 2020,
            "rank_display": 3,
            "score": 97.4,
            "link": "https://www.topuniversities.com/universities/harvard-university",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/harvard-university_253_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5877,
            "size": "L",
            "faculty_count": 4646
        },
        {
            "university": "University of Oxford",
            "year": 2020,
            "rank_display": 4,
            "score": 97.2,
            "link": "https://www.topuniversities.com/universities/university-oxford",
            "country": "United Kingdom",
            "city": "Oxford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oxford_478_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 8442,
            "size": "L",
            "faculty_count": 6708
        },
        {
            "university": "California Institute of Technology (Caltech)",
            "year": 2020,
            "rank_display": 5,
            "score": 96.9,
            "link": "https://www.topuniversities.com/universities/california-institute-technology-caltech",
            "country": "United States",
            "city": "Pasadena",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/california-institute-of-technology-caltech_94_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 692,
            "size": "S",
            "faculty_count": 968
        },
        {
            "university": "ETH Zurich - Swiss Federal Institute of Technology",
            "year": 2020,
            "rank_display": 6,
            "score": 95.9,
            "link": "https://www.topuniversities.com/universities/eth-zurich-swiss-federal-institute-technology",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eth-zurich-swiss-federal-institute-of-technology_201_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7733,
            "size": "L",
            "faculty_count": 2719
        },
        {
            "university": "University of Cambridge",
            "year": 2020,
            "rank_display": 7,
            "score": 95,
            "link": "https://www.topuniversities.com/universities/university-cambridge",
            "country": "United Kingdom",
            "city": "Cambridge",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cambridge_95_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 4,
            "international_students": 7925,
            "size": "L",
            "faculty_count": 5800
        },
        {
            "university": "UCL",
            "year": 2020,
            "rank_display": 8,
            "score": 94.8,
            "link": "https://www.topuniversities.com/universities/ucl",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ucl_592560cf2aeae70239af4bf1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 21824,
            "size": "XL",
            "faculty_count": 7195
        },
        {
            "university": "Imperial College London",
            "year": 2020,
            "rank_display": 9,
            "score": 94.1,
            "link": "https://www.topuniversities.com/universities/imperial-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/imperial-college-london_592560cf2aeae70239af4be8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11143,
            "size": "L",
            "faculty_count": 8000
        },
        {
            "university": "University of Chicago",
            "year": 2020,
            "rank_display": 10,
            "score": 92,
            "link": "https://www.topuniversities.com/universities/university-chicago",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chicago_120_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4696,
            "size": "L",
            "faculty_count": 2703
        },
        {
            "university": "Nanyang Technological University, Singapore (NTU)",
            "year": 2020,
            "rank_display": 11,
            "score": 91.8,
            "link": "https://www.topuniversities.com/universities/nanyang-technological-university-singapore-ntu",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanyang-technological-university-singapore-ntu_592560cf2aeae70239af4c32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6091,
            "size": "L",
            "faculty_count": 3812
        },
        {
            "university": "National University of Singapore (NUS)",
            "year": 2020,
            "rank_display": 11,
            "score": 91.8,
            "link": "https://www.topuniversities.com/universities/national-university-singapore-nus",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-singapore-nus_443_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7551,
            "size": "XL",
            "faculty_count": 4288
        },
        {
            "university": "Princeton University",
            "year": 2020,
            "rank_display": 13,
            "score": 90.9,
            "link": "https://www.topuniversities.com/universities/princeton-university",
            "country": "United States",
            "city": "Princeton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/princeton-university_508_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1912,
            "size": "M",
            "faculty_count": 1050
        },
        {
            "university": "Cornell University",
            "year": 2020,
            "rank_display": 14,
            "score": 89.3,
            "link": "https://www.topuniversities.com/universities/cornell-university",
            "country": "United States",
            "city": "Ithaca",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/cornell-university_143_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "University of Pennsylvania",
            "year": 2020,
            "rank_display": 15,
            "score": 88.9,
            "link": "https://www.topuniversities.com/universities/university-pennsylvania",
            "country": "United States",
            "city": "Philadelphia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pennsylvania_495_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4636,
            "size": "L",
            "faculty_count": 5154
        },
        {
            "university": "Tsinghua University",
            "year": 2020,
            "rank_display": 16,
            "score": 88.6,
            "link": "https://www.topuniversities.com/universities/tsinghua-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tsinghua-university_626_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5420,
            "size": "XL",
            "faculty_count": 6174
        },
        {
            "university": "Yale University",
            "year": 2020,
            "rank_display": 17,
            "score": 87.7,
            "link": "https://www.topuniversities.com/universities/yale-university",
            "country": "United States",
            "city": "New Haven",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/yale-university_684_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 2537,
            "size": "M",
            "faculty_count": 5391
        },
        {
            "university": "Columbia University",
            "year": 2020,
            "rank_display": 18,
            "score": 87.4,
            "link": "https://www.topuniversities.com/universities/columbia-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/columbia-university_138_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 11252,
            "size": "L",
            "faculty_count": 7087
        },
        {
            "university": "EPFL",
            "year": 2020,
            "rank_display": 18,
            "score": 87.4,
            "link": "https://www.topuniversities.com/universities/epfl",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/epfl-ecole-polytechnique-federale-de-lausanne_592560cf2aeae70239af4b34_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6426,
            "size": "M",
            "faculty_count": 1767
        },
        {
            "university": "The University of Edinburgh",
            "year": 2020,
            "rank_display": 20,
            "score": 86.2,
            "link": "https://www.topuniversities.com/universities/university-edinburgh",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-edinburgh_180_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 14637,
            "size": "XL",
            "faculty_count": 4832
        },
        {
            "university": "University of Michigan-Ann Arbor",
            "year": 2020,
            "rank_display": 21,
            "score": 86,
            "link": "https://www.topuniversities.com/universities/university-michigan-ann-arbor",
            "country": "United States",
            "city": "Ann Arbor",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-michigan_403_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 7341,
            "size": "XL",
            "faculty_count": 7132
        },
        {
            "university": "Peking University",
            "year": 2020,
            "rank_display": 22,
            "score": 84.3,
            "link": "https://www.topuniversities.com/universities/peking-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/peking-university_50_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5436,
            "size": "XL",
            "faculty_count": 5302
        },
        {
            "university": "The University of Tokyo",
            "year": 2020,
            "rank_display": 22,
            "score": 84.3,
            "link": "https://www.topuniversities.com/universities/university-tokyo",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tokyo_615_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3983,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Johns Hopkins University",
            "year": 2020,
            "rank_display": 24,
            "score": 83.9,
            "link": "https://www.topuniversities.com/universities/johns-hopkins-university",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/johns-hopkins-university_305_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 5070,
            "size": "L",
            "faculty_count": 4855
        },
        {
            "university": "Duke University",
            "year": 2020,
            "rank_display": 25,
            "score": 83.8,
            "link": "https://www.topuniversities.com/universities/duke-university",
            "country": "United States",
            "city": "Durham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/duke-university_168_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3445,
            "size": "L",
            "faculty_count": 5673
        },
        {
            "university": "The University of Hong Kong",
            "year": 2020,
            "rank_display": 25,
            "score": 83.8,
            "link": "https://www.topuniversities.com/universities/university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-hong-kong_268_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 8311,
            "size": "L",
            "faculty_count": 2944
        },
        {
            "university": "The University of Manchester",
            "year": 2020,
            "rank_display": 27,
            "score": 82.7,
            "link": "https://www.topuniversities.com/universities/university-manchester",
            "country": "United Kingdom",
            "city": "Manchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-manchester_389_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 16079,
            "size": "XL",
            "faculty_count": 4889
        },
        {
            "university": "University of California, Berkeley (UCB)",
            "year": 2020,
            "rank_display": 28,
            "score": 82.6,
            "link": "https://www.topuniversities.com/universities/university-california-berkeley-ucb",
            "country": "United States",
            "city": "Berkeley",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-berkeley-ucb_84_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 9428,
            "size": "XL",
            "faculty_count": 3701
        },
        {
            "university": "The Australian National University",
            "year": 2020,
            "rank_display": 29,
            "score": 82.1,
            "link": "https://www.topuniversities.com/universities/australian-national-university",
            "country": "Australia",
            "city": "Canberra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-australian-national-university_40_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6496,
            "size": "L",
            "faculty_count": 1763
        },
        {
            "university": "University of Toronto",
            "year": 2020,
            "rank_display": 29,
            "score": 82.1,
            "link": "https://www.topuniversities.com/universities/university-toronto",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-toronto_619_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 8,
            "international_students": 27536,
            "size": "XL",
            "faculty_count": 9881
        },
        {
            "university": "Northwestern University",
            "year": 2020,
            "rank_display": 31,
            "score": 81.5,
            "link": "https://www.topuniversities.com/universities/northwestern-university",
            "country": "United States",
            "city": "Evanston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northwestern-university_592560cf2aeae70239af4c50_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3846,
            "size": "L",
            "faculty_count": 3652
        },
        {
            "university": "The Hong Kong University of Science and Technology",
            "year": 2020,
            "rank_display": 32,
            "score": 80.6,
            "link": "https://www.topuniversities.com/universities/hong-kong-university-science-technology",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-university-of-science-and-technology_269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3033,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "King's College London",
            "year": 2020,
            "rank_display": 33,
            "score": 80.5,
            "link": "https://www.topuniversities.com/universities/kings-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kings-college-london_357_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 15075,
            "size": "L",
            "faculty_count": 4216
        },
        {
            "university": "Kyoto University",
            "year": 2020,
            "rank_display": 33,
            "score": 80.5,
            "link": "https://www.topuniversities.com/universities/kyoto-university",
            "country": "Japan",
            "city": "Kyoto",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyoto-university_328_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2863,
            "size": "L",
            "faculty_count": 3911
        },
        {
            "university": "McGill University",
            "year": 2020,
            "rank_display": 35,
            "score": 80.4,
            "link": "https://www.topuniversities.com/universities/mcgill-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcgill-university_592560cf2aeae70239af4c10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 10827,
            "size": "L",
            "faculty_count": 3876
        },
        {
            "university": "University of California, Los Angeles (UCLA)",
            "year": 2020,
            "rank_display": 35,
            "score": 80.4,
            "link": "https://www.topuniversities.com/universities/university-california-los-angeles-ucla",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-los-angeles-ucla_87_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6140,
            "size": "XL",
            "faculty_count": 4487
        },
        {
            "university": "Seoul National University",
            "year": 2020,
            "rank_display": 37,
            "score": 79.6,
            "link": "https://www.topuniversities.com/universities/seoul-national-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/graduate-school-of-business-seoul-national-university_553_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1914,
            "size": "L",
            "faculty_count": 3859
        },
        {
            "university": "The University of Melbourne",
            "year": 2020,
            "rank_display": 38,
            "score": 79.5,
            "link": "https://www.topuniversities.com/universities/university-melbourne",
            "country": "Australia",
            "city": "Parkville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-melbourne_399_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 19,
            "international_students": 21406,
            "size": "S",
            "faculty_count": 4835
        },
        {
            "university": "New York University (NYU)",
            "year": 2020,
            "rank_display": 39,
            "score": 78.8,
            "link": "https://www.topuniversities.com/universities/new-york-university-nyu",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/new-york-university-nyu_448_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 16218,
            "size": "XL",
            "faculty_count": 7874
        },
        {
            "university": "Fudan University",
            "year": 2020,
            "rank_display": 40,
            "score": 78.6,
            "link": "https://www.topuniversities.com/universities/fudan-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/fudan-university_218_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5322,
            "size": "L",
            "faculty_count": 4526
        },
        {
            "university": "KAIST - Korea Advanced Institute of Science & Technology",
            "year": 2020,
            "rank_display": 41,
            "score": 77.9,
            "link": "https://www.topuniversities.com/universities/kaist-korea-advanced-institute-science-technology",
            "country": "South Korea",
            "city": "Daejeon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kaist-korea-advanced-institute-of-science-technology_324_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 869,
            "size": "M",
            "faculty_count": 1307
        },
        {
            "university": "The University of Sydney",
            "year": 2020,
            "rank_display": 42,
            "score": 77.8,
            "link": "https://www.topuniversities.com/universities/university-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sydney_592560cf2aeae70239af4cd0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 25940,
            "size": "XL",
            "faculty_count": 3571
        },
        {
            "university": "The University of New South Wales (UNSW Sydney)",
            "year": 2020,
            "rank_display": 43,
            "score": 77.1,
            "link": "https://www.topuniversities.com/universities/university-new-south-wales-unsw-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-new-south-wales-unsw-sydney_447_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 21603,
            "size": "XL",
            "faculty_count": 3219
        },
        {
            "university": "The London School of Economics and Political Science (LSE)",
            "year": 2020,
            "rank_display": 44,
            "score": 77,
            "link": "https://www.topuniversities.com/universities/london-school-economics-political-science-lse",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/london-school-of-economics-and-political-science-lse_362_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8274,
            "size": "M",
            "faculty_count": 1172
        },
        {
            "university": "University of California, San Diego (UCSD)",
            "year": 2020,
            "rank_display": 45,
            "score": 76.6,
            "link": "https://www.topuniversities.com/universities/university-california-san-diego-ucsd",
            "country": "United States",
            "city": "San Diego",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-san-diego-ucsd_89_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 8567,
            "size": "XL",
            "faculty_count": 4545
        },
        {
            "university": "The Chinese University of Hong Kong (CUHK)",
            "year": 2020,
            "rank_display": 46,
            "score": 75.9,
            "link": "https://www.topuniversities.com/universities/chinese-university-hong-kong-cuhk",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-chinese-university-of-hong-kong-cuhk_123_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6062,
            "size": "L",
            "faculty_count": 2201
        },
        {
            "university": "The University of Queensland",
            "year": 2020,
            "rank_display": 47,
            "score": 75.7,
            "link": "https://www.topuniversities.com/universities/university-queensland",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-queensland_515_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 22241,
            "size": "XL",
            "faculty_count": 3513
        },
        {
            "university": "Carnegie Mellon University",
            "year": 2020,
            "rank_display": 48,
            "score": 74.8,
            "link": "https://www.topuniversities.com/universities/carnegie-mellon-university",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/carnegie-mellon-university_101_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5739,
            "size": "L",
            "faculty_count": 1534
        },
        {
            "university": "University of Bristol",
            "year": 2020,
            "rank_display": 49,
            "score": 74.5,
            "link": "https://www.topuniversities.com/universities/university-bristol",
            "country": "United Kingdom",
            "city": "Bristol",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bristol_69_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 7483,
            "size": "L",
            "faculty_count": 20311
        },
        {
            "university": "Delft University of Technology",
            "year": 2020,
            "rank_display": 50,
            "score": 74.2,
            "link": "https://www.topuniversities.com/universities/delft-university-technology",
            "country": "Netherlands",
            "city": "Delft",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/delft-university-of-technology_155_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6104,
            "size": "L",
            "faculty_count": 2041
        },
        {
            "university": "University of British Columbia",
            "year": 2020,
            "rank_display": 51,
            "score": 74.1,
            "link": "https://www.topuniversities.com/universities/university-british-columbia",
            "country": "Canada",
            "city": "Vancouver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-british-columbia_70_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 15399,
            "size": "XL",
            "faculty_count": 6201
        },
        {
            "university": "City University of Hong Kong",
            "year": 2020,
            "rank_display": 52,
            "score": 73.6,
            "link": "https://www.topuniversities.com/universities/city-university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-hong-kong_592560cf2aeae70239af4b05_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5331,
            "size": "M",
            "faculty_count": 1483
        },
        {
            "university": "Université PSL",
            "year": 2020,
            "rank_display": 53,
            "score": 73.4,
            "link": "https://www.topuniversities.com/universities/universite-psl",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-psl_592560e69988f300e2321dfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4922,
            "size": "L",
            "faculty_count": 3914
        },
        {
            "university": "Zhejiang University",
            "year": 2020,
            "rank_display": 54,
            "score": 72.4,
            "link": "https://www.topuniversities.com/universities/zhejiang-university",
            "country": "China (Mainland)",
            "city": "Hangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/zhejiang-university_592560cf2aeae70239af4d37_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 9177,
            "size": "XL",
            "faculty_count": 6065
        },
        {
            "university": "Technical University of Munich",
            "year": 2020,
            "rank_display": 55,
            "score": 72.3,
            "link": "https://www.topuniversities.com/universities/technical-university-munich",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-munich_599_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11447,
            "size": "XL",
            "faculty_count": 6663
        },
        {
            "university": "University of Wisconsin-Madison",
            "year": 2020,
            "rank_display": 56,
            "score": 71.8,
            "link": "https://www.topuniversities.com/universities/university-wisconsin-madison",
            "country": "United States",
            "city": "Madison",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wisconsin-madison_678_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5737,
            "size": "XL",
            "faculty_count": 5166
        },
        {
            "university": "Brown University",
            "year": 2020,
            "rank_display": 57,
            "score": 71.5,
            "link": "https://www.topuniversities.com/universities/brown-university",
            "country": "United States",
            "city": "Providence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brown-university_72_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2002,
            "size": "M",
            "faculty_count": 1389
        },
        {
            "university": "Monash University",
            "year": 2020,
            "rank_display": 58,
            "score": 70.9,
            "link": "https://www.topuniversities.com/universities/monash-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/monash-university_412_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 31049,
            "size": "XL",
            "faculty_count": 3852
        },
        {
            "university": "Tokyo Institute of Technology (Tokyo Tech)",
            "year": 2020,
            "rank_display": 58,
            "score": 70.9,
            "link": "https://www.topuniversities.com/universities/tokyo-institute-technology-tokyo-tech",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-institute-of-technology_592560cf2aeae70239af4ceb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1622,
            "size": "M",
            "faculty_count": 1492
        },
        {
            "university": "Ecole Polytechnique",
            "year": 2020,
            "rank_display": 60,
            "score": 69.9,
            "link": "https://www.topuniversities.com/universities/ecole-polytechnique",
            "country": "France",
            "city": "Palaiseau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-polytechnique_592560cf2aeae70239af4b36_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1248,
            "size": "S",
            "faculty_count": 633
        },
        {
            "university": "Shanghai Jiao Tong University",
            "year": 2020,
            "rank_display": 60,
            "score": 70.5,
            "link": "https://www.topuniversities.com/universities/shanghai-jiao-tong-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-jiao-tong-university_556_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6371,
            "size": "XL",
            "faculty_count": 4386
        },
        {
            "university": "The University of Warwick",
            "year": 2020,
            "rank_display": 62,
            "score": 70.4,
            "link": "https://www.topuniversities.com/universities/university-warwick",
            "country": "United Kingdom",
            "city": "Coventry",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-warwick_664_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10459,
            "size": "L",
            "faculty_count": 2634
        },
        {
            "university": "Ludwig-Maximilians-Universität München",
            "year": 2020,
            "rank_display": 63,
            "score": 69.6,
            "link": "https://www.topuniversities.com/universities/ludwig-maximilians-universitat-munchen",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ludwig-maximilians-universitt-mnchen_420_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5958,
            "size": "XL",
            "faculty_count": 3914
        },
        {
            "university": "University of Amsterdam",
            "year": 2020,
            "rank_display": 64,
            "score": 69,
            "link": "https://www.topuniversities.com/universities/university-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-amsterdam_18_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7969,
            "size": "XL",
            "faculty_count": 6000
        },
        {
            "university": "University of Texas at Austin",
            "year": 2020,
            "rank_display": 65,
            "score": 68.6,
            "link": "https://www.topuniversities.com/universities/university-texas-austin",
            "country": "United States",
            "city": "Austin",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-at-austin_604_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 4953,
            "size": "XL",
            "faculty_count": 2887
        },
        {
            "university": "Ruprecht-Karls-Universität Heidelberg",
            "year": 2020,
            "rank_display": 66,
            "score": 68.4,
            "link": "https://www.topuniversities.com/universities/ruprecht-karls-universitat-heidelberg",
            "country": "Germany",
            "city": "Heidelberg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruprecht-karls-universitaet-heidelberg_259_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4048,
            "size": "L",
            "faculty_count": 4100
        },
        {
            "university": "University of Glasgow",
            "year": 2020,
            "rank_display": 67,
            "score": 68.2,
            "link": "https://www.topuniversities.com/universities/university-glasgow",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-glasgow_592560cf2aeae70239af4b6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 11065,
            "size": "L",
            "faculty_count": 3314
        },
        {
            "university": "University of Washington",
            "year": 2020,
            "rank_display": 68,
            "score": 67.9,
            "link": "https://www.topuniversities.com/universities/university-washington",
            "country": "United States",
            "city": "Seattle",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-washington_592560cf2aeae70239af4d1e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 7362,
            "size": "XL",
            "faculty_count": 2796
        },
        {
            "university": "National Taiwan University (NTU)",
            "year": 2020,
            "rank_display": 69,
            "score": 67.3,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-ntu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-ntu_592560cf2aeae70239af4c3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5058,
            "size": "L",
            "faculty_count": 2939
        },
        {
            "university": "Universiti Malaya (UM)",
            "year": 2020,
            "rank_display": 70,
            "score": 67.1,
            "link": "https://www.topuniversities.com/universities/universiti-malaya-um",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-malaya-um_383_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3340,
            "size": "L",
            "faculty_count": 2386
        },
        {
            "university": "Osaka University",
            "year": 2020,
            "rank_display": 71,
            "score": 66.5,
            "link": "https://www.topuniversities.com/universities/osaka-university",
            "country": "Japan",
            "city": "Osaka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/osaka-university_472_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2268,
            "size": "L",
            "faculty_count": 3127
        },
        {
            "university": "Georgia Institute of Technology",
            "year": 2020,
            "rank_display": 72,
            "score": 66.2,
            "link": "https://www.topuniversities.com/universities/georgia-institute-technology",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgia-institute-of-technology_225_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 1267
        },
        {
            "university": "Universidad de Buenos Aires (UBA)",
            "year": 2020,
            "rank_display": 74,
            "score": 66,
            "link": "https://www.topuniversities.com/universities/universidad-de-buenos-aires-uba",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-buenos-aires-uba_78_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 29516,
            "size": "XL",
            "faculty_count": 16103
        },
        {
            "university": "University of Illinois at Urbana-Champaign",
            "year": 2020,
            "rank_display": 75,
            "score": 65.9,
            "link": "https://www.topuniversities.com/universities/university-illinois-urbana-champaign",
            "country": "United States",
            "city": "Champaign",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-at-urbana-champaign_2090_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 9987,
            "size": "XL",
            "faculty_count": 2748
        },
        {
            "university": "University of Zurich",
            "year": 2020,
            "rank_display": 76,
            "score": 65.8,
            "link": "https://www.topuniversities.com/universities/university-zurich",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-zurich_693_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5044,
            "size": "L",
            "faculty_count": 3761
        },
        {
            "university": "Sorbonne University",
            "year": 2020,
            "rank_display": 77,
            "score": 64.9,
            "link": "https://www.topuniversities.com/universities/sorbonne-university",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sorbonne-university_5a6afcfecb4de709397114fe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "Durham University",
            "year": 2020,
            "rank_display": 78,
            "score": 64.2,
            "link": "https://www.topuniversities.com/universities/durham-university",
            "country": "United Kingdom",
            "city": "Durham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/durham-university_170_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6174,
            "size": "L",
            "faculty_count": 1775
        },
        {
            "university": "The University of Sheffield",
            "year": 2020,
            "rank_display": 78,
            "score": 64.2,
            "link": "https://www.topuniversities.com/universities/university-sheffield",
            "country": "United Kingdom",
            "city": "Sheffield",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sheffield_592560cf2aeae70239af4cb1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 11090,
            "size": "L",
            "faculty_count": 3463
        },
        {
            "university": "KU Leuven",
            "year": 2020,
            "rank_display": 80,
            "score": 64.1,
            "link": "https://www.topuniversities.com/universities/ku-leuven",
            "country": "Belgium",
            "city": "Leuven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ku-leuven_592560cf2aeae70239af4aec_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 8485,
            "size": "XL",
            "faculty_count": 2677
        },
        {
            "university": "University of Copenhagen",
            "year": 2020,
            "rank_display": 81,
            "score": 63.8,
            "link": "https://www.topuniversities.com/universities/university-copenhagen",
            "country": "Denmark",
            "city": "Copenhagen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-copenhagen_141_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4135,
            "size": "XL",
            "faculty_count": 7293
        },
        {
            "university": "University of Birmingham",
            "year": 2020,
            "rank_display": 81,
            "score": 64,
            "link": "https://www.topuniversities.com/universities/university-birmingham",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-birmingham_59_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10457,
            "size": "XL",
            "faculty_count": 3586
        },
        {
            "university": "Tohoku University",
            "year": 2020,
            "rank_display": 82,
            "score": 63.7,
            "link": "https://www.topuniversities.com/universities/tohoku-university",
            "country": "Japan",
            "city": "Sendai City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tohoku-university_611_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1872,
            "size": "L",
            "faculty_count": 3323
        },
        {
            "university": "Korea University",
            "year": 2020,
            "rank_display": 83,
            "score": 63.4,
            "link": "https://www.topuniversities.com/universities/korea-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/korea-university_325_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3325,
            "size": "L",
            "faculty_count": 4026
        },
        {
            "university": "The University of Auckland",
            "year": 2020,
            "rank_display": 83,
            "score": 63.4,
            "link": "https://www.topuniversities.com/universities/university-auckland",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-auckland_592560cf2aeae70239af4aa8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9924,
            "size": "L",
            "faculty_count": 2316
        },
        {
            "university": "Lomonosov Moscow State University",
            "year": 2020,
            "rank_display": 84,
            "score": 63.2,
            "link": "https://www.topuniversities.com/universities/lomonosov-moscow-state-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lomonosov-moscow-state-university-_592560cf2aeae70239af4c27_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5476,
            "size": "XL",
            "faculty_count": 6575
        },
        {
            "university": "Rice University",
            "year": 2020,
            "rank_display": 85,
            "score": 63.1,
            "link": "https://www.topuniversities.com/universities/rice-university",
            "country": "United States",
            "city": "Houston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rice-university_524_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2278,
            "size": "M",
            "faculty_count": 996
        },
        {
            "university": "The University of Western Australia",
            "year": 2020,
            "rank_display": 86,
            "score": 62.9,
            "link": "https://www.topuniversities.com/universities/university-western-australia",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-australia_673_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9017,
            "size": "L",
            "faculty_count": 1539
        },
        {
            "university": "Pohang University of Science And Technology (POSTECH)",
            "year": 2020,
            "rank_display": 87,
            "score": 62.6,
            "link": "https://www.topuniversities.com/universities/pohang-university-science-technology-postech",
            "country": "South Korea",
            "city": "Pohang",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pohang-university-of-science-and-technology-postech_592560cf2aeae70239af4c7b_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 87,
            "size": "S",
            "faculty_count": 743
        },
        {
            "university": "University of Science and Technology of China",
            "year": 2020,
            "rank_display": 89,
            "score": 62.3,
            "link": "https://www.topuniversities.com/universities/university-science-technology-china",
            "country": "China (Mainland)",
            "city": "Hefei",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-of-china_122_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1019,
            "size": "L",
            "faculty_count": 2708
        },
        {
            "university": "University of North Carolina, Chapel Hill",
            "year": 2020,
            "rank_display": 90,
            "score": 62.2,
            "link": "https://www.topuniversities.com/universities/university-north-carolina-chapel-hill",
            "country": "United States",
            "city": "Chapel Hill",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-north-carolina-chapel-hill_424_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2749,
            "size": "XL",
            "faculty_count": 4646
        },
        {
            "university": "The Hong Kong Polytechnic University",
            "year": 2020,
            "rank_display": 91,
            "score": 61.9,
            "link": "https://www.topuniversities.com/universities/hong-kong-polytechnic-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-polytechnic-university_267_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5774,
            "size": "L",
            "faculty_count": 2822
        },
        {
            "university": "Lund University",
            "year": 2020,
            "rank_display": 92,
            "score": 61.8,
            "link": "https://www.topuniversities.com/universities/lund-university",
            "country": "Sweden",
            "city": "Lund",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lund-university_371_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6682,
            "size": "L",
            "faculty_count": 3040
        },
        {
            "university": "Pennsylvania State University",
            "year": 2020,
            "rank_display": 93,
            "score": 61.7,
            "link": "https://www.topuniversities.com/universities/pennsylvania-state-university",
            "country": "United States",
            "city": "University Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/pennsylvania-state-university_494_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6943,
            "size": "XL",
            "faculty_count": 4207
        },
        {
            "university": "University of Leeds",
            "year": 2020,
            "rank_display": 93,
            "score": 61.7,
            "link": "https://www.topuniversities.com/universities/university-leeds",
            "country": "United Kingdom",
            "city": "Leeds",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leeds_338_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 11584,
            "size": "XL",
            "faculty_count": 3593
        },
        {
            "university": "Sungkyunkwan University(SKKU)",
            "year": 2020,
            "rank_display": 95,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/sungkyunkwan-universityskku",
            "country": "South Korea",
            "city": "Suwon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sungkyunkwan-universityskku_592560cf2aeae70239af4cc9_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3821,
            "size": "L",
            "faculty_count": 3313
        },
        {
            "university": "University of Nottingham",
            "year": 2020,
            "rank_display": 96,
            "score": 60.8,
            "link": "https://www.topuniversities.com/universities/university-nottingham",
            "country": "United Kingdom",
            "city": "Nottingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-nottingham_592560cf2aeae70239af4c4e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9659,
            "size": "XL",
            "faculty_count": 3386
        },
        {
            "university": "University of Southampton",
            "year": 2020,
            "rank_display": 97,
            "score": 60.6,
            "link": "https://www.topuniversities.com/universities/university-southampton",
            "country": "United Kingdom",
            "city": "Southampton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southampton_567_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8417,
            "size": "L",
            "faculty_count": 2468
        },
        {
            "university": "Boston University",
            "year": 2020,
            "rank_display": 98,
            "score": 60.5,
            "link": "https://www.topuniversities.com/universities/boston-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-university_592560cf2aeae70239af4ac2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8159,
            "size": "L",
            "faculty_count": 2642
        },
        {
            "university": "KTH Royal Institute of Technology",
            "year": 2020,
            "rank_display": 98,
            "score": 60.5,
            "link": "https://www.topuniversities.com/universities/kth-royal-institute-technology",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kth-royal-institute-of-technology-_592560cf2aeae70239af4c9b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3057,
            "size": "L",
            "faculty_count": 3600
        },
        {
            "university": "University of St Andrews",
            "year": 2020,
            "rank_display": 100,
            "score": 59.9,
            "link": "https://www.topuniversities.com/universities/university-st-andrews",
            "country": "United Kingdom",
            "city": "St. Andrews",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st-andrews_570_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4377,
            "size": "M",
            "faculty_count": 1208
        },
        {
            "university": "The Ohio State University",
            "year": 2020,
            "rank_display": 101,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/ohio-state-university",
            "country": "United States",
            "city": "Columbus",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-ohio-state-university_465_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7029,
            "size": "XL",
            "faculty_count": 6388
        },
        {
            "university": "Eindhoven University of Technology",
            "year": 2020,
            "rank_display": 102,
            "score": 58.9,
            "link": "https://www.topuniversities.com/universities/eindhoven-university-technology",
            "country": "Netherlands",
            "city": "Eindhoven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eindhoven-university-of-technology_592560cf2aeae70239af4b3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1872,
            "size": "M",
            "faculty_count": 1145
        },
        {
            "university": "Universidad Nacional Autónoma de México  (UNAM)",
            "year": 2020,
            "rank_display": 103,
            "score": 58.8,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-autonoma-de-mexico-unam",
            "country": "Mexico",
            "city": "Mexico City",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-autnoma-de-mxico-unam_425_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5110,
            "size": "XL",
            "faculty_count": 16381
        },
        {
            "university": "University of California, Davis",
            "year": 2020,
            "rank_display": 104,
            "score": 58.7,
            "link": "https://www.topuniversities.com/universities/university-california-davis",
            "country": "United States",
            "city": "Davis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-davis_592560cf2aeae70239af4ad8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8414,
            "size": "XL",
            "faculty_count": 3420
        },
        {
            "university": "Yonsei University",
            "year": 2020,
            "rank_display": 104,
            "score": 58.7,
            "link": "https://www.topuniversities.com/universities/yonsei-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yonsei-university_688_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4274,
            "size": "L",
            "faculty_count": 3605
        },
        {
            "university": "The University of Adelaide",
            "year": 2020,
            "rank_display": 106,
            "score": 58.6,
            "link": "https://www.topuniversities.com/universities/university-adelaide",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-adelaide_10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10107,
            "size": "L",
            "faculty_count": 1757
        },
        {
            "university": "University of Helsinki",
            "year": 2020,
            "rank_display": 107,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/university-helsinki",
            "country": "Finland",
            "city": "Helsinki",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-helsinki_260_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1254,
            "size": "L",
            "faculty_count": 2855
        },
        {
            "university": "Trinity College Dublin, The University of Dublin",
            "year": 2020,
            "rank_display": 108,
            "score": 58.3,
            "link": "https://www.topuniversities.com/universities/trinity-college-dublin-university-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/trinity-college-dublin-the-university-of-dublin_167_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5593,
            "size": "L",
            "faculty_count": 1383
        },
        {
            "university": "Washington University in St. Louis",
            "year": 2020,
            "rank_display": 108,
            "score": 58.3,
            "link": "https://www.topuniversities.com/universities/washington-university-st-louis",
            "country": "United States",
            "city": "St. Louis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-university-in-st.-louis_668_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3535,
            "size": "L",
            "faculty_count": 3193
        },
        {
            "university": "University of Geneva",
            "year": 2020,
            "rank_display": 110,
            "score": 58.1,
            "link": "https://www.topuniversities.com/universities/university-geneva",
            "country": "Switzerland",
            "city": "Geneva",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-geneva_221_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6547,
            "size": "L",
            "faculty_count": 1814
        },
        {
            "university": "Purdue University",
            "year": 2020,
            "rank_display": 111,
            "score": 57.8,
            "link": "https://www.topuniversities.com/universities/purdue-university",
            "country": "United States",
            "city": "West Lafayette",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/purdue-university_510_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 8034,
            "size": "XL",
            "faculty_count": 2622
        },
        {
            "university": "Technical University of Denmark",
            "year": 2020,
            "rank_display": 112,
            "score": 57.4,
            "link": "https://www.topuniversities.com/universities/technical-university-denmark",
            "country": "Denmark",
            "city": "Kongens Lyngby",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-denmark_592560cf2aeae70239af4cd8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2495,
            "size": "M",
            "faculty_count": 2219
        },
        {
            "university": "University of Alberta",
            "year": 2020,
            "rank_display": 113,
            "score": 57.3,
            "link": "https://www.topuniversities.com/universities/university-alberta",
            "country": "Canada",
            "city": "Edmonton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-alberta_15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9689,
            "size": "XL",
            "faculty_count": 3645
        },
        {
            "university": "University of Groningen",
            "year": 2020,
            "rank_display": 114,
            "score": 56.1,
            "link": "https://www.topuniversities.com/universities/university-groningen",
            "country": "Netherlands",
            "city": "Groningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-groningen_243_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 9397,
            "size": "XL",
            "faculty_count": 3724
        },
        {
            "university": "Nagoya University",
            "year": 2020,
            "rank_display": 115,
            "score": 55.8,
            "link": "https://www.topuniversities.com/universities/nagoya-university",
            "country": "Japan",
            "city": "Nagoya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nagoya-university_427_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1879,
            "size": "L",
            "faculty_count": 2512
        },
        {
            "university": "Universidade de São Paulo",
            "year": 2020,
            "rank_display": 116,
            "score": 55.5,
            "link": "https://www.topuniversities.com/universities/universidade-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-de-so-paulo_550_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1858,
            "size": "XL",
            "faculty_count": 4943
        },
        {
            "university": "Uppsala University",
            "year": 2020,
            "rank_display": 116,
            "score": 55.5,
            "link": "https://www.topuniversities.com/universities/uppsala-university",
            "country": "Sweden",
            "city": "Uppsala",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/uppsala-university_643_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8401,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Leiden University",
            "year": 2020,
            "rank_display": 118,
            "score": 55.4,
            "link": "https://www.topuniversities.com/universities/leiden-university",
            "country": "Netherlands",
            "city": "Leiden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leiden-university_340_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6064,
            "size": "XL",
            "faculty_count": 2069
        },
        {
            "university": "University of Oslo",
            "year": 2020,
            "rank_display": 119,
            "score": 54.7,
            "link": "https://www.topuniversities.com/universities/university-oslo",
            "country": "Norway",
            "city": "Oslo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oslo_473_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2560,
            "size": "L",
            "faculty_count": 2600
        },
        {
            "university": "Humboldt-Universität zu Berlin",
            "year": 2020,
            "rank_display": 120,
            "score": 54.5,
            "link": "https://www.topuniversities.com/universities/humboldt-universitat-zu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/humboldt-universitt-zu-berlin_272_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5239,
            "size": "XL",
            "faculty_count": 1967
        },
        {
            "university": "Nanjing University",
            "year": 2020,
            "rank_display": 120,
            "score": 54.5,
            "link": "https://www.topuniversities.com/universities/nanjing-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanjing-university_430_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2513,
            "size": "XL",
            "faculty_count": 3040
        },
        {
            "university": "Utrecht University",
            "year": 2020,
            "rank_display": 120,
            "score": 54.5,
            "link": "https://www.topuniversities.com/universities/utrecht-university",
            "country": "Netherlands",
            "city": "Utrecht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/utrecht-university_646_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4454,
            "size": "XL",
            "faculty_count": 3254
        },
        {
            "university": "University of Bern",
            "year": 2020,
            "rank_display": 123,
            "score": 54.3,
            "link": "https://www.topuniversities.com/universities/university-bern",
            "country": "Switzerland",
            "city": "Bern",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bern_55_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1776,
            "size": "M",
            "faculty_count": 1212
        },
        {
            "university": "KIT, Karlsruhe Institute of Technology",
            "year": 2020,
            "rank_display": 124,
            "score": 54.2,
            "link": "https://www.topuniversities.com/universities/kit-karlsruhe-institute-technology",
            "country": "Germany",
            "city": "Karlsruhe",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kit-karlsruhe-institute-of-technology_310_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5030,
            "size": "L",
            "faculty_count": 2080
        },
        {
            "university": "Chalmers University of Technology",
            "year": 2020,
            "rank_display": 125,
            "score": 54,
            "link": "https://www.topuniversities.com/universities/chalmers-university-technology",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/chalmers-university-of-technology_592560cf2aeae70239af4af7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2502,
            "size": "M",
            "faculty_count": 2500
        },
        {
            "university": "Wageningen University & Research",
            "year": 2020,
            "rank_display": 125,
            "score": 54,
            "link": "https://www.topuniversities.com/universities/wageningen-university-research",
            "country": "Netherlands",
            "city": "Wageningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/wageningen-university_659_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4131,
            "size": "L",
            "faculty_count": 2767
        },
        {
            "university": "Queen Mary University of London",
            "year": 2020,
            "rank_display": 126,
            "score": 53.9,
            "link": "https://www.topuniversities.com/universities/queen-mary-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queen-mary-university-of-london_592560cf2aeae70239af4bea_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8625,
            "size": "L",
            "faculty_count": 2119
        },
        {
            "university": "Pontificia Universidad Católica de Chile (UC)",
            "year": 2020,
            "rank_display": 127,
            "score": 53.4,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-cat%C3%B3lica-de-chile-uc",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-de-chile-uc_107_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1072,
            "size": "L",
            "faculty_count": 2343
        },
        {
            "university": "Lancaster University",
            "year": 2020,
            "rank_display": 128,
            "score": 53.2,
            "link": "https://www.topuniversities.com/universities/lancaster-university",
            "country": "United Kingdom",
            "city": "Lancaster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lancaster-university_335_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5557,
            "size": "L",
            "faculty_count": 1440
        },
        {
            "university": "University of Southern California",
            "year": 2020,
            "rank_display": 129,
            "score": 53.1,
            "link": "https://www.topuniversities.com/universities/university-southern-california",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-california_569_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9107,
            "size": "XL",
            "faculty_count": 3116
        },
        {
            "university": "Freie Universitaet Berlin",
            "year": 2020,
            "rank_display": 130,
            "score": 53,
            "link": "https://www.topuniversities.com/universities/freie-universitaet-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/freie-universitaet-berlin_215_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 6659,
            "size": "XL",
            "faculty_count": 1389
        },
        {
            "university": "Ghent University",
            "year": 2020,
            "rank_display": 130,
            "score": 53,
            "link": "https://www.topuniversities.com/universities/ghent-university",
            "country": "Belgium",
            "city": "Ghent",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ghent-university_228_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4609,
            "size": "XL",
            "faculty_count": 5537
        },
        {
            "university": "Hokkaido University",
            "year": 2020,
            "rank_display": 132,
            "score": 52.9,
            "link": "https://www.topuniversities.com/universities/hokkaido-university",
            "country": "Japan",
            "city": "Sapporo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hokkaido-university_266_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1984,
            "size": "L",
            "faculty_count": 2476
        },
        {
            "university": "Kyushu University",
            "year": 2020,
            "rank_display": 132,
            "score": 52.9,
            "link": "https://www.topuniversities.com/universities/kyushu-university",
            "country": "Japan",
            "city": "Fukuoka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyushu-university_331_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2125,
            "size": "L",
            "faculty_count": 2761
        },
        {
            "university": "Aalto University",
            "year": 2020,
            "rank_display": 134,
            "score": 52.4,
            "link": "https://www.topuniversities.com/universities/aalto-university",
            "country": "Finland",
            "city": "Espoo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalto-university_261_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2202,
            "size": "L",
            "faculty_count": 1396
        },
        {
            "university": "University of California, Santa Barbara (UCSB)",
            "year": 2020,
            "rank_display": 135,
            "score": 52.3,
            "link": "https://www.topuniversities.com/universities/university-california-santa-barbara-ucsb",
            "country": "United States",
            "city": "Santa Barbara",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-barbara-ucsb_91_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 4309,
            "size": "L",
            "faculty_count": 1597
        },
        {
            "university": "University of Maryland, College Park",
            "year": 2020,
            "rank_display": 136,
            "score": 52.1,
            "link": "https://www.topuniversities.com/universities/university-maryland-college-park",
            "country": "United States",
            "city": "College Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-college-park_393_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4659,
            "size": "XL",
            "faculty_count": 3798
        },
        {
            "university": "Université de Montréal",
            "year": 2020,
            "rank_display": 137,
            "score": 52,
            "link": "https://www.topuniversities.com/universities/universite-de-montreal",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montral_417_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7625,
            "size": "XL",
            "faculty_count": 5533
        },
        {
            "university": "RWTH Aachen University",
            "year": 2020,
            "rank_display": 138,
            "score": 51.9,
            "link": "https://www.topuniversities.com/universities/rwth-aachen-university",
            "country": "Germany",
            "city": "Aachen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rwth-aachen-university_4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 12477,
            "size": "XL",
            "faculty_count": 2431
        },
        {
            "university": "CentraleSupélec",
            "year": 2020,
            "rank_display": 139,
            "score": 52.3,
            "link": "https://www.topuniversities.com/universities/centralesupelec",
            "country": "France",
            "city": "Saint-Aubin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/centralesuplec_173_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": "",
            "international_students": 1068,
            "size": "S",
            "faculty_count": 552
        },
        {
            "university": "McMaster University",
            "year": 2020,
            "rank_display": 140,
            "score": 51.2,
            "link": "https://www.topuniversities.com/universities/mcmaster-university",
            "country": "Canada",
            "city": "Hamilton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcmaster-university_397_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5386,
            "size": "L",
            "faculty_count": 3819
        },
        {
            "university": "University of Pittsburgh",
            "year": 2020,
            "rank_display": 140,
            "score": 51.2,
            "link": "https://www.topuniversities.com/universities/university-pittsburgh",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pittsburgh_500_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2590,
            "size": "L",
            "faculty_count": 4746
        },
        {
            "university": "University of Technology Sydney",
            "year": 2020,
            "rank_display": 140,
            "score": 51.2,
            "link": "https://www.topuniversities.com/universities/university-technology-sydney",
            "country": "Australia",
            "city": "Haymarket",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-technology-sydney_592560cf2aeae70239af4ccf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8653,
            "size": "L",
            "faculty_count": 2183
        },
        {
            "university": "Michigan State University",
            "year": 2020,
            "rank_display": 144,
            "score": 51.1,
            "link": "https://www.topuniversities.com/universities/michigan-state-university",
            "country": "United States",
            "city": "East Lansing",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/michigan-state-university_402_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5795,
            "size": "XL",
            "faculty_count": 3008
        },
        {
            "university": "Aarhus University",
            "year": 2020,
            "rank_display": 145,
            "score": 51,
            "link": "https://www.topuniversities.com/universities/aarhus-university",
            "country": "Denmark",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aarhus-university_592560cf2aeae70239af4a8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2911,
            "size": "L",
            "faculty_count": 683
        },
        {
            "university": "Newcastle University",
            "year": 2020,
            "rank_display": 146,
            "score": 50.9,
            "link": "https://www.topuniversities.com/universities/newcastle-university",
            "country": "United Kingdom",
            "city": "Newcastle upon Tyne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/newcastle-university_592560cf2aeae70239af4c45_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8100,
            "size": "L",
            "faculty_count": 2766
        },
        {
            "university": "Technische Universität Berlin (TU Berlin)",
            "year": 2020,
            "rank_display": 147,
            "score": 50.6,
            "link": "https://www.topuniversities.com/universities/technische-universitat-berlin-tu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-berlin-tu-berlin_595_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6464,
            "size": "L",
            "faculty_count": 2331
        },
        {
            "university": "University of York",
            "year": 2020,
            "rank_display": 148,
            "score": 50.5,
            "link": "https://www.topuniversities.com/universities/university-york",
            "country": "United Kingdom",
            "city": "York",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-york_689_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5098,
            "size": "L",
            "faculty_count": 2209
        },
        {
            "university": "Politecnico di Milano",
            "year": 2020,
            "rank_display": 149,
            "score": 50.4,
            "link": "https://www.topuniversities.com/universities/politecnico-di-milano",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-milano_592560cf2aeae70239af4c1c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7452,
            "size": "XL",
            "faculty_count": 1659
        },
        {
            "university": "Hanyang University",
            "year": 2020,
            "rank_display": 150,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/hanyang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hanyang-university_250_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3455,
            "size": "L",
            "faculty_count": 3164
        },
        {
            "university": "University of Basel",
            "year": 2020,
            "rank_display": 151,
            "score": 50,
            "link": "https://www.topuniversities.com/universities/university-basel",
            "country": "Switzerland",
            "city": "Basel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-basel_46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2875,
            "size": "M",
            "faculty_count": 1036
        },
        {
            "university": "Indian Institute of Technology Bombay (IITB)",
            "year": 2020,
            "rank_display": 152,
            "score": 49.4,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-bombay-iitb",
            "country": "India",
            "city": "Mumbai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-bombay-iitb_281_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 116,
            "size": "M",
            "faculty_count": 1015
        },
        {
            "university": "University of Lausanne",
            "year": 2020,
            "rank_display": 153,
            "score": 49.2,
            "link": "https://www.topuniversities.com/universities/university-lausanne",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lausanne_592560cf2aeae70239af4bd3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3026,
            "size": "L",
            "faculty_count": 1478
        },
        {
            "university": "Cardiff University",
            "year": 2020,
            "rank_display": 154,
            "score": 49,
            "link": "https://www.topuniversities.com/universities/cardiff-university",
            "country": "United Kingdom",
            "city": "Cardiff",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cardiff-university_592560cf2aeae70239af4ae6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7819,
            "size": "L",
            "faculty_count": 3350
        },
        {
            "university": "University of Vienna",
            "year": 2020,
            "rank_display": 154,
            "score": 49,
            "link": "https://www.topuniversities.com/universities/university-vienna",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-vienna_652_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 14514,
            "size": "XL",
            "faculty_count": 3637
        },
        {
            "university": "Emory University",
            "year": 2020,
            "rank_display": 156,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/emory-university",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/emory-university_185_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2264,
            "size": "L",
            "faculty_count": 2725
        },
        {
            "university": "University of Minnesota Twin Cities",
            "year": 2020,
            "rank_display": 156,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/university-minnesota-twin-cities",
            "country": "United States",
            "city": "Minneapolis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-minnesota_408_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5630,
            "size": "XL",
            "faculty_count": 5087
        },
        {
            "university": "Tecnológico de Monterrey",
            "year": 2020,
            "rank_display": 158,
            "score": 48.5,
            "link": "https://www.topuniversities.com/universities/tecnologico-de-monterrey",
            "country": "Mexico",
            "city": "Monterrey",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/instituto-tecnolgico-y-de-estudios-superiores-de-monterrey_592560cf2aeae70239af4cd6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3514,
            "size": "XL",
            "faculty_count": 5894
        },
        {
            "university": "Universiti Putra Malaysia (UPM)",
            "year": 2020,
            "rank_display": 159,
            "score": 48.4,
            "link": "https://www.topuniversities.com/universities/universiti-putra-malaysia-upm",
            "country": "Malaysia",
            "city": "Seri Kembangan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-putra-malaysia-upm_385_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5040,
            "size": "L",
            "faculty_count": 2174
        },
        {
            "university": "École Normale Supérieure de Lyon",
            "year": 2020,
            "rank_display": 160,
            "score": 48.3,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-de-lyon",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cole-normale-suprieure-de-lyon_175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 233,
            "size": "S",
            "faculty_count": 387
        },
        {
            "university": "Universiti Kebangsaan Malaysia (UKM)",
            "year": 2020,
            "rank_display": 160,
            "score": 48.3,
            "link": "https://www.topuniversities.com/universities/universiti-kebangsaan-malaysia-ukm",
            "country": "Malaysia",
            "city": "Bangi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-kebangsaan-malaysia-ukm_384_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2410,
            "size": "L",
            "faculty_count": 2829
        },
        {
            "university": "The Hebrew University of Jerusalem",
            "year": 2020,
            "rank_display": 162,
            "score": 48.2,
            "link": "https://www.topuniversities.com/universities/hebrew-university-jerusalem",
            "country": "Israel",
            "city": "Jerusalem",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hebrew-university-of-jerusalem_256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1176,
            "size": "L",
            "faculty_count": 2295
        },
        {
            "university": "University of Bergen",
            "year": 2020,
            "rank_display": 163,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/university-bergen",
            "country": "Norway",
            "city": "Bergen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bergen_53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 832,
            "size": "M",
            "faculty_count": 1634
        },
        {
            "university": "The University of Exeter",
            "year": 2020,
            "rank_display": 163,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/university-exeter",
            "country": "United Kingdom",
            "city": "Exeter",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-exeter_204_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7188,
            "size": "L",
            "faculty_count": 2393
        },
        {
            "university": "Universitat de Barcelona",
            "year": 2020,
            "rank_display": 165,
            "score": 47.9,
            "link": "https://www.topuniversities.com/universities/universitat-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-barcelona_592560cf2aeae70239af4aaf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 6979,
            "size": "XL",
            "faculty_count": 3002
        },
        {
            "university": "Universiti Sains Malaysia (USM)",
            "year": 2020,
            "rank_display": 165,
            "score": 47.9,
            "link": "https://www.topuniversities.com/universities/universiti-sains-malaysia-usm",
            "country": "Malaysia",
            "city": "Gelugor",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-sains-malaysia-usm_386_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4198,
            "size": "L",
            "faculty_count": 2249
        },
        {
            "university": "Case Western Reserve University",
            "year": 2020,
            "rank_display": 167,
            "score": 47.8,
            "link": "https://www.topuniversities.com/universities/case-western-reserve-university",
            "country": "United States",
            "city": "Cleveland",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/case-western-reserve-university_102_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2864,
            "size": "M",
            "faculty_count": 1639
        },
        {
            "university": "Université catholique de Louvain (UCLouvain)",
            "year": 2020,
            "rank_display": 167,
            "score": 47.7,
            "link": "https://www.topuniversities.com/universities/universite-catholique-de-louvain-uclouvain",
            "country": "Belgium",
            "city": "Louvain-la-Neuve",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-catholique-de-louvain-uclouvain_592560cf2aeae70239af4aed_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4524,
            "size": "L",
            "faculty_count": 1315
        },
        {
            "university": "University of Florida",
            "year": 2020,
            "rank_display": 167,
            "score": 47.7,
            "link": "https://www.topuniversities.com/universities/university-florida",
            "country": "United States",
            "city": "Gainesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florida_212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4143,
            "size": "XL",
            "faculty_count": 6222
        },
        {
            "university": "Eberhard Karls Universität Tübingen",
            "year": 2020,
            "rank_display": 169,
            "score": 47.3,
            "link": "https://www.topuniversities.com/universities/eberhard-karls-universitat-tubingen",
            "country": "Germany",
            "city": "Tübingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eberhard-karls-universitt-tbingen_629_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2872,
            "size": "L",
            "faculty_count": 4517
        },
        {
            "university": "Albert-Ludwigs-Universitaet Freiburg",
            "year": 2020,
            "rank_display": 169,
            "score": 47.3,
            "link": "https://www.topuniversities.com/universities/albert-ludwigs-universitaet-freiburg",
            "country": "Germany",
            "city": "Freiburg im Breisgau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/albert-ludwigs-universitaet-freiburg_216_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3883,
            "size": "L",
            "faculty_count": 1993
        },
        {
            "university": "University of Rochester",
            "year": 2020,
            "rank_display": 170,
            "score": 47.3,
            "link": "https://www.topuniversities.com/universities/university-rochester",
            "country": "United States",
            "city": "Rochester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rochester_592560cf2aeae70239af4c96_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3644,
            "size": "M",
            "faculty_count": 1853
        },
        {
            "university": "University of Bath",
            "year": 2020,
            "rank_display": 172,
            "score": 47,
            "link": "https://www.topuniversities.com/universities/university-bath",
            "country": "United Kingdom",
            "city": "Bath",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bath_47_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5326,
            "size": "L",
            "faculty_count": 1339
        },
        {
            "university": "National Tsing Hua University",
            "year": 2020,
            "rank_display": 173,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/national-tsing-hua-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-tsing-hua-university_442_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1551,
            "size": "L",
            "faculty_count": 1265
        },
        {
            "university": "Queen's University Belfast",
            "year": 2020,
            "rank_display": 173,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/queens-university-belfast",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-belfast_592560cf2aeae70239af4c86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7397,
            "size": "L",
            "faculty_count": 1951
        },
        {
            "university": "University of Waterloo",
            "year": 2020,
            "rank_display": 173,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/university-waterloo",
            "country": "Canada",
            "city": "Waterloo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waterloo_670_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 10269,
            "size": "XL",
            "faculty_count": 1908
        },
        {
            "university": "University of Otago",
            "year": 2020,
            "rank_display": 176,
            "score": 46.7,
            "link": "https://www.topuniversities.com/universities/university-otago",
            "country": "New Zealand",
            "city": "Dunedin",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-otago_474_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3585,
            "size": "L",
            "faculty_count": 1333
        },
        {
            "university": "Sant'Anna - Scuola Universitaria Superiore Pisa",
            "year": 2020,
            "rank_display": 177,
            "score": "",
            "link": "https://www.topuniversities.com/universities/santanna-scuola-universitaria-superiore-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/santanna-scuola-universitaria-superiore-pisa_592560cf2aeae70239af4d74_small.jpg",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 131,
            "size": "S",
            "faculty_count": 174
        },
        {
            "university": "Alma Mater Studiorum - University of Bologna",
            "year": 2020,
            "rank_display": 177,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/alma-mater-studiorum-university-bologna",
            "country": "Italy",
            "city": "Bologna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/alma-mater-studiorum-university-of-bologna_592560cf2aeae70239af4ac0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 5536,
            "size": "XL",
            "faculty_count": 2885
        },
        {
            "university": "Technische Universität Dresden",
            "year": 2020,
            "rank_display": 179,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/technische-universitat-dresden",
            "country": "Germany",
            "city": "Dresden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-dresden_598_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5167,
            "size": "XL",
            "faculty_count": 4636
        },
        {
            "university": "University of Liverpool",
            "year": 2020,
            "rank_display": 181,
            "score": 46.3,
            "link": "https://www.topuniversities.com/universities/university-liverpool",
            "country": "United Kingdom",
            "city": "Liverpool",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-liverpool_350_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9500,
            "size": "L",
            "faculty_count": 2771
        },
        {
            "university": "Indian Institute of Technology Delhi (IITD)",
            "year": 2020,
            "rank_display": 182,
            "score": 46.2,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-delhi-iitd",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-delhi-iitd_282_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 100,
            "size": "M",
            "faculty_count": 843
        },
        {
            "university": "Erasmus University Rotterdam",
            "year": 2020,
            "rank_display": 183,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/erasmus-university-rotterdam",
            "country": "Netherlands",
            "city": "Rotterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/erasmus-university-rotterdam_190_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5932,
            "size": "L",
            "faculty_count": 2699
        },
        {
            "university": "Indian Institute of Science",
            "year": 2020,
            "rank_display": 184,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-science",
            "country": "India",
            "city": "Bangalore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-science-iisc-bangalore_22878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5,
            "size": "S",
            "faculty_count": 446
        },
        {
            "university": "University College Dublin",
            "year": 2020,
            "rank_display": 185,
            "score": 45.6,
            "link": "https://www.topuniversities.com/universities/university-college-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-dublin_166_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6118,
            "size": "L",
            "faculty_count": 1836
        },
        {
            "university": "King Abdulaziz University (KAU)",
            "year": 2020,
            "rank_display": 186,
            "score": 45.2,
            "link": "https://www.topuniversities.com/universities/king-abdulaziz-university-kau",
            "country": "Saudi Arabia",
            "city": "Jeddah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-abdulaziz-university-kau_1172_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6821,
            "size": "XL",
            "faculty_count": 4054
        },
        {
            "university": "University of Twente",
            "year": 2020,
            "rank_display": 186,
            "score": 45.2,
            "link": "https://www.topuniversities.com/universities/university-twente",
            "country": "Netherlands",
            "city": "Enschede",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-twente_635_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3872,
            "size": "L",
            "faculty_count": 1177
        },
        {
            "university": "Universitat Autònoma de Barcelona",
            "year": 2020,
            "rank_display": 188,
            "score": 45.1,
            "link": "https://www.topuniversities.com/universities/universitat-autonoma-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-autnoma-de-barcelona_592560cf2aeae70239af4ab0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 4069,
            "size": "XL",
            "faculty_count": 2132
        },
        {
            "university": "Texas A&M University",
            "year": 2020,
            "rank_display": 189,
            "score": 45,
            "link": "https://www.topuniversities.com/universities/texas-am-university",
            "country": "United States",
            "city": "Galveston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/texas-am-university_592560cf2aeae70239af4ce2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 6621,
            "size": "XL",
            "faculty_count": 3049
        },
        {
            "university": "Universidad de Chile",
            "year": 2020,
            "rank_display": 189,
            "score": 45,
            "link": "https://www.topuniversities.com/universities/universidad-de-chile",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-chile_121_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1697,
            "size": "XL",
            "faculty_count": 2412
        },
        {
            "university": "Stockholm University",
            "year": 2020,
            "rank_display": 191,
            "score": 44.8,
            "link": "https://www.topuniversities.com/universities/stockholm-university",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/stockholm-university_577_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7567,
            "size": "L",
            "faculty_count": 2325
        },
        {
            "university": "Technische Universität Wien",
            "year": 2020,
            "rank_display": 192,
            "score": 44.7,
            "link": "https://www.topuniversities.com/universities/vienna-university-technology",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vienna-university-of-technology_653_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2515,
            "size": "M",
            "faculty_count": 761
        },
        {
            "university": "Universidad Autónoma de Madrid",
            "year": 2020,
            "rank_display": 192,
            "score": 44.7,
            "link": "https://www.topuniversities.com/universities/universidad-autonoma-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-autnoma-de-madrid_379_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2470,
            "size": "L",
            "faculty_count": 2869
        },
        {
            "university": "University of Aberdeen",
            "year": 2020,
            "rank_display": 194,
            "score": 44.6,
            "link": "https://www.topuniversities.com/universities/university-aberdeen",
            "country": "United Kingdom",
            "city": "Aberdeen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-aberdeen_592560cf2aeae70239af4a8b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5544,
            "size": "L",
            "faculty_count": 1175
        },
        {
            "university": "Vrije Universiteit Brussel (VUB)",
            "year": 2020,
            "rank_display": 195,
            "score": 44.5,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-brussel-vub",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-brussel-vub_74_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2994,
            "size": "L",
            "faculty_count": 1815
        },
        {
            "university": "Waseda University",
            "year": 2020,
            "rank_display": 196,
            "score": 44.4,
            "link": "https://www.topuniversities.com/universities/waseda-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/waseda-university_592560cf2aeae70239af4d1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7209,
            "size": "XL",
            "faculty_count": 3833
        },
        {
            "university": "University of Göttingen",
            "year": 2020,
            "rank_display": 197,
            "score": 44.2,
            "link": "https://www.topuniversities.com/universities/university-gottingen",
            "country": "Germany",
            "city": "Göttingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gttingen_234_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3875,
            "size": "XL",
            "faculty_count": 3717
        },
        {
            "university": "University of Cape Town",
            "year": 2020,
            "rank_display": 198,
            "score": 44.1,
            "link": "https://www.topuniversities.com/universities/university-cape-town",
            "country": "South Africa",
            "city": "Cape Town",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/uct-graduate-school-of-business_592560cf2aeae70239af4ae5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3204,
            "size": "L",
            "faculty_count": 1503
        },
        {
            "university": "University of Virginia",
            "year": 2020,
            "rank_display": 198,
            "score": 44.1,
            "link": "https://www.topuniversities.com/universities/university-virginia",
            "country": "United States",
            "city": "Charlottesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-virginia_656_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2201,
            "size": "L",
            "faculty_count": 2731
        },
        {
            "university": "Keio University",
            "year": 2020,
            "rank_display": 200,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/keio-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/keio-university_592560cf2aeae70239af4bbe_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3101,
            "size": "XL",
            "faculty_count": 4028
        },
        {
            "university": "King Fahd University of Petroleum & Minerals",
            "year": 2020,
            "rank_display": 200,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/king-fahd-university-petroleum-minerals",
            "country": "Saudi Arabia",
            "city": "Dhahran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-fahd-university-of-petroleum-minerals_779_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 782,
            "size": "M",
            "faculty_count": 1103
        },
        {
            "university": "Vanderbilt University",
            "year": 2020,
            "rank_display": 200,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/vanderbilt-university",
            "country": "United States",
            "city": "Nashville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/vanderbilt-university_649_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 2022,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Sapienza University of Rome",
            "year": 2020,
            "rank_display": 203,
            "score": 43.5,
            "link": "https://www.topuniversities.com/universities/sapienza-university-rome",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sapienza-university-of-rome_592560cf2aeae70239af4c97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 5960,
            "size": "XL",
            "faculty_count": 3287
        },
        {
            "university": "Scuola Normale Superiore",
            "year": 2020,
            "rank_display": 204,
            "score": "",
            "link": "https://www.topuniversities.com/universities/scuola-normale-superiore",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/scuola-normale-superiore-di-pisa_25824_small.jpg",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 69,
            "size": "S",
            "faculty_count": 150
        },
        {
            "university": "University of Reading",
            "year": 2020,
            "rank_display": 205,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/university-reading",
            "country": "United Kingdom",
            "city": "Reading",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-reading_517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5007,
            "size": "L",
            "faculty_count": 1325
        },
        {
            "university": "University of Colorado Boulder",
            "year": 2020,
            "rank_display": 206,
            "score": 43.2,
            "link": "https://www.topuniversities.com/universities/university-colorado-boulder",
            "country": "United States",
            "city": "Boulder",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-boulder_137_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2441,
            "size": "XL",
            "faculty_count": 2946
        },
        {
            "university": "Al-Farabi Kazakh National University",
            "year": 2020,
            "rank_display": 207,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/al-farabi-kazakh-national-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/al-farabi-kazakh-national-university_2146_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3054,
            "size": "L",
            "faculty_count": 4047
        },
        {
            "university": "Dartmouth College",
            "year": 2020,
            "rank_display": 207,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/dartmouth-college",
            "country": "United States",
            "city": "Hanover",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dartmouth-college_592560cf2aeae70239af4b1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 997,
            "size": "M",
            "faculty_count": 836
        },
        {
            "university": "The University of Newcastle, Australia (UON)",
            "year": 2020,
            "rank_display": 207,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/university-newcastle-australia-uon",
            "country": "Australia",
            "city": "Callaghan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-newcastle-australia-uon_449_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1747,
            "size": "L",
            "faculty_count": 1135
        },
        {
            "university": "University of Notre Dame",
            "year": 2020,
            "rank_display": 210,
            "score": 42.9,
            "link": "https://www.topuniversities.com/universities/university-notre-dame",
            "country": "United States",
            "city": "Notre Dame",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-notre-dame_458_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1484,
            "size": "L",
            "faculty_count": 1545
        },
        {
            "university": "Western University",
            "year": 2020,
            "rank_display": 211,
            "score": 42.8,
            "link": "https://www.topuniversities.com/universities/western-university",
            "country": "Canada",
            "city": "London",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-ontario_674_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 6314,
            "size": "L",
            "faculty_count": 1311
        },
        {
            "university": "Complutense University of Madrid",
            "year": 2020,
            "rank_display": 212,
            "score": 42.7,
            "link": "https://www.topuniversities.com/universities/complutense-university-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/complutense-university-of-madrid_592560cf2aeae70239af4bff_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 7295,
            "size": "XL",
            "faculty_count": 4778
        },
        {
            "university": "University of Wollongong",
            "year": 2020,
            "rank_display": 212,
            "score": 42.7,
            "link": "https://www.topuniversities.com/universities/university-wollongong",
            "country": "Australia",
            "city": "Wollongong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wollongong_680_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4779,
            "size": "L",
            "faculty_count": 1410
        },
        {
            "university": "Universidade Estadual de Campinas (Unicamp)",
            "year": 2020,
            "rank_display": 214,
            "score": 42.1,
            "link": "https://www.topuniversities.com/universities/universidade-estadual-de-campinas-unicamp",
            "country": "Brazil",
            "city": "Campinas",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-estadual-de-campinas-unicamp_96_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 944,
            "size": "L",
            "faculty_count": 1875
        },
        {
            "university": "Arizona State University",
            "year": 2020,
            "rank_display": 215,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/arizona-state-university",
            "country": "United States",
            "city": "Phoenix",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/arizona-state-university_592560cf2aeae70239af4a9c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8430,
            "size": "XL",
            "faculty_count": 2869
        },
        {
            "university": "Victoria University of Wellington",
            "year": 2020,
            "rank_display": 215,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/victoria-university-wellington",
            "country": "New Zealand",
            "city": "Kelburn, Wellington",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/victoria-university-of-wellington_592560cf2aeae70239af4d0f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 3668,
            "size": "L",
            "faculty_count": 1124
        },
        {
            "university": "Radboud University",
            "year": 2020,
            "rank_display": 217,
            "score": 41.4,
            "link": "https://www.topuniversities.com/universities/radboud-university",
            "country": "Netherlands",
            "city": "Nijmegen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/radboud-university_452_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2781,
            "size": "L",
            "faculty_count": 2074
        },
        {
            "university": "Universiti Teknologi Malaysia",
            "year": 2020,
            "rank_display": 217,
            "score": 41.4,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-malaysia",
            "country": "Malaysia",
            "city": "Skudai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-malaysia_387_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4956,
            "size": "L",
            "faculty_count": 1869
        },
        {
            "university": "Tel Aviv University",
            "year": 2020,
            "rank_display": 219,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/tel-aviv-university",
            "country": "Israel",
            "city": "Tel Aviv",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tel-aviv-university_592560cf2aeae70239af4cde_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1294,
            "size": "L",
            "faculty_count": 1048
        },
        {
            "university": "University of California, Irvine",
            "year": 2020,
            "rank_display": 219,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/university-california-irvine",
            "country": "United States",
            "city": "Irvine",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-irvine_86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 9323,
            "size": "XL",
            "faculty_count": 2775
        },
        {
            "university": "Vrije Universiteit Amsterdam",
            "year": 2020,
            "rank_display": 219,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-amsterdam_658_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4059,
            "size": "L",
            "faculty_count": 2598
        },
        {
            "university": "Loughborough University",
            "year": 2020,
            "rank_display": 222,
            "score": 41.2,
            "link": "https://www.topuniversities.com/universities/loughborough-university",
            "country": "United Kingdom",
            "city": "Loughborough",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/loughborough-university_592560cf2aeae70239af4bf3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4540,
            "size": "L",
            "faculty_count": 1445
        },
        {
            "university": "University of Antwerp",
            "year": 2020,
            "rank_display": 223,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/university-antwerp",
            "country": "Belgium",
            "city": "Antwerp",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-antwerp_21_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2688,
            "size": "L",
            "faculty_count": 1704
        },
        {
            "university": "Queensland University of Technology (QUT)",
            "year": 2020,
            "rank_display": 224,
            "score": 40.7,
            "link": "https://www.topuniversities.com/universities/queensland-university-technology-qut",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/queensland-university-of-technology-qut_592560cf2aeae70239af4c88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6403,
            "size": "XL",
            "faculty_count": 2028
        },
        {
            "university": "National Cheng Kung University (NCKU)",
            "year": 2020,
            "rank_display": 225,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/national-cheng-kung-university-ncku",
            "country": "Taiwan",
            "city": "Tainan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-cheng-kung-university-ncku_435_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2656,
            "size": "L",
            "faculty_count": 2079
        },
        {
            "university": "Georgetown University",
            "year": 2020,
            "rank_display": 226,
            "score": 40.1,
            "link": "https://www.topuniversities.com/universities/georgetown-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgetown-university_224_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3286,
            "size": "L",
            "faculty_count": 2009
        },
        {
            "university": "National Chiao Tung University",
            "year": 2020,
            "rank_display": 227,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/national-chiao-tung-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-chiao-tung-university_437_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1420,
            "size": "M",
            "faculty_count": 1152
        },
        {
            "university": "Universität Hamburg",
            "year": 2020,
            "rank_display": 227,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/universitat-hamburg",
            "country": "Germany",
            "city": "Hamburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-hamburg_592560cf2aeae70239af4b79_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3626,
            "size": "L",
            "faculty_count": 2032
        },
        {
            "university": "University of Canterbury",
            "year": 2020,
            "rank_display": 227,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/university-canterbury",
            "country": "New Zealand",
            "city": "Christchurch",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canterbury_97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2816,
            "size": "L",
            "faculty_count": ""
        },
        {
            "university": "Curtin University",
            "year": 2020,
            "rank_display": 230,
            "score": 39.9,
            "link": "https://www.topuniversities.com/universities/curtin-university",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/curtin-university_150_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4565,
            "size": "L",
            "faculty_count": 1698
        },
        {
            "university": "Novosibirsk State University",
            "year": 2020,
            "rank_display": 231,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/novosibirsk-state-university",
            "country": "Russia",
            "city": "Novosibirsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/novosibirsk-state-university_462_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1623,
            "size": "M",
            "faculty_count": 1218
        },
        {
            "university": "University of Illinois at Chicago (UIC)",
            "year": 2020,
            "rank_display": 231,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/university-illinois-chicago-uic",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-chicago-uic_592560cf2aeae70239af4b9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3425,
            "size": "L",
            "faculty_count": 2808
        },
        {
            "university": "University of Calgary",
            "year": 2020,
            "rank_display": 233,
            "score": 39.7,
            "link": "https://www.topuniversities.com/universities/university-calgary",
            "country": "Canada",
            "city": "Calgary",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-calgary_592560cf2aeae70239af4ad5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6791,
            "size": "XL",
            "faculty_count": 2668
        },
        {
            "university": "Saint Petersburg State University",
            "year": 2020,
            "rank_display": 234,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/saint-petersburg-state-university",
            "country": "Russia",
            "city": "Saint Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-petersburg-state-university_592560cf2aeae70239af4cc0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4982,
            "size": "L",
            "faculty_count": 3054
        },
        {
            "university": "Universidad de los Andes",
            "year": 2020,
            "rank_display": 234,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universidad-de-los-andes",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-los-andes_592560cf2aeae70239af4bf6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 13,
            "international_students": 151,
            "size": "L",
            "faculty_count": 1223
        },
        {
            "university": "Università di Padova",
            "year": 2020,
            "rank_display": 234,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universita-di-padova",
            "country": "Italy",
            "city": "Padua",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitdi-padova_480_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2654,
            "size": "XL",
            "faculty_count": 2806
        },
        {
            "university": "Macquarie University",
            "year": 2020,
            "rank_display": 237,
            "score": 39.5,
            "link": "https://www.topuniversities.com/universities/macquarie-university",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/macquarie-university_378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8621,
            "size": "L",
            "faculty_count": 1766
        },
        {
            "university": "RMIT University",
            "year": 2020,
            "rank_display": 238,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/rmit-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/rmit-university_592560cf2aeae70239af4c95_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 11743,
            "size": "XL",
            "faculty_count": 1859
        },
        {
            "university": "Maastricht University",
            "year": 2020,
            "rank_display": 239,
            "score": 39,
            "link": "https://www.topuniversities.com/universities/maastricht-university",
            "country": "Netherlands",
            "city": "Maastricht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/maastricht-university_377_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10425,
            "size": "L",
            "faculty_count": 1418
        },
        {
            "university": "Queen's University at Kingston",
            "year": 2020,
            "rank_display": 239,
            "score": 39,
            "link": "https://www.topuniversities.com/universities/queens-university-kingston",
            "country": "Canada",
            "city": "Kingston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-at-kingston_513_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3858,
            "size": "L",
            "faculty_count": 1287
        },
        {
            "university": "University of Leicester",
            "year": 2020,
            "rank_display": 239,
            "score": 39,
            "link": "https://www.topuniversities.com/universities/university-leicester",
            "country": "United Kingdom",
            "city": "Leicester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leicester_339_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4919,
            "size": "L",
            "faculty_count": 1518
        },
        {
            "university": "Sciences Po",
            "year": 2020,
            "rank_display": 242,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/sciences-po",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sciences-po-_592560cf2aeae70239af4c64_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4841,
            "size": "M",
            "faculty_count": 1808
        },
        {
            "university": "Rheinische Friedrich-Wilhelms-Universität Bonn",
            "year": 2020,
            "rank_display": 243,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/rheinische-friedrich-wilhelms-universitat-bonn",
            "country": "Germany",
            "city": "Bonn",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rheinische-friedrich-wilhelms-universitt-bonn_592560cf2aeae70239af4ac1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3276,
            "size": "L",
            "faculty_count": 4207
        },
        {
            "university": "American University of Beirut (AUB)",
            "year": 2020,
            "rank_display": 244,
            "score": 38.7,
            "link": "https://www.topuniversities.com/universities/american-university-beirut-aub",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-beirut-aub_780_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1724,
            "size": "M",
            "faculty_count": 964
        },
        {
            "university": "University of Navarra",
            "year": 2020,
            "rank_display": 245,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/university-navarra",
            "country": "Spain",
            "city": "Pamplona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-navarra_592560cf2aeae70239af4c40_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2978,
            "size": "M",
            "faculty_count": 1175
        },
        {
            "university": "University of Sussex",
            "year": 2020,
            "rank_display": 246,
            "score": 38.4,
            "link": "https://www.topuniversities.com/universities/university-sussex",
            "country": "United Kingdom",
            "city": "Brighton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-sussex_592560cf2aeae70239af4ccd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6350,
            "size": "L",
            "faculty_count": 1320
        },
        {
            "university": "Chulalongkorn University",
            "year": 2020,
            "rank_display": 247,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/chulalongkorn-university",
            "country": "Thailand",
            "city": "Bangkok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chulalongkorn-university_592560cf2aeae70239af4b01_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 985,
            "size": "XL",
            "faculty_count": 3354
        },
        {
            "university": "Kyung Hee University",
            "year": 2020,
            "rank_display": 247,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/kyung-hee-university",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyung-hee-university_592560cf2aeae70239af4bcd_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4715,
            "size": "L",
            "faculty_count": 3435
        },
        {
            "university": "Ecole des Ponts ParisTech",
            "year": 2020,
            "rank_display": 250,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/ecole-des-ponts-paristech",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-des-ponts-paristech_187_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 735,
            "size": "S",
            "faculty_count": 338
        },
        {
            "university": "National Taiwan University of Science and Technology (Taiwan Tech)",
            "year": 2020,
            "rank_display": 251,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-science-technology-taiwan-tech",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-of-science-and-technology-taiwan-tech_590_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1537,
            "size": "M",
            "faculty_count": 1005
        },
        {
            "university": "Universite libre de Bruxelles",
            "year": 2020,
            "rank_display": 251,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/universite-libre-de-bruxelles",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universite-libre-de-bruxelles_75_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 8073,
            "size": "L",
            "faculty_count": 1101
        },
        {
            "university": "Tufts University",
            "year": 2020,
            "rank_display": 253,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/tufts-university",
            "country": "United States",
            "city": "Medford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tufts-university_630_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1798,
            "size": "M",
            "faculty_count": 1459
        },
        {
            "university": "Universidad Nacional de Colombia",
            "year": 2020,
            "rank_display": 253,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-de-colombia_592560cf2aeae70239af4b0b_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 335,
            "size": "XL",
            "faculty_count": 3055
        },
        {
            "university": "University of Gothenburg",
            "year": 2020,
            "rank_display": 256,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/university-gothenburg",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gothenburg_233_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5652,
            "size": "L",
            "faculty_count": 2680
        },
        {
            "university": "Technion - Israel Institute of Technology",
            "year": 2020,
            "rank_display": 257,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/technion-israel-institute-technology",
            "country": "Israel",
            "city": "Haifa",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/technion-israel-institute-of-technology_600_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "L",
            "faculty_count": 1.616
        },
        {
            "university": "Wuhan University",
            "year": 2020,
            "rank_display": 257,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/wuhan-university",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/wuhan-university_889_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3682,
            "size": "XL",
            "faculty_count": 3828
        },
        {
            "university": "National University of Ireland Galway",
            "year": 2020,
            "rank_display": 259,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/national-university-ireland-galway",
            "country": "Ireland",
            "city": "Galway",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-ireland-galway_220_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3208,
            "size": "L",
            "faculty_count": 1835
        },
        {
            "university": "Technical University of Darmstadt",
            "year": 2020,
            "rank_display": 260,
            "score": 37,
            "link": "https://www.topuniversities.com/universities/technical-university-darmstadt",
            "country": "Germany",
            "city": "Darmstadt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-darmstadt_628_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3979,
            "size": "L",
            "faculty_count": 804
        },
        {
            "university": "Hong Kong Baptist University",
            "year": 2020,
            "rank_display": 261,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/hong-kong-baptist-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hong-kong-baptist-university_893_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4704,
            "size": "M",
            "faculty_count": 980
        },
        {
            "university": "Rutgers University–New Brunswick",
            "year": 2020,
            "rank_display": 262,
            "score": 36.8,
            "link": "https://www.topuniversities.com/universities/rutgers-university-new-brunswick",
            "country": "United States",
            "city": "New Brunswick",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rutgers-university-new-brunswick_538_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7965,
            "size": "XL",
            "faculty_count": 5009
        },
        {
            "university": "Université Paris-Saclay",
            "year": 2020,
            "rank_display": 262,
            "score": 36.8,
            "link": "https://www.topuniversities.com/universities/universite-paris-saclay",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-saclay_592560cf2aeae70239af4c6e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 7649,
            "size": "XL",
            "faculty_count": 8010
        },
        {
            "university": "The University of Arizona",
            "year": 2020,
            "rank_display": 262,
            "score": 36.8,
            "link": "https://www.topuniversities.com/universities/university-arizona",
            "country": "United States",
            "city": "Tucson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-arizona_26_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3526,
            "size": "XL",
            "faculty_count": 5724
        },
        {
            "university": "Tongji University",
            "year": 2020,
            "rank_display": 265,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/tongji-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tongji-university_617_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5095,
            "size": "XL",
            "faculty_count": 2785
        },
        {
            "university": "Universität Innsbruck",
            "year": 2020,
            "rank_display": 266,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/universitat-innsbruck",
            "country": "Austria",
            "city": "Innsbruck",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-innsbruck_592560cf2aeae70239af4ba7_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 6323,
            "size": "L",
            "faculty_count": 1769
        },
        {
            "university": "University of Waikato",
            "year": 2020,
            "rank_display": 266,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/university-waikato",
            "country": "New Zealand",
            "city": "Hamilton",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waikato_660_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.27,
            "size": "M",
            "faculty_count": 595
        },
        {
            "university": "Khalifa University of Science and Technology",
            "year": 2020,
            "rank_display": 268,
            "score": 36.5,
            "link": "https://www.topuniversities.com/universities/khalifa-university-science-technology",
            "country": "United Arab Emirates",
            "city": "Abu Dhabi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/khalifa-university-of-science-and-technology_592560e19988f300e2320ee2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 652,
            "size": "S",
            "faculty_count": ""
        },
        {
            "university": "Tomsk State University",
            "year": 2020,
            "rank_display": 268,
            "score": 36.5,
            "link": "https://www.topuniversities.com/universities/tomsk-state-university",
            "country": "Russia",
            "city": "Tomsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tomsk-state-university_616_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2828,
            "size": "M",
            "faculty_count": 1742
        },
        {
            "university": "University of Tsukuba",
            "year": 2020,
            "rank_display": 270,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/university-tsukuba",
            "country": "Japan",
            "city": "Tsukuba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tsukuba_592560cf2aeae70239af4cf7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2358,
            "size": "L",
            "faculty_count": 2073
        },
        {
            "university": "Deakin University",
            "year": 2020,
            "rank_display": 271,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/deakin-university",
            "country": "Australia",
            "city": "Geelong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/download-48x48.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 10684,
            "size": "XL",
            "faculty_count": 1851
        },
        {
            "university": "Indian Institute of Technology Madras (IITM)",
            "year": 2020,
            "rank_display": 271,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-madras-iitm",
            "country": "India",
            "city": "Chennai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-madras-iitm_286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 94,
            "size": "M",
            "faculty_count": 884
        },
        {
            "university": "University of Miami",
            "year": 2020,
            "rank_display": 271,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/university-miami",
            "country": "United States",
            "city": "Miami",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-miami_401_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2646,
            "size": "L",
            "faculty_count": 2565
        },
        {
            "university": "University of South Australia",
            "year": 2020,
            "rank_display": 274,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/university-south-australia",
            "country": "Australia",
            "city": "Magill",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-south-australia_566_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.886,
            "size": "L",
            "faculty_count": 1.524
        },
        {
            "university": "University of Surrey",
            "year": 2020,
            "rank_display": 274,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/university-surrey",
            "country": "United Kingdom",
            "city": "Guildford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-surrey_584_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5556,
            "size": "L",
            "faculty_count": 1221
        },
        {
            "university": "Qatar University",
            "year": 2020,
            "rank_display": 276,
            "score": 35.8,
            "link": "https://www.topuniversities.com/universities/qatar-university",
            "country": "Qatar",
            "city": "Doha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/qatar-university_2537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3197,
            "size": "M",
            "faculty_count": 1163
        },
        {
            "university": "Beijing Normal University",
            "year": 2020,
            "rank_display": 277,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/beijing-normal-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-normal-university_869_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3288,
            "size": "L",
            "faculty_count": 2098
        },
        {
            "university": "Harbin Institute of Technology",
            "year": 2020,
            "rank_display": 277,
            "score": 35.7,
            "link": "https://www.topuniversities.com/universities/harbin-institute-technology",
            "country": "China (Mainland)",
            "city": "Harbin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/harbin-institute-of-technology_877_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2102,
            "size": "XL",
            "faculty_count": 3215
        },
        {
            "university": "Universität Stuttgart",
            "year": 2020,
            "rank_display": 279,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/universitat-stuttgart",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-stuttgart_580_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.028,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "Dalhousie University",
            "year": 2020,
            "rank_display": 280,
            "score": 35.4,
            "link": "https://www.topuniversities.com/universities/dalhousie-university",
            "country": "Canada",
            "city": "Halifax",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dalhousie-university_151_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4483,
            "size": "L",
            "faculty_count": 1150
        },
        {
            "university": "Indian Institute of Technology Kharagpur (IIT-KGP)",
            "year": 2020,
            "rank_display": 281,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kharagpur-iit-kgp",
            "country": "India",
            "city": "Kharagpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kharagpur-iit-kgp_285_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 27,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "King Saud University",
            "year": 2020,
            "rank_display": 281,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/king-saud-university",
            "country": "Saudi Arabia",
            "city": "Riyadh",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-saud-university_1175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3896,
            "size": "XL",
            "faculty_count": 4216
        },
        {
            "university": "University of Ottawa",
            "year": 2020,
            "rank_display": 281,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/university-ottawa",
            "country": "Canada",
            "city": "Ottawa",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-ottawa_475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 8885,
            "size": "XL",
            "faculty_count": 1677
        },
        {
            "university": "Bauman Moscow State Technical University",
            "year": 2020,
            "rank_display": 284,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/bauman-moscow-state-technical-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bauman-moscow-state-technical-university_592560cf2aeae70239af505c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2370,
            "size": "L",
            "faculty_count": 4349
        },
        {
            "university": "North Carolina State University",
            "year": 2020,
            "rank_display": 285,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/north-carolina-state-university",
            "country": "United States",
            "city": "Raleigh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/north-carolina-state-university_592560cf2aeae70239af4c2a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 3156,
            "size": "L",
            "faculty_count": 1944
        },
        {
            "university": "Universitat Pompeu Fabra (Barcelona)",
            "year": 2020,
            "rank_display": 285,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/universitat-pompeu-fabra",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-pompeu-fabra_504_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1633,
            "size": "L",
            "faculty_count": 785
        },
        {
            "university": "Massey University",
            "year": 2020,
            "rank_display": 287,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/massey-university",
            "country": "New Zealand",
            "city": "Palmerston North",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/massey-university_395_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4836,
            "size": "M",
            "faculty_count": 1209
        },
        {
            "university": "National Yang Ming University",
            "year": 2020,
            "rank_display": 287,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/national-yang-ming-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-yang-ming-university_444_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 237,
            "size": "S",
            "faculty_count": 965
        },
        {
            "university": "Sun Yat-sen University",
            "year": 2020,
            "rank_display": 287,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/sun-yat-sen-university",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sun-yat-sen-university_888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3127,
            "size": "XL",
            "faculty_count": 7002
        },
        {
            "university": "University of Turku",
            "year": 2020,
            "rank_display": 287,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/university-turku",
            "country": "Finland",
            "city": "Turku",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turku_592560cf2aeae70239af4cfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 604,
            "size": "L",
            "faculty_count": 1505
        },
        {
            "university": "Charles University",
            "year": 2020,
            "rank_display": 291,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/charles-university",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/charles-university_117_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9080,
            "size": "XL",
            "faculty_count": 5019
        },
        {
            "university": "Indian Institute of Technology Kanpur (IITK)",
            "year": 2020,
            "rank_display": 291,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kanpur-iitk",
            "country": "India",
            "city": "Kanpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kanpur-iitk_592560cf2aeae70239af4ba5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 29,
            "size": "M",
            "faculty_count": 551
        },
        {
            "university": "Royal Holloway University of London",
            "year": 2020,
            "rank_display": 291,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/royal-holloway-university-london",
            "country": "United Kingdom",
            "city": "Egham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/royal-holloway-university-of-london_534_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.705,
            "size": "M",
            "faculty_count": 831
        },
        {
            "university": "Goethe-University Frankfurt am Main",
            "year": 2020,
            "rank_display": 291,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/goethe-university-frankfurt-am-main",
            "country": "Germany",
            "city": "Frankfurt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-frankfurt-am-main_214_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6.749,
            "size": "XL",
            "faculty_count": 3.623
        },
        {
            "university": "University of Tasmania",
            "year": 2020,
            "rank_display": 291,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/university-tasmania",
            "country": "Australia",
            "city": "Hobart",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tasmania_592560cf2aeae70239af4cd5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5260,
            "size": "L",
            "faculty_count": 982
        },
        {
            "university": "Universitas Indonesia",
            "year": 2020,
            "rank_display": 296,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/universitas-indonesia",
            "country": "Indonesia",
            "city": "Depok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universitas-indonesia_290_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1412,
            "size": "XL",
            "faculty_count": 4210
        },
        {
            "university": "University of Strathclyde",
            "year": 2020,
            "rank_display": 296,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/university-strathclyde",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-strathclyde_579_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5397,
            "size": "L",
            "faculty_count": 1532
        },
        {
            "university": "Universidad Carlos III de Madrid (UC3M)",
            "year": 2020,
            "rank_display": 298,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/universidad-carlos-iii-de-madrid-uc3m",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-carlos-iii-de-madrid-uc3m_1509_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.11,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "Universiti Brunei Darussalam (UBD)",
            "year": 2020,
            "rank_display": 298,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/universiti-brunei-darussalam-ubd",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-brunei-darussalam-ubd_22212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 635,
            "size": "S",
            "faculty_count": 475
        },
        {
            "university": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
            "year": 2020,
            "rank_display": 300,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-catalunya-barcelonatech-upc",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-catalunya_103_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3101,
            "size": "L",
            "faculty_count": 2485
        },
        {
            "university": "University of Tartu",
            "year": 2020,
            "rank_display": 301,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/university-tartu",
            "country": "Estonia",
            "city": "Tartu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tartu_592560cf2aeae70239af4d08_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1400,
            "size": "M",
            "faculty_count": 1605
        },
        {
            "university": "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
            "year": 2020,
            "rank_display": 302,
            "score": 34.3,
            "link": "https://www.topuniversities.com/universities/moscow-institute-physics-technology-mipt-moscow-phystech",
            "country": "Russia",
            "city": "Dolgoprudny",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-institute-of-physics-and-technology-mipt-moscow-phystech_15270_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1153,
            "size": "M",
            "faculty_count": 1110
        },
        {
            "university": "University of Milan",
            "year": 2020,
            "rank_display": 302,
            "score": 34.3,
            "link": "https://www.topuniversities.com/universities/university-milan",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milan_1873_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3269,
            "size": "XL",
            "faculty_count": 2516
        },
        {
            "university": "University of Dundee",
            "year": 2020,
            "rank_display": 302,
            "score": 34.3,
            "link": "https://www.topuniversities.com/universities/university-dundee",
            "country": "United Kingdom",
            "city": "Dundee",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/-university-of-dundee_592560cf2aeae70239af4b2c_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 3025,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Université Paris 1 Panthéon-Sorbonne",
            "year": 2020,
            "rank_display": 305,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/universite-paris-1-pantheon-sorbonne",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-1-panthon-sorbonne_482_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7257,
            "size": "XL",
            "faculty_count": 1679
        },
        {
            "university": "University of Massachusetts Amherst",
            "year": 2020,
            "rank_display": 305,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/university-massachusetts-amherst",
            "country": "United States",
            "city": "Amherst",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-massachusetts-amherst_592560cf2aeae70239af4c0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3696,
            "size": "L",
            "faculty_count": 1852
        },
        {
            "university": "Xi’an Jiaotong University",
            "year": 2020,
            "rank_display": 307,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/xian-jiaotong-university",
            "country": "China (Mainland)",
            "city": "Xi'an",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xian-jiaotong-university_683_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2266,
            "size": "XL",
            "faculty_count": 3729
        },
        {
            "university": "University of Cologne",
            "year": 2020,
            "rank_display": 308,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/university-cologne",
            "country": "Germany",
            "city": "Cologne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cologne_592560cf2aeae70239af4bc6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4890,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "SOAS University of London",
            "year": 2020,
            "rank_display": 309,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/soas-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/soas-university-of-london-_592560cf2aeae70239af4bf0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.398,
            "size": "S",
            "faculty_count": 300
        },
        {
            "university": "University College Cork",
            "year": 2020,
            "rank_display": 310,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/university-college-cork",
            "country": "Ireland",
            "city": "Cork",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-cork_142_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 3002,
            "size": "L",
            "faculty_count": 1619
        },
        {
            "university": "Graz University of Technology",
            "year": 2020,
            "rank_display": 311,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/graz-university-technology",
            "country": "Austria",
            "city": "Graz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/graz-university-of-technology_1239_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1473,
            "size": "M",
            "faculty_count": 1427
        },
        {
            "university": "ENS Paris-Saclay",
            "year": 2020,
            "rank_display": 312,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/ens-paris-saclay",
            "country": "France",
            "city": "Cachan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-de-cachan_1878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 298,
            "size": "S",
            "faculty_count": 289
        },
        {
            "university": "Indiana University Bloomington",
            "year": 2020,
            "rank_display": 312,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/indiana-university-bloomington",
            "country": "United States",
            "city": "Bloomington",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/indiana-university-bloomington_289_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3722,
            "size": "XL",
            "faculty_count": 3837
        },
        {
            "university": "Heriot-Watt University",
            "year": 2020,
            "rank_display": 314,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/heriot-watt-university",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/heriot-watt-university_592560cf2aeae70239af4d5f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3536,
            "size": "M",
            "faculty_count": 806
        },
        {
            "university": "Mahidol University",
            "year": 2020,
            "rank_display": 314,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/mahidol-university",
            "country": "Thailand",
            "city": "Nakhon Pathom",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/mahidol-university_381_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1415,
            "size": "L",
            "faculty_count": 3850
        },
        {
            "university": "Simon Fraser University",
            "year": 2020,
            "rank_display": 314,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/simon-fraser-university",
            "country": "Canada",
            "city": "Burnaby",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/simon-fraser-university_562_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 8051,
            "size": "L",
            "faculty_count": 1193
        },
        {
            "university": "Universität Mannheim",
            "year": 2020,
            "rank_display": 314,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/universitat-mannheim",
            "country": "Germany",
            "city": "Mannheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-mannheim_592560cf2aeae70239af4c0b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 1.29,
            "size": "M",
            "faculty_count": 307
        },
        {
            "university": "University of East Anglia (UEA)",
            "year": 2020,
            "rank_display": 314,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/university-east-anglia-uea",
            "country": "United Kingdom",
            "city": "Norwich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-east-anglia-uea_637_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3541,
            "size": "L",
            "faculty_count": 1206
        },
        {
            "university": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            "year": 2020,
            "rank_display": 319,
            "score": 33.3,
            "link": "https://www.topuniversities.com/universities/friedrich-alexander-universitat-erlangen-nurnberg",
            "country": "Germany",
            "city": "Erlangen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/friedrich-alexander-universitt-erlangen-nrnberg_191_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 4943,
            "size": "XL",
            "faculty_count": 1107
        },
        {
            "university": "Griffith University",
            "year": 2020,
            "rank_display": 320,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/griffith-university",
            "country": "Australia",
            "city": "Nathan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/griffith-university_242_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5875,
            "size": "XL",
            "faculty_count": 1918
        },
        {
            "university": "Gadjah Mada University",
            "year": 2020,
            "rank_display": 320,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/gadjah-mada-university",
            "country": "Indonesia",
            "city": "Yogyakarta",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gadjah-mada-university_219_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 738,
            "size": "XL",
            "faculty_count": 4728
        },
        {
            "university": "Gwangju Institute of Science and Technology (GIST)",
            "year": 2020,
            "rank_display": 322,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/gwangju-institute-science-technology-gist",
            "country": "South Korea",
            "city": "Gwangju",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gwangju-institute-of-science-and-technology-gist_718_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 122,
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "HSE University",
            "year": 2020,
            "rank_display": 322,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/hse-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/logo-3-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4535,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "Aalborg University",
            "year": 2020,
            "rank_display": 324,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/aalborg-university",
            "country": "Denmark",
            "city": "Aalborg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalborg-university_1262_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.474,
            "size": "L",
            "faculty_count": 1.716
        },
        {
            "university": "Linköping University",
            "year": 2020,
            "rank_display": 324,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/linkoping-university",
            "country": "Sweden",
            "city": "Linköping",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/linkping-university_348_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.514,
            "size": "L",
            "faculty_count": 1.471
        },
        {
            "university": "University of Hawaiʻi at Mānoa",
            "year": 2020,
            "rank_display": 326,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/university-hawaii-manoa",
            "country": "United States",
            "city": "Honolulu",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hawaii-at-maoa_255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.586,
            "size": "L",
            "faculty_count": 1.64
        },
        {
            "university": "Virginia Polytechnic Institute and State University",
            "year": 2020,
            "rank_display": 327,
            "score": 32.7,
            "link": "https://www.topuniversities.com/universities/virginia-polytechnic-institute-state-university",
            "country": "United States",
            "city": "Blacksburg",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/virginia-polytechnic-institute-and-state-university_592560cf2aeae70239af4d15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.477,
            "size": "XL",
            "faculty_count": 2.802
        },
        {
            "university": "Birkbeck, University of London",
            "year": 2020,
            "rank_display": 328,
            "score": 32.6,
            "link": "https://www.topuniversities.com/universities/birkbeck-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/birkbeck-university-of-london_1636_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3.214,
            "size": "M",
            "faculty_count": 585
        },
        {
            "university": "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
            "year": 2020,
            "rank_display": 329,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/national-research-nuclear-university-mephi-moscow-engineering-physics-institute",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-nuclear-university-mephi-moscow-engineering-physics-institute_15207_small.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1590,
            "size": "M",
            "faculty_count": 998
        },
        {
            "university": "United Arab Emirates University",
            "year": 2020,
            "rank_display": 329,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/united-arab-emirates-university",
            "country": "United Arab Emirates",
            "city": "Al Ain",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/united-arab-emirates-university_778_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1238,
            "size": "M",
            "faculty_count": 919
        },
        {
            "university": "Bandung Institute of Technology (ITB)",
            "year": 2020,
            "rank_display": 331,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/bandung-institute-technology-itb",
            "country": "Indonesia",
            "city": "Bandung",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bandung-institute-of-technology-itb_41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 613,
            "size": "L",
            "faculty_count": 2228
        },
        {
            "university": "Ewha Womans University",
            "year": 2020,
            "rank_display": 331,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/ewha-womans-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ewha-womans-university_592560cf2aeae70239af4b38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.105,
            "size": "L",
            "faculty_count": 2.436
        },
        {
            "university": "National Taiwan Normal University",
            "year": 2020,
            "rank_display": 331,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/national-taiwan-normal-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-normal-university_440_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.499,
            "size": "M",
            "faculty_count": 1.374
        },
        {
            "university": "Hiroshima University",
            "year": 2020,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/hiroshima-university",
            "country": "Japan",
            "city": "Higashihiroshima City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hiroshima-university_264_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.68,
            "size": "L",
            "faculty_count": 2.366
        },
        {
            "university": "IE University",
            "year": 2020,
            "rank_display": 335,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/ie-university",
            "country": "Spain",
            "city": "Segovia",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ie-university_293_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 3917,
            "size": "S",
            "faculty_count": 662
        },
        {
            "university": "George Washington University",
            "year": 2020,
            "rank_display": 336,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/george-washington-university",
            "country": "United States",
            "city": "Ashburn",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/george-washington-university_223_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4.024,
            "size": "L",
            "faculty_count": 2.016
        },
        {
            "university": "Universitat Politècnica de València",
            "year": 2020,
            "rank_display": 336,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-valencia",
            "country": "Spain",
            "city": "València",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-valncia_648_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.589,
            "size": "L",
            "faculty_count": 2.645
        },
        {
            "university": "Jagiellonian University",
            "year": 2020,
            "rank_display": 338,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/jagiellonian-university",
            "country": "Poland",
            "city": "Krakow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/jagiellonian-university_300_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2907,
            "size": "XL",
            "faculty_count": 4089
        },
        {
            "university": "University of Lisbon",
            "year": 2020,
            "rank_display": 338,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/university-lisbon",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lisbon_1475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8.546,
            "size": "XL",
            "faculty_count": 3.389
        },
        {
            "university": "Umea University",
            "year": 2020,
            "rank_display": 340,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/umea-university",
            "country": "Sweden",
            "city": "Umeå",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/umea-university_639_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.186,
            "size": "L",
            "faculty_count": 1.966
        },
        {
            "university": "Universität Jena",
            "year": 2020,
            "rank_display": 340,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/universitat-jena",
            "country": "Germany",
            "city": "Jena",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-jena_592560cf2aeae70239af4bb5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2.307,
            "size": "L",
            "faculty_count": 3.974
        },
        {
            "university": "University Ulm",
            "year": 2020,
            "rank_display": 340,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-ulm",
            "country": "Germany",
            "city": "Ulm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-ulm_638_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1289,
            "size": "M",
            "faculty_count": 1473
        },
        {
            "university": "University at Buffalo SUNY",
            "year": 2020,
            "rank_display": 340,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/university-buffalo-suny",
            "country": "United States",
            "city": "Buffalo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-at-buffalo-suny_1888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4.409,
            "size": "L",
            "faculty_count": 1.53
        },
        {
            "university": "Northeastern University",
            "year": 2020,
            "rank_display": 344,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/northeastern-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northeastern-university_454_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7.595,
            "size": "L",
            "faculty_count": 1.723
        },
        {
            "university": "Pontificia Universidad Católica Argentina",
            "year": 2020,
            "rank_display": 344,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-argentina",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-argentina_592560cf2aeae70239af5486_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 4,
            "international_students": 1063,
            "size": "L",
            "faculty_count": 3805
        },
        {
            "university": "University of Jyväskylä",
            "year": 2020,
            "rank_display": 346,
            "score": 31.6,
            "link": "https://www.topuniversities.com/universities/university-jyvaskyla",
            "country": "Finland",
            "city": "Jyväskylä",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-jyvskyl_306_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 339,
            "size": "M",
            "faculty_count": 1.573
        },
        {
            "university": "Westfälische Wilhelms-Universität Münster",
            "year": 2020,
            "rank_display": 347,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/westfalische-wilhelms-universitat-munster",
            "country": "Germany",
            "city": "Münster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/westflische-wilhelms-universitt-mnster_421_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.845,
            "size": "XL",
            "faculty_count": 4.23
        },
        {
            "university": "Politecnico di Torino",
            "year": 2020,
            "rank_display": 348,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/politecnico-di-torino",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-torino_633_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 5.369,
            "size": "XL",
            "faculty_count": 1.05
        },
        {
            "university": "City, University of London",
            "year": 2020,
            "rank_display": 349,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/city-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-london_354_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 7.362,
            "size": "L",
            "faculty_count": 970
        },
        {
            "university": "University of Warsaw",
            "year": 2020,
            "rank_display": 349,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/university-warsaw",
            "country": "Poland",
            "city": "Warsaw",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-warsaw_663_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2892,
            "size": "XL",
            "faculty_count": 3658
        },
        {
            "university": "Belarusian State University",
            "year": 2020,
            "rank_display": 351,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/belarusian-state-university",
            "country": "Belarus",
            "city": "Minsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/belarusian-state-university_14128_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4264,
            "size": "L",
            "faculty_count": 4173
        },
        {
            "university": "Université Grenoble Alpes",
            "year": 2020,
            "rank_display": 351,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/universite-grenoble-alpes",
            "country": "France",
            "city": "Grenoble",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-grenoble-alpes_592560cf2aeae70239af5ab2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8880,
            "size": "XL",
            "faculty_count": 3088
        },
        {
            "university": "University of Porto",
            "year": 2020,
            "rank_display": 353,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/university-porto",
            "country": "Portugal",
            "city": "Porto",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-porto_760_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3783,
            "size": "L",
            "faculty_count": 2028
        },
        {
            "university": "University of Utah",
            "year": 2020,
            "rank_display": 353,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/university-utah",
            "country": "United States",
            "city": "Salt Lake City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-utah_645_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.134,
            "size": "L",
            "faculty_count": 3.345
        },
        {
            "university": "University of Chemistry and Technology, Prague",
            "year": 2020,
            "rank_display": 355,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/university-chemistry-technology-prague",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chemistry-and-technology-prague_592560cf2aeae70239af4f53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 864,
            "size": "S",
            "faculty_count": 903
        },
        {
            "university": "Lincoln University",
            "year": 2020,
            "rank_display": 356,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/lincoln-university",
            "country": "New Zealand",
            "city": "Lincoln",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/lincoln-university-_592560cf2aeae70239af4ef3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 898,
            "size": "S",
            "faculty_count": 643
        },
        {
            "university": "University of the Philippines",
            "year": 2020,
            "rank_display": 356,
            "score": 30.7,
            "link": "https://www.topuniversities.com/universities/university-philippines",
            "country": "Philippines",
            "city": "Quezon City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-philippines_498_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 292,
            "size": "XL",
            "faculty_count": 4.48
        },
        {
            "university": "Universidade Federal do Rio de Janeiro",
            "year": 2020,
            "rank_display": 358,
            "score": 30.6,
            "link": "https://www.topuniversities.com/universities/universidade-federal-do-rio-de-janeiro",
            "country": "Brazil",
            "city": "Rio de Janeiro",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-do-rio-de-janeiro_525_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 555,
            "size": "XL",
            "faculty_count": 3.983
        },
        {
            "university": "Brunel University London",
            "year": 2020,
            "rank_display": 359,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/brunel-university-london",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/brunel-university-london_592560cf2aeae70239af4acc_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 5.305,
            "size": "L",
            "faculty_count": 860
        },
        {
            "university": "Norwegian University of Science And Technology",
            "year": 2020,
            "rank_display": 359,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/norwegian-university-science-technology",
            "country": "Norway",
            "city": "Trondheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/norwegian-university-of-science-and-technology_457_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.95,
            "size": "XL",
            "faculty_count": 3.132
        },
        {
            "university": "Stony Brook University, State University of New York",
            "year": 2020,
            "rank_display": 359,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/stony-brook-university-state-university-new-york",
            "country": "United States",
            "city": "Stony Brook",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stony-brook-university-state-university-of-new-york_592560cf2aeae70239af4ccb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6.198,
            "size": "L",
            "faculty_count": 2.316
        },
        {
            "university": "Tokyo Medical and Dental University (TMDU)",
            "year": 2020,
            "rank_display": 359,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/tokyo-medical-dental-university-tmdu",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-medical-and-dental-university-tmdu_592560cf2aeae70239af4e8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 325,
            "size": "S",
            "faculty_count": 930
        },
        {
            "university": "Yeshiva University",
            "year": 2020,
            "rank_display": 359,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/yeshiva-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-katz-school-at-yeshiva-university_685_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 467,
            "size": "S",
            "faculty_count": 471
        },
        {
            "university": "University of Victoria (UVic)",
            "year": 2020,
            "rank_display": 364,
            "score": 30.4,
            "link": "https://www.topuniversities.com/universities/university-victoria-uvic",
            "country": "Canada",
            "city": "Victoria",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-victoria-uvic_592560cf2aeae70239af4d0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 6.135,
            "size": "L",
            "faculty_count": 865
        },
        {
            "university": "Ural Federal University - UrFU",
            "year": 2020,
            "rank_display": 364,
            "score": 30.4,
            "link": "https://www.topuniversities.com/universities/ural-federal-university-urfu",
            "country": "Russia",
            "city": "Ekaterinburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ural-federal-university-urfu_592560cf2aeae70239af505a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5.089,
            "size": "L",
            "faculty_count": 5.009
        },
        {
            "university": "MGIMO University",
            "year": 2020,
            "rank_display": 366,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/mgimo-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-state-institute-of-international-relations-mgimo-university_2156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 1517,
            "size": "M",
            "faculty_count": 1739
        },
        {
            "university": "University of California, Santa Cruz",
            "year": 2020,
            "rank_display": 367,
            "score": 30.2,
            "link": "https://www.topuniversities.com/universities/university-california-santa-cruz",
            "country": "United States",
            "city": "Santa Cruz",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-cruz_92_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 2.13,
            "size": "L",
            "faculty_count": 1.045
        },
        {
            "university": "Nankai University",
            "year": 2020,
            "rank_display": 368,
            "score": 29.8,
            "link": "https://www.topuniversities.com/universities/nankai-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nankai-university_882_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.488,
            "size": "XL",
            "faculty_count": 2.846
        },
        {
            "university": "University of Kent",
            "year": 2020,
            "rank_display": 368,
            "score": 29.8,
            "link": "https://www.topuniversities.com/universities/university-kent",
            "country": "United Kingdom",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kent_315_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.515,
            "size": "L",
            "faculty_count": 4000
        },
        {
            "university": "Essex, University of",
            "year": 2020,
            "rank_display": 370,
            "score": 29.7,
            "link": "https://www.topuniversities.com/universities/essex-university",
            "country": "United Kingdom",
            "city": "Colchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-essex_592560cf2aeae70239af4b4c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6.124,
            "size": "L",
            "faculty_count": 1.044
        },
        {
            "university": "Tilburg University",
            "year": 2020,
            "rank_display": 371,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/tilburg-university",
            "country": "Netherlands",
            "city": "Tilburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tilburg-university_592560cf2aeae70239af4ce5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.189,
            "size": "M",
            "faculty_count": 745
        },
        {
            "university": "American University of Sharjah",
            "year": 2020,
            "rank_display": 371,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/american-university-sharjah",
            "country": "United Arab Emirates",
            "city": "Sharjah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-sharjah_1201_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.199,
            "size": "S",
            "faculty_count": 371
        },
        {
            "university": "University of Kansas",
            "year": 2020,
            "rank_display": 372,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/university-kansas",
            "country": "United States",
            "city": "Lawrence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kansas_309_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2.014,
            "size": "L",
            "faculty_count": 3.357
        },
        {
            "university": "University of Southern Denmark (SDU)",
            "year": 2020,
            "rank_display": 372,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/university-southern-denmark-sdu",
            "country": "Denmark",
            "city": "Odense",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-denmark_592560cf2aeae70239af4c53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2511,
            "size": "L",
            "faculty_count": 1760
        },
        {
            "university": "University of Oulu",
            "year": 2020,
            "rank_display": 374,
            "score": 29.3,
            "link": "https://www.topuniversities.com/universities/university-oulu",
            "country": "Finland",
            "city": "Oulu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oulu_476_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 579,
            "size": "M",
            "faculty_count": 883
        },
        {
            "university": "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
            "year": 2020,
            "rank_display": 375,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/pakistan-institute-engineering-applied-sciences-pieas",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pakistan-institute-of-engineering-and-applied-sciences-pieas_592560cf2aeae70239af532f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "Oxford Brookes University",
            "year": 2020,
            "rank_display": 376,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/oxford-brookes-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/oxford-brookes-university_592560cf2aeae70239af4c61_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 15,
            "international_students": 3.439,
            "size": "L",
            "faculty_count": 1471
        },
        {
            "university": "James Cook University",
            "year": 2020,
            "rank_display": 377,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/james-cook-university",
            "country": "Australia",
            "city": "Townsville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/james-cook-university_833_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.169,
            "size": "M",
            "faculty_count": 2940
        },
        {
            "university": "University of Connecticut",
            "year": 2020,
            "rank_display": 377,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/university-connecticut",
            "country": "United States",
            "city": "Storrs",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-connecticut_140_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3.765,
            "size": "L",
            "faculty_count": 1.328
        },
        {
            "university": "Sultan Qaboos University",
            "year": 2020,
            "rank_display": 379,
            "score": 28.9,
            "link": "https://www.topuniversities.com/universities/sultan-qaboos-university",
            "country": "Oman",
            "city": "Muscat",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sultan-qaboos-university_2530_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 248,
            "size": "M",
            "faculty_count": 916
        },
        {
            "university": "Taipei Medical University (TMU)",
            "year": 2020,
            "rank_display": 379,
            "score": 28.9,
            "link": "https://www.topuniversities.com/universities/taipei-medical-university-tmu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taipei-medical-university_2134_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 614,
            "size": "M",
            "faculty_count": 999
        },
        {
            "university": "Université de Strasbourg",
            "year": 2020,
            "rank_display": 379,
            "score": 28.9,
            "link": "https://www.topuniversities.com/universities/universite-de-strasbourg",
            "country": "France",
            "city": "Strasbourg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-strasbourg_578_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 9.111,
            "size": "XL",
            "faculty_count": 2.544
        },
        {
            "university": "Universiti Teknologi Brunei",
            "year": 2020,
            "rank_display": 379,
            "score": 28.9,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-brunei",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-brunei_592560e49988f300e23219b2_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 139
        },
        {
            "university": "Indian Institute of Technology Roorkee (IITR)",
            "year": 2020,
            "rank_display": 383,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-roorkee-iitr",
            "country": "India",
            "city": "Roorkee",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-roorkee-iitr_287_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 202,
            "size": "M",
            "faculty_count": 499
        },
        {
            "university": "Rensselaer Polytechnic Institute",
            "year": 2020,
            "rank_display": 383,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/rensselaer-polytechnic-institute",
            "country": "United States",
            "city": "Troy",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rensselaer-polytechnic-institute_522_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.594,
            "size": "M",
            "faculty_count": 639
        },
        {
            "university": "Swinburne University of Technology",
            "year": 2020,
            "rank_display": 383,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/swinburne-university-technology",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/swinburne-university-of-technology_759_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4000,
            "size": "L",
            "faculty_count": 946
        },
        {
            "university": "Universidad de Palermo (UP)",
            "year": 2020,
            "rank_display": 383,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/universidad-de-palermo",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-palermo-up_592560cf2aeae70239af54de_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.049,
            "size": "M",
            "faculty_count": 833
        },
        {
            "university": "National Research Tomsk Polytechnic University",
            "year": 2020,
            "rank_display": 387,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/national-research-tomsk-polytechnic-university",
            "country": "Russia",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-tomsk-polytechnic-university_14158_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.757,
            "size": "M",
            "faculty_count": 1.55
        },
        {
            "university": "University of Macau",
            "year": 2020,
            "rank_display": 387,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/university-macau",
            "country": "Macau SAR",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-macau_14378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.921,
            "size": "M",
            "faculty_count": 650
        },
        {
            "university": "University of Trento",
            "year": 2020,
            "rank_display": 389,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/university-trento",
            "country": "Italy",
            "city": "Trento",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-trento_623_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 738,
            "size": "L",
            "faculty_count": 1.066
        },
        {
            "university": "University of Pisa",
            "year": 2020,
            "rank_display": 389,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/university-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pisa_499_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 1.507,
            "size": "XL",
            "faculty_count": 1.796
        },
        {
            "university": "University of Tromsø The Arctic University of Norway",
            "year": 2020,
            "rank_display": 389,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/university-tromso-arctic-university-norway",
            "country": "Norway",
            "city": "Tromsø",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-troms-the-arctic-university-of-norway_625_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.134,
            "size": "L",
            "faculty_count": 2.107
        },
        {
            "university": "Kazan (Volga region) Federal University",
            "year": 2020,
            "rank_display": 392,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/kazan-volga-region-federal-university",
            "country": "Russia",
            "city": "Kazan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kazan-volga-region-federal-university_313_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 8.375,
            "size": "L",
            "faculty_count": 3.847
        },
        {
            "university": "RUDN University",
            "year": 2020,
            "rank_display": 392,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/rudn-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rudn-university_1503_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 8319,
            "size": "L",
            "faculty_count": 3890
        },
        {
            "university": "University of Colorado, Denver",
            "year": 2020,
            "rank_display": 392,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/university-colorado-denver",
            "country": "United States",
            "city": "Denver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-denver_2116_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 1.274,
            "size": "L",
            "faculty_count": 5.319
        },
        {
            "university": "Kobe University",
            "year": 2020,
            "rank_display": 395,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/kobe-university",
            "country": "Japan",
            "city": "Kobe City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kobe-university_320_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.216,
            "size": "L",
            "faculty_count": 1.986
        },
        {
            "university": "Tampere University",
            "year": 2020,
            "rank_display": 395,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/tampere-university",
            "country": "Finland",
            "city": "Tampere",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tampere-university_5bbf14847d023f5bc849ec9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 996,
            "size": "L",
            "faculty_count": 1.31
        },
        {
            "university": "The American University in Cairo",
            "year": 2020,
            "rank_display": 395,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/american-university-cairo",
            "country": "Egypt",
            "city": "Cairo",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/the-american-university-in-cairo_36_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 213,
            "size": "M",
            "faculty_count": 578
        },
        {
            "university": "University of St.Gallen (HSG)",
            "year": 2020,
            "rank_display": 398,
            "score": 28.3,
            "link": "https://www.topuniversities.com/universities/university-stgallen-hsg",
            "country": "Switzerland",
            "city": "St. Gallen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st.gallen-hsg_571_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 2.39,
            "size": "M",
            "faculty_count": 444
        },
        {
            "university": "Wake Forest University",
            "year": 2020,
            "rank_display": 398,
            "score": 28.3,
            "link": "https://www.topuniversities.com/universities/wake-forest-university",
            "country": "United States",
            "city": "Winston-Salem",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wake-forest-university_661_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 805,
            "size": "M",
            "faculty_count": 2.276
        },
        {
            "university": "Huazhong University of Science and Technology",
            "year": 2020,
            "rank_display": 400,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/huazhong-university-science-technology",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/huazhong-university-of-science-and-technology_592560cf2aeae70239af5256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.796,
            "size": "XL",
            "faculty_count": 4.014
        },
        {
            "university": "La Trobe University",
            "year": 2020,
            "rank_display": 400,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/la-trobe-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/la-trobe-university_592560cf2aeae70239af4bd1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 9.456,
            "size": "L",
            "faculty_count": 1.252
        },
        {
            "university": "National University of Sciences And Technology (NUST) Islamabad",
            "year": 2020,
            "rank_display": 400,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/national-university-sciences-technology-nust-islamabad",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-sciences-and-technology-nust-islamabad_698_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 563,
            "size": "M",
            "faculty_count": 1.608
        },
        {
            "university": "Universidad Austral",
            "year": 2020,
            "rank_display": 400,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/universidad-austral",
            "country": "Argentina",
            "city": "Pilar",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-austral_38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 334,
            "size": "S",
            "faculty_count": 663
        },
        {
            "university": "University of Witwatersrand",
            "year": 2020,
            "rank_display": 400,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/university-witwatersrand",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-witwatersrand_679_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.885,
            "size": "L",
            "faculty_count": 1.626
        },
        {
            "university": "Washington State University",
            "year": 2020,
            "rank_display": 400,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/washington-state-university",
            "country": "United States",
            "city": "Pullman",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-state-university_666_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.731,
            "size": "L",
            "faculty_count": 1.935
        },
        {
            "university": "University of Coimbra",
            "year": 2020,
            "rank_display": 406,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/university-coimbra",
            "country": "Portugal",
            "city": "Coimbra",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-coimbra_592560cf2aeae70239af4b07_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.477,
            "size": "L",
            "faculty_count": 1.74
        },
        {
            "university": "HUFS - Hankuk (Korea) University of Foreign Studies",
            "year": 2020,
            "rank_display": 407,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/hufs-hankuk-korea-university-foreign-studies",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hufs-hankuk-korea-university-of-foreign-studies_1054_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.933,
            "size": "L",
            "faculty_count": 2.353
        },
        {
            "university": "Sharif University of Technology",
            "year": 2020,
            "rank_display": 407,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/sharif-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sharif-university-of-technology_592560cf2aeae70239af4d42_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 198,
            "size": "M",
            "faculty_count": 495
        },
        {
            "university": "The University of Tennessee, Knoxville",
            "year": 2020,
            "rank_display": 407,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/university-tennessee-knoxville",
            "country": "United States",
            "city": "Knoxville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tennessee-knoxville_603_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 17,
            "international_students": 1156,
            "size": "XL",
            "faculty_count": 1700
        },
        {
            "university": "Johannes Gutenberg Universität Mainz",
            "year": 2020,
            "rank_display": 410,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/johannes-gutenberg-universitat-mainz",
            "country": "Germany",
            "city": "Mainz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-gutenberg-universitt-mainz_382_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.605,
            "size": "XL",
            "faculty_count": 3.473
        },
        {
            "university": "National Sun Yat-sen University",
            "year": 2020,
            "rank_display": 410,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/national-sun-yat-sen-university",
            "country": "Taiwan",
            "city": "Kaohsiung City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-sun-yat-sen-university_439_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 757,
            "size": "M",
            "faculty_count": 735
        },
        {
            "university": "Chung-Ang University (CAU)",
            "year": 2020,
            "rank_display": 412,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/chung-ang-university-cau",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chung-ang-university-cau_1042_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3.099,
            "size": "L",
            "faculty_count": 2.65
        },
        {
            "university": "Johannes Kepler University Linz",
            "year": 2020,
            "rank_display": 412,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/johannes-kepler-university-linz",
            "country": "Austria",
            "city": "Linz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-kepler-university-linz_349_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 774,
            "size": "M",
            "faculty_count": 897
        },
        {
            "university": "Shanghai University",
            "year": 2020,
            "rank_display": 412,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/shanghai-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-university_557_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.195,
            "size": "XL",
            "faculty_count": 4.156
        },
        {
            "university": "Université Laval",
            "year": 2020,
            "rank_display": 416,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/universite-laval",
            "country": "Canada",
            "city": "Québec",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/laval-university_337_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3.673,
            "size": "L",
            "faculty_count": 2.29
        },
        {
            "university": "Goldsmiths, University of London",
            "year": 2020,
            "rank_display": 417,
            "score": 27.6,
            "link": "https://www.topuniversities.com/universities/goldsmiths-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/goldsmiths-university-of-london_355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.492,
            "size": "M",
            "faculty_count": 683
        },
        {
            "university": "L.N. Gumilyov Eurasian National University (ENU)",
            "year": 2020,
            "rank_display": 418,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/ln-gumilyov-eurasian-national-university-enu",
            "country": "Kazakhstan",
            "city": "Nur-Sultan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/l.n.-gumilyov-eurasian-national-university-enu_592560cf2aeae70239af52e8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1.077,
            "size": "L",
            "faculty_count": 3.35
        },
        {
            "university": "Ben-Gurion University of The Negev",
            "year": 2020,
            "rank_display": 419,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/ben-gurion-university-negev",
            "country": "Israel",
            "city": "Be'er Sheva",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ben-gurion-university-of-the-negev_592560cf2aeae70239af4ab7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.755,
            "size": "L",
            "faculty_count": 1.06
        },
        {
            "university": "Tulane University",
            "year": 2020,
            "rank_display": 419,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/tulane-university",
            "country": "United States",
            "city": "New Orleans",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tulane-university_631_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.172,
            "size": "M",
            "faculty_count": 1.733
        },
        {
            "university": "Illinois Institute of Technology",
            "year": 2020,
            "rank_display": 421,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/illinois-institute-technology",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/illinois-institute-of-technology_277_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.33,
            "size": "M",
            "faculty_count": 402
        },
        {
            "university": "Universidade Nova de Lisboa",
            "year": 2020,
            "rank_display": 421,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/universidade-nova-de-lisboa",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-nova-de-lisboa_460_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.427,
            "size": "L",
            "faculty_count": 1.72
        },
        {
            "university": "University of Iowa",
            "year": 2020,
            "rank_display": 421,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/university-iowa",
            "country": "United States",
            "city": "Iowa City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-iowa_592560cf2aeae70239af4baa_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.049,
            "size": "L",
            "faculty_count": 2.924
        },
        {
            "university": "Flinders University",
            "year": 2020,
            "rank_display": 424,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/flinders-university",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/flinders-university_592560cf2aeae70239af4b53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 5.981,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "University of Naples - Federico II",
            "year": 2020,
            "rank_display": 424,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/university-naples-federico-ii",
            "country": "Italy",
            "city": "Naples",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-naples-federico-ii_432_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 128,
            "size": "XL",
            "faculty_count": 2.299
        },
        {
            "university": "Universität Konstanz",
            "year": 2020,
            "rank_display": 424,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/universitat-konstanz",
            "country": "Germany",
            "city": "Konstanz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-konstanz_323_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.239,
            "size": "M",
            "faculty_count": 1.039
        },
        {
            "university": "National Central University",
            "year": 2020,
            "rank_display": 427,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/national-central-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-central-university_434_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.098,
            "size": "M",
            "faculty_count": 985
        },
        {
            "university": "Stellenbosch University",
            "year": 2020,
            "rank_display": 427,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/stellenbosch-university",
            "country": "South Africa",
            "city": "Stellenbosch",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/stellenbosch-university_574_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 1.776,
            "size": "L",
            "faculty_count": 934
        },
        {
            "university": "Dublin City University",
            "year": 2020,
            "rank_display": 429,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/dublin-city-university",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/dublin-city-university_592560cf2aeae70239af4b27_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.415,
            "size": "L",
            "faculty_count": 1.257
        },
        {
            "university": "Tianjin University",
            "year": 2020,
            "rank_display": 429,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/tianjin-university",
            "country": "China (Mainland)",
            "city": "Nankai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tianjin-university_609_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.425,
            "size": "L",
            "faculty_count": 3.147
        },
        {
            "university": "Université de Liège",
            "year": 2020,
            "rank_display": 429,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/universite-de-liege",
            "country": "Belgium",
            "city": "Arlon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-lige_592560cf2aeae70239af4bd9_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.381,
            "size": "L",
            "faculty_count": 1.619
        },
        {
            "university": "Boston College",
            "year": 2020,
            "rank_display": 432,
            "score": 26.7,
            "link": "https://www.topuniversities.com/universities/boston-college",
            "country": "United States",
            "city": "Newton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-college_723_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.471,
            "size": "L",
            "faculty_count": 1.048
        },
        {
            "university": "Ruhr-Universität Bochum",
            "year": 2020,
            "rank_display": 432,
            "score": 26.7,
            "link": "https://www.topuniversities.com/universities/ruhr-universitat-bochum",
            "country": "Germany",
            "city": "Bochum",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruhr-universitt-bochum_537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 35,
            "international_students": 6.564,
            "size": "XL",
            "faculty_count": 1.232
        },
        {
            "university": "Universidad de Zaragoza",
            "year": 2020,
            "rank_display": 432,
            "score": 26.7,
            "link": "https://www.topuniversities.com/universities/universidad-de-zaragoza",
            "country": "Spain",
            "city": "Huesca",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-zaragoza_691_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.216,
            "size": "L",
            "faculty_count": 2.834
        },
        {
            "university": "Universidad Politécnica de Madrid (UPM)",
            "year": 2020,
            "rank_display": 435,
            "score": 26.5,
            "link": "https://www.topuniversities.com/universities/universidad-politecnica-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politcnica-de-madrid_1507_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5.319,
            "size": "XL",
            "faculty_count": 2.486
        },
        {
            "university": "Beijing Institute of Technology",
            "year": 2020,
            "rank_display": 436,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/beijing-institute-technology",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-institute-of-technology_868_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.115,
            "size": "XL",
            "faculty_count": 2.811
        },
        {
            "university": "ITMO University",
            "year": 2020,
            "rank_display": 436,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/itmo-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/itmo-university_592560cf2aeae70239af5829_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.337,
            "size": "M",
            "faculty_count": 1.849
        },
        {
            "university": "Universidad de Belgrano",
            "year": 2020,
            "rank_display": 438,
            "score": 26.3,
            "link": "https://www.topuniversities.com/universities/universidad-de-belgrano",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-belgrano_51_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 758,
            "size": "M",
            "faculty_count": 810
        },
        {
            "university": "Peter the Great St. Petersburg Polytechnic University",
            "year": 2020,
            "rank_display": 439,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/peter-great-st-petersburg-polytechnic-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/peter-the-great-st.-petersburg-polytechnic-university_592560cf2aeae70239af52ed_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5.121,
            "size": "L",
            "faculty_count": 2.355
        },
        {
            "university": "Universidade Federal de São Paulo",
            "year": 2020,
            "rank_display": 439,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/universidade-federal-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-de-so-paulo_2750_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 153,
            "size": "L",
            "faculty_count": 2.451
        },
        {
            "university": "University of Saskatchewan",
            "year": 2020,
            "rank_display": 439,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/university-saskatchewan",
            "country": "Canada",
            "city": "Saskatoon",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-saskatchewan_1755_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.382,
            "size": "L",
            "faculty_count": 1.415
        },
        {
            "university": "Aston University",
            "year": 2020,
            "rank_display": 442,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/aston-university",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aston-university_29_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1.719,
            "size": "M",
            "faculty_count": 730
        },
        {
            "university": "Auckland University of Technology (AUT)",
            "year": 2020,
            "rank_display": 442,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/auckland-university-technology-aut",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/auckland-university-of-technology-aut_592560cf2aeae70239af4ef7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8.926,
            "size": "L",
            "faculty_count": 1255
        },
        {
            "university": "Bond University",
            "year": 2020,
            "rank_display": 442,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/bond-university",
            "country": "Australia",
            "city": "Gold Coast",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/bond-university_715_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1.302,
            "size": "S",
            "faculty_count": 325
        },
        {
            "university": "Chiba University",
            "year": 2020,
            "rank_display": 442,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/chiba-university",
            "country": "Japan",
            "city": "Chiba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chiba-university_119_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 736,
            "size": "L",
            "faculty_count": 1.79
        },
        {
            "university": "Colorado State University",
            "year": 2020,
            "rank_display": 442,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/colorado-state-university",
            "country": "United States",
            "city": "Fort Collins",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/colorado-state-university_136_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.547,
            "size": "L",
            "faculty_count": 3.115
        },
        {
            "university": "UCSI University",
            "year": 2020,
            "rank_display": 442,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/ucsi-university",
            "country": "Malaysia",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ucsi-university_592560e09988f300e2320ce5_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1.597,
            "size": "M",
            "faculty_count": 744
        },
        {
            "university": "Florida State University",
            "year": 2020,
            "rank_display": 448,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/florida-state-university",
            "country": "United States",
            "city": "Tallahassee",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/florida-state-university_211_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 1.618,
            "size": "XL",
            "faculty_count": 2.236
        },
        {
            "university": "Hitotsubashi University",
            "year": 2020,
            "rank_display": 448,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/hitotsubashi-university",
            "country": "Japan",
            "city": "Kunitachi City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hitotsubashi-university-business-school-school-of-international-corporate-strategy_592560cf2aeae70239af4b8d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 802,
            "size": "M",
            "faculty_count": 571
        },
        {
            "university": "University of Florence",
            "year": 2020,
            "rank_display": 448,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/university-florence",
            "country": "Italy",
            "city": "Florence",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florence_208_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 4.203,
            "size": "XL",
            "faculty_count": 1.775
        },
        {
            "university": "Koç University",
            "year": 2020,
            "rank_display": 451,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/koc-university",
            "country": "Turkey",
            "city": "Istanbul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ko-university_592560cf2aeae70239af4d7e_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 368,
            "size": "M",
            "faculty_count": 560
        },
        {
            "university": "The National University of Science and Technology MISIS",
            "year": 2020,
            "rank_display": 451,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/national-university-science-technology-misis",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-national-university-of-science-and-technology-misis_14956_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.605,
            "size": "M",
            "faculty_count": 915
        },
        {
            "university": "Xiamen University",
            "year": 2020,
            "rank_display": 451,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/xiamen-university",
            "country": "China (Mainland)",
            "city": "Xiamen",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xiamen-university_891_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.443,
            "size": "XL",
            "faculty_count": 3.106
        },
        {
            "university": "Dongguk University",
            "year": 2020,
            "rank_display": 454,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/dongguk-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/dongguk-university_1048_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.27,
            "size": "L",
            "faculty_count": 2.668
        },
        {
            "university": "National Technical University of Athens",
            "year": 2020,
            "rank_display": 454,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/national-technical-university-athens",
            "country": "Greece",
            "city": "Athens",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-technical-university-of-athens_592560cf2aeae70239af4aa3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 846,
            "size": "L",
            "faculty_count": 689
        },
        {
            "university": "Sogang University",
            "year": 2020,
            "rank_display": 454,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/sogang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sogang-university_565_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.429,
            "size": "M",
            "faculty_count": 1.017
        },
        {
            "university": "University of California, Riverside",
            "year": 2020,
            "rank_display": 454,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/university-california-riverside",
            "country": "United States",
            "city": "Riverside",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-riverside_88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.034,
            "size": "L",
            "faculty_count": 1.497
        },
        {
            "university": "University of Bordeaux",
            "year": 2020,
            "rank_display": 458,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/university-bordeaux",
            "country": "France",
            "city": "Talence",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bordeaux_1286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5.643,
            "size": "XL",
            "faculty_count": 3.195
        },
        {
            "university": "Vilnius University",
            "year": 2020,
            "rank_display": 458,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/vilnius-university",
            "country": "Lithuania",
            "city": "Vilnius",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vilnius-university-_592560cf2aeae70239af4d7f_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.29,
            "size": "L",
            "faculty_count": 2.461
        },
        {
            "university": "University of Maryland, Baltimore County",
            "year": 2020,
            "rank_display": 460,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/university-maryland-baltimore-county",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-baltimore-county_592560cf2aeae70239af52af_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 1.074,
            "size": "M",
            "faculty_count": 834
        },
        {
            "university": "Oregon State University",
            "year": 2020,
            "rank_display": 461,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/oregon-state-university",
            "country": "United States",
            "city": "Corvallis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/oregon-state-university_592560cf2aeae70239af4dc3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.769,
            "size": "L",
            "faculty_count": 2.147
        },
        {
            "university": "Beihang University (former BUAA)",
            "year": 2020,
            "rank_display": 462,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/beihang-university-former-buaa",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beihang-university-former-buaa_870_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.575,
            "size": "XL",
            "faculty_count": 2.529
        },
        {
            "university": "Concordia University",
            "year": 2020,
            "rank_display": 462,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/concordia-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/concordia-university_592560cf2aeae70239af4b0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 11.717,
            "size": "XL",
            "faculty_count": 2282
        },
        {
            "university": "Julius-Maximilians-Universität Würzburg",
            "year": 2020,
            "rank_display": 462,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/julius-maximilians-universitat-wurzburg",
            "country": "Germany",
            "city": "Würzburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/julius-maximilians-universitt-wrzburg_592560cf2aeae70239af4d2d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 1.951,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Swansea University",
            "year": 2020,
            "rank_display": 462,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/swansea-university",
            "country": "United Kingdom",
            "city": "Swansea",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/swansea-university_592560cf2aeae70239af4cce_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 3.641,
            "size": "L",
            "faculty_count": 3290
        },
        {
            "university": "The Catholic University of Korea",
            "year": 2020,
            "rank_display": 462,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/catholic-university-korea",
            "country": "South Korea",
            "city": "Gyeonggi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-catholic-university-of-korea-_592560cf2aeae70239af4ed7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 370,
            "size": "M",
            "faculty_count": 1.668
        },
        {
            "university": "University of Science and Technology Beijing",
            "year": 2020,
            "rank_display": 462,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/university-science-technology-beijing",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-beijing_2004_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 818,
            "size": "L",
            "faculty_count": 1.605
        },
        {
            "university": "Brandeis University",
            "year": 2020,
            "rank_display": 468,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/brandeis-university",
            "country": "United States",
            "city": "Waltham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brandeis-university_66_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.554,
            "size": "M",
            "faculty_count": 611
        },
        {
            "university": "Pontificia Universidad Javeriana",
            "year": 2020,
            "rank_display": 468,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-javeriana",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-javeriana_592560cf2aeae70239af514a_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 415,
            "size": "L",
            "faculty_count": 2.082
        },
        {
            "university": "Shandong University",
            "year": 2020,
            "rank_display": 468,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/shandong-university",
            "country": "China (Mainland)",
            "city": "Jinan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shandong-university_555_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.637,
            "size": "XL",
            "faculty_count": 3.406
        },
        {
            "university": "Universität des Saarlandes",
            "year": 2020,
            "rank_display": 468,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/universitat-des-saarlandes",
            "country": "Germany",
            "city": "Saarbrücken",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-des-saarlandes_541_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 2.939,
            "size": "L",
            "faculty_count": 2.823
        },
        {
            "university": "Western Sydney University",
            "year": 2020,
            "rank_display": 468,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/western-sydney-university",
            "country": "Australia",
            "city": "Milperra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/western-sydney-university_848_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 22,
            "international_students": 7.123,
            "size": "XL",
            "faculty_count": 1.62
        },
        {
            "university": "Yokohama City University",
            "year": 2020,
            "rank_display": 468,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/yokohama-city-university",
            "country": "Japan",
            "city": "Yokohama City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yokohama-city-university_592560cf2aeae70239af4d32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 108,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Pontificia Universidad Católica del Perú",
            "year": 2020,
            "rank_display": 474,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-del-peru",
            "country": "Peru",
            "city": "Lima",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-del-per_592560cf2aeae70239af4af0_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 292,
            "size": "L",
            "faculty_count": 2369
        },
        {
            "university": "The University of Georgia",
            "year": 2020,
            "rank_display": 474,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/university-georgia",
            "country": "United States",
            "city": "Athens",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-georgia_227_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.879,
            "size": "XL",
            "faculty_count": 2.406
        },
        {
            "university": "University of Delhi",
            "year": 2020,
            "rank_display": 474,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/university-delhi",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-delhi_156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 322,
            "size": "L",
            "faculty_count": 1.171
        },
        {
            "university": "Singapore Management University",
            "year": 2020,
            "rank_display": 477,
            "score": 24.9,
            "link": "https://www.topuniversities.com/universities/singapore-management-university",
            "country": "Singapore",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/singapore-management-university_1177_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.04,
            "size": "M",
            "faculty_count": 590
        },
        {
            "university": "Christian-Albrechts-University zu Kiel",
            "year": 2020,
            "rank_display": 478,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/christian-albrechts-university-zu-kiel",
            "country": "Germany",
            "city": "Kiel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/christian-albrechts-university-zu-kiel_318_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.161,
            "size": "L",
            "faculty_count": 2.889
        },
        {
            "university": "University of Stirling",
            "year": 2020,
            "rank_display": 478,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/university-stirling",
            "country": "United Kingdom",
            "city": "Stirling",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-stirling_575_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.563,
            "size": "M",
            "faculty_count": 728
        },
        {
            "university": "South China University of Technology",
            "year": 2020,
            "rank_display": 480,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/south-china-university-technology",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/south-china-university-of-technology_887_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.688,
            "size": "XL",
            "faculty_count": 3.006
        },
        {
            "university": "Universidad Externado de Colombia",
            "year": 2020,
            "rank_display": 480,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/universidad-externado-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-externado-de-colombia_795_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 12,
            "international_students": 142,
            "size": "M",
            "faculty_count": 1.019
        },
        {
            "university": "UNESP",
            "year": 2020,
            "rank_display": 482,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/unesp",
            "country": "Brazil",
            "city": "SÃ£o Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/unesp_492_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.491,
            "size": "XL",
            "faculty_count": 2.794
        },
        {
            "university": "Universiti Teknologi PETRONAS (UTP)",
            "year": 2020,
            "rank_display": 482,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-petronas-utp",
            "country": "Malaysia",
            "city": "Seri Iskandar",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-petronas-utp_592560cf2aeae70239af4ef2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 639,
            "size": "M",
            "faculty_count": 438
        },
        {
            "university": "Aberystwyth University",
            "year": 2020,
            "rank_display": 484,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/aberystwyth-university",
            "country": "United Kingdom",
            "city": "Aberystwyth",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aberystwyth-university_8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.392,
            "size": "M",
            "faculty_count": 516
        },
        {
            "university": "Chang Gung University",
            "year": 2020,
            "rank_display": 484,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/chang-gung-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chang-gung-university_1187_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 381,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Jilin University",
            "year": 2020,
            "rank_display": 484,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/jilin-university",
            "country": "China (Mainland)",
            "city": "Changchun",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/jilin-university_304_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1.57,
            "size": "XL",
            "faculty_count": 7.078
        },
        {
            "university": "University of Canberra",
            "year": 2020,
            "rank_display": 484,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/university-canberra",
            "country": "Australia",
            "city": "Bruce",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canberra_844_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 27,
            "international_students": 3.132,
            "size": "M",
            "faculty_count": 395
        },
        {
            "university": "Wayne State University",
            "year": 2020,
            "rank_display": 484,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/wayne-state-university",
            "country": "United States",
            "city": "Detroit",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wayne-state-university_592560cf2aeae70239af51d1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.278,
            "size": "L",
            "faculty_count": 2.699
        },
        {
            "university": "Amirkabir University of Technology",
            "year": 2020,
            "rank_display": 489,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/amirkabir-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/amirkabir-university-of-technology_592560cf2aeae70239af4e54_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 235,
            "size": "M",
            "faculty_count": 525
        },
        {
            "university": "Universidad de Santiago de Chile (USACH)",
            "year": 2020,
            "rank_display": 489,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/universidad-de-santiago-de-chile-usach",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-santiago-de-chile-usach_548_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 17,
            "international_students": 503,
            "size": "L",
            "faculty_count": 1.334
        },
        {
            "university": "Indian Institute of Technology Guwahati (IITG)",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-guwahati-iitg",
            "country": "India",
            "city": "Guwahati",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-guwahati-iitg_283_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 42,
            "size": "M",
            "faculty_count": 410
        },
        {
            "university": "Lappeenranta-Lahti University of Technology LUT",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/lappeenranta-lahti-university-technology-lut",
            "country": "Finland",
            "city": "Lappeenranta",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lappeenranta-university-of-technology_592560cf2aeae70239af4f7d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 399,
            "size": "S",
            "faculty_count": 298
        },
        {
            "university": "Auezov South Kazakhstan University (SKU)",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/auezov-south-kazakhstan-university-sku",
            "country": "Kazakhstan",
            "city": "Shymkent",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/auezov-south-kazakhstan-state-university-sksu_592560cf2aeae70239af52eb_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.22,
            "size": "L",
            "faculty_count": 2.902
        },
        {
            "university": "Universidad de Montevideo (UM)",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/universidad-de-montevideo-um",
            "country": "Uruguay",
            "city": "Montevideo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-montevideo-um_695_small.jpg",
            "type": "Private",
            "research_output": "Low",
            "student_faculty_ratio": 7,
            "international_students": 76,
            "size": "S",
            "faculty_count": 295
        },
        {
            "university": "Aix-Marseille University",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/aix-marseille-university",
            "country": "France",
            "city": "Marseille",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aix-marseille-university_592560cf2aeae70239af51e1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8.651,
            "size": "XL",
            "faculty_count": 4.069
        },
        {
            "university": "University of Delaware",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/university-delaware",
            "country": "United States",
            "city": "Newark",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-delaware_154_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.337,
            "size": "L",
            "faculty_count": 1.272
        },
        {
            "university": "V. N. Karazin Kharkiv National University",
            "year": 2020,
            "rank_display": 491,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/v-n-karazin-kharkiv-national-university",
            "country": "Ukraine",
            "city": "Kharkiv",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/v.-n.-karazin-kharkiv-national-university_19511_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3.954,
            "size": "L",
            "faculty_count": 2.091
        },
        {
            "university": "Czech Technical University in Prague",
            "year": 2020,
            "rank_display": 498,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/czech-technical-university-prague",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/czech-technical-university-in-prague_592560cf2aeae70239af4d3b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3.006,
            "size": "L",
            "faculty_count": 2.628
        },
        {
            "university": "Université de Montpellier",
            "year": 2020,
            "rank_display": 498,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/universite-de-montpellier",
            "country": "France",
            "city": "Montpellier",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montpellier_592560e69988f300e2321d99_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6.229,
            "size": "XL",
            "faculty_count": 2.464
        },
        {
            "university": "University of Eastern Finland",
            "year": 2020,
            "rank_display": 498,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/university-eastern-finland",
            "country": "Finland",
            "city": "Kuopio",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-eastern-finland_592560cf2aeae70239af4bcb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 669,
            "size": "M",
            "faculty_count": 1.207
        },
        {
            "university": "Massachusetts Institute of Technology (MIT)",
            "year": 2021,
            "rank_display": 1,
            "score": 100,
            "link": "https://www.topuniversities.com/universities/massachusetts-institute-technology-mit",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/massachusetts-institute-of-technology-mit_410_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 3730,
            "size": "M",
            "faculty_count": 3065
        },
        {
            "university": "Stanford University",
            "year": 2021,
            "rank_display": 2,
            "score": 98.4,
            "link": "https://www.topuniversities.com/universities/stanford-university",
            "country": "United States",
            "city": "Stanford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stanford-university_573_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3879,
            "size": "L",
            "faculty_count": 4725
        },
        {
            "university": "Harvard University",
            "year": 2021,
            "rank_display": 3,
            "score": 97.9,
            "link": "https://www.topuniversities.com/universities/harvard-university",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/harvard-university_253_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5877,
            "size": "L",
            "faculty_count": 4646
        },
        {
            "university": "California Institute of Technology (Caltech)",
            "year": 2021,
            "rank_display": 4,
            "score": 97,
            "link": "https://www.topuniversities.com/universities/california-institute-technology-caltech",
            "country": "United States",
            "city": "Pasadena",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/california-institute-of-technology-caltech_94_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 692,
            "size": "S",
            "faculty_count": 968
        },
        {
            "university": "University of Oxford",
            "year": 2021,
            "rank_display": 5,
            "score": 96.7,
            "link": "https://www.topuniversities.com/universities/university-oxford",
            "country": "United Kingdom",
            "city": "Oxford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oxford_478_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 8442,
            "size": "L",
            "faculty_count": 6708
        },
        {
            "university": "ETH Zurich - Swiss Federal Institute of Technology",
            "year": 2021,
            "rank_display": 6,
            "score": 95,
            "link": "https://www.topuniversities.com/universities/eth-zurich-swiss-federal-institute-technology",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eth-zurich-swiss-federal-institute-of-technology_201_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7733,
            "size": "L",
            "faculty_count": 2719
        },
        {
            "university": "University of Cambridge",
            "year": 2021,
            "rank_display": 7,
            "score": 94.3,
            "link": "https://www.topuniversities.com/universities/university-cambridge",
            "country": "United Kingdom",
            "city": "Cambridge",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cambridge_95_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 4,
            "international_students": 7925,
            "size": "L",
            "faculty_count": 5800
        },
        {
            "university": "Imperial College London",
            "year": 2021,
            "rank_display": 8,
            "score": 93.6,
            "link": "https://www.topuniversities.com/universities/imperial-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/imperial-college-london_592560cf2aeae70239af4be8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11143,
            "size": "L",
            "faculty_count": 8000
        },
        {
            "university": "University of Chicago",
            "year": 2021,
            "rank_display": 9,
            "score": 93.1,
            "link": "https://www.topuniversities.com/universities/university-chicago",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chicago_120_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4696,
            "size": "L",
            "faculty_count": 2703
        },
        {
            "university": "UCL",
            "year": 2021,
            "rank_display": 10,
            "score": 92.9,
            "link": "https://www.topuniversities.com/universities/ucl",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ucl_592560cf2aeae70239af4bf1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 21824,
            "size": "XL",
            "faculty_count": 7195
        },
        {
            "university": "National University of Singapore (NUS)",
            "year": 2021,
            "rank_display": 11,
            "score": 91.5,
            "link": "https://www.topuniversities.com/universities/national-university-singapore-nus",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-singapore-nus_443_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7551,
            "size": "XL",
            "faculty_count": 4288
        },
        {
            "university": "Princeton University",
            "year": 2021,
            "rank_display": 12,
            "score": 91,
            "link": "https://www.topuniversities.com/universities/princeton-university",
            "country": "United States",
            "city": "Princeton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/princeton-university_508_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1912,
            "size": "M",
            "faculty_count": 1050
        },
        {
            "university": "Nanyang Technological University, Singapore (NTU)",
            "year": 2021,
            "rank_display": 13,
            "score": 89.9,
            "link": "https://www.topuniversities.com/universities/nanyang-technological-university-singapore-ntu",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanyang-technological-university-singapore-ntu_592560cf2aeae70239af4c32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6091,
            "size": "L",
            "faculty_count": 3812
        },
        {
            "university": "EPFL",
            "year": 2021,
            "rank_display": 14,
            "score": 89.6,
            "link": "https://www.topuniversities.com/universities/epfl",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/epfl-ecole-polytechnique-federale-de-lausanne_592560cf2aeae70239af4b34_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6426,
            "size": "M",
            "faculty_count": 1767
        },
        {
            "university": "Tsinghua University",
            "year": 2021,
            "rank_display": 15,
            "score": 89.2,
            "link": "https://www.topuniversities.com/universities/tsinghua-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tsinghua-university_626_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5420,
            "size": "XL",
            "faculty_count": 6174
        },
        {
            "university": "University of Pennsylvania",
            "year": 2021,
            "rank_display": 16,
            "score": 88.6,
            "link": "https://www.topuniversities.com/universities/university-pennsylvania",
            "country": "United States",
            "city": "Philadelphia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pennsylvania_495_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4636,
            "size": "L",
            "faculty_count": 5154
        },
        {
            "university": "Yale University",
            "year": 2021,
            "rank_display": 17,
            "score": 88,
            "link": "https://www.topuniversities.com/universities/yale-university",
            "country": "United States",
            "city": "New Haven",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/yale-university_684_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 2537,
            "size": "M",
            "faculty_count": 5391
        },
        {
            "university": "Cornell University",
            "year": 2021,
            "rank_display": 18,
            "score": 87.6,
            "link": "https://www.topuniversities.com/universities/cornell-university",
            "country": "United States",
            "city": "Ithaca",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/cornell-university_143_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Columbia University",
            "year": 2021,
            "rank_display": 19,
            "score": 86.5,
            "link": "https://www.topuniversities.com/universities/columbia-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/columbia-university_138_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 11252,
            "size": "L",
            "faculty_count": 7087
        },
        {
            "university": "The University of Edinburgh",
            "year": 2021,
            "rank_display": 20,
            "score": 85.8,
            "link": "https://www.topuniversities.com/universities/university-edinburgh",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-edinburgh_180_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 14637,
            "size": "XL",
            "faculty_count": 4832
        },
        {
            "university": "University of Michigan-Ann Arbor",
            "year": 2021,
            "rank_display": 21,
            "score": 84.6,
            "link": "https://www.topuniversities.com/universities/university-michigan-ann-arbor",
            "country": "United States",
            "city": "Ann Arbor",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-michigan_403_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 7341,
            "size": "XL",
            "faculty_count": 7132
        },
        {
            "university": "The University of Hong Kong",
            "year": 2021,
            "rank_display": 22,
            "score": 83.7,
            "link": "https://www.topuniversities.com/universities/university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-hong-kong_268_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 8311,
            "size": "L",
            "faculty_count": 2944
        },
        {
            "university": "Peking University",
            "year": 2021,
            "rank_display": 23,
            "score": 83.5,
            "link": "https://www.topuniversities.com/universities/peking-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/peking-university_50_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5436,
            "size": "XL",
            "faculty_count": 5302
        },
        {
            "university": "The University of Tokyo",
            "year": 2021,
            "rank_display": 24,
            "score": 83.2,
            "link": "https://www.topuniversities.com/universities/university-tokyo",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tokyo_615_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3983,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Johns Hopkins University",
            "year": 2021,
            "rank_display": 25,
            "score": 82.7,
            "link": "https://www.topuniversities.com/universities/johns-hopkins-university",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/johns-hopkins-university_305_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 5070,
            "size": "L",
            "faculty_count": 4855
        },
        {
            "university": "University of Toronto",
            "year": 2021,
            "rank_display": 25,
            "score": 82.7,
            "link": "https://www.topuniversities.com/universities/university-toronto",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-toronto_619_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 8,
            "international_students": 27536,
            "size": "XL",
            "faculty_count": 9881
        },
        {
            "university": "The Hong Kong University of Science and Technology",
            "year": 2021,
            "rank_display": 27,
            "score": 82.1,
            "link": "https://www.topuniversities.com/universities/hong-kong-university-science-technology",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-university-of-science-and-technology_269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3033,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "The University of Manchester",
            "year": 2021,
            "rank_display": 27,
            "score": 82.1,
            "link": "https://www.topuniversities.com/universities/university-manchester",
            "country": "United Kingdom",
            "city": "Manchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-manchester_389_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 16079,
            "size": "XL",
            "faculty_count": 4889
        },
        {
            "university": "Northwestern University",
            "year": 2021,
            "rank_display": 29,
            "score": 81.5,
            "link": "https://www.topuniversities.com/universities/northwestern-university",
            "country": "United States",
            "city": "Evanston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northwestern-university_592560cf2aeae70239af4c50_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3846,
            "size": "L",
            "faculty_count": 3652
        },
        {
            "university": "University of California, Berkeley (UCB)",
            "year": 2021,
            "rank_display": 30,
            "score": 80.8,
            "link": "https://www.topuniversities.com/universities/university-california-berkeley-ucb",
            "country": "United States",
            "city": "Berkeley",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-berkeley-ucb_84_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 9428,
            "size": "XL",
            "faculty_count": 3701
        },
        {
            "university": "The Australian National University",
            "year": 2021,
            "rank_display": 31,
            "score": 80,
            "link": "https://www.topuniversities.com/universities/australian-national-university",
            "country": "Australia",
            "city": "Canberra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-australian-national-university_40_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6496,
            "size": "L",
            "faculty_count": 1763
        },
        {
            "university": "King's College London",
            "year": 2021,
            "rank_display": 31,
            "score": 80,
            "link": "https://www.topuniversities.com/universities/kings-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kings-college-london_357_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 15075,
            "size": "L",
            "faculty_count": 4216
        },
        {
            "university": "McGill University",
            "year": 2021,
            "rank_display": 31,
            "score": 80,
            "link": "https://www.topuniversities.com/universities/mcgill-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcgill-university_592560cf2aeae70239af4c10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 10827,
            "size": "L",
            "faculty_count": 3876
        },
        {
            "university": "Fudan University",
            "year": 2021,
            "rank_display": 34,
            "score": 79.9,
            "link": "https://www.topuniversities.com/universities/fudan-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/fudan-university_218_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5322,
            "size": "L",
            "faculty_count": 4526
        },
        {
            "university": "New York University (NYU)",
            "year": 2021,
            "rank_display": 35,
            "score": 79.4,
            "link": "https://www.topuniversities.com/universities/new-york-university-nyu",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/new-york-university-nyu_448_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 16218,
            "size": "XL",
            "faculty_count": 7874
        },
        {
            "university": "University of California, Los Angeles (UCLA)",
            "year": 2021,
            "rank_display": 36,
            "score": 79.2,
            "link": "https://www.topuniversities.com/universities/university-california-los-angeles-ucla",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-los-angeles-ucla_87_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6140,
            "size": "XL",
            "faculty_count": 4487
        },
        {
            "university": "Seoul National University",
            "year": 2021,
            "rank_display": 37,
            "score": 79,
            "link": "https://www.topuniversities.com/universities/seoul-national-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/graduate-school-of-business-seoul-national-university_553_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1914,
            "size": "L",
            "faculty_count": 3859
        },
        {
            "university": "Kyoto University",
            "year": 2021,
            "rank_display": 38,
            "score": 78.9,
            "link": "https://www.topuniversities.com/universities/kyoto-university",
            "country": "Japan",
            "city": "Kyoto",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyoto-university_328_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2863,
            "size": "L",
            "faculty_count": 3911
        },
        {
            "university": "KAIST - Korea Advanced Institute of Science & Technology",
            "year": 2021,
            "rank_display": 39,
            "score": 78.6,
            "link": "https://www.topuniversities.com/universities/kaist-korea-advanced-institute-science-technology",
            "country": "South Korea",
            "city": "Daejeon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kaist-korea-advanced-institute-of-science-technology_324_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 869,
            "size": "M",
            "faculty_count": 1307
        },
        {
            "university": "The University of Sydney",
            "year": 2021,
            "rank_display": 40,
            "score": 77.1,
            "link": "https://www.topuniversities.com/universities/university-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sydney_592560cf2aeae70239af4cd0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 25940,
            "size": "XL",
            "faculty_count": 3571
        },
        {
            "university": "The University of Melbourne",
            "year": 2021,
            "rank_display": 41,
            "score": 76.5,
            "link": "https://www.topuniversities.com/universities/university-melbourne",
            "country": "Australia",
            "city": "Parkville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-melbourne_399_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 19,
            "international_students": 21406,
            "size": "S",
            "faculty_count": 4835
        },
        {
            "university": "Duke University",
            "year": 2021,
            "rank_display": 42,
            "score": 76.4,
            "link": "https://www.topuniversities.com/universities/duke-university",
            "country": "United States",
            "city": "Durham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/duke-university_168_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3445,
            "size": "L",
            "faculty_count": 5673
        },
        {
            "university": "The Chinese University of Hong Kong (CUHK)",
            "year": 2021,
            "rank_display": 43,
            "score": 75.8,
            "link": "https://www.topuniversities.com/universities/chinese-university-hong-kong-cuhk",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-chinese-university-of-hong-kong-cuhk_123_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6062,
            "size": "L",
            "faculty_count": 2201
        },
        {
            "university": "The University of New South Wales (UNSW Sydney)",
            "year": 2021,
            "rank_display": 44,
            "score": 75.4,
            "link": "https://www.topuniversities.com/universities/university-new-south-wales-unsw-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-new-south-wales-unsw-sydney_447_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 21603,
            "size": "XL",
            "faculty_count": 3219
        },
        {
            "university": "University of British Columbia",
            "year": 2021,
            "rank_display": 45,
            "score": 74.8,
            "link": "https://www.topuniversities.com/universities/university-british-columbia",
            "country": "Canada",
            "city": "Vancouver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-british-columbia_70_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 15399,
            "size": "XL",
            "faculty_count": 6201
        },
        {
            "university": "The University of Queensland",
            "year": 2021,
            "rank_display": 46,
            "score": 74.5,
            "link": "https://www.topuniversities.com/universities/university-queensland",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-queensland_515_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 22241,
            "size": "XL",
            "faculty_count": 3513
        },
        {
            "university": "Shanghai Jiao Tong University",
            "year": 2021,
            "rank_display": 47,
            "score": 74.4,
            "link": "https://www.topuniversities.com/universities/shanghai-jiao-tong-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-jiao-tong-university_556_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6371,
            "size": "XL",
            "faculty_count": 4386
        },
        {
            "university": "City University of Hong Kong",
            "year": 2021,
            "rank_display": 48,
            "score": 74.3,
            "link": "https://www.topuniversities.com/universities/city-university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-hong-kong_592560cf2aeae70239af4b05_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5331,
            "size": "M",
            "faculty_count": 1483
        },
        {
            "university": "The London School of Economics and Political Science (LSE)",
            "year": 2021,
            "rank_display": 49,
            "score": 73.9,
            "link": "https://www.topuniversities.com/universities/london-school-economics-political-science-lse",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/london-school-of-economics-and-political-science-lse_362_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8274,
            "size": "M",
            "faculty_count": 1172
        },
        {
            "university": "Technical University of Munich",
            "year": 2021,
            "rank_display": 50,
            "score": 73.6,
            "link": "https://www.topuniversities.com/universities/technical-university-munich",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-munich_599_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11447,
            "size": "XL",
            "faculty_count": 6663
        },
        {
            "university": "Carnegie Mellon University",
            "year": 2021,
            "rank_display": 51,
            "score": 73.4,
            "link": "https://www.topuniversities.com/universities/carnegie-mellon-university",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/carnegie-mellon-university_101_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5739,
            "size": "L",
            "faculty_count": 1534
        },
        {
            "university": "Université PSL",
            "year": 2021,
            "rank_display": 52,
            "score": 73.3,
            "link": "https://www.topuniversities.com/universities/universite-psl",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-psl_592560e69988f300e2321dfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4922,
            "size": "L",
            "faculty_count": 3914
        },
        {
            "university": "Zhejiang University",
            "year": 2021,
            "rank_display": 53,
            "score": 71.9,
            "link": "https://www.topuniversities.com/universities/zhejiang-university",
            "country": "China (Mainland)",
            "city": "Hangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/zhejiang-university_592560cf2aeae70239af4d37_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 9177,
            "size": "XL",
            "faculty_count": 6065
        },
        {
            "university": "University of California, San Diego (UCSD)",
            "year": 2021,
            "rank_display": 54,
            "score": 71.2,
            "link": "https://www.topuniversities.com/universities/university-california-san-diego-ucsd",
            "country": "United States",
            "city": "San Diego",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-san-diego-ucsd_89_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 8567,
            "size": "XL",
            "faculty_count": 4545
        },
        {
            "university": "Monash University",
            "year": 2021,
            "rank_display": 55,
            "score": 70.9,
            "link": "https://www.topuniversities.com/universities/monash-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/monash-university_412_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 31049,
            "size": "XL",
            "faculty_count": 3852
        },
        {
            "university": "Tokyo Institute of Technology (Tokyo Tech)",
            "year": 2021,
            "rank_display": 56,
            "score": 70.8,
            "link": "https://www.topuniversities.com/universities/tokyo-institute-technology-tokyo-tech",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-institute-of-technology_592560cf2aeae70239af4ceb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1622,
            "size": "M",
            "faculty_count": 1492
        },
        {
            "university": "Delft University of Technology",
            "year": 2021,
            "rank_display": 57,
            "score": 70.7,
            "link": "https://www.topuniversities.com/universities/delft-university-technology",
            "country": "Netherlands",
            "city": "Delft",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/delft-university-of-technology_155_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6104,
            "size": "L",
            "faculty_count": 2041
        },
        {
            "university": "University of Bristol",
            "year": 2021,
            "rank_display": 58,
            "score": 70.6,
            "link": "https://www.topuniversities.com/universities/university-bristol",
            "country": "United Kingdom",
            "city": "Bristol",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bristol_69_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 7483,
            "size": "L",
            "faculty_count": 20311
        },
        {
            "university": "Universiti Malaya (UM)",
            "year": 2021,
            "rank_display": 59,
            "score": 70.1,
            "link": "https://www.topuniversities.com/universities/universiti-malaya-um",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-malaya-um_383_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3340,
            "size": "L",
            "faculty_count": 2386
        },
        {
            "university": "Brown University",
            "year": 2021,
            "rank_display": 60,
            "score": 70,
            "link": "https://www.topuniversities.com/universities/brown-university",
            "country": "United States",
            "city": "Providence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brown-university_72_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2002,
            "size": "M",
            "faculty_count": 1389
        },
        {
            "university": "University of Amsterdam",
            "year": 2021,
            "rank_display": 61,
            "score": 69.9,
            "link": "https://www.topuniversities.com/universities/university-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-amsterdam_18_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7969,
            "size": "XL",
            "faculty_count": 6000
        },
        {
            "university": "Ecole Polytechnique",
            "year": 2021,
            "rank_display": 61,
            "score": 69.9,
            "link": "https://www.topuniversities.com/universities/ecole-polytechnique",
            "country": "France",
            "city": "Palaiseau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-polytechnique_592560cf2aeae70239af4b36_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1248,
            "size": "S",
            "faculty_count": 633
        },
        {
            "university": "The University of Warwick",
            "year": 2021,
            "rank_display": 62,
            "score": 69,
            "link": "https://www.topuniversities.com/universities/university-warwick",
            "country": "United Kingdom",
            "city": "Coventry",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-warwick_664_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10459,
            "size": "L",
            "faculty_count": 2634
        },
        {
            "university": "Ludwig-Maximilians-Universität München",
            "year": 2021,
            "rank_display": 63,
            "score": 68.9,
            "link": "https://www.topuniversities.com/universities/ludwig-maximilians-universitat-munchen",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ludwig-maximilians-universitt-mnchen_420_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5958,
            "size": "XL",
            "faculty_count": 3914
        },
        {
            "university": "Ruprecht-Karls-Universität Heidelberg",
            "year": 2021,
            "rank_display": 64,
            "score": 68.6,
            "link": "https://www.topuniversities.com/universities/ruprecht-karls-universitat-heidelberg",
            "country": "Germany",
            "city": "Heidelberg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruprecht-karls-universitaet-heidelberg_259_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4048,
            "size": "L",
            "faculty_count": 4100
        },
        {
            "university": "University of Wisconsin-Madison",
            "year": 2021,
            "rank_display": 65,
            "score": 68.2,
            "link": "https://www.topuniversities.com/universities/university-wisconsin-madison",
            "country": "United States",
            "city": "Madison",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wisconsin-madison_678_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5737,
            "size": "XL",
            "faculty_count": 5166
        },
        {
            "university": "National Taiwan University (NTU)",
            "year": 2021,
            "rank_display": 66,
            "score": 67.5,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-ntu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-ntu_592560cf2aeae70239af4c3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5058,
            "size": "L",
            "faculty_count": 2939
        },
        {
            "university": "Universidad de Buenos Aires (UBA)",
            "year": 2021,
            "rank_display": 66,
            "score": 67.5,
            "link": "https://www.topuniversities.com/universities/universidad-de-buenos-aires-uba",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-buenos-aires-uba_78_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 29516,
            "size": "XL",
            "faculty_count": 16103
        },
        {
            "university": "Korea University",
            "year": 2021,
            "rank_display": 69,
            "score": 67.1,
            "link": "https://www.topuniversities.com/universities/korea-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/korea-university_325_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3325,
            "size": "L",
            "faculty_count": 4026
        },
        {
            "university": "University of Zurich",
            "year": 2021,
            "rank_display": 69,
            "score": 67.1,
            "link": "https://www.topuniversities.com/universities/university-zurich",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-zurich_693_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5044,
            "size": "L",
            "faculty_count": 3761
        },
        {
            "university": "University of Texas at Austin",
            "year": 2021,
            "rank_display": 71,
            "score": 66.7,
            "link": "https://www.topuniversities.com/universities/university-texas-austin",
            "country": "United States",
            "city": "Austin",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-at-austin_604_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 4953,
            "size": "XL",
            "faculty_count": 2887
        },
        {
            "university": "Osaka University",
            "year": 2021,
            "rank_display": 72,
            "score": 66.1,
            "link": "https://www.topuniversities.com/universities/osaka-university",
            "country": "Japan",
            "city": "Osaka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/osaka-university_472_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2268,
            "size": "L",
            "faculty_count": 3127
        },
        {
            "university": "University of Washington",
            "year": 2021,
            "rank_display": 72,
            "score": 66.1,
            "link": "https://www.topuniversities.com/universities/university-washington",
            "country": "United States",
            "city": "Seattle",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-washington_592560cf2aeae70239af4d1e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 7362,
            "size": "XL",
            "faculty_count": 2796
        },
        {
            "university": "Lomonosov Moscow State University",
            "year": 2021,
            "rank_display": 74,
            "score": 65.9,
            "link": "https://www.topuniversities.com/universities/lomonosov-moscow-state-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lomonosov-moscow-state-university-_592560cf2aeae70239af4c27_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5476,
            "size": "XL",
            "faculty_count": 6575
        },
        {
            "university": "The Hong Kong Polytechnic University",
            "year": 2021,
            "rank_display": 75,
            "score": 65.8,
            "link": "https://www.topuniversities.com/universities/hong-kong-polytechnic-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-polytechnic-university_267_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5774,
            "size": "L",
            "faculty_count": 2822
        },
        {
            "university": "University of Copenhagen",
            "year": 2021,
            "rank_display": 76,
            "score": 65.5,
            "link": "https://www.topuniversities.com/universities/university-copenhagen",
            "country": "Denmark",
            "city": "Copenhagen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-copenhagen_141_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4135,
            "size": "XL",
            "faculty_count": 7293
        },
        {
            "university": "Pohang University of Science And Technology (POSTECH)",
            "year": 2021,
            "rank_display": 77,
            "score": 64.6,
            "link": "https://www.topuniversities.com/universities/pohang-university-science-technology-postech",
            "country": "South Korea",
            "city": "Pohang",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pohang-university-of-science-and-technology-postech_592560cf2aeae70239af4c7b_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 87,
            "size": "S",
            "faculty_count": 743
        },
        {
            "university": "University of Glasgow",
            "year": 2021,
            "rank_display": 77,
            "score": 64.6,
            "link": "https://www.topuniversities.com/universities/university-glasgow",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-glasgow_592560cf2aeae70239af4b6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 11065,
            "size": "L",
            "faculty_count": 3314
        },
        {
            "university": "Tohoku University",
            "year": 2021,
            "rank_display": 79,
            "score": 64.3,
            "link": "https://www.topuniversities.com/universities/tohoku-university",
            "country": "Japan",
            "city": "Sendai City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tohoku-university_611_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1872,
            "size": "L",
            "faculty_count": 3323
        },
        {
            "university": "Georgia Institute of Technology",
            "year": 2021,
            "rank_display": 80,
            "score": 64.1,
            "link": "https://www.topuniversities.com/universities/georgia-institute-technology",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgia-institute-of-technology_225_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 1267
        },
        {
            "university": "The University of Auckland",
            "year": 2021,
            "rank_display": 81,
            "score": 64,
            "link": "https://www.topuniversities.com/universities/university-auckland",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-auckland_592560cf2aeae70239af4aa8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9924,
            "size": "L",
            "faculty_count": 2316
        },
        {
            "university": "University of Illinois at Urbana-Champaign",
            "year": 2021,
            "rank_display": 82,
            "score": 63.4,
            "link": "https://www.topuniversities.com/universities/university-illinois-urbana-champaign",
            "country": "United States",
            "city": "Champaign",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-at-urbana-champaign_2090_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 9987,
            "size": "XL",
            "faculty_count": 2748
        },
        {
            "university": "Sorbonne University",
            "year": 2021,
            "rank_display": 83,
            "score": 63.3,
            "link": "https://www.topuniversities.com/universities/sorbonne-university",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sorbonne-university_5a6afcfecb4de709397114fe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "KU Leuven",
            "year": 2021,
            "rank_display": 84,
            "score": 63,
            "link": "https://www.topuniversities.com/universities/ku-leuven",
            "country": "Belgium",
            "city": "Leuven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ku-leuven_592560cf2aeae70239af4aec_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 8485,
            "size": "XL",
            "faculty_count": 2677
        },
        {
            "university": "Durham University",
            "year": 2021,
            "rank_display": 86,
            "score": 62.2,
            "link": "https://www.topuniversities.com/universities/durham-university",
            "country": "United Kingdom",
            "city": "Durham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/durham-university_170_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6174,
            "size": "L",
            "faculty_count": 1775
        },
        {
            "university": "Yonsei University",
            "year": 2021,
            "rank_display": 85,
            "score": 62.5,
            "link": "https://www.topuniversities.com/universities/yonsei-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yonsei-university_688_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4274,
            "size": "L",
            "faculty_count": 3605
        },
        {
            "university": "University of Birmingham",
            "year": 2021,
            "rank_display": 87,
            "score": 62,
            "link": "https://www.topuniversities.com/universities/university-birmingham",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-birmingham_59_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10457,
            "size": "XL",
            "faculty_count": 3586
        },
        {
            "university": "Sungkyunkwan University(SKKU)",
            "year": 2021,
            "rank_display": 88,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/sungkyunkwan-universityskku",
            "country": "South Korea",
            "city": "Suwon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sungkyunkwan-universityskku_592560cf2aeae70239af4cc9_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3821,
            "size": "L",
            "faculty_count": 3313
        },
        {
            "university": "Rice University",
            "year": 2021,
            "rank_display": 89,
            "score": 61.4,
            "link": "https://www.topuniversities.com/universities/rice-university",
            "country": "United States",
            "city": "Houston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rice-university_524_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2278,
            "size": "M",
            "faculty_count": 996
        },
        {
            "university": "University of Southampton",
            "year": 2021,
            "rank_display": 90,
            "score": 61.3,
            "link": "https://www.topuniversities.com/universities/university-southampton",
            "country": "United Kingdom",
            "city": "Southampton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southampton_567_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8417,
            "size": "L",
            "faculty_count": 2468
        },
        {
            "university": "University of Leeds",
            "year": 2021,
            "rank_display": 91,
            "score": 61.2,
            "link": "https://www.topuniversities.com/universities/university-leeds",
            "country": "United Kingdom",
            "city": "Leeds",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leeds_338_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 11584,
            "size": "XL",
            "faculty_count": 3593
        },
        {
            "university": "The University of Western Australia",
            "year": 2021,
            "rank_display": 92,
            "score": 61,
            "link": "https://www.topuniversities.com/universities/university-western-australia",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-australia_673_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9017,
            "size": "L",
            "faculty_count": 1539
        },
        {
            "university": "The University of Sheffield",
            "year": 2021,
            "rank_display": 93,
            "score": 60.8,
            "link": "https://www.topuniversities.com/universities/university-sheffield",
            "country": "United Kingdom",
            "city": "Sheffield",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sheffield_592560cf2aeae70239af4cb1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 11090,
            "size": "L",
            "faculty_count": 3463
        },
        {
            "university": "University of Science and Technology of China",
            "year": 2021,
            "rank_display": 93,
            "score": 60.8,
            "link": "https://www.topuniversities.com/universities/university-science-technology-china",
            "country": "China (Mainland)",
            "city": "Hefei",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-of-china_122_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1019,
            "size": "L",
            "faculty_count": 2708
        },
        {
            "university": "University of North Carolina, Chapel Hill",
            "year": 2021,
            "rank_display": 95,
            "score": 60.6,
            "link": "https://www.topuniversities.com/universities/university-north-carolina-chapel-hill",
            "country": "United States",
            "city": "Chapel Hill",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-north-carolina-chapel-hill_424_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2749,
            "size": "XL",
            "faculty_count": 4646
        },
        {
            "university": "University of St Andrews",
            "year": 2021,
            "rank_display": 96,
            "score": 60.3,
            "link": "https://www.topuniversities.com/universities/university-st-andrews",
            "country": "United Kingdom",
            "city": "St. Andrews",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st-andrews_570_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4377,
            "size": "M",
            "faculty_count": 1208
        },
        {
            "university": "Lund University",
            "year": 2021,
            "rank_display": 97,
            "score": 60.2,
            "link": "https://www.topuniversities.com/universities/lund-university",
            "country": "Sweden",
            "city": "Lund",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lund-university_371_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6682,
            "size": "L",
            "faculty_count": 3040
        },
        {
            "university": "KTH Royal Institute of Technology",
            "year": 2021,
            "rank_display": 98,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/kth-royal-institute-technology",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kth-royal-institute-of-technology-_592560cf2aeae70239af4c9b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3057,
            "size": "L",
            "faculty_count": 3600
        },
        {
            "university": "University of Nottingham",
            "year": 2021,
            "rank_display": 99,
            "score": 59,
            "link": "https://www.topuniversities.com/universities/university-nottingham",
            "country": "United Kingdom",
            "city": "Nottingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-nottingham_592560cf2aeae70239af4c4e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9659,
            "size": "XL",
            "faculty_count": 3386
        },
        {
            "university": "Universidad Nacional Autónoma de México  (UNAM)",
            "year": 2021,
            "rank_display": 100,
            "score": 58.8,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-autonoma-de-mexico-unam",
            "country": "Mexico",
            "city": "Mexico City",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-autnoma-de-mxico-unam_425_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5110,
            "size": "XL",
            "faculty_count": 16381
        },
        {
            "university": "Pennsylvania State University",
            "year": 2021,
            "rank_display": 101,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/pennsylvania-state-university",
            "country": "United States",
            "city": "University Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/pennsylvania-state-university_494_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6943,
            "size": "XL",
            "faculty_count": 4207
        },
        {
            "university": "Trinity College Dublin, The University of Dublin",
            "year": 2021,
            "rank_display": 101,
            "score": 58.4,
            "link": "https://www.topuniversities.com/universities/trinity-college-dublin-university-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/trinity-college-dublin-the-university-of-dublin_167_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5593,
            "size": "L",
            "faculty_count": 1383
        },
        {
            "university": "Technical University of Denmark",
            "year": 2021,
            "rank_display": 103,
            "score": 57.9,
            "link": "https://www.topuniversities.com/universities/technical-university-denmark",
            "country": "Denmark",
            "city": "Kongens Lyngby",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-denmark_592560cf2aeae70239af4cd8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2495,
            "size": "M",
            "faculty_count": 2219
        },
        {
            "university": "University of Helsinki",
            "year": 2021,
            "rank_display": 104,
            "score": 57.6,
            "link": "https://www.topuniversities.com/universities/university-helsinki",
            "country": "Finland",
            "city": "Helsinki",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-helsinki_260_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1254,
            "size": "L",
            "faculty_count": 2855
        },
        {
            "university": "Washington University in St. Louis",
            "year": 2021,
            "rank_display": 105,
            "score": 56.9,
            "link": "https://www.topuniversities.com/universities/washington-university-st-louis",
            "country": "United States",
            "city": "St. Louis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-university-in-st.-louis_668_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3535,
            "size": "L",
            "faculty_count": 3193
        },
        {
            "university": "The University of Adelaide",
            "year": 2021,
            "rank_display": 106,
            "score": 56.8,
            "link": "https://www.topuniversities.com/universities/university-adelaide",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-adelaide_10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10107,
            "size": "L",
            "faculty_count": 1757
        },
        {
            "university": "University of Geneva",
            "year": 2021,
            "rank_display": 106,
            "score": 56.8,
            "link": "https://www.topuniversities.com/universities/university-geneva",
            "country": "Switzerland",
            "city": "Geneva",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-geneva_221_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6547,
            "size": "L",
            "faculty_count": 1814
        },
        {
            "university": "The Ohio State University",
            "year": 2021,
            "rank_display": 108,
            "score": 56.4,
            "link": "https://www.topuniversities.com/universities/ohio-state-university",
            "country": "United States",
            "city": "Columbus",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-ohio-state-university_465_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7029,
            "size": "XL",
            "faculty_count": 6388
        },
        {
            "university": "Purdue University",
            "year": 2021,
            "rank_display": 109,
            "score": 55.9,
            "link": "https://www.topuniversities.com/universities/purdue-university",
            "country": "United States",
            "city": "West Lafayette",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/purdue-university_510_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 8034,
            "size": "XL",
            "faculty_count": 2622
        },
        {
            "university": "Boston University",
            "year": 2021,
            "rank_display": 110,
            "score": 55.8,
            "link": "https://www.topuniversities.com/universities/boston-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-university_592560cf2aeae70239af4ac2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8159,
            "size": "L",
            "faculty_count": 2642
        },
        {
            "university": "Nagoya University",
            "year": 2021,
            "rank_display": 110,
            "score": 55.8,
            "link": "https://www.topuniversities.com/universities/nagoya-university",
            "country": "Japan",
            "city": "Nagoya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nagoya-university_427_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1879,
            "size": "L",
            "faculty_count": 2512
        },
        {
            "university": "University of California, Davis",
            "year": 2021,
            "rank_display": 112,
            "score": 55.3,
            "link": "https://www.topuniversities.com/universities/university-california-davis",
            "country": "United States",
            "city": "Davis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-davis_592560cf2aeae70239af4ad8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8414,
            "size": "XL",
            "faculty_count": 3420
        },
        {
            "university": "University of Oslo",
            "year": 2021,
            "rank_display": 113,
            "score": 55.2,
            "link": "https://www.topuniversities.com/universities/university-oslo",
            "country": "Norway",
            "city": "Oslo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oslo_473_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2560,
            "size": "L",
            "faculty_count": 2600
        },
        {
            "university": "University of Bern",
            "year": 2021,
            "rank_display": 114,
            "score": 54.9,
            "link": "https://www.topuniversities.com/universities/university-bern",
            "country": "Switzerland",
            "city": "Bern",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bern_55_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1776,
            "size": "M",
            "faculty_count": 1212
        },
        {
            "university": "Queen Mary University of London",
            "year": 2021,
            "rank_display": 114,
            "score": 55.1,
            "link": "https://www.topuniversities.com/universities/queen-mary-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queen-mary-university-of-london_592560cf2aeae70239af4bea_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8625,
            "size": "L",
            "faculty_count": 2119
        },
        {
            "university": "Universidade de São Paulo",
            "year": 2021,
            "rank_display": 115,
            "score": 54.8,
            "link": "https://www.topuniversities.com/universities/universidade-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-de-so-paulo_550_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1858,
            "size": "XL",
            "faculty_count": 4943
        },
        {
            "university": "Wageningen University & Research",
            "year": 2021,
            "rank_display": 115,
            "score": 54.8,
            "link": "https://www.topuniversities.com/universities/wageningen-university-research",
            "country": "Netherlands",
            "city": "Wageningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/wageningen-university_659_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4131,
            "size": "L",
            "faculty_count": 2767
        },
        {
            "university": "Humboldt-Universität zu Berlin",
            "year": 2021,
            "rank_display": 117,
            "score": 54.5,
            "link": "https://www.topuniversities.com/universities/humboldt-universitat-zu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/humboldt-universitt-zu-berlin_272_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5239,
            "size": "XL",
            "faculty_count": 1967
        },
        {
            "university": "Université de Montréal",
            "year": 2021,
            "rank_display": 118,
            "score": 54.3,
            "link": "https://www.topuniversities.com/universities/universite-de-montreal",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montral_417_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7625,
            "size": "XL",
            "faculty_count": 5533
        },
        {
            "university": "University of Alberta",
            "year": 2021,
            "rank_display": 119,
            "score": 54,
            "link": "https://www.topuniversities.com/universities/university-alberta",
            "country": "Canada",
            "city": "Edmonton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-alberta_15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9689,
            "size": "XL",
            "faculty_count": 3645
        },
        {
            "university": "Eindhoven University of Technology",
            "year": 2021,
            "rank_display": 120,
            "score": 53.9,
            "link": "https://www.topuniversities.com/universities/eindhoven-university-technology",
            "country": "Netherlands",
            "city": "Eindhoven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eindhoven-university-of-technology_592560cf2aeae70239af4b3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1872,
            "size": "M",
            "faculty_count": 1145
        },
        {
            "university": "Pontificia Universidad Católica de Chile (UC)",
            "year": 2021,
            "rank_display": 121,
            "score": 53.8,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-cat%C3%B3lica-de-chile-uc",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-de-chile-uc_107_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1072,
            "size": "L",
            "faculty_count": 2343
        },
        {
            "university": "University of Southern California",
            "year": 2021,
            "rank_display": 121,
            "score": 53.8,
            "link": "https://www.topuniversities.com/universities/university-southern-california",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-california_569_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9107,
            "size": "XL",
            "faculty_count": 3116
        },
        {
            "university": "Utrecht University",
            "year": 2021,
            "rank_display": 121,
            "score": 53.8,
            "link": "https://www.topuniversities.com/universities/utrecht-university",
            "country": "Netherlands",
            "city": "Utrecht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/utrecht-university_646_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4454,
            "size": "XL",
            "faculty_count": 3254
        },
        {
            "university": "Kyushu University",
            "year": 2021,
            "rank_display": 124,
            "score": 53.7,
            "link": "https://www.topuniversities.com/universities/kyushu-university",
            "country": "Japan",
            "city": "Fukuoka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyushu-university_331_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2125,
            "size": "L",
            "faculty_count": 2761
        },
        {
            "university": "Nanjing University",
            "year": 2021,
            "rank_display": 124,
            "score": 53.7,
            "link": "https://www.topuniversities.com/universities/nanjing-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanjing-university_430_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2513,
            "size": "XL",
            "faculty_count": 3040
        },
        {
            "university": "Uppsala University",
            "year": 2021,
            "rank_display": 124,
            "score": 53.7,
            "link": "https://www.topuniversities.com/universities/uppsala-university",
            "country": "Sweden",
            "city": "Uppsala",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/uppsala-university_643_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8401,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Aalto University",
            "year": 2021,
            "rank_display": 127,
            "score": 53.6,
            "link": "https://www.topuniversities.com/universities/aalto-university",
            "country": "Finland",
            "city": "Espoo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalto-university_261_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2202,
            "size": "L",
            "faculty_count": 1396
        },
        {
            "university": "Leiden University",
            "year": 2021,
            "rank_display": 128,
            "score": 53.4,
            "link": "https://www.topuniversities.com/universities/leiden-university",
            "country": "Netherlands",
            "city": "Leiden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leiden-university_340_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6064,
            "size": "XL",
            "faculty_count": 2069
        },
        {
            "university": "University of Groningen",
            "year": 2021,
            "rank_display": 128,
            "score": 53.4,
            "link": "https://www.topuniversities.com/universities/university-groningen",
            "country": "Netherlands",
            "city": "Groningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-groningen_243_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 9397,
            "size": "XL",
            "faculty_count": 3724
        },
        {
            "university": "Freie Universitaet Berlin",
            "year": 2021,
            "rank_display": 130,
            "score": 53.2,
            "link": "https://www.topuniversities.com/universities/freie-universitaet-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/freie-universitaet-berlin_215_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 6659,
            "size": "XL",
            "faculty_count": 1389
        },
        {
            "university": "KIT, Karlsruhe Institute of Technology",
            "year": 2021,
            "rank_display": 131,
            "score": 52.9,
            "link": "https://www.topuniversities.com/universities/kit-karlsruhe-institute-technology",
            "country": "Germany",
            "city": "Karlsruhe",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kit-karlsruhe-institute-of-technology_310_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5030,
            "size": "L",
            "faculty_count": 2080
        },
        {
            "university": "Universiti Putra Malaysia (UPM)",
            "year": 2021,
            "rank_display": 132,
            "score": 52.7,
            "link": "https://www.topuniversities.com/universities/universiti-putra-malaysia-upm",
            "country": "Malaysia",
            "city": "Seri Kembangan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-putra-malaysia-upm_385_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5040,
            "size": "L",
            "faculty_count": 2174
        },
        {
            "university": "University of Technology Sydney",
            "year": 2021,
            "rank_display": 133,
            "score": 52.6,
            "link": "https://www.topuniversities.com/universities/university-technology-sydney",
            "country": "Australia",
            "city": "Haymarket",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-technology-sydney_592560cf2aeae70239af4ccf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8653,
            "size": "L",
            "faculty_count": 2183
        },
        {
            "university": "Lancaster University",
            "year": 2021,
            "rank_display": 135,
            "score": 52.5,
            "link": "https://www.topuniversities.com/universities/lancaster-university",
            "country": "United Kingdom",
            "city": "Lancaster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lancaster-university_335_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5557,
            "size": "L",
            "faculty_count": 1440
        },
        {
            "university": "Ghent University",
            "year": 2021,
            "rank_display": 135,
            "score": 52.5,
            "link": "https://www.topuniversities.com/universities/ghent-university",
            "country": "Belgium",
            "city": "Ghent",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ghent-university_228_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4609,
            "size": "XL",
            "faculty_count": 5537
        },
        {
            "university": "Politecnico di Milano",
            "year": 2021,
            "rank_display": 137,
            "score": 52.4,
            "link": "https://www.topuniversities.com/universities/politecnico-di-milano",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-milano_592560cf2aeae70239af4c1c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7452,
            "size": "XL",
            "faculty_count": 1659
        },
        {
            "university": "CentraleSupélec",
            "year": 2021,
            "rank_display": 138,
            "score": 52.3,
            "link": "https://www.topuniversities.com/universities/centralesupelec",
            "country": "France",
            "city": "Saint-Aubin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/centralesuplec_173_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": "",
            "international_students": 1068,
            "size": "S",
            "faculty_count": 552
        },
        {
            "university": "Chalmers University of Technology",
            "year": 2021,
            "rank_display": 139,
            "score": 52.2,
            "link": "https://www.topuniversities.com/universities/chalmers-university-technology",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/chalmers-university-of-technology_592560cf2aeae70239af4af7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2502,
            "size": "M",
            "faculty_count": 2500
        },
        {
            "university": "Hokkaido University",
            "year": 2021,
            "rank_display": 139,
            "score": 52.2,
            "link": "https://www.topuniversities.com/universities/hokkaido-university",
            "country": "Japan",
            "city": "Sapporo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hokkaido-university_266_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1984,
            "size": "L",
            "faculty_count": 2476
        },
        {
            "university": "Universiti Kebangsaan Malaysia (UKM)",
            "year": 2021,
            "rank_display": 141,
            "score": 52,
            "link": "https://www.topuniversities.com/universities/universiti-kebangsaan-malaysia-ukm",
            "country": "Malaysia",
            "city": "Bangi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-kebangsaan-malaysia-ukm_384_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2410,
            "size": "L",
            "faculty_count": 2829
        },
        {
            "university": "Universiti Sains Malaysia (USM)",
            "year": 2021,
            "rank_display": 142,
            "score": 51.9,
            "link": "https://www.topuniversities.com/universities/universiti-sains-malaysia-usm",
            "country": "Malaysia",
            "city": "Gelugor",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-sains-malaysia-usm_386_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4198,
            "size": "L",
            "faculty_count": 2249
        },
        {
            "university": "King Abdulaziz University (KAU)",
            "year": 2021,
            "rank_display": 143,
            "score": 51.7,
            "link": "https://www.topuniversities.com/universities/king-abdulaziz-university-kau",
            "country": "Saudi Arabia",
            "city": "Jeddah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-abdulaziz-university-kau_1172_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6821,
            "size": "XL",
            "faculty_count": 4054
        },
        {
            "university": "McMaster University",
            "year": 2021,
            "rank_display": 144,
            "score": 51.5,
            "link": "https://www.topuniversities.com/universities/mcmaster-university",
            "country": "Canada",
            "city": "Hamilton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcmaster-university_397_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5386,
            "size": "L",
            "faculty_count": 3819
        },
        {
            "university": "RWTH Aachen University",
            "year": 2021,
            "rank_display": 145,
            "score": 51.4,
            "link": "https://www.topuniversities.com/universities/rwth-aachen-university",
            "country": "Germany",
            "city": "Aachen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rwth-aachen-university_4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 12477,
            "size": "XL",
            "faculty_count": 2431
        },
        {
            "university": "Hanyang University",
            "year": 2021,
            "rank_display": 146,
            "score": 51.3,
            "link": "https://www.topuniversities.com/universities/hanyang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hanyang-university_250_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3455,
            "size": "L",
            "faculty_count": 3164
        },
        {
            "university": "Aarhus University",
            "year": 2021,
            "rank_display": 147,
            "score": 51,
            "link": "https://www.topuniversities.com/universities/aarhus-university",
            "country": "Denmark",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aarhus-university_592560cf2aeae70239af4a8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2911,
            "size": "L",
            "faculty_count": 683
        },
        {
            "university": "Technische Universität Berlin (TU Berlin)",
            "year": 2021,
            "rank_display": 148,
            "score": 50.8,
            "link": "https://www.topuniversities.com/universities/technische-universitat-berlin-tu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-berlin-tu-berlin_595_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6464,
            "size": "L",
            "faculty_count": 2331
        },
        {
            "university": "University of Basel",
            "year": 2021,
            "rank_display": 149,
            "score": 50.6,
            "link": "https://www.topuniversities.com/universities/university-basel",
            "country": "Switzerland",
            "city": "Basel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-basel_46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2875,
            "size": "M",
            "faculty_count": 1036
        },
        {
            "university": "University of Vienna",
            "year": 2021,
            "rank_display": 150,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/university-vienna",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-vienna_652_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 14514,
            "size": "XL",
            "faculty_count": 3637
        },
        {
            "university": "University of York",
            "year": 2021,
            "rank_display": 150,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/university-york",
            "country": "United Kingdom",
            "city": "York",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-york_689_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5098,
            "size": "L",
            "faculty_count": 2209
        },
        {
            "university": "Newcastle University",
            "year": 2021,
            "rank_display": 152,
            "score": 50.2,
            "link": "https://www.topuniversities.com/universities/newcastle-university",
            "country": "United Kingdom",
            "city": "Newcastle upon Tyne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/newcastle-university_592560cf2aeae70239af4c45_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8100,
            "size": "L",
            "faculty_count": 2766
        },
        {
            "university": "University of California, Santa Barbara (UCSB)",
            "year": 2021,
            "rank_display": 152,
            "score": 50.2,
            "link": "https://www.topuniversities.com/universities/university-california-santa-barbara-ucsb",
            "country": "United States",
            "city": "Santa Barbara",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-barbara-ucsb_91_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 4309,
            "size": "L",
            "faculty_count": 1597
        },
        {
            "university": "University of Maryland, College Park",
            "year": 2021,
            "rank_display": 152,
            "score": 50.2,
            "link": "https://www.topuniversities.com/universities/university-maryland-college-park",
            "country": "United States",
            "city": "College Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-college-park_393_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4659,
            "size": "XL",
            "faculty_count": 3798
        },
        {
            "university": "Tecnológico de Monterrey",
            "year": 2021,
            "rank_display": 155,
            "score": 49.9,
            "link": "https://www.topuniversities.com/universities/tecnologico-de-monterrey",
            "country": "Mexico",
            "city": "Monterrey",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/instituto-tecnolgico-y-de-estudios-superiores-de-monterrey_592560cf2aeae70239af4cd6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3514,
            "size": "XL",
            "faculty_count": 5894
        },
        {
            "university": "University of Pittsburgh",
            "year": 2021,
            "rank_display": 156,
            "score": 49.7,
            "link": "https://www.topuniversities.com/universities/university-pittsburgh",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pittsburgh_500_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2590,
            "size": "L",
            "faculty_count": 4746
        },
        {
            "university": "Michigan State University",
            "year": 2021,
            "rank_display": 157,
            "score": 49.6,
            "link": "https://www.topuniversities.com/universities/michigan-state-university",
            "country": "United States",
            "city": "East Lansing",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/michigan-state-university_402_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5795,
            "size": "XL",
            "faculty_count": 3008
        },
        {
            "university": "Emory University",
            "year": 2021,
            "rank_display": 158,
            "score": 49.1,
            "link": "https://www.topuniversities.com/universities/emory-university",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/emory-university_185_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2264,
            "size": "L",
            "faculty_count": 2725
        },
        {
            "university": "Cardiff University",
            "year": 2021,
            "rank_display": 159,
            "score": 48.5,
            "link": "https://www.topuniversities.com/universities/cardiff-university",
            "country": "United Kingdom",
            "city": "Cardiff",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cardiff-university_592560cf2aeae70239af4ae6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7819,
            "size": "L",
            "faculty_count": 3350
        },
        {
            "university": "Alma Mater Studiorum - University of Bologna",
            "year": 2021,
            "rank_display": 160,
            "score": 48.2,
            "link": "https://www.topuniversities.com/universities/alma-mater-studiorum-university-bologna",
            "country": "Italy",
            "city": "Bologna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/alma-mater-studiorum-university-of-bologna_592560cf2aeae70239af4ac0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 5536,
            "size": "XL",
            "faculty_count": 2885
        },
        {
            "university": "École Normale Supérieure de Lyon",
            "year": 2021,
            "rank_display": 161,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-de-lyon",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cole-normale-suprieure-de-lyon_175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 233,
            "size": "S",
            "faculty_count": 387
        },
        {
            "university": "Case Western Reserve University",
            "year": 2021,
            "rank_display": 162,
            "score": 47.4,
            "link": "https://www.topuniversities.com/universities/case-western-reserve-university",
            "country": "United States",
            "city": "Cleveland",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/case-western-reserve-university_102_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2864,
            "size": "M",
            "faculty_count": 1639
        },
        {
            "university": "University of Florida",
            "year": 2021,
            "rank_display": 162,
            "score": 47.4,
            "link": "https://www.topuniversities.com/universities/university-florida",
            "country": "United States",
            "city": "Gainesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florida_212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4143,
            "size": "XL",
            "faculty_count": 6222
        },
        {
            "university": "The University of Exeter",
            "year": 2021,
            "rank_display": 164,
            "score": 47.2,
            "link": "https://www.topuniversities.com/universities/university-exeter",
            "country": "United Kingdom",
            "city": "Exeter",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-exeter_204_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7188,
            "size": "L",
            "faculty_count": 2393
        },
        {
            "university": "University of Rochester",
            "year": 2021,
            "rank_display": 165,
            "score": 46.9,
            "link": "https://www.topuniversities.com/universities/university-rochester",
            "country": "United States",
            "city": "Rochester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rochester_592560cf2aeae70239af4c96_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3644,
            "size": "M",
            "faculty_count": 1853
        },
        {
            "university": "Al-Farabi Kazakh National University",
            "year": 2021,
            "rank_display": 165,
            "score": 46.9,
            "link": "https://www.topuniversities.com/universities/al-farabi-kazakh-national-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/al-farabi-kazakh-national-university_2146_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3054,
            "size": "L",
            "faculty_count": 4047
        },
        {
            "university": "University of Waterloo",
            "year": 2021,
            "rank_display": 166,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/university-waterloo",
            "country": "Canada",
            "city": "Waterloo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waterloo_670_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 10269,
            "size": "XL",
            "faculty_count": 1908
        },
        {
            "university": "National Tsing Hua University",
            "year": 2021,
            "rank_display": 168,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/national-tsing-hua-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-tsing-hua-university_442_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1551,
            "size": "L",
            "faculty_count": 1265
        },
        {
            "university": "Texas A&M University",
            "year": 2021,
            "rank_display": 169,
            "score": 46.2,
            "link": "https://www.topuniversities.com/universities/texas-am-university",
            "country": "United States",
            "city": "College Station",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/texas-am-university_592560cf2aeae70239af4ce2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 6621,
            "size": "XL",
            "faculty_count": 3049
        },
        {
            "university": "University of Lausanne",
            "year": 2021,
            "rank_display": 169,
            "score": 46.2,
            "link": "https://www.topuniversities.com/universities/university-lausanne",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lausanne_592560cf2aeae70239af4bd3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3026,
            "size": "L",
            "faculty_count": 1478
        },
        {
            "university": "Sapienza University of Rome",
            "year": 2021,
            "rank_display": 171,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/sapienza-university-rome",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sapienza-university-of-rome_592560cf2aeae70239af4c97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 5960,
            "size": "XL",
            "faculty_count": 3287
        },
        {
            "university": "Indian Institute of Technology Bombay (IITB)",
            "year": 2021,
            "rank_display": 172,
            "score": 46,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-bombay-iitb",
            "country": "India",
            "city": "Mumbai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-bombay-iitb_281_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 116,
            "size": "M",
            "faculty_count": 1015
        },
        {
            "university": "Technische Universität Dresden",
            "year": 2021,
            "rank_display": 173,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/technische-universitat-dresden",
            "country": "Germany",
            "city": "Dresden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-dresden_598_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5167,
            "size": "XL",
            "faculty_count": 4636
        },
        {
            "university": "University of Bath",
            "year": 2021,
            "rank_display": 173,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/university-bath",
            "country": "United Kingdom",
            "city": "Bath",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bath_47_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5326,
            "size": "L",
            "faculty_count": 1339
        },
        {
            "university": "Eberhard Karls Universität Tübingen",
            "year": 2021,
            "rank_display": 175,
            "score": 45.8,
            "link": "https://www.topuniversities.com/universities/eberhard-karls-universitat-tubingen",
            "country": "Germany",
            "city": "Tübingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eberhard-karls-universitt-tbingen_629_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2872,
            "size": "L",
            "faculty_count": 4517
        },
        {
            "university": "Albert-Ludwigs-Universitaet Freiburg",
            "year": 2021,
            "rank_display": 175,
            "score": 45.8,
            "link": "https://www.topuniversities.com/universities/albert-ludwigs-universitaet-freiburg",
            "country": "Germany",
            "city": "Freiburg im Breisgau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/albert-ludwigs-universitaet-freiburg_216_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3883,
            "size": "L",
            "faculty_count": 1993
        },
        {
            "university": "The Hebrew University of Jerusalem",
            "year": 2021,
            "rank_display": 177,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/hebrew-university-jerusalem",
            "country": "Israel",
            "city": "Jerusalem",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hebrew-university-of-jerusalem_256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1176,
            "size": "L",
            "faculty_count": 2295
        },
        {
            "university": "University College Dublin",
            "year": 2021,
            "rank_display": 177,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/university-college-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-dublin_166_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6118,
            "size": "L",
            "faculty_count": 1836
        },
        {
            "university": "University of Minnesota Twin Cities",
            "year": 2021,
            "rank_display": 177,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/university-minnesota-twin-cities",
            "country": "United States",
            "city": "Minneapolis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-minnesota_408_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5630,
            "size": "XL",
            "faculty_count": 5087
        },
        {
            "university": "Universidad de Chile",
            "year": 2021,
            "rank_display": 180,
            "score": 45.6,
            "link": "https://www.topuniversities.com/universities/universidad-de-chile",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-chile_121_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1697,
            "size": "XL",
            "faculty_count": 2412
        },
        {
            "university": "Stockholm University",
            "year": 2021,
            "rank_display": 181,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/stockholm-university",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/stockholm-university_577_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7567,
            "size": "L",
            "faculty_count": 2325
        },
        {
            "university": "University of Liverpool",
            "year": 2021,
            "rank_display": 181,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/university-liverpool",
            "country": "United Kingdom",
            "city": "Liverpool",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-liverpool_350_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9500,
            "size": "L",
            "faculty_count": 2771
        },
        {
            "university": "Universitat de Barcelona",
            "year": 2021,
            "rank_display": 183,
            "score": 45.4,
            "link": "https://www.topuniversities.com/universities/universitat-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-barcelona_592560cf2aeae70239af4aaf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 6979,
            "size": "XL",
            "faculty_count": 3002
        },
        {
            "university": "University of Otago",
            "year": 2021,
            "rank_display": 184,
            "score": 45.3,
            "link": "https://www.topuniversities.com/universities/university-otago",
            "country": "New Zealand",
            "city": "Dunedin",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-otago_474_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3585,
            "size": "L",
            "faculty_count": 1333
        },
        {
            "university": "Indian Institute of Science",
            "year": 2021,
            "rank_display": 185,
            "score": 44.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-science",
            "country": "India",
            "city": "Bangalore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-science-iisc-bangalore_22878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5,
            "size": "S",
            "faculty_count": 446
        },
        {
            "university": "King Fahd University of Petroleum & Minerals",
            "year": 2021,
            "rank_display": 186,
            "score": 44.7,
            "link": "https://www.topuniversities.com/universities/king-fahd-university-petroleum-minerals",
            "country": "Saudi Arabia",
            "city": "Dhahran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-fahd-university-of-petroleum-minerals_779_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 782,
            "size": "M",
            "faculty_count": 1103
        },
        {
            "university": "Universiti Teknologi Malaysia",
            "year": 2021,
            "rank_display": 187,
            "score": 44.2,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-malaysia",
            "country": "Malaysia",
            "city": "Skudai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-malaysia_387_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4956,
            "size": "L",
            "faculty_count": 1869
        },
        {
            "university": "Vanderbilt University",
            "year": 2021,
            "rank_display": 187,
            "score": 44.2,
            "link": "https://www.topuniversities.com/universities/vanderbilt-university",
            "country": "United States",
            "city": "Nashville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/vanderbilt-university_649_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 2022,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Université catholique de Louvain (UCLouvain)",
            "year": 2021,
            "rank_display": 189,
            "score": 44.1,
            "link": "https://www.topuniversities.com/universities/universite-catholique-de-louvain-uclouvain",
            "country": "Belgium",
            "city": "Louvain-la-Neuve",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-catholique-de-louvain-uclouvain_592560cf2aeae70239af4aed_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4524,
            "size": "L",
            "faculty_count": 1315
        },
        {
            "university": "Waseda University",
            "year": 2021,
            "rank_display": 189,
            "score": 44.1,
            "link": "https://www.topuniversities.com/universities/waseda-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/waseda-university_592560cf2aeae70239af4d1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7209,
            "size": "XL",
            "faculty_count": 3833
        },
        {
            "university": "Keio University",
            "year": 2021,
            "rank_display": 191,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/keio-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/keio-university_592560cf2aeae70239af4bbe_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3101,
            "size": "XL",
            "faculty_count": 4028
        },
        {
            "university": "Technische Universität Wien",
            "year": 2021,
            "rank_display": 191,
            "score": 44,
            "link": "https://www.topuniversities.com/universities/vienna-university-technology",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vienna-university-of-technology_653_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2515,
            "size": "M",
            "faculty_count": 761
        },
        {
            "university": "Indian Institute of Technology Delhi (IITD)",
            "year": 2021,
            "rank_display": 193,
            "score": 43.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-delhi-iitd",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-delhi-iitd_282_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 100,
            "size": "M",
            "faculty_count": 843
        },
        {
            "university": "University of Bergen",
            "year": 2021,
            "rank_display": 194,
            "score": 43.4,
            "link": "https://www.topuniversities.com/universities/university-bergen",
            "country": "Norway",
            "city": "Bergen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bergen_53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 832,
            "size": "M",
            "faculty_count": 1634
        },
        {
            "university": "University of Göttingen",
            "year": 2021,
            "rank_display": 195,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/university-gottingen",
            "country": "Germany",
            "city": "Göttingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gttingen_234_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3875,
            "size": "XL",
            "faculty_count": 3717
        },
        {
            "university": "University of Wollongong",
            "year": 2021,
            "rank_display": 196,
            "score": 43.2,
            "link": "https://www.topuniversities.com/universities/university-wollongong",
            "country": "Australia",
            "city": "Wollongong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wollongong_680_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4779,
            "size": "L",
            "faculty_count": 1410
        },
        {
            "university": "Erasmus University Rotterdam",
            "year": 2021,
            "rank_display": 197,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/erasmus-university-rotterdam",
            "country": "Netherlands",
            "city": "Rotterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/erasmus-university-rotterdam_190_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5932,
            "size": "L",
            "faculty_count": 2699
        },
        {
            "university": "The University of Newcastle, Australia (UON)",
            "year": 2021,
            "rank_display": 197,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/university-newcastle-australia-uon",
            "country": "Australia",
            "city": "Callaghan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-newcastle-australia-uon_449_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1747,
            "size": "L",
            "faculty_count": 1135
        },
        {
            "university": "University of Twente",
            "year": 2021,
            "rank_display": 197,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/university-twente",
            "country": "Netherlands",
            "city": "Enschede",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-twente_635_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3872,
            "size": "L",
            "faculty_count": 1177
        },
        {
            "university": "Universidad Autónoma de Madrid",
            "year": 2021,
            "rank_display": 200,
            "score": 42.8,
            "link": "https://www.topuniversities.com/universities/universidad-autonoma-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-autnoma-de-madrid_379_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2470,
            "size": "L",
            "faculty_count": 2869
        },
        {
            "university": "Vrije Universiteit Brussel (VUB)",
            "year": 2021,
            "rank_display": 200,
            "score": 42.8,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-brussel-vub",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-brussel-vub_74_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2994,
            "size": "L",
            "faculty_count": 1815
        },
        {
            "university": "University of Gothenburg",
            "year": 2021,
            "rank_display": 202,
            "score": 42.7,
            "link": "https://www.topuniversities.com/universities/university-gothenburg",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gothenburg_233_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5652,
            "size": "L",
            "faculty_count": 2680
        },
        {
            "university": "Dartmouth College",
            "year": 2021,
            "rank_display": 203,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/dartmouth-college",
            "country": "United States",
            "city": "Hanover",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dartmouth-college_592560cf2aeae70239af4b1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 997,
            "size": "M",
            "faculty_count": 836
        },
        {
            "university": "Western University",
            "year": 2021,
            "rank_display": 203,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/western-university",
            "country": "Canada",
            "city": "London",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-ontario_674_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 6314,
            "size": "L",
            "faculty_count": 1311
        },
        {
            "university": "University of Reading",
            "year": 2021,
            "rank_display": 205,
            "score": 42.3,
            "link": "https://www.topuniversities.com/universities/university-reading",
            "country": "United Kingdom",
            "city": "Reading",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-reading_517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5007,
            "size": "L",
            "faculty_count": 1325
        },
        {
            "university": "Complutense University of Madrid",
            "year": 2021,
            "rank_display": 206,
            "score": 41.9,
            "link": "https://www.topuniversities.com/universities/complutense-university-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/complutense-university-of-madrid_592560cf2aeae70239af4bff_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 7295,
            "size": "XL",
            "faculty_count": 4778
        },
        {
            "university": "University of Aberdeen",
            "year": 2021,
            "rank_display": 207,
            "score": 41.8,
            "link": "https://www.topuniversities.com/universities/university-aberdeen",
            "country": "United Kingdom",
            "city": "Aberdeen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-aberdeen_592560cf2aeae70239af4a8b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5544,
            "size": "L",
            "faculty_count": 1175
        },
        {
            "university": "Chulalongkorn University",
            "year": 2021,
            "rank_display": 208,
            "score": 41.6,
            "link": "https://www.topuniversities.com/universities/chulalongkorn-university",
            "country": "Thailand",
            "city": "Bangkok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chulalongkorn-university_592560cf2aeae70239af4b01_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 985,
            "size": "XL",
            "faculty_count": 3354
        },
        {
            "university": "Queen's University Belfast",
            "year": 2021,
            "rank_display": 209,
            "score": 41.5,
            "link": "https://www.topuniversities.com/universities/queens-university-belfast",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-belfast_592560cf2aeae70239af4c86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7397,
            "size": "L",
            "faculty_count": 1951
        },
        {
            "university": "University of California, Irvine",
            "year": 2021,
            "rank_display": 210,
            "score": 41.4,
            "link": "https://www.topuniversities.com/universities/university-california-irvine",
            "country": "United States",
            "city": "Irvine",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-irvine_86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 9323,
            "size": "XL",
            "faculty_count": 2775
        },
        {
            "university": "Khalifa University",
            "year": 2021,
            "rank_display": 211,
            "score": 41.2,
            "link": "https://www.topuniversities.com/universities/khalifa-university",
            "country": "United Arab Emirates",
            "city": "Abu Dhabi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/khalifa-university-of-science-and-technology_592560e19988f300e2320ee2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 652,
            "size": "S",
            "faculty_count": 498
        },
        {
            "university": "University of Notre Dame",
            "year": 2021,
            "rank_display": 211,
            "score": 41.2,
            "link": "https://www.topuniversities.com/universities/university-notre-dame",
            "country": "United States",
            "city": "Notre Dame",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-notre-dame_458_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1484,
            "size": "L",
            "faculty_count": 1545
        },
        {
            "university": "Universitat Autònoma de Barcelona",
            "year": 2021,
            "rank_display": 213,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/universitat-autonoma-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-autnoma-de-barcelona_592560cf2aeae70239af4ab0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 4069,
            "size": "XL",
            "faculty_count": 2132
        },
        {
            "university": "Macquarie University",
            "year": 2021,
            "rank_display": 214,
            "score": 40.8,
            "link": "https://www.topuniversities.com/universities/macquarie-university",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/macquarie-university_378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8621,
            "size": "L",
            "faculty_count": 1766
        },
        {
            "university": "Radboud University",
            "year": 2021,
            "rank_display": 214,
            "score": 40.8,
            "link": "https://www.topuniversities.com/universities/radboud-university",
            "country": "Netherlands",
            "city": "Nijmegen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/radboud-university_452_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2781,
            "size": "L",
            "faculty_count": 2074
        },
        {
            "university": "Università di Padova",
            "year": 2021,
            "rank_display": 216,
            "score": 40.6,
            "link": "https://www.topuniversities.com/universities/universita-di-padova",
            "country": "Italy",
            "city": "Padua",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitdi-padova_480_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 17,
            "international_students": 2654,
            "size": "XL",
            "faculty_count": 2806
        },
        {
            "university": "Curtin University",
            "year": 2021,
            "rank_display": 217,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/curtin-university",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/curtin-university_150_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4565,
            "size": "L",
            "faculty_count": 1698
        },
        {
            "university": "Queensland University of Technology (QUT)",
            "year": 2021,
            "rank_display": 217,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/queensland-university-technology-qut",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/queensland-university-of-technology-qut_592560cf2aeae70239af4c88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6403,
            "size": "XL",
            "faculty_count": 2028
        },
        {
            "university": "University of Virginia",
            "year": 2021,
            "rank_display": 217,
            "score": 40.5,
            "link": "https://www.topuniversities.com/universities/university-virginia",
            "country": "United States",
            "city": "Charlottesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-virginia_656_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2201,
            "size": "L",
            "faculty_count": 2731
        },
        {
            "university": "American University of Beirut (AUB)",
            "year": 2021,
            "rank_display": 220,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/american-university-beirut-aub",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-beirut-aub_780_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1724,
            "size": "M",
            "faculty_count": 964
        },
        {
            "university": "Arizona State University",
            "year": 2021,
            "rank_display": 220,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/arizona-state-university",
            "country": "United States",
            "city": "Phoenix",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/arizona-state-university_592560cf2aeae70239af4a9c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8430,
            "size": "XL",
            "faculty_count": 2869
        },
        {
            "university": "University of Cape Town",
            "year": 2021,
            "rank_display": 220,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/university-cape-town",
            "country": "South Africa",
            "city": "Cape Town",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/uct-graduate-school-of-business_592560cf2aeae70239af4ae5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3204,
            "size": "L",
            "faculty_count": 1503
        },
        {
            "university": "RMIT University",
            "year": 2021,
            "rank_display": 223,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/rmit-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/rmit-university_592560cf2aeae70239af4c95_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 11743,
            "size": "XL",
            "faculty_count": 1859
        },
        {
            "university": "Victoria University of Wellington",
            "year": 2021,
            "rank_display": 223,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/victoria-university-wellington",
            "country": "New Zealand",
            "city": "Kelburn, Wellington",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/victoria-university-of-wellington_592560cf2aeae70239af4d0f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 3668,
            "size": "L",
            "faculty_count": 1124
        },
        {
            "university": "Saint Petersburg State University",
            "year": 2021,
            "rank_display": 225,
            "score": 39.9,
            "link": "https://www.topuniversities.com/universities/saint-petersburg-state-university",
            "country": "Russia",
            "city": "Saint Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-petersburg-state-university_592560cf2aeae70239af4cc0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4982,
            "size": "L",
            "faculty_count": 3054
        },
        {
            "university": "Loughborough University",
            "year": 2021,
            "rank_display": 226,
            "score": 39.7,
            "link": "https://www.topuniversities.com/universities/loughborough-university",
            "country": "United Kingdom",
            "city": "Loughborough",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/loughborough-university_592560cf2aeae70239af4bf3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4540,
            "size": "L",
            "faculty_count": 1445
        },
        {
            "university": "Universidad de los Andes",
            "year": 2021,
            "rank_display": 227,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universidad-de-los-andes",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-los-andes_592560cf2aeae70239af4bf6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 13,
            "international_students": 151,
            "size": "L",
            "faculty_count": 1223
        },
        {
            "university": "Novosibirsk State University",
            "year": 2021,
            "rank_display": 228,
            "score": 39.4,
            "link": "https://www.topuniversities.com/universities/novosibirsk-state-university",
            "country": "Russia",
            "city": "Novosibirsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/novosibirsk-state-university_462_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1623,
            "size": "M",
            "faculty_count": 1218
        },
        {
            "university": "Universität Hamburg",
            "year": 2021,
            "rank_display": 228,
            "score": 39.4,
            "link": "https://www.topuniversities.com/universities/universitat-hamburg",
            "country": "Germany",
            "city": "Hamburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-hamburg_592560cf2aeae70239af4b79_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3626,
            "size": "L",
            "faculty_count": 2032
        },
        {
            "university": "Georgetown University",
            "year": 2021,
            "rank_display": 230,
            "score": 39.3,
            "link": "https://www.topuniversities.com/universities/georgetown-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgetown-university_224_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3286,
            "size": "L",
            "faculty_count": 2009
        },
        {
            "university": "Tel Aviv University",
            "year": 2021,
            "rank_display": 230,
            "score": 39.3,
            "link": "https://www.topuniversities.com/universities/tel-aviv-university",
            "country": "Israel",
            "city": "Tel Aviv",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tel-aviv-university_592560cf2aeae70239af4cde_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1294,
            "size": "L",
            "faculty_count": 1048
        },
        {
            "university": "University of Colorado Boulder",
            "year": 2021,
            "rank_display": 230,
            "score": 39.3,
            "link": "https://www.topuniversities.com/universities/university-colorado-boulder",
            "country": "United States",
            "city": "Boulder",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-boulder_137_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2441,
            "size": "XL",
            "faculty_count": 2946
        },
        {
            "university": "Universidade Estadual de Campinas (Unicamp)",
            "year": 2021,
            "rank_display": 233,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/universidade-estadual-de-campinas-unicamp",
            "country": "Brazil",
            "city": "Campinas",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-estadual-de-campinas-unicamp_96_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 944,
            "size": "L",
            "faculty_count": 1875
        },
        {
            "university": "Maastricht University",
            "year": 2021,
            "rank_display": 234,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/maastricht-university",
            "country": "Netherlands",
            "city": "Maastricht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/maastricht-university_377_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10425,
            "size": "L",
            "faculty_count": 1418
        },
        {
            "university": "National Cheng Kung University (NCKU)",
            "year": 2021,
            "rank_display": 234,
            "score": 38.9,
            "link": "https://www.topuniversities.com/universities/national-cheng-kung-university-ncku",
            "country": "Taiwan",
            "city": "Tainan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-cheng-kung-university-ncku_435_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2656,
            "size": "L",
            "faculty_count": 2079
        },
        {
            "university": "Kyung Hee University",
            "year": 2021,
            "rank_display": 236,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/kyung-hee-university",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyung-hee-university_592560cf2aeae70239af4bcd_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4715,
            "size": "L",
            "faculty_count": 3435
        },
        {
            "university": "Vrije Universiteit Amsterdam",
            "year": 2021,
            "rank_display": 236,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-amsterdam_658_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4059,
            "size": "L",
            "faculty_count": 2598
        },
        {
            "university": "National University of Ireland Galway",
            "year": 2021,
            "rank_display": 238,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/national-university-ireland-galway",
            "country": "Ireland",
            "city": "Galway",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-ireland-galway_220_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3208,
            "size": "L",
            "faculty_count": 1835
        },
        {
            "university": "University of Antwerp",
            "year": 2021,
            "rank_display": 238,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/university-antwerp",
            "country": "Belgium",
            "city": "Antwerp",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-antwerp_21_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2688,
            "size": "L",
            "faculty_count": 1704
        },
        {
            "university": "National Chiao Tung University",
            "year": 2021,
            "rank_display": 240,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/national-chiao-tung-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-chiao-tung-university_437_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1420,
            "size": "M",
            "faculty_count": 1152
        },
        {
            "university": "Rheinische Friedrich-Wilhelms-Universität Bonn",
            "year": 2021,
            "rank_display": 240,
            "score": 38.2,
            "link": "https://www.topuniversities.com/universities/rheinische-friedrich-wilhelms-universitat-bonn",
            "country": "Germany",
            "city": "Bonn",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rheinische-friedrich-wilhelms-universitt-bonn_592560cf2aeae70239af4ac1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3276,
            "size": "L",
            "faculty_count": 4207
        },
        {
            "university": "Ecole des Ponts ParisTech",
            "year": 2021,
            "rank_display": 242,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/ecole-des-ponts-paristech",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-des-ponts-paristech_187_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 735,
            "size": "S",
            "faculty_count": 338
        },
        {
            "university": "Sciences Po",
            "year": 2021,
            "rank_display": 242,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/sciences-po",
            "country": "France",
            "city": "Dijon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sciences-po-_592560cf2aeae70239af4c64_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4841,
            "size": "M",
            "faculty_count": 1808
        },
        {
            "university": "University of Leicester",
            "year": 2021,
            "rank_display": 242,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/university-leicester",
            "country": "United Kingdom",
            "city": "Leicester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leicester_339_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4919,
            "size": "L",
            "faculty_count": 1518
        },
        {
            "university": "Qatar University",
            "year": 2021,
            "rank_display": 245,
            "score": 37.9,
            "link": "https://www.topuniversities.com/universities/qatar-university",
            "country": "Qatar",
            "city": "Doha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/qatar-university_2537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3197,
            "size": "M",
            "faculty_count": 1163
        },
        {
            "university": "Queen's University at Kingston",
            "year": 2021,
            "rank_display": 246,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/queens-university-kingston",
            "country": "Canada",
            "city": "Kingston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-at-kingston_513_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3858,
            "size": "L",
            "faculty_count": 1287
        },
        {
            "university": "University of Calgary",
            "year": 2021,
            "rank_display": 246,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/university-calgary",
            "country": "Canada",
            "city": "Calgary",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-calgary_592560cf2aeae70239af4ad5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6791,
            "size": "XL",
            "faculty_count": 2668
        },
        {
            "university": "University of Sussex",
            "year": 2021,
            "rank_display": 246,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/university-sussex",
            "country": "United Kingdom",
            "city": "Brighton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-sussex_592560cf2aeae70239af4ccd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6350,
            "size": "L",
            "faculty_count": 1320
        },
        {
            "university": "Wuhan University",
            "year": 2021,
            "rank_display": 246,
            "score": 37.8,
            "link": "https://www.topuniversities.com/universities/wuhan-university",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/wuhan-university_889_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3682,
            "size": "XL",
            "faculty_count": 3828
        },
        {
            "university": "Tomsk State University",
            "year": 2021,
            "rank_display": 250,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/tomsk-state-university",
            "country": "Russia",
            "city": "Tomsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tomsk-state-university_616_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2828,
            "size": "M",
            "faculty_count": 1742
        },
        {
            "university": "Universite libre de Bruxelles",
            "year": 2021,
            "rank_display": 250,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/universite-libre-de-bruxelles",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universite-libre-de-bruxelles_75_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 8073,
            "size": "L",
            "faculty_count": 1101
        },
        {
            "university": "Mahidol University",
            "year": 2021,
            "rank_display": 252,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/mahidol-university",
            "country": "Thailand",
            "city": "Nakhon Pathom",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/mahidol-university_381_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1415,
            "size": "L",
            "faculty_count": 3850
        },
        {
            "university": "University of Navarra",
            "year": 2021,
            "rank_display": 252,
            "score": 37.5,
            "link": "https://www.topuniversities.com/universities/university-navarra",
            "country": "Spain",
            "city": "Pamplona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-navarra_592560cf2aeae70239af4c40_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2978,
            "size": "M",
            "faculty_count": 1175
        },
        {
            "university": "Gadjah Mada University",
            "year": 2021,
            "rank_display": 254,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/gadjah-mada-university",
            "country": "Indonesia",
            "city": "Yogyakarta",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gadjah-mada-university_219_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 738,
            "size": "XL",
            "faculty_count": 4728
        },
        {
            "university": "Universiti Brunei Darussalam (UBD)",
            "year": 2021,
            "rank_display": 254,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/universiti-brunei-darussalam-ubd",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-brunei-darussalam-ubd_22212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 635,
            "size": "S",
            "faculty_count": 475
        },
        {
            "university": "Tongji University",
            "year": 2021,
            "rank_display": 256,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/tongji-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tongji-university_617_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5095,
            "size": "XL",
            "faculty_count": 2785
        },
        {
            "university": "University of Illinois at Chicago (UIC)",
            "year": 2021,
            "rank_display": 256,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/university-illinois-chicago-uic",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-chicago-uic_592560cf2aeae70239af4b9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3425,
            "size": "L",
            "faculty_count": 2808
        },
        {
            "university": "Rutgers University–New Brunswick",
            "year": 2021,
            "rank_display": 258,
            "score": 37,
            "link": "https://www.topuniversities.com/universities/rutgers-university-new-brunswick",
            "country": "United States",
            "city": "New Brunswick",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rutgers-university-new-brunswick_538_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7965,
            "size": "XL",
            "faculty_count": 5009
        },
        {
            "university": "Universidad Nacional de Colombia",
            "year": 2021,
            "rank_display": 259,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-de-colombia_592560cf2aeae70239af4b0b_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 335,
            "size": "XL",
            "faculty_count": 3055
        },
        {
            "university": "Charles University",
            "year": 2021,
            "rank_display": 260,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/charles-university",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/charles-university_117_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9080,
            "size": "XL",
            "faculty_count": 5019
        },
        {
            "university": "Harbin Institute of Technology",
            "year": 2021,
            "rank_display": 260,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/harbin-institute-technology",
            "country": "China (Mainland)",
            "city": "Harbin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/harbin-institute-of-technology_877_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2102,
            "size": "XL",
            "faculty_count": 3215
        },
        {
            "university": "Tufts University",
            "year": 2021,
            "rank_display": 260,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/tufts-university",
            "country": "United States",
            "city": "Medford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tufts-university_630_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1798,
            "size": "M",
            "faculty_count": 1459
        },
        {
            "university": "Sun Yat-sen University",
            "year": 2021,
            "rank_display": 263,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/sun-yat-sen-university",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sun-yat-sen-university_888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3127,
            "size": "XL",
            "faculty_count": 7002
        },
        {
            "university": "Hong Kong Baptist University",
            "year": 2021,
            "rank_display": 264,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/hong-kong-baptist-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hong-kong-baptist-university_893_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4704,
            "size": "M",
            "faculty_count": 980
        },
        {
            "university": "Universität Innsbruck",
            "year": 2021,
            "rank_display": 265,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/universitat-innsbruck",
            "country": "Austria",
            "city": "Innsbruck",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-innsbruck_592560cf2aeae70239af4ba7_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 6323,
            "size": "L",
            "faculty_count": 1769
        },
        {
            "university": "University of Tsukuba",
            "year": 2021,
            "rank_display": 265,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/university-tsukuba",
            "country": "Japan",
            "city": "Tsukuba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tsukuba_592560cf2aeae70239af4cf7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2358,
            "size": "L",
            "faculty_count": 2073
        },
        {
            "university": "National Taiwan University of Science and Technology (Taiwan Tech)",
            "year": 2021,
            "rank_display": 267,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-science-technology-taiwan-tech",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-of-science-and-technology-taiwan-tech_590_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1537,
            "size": "M",
            "faculty_count": 1005
        },
        {
            "university": "Technical University of Darmstadt",
            "year": 2021,
            "rank_display": 267,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/technical-university-darmstadt",
            "country": "Germany",
            "city": "Darmstadt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-darmstadt_628_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3979,
            "size": "L",
            "faculty_count": 804
        },
        {
            "university": "University of Surrey",
            "year": 2021,
            "rank_display": 267,
            "score": 36,
            "link": "https://www.topuniversities.com/universities/university-surrey",
            "country": "United Kingdom",
            "city": "Guildford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-surrey_584_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5556,
            "size": "L",
            "faculty_count": 1221
        },
        {
            "university": "University of Canterbury | Te Whare Wānanga o Waitaha",
            "year": 2021,
            "rank_display": 270,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/university-canterbury-te-whare-wananga-o-waitaha",
            "country": "New Zealand",
            "city": "Christchurch",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canterbury_97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2816,
            "size": "L",
            "faculty_count": 867
        },
        {
            "university": "University of Massachusetts Amherst",
            "year": 2021,
            "rank_display": 270,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/university-massachusetts-amherst",
            "country": "United States",
            "city": "Amherst",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-massachusetts-amherst_592560cf2aeae70239af4c0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3696,
            "size": "L",
            "faculty_count": 1852
        },
        {
            "university": "Massey University",
            "year": 2021,
            "rank_display": 272,
            "score": 35.8,
            "link": "https://www.topuniversities.com/universities/massey-university",
            "country": "New Zealand",
            "city": "Palmerston North",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/massey-university_395_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4836,
            "size": "M",
            "faculty_count": 1209
        },
        {
            "university": "USI - Università della Svizzera italiana",
            "year": 2021,
            "rank_display": 273,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/usi-universita-della-svizzera-italiana",
            "country": "Switzerland",
            "city": "Lugano",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/usi-universit-della-svizzera-italiana_592560cf2aeae70239af50ca_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1864,
            "size": "S",
            "faculty_count": 869
        },
        {
            "university": "The University of Arizona",
            "year": 2021,
            "rank_display": 273,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/university-arizona",
            "country": "United States",
            "city": "Tucson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-arizona_26_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3526,
            "size": "XL",
            "faculty_count": 5724
        },
        {
            "university": "Deakin University",
            "year": 2021,
            "rank_display": 275,
            "score": 35.5,
            "link": "https://www.topuniversities.com/universities/deakin-university",
            "country": "Australia",
            "city": "Geelong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/download-48x48.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 10684,
            "size": "XL",
            "faculty_count": 1851
        },
        {
            "university": "Indian Institute of Technology Madras (IITM)",
            "year": 2021,
            "rank_display": 275,
            "score": 35.5,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-madras-iitm",
            "country": "India",
            "city": "Chennai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-madras-iitm_286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 94,
            "size": "M",
            "faculty_count": 884
        },
        {
            "university": "Graz University of Technology",
            "year": 2021,
            "rank_display": 275,
            "score": 35.5,
            "link": "https://www.topuniversities.com/universities/graz-university-technology",
            "country": "Austria",
            "city": "Graz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/graz-university-of-technology_1239_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1473,
            "size": "M",
            "faculty_count": 1427
        },
        {
            "university": "Université de Paris",
            "year": 2021,
            "rank_display": 275,
            "score": 35.5,
            "link": "https://www.topuniversities.com/universities/universite-de-paris",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-paris_5ddd0e075c52f3703747e454_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 11387,
            "size": "XL",
            "faculty_count": 3755
        },
        {
            "university": "Beijing Normal University",
            "year": 2021,
            "rank_display": 279,
            "score": 35.4,
            "link": "https://www.topuniversities.com/universities/beijing-normal-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-normal-university_869_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3288,
            "size": "L",
            "faculty_count": 2098
        },
        {
            "university": "University of Ottawa",
            "year": 2021,
            "rank_display": 279,
            "score": 35.4,
            "link": "https://www.topuniversities.com/universities/university-ottawa",
            "country": "Canada",
            "city": "Ottawa",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-ottawa_475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 8885,
            "size": "XL",
            "faculty_count": 1677
        },
        {
            "university": "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
            "year": 2021,
            "rank_display": 281,
            "score": 35.3,
            "link": "https://www.topuniversities.com/universities/moscow-institute-physics-technology-mipt-moscow-phystech",
            "country": "Russia",
            "city": "Dolgoprudny",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-institute-of-physics-and-technology-mipt-moscow-phystech_15270_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1153,
            "size": "M",
            "faculty_count": 1110
        },
        {
            "university": "Bauman Moscow State Technical University",
            "year": 2021,
            "rank_display": 282,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/bauman-moscow-state-technical-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bauman-moscow-state-technical-university_592560cf2aeae70239af505c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2370,
            "size": "L",
            "faculty_count": 4349
        },
        {
            "university": "University of Cologne",
            "year": 2021,
            "rank_display": 282,
            "score": 35.2,
            "link": "https://www.topuniversities.com/universities/university-cologne",
            "country": "Germany",
            "city": "Cologne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cologne_592560cf2aeae70239af4bc6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4890,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "United Arab Emirates University",
            "year": 2021,
            "rank_display": 284,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/united-arab-emirates-university",
            "country": "United Arab Emirates",
            "city": "Al Ain",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/united-arab-emirates-university_778_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1238,
            "size": "M",
            "faculty_count": 919
        },
        {
            "university": "University of Tartu",
            "year": 2021,
            "rank_display": 285,
            "score": 35,
            "link": "https://www.topuniversities.com/universities/university-tartu",
            "country": "Estonia",
            "city": "Tartu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tartu_592560cf2aeae70239af4d08_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1400,
            "size": "M",
            "faculty_count": 1605
        },
        {
            "university": "University College Cork",
            "year": 2021,
            "rank_display": 286,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/university-college-cork",
            "country": "Ireland",
            "city": "Cork",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-cork_142_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 3002,
            "size": "L",
            "faculty_count": 1619
        },
        {
            "university": "King Saud University",
            "year": 2021,
            "rank_display": 287,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/king-saud-university",
            "country": "Saudi Arabia",
            "city": "Riyadh",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-saud-university_1175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3896,
            "size": "XL",
            "faculty_count": 4216
        },
        {
            "university": "Universitat Pompeu Fabra (Barcelona)",
            "year": 2021,
            "rank_display": 287,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/universitat-pompeu-fabra",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-pompeu-fabra_504_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1633,
            "size": "L",
            "faculty_count": 785
        },
        {
            "university": "Université Paris 1 Panthéon-Sorbonne",
            "year": 2021,
            "rank_display": 287,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/universite-paris-1-pantheon-sorbonne",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-1-panthon-sorbonne_482_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7257,
            "size": "XL",
            "faculty_count": 1679
        },
        {
            "university": "University of Turku",
            "year": 2021,
            "rank_display": 287,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/university-turku",
            "country": "Finland",
            "city": "Turku",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turku_592560cf2aeae70239af4cfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 604,
            "size": "L",
            "faculty_count": 1505
        },
        {
            "university": "Dalhousie University",
            "year": 2021,
            "rank_display": 291,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/dalhousie-university",
            "country": "Canada",
            "city": "Halifax",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dalhousie-university_151_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4483,
            "size": "L",
            "faculty_count": 1150
        },
        {
            "university": "ENS Paris-Saclay",
            "year": 2021,
            "rank_display": 291,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/ens-paris-saclay",
            "country": "France",
            "city": "Cachan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-normale-suprieure-de-cachan_1878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 298,
            "size": "S",
            "faculty_count": 289
        },
        {
            "university": "Technion - Israel Institute of Technology",
            "year": 2021,
            "rank_display": 291,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/technion-israel-institute-technology",
            "country": "Israel",
            "city": "Haifa",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/technion-israel-institute-of-technology_600_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "L",
            "faculty_count": 1.616
        },
        {
            "university": "University of Miami",
            "year": 2021,
            "rank_display": 291,
            "score": 34.6,
            "link": "https://www.topuniversities.com/universities/university-miami",
            "country": "United States",
            "city": "Miami",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-miami_401_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2646,
            "size": "L",
            "faculty_count": 2565
        },
        {
            "university": "Gwangju Institute of Science and Technology (GIST)",
            "year": 2021,
            "rank_display": 295,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/gwangju-institute-science-technology-gist",
            "country": "South Korea",
            "city": "Gwangju",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gwangju-institute-of-science-and-technology-gist_718_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 122,
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "North Carolina State University",
            "year": 2021,
            "rank_display": 295,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/north-carolina-state-university",
            "country": "United States",
            "city": "Raleigh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/north-carolina-state-university_592560cf2aeae70239af4c2a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 3156,
            "size": "L",
            "faculty_count": 1944
        },
        {
            "university": "University of South Australia",
            "year": 2021,
            "rank_display": 295,
            "score": 34.5,
            "link": "https://www.topuniversities.com/universities/university-south-australia",
            "country": "Australia",
            "city": "Magill",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-south-australia_566_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.886,
            "size": "L",
            "faculty_count": 1.524
        },
        {
            "university": "HSE University",
            "year": 2021,
            "rank_display": 298,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/hse-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/logo-3-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4535,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "National Yang Ming University",
            "year": 2021,
            "rank_display": 298,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/national-yang-ming-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-yang-ming-university_444_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 237,
            "size": "S",
            "faculty_count": 965
        },
        {
            "university": "University of Strathclyde",
            "year": 2021,
            "rank_display": 300,
            "score": 34.3,
            "link": "https://www.topuniversities.com/universities/university-strathclyde",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-strathclyde_579_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5397,
            "size": "L",
            "faculty_count": 1532
        },
        {
            "university": "Heriot-Watt University",
            "year": 2021,
            "rank_display": 301,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/heriot-watt-university",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/heriot-watt-university_592560cf2aeae70239af4d5f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3536,
            "size": "M",
            "faculty_count": 806
        },
        {
            "university": "University of Milan",
            "year": 2021,
            "rank_display": 301,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/university-milan",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milan_1873_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3269,
            "size": "XL",
            "faculty_count": 2516
        },
        {
            "university": "Griffith University",
            "year": 2021,
            "rank_display": 303,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/griffith-university",
            "country": "Australia",
            "city": "Nathan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/griffith-university_242_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5875,
            "size": "XL",
            "faculty_count": 1918
        },
        {
            "university": "Xi’an Jiaotong University",
            "year": 2021,
            "rank_display": 303,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/xian-jiaotong-university",
            "country": "China (Mainland)",
            "city": "Xi'an",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xian-jiaotong-university_683_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2266,
            "size": "XL",
            "faculty_count": 3729
        },
        {
            "university": "Aalborg University",
            "year": 2021,
            "rank_display": 305,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/aalborg-university",
            "country": "Denmark",
            "city": "Aalborg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalborg-university_1262_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.474,
            "size": "L",
            "faculty_count": 1.716
        },
        {
            "university": "Université Paris-Saclay",
            "year": 2021,
            "rank_display": 305,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/universite-paris-saclay",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-saclay_592560cf2aeae70239af4c6e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 7649,
            "size": "XL",
            "faculty_count": 8010
        },
        {
            "university": "Universitas Indonesia",
            "year": 2021,
            "rank_display": 305,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/universitas-indonesia",
            "country": "Indonesia",
            "city": "Depok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universitas-indonesia_290_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1412,
            "size": "XL",
            "faculty_count": 4210
        },
        {
            "university": "Universität Mannheim",
            "year": 2021,
            "rank_display": 307,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/universitat-mannheim",
            "country": "Germany",
            "city": "Mannheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-mannheim_592560cf2aeae70239af4c0b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 1.29,
            "size": "M",
            "faculty_count": 307
        },
        {
            "university": "Politecnico di Torino",
            "year": 2021,
            "rank_display": 308,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/politecnico-di-torino",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-torino_633_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 5.369,
            "size": "XL",
            "faculty_count": 1.05
        },
        {
            "university": "Royal Holloway University of London",
            "year": 2021,
            "rank_display": 308,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/royal-holloway-university-london",
            "country": "United Kingdom",
            "city": "Egham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/royal-holloway-university-of-london_534_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.705,
            "size": "M",
            "faculty_count": 831
        },
        {
            "university": "University of Tasmania",
            "year": 2021,
            "rank_display": 308,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/university-tasmania",
            "country": "Australia",
            "city": "Hobart",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tasmania_592560cf2aeae70239af4cd5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5260,
            "size": "L",
            "faculty_count": 982
        },
        {
            "university": "Universidad Carlos III de Madrid (UC3M)",
            "year": 2021,
            "rank_display": 311,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/universidad-carlos-iii-de-madrid-uc3m",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-carlos-iii-de-madrid-uc3m_1509_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.11,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "Goethe-University Frankfurt am Main",
            "year": 2021,
            "rank_display": 311,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/goethe-university-frankfurt-am-main",
            "country": "Germany",
            "city": "Frankfurt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-frankfurt-am-main_214_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6.749,
            "size": "XL",
            "faculty_count": 3.623
        },
        {
            "university": "Bandung Institute of Technology (ITB)",
            "year": 2021,
            "rank_display": 313,
            "score": 33.3,
            "link": "https://www.topuniversities.com/universities/bandung-institute-technology-itb",
            "country": "Indonesia",
            "city": "Bandung",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bandung-institute-of-technology-itb_41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 613,
            "size": "L",
            "faculty_count": 2228
        },
        {
            "university": "Indian Institute of Technology Kharagpur (IIT-KGP)",
            "year": 2021,
            "rank_display": 314,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kharagpur-iit-kgp",
            "country": "India",
            "city": "Kharagpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kharagpur-iit-kgp_285_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 27,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
            "year": 2021,
            "rank_display": 314,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/national-research-nuclear-university-mephi-moscow-engineering-physics-institute",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-nuclear-university-mephi-moscow-engineering-physics-institute_15207_small.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1590,
            "size": "M",
            "faculty_count": 998
        },
        {
            "university": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
            "year": 2021,
            "rank_display": 314,
            "score": 33.2,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-catalunya-barcelonatech-upc",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-catalunya_103_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3101,
            "size": "L",
            "faculty_count": 2485
        },
        {
            "university": "Belarusian State University",
            "year": 2021,
            "rank_display": 317,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/belarusian-state-university",
            "country": "Belarus",
            "city": "Minsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/belarusian-state-university_14128_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4264,
            "size": "L",
            "faculty_count": 4173
        },
        {
            "university": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            "year": 2021,
            "rank_display": 317,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/friedrich-alexander-universitat-erlangen-nurnberg",
            "country": "Germany",
            "city": "Erlangen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/friedrich-alexander-universitt-erlangen-nrnberg_191_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 4943,
            "size": "XL",
            "faculty_count": 1107
        },
        {
            "university": "University of Dundee",
            "year": 2021,
            "rank_display": 319,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/university-dundee",
            "country": "United Kingdom",
            "city": "Dundee",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/-university-of-dundee_592560cf2aeae70239af4b2c_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 3025,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Indiana University Bloomington",
            "year": 2021,
            "rank_display": 320,
            "score": 32.6,
            "link": "https://www.topuniversities.com/universities/indiana-university-bloomington",
            "country": "United States",
            "city": "Bloomington",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/indiana-university-bloomington_289_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3722,
            "size": "XL",
            "faculty_count": 3837
        },
        {
            "university": "Hiroshima University",
            "year": 2021,
            "rank_display": 321,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/hiroshima-university",
            "country": "Japan",
            "city": "Higashihiroshima City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hiroshima-university_264_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.68,
            "size": "L",
            "faculty_count": 2.366
        },
        {
            "university": "University of Warsaw",
            "year": 2021,
            "rank_display": 321,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/university-warsaw",
            "country": "Poland",
            "city": "Warsaw",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-warsaw_663_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2892,
            "size": "XL",
            "faculty_count": 3658
        },
        {
            "university": "Simon Fraser University",
            "year": 2021,
            "rank_display": 323,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/simon-fraser-university",
            "country": "Canada",
            "city": "Burnaby",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/simon-fraser-university_562_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 8051,
            "size": "L",
            "faculty_count": 1193
        },
        {
            "university": "Southern University of Science and Technology",
            "year": 2021,
            "rank_display": 323,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/southern-university-science-technology",
            "country": "China (Mainland)",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/southern-university-of-science-and-technology-sustech_5963564fca24f34bfa1fd83a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 88,
            "size": "M",
            "faculty_count": 981
        },
        {
            "university": "Universität Jena",
            "year": 2021,
            "rank_display": 323,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/universitat-jena",
            "country": "Germany",
            "city": "Jena",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-jena_592560cf2aeae70239af4bb5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2.307,
            "size": "L",
            "faculty_count": 3.974
        },
        {
            "university": "IE University",
            "year": 2021,
            "rank_display": 327,
            "score": 32,
            "link": "https://www.topuniversities.com/universities/ie-university",
            "country": "Spain",
            "city": "Segovia",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ie-university_293_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 3917,
            "size": "S",
            "faculty_count": 662
        },
        {
            "university": "Jagiellonian University",
            "year": 2021,
            "rank_display": 326,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/jagiellonian-university",
            "country": "Poland",
            "city": "Krakow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/jagiellonian-university_300_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2907,
            "size": "XL",
            "faculty_count": 4089
        },
        {
            "university": "Pontificia Universidad Católica Argentina",
            "year": 2021,
            "rank_display": 326,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-argentina",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-argentina_592560cf2aeae70239af5486_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 4,
            "international_students": 1063,
            "size": "L",
            "faculty_count": 3805
        },
        {
            "university": "RUDN University",
            "year": 2021,
            "rank_display": 326,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/rudn-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rudn-university_1503_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 8319,
            "size": "L",
            "faculty_count": 3890
        },
        {
            "university": "Universitat Politècnica de València",
            "year": 2021,
            "rank_display": 326,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-valencia",
            "country": "Spain",
            "city": "València",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-valncia_648_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.589,
            "size": "L",
            "faculty_count": 2.645
        },
        {
            "university": "Virginia Polytechnic Institute and State University",
            "year": 2021,
            "rank_display": 326,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/virginia-polytechnic-institute-state-university",
            "country": "United States",
            "city": "Blacksburg",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/virginia-polytechnic-institute-and-state-university_592560cf2aeae70239af4d15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.477,
            "size": "XL",
            "faculty_count": 2.802
        },
        {
            "university": "National Taiwan Normal University",
            "year": 2021,
            "rank_display": 331,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/national-taiwan-normal-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-normal-university_440_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.499,
            "size": "M",
            "faculty_count": 1.374
        },
        {
            "university": "Ural Federal University - UrFU",
            "year": 2021,
            "rank_display": 331,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/ural-federal-university-urfu",
            "country": "Russia",
            "city": "Ekaterinburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ural-federal-university-urfu_592560cf2aeae70239af505a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5.089,
            "size": "L",
            "faculty_count": 5.009
        },
        {
            "university": "Ewha Womans University",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/ewha-womans-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ewha-womans-university_592560cf2aeae70239af4b38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.105,
            "size": "L",
            "faculty_count": 2.436
        },
        {
            "university": "Umea University",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/umea-university",
            "country": "Sweden",
            "city": "Umeå",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/logo_Umu-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.186,
            "size": "L",
            "faculty_count": 1.966
        },
        {
            "university": "Universität Stuttgart",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/universitat-stuttgart",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-stuttgart_580_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.028,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "University of East Anglia (UEA)",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/university-east-anglia-uea",
            "country": "United Kingdom",
            "city": "Norwich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-east-anglia-uea_637_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3541,
            "size": "L",
            "faculty_count": 1206
        },
        {
            "university": "University of Hawaiʻi at Mānoa",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/university-hawaii-manoa",
            "country": "United States",
            "city": "Honolulu",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hawaii-at-maoa_255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.586,
            "size": "L",
            "faculty_count": 1.64
        },
        {
            "university": "University of Jyväskylä",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/university-jyvaskyla",
            "country": "Finland",
            "city": "Jyväskylä",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-jyvskyl_306_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 339,
            "size": "M",
            "faculty_count": 1.573
        },
        {
            "university": "University of Utah",
            "year": 2021,
            "rank_display": 333,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/university-utah",
            "country": "United States",
            "city": "Salt Lake City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-utah_645_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.134,
            "size": "L",
            "faculty_count": 3.345
        },
        {
            "university": "Yeshiva University",
            "year": 2021,
            "rank_display": 341,
            "score": 31.6,
            "link": "https://www.topuniversities.com/universities/yeshiva-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-katz-school-at-yeshiva-university_685_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 467,
            "size": "S",
            "faculty_count": 471
        },
        {
            "university": "Université Grenoble Alpes",
            "year": 2021,
            "rank_display": 342,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/universite-grenoble-alpes",
            "country": "France",
            "city": "Grenoble",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-grenoble-alpes_592560cf2aeae70239af5ab2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8880,
            "size": "XL",
            "faculty_count": 3088
        },
        {
            "university": "University of Chemistry and Technology, Prague",
            "year": 2021,
            "rank_display": 342,
            "score": 31.5,
            "link": "https://www.topuniversities.com/universities/university-chemistry-technology-prague",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chemistry-and-technology-prague_592560cf2aeae70239af4f53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 864,
            "size": "S",
            "faculty_count": 903
        },
        {
            "university": "Birkbeck, University of London",
            "year": 2021,
            "rank_display": 344,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/birkbeck-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/birkbeck-university-of-london_1636_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3.214,
            "size": "M",
            "faculty_count": 585
        },
        {
            "university": "University Ulm",
            "year": 2021,
            "rank_display": 344,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-ulm",
            "country": "Germany",
            "city": "Ulm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-ulm_638_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1289,
            "size": "M",
            "faculty_count": 1473
        },
        {
            "university": "SOAS University of London",
            "year": 2021,
            "rank_display": 346,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/soas-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/soas-university-of-london-_592560cf2aeae70239af4bf0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.398,
            "size": "S",
            "faculty_count": 300
        },
        {
            "university": "University at Buffalo SUNY",
            "year": 2021,
            "rank_display": 346,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/university-buffalo-suny",
            "country": "United States",
            "city": "Buffalo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-at-buffalo-suny_1888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4.409,
            "size": "L",
            "faculty_count": 1.53
        },
        {
            "university": "American University of Sharjah",
            "year": 2021,
            "rank_display": 348,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/american-university-sharjah",
            "country": "United Arab Emirates",
            "city": "Sharjah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-sharjah_1201_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.199,
            "size": "S",
            "faculty_count": 371
        },
        {
            "university": "MGIMO University",
            "year": 2021,
            "rank_display": 348,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/mgimo-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-state-institute-of-international-relations-mgimo-university_2156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 1517,
            "size": "M",
            "faculty_count": 1739
        },
        {
            "university": "City, University of London",
            "year": 2021,
            "rank_display": 350,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/city-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-london_354_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 7.362,
            "size": "L",
            "faculty_count": 970
        },
        {
            "university": "Indian Institute of Technology Kanpur (IITK)",
            "year": 2021,
            "rank_display": 350,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kanpur-iitk",
            "country": "India",
            "city": "Kanpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kanpur-iitk_592560cf2aeae70239af4ba5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 29,
            "size": "M",
            "faculty_count": 551
        },
        {
            "university": "Universiti Teknologi Brunei",
            "year": 2021,
            "rank_display": 350,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-brunei",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-brunei_592560e49988f300e23219b2_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 139
        },
        {
            "university": "George Washington University",
            "year": 2021,
            "rank_display": 353,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/george-washington-university",
            "country": "United States",
            "city": "Ashburn",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/george-washington-university_223_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4.024,
            "size": "L",
            "faculty_count": 2.016
        },
        {
            "university": "University of Southern Denmark (SDU)",
            "year": 2021,
            "rank_display": 353,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/university-southern-denmark-sdu",
            "country": "Denmark",
            "city": "Odense",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-denmark_592560cf2aeae70239af4c53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2511,
            "size": "L",
            "faculty_count": 1760
        },
        {
            "university": "National University of Sciences And Technology (NUST) Islamabad",
            "year": 2021,
            "rank_display": 355,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/national-university-sciences-technology-nust-islamabad",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-sciences-and-technology-nust-islamabad_698_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 563,
            "size": "M",
            "faculty_count": 1.608
        },
        {
            "university": "Westfälische Wilhelms-Universität Münster",
            "year": 2021,
            "rank_display": 356,
            "score": 30.3,
            "link": "https://www.topuniversities.com/universities/westfalische-wilhelms-universitat-munster",
            "country": "Germany",
            "city": "Münster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/westflische-wilhelms-universitt-mnster_421_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.845,
            "size": "XL",
            "faculty_count": 4.23
        },
        {
            "university": "L.N. Gumilyov Eurasian National University (ENU)",
            "year": 2021,
            "rank_display": 357,
            "score": 30.2,
            "link": "https://www.topuniversities.com/universities/ln-gumilyov-eurasian-national-university-enu",
            "country": "Kazakhstan",
            "city": "Nur-Sultan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/l.n.-gumilyov-eurasian-national-university-enu_592560cf2aeae70239af52e8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1.077,
            "size": "L",
            "faculty_count": 3.35
        },
        {
            "university": "University of Lisbon",
            "year": 2021,
            "rank_display": 357,
            "score": 30.2,
            "link": "https://www.topuniversities.com/universities/university-lisbon",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lisbon_1475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8.546,
            "size": "XL",
            "faculty_count": 3.389
        },
        {
            "university": "University of Porto",
            "year": 2021,
            "rank_display": 357,
            "score": 30.2,
            "link": "https://www.topuniversities.com/universities/university-porto",
            "country": "Portugal",
            "city": "Porto",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-porto_760_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3783,
            "size": "L",
            "faculty_count": 2028
        },
        {
            "university": "ITMO University",
            "year": 2021,
            "rank_display": 360,
            "score": 30.1,
            "link": "https://www.topuniversities.com/universities/itmo-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/itmo-university_592560cf2aeae70239af5829_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.337,
            "size": "M",
            "faculty_count": 1.849
        },
        {
            "university": "Norwegian University of Science And Technology",
            "year": 2021,
            "rank_display": 360,
            "score": 30.1,
            "link": "https://www.topuniversities.com/universities/norwegian-university-science-technology",
            "country": "Norway",
            "city": "Trondheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/norwegian-university-of-science-and-technology_457_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.95,
            "size": "XL",
            "faculty_count": 3.132
        },
        {
            "university": "Johannes Kepler University Linz",
            "year": 2021,
            "rank_display": 362,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/johannes-kepler-university-linz",
            "country": "Austria",
            "city": "Linz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-kepler-university-linz_349_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 774,
            "size": "M",
            "faculty_count": 897
        },
        {
            "university": "Linköping University",
            "year": 2021,
            "rank_display": 362,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/linkoping-university",
            "country": "Sweden",
            "city": "Linköping",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/linkping-university_348_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.514,
            "size": "L",
            "faculty_count": 1.471
        },
        {
            "university": "Northeastern University",
            "year": 2021,
            "rank_display": 362,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/northeastern-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northeastern-university_454_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7.595,
            "size": "L",
            "faculty_count": 1.723
        },
        {
            "university": "Tokyo Medical and Dental University (TMDU)",
            "year": 2021,
            "rank_display": 362,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/tokyo-medical-dental-university-tmdu",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-medical-and-dental-university-tmdu_592560cf2aeae70239af4e8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 325,
            "size": "S",
            "faculty_count": 930
        },
        {
            "university": "University of Macau",
            "year": 2021,
            "rank_display": 367,
            "score": 29.9,
            "link": "https://www.topuniversities.com/universities/university-macau",
            "country": "Macau SAR",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-macau_14378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.921,
            "size": "M",
            "faculty_count": 650
        },
        {
            "university": "Brunel University London",
            "year": 2021,
            "rank_display": 368,
            "score": 29.7,
            "link": "https://www.topuniversities.com/universities/brunel-university-london",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/brunel-university-london_592560cf2aeae70239af4acc_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 5.305,
            "size": "L",
            "faculty_count": 860
        },
        {
            "university": "Tilburg University",
            "year": 2021,
            "rank_display": 368,
            "score": 29.7,
            "link": "https://www.topuniversities.com/universities/tilburg-university",
            "country": "Netherlands",
            "city": "Tilburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tilburg-university_592560cf2aeae70239af4ce5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.189,
            "size": "M",
            "faculty_count": 745
        },
        {
            "university": "Kazan (Volga region) Federal University",
            "year": 2021,
            "rank_display": 370,
            "score": 29.5,
            "link": "https://www.topuniversities.com/universities/kazan-volga-region-federal-university",
            "country": "Russia",
            "city": "Kazan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kazan-volga-region-federal-university_313_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 8.375,
            "size": "L",
            "faculty_count": 3.847
        },
        {
            "university": "University of Victoria (UVic)",
            "year": 2021,
            "rank_display": 370,
            "score": 29.5,
            "link": "https://www.topuniversities.com/universities/university-victoria-uvic",
            "country": "Canada",
            "city": "Victoria",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-victoria-uvic_592560cf2aeae70239af4d0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 6.135,
            "size": "L",
            "faculty_count": 865
        },
        {
            "university": "Swinburne University of Technology",
            "year": 2021,
            "rank_display": 372,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/swinburne-university-technology",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/swinburne-university-of-technology_759_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4000,
            "size": "L",
            "faculty_count": 946
        },
        {
            "university": "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
            "year": 2021,
            "rank_display": 373,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/pakistan-institute-engineering-applied-sciences-pieas",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pakistan-institute-of-engineering-and-applied-sciences-pieas_592560cf2aeae70239af532f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "Stony Brook University, State University of New York",
            "year": 2021,
            "rank_display": 373,
            "score": 29.2,
            "link": "https://www.topuniversities.com/universities/stony-brook-university-state-university-new-york",
            "country": "United States",
            "city": "Stony Brook",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stony-brook-university-state-university-of-new-york_592560cf2aeae70239af4ccb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6.198,
            "size": "L",
            "faculty_count": 2.316
        },
        {
            "university": "Sultan Qaboos University",
            "year": 2021,
            "rank_display": 375,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/sultan-qaboos-university",
            "country": "Oman",
            "city": "Muscat",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sultan-qaboos-university_2530_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 248,
            "size": "M",
            "faculty_count": 916
        },
        {
            "university": "University of Waikato",
            "year": 2021,
            "rank_display": 375,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/university-waikato",
            "country": "New Zealand",
            "city": "Hamilton",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waikato_660_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.27,
            "size": "M",
            "faculty_count": 595
        },
        {
            "university": "Nankai University",
            "year": 2021,
            "rank_display": 377,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/nankai-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nankai-university_882_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.488,
            "size": "XL",
            "faculty_count": 2.846
        },
        {
            "university": "Universidad de Palermo (UP)",
            "year": 2021,
            "rank_display": 377,
            "score": 29,
            "link": "https://www.topuniversities.com/universities/universidad-de-palermo",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-palermo-up_592560cf2aeae70239af54de_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.049,
            "size": "M",
            "faculty_count": 833
        },
        {
            "university": "Taylor's University",
            "year": 2021,
            "rank_display": 379,
            "score": 28.9,
            "link": "https://www.topuniversities.com/universities/taylors-university",
            "country": "Malaysia",
            "city": "Subang Jaya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taylors-university_592560cf2aeae70239af57af_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.704,
            "size": "S",
            "faculty_count": 578
        },
        {
            "university": "Universidade Federal do Rio de Janeiro",
            "year": 2021,
            "rank_display": 380,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/universidade-federal-do-rio-de-janeiro",
            "country": "Brazil",
            "city": "Rio de Janeiro",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-do-rio-de-janeiro_525_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 555,
            "size": "XL",
            "faculty_count": 3.983
        },
        {
            "university": "University of Kent",
            "year": 2021,
            "rank_display": 380,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/university-kent",
            "country": "United Kingdom",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kent_315_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.515,
            "size": "L",
            "faculty_count": 4000
        },
        {
            "university": "Wake Forest University",
            "year": 2021,
            "rank_display": 380,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/wake-forest-university",
            "country": "United States",
            "city": "Winston-Salem",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wake-forest-university_661_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 805,
            "size": "M",
            "faculty_count": 2.276
        },
        {
            "university": "Indian Institute of Technology Roorkee (IITR)",
            "year": 2021,
            "rank_display": 383,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-roorkee-iitr",
            "country": "India",
            "city": "Roorkee",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-roorkee-iitr_287_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 202,
            "size": "M",
            "faculty_count": 499
        },
        {
            "university": "Oxford Brookes University",
            "year": 2021,
            "rank_display": 383,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/oxford-brookes-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/oxford-brookes-university_592560cf2aeae70239af4c61_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 15,
            "international_students": 3.439,
            "size": "L",
            "faculty_count": 1471
        },
        {
            "university": "University of Pisa",
            "year": 2021,
            "rank_display": 383,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/university-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pisa_499_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 1.507,
            "size": "XL",
            "faculty_count": 1.796
        },
        {
            "university": "University of Kansas",
            "year": 2021,
            "rank_display": 383,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/university-kansas",
            "country": "United States",
            "city": "Lawrence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kansas_309_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2.014,
            "size": "L",
            "faculty_count": 3.357
        },
        {
            "university": "Lincoln University",
            "year": 2021,
            "rank_display": 387,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/lincoln-university",
            "country": "New Zealand",
            "city": "Lincoln",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/lincoln-university-_592560cf2aeae70239af4ef3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 898,
            "size": "S",
            "faculty_count": 643
        },
        {
            "university": "Shanghai University",
            "year": 2021,
            "rank_display": 387,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/shanghai-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-university_557_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.195,
            "size": "XL",
            "faculty_count": 4.156
        },
        {
            "university": "Taipei Medical University (TMU)",
            "year": 2021,
            "rank_display": 387,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/taipei-medical-university-tmu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taipei-medical-university_2134_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 614,
            "size": "M",
            "faculty_count": 999
        },
        {
            "university": "Tianjin University",
            "year": 2021,
            "rank_display": 387,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/tianjin-university",
            "country": "China (Mainland)",
            "city": "Nankai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tianjin-university_609_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.425,
            "size": "L",
            "faculty_count": 3.147
        },
        {
            "university": "UCSI University",
            "year": 2021,
            "rank_display": 391,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/ucsi-university",
            "country": "Malaysia",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ucsi-university_592560e09988f300e2320ce5_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1.597,
            "size": "M",
            "faculty_count": 744
        },
        {
            "university": "Beijing Institute of Technology",
            "year": 2021,
            "rank_display": 392,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/beijing-institute-technology",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-institute-of-technology_868_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.115,
            "size": "XL",
            "faculty_count": 2.811
        },
        {
            "university": "HUFS - Hankuk (Korea) University of Foreign Studies",
            "year": 2021,
            "rank_display": 392,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/hufs-hankuk-korea-university-foreign-studies",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hufs-hankuk-korea-university-of-foreign-studies_1054_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.933,
            "size": "L",
            "faculty_count": 2.353
        },
        {
            "university": "University of Naples - Federico II",
            "year": 2021,
            "rank_display": 392,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/university-naples-federico-ii",
            "country": "Italy",
            "city": "Naples",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-naples-federico-ii_432_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 128,
            "size": "XL",
            "faculty_count": 2.299
        },
        {
            "university": "Università Vita-Salute San Raffaele",
            "year": 2021,
            "rank_display": 392,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/universita-vita-salute-san-raffaele",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-vita-salute-san-raffaele_592560cf2aeae70239af5016_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 94,
            "size": "S",
            "faculty_count": 451
        },
        {
            "university": "Huazhong University of Science and Technology",
            "year": 2021,
            "rank_display": 396,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/huazhong-university-science-technology",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/huazhong-university-of-science-and-technology_592560cf2aeae70239af5256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.796,
            "size": "XL",
            "faculty_count": 4.014
        },
        {
            "university": "University of the Philippines",
            "year": 2021,
            "rank_display": 396,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/university-philippines",
            "country": "Philippines",
            "city": "Quezon City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-philippines_498_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 292,
            "size": "XL",
            "faculty_count": 4.48
        },
        {
            "university": "La Trobe University",
            "year": 2021,
            "rank_display": 398,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/la-trobe-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/la-trobe-university_592560cf2aeae70239af4bd1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 9.456,
            "size": "L",
            "faculty_count": 1.252
        },
        {
            "university": "Université de Strasbourg",
            "year": 2021,
            "rank_display": 398,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/universite-de-strasbourg",
            "country": "France",
            "city": "Strasbourg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-strasbourg_578_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 9.111,
            "size": "XL",
            "faculty_count": 2.544
        },
        {
            "university": "University of Colorado, Denver",
            "year": 2021,
            "rank_display": 398,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/university-colorado-denver1",
            "country": "United States",
            "city": "Denver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-denver_2116_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 1.274,
            "size": "L",
            "faculty_count": 5.319
        },
        {
            "university": "Peter the Great St. Petersburg Polytechnic University",
            "year": 2021,
            "rank_display": 401,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/peter-great-st-petersburg-polytechnic-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/peter-the-great-st.-petersburg-polytechnic-university_592560cf2aeae70239af52ed_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5.121,
            "size": "L",
            "faculty_count": 2.355
        },
        {
            "university": "National Research Tomsk Polytechnic University",
            "year": 2021,
            "rank_display": 401,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/national-research-tomsk-polytechnic-university",
            "country": "Russia",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-tomsk-polytechnic-university_14158_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.757,
            "size": "M",
            "faculty_count": 1.55
        },
        {
            "university": "Chung-Ang University (CAU)",
            "year": 2021,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/chung-ang-university-cau",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chung-ang-university-cau_1042_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3.099,
            "size": "L",
            "faculty_count": 2.65
        },
        {
            "university": "Johannes Gutenberg Universität Mainz",
            "year": 2021,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/johannes-gutenberg-universitat-mainz",
            "country": "Germany",
            "city": "Mainz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-gutenberg-universitt-mainz_382_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.605,
            "size": "XL",
            "faculty_count": 3.473
        },
        {
            "university": "Kobe University",
            "year": 2021,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/kobe-university",
            "country": "Japan",
            "city": "Kobe City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kobe-university_320_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.216,
            "size": "L",
            "faculty_count": 1.986
        },
        {
            "university": "University of Trento",
            "year": 2021,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/university-trento",
            "country": "Italy",
            "city": "Trento",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-trento_623_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 738,
            "size": "L",
            "faculty_count": 1.066
        },
        {
            "university": "University of Witwatersrand",
            "year": 2021,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/university-witwatersrand",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-witwatersrand_679_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.885,
            "size": "L",
            "faculty_count": 1.626
        },
        {
            "university": "University of Oulu",
            "year": 2021,
            "rank_display": 408,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/university-oulu",
            "country": "Finland",
            "city": "Oulu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oulu_476_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 579,
            "size": "M",
            "faculty_count": 883
        },
        {
            "university": "Sharif University of Technology",
            "year": 2021,
            "rank_display": 409,
            "score": 27.6,
            "link": "https://www.topuniversities.com/universities/sharif-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sharif-university-of-technology_592560cf2aeae70239af4d42_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 198,
            "size": "M",
            "faculty_count": 495
        },
        {
            "university": "Tampere University",
            "year": 2021,
            "rank_display": 409,
            "score": 27.6,
            "link": "https://www.topuniversities.com/universities/tampere-university",
            "country": "Finland",
            "city": "Tampere",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tampere-university_5bbf14847d023f5bc849ec9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 996,
            "size": "L",
            "faculty_count": 1.31
        },
        {
            "university": "The American University in Cairo",
            "year": 2021,
            "rank_display": 411,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/american-university-cairo",
            "country": "Egypt",
            "city": "Cairo",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/the-american-university-in-cairo_36_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 213,
            "size": "M",
            "faculty_count": 578
        },
        {
            "university": "Essex, University of",
            "year": 2021,
            "rank_display": 411,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/essex-university",
            "country": "United Kingdom",
            "city": "Colchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-essex_592560cf2aeae70239af4b4c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6.124,
            "size": "L",
            "faculty_count": 1.044
        },
        {
            "university": "Washington State University",
            "year": 2021,
            "rank_display": 411,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/washington-state-university",
            "country": "United States",
            "city": "Pullman",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-state-university_666_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.731,
            "size": "L",
            "faculty_count": 1.935
        },
        {
            "university": "Bond University",
            "year": 2021,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/bond-university",
            "country": "Australia",
            "city": "Gold Coast",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/bond-university_715_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1.302,
            "size": "S",
            "faculty_count": 325
        },
        {
            "university": "Tulane University",
            "year": 2021,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/tulane-university",
            "country": "United States",
            "city": "New Orleans",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tulane-university_631_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.172,
            "size": "M",
            "faculty_count": 1.733
        },
        {
            "university": "Goldsmiths, University of London",
            "year": 2021,
            "rank_display": 416,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/goldsmiths-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/goldsmiths-university-of-london_355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.492,
            "size": "M",
            "faculty_count": 683
        },
        {
            "university": "National Sun Yat-sen University",
            "year": 2021,
            "rank_display": 416,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/national-sun-yat-sen-university",
            "country": "Taiwan",
            "city": "Kaohsiung City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-sun-yat-sen-university_439_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 757,
            "size": "M",
            "faculty_count": 735
        },
        {
            "university": "University of California, Santa Cruz",
            "year": 2021,
            "rank_display": 416,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/university-california-santa-cruz",
            "country": "United States",
            "city": "Santa Cruz",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-cruz_92_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 2.13,
            "size": "L",
            "faculty_count": 1.045
        },
        {
            "university": "University of Tromsø The Arctic University of Norway",
            "year": 2021,
            "rank_display": 416,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/university-tromso-arctic-university-norway",
            "country": "Norway",
            "city": "Tromsø",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-troms-the-arctic-university-of-norway_625_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.134,
            "size": "L",
            "faculty_count": 2.107
        },
        {
            "university": "Université Laval",
            "year": 2021,
            "rank_display": 420,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/universite-laval",
            "country": "Canada",
            "city": "Québec",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/laval-university_337_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3.673,
            "size": "L",
            "faculty_count": 2.29
        },
        {
            "university": "Universidade Federal de São Paulo",
            "year": 2021,
            "rank_display": 420,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/universidade-federal-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-de-so-paulo_2750_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 153,
            "size": "L",
            "faculty_count": 2.451
        },
        {
            "university": "University of Iowa",
            "year": 2021,
            "rank_display": 420,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/university-iowa",
            "country": "United States",
            "city": "Iowa City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-iowa_592560cf2aeae70239af4baa_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.049,
            "size": "L",
            "faculty_count": 2.924
        },
        {
            "university": "Flinders University",
            "year": 2021,
            "rank_display": 423,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/flinders-university",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/flinders-university_592560cf2aeae70239af4b53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 5.981,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "Vilnius University",
            "year": 2021,
            "rank_display": 423,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/vilnius-university",
            "country": "Lithuania",
            "city": "Vilnius",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vilnius-university-_592560cf2aeae70239af4d7f_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.29,
            "size": "L",
            "faculty_count": 2.461
        },
        {
            "university": "University of Bordeaux",
            "year": 2021,
            "rank_display": 425,
            "score": 26.9,
            "link": "https://www.topuniversities.com/universities/university-bordeaux",
            "country": "France",
            "city": "Talence",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bordeaux_1286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5.643,
            "size": "XL",
            "faculty_count": 3.195
        },
        {
            "university": "Illinois Institute of Technology",
            "year": 2021,
            "rank_display": 426,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/illinois-institute-technology",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/illinois-institute-of-technology_277_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.33,
            "size": "M",
            "faculty_count": 402
        },
        {
            "university": "Pontificia Universidad Javeriana",
            "year": 2021,
            "rank_display": 426,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-javeriana",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-javeriana_592560cf2aeae70239af514a_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 415,
            "size": "L",
            "faculty_count": 2.082
        },
        {
            "university": "The National University of Science and Technology MISIS",
            "year": 2021,
            "rank_display": 428,
            "score": 26.6,
            "link": "https://www.topuniversities.com/universities/national-university-science-technology-misis",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-national-university-of-science-and-technology-misis_14956_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.605,
            "size": "M",
            "faculty_count": 915
        },
        {
            "university": "Universidade Nova de Lisboa",
            "year": 2021,
            "rank_display": 428,
            "score": 26.6,
            "link": "https://www.topuniversities.com/universities/universidade-nova-de-lisboa",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-nova-de-lisboa_460_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.427,
            "size": "L",
            "faculty_count": 1.72
        },
        {
            "university": "University of St.Gallen (HSG)",
            "year": 2021,
            "rank_display": 428,
            "score": 26.6,
            "link": "https://www.topuniversities.com/universities/university-stgallen-hsg",
            "country": "Switzerland",
            "city": "St. Gallen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st.gallen-hsg_571_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 2.39,
            "size": "M",
            "faculty_count": 444
        },
        {
            "university": "University of Coimbra",
            "year": 2021,
            "rank_display": 431,
            "score": 26.5,
            "link": "https://www.topuniversities.com/universities/university-coimbra",
            "country": "Portugal",
            "city": "Coimbra",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-coimbra_592560cf2aeae70239af4b07_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.477,
            "size": "L",
            "faculty_count": 1.74
        },
        {
            "university": "Czech Technical University in Prague",
            "year": 2021,
            "rank_display": 432,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/czech-technical-university-prague",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/czech-technical-university-in-prague_592560cf2aeae70239af4d3b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3.006,
            "size": "L",
            "faculty_count": 2.628
        },
        {
            "university": "Pontificia Universidad Católica del Perú",
            "year": 2021,
            "rank_display": 432,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-del-peru",
            "country": "Peru",
            "city": "Lima",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-del-per_592560cf2aeae70239af4af0_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 292,
            "size": "L",
            "faculty_count": 2369
        },
        {
            "university": "University of Florence",
            "year": 2021,
            "rank_display": 432,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/university-florence",
            "country": "Italy",
            "city": "Florence",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florence_208_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 4.203,
            "size": "XL",
            "faculty_count": 1.775
        },
        {
            "university": "The University of Tennessee, Knoxville",
            "year": 2021,
            "rank_display": 432,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/university-tennessee-knoxville",
            "country": "United States",
            "city": "Knoxville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tennessee-knoxville_603_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 17,
            "international_students": 1156,
            "size": "XL",
            "faculty_count": 1700
        },
        {
            "university": "Xiamen University",
            "year": 2021,
            "rank_display": 432,
            "score": 26.4,
            "link": "https://www.topuniversities.com/universities/xiamen-university",
            "country": "China (Mainland)",
            "city": "Xiamen",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xiamen-university_891_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.443,
            "size": "XL",
            "faculty_count": 3.106
        },
        {
            "university": "Auckland University of Technology (AUT)",
            "year": 2021,
            "rank_display": 437,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/auckland-university-technology-aut",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/auckland-university-of-technology-aut_592560cf2aeae70239af4ef7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8.926,
            "size": "L",
            "faculty_count": 1255
        },
        {
            "university": "Julius-Maximilians-Universität Würzburg",
            "year": 2021,
            "rank_display": 437,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/julius-maximilians-universitat-wurzburg",
            "country": "Germany",
            "city": "Würzburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/julius-maximilians-universitt-wrzburg_592560cf2aeae70239af4d2d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 1.951,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Dublin City University",
            "year": 2021,
            "rank_display": 439,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/dublin-city-university",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/dublin-city-university_592560cf2aeae70239af4b27_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.415,
            "size": "L",
            "faculty_count": 1.257
        },
        {
            "university": "Ruhr-Universität Bochum",
            "year": 2021,
            "rank_display": 439,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/ruhr-universitat-bochum",
            "country": "Germany",
            "city": "Bochum",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruhr-universitt-bochum_537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 35,
            "international_students": 6.564,
            "size": "XL",
            "faculty_count": 1.232
        },
        {
            "university": "Universiti Teknologi PETRONAS (UTP)",
            "year": 2021,
            "rank_display": 439,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-petronas-utp",
            "country": "Malaysia",
            "city": "Seri Iskandar",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-petronas-utp_592560cf2aeae70239af4ef2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 639,
            "size": "M",
            "faculty_count": 438
        },
        {
            "university": "University of Johannesburg",
            "year": 2021,
            "rank_display": 439,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/university-johannesburg",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-johannesburg-_592560cf2aeae70239af4f46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4.016,
            "size": "L",
            "faculty_count": 1.886
        },
        {
            "university": "Colorado State University",
            "year": 2021,
            "rank_display": 443,
            "score": 25.9,
            "link": "https://www.topuniversities.com/universities/colorado-state-university",
            "country": "United States",
            "city": "Fort Collins",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/colorado-state-university_136_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.547,
            "size": "L",
            "faculty_count": 3.115
        },
        {
            "university": "Universidad Austral",
            "year": 2021,
            "rank_display": 443,
            "score": 25.9,
            "link": "https://www.topuniversities.com/universities/universidad-austral",
            "country": "Argentina",
            "city": "Pilar",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-austral_38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 334,
            "size": "S",
            "faculty_count": 663
        },
        {
            "university": "Universität Konstanz",
            "year": 2021,
            "rank_display": 443,
            "score": 25.9,
            "link": "https://www.topuniversities.com/universities/universitat-konstanz",
            "country": "Germany",
            "city": "Konstanz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-konstanz_323_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.239,
            "size": "M",
            "faculty_count": 1.039
        },
        {
            "university": "Ben-Gurion University of The Negev",
            "year": 2021,
            "rank_display": 446,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/ben-gurion-university-negev",
            "country": "Israel",
            "city": "Be'er Sheva",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ben-gurion-university-of-the-negev_592560cf2aeae70239af4ab7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.755,
            "size": "L",
            "faculty_count": 1.06
        },
        {
            "university": "Brandeis University",
            "year": 2021,
            "rank_display": 446,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/brandeis-university",
            "country": "United States",
            "city": "Waltham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brandeis-university_66_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.554,
            "size": "M",
            "faculty_count": 611
        },
        {
            "university": "University of Science and Technology Beijing",
            "year": 2021,
            "rank_display": 446,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/university-science-technology-beijing",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-beijing_2004_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 818,
            "size": "L",
            "faculty_count": 1.605
        },
        {
            "university": "Beihang University (former BUAA)",
            "year": 2021,
            "rank_display": 449,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/beihang-university-former-buaa",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beihang-university-former-buaa_870_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.575,
            "size": "XL",
            "faculty_count": 2.529
        },
        {
            "university": "University of California, Riverside",
            "year": 2021,
            "rank_display": 449,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/university-california-riverside",
            "country": "United States",
            "city": "Riverside",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-riverside_88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.034,
            "size": "L",
            "faculty_count": 1.497
        },
        {
            "university": "Universidad Politécnica de Madrid (UPM)",
            "year": 2021,
            "rank_display": 451,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/universidad-politecnica-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politcnica-de-madrid_1507_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5.319,
            "size": "XL",
            "faculty_count": 2.486
        },
        {
            "university": "Universität des Saarlandes",
            "year": 2021,
            "rank_display": 451,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/universitat-des-saarlandes",
            "country": "Germany",
            "city": "Saarbrücken",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-des-saarlandes_541_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 2.939,
            "size": "L",
            "faculty_count": 2.823
        },
        {
            "university": "Université de Liège",
            "year": 2021,
            "rank_display": 451,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/universite-de-liege",
            "country": "Belgium",
            "city": "Arlon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-lige_592560cf2aeae70239af4bd9_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.381,
            "size": "L",
            "faculty_count": 1.619
        },
        {
            "university": "Boston College",
            "year": 2021,
            "rank_display": 454,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/boston-college",
            "country": "United States",
            "city": "Newton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-college_723_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.471,
            "size": "L",
            "faculty_count": 1.048
        },
        {
            "university": "Quaid-i-Azam University",
            "year": 2021,
            "rank_display": 454,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/quaid-i-azam-university",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/quaid-i-azam-university_592560cf2aeae70239af4d41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 33,
            "international_students": 115,
            "size": "L",
            "faculty_count": 452
        },
        {
            "university": "Dongguk University",
            "year": 2021,
            "rank_display": 456,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/dongguk-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/dongguk-university_1048_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.27,
            "size": "L",
            "faculty_count": 2.668
        },
        {
            "university": "Florida State University",
            "year": 2021,
            "rank_display": 456,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/florida-state-university",
            "country": "United States",
            "city": "Tallahassee",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/florida-state-university_211_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 1.618,
            "size": "XL",
            "faculty_count": 2.236
        },
        {
            "university": "Hasselt University",
            "year": 2021,
            "rank_display": 456,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/hasselt-university",
            "country": "Belgium",
            "city": "Diepenbeek",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/hasselt-university_1255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 736,
            "size": "M",
            "faculty_count": 743
        },
        {
            "university": "Stellenbosch University",
            "year": 2021,
            "rank_display": 456,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/stellenbosch-university",
            "country": "South Africa",
            "city": "Stellenbosch",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/stellenbosch-university_574_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 1.776,
            "size": "L",
            "faculty_count": 934
        },
        {
            "university": "The Catholic University of Korea",
            "year": 2021,
            "rank_display": 456,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/catholic-university-korea",
            "country": "South Korea",
            "city": "Gyeonggi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-catholic-university-of-korea-_592560cf2aeae70239af4ed7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 370,
            "size": "M",
            "faculty_count": 1.668
        },
        {
            "university": "University of Canberra",
            "year": 2021,
            "rank_display": 456,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/university-canberra",
            "country": "Australia",
            "city": "Bruce",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canberra_844_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 27,
            "international_students": 3.132,
            "size": "M",
            "faculty_count": 395
        },
        {
            "university": "James Cook University",
            "year": 2021,
            "rank_display": 462,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/james-cook-university",
            "country": "Australia",
            "city": "Townsville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/james-cook-university_833_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.169,
            "size": "M",
            "faculty_count": 2940
        },
        {
            "university": "South China University of Technology",
            "year": 2021,
            "rank_display": 462,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/south-china-university-technology",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/south-china-university-of-technology_887_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.688,
            "size": "XL",
            "faculty_count": 3.006
        },
        {
            "university": "Universidad ORT Uruguay",
            "year": 2021,
            "rank_display": 462,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/universidad-ort-uruguay",
            "country": "Uruguay",
            "city": "Montevideo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-ort-uruguay_470_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 62,
            "size": "S",
            "faculty_count": 620
        },
        {
            "university": "Koç University",
            "year": 2021,
            "rank_display": 465,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/koc-university",
            "country": "Turkey",
            "city": "Istanbul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ko-university_592560cf2aeae70239af4d7e_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 368,
            "size": "M",
            "faculty_count": 560
        },
        {
            "university": "National Central University",
            "year": 2021,
            "rank_display": 465,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/national-central-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-central-university_434_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.098,
            "size": "M",
            "faculty_count": 985
        },
        {
            "university": "Universidad de Belgrano",
            "year": 2021,
            "rank_display": 465,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/universidad-de-belgrano",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-belgrano_51_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 758,
            "size": "M",
            "faculty_count": 810
        },
        {
            "university": "University of Saskatchewan",
            "year": 2021,
            "rank_display": 465,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/university-saskatchewan",
            "country": "Canada",
            "city": "Saskatoon",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-saskatchewan_1755_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.382,
            "size": "L",
            "faculty_count": 1.415
        },
        {
            "university": "Yokohama City University",
            "year": 2021,
            "rank_display": 465,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/yokohama-city-university",
            "country": "Japan",
            "city": "Yokohama City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yokohama-city-university_592560cf2aeae70239af4d32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 108,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Indian Institute of Technology Guwahati (IITG)",
            "year": 2021,
            "rank_display": 470,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-guwahati-iitg",
            "country": "India",
            "city": "Guwahati",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-guwahati-iitg_283_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 42,
            "size": "M",
            "faculty_count": 410
        },
        {
            "university": "Lappeenranta-Lahti University of Technology LUT",
            "year": 2021,
            "rank_display": 470,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/lappeenranta-lahti-university-technology-lut",
            "country": "Finland",
            "city": "Lappeenranta",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lappeenranta-university-of-technology_592560cf2aeae70239af4f7d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 399,
            "size": "S",
            "faculty_count": 298
        },
        {
            "university": "Martin-Luther-Universität Halle-Wittenberg",
            "year": 2021,
            "rank_display": 470,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/martin-luther-universitat-halle-wittenberg",
            "country": "Germany",
            "city": "Halle",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/martin-luther-universitt-halle-wittenberg_245_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.682,
            "size": "L",
            "faculty_count": 2.942
        },
        {
            "university": "Rensselaer Polytechnic Institute",
            "year": 2021,
            "rank_display": 470,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/rensselaer-polytechnic-institute",
            "country": "United States",
            "city": "Troy",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rensselaer-polytechnic-institute_522_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.594,
            "size": "M",
            "faculty_count": 639
        },
        {
            "university": "Swansea University",
            "year": 2021,
            "rank_display": 474,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/swansea-university",
            "country": "United Kingdom",
            "city": "Swansea",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/swansea-university_592560cf2aeae70239af4cce_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 3.641,
            "size": "L",
            "faculty_count": 3290
        },
        {
            "university": "Umm Al-Qura University",
            "year": 2021,
            "rank_display": 474,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/umm-al-qura-university",
            "country": "Saudi Arabia",
            "city": "Makkah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/umm-al-qura-university-_592560cf2aeae70239af4d44_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 8,
            "international_students": 3.566,
            "size": "XL",
            "faculty_count": 3.619
        },
        {
            "university": "Western Sydney University",
            "year": 2021,
            "rank_display": 474,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/western-sydney-university",
            "country": "Australia",
            "city": "Milperra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/western-sydney-university_848_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 22,
            "international_students": 7.123,
            "size": "XL",
            "faculty_count": 1.62
        },
        {
            "university": "Amirkabir University of Technology",
            "year": 2021,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/amirkabir-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/amirkabir-university-of-technology_592560cf2aeae70239af4e54_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 235,
            "size": "M",
            "faculty_count": 525
        },
        {
            "university": "Concordia University",
            "year": 2021,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/concordia-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/concordia-university_592560cf2aeae70239af4b0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 11.717,
            "size": "XL",
            "faculty_count": 2282
        },
        {
            "university": "National Technical University of Athens",
            "year": 2021,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/national-technical-university-athens",
            "country": "Greece",
            "city": "Athens",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-technical-university-of-athens_592560cf2aeae70239af4aa3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 846,
            "size": "L",
            "faculty_count": 689
        },
        {
            "university": "University of Cyprus (UCY)",
            "year": 2021,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/university-cyprus-ucy",
            "country": "Cyprus",
            "city": "Nicosia",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cyprus-ucy_14238_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 874,
            "size": "M",
            "faculty_count": 318
        },
        {
            "university": "V. N. Karazin Kharkiv National University",
            "year": 2021,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/v-n-karazin-kharkiv-national-university",
            "country": "Ukraine",
            "city": "Kharkiv",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/v.-n.-karazin-kharkiv-national-university_19511_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3.954,
            "size": "L",
            "faculty_count": 2.091
        },
        {
            "university": "Wayne State University",
            "year": 2021,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/wayne-state-university",
            "country": "United States",
            "city": "Detroit",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wayne-state-university_592560cf2aeae70239af51d1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.278,
            "size": "L",
            "faculty_count": 2.699
        },
        {
            "university": "Universität  Leipzig",
            "year": 2021,
            "rank_display": 483,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/universitat-leipzig",
            "country": "Germany",
            "city": "Leipzig",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-leipzig_341_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3.174,
            "size": "L",
            "faculty_count": 4.119
        },
        {
            "university": "University of Missouri, Columbia",
            "year": 2021,
            "rank_display": 483,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/university-missouri-columbia",
            "country": "United States",
            "city": "Columbia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-missouri-columbia_409_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1.273,
            "size": "L",
            "faculty_count": 2.6
        },
        {
            "university": "Aberystwyth University",
            "year": 2021,
            "rank_display": 485,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/aberystwyth-university",
            "country": "United Kingdom",
            "city": "Aberystwyth",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aberystwyth-university_8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.392,
            "size": "M",
            "faculty_count": 516
        },
        {
            "university": "Shandong University",
            "year": 2021,
            "rank_display": 485,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/shandong-university",
            "country": "China (Mainland)",
            "city": "Jinan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shandong-university_555_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.637,
            "size": "XL",
            "faculty_count": 3.406
        },
        {
            "university": "University of Stirling",
            "year": 2021,
            "rank_display": 485,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/university-stirling",
            "country": "United Kingdom",
            "city": "Stirling",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-stirling_575_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.563,
            "size": "M",
            "faculty_count": 728
        },
        {
            "university": "Chiba University",
            "year": 2021,
            "rank_display": 488,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/chiba-university",
            "country": "Japan",
            "city": "Chiba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chiba-university_119_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 736,
            "size": "L",
            "faculty_count": 1.79
        },
        {
            "university": "National Taipei University of Technology",
            "year": 2021,
            "rank_display": 488,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/national-taipei-university-technology",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taipei-university-of-technology_1993_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.04,
            "size": "M",
            "faculty_count": 714
        },
        {
            "university": "Auezov South Kazakhstan University (SKU)",
            "year": 2021,
            "rank_display": 490,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/auezov-south-kazakhstan-university-sku",
            "country": "Kazakhstan",
            "city": "Shymkent",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/auezov-south-kazakhstan-state-university-sksu_592560cf2aeae70239af52eb_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.22,
            "size": "L",
            "faculty_count": 2.902
        },
        {
            "university": "Sogang University",
            "year": 2021,
            "rank_display": 490,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/sogang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sogang-university_565_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.429,
            "size": "M",
            "faculty_count": 1.017
        },
        {
            "university": "Universidad de Montevideo (UM)",
            "year": 2021,
            "rank_display": 492,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/universidad-de-montevideo-um",
            "country": "Uruguay",
            "city": "Montevideo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-montevideo-um_695_small.jpg",
            "type": "Private",
            "research_output": "Low",
            "student_faculty_ratio": 7,
            "international_students": 76,
            "size": "S",
            "faculty_count": 295
        },
        {
            "university": "Chang Gung University",
            "year": 2021,
            "rank_display": 493,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/chang-gung-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chang-gung-university_1187_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 381,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Far Eastern Federal University",
            "year": 2021,
            "rank_display": 493,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/far-eastern-federal-university",
            "country": "Russia",
            "city": "Vladivostok",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/far-eastern-federal-university_592560cf2aeae70239af57c9_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3.262,
            "size": "L",
            "faculty_count": 2.268
        },
        {
            "university": "Jilin University",
            "year": 2021,
            "rank_display": 493,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/jilin-university",
            "country": "China (Mainland)",
            "city": "Changchun",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/jilin-university_304_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1.57,
            "size": "XL",
            "faculty_count": 7.078
        },
        {
            "university": "Southeast University",
            "year": 2021,
            "rank_display": 493,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/southeast-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/southeast-university_568_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.5,
            "size": "XL",
            "faculty_count": 3.409
        },
        {
            "university": "UNESP",
            "year": 2021,
            "rank_display": 493,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/unesp",
            "country": "Brazil",
            "city": "Sao Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/unesp_492_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.491,
            "size": "XL",
            "faculty_count": 2.794
        },
        {
            "university": "Universidad de La Habana",
            "year": 2021,
            "rank_display": 498,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/universidad-de-la-habana",
            "country": "Cuba",
            "city": "Havana",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-la-habana_592560cf2aeae70239af54d1_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 564,
            "size": "L",
            "faculty_count": 1.501
        },
        {
            "university": "Oregon State University",
            "year": 2021,
            "rank_display": 499,
            "score": 23.9,
            "link": "https://www.topuniversities.com/universities/oregon-state-university",
            "country": "United States",
            "city": "Corvallis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/oregon-state-university_592560cf2aeae70239af4dc3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.769,
            "size": "L",
            "faculty_count": 2.147
        },
        {
            "university": "Universidad de Alcalá",
            "year": 2021,
            "rank_display": 499,
            "score": 23.9,
            "link": "https://www.topuniversities.com/universities/universidad-de-alcala",
            "country": "Spain",
            "city": "Alcalá de Henares",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-alcal_1511_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 4.56,
            "size": "L",
            "faculty_count": 2.694
        },
        {
            "university": "University of Eastern Finland",
            "year": 2021,
            "rank_display": 499,
            "score": 23.9,
            "link": "https://www.topuniversities.com/universities/university-eastern-finland",
            "country": "Finland",
            "city": "Kuopio",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-eastern-finland_592560cf2aeae70239af4bcb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 669,
            "size": "M",
            "faculty_count": 1.207
        },
        {
            "university": "Massachusetts Institute of Technology (MIT)",
            "year": 2022,
            "rank_display": 1,
            "score": 100,
            "link": "https://www.topuniversities.com/universities/massachusetts-institute-technology-mit",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/massachusetts-institute-of-technology-mit_410_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 3730,
            "size": "M",
            "faculty_count": 3065
        },
        {
            "university": "University of Oxford",
            "year": 2022,
            "rank_display": 2,
            "score": 99.5,
            "link": "https://www.topuniversities.com/universities/university-oxford",
            "country": "United Kingdom",
            "city": "Oxford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oxford_478_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 8442,
            "size": "L",
            "faculty_count": 6708
        },
        {
            "university": "Stanford University",
            "year": 2022,
            "rank_display": 3,
            "score": 98.7,
            "link": "https://www.topuniversities.com/universities/stanford-university",
            "country": "United States",
            "city": "Stanford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stanford-university_573_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3879,
            "size": "L",
            "faculty_count": 4725
        },
        {
            "university": "University of Cambridge",
            "year": 2022,
            "rank_display": 3,
            "score": 98.7,
            "link": "https://www.topuniversities.com/universities/university-cambridge",
            "country": "United Kingdom",
            "city": "Cambridge",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cambridge_95_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 4,
            "international_students": 7925,
            "size": "L",
            "faculty_count": 5800
        },
        {
            "university": "Harvard University",
            "year": 2022,
            "rank_display": 5,
            "score": 98,
            "link": "https://www.topuniversities.com/universities/harvard-university",
            "country": "United States",
            "city": "Cambridge",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/harvard-university_253_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5877,
            "size": "L",
            "faculty_count": 4646
        },
        {
            "university": "California Institute of Technology (Caltech)",
            "year": 2022,
            "rank_display": 6,
            "score": 97.4,
            "link": "https://www.topuniversities.com/universities/california-institute-technology-caltech",
            "country": "United States",
            "city": "Pasadena",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/california-institute-of-technology-caltech_94_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 692,
            "size": "S",
            "faculty_count": 968
        },
        {
            "university": "Imperial College London",
            "year": 2022,
            "rank_display": 7,
            "score": 97.3,
            "link": "https://www.topuniversities.com/universities/imperial-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/imperial-college-london_592560cf2aeae70239af4be8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11143,
            "size": "L",
            "faculty_count": 8000
        },
        {
            "university": "ETH Zurich - Swiss Federal Institute of Technology",
            "year": 2022,
            "rank_display": 8,
            "score": 95.4,
            "link": "https://www.topuniversities.com/universities/eth-zurich-swiss-federal-institute-technology",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eth-zurich-swiss-federal-institute-of-technology_201_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7733,
            "size": "L",
            "faculty_count": 2719
        },
        {
            "university": "UCL",
            "year": 2022,
            "rank_display": 8,
            "score": 95.4,
            "link": "https://www.topuniversities.com/universities/ucl",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ucl_592560cf2aeae70239af4bf1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 21824,
            "size": "XL",
            "faculty_count": 7195
        },
        {
            "university": "University of Chicago",
            "year": 2022,
            "rank_display": 10,
            "score": 94.5,
            "link": "https://www.topuniversities.com/universities/university-chicago",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chicago_120_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4696,
            "size": "L",
            "faculty_count": 2703
        },
        {
            "university": "National University of Singapore (NUS)",
            "year": 2022,
            "rank_display": 11,
            "score": 93.9,
            "link": "https://www.topuniversities.com/universities/national-university-singapore-nus",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-singapore-nus_443_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7551,
            "size": "XL",
            "faculty_count": 4288
        },
        {
            "university": "Nanyang Technological University, Singapore (NTU)",
            "year": 2022,
            "rank_display": 12,
            "score": 90.8,
            "link": "https://www.topuniversities.com/universities/nanyang-technological-university-singapore-ntu",
            "country": "Singapore",
            "city": "Singapore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanyang-technological-university-singapore-ntu_592560cf2aeae70239af4c32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6091,
            "size": "L",
            "faculty_count": 3812
        },
        {
            "university": "University of Pennsylvania",
            "year": 2022,
            "rank_display": 13,
            "score": 90.7,
            "link": "https://www.topuniversities.com/universities/university-pennsylvania",
            "country": "United States",
            "city": "Philadelphia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pennsylvania_495_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4636,
            "size": "L",
            "faculty_count": 5154
        },
        {
            "university": "EPFL",
            "year": 2022,
            "rank_display": 14,
            "score": 90.2,
            "link": "https://www.topuniversities.com/universities/epfl",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/epfl-ecole-polytechnique-federale-de-lausanne_592560cf2aeae70239af4b34_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 6426,
            "size": "M",
            "faculty_count": 1767
        },
        {
            "university": "Yale University",
            "year": 2022,
            "rank_display": 14,
            "score": 90.2,
            "link": "https://www.topuniversities.com/universities/yale-university",
            "country": "United States",
            "city": "New Haven",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/yale-university_684_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 2,
            "international_students": 2537,
            "size": "M",
            "faculty_count": 5391
        },
        {
            "university": "The University of Edinburgh",
            "year": 2022,
            "rank_display": 16,
            "score": 89.9,
            "link": "https://www.topuniversities.com/universities/university-edinburgh",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-edinburgh_180_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 14637,
            "size": "XL",
            "faculty_count": 4832
        },
        {
            "university": "Tsinghua University",
            "year": 2022,
            "rank_display": 17,
            "score": 89,
            "link": "https://www.topuniversities.com/universities/tsinghua-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tsinghua-university_626_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5420,
            "size": "XL",
            "faculty_count": 6174
        },
        {
            "university": "Peking University",
            "year": 2022,
            "rank_display": 18,
            "score": 88.8,
            "link": "https://www.topuniversities.com/universities/peking-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/peking-university_50_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5436,
            "size": "XL",
            "faculty_count": 5302
        },
        {
            "university": "Columbia University",
            "year": 2022,
            "rank_display": 19,
            "score": 88.7,
            "link": "https://www.topuniversities.com/universities/columbia-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/211112094012am25532422IKNS031-Banner-Ads-Topuniversities.com-200x200-v02-48x48.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 11252,
            "size": "L",
            "faculty_count": 7087
        },
        {
            "university": "Princeton University",
            "year": 2022,
            "rank_display": 20,
            "score": 88.6,
            "link": "https://www.topuniversities.com/universities/princeton-university",
            "country": "United States",
            "city": "Princeton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/princeton-university_508_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1912,
            "size": "M",
            "faculty_count": 1050
        },
        {
            "university": "Cornell University",
            "year": 2022,
            "rank_display": 21,
            "score": 88.3,
            "link": "https://www.topuniversities.com/universities/cornell-university",
            "country": "United States",
            "city": "Ithaca",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/cornell-university_143_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "The University of Hong Kong",
            "year": 2022,
            "rank_display": 22,
            "score": 86.3,
            "link": "https://www.topuniversities.com/universities/university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-hong-kong_268_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 8311,
            "size": "L",
            "faculty_count": 2944
        },
        {
            "university": "The University of Tokyo",
            "year": 2022,
            "rank_display": 23,
            "score": 86.2,
            "link": "https://www.topuniversities.com/universities/university-tokyo",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-tokyo_615_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3983,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "University of Michigan-Ann Arbor",
            "year": 2022,
            "rank_display": 23,
            "score": 86.2,
            "link": "https://www.topuniversities.com/universities/university-michigan-ann-arbor",
            "country": "United States",
            "city": "Ann Arbor",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-michigan_403_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 7341,
            "size": "XL",
            "faculty_count": 7132
        },
        {
            "university": "Johns Hopkins University",
            "year": 2022,
            "rank_display": 25,
            "score": 85.9,
            "link": "https://www.topuniversities.com/universities/johns-hopkins-university",
            "country": "United States",
            "city": "Baltimore",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/johns-hopkins-university_305_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 5070,
            "size": "L",
            "faculty_count": 4855
        },
        {
            "university": "University of Toronto",
            "year": 2022,
            "rank_display": 26,
            "score": 85.3,
            "link": "https://www.topuniversities.com/universities/university-toronto",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-toronto_619_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 8,
            "international_students": 27536,
            "size": "XL",
            "faculty_count": 9881
        },
        {
            "university": "McGill University",
            "year": 2022,
            "rank_display": 27,
            "score": 84,
            "link": "https://www.topuniversities.com/universities/mcgill-university",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcgill-university_592560cf2aeae70239af4c10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 10827,
            "size": "L",
            "faculty_count": 3876
        },
        {
            "university": "The Australian National University",
            "year": 2022,
            "rank_display": 27,
            "score": 84,
            "link": "https://www.topuniversities.com/universities/australian-national-university",
            "country": "Australia",
            "city": "Canberra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/ANU-crest-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6496,
            "size": "L",
            "faculty_count": 1763
        },
        {
            "university": "The University of Manchester",
            "year": 2022,
            "rank_display": 27,
            "score": 84,
            "link": "https://www.topuniversities.com/universities/university-manchester",
            "country": "United Kingdom",
            "city": "Manchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-manchester_389_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 16079,
            "size": "XL",
            "faculty_count": 4889
        },
        {
            "university": "Northwestern University",
            "year": 2022,
            "rank_display": 30,
            "score": 82.8,
            "link": "https://www.topuniversities.com/universities/northwestern-university",
            "country": "United States",
            "city": "Evanston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northwestern-university_592560cf2aeae70239af4c50_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3846,
            "size": "L",
            "faculty_count": 3652
        },
        {
            "university": "Fudan University",
            "year": 2022,
            "rank_display": 31,
            "score": 82.6,
            "link": "https://www.topuniversities.com/universities/fudan-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/fudan-university_218_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5322,
            "size": "L",
            "faculty_count": 4526
        },
        {
            "university": "University of California, Berkeley (UCB)",
            "year": 2022,
            "rank_display": 32,
            "score": 82.5,
            "link": "https://www.topuniversities.com/universities/university-california-berkeley-ucb",
            "country": "United States",
            "city": "Berkeley",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-berkeley-ucb_84_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 9428,
            "size": "XL",
            "faculty_count": 3701
        },
        {
            "university": "Kyoto University",
            "year": 2022,
            "rank_display": 33,
            "score": 82.3,
            "link": "https://www.topuniversities.com/universities/kyoto-university",
            "country": "Japan",
            "city": "Kyoto",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyoto-university_328_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2863,
            "size": "L",
            "faculty_count": 3911
        },
        {
            "university": "The Hong Kong University of Science and Technology",
            "year": 2022,
            "rank_display": 34,
            "score": 82.2,
            "link": "https://www.topuniversities.com/universities/hong-kong-university-science-technology",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-university-of-science-and-technology_269_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3033,
            "size": "M",
            "faculty_count": 697
        },
        {
            "university": "King's College London",
            "year": 2022,
            "rank_display": 35,
            "score": 82,
            "link": "https://www.topuniversities.com/universities/kings-college-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kings-college-london_357_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 15075,
            "size": "L",
            "faculty_count": 4216
        },
        {
            "university": "Seoul National University",
            "year": 2022,
            "rank_display": 36,
            "score": 81.7,
            "link": "https://www.topuniversities.com/universities/seoul-national-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/graduate-school-of-business-seoul-national-university_553_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1914,
            "size": "L",
            "faculty_count": 3859
        },
        {
            "university": "The University of Melbourne",
            "year": 2022,
            "rank_display": 37,
            "score": 81.4,
            "link": "https://www.topuniversities.com/universities/university-melbourne",
            "country": "Australia",
            "city": "Parkville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-melbourne_399_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 19,
            "international_students": 21406,
            "size": "S",
            "faculty_count": 4835
        },
        {
            "university": "The University of Sydney",
            "year": 2022,
            "rank_display": 38,
            "score": 80.4,
            "link": "https://www.topuniversities.com/universities/university-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sydney_592560cf2aeae70239af4cd0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 25940,
            "size": "XL",
            "faculty_count": 3571
        },
        {
            "university": "The Chinese University of Hong Kong (CUHK)",
            "year": 2022,
            "rank_display": 39,
            "score": 80.3,
            "link": "https://www.topuniversities.com/universities/chinese-university-hong-kong-cuhk",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-chinese-university-of-hong-kong-cuhk_123_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6062,
            "size": "L",
            "faculty_count": 2201
        },
        {
            "university": "University of California, Los Angeles (UCLA)",
            "year": 2022,
            "rank_display": 40,
            "score": 79.7,
            "link": "https://www.topuniversities.com/universities/university-california-los-angeles-ucla",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-los-angeles-ucla_87_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6140,
            "size": "XL",
            "faculty_count": 4487
        },
        {
            "university": "KAIST - Korea Advanced Institute of Science & Technology",
            "year": 2022,
            "rank_display": 41,
            "score": 79.1,
            "link": "https://www.topuniversities.com/universities/kaist-korea-advanced-institute-science-technology",
            "country": "South Korea",
            "city": "Daejeon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kaist-korea-advanced-institute-of-science-technology_324_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 869,
            "size": "M",
            "faculty_count": 1307
        },
        {
            "university": "New York University (NYU)",
            "year": 2022,
            "rank_display": 42,
            "score": 78.9,
            "link": "https://www.topuniversities.com/universities/new-york-university-nyu",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/new-york-university-nyu_448_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 16218,
            "size": "XL",
            "faculty_count": 7874
        },
        {
            "university": "The University of New South Wales (UNSW Sydney)",
            "year": 2022,
            "rank_display": 43,
            "score": 77.7,
            "link": "https://www.topuniversities.com/universities/university-new-south-wales-unsw-sydney",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-new-south-wales-unsw-sydney_447_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 21603,
            "size": "XL",
            "faculty_count": 3219
        },
        {
            "university": "Université PSL",
            "year": 2022,
            "rank_display": 44,
            "score": 77.6,
            "link": "https://www.topuniversities.com/universities/universite-psl",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-psl_592560e69988f300e2321dfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4922,
            "size": "L",
            "faculty_count": 3914
        },
        {
            "university": "Zhejiang University",
            "year": 2022,
            "rank_display": 45,
            "score": 77.4,
            "link": "https://www.topuniversities.com/universities/zhejiang-university",
            "country": "China (Mainland)",
            "city": "Hangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/zhejiang-university_592560cf2aeae70239af4d37_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 9177,
            "size": "XL",
            "faculty_count": 6065
        },
        {
            "university": "University of British Columbia",
            "year": 2022,
            "rank_display": 46,
            "score": 77.1,
            "link": "https://www.topuniversities.com/universities/university-british-columbia",
            "country": "Canada",
            "city": "Vancouver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-british-columbia_70_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 15399,
            "size": "XL",
            "faculty_count": 6201
        },
        {
            "university": "The University of Queensland",
            "year": 2022,
            "rank_display": 47,
            "score": 76.6,
            "link": "https://www.topuniversities.com/universities/university-queensland",
            "country": "Australia",
            "city": "Brisbane City",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/210824044126am970441uq-logo-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 22241,
            "size": "XL",
            "faculty_count": 3513
        },
        {
            "university": "University of California, San Diego (UCSD)",
            "year": 2022,
            "rank_display": 48,
            "score": 76.1,
            "link": "https://www.topuniversities.com/universities/university-california-san-diego-ucsd",
            "country": "United States",
            "city": "San Diego",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-san-diego-ucsd_89_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 8567,
            "size": "XL",
            "faculty_count": 4545
        },
        {
            "university": "Institut Polytechnique de Paris",
            "year": 2022,
            "rank_display": 49,
            "score": 75.9,
            "link": "https://www.topuniversities.com/universities/institut-polytechnique-de-paris",
            "country": "France",
            "city": "Palaiseau Cedex",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/institut-polytechnique-de-paris_5f3a6d4210ca6076bc71cae4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2413,
            "size": "M",
            "faculty_count": 1057
        },
        {
            "university": "The London School of Economics and Political Science (LSE)",
            "year": 2022,
            "rank_display": 49,
            "score": 75.8,
            "link": "https://www.topuniversities.com/universities/london-school-economics-political-science-lse",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/london-school-of-economics-and-political-science-lse_362_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8274,
            "size": "M",
            "faculty_count": 1172
        },
        {
            "university": "Shanghai Jiao Tong University",
            "year": 2022,
            "rank_display": 50,
            "score": 75.6,
            "link": "https://www.topuniversities.com/universities/shanghai-jiao-tong-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-jiao-tong-university_556_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6371,
            "size": "XL",
            "faculty_count": 4386
        },
        {
            "university": "Technical University of Munich",
            "year": 2022,
            "rank_display": 50,
            "score": 75.6,
            "link": "https://www.topuniversities.com/universities/technical-university-munich",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-munich_599_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 11447,
            "size": "XL",
            "faculty_count": 6663
        },
        {
            "university": "Duke University",
            "year": 2022,
            "rank_display": 52,
            "score": 75.2,
            "link": "https://www.topuniversities.com/universities/duke-university",
            "country": "United States",
            "city": "Durham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/duke-university_168_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 3445,
            "size": "L",
            "faculty_count": 5673
        },
        {
            "university": "Carnegie Mellon University",
            "year": 2022,
            "rank_display": 53,
            "score": 74.6,
            "link": "https://www.topuniversities.com/universities/carnegie-mellon-university",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/carnegie-mellon-university_101_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5739,
            "size": "L",
            "faculty_count": 1534
        },
        {
            "university": "City University of Hong Kong",
            "year": 2022,
            "rank_display": 53,
            "score": 74.6,
            "link": "https://www.topuniversities.com/universities/city-university-hong-kong",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-hong-kong_592560cf2aeae70239af4b05_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5331,
            "size": "M",
            "faculty_count": 1483
        },
        {
            "university": "University of Amsterdam",
            "year": 2022,
            "rank_display": 55,
            "score": 73.8,
            "link": "https://www.topuniversities.com/universities/university-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-amsterdam_18_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7969,
            "size": "XL",
            "faculty_count": 6000
        },
        {
            "university": "Tokyo Institute of Technology (Tokyo Tech)",
            "year": 2022,
            "rank_display": 56,
            "score": 73.5,
            "link": "https://www.topuniversities.com/universities/tokyo-institute-technology-tokyo-tech",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-institute-of-technology_592560cf2aeae70239af4ceb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1622,
            "size": "M",
            "faculty_count": 1492
        },
        {
            "university": "Delft University of Technology",
            "year": 2022,
            "rank_display": 57,
            "score": 73.1,
            "link": "https://www.topuniversities.com/universities/delft-university-technology",
            "country": "Netherlands",
            "city": "Delft",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/delft-university-of-technology_155_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6104,
            "size": "L",
            "faculty_count": 2041
        },
        {
            "university": "Monash University",
            "year": 2022,
            "rank_display": 58,
            "score": 72.2,
            "link": "https://www.topuniversities.com/universities/monash-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/monash-university_412_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 31049,
            "size": "XL",
            "faculty_count": 3852
        },
        {
            "university": "Brown University",
            "year": 2022,
            "rank_display": 60,
            "score": 71.3,
            "link": "https://www.topuniversities.com/universities/brown-university",
            "country": "United States",
            "city": "Providence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brown-university_72_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2002,
            "size": "M",
            "faculty_count": 1389
        },
        {
            "university": "The University of Warwick",
            "year": 2022,
            "rank_display": 61,
            "score": 71.2,
            "link": "https://www.topuniversities.com/universities/university-warwick",
            "country": "United Kingdom",
            "city": "Coventry",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-warwick_664_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10459,
            "size": "L",
            "faculty_count": 2634
        },
        {
            "university": "University of Bristol",
            "year": 2022,
            "rank_display": 62,
            "score": 71,
            "link": "https://www.topuniversities.com/universities/university-bristol",
            "country": "United Kingdom",
            "city": "Bristol",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bristol_69_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 7483,
            "size": "L",
            "faculty_count": 20311
        },
        {
            "university": "Ruprecht-Karls-Universität Heidelberg",
            "year": 2022,
            "rank_display": 63,
            "score": 70.8,
            "link": "https://www.topuniversities.com/universities/ruprecht-karls-universitat-heidelberg",
            "country": "Germany",
            "city": "Heidelberg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruprecht-karls-universitaet-heidelberg_259_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4048,
            "size": "L",
            "faculty_count": 4100
        },
        {
            "university": "Ludwig-Maximilians-Universität München",
            "year": 2022,
            "rank_display": 64,
            "score": 70.1,
            "link": "https://www.topuniversities.com/universities/ludwig-maximilians-universitat-munchen",
            "country": "Germany",
            "city": "Munich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ludwig-maximilians-universitt-mnchen_420_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5958,
            "size": "XL",
            "faculty_count": 3914
        },
        {
            "university": "Universiti Malaya (UM)",
            "year": 2022,
            "rank_display": 65,
            "score": 69.8,
            "link": "https://www.topuniversities.com/universities/universiti-malaya-um",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-malaya-um_383_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3340,
            "size": "L",
            "faculty_count": 2386
        },
        {
            "university": "The Hong Kong Polytechnic University",
            "year": 2022,
            "rank_display": 66,
            "score": 69.1,
            "link": "https://www.topuniversities.com/universities/hong-kong-polytechnic-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hong-kong-polytechnic-university_267_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5774,
            "size": "L",
            "faculty_count": 2822
        },
        {
            "university": "University of Texas at Austin",
            "year": 2022,
            "rank_display": 67,
            "score": 68.4,
            "link": "https://www.topuniversities.com/universities/university-texas-austin",
            "country": "United States",
            "city": "Austin",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-at-austin_604_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 4953,
            "size": "XL",
            "faculty_count": 2887
        },
        {
            "university": "National Taiwan University (NTU)",
            "year": 2022,
            "rank_display": 68,
            "score": 68.2,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-ntu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-ntu_592560cf2aeae70239af4c3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5058,
            "size": "L",
            "faculty_count": 2939
        },
        {
            "university": "Universidad de Buenos Aires (UBA)",
            "year": 2022,
            "rank_display": 69,
            "score": 67.9,
            "link": "https://www.topuniversities.com/universities/universidad-de-buenos-aires-uba",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-buenos-aires-uba_78_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 29516,
            "size": "XL",
            "faculty_count": 16103
        },
        {
            "university": "KU Leuven",
            "year": 2022,
            "rank_display": 70,
            "score": 67.2,
            "link": "https://www.topuniversities.com/universities/ku-leuven",
            "country": "Belgium",
            "city": "Leuven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ku-leuven_592560cf2aeae70239af4aec_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 8485,
            "size": "XL",
            "faculty_count": 2677
        },
        {
            "university": "University of Zurich",
            "year": 2022,
            "rank_display": 70,
            "score": 67.2,
            "link": "https://www.topuniversities.com/universities/university-zurich",
            "country": "Switzerland",
            "city": "Zürich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-zurich_693_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5044,
            "size": "L",
            "faculty_count": 3761
        },
        {
            "university": "Sorbonne University",
            "year": 2022,
            "rank_display": 72,
            "score": 67.1,
            "link": "https://www.topuniversities.com/universities/sorbonne-university",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sorbonne-university_5a6afcfecb4de709397114fe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8782,
            "size": "XL",
            "faculty_count": 4680
        },
        {
            "university": "University of Glasgow",
            "year": 2022,
            "rank_display": 73,
            "score": 66.8,
            "link": "https://www.topuniversities.com/universities/university-glasgow",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-glasgow_592560cf2aeae70239af4b6c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 11065,
            "size": "L",
            "faculty_count": 3314
        },
        {
            "university": "Korea University",
            "year": 2022,
            "rank_display": 74,
            "score": 66.3,
            "link": "https://www.topuniversities.com/universities/korea-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/korea-university_325_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3325,
            "size": "L",
            "faculty_count": 4026
        },
        {
            "university": "Osaka University",
            "year": 2022,
            "rank_display": 75,
            "score": 66.2,
            "link": "https://www.topuniversities.com/universities/osaka-university",
            "country": "Japan",
            "city": "Osaka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/osaka-university_472_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2268,
            "size": "L",
            "faculty_count": 3127
        },
        {
            "university": "University of Wisconsin-Madison",
            "year": 2022,
            "rank_display": 75,
            "score": 66.2,
            "link": "https://www.topuniversities.com/universities/university-wisconsin-madison",
            "country": "United States",
            "city": "Madison",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wisconsin-madison_678_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5737,
            "size": "XL",
            "faculty_count": 5166
        },
        {
            "university": "University of Southampton",
            "year": 2022,
            "rank_display": 77,
            "score": 65.7,
            "link": "https://www.topuniversities.com/universities/university-southampton",
            "country": "United Kingdom",
            "city": "Southampton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southampton_567_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8417,
            "size": "L",
            "faculty_count": 2468
        },
        {
            "university": "Lomonosov Moscow State University",
            "year": 2022,
            "rank_display": 78,
            "score": 65.6,
            "link": "https://www.topuniversities.com/universities/lomonosov-moscow-state-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lomonosov-moscow-state-university-_592560cf2aeae70239af4c27_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 5476,
            "size": "XL",
            "faculty_count": 6575
        },
        {
            "university": "University of Copenhagen",
            "year": 2022,
            "rank_display": 79,
            "score": 65.5,
            "link": "https://www.topuniversities.com/universities/university-copenhagen",
            "country": "Denmark",
            "city": "Copenhagen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-copenhagen_141_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 4135,
            "size": "XL",
            "faculty_count": 7293
        },
        {
            "university": "Yonsei University",
            "year": 2022,
            "rank_display": 79,
            "score": 65.5,
            "link": "https://www.topuniversities.com/universities/yonsei-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yonsei-university_688_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4274,
            "size": "L",
            "faculty_count": 3605
        },
        {
            "university": "Pohang University of Science And Technology (POSTECH)",
            "year": 2022,
            "rank_display": 81,
            "score": 65.4,
            "link": "https://www.topuniversities.com/universities/pohang-university-science-technology-postech",
            "country": "South Korea",
            "city": "Pohang",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pohang-university-of-science-and-technology-postech_592560cf2aeae70239af4c7b_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 87,
            "size": "S",
            "faculty_count": 743
        },
        {
            "university": "Durham University",
            "year": 2022,
            "rank_display": 82,
            "score": 65.2,
            "link": "https://www.topuniversities.com/universities/durham-university",
            "country": "United Kingdom",
            "city": "Durham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/durham-university_170_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6174,
            "size": "L",
            "faculty_count": 1775
        },
        {
            "university": "Tohoku University",
            "year": 2022,
            "rank_display": 82,
            "score": 65.2,
            "link": "https://www.topuniversities.com/universities/tohoku-university",
            "country": "Japan",
            "city": "Sendai City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tohoku-university_611_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1872,
            "size": "L",
            "faculty_count": 3323
        },
        {
            "university": "University of Illinois at Urbana-Champaign",
            "year": 2022,
            "rank_display": 82,
            "score": 65.2,
            "link": "https://www.topuniversities.com/universities/university-illinois-urbana-champaign",
            "country": "United States",
            "city": "Champaign",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-at-urbana-champaign_2090_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 9987,
            "size": "XL",
            "faculty_count": 2748
        },
        {
            "university": "The University of Auckland",
            "year": 2022,
            "rank_display": 85,
            "score": 65,
            "link": "https://www.topuniversities.com/universities/university-auckland",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-auckland_592560cf2aeae70239af4aa8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9924,
            "size": "L",
            "faculty_count": 2316
        },
        {
            "university": "University of Washington",
            "year": 2022,
            "rank_display": 85,
            "score": 65,
            "link": "https://www.topuniversities.com/universities/university-washington",
            "country": "United States",
            "city": "Seattle",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-washington_592560cf2aeae70239af4d1e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 7362,
            "size": "XL",
            "faculty_count": 2796
        },
        {
            "university": "Université Paris-Saclay",
            "year": 2022,
            "rank_display": 86,
            "score": 64.9,
            "link": "https://www.topuniversities.com/universities/universite-paris-saclay",
            "country": "France",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-saclay_592560cf2aeae70239af4c6e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 7649,
            "size": "XL",
            "faculty_count": 8010
        },
        {
            "university": "Lund University",
            "year": 2022,
            "rank_display": 87,
            "score": 63.8,
            "link": "https://www.topuniversities.com/universities/lund-university",
            "country": "Sweden",
            "city": "Lund",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lund-university_371_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6682,
            "size": "L",
            "faculty_count": 3040
        },
        {
            "university": "Georgia Institute of Technology",
            "year": 2022,
            "rank_display": 88,
            "score": 63.5,
            "link": "https://www.topuniversities.com/universities/georgia-institute-technology",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgia-institute-of-technology_225_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5675,
            "size": "L",
            "faculty_count": 1267
        },
        {
            "university": "KTH Royal Institute of Technology",
            "year": 2022,
            "rank_display": 89,
            "score": 63.2,
            "link": "https://www.topuniversities.com/universities/kth-royal-institute-technology",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kth-royal-institute-of-technology-_592560cf2aeae70239af4c9b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3057,
            "size": "L",
            "faculty_count": 3600
        },
        {
            "university": "University of Birmingham",
            "year": 2022,
            "rank_display": 90,
            "score": 62.9,
            "link": "https://www.topuniversities.com/universities/university-birmingham",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-birmingham_59_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 10457,
            "size": "XL",
            "faculty_count": 3586
        },
        {
            "university": "University of St Andrews",
            "year": 2022,
            "rank_display": 91,
            "score": 62.8,
            "link": "https://www.topuniversities.com/universities/university-st-andrews",
            "country": "United Kingdom",
            "city": "St. Andrews",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st-andrews_570_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4377,
            "size": "M",
            "faculty_count": 1208
        },
        {
            "university": "University of Leeds",
            "year": 2022,
            "rank_display": 92,
            "score": 62.6,
            "link": "https://www.topuniversities.com/universities/university-leeds",
            "country": "United Kingdom",
            "city": "Leeds",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/University-of-Leeds-2-1-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 11584,
            "size": "XL",
            "faculty_count": 3593
        },
        {
            "university": "The University of Western Australia",
            "year": 2022,
            "rank_display": 93,
            "score": 62.5,
            "link": "https://www.topuniversities.com/universities/university-western-australia",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-australia_673_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 9017,
            "size": "L",
            "faculty_count": 1539
        },
        {
            "university": "Rice University",
            "year": 2022,
            "rank_display": 94,
            "score": 62.3,
            "link": "https://www.topuniversities.com/universities/rice-university",
            "country": "United States",
            "city": "Houston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rice-university_524_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2278,
            "size": "M",
            "faculty_count": 996
        },
        {
            "university": "The University of Sheffield",
            "year": 2022,
            "rank_display": 95,
            "score": 61.6,
            "link": "https://www.topuniversities.com/universities/university-sheffield",
            "country": "United Kingdom",
            "city": "Sheffield",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-sheffield_592560cf2aeae70239af4cb1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 11090,
            "size": "L",
            "faculty_count": 3463
        },
        {
            "university": "Pennsylvania State University",
            "year": 2022,
            "rank_display": 96,
            "score": 61.5,
            "link": "https://www.topuniversities.com/universities/pennsylvania-state-university",
            "country": "United States",
            "city": "University Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/pennsylvania-state-university_494_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6943,
            "size": "XL",
            "faculty_count": 4207
        },
        {
            "university": "Sungkyunkwan University (SKKU)",
            "year": 2022,
            "rank_display": 97,
            "score": 60.5,
            "link": "https://www.topuniversities.com/universities/sungkyunkwan-universityskku",
            "country": "South Korea",
            "city": "Suwon",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sungkyunkwan-universityskku_592560cf2aeae70239af4cc9_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3821,
            "size": "L",
            "faculty_count": 3313
        },
        {
            "university": "University of Science and Technology of China",
            "year": 2022,
            "rank_display": 98,
            "score": 60.1,
            "link": "https://www.topuniversities.com/universities/university-science-technology-china",
            "country": "China (Mainland)",
            "city": "Hefei",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-of-china_122_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1019,
            "size": "L",
            "faculty_count": 2708
        },
        {
            "university": "Technical University of Denmark",
            "year": 2022,
            "rank_display": 99,
            "score": 59.9,
            "link": "https://www.topuniversities.com/universities/technical-university-denmark",
            "country": "Denmark",
            "city": "Kongens Lyngby",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technical-university-of-denmark_592560cf2aeae70239af4cd8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2495,
            "size": "M",
            "faculty_count": 2219
        },
        {
            "university": "University of North Carolina, Chapel Hill",
            "year": 2022,
            "rank_display": 100,
            "score": 59.6,
            "link": "https://www.topuniversities.com/universities/university-north-carolina-chapel-hill",
            "country": "United States",
            "city": "Chapel Hill",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-north-carolina-chapel-hill_424_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2749,
            "size": "XL",
            "faculty_count": 4646
        },
        {
            "university": "Trinity College Dublin, The University of Dublin",
            "year": 2022,
            "rank_display": 101,
            "score": 59.5,
            "link": "https://www.topuniversities.com/universities/trinity-college-dublin-university-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/trinity-college-dublin-the-university-of-dublin_167_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5593,
            "size": "L",
            "faculty_count": 1383
        },
        {
            "university": "University of Oslo",
            "year": 2022,
            "rank_display": 102,
            "score": 59,
            "link": "https://www.topuniversities.com/universities/university-oslo",
            "country": "Norway",
            "city": "Oslo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oslo_473_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2560,
            "size": "L",
            "faculty_count": 2600
        },
        {
            "university": "University of Nottingham",
            "year": 2022,
            "rank_display": 103,
            "score": 58.9,
            "link": "https://www.topuniversities.com/universities/university-nottingham",
            "country": "United Kingdom",
            "city": "Nottingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-nottingham_592560cf2aeae70239af4c4e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9659,
            "size": "XL",
            "faculty_count": 3386
        },
        {
            "university": "University of Helsinki",
            "year": 2022,
            "rank_display": 104,
            "score": 58.5,
            "link": "https://www.topuniversities.com/universities/university-helsinki",
            "country": "Finland",
            "city": "Helsinki",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-helsinki_260_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1254,
            "size": "L",
            "faculty_count": 2855
        },
        {
            "university": "Universidad Nacional Autónoma de México  (UNAM)",
            "year": 2022,
            "rank_display": 105,
            "score": 58.3,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-autonoma-de-mexico-unam",
            "country": "Mexico",
            "city": "Mexico City",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-autnoma-de-mxico-unam_425_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5110,
            "size": "XL",
            "faculty_count": 16381
        },
        {
            "university": "University of Geneva",
            "year": 2022,
            "rank_display": 105,
            "score": 58.3,
            "link": "https://www.topuniversities.com/universities/university-geneva",
            "country": "Switzerland",
            "city": "Geneva",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-geneva_221_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 6547,
            "size": "L",
            "faculty_count": 1814
        },
        {
            "university": "Washington University in St. Louis",
            "year": 2022,
            "rank_display": 107,
            "score": 58,
            "link": "https://www.topuniversities.com/universities/washington-university-st-louis",
            "country": "United States",
            "city": "St. Louis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-university-in-st.-louis_668_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3535,
            "size": "L",
            "faculty_count": 3193
        },
        {
            "university": "The University of Adelaide",
            "year": 2022,
            "rank_display": 108,
            "score": 57.9,
            "link": "https://www.topuniversities.com/universities/university-adelaide",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-adelaide_10_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10107,
            "size": "L",
            "faculty_count": 1757
        },
        {
            "university": "University of California, Davis",
            "year": 2022,
            "rank_display": 108,
            "score": 57.9,
            "link": "https://www.topuniversities.com/universities/university-california-davis",
            "country": "United States",
            "city": "Davis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-davis_592560cf2aeae70239af4ad8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8414,
            "size": "XL",
            "faculty_count": 3420
        },
        {
            "university": "King Abdulaziz University (KAU)",
            "year": 2022,
            "rank_display": 109,
            "score": 57.7,
            "link": "https://www.topuniversities.com/universities/king-abdulaziz-university-kau",
            "country": "Saudi Arabia",
            "city": "Jeddah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-abdulaziz-university-kau_1172_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 6821,
            "size": "XL",
            "faculty_count": 4054
        },
        {
            "university": "Utrecht University",
            "year": 2022,
            "rank_display": 110,
            "score": 57.5,
            "link": "https://www.topuniversities.com/universities/utrecht-university",
            "country": "Netherlands",
            "city": "Utrecht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/utrecht-university_646_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4454,
            "size": "XL",
            "faculty_count": 3254
        },
        {
            "university": "Université de Montréal",
            "year": 2022,
            "rank_display": 111,
            "score": 57.3,
            "link": "https://www.topuniversities.com/universities/universite-de-montreal",
            "country": "Canada",
            "city": "Montreal",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-montral_417_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 7625,
            "size": "XL",
            "faculty_count": 5533
        },
        {
            "university": "Aalto University",
            "year": 2022,
            "rank_display": 112,
            "score": 57,
            "link": "https://www.topuniversities.com/universities/aalto-university",
            "country": "Finland",
            "city": "Espoo",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalto-university_261_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2202,
            "size": "L",
            "faculty_count": 1396
        },
        {
            "university": "Boston University",
            "year": 2022,
            "rank_display": 112,
            "score": 57,
            "link": "https://www.topuniversities.com/universities/boston-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-university_592560cf2aeae70239af4ac2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8159,
            "size": "L",
            "faculty_count": 2642
        },
        {
            "university": "Leiden University",
            "year": 2022,
            "rank_display": 112,
            "score": 57,
            "link": "https://www.topuniversities.com/universities/leiden-university",
            "country": "Netherlands",
            "city": "Leiden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/leiden-university_340_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6064,
            "size": "XL",
            "faculty_count": 2069
        },
        {
            "university": "University of Southern California",
            "year": 2022,
            "rank_display": 112,
            "score": 57,
            "link": "https://www.topuniversities.com/universities/university-southern-california",
            "country": "United States",
            "city": "Los Angeles",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-california_569_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9107,
            "size": "XL",
            "faculty_count": 3116
        },
        {
            "university": "Purdue University",
            "year": 2022,
            "rank_display": 116,
            "score": 56.9,
            "link": "https://www.topuniversities.com/universities/purdue-university",
            "country": "United States",
            "city": "West Lafayette",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/purdue-university_510_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 8034,
            "size": "XL",
            "faculty_count": 2622
        },
        {
            "university": "Queen Mary University of London",
            "year": 2022,
            "rank_display": 117,
            "score": 56.7,
            "link": "https://www.topuniversities.com/universities/queen-mary-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queen-mary-university-of-london_592560cf2aeae70239af4bea_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 8625,
            "size": "L",
            "faculty_count": 2119
        },
        {
            "university": "Nagoya University",
            "year": 2022,
            "rank_display": 118,
            "score": 56.2,
            "link": "https://www.topuniversities.com/universities/nagoya-university",
            "country": "Japan",
            "city": "Nagoya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nagoya-university_427_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1879,
            "size": "L",
            "faculty_count": 2512
        },
        {
            "university": "University of Bern",
            "year": 2022,
            "rank_display": 119,
            "score": 56.1,
            "link": "https://www.topuniversities.com/universities/university-bern",
            "country": "Switzerland",
            "city": "Bern",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bern_55_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1776,
            "size": "M",
            "faculty_count": 1212
        },
        {
            "university": "The Ohio State University",
            "year": 2022,
            "rank_display": 120,
            "score": 56,
            "link": "https://www.topuniversities.com/universities/ohio-state-university",
            "country": "United States",
            "city": "Columbus",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-ohio-state-university_465_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7029,
            "size": "XL",
            "faculty_count": 6388
        },
        {
            "university": "Chalmers University of Technology",
            "year": 2022,
            "rank_display": 121,
            "score": 55.9,
            "link": "https://www.topuniversities.com/universities/chalmers-university-technology",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/chalmers-university-of-technology_592560cf2aeae70239af4af7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2502,
            "size": "M",
            "faculty_count": 2500
        },
        {
            "university": "Universidade de São Paulo",
            "year": 2022,
            "rank_display": 121,
            "score": 55.9,
            "link": "https://www.topuniversities.com/universities/universidade-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-de-so-paulo_550_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1858,
            "size": "XL",
            "faculty_count": 4943
        },
        {
            "university": "Wageningen University & Research",
            "year": 2022,
            "rank_display": 123,
            "score": 55.8,
            "link": "https://www.topuniversities.com/universities/wageningen-university-research",
            "country": "Netherlands",
            "city": "Wageningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/wageningen-university_659_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4131,
            "size": "L",
            "faculty_count": 2767
        },
        {
            "university": "Uppsala University",
            "year": 2022,
            "rank_display": 124,
            "score": 55.4,
            "link": "https://www.topuniversities.com/universities/uppsala-university",
            "country": "Sweden",
            "city": "Uppsala",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/uppsala-university_643_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8401,
            "size": "L",
            "faculty_count": 2843
        },
        {
            "university": "Eindhoven University of Technology",
            "year": 2022,
            "rank_display": 125,
            "score": 55.3,
            "link": "https://www.topuniversities.com/universities/eindhoven-university-technology",
            "country": "Netherlands",
            "city": "Eindhoven",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eindhoven-university-of-technology_592560cf2aeae70239af4b3c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1872,
            "size": "M",
            "faculty_count": 1145
        },
        {
            "university": "University of Alberta",
            "year": 2022,
            "rank_display": 126,
            "score": 55.2,
            "link": "https://www.topuniversities.com/universities/university-alberta",
            "country": "Canada",
            "city": "Edmonton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-alberta_15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9689,
            "size": "XL",
            "faculty_count": 3645
        },
        {
            "university": "Freie Universitaet Berlin",
            "year": 2022,
            "rank_display": 127,
            "score": 54.9,
            "link": "https://www.topuniversities.com/universities/freie-universitaet-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/freie-universitaet-berlin_215_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 6659,
            "size": "XL",
            "faculty_count": 1389
        },
        {
            "university": "Humboldt-Universität zu Berlin",
            "year": 2022,
            "rank_display": 128,
            "score": 54.8,
            "link": "https://www.topuniversities.com/universities/humboldt-universitat-zu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/humboldt-universitt-zu-berlin_272_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5239,
            "size": "XL",
            "faculty_count": 1967
        },
        {
            "university": "University of Groningen",
            "year": 2022,
            "rank_display": 128,
            "score": 54.8,
            "link": "https://www.topuniversities.com/universities/university-groningen",
            "country": "Netherlands",
            "city": "Groningen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-groningen_243_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 9397,
            "size": "XL",
            "faculty_count": 3724
        },
        {
            "university": "École Normale Supérieure de Lyon",
            "year": 2022,
            "rank_display": 130,
            "score": 54.6,
            "link": "https://www.topuniversities.com/universities/ecole-normale-superieure-de-lyon",
            "country": "France",
            "city": "Lyon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cole-normale-suprieure-de-lyon_175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 233,
            "size": "S",
            "faculty_count": 387
        },
        {
            "university": "Nanjing University",
            "year": 2022,
            "rank_display": 131,
            "score": 54.2,
            "link": "https://www.topuniversities.com/universities/nanjing-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nanjing-university_430_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2513,
            "size": "XL",
            "faculty_count": 3040
        },
        {
            "university": "Lancaster University",
            "year": 2022,
            "rank_display": 132,
            "score": 54,
            "link": "https://www.topuniversities.com/universities/lancaster-university",
            "country": "United Kingdom",
            "city": "Lancaster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lancaster-university_335_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5557,
            "size": "L",
            "faculty_count": 1440
        },
        {
            "university": "University of Technology Sydney",
            "year": 2022,
            "rank_display": 133,
            "score": 53.9,
            "link": "https://www.topuniversities.com/universities/university-technology-sydney",
            "country": "Australia",
            "city": "Haymarket",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-technology-sydney_592560cf2aeae70239af4ccf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8653,
            "size": "L",
            "faculty_count": 2183
        },
        {
            "university": "Newcastle University",
            "year": 2022,
            "rank_display": 134,
            "score": 53.8,
            "link": "https://www.topuniversities.com/universities/newcastle-university",
            "country": "United Kingdom",
            "city": "Newcastle upon Tyne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/newcastle-university_592560cf2aeae70239af4c45_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 8100,
            "size": "L",
            "faculty_count": 2766
        },
        {
            "university": "Pontificia Universidad Católica de Chile (UC)",
            "year": 2022,
            "rank_display": 135,
            "score": 53.6,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-cat%C3%B3lica-de-chile-uc",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-de-chile-uc_107_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1072,
            "size": "L",
            "faculty_count": 2343
        },
        {
            "university": "KIT, Karlsruhe Institute of Technology",
            "year": 2022,
            "rank_display": 136,
            "score": 53.4,
            "link": "https://www.topuniversities.com/universities/kit-karlsruhe-institute-technology",
            "country": "Germany",
            "city": "Karlsruhe",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kit-karlsruhe-institute-of-technology_310_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 5030,
            "size": "L",
            "faculty_count": 2080
        },
        {
            "university": "Kyushu University",
            "year": 2022,
            "rank_display": 137,
            "score": 53.3,
            "link": "https://www.topuniversities.com/universities/kyushu-university",
            "country": "Japan",
            "city": "Fukuoka City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyushu-university_331_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2125,
            "size": "L",
            "faculty_count": 2761
        },
        {
            "university": "University of Basel",
            "year": 2022,
            "rank_display": 138,
            "score": 53,
            "link": "https://www.topuniversities.com/universities/university-basel",
            "country": "Switzerland",
            "city": "Basel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-basel_46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2875,
            "size": "M",
            "faculty_count": 1036
        },
        {
            "university": "McMaster University",
            "year": 2022,
            "rank_display": 140,
            "score": 52.7,
            "link": "https://www.topuniversities.com/universities/mcmaster-university",
            "country": "Canada",
            "city": "Hamilton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/mcmaster-university_397_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5386,
            "size": "L",
            "faculty_count": 3819
        },
        {
            "university": "Ghent University",
            "year": 2022,
            "rank_display": 141,
            "score": 52.6,
            "link": "https://www.topuniversities.com/universities/ghent-university",
            "country": "Belgium",
            "city": "Ghent",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ghent-university_228_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4609,
            "size": "XL",
            "faculty_count": 5537
        },
        {
            "university": "Politecnico di Milano",
            "year": 2022,
            "rank_display": 142,
            "score": 52.5,
            "link": "https://www.topuniversities.com/universities/politecnico-di-milano",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politecnico-di-milano_592560cf2aeae70239af4c1c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7452,
            "size": "XL",
            "faculty_count": 1659
        },
        {
            "university": "Universiti Putra Malaysia (UPM)",
            "year": 2022,
            "rank_display": 143,
            "score": 52.2,
            "link": "https://www.topuniversities.com/universities/universiti-putra-malaysia-upm",
            "country": "Malaysia",
            "city": "Seri Kembangan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-putra-malaysia-upm_385_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5040,
            "size": "L",
            "faculty_count": 2174
        },
        {
            "university": "Universiti Kebangsaan Malaysia (UKM)",
            "year": 2022,
            "rank_display": 144,
            "score": 52,
            "link": "https://www.topuniversities.com/universities/universiti-kebangsaan-malaysia-ukm",
            "country": "Malaysia",
            "city": "Bangi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-kebangsaan-malaysia-ukm_384_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2410,
            "size": "L",
            "faculty_count": 2829
        },
        {
            "university": "Hokkaido University",
            "year": 2022,
            "rank_display": 145,
            "score": 51.4,
            "link": "https://www.topuniversities.com/universities/hokkaido-university",
            "country": "Japan",
            "city": "Sapporo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hokkaido-university_266_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1984,
            "size": "L",
            "faculty_count": 2476
        },
        {
            "university": "University of California, Santa Barbara (UCSB)",
            "year": 2022,
            "rank_display": 146,
            "score": 51.2,
            "link": "https://www.topuniversities.com/universities/university-california-santa-barbara-ucsb",
            "country": "United States",
            "city": "Santa Barbara",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-barbara-ucsb_91_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 4309,
            "size": "L",
            "faculty_count": 1597
        },
        {
            "university": "Universiti Sains Malaysia (USM)",
            "year": 2022,
            "rank_display": 147,
            "score": 51,
            "link": "https://www.topuniversities.com/universities/universiti-sains-malaysia-usm",
            "country": "Malaysia",
            "city": "Gelugor",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-sains-malaysia-usm_386_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4198,
            "size": "L",
            "faculty_count": 2249
        },
        {
            "university": "Stockholm University",
            "year": 2022,
            "rank_display": 148,
            "score": 50.6,
            "link": "https://www.topuniversities.com/universities/stockholm-university",
            "country": "Sweden",
            "city": "Stockholm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/stockholm-university_577_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7567,
            "size": "L",
            "faculty_count": 2325
        },
        {
            "university": "The University of Exeter",
            "year": 2022,
            "rank_display": 149,
            "score": 50.5,
            "link": "https://www.topuniversities.com/universities/university-exeter",
            "country": "United Kingdom",
            "city": "Exeter",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-exeter_204_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7188,
            "size": "L",
            "faculty_count": 2393
        },
        {
            "university": "University of Waterloo",
            "year": 2022,
            "rank_display": 149,
            "score": 50.5,
            "link": "https://www.topuniversities.com/universities/university-waterloo",
            "country": "Canada",
            "city": "Waterloo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waterloo_670_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 10269,
            "size": "XL",
            "faculty_count": 1908
        },
        {
            "university": "Cardiff University",
            "year": 2022,
            "rank_display": 151,
            "score": 50.4,
            "link": "https://www.topuniversities.com/universities/cardiff-university",
            "country": "United Kingdom",
            "city": "Cardiff",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/cardiff-university_592560cf2aeae70239af4ae6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7819,
            "size": "L",
            "faculty_count": 3350
        },
        {
            "university": "University of Vienna",
            "year": 2022,
            "rank_display": 151,
            "score": 50.4,
            "link": "https://www.topuniversities.com/universities/university-vienna",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-vienna_652_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 14514,
            "size": "XL",
            "faculty_count": 3637
        },
        {
            "university": "University of York",
            "year": 2022,
            "rank_display": 151,
            "score": 50.4,
            "link": "https://www.topuniversities.com/universities/university-york",
            "country": "United Kingdom",
            "city": "York",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-york_689_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5098,
            "size": "L",
            "faculty_count": 2209
        },
        {
            "university": "University of Rochester",
            "year": 2022,
            "rank_display": 154,
            "score": 50.3,
            "link": "https://www.topuniversities.com/universities/university-rochester",
            "country": "United States",
            "city": "Rochester",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rochester_592560cf2aeae70239af4c96_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3644,
            "size": "M",
            "faculty_count": 1853
        },
        {
            "university": "Aarhus University",
            "year": 2022,
            "rank_display": 155,
            "score": 50.2,
            "link": "https://www.topuniversities.com/universities/aarhus-university",
            "country": "Denmark",
            "city": "Aarhus",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aarhus-university_592560cf2aeae70239af4a8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2911,
            "size": "L",
            "faculty_count": 683
        },
        {
            "university": "Hanyang University",
            "year": 2022,
            "rank_display": 156,
            "score": 49.8,
            "link": "https://www.topuniversities.com/universities/hanyang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hanyang-university_250_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3455,
            "size": "L",
            "faculty_count": 3164
        },
        {
            "university": "Michigan State University",
            "year": 2022,
            "rank_display": 157,
            "score": 49.7,
            "link": "https://www.topuniversities.com/universities/michigan-state-university",
            "country": "United States",
            "city": "East Lansing",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/michigan-state-university_402_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 5795,
            "size": "XL",
            "faculty_count": 3008
        },
        {
            "university": "University of Maryland, College Park",
            "year": 2022,
            "rank_display": 158,
            "score": 49.5,
            "link": "https://www.topuniversities.com/universities/university-maryland-college-park",
            "country": "United States",
            "city": "College Park",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-maryland-college-park_393_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4659,
            "size": "XL",
            "faculty_count": 3798
        },
        {
            "university": "Technische Universität Berlin (TU Berlin)",
            "year": 2022,
            "rank_display": 159,
            "score": 49.3,
            "link": "https://www.topuniversities.com/universities/technische-universitat-berlin-tu-berlin",
            "country": "Germany",
            "city": "Berlin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-berlin-tu-berlin_595_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 6464,
            "size": "L",
            "faculty_count": 2331
        },
        {
            "university": "Emory University",
            "year": 2022,
            "rank_display": 160,
            "score": 48.6,
            "link": "https://www.topuniversities.com/universities/emory-university",
            "country": "United States",
            "city": "Atlanta",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/emory-university_185_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2264,
            "size": "L",
            "faculty_count": 2725
        },
        {
            "university": "Case Western Reserve University",
            "year": 2022,
            "rank_display": 161,
            "score": 48.2,
            "link": "https://www.topuniversities.com/universities/case-western-reserve-university",
            "country": "United States",
            "city": "Cleveland",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/case-western-reserve-university_102_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2864,
            "size": "M",
            "faculty_count": 1639
        },
        {
            "university": "Tecnológico de Monterrey",
            "year": 2022,
            "rank_display": 161,
            "score": 48.2,
            "link": "https://www.topuniversities.com/universities/tecnologico-de-monterrey",
            "country": "Mexico",
            "city": "Monterrey",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/instituto-tecnolgico-y-de-estudios-superiores-de-monterrey_592560cf2aeae70239af4cd6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3514,
            "size": "XL",
            "faculty_count": 5894
        },
        {
            "university": "King Fahd University of Petroleum & Minerals",
            "year": 2022,
            "rank_display": 163,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/king-fahd-university-petroleum-minerals",
            "country": "Saudi Arabia",
            "city": "Dhahran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-fahd-university-of-petroleum-minerals_779_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 782,
            "size": "M",
            "faculty_count": 1103
        },
        {
            "university": "University of Pittsburgh",
            "year": 2022,
            "rank_display": 163,
            "score": 48.1,
            "link": "https://www.topuniversities.com/universities/university-pittsburgh",
            "country": "United States",
            "city": "Pittsburgh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pittsburgh_500_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2590,
            "size": "L",
            "faculty_count": 4746
        },
        {
            "university": "RWTH Aachen University",
            "year": 2022,
            "rank_display": 165,
            "score": 48,
            "link": "https://www.topuniversities.com/universities/rwth-aachen-university",
            "country": "Germany",
            "city": "Aachen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rwth-aachen-university_4_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 12477,
            "size": "XL",
            "faculty_count": 2431
        },
        {
            "university": "Alma Mater Studiorum - University of Bologna",
            "year": 2022,
            "rank_display": 166,
            "score": 47.7,
            "link": "https://www.topuniversities.com/universities/alma-mater-studiorum-university-bologna",
            "country": "Italy",
            "city": "Bologna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/alma-mater-studiorum-university-of-bologna_592560cf2aeae70239af4ac0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 5536,
            "size": "XL",
            "faculty_count": 2885
        },
        {
            "university": "University of Bath",
            "year": 2022,
            "rank_display": 166,
            "score": 47.7,
            "link": "https://www.topuniversities.com/universities/university-bath",
            "country": "United Kingdom",
            "city": "Bath",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bath_47_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5326,
            "size": "L",
            "faculty_count": 1339
        },
        {
            "university": "Texas A&M University",
            "year": 2022,
            "rank_display": 168,
            "score": 47.6,
            "link": "https://www.topuniversities.com/universities/texas-am-university",
            "country": "United States",
            "city": "College Station",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/texas-am-university_592560cf2aeae70239af4ce2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 6621,
            "size": "XL",
            "faculty_count": 3049
        },
        {
            "university": "Universitat de Barcelona",
            "year": 2022,
            "rank_display": 168,
            "score": 47.6,
            "link": "https://www.topuniversities.com/universities/universitat-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-barcelona_592560cf2aeae70239af4aaf_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 6979,
            "size": "XL",
            "faculty_count": 3002
        },
        {
            "university": "Western University",
            "year": 2022,
            "rank_display": 170,
            "score": 47.5,
            "link": "https://www.topuniversities.com/universities/western-university",
            "country": "Canada",
            "city": "London",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-western-ontario_674_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 6314,
            "size": "L",
            "faculty_count": 1311
        },
        {
            "university": "Sapienza University of Rome",
            "year": 2022,
            "rank_display": 171,
            "score": 47.2,
            "link": "https://www.topuniversities.com/universities/sapienza-university-rome",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sapienza-university-of-rome_592560cf2aeae70239af4c97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 5960,
            "size": "XL",
            "faculty_count": 3287
        },
        {
            "university": "Albert-Ludwigs-Universitaet Freiburg",
            "year": 2022,
            "rank_display": 172,
            "score": 47,
            "link": "https://www.topuniversities.com/universities/albert-ludwigs-universitaet-freiburg",
            "country": "Germany",
            "city": "Freiburg im Breisgau",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/albert-ludwigs-universitaet-freiburg_216_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3883,
            "size": "L",
            "faculty_count": 1993
        },
        {
            "university": "University College Dublin",
            "year": 2022,
            "rank_display": 173,
            "score": 46.9,
            "link": "https://www.topuniversities.com/universities/university-college-dublin",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-dublin_166_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6118,
            "size": "L",
            "faculty_count": 1836
        },
        {
            "university": "University of Florida",
            "year": 2022,
            "rank_display": 173,
            "score": 46.9,
            "link": "https://www.topuniversities.com/universities/university-florida",
            "country": "United States",
            "city": "Gainesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florida_212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4143,
            "size": "XL",
            "faculty_count": 6222
        },
        {
            "university": "Al-Farabi Kazakh National University",
            "year": 2022,
            "rank_display": 175,
            "score": 46.8,
            "link": "https://www.topuniversities.com/universities/al-farabi-kazakh-national-university",
            "country": "Kazakhstan",
            "city": "Almaty",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/al-farabi-kazakh-national-university_2146_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3054,
            "size": "L",
            "faculty_count": 4047
        },
        {
            "university": "University of Lausanne",
            "year": 2022,
            "rank_display": 176,
            "score": 46.6,
            "link": "https://www.topuniversities.com/universities/university-lausanne",
            "country": "Switzerland",
            "city": "Lausanne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lausanne_592560cf2aeae70239af4bd3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3026,
            "size": "L",
            "faculty_count": 1478
        },
        {
            "university": "Eberhard Karls Universität Tübingen",
            "year": 2022,
            "rank_display": 177,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/eberhard-karls-universitat-tubingen",
            "country": "Germany",
            "city": "Tübingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/eberhard-karls-universitt-tbingen_629_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2872,
            "size": "L",
            "faculty_count": 4517
        },
        {
            "university": "Indian Institute of Technology Bombay (IITB)",
            "year": 2022,
            "rank_display": 177,
            "score": 46.4,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-bombay-iitb",
            "country": "India",
            "city": "Mumbai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-bombay-iitb_281_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 116,
            "size": "M",
            "faculty_count": 1015
        },
        {
            "university": "Erasmus University Rotterdam",
            "year": 2022,
            "rank_display": 179,
            "score": 46.3,
            "link": "https://www.topuniversities.com/universities/erasmus-university-rotterdam",
            "country": "Netherlands",
            "city": "Rotterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/erasmus-university-rotterdam_190_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5932,
            "size": "L",
            "faculty_count": 2699
        },
        {
            "university": "National Tsing Hua University",
            "year": 2022,
            "rank_display": 180,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/national-tsing-hua-university",
            "country": "Taiwan",
            "city": "Hsinchu City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-tsing-hua-university_442_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1551,
            "size": "L",
            "faculty_count": 1265
        },
        {
            "university": "Technische Universität Wien",
            "year": 2022,
            "rank_display": 180,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/vienna-university-technology",
            "country": "Austria",
            "city": "Vienna",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vienna-university-of-technology_653_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2515,
            "size": "M",
            "faculty_count": 761
        },
        {
            "university": "University of Gothenburg",
            "year": 2022,
            "rank_display": 180,
            "score": 46.1,
            "link": "https://www.topuniversities.com/universities/university-gothenburg",
            "country": "Sweden",
            "city": "Gothenburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gothenburg_233_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 5652,
            "size": "L",
            "faculty_count": 2680
        },
        {
            "university": "Khalifa University",
            "year": 2022,
            "rank_display": 183,
            "score": 46,
            "link": "https://www.topuniversities.com/universities/khalifa-university",
            "country": "United Arab Emirates",
            "city": "Abu Dhabi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/khalifa-university-of-science-and-technology_592560e19988f300e2320ee2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 652,
            "size": "S",
            "faculty_count": 498
        },
        {
            "university": "Universidad de Chile",
            "year": 2022,
            "rank_display": 183,
            "score": 46,
            "link": "https://www.topuniversities.com/universities/universidad-de-chile",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-chile_121_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1697,
            "size": "XL",
            "faculty_count": 2412
        },
        {
            "university": "Indian Institute of Technology Delhi (IITD)",
            "year": 2022,
            "rank_display": 185,
            "score": 45.9,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-delhi-iitd",
            "country": "India",
            "city": "New Delhi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-delhi-iitd_282_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 100,
            "size": "M",
            "faculty_count": 843
        },
        {
            "university": "Indian Institute of Science",
            "year": 2022,
            "rank_display": 186,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/indian-institute-science",
            "country": "India",
            "city": "Bangalore",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-science-iisc-bangalore_22878_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 5,
            "size": "S",
            "faculty_count": 446
        },
        {
            "university": "University of Minnesota Twin Cities",
            "year": 2022,
            "rank_display": 186,
            "score": 45.7,
            "link": "https://www.topuniversities.com/universities/university-minnesota-twin-cities",
            "country": "United States",
            "city": "Minneapolis",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-minnesota_408_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5630,
            "size": "XL",
            "faculty_count": 5087
        },
        {
            "university": "Université catholique de Louvain (UCLouvain)",
            "year": 2022,
            "rank_display": 188,
            "score": 45.6,
            "link": "https://www.topuniversities.com/universities/universite-catholique-de-louvain-uclouvain",
            "country": "Belgium",
            "city": "Louvain-la-Neuve",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-catholique-de-louvain-uclouvain_592560cf2aeae70239af4aed_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4524,
            "size": "L",
            "faculty_count": 1315
        },
        {
            "university": "University of Liverpool",
            "year": 2022,
            "rank_display": 189,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/university-liverpool",
            "country": "United Kingdom",
            "city": "Liverpool",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-liverpool_350_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 9500,
            "size": "L",
            "faculty_count": 2771
        },
        {
            "university": "University of Twente",
            "year": 2022,
            "rank_display": 189,
            "score": 45.5,
            "link": "https://www.topuniversities.com/universities/university-twente",
            "country": "Netherlands",
            "city": "Enschede",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-twente_635_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3872,
            "size": "L",
            "faculty_count": 1177
        },
        {
            "university": "Dartmouth College",
            "year": 2022,
            "rank_display": 191,
            "score": 45.1,
            "link": "https://www.topuniversities.com/universities/dartmouth-college",
            "country": "United States",
            "city": "Hanover",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dartmouth-college_592560cf2aeae70239af4b1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 997,
            "size": "M",
            "faculty_count": 836
        },
        {
            "university": "Universiti Teknologi Malaysia",
            "year": 2022,
            "rank_display": 191,
            "score": 45.1,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-malaysia",
            "country": "Malaysia",
            "city": "Skudai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-malaysia_387_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4956,
            "size": "L",
            "faculty_count": 1869
        },
        {
            "university": "University of Wollongong",
            "year": 2022,
            "rank_display": 193,
            "score": 44.9,
            "link": "https://www.topuniversities.com/universities/university-wollongong",
            "country": "Australia",
            "city": "Wollongong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-wollongong_680_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4779,
            "size": "L",
            "faculty_count": 1410
        },
        {
            "university": "Curtin University",
            "year": 2022,
            "rank_display": 194,
            "score": 44.8,
            "link": "https://www.topuniversities.com/universities/curtin-university",
            "country": "Australia",
            "city": "Perth",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/curtin-university_150_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4565,
            "size": "L",
            "faculty_count": 1698
        },
        {
            "university": "Technische Universität Dresden",
            "year": 2022,
            "rank_display": 194,
            "score": 44.8,
            "link": "https://www.topuniversities.com/universities/technische-universitat-dresden",
            "country": "Germany",
            "city": "Dresden",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-dresden_598_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5167,
            "size": "XL",
            "faculty_count": 4636
        },
        {
            "university": "University of Otago",
            "year": 2022,
            "rank_display": 194,
            "score": 44.8,
            "link": "https://www.topuniversities.com/universities/university-otago",
            "country": "New Zealand",
            "city": "Dunedin",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-otago_474_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3585,
            "size": "L",
            "faculty_count": 1333
        },
        {
            "university": "The University of Newcastle, Australia (UON)",
            "year": 2022,
            "rank_display": 197,
            "score": 44.6,
            "link": "https://www.topuniversities.com/universities/university-newcastle-australia-uon",
            "country": "Australia",
            "city": "Callaghan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-newcastle-australia-uon_449_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1747,
            "size": "L",
            "faculty_count": 1135
        },
        {
            "university": "The Hebrew University of Jerusalem",
            "year": 2022,
            "rank_display": 198,
            "score": 44.5,
            "link": "https://www.topuniversities.com/universities/hebrew-university-jerusalem",
            "country": "Israel",
            "city": "Jerusalem",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-hebrew-university-of-jerusalem_256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1176,
            "size": "L",
            "faculty_count": 2295
        },
        {
            "university": "University of Bergen",
            "year": 2022,
            "rank_display": 199,
            "score": 44.2,
            "link": "https://www.topuniversities.com/universities/university-bergen",
            "country": "Norway",
            "city": "Bergen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-bergen_53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 832,
            "size": "M",
            "faculty_count": 1634
        },
        {
            "university": "Macquarie University",
            "year": 2022,
            "rank_display": 200,
            "score": 44.1,
            "link": "https://www.topuniversities.com/universities/macquarie-university",
            "country": "Australia",
            "city": "Sydney",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/macquarie-university_378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8621,
            "size": "L",
            "faculty_count": 1766
        },
        {
            "university": "Keio University",
            "year": 2022,
            "rank_display": 201,
            "score": 43.9,
            "link": "https://www.topuniversities.com/universities/keio-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/keio-university_592560cf2aeae70239af4bbe_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3101,
            "size": "XL",
            "faculty_count": 4028
        },
        {
            "university": "University of Reading",
            "year": 2022,
            "rank_display": 202,
            "score": 43.5,
            "link": "https://www.topuniversities.com/universities/university-reading",
            "country": "United Kingdom",
            "city": "Reading",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-reading_517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 5007,
            "size": "L",
            "faculty_count": 1325
        },
        {
            "university": "Waseda University",
            "year": 2022,
            "rank_display": 203,
            "score": 43.3,
            "link": "https://www.topuniversities.com/universities/waseda-university",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/waseda-university_592560cf2aeae70239af4d1c_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 7209,
            "size": "XL",
            "faculty_count": 3833
        },
        {
            "university": "University of Göttingen",
            "year": 2022,
            "rank_display": 204,
            "score": 43.2,
            "link": "https://www.topuniversities.com/universities/university-gottingen",
            "country": "Germany",
            "city": "Göttingen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-gttingen_234_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3875,
            "size": "XL",
            "faculty_count": 3717
        },
        {
            "university": "University of Aberdeen",
            "year": 2022,
            "rank_display": 205,
            "score": 43.1,
            "link": "https://www.topuniversities.com/universities/university-aberdeen",
            "country": "United Kingdom",
            "city": "Aberdeen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-aberdeen_592560cf2aeae70239af4a8b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5544,
            "size": "L",
            "faculty_count": 1175
        },
        {
            "university": "RMIT University",
            "year": 2022,
            "rank_display": 206,
            "score": 42.8,
            "link": "https://www.topuniversities.com/universities/rmit-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/rmit-university_592560cf2aeae70239af4c95_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 11743,
            "size": "XL",
            "faculty_count": 1859
        },
        {
            "university": "Universidad Autónoma de Madrid",
            "year": 2022,
            "rank_display": 207,
            "score": 42.7,
            "link": "https://www.topuniversities.com/universities/universidad-autonoma-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-autnoma-de-madrid_379_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2470,
            "size": "L",
            "faculty_count": 2869
        },
        {
            "university": "Universite libre de Bruxelles",
            "year": 2022,
            "rank_display": 207,
            "score": 42.7,
            "link": "https://www.topuniversities.com/universities/universite-libre-de-bruxelles",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universite-libre-de-bruxelles_75_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 8073,
            "size": "L",
            "faculty_count": 1101
        },
        {
            "university": "Universitat Autònoma de Barcelona",
            "year": 2022,
            "rank_display": 209,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/universitat-autonoma-de-barcelona",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-autnoma-de-barcelona_592560cf2aeae70239af4ab0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 4069,
            "size": "XL",
            "faculty_count": 2132
        },
        {
            "university": "Vrije Universiteit Amsterdam",
            "year": 2022,
            "rank_display": 209,
            "score": 42.6,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-amsterdam",
            "country": "Netherlands",
            "city": "Amsterdam",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-amsterdam_658_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4059,
            "size": "L",
            "faculty_count": 2598
        },
        {
            "university": "Tongji University",
            "year": 2022,
            "rank_display": 211,
            "score": 42.5,
            "link": "https://www.topuniversities.com/universities/tongji-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tongji-university_617_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 5095,
            "size": "XL",
            "faculty_count": 2785
        },
        {
            "university": "Ulsan National Institute of Science and Technology (UNIST)",
            "year": 2022,
            "rank_display": 212,
            "score": 42.4,
            "link": "https://www.topuniversities.com/universities/ulsan-national-institute-science-technology-unist",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ulsan-national-institute-of-science-and-technology-unist_5ba37add9ed419611e043517_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 355,
            "size": "S",
            "faculty_count": 518
        },
        {
            "university": "Queensland University of Technology (QUT)",
            "year": 2022,
            "rank_display": 213,
            "score": 42.1,
            "link": "https://www.topuniversities.com/universities/queensland-university-technology-qut",
            "country": "Australia",
            "city": "Brisbane",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/queensland-university-of-technology-qut_592560cf2aeae70239af4c88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 6403,
            "size": "XL",
            "faculty_count": 2028
        },
        {
            "university": "Universität Hamburg",
            "year": 2022,
            "rank_display": 214,
            "score": 42,
            "link": "https://www.topuniversities.com/universities/universitat-hamburg",
            "country": "Germany",
            "city": "Hamburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-hamburg_592560cf2aeae70239af4b79_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3626,
            "size": "L",
            "faculty_count": 2032
        },
        {
            "university": "Chulalongkorn University",
            "year": 2022,
            "rank_display": 215,
            "score": 41.9,
            "link": "https://www.topuniversities.com/universities/chulalongkorn-university",
            "country": "Thailand",
            "city": "Bangkok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chulalongkorn-university_592560cf2aeae70239af4b01_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 985,
            "size": "XL",
            "faculty_count": 3354
        },
        {
            "university": "Arizona State University",
            "year": 2022,
            "rank_display": 216,
            "score": 41.8,
            "link": "https://www.topuniversities.com/universities/arizona-state-university",
            "country": "United States",
            "city": "Phoenix",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/arizona-state-university_592560cf2aeae70239af4a9c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8430,
            "size": "XL",
            "faculty_count": 2869
        },
        {
            "university": "Queen's University Belfast",
            "year": 2022,
            "rank_display": 216,
            "score": 41.8,
            "link": "https://www.topuniversities.com/universities/queens-university-belfast",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-belfast_592560cf2aeae70239af4c86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 7397,
            "size": "L",
            "faculty_count": 1951
        },
        {
            "university": "Vanderbilt University",
            "year": 2022,
            "rank_display": 218,
            "score": 41.7,
            "link": "https://www.topuniversities.com/universities/vanderbilt-university",
            "country": "United States",
            "city": "Nashville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/vanderbilt-university_649_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 2022,
            "size": "L",
            "faculty_count": 4473
        },
        {
            "university": "Universidade Estadual de Campinas (Unicamp)",
            "year": 2022,
            "rank_display": 219,
            "score": 41.3,
            "link": "https://www.topuniversities.com/universities/universidade-estadual-de-campinas-unicamp",
            "country": "Brazil",
            "city": "Campinas",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-estadual-de-campinas-unicamp_96_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 944,
            "size": "L",
            "faculty_count": 1875
        },
        {
            "university": "Radboud University",
            "year": 2022,
            "rank_display": 220,
            "score": 41.1,
            "link": "https://www.topuniversities.com/universities/radboud-university",
            "country": "Netherlands",
            "city": "Nijmegen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/radboud-university_452_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2781,
            "size": "L",
            "faculty_count": 2074
        },
        {
            "university": "Vrije Universiteit Brussel (VUB)",
            "year": 2022,
            "rank_display": 221,
            "score": 41,
            "link": "https://www.topuniversities.com/universities/vrije-universiteit-brussel-vub",
            "country": "Belgium",
            "city": "Brussels",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vrije-universiteit-brussel-vub_74_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2994,
            "size": "L",
            "faculty_count": 1815
        },
        {
            "university": "University of Notre Dame",
            "year": 2022,
            "rank_display": 222,
            "score": 40.9,
            "link": "https://www.topuniversities.com/universities/university-notre-dame",
            "country": "United States",
            "city": "Notre Dame",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-notre-dame_458_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1484,
            "size": "L",
            "faculty_count": 1545
        },
        {
            "university": "Complutense University of Madrid",
            "year": 2022,
            "rank_display": 223,
            "score": 40.8,
            "link": "https://www.topuniversities.com/universities/complutense-university-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/complutense-university-of-madrid_592560cf2aeae70239af4bff_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 7295,
            "size": "XL",
            "faculty_count": 4778
        },
        {
            "university": "Qatar University",
            "year": 2022,
            "rank_display": 224,
            "score": 40.7,
            "link": "https://www.topuniversities.com/universities/qatar-university",
            "country": "Qatar",
            "city": "Doha",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/qatar-university_2537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3197,
            "size": "M",
            "faculty_count": 1163
        },
        {
            "university": "Wuhan University",
            "year": 2022,
            "rank_display": 225,
            "score": 40.4,
            "link": "https://www.topuniversities.com/universities/wuhan-university",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/wuhan-university_889_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3682,
            "size": "XL",
            "faculty_count": 3828
        },
        {
            "university": "Rheinische Friedrich-Wilhelms-Universität Bonn",
            "year": 2022,
            "rank_display": 226,
            "score": 40.3,
            "link": "https://www.topuniversities.com/universities/rheinische-friedrich-wilhelms-universitat-bonn",
            "country": "Germany",
            "city": "Bonn",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rheinische-friedrich-wilhelms-universitt-bonn_592560cf2aeae70239af4ac1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 3276,
            "size": "L",
            "faculty_count": 4207
        },
        {
            "university": "University of Cape Town",
            "year": 2022,
            "rank_display": 226,
            "score": 40.3,
            "link": "https://www.topuniversities.com/universities/university-cape-town",
            "country": "South Africa",
            "city": "Cape Town",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/210830024732pm396697UCTcircular-logo1-CMYK-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3204,
            "size": "L",
            "faculty_count": 1503
        },
        {
            "university": "University of Sussex",
            "year": 2022,
            "rank_display": 226,
            "score": 40.3,
            "link": "https://www.topuniversities.com/universities/university-sussex",
            "country": "United Kingdom",
            "city": "Brighton",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-sussex_592560cf2aeae70239af4ccd_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6350,
            "size": "L",
            "faculty_count": 1320
        },
        {
            "university": "University of Virginia",
            "year": 2022,
            "rank_display": 226,
            "score": 40.3,
            "link": "https://www.topuniversities.com/universities/university-virginia",
            "country": "United States",
            "city": "Charlottesville",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-virginia_656_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2201,
            "size": "L",
            "faculty_count": 2731
        },
        {
            "university": "University of Ottawa",
            "year": 2022,
            "rank_display": 230,
            "score": 40.2,
            "link": "https://www.topuniversities.com/universities/university-ottawa",
            "country": "Canada",
            "city": "Ottawa",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-ottawa_475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 8885,
            "size": "XL",
            "faculty_count": 1677
        },
        {
            "university": "Loughborough University",
            "year": 2022,
            "rank_display": 231,
            "score": 40.1,
            "link": "https://www.topuniversities.com/universities/loughborough-university",
            "country": "United Kingdom",
            "city": "Loughborough",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/loughborough-university_592560cf2aeae70239af4bf3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4540,
            "size": "L",
            "faculty_count": 1445
        },
        {
            "university": "University of California, Irvine",
            "year": 2022,
            "rank_display": 232,
            "score": 40,
            "link": "https://www.topuniversities.com/universities/university-california-irvine",
            "country": "United States",
            "city": "Irvine",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-irvine_86_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 9323,
            "size": "XL",
            "faculty_count": 2775
        },
        {
            "university": "Maastricht University",
            "year": 2022,
            "rank_display": 233,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/maastricht-university",
            "country": "Netherlands",
            "city": "Maastricht",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/maastricht-university_377_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 10425,
            "size": "L",
            "faculty_count": 1418
        },
        {
            "university": "University of Antwerp",
            "year": 2022,
            "rank_display": 233,
            "score": 39.8,
            "link": "https://www.topuniversities.com/universities/university-antwerp",
            "country": "Belgium",
            "city": "Antwerp",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-antwerp_21_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2688,
            "size": "L",
            "faculty_count": 1704
        },
        {
            "university": "University of Calgary",
            "year": 2022,
            "rank_display": 235,
            "score": 39.7,
            "link": "https://www.topuniversities.com/universities/university-calgary",
            "country": "Canada",
            "city": "Calgary",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-calgary_592560cf2aeae70239af4ad5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6791,
            "size": "XL",
            "faculty_count": 2668
        },
        {
            "university": "Harbin Institute of Technology",
            "year": 2022,
            "rank_display": 236,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/harbin-institute-technology",
            "country": "China (Mainland)",
            "city": "Harbin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/harbin-institute-of-technology_877_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2102,
            "size": "XL",
            "faculty_count": 3215
        },
        {
            "university": "Universidad de los Andes",
            "year": 2022,
            "rank_display": 236,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/universidad-de-los-andes",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-los-andes_592560cf2aeae70239af4bf6_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 13,
            "international_students": 151,
            "size": "L",
            "faculty_count": 1223
        },
        {
            "university": "University of Leicester",
            "year": 2022,
            "rank_display": 236,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/university-leicester",
            "country": "United Kingdom",
            "city": "Leicester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-leicester_339_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4919,
            "size": "L",
            "faculty_count": 1518
        },
        {
            "university": "Victoria University of Wellington",
            "year": 2022,
            "rank_display": 236,
            "score": 39.6,
            "link": "https://www.topuniversities.com/universities/victoria-university-wellington",
            "country": "New Zealand",
            "city": "Kelburn, Wellington",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/victoria-university-of-wellington_592560cf2aeae70239af4d0f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 3668,
            "size": "L",
            "faculty_count": 1124
        },
        {
            "university": "Queen's University at Kingston",
            "year": 2022,
            "rank_display": 240,
            "score": 39.5,
            "link": "https://www.topuniversities.com/universities/queens-university-kingston",
            "country": "Canada",
            "city": "Kingston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/queens-university-at-kingston_513_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3858,
            "size": "L",
            "faculty_count": 1287
        },
        {
            "university": "USI - Università della Svizzera italiana",
            "year": 2022,
            "rank_display": 240,
            "score": 39.5,
            "link": "https://www.topuniversities.com/universities/usi-universita-della-svizzera-italiana",
            "country": "Switzerland",
            "city": "Lugano",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/usi-universit-della-svizzera-italiana_592560cf2aeae70239af50ca_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1864,
            "size": "S",
            "faculty_count": 869
        },
        {
            "university": "American University of Beirut (AUB)",
            "year": 2022,
            "rank_display": 242,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/american-university-beirut-aub",
            "country": "Lebanon",
            "city": "Beirut",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-beirut-aub_780_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1724,
            "size": "M",
            "faculty_count": 964
        },
        {
            "university": "Saint Petersburg State University",
            "year": 2022,
            "rank_display": 242,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/saint-petersburg-state-university",
            "country": "Russia",
            "city": "Saint Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/saint-petersburg-state-university_592560cf2aeae70239af4cc0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 4982,
            "size": "L",
            "faculty_count": 3054
        },
        {
            "university": "Università di Padova",
            "year": 2022,
            "rank_display": 242,
            "score": 39.2,
            "link": "https://www.topuniversities.com/universities/universita-di-padova",
            "country": "Italy",
            "city": "Padova",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitdi-padova_480_small.jpg",
            "type": "Public",
            "research_output": "Very high",
            "student_faculty_ratio": 17,
            "international_students": 2654,
            "size": "XL",
            "faculty_count": 2806
        },
        {
            "university": "Ecole des Ponts ParisTech",
            "year": 2022,
            "rank_display": 245,
            "score": 39.1,
            "link": "https://www.topuniversities.com/universities/ecole-des-ponts-paristech",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ecole-des-ponts-paristech_187_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 735,
            "size": "S",
            "faculty_count": 338
        },
        {
            "university": "Novosibirsk State University",
            "year": 2022,
            "rank_display": 246,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/novosibirsk-state-university",
            "country": "Russia",
            "city": "Novosibirsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/novosibirsk-state-university_462_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1623,
            "size": "M",
            "faculty_count": 1218
        },
        {
            "university": "University of Massachusetts Amherst",
            "year": 2022,
            "rank_display": 246,
            "score": 38.8,
            "link": "https://www.topuniversities.com/universities/university-massachusetts-amherst",
            "country": "United States",
            "city": "Amherst",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-massachusetts-amherst_592560cf2aeae70239af4c0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3696,
            "size": "L",
            "faculty_count": 1852
        },
        {
            "university": "Georgetown University",
            "year": 2022,
            "rank_display": 248,
            "score": 38.7,
            "link": "https://www.topuniversities.com/universities/georgetown-university",
            "country": "United States",
            "city": "Washington D.C.",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/georgetown-university_224_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3286,
            "size": "L",
            "faculty_count": 2009
        },
        {
            "university": "Universitat Pompeu Fabra (Barcelona)",
            "year": 2022,
            "rank_display": 248,
            "score": 38.7,
            "link": "https://www.topuniversities.com/universities/universitat-pompeu-fabra",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-pompeu-fabra_504_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1633,
            "size": "L",
            "faculty_count": 785
        },
        {
            "university": "Universiti Brunei Darussalam (UBD)",
            "year": 2022,
            "rank_display": 250,
            "score": 38.6,
            "link": "https://www.topuniversities.com/universities/universiti-brunei-darussalam-ubd",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-brunei-darussalam-ubd_22212_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 635,
            "size": "S",
            "faculty_count": 475
        },
        {
            "university": "University of Colorado Boulder",
            "year": 2022,
            "rank_display": 251,
            "score": 38.5,
            "link": "https://www.topuniversities.com/universities/university-colorado-boulder",
            "country": "United States",
            "city": "Boulder",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-boulder_137_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2441,
            "size": "XL",
            "faculty_count": 2946
        },
        {
            "university": "National Cheng Kung University (NCKU)",
            "year": 2022,
            "rank_display": 252,
            "score": 38.4,
            "link": "https://www.topuniversities.com/universities/national-cheng-kung-university-ncku",
            "country": "Taiwan",
            "city": "Tainan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-cheng-kung-university-ncku_435_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2656,
            "size": "L",
            "faculty_count": 2079
        },
        {
            "university": "Yeshiva University",
            "year": 2022,
            "rank_display": 252,
            "score": 38.4,
            "link": "https://www.topuniversities.com/universities/yeshiva-university",
            "country": "United States",
            "city": "New York City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-katz-school-at-yeshiva-university_685_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 467,
            "size": "S",
            "faculty_count": 471
        },
        {
            "university": "Gadjah Mada University",
            "year": 2022,
            "rank_display": 254,
            "score": 38.3,
            "link": "https://www.topuniversities.com/universities/gadjah-mada-university",
            "country": "Indonesia",
            "city": "Yogyakarta",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gadjah-mada-university_219_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 738,
            "size": "XL",
            "faculty_count": 4728
        },
        {
            "university": "Indian Institute of Technology Madras (IITM)",
            "year": 2022,
            "rank_display": 255,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-madras-iitm",
            "country": "India",
            "city": "Chennai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-madras-iitm_286_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 94,
            "size": "M",
            "faculty_count": 884
        },
        {
            "university": "Mahidol University",
            "year": 2022,
            "rank_display": 255,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/mahidol-university",
            "country": "Thailand",
            "city": "Nakhon Pathom",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/mahidol-university_381_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1415,
            "size": "L",
            "faculty_count": 3850
        },
        {
            "university": "Tel Aviv University",
            "year": 2022,
            "rank_display": 255,
            "score": 38.1,
            "link": "https://www.topuniversities.com/universities/tel-aviv-university",
            "country": "Israel",
            "city": "Tel Aviv",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tel-aviv-university_592560cf2aeae70239af4cde_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 1294,
            "size": "L",
            "faculty_count": 1048
        },
        {
            "university": "National University of Ireland Galway",
            "year": 2022,
            "rank_display": 258,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/national-university-ireland-galway",
            "country": "Ireland",
            "city": "Galway",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-ireland-galway_220_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 3208,
            "size": "L",
            "faculty_count": 1835
        },
        {
            "university": "Universidad Nacional de Colombia",
            "year": 2022,
            "rank_display": 258,
            "score": 37.6,
            "link": "https://www.topuniversities.com/universities/universidad-nacional-de-colombia",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-nacional-de-colombia_592560cf2aeae70239af4b0b_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 335,
            "size": "XL",
            "faculty_count": 3055
        },
        {
            "university": "University of Canterbury | Te Whare Wānanga o Waitaha",
            "year": 2022,
            "rank_display": 258,
            "score": 37.7,
            "link": "https://www.topuniversities.com/universities/university-canterbury-te-whare-wananga-o-waitaha",
            "country": "New Zealand",
            "city": "Christchurch",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canterbury_97_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2816,
            "size": "L",
            "faculty_count": 867
        },
        {
            "university": "Sun Yat-sen University",
            "year": 2022,
            "rank_display": 260,
            "score": 37.4,
            "link": "https://www.topuniversities.com/universities/sun-yat-sen-university",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sun-yat-sen-university_888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3127,
            "size": "XL",
            "faculty_count": 7002
        },
        {
            "university": "Sciences Po",
            "year": 2022,
            "rank_display": 261,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/sciences-po",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/sciences-po-_592560cf2aeae70239af4c64_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4841,
            "size": "M",
            "faculty_count": 1808
        },
        {
            "university": "Université de Paris",
            "year": 2022,
            "rank_display": 261,
            "score": 37.3,
            "link": "https://www.topuniversities.com/universities/universite-de-paris",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-paris_5ddd0e075c52f3703747e454_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 11387,
            "size": "XL",
            "faculty_count": 3755
        },
        {
            "university": "Kyung Hee University",
            "year": 2022,
            "rank_display": 264,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/kyung-hee-university",
            "country": "South Korea",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kyung-hee-university_592560cf2aeae70239af4bcd_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 4715,
            "size": "L",
            "faculty_count": 3435
        },
        {
            "university": "Rutgers University–New Brunswick",
            "year": 2022,
            "rank_display": 264,
            "score": 37.2,
            "link": "https://www.topuniversities.com/universities/rutgers-university-new-brunswick",
            "country": "United States",
            "city": "New Brunswick",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rutgers-university-new-brunswick_538_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 7965,
            "size": "XL",
            "faculty_count": 5009
        },
        {
            "university": "Charles University",
            "year": 2022,
            "rank_display": 266,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/charles-university",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/charles-university_117_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 9080,
            "size": "XL",
            "faculty_count": 5019
        },
        {
            "university": "University of Navarra",
            "year": 2022,
            "rank_display": 266,
            "score": 37.1,
            "link": "https://www.topuniversities.com/universities/university-navarra",
            "country": "Spain",
            "city": "Pamplona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-navarra_592560cf2aeae70239af4c40_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2978,
            "size": "M",
            "faculty_count": 1175
        },
        {
            "university": "National Yang Ming Chiao Tung University",
            "year": 2022,
            "rank_display": 268,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/national-yang-ming-chiao-tung-university",
            "country": "Taiwan",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-yang-ming-chiao-tung-university_6018cedc5c52f3687b1e0ed2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1475,
            "size": "L",
            "faculty_count": 2112
        },
        {
            "university": "The University of Arizona",
            "year": 2022,
            "rank_display": 268,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/university-arizona",
            "country": "United States",
            "city": "Tucson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/the-university-of-arizona_26_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3526,
            "size": "XL",
            "faculty_count": 5724
        },
        {
            "university": "Technical University of Darmstadt",
            "year": 2022,
            "rank_display": 269,
            "score": 36.9,
            "link": "https://www.topuniversities.com/universities/technical-university-darmstadt",
            "country": "Germany",
            "city": "Darmstadt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/technische-universitt-darmstadt_628_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3979,
            "size": "L",
            "faculty_count": 804
        },
        {
            "university": "Beijing Normal University",
            "year": 2022,
            "rank_display": 270,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/beijing-normal-university",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-normal-university_869_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3288,
            "size": "L",
            "faculty_count": 2098
        },
        {
            "university": "Heriot-Watt University",
            "year": 2022,
            "rank_display": 270,
            "score": 36.7,
            "link": "https://www.topuniversities.com/universities/heriot-watt-university",
            "country": "United Kingdom",
            "city": "Edinburgh",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/heriot-watt-university_592560cf2aeae70239af4d5f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3536,
            "size": "M",
            "faculty_count": 806
        },
        {
            "university": "Dalhousie University",
            "year": 2022,
            "rank_display": 272,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/dalhousie-university",
            "country": "Canada",
            "city": "Halifax",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/dalhousie-university_151_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4483,
            "size": "L",
            "faculty_count": 1150
        },
        {
            "university": "Tomsk State University",
            "year": 2022,
            "rank_display": 272,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/tomsk-state-university",
            "country": "Russia",
            "city": "Tomsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tomsk-state-university_616_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 2828,
            "size": "M",
            "faculty_count": 1742
        },
        {
            "university": "University of Surrey",
            "year": 2022,
            "rank_display": 272,
            "score": 36.6,
            "link": "https://www.topuniversities.com/universities/university-surrey",
            "country": "United Kingdom",
            "city": "Guildford",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-surrey_584_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5556,
            "size": "L",
            "faculty_count": 1221
        },
        {
            "university": "Southern University of Science and Technology",
            "year": 2022,
            "rank_display": 275,
            "score": 36.5,
            "link": "https://www.topuniversities.com/universities/southern-university-science-technology",
            "country": "China (Mainland)",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/southern-university-of-science-and-technology-sustech_5963564fca24f34bfa1fd83a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 88,
            "size": "M",
            "faculty_count": 981
        },
        {
            "university": "Tufts University",
            "year": 2022,
            "rank_display": 275,
            "score": 36.5,
            "link": "https://www.topuniversities.com/universities/tufts-university",
            "country": "United States",
            "city": "Medford",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tufts-university_630_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1798,
            "size": "M",
            "faculty_count": 1459
        },
        {
            "university": "Graz University of Technology",
            "year": 2022,
            "rank_display": 277,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/graz-university-technology",
            "country": "Austria",
            "city": "Graz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/graz-university-of-technology_1239_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1473,
            "size": "M",
            "faculty_count": 1427
        },
        {
            "university": "Indian Institute of Technology Kanpur (IITK)",
            "year": 2022,
            "rank_display": 277,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kanpur-iitk",
            "country": "India",
            "city": "Kanpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kanpur-iitk_592560cf2aeae70239af4ba5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 29,
            "size": "M",
            "faculty_count": 551
        },
        {
            "university": "King Saud University",
            "year": 2022,
            "rank_display": 277,
            "score": 36.4,
            "link": "https://www.topuniversities.com/universities/king-saud-university",
            "country": "Saudi Arabia",
            "city": "Riyadh",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/king-saud-university_1175_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3896,
            "size": "XL",
            "faculty_count": 4216
        },
        {
            "university": "Indian Institute of Technology Kharagpur (IIT-KGP)",
            "year": 2022,
            "rank_display": 280,
            "score": 36.3,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-kharagpur-iit-kgp",
            "country": "India",
            "city": "Kharagpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-kharagpur-iit-kgp_285_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 27,
            "size": "M",
            "faculty_count": 770
        },
        {
            "university": "Bauman Moscow State Technical University",
            "year": 2022,
            "rank_display": 281,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/bauman-moscow-state-technical-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/bauman-moscow-state-technical-university_592560cf2aeae70239af505c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2370,
            "size": "L",
            "faculty_count": 4349
        },
        {
            "university": "Universität Innsbruck",
            "year": 2022,
            "rank_display": 281,
            "score": 36.2,
            "link": "https://www.topuniversities.com/universities/universitat-innsbruck",
            "country": "Austria",
            "city": "Innsbruck",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-innsbruck_592560cf2aeae70239af4ba7_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 6323,
            "size": "L",
            "faculty_count": 1769
        },
        {
            "university": "Deakin University",
            "year": 2022,
            "rank_display": 283,
            "score": 36.1,
            "link": "https://www.topuniversities.com/universities/deakin-university",
            "country": "Australia",
            "city": "Geelong",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/download-48x48.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 23,
            "international_students": 10684,
            "size": "XL",
            "faculty_count": 1851
        },
        {
            "university": "Massey University",
            "year": 2022,
            "rank_display": 284,
            "score": 35.9,
            "link": "https://www.topuniversities.com/universities/massey-university",
            "country": "New Zealand",
            "city": "Palmerston North",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/massey-university_395_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4836,
            "size": "M",
            "faculty_count": 1209
        },
        {
            "university": "University of Illinois at Chicago (UIC)",
            "year": 2022,
            "rank_display": 285,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/university-illinois-chicago-uic",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-illinois-chicago-uic_592560cf2aeae70239af4b9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 3425,
            "size": "L",
            "faculty_count": 2808
        },
        {
            "university": "University of Tsukuba",
            "year": 2022,
            "rank_display": 285,
            "score": 35.6,
            "link": "https://www.topuniversities.com/universities/university-tsukuba",
            "country": "Japan",
            "city": "Tsukuba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tsukuba_592560cf2aeae70239af4cf7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2358,
            "size": "L",
            "faculty_count": 2073
        },
        {
            "university": "Hong Kong Baptist University",
            "year": 2022,
            "rank_display": 287,
            "score": 35.5,
            "link": "https://www.topuniversities.com/universities/hong-kong-baptist-university",
            "country": "Hong Kong SAR",
            "city": "Hong Kong",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hong-kong-baptist-university_893_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 4704,
            "size": "M",
            "faculty_count": 980
        },
        {
            "university": "United Arab Emirates University",
            "year": 2022,
            "rank_display": 288,
            "score": 35.4,
            "link": "https://www.topuniversities.com/universities/united-arab-emirates-university",
            "country": "United Arab Emirates",
            "city": "Al Ain",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/united-arab-emirates-university_778_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1238,
            "size": "M",
            "faculty_count": 919
        },
        {
            "university": "Griffith University",
            "year": 2022,
            "rank_display": 290,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/griffith-university",
            "country": "Australia",
            "city": "Nathan",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/griffith-university_242_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 5875,
            "size": "XL",
            "faculty_count": 1918
        },
        {
            "university": "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
            "year": 2022,
            "rank_display": 290,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/moscow-institute-physics-technology-mipt-moscow-phystech",
            "country": "Russia",
            "city": "Dolgoprudny",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-institute-of-physics-and-technology-mipt-moscow-phystech_15270_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1153,
            "size": "M",
            "faculty_count": 1110
        },
        {
            "university": "Universitas Indonesia",
            "year": 2022,
            "rank_display": 290,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/universitas-indonesia",
            "country": "Indonesia",
            "city": "Depok",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universitas-indonesia_290_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1412,
            "size": "XL",
            "faculty_count": 4210
        },
        {
            "university": "Université Paris 1 Panthéon-Sorbonne",
            "year": 2022,
            "rank_display": 290,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/universite-paris-1-pantheon-sorbonne",
            "country": "France",
            "city": "Paris",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-paris-1-panthon-sorbonne_482_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 7257,
            "size": "XL",
            "faculty_count": 1679
        },
        {
            "university": "Xi’an Jiaotong University",
            "year": 2022,
            "rank_display": 290,
            "score": 35.1,
            "link": "https://www.topuniversities.com/universities/xian-jiaotong-university",
            "country": "China (Mainland)",
            "city": "Xi'an",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xian-jiaotong-university_683_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2266,
            "size": "XL",
            "faculty_count": 3729
        },
        {
            "university": "Belarusian State University",
            "year": 2022,
            "rank_display": 295,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/belarusian-state-university",
            "country": "Belarus",
            "city": "Minsk",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/belarusian-state-university_14128_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 4264,
            "size": "L",
            "faculty_count": 4173
        },
        {
            "university": "University of Porto",
            "year": 2022,
            "rank_display": 295,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/university-porto",
            "country": "Portugal",
            "city": "Porto",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-porto_760_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3783,
            "size": "L",
            "faculty_count": 2028
        },
        {
            "university": "University of Turku",
            "year": 2022,
            "rank_display": 295,
            "score": 34.9,
            "link": "https://www.topuniversities.com/universities/university-turku",
            "country": "Finland",
            "city": "Turku",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turku_592560cf2aeae70239af4cfe_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 604,
            "size": "L",
            "faculty_count": 1505
        },
        {
            "university": "Simon Fraser University",
            "year": 2022,
            "rank_display": 298,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/simon-fraser-university",
            "country": "Canada",
            "city": "Burnaby",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/simon-fraser-university_562_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 8051,
            "size": "L",
            "faculty_count": 1193
        },
        {
            "university": "University College Cork",
            "year": 2022,
            "rank_display": 298,
            "score": 34.8,
            "link": "https://www.topuniversities.com/universities/university-college-cork",
            "country": "Ireland",
            "city": "Cork",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-college-cork_142_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 3002,
            "size": "L",
            "faculty_count": 1619
        },
        {
            "university": "North Carolina State University",
            "year": 2022,
            "rank_display": 300,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/north-carolina-state-university",
            "country": "United States",
            "city": "Raleigh",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/north-carolina-state-university_592560cf2aeae70239af4c2a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 3156,
            "size": "L",
            "faculty_count": 1944
        },
        {
            "university": "University of Tartu",
            "year": 2022,
            "rank_display": 300,
            "score": 34.7,
            "link": "https://www.topuniversities.com/universities/university-tartu",
            "country": "Estonia",
            "city": "Tartu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tartu_592560cf2aeae70239af4d08_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1400,
            "size": "M",
            "faculty_count": 1605
        },
        {
            "university": "University of Strathclyde",
            "year": 2022,
            "rank_display": 302,
            "score": 34.4,
            "link": "https://www.topuniversities.com/universities/university-strathclyde",
            "country": "United Kingdom",
            "city": "Glasgow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-strathclyde_579_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5397,
            "size": "L",
            "faculty_count": 1532
        },
        {
            "university": "Bandung Institute of Technology (ITB)",
            "year": 2022,
            "rank_display": 303,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/bandung-institute-technology-itb",
            "country": "Indonesia",
            "city": "Bandung",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bandung-institute-of-technology-itb_41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 613,
            "size": "L",
            "faculty_count": 2228
        },
        {
            "university": "University of Tasmania",
            "year": 2022,
            "rank_display": 303,
            "score": 34.2,
            "link": "https://www.topuniversities.com/universities/university-tasmania",
            "country": "Australia",
            "city": "Hobart",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-tasmania_592560cf2aeae70239af4cd5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5260,
            "size": "L",
            "faculty_count": 982
        },
        {
            "university": "Gwangju Institute of Science and Technology (GIST)",
            "year": 2022,
            "rank_display": 305,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/gwangju-institute-science-technology-gist",
            "country": "South Korea",
            "city": "Gwangju",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/gwangju-institute-of-science-and-technology-gist_718_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 122,
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "HSE University",
            "year": 2022,
            "rank_display": 305,
            "score": 34.1,
            "link": "https://www.topuniversities.com/universities/hse-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/210831083256am323493logo-3-180x180-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 4535,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "University of East Anglia (UEA)",
            "year": 2022,
            "rank_display": 307,
            "score": 34,
            "link": "https://www.topuniversities.com/universities/university-east-anglia-uea",
            "country": "United Kingdom",
            "city": "Norwich",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-east-anglia-uea_637_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3541,
            "size": "L",
            "faculty_count": 1206
        },
        {
            "university": "University of Warsaw",
            "year": 2022,
            "rank_display": 308,
            "score": 33.9,
            "link": "https://www.topuniversities.com/universities/university-warsaw",
            "country": "Poland",
            "city": "Warsaw",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-warsaw_663_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2892,
            "size": "XL",
            "faculty_count": 3658
        },
        {
            "university": "Jagiellonian University",
            "year": 2022,
            "rank_display": 309,
            "score": 33.8,
            "link": "https://www.topuniversities.com/universities/jagiellonian-university",
            "country": "Poland",
            "city": "Krakow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/jagiellonian-university_300_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2907,
            "size": "XL",
            "faculty_count": 4089
        },
        {
            "university": "University of Southern Denmark (SDU)",
            "year": 2022,
            "rank_display": 309,
            "score": 33.8,
            "link": "https://www.topuniversities.com/universities/university-southern-denmark-sdu",
            "country": "Denmark",
            "city": "Odense",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-southern-denmark_592560cf2aeae70239af4c53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2511,
            "size": "L",
            "faculty_count": 1760
        },
        {
            "university": "Indiana University Bloomington",
            "year": 2022,
            "rank_display": 311,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/indiana-university-bloomington",
            "country": "United States",
            "city": "Bloomington",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/indiana-university-bloomington_289_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3722,
            "size": "XL",
            "faculty_count": 3837
        },
        {
            "university": "University of Cologne",
            "year": 2022,
            "rank_display": 311,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/university-cologne",
            "country": "Germany",
            "city": "Cologne",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cologne_592560cf2aeae70239af4bc6_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4890,
            "size": "XL",
            "faculty_count": 2724
        },
        {
            "university": "University of Miami",
            "year": 2022,
            "rank_display": 311,
            "score": 33.7,
            "link": "https://www.topuniversities.com/universities/university-miami",
            "country": "United States",
            "city": "Miami",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-miami_401_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2646,
            "size": "L",
            "faculty_count": 2565
        },
        {
            "university": "National Taiwan University of Science and Technology (Taiwan Tech)",
            "year": 2022,
            "rank_display": 314,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/national-taiwan-university-science-technology-taiwan-tech",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-university-of-science-and-technology-taiwan-tech_590_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1537,
            "size": "M",
            "faculty_count": 1005
        },
        {
            "university": "Université Grenoble Alpes",
            "year": 2022,
            "rank_display": 314,
            "score": 33.6,
            "link": "https://www.topuniversities.com/universities/universite-grenoble-alpes",
            "country": "France",
            "city": "Grenoble",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-grenoble-alpes_592560cf2aeae70239af5ab2_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 8880,
            "size": "XL",
            "faculty_count": 3088
        },
        {
            "university": "University of Milan",
            "year": 2022,
            "rank_display": 316,
            "score": 33.5,
            "link": "https://www.topuniversities.com/universities/university-milan",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milan_1873_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 3269,
            "size": "XL",
            "faculty_count": 2516
        },
        {
            "university": "IE University",
            "year": 2022,
            "rank_display": 317,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/ie-university",
            "country": "Spain",
            "city": "Segovia",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ie-university_293_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 3917,
            "size": "S",
            "faculty_count": 662
        },
        {
            "university": "RUDN University",
            "year": 2022,
            "rank_display": 317,
            "score": 33.4,
            "link": "https://www.topuniversities.com/universities/rudn-university",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/rudn-university_1503_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 8319,
            "size": "L",
            "faculty_count": 3890
        },
        {
            "university": "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
            "year": 2022,
            "rank_display": 319,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/national-research-nuclear-university-mephi-moscow-engineering-physics-institute",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-nuclear-university-mephi-moscow-engineering-physics-institute_15207_small.png",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1590,
            "size": "M",
            "faculty_count": 998
        },
        {
            "university": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
            "year": 2022,
            "rank_display": 319,
            "score": 33.1,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-catalunya-barcelonatech-upc",
            "country": "Spain",
            "city": "Barcelona",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-catalunya_103_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3101,
            "size": "L",
            "faculty_count": 2485
        },
        {
            "university": "Swinburne University of Technology",
            "year": 2022,
            "rank_display": 321,
            "score": 33,
            "link": "https://www.topuniversities.com/universities/swinburne-university-technology",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/swinburne-university-of-technology_759_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 4000,
            "size": "L",
            "faculty_count": 946
        },
        {
            "university": "University of Dundee",
            "year": 2022,
            "rank_display": 322,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/university-dundee",
            "country": "United Kingdom",
            "city": "Dundee",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/-university-of-dundee_592560cf2aeae70239af4b2c_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 3025,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            "year": 2022,
            "rank_display": 322,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/friedrich-alexander-universitat-erlangen-nurnberg",
            "country": "Germany",
            "city": "Erlangen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/friedrich-alexander-universitt-erlangen-nrnberg_191_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 4943,
            "size": "XL",
            "faculty_count": 1107
        },
        {
            "university": "Pontificia Universidad Católica Argentina",
            "year": 2022,
            "rank_display": 322,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-argentina",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-catlica-argentina_592560cf2aeae70239af5486_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 4,
            "international_students": 1063,
            "size": "L",
            "faculty_count": 3805
        },
        {
            "university": "University of Macau",
            "year": 2022,
            "rank_display": 322,
            "score": 32.9,
            "link": "https://www.topuniversities.com/universities/university-macau",
            "country": "Macau SAR",
            "city": "",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-macau_14378_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.921,
            "size": "M",
            "faculty_count": 650
        },
        {
            "university": "Aalborg University",
            "year": 2022,
            "rank_display": 326,
            "score": 32.8,
            "link": "https://www.topuniversities.com/universities/aalborg-university",
            "country": "Denmark",
            "city": "Aalborg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aalborg-university_1262_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.474,
            "size": "L",
            "faculty_count": 1.716
        },
        {
            "university": "University of South Australia",
            "year": 2022,
            "rank_display": 326,
            "score": 32.8,
            "link": "https://www.topuniversities.com/universities/university-south-australia",
            "country": "Australia",
            "city": "Magill",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-south-australia_566_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.886,
            "size": "L",
            "faculty_count": 1.524
        },
        {
            "university": "L.N. Gumilyov Eurasian National University (ENU)",
            "year": 2022,
            "rank_display": 328,
            "score": 32.7,
            "link": "https://www.topuniversities.com/universities/ln-gumilyov-eurasian-national-university-enu",
            "country": "Kazakhstan",
            "city": "Nur-Sultan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/l.n.-gumilyov-eurasian-national-university-enu_592560cf2aeae70239af52e8_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 1.077,
            "size": "L",
            "faculty_count": 3.35
        },
        {
            "university": "Linköping University",
            "year": 2022,
            "rank_display": 329,
            "score": 32.5,
            "link": "https://www.topuniversities.com/universities/linkoping-university",
            "country": "Sweden",
            "city": "Linköping",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/linkping-university_348_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.514,
            "size": "L",
            "faculty_count": 1.471
        },
        {
            "university": "City, University of London",
            "year": 2022,
            "rank_display": 330,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/city-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/city-university-of-london_354_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 7.362,
            "size": "L",
            "faculty_count": 970
        },
        {
            "university": "Technion - Israel Institute of Technology",
            "year": 2022,
            "rank_display": 330,
            "score": 32.4,
            "link": "https://www.topuniversities.com/universities/technion-israel-institute-technology",
            "country": "Israel",
            "city": "Haifa",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/technion-israel-institute-of-technology_600_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 526,
            "size": "L",
            "faculty_count": 1.616
        },
        {
            "university": "Birkbeck, University of London",
            "year": 2022,
            "rank_display": 332,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/birkbeck-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/birkbeck-university-of-london_1636_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 3.214,
            "size": "M",
            "faculty_count": 585
        },
        {
            "university": "Taylor's University",
            "year": 2022,
            "rank_display": 332,
            "score": 32.3,
            "link": "https://www.topuniversities.com/universities/taylors-university",
            "country": "Malaysia",
            "city": "Subang Jaya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taylors-university_592560cf2aeae70239af57af_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.704,
            "size": "S",
            "faculty_count": 578
        },
        {
            "university": "Huazhong University of Science and Technology",
            "year": 2022,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/huazhong-university-science-technology",
            "country": "China (Mainland)",
            "city": "Wuhan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/huazhong-university-of-science-and-technology_592560cf2aeae70239af5256_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.796,
            "size": "XL",
            "faculty_count": 4.014
        },
        {
            "university": "National Taiwan Normal University",
            "year": 2022,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/national-taiwan-normal-university",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taiwan-normal-university_440_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.499,
            "size": "M",
            "faculty_count": 1.374
        },
        {
            "university": "Politecnico di Torino",
            "year": 2022,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/politecnico-di-torino",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/211027114149am317273Poli-di-Torino-logo-200x2000-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 5.369,
            "size": "XL",
            "faculty_count": 1.05
        },
        {
            "university": "Royal Holloway University of London",
            "year": 2022,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/royal-holloway-university-london",
            "country": "United Kingdom",
            "city": "Egham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/royal-holloway-university-of-london_534_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.705,
            "size": "M",
            "faculty_count": 831
        },
        {
            "university": "Tianjin University",
            "year": 2022,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/tianjin-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tianjin-university_609_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.425,
            "size": "L",
            "faculty_count": 3.147
        },
        {
            "university": "University of Victoria (UVic)",
            "year": 2022,
            "rank_display": 334,
            "score": 32.2,
            "link": "https://www.topuniversities.com/universities/university-victoria-uvic",
            "country": "Canada",
            "city": "Victoria",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-victoria-uvic_592560cf2aeae70239af4d0e_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 6.135,
            "size": "L",
            "faculty_count": 865
        },
        {
            "university": "Goethe-University Frankfurt am Main",
            "year": 2022,
            "rank_display": 340,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/goethe-university-frankfurt-am-main",
            "country": "Germany",
            "city": "Frankfurt",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-frankfurt-am-main_214_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 6.749,
            "size": "XL",
            "faculty_count": 3.623
        },
        {
            "university": "University of Hawaiʻi at Mānoa",
            "year": 2022,
            "rank_display": 340,
            "score": 32.1,
            "link": "https://www.topuniversities.com/universities/university-hawaii-manoa",
            "country": "United States",
            "city": "Honolulu",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-hawaii-at-maoa_255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.586,
            "size": "L",
            "faculty_count": 1.64
        },
        {
            "university": "Northeastern University",
            "year": 2022,
            "rank_display": 342,
            "score": 31.9,
            "link": "https://www.topuniversities.com/universities/northeastern-university",
            "country": "United States",
            "city": "Boston",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/northeastern-university_454_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 7.595,
            "size": "L",
            "faculty_count": 1.723
        },
        {
            "university": "Hiroshima University",
            "year": 2022,
            "rank_display": 343,
            "score": 31.8,
            "link": "https://www.topuniversities.com/universities/hiroshima-university",
            "country": "Japan",
            "city": "Higashihiroshima City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hiroshima-university_264_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.68,
            "size": "L",
            "faculty_count": 2.366
        },
        {
            "university": "Universiti Teknologi Brunei",
            "year": 2022,
            "rank_display": 344,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-brunei",
            "country": "Brunei",
            "city": "Bandar Seri Begawan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-brunei_592560e49988f300e23219b2_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 139
        },
        {
            "university": "Universität Jena",
            "year": 2022,
            "rank_display": 344,
            "score": 31.7,
            "link": "https://www.topuniversities.com/universities/universitat-jena",
            "country": "Germany",
            "city": "Jena",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-jena_592560cf2aeae70239af4bb5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 2.307,
            "size": "L",
            "faculty_count": 3.974
        },
        {
            "university": "Virginia Polytechnic Institute and State University",
            "year": 2022,
            "rank_display": 346,
            "score": 31.6,
            "link": "https://www.topuniversities.com/universities/virginia-polytechnic-institute-state-university",
            "country": "United States",
            "city": "Blacksburg",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/virginia-polytechnic-institute-and-state-university_592560cf2aeae70239af4d15_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.477,
            "size": "XL",
            "faculty_count": 2.802
        },
        {
            "university": "Kazan (Volga region) Federal University",
            "year": 2022,
            "rank_display": 347,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/kazan-volga-region-federal-university",
            "country": "Russia",
            "city": "Kazan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/kazan-volga-region-federal-university_313_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 8.375,
            "size": "L",
            "faculty_count": 3.847
        },
        {
            "university": "UCSI University",
            "year": 2022,
            "rank_display": 347,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/ucsi-university",
            "country": "Malaysia",
            "city": "Kuala Lumpur",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ucsi-university_592560e09988f300e2320ce5_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 8,
            "international_students": 1.597,
            "size": "M",
            "faculty_count": 744
        },
        {
            "university": "University of California, Santa Cruz",
            "year": 2022,
            "rank_display": 347,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/university-california-santa-cruz",
            "country": "United States",
            "city": "Santa Cruz",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-santa-cruz_92_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 2.13,
            "size": "L",
            "faculty_count": 1.045
        },
        {
            "university": "Universität Stuttgart",
            "year": 2022,
            "rank_display": 347,
            "score": 31.4,
            "link": "https://www.topuniversities.com/universities/universitat-stuttgart",
            "country": "Germany",
            "city": "Stuttgart",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-stuttgart_580_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.028,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "Brunel University London",
            "year": 2022,
            "rank_display": 351,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/brunel-university-london",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/brunel-university-london_592560cf2aeae70239af4acc_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 5.305,
            "size": "L",
            "faculty_count": 860
        },
        {
            "university": "Universidad Carlos III de Madrid (UC3M)",
            "year": 2022,
            "rank_display": 351,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/universidad-carlos-iii-de-madrid-uc3m",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-carlos-iii-de-madrid-uc3m_1509_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.11,
            "size": "L",
            "faculty_count": 2.119
        },
        {
            "university": "Ural Federal University - UrFU",
            "year": 2022,
            "rank_display": 351,
            "score": 31.3,
            "link": "https://www.topuniversities.com/universities/ural-federal-university-urfu",
            "country": "Russia",
            "city": "Ekaterinburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ural-federal-university-urfu_592560cf2aeae70239af505a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 5.089,
            "size": "L",
            "faculty_count": 5.009
        },
        {
            "university": "Johannes Kepler University Linz",
            "year": 2022,
            "rank_display": 354,
            "score": 31.2,
            "link": "https://www.topuniversities.com/universities/johannes-kepler-university-linz",
            "country": "Austria",
            "city": "Linz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-kepler-university-linz_349_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 774,
            "size": "M",
            "faculty_count": 897
        },
        {
            "university": "George Washington University",
            "year": 2022,
            "rank_display": 355,
            "score": 31.1,
            "link": "https://www.topuniversities.com/universities/george-washington-university",
            "country": "United States",
            "city": "Ashburn",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/george-washington-university_223_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 4.024,
            "size": "L",
            "faculty_count": 2.016
        },
        {
            "university": "Tilburg University",
            "year": 2022,
            "rank_display": 356,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/tilburg-university",
            "country": "Netherlands",
            "city": "Tilburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tilburg-university_592560cf2aeae70239af4ce5_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.189,
            "size": "M",
            "faculty_count": 745
        },
        {
            "university": "University of Lisbon",
            "year": 2022,
            "rank_display": 356,
            "score": 31,
            "link": "https://www.topuniversities.com/universities/university-lisbon",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-lisbon_1475_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 8.546,
            "size": "XL",
            "faculty_count": 3.389
        },
        {
            "university": "Nankai University",
            "year": 2022,
            "rank_display": 358,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/nankai-university",
            "country": "China (Mainland)",
            "city": "Tianjin",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/nankai-university_882_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.488,
            "size": "XL",
            "faculty_count": 2.846
        },
        {
            "university": "National University of Sciences And Technology (NUST) Islamabad",
            "year": 2022,
            "rank_display": 358,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/national-university-sciences-technology-nust-islamabad",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-university-of-sciences-and-technology-nust-islamabad_698_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 563,
            "size": "M",
            "faculty_count": 1.608
        },
        {
            "university": "University of Jyväskylä",
            "year": 2022,
            "rank_display": 358,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/university-jyvaskyla",
            "country": "Finland",
            "city": "Jyväskylä",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-jyvskyl_306_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 339,
            "size": "M",
            "faculty_count": 1.573
        },
        {
            "university": "University of Utah",
            "year": 2022,
            "rank_display": 358,
            "score": 30.9,
            "link": "https://www.topuniversities.com/universities/university-utah",
            "country": "United States",
            "city": "Salt Lake City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-utah_645_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.134,
            "size": "L",
            "faculty_count": 3.345
        },
        {
            "university": "Ewha Womans University",
            "year": 2022,
            "rank_display": 362,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/ewha-womans-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ewha-womans-university_592560cf2aeae70239af4b38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.105,
            "size": "L",
            "faculty_count": 2.436
        },
        {
            "university": "La Trobe University",
            "year": 2022,
            "rank_display": 362,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/la-trobe-university",
            "country": "Australia",
            "city": "Melbourne",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/la-trobe-university_592560cf2aeae70239af4bd1_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 9.456,
            "size": "L",
            "faculty_count": 1.252
        },
        {
            "university": "MGIMO School of Business and International Proficiency",
            "year": 2022,
            "rank_display": 362,
            "score": 30.8,
            "link": "https://www.topuniversities.com/universities/mgimo-school-business-international-proficiency",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/moscow-state-institute-of-international-relations-mgimo-university_2156_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 1.517,
            "size": "M",
            "faculty_count": 1.739
        },
        {
            "university": "ITMO University",
            "year": 2022,
            "rank_display": 365,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/itmo-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/itmo-university_592560cf2aeae70239af5829_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.337,
            "size": "M",
            "faculty_count": 1.849
        },
        {
            "university": "Umea University",
            "year": 2022,
            "rank_display": 365,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/umea-university",
            "country": "Sweden",
            "city": "Umeå",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/logo_Umu-48x48.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.186,
            "size": "L",
            "faculty_count": 1.966
        },
        {
            "university": "Ulm University",
            "year": 2022,
            "rank_display": 365,
            "score": 30.5,
            "link": "https://www.topuniversities.com/universities/ulm-university",
            "country": "Germany",
            "city": "Ulm",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-ulm_638_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 1.289,
            "size": "M",
            "faculty_count": 1.473
        },
        {
            "university": "Sultan Qaboos University",
            "year": 2022,
            "rank_display": 368,
            "score": 30.4,
            "link": "https://www.topuniversities.com/universities/sultan-qaboos-university",
            "country": "Oman",
            "city": "Muscat",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sultan-qaboos-university_2530_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 248,
            "size": "M",
            "faculty_count": 916
        },
        {
            "university": "Norwegian University of Science And Technology",
            "year": 2022,
            "rank_display": 369,
            "score": 30.3,
            "link": "https://www.topuniversities.com/universities/norwegian-university-science-technology",
            "country": "Norway",
            "city": "Trondheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/norwegian-university-of-science-and-technology_457_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 2.95,
            "size": "XL",
            "faculty_count": 3.132
        },
        {
            "university": "Universidade Federal do Rio de Janeiro",
            "year": 2022,
            "rank_display": 369,
            "score": 30.3,
            "link": "https://www.topuniversities.com/universities/universidade-federal-do-rio-de-janeiro",
            "country": "Brazil",
            "city": "Rio de Janeiro",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-do-rio-de-janeiro_525_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 555,
            "size": "XL",
            "faculty_count": 3.983
        },
        {
            "university": "Universitat Politècnica de València",
            "year": 2022,
            "rank_display": 371,
            "score": 30,
            "link": "https://www.topuniversities.com/universities/universitat-politecnica-de-valencia",
            "country": "Spain",
            "city": "València",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitat-politcnica-de-valncia_648_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 2.589,
            "size": "L",
            "faculty_count": 2.645
        },
        {
            "university": "Lincoln University",
            "year": 2022,
            "rank_display": 372,
            "score": 29.8,
            "link": "https://www.topuniversities.com/universities/lincoln-university",
            "country": "New Zealand",
            "city": "Lincoln",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/lincoln-university-_592560cf2aeae70239af4ef3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 898,
            "size": "S",
            "faculty_count": 643
        },
        {
            "university": "Beijing Institute of Technology",
            "year": 2022,
            "rank_display": 373,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/beijing-institute-technology",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beijing-institute-of-technology_868_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.115,
            "size": "XL",
            "faculty_count": 2.811
        },
        {
            "university": "University of Chemistry and Technology, Prague",
            "year": 2022,
            "rank_display": 373,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/university-chemistry-technology-prague",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-chemistry-and-technology-prague_592560cf2aeae70239af4f53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 864,
            "size": "S",
            "faculty_count": 903
        },
        {
            "university": "University of Connecticut",
            "year": 2022,
            "rank_display": 373,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/university-connecticut",
            "country": "United States",
            "city": "Storrs",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-connecticut_140_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 3.765,
            "size": "L",
            "faculty_count": 1.328
        },
        {
            "university": "University of Waikato",
            "year": 2022,
            "rank_display": 373,
            "score": 29.6,
            "link": "https://www.topuniversities.com/universities/university-waikato",
            "country": "New Zealand",
            "city": "Hamilton",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-waikato_660_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.27,
            "size": "M",
            "faculty_count": 595
        },
        {
            "university": "University of Oulu",
            "year": 2022,
            "rank_display": 377,
            "score": 29.5,
            "link": "https://www.topuniversities.com/universities/university-oulu",
            "country": "Finland",
            "city": "Oulu",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-oulu_476_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 579,
            "size": "M",
            "faculty_count": 883
        },
        {
            "university": "Julius-Maximilians-Universität Würzburg",
            "year": 2022,
            "rank_display": 378,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/julius-maximilians-universitat-wurzburg",
            "country": "Germany",
            "city": "Würzburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/julius-maximilians-universitt-wrzburg_592560cf2aeae70239af4d2d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 24,
            "international_students": 1.951,
            "size": "L",
            "faculty_count": 953
        },
        {
            "university": "Quaid-i-Azam University",
            "year": 2022,
            "rank_display": 378,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/quaid-i-azam-university",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/quaid-i-azam-university_592560cf2aeae70239af4d41_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 33,
            "international_students": 115,
            "size": "L",
            "faculty_count": 452
        },
        {
            "university": "Stony Brook University, State University of New York",
            "year": 2022,
            "rank_display": 378,
            "score": 29.4,
            "link": "https://www.topuniversities.com/universities/stony-brook-university-state-university-new-york",
            "country": "United States",
            "city": "Stony Brook",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/stony-brook-university-state-university-of-new-york_592560cf2aeae70239af4ccb_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 6.198,
            "size": "L",
            "faculty_count": 2.316
        },
        {
            "university": "Sharif University of Technology",
            "year": 2022,
            "rank_display": 381,
            "score": 29.3,
            "link": "https://www.topuniversities.com/universities/sharif-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sharif-university-of-technology_592560cf2aeae70239af4d42_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 198,
            "size": "M",
            "faculty_count": 495
        },
        {
            "university": "Tokyo Medical and Dental University (TMDU)",
            "year": 2022,
            "rank_display": 381,
            "score": 29.3,
            "link": "https://www.topuniversities.com/universities/tokyo-medical-dental-university-tmdu",
            "country": "Japan",
            "city": "Tokyo",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/tokyo-medical-and-dental-university-tmdu_592560cf2aeae70239af4e8a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 325,
            "size": "S",
            "faculty_count": 930
        },
        {
            "university": "American University of Sharjah",
            "year": 2022,
            "rank_display": 383,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/american-university-sharjah",
            "country": "United Arab Emirates",
            "city": "Sharjah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/american-university-of-sharjah_1201_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.199,
            "size": "S",
            "faculty_count": 371
        },
        {
            "university": "Beihang University (former BUAA)",
            "year": 2022,
            "rank_display": 383,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/beihang-university-former-buaa",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/beihang-university-former-buaa_870_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.575,
            "size": "XL",
            "faculty_count": 2.529
        },
        {
            "university": "University of Kent",
            "year": 2022,
            "rank_display": 383,
            "score": 29.1,
            "link": "https://www.topuniversities.com/universities/university-kent",
            "country": "United Kingdom",
            "city": "Canterbury",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kent_315_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.515,
            "size": "L",
            "faculty_count": 4000
        },
        {
            "university": "Kobe University",
            "year": 2022,
            "rank_display": 386,
            "score": 28.9,
            "link": "https://www.topuniversities.com/universities/kobe-university",
            "country": "Japan",
            "city": "Kobe City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/kobe-university_320_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.216,
            "size": "L",
            "faculty_count": 1.986
        },
        {
            "university": "University of Kansas",
            "year": 2022,
            "rank_display": 387,
            "score": 28.8,
            "link": "https://www.topuniversities.com/universities/university-kansas",
            "country": "United States",
            "city": "Lawrence",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-kansas_309_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 2.014,
            "size": "L",
            "faculty_count": 3.357
        },
        {
            "university": "University at Buffalo SUNY",
            "year": 2022,
            "rank_display": 388,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/university-buffalo-suny",
            "country": "United States",
            "city": "Buffalo",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-at-buffalo-suny_1888_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 4.409,
            "size": "L",
            "faculty_count": 1.53
        },
        {
            "university": "University of Pisa",
            "year": 2022,
            "rank_display": 388,
            "score": 28.7,
            "link": "https://www.topuniversities.com/universities/university-pisa",
            "country": "Italy",
            "city": "Pisa",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-pisa_499_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 1.507,
            "size": "XL",
            "faculty_count": 1.796
        },
        {
            "university": "Università Vita-Salute San Raffaele",
            "year": 2022,
            "rank_display": 390,
            "score": 28.6,
            "link": "https://www.topuniversities.com/universities/universita-vita-salute-san-raffaele",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-vita-salute-san-raffaele_592560cf2aeae70239af5016_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 94,
            "size": "S",
            "faculty_count": 451
        },
        {
            "university": "SOAS University of London",
            "year": 2022,
            "rank_display": 391,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/soas-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/soas-university-of-london-_592560cf2aeae70239af4bf0_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 2.398,
            "size": "S",
            "faculty_count": 300
        },
        {
            "university": "Universidad de Palermo (UP)",
            "year": 2022,
            "rank_display": 391,
            "score": 28.5,
            "link": "https://www.topuniversities.com/universities/universidad-de-palermo",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-palermo-up_592560cf2aeae70239af54de_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.049,
            "size": "M",
            "faculty_count": 833
        },
        {
            "university": "Peter the Great St. Petersburg Polytechnic University",
            "year": 2022,
            "rank_display": 393,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/peter-great-st-petersburg-polytechnic-university",
            "country": "Russia",
            "city": "St. Petersburg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/peter-the-great-st.-petersburg-polytechnic-university_592560cf2aeae70239af52ed_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 5.121,
            "size": "L",
            "faculty_count": 2.355
        },
        {
            "university": "Ruhr-Universität Bochum",
            "year": 2022,
            "rank_display": 393,
            "score": 28.4,
            "link": "https://www.topuniversities.com/universities/ruhr-universitat-bochum",
            "country": "Germany",
            "city": "Bochum",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/ruhr-universitt-bochum_537_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 35,
            "international_students": 6.564,
            "size": "XL",
            "faculty_count": 1.232
        },
        {
            "university": "Indian Institute of Technology Guwahati (IITG)",
            "year": 2022,
            "rank_display": 395,
            "score": 28.3,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-guwahati-iitg",
            "country": "India",
            "city": "Guwahati",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-guwahati-iitg_283_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 42,
            "size": "M",
            "faculty_count": 410
        },
        {
            "university": "National Research Tomsk Polytechnic University",
            "year": 2022,
            "rank_display": 395,
            "score": 28.3,
            "link": "https://www.topuniversities.com/universities/national-research-tomsk-polytechnic-university",
            "country": "Russia",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-research-tomsk-polytechnic-university_14158_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 2.757,
            "size": "M",
            "faculty_count": 1.55
        },
        {
            "university": "Pontificia Universidad Catolica del Peru",
            "year": 2022,
            "rank_display": 395,
            "score": 28.3,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-catolica-del-peru",
            "country": "Peru",
            "city": "Lima",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/210825042529am231772Isotipo-PUCP-200x200-48x48.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 292,
            "size": "L",
            "faculty_count": 2.369
        },
        {
            "university": "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
            "year": 2022,
            "rank_display": 398,
            "score": 28.2,
            "link": "https://www.topuniversities.com/universities/pakistan-institute-engineering-applied-sciences-pieas",
            "country": "Pakistan",
            "city": "Islamabad",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/pakistan-institute-of-engineering-and-applied-sciences-pieas_592560cf2aeae70239af532f_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": "",
            "size": "S",
            "faculty_count": 180
        },
        {
            "university": "University of the Philippines",
            "year": 2022,
            "rank_display": 399,
            "score": 28.1,
            "link": "https://www.topuniversities.com/universities/university-philippines",
            "country": "Philippines",
            "city": "Quezon City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-philippines_498_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 292,
            "size": "XL",
            "faculty_count": 4.48
        },
        {
            "university": "Indian Institute of Technology Roorkee (IITR)",
            "year": 2022,
            "rank_display": 400,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/indian-institute-technology-roorkee-iitr",
            "country": "India",
            "city": "Roorkee",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/indian-institute-of-technology-roorkee-iitr_287_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 202,
            "size": "M",
            "faculty_count": 499
        },
        {
            "university": "Universidad Austral",
            "year": 2022,
            "rank_display": 400,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/universidad-austral",
            "country": "Argentina",
            "city": "Pilar",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-austral_38_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 334,
            "size": "S",
            "faculty_count": 663
        },
        {
            "university": "Vilnius University",
            "year": 2022,
            "rank_display": 400,
            "score": 28,
            "link": "https://www.topuniversities.com/universities/vilnius-university",
            "country": "Lithuania",
            "city": "Vilnius",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/vilnius-university-_592560cf2aeae70239af4d7f_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.29,
            "size": "L",
            "faculty_count": 2.461
        },
        {
            "university": "Czech Technical University in Prague",
            "year": 2022,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/czech-technical-university-prague",
            "country": "Czech Republic",
            "city": "Prague",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/czech-technical-university-in-prague_592560cf2aeae70239af4d3b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 3.006,
            "size": "L",
            "faculty_count": 2.628
        },
        {
            "university": "Shandong University",
            "year": 2022,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/shandong-university",
            "country": "China (Mainland)",
            "city": "Jinan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shandong-university_555_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.637,
            "size": "XL",
            "faculty_count": 3.406
        },
        {
            "university": "University of California, Riverside",
            "year": 2022,
            "rank_display": 403,
            "score": 27.9,
            "link": "https://www.topuniversities.com/universities/university-california-riverside",
            "country": "United States",
            "city": "Riverside",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-california-riverside_88_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.034,
            "size": "L",
            "faculty_count": 1.497
        },
        {
            "university": "Flinders University",
            "year": 2022,
            "rank_display": 407,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/flinders-university",
            "country": "Australia",
            "city": "Adelaide",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/flinders-university_592560cf2aeae70239af4b53_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 5.981,
            "size": "L",
            "faculty_count": 932
        },
        {
            "university": "South China University of Technology",
            "year": 2022,
            "rank_display": 407,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/south-china-university-technology",
            "country": "China (Mainland)",
            "city": "Guangzhou",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/south-china-university-of-technology_887_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.688,
            "size": "XL",
            "faculty_count": 3.006
        },
        {
            "university": "Taipei Medical University (TMU)",
            "year": 2022,
            "rank_display": 407,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/taipei-medical-university-tmu",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/taipei-medical-university_2134_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 614,
            "size": "M",
            "faculty_count": 999
        },
        {
            "university": "Xiamen University",
            "year": 2022,
            "rank_display": 407,
            "score": 27.8,
            "link": "https://www.topuniversities.com/universities/xiamen-university",
            "country": "China (Mainland)",
            "city": "Xiamen",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/xiamen-university_891_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.443,
            "size": "XL",
            "faculty_count": 3.106
        },
        {
            "university": "Westfälische Wilhelms-Universität Münster",
            "year": 2022,
            "rank_display": 411,
            "score": 27.7,
            "link": "https://www.topuniversities.com/universities/westfalische-wilhelms-universitat-munster",
            "country": "Germany",
            "city": "Münster",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/westflische-wilhelms-universitt-mnster_421_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.845,
            "size": "XL",
            "faculty_count": 4.23
        },
        {
            "university": "National Sun Yat-sen University",
            "year": 2022,
            "rank_display": 412,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/national-sun-yat-sen-university",
            "country": "Taiwan",
            "city": "Kaohsiung City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-sun-yat-sen-university_439_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 757,
            "size": "M",
            "faculty_count": 735
        },
        {
            "university": "Pontificia Universidad Javeriana",
            "year": 2022,
            "rank_display": 412,
            "score": 27.5,
            "link": "https://www.topuniversities.com/universities/pontificia-universidad-javeriana",
            "country": "Colombia",
            "city": "Bogotá",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/pontificia-universidad-javeriana_592560cf2aeae70239af514a_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 14,
            "international_students": 415,
            "size": "L",
            "faculty_count": 2.082
        },
        {
            "university": "Chung-Ang University (CAU)",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/chung-ang-university-cau",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chung-ang-university-cau_1042_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 3.099,
            "size": "L",
            "faculty_count": 2.65
        },
        {
            "university": "Lappeenranta-Lahti University of Technology LUT",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/lappeenranta-lahti-university-technology-lut",
            "country": "Finland",
            "city": "Lappeenranta",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/lappeenranta-university-of-technology_592560cf2aeae70239af4f7d_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 399,
            "size": "S",
            "faculty_count": 298
        },
        {
            "university": "Tampere University",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/tampere-university",
            "country": "Finland",
            "city": "Tampere",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/tampere-university_5bbf14847d023f5bc849ec9a_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 996,
            "size": "L",
            "faculty_count": 1.31
        },
        {
            "university": "Universiti Teknologi PETRONAS (UTP)",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/universiti-teknologi-petronas-utp",
            "country": "Malaysia",
            "city": "Seri Iskandar",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/universiti-teknologi-petronas-utp_592560cf2aeae70239af4ef2_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 639,
            "size": "M",
            "faculty_count": 438
        },
        {
            "university": "University of Colorado, Denver",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/university-colorado-denver1",
            "country": "United States",
            "city": "Denver",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-colorado-denver_2116_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 3,
            "international_students": 1.274,
            "size": "L",
            "faculty_count": 5.319
        },
        {
            "university": "University of Science and Technology Beijing",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/university-science-technology-beijing",
            "country": "China (Mainland)",
            "city": "Beijing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-science-and-technology-beijing_2004_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 818,
            "size": "L",
            "faculty_count": 1.605
        },
        {
            "university": "Université Laval",
            "year": 2022,
            "rank_display": 414,
            "score": 27.4,
            "link": "https://www.topuniversities.com/universities/universite-laval",
            "country": "Canada",
            "city": "Québec",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/laval-university_337_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 3.673,
            "size": "L",
            "faculty_count": 2.29
        },
        {
            "university": "National Technical University of Athens",
            "year": 2022,
            "rank_display": 421,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/national-technical-university-athens",
            "country": "Greece",
            "city": "Athens",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/national-technical-university-of-athens_592560cf2aeae70239af4aa3_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 846,
            "size": "L",
            "faculty_count": 689
        },
        {
            "university": "Université de Strasbourg",
            "year": 2022,
            "rank_display": 421,
            "score": 27.3,
            "link": "https://www.topuniversities.com/universities/universite-de-strasbourg",
            "country": "France",
            "city": "Strasbourg",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-strasbourg_578_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 9.111,
            "size": "XL",
            "faculty_count": 2.544
        },
        {
            "university": "Universität Mannheim",
            "year": 2022,
            "rank_display": 423,
            "score": 27.2,
            "link": "https://www.topuniversities.com/universities/universitat-mannheim",
            "country": "Germany",
            "city": "Mannheim",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-mannheim_592560cf2aeae70239af4c0b_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 32,
            "international_students": 1.29,
            "size": "M",
            "faculty_count": 307
        },
        {
            "university": "James Cook University",
            "year": 2022,
            "rank_display": 424,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/james-cook-university",
            "country": "Australia",
            "city": "Townsville",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/james-cook-university_833_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.169,
            "size": "M",
            "faculty_count": 2940
        },
        {
            "university": "University of Naples - Federico II",
            "year": 2022,
            "rank_display": 424,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/university-naples-federico-ii",
            "country": "Italy",
            "city": "Naples",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-naples-federico-ii_432_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 128,
            "size": "XL",
            "faculty_count": 2.299
        },
        {
            "university": "University of Witwatersrand",
            "year": 2022,
            "rank_display": 424,
            "score": 27.1,
            "link": "https://www.topuniversities.com/universities/university-witwatersrand",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-the-witwatersrand_679_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.885,
            "size": "L",
            "faculty_count": 1.626
        },
        {
            "university": "Johannes Gutenberg Universität Mainz",
            "year": 2022,
            "rank_display": 427,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/johannes-gutenberg-universitat-mainz",
            "country": "Germany",
            "city": "Mainz",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/johannes-gutenberg-universitt-mainz_382_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 3.605,
            "size": "XL",
            "faculty_count": 3.473
        },
        {
            "university": "Washington State University",
            "year": 2022,
            "rank_display": 427,
            "score": 27,
            "link": "https://www.topuniversities.com/universities/washington-state-university",
            "country": "United States",
            "city": "Pullman",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/washington-state-university_666_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.731,
            "size": "L",
            "faculty_count": 1.935
        },
        {
            "university": "Oxford Brookes University",
            "year": 2022,
            "rank_display": 429,
            "score": 26.9,
            "link": "https://www.topuniversities.com/universities/oxford-brookes-university",
            "country": "United Kingdom",
            "city": "",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/oxford-brookes-university_592560cf2aeae70239af4c61_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 15,
            "international_students": 3.439,
            "size": "L",
            "faculty_count": 1471
        },
        {
            "university": "Wake Forest University",
            "year": 2022,
            "rank_display": 429,
            "score": 26.9,
            "link": "https://www.topuniversities.com/universities/wake-forest-university",
            "country": "United States",
            "city": "Winston-Salem",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/wake-forest-university_661_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 4,
            "international_students": 805,
            "size": "M",
            "faculty_count": 2.276
        },
        {
            "university": "Colorado State University",
            "year": 2022,
            "rank_display": 431,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/colorado-state-university",
            "country": "United States",
            "city": "Fort Collins",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/colorado-state-university_136_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.547,
            "size": "L",
            "faculty_count": 3.115
        },
        {
            "university": "Rensselaer Polytechnic Institute",
            "year": 2022,
            "rank_display": 431,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/rensselaer-polytechnic-institute",
            "country": "United States",
            "city": "Troy",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/rensselaer-polytechnic-institute_522_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 1.594,
            "size": "M",
            "faculty_count": 639
        },
        {
            "university": "Universidade Nova de Lisboa",
            "year": 2022,
            "rank_display": 431,
            "score": 26.8,
            "link": "https://www.topuniversities.com/universities/universidade-nova-de-lisboa",
            "country": "Portugal",
            "city": "Lisbon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-nova-de-lisboa_460_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 4.427,
            "size": "L",
            "faculty_count": 1.72
        },
        {
            "university": "Universidade Federal de São Paulo",
            "year": 2022,
            "rank_display": 434,
            "score": 26.7,
            "link": "https://www.topuniversities.com/universities/universidade-federal-de-sao-paulo",
            "country": "Brazil",
            "city": "São Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidade-federal-de-so-paulo_2750_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 153,
            "size": "L",
            "faculty_count": 2.451
        },
        {
            "university": "University of Johannesburg",
            "year": 2022,
            "rank_display": 434,
            "score": 26.7,
            "link": "https://www.topuniversities.com/universities/university-johannesburg",
            "country": "South Africa",
            "city": "Johannesburg",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-johannesburg-_592560cf2aeae70239af4f46_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 4.016,
            "size": "L",
            "faculty_count": 1.886
        },
        {
            "university": "Shanghai University",
            "year": 2022,
            "rank_display": 436,
            "score": 26.6,
            "link": "https://www.topuniversities.com/universities/shanghai-university",
            "country": "China (Mainland)",
            "city": "Shanghai",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/shanghai-university_557_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.195,
            "size": "XL",
            "faculty_count": 4.156
        },
        {
            "university": "Tulane University",
            "year": 2022,
            "rank_display": 436,
            "score": 26.6,
            "link": "https://www.topuniversities.com/universities/tulane-university",
            "country": "United States",
            "city": "New Orleans",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/tulane-university_631_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 1.172,
            "size": "M",
            "faculty_count": 1.733
        },
        {
            "university": "University of Canberra",
            "year": 2022,
            "rank_display": 436,
            "score": 26.6,
            "link": "https://www.topuniversities.com/universities/university-canberra",
            "country": "Australia",
            "city": "Bruce",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-canberra_844_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 27,
            "international_students": 3.132,
            "size": "M",
            "faculty_count": 395
        },
        {
            "university": "Essex, University of",
            "year": 2022,
            "rank_display": 439,
            "score": 26.5,
            "link": "https://www.topuniversities.com/universities/essex-university",
            "country": "United Kingdom",
            "city": "Colchester",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-essex_592560cf2aeae70239af4b4c_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 6.124,
            "size": "L",
            "faculty_count": 1.044
        },
        {
            "university": "Swansea University",
            "year": 2022,
            "rank_display": 440,
            "score": 26.3,
            "link": "https://www.topuniversities.com/universities/swansea-university",
            "country": "United Kingdom",
            "city": "Swansea",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/swansea-university_592560cf2aeae70239af4cce_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 3.641,
            "size": "L",
            "faculty_count": 3290
        },
        {
            "university": "University of Cyprus (UCY)",
            "year": 2022,
            "rank_display": 440,
            "score": 26.3,
            "link": "https://www.topuniversities.com/universities/university-cyprus-ucy",
            "country": "Cyprus",
            "city": "Nicosia",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-cyprus-ucy_14238_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 21,
            "international_students": 874,
            "size": "M",
            "faculty_count": 318
        },
        {
            "university": "University of Trento",
            "year": 2022,
            "rank_display": 440,
            "score": 26.3,
            "link": "https://www.topuniversities.com/universities/university-trento",
            "country": "Italy",
            "city": "Trento",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-trento_623_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 738,
            "size": "L",
            "faculty_count": 1.066
        },
        {
            "university": "University of Tromsø The Arctic University of Norway",
            "year": 2022,
            "rank_display": 440,
            "score": 26.3,
            "link": "https://www.topuniversities.com/universities/university-tromso-arctic-university-norway",
            "country": "Norway",
            "city": "Tromsø",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-troms-the-arctic-university-of-norway_625_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.134,
            "size": "L",
            "faculty_count": 2.107
        },
        {
            "university": "Illinois Institute of Technology",
            "year": 2022,
            "rank_display": 444,
            "score": 26.2,
            "link": "https://www.topuniversities.com/universities/illinois-institute-technology",
            "country": "United States",
            "city": "Chicago",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/illinois-institute-of-technology_277_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 2.33,
            "size": "M",
            "faculty_count": 402
        },
        {
            "university": "HUFS - Hankuk (Korea) University of Foreign Studies",
            "year": 2022,
            "rank_display": 445,
            "score": 26.1,
            "link": "https://www.topuniversities.com/universities/hufs-hankuk-korea-university-foreign-studies",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/hufs-hankuk-korea-university-of-foreign-studies_1054_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.933,
            "size": "L",
            "faculty_count": 2.353
        },
        {
            "university": "The American University in Cairo",
            "year": 2022,
            "rank_display": 445,
            "score": 26.1,
            "link": "https://www.topuniversities.com/universities/american-university-cairo",
            "country": "Egypt",
            "city": "Cairo",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/the-american-university-in-cairo_36_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 213,
            "size": "M",
            "faculty_count": 578
        },
        {
            "university": "Umm Al-Qura University",
            "year": 2022,
            "rank_display": 447,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/umm-al-qura-university",
            "country": "Saudi Arabia",
            "city": "Makkah",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/umm-al-qura-university-_592560cf2aeae70239af4d44_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 8,
            "international_students": 3.566,
            "size": "XL",
            "faculty_count": 3.619
        },
        {
            "university": "Universität  Leipzig",
            "year": 2022,
            "rank_display": 447,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/universitat-leipzig",
            "country": "Germany",
            "city": "Leipzig",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-leipzig_341_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 3.174,
            "size": "L",
            "faculty_count": 4.119
        },
        {
            "university": "Universität des Saarlandes",
            "year": 2022,
            "rank_display": 447,
            "score": 26,
            "link": "https://www.topuniversities.com/universities/universitat-des-saarlandes",
            "country": "Germany",
            "city": "Saarbrücken",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universitt-des-saarlandes_541_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 2.939,
            "size": "L",
            "faculty_count": 2.823
        },
        {
            "university": "University of Milano-Bicocca",
            "year": 2022,
            "rank_display": 450,
            "score": 25.9,
            "link": "https://www.topuniversities.com/universities/university-milano-bicocca",
            "country": "Italy",
            "city": "Milan",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-milano-bicocca_14336_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 42,
            "international_students": 2.153,
            "size": "XL",
            "faculty_count": 784
        },
        {
            "university": "Auckland University of Technology (AUT)",
            "year": 2022,
            "rank_display": 451,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/auckland-university-technology-aut",
            "country": "New Zealand",
            "city": "Auckland",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/auckland-university-of-technology-aut_592560cf2aeae70239af4ef7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 8.926,
            "size": "L",
            "faculty_count": 1255
        },
        {
            "university": "Bond University",
            "year": 2022,
            "rank_display": 451,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/bond-university",
            "country": "Australia",
            "city": "Gold Coast",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/bond-university_715_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 10,
            "international_students": 1.302,
            "size": "S",
            "faculty_count": 325
        },
        {
            "university": "Sichuan University",
            "year": 2022,
            "rank_display": 451,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/sichuan-university",
            "country": "China (Mainland)",
            "city": "Chengdu",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sichuan-university_885_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 2.714,
            "size": "XL",
            "faculty_count": 3.173
        },
        {
            "university": "University of Florence",
            "year": 2022,
            "rank_display": 451,
            "score": 25.8,
            "link": "https://www.topuniversities.com/universities/university-florence",
            "country": "Italy",
            "city": "Florence",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-florence_208_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 4.203,
            "size": "XL",
            "faculty_count": 1.775
        },
        {
            "university": "Brandeis University",
            "year": 2022,
            "rank_display": 455,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/brandeis-university",
            "country": "United States",
            "city": "Waltham",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/brandeis-university_66_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.554,
            "size": "M",
            "faculty_count": 611
        },
        {
            "university": "University of Coimbra",
            "year": 2022,
            "rank_display": 455,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/university-coimbra",
            "country": "Portugal",
            "city": "Coimbra",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-coimbra_592560cf2aeae70239af4b07_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.477,
            "size": "L",
            "faculty_count": 1.74
        },
        {
            "university": "University of Iowa",
            "year": 2022,
            "rank_display": 455,
            "score": 25.7,
            "link": "https://www.topuniversities.com/universities/university-iowa",
            "country": "United States",
            "city": "Iowa City",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-iowa_592560cf2aeae70239af4baa_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 2.049,
            "size": "L",
            "faculty_count": 2.924
        },
        {
            "university": "University of Saskatchewan",
            "year": 2022,
            "rank_display": 458,
            "score": 25.6,
            "link": "https://www.topuniversities.com/universities/university-saskatchewan",
            "country": "Canada",
            "city": "Saskatoon",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-saskatchewan_1755_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 12,
            "international_students": 3.382,
            "size": "L",
            "faculty_count": 1.415
        },
        {
            "university": "Universidad Politécnica de Madrid (UPM)",
            "year": 2022,
            "rank_display": 459,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/universidad-politecnica-de-madrid",
            "country": "Spain",
            "city": "Madrid",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/politcnica-de-madrid_1507_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 5.319,
            "size": "XL",
            "faculty_count": 2.486
        },
        {
            "university": "University of St.Gallen (HSG)",
            "year": 2022,
            "rank_display": 459,
            "score": 25.5,
            "link": "https://www.topuniversities.com/universities/university-stgallen-hsg",
            "country": "Switzerland",
            "city": "St. Gallen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-st.gallen-hsg_571_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 20,
            "international_students": 2.39,
            "size": "M",
            "faculty_count": 444
        },
        {
            "university": "Colorado School of Mines",
            "year": 2022,
            "rank_display": 461,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/colorado-school-mines",
            "country": "United States",
            "city": "Golden",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/colorado-school-of-mines_592560de9988f300e2320883_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 627,
            "size": "M",
            "faculty_count": 544
        },
        {
            "university": "Far Eastern Federal University",
            "year": 2022,
            "rank_display": 461,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/far-eastern-federal-university",
            "country": "Russia",
            "city": "Vladivostok",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/far-eastern-federal-university_592560cf2aeae70239af57c9_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 3.262,
            "size": "L",
            "faculty_count": 2.268
        },
        {
            "university": "Goldsmiths, University of London",
            "year": 2022,
            "rank_display": 461,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/goldsmiths-university-london",
            "country": "United Kingdom",
            "city": "London",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/goldsmiths-university-of-london_355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 3.492,
            "size": "M",
            "faculty_count": 683
        },
        {
            "university": "Martin-Luther-Universität Halle-Wittenberg",
            "year": 2022,
            "rank_display": 461,
            "score": 25.4,
            "link": "https://www.topuniversities.com/universities/martin-luther-universitat-halle-wittenberg",
            "country": "Germany",
            "city": "Halle",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/martin-luther-universitt-halle-wittenberg_245_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 1.682,
            "size": "L",
            "faculty_count": 2.942
        },
        {
            "university": "Airlangga University",
            "year": 2022,
            "rank_display": 465,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/airlangga-university",
            "country": "Indonesia",
            "city": "Surabaya",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/logo-UNAIR-200px-x-200px-01-48x48.jpeg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 9,
            "international_students": 495,
            "size": "L",
            "faculty_count": 2.983
        },
        {
            "university": "Amirkabir University of Technology",
            "year": 2022,
            "rank_display": 465,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/amirkabir-university-technology",
            "country": "Iran, Islamic Republic of",
            "city": "Tehran",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/amirkabir-university-of-technology_592560cf2aeae70239af4e54_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 235,
            "size": "M",
            "faculty_count": 525
        },
        {
            "university": "Southeast University",
            "year": 2022,
            "rank_display": 465,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/southeast-university",
            "country": "China (Mainland)",
            "city": "Nanjing",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/southeast-university_568_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.5,
            "size": "XL",
            "faculty_count": 3.409
        },
        {
            "university": "Universidad de Belgrano",
            "year": 2022,
            "rank_display": 465,
            "score": 25.3,
            "link": "https://www.topuniversities.com/universities/universidad-de-belgrano",
            "country": "Argentina",
            "city": "Buenos Aires",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-belgrano_51_small.jpg",
            "type": "Private",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 758,
            "size": "M",
            "faculty_count": 810
        },
        {
            "university": "National Taipei University of Technology",
            "year": 2022,
            "rank_display": 469,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/national-taipei-university-technology",
            "country": "Taiwan",
            "city": "Taipei City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/national-taipei-university-of-technology_1993_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 11,
            "international_students": 1.04,
            "size": "M",
            "faculty_count": 714
        },
        {
            "university": "Universidad de Montevideo (UM)",
            "year": 2022,
            "rank_display": 469,
            "score": 25.2,
            "link": "https://www.topuniversities.com/universities/universidad-de-montevideo-um",
            "country": "Uruguay",
            "city": "Montevideo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-montevideo-um_695_small.jpg",
            "type": "Private",
            "research_output": "Low",
            "student_faculty_ratio": 7,
            "international_students": 76,
            "size": "S",
            "faculty_count": 295
        },
        {
            "university": "Ben-Gurion University of The Negev",
            "year": 2022,
            "rank_display": 471,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/ben-gurion-university-negev",
            "country": "Israel",
            "city": "Be'er Sheva",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/ben-gurion-university-of-the-negev_592560cf2aeae70239af4ab7_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 15,
            "international_students": 1.755,
            "size": "L",
            "faculty_count": 1.06
        },
        {
            "university": "Hasselt University",
            "year": 2022,
            "rank_display": 471,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/hasselt-university",
            "country": "Belgium",
            "city": "Diepenbeek",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/hasselt-university_1255_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 7,
            "international_students": 736,
            "size": "M",
            "faculty_count": 743
        },
        {
            "university": "Universidad ORT Uruguay",
            "year": 2022,
            "rank_display": 471,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/universidad-ort-uruguay",
            "country": "Uruguay",
            "city": "Montevideo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-ort-uruguay_470_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 6,
            "international_students": 62,
            "size": "S",
            "faculty_count": 620
        },
        {
            "university": "University of Stirling",
            "year": 2022,
            "rank_display": 471,
            "score": 25.1,
            "link": "https://www.topuniversities.com/universities/university-stirling",
            "country": "United Kingdom",
            "city": "Stirling",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-stirling_575_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 2.563,
            "size": "M",
            "faculty_count": 728
        },
        {
            "university": "Florida State University",
            "year": 2022,
            "rank_display": 475,
            "score": 25,
            "link": "https://www.topuniversities.com/universities/florida-state-university",
            "country": "United States",
            "city": "Tallahassee",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/florida-state-university_211_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 1.618,
            "size": "XL",
            "faculty_count": 2.236
        },
        {
            "university": "University of Missouri, Columbia",
            "year": 2022,
            "rank_display": 476,
            "score": 24.9,
            "link": "https://www.topuniversities.com/universities/university-missouri-columbia",
            "country": "United States",
            "city": "Columbia",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-missouri-columbia_409_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 10,
            "international_students": 1.273,
            "size": "L",
            "faculty_count": 2.6
        },
        {
            "university": "Bar-Ilan University",
            "year": 2022,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/bar-ilan-university",
            "country": "Israel",
            "city": "Ramat Gan",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/bar-ilan-university_997_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 18,
            "international_students": 990,
            "size": "L",
            "faculty_count": 923
        },
        {
            "university": "Chiba University",
            "year": 2022,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/chiba-university",
            "country": "Japan",
            "city": "Chiba City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chiba-university_119_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 736,
            "size": "L",
            "faculty_count": 1.79
        },
        {
            "university": "University of Texas Dallas",
            "year": 2022,
            "rank_display": 477,
            "score": 24.8,
            "link": "https://www.topuniversities.com/universities/university-texas-dallas",
            "country": "United States",
            "city": "Richardson",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-texas-dallas_592560cf2aeae70239af5355_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 22,
            "international_students": 4.828,
            "size": "L",
            "faculty_count": 1.14
        },
        {
            "university": "Chang Gung University",
            "year": 2022,
            "rank_display": 480,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/chang-gung-university",
            "country": "Taiwan",
            "city": "Taoyuan City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/chang-gung-university_1187_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 381,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Université de Liège",
            "year": 2022,
            "rank_display": 480,
            "score": 24.7,
            "link": "https://www.topuniversities.com/universities/universite-de-liege",
            "country": "Belgium",
            "city": "Arlon",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/universit-de-lige_592560cf2aeae70239af4bd9_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 4.381,
            "size": "L",
            "faculty_count": 1.619
        },
        {
            "university": "Auezov South Kazakhstan University (SKU)",
            "year": 2022,
            "rank_display": 482,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/auezov-south-kazakhstan-university-sku",
            "country": "Kazakhstan",
            "city": "Shymkent",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/auezov-south-kazakhstan-state-university-sksu_592560cf2aeae70239af52eb_small.jpg",
            "type": "Public",
            "research_output": "Medium",
            "student_faculty_ratio": 6,
            "international_students": 2.22,
            "size": "L",
            "faculty_count": 2.902
        },
        {
            "university": "Stellenbosch University",
            "year": 2022,
            "rank_display": 482,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/stellenbosch-university",
            "country": "South Africa",
            "city": "Stellenbosch",
            "region": "Africa",
            "logo": "https://www.topuniversities.com/sites/default/files/stellenbosch-university_574_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 25,
            "international_students": 1.776,
            "size": "L",
            "faculty_count": 934
        },
        {
            "university": "The Catholic University of Korea",
            "year": 2022,
            "rank_display": 482,
            "score": 24.6,
            "link": "https://www.topuniversities.com/universities/catholic-university-korea",
            "country": "South Korea",
            "city": "Gyeonggi",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/the-catholic-university-of-korea-_592560cf2aeae70239af4ed7_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 5,
            "international_students": 370,
            "size": "M",
            "faculty_count": 1.668
        },
        {
            "university": "Aston University",
            "year": 2022,
            "rank_display": 485,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/aston-university",
            "country": "United Kingdom",
            "city": "Birmingham",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/aston-university_29_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 16,
            "international_students": 1.719,
            "size": "M",
            "faculty_count": 730
        },
        {
            "university": "University of Turin",
            "year": 2022,
            "rank_display": 485,
            "score": 24.5,
            "link": "https://www.topuniversities.com/universities/university-turin",
            "country": "Italy",
            "city": "Turin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-turin_632_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 26,
            "international_students": 4.721,
            "size": "XL",
            "faculty_count": 2.261
        },
        {
            "university": "The National University of Science and Technology MISIS",
            "year": 2022,
            "rank_display": 487,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/national-university-science-technology-misis",
            "country": "Russia",
            "city": "Moscow",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/the-national-university-of-science-and-technology-misis_14956_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 8,
            "international_students": 1.605,
            "size": "M",
            "faculty_count": 915
        },
        {
            "university": "Universidad de Santiago de Chile (USACH)",
            "year": 2022,
            "rank_display": 487,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/universidad-de-santiago-de-chile-usach",
            "country": "Chile",
            "city": "Santiago",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/universidad-de-santiago-de-chile-usach_548_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 17,
            "international_students": 503,
            "size": "L",
            "faculty_count": 1.334
        },
        {
            "university": "Yokohama City University",
            "year": 2022,
            "rank_display": 487,
            "score": 24.4,
            "link": "https://www.topuniversities.com/universities/yokohama-city-university",
            "country": "Japan",
            "city": "Yokohama City",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/yokohama-city-university_592560cf2aeae70239af4d32_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 6,
            "international_students": 108,
            "size": "M",
            "faculty_count": 889
        },
        {
            "university": "Dublin City University",
            "year": 2022,
            "rank_display": 490,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/dublin-city-university",
            "country": "Ireland",
            "city": "Dublin",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/dublin-city-university_592560cf2aeae70239af4b27_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 11,
            "international_students": 1.415,
            "size": "L",
            "faculty_count": 1.257
        },
        {
            "university": "Justus-Liebig-University Giessen",
            "year": 2022,
            "rank_display": 490,
            "score": 24.3,
            "link": "https://www.topuniversities.com/universities/justus-liebig-university-giessen",
            "country": "Germany",
            "city": "Giessen",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/justus-liebig-university-giessen_592560cf2aeae70239af4b68_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 44,
            "international_students": 2.291,
            "size": "L",
            "faculty_count": 611
        },
        {
            "university": "UNESP",
            "year": 2022,
            "rank_display": 492,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/unesp",
            "country": "Brazil",
            "city": "Sao Paulo",
            "region": "Latin America",
            "logo": "https://www.topuniversities.com/sites/default/files/unesp_492_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 14,
            "international_students": 1.491,
            "size": "XL",
            "faculty_count": 2.794
        },
        {
            "university": "University of Granada",
            "year": 2022,
            "rank_display": 492,
            "score": 24.2,
            "link": "https://www.topuniversities.com/universities/university-granada",
            "country": "Spain",
            "city": "Granada",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-granada_235_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 19,
            "international_students": 5.611,
            "size": "XL",
            "faculty_count": 2.682
        },
        {
            "university": "Western Sydney University",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/western-sydney-university",
            "country": "Australia",
            "city": "Milperra",
            "region": "Oceania",
            "logo": "https://www.topuniversities.com/sites/default/files/western-sydney-university_848_small.jpg",
            "type": "Public",
            "research_output": "High",
            "student_faculty_ratio": 22,
            "international_students": 7.123,
            "size": "XL",
            "faculty_count": 1.62
        },
        {
            "university": "Boston College",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/boston-college",
            "country": "United States",
            "city": "Newton",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/boston-college_723_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 13,
            "international_students": 1.471,
            "size": "L",
            "faculty_count": 1.048
        },
        {
            "university": "Christian-Albrechts-University zu Kiel",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/christian-albrechts-university-zu-kiel",
            "country": "Germany",
            "city": "Kiel",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/christian-albrechts-university-zu-kiel_318_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 2.161,
            "size": "L",
            "faculty_count": 2.889
        },
        {
            "university": "Dongguk University",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/dongguk-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/dongguk-university_1048_small.jpg",
            "type": "Private",
            "research_output": "High",
            "student_faculty_ratio": 7,
            "international_students": 2.27,
            "size": "L",
            "faculty_count": 2.668
        },
        {
            "university": "Iowa State University",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/iowa-state-university",
            "country": "United States",
            "city": "Ames",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/iowa-state-university_294_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 2.441,
            "size": "L",
            "faculty_count": 1.656
        },
        {
            "university": "Sogang University",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/sogang-university",
            "country": "South Korea",
            "city": "Seoul",
            "region": "Asia",
            "logo": "https://www.topuniversities.com/sites/default/files/sogang-university_565_small.jpg",
            "type": "Private",
            "research_output": "Very High",
            "student_faculty_ratio": 9,
            "international_students": 1.429,
            "size": "M",
            "faculty_count": 1.017
        },
        {
            "university": "University of Rome \"Tor Vergata",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/university-rome-tor-vergata",
            "country": "Italy",
            "city": "Rome",
            "region": "Europe",
            "logo": "https://www.topuniversities.com/sites/default/files/university-of-rome-tor-vergata_592560cf2aeae70239af4c99_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 17,
            "international_students": 3.505,
            "size": "L",
            "faculty_count": 1.446
        },
        {
            "university": "York University",
            "year": 2022,
            "rank_display": 494,
            "score": 24.1,
            "link": "https://www.topuniversities.com/universities/york-university",
            "country": "Canada",
            "city": "Toronto",
            "region": "North America",
            "logo": "https://www.topuniversities.com/sites/default/files/york-university_592560cf2aeae70239af4d39_small.jpg",
            "type": "Public",
            "research_output": "Very High",
            "student_faculty_ratio": 29,
            "international_students": 12.285,
            "size": "XL",
            "faculty_count": 1.508
        }
    ]
}