import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deletePageList, getPageList } from '../../store/ApiSlice/canvasSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const DeletePage = ({
    selectedRows,
    setSelectedRows,
    isModalOpenDelete,
    setModalOpenDelete,
    attriId,
}) => {
    const dispatch = useDispatch();
    const { canvasData } = useSelector((state) => state.canvas)
    const { id } = useParams();
    const canvasFindData = canvasData?.find((item) => item?._id == id)
    const handleCloseModal = () => {
        setModalOpenDelete(false);
        setSelectedRows([]);
    };

    const handleSubmit = async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        try {
            const response = dispatch(deletePageList({ attriId, config }));
            if (!response?.data?.error) {
                // toast.success("Page Deleted Successfully ");
                dispatch(getPageList({ canvasFindData }))
            } else {
                toast.warn(
                    response?.data?.error
                        ? response.data.error
                        : "Enterprise can not be deleted"
                );
            }
        } catch (error) {
            console.log(error);
        }
        handleCloseModal();
    };

    const renderModalContent = () => {
        return (
            <>
                <DialogTitle sx={{ fontSize: "16px" }}>Delete Page</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography variant="h6" component="h2" sx={{ fontSize: "12px" }}>
                        {/* Are you sure for delete this Enterprise? */}
                        This is irreversible.Are you sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleSubmit();
                        }}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };


    return (
        <Dialog open={isModalOpenDelete} onClose={handleCloseModal}>
            {renderModalContent()}
        </Dialog>
    )
}

export default DeletePage
