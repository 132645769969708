import { Autocomplete, Box, Switch, styled, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { CommonAutoComplete } from '../commonComponent/commonSelect/CommonSelect'
import { CommonNumberInput } from '../commonComponent/input/CommonNumberInput'
import { MdMoreVert } from 'react-icons/md'
import profileFieldData from "../../profileFieldData.json"
const PredictiveTab = ({
    formValues,
    selectedProfile,
    handleChangeProfile,
    isView,
    profileMeasure,
    driverBasedMeasure,
    deleteDemandDriven,
    anchorMeasureEl,
    setAnchorMeasureEl,
    handleClick,
    handleremove,
    handleChangeDriverBasedMeasure,
    handleAddDriverMeasure,
    handleAddPredictivebasicKPI,
    deletePredictiveBasicKPI,
    predictiveBasicKPI,
    anchorPredictiveBasicKPIEl,
    setAnchorPredictiveBasicEl,
    handleChangePredictiveBasicKPI,
    predictiveAdditionalKPI,
    setPredictiveAdditionalKPI,
    handleChangePredictiveAdditionalBasicKPI,
    anchorPredictiveAdditionalKPIEl,
    setAnchorPredictiveAdditionalKPIEl,
    deletePredictiveAdditionalKPI,
    handleAddPredictiveAdditionalKPI
}) => {

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        }
    }));

    const findEnterpriseProfile = profileMeasure?.length > 0 ? profileMeasure?.filter((item) => item?.enterpriseUID === formValues?.enterpriseUID)
        ?.filter((item) => item?.modelUID === formValues?.modelUID) : []

    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Driver Based Forecasting: Basic Settings
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Best Driver Based Forecast Measure"
                                name="driverBasedForecastMeasureUID"
                                value={formValues?.driverBasedForecastMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Baseline Demand Measure"
                                name="baselineDemandMeasureUID"
                                value={formValues?.baselineDemandMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Best Driver Based Forecast Discrepancy Rank Measure"
                                name="driverBasedForecastOverallDiscrepancyRankMeasureUID"
                                value={formValues?.driverBasedForecastOverallDiscrepancyRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Best Driver Based Forecast Quality Rank Measure"
                                name="driverBasedForecastOverallQualityRankMeasureUID"
                                value={formValues?.driverBasedForecastOverallQualityRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={2.4} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="driverSensitivityAnalysis"
                                    name="driverSensitivityAnalysis"
                                    checked={formValues?.driverSensitivityAnalysis || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "driverSensitivityAnalysis", e);
                                    }}
                                />
                            }
                            label="Driver Sensitivity Analysis"
                        />
                    </Grid>
                    <Grid item xs={2.4} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="automatedWhatIfSimulation"
                                    name="automatedWhatIfSimulation"
                                    checked={formValues?.automatedWhatIfSimulation || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "automatedWhatIfSimulation", e);
                                    }}
                                />
                            }
                            label="Automated What-if Simulation"
                        />
                    </Grid>
                    {formValues?.automatedWhatIfSimulation && (
                        <>
                            <Grid item xs={2.4}>
                                <CommonNumberInput
                                    name="noOfIterations"
                                    label="# of Iterations"
                                    value={formValues?.noOfIterations || ""}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter iterations"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-readonly" shrink>Initial Seed Type</InputLabel>
                                    <Select
                                        labelId="Initial Seed Type"
                                        name="initialSeedType"
                                        id="demo-simple-select-readonly"
                                        value={formValues?.initialSeedType || ""}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "initialSeedType", e);
                                        }}
                                        label="Initial Seed Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="Random">Random</MenuItem>
                                        <MenuItem value="Fixed">Fixed</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {formValues?.initialSeedType === "Fixed" && (
                                <Grid item xs={2.4}>
                                    <CommonNumberInput
                                        name="initialSeedValue"
                                        label="Initial Seed Value"
                                        value={formValues?.initialSeedValue || ""}
                                        handleChangeProfile={handleChangeProfile}
                                        placeholder="Enter initial seed value"
                                        disabled={isView}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Driver Based Forecasting: Drivers
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddDriverMeasure}>
                    +
                </Button>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <div style={{ marginTop: "10px" }}>
                    {driverBasedMeasure?.length > 0 && driverBasedMeasure?.map((item, index) => {
                        return (
                            <>
                                <Box sx={{ width: "100%" }}>
                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Driver Measure"
                                                name="measure"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName || ''}
                                                value={
                                                    item?.measure
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.measure
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeDriverBasedMeasure(index, newValue, "measure", e);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Demand Driver Measure"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <FormControlLabel
                                                style={{
                                                    marginLeft: "0",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                                control={
                                                    <Checkbox
                                                        id="categoricalDriver"
                                                        name="categoricalDriver"
                                                        checked={item?.categoricalDriver || false}
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverBasedMeasure(index, newValue, "categoricalDriver", e);
                                                        }}
                                                        disabled={isView}
                                                        style={{
                                                            padding: "0",
                                                            paddingRight: "6px",
                                                            height: "54px",
                                                        }}
                                                    />
                                                }
                                                label="Categorical Driver"
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="offset"
                                                    name="offset"
                                                    type="number"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Offset"
                                                    value={item?.offset || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        if (newValue >= 0 && newValue <= 10) {
                                                            handleChangeDriverBasedMeasure(index, newValue, "offset", e);
                                                        } else if (newValue > 10) {
                                                            handleChangeDriverBasedMeasure(index, 10, "offset", e);
                                                        } else {
                                                            handleChangeDriverBasedMeasure(index, 0, "offset", e);
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter offset value"
                                                    inputProps={{ min: 0, max: 10 }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="demo-simple-select-readonly"
                                                    labelId="Driver Importance Measure"
                                                    name="importanceMeasure"
                                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                    getOptionLabel={(option) => option?.measureName}
                                                    value={
                                                        item?.importanceMeasure
                                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                (row) =>
                                                                    row?._id ==
                                                                    item?.importanceMeasure
                                                            ) || null : [])
                                                            : null
                                                    }
                                                    onChange={(e, newValue) => {
                                                        handleChangeDriverBasedMeasure(index, newValue, "importanceMeasure", e);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Driver Importance Measure"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ width: "100%", flex: 1, display: "flex", alignItems: "center" }}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="demo-simple-select-readonly"
                                                    labelId="Driver Contribution Measure"
                                                    name="contributionMeasure"
                                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                    getOptionLabel={(option) => option?.measureName}
                                                    value={
                                                        item?.contributionMeasure
                                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                (row) =>
                                                                    row?._id ==
                                                                    item?.contributionMeasure
                                                            ) || null : [])
                                                            : null
                                                    }
                                                    onChange={(e, newValue) => {
                                                        handleChangeDriverBasedMeasure(index, newValue, "contributionMeasure", e);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Driver Contribution Measure"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={deleteDemandDriven ? "long-menu" : undefined}
                                                aria-expanded={deleteDemandDriven ? "true" : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, index, "demandDriven")}
                                                size="small"
                                            >
                                                <MdMoreVert />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    "aria-labelledby": "long-button",
                                                }}
                                                anchorEl={anchorMeasureEl}
                                                open={deleteDemandDriven}
                                                onClose={() => { setAnchorMeasureEl(null) }}
                                            >
                                                <MenuItem onClick={() => { handleremove("demandDriven") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                        {!item?.categoricalDriver && (
                                            <>
                                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Driver Minimum Change %"
                                                        name="lowerBoundMeasure"
                                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                        getOptionLabel={(option) => option?.measureName}
                                                        value={
                                                            item?.lowerBoundMeasure
                                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                    (row) =>
                                                                        row?._id ==
                                                                        item?.lowerBoundMeasure
                                                                ) || null : [])
                                                                : null
                                                        }
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverBasedMeasure(index, newValue, "lowerBoundMeasure", e);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Driver Minimum Change %"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Driver Maximum Change %"
                                                        name="upperBoundMeasure"
                                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                        getOptionLabel={(option) => option?.measureName}
                                                        value={
                                                            item?.upperBoundMeasure
                                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                    (row) =>
                                                                        row?._id ==
                                                                        item?.upperBoundMeasure
                                                                ) || null : [])
                                                                : null
                                                        }
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverBasedMeasure(index, newValue, "upperBoundMeasure", e);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Driver Maximum Change %"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </Grid>
                                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Driver Step Size %"
                                                        name="stepSizeMeasure"
                                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                        getOptionLabel={(option) => option?.measureName}
                                                        value={
                                                            item?.stepSizeMeasure
                                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                    (row) =>
                                                                        row?._id ==
                                                                        item?.stepSizeMeasure
                                                                ) || null : [])
                                                                : null
                                                        }
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverBasedMeasure(index, newValue, "stepSizeMeasure", e);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Driver Step Size %"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {formValues?.automatedWhatIfSimulation && (
                                            <>
                                                {!item?.categoricalDriver && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                    </>
                                                )}
                                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-readonly" shrink>Uncertainty Distribution</InputLabel>
                                                        <Select
                                                            labelId="Uncertainty Distribution"
                                                            name="uncertaintyDistribution"
                                                            id="demo-simple-select-readonly"
                                                            value={item?.uncertaintyDistribution || ""}
                                                            fullWidth
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "uncertaintyDistribution", e);
                                                            }}
                                                            label="Uncertainty Distribution"
                                                            variant="outlined"
                                                        >
                                                            {profileFieldData?.sensitivityProfileOption
                                                                ?.slice() // Create a shallow copy to avoid mutating the original array
                                                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                                                .map((item) => (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                ))}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                {item?.uncertaintyDistribution === "Normal" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Mean"
                                                                name="meanMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.meanMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.meanMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "meanMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Mean"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Standard Deviation"
                                                                name="standardDeviationMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.standardDeviationMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.standardDeviationMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "standardDeviationMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Standard Deviation"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Beta" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Alpha 1"
                                                                name="alpha1MeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.alpha1MeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.alpha1MeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "alpha1MeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Alpha 1"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Alpha 2"
                                                                name="alpha2MeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.alpha2MeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.alpha2MeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "alpha2MeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Alpha 2"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "PERT" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Minimum"
                                                                name="minimumMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.minimumMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.minimumMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "minimumMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Minimum"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Most likely"
                                                                name="mostLikelyMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.mostLikelyMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.mostLikelyMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "mostLikelyMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Most likely"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Maximum"
                                                                name="maximumMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.maximumMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.maximumMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "maximumMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Maximum"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Exponential" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Beta"
                                                                name="betaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.betaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.betaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "betaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Beta"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Trangular" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Bottom %"
                                                                name="bottomPercentageMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.bottomPercentageMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.bottomPercentageMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "bottomPercentageMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Bottom %"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Top %"
                                                                name="topPercentageMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.topPercentageMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.topPercentageMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "topPercentageMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Top %"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Lognormal" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Mean"
                                                                name="meanMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.meanMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.meanMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "meanMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Mean"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Standard Deviation"
                                                                name="standardDeviationMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.standardDeviationMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.standardDeviationMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "standardDeviationMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Standard Deviation"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Weibull" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Alpha"
                                                                name="alphaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.alphaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.alphaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "alphaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Alpha"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Beta"
                                                                name="betaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.betaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.betaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "betaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Beta"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Uniform" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Minimum"
                                                                name="minimumMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.minimumMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.minimumMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "minimumMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Minimum"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Maximum"
                                                                name="maximumMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.maximumMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.maximumMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "maximumMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Maximum"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Bernoulli" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Probability"
                                                                name="probabilityMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.probabilityMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.probabilityMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Probability"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Gamma" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Alpha"
                                                                name="alphaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.alphaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.alphaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "alphaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Alpha"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Beta"
                                                                name="betaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.betaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.betaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "betaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Beta"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "General" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Minimum"
                                                                name="minimumMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.minimumMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.minimumMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "minimumMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Minimum"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Maximum"
                                                                name="maximumMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.maximumMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.maximumMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "maximumMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Maximum"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Bionomial" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Probability"
                                                                name="probabilityMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.probabilityMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.probabilityMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Probability"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Count"
                                                                name="countMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.countMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.countMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "countMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Count"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Histogram" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Probability"
                                                                name="probabilityMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.probabilityMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.probabilityMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Probability"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Pareto" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Theta"
                                                                name="thetaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.thetaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.thetaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "thetaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Theta"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Alpha"
                                                                name="alphaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.alphaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.alphaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "alphaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Alpha"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Student's" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Degrees of Freedom"
                                                                name="degreesOfFreedomMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.degreesOfFreedomMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.degreesOfFreedomMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "degreesOfFreedomMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Degrees of Freedom"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Poisson" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Lambda"
                                                                name="lambdaMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.lambdaMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.lambdaMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "lambdaMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Lambda"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Geometric" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Probability"
                                                                name="probabilityMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.probabilityMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.probabilityMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Probability"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Hypergeometric" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Sample Size"
                                                                name="sampleSizeMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.sampleSizeMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.sampleSizeMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "sampleSizeMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Sample Size"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Population"
                                                                name="populationMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.populationMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.populationMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "populationMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Population"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Population Size"
                                                                name="populationSizeMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.populationSizeMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.populationSizeMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "populationSizeMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Population Size"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Negative Bionomial" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Probability of a Single Success"
                                                                name="probabilityOfASingleSuccessMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.probabilityOfASingleSuccessMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.probabilityOfASingleSuccessMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "probabilityOfASingleSuccessMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Probability of a Single Success"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="# of Successes "
                                                                name="noOfSuccessesMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.noOfSuccessesMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.noOfSuccessesMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "noOfSuccessesMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="# of Successes "
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Discrete" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Possible Values"
                                                                name="possibleValuesMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.possibleValuesMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.possibleValuesMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "possibleValuesMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Possible Values"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Probability weight"
                                                                name="probabilityWeightMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.probabilityWeightMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.probabilityWeightMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "probabilityWeightMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Probability weight"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.uncertaintyDistribution === "Discrete Uniform" && (
                                                    <>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                                            <Autocomplete
                                                                id="demo-simple-select-readonly"
                                                                labelId="Possible Values"
                                                                name="possibleValuesMeasureUID"
                                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                                getOptionLabel={(option) => option?.measureName}
                                                                value={
                                                                    item?.possibleValuesMeasureUID
                                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                            (row) =>
                                                                                row?._id ==
                                                                                item?.possibleValuesMeasureUID
                                                                        ) || null : [])
                                                                        : null
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "possibleValuesMeasureUID", e);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Possible Values"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Box>
                            </>
                        )
                    })}
                </div>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Driver Based Forecasting: Basic KPI Measures
                </Typography>{" "}
                {/* <Button variant="contained" onClick={handleAddPredictivebasicKPI}>
                    +
                </Button> */}
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                {formValues?.isCheckedbasicKPIError && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {predictiveBasicKPI?.length > 0 ? predictiveBasicKPI?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="text"
                                                    id="error"
                                                    name="error"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Error"
                                                    value={"Error"}
                                                    placeholder="Enter default value"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Error"
                                                name="driverBasedForecastErrorMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastErrorMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastErrorMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastErrorMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Error"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Moving Average Error"
                                                name="driverBasedForecastMovingAverageErrorMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastMovingAverageErrorMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastMovingAverageErrorMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastMovingAverageErrorMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Moving Average Error"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Relative Error"
                                                name="driverBasedForecastRelativeErrorMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastRelativeErrorMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastRelativeErrorMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastRelativeErrorMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Relative Error"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Error Rank"
                                                name="driverBasedForecastErrorRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastErrorRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastErrorRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastErrorRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Error Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Accuracy Rank"
                                                name="driverBasedForecastAccuracyRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastAccuracyRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastAccuracyRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastAccuracyRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Accuracy Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Error FVA"
                                                name="driverBasedForecastErrorFVAMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastErrorFVAMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastErrorFVAMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastErrorFVAMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast KPI FVA"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                )
                            }) : []}
                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIBias && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {predictiveBasicKPI?.length > 0 ? predictiveBasicKPI?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="text"
                                                    id="Bias"
                                                    name="Bias"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Bias"
                                                    value={"Bias"}
                                                    placeholder="Enter default value"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Bias"
                                                name="driverBasedForecastBiasMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastBiasMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastBiasMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastBiasMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Bias"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Moving Average Bias"
                                                name="driverBasedForecastMovingAverageBiasMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastMovingAverageBiasMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastMovingAverageBiasMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastMovingAverageBiasMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Moving Average Bias"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Relative Bias"
                                                name="driverBasedForecastRelativeBiasMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastRelativeBiasMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastRelativeBiasMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastRelativeBiasMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Relative Bias"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Bias Rank"
                                                name="driverBasedForecastBiasRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastBiasRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastBiasRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastBiasRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Bias Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Neutrality Rank"
                                                name="driverBasedForecastNeutralityRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastNeutralityRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastNeutralityRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastNeutralityRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Neutrality Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Bias FVA"
                                                name="driverBasedForecastBiasFVAMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastBiasFVAMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastBiasFVAMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastBiasFVAMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Bias FVA"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                )
                            }) : []}
                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIChurn && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {predictiveBasicKPI?.length > 0 ? predictiveBasicKPI?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="text"
                                                    id="Churn"
                                                    name="Churn"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Churn"
                                                    value={"Churn"}
                                                    placeholder="Enter default value"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Churn"
                                                name="driverBasedForecastChurnMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastChurnMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastChurnMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastChurnMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Churn"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Moving Average Churn"
                                                name="driverBasedForecastMovingAverageChurnMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastMovingAverageChurnMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastMovingAverageChurnMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastMovingAverageChurnMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Moving Average Churn"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Relative Churn"
                                                name="driverBasedForecastRelativeChurnMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastRelativeChurnMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastRelativeChurnMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastRelativeChurnMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Relative Churn"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Churn Rank"
                                                name="driverBasedForecastChurnRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastChurnRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastChurnRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastChurnRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Churn Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Stability Rank"
                                                name="driverBasedForecastStabilityRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastStabilityRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastStabilityRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastStabilityRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Stability Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Driver Based Forecast Churn FVA"
                                                name="driverBasedForecastChurnFVAMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.driverBasedForecastChurnFVAMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.driverBasedForecastChurnFVAMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "driverBasedForecastChurnFVAMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Driver Based Forecast Churn FVA"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                )
                            }) : []}
                        </Grid>
                    </>
                )}

            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Driver Based Forecasting: Additional KPIs
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddPredictiveAdditionalKPI}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {predictiveAdditionalKPI?.length > 0 ? predictiveAdditionalKPI?.map((item, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="objective" shrink>Additional KPI</InputLabel>
                                        <Select
                                            labelId="objective"
                                            name="predictiveAdditionalKPI"
                                            id="demo-simple-select-readonly"
                                            value={item?.predictiveAdditionalKPI || ""}
                                            fullWidth
                                            onChange={(e, newValue) => {
                                                handleChangePredictiveAdditionalBasicKPI(index,
                                                    newValue,
                                                    "predictiveAdditionalKPI",
                                                    e);
                                            }}
                                            label="Additional KPI"
                                            variant="outlined"
                                        >
                                            <MenuItem value="Elasticity">Elasticity</MenuItem>
                                            <MenuItem value="Cross-Elasticity">Cross-Elasticity</MenuItem>
                                            <MenuItem value="Hazard Rate">Hazard Rate</MenuItem>
                                            <MenuItem value="Slope">Slope</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Additional KPI Measure"
                                        name="KPIMeasure"
                                        // options={profileMeasure}
                                        options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                                        getOptionLabel={(option) => option.measureName}
                                        value={
                                            item?.KPIMeasure
                                                ? (findEnterpriseProfile?.length > 0 ? findEnterpriseProfile?.find(
                                                    (row) => row?._id == item?.KPIMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangePredictiveAdditionalBasicKPI(index,
                                                newValue,
                                                "KPIMeasure",
                                                e);
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Additional KPI Measure"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deletePredictiveAdditionalKPI ? "long-menu" : undefined}
                                        aria-expanded={deletePredictiveAdditionalKPI ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "predictiveAdditionalKPIMeasure")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorPredictiveAdditionalKPIEl}
                                        open={deletePredictiveAdditionalKPI}
                                        onClose={() => { setAnchorPredictiveAdditionalKPIEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("predictiveAdditionalKPIMeasure") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    }) : []
                    }
                </Grid>
            </Container>
        </>
    )
}

export default PredictiveTab
