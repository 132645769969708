import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { API_BASE_URL } from '../../helper/constants'
import { AppBar, Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputBase, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography, alpha, styled } from '@mui/material'
import Paper from '@mui/material/Paper';
import { FaSearch } from 'react-icons/fa';
import { Select as MuiSelect } from '@mui/material';
import { createFilterDetail, getAttributeValue, getFilterDetail } from '../../store/ApiSlice/canvasSlice'
import { toast } from 'react-toastify'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: "1px solid black",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const CreateFilter = ({
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows
}) => {
    const { enterpriseData } = useSelector((state) => state?.enterprise)
    const { attributeValue, filterDetail } = useSelector((state) => state?.canvas)
    const { attribute } = useSelector((state) => state.attribute)
    const [filterFormValue, setFilterFormValue] = useState({})
    const [valueModal, setValueModal] = useState(false)
    const [modalName, setModalName] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [attributePage, setAttributePage] = useState(0);
    const [rowsAttributePerPage, setRowsAttributePerPage] = useState(10);
    const [search, setSearch] = useState()
    const [selectedValue, setSelectedValue] = useState({})
    const [selectedAttributes, setSelectedAttributes] = useState([
        {
            attributes: '',
            operator: 'Equal To',
            attValues: {},
        }
    ]);
    const [openModalRow, setOpenModalRow] = useState(null);
    const [attributeValueData, setAttributeValueData] = useState({ attValues: {} })
    const dispatch = useDispatch();
    const AttributeEnterpriseData = attribute?.filter((item) => item?.enterpriseUID?._id === filterFormValue?.enterpriseUID && (item?.modelUID?.modalUID === filterFormValue?.modelUID || item?.modelName === filterFormValue?.modelName))
    const selectedFilterDetail = filterDetail?.find((item) => item?._id === selectedRows)

    useEffect(() => {
        if (selectedFilterDetail && isEdit) {
            setFilterFormValue({
                filterName: selectedFilterDetail?.name,
                filterNotes: selectedFilterDetail?.notes,
                enterpriseUID: selectedFilterDetail?.enterpriseUID?._id,
                enterpriseName: selectedFilterDetail?.enterpriseUID?.enterpriseName,
                modelUID: selectedFilterDetail?.modelUID?.modalUID,
                modelName: selectedFilterDetail?.modelUID?.modelName
            })
            setSelectedAttributes(selectedFilterDetail?.attributes?.map((item) => ({
                attributes: item?.attributeUID?._id,
                operator: item?.operator,
                attValues: item?.values?.reduce((acc, value) => {
                    acc[value] = true;
                    return acc;
                }, {})
            })))
        }
    }, [filterDetail, isEdit, selectedFilterDetail])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    const handleChangeRowsAttributePerPage = (event) => {
        setRowsAttributePerPage(parseInt(event.target.value, 10));
        setAttributePage(0);
    };

    const handleChangeAttributePage = (event, newPage) => {
        setAttributePage(newPage)
    }

    const handleCancel = () => {
        setIsCreate(false);
    };

    const handleChangeFilter = (newValue, fieldName, e) => {
        if (fieldName === 'enterpriseName') {
            const selectedData = enterpriseData?.filter((data) => data?._id === newValue?._id);

            setFilterFormValue({
                ...filterFormValue,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id
            });
        } else if (fieldName === 'modelName') {
            const selectedData = modalName?.filter((data) => data?.modalUID === newValue?.modalUID);

            setFilterFormValue({
                ...filterFormValue,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID
            });
        } else if (fieldName === 'filterAttribute') {
            const selectedData = AttributeEnterpriseData?.filter((data) => data?._id === newValue?._id);

            setFilterFormValue({
                ...filterFormValue,
                [fieldName]: selectedData[0]?.name,
                [`attributeUID`]: newValue?._id
            });
        }
        else {
            const { name, value } = e.target;
            setFilterFormValue((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    }

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === filterFormValue.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (filterFormValue.enterpriseName) {
                filteredData = filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === filterFormValue.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };
    useEffect(() => {
        if (filterFormValue.enterpriseName) {
            getModelList();
        }
    }, [filterFormValue.enterpriseName]);

    const handleClickValue = (rowIndex, data) => {
        setValueModal(true)
        setOpenModalRow(rowIndex)
        if (Object.keys(data?.attValues).length > 0) {
            setAttributeValueData({ attValues: data.attValues });
        }
    }
    const handleCloseValue = () => {
        setValueModal(false)
        setAttributeValueData({ attValues: {} })
    }

    const validation = () => {
        if (filterFormValue?.filterName == "" || filterFormValue?.filterName == undefined) {
            toast.error("Please enter filter name")
            return true;
        } else if (filterFormValue?.enterpriseUID == "" || filterFormValue?.enterpriseUID == undefined) {
            toast.error("Please select enterPrise")
            return true;
        } else if (filterFormValue?.modelUID == "" || filterFormValue?.modelUID == undefined) {
            toast.error("Please select model")
            return true;
        } else {
            const hasInvalidAttribute = selectedAttributes.some(item => !item.attributes || item.attributes.trim() === '');
            const hasInvalidOperator = selectedAttributes.some(item => !item.operator || item.operator.trim() === '');
            const hasInvalidAttValues = selectedAttributes.some(item => {
                if (!item.attValues || Object.keys(item.attValues).length === 0) {
                    return true;
                }
                return false;
            });
            if (hasInvalidAttribute) {
                toast.error("Please select attribute")
                return true;
            } else if (hasInvalidOperator) {
                toast.error("Please select operator")
                return true;
            } else if (hasInvalidAttValues) {
                toast.error("Please select attribute values")
                return true;
            }
        }
        return false
    }

    const handleSubmitFilter = async () => {
        if (validation()) {
            return;
        } else {
            const payload = {
                enterpriseUID: filterFormValue?.enterpriseUID,
                modelUID: filterFormValue?.modelUID,
                name: filterFormValue?.filterName,
                notes: filterFormValue?.filterNotes,
                attributes: selectedAttributes?.map((item) => ({
                    attributeUID: item?.attributes,
                    values: Object.entries(item?.attValues)
                        .filter(([key, value]) => value === true)
                        .map(([key, value]) => key),
                    operator: item?.operator
                }))
            }
            const result = await dispatch(createFilterDetail({ payload, isEdit, selectedRows }));
            if (result?.meta?.requestStatus === 'fulfilled') {
                setFilterFormValue({});
                setSelectedAttributes([])
                setIsCreate(false)
                // toast.success(isEdit ? "Filter updated successfully" : "Filter created successfully");
            } else if (result?.meta?.requestStatus === 'rejected') {
                toast.error(result?.error?.message)
            }
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleAddField = () => {
        setSelectedAttributes([...selectedAttributes, {
            attributes: '',
            operator: 'Equal To',
            attValues: {},
        }]);
    };

    const handleStepChangeValue = (newValue, fieldname, event, index, data) => {
        const { name, value, checked } = event.target;
        if (fieldname == "attributes") {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][fieldname] = newValue?._id;
            setSelectedAttributes(updatedFormValues);
        }
        else {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][name] = value;
            setSelectedAttributes(updatedFormValues);
        }
    };

    const handleCheckboxChange = (newValue, item) => {
        const updatedFormValues = { ...attributeValueData };
        updatedFormValues.attValues[item] = !updatedFormValues.attValues[item];
        setAttributeValueData(updatedFormValues);
    };

    const handleHeaderCheckboxChange = (event, index) => {
        const isChecked = event.target.checked;
        const updatedFormValues = { ...attributeValueData };
        attributeValue.forEach((item) => {
            updatedFormValues.attValues[item] = isChecked;
        });
        setAttributeValueData(updatedFormValues)
    };

    const handleSubmit = (index) => {

        const updatedFormValues = [...selectedAttributes];
        updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
        setSelectedAttributes(updatedFormValues);
        handleCloseValue();
    };


    const handleRemoveField = () => {
        const updatedFormValues = [...selectedAttributes];
        updatedFormValues.splice(selectedValue, 1);
        setSelectedAttributes(updatedFormValues);
    };

    return (
        <>
            <div>
                <AppBar position="static" sx={{ backgroundColor: "white" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                            Filter
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={6}>
                            <TextField
                                id="filterName"
                                name="filterName"
                                variant="outlined"
                                fullWidth
                                label="Filter Name"
                                value={filterFormValue?.filterName}
                                onChange={(e, newValue) => {
                                    handleChangeFilter(newValue, "filterName", e);
                                }}
                                placeholder="Enter filter name"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Enterprise Name"
                                    name="enterpriseName"
                                    options={enterpriseData}
                                    getOptionLabel={(option) => option.enterpriseName}
                                    value={filterFormValue?.enterpriseName ?
                                        enterpriseData.find(option => option.enterpriseName === filterFormValue.enterpriseName)
                                        || null : null}
                                    onChange={(e, newValue) => {
                                        handleChangeFilter(newValue, "enterpriseName", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enterprise Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Model Name"
                                    name="modelName"
                                    options={modalName}
                                    getOptionLabel={(option) => option.modelName}
                                    value={filterFormValue?.modelName ?
                                        modalName.find(option => option.modelName === filterFormValue.modelName)
                                        || null : null}
                                    onChange={(e, newValue) => {
                                        handleChangeFilter(newValue, "modelName", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Model Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>

                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />

                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}>
                        <Button
                            variant='contained'
                            onClick={handleAddField}
                        >
                            Add
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => handleRemoveField()}
                        >
                            Remove
                        </Button>
                    </div>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: "50px"}}>
                                            Select
                                        </TableCell>
                                        <TableCell>Attribute</TableCell>
                                        <TableCell>Operator</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                {selectedAttributes?.length > 0 && selectedAttributes?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((row, i) => {
                                    const findAttribute = AttributeEnterpriseData.find(item => item?._id === row.attributes);

                                    return (

                                        <TableBody>
                                            <TableCell>
                                                <Checkbox onChange={() => { setSelectedValue(i) }} />
                                            </TableCell>
                                            <TableCell style={{ width: "300px" }}>
                                                <Autocomplete
                                                    id="demo-simple-select-readonly"
                                                    labelId={`attributes${i}`}
                                                    name="attributes"
                                                    options={AttributeEnterpriseData}
                                                    getOptionLabel={(option) => option.name}
                                                    value={row?.attributes ?
                                                        AttributeEnterpriseData?.find(option => option._id === row?.attributes)
                                                        || null : null}
                                                    onChange={(e, newValue) => {
                                                        handleStepChangeValue(newValue, "attributes", e, i, row);
                                                    }}
                                                    sx={{ fontSize: "12px" }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Attribute"
                                                            variant="outlined"
                                                            fullWidth
                                                            style={{ width: "300px", fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell style={{ width: "240px" }}>
                                                <FormControl style={{ width: "240px" }}>
                                                    <InputLabel id="operator" sx={{ fontSize: "12px" }}>Operator</InputLabel>
                                                    <MuiSelect
                                                        labelId="Value"
                                                        name='operator'
                                                        id="demo-simple-select-readonly"
                                                        value={row?.operator}
                                                        onChange={(e, newValue) => {
                                                            handleStepChangeValue(newValue, "operator", e, i, row);
                                                        }}
                                                        variant="outlined"
                                                        sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                    >
                                                        <MenuItem value="Equal To" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Equal To</MenuItem>
                                                        <MenuItem value="Not Equal To" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Not Equal To</MenuItem>
                                                    </MuiSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        cursor: "pointer",
                                                        border: "1px solid gray",
                                                        borderRadius: "4px",
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        alignItems: "center",
                                                        flexWrap: "wrap",
                                                        gap: "20px",
                                                        padding: `${!row?.attValues ? "10px" : "25px"}`
                                                    }}
                                                    fullWidth
                                                    onClick={() => {
                                                        handleClickValue(i, row)
                                                        dispatch(getAttributeValue({ row }))
                                                    }}
                                                >
                                                    {Object.entries(row?.attValues).map(([key, value]) => (
                                                        value === true && (
                                                            <div
                                                                key={key}
                                                                style={{ background: "bisque", padding: "6px", borderRadius: "10px", fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                            >
                                                                {key}
                                                            </div>
                                                        )
                                                    ))}
                                                </Box>
                                            </TableCell>
                                            {valueModal && (

                                                <Dialog
                                                    open={valueModal && openModalRow === i}
                                                    onClose={handleCloseValue}
                                                >
                                                    <DialogTitle sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Attribute Values</DialogTitle>
                                                    <DialogContent sx={{ marginTop: "10px !important", width: '500px' }}>
                                                        <Search sx={{ marginBottom: "31px" }}>
                                                            <SearchIconWrapper>
                                                                <FaSearch />
                                                            </SearchIconWrapper>
                                                            <StyledInputBase
                                                                placeholder="Search…"
                                                                inputProps={{ 'aria-label': 'search' }}
                                                                onChange={(e) => { handleSearch(e) }}
                                                            />
                                                        </Search>

                                                        <TableContainer component={Paper}>
                                                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                                                <Table aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Checkbox onChange={(e) => handleHeaderCheckboxChange(e, i)} />
                                                                            </TableCell>
                                                                            <TableCell sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif" }}>{findAttribute?.name}</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    {search ? (
                                                                        attributeValue?.filter((item) => {
                                                                            if (typeof item === 'string') {
                                                                                return item?.toLowerCase().includes(
                                                                                    typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                                                );
                                                                            } else if (typeof item === 'number') {
                                                                                return item?.toString().includes(search?.toString());
                                                                            }
                                                                            return false;
                                                                        })?.length === 0 ? (
                                                                            <TableBody>
                                                                                <TableCell colSpan={2} style={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif" }}>No data found</TableCell>
                                                                            </TableBody>
                                                                        ) : (
                                                                            attributeValue?.filter((item) => {
                                                                                if (typeof item === 'string') {
                                                                                    return item?.toLowerCase().includes(
                                                                                        typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                                                    );
                                                                                } else if (typeof item === 'number') {
                                                                                    return item.toString()?.includes(search?.toString());
                                                                                }
                                                                                return false;
                                                                            })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => {
                                                                                return (
                                                                                    <TableBody key={index}>
                                                                                        <TableCell>
                                                                                            <Checkbox
                                                                                                checked={attributeValueData?.attValues[item] || false}
                                                                                                onChange={(e) => {
                                                                                                    handleCheckboxChange(e.target.checked, item);
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell>{item}</TableCell>
                                                                                    </TableBody>
                                                                                )
                                                                            })
                                                                        )
                                                                    ) : (
                                                                        attributeValue?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                            <TableBody key={index}>
                                                                                <TableCell>
                                                                                    <Checkbox
                                                                                        checked={attributeValueData?.attValues[item] || false}
                                                                                        onChange={(e) => {
                                                                                            handleCheckboxChange(e.target.checked, item);
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>{item}</TableCell>
                                                                            </TableBody>
                                                                        ))
                                                                    )}
                                                                </Table>
                                                            </div>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                                component="div"
                                                                count={search ? attributeValue?.filter((item) => {
                                                                    if (typeof item === 'string') {
                                                                        return item?.toLowerCase().includes(
                                                                            typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                                                        );
                                                                    } else if (typeof item === 'number') {
                                                                        return item?.toString().includes(search?.toString());
                                                                    }
                                                                    return false;
                                                                })?.length : attributeValue?.length}
                                                                rowsPerPage={rowsAttributePerPage}
                                                                page={attributePage}
                                                                onPageChange={(event, newPage) => handleChangeAttributePage(event, newPage)}
                                                                onRowsPerPageChange={handleChangeRowsAttributePerPage}
                                                            />
                                                        </TableContainer>

                                                        <DialogActions >
                                                            <Button onClick={() => {
                                                                handleCloseValue()
                                                                setSearch()
                                                            }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    handleSubmit(i)
                                                                    setSearch()
                                                                }}
                                                                sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </DialogActions>
                                                    </DialogContent>
                                                </Dialog>
                                            )}
                                        </TableBody>
                                    )
                                })}
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                                component="div"
                                count={selectedAttributes?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Grid>
                </Container>

                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />

                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={12}>
                            <TextField
                                id="filterNotes"
                                name="filterNotes"
                                variant="outlined"
                                fullWidth
                                label="Filter Notes"
                                multiline
                                rows={6}
                                value={filterFormValue.filterNotes}
                                onChange={(e, newValue) => {
                                    handleChangeFilter(newValue, "filterNotes", e);
                                }}
                                placeholder="Enter Filter Notes"
                                // onBlur={validateOne}
                                disabled={isView}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: "30px",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        onClick={handleCancel}
                        title="Cancel"
                        style={{
                            padding: "6px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            marginRight: "10px",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "12px"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmitFilter}
                        title="Create new dimension with selected attributes"
                        style={{
                            padding: "7px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "12px"
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div >


        </>
    )
}

export default CreateFilter
