import { setUnionDependencies } from 'mathjs';
import React, { useEffect, useRef, useState } from 'react';
import { FaBell, FaHeart, FaCommentDots, FaChartLine, FaUserCog, FaTasks } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { setExpandedMenu } from '../store/ApiSlice/authSlice';
import { useDispatch } from 'react-redux';
import useSocket from '../socket/SocketComponent';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { changeDefaultEnterprise } from '../store/ApiSlice/userSlice';
import { toast } from 'react-toastify';
import starIcon from '../image/starIcon.svg';
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { IoAdd, IoChatboxEllipsesOutline } from "react-icons/io5";

function Header({ toggleChat }) {
    const userProfile = JSON.parse(localStorage.getItem("accessToken"))
    const [enterpriseModel, setEnterpriseModel] = useState(false);
    const [open, setOpen] = useState(false)
    const [defaultEnterprise, setDefaultEnterprise] = useState({});
    const [activeButton, setActiveButton] = useState('tasks');
    const dropdownRef = useRef(null);
    const avatarRef = useRef(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userOffline } = useSocket();

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                avatarRef.current &&
                !avatarRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        navigate("/login");
        userOffline(userProfile?.userId)
        localStorage.removeItem("accessToken");
        dispatch(setExpandedMenu("Planning"))
        setOpen(false)
    };

    const handleDefaultEnterprise = () => {
        setEnterpriseModel(!enterpriseModel);
        setOpen(false)
    }

    useEffect(() => {
        setDefaultEnterprise({
            ...defaultEnterprise,
            enterpriseUID: userProfile?.data?.enterprise?._id
        })
    }, [enterpriseModel])

    const handleSubmit = async () => {
        const body = {
            enterpriseUID: defaultEnterprise?.enterpriseUID
        }
        const responce = await dispatch(changeDefaultEnterprise(body))
        if (responce?.meta?.requestStatus === "fulfilled") {
            toast.success(responce?.payload?.message)
        } else if (responce?.meta?.requestStatus === "rejected") {
            toast.error(responce?.error?.message);
        }
    }

    return (
        <>
            <div className="p-4">
                <div className="flex justify-between items-center">
                    <div className='flex'>
                        <div className="flex gap-2">
                            {[
                                { label: 'My Tasks', icon: <FaTasks />, key: 'tasks' },
                                { label: 'My Notifications', icon: <FaBell />, key: 'notifications' },
                                { label: 'My Favorites', icon: <FaHeart />, key: 'favorites' },
                                { label: 'Chat', icon: <FaCommentDots />, key: 'chat' },
                                { label: 'Insights', icon: <FaChartLine />, key: 'insights', onClick: toggleChat },
                                // { label: 'User Defaults', icon: <FaUserCog />, key: 'userDefaults' },
                            ].map((item) => (
                                <div
                                    key={item.key}
                                    className={`flex items-center gap-2 text-sm cursor-pointer rounded-md py-2.5 px-3 shadow-sm border 
                                        ${activeButton === item.key
                                            ? 'border-[#C7D7FE] bg-[#F5F8FF] text-[#6172F3]'
                                            : 'border-[#E9EAEB] bg-[#FAFAFA] text-black'
                                        }`}
                                    onClick={() => {
                                        if (item?.key === "insights") {
                                            toggleChat()
                                            setActiveButton(item.key)
                                        } else {
                                            setActiveButton(item.key)
                                        }
                                    }}
                                >
                                    {item.icon}
                                    <p>{item.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex items-center gap-4'>
                        {/* <div>
                            <div className='text-[#717680] flex items-center'>
                                <button className='flex items-center gap-[6.5px] py-2.5 px-[6PX]'>
                                    <IoChatboxEllipsesOutline size={16} />
                                    <p>1</p>
                                </button>
                                <div className='w-[2px] h-6 bg-[#717680] mx-1'></div>
                                <button className='text-[#717680] py-2.5 px-[6PX]'>
                                    <HiOutlineQuestionMarkCircle size={16} />
                                </button>
                            </div>

                        </div> */}
                        {/* <button className='flex items-center gap-2 text-sm cursor-pointer rounded-md py-2 px-3 shadow-sm border border-[#E9EAEB] bg-[#FFFFFF] text-[#181D27]'>
                            <IoAdd />
                            <p>Add a Widget</p>
                        </button> */}
                        {/* <button className='flex items-center gap-2 text-sm cursor-pointer rounded-md py-2 px-3 shadow-sm border border-[#E9EAEB] bg-[#FFFFFF] text-[#181D27]'>
                            <img src={starIcon} alt='star' />
                            <p>Insights</p>
                        </button> */}
                        {/* <div className='relative'>
                            <div ref={avatarRef} onClick={() => setOpen(!open)} className="w-12 h-12 bg-blue-400 rounded-full text-white text-lg font-bold flex items-center justify-center cursor-pointer shadow-lg hover:scale-110 transition-transform">
                                {userProfile?.data?.userName
                                    ? userProfile?.data?.userName?.split(" ")?.map(word => word[0])?.join("")
                                    : ""}
                            </div>
                            {open && (
                                <div
                                    ref={dropdownRef}
                                    id="dropdownInformation"
                                    className="absolute right-0 top-[52px] z-[11111] w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600"
                                >
                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                        <li className="flex flex-col px-4 py-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                            {userProfile?.data?.userName}
                                            <span className="text-xs text-gray-500 dark:text-gray-400">{userProfile?.data?.email}</span>
                                        </li>
                                        <div className="w-full h-px bg-gray-300 dark:bg-gray-600"></div>
                                        <li className="flex flex-col px-2 pt-2">
                                            <button
                                                onClick={() => navigate("/user-account")}
                                                className="flex items-center px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-300"
                                            >
                                                User Data
                                            </button>

                                            <button
                                                onClick={handleDefaultEnterprise}
                                                className="flex items-center px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-300"
                                            >
                                                Default Enterprise
                                            </button>

                                            <button
                                                onClick={handleLogout}
                                                className="flex items-center px-3 py-2 rounded-md text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700 transition duration-300"
                                            >
                                                Sign Out
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <Dialog open={enterpriseModel} onClose={handleDefaultEnterprise}>
                <DialogTitle>Default Enterprise</DialogTitle>
                <DialogContent sx={{ width: "432px" }}>
                    <FormControl fullWidth style={{ paddingBottom: "25px", marginTop: "13px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="enterprise"
                            name="enterpriseUID"
                            fullWidth
                            options={userProfile?.data?.multiEnterprise?.length > 0 ? userProfile?.data?.multiEnterprise : []}
                            getOptionLabel={(option) => option.enterpriseName}
                            value={defaultEnterprise?.enterpriseUID ?
                                userProfile?.data?.multiEnterprise?.find
                                    ((item) => item?.enterpriseUID === defaultEnterprise?.enterpriseUID)
                                || null : null}
                            onChange={(event, newValue) => {
                                setDefaultEnterprise({
                                    ...defaultEnterprise,
                                    enterpriseUID: newValue?.enterpriseUID
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Enterprise"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>

                    <DialogActions>
                        <Button onClick={handleDefaultEnterprise} sx={{ textTransform: "capitalize" }}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} sx={{ textTransform: "capitalize" }}>
                            Submit
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog> */}
        </>
    );
}

export default Header;