import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import ChatBot from "../components/ChatBot";
import bgBlur from '../image/bg-blur.svg';

const AppLayout = ({ children }) => {
  const location = useLocation();

  // Define routes where Sidebar and Header should be hidden
  const hideLayoutRoutes = ["/login", "/signup", "/change-password", "/reset-password"];

  const shouldHideLayout = hideLayoutRoutes.includes(location.pathname);
  console.log("🚀 ~ AppLayout ~ shouldHideLayout:", shouldHideLayout)

  const [isChatOpen, setIsChatOpen] = useState(false);
  const toggleChat = () => setIsChatOpen(!isChatOpen);

  return (
    <div className="flex">
      {!shouldHideLayout && <Sidebar />}
      <div className="flex-1 relative bg-no-repeat bg-cover bg-center w-[calc(100%-296px)]" style={{ backgroundImage: `url(${bgBlur})` }}>
        {!shouldHideLayout && <Header toggleChat={toggleChat} />}
        <div className={!shouldHideLayout ? 'p-8' : ''}>{children}</div>
      </div>
      {isChatOpen && <ChatBot toggleChat={toggleChat} />}
    </div>
  );
};

export default AppLayout
