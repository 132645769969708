import React from 'react'
import { useDispatch } from 'react-redux';
import { deletePlanningObject, getPlanningObjects } from '../../store/ApiSlice/dimensionSlice';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const DeletePlanningObject = ({
    formValues,
    planningObjectID,
    setPlanningObjectID,
    setDeletePlanningObject
}) => {
    const handleCloseModal = () => {
        setDeletePlanningObject(false);
    };
    const dispatch = useDispatch();

    const handleDelete = async () => {
        // try {
        const payload = {
            enterpriseUID: formValues?.enterpriseUID,
            modelUID: formValues.modelUID,
            dimensionUID: formValues?.dimensionUID,
            ids: [planningObjectID]
        }
        const response = await dispatch(deletePlanningObject({ payload }));
        if (response?.meta?.requestStatus === "fulfilled") {
            setDeletePlanningObject(false);
            // toast.success(response?.payload?.data?.message);
            dispatch(getPlanningObjects({ page: 1, limit: 10, id: formValues?.dimensionUID }));
        } else if (response?.meta?.requestStatus === "rejected") {
            toast.error(response?.data?.error);
        }
        handleCloseModal();
    };
    const renderModalContent = () => {
        return (
            <>
                <DialogTitle>Delete Planning Object</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography>
                        This is irreversible. Are you sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button color="primary" onClick={handleDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };
    return (
        <>
            <Dialog open={deletePlanningObject} onClose={handleCloseModal}>
                {renderModalContent()}
            </Dialog>
        </>
    )
}

export default DeletePlanningObject
