import React, { useState } from "react";
import { Toolbar, Typography, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Select, MenuItem } from "@mui/material";
import CreateAttribute from "../../attributes/CreateAttribute";
import AttributesListModel from "./AttributesListModel";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import moment from "moment/moment";

const ScenariosTable = ({
  dimensionAttributes,
  setDimensionAttributes,
  isView,
  formValues,
  setformValues,
  data,
  refAttributeMapping,
  setRefAttributeMapping,
  dimenstionData,
}) => {
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
    useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const handleKeyChange = (event, row, keyToUpdate) => {
    let newValue = event.target.checked;
    setDimensionAttributes((prevArray) =>
      prevArray.map((obj, index) => {
        if (row._id === obj._id) {
          if (keyToUpdate === "primaryKey") {
            return {
              ...obj,
              [keyToUpdate]: newValue,
              required: newValue ? true : false,
            };
          } else {
            return { ...obj, [keyToUpdate]: newValue };
          }
        } else {
          return obj;
        }
      })
    );
  };
  const handleAddClick = () => {
    setModalOpen(true);
  };
  const handleNewClick = () => {
    setIsCreateUpdateModalOpen(true);
  };
  const handleDeleteClick = () => {
    setIsDeleteDimensionModalOpen(true);
  };
  const handleTableRowSelect = (event) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(id)
      ? selectedRows.filter((n) => n !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedIds);
  };

  const handleDeleteSubmit = () => {
    let newFilteredRows = dimensionAttributes.filter((item) => {
      return !selectedRows.includes(item._id);
    });
    setDimensionAttributes(newFilteredRows);
    setIsDeleteDimensionModalOpen(false);
  };
  const handleDeleteDimensionCloseModal = () => {
    setIsDeleteDimensionModalOpen(false);
  };

  const renderModalContent = () => {
    return (
      <Dialog
        open={isDeleteDimensionModalOpen}
        onClose={handleDeleteDimensionCloseModal}
      >
        <DialogTitle>Remove Attribute</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
            Are You Sure For Remove This Attribute?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDimensionCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteSubmit();
            }}
            color="primary" sx={{ textTransform: "capitalize" }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const referenceDimFields =
    dimenstionData && dimenstionData.fields ? dimenstionData.fields : [];

  const handleReferenceAttribute = (event, currentAttribute) => {
    let mappingExists = refAttributeMapping.find(
      (item) => item.mainAttribute === currentAttribute._id
    );
    if (mappingExists) {
      let mappings = refAttributeMapping.map((mapping) => {
        if (mappingExists.mainAttribute === currentAttribute._id) {
          mapping.refAttribute = event.target.value;
        }
        return mapping;
      });
      setRefAttributeMapping([...mappings]);
    } else {
      let mapping = {
        mainAttribute: currentAttribute._id,
        refAttribute: event.target.value,
      };
      setRefAttributeMapping((preMapping) => [...preMapping, mapping]);
    }
  };

  const getRefAttributeId = (row) => {
    let mapping = refAttributeMapping.find(
      (item) => item.mainAttribute === row._id
    );
    return mapping ? mapping.refAttribute : "";
  };

  return (
    <>
      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />
      <Toolbar>
        <Typography variant="h6">Assigned Attribute</Typography>
      </Toolbar>
      <Grid item>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "26px",
          }}
        >
          <Button
            disabled={isView}
            title="Add new attribute in dimension"
            variant="text"
            onClick={handleAddClick}
          >
            Add
          </Button>
          <Button
            disabled={isView}
            title="Create new attribute and add in dimension"
            variant="text"
            onClick={handleNewClick}
          >
            New
          </Button>
          <Button
            disabled={isView}
            title="Delete attribute from dimension"
            variant="text"
            onClick={handleDeleteClick}
          >
            Remove
          </Button>
        </Typography>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {!isView && <TableCell align="left">Select</TableCell>}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Length</TableCell>
              <TableCell align="left">Notes</TableCell>
              <TableCell align="left">Primary Key</TableCell>
              <TableCell align="left">Required</TableCell>
              {formValues.type === "REFERENCE" && (
                <TableCell align="left">Reference Attribute</TableCell>
              )}
              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Changed On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dimensionAttributes?.length>0&&dimensionAttributes?.map((row) => (
              <TableRow
                key={row?.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {!isView && (
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={selectedRows.includes(row?._id)}
                      value={row?._id}
                      onChange={handleTableRowSelect}
                      disabled={row?.fromCompound ? true : false}
                    />
                  </TableCell>
                )}
                <TableCell align="left">{row?.name}</TableCell>
                <TableCell align="left">{row?.type}</TableCell>
                <TableCell align="left">{row?.length}</TableCell>
                <TableCell align="left">{row?.notes}</TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={row?.primaryKey ? true : false}
                    disabled={isView ? true : false}
                    value={row?.primaryKey ? true : false}
                    onChange={(event) => {
                      handleKeyChange(event, row, "primaryKey");
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={row?.required ? true : false}
                    disabled={row?.primaryKey || isView ? true : false}
                    value={row?.required ? true : false}
                    onChange={(event) => {
                      handleKeyChange(event, row, "required");
                    }}
                  />
                </TableCell>
                {formValues.type === "REFERENCE" && (
                  <TableCell align="left">
                    <Select
                      id="type"
                      name="type"
                      value={getRefAttributeId(row)}
                      onChange={(event) => {
                        handleReferenceAttribute(event, row);
                      }}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      disabled={isView}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      {referenceDimFields.map((item) => (
                        <MenuItem value={item?._id}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                )}
                <TableCell align="left">
                  {moment(row?.createdOn).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="left">
                  {moment(row?.changedOn).format("DD-MM-YYYY")}  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!dimensionAttributes?.length && (
          <div className="centeredText">No attributes selected yet</div>
        )}
      </TableContainer>

      {isModalOpen && (
        <AttributesListModel
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          formValues={formValues}
        />
      )}

      {isCreateUpdateModalOpen && (
        <CreateAttribute
          isCreateUpdateModalOpen={isCreateUpdateModalOpen}
          setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
          isEdit={false}
          data={[]}
          selectedRows={[]}
          // getAttributes={() => {}}
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
        />
      )}
      {renderModalContent()}
    </>
  );
};

export default ScenariosTable;
