import React, { useEffect, useMemo, useState } from 'react';
import MiniDrawer from '../common/drawer/DrawerToggle';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from '../../helper/constants';
import axios from 'axios';
import {
  createDimensionFilteredmasterData,
  createPageDetail,
  createViewAggregated,
  getFilterDetail,
  getPageList,
  getPrimaryTimeDimension,
  getSchemaData,
  setAggregatedViewData,
  setLayout,
} from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import PageMaterailList from './PageMaterailList';
import DeletePage from './DeletePage';
import { useLocation, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  createProcess,
  getExcuteProcess,
  getProcessList,
  getScenarios,
} from '../../store/ApiSlice/processSlice';
import GeneralPageLayout from './GeneralPageLayout';
import CanvasModal from '../commonComponent/CanvasModal/CanvasModal';
import moment from 'moment';
import { getProfileData } from '../../store/ApiSlice/profileSlice';
import { getMeasureProfileList } from '../../store/ApiSlice/measuredataslice';
import { PageModel, ProcessLaterModel, ProcessModel, ProcessStepModel } from './RunProcessModel';
import { to } from 'mathjs';


const PageName = () => {
  const today = new Date().toISOString().split('T')[0];
  const { measureProfileDataList } = useSelector((state) => state.measureprofile)
  const { processData, scenarioList } = useSelector((state) => state.process);
  const { enterpriseData } = useSelector((state) => state?.enterprise);
  const { profileData } = useSelector((state) => state.profile)
  const [modalName, setModalName] = useState([]);
  const { canvasData, pageData, layout, workBookData, filterDetail, timeDimensionData,
    schemaData, } = useSelector(
      (state) => state.canvas
    );

  const [formValues, setFormValues] = useState({
    measure: {}
  });
  const [canvasFormValue, setCanvasFormValue] = useState({});
  const [openPageModal, setOpenPageModal] = useState(false);
  const [isEdits, setIsEdits] = useState(false);
  const [updatedId, setUpdatedId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [attriId, setAttriId] = useState(null);
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const [openProcessModel, setOpenProcessModel] = useState(false);
  const [processName, setProcessName] = useState({
    steps: [{}]
  });
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEdit = queryParams.get('isEdit') === 'true';
  const [canvasEdit, setcanvasEdit] = useState(isEdit)
  const [anchorEl, setAnchorEl] = useState(null);
  const model = Boolean(anchorEl);
  const [settingLayoutData, setSettingLayoutData] = useState();
  const [editWorkSheet, setEditWorkSheet] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isCreateWidget, setIsCreateWidget] = useState(false);
  const [value, setValue] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openedWorkbookName, setOpenWorkbookName] = useState({
    pageName: '',
    pageId: '',
  });
  const [openedWorksheet, setOpenedWorkSheet] = useState({});
  const [generalModel, setGeneralModel] = useState('');
  const [activeTab, setActiveTab] = useState(null);
  const [editChart, setEditChart] = useState('');
  const [CanvasOpenModal, setCanvasOpenModal] = useState(false)
  const [processStepDetail, setProcessStepDetail] = useState(false)
  const [processLater, setProcessLater] = useState(false)
  const [widgetGroupData, setWidgetGroupData] = useState([])
  const [newActiveWidgetLoader, setNewActiveWidgetLoader] = useState({})
  const [pageLoader, setPageLoader] = useState(true)
  const [editWidget, setEditWidget] = useState({})

  const canvasFindData = canvasData?.length > 0 && canvasData?.find((item) => item?._id === id);

  const findPageData = pageData?.filter(
    (item) => item?.canvasUID?._id === canvasFindData?._id
  );

  const findProcessEnterpriseData = (processData?.length > 0 && canvasFindData) ?
    processData?.filter((item) => item?.enterpriseUID?._id === canvasFindData?.enterpriseUID._id)
      ?.filter((item) => item?.steps?.some((row) => row?.modelUID?._id === canvasFindData?.modelUID?.modelUID)) : []

  const findProcesSTepDetail = findProcessEnterpriseData?.length > 0 ? findProcessEnterpriseData?.find((item) => item?._id === processName?.processName) : []

  const newPageID = value ? value : findPageData.length > 1 ? findPageData[findPageData.length - 1]._id : findPageData[0]?._id

  const editRow = pageData?.length > 0 && pageData?.find((item) => item._id === updatedId);
  let finalData;

  if (settingLayoutData) {
    finalData = settingLayoutData;
  } else if (
    findPageData &&
    findPageData.length > 0 &&
    findPageData[0]?.widgets &&
    findPageData[0]?.widgets?.length > 0
  ) {
    const worksheets = findPageData[0]?.widgets[0];
    finalData = worksheets;
  }


  useEffect(() => {
    dispatch(getPrimaryTimeDimension({ canvasFindData }))
  }, [canvasFindData])

  useEffect(() => {
    if (timeDimensionData && Object.keys(timeDimensionData).length > 0) {
      dispatch(getSchemaData({ timeDimensionData }));
    }
  }, [timeDimensionData?._id])

  useEffect(() => {
    dispatch(getScenarios())
    dispatch(getProcessList())
    dispatch(getProfileData())
    dispatch(getFilterDetail())
    dispatch(getMeasureProfileList())
  }, [])

  useEffect(() => {
    if (editRow && isEdits) {
      setFormValues({
        pageName: editRow?.name,
        notes: editRow?.notes,
        enterpriseUID: editRow?.enterpriseUID?._id,
        modelUID: editRow?.modelUID.modalUID,
        canvasUID: editRow?.canvasUID?._id,
      });
    }
  }, [editRow, isEdits]);

  useEffect(() => {
    if (canvasFindData && isEdit) {
      setCanvasFormValue({
        canvasName: canvasFindData?.name,
        enterpriseUID: canvasFindData?.enterpriseUID?._id,
        enterpriseName: canvasFindData?.enterpriseUID?.enterpriseName,
        modelName: canvasFindData?.modelUID?.modelName,
        modelUID: canvasFindData?.modelUID?.modalUID,
      });
    }
  }, [isEdit, canvasFindData]);

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseButton = (item) => {
    setAnchorEl(null);
  };

  const getModelList = async () => {
    try {
      const userId = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };

      let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
      let filteredData = response.data?.enterpriseList?.filter(
        (item) => item.enterpriseUID?._id === canvasFormValue.enterpriseUID
      );

      if (canvasFormValue.enterpriseName) {
        filteredData = filteredData?.filter(
          (item) => item.enterpriseUID?._id === canvasFormValue.enterpriseUID
        );
      }
      setModalName(filteredData);
    } catch (error) {
      console.error('Error fetching model list:', error);
    }
  };

  useEffect(() => {
    if (canvasFormValue?.enterpriseUID) {
      getModelList();
    }
  }, [canvasFormValue?.enterpriseUID]);


  const handleDimensionFieldChange = (newValue, fieldName, e) => {
    if (fieldName === 'enterpriseName') {
      const selectedData = enterpriseData.filter(
        (data) => data._id === newValue?._id
      );
      setFormValues({
        ...formValues,
        [fieldName]: selectedData[0]?.enterpriseName,
        [`enterpriseUID`]: newValue?._id,
      });
    } else if (fieldName === 'modelName') {
      const selectedData = modalName.filter(
        (data) => data.modalUID === newValue?.modalUID
      );
      setFormValues({
        ...formValues,
        [fieldName]: selectedData[0]?.modelName,
        [`modelUID`]: newValue?.modalUID,
      });
    } else if (fieldName === 'canvasPageName') {
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: newValue?._id,
        ['canvasName']: newValue?.name,
      }));
    } else {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleTableRowSelect = (event, row) => {
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    const selectedRow = enterpriseData?.find(
      (row) => row._id === row?.original?._id
    );
    setSelectedRows(updatedIds);
  };

  const handleCreateUpdate = async (edit, id) => {
    setUpdatedId(id);
    setOpenPageModal(true);
    setIsEdits(edit);
    await dispatch(getPageList({ canvasFindData }));
  };

  const handleSubmitPage = async () => {
    const payload = {
      name: formValues?.pageName,
      canvasUID: canvasFindData?._id,
      enterpriseUID: canvasFindData?.enterpriseUID?._id,
      modelUID: canvasFindData?.modelUID?.modalUID,
      notes: formValues?.notes,
    };
    const result = await dispatch(
      createPageDetail({ payload, isEdits, updatedId })
    );
    if (result?.meta?.requestStatus === 'fulfilled') {
      // toast.success(
      //   isEdit ? 'Page updated successfully' : 'Page created successfully'
      // );
      setFormValues({});
      setUpdatedId(null);
      setOpenPageModal(false);
      dispatch(getPageList({ canvasFindData }));
    } else if (result?.meta?.requestStatus === 'rejected') {
      toast.error(result?.error?.message);
    }
  };

  const handleCloseProcessModel = () => {
    setOpenProcessModel(false);
    setProcessName({});
  };

  const handleRunProcessField = (newValue, fieldName, e) => {
    if (fieldName === 'processName') {
      setProcessName({
        ...processName,
        [fieldName]: newValue?._id,
      });
    } else {
      setProcessName({
        ...processName,
        [fieldName]: e.target.value,
      });
    }
  };

  const getScenarioData = async () => {
    const body = {
      enterpriseName: canvasFindData?.enterpriseUID?.enterpriseName,
      modelName: canvasFindData?.modelUID?.modelName,
    };
    await dispatch(getScenarios({ body }));
  };

  const processPageData = async (initialResult) => {

    let newPageData = [];
    let newSortPageData = [];
    if (initialResult) {

      const pageDatass = await dispatch(getPageList({ canvasFindData }));
      const newPageDataTest = pageDatass?.payload?.data?.filter(
        (item) => item?.canvasUID?._id === canvasFindData?._id
      );

      for (const PageItem of newPageDataTest) {
        let pageTab = {};

        const pageWidgets = PageItem?.widgets?.filter(
          (page) => page?.widgetUID?.pageUID === newPageID
        );
        const groupedWidgets = pageWidgets?.reduce((groups, widget) => {
          const groupName = widget?.widgetUID?.widgetName;
          if (!groups[groupName]) {
            groups[groupName] = [];
          }
          groups[groupName].push(widget);
          return groups;
        }, {});

        const pageFilter = PageItem?.filter;

        await Promise.all(
          Object.keys(groupedWidgets).map(async (groupName) => {
            const widgets = groupedWidgets[groupName];

            if (widgets?.length > 0 && groupName === initialResult?.widgetName) {
              const findUpdatedWidget = Object.keys(groupedWidgets)
                .filter((groupName) => groupName === initialResult?.widgetName)
                .map((groupName) => groupedWidgets[groupName])
                .flat()
                .find((widget) => widget?.widgetUID?.widgetName === initialResult?.widgetName);

              const lastIndex = findUpdatedWidget?.widgetName === initialResult?.widgetName ?
                widgets?.length - 1
                : widgetGroupData
                  ?.find((item) => item?.PageID === newPageID)
                  ?.pageTab?.[groupName]
                  ?.map((item, index) => ({ item, index }))
                  ?.filter(({ item }) => item.getResul)
                  ?.map(({ index }) => index)
                  ?.pop() || 0;

              widgetGroupData?.find((item) => item.PageID === newPageID)?.pageTab?.[groupName]?.find((item) => item.getResul)
              const defaultActiveWidgets = widgets?.[lastIndex];
              const findFilterId = filterDetail?.find(filter => filter?._id === defaultActiveWidgets?.widgetUID?.filterInfo?.filterRefID);
              const widgetType = defaultActiveWidgets?.widgetUID?.pivotTable
              console.log("defaultActiveWidgets--//->", pageFilter)
              const timeName = defaultActiveWidgets?.widgetUID?.filterInfo?.timeAttribute?.periodType || pageFilter?.timeAttribute?.periodType;
              const isWidgetFilterActive = defaultActiveWidgets?.widgetUID?.isWidgetFilterActive;
              const scenario = isWidgetFilterActive ? defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.scenario : pageFilter?.systemAttributes?.scenario;
              const cycleID = isWidgetFilterActive ? defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.cycleIds : pageFilter?.systemAttributes.cycleIds;
              const lagID = isWidgetFilterActive ? defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.lagIds : pageFilter?.systemAttributes.lagIds;
              const timeAttribute = isWidgetFilterActive? defaultActiveWidgets?.widgetUID?.filterInfo?.timeAttribute : pageFilter?.timeAttribute;
              // widgetType?.widgetUID?.widgetSubType === "Measure Data" ? {
              let result;
              if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                const payload = {
                  enterpriseUID : defaultActiveWidgets?.widgetUID?.enterpriseUID,
                  modelUID : defaultActiveWidgets?.widgetUID?.modelUID,
                  ...(widgetType?.measures?.length > 0 && {
                    measures: widgetType?.measures,
                  }),
                  timeFields: {
                    from: timeAttribute?.from,
                    to: timeAttribute?.to,
                    timePeriodType: defaultActiveWidgets?.widgetUID?.pivotTable?.timePeriodType
                  },
                  aggregationFields: widgetType?.attributes?.map(
                    (attr) => attr?.attributeName
                  ),
                  ...(scenario?.length > 0 && {
                    scenario: scenario,
                  }),
                  ...(cycleID?.length > 0 && {
                    cycleID: cycleID,
                  }),
                  ...(lagID?.length > 0 && {
                    lagID: lagID,
                  }),
                  ...(findFilterId?.attributes?.length > 0 && {
                    additionalFilters: findFilterId.attributes.map((attr) => ({
                      attribute: attr?.attributeUID?.name,
                      operator: attr?.operator,
                      values: attr?.values,
                    })),
                  }),
                }
                console.log("result------123", payload)
                result = await dispatch(createViewAggregated({ payload }));
                if (defaultActiveWidgets?.widgetUID?.widgetType === "Chart") {
                  setLoader({
                    ...loader,
                    [groupName]: false
                  });
                } else if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                  setLoader(false);
                }
              }
              // else {
              //   const payload = {
              //     dimensionUID: defaultActiveWidgets?.widgetUID?.worksheet?.dimension,
              //     attributes: defaultActiveWidgets?.widgetUID?.worksheet?.attributes?.map((item) => item?.attributeName),
              //     ...(findFilterId?.attributes?.length > 0 && {
              //       additionalFilters: findFilterId?.attributes?.map((item) => ({
              //         attribute: item?.attributeUID?.name,
              //         operator: item?.operator,
              //         values: item?.values
              //       }))
              //     }),
              //     widgetUID: defaultActiveWidgets?.widgetUID?.worksheet?._id,
              //     scenario: defaultActiveWidgets?.widgetUID?.scenario?.map((item) => item),
              //   }
              //   result = await dispatch(createDimensionFilteredmasterData({ payload }));
              //   setLoader(false);
              // }

              widgets[Number(lastIndex)] = {
                getResul: result?.payload?.data,
                ...widgets[Number(lastIndex)],
              };
              pageTab = {
                ...pageTab,
                [groupName]: widgets,
              };
            }
            else {
              pageTab = {
                ...pageTab,
                [groupName]: widgetGroupData?.[0]?.pageTab?.[groupName],
              };
            }
          })
        );
        newSortPageData.push({ pageTab, PageID: newPageID });
        newPageData = newSortPageData?.length > 0 && newSortPageData?.map((page) => ({
          ...page,
          pageTab: Object.fromEntries(
            Object.entries(page.pageTab).map(([key, value]) => [
              key,
              Array.isArray(value) ? value.slice().reverse() : value,
            ])
          ),
        }));
      }
    } else {
      let pageDatass
      if (canvasFindData) {
        pageDatass = await dispatch(getPageList({ canvasFindData }));
      }
      const newPageDataTest = Array.isArray(pageDatass?.payload?.data)
        ? pageDatass?.payload?.data.filter(
          (item) => item?.canvasUID?._id === canvasFindData?._id
        )
        : [];
      
      for (const PageItem of newPageDataTest) {
        let pageTab = {};

        const pageWidgets = PageItem?.widgets?.filter(
          (page) => page?.widgetUID?.pageUID === newPageID
        );
        const groupedWidgets = pageWidgets?.length > 0 ? pageWidgets?.reduce((groups, widget) => {
          const groupName = widget?.widgetUID?.widgetName;
          if (!groups[groupName]) {
            groups[groupName] = [];
          }
          groups[groupName].push(widget);
          return groups;
        }, {}) : [];

        const pageFilter = PageItem?.filter;

        // Process each group of widgets
        await Promise.all(
          Object.keys(groupedWidgets).map(async (groupName) => {
            const widgets = groupedWidgets[groupName];
            if (widgets?.length > 0) {
              const defaultActiveWidgets = widgetGroupData?.find((item) => item.PageID === newPageID)?.pageTab?.[groupName]?.find((item) => item.getResul) || widgets[0];
              
              const findFilterId = filterDetail.find(filter => filter?._id === defaultActiveWidgets?.widgetUID?.filterInfo?.filterRefID);
              const widgetType = defaultActiveWidgets?.widgetUID?.pivotTable;
              const timeName = defaultActiveWidgets?.widgetUID?.filterInfo?.timeAttribute?.periodType || pageFilter?.timeAttribute?.periodType;
              const isWidgetFilterActive = defaultActiveWidgets?.widgetUID?.isWidgetFilterActive;
              const scenario = isWidgetFilterActive ? defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.scenario : pageFilter?.systemAttributes?.scenario;
              const cycleID = isWidgetFilterActive ? defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.cycleIds : pageFilter?.systemAttributes.cycleIds;
              const lagID = isWidgetFilterActive ? defaultActiveWidgets?.widgetUID?.filterInfo?.systemAttributes?.lagIds : pageFilter?.systemAttributes.lagIds;
              const timeAttribute = isWidgetFilterActive? defaultActiveWidgets?.widgetUID?.filterInfo?.timeAttribute : pageFilter?.timeAttribute;

              let result;
              if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                const payload = {
                  enterpriseUID : defaultActiveWidgets?.widgetUID?.enterpriseUID,
                  modelUID : defaultActiveWidgets?.widgetUID?.modelUID,
                  ...(widgetType?.measures?.length > 0 && {
                    measures: widgetType?.measures,
                  }),
                  timeFields: {
                    from: timeAttribute?.from,
                    to: timeAttribute?.to,
                    timePeriodType: defaultActiveWidgets?.widgetUID?.pivotTable?.timePeriodType
                  },
                  timeFieldType: widgetType?.timePeriods?.periodType,
                  aggregationFields: widgetType?.attributes?.map(
                    (attr) => attr?.attributeName
                  ),
                  ...(scenario?.length > 0 && {
                    scenario: scenario,
                  }),
                  ...(cycleID?.length > 0 && {
                    cycleID: cycleID,
                  }),
                  ...(lagID?.length > 0 && {
                    lagID: lagID,
                  }),
                  ...(findFilterId?.attributes?.length > 0 && {
                    additionalFilters: findFilterId.attributes.map((attr) => ({
                      attribute: attr?.attributeUID?.name,
                      operator: attr?.operator,
                      values: attr?.values,
                    })),
                  }),
                };

                console.log("result------456", payload)
                result = await dispatch(createViewAggregated({ payload }));
                if (defaultActiveWidgets?.widgetUID?.widgetType === "Chart") {
                  setLoader({
                    ...loader,
                    [groupName]: false
                  });
                } else if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                  setLoader(false);
                }
              } else {
                const payload = {
                  dimensionUID: defaultActiveWidgets?.widgetUID?.worksheet?.dimension,
                  attributes: defaultActiveWidgets?.widgetUID?.worksheet?.attributes?.map((item) => item?.attributeName),
                  ...(findFilterId?.attributes?.length > 0 && {
                    additionalFilters: findFilterId?.attributes?.map((item) => ({
                      attribute: item?.attributeUID?.name,
                      operator: item?.operator,
                      values: item?.values
                    }))
                  }),
                  widgetUID: defaultActiveWidgets?.widgetUID?.worksheet?._id,
                  scenario: defaultActiveWidgets?.widgetUID?.scenario?.map((item) => item),
                }
                result = await dispatch(createDimensionFilteredmasterData({ payload }));
                setLoader(false);
              }
              widgets[0] = {
                ...widgets[0],
                getResul: result?.payload?.data,
              };

              pageTab = {
                ...pageTab,
                [groupName]: widgets,
              };
            } else {
              pageTab = {
                ...pageTab,
                [groupName]: widgets,
              };
            }
          })
        );

        newPageData?.push({ pageTab, PageID: newPageID });
      }
    }
    setWidgetGroupData(newPageData)
  };

  const isEditChartData = useMemo(() => {
    const datas = workBookData?.length > 0 && workBookData?.find((item) => item?._id === editChart);
    return generalModel === 'chart' && editChart
      ? { widgetUID: datas }
      : finalData;
  }, [finalData, editChart, generalModel]);

  const convertTo12HourFormat = (time) => {
    if (!time) return ''; // Return an empty string if time is undefined or empty

    let [hours, minutes, seconds] = time?.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${hours}:${minutes}:${seconds} ${period}`;
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime, 'h:mm:ss A')?.format('HH:mm:ss A');
  };

  const handleTerminate = async (type) => {
    const fomatTime = convertTo12HourFormat(processName?.selectTime)
    const formattedDateTime = formatDateTime(fomatTime);
    const body = {
      processUID: processName?.processName,
      whenExecuted: type,
      ...(type === "Later" && {
        executedOn: `${moment(processName?.selectDate)?.format("DD-MM-YYYY")} ${formattedDateTime}`
      })
    }

    const payload = {
      name: findProcesSTepDetail?.processname,
      notes: findProcesSTepDetail?.processNotes,
      enterpriseUID: findProcesSTepDetail?.enterpriseUID?._id,
      steps: processName?.steps?.map((item, index) => ({
        stepType: item?.stepType,
        stepName: item?.stepName,
        stepOrder: index + 1,
        operatorType: item?.operatorType,
        ...(item?.profile && ({
          profile: item?.profile ? item?.profile : "",
        })),
        modelUID: item?.modelUID,
        ...(item?.filter && ({
          filter: item?.filter ? item?.filter : "",
        })),
        ...(item?.measureProfile && ({
          measureProfile: item?.measureProfile ? item?.measureProfile : "",
        })),
        scenario: item?.scenario,
      }))
    }
    const result = await dispatch(createProcess({ payload, isEdit: true, selectedRows: findProcesSTepDetail?._id }))
    if (result?.meta?.requestStatus === 'fulfilled') {
      dispatch(getProcessList())
      const response = await dispatch(getExcuteProcess(body))
      if (response?.meta?.requestStatus === "fulfilled") {
        handleCloseProcessModel();
        setProcessName({});
        setProcessStepDetail(false)
        setProcessLater(false)
        setOpenProcessModel(false)
      } else if (response?.meta?.requestStatus === "rejected") {
        toast.warn(
          response?.payload?.data?.error
            ? response?.payload?.data?.error
            : "Process is not executing."
        );
      }
    }
  }


  const handleStepChangeValue = (index, newValue, type, e) => {
    const newStep = [...processName?.steps];
    if (type === "modelUID") {
      newStep[index][type] = newValue?._id;
      setProcessName({ ...processName, steps: newStep });
    } else if (type === "scenario") {
      newStep[index][type] = newValue?.scenarioName;
      setProcessName({ ...processName, steps: newStep });
    }
    else {
      const { name, value } = e.target
      newStep[index][type] = value;
      setProcessName({ ...processName, steps: newStep });
    }
  }

  useEffect(() => {
    if (processStepDetail) {
      setProcessName({
        ...processName,
        steps: findProcesSTepDetail?.steps?.map((item) => ({
          stepType: item?.stepType,
          stepName: item?.stepName,
          operatorType: item?.operatorType,
          profile: item?.profile?._id && item?.profile?._id,
          modelUID: item?.modelUID?._id,
          filter: item?.filter,
          scenario: item?.scenario,
          measureProfile: item?.measureProfile,
        }))
      })
    }
  }, [processStepDetail, findProcesSTepDetail])

  return (
    <>
      {/* <Box sx={{ display: 'flex' }}>
        <MiniDrawer />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        > */}
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px 0 0 0'
        }}
      >
        <div>
          <Link
            to="/canvas"
            style={{ color: 'black' }}
            onClick={() => {
              dispatch(setAggregatedViewData([]));
            }}
          >
            <ChevronLeftIcon />
          </Link>
        </div>

        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={model ? 'long-menu' : undefined}
          aria-expanded={model ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(e) => {
            handleClickButton(e);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={model}
          onClose={handleCloseButton}
        >
          <MenuItem
            onClick={() => {
              setOpenProcessModel(true);
              setAnchorEl(null);
            }}
            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
          >
            Run Process
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCanvasOpenModal(true);
              setAnchorEl(null);
            }}
            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
          >
            Rename Canvas
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCreateUpdate(false);
              handleCloseButton();
            }}
            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
          >
            Add Page
          </MenuItem>
        </Menu>
      </div>
      <PageMaterailList
        newPageID={newPageID}
        handleCreateUpdate={handleCreateUpdate}
        handleTableRowSelect={handleTableRowSelect}
        setAttriId={setAttriId}
        setModalOpenDelete={setModalOpenDelete}
        setSettingLayoutData={setSettingLayoutData}
        finalData={isEditChartData}
        loader={loader}
        setLoader={setLoader}
        setEditWorkSheet={setEditWorkSheet}
        setIsCreateWidget={setIsCreateWidget}
        value={value}
        setValue={setValue}
        formValues={formValues}
        setFormValues={setFormValues}
        setOpenModal={setOpenModal}
        setOpenWorkbookName={setOpenWorkbookName}
        setGeneralModel={setGeneralModel}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setOpenedWorkSheet={setOpenedWorkSheet}
        openedWorksheet={openedWorksheet}
        getScenarioData={getScenarioData}
        generalModel={generalModel}
        processPageData={processPageData}
        widgetGroupData={widgetGroupData}
        setWidgetGroupData={setWidgetGroupData}
        newActiveWidgetLoader={newActiveWidgetLoader}
        setNewActiveWidgetLoader={setNewActiveWidgetLoader}
        setEditWidget={setEditWidget}
        pageLoader={pageLoader}
        setPageLoader={setPageLoader}
        openedWorkbookName={openedWorkbookName}
      />
      <Box
        sx={{
          padding: '30px',
        }}
      >
      </Box>
      <DeletePage
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        attriId={attriId}
        setAttriId={setAttriId}
        setModalOpenDelete={setModalOpenDelete}
        isModalOpenDelete={isModalOpenDelete}
      />
      {/* </Box> */}

      {(isCreateWidget || editWorkSheet) && generalModel && (
        <Dialog open={generalModel} onClose={() => setGeneralModel('')}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              overflow: 'auto',
              width: '600px',
            }}
          >
            <GeneralPageLayout
              newPageID={newPageID}
              editWorkSheet={editWorkSheet}
              setEditWorkSheet={setEditWorkSheet}
              setLoader={setLoader}
              isCreateWidget={isCreateWidget}
              value={value}
              formValues={formValues}
              setFormValues={setFormValues}
              openModal={openModal}
              openedWorkbookName={openedWorkbookName}
              setGeneralModel={setGeneralModel}
              finalData={editWidget}
              setActiveTab={setActiveTab}
              generalModel={generalModel}
              processPageData={processPageData}
              widgetGroupData={widgetGroupData}
              setWidgetGroupData={setWidgetGroupData}
              newActiveWidgetLoader={newActiveWidgetLoader}
              setNewActiveWidgetLoader={setNewActiveWidgetLoader}
            />
          </Box>
        </Dialog>
      )}
      {/* </Box> */}
      {openPageModal && (
        <PageModel
          openPageModal={openPageModal}
          setOpenPageModal={setOpenPageModal}
          formValues={formValues}
          handleDimensionFieldChange={handleDimensionFieldChange}
          canvasFindData={canvasFindData}
          handleSubmitPage={handleSubmitPage}
        />
      )}

      {openProcessModel && (
        <ProcessModel
          openProcessModel={openProcessModel}
          handleCloseProcessModel={handleCloseProcessModel}
          findProcessEnterpriseData={findProcessEnterpriseData}
          processName={processName}
          handleRunProcessField={handleRunProcessField}
          today={today}
          setProcessStepDetail={setProcessStepDetail}
          getScenarioData={getScenarioData}
        />
      )}
      {processStepDetail && (
        <ProcessStepModel
          processStepDetail={processStepDetail}
          setProcessStepDetail={setProcessStepDetail}
          processName={processName}
          handleStepChangeValue={handleStepChangeValue}
          modalName={modalName}
          measureProfileDataList={measureProfileDataList}
          profileData={profileData}
          filterDetail={filterDetail}
          scenarioList={scenarioList}
          setOpenProcessModel={setOpenProcessModel}
          handleTerminate={handleTerminate}
          setProcessLater={setProcessLater}
          canvasFindData={canvasFindData}
        />
      )}

      {processLater && (
        <ProcessLaterModel
          processLater={processLater}
          setProcessLater={setProcessLater}
          today={today}
          processName={processName}
          handleRunProcessField={handleRunProcessField}
          setProcessStepDetail={setProcessStepDetail}
          setOpenProcessModel={setOpenProcessModel}
          handleTerminate={handleTerminate}

        />
      )}
      {CanvasOpenModal && (
        <CanvasModal
          formValues={canvasFormValue}
          setFormValues={setCanvasFormValue}
          CanvasOpenModal={CanvasOpenModal}
          setCanvasOpenModal={setCanvasOpenModal}
          updatedId={id}
          isEdit={canvasEdit}
          setIsEdit={setcanvasEdit}
        />
      )}
      {/* </Box> */}
    </>
  );
};

export default PageName;
