import { Autocomplete, Box, Switch, styled, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography, Radio } from '@mui/material'
import React, { useEffect } from 'react'
import { CommonAutoComplete } from '../commonComponent/commonSelect/CommonSelect'
import { CommonNumberInput } from '../commonComponent/input/CommonNumberInput'
import { MdMoreVert } from 'react-icons/md'
import profileFieldData from "../../profileFieldData.json"
import { toast } from 'react-toastify'
const PredictiveTab = ({
    formValues,
    selectedProfile,
    handleChangeProfile,
    isView,
    profileMeasure,
    driverBasedMeasure,
    deleteDemandDriven,
    anchorMeasureEl,
    setAnchorMeasureEl,
    handleClick,
    handleremove,
    handleChangeDriverBasedMeasure,
    handleAddDriverMeasure,
    handleAddPredictivebasicKPI,
    deletePredictiveBasicKPI,
    predictiveBasicKPI,
    anchorPredictiveBasicKPIEl,
    setAnchorPredictiveBasicEl,
    handleChangePredictiveBasicKPI,
    predictiveAdditionalKPI,
    setPredictiveAdditionalKPI,
    handleChangePredictiveAdditionalBasicKPI,
    anchorPredictiveAdditionalKPIEl,
    setAnchorPredictiveAdditionalKPIEl,
    deletePredictiveAdditionalKPI,
    handleAddPredictiveAdditionalKPI,
    handleAddCausalForecasting,
    causalOutcomesForecasting,
    handleChangeCausalForecasting,
    anchorCausalForecastingEl,
    setAnchorCausalForecastingEl,
    deleteCausalForecasting,
    handleAddbasicKPI,
    basicKPI,
    handleChangeBasicKPI,
    deletebasicKPI,
    anchorBasicKPIl,
    setAnchorBasicKPIEl,
    causalForecastModels,
    handleAddCausalForecast,
    handleChangeCausalForecastModels,
    anchorCausalForecastModelsEl,
    setAnchorCausalForecastingModelsEl,
    deleteCausalForecastModelsEnsemble
}) => {
    console.log("🚀 ~ driverBasedMeasure:", driverBasedMeasure)
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        }
    }));

    const findEnterpriseProfile = profileMeasure?.length > 0 ? profileMeasure?.filter((item) => item?.enterpriseUID === formValues?.enterpriseUID)
        ?.filter((item) => item?.modelUID === formValues?.modelUID) : []

    const forecastModelOption = [
        "Random Forest",
        "AdaBoost",
        "Neural Networks",
        "Lower Quartile",
        "Upper Quartile"
    ]

    const labelTransparentStyle = {
        '& .MuiInputLabel-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
        }
    };

    const inputLabelStyles = {
        backgroundColor: 'transparent',
        '&.MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
        }
    };

    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Basic Settings
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px",
                            paddingBottom: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="probabilisticForecstingEngine"
                                    name="probabilisticForecstingEngine"
                                    checked={formValues?.probabilisticForecstingEngine || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "probabilisticForecstingEngine", e);
                                    }}
                                />
                            }
                            label="Probabilistic Causal Forecasting Engine"
                        />
                    </Grid>
                    <Grid item xs={3} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px",
                            paddingBottom: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="sensitivityExplorerEngine"
                                    name="sensitivityExplorerEngine"
                                    checked={formValues?.sensitivityExplorerEngine || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "sensitivityExplorerEngine", e);
                                    }}
                                />
                            }
                            label="Sensitivity Explorer Engine"
                        />
                    </Grid>
                    <Grid item xs={3} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px",
                            paddingBottom: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="publishBestCausalForecastOnly"
                                    name="publishBestCausalForecastOnly"
                                    checked={formValues?.publishBestCausalForecastOnly || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "publishBestCausalForecastOnly", e);
                                    }}
                                />
                            }
                            label="Publish Best Causal Forecast Only"
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Causal Forecast Discrepancy Rank"
                                name="causalForecastDiscrepancyRankMeasureUID"
                                value={formValues?.causalForecastDiscrepancyRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Causal Forecast Quality Rank"
                                name="causalForecastQualityRankMeasureUID"
                                value={formValues?.causalForecastQualityRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Forecast Models
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddCausalForecast}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {causalForecastModels?.length > 0 ? causalForecastModels?.map((item, index) => {
                        return (
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Forecast model"
                                    name="causalForecastEngineForecastModels"
                                    options={Array.isArray(forecastModelOption) ? forecastModelOption : []}
                                    getOptionLabel={(option) => option}
                                    value={
                                        item?.causalForecastEngineForecastModels
                                            ? (forecastModelOption?.length > 0 ? forecastModelOption?.find(
                                                (row) => row == item?.causalForecastEngineForecastModels
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeCausalForecastModels(index, newValue, "causalForecastEngineForecastModels", e);
                                    }}
                                    sx={{
                                        ...labelTransparentStyle,
                                        width: '100%'
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Forecast Model"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={deleteCausalForecastModelsEnsemble ? "long-menu" : undefined}
                                    aria-expanded={deleteCausalForecastModelsEnsemble ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, index, "causalForecastModels")}
                                    size="small"
                                >
                                    <MdMoreVert />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorCausalForecastModelsEl}
                                    open={deleteCausalForecastModelsEnsemble}
                                    onClose={() => { setAnchorCausalForecastingModelsEl(null) }}
                                >
                                    <MenuItem onClick={() => { handleremove("causalForecastModels") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                </Menu>
                            </Grid>
                        )
                    }) : []}
                </Grid>
            </Container>

            {formValues?.probabilisticCausalForecastLevels?.length > 0 && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />

                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Probabilistic Forecast Levels
                    </Typography>{" "}
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {formValues?.probabilisticCausalForecastLevels?.length > 0 && formValues?.probabilisticCausalForecastLevels?.map((item) => {
                                return (
                                    <>
                                        <Grid item xs={4} style={{ marginTop: "8px" }}>
                                            <Typography>{item?.levelDetail}:</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name={item?.levelUID?.name}
                                                    value={item?.levelUID?.name}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    </Container>
                </>
            )}

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Outcomes
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddCausalForecasting}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                {causalOutcomesForecasting?.map((item, index) => {
                    return (
                        <>
                            <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Best Causal Forecast Measure"
                                        name="bestCausalForecastMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName || ''}
                                        value={
                                            item?.bestCausalForecastMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        item?.bestCausalForecastMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeCausalForecasting(index, newValue, "bestCausalForecastMeasureUID", e);
                                        }}
                                        sx={labelTransparentStyle}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Best Causal Forecast Measure"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Baseline Demand"
                                        name="causalForecastBaselineMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName || ''}
                                        value={
                                            item?.causalForecastBaselineMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        item?.causalForecastBaselineMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeCausalForecasting(index, newValue, "causalForecastBaselineMeasureUID", e);
                                        }}
                                        sx={labelTransparentStyle}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Baseline Demand"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2.40} sx={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-readonly" sx={inputLabelStyles} shrink>Uncertainty Distribution</InputLabel>
                                        <Select
                                            labelId="Uncertainty Distribution"
                                            name="uncertaintyDistribution"
                                            id="demo-simple-select-readonly"
                                            value={item?.uncertaintyDistribution}
                                            fullWidth
                                            onChange={(e, newValue) => {
                                                handleChangeCausalForecasting(index, newValue, "uncertaintyDistribution", e);
                                            }}
                                            label="Uncertainty Distribution"
                                            variant="outlined"
                                        >
                                            {profileFieldData?.sensitivityProfileOption
                                                ?.slice() // Create a shallow copy to avoid mutating the original array
                                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                                .map((item) => (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {item?.uncertaintyDistribution === "Normal" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Mean"
                                                name="meanMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.meanMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.meanMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "meanMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Mean"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Standard Deviation"
                                                name="standardDeviationMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.standardDeviationMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.standardDeviationMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "standardDeviationMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Standard Deviation"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Beta" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Alpha 1"
                                                name="alpha1MeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.alpha1MeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.alpha1MeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "alpha1MeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Alpha 1"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Alpha 2"
                                                name="alpha2MeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.alpha2MeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.alpha2MeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "alpha2MeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Alpha 2"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "PERT" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Minimum"
                                                name="minimumMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.minimumMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.minimumMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "minimumMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Minimum"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Most likely"
                                                name="mostLikelyMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.mostLikelyMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.mostLikelyMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "mostLikelyMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Most likely"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid> */}
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Maximum"
                                                name="maximumMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.maximumMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.maximumMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "maximumMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Maximum"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Exponential" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Beta"
                                                name="betaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.betaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.betaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "betaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Beta"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Trangular" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Bottom %"
                                                name="bottomPercentageMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.bottomPercentageMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.bottomPercentageMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "bottomPercentageMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Bottom %"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Top %"
                                                name="topPercentageMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.topPercentageMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.topPercentageMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "topPercentageMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Top %"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Lognormal" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Mean"
                                                name="meanMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.meanMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.meanMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "meanMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Mean"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Standard Deviation"
                                                name="standardDeviationMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.standardDeviationMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.standardDeviationMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "standardDeviationMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Standard Deviation"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Weibull" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Alpha"
                                                name="alphaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.alphaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.alphaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "alphaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Alpha"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Beta"
                                                name="betaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.betaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.betaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "betaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Beta"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Uniform" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Minimum"
                                                name="minimumMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.minimumMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.minimumMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "minimumMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Minimum"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Maximum"
                                                name="maximumMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.maximumMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.maximumMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "maximumMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Maximum"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Bernoulli" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Probability"
                                                name="probabilityMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.probabilityMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.probabilityMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "probabilityMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Probability"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Gamma" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Alpha"
                                                name="alphaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.alphaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.alphaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "alphaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Alpha"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Beta"
                                                name="betaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.betaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.betaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "betaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Beta"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "General" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Minimum"
                                                name="minimumMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.minimumMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.minimumMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "minimumMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Minimum"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Maximum"
                                                name="maximumMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.maximumMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.maximumMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "maximumMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Maximum"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Bionomial" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Probability"
                                                name="probabilityMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.probabilityMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.probabilityMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "probabilityMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Probability"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Count"
                                                name="countMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.countMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.countMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "countMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Count"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Histogram" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Probability"
                                                name="probabilityMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.probabilityMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.probabilityMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "probabilityMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Probability"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Pareto" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Theta"
                                                name="thetaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.thetaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.thetaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "thetaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Theta"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Alpha"
                                                name="alphaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.alphaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.alphaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "alphaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Alpha"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Student's" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Degrees of Freedom"
                                                name="degreesOfFreedomMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.degreesOfFreedomMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.degreesOfFreedomMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "degreesOfFreedomMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Degrees of Freedom"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Poisson" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Lambda"
                                                name="lambdaMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.lambdaMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.lambdaMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "lambdaMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Lambda"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Geometric" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Probability"
                                                name="probabilityMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.probabilityMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.probabilityMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "probabilityMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Probability"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Hypergeometric" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Sample Size"
                                                name="sampleSizeMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.sampleSizeMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.sampleSizeMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "sampleSizeMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Sample Size"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Population"
                                                name="populationMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.populationMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.populationMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "populationMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Population"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={2.40} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={2.40} sx={{ flex: 1 }}></Grid> */}
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Population Size"
                                                name="populationSizeMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.populationSizeMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.populationSizeMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "populationSizeMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Population Size"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Negative Bionomial" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Probability of a Single Success"
                                                name="probabilityOfASingleSuccessMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.probabilityOfASingleSuccessMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.probabilityOfASingleSuccessMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "probabilityOfASingleSuccessMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Probability of a Single Success"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="# of Successes "
                                                name="noOfSuccessesMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.noOfSuccessesMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.noOfSuccessesMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "noOfSuccessesMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="# of Successes "
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Discrete" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Possible Values"
                                                name="possibleValuesMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.possibleValuesMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.possibleValuesMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "possibleValuesMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Possible Values"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Probability weight"
                                                name="probabilityWeightMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.probabilityWeightMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.probabilityWeightMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "probabilityWeightMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Probability weight"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {item?.uncertaintyDistribution === "Discrete Uniform" && (
                                    <>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Possible Values"
                                                name="possibleValuesMeasureUID"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName}
                                                value={
                                                    item?.possibleValuesMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.possibleValuesMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "possibleValuesMeasureUID", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Possible Values"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={2.40}>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                id="exploreSensitivity"
                                                name="exploreSensitivity"
                                                checked={Boolean(item?.exploreSensitivity) || false}
                                                onChange={(e, newValue) => {
                                                    handleChangeCausalForecasting(index, newValue, "exploreSensitivity", e);
                                                }}
                                                onClick={(e) => {
                                                    // Manually toggle state when the radio is clicked again
                                                    if (item?.exploreSensitivity) {
                                                        handleChangeCausalForecasting(index, null, "exploreSensitivity", e, true);
                                                    }
                                                }}
                                                style={{
                                                    padding: "0px !important",
                                                    height: "54px",
                                                    margin: "0px 19px"
                                                }}
                                            />
                                        }
                                        label="Explore Sensitivity"
                                    />
                                </Grid>
                                <Grid item xs={2.40} sx={{ width: "100%", flex: 1, display: "flex", alignItems: "center" }}>
                                    {item?.exploreSensitivity && (
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-readonly" sx={inputLabelStyles} shrink>Probabilistic Metrics</InputLabel>
                                            <Select
                                                labelId="Probabilistic Metrics"
                                                name="probabilisticMetric"
                                                id="demo-simple-select-readonly"
                                                value={item?.probabilisticMetric || ""}
                                                // value={item?.probabilisticMetric || []}
                                                fullWidth
                                                // multiple
                                                onChange={(e) => {
                                                    handleChangeCausalForecasting(index, e.target.value, "probabilisticMetric", e);
                                                }}
                                                label="Probabilistic Metrics"
                                                variant="outlined"
                                            // renderValue={(selected) => selected.join(", ")}
                                            >
                                                {profileFieldData?.probabilisticMetricsOption.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {/* <Checkbox
                                                            checked={formValues?.probabilisticMetricsOverview?.includes(option)}
                                                        /> */}
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deleteCausalForecasting ? "long-menu" : undefined}
                                        aria-expanded={deleteCausalForecasting ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "causalForecastProfile")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorCausalForecastingEl}
                                        open={deleteCausalForecasting}
                                        onClose={() => { setAnchorCausalForecastingEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("causalForecastProfile") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </>
                    )
                })}
            </Container >

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            ></Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Drivers
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddDriverMeasure}>
                    +
                </Button>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <div style={{ marginTop: "10px" }}>
                    {driverBasedMeasure?.length > 0 && driverBasedMeasure?.map((item, index) => {
                        return (
                            <>
                                <Box sx={{ width: "100%" }}>
                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Driver Measure"
                                                name="measure"
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option?.measureName || ''}
                                                value={
                                                    item?.measure
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) =>
                                                                row?._id ==
                                                                item?.measure
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeDriverBasedMeasure(index, newValue, "measure", e);
                                                }}
                                                sx={labelTransparentStyle}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Demand Driver Measure"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-readonly" sx={inputLabelStyles} shrink>Driver Type</InputLabel>
                                                <Select
                                                    labelId="Driver Type"
                                                    name="driverType"
                                                    id="demo-simple-select-readonly"
                                                    value={item?.driverType || ""}
                                                    fullWidth
                                                    onChange={(e, newValue) => {
                                                        handleChangeDriverBasedMeasure(index, newValue, "driverType", e);
                                                    }}
                                                    label="Driver Type"
                                                    variant="outlined"
                                                >
                                                    <MenuItem value="Continuous" selected>Continuous</MenuItem>
                                                    <MenuItem value="Discrete">Discrete</MenuItem>
                                                    <MenuItem value="Binary">Binary</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="offset"
                                                    name="offset"
                                                    type="number"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Offset"
                                                    value={item?.offset || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        if (newValue >= 0 && newValue <= 10) {
                                                            handleChangeDriverBasedMeasure(index, newValue, "offset", e);
                                                        } else if (newValue > 10) {
                                                            handleChangeDriverBasedMeasure(index, 10, "offset", e);
                                                        } else {
                                                            handleChangeDriverBasedMeasure(index, 0, "offset", e);
                                                        }
                                                    }}
                                                    sx={labelTransparentStyle}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter offset value"
                                                    inputProps={{ min: 0, max: 10 }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ flex: 1 }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="uncertaintyAdjustmentFactor"
                                                    name="uncertaintyAdjustmentFactor"
                                                    type="number"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Uncertainty Adjustment Factor"
                                                    value={item?.uncertaintyAdjustmentFactor || ''}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value;

                                                        // Allow only digits and prevent the value from exceeding 9999
                                                        if (/^\d*$/.test(newValue)) {  // This regex ensures only digits are entered
                                                            newValue = Number(newValue);
                                                            if (newValue >= 0 && newValue <= 9999) {
                                                                handleChangeDriverBasedMeasure(index, newValue, "uncertaintyAdjustmentFactor", e);
                                                            } else if (newValue > 9999) {
                                                                toast.error("Please enter a value between from 0 to 9999.")
                                                                return
                                                                // handleChangeDriverBasedMeasure(index, 9999, "uncertaintyAdjustmentFactor", e);
                                                            }
                                                        }
                                                    }}
                                                    sx={labelTransparentStyle}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter Uncertainty Adjustment Factor"
                                                    inputProps={{ min: 0, max: 10 }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2.40} sx={{ width: "100%", flex: 1, display: "flex", alignItems: "center" }}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="demo-simple-select-readonly"
                                                    labelId="Driver Importance Measure"
                                                    name="importanceMeasure"
                                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                    getOptionLabel={(option) => option?.measureName}
                                                    value={
                                                        item?.importanceMeasure
                                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                (row) =>
                                                                    row?._id ==
                                                                    item?.importanceMeasure
                                                            ) || null : [])
                                                            : null
                                                    }
                                                    onChange={(e, newValue) => {
                                                        handleChangeDriverBasedMeasure(index, newValue, "importanceMeasure", e);
                                                    }}
                                                    sx={labelTransparentStyle}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Driver Importance Measure"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={deleteDemandDriven ? "long-menu" : undefined}
                                                aria-expanded={deleteDemandDriven ? "true" : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, index, "demandDriven")}
                                                size="small"
                                            >
                                                <MdMoreVert />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    "aria-labelledby": "long-button",
                                                }}
                                                anchorEl={anchorMeasureEl}
                                                open={deleteDemandDriven}
                                                onClose={() => { setAnchorMeasureEl(null) }}
                                            >
                                                <MenuItem onClick={() => { handleremove("demandDriven") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                            </Menu>
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid container spacing={2} style={{ width: "calc(100% - 200px)", marginLeft: "236px" }}>
                                            <Grid item xs={3} sx={{ flex: 1 }}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Driver Contribution Measure"
                                                        name="contributionMeasure"
                                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                        getOptionLabel={(option) => option?.measureName}
                                                        value={
                                                            item?.contributionMeasure
                                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                    (row) =>
                                                                        row?._id ==
                                                                        item?.contributionMeasure
                                                                ) || null : [])
                                                                : null
                                                        }
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverBasedMeasure(index, newValue, "contributionMeasure", e);
                                                        }}
                                                        sx={labelTransparentStyle}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Driver Contribution Measure"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} sx={{ flex: 1 }}>
                                                <FormControlLabel
                                                    style={{
                                                        marginLeft: "0",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            id="exploreSensitivity"
                                                            name="exploreSensitivity"
                                                            checked={item?.exploreSensitivity || false}
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "exploreSensitivity", e);
                                                            }}
                                                            disabled={isView}
                                                            style={{
                                                                padding: "0",
                                                                paddingRight: "6px",
                                                                height: "54px",
                                                            }}
                                                        />
                                                    }
                                                    label="Explore Sensitivity"
                                                />
                                            </Grid>
                                            <Grid item xs={3} sx={{ flex: 1 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-readonly" sx={inputLabelStyles} shrink>Uncertainty Discovery Engine</InputLabel>
                                                    <Select
                                                        labelId="Uncertainty Discovery Engine"
                                                        name="uncertaintyDistribution"
                                                        id="demo-simple-select-readonly"
                                                        value={item?.uncertaintyDistribution || ""}
                                                        fullWidth
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverBasedMeasure(index, newValue, "uncertaintyDistribution", e);
                                                        }}
                                                        label="Uncertainty Discovery Engine"
                                                        variant="outlined"
                                                    >
                                                        {profileFieldData?.sensitivityProfileOption
                                                            ?.slice() // Create a shallow copy to avoid mutating the original array
                                                            .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                                            .map((item) => (
                                                                <MenuItem value={item} key={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            ))}

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {item?.uncertaintyDistribution === "Normal" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Mean"
                                                            name="meanMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.meanMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.meanMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "meanMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Mean"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Standard Deviation"
                                                            name="standardDeviationMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.standardDeviationMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.standardDeviationMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "standardDeviationMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Standard Deviation"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Beta" && (
                                                <>

                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Alpha 1"
                                                            name="alpha1MeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.alpha1MeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.alpha1MeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "alpha1MeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alpha 1"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Alpha 2"
                                                            name="alpha2MeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.alpha2MeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.alpha2MeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "alpha2MeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alpha 2"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "PERT" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Minimum"
                                                            name="minimumMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.minimumMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.minimumMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "minimumMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Minimum"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Most likely"
                                                            name="mostLikelyMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.mostLikelyMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.mostLikelyMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "mostLikelyMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Most likely"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Maximum"
                                                            name="maximumMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.maximumMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.maximumMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "maximumMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Maximum"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Exponential" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Beta"
                                                            name="betaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.betaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.betaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "betaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Beta"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Trangular" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Bottom %"
                                                            name="bottomPercentageMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.bottomPercentageMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.bottomPercentageMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "bottomPercentageMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Bottom %"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Top %"
                                                            name="topPercentageMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.topPercentageMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.topPercentageMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "topPercentageMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Top %"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Lognormal" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Mean"
                                                            name="meanMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.meanMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.meanMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "meanMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Mean"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Standard Deviation"
                                                            name="standardDeviationMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.standardDeviationMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.standardDeviationMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "standardDeviationMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Standard Deviation"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Weibull" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Alpha"
                                                            name="alphaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.alphaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.alphaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "alphaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alpha"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Beta"
                                                            name="betaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.betaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.betaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "betaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Beta"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Uniform" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Minimum"
                                                            name="minimumMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.minimumMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.minimumMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "minimumMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Minimum"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Maximum"
                                                            name="maximumMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.maximumMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.maximumMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "maximumMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Maximum"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Bernoulli" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Probability"
                                                            name="probabilityMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.probabilityMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.probabilityMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Probability"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Gamma" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Alpha"
                                                            name="alphaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.alphaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.alphaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "alphaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alpha"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Beta"
                                                            name="betaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.betaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.betaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "betaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Beta"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "General" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Minimum"
                                                            name="minimumMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.minimumMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.minimumMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "minimumMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Minimum"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Maximum"
                                                            name="maximumMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.maximumMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.maximumMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "maximumMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Maximum"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Bionomial" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Probability"
                                                            name="probabilityMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.probabilityMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.probabilityMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Probability"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Count"
                                                            name="countMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.countMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.countMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "countMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Count"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Histogram" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Probability"
                                                            name="probabilityMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.probabilityMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.probabilityMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Probability"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Pareto" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Theta"
                                                            name="thetaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.thetaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.thetaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "thetaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Theta"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Alpha"
                                                            name="alphaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.alphaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.alphaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "alphaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alpha"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Student's" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Degrees of Freedom"
                                                            name="degreesOfFreedomMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.degreesOfFreedomMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.degreesOfFreedomMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "degreesOfFreedomMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Degrees of Freedom"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Poisson" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Lambda"
                                                            name="lambdaMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.lambdaMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.lambdaMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "lambdaMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Lambda"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Geometric" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Probability"
                                                            name="probabilityMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.probabilityMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.probabilityMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "probabilityMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Probability"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Hypergeometric" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Sample Size"
                                                            name="sampleSizeMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.sampleSizeMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.sampleSizeMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "sampleSizeMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Sample Size"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Population"
                                                            name="populationMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.populationMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.populationMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "populationMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Population"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    {/* <Grid item xs={3} sx={{ flex: 1 }}></Grid>
                                                        <Grid item xs={3} sx={{ flex: 1 }}></Grid> */}
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Population Size"
                                                            name="populationSizeMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.populationSizeMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.populationSizeMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "populationSizeMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Population Size"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Negative Bionomial" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Probability of a Single Success"
                                                            name="probabilityOfASingleSuccessMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.probabilityOfASingleSuccessMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.probabilityOfASingleSuccessMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "probabilityOfASingleSuccessMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Probability of a Single Success"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="# of Successes "
                                                            name="noOfSuccessesMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.noOfSuccessesMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.noOfSuccessesMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "noOfSuccessesMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="# of Successes "
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Discrete" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Possible Values"
                                                            name="possibleValuesMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.possibleValuesMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.possibleValuesMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "possibleValuesMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Possible Values"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Probability weight"
                                                            name="probabilityWeightMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.probabilityWeightMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.probabilityWeightMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "probabilityWeightMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Probability weight"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.uncertaintyDistribution === "Discrete Uniform" && (
                                                <>
                                                    <Grid item xs={3} sx={{ flex: 1 }}>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Possible Values"
                                                            name="possibleValuesMeasureUID"
                                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                            getOptionLabel={(option) => option?.measureName}
                                                            value={
                                                                item?.possibleValuesMeasureUID
                                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                                        (row) =>
                                                                            row?._id ==
                                                                            item?.possibleValuesMeasureUID
                                                                    ) || null : [])
                                                                    : null
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "possibleValuesMeasureUID", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Possible Values"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.exploreSensitivity && (
                                                <Grid item xs={3} sx={{ flex: 1 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-readonly" sx={inputLabelStyles} shrink>Variation Method</InputLabel>
                                                        <Select
                                                            labelId="Variation Method"
                                                            name="variationMethod"
                                                            id="demo-simple-select-readonly"
                                                            value={item?.variationMethod || ""}
                                                            fullWidth
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "variationMethod", e);
                                                            }}
                                                            label="Variation Method"
                                                            variant="outlined"
                                                        >
                                                            {profileFieldData?.variationMethodOption
                                                                ?.slice() // Create a shallow copy to avoid mutating the original array
                                                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                                                .map((item) => (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                            {item?.variationMethod === "Ranges" && item?.exploreSensitivity && (
                                                <>
                                                    <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="minChangePercentage"
                                                                name="minChangePercentage"
                                                                type="number"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Min Change %"
                                                                value={item?.minChangePercentage || ''}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "minChangePercentage", e);
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                sx={labelTransparentStyle}
                                                                inputProps={{ min: 0, max: 10 }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="maxChangePercentage"
                                                                name="maxChangePercentage"
                                                                type="number"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Max Change %"
                                                                value={item?.maxChangePercentage || ''}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "maxChangePercentage", e);
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                sx={labelTransparentStyle}
                                                                inputProps={{ min: 0, max: 10 }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="stepSizePercentage"
                                                                name="stepSizePercentage"
                                                                type="number"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Step Size %"
                                                                value={item?.stepSizePercentage || ''}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "stepSizePercentage", e);
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                sx={labelTransparentStyle}
                                                                inputProps={{ min: 0, max: 10 }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )}

                                            {item?.variationMethod === "Percentiles" && item?.exploreSensitivity && (
                                                <>
                                                    <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="percentiles"
                                                                name="percentiles"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Percentiles"
                                                                value={item?.percentiles?.join(",") || ''}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "percentiles", e);
                                                                }}
                                                                sx={labelTransparentStyle}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.variationMethod === "Fixed" && item?.exploreSensitivity && (
                                                <>
                                                    <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="fixedValues"
                                                                name="fixedValues"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Fixed value"
                                                                value={item?.fixedValues?.join(",") || ''}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "fixedValues", e);
                                                                }}
                                                                sx={labelTransparentStyle}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.variationMethod === "Random" && item?.exploreSensitivity && (
                                                <>
                                                    <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="randomValue"
                                                                name="randomValue"
                                                                type="number"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Random value"
                                                                value={item?.randomValue}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverBasedMeasure(index, newValue, "randomValue", e);
                                                                }}
                                                                sx={labelTransparentStyle}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )}
                                            {item?.exploreSensitivity && (
                                                <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="noOfSimulation"
                                                            name="noOfSimulation"
                                                            type="number"
                                                            variant="outlined"
                                                            fullWidth
                                                            label="# of Simulations"
                                                            value={item?.noOfSimulation || ''}
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "noOfSimulation", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{ min: 0, max: 10 }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )}
                                            {!item?.exploreSensitivity && (
                                                <Grid item xs={3} sx={{ flex: 1 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-readonly" shrink>Base Value</InputLabel>
                                                        <Select
                                                            labelId="Base Value"
                                                            name="baseValueType"
                                                            id="demo-simple-select-readonly"
                                                            value={item?.baseValueType || ""}
                                                            fullWidth
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "baseValueType", e);
                                                            }}
                                                            label="Base Value"
                                                            variant="outlined"
                                                        >
                                                            {profileFieldData?.baseValueOption
                                                                ?.slice() // Create a shallow copy to avoid mutating the original array
                                                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                                                .map((item) => (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                            {item?.baseValueType === "Percentile" && (
                                                <Grid Grid item xs={3} sx={{ flex: 1 }}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="baseValueNumber"
                                                            name="baseValueNumber"
                                                            variant="outlined"
                                                            fullWidth
                                                            label="base Value Number"
                                                            value={item?.baseValueNumber}
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverBasedMeasure(index, newValue, "baseValueNumber", e);
                                                            }}
                                                            sx={labelTransparentStyle}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box >
                            </>
                        )
                    })}
                </div>
            </Container >

            {/* <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Basic KPI Measures
                </Typography>{" "}
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                {formValues?.isCheckedbasicKPIError && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {predictiveBasicKPI?.length > 0 ? predictiveBasicKPI?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="text"
                                                    id="error"
                                                    name="error"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Error"
                                                    value={"Error"}
                                                    placeholder="Enter default value"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Error"
                                                name="bestCausalForecastErrorMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastErrorMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastErrorMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastErrorMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Error"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Moving Average Error"
                                                name="bestCausalForecastMovingAverageErrorMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastMovingAverageErrorMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastMovingAverageErrorMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastMovingAverageErrorMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Moving Average Error"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Relative Error"
                                                name="bestCausalForecastRelativeErrorMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastRelativeErrorMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastRelativeErrorMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastRelativeErrorMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Relative Error"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Error Rank"
                                                name="bestCausalForecastErrorRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastErrorRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastErrorRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastErrorRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Error Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Accuracy Rank"
                                                name="bestCausalForecastAccuracyRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastAccuracyRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastAccuracyRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastAccuracyRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Accuracy Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Error FVA"
                                                name="bestCausalForecastErrorFVAMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastErrorFVAMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastErrorFVAMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastErrorFVAMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Error FVA"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                )
                            }) : []}
                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIBias && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {predictiveBasicKPI?.length > 0 ? predictiveBasicKPI?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="text"
                                                    id="Bias"
                                                    name="Bias"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Bias"
                                                    value={"Bias"}
                                                    placeholder="Enter default value"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Bias"
                                                name="bestCausalForecastBiasMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastBiasMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastBiasMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastBiasMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Bias"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Moving Average Bias"
                                                name="bestCausalForecastMovingAverageBiasMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastMovingAverageBiasMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastMovingAverageBiasMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastMovingAverageBiasMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Moving Average Bias"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Relative Bias"
                                                name="bestCausalForecastRelativeBiasMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastRelativeBiasMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastRelativeBiasMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastRelativeBiasMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Relative Bias"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Bias Rank"
                                                name="bestCausalForecastBiasRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastBiasRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastBiasRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastBiasRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Bias Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Neutrality Rank"
                                                name="bestCausalForecastNeutralityRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastNeutralityRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastNeutralityRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastNeutralityRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Neutrality Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Bias FVA"
                                                name="bestCausalForecastBiasFVAMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastBiasFVAMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastBiasFVAMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastBiasFVAMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Bias FVA"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                )
                            }) : []}
                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIChurn && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {predictiveBasicKPI?.length > 0 ? predictiveBasicKPI?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    type="text"
                                                    id="Churn"
                                                    name="Churn"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Churn"
                                                    value={"Churn"}
                                                    placeholder="Enter default value"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Churn"
                                                name="bestCausalForecastChurnMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastChurnMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastChurnMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastChurnMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Churn"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Moving Average Churn"
                                                name="bestCausalForecastMovingAverageChurnMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastMovingAverageChurnMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastMovingAverageChurnMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastMovingAverageChurnMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Moving Average Churn"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Relative Churn"
                                                name="bestCausalForecastRelativeChurnMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastRelativeChurnMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastRelativeChurnMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastRelativeChurnMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Relative Churn"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Churn Rank"
                                                name="bestCausalForecastChurnRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastChurnRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastChurnRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastChurnRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Churn Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Stability Rank"
                                                name="bestCausalForecastStabilityRankMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastStabilityRankMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastStabilityRankMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastStabilityRankMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Stability Rank"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Best Causal Forecast Churn FVA"
                                                name="bestCausalForecastChurnFVAMeasureUID"
                                                // options={profileMeasure}
                                                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                                getOptionLabel={(option) => option.measureName}
                                                value={
                                                    formValues?.bestCausalForecastChurnFVAMeasureUID
                                                        ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                            (row) => row?._id == formValues?.bestCausalForecastChurnFVAMeasureUID
                                                        ) || null : [])
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(
                                                        newValue,
                                                        "bestCausalForecastChurnFVAMeasureUID",
                                                        e);
                                                }}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Best Causal Forecast Churn FVA"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                )
                            }) : []}
                        </Grid>
                    </>
                )}
            </Container> */}

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Additional KPIs
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddPredictiveAdditionalKPI}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {predictiveAdditionalKPI?.length > 0 ? predictiveAdditionalKPI?.map((item, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="objective" sx={inputLabelStyles} shrink>Additional KPI</InputLabel>
                                        <Select
                                            labelId="objective"
                                            name="predictiveAdditionalKPI"
                                            id="demo-simple-select-readonly"
                                            value={item?.predictiveAdditionalKPI || ""}
                                            fullWidth
                                            onChange={(e, newValue) => {
                                                handleChangePredictiveAdditionalBasicKPI(index,
                                                    newValue,
                                                    "predictiveAdditionalKPI",
                                                    e);
                                            }}
                                            label="Additional KPI"
                                            variant="outlined"
                                        >
                                            <MenuItem value="Elasticity">Elasticity</MenuItem>
                                            <MenuItem value="Cross-Elasticity">Cross-Elasticity</MenuItem>
                                            <MenuItem value="Hazard Rate">Hazard Rate</MenuItem>
                                            <MenuItem value="Slope">Slope</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Additional KPI Measure"
                                        name="KPIMeasure"
                                        // options={profileMeasure}
                                        options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                                        getOptionLabel={(option) => option.measureName}
                                        value={
                                            item?.KPIMeasure
                                                ? (findEnterpriseProfile?.length > 0 ? findEnterpriseProfile?.find(
                                                    (row) => row?._id == item?.KPIMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangePredictiveAdditionalBasicKPI(index,
                                                newValue,
                                                "KPIMeasure",
                                                e);
                                        }}
                                        sx={{
                                            ...labelTransparentStyle,
                                            width: '100%'
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Additional KPI Measure"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deletePredictiveAdditionalKPI ? "long-menu" : undefined}
                                        aria-expanded={deletePredictiveAdditionalKPI ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "predictiveAdditionalKPIMeasure")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorPredictiveAdditionalKPIEl}
                                        open={deletePredictiveAdditionalKPI}
                                        onClose={() => { setAnchorPredictiveAdditionalKPIEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("predictiveAdditionalKPIMeasure") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    }) : []
                    }
                </Grid>
            </Container>
        </>
    )
}

export default PredictiveTab
