import { Autocomplete, Box, Button, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, styled, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { MdMoreVert } from 'react-icons/md'
import { CommonNumberInput } from '../commonComponent/input/CommonNumberInput'
import profileFieldData from "../../profileFieldData.json"

const ExtrapolativeTab = ({
    formValues,
    handleChangeProfile,
    profileMeasure,
    handleClick,
    handleremove,
    basicKPI,
    handleChangeBasicKPI,
    handleAddbasicKPI,
    setBasicKPI,
    deletebasicKPI,
    anchorBasicKPIl,
    setAnchorBasicKPIEl
}) => {
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        }
    }));
    const findEnterpriseProfile = profileMeasure?.filter((item) => item?.enterpriseUID === formValues?.enterpriseUID)
        ?.filter((item) => item?.modelUID === formValues?.modelUID)

    const forecastModelOption = [
        "Moving Average",
        "Exponential Smoothing",
        "ARIMA",
        "Croston's",
        "Random Forest",
        "AdaBoost",
        "Neural Networks",
        "Lower Quartile",
        "Upper Quartile"
    ]

    const labelTransparentStyle = {
        '& .MuiInputLabel-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
        }
    };

    return (
        <>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Basic Settings
                </Typography>{" "}
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px", paddingBottom: "10px" }}>
                    <Grid item xs={3} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="probabilisticStatisticalForecastingEngine"
                                    name="probabilisticStatisticalForecastingEngine"
                                    checked={formValues?.probabilisticStatisticalForecastingEngine || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "probabilisticStatisticalForecastingEngine", e);
                                    }}
                                />
                            }
                            label="Probabilistic Statistical Forecasting Engine"
                        />
                    </Grid>
                    <Grid item xs={3} sx={{
                        '& .MuiFormControlLabel-root': {
                            marginTop: '7px',
                            gap: '10px',
                            paddingLeft: "10px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="publishBestStatForecastOnly"
                                    name="publishBestStatForecastOnly"
                                    checked={formValues?.publishBestStatForecastOnly || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "publishBestStatForecastOnly", e);
                                    }}
                                />
                            }
                            label="Publish Best Stat Forecast Only"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Best Statistical Forecast Measure"
                            name="bestStatisticalForecastMeasureUID"
                            // options={profileMeasure}
                            options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                            getOptionLabel={(option) => option.measureName}
                            value={
                                formValues?.bestStatisticalForecastMeasureUID
                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                        (row) => row?._id == formValues?.bestStatisticalForecastMeasureUID
                                    ) || null : [])
                                    : null
                            }
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "bestStatisticalForecastMeasureUID", e);
                            }}
                            sx={{
                                ...labelTransparentStyle,
                                width: "100%",
                              }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Best Statistical Forecast Measure"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Best Statistical Forecast Discrepancy Rank Measure"
                            name="bestStatisticalForecastDiscrepancyRankMeasureUID"
                            // options={profileMeasure}
                            options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                            getOptionLabel={(option) => option.measureName}
                            value={
                                formValues?.bestStatisticalForecastDiscrepancyRankMeasureUID
                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                        (row) => row?._id == formValues?.bestStatisticalForecastDiscrepancyRankMeasureUID
                                    ) || null : [])
                                    : null
                            }
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "bestStatisticalForecastDiscrepancyRankMeasureUID", e);
                            }}
                            sx={{
                                ...labelTransparentStyle,
                                width: "100%",
                              }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Best Statistical Forecast Discrepancy Rank Measure"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Best Statistical Forecast Quality Rank Measure"
                            name="bestStatisticalForecastQualityRankMeasureUID"
                            // options={profileMeasure}
                            options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                            getOptionLabel={(option) => option.measureName}
                            value={
                                formValues?.bestStatisticalForecastQualityRankMeasureUID
                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                        (row) => row?._id == formValues?.bestStatisticalForecastQualityRankMeasureUID
                                    ) || null : [])
                                    : null
                            }
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "bestStatisticalForecastQualityRankMeasureUID", e);
                            }}
                            sx={{
                                ...labelTransparentStyle,
                                width: "100%",
                              }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Best Statistical Forecast Quality Rank Measure"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Container >
            {formValues?.probabilisticStatisticalForecastingEngine && formValues?.ProbabilisticStatisticalForecastLevels?.length > 0 && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />

                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Probabilistic Forecast Levels
                    </Typography>{" "}
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {formValues?.ProbabilisticStatisticalForecastLevels?.length > 0 && formValues?.ProbabilisticStatisticalForecastLevels?.map((item) => {
                                return (
                                    <>
                                        <Grid item xs={4} style={{ marginTop: "8px" }}>
                                            <Typography>{item?.levelDetail}:</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name={item?.levelUID?.name}
                                                    value={item?.levelUID?.name}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    </Container>
                </>
            )}

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Forecast Models
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddbasicKPI}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {basicKPI?.length > 0 ? basicKPI?.map((item, index) => {
                        return (
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Forecast model"
                                    name="StatisticalForecastModels"
                                    options={Array.isArray(forecastModelOption) ? forecastModelOption : []}
                                    getOptionLabel={(option) => option}
                                    value={
                                        item?.StatisticalForecastModels
                                            ? (forecastModelOption?.length > 0 ? forecastModelOption?.find(
                                                (row) => row == item?.StatisticalForecastModels
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeBasicKPI(index, newValue, "StatisticalForecastModels", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Forecast Model"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={deletebasicKPI ? "long-menu" : undefined}
                                    aria-expanded={deletebasicKPI ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, index, "basicKPI")}
                                    size="small"
                                >
                                    <MdMoreVert />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorBasicKPIl}
                                    open={deletebasicKPI}
                                    onClose={() => { setAnchorBasicKPIEl(null) }}
                                >
                                    <MenuItem onClick={() => { handleremove("basicKPI") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                </Menu>
                            </Grid>
                        )
                    }) : []}
                </Grid>
            </Container>

            {/* <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Basic KPI Measures
                </Typography>{" "}
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                {formValues?.isCheckedbasicKPIError && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="error"
                                        name="error"
                                        variant="outlined"
                                        fullWidth
                                        label="Error"
                                        value={"Error"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Error"
                                    name="statisticalForecastErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Moving Average Error"
                                    name="statisticalForecastMovingAverageErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastMovingAverageErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastMovingAverageErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastMovingAverageErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Moving Average Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Relative Error"
                                    name="statisticalForecastRelativeErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastRelativeErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastRelativeErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastRelativeErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Relative Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Error Rank"
                                    name="statisticalForecastErrorRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastErrorRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastErrorRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastErrorRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Error Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Accuracy Rank"
                                    name="statisticalForecastAccuracyRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastAccuracyRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastAccuracyRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastAccuracyRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Accuracy Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Error FVA"
                                    name="statisticalForecastErrorFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastErrorFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastErrorFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastErrorFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Error FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIBias && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Bias"
                                        name="Bias"
                                        variant="outlined"
                                        fullWidth
                                        label="Bias"
                                        value={"Bias"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Bias"
                                    name="statisticalForecastBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Moving Average Bias"
                                    name="statisticalForecastMovingAverageBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastMovingAverageBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastMovingAverageBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastMovingAverageBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Moving Average Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Relative Bias"
                                    name="statisticalForecastRelativeBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastRelativeBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastRelativeBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastRelativeBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Relative Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Bias Rank"
                                    name="statisticalForecastBiasRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastBiasRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastBiasRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastBiasRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Bias Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Neutrality Rank"
                                    name="statisticalForecastNeutralityRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastNeutralityRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastNeutralityRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastNeutralityRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Neutrality Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Bias FVA"
                                    name="statisticalForecastBiasFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastBiasFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastBiasFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastBiasFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Bias FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIChurn && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Churn"
                                        name="Churn"
                                        variant="outlined"
                                        fullWidth
                                        label="Churn"
                                        value={"Churn"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Churn"
                                    name="statisticalForecastChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Moving Average Churn"
                                    name="statisticalForecastMovingAverageChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastMovingAverageChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastMovingAverageChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastMovingAverageChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Moving Average Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Relative Churn"
                                    name="statisticalForecastRelativeChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastRelativeChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastRelativeChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastRelativeChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Relative Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Churn Rank"
                                    name="statisticalForecastChurnRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastChurnRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastChurnRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastChurnRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Churn Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Stability Rank"
                                    name="statisticalForecastStabilityRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastStabilityRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastStabilityRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastStabilityRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Stability Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Churn FVA"
                                    name="statisticalForecastChurnFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastChurnFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastChurnFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastChurnFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Churn FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                        </Grid>
                    </>
                )}

            </Container> */}
        </>
    )
}

export default ExtrapolativeTab
