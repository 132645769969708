import { TextField } from '@mui/material';
import React from 'react'

export const CommonNumberInput = ({
    name,
    label,
    value,
    handleChangeProfile,
    placeholder,
    disabled
}) => {
    return (
        <>
            <TextField
                type="number"
                id={name}
                name={name}
                variant="outlined"
                fullWidth
                label={label}
                value={value || ""}
                onChange={(e, newValue) => {
                    handleChangeProfile(newValue, name, e);
                }}
                placeholder={placeholder}
                // onBlur={validateOne}
                disabled={disabled}
                onKeyDown={(e) => {
                    if (
                        e.key === "Backspace" ||
                        (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                        (e.key !== "-" &&
                            e.key !== "e" &&
                            e.keyCode >= 47 &&
                            e.keyCode <= 58)
                    ) {
                    } else {
                        e.preventDefault();
                    }
                }}
                onFocus={(e) =>
                    e.target.addEventListener(
                        "wheel",
                        function (e) {
                            e.preventDefault();
                        },
                        { passive: false }
                    )
                }
            />
        </>
    )
}

