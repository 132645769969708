import { AppBar, Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputBase, InputLabel, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography, alpha, styled } from '@mui/material'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { API_BASE_URL } from '../../helper/constants'
import { toast } from 'react-toastify'
import { createProfile, getProfileData } from '../../store/ApiSlice/profileSlice'
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createMeasureProfile, getMeasureProfileList } from '../../store/ApiSlice/measuredataslice'
import { getMeasureList } from '../../store/ApiSlice/measureSlice'
import { fetchDimensions, getModelListAsync } from '../../store/ApiSlice/dimensionSlice'

const CreateMeasureDataProfile = (props) => {
  const {
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows,
    measureProfileModel,
    anchorEl,
    setAnchorEl,
    handleClickButton,
    handleCloseButton,
    setMeasureEditProfile,
    setMeasureCreateProfile,
    handleUpdateProfile,
    formValues,
    setFormValues,
    dataProfileDetail,
    setDataProfileDetail,
    isDataProfileDetailUpdated,
    setIsDataProfileDetailUpdated,
    selectedIndex
  } = props
  const { measureData, measureList } = useSelector((state) => state.measure)
  const { enterpriseData } = useSelector((state) => state?.enterprise)
  const { dimensionData } = useSelector((state) => state?.dimension)
  const { measureProfileDataList } = useSelector((state) => state.measureprofile)
  const [modalName, setModalName] = useState([])
  const userData = JSON.parse(localStorage.getItem("accessToken"))
  const dispatch = useDispatch()

  const selectedProfile = measureProfileDataList?.find((item) => item?._id === selectedRows)

  useEffect(() => {
    dispatch(getModelListAsync())
    dispatch(getMeasureList())
  }, [])

  useEffect(() => {
    if (selectedProfile && isEdit) {
      setFormValues({
        name: selectedProfile?.name,
        notes: selectedProfile?.notes,
        enterpriseUID: selectedProfile?.enterpriseUID?._id,
        modelUID: selectedProfile?.modelUID?.modalUID,
      })
    }
    if (selectedProfile && isEdit && !isDataProfileDetailUpdated) {
      setDataProfileDetail({
        operations: selectedProfile?.operations?.map((item) => ({
          action: item?.action,
          fromDate: item?.fromDate,
          toDate: item?.toDate,
          timeAttribute: item?.timeAttribute,
          attributes: item?.attributes?.map((item) => item?._id),
          measure: item?.measure?._id,
          periodShift: item?.periodShift,
          sourceMeasure: item?.sourceMeasure?._id,
          level: item?.level?._id,
          filter: item?.filter?._id
        })
        ),
      })
    }
  }, [isEdit, selectedRows, selectedProfile, isDataProfileDetailUpdated])

  const handleChangeProfile = (newValue, fieldName, e) => {
    if (fieldName === 'enterpriseUID') {
      setFormValues({
        ...formValues,
        [fieldName]: newValue?._id
      });
    } else if (fieldName === 'modelUID') {
      setFormValues({
        ...formValues,
        [fieldName]: newValue?.modalUID
      });
    }
    else {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  }

  const getModelList = useCallback(async () => {
    try {
      const userId = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };

      let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
      let filteredData = isEdit
        ? response.data?.enterpriseList?.filter(
          (item) => item.enterpriseUID?._id === formValues.enterpriseUID
        )
        : response.data?.enterpriseList;

      if (formValues.enterpriseUID) {
        filteredData = filteredData?.filter(
          (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
        );
      }
      setModalName(filteredData);
    } catch (error) {
      console.error("Error fetching model list:", error);
    }
  }, [isEdit, formValues.enterpriseUID]); // Add necessary dependencies here

  useEffect(() => {
    if (formValues.enterpriseUID) {
      getModelList();
    }
  }, [formValues.enterpriseUID, getModelList]);

  const handleCancel = () => {
    setFormValues({});
    setDataProfileDetail({
      operations: []
    })
    setIsCreate(false);
    setIsDataProfileDetailUpdated(false)
  };

  const handleSubmit = async () => {
    const payload = {
      name: formValues?.name,
      enterpriseUID: formValues?.enterpriseUID,
      modelUID: formValues?.modelUID,
      notes: formValues?.notes,
      operations: dataProfileDetail?.operations?.map((item) => ({
        action: item?.action,
        fromDate: item?.fromDate,
        toDate: item?.toDate,
        ...(item?.sourceMeasure && item?.action === "Copy" && ({
          sourceMeasure: item?.sourceMeasure,
          measure: item?.measure,
        })),
        ...(item?.level && (item?.action === "Download" || item?.action === "Delete") && ({
          level: item?.level,
        })),
        ...(item?.measure && (item?.action === "Download" || item?.action === "Delete") && ({
          measure: item?.measure,
        })),
        ...(item?.timeAttribute && item?.action === "Copy" && ({
          timeAttribute: item?.timeAttribute,
        })),
        ...(item?.periodShift && item?.action === "Copy" && ({
          periodShift: item?.periodShift,
        })),
        ...(item?.filter && ({
          filter: item?.filter,
        })),
        ...(item?.attributes && item?.attributes.length > 0 && item?.attributes?.every(attr => attr !== '') && ({
          attributes: item?.attributes,
        })),
      }))
    }
    const result = await dispatch(createMeasureProfile({ payload, isEdit, selectedRows }))
    if (result?.meta?.requestStatus == 'fulfilled') {
      // toast.success(isEdit ? "Profile updated successfully" : "Profile created successfully")
      dispatch(getMeasureProfileList())
      setFormValues({});
      setDataProfileDetail({
        operations: []
      })
      setIsCreate(false);
      setIsDataProfileDetailUpdated(false)
    } else if (result?.meta?.requestStatus === 'rejected') {
      toast.error(result?.error?.message)
      setIsCreate(true)
    }
  }

  const handleRemoveField = (index) => {
    const updatedFormValues = [...dataProfileDetail?.operations];
    updatedFormValues.splice(selectedIndex, 1);
    setDataProfileDetail({ operations: updatedFormValues });
    setAnchorEl(null)
  };

  const labelTransparentStyle = {
    '& .MuiInputLabel-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputLabel-shrink': {
      backgroundColor: 'transparent',
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
            {/* {getPageHeading()} */}
            Measure Data Profile
          </Typography>
        </Toolbar>
      </AppBar>

      <Container style={{ display: "grid", paddingBottom: "20px" }}>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>

          <Grid item xs={6}>
            <TextField
              id="name"
              name="name"
              variant="outlined"
              fullWidth
              label="Data Profile Name"
              value={formValues?.name}
              onChange={(e, newValue) => {
                handleChangeProfile(newValue, "name", e);
              }}
              placeholder="Enter data profile name"
              sx={labelTransparentStyle}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                id="demo-simple-select-readonly"
                labelId="Enterprise"
                name="enterpriseUID"
                options={
                  enterpriseData?.length > 0
                    ? enterpriseData
                    : userData?.data?.enterprise
                      ? [userData.data.enterprise]
                      : []
                }
                getOptionLabel={(option) => option.enterpriseName}
                value={
                  enterpriseData?.length > 0
                    ? enterpriseData?.find((option) => option?._id === formValues.enterpriseUID) || null
                    : userData?.data?.enterprise?._id === formValues.enterpriseUID
                      ? userData.data.enterprise
                      : null
                }
                onChange={(e, newValue) => {
                  handleChangeProfile(newValue, "enterpriseUID", e);
                }}
                sx={labelTransparentStyle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enterprise"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                id="demo-simple-select-readonly"
                labelId="Model"
                name="modelUID"
                options={modalName}
                getOptionLabel={(option) => option.modelName}
                value={formValues?.modelUID ?
                  modalName.find(option => option.modalUID === formValues.modelUID)
                  || null : null}
                onChange={(e, newValue) => {
                  handleChangeProfile(newValue, "modelUID", e);
                }}
                sx={labelTransparentStyle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Model"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>

      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />

      <Container style={{ display: "grid", paddingBottom: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button variant='contained' onClick={() => {
            if (formValues?.enterpriseUID && formValues?.modelUID) {
              setMeasureCreateProfile(true)
              // setIsCreate(false)
              setIsDataProfileDetailUpdated(true)
            } else {
              toast.error("Please select enterprise and model name")
            }
          }}>+</Button>
        </Box>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Action
                  </TableCell>
                  <TableCell>Source Measure</TableCell>
                  <TableCell>Target Measure</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Form Date</TableCell>
                  <TableCell>To Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {dataProfileDetail?.operations?.length > 0 && dataProfileDetail?.operations?.map((item, index) => {
                return (
                  <TableBody key={index}>
                    <TableCell>
                      {item?.action}
                    </TableCell>
                    <TableCell>{item?.sourceMeasure && item?.action === "Copy"
                      ? measureData?.find((row) => row?._id === item?.sourceMeasure)?.measureName || "N/A"
                      : "-"
                    }</TableCell>
                    <TableCell>{item?.measure
                      ? measureData?.find((row) => row?._id === item?.measure)?.measureName || "N/A"
                      : "-"
                    }</TableCell>
                    <TableCell>{item?.level
                      ? dimensionData?.find((row) => row?._id === item?.level)?.name || "N/A"
                      : "-"
                    }</TableCell>
                    <TableCell>
                      {item?.fromDate}
                    </TableCell>
                    <TableCell>
                      {item?.toDate}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={measureProfileModel ? 'long-menu' : undefined}
                        aria-expanded={measureProfileModel ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClickButton(e, index, item);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={measureProfileModel}
                        onClose={handleCloseButton}
                      >
                        <MenuItem onClick={() => {
                          handleUpdateProfile(true)
                          setAnchorEl(null)
                        }}
                          sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                          Edit Details
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                          handleRemoveField()
                          setAnchorEl(null)
                        }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableBody>
                )
              })}
            </Table>
          </TableContainer>
        </Grid>
      </Container>


      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />

      <Container style={{ display: "grid", paddingBottom: "20px" }}>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <TextField
              id="notes"
              name="notes"
              variant="outlined"
              fullWidth
              label="Profile Notes"
              value={formValues.notes}
              multiline
              rows={6}
              onChange={(e, newValue) => {
                handleChangeProfile(newValue, "notes", e);
              }}
              placeholder="Enter Notes"
              disabled={isView}
              sx={labelTransparentStyle}
            />
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "30px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Button
          // disabled={isView}
          onClick={handleCancel}
          title="Cancel"
          style={{
            padding: "6px 15px",
            borderRadius: "2px",
            cursor: "pointer",
            marginRight: "10px",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize"
          }}
        >
          Cancel
        </Button>
        <Button
          // disabled={isView}
          onClick={handleSubmit}
          title="Create Profile"
          style={{
            padding: "7px 15px",
            borderRadius: "2px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize"
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default CreateMeasureDataProfile
