import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";


const initialState = {
    loading: false,
    error: null,
    success: false,
    scenarioData: [],
    parentScenarioData: []
}

export const getAllScenarioList = createAsyncThunk(
    'scenario/getAllScenarioList',
    async ({ formValues }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/scenario/getAll/${formValues?.enterpriseUID}/${formValues?.modelUID}`, config);
        return response.data;
    }
);

export const getAllParentScenarioList = createAsyncThunk(
    'scenario/getAllParentScenarioList',
    async ({ formValues }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/scenario/parentScenarioList/${formValues?.enterpriseUID}/${formValues?.modelUID}`, config);
        return response.data;
    }
);

export const createScenario = createAsyncThunk(
    'scenario/createScenario',
    async ({ payload, isEdit, updatedId }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/scenario/upload`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/scenario/upload/${updatedId}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteScenarioList = createAsyncThunk('scenario/deleteScenarioList',
    async ({ attriId, config, payload }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/scenario/delete`, {
                data: payload, // Pass the payload in the data property
                ...config,     // Add headers and other configurations
            })
            return responce
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

export const promoteDeleteScenarioList = createAsyncThunk('scenario/promoteDeleteScenarioList',
    async ({ config, payload }) => {
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/scenario/promote-and-delete`;
            result = await axios.post(baseURLCreate, payload, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const promoteScenarioList = createAsyncThunk('scenario/promoteScenarioList',
    async ({ config, payload }) => {
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/scenario/promote`;
            result = await axios.post(baseURLCreate, payload, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

const scenarioListSlice = createSlice({
    name: "scenario",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllScenarioList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAllScenarioList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.scenarioData = action?.payload?.data;
            })
            .addCase(getAllScenarioList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getAllParentScenarioList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAllParentScenarioList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.parentScenarioData = action?.payload?.data;
            })
            .addCase(getAllParentScenarioList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
})

export default scenarioListSlice.reducer;