import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../helper/constants";
import axios from "axios";


const initialState = {
    loading: false,
    error: null,
    success: false,
    processData: [],
    scenarioList: [],
    excuteProcessData: {},
    progressStatusDetail: {}
}
export const getProcessList = createAsyncThunk(
    'process/getProcessList',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/process`, config);
        return response.data;
    }
);

export const getExcuteProcess = createAsyncThunk(
    'process/getExcuteProcess',
    async (body) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        try {
            const response = await axios.post(`${API_BASE_URL}/process/excute`, body, config);
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const getScenarios = createAsyncThunk(
    'process/getScenarios',
    async ({ body }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };
        try {
            const baseURLCreate = `${API_BASE_URL}/dataload/scenarioList`;
            const response = await axios.post(baseURLCreate, body, config);
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createProgressStatus = createAsyncThunk(
    'process/createProgressStatus',
    async (row) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        const payload = {
            processUID: row?.original?._id,
            executionLogUID: row?.original?.latestLog?._id
        }
        try {
            let result;

            const baseURLCreate = `${API_BASE_URL}/process/excute/status-details`;
            result = await axios.post(baseURLCreate, payload, config);

            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createProcess = createAsyncThunk(
    'process/createProcess',
    async ({ payload, isEdit, selectedRows }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/process`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/process/${selectedRows}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteProcessList = createAsyncThunk('process/deleteProcessList',
    async ({ processId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/process/${processId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);
export const terminateProcessList = createAsyncThunk('process/terminateProcessList',
    async ({ processId, config }) => {
        try {
            const responce = await axios.get(`${API_BASE_URL}/process/terminate/${processId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

const processSlice = createSlice({
    name: "process",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProcessList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getProcessList.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.processData = action?.payload?.data;
            })
            .addCase(getProcessList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getExcuteProcess.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getExcuteProcess.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.excuteProcessData = action?.payload?.data;
            })
            .addCase(getExcuteProcess.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getScenarios.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getScenarios.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.scenarioList = action?.payload?.data;
            })
            .addCase(getScenarios.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createProcess.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createProcess.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(createProcess.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createProgressStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createProgressStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
                state.progressStatusDetail = action?.payload?.data
            })
            .addCase(createProgressStatus.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteProcessList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteProcessList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(deleteProcessList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(terminateProcessList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(terminateProcessList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(terminateProcessList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
    }
})

export default processSlice.reducer;