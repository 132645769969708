import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export const generateFilterPayload = (systemAttributes, timeAttributes) => {
    const systemAttributesResult = {
        scenario: [],
        cyclePeriodicity: "",
        cycleIds: [],
        lagIds: [],
    };

    // Process systemAttributes
    systemAttributes.forEach(item => {
        const { attributes, cyclePeriodicity, operator, attValues } = item;

        switch (attributes) {
            case "scenario":
                systemAttributesResult.scenario = Object.keys(attValues);
                break;
            case "cycleIds":
                if (cyclePeriodicity) {
                    systemAttributesResult.cyclePeriodicity = cyclePeriodicity; // Extract the cycle period
                }
                systemAttributesResult.cycleIds = Object.keys(attValues);
                break;
            case "lagIds":
                systemAttributesResult.lagIds = Object.keys(attValues).map(key => parseInt(key)); // Convert to integers
                break;
            default:
                break;
        }
    });

    // Process timeAttributes
    const timeAttributesResult = timeAttributes.map(item => {
        const { timePeriod, operator, startDate, endDate } = item;
        return {
            periodType: timePeriod?.charAt(0)?.toUpperCase() + timePeriod?.slice(1),
            operator: operator, 
            // values: [parseInt(startDate.split('-')[0])],
            // values:[],
            from: moment(startDate).format('DD-MM-YYYY'),
            to: moment(endDate).format('DD-MM-YYYY')
        };
    });

    // Return the final payload
    return {
        systemAttributes: systemAttributesResult,
        timeAttributes: timeAttributesResult,
    };
};


export const generateSystemAttributeInitialPayload = (sysAtt) => {
        const generateAttValues = (item) => {
            return sysAtt[item].reduce((acc, keyItem) => {
                acc[keyItem] = true;
                return acc;
            }, {});
        };

        const validAttributes = ['cycleIds', 'scenario', 'lagIds'];

        const updatedData = validAttributes
            .filter((item) => sysAtt[item]?.length) // Only process attributes that have data
            .map((item) => {
                return {
                    id: uuidv4(),
                    attributes: item,
                    attValues: generateAttValues(item, sysAtt),
                    ...(item === 'cycleIds' && { cyclePeriodicity: sysAtt.cyclePeriodicity || '' })
                };
            });

        return updatedData.length > 0 ? updatedData : [];
    };

export const formatDate = (date) => {
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
};
