import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteDimanationList, getModelListAsync } from "../../store/ApiSlice/dimensionSlice";

const DeleteDimension = ({
  selectedRows,
  setModalDeleteOpen,
  isModalDeleteOpen,
  updatedId,
  getDimensions,
}) => {
  const handleCloseModal = () => {
    setModalDeleteOpen(false);
  };
  const dispatch = useDispatch();
  const handleDelete = async () => {
    // try {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    const response = await dispatch(deleteDimanationList({ attriId: updatedId, config }));
    if (response?.meta?.requestStatus === "fulfilled") {
      setModalDeleteOpen(false);
      // toast.success(response?.payload?.data?.message);
      dispatch(getModelListAsync());
    } else if (response?.meta?.requestStatus === "rejected") {
      toast.warn(
        response?.data?.error
          ? response.data.error
          : "Dimanation Model can not be deleted"
      );
    }
    handleCloseModal();
  };
  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Delete Dimension</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography>
            This is irreversible. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button color="primary" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </>
    );
  };
  return (
    <>
      {/* <Button
                onClick={handleClick}
                className={selectedRows.length ? 'awesome-button' : 'awesome-button-disabled'}
                disabled={!selectedRows.length}
                sx={{ color: 'white', margin: '0 4px' }}>
                Delete
            </Button> */}
      <Dialog open={isModalDeleteOpen} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  );
};

export default DeleteDimension;
