import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { recoverPassword, signIn, signUp, updatePassword, user, userAccess, userList } from "../../utils/url";
import { api } from '../../utils/index';

const initialState = {
    loading: false,
    error: null,
    success: false,
    loggedUser: {},
    users: [],
    forgotPassword: {},
    expandedMenu: null
}

export const makeUserLogin = createAsyncThunk(
    'auth/signin',
    async ({ formValue }) => {
        try {
            const body = {
                email: formValue?.email,
                password: formValue?.password
            }
            let response = await api(signIn(), 'post', body);
            localStorage.setItem("accessToken", JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.error || error);
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (body) => {
        try {
            let response = await api(updatePassword(), 'post', body);
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.error || error);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (body) => {
        try {
            let response = await api(recoverPassword(), 'post', body);
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.error || error);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/singup',
    async (data) => {
        try {
            let response = await api(signUp(), 'post', data);
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.error || error);
        }
    }
);

export const loadUsersList = createAsyncThunk(
    'user/list',
    async () => {
        try {
            let response = await api(userList(), 'get', {});
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data || error);
        }
    }
);

export const userAccessAction = createAsyncThunk(
    'user/access',
    async (data) => {
        try {
            let response = await api(userAccess(), 'put', data);
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data || error);
        }
    }
);

export const userDelete = createAsyncThunk(
    'user/delete',
    async (userId) => {
        try {
            let response = await api(user(userId), 'delete', {});
            return response.data;
        } catch (error) {
            return Promise.reject(error?.response?.data || error);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setExpandedMenu(state, action) {
            console.log("🚀 ~ setExpandedMenu ~ action:", action)
            state.expandedMenu = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(makeUserLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(makeUserLogin.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.loggedUser = action.payload.userId
            })
            .addCase(makeUserLogin.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
            .addCase(changePassword.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
            .addCase(loadUsersList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(loadUsersList.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
                state.users = action.payload.user
            })
            .addCase(loadUsersList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
            .addCase(userAccessAction.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(userAccessAction.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(userAccessAction.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
            .addCase(userDelete.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(userDelete.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(userDelete.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message
            })
    }
});

export const { setExpandedMenu } = authSlice.actions;
export default authSlice.reducer