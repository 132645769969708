import React, { useMemo } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Box } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import moment from 'moment';

const DetailsModal = ({ isOpen, onClose }) => {
    const detailsMockData = [
        {
            processStep: 'Step 1',
            processActivity: 'Activity 1',
            processTask: 'Task 1',
            processStatus: 'Completed',
            startDateTime: '2023-10-01T10:00:00',
            endDateTime: '2023-10-01T10:02:00',
            duration: 120,
        },
        {
            processStep: 'Step 2',
            processActivity: 'Activity 2',
            processTask: 'Task 2',
            processStatus: 'In Progress',
            startDateTime: '2023-10-01T10:02:00',
            endDateTime: '2023-10-01T10:05:00',
            duration: 180,
        },
        {
            processStep: 'Step 3',
            processActivity: 'Activity 3',
            processTask: 'Task 3',
            processStatus: 'Failed',
            startDateTime: '2023-10-01T10:05:00',
            endDateTime: '2023-10-01T10:06:00',
            duration: 60,
        },
    ];

    const detailsColumns = useMemo(
        () => [
            {
                accessorKey: 'processStep',
                enableColumnOrdering: false,
                header: 'Process Step',
                enableSorting: false,
            },
            {
                accessorKey: 'processActivity',
                enableColumnOrdering: false,
                header: 'Process Activity',
                enableSorting: false,
            },
            {
                accessorKey: 'processTask',
                header: 'Process Task',
                enableSorting: false,
            },
            {
                accessorKey: 'processStatus',
                enableColumnOrdering: false,
                header: 'Process Status',
                enableSorting: false,
            },
            {
                accessorKey: 'startDateTime',
                enableColumnOrdering: false,
                header: 'Start Date/Time',
                enableSorting: false,
                Cell: ({ row }) => (
                    <p>
                        {moment(row.original.startDateTime).format('DD-MM-YYYY HH:mm:ss')}
                    </p>
                ),
            },
            {
                accessorKey: 'endDateTime',
                enableColumnOrdering: false,
                header: 'End Date/Time',
                enableSorting: false,
                Cell: ({ row }) => (
                    <p>
                        {moment(row.original.endDateTime).format('DD-MM-YYYY HH:mm:ss')}
                    </p>
                ),
            },
            {
                accessorKey: 'duration',
                header: 'Duration (Seconds)',
                enableColumnOrdering: false,
                enableSorting: false,
            },
        ],
        []
    );

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontFamily: "'Poppins', sans-serif",
                }}
            >
                <Box
                    onClick={onClose}
                    sx={{
                        color: 'black',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <ChevronLeftIcon />
                </Box>
                Process Run ID:
            </DialogTitle>
            <MaterialReactTable
                columns={detailsColumns}
                data={detailsMockData}
                enablePagination
                initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
                enableColumnOrdering
                enableRowSelection={false}
                muiTableHeadCellProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: '12px',
                        fontFamily: "'Poppins', sans-serif",
                    },
                }}
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        cursor: 'pointer',
                        fontSize: '12px',
                        fontFamily: "'Poppins', sans-serif",
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                })}
                muiTableContainerProps={{
                    sx: {
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                    },
                }}
                muiTablePaperProps={{
                    sx: {
                        margin: '16px',
                        padding: '16px',
                    },
                }}
            />
            <DialogActions>
                <Button onClick={onClose} sx={{ textTransform: 'capitalize' }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DetailsModal;
