import moment from 'moment';
import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Dialog, DialogTitle, DialogActions, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ProcessName = ({ isProgressName, setIsProgressName, setIsDetailsModalOpen, setIsProgressStatus }) => {

    const mockData = [
        {
            processRunId: 1,
            progressStatus: 'Completed',
            startDateTime: '2023-10-01T10:00:00',
            duration: 120,
            runLog: 'Log details for run 1',
            errorLog: 'No errors',
        },
        {
            processRunId: 2,
            progressStatus: 'In Progress',
            startDateTime: '2023-10-02T11:00:00',
            duration: 300,
            runLog: 'Log details for run 2',
            errorLog: 'Error occurred',
        },
        {
            processRunId: 3,
            progressStatus: 'Failed',
            startDateTime: '2023-10-03T12:00:00',
            duration: 60,
            runLog: 'Log details for run 3',
            errorLog: 'Critical error',
        }, {
            processRunId: 4,
            progressStatus: 'Failed',
            startDateTime: '2023-10-03T12:00:00',
            duration: 60,
            runLog: 'Log details for run 3',
            errorLog: 'Critical error',
        }, {
            processRunId: 5,
            progressStatus: 'Failed',
            startDateTime: '2023-10-03T12:00:00',
            duration: 60,
            runLog: 'Log details for run 3',
            errorLog: 'Critical error',
        }, {
            processRunId: 7,
            progressStatus: 'Failed',
            startDateTime: '2023-10-03T12:00:00',
            duration: 60,
            runLog: 'Log details for run 3',
            errorLog: 'Critical error',
        }, {
            processRunId: 8,
            progressStatus: 'Failed',
            startDateTime: '2023-10-03T12:00:00',
            duration: 60,
            runLog: 'Log details for run 3',
            errorLog: 'Critical error',
        },
        {
            processRunId: 9,
            progressStatus: 'In Progress',
            startDateTime: '2023-10-02T11:00:00',
            duration: 300,
            runLog: 'Log details for run 2',
            errorLog: 'Error occurred',
        },
        {
            processRunId: 10,
            progressStatus: 'In Progress',
            startDateTime: '2023-10-02T11:00:00',
            duration: 300,
            runLog: 'Log details for run 2',
            errorLog: 'Error occurred',
        },
        {
            processRunId: 11,
            progressStatus: 'In Progress',
            startDateTime: '2023-10-02T11:00:00',
            duration: 300,
            runLog: 'Log details for run 2',
            errorLog: 'Error occurred',
        }, {
            processRunId: 12,
            progressStatus: 'Failed',
            startDateTime: '2023-10-03T12:00:00',
            duration: 60,
            runLog: 'Log details for run 3',
            errorLog: 'Critical error',
        },
    ];

    const columns = useMemo(() => [
        {
            accessorKey: 'processRunId',
            enableColumnOrdering: false,
            header: 'Process Run ID',
            enableSorting: false,
        },
        {
            accessorKey: 'progressStatus',
            enableColumnOrdering: false,
            header: 'Progress Status',
            enableSorting: false,
        },
        {
            accessorKey: 'startDateTime',
            enableColumnOrdering: false,
            header: 'Start Date/Time',
            enableSorting: false,
            Cell: ({ row }) => (
                <p>{moment(row.original.startDateTime).format('DD-MM-YYYY HH:mm:ss')}</p>
            ),
        },
        {
            accessorKey: 'duration',
            enableColumnOrdering: false,
            header: 'Duration (Seconds)',
            enableSorting: false,
        },
        {
            accessorKey: 'runLog',
            enableColumnOrdering: false,
            header: 'Run Log',
            enableSorting: false,
        },
        {
            accessorKey: 'errorLog',
            enableColumnOrdering: false,
            header: 'Error Log',
            enableSorting: false,
        },
        {
            accessorKey: 'actions',
            header: '',
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                const [anchorEl, setAnchorEl] = React.useState(null);
                const open = Boolean(anchorEl);

                const handleClick = (event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                };

                const handleClose = () => {
                    setAnchorEl(null);
                };

                return (
                    <>
                        <IconButton onClick={handleClick} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose();
                                    setIsDetailsModalOpen(true);
                                    setIsProgressName(false);
                                }}
                                sx={{ fontSize: '12px', fontFamily: "'Poppins', sans-serif" }}
                            >
                                See Details
                            </MenuItem>
                        </Menu>
                    </>
                );
            },
        },
    ], []);

    const handleCloseModal = () => {
        setIsProgressName(false);
        setIsProgressStatus(true);
    };

    return (
        <Dialog open={isProgressName} onClose={handleCloseModal} maxWidth="lg" fullWidth>
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center', fontSize: '12px', fontFamily: "'Poppins', sans-serif"
            }}>
                <Box
                    onClick={handleCloseModal}
                    sx={{
                        color: 'black',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <ChevronLeftIcon />
                </Box>
                Progress Name Detail
            </DialogTitle>
            <MaterialReactTable
                columns={columns}
                data={mockData}
                enablePagination
                initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
                enableColumnOrdering
                enableRowSelection={false}
                muiTableHeadCellProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: '12px',
                        fontFamily: "'Poppins', sans-serif",
                    },
                }}
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        cursor: 'pointer',
                        fontSize: '12px',
                        fontFamily: "'Poppins', sans-serif",
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                })}
                muiTableContainerProps={{
                    sx: {
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                    },
                }}
                muiTablePaperProps={{
                    sx: {
                        margin: '16px',
                        padding: '16px',
                    },
                }}
            />
            <DialogActions>
                <Button onClick={handleCloseModal} sx={{ textTransform: 'capitalize' }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProcessName;