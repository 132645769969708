import { Autocomplete, TextField } from '@mui/material';
import React from 'react'

export const CommonSelect = ({
    labelId,
    name,
    options,
    getOptionLabel,
    value,
    setSelectedOptions,
    renderInput,
    setSelectedAttributes
}) => {
    return (
        <>
            <Autocomplete
                id="demo-simple-select-readonly"
                labelId={labelId}
                name={name}
                options={options}
                getOptionLabel={(option) => option.name}
                value={value ?
                    options?.find(option => option?._id === value)
                    || null : null}
                onChange={(e, newValue) => {
                    if (name === "filter") {
                        console.log("newValuenewValuenewValue", newValue);
                        setSelectedOptions(prevState => ({
                            ...prevState,
                            [name]: newValue?._id
                        }));
                        setSelectedAttributes(newValue?.attributes?.map((item) => ({
                            attributes: item?.attributeUID?._id,
                            operator: item?.operator,
                            attValues: item?.values?.reduce((acc, value) => {
                                acc[value] = true;
                                return acc;
                            }, {})
                        })))
                    } else {
                        setSelectedOptions(prevState => ({
                            ...prevState,
                            [name]: newValue?._id
                        }));
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={labelId}
                        variant="outlined"
                        fullWidth
                    />
                )}
            />
        </>
    )
}

export const CommonAutoComplete = ({
    label,
    name,
    value,
    profileMeasure,
    index,
    handleChangeProfile
}) => {

    const labelTransparentStyle = {
        '& .MuiInputLabel-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
        }
    };

    return (
        <>
            <Autocomplete
                id={`autocomplete-${name}`}
                labelId={label}
                name={name}
                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                getOptionLabel={(option) => option?.measureName || ''}
                value={
                    value
                        ? profileMeasure?.find(
                            (item) => item?._id === value
                        ) || null
                        : null
                }
                sx={{ ...labelTransparentStyle, width: '100%'}}
                onChange={(e, newValue) => handleChangeProfile(newValue, name, e)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
        </>
    )
}
