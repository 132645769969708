import { AppBar, Autocomplete, Box, Button, Container, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createRole, getRole } from '../../store/ApiSlice/roleSlice';
import { toast } from 'react-toastify';
import moment from 'moment';

const CreateRole = ({
    setIsCreate,
    isEdit,
    selectedRows
}) => {
    const { roleData } = useSelector((state) => state.role)
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const [roleFormvalue, setRoleFormvalue] = useState({});
    const [assignmentData, setAssignmentData] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataIndex, setDataIndex] = useState();
    const deleteAddignMentData = Boolean(anchorEl);
    const dispatch = useDispatch()

    const handleAdd = () => {
        setAssignmentData([...assignmentData, { assignMentData: "", allwed: "" }]);
    };

    const selectedProfile = roleData?.find(
        (item) => item?._id === selectedRows
    );


    useEffect(() => {
        if (selectedProfile && isEdit) {
            setRoleFormvalue({
                enterpriseName: selectedProfile?.enterpriseUID?.enterpriseName,
                enterpriseUID: selectedProfile?.enterpriseUID._id,
                roleName: selectedProfile?.roleName,
                note: selectedProfile?.notes,
                createdOn: moment(selectedProfile?.createdOn).format("DD-MM-YYYY"),
                changedOn: moment(selectedProfile?.changedOn).format("DD-MM-YYYY"),
            })
            setAssignmentData(selectedProfile?.permissions?.map((item) => ({
                assignMentData: item?.object,
                allowed: item?.editAllowed
            })))
        }
    }, [selectedProfile, isEdit])

    const handleChangeProfile = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            const selectedData = enterpriseData?.length > 0 && enterpriseData?.filter(
                (data) => data?._id === newValue?._id
            );

            setRoleFormvalue({
                ...roleFormvalue,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id,
            });
        } else {
            setRoleFormvalue({
                ...roleFormvalue,
                [fieldName]: e.target.value
            })
        }
    }

    const handleChangeObjectAssignMent = (e, index) => {
        const { name, value } = e.target
        const updatedFormValues = [...assignmentData];
        if (name === "allowed") {
            updatedFormValues[index][name] = value;
            setAssignmentData(updatedFormValues);
        } else {
            updatedFormValues[index][name] = value;
            setAssignmentData(updatedFormValues);
        }

    }
    const handleCancel = () => {
        setIsCreate(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event, index, type) => {
        setAnchorEl(event.currentTarget);
        setDataIndex(index);
    };

    const handleremove = (type) => {
        const updatedField = [...assignmentData];
        updatedField.splice(dataIndex, 1);
        setAssignmentData(updatedField);
        setAnchorEl(null);
    };

    const handleSubmit = async () => {
        const payload = {
            enterpriseUID: roleFormvalue?.enterpriseUID,
            roleName: roleFormvalue?.roleName,
            notes: roleFormvalue?.note,
            permissions: assignmentData?.length > 0 && assignmentData?.map((item) => ({
                object: item?.assignMentData,
                editAllowed: item?.allowed === "false" ? false : true
            }))
        }

        const result = await dispatch(
            createRole({ payload, isEdit, selectedRows })
        );

        if (result?.meta?.requestStatus == "fulfilled") {
            // toast.success(
            //     result?.payload?.message
            // );
            dispatch(getRole());
            setRoleFormvalue({});
            setAssignmentData([]);
            setIsCreate(false);
        } else if (result?.meta?.requestStatus === "rejected") {
            toast.error(result?.error?.message);
            setIsCreate(true);
        }
    }
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <AppBar position="static" sx={{ backgroundColor: "white" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                            {/* {getPageHeading()} */}
                            Create Role
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    General Data
                </Typography>{" "}
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Enterprise Name"
                                    name="enterpriseName"
                                    options={enterpriseData}
                                    getOptionLabel={(option) => option.enterpriseName}
                                    value={
                                        roleFormvalue?.enterpriseName
                                            ? enterpriseData.find(
                                                (option) =>
                                                    option.enterpriseName === roleFormvalue.enterpriseName
                                            ) || null
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "enterpriseName", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enterprise Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                id="roleName"
                                name="roleName"
                                variant="outlined"
                                fullWidth
                                label="Role Name"
                                value={roleFormvalue?.roleName}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "roleName", e);
                                }}
                                placeholder="Enter role name"
                            />
                        </Grid>
                    </Grid>
                </Container>
                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Object Assignment
                </Typography>{" "}
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <Button variant="contained" onClick={handleAdd}>
                            +
                        </Button>
                    </Box>
                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        {
                            assignmentData?.map((item, index) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <InputLabel>Object</InputLabel>
                                                <Select
                                                    id="type"
                                                    label="Object"
                                                    name="assignMentData"
                                                    value={item?.assignMentData}
                                                    onChange={(e) => {
                                                        handleChangeObjectAssignMent(e, index);
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <MenuItem value="Canvas">Canvas</MenuItem>
                                                    <MenuItem value="Filter">Filter</MenuItem>
                                                    <MenuItem value="Process">Process</MenuItem>
                                                    <MenuItem value="Model">Model</MenuItem>
                                                    <MenuItem value="Forecast Profile">Forecast Profile</MenuItem>
                                                    <MenuItem value="Measure Data Profile">Measure Data Profile</MenuItem>
                                                    <MenuItem value="User">User</MenuItem>
                                                    <MenuItem value="Role">Role</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        >
                                            <FormControl fullWidth>
                                                <InputLabel>Edit Allowed</InputLabel>
                                                <Select
                                                    id="type"
                                                    label="Edit Allowed"
                                                    name="allowed"
                                                    value={item?.allowed}
                                                    onChange={(e) => {
                                                        handleChangeObjectAssignMent(e, index);
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={deleteAddignMentData ? "long-menu" : undefined}
                                                aria-expanded={deleteAddignMentData ? "true" : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, index)}
                                                size="small"
                                            >
                                                <MdMoreVert />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    "aria-labelledby": "long-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={deleteAddignMentData}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleremove} sx={{ fontSize: "12px" }}>Delete</MenuItem>
                                            </Menu>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid>
                </Container>

                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Administrative Info
                </Typography>{" "}
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                id="note"
                                name="note"
                                label="Note"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={6}
                                value={roleFormvalue?.note}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "note", e);
                                }}
                                placeholder="Enter note"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="createdOn"
                                variant="outlined"
                                label="Created On"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={moment(roleFormvalue?.createdOn).format("DD-MM-YYYY")}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "createdOn", e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="changedOn"
                                variant="outlined"
                                label="Changed On"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={moment(roleFormvalue?.changedOn).format("DD-MM-YYYY")}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "changedOn", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="createdBy"
                                variant="outlined"
                                label="Created By"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={roleFormvalue?.createdBy}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "createdBy", e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="changedBy"
                                variant="outlined"
                                label="Changed By"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={roleFormvalue?.changedBy}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "changedBy", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: "30px",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        // disabled={isView}
                        onClick={handleCancel}
                        title="Cancel"
                        style={{
                            padding: "6px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            marginRight: "10px",
                            fontFamily: "'Poppins', sans-serif"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // disabled={isView}
                        onClick={handleSubmit}
                        title="Create Profile"
                        style={{
                            padding: "7px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            fontFamily: "'Poppins', sans-serif"
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CreateRole
