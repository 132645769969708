import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from '../../helper/constants';
import { getProfileMeasure } from '../../store/ApiSlice/profileSlice';
import { AppBar, Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';

const CreateSensitivityProfile = ({
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows
}) => {
    const { enterpriseData } = useSelector((state) => state?.enterprise)
    const { profileData, profileMeasure } = useSelector(
        (state) => state.profile
    );
    const [sensitivityFormValue, setSensitivityFormvalue] = useState({})
    const [modalName, setModalName] = useState([]);
    const [driverSpecificDetail, setDriverSpecificDetail] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [dataIndex, setDataIndex] = useState()
    const deleteDriverDetail = Boolean(anchorEl);
    const dispatch = useDispatch();

    const findEnterpriseModelProfile = profileData?.filter((item) => item?.enterpriseUID?._id === sensitivityFormValue?.enterpriseUID)
        ?.filter((item) => item?.modelUID?._id === sensitivityFormValue?.modelUID)

    const findProfileData = profileData?.find((item) => item?._id === sensitivityFormValue?.forecastProfileUID)

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.length > 0 && response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === sensitivityFormValue.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (sensitivityFormValue.enterpriseName) {
                filteredData = filteredData?.length > 0 && filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === sensitivityFormValue.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (sensitivityFormValue.enterpriseName) {
            getModelList();
        }
    }, [sensitivityFormValue.enterpriseName]);

    useEffect(() => {
        if (sensitivityFormValue?.enterpriseUID && sensitivityFormValue?.modelUID) {
            dispatch(getProfileMeasure({ formValues: sensitivityFormValue }));
        }
    }, [sensitivityFormValue?.enterpriseUID && sensitivityFormValue?.modelUID]);

    const handleCancel = () => {
        setIsCreate(false);
    };

    const handleChangeSensitivityProfile = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            const selectedData = enterpriseData?.find((data) => data?._id === newValue?._id);
            setSensitivityFormvalue({
                ...sensitivityFormValue,
                [fieldName]: selectedData?.enterpriseName,
                [`enterpriseUID`]: newValue?._id
            });
        } else if (fieldName === "modelName") {
            const selectedData = modalName?.find((data) => data?.modalUID === newValue?.modalUID);
            setSensitivityFormvalue({
                ...sensitivityFormValue,
                [fieldName]: selectedData?.modelName,
                [`modelUID`]: newValue?.modalUID
            });
        } else if (fieldName === "forecastProfileUID") {
            setSensitivityFormvalue({
                ...sensitivityFormValue,
                [fieldName]: newValue?._id
            });
        } else {
            const { name, value } = e.target;
            setSensitivityFormvalue((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    }

    const handleAddDriverDetail = () => {
        setDriverSpecificDetail([
            ...driverSpecificDetail,
            {
                driver: "",
                driver2: "",
                minchange: "",
                maxChange: "",
                stepSize: "",
                userProfileParameter: ""
            }
        ])
    }

    const handleChangeDriverDetail = (index, newValue, fieldName, e) => {
        const updatedValues = [...driverSpecificDetail]
        if (fieldName === "driver" || fieldName === "driver2") {
            updatedValues[index][fieldName] = newValue?._id
            setDriverSpecificDetail(updatedValues)
        } else if (fieldName === "userProfileParameter") {
            const { checked } = e.target
            updatedValues[index][fieldName] = checked
            setDriverSpecificDetail(updatedValues)
        } else {
            const { value } = e.target
            updatedValues[index][fieldName] = value
            setDriverSpecificDetail(updatedValues)
        }
    }

    const handleClick = (event, index, type) => {
        setAnchorEl(event.currentTarget);
        setDataIndex(index);
    }

    const handleremove = (type) => {
        const updatedField = [...driverSpecificDetail];
        updatedField.splice(dataIndex, 1);
        setDriverSpecificDetail(updatedField);
        setAnchorEl(null);
    }
    
    return (
        <>
            <div>
                <AppBar position="static" sx={{ backgroundColor: "white" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                            {/* {getPageHeading()} */}
                            Driver Sensitivity Profile
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container style={{ display: "grid", paddingBottom: "10px" }}>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={3}>
                            <TextField
                                id="filterName"
                                name="filterName"
                                variant="outlined"
                                fullWidth
                                label="Driver Sensitivity Profile Name"
                                value={sensitivityFormValue?.filterName}
                                onChange={(e, newValue) => {
                                    handleChangeSensitivityProfile(newValue, "filterName", e);
                                }}
                                placeholder="Enter driver sensitivity profile name"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Enterprise Name"
                                    name="enterpriseName"
                                    options={enterpriseData}
                                    getOptionLabel={(option) => option.enterpriseName}
                                    value={sensitivityFormValue?.enterpriseName ?
                                        enterpriseData.find(option => option.enterpriseName === sensitivityFormValue.enterpriseName)
                                        || null : null}
                                    onChange={(e, newValue) => {
                                        handleChangeSensitivityProfile(newValue, "enterpriseName", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enterprise Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Model Name"
                                    name="modelName"
                                    options={modalName}
                                    getOptionLabel={(option) => option.modelName}
                                    value={sensitivityFormValue?.modelName ?
                                        modalName.find(option => option.modelName === sensitivityFormValue.modelName)
                                        || null : null}
                                    onChange={(e, newValue) => {
                                        handleChangeSensitivityProfile(newValue, "modelName", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Model Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Container>
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormControl fullWidth>
                                    <InputLabel id="type">Driver Sensitivity Analysis Type</InputLabel>
                                    <Select
                                        labelId="type"
                                        name="type"
                                        id="demo-simple-select-readonly"
                                        value={sensitivityFormValue?.type || ""}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleChangeSensitivityProfile(newValue, "type", e);
                                        }}
                                        label="Driver Sensitivity Analysis Type"
                                        variant="outlined"

                                    >
                                        <MenuItem value="One-Way Sensitivity">One-Way Sensitivity</MenuItem>
                                        <MenuItem value="Two-Way Sensitivity Analysis">Two-Way Sensitivity Analysis</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Forecast Profile"
                                    name="forecastProfileUID"
                                    options={findEnterpriseModelProfile}
                                    getOptionLabel={(option) => option.name}
                                    value={sensitivityFormValue?.forecastProfileUID ?
                                        findEnterpriseModelProfile.find(option => option._id === sensitivityFormValue.forecastProfileUID)
                                        || null : null}
                                    onChange={(e, newValue) => {
                                        handleChangeSensitivityProfile(newValue, "forecastProfileUID", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Forecast Profile"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="validFrom"
                                label="Valid From"
                                variant="outlined"
                                name='validFrom'
                                fullWidth
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sensitivityFormValue.validFrom} // Add value attribute
                                onChange={(e, newValue) => handleChangeSensitivityProfile(newValue, "validFrom", e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="validTo"
                                name="validTo"
                                label="Valid To"
                                variant="outlined"
                                fullWidth
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: sensitivityFormValue.validFrom,
                                }}
                                value={sensitivityFormValue.validTo}
                                onChange={(e, newValue) => handleChangeSensitivityProfile(newValue, "validTo", e)}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 10px"
                    }}
                >
                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Driver Specific Details
                    </Typography>{" "}
                    <Button variant="contained" onClick={handleAddDriverDetail}>
                        +
                    </Button>
                </Box>

                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <div style={{ marginTop: "10px" }}>
                        {driverSpecificDetail?.length > 0 && driverSpecificDetail?.map((item, index) => {
                            return (
                                <>
                                    <Box sx={{ width: "100%" }}>
                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Driver"
                                                        name="driver"
                                                        options={findProfileData?.driverBasedForecastDrivers}
                                                        getOptionLabel={(option) => option.driverMeasureUID?.measureName}
                                                        value={item?.driver ?
                                                            findProfileData?.driverBasedForecastDrivers.find(option => option._id === item.driver)
                                                            || null : null}
                                                        onChange={(e, newValue) => {
                                                            handleChangeDriverDetail(index, newValue, "driver", e);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Driver"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            {sensitivityFormValue?.type === "Two-Way Sensitivity Analysis" && (
                                                <Grid item xs={2}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId="Driver #2"
                                                            name="driver2"
                                                            options={findProfileData?.driverBasedForecastDrivers}
                                                            getOptionLabel={(option) => option.driverMeasureUID?.measureName}
                                                            value={item?.driver2 ?
                                                                findProfileData?.driverBasedForecastDrivers.find(option => option._id === item.driver2)
                                                                || null : null}
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverDetail(index, newValue, "driver2", e);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Driver #2"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )}

                                            {findProfileData?.driverBasedForecastDrivers?.
                                                find((row) => row?._id === item?.driver)?.categoricalDriver === false && (
                                                    <>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                type="number"
                                                                id="minchange"
                                                                name="minchange"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Minimum Change %"
                                                                value={item?.minchange || ""}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverDetail(index, newValue, "minchange", e);
                                                                }}
                                                                placeholder=""
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (
                                                                        e.key === "Backspace" ||
                                                                        (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                                                                        (e.key !== "-" &&
                                                                            e.key !== "e" &&
                                                                            e.keyCode >= 47 &&
                                                                            e.keyCode <= 58)
                                                                    ) {
                                                                    } else {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                        "wheel",
                                                                        function (e) {
                                                                            e.preventDefault();
                                                                        },
                                                                        { passive: false }
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                type="number"
                                                                id="maxchange"
                                                                name="maxchange"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Maximum Change %"
                                                                value={item?.maxchange || ""}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverDetail(index, newValue, "maxchange", e);
                                                                }}
                                                                placeholder=""
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (
                                                                        e.key === "Backspace" ||
                                                                        (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                                                                        (e.key !== "-" &&
                                                                            e.key !== "e" &&
                                                                            e.keyCode >= 47 &&
                                                                            e.keyCode <= 58)
                                                                    ) {
                                                                    } else {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                        "wheel",
                                                                        function (e) {
                                                                            e.preventDefault();
                                                                        },
                                                                        { passive: false }
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                type="number"
                                                                id="stepSize"
                                                                name="stepSize"
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Step Size %"
                                                                value={item?.stepSize || ""}
                                                                onChange={(e, newValue) => {
                                                                    handleChangeDriverDetail(index, newValue, "stepSize", e);
                                                                }}
                                                                placeholder=""
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (
                                                                        e.key === "Backspace" ||
                                                                        (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                                                                        (e.key !== "-" &&
                                                                            e.key !== "e" &&
                                                                            e.keyCode >= 47 &&
                                                                            e.keyCode <= 58)
                                                                    ) {
                                                                    } else {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                        "wheel",
                                                                        function (e) {
                                                                            e.preventDefault();
                                                                        },
                                                                        { passive: false }
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                            <Grid
                                                item
                                                xs={2}
                                                key={item?.userProfileParameter}
                                                sx={{
                                                    '& .MuiFormControlLabel-root': {
                                                        gap: '10px',
                                                    },
                                                    width: "100%", flex: 1, display: "flex", alignItems: "center"
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={item?.userProfileParameter}
                                                            name={item?.userProfileParameter}
                                                            checked={
                                                                item?.userProfileParameter || false
                                                            }
                                                            onChange={(e, newValue) => {
                                                                handleChangeDriverDetail(index, newValue, "userProfileParameter", e);
                                                            }}
                                                        />
                                                    }
                                                    label="Use Profile Parameters"
                                                />
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-controls={deleteDriverDetail ? "long-menu" : undefined}
                                                    aria-expanded={deleteDriverDetail ? "true" : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleClick(e, index, "driverDetail")}
                                                    size="small"
                                                >
                                                    <MdMoreVert />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        "aria-labelledby": "long-button",
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={deleteDriverDetail}
                                                    onClose={() => { setAnchorEl(null) }}
                                                >
                                                    <MenuItem sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Copy</MenuItem>
                                                    <MenuItem onClick={() => { handleremove("driverDetail") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            )
                        })}
                    </div>
                </Container>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: "30px",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        onClick={handleCancel}
                        title="Cancel"
                        style={{
                            padding: "6px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            marginRight: "10px",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "12px"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // onClick={handleSubmitFilter}
                        style={{
                            padding: "7px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "12px"
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CreateSensitivityProfile
