import React, { useState } from "react";
import { Toolbar, Typography, Grid, IconButton, Collapse, Box, Container, Autocomplete, TextField, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Select, MenuItem, InputLabel, FormControl, Chip } from "@mui/material";
import CreateAttribute from "../../attributes/CreateAttribute";
import AttributesListModel from "./AttributesListModel";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  alpha, styled,
  InputBase
} from "@mui/material";
import moment from "moment/moment";
import ReactSelect from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch } from "react-redux";
import { getAttributeOrEnterPrise } from "../../../store/ApiSlice/attributeSlice";
import { getAttributeValue } from "../../../store/ApiSlice/canvasSlice";
import { FaSearch } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: "1px solid black",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: "12px"
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    zIndex: 9999,
    fontSize: "12px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#0366d6' : 'white',
    color: state.isSelected ? 'white' : 'black',
    fontSize: "12px"
  }),
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'red', fontSize: "12px" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6, fontSize: "12px" }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const AttributesTable = ({
  dimensionAttributes,
  setDimensionAttributes,
  isView,
  isEdit,
  isCreate,
  newData,
  formValues,
  setformValues,
  data,
  selectedDimension,
  selectedDimensions,
  refAttributeMapping,
  setRefAttributeMapping,
  dimenstionData,
  modelEnterprise,
  filterAttributes,
  setFilterAttributes
}) => {
  const dispatch = useDispatch();
  const { attributeValue, filterDetail } = useSelector((state) => state?.canvas)
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
    useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const [selectedValue, setSelectedValue] = useState({})
  const [selectedOption, setSelectedOption] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModalRow, setOpenModalRow] = useState(null);
  const [attributeValueData, setAttributeValueData] = useState({ attValues: {} })
  const [valueModal, setValueModal] = useState(false)
  const [search, setSearch] = useState()
  const [attributePage, setAttributePage] = useState(0);
  const [rowsAttributePerPage, setRowsAttributePerPage] = useState(10);
  const handleClickValue = (rowIndex, data) => {
    setValueModal(true)
    setOpenModalRow(rowIndex)
    if (Object.keys(data?.attValues).length > 0) {
      setAttributeValueData({ attValues: data.attValues });
    }
  }
  const enterPriseId = modelEnterprise?.enterpriseUID
  const modalId = modelEnterprise?.modelUID
  const handleKeyChange = (event, row, keyToUpdate) => {
    let newValue = event.target.checked;
    setDimensionAttributes((prevArray) =>
      prevArray.map((obj, index) => {
        if (row._id === obj._id) {
          if (keyToUpdate === "primaryKey") {
            return {
              ...obj,
              [keyToUpdate]: newValue,
              required: newValue ? true : false,
            };
          } else {
            return { ...obj, [keyToUpdate]: newValue };
          }
        } else {
          return obj;
        }
      })
    );
  };
  const handleAddClick = () => {
    setModalOpen(true);
    dispatch(getAttributeOrEnterPrise({ enterPriseId, modalId }))
  };
  const handleNewClick = () => {
    setIsCreateUpdateModalOpen(true);
  };
  const handleDeleteClick = () => {
    if (selectedRows?.length > 0) {
      setIsDeleteDimensionModalOpen(true);
    } else {
      toast.error("Please Select Assign Attribute.")
      return
    }
  };
  const handleTableRowSelect = (event) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(id)
      ? selectedRows.filter((n) => n !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedIds);
  };

  const handleDeleteSubmit = () => {
    let newFilteredRows = dimensionAttributes.filter((item) => {
      return !selectedRows.includes(item._id);
    });
    setDimensionAttributes(newFilteredRows);
    setIsDeleteDimensionModalOpen(false);
  };
  const handleDeleteDimensionCloseModal = () => {
    setIsDeleteDimensionModalOpen(false);
  };

  const renderModalContent = () => {
    return (
      <Dialog
        open={isDeleteDimensionModalOpen}
        onClose={handleDeleteDimensionCloseModal}
      >
        <DialogTitle>Remove Attribute</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
            Are You Sure For Remove This Attribute?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDimensionCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteSubmit();
            }}
            color="primary" sx={{ textTransform: "capitalize" }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const referenceDimFields =
    dimenstionData && dimenstionData?.fields ? dimenstionData?.fields : [];

  const handleReferenceAttribute = (event, currentAttribute) => {
    let mappingIndex = refAttributeMapping.findIndex((item) => item.mainAttribute === currentAttribute._id);

    if (mappingIndex !== -1) {
      setRefAttributeMapping((prevMappings) => {
        const updatedMappings = [...prevMappings];
        updatedMappings[mappingIndex] = {
          ...updatedMappings[mappingIndex],
          refAttribute: event.target.value
        };
        return updatedMappings;
      });
    } else {
      setRefAttributeMapping((prevMappings) => [
        ...prevMappings,
        {
          mainAttribute: currentAttribute._id,
          refAttribute: event.target.value
        }
      ]);
    }
  };

  const getRefAttributeId = (row) => {
    let mapping = refAttributeMapping.find(
      (item) => item.mainAttribute?._id === row._id
    );
    return mapping ? mapping.refAttribute?._id : mapping;
  };

  const handleStepChangeValue = (newValue, fieldname, event, index, data) => {
    const { name, value, checked } = event.target;
    if (fieldname == "attributes") {
      const updatedFormValues = [...filterAttributes];
      updatedFormValues[index][fieldname] = newValue?._id;
      setFilterAttributes(updatedFormValues);
    }
    else {
      const updatedFormValues = [...filterAttributes];
      updatedFormValues[index][name] = value;
      setFilterAttributes(updatedFormValues);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddField = () => {
    setFilterAttributes([...filterAttributes, {
      attributes: '',
      operator: 'Equal To',
      attValues: {},
    }]);
  };

  const handleRemoveField = () => {
    const updatedFormValues = [...filterAttributes];
    updatedFormValues.splice(selectedValue, 1);
    setFilterAttributes(updatedFormValues);
  };

  const handleCloseValue = () => {
    setValueModal(false)
    setAttributeValueData({ attValues: {} })
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  const handleHeaderCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    const updatedFormValues = { ...attributeValueData };
    attributeValue.forEach((item) => {
      updatedFormValues.attValues[item] = isChecked;
    });
    setAttributeValueData(updatedFormValues)
  };

  const handleCheckboxChange = (newValue, item) => {
    const updatedFormValues = { ...attributeValueData };
    updatedFormValues.attValues[item] = !updatedFormValues.attValues[item];
    setAttributeValueData(updatedFormValues);
  };

  const handleChangeRowsAttributePerPage = (event) => {
    setRowsAttributePerPage(parseInt(event.target.value, 10));
    setAttributePage(0);
  };

  const handleChangeAttributePage = (event, newPage) => {
    setAttributePage(newPage)
  }

  const handleSubmit = (index) => {

    const updatedFormValues = [...filterAttributes];
    updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
    setFilterAttributes(updatedFormValues);
    handleCloseValue();
  };

  let refDimensionFields = dimenstionData?.fields ? dimenstionData?.fields : []
  const refFilterModelAttribute = refDimensionFields?.filter((item) => item?.enterpriseUID === modelEnterprise?.enterpriseUID)
    ?.filter((item) => item?.modelUID === modelEnterprise?.modelUID)
  console.log("🚀 ~ refFilterModelAttribute:", refFilterModelAttribute, filterAttributes, dimensionAttributes)

  return (
    <>
      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />
      <Toolbar>
        <Typography variant="h6">Assigned Attributes</Typography>
      </Toolbar>
      <Grid item>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "26px",
          }}
        >
          <Button
            disabled={isView}
            title="Add new attribute in dimension"
            variant="text"
            onClick={handleAddClick}
            sx={{ textTransform: "capitalize" }}
          >
            Add
          </Button>
          <Button
            disabled={isView}
            title="Create new attribute and add in dimension"
            variant="text"
            onClick={handleNewClick}
            sx={{ textTransform: "capitalize" }}
          >
            New
          </Button>
          <Button
            disabled={isView}
            title="Delete attribute from dimension"
            variant="text"
            onClick={handleDeleteClick}
            sx={{ textTransform: "capitalize" }}
          >
            Remove
          </Button>
        </Typography>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>

              {!isView && <TableCell align="left">Select</TableCell>}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Length</TableCell>
              <TableCell align="left">Notes</TableCell>
              <TableCell align="left">Primary Key</TableCell>
              <TableCell align="left">Required</TableCell>
              {formValues.type === "REFERENCE" && (
                <TableCell align="left">Reference Attribute</TableCell>
              )}
              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Changed On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dimensionAttributes?.length > 0 && dimensionAttributes?.map((item) => (
              <TableRow>
                {!isView && (
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={selectedRows.includes(item?._id)}
                      value={item?._id}
                      onChange={handleTableRowSelect}
                      disabled={item?.fromCompound ? true : false}
                    />
                  </TableCell>
                )}
                <TableCell align="left">{item?.name}</TableCell>
                <TableCell align="left">{item?.type}</TableCell>
                <TableCell align="left">{item?.length}</TableCell>
                <TableCell align="left">{item?.notes}</TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={item?.primaryKey ? true : false}
                    disabled={isView ? true : false}
                    value={item?.primaryKey ? true : false}
                    onChange={(event) => {
                      handleKeyChange(event, item, "primaryKey");
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={item?.required ? true : false}
                    disabled={item?.primaryKey || isView ? true : false}
                    value={item?.required ? true : false}
                    onChange={(event) => {
                      handleKeyChange(event, item, "required");
                    }}
                  />
                </TableCell>
                {formValues.type === "REFERENCE" && (
                  <TableCell align="left">
                    <Select
                      id="type"
                      name="type"
                      value={getRefAttributeId(item)}
                      onChange={(event) => {
                        handleReferenceAttribute(event, item);
                      }}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      disabled={isView}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      {referenceDimFields.map((item) => (
                        <MenuItem value={item?._id}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                )}
                <TableCell align="left">
                  {moment(item?.createdOn).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="left">
                  {moment(item?.changedOn).format("DD-MM-YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!dimensionAttributes?.length && (
          <div className="centeredText" style={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>No attributes selected yet</div>
        )}
      </TableContainer>
      {
        formValues?.type === "REFERENCE" && (
          <>
            <hr
              style={{
                borderTop: "2px solid #1976d2",
                width: "100%",
                margin: "20px auto",
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Toolbar>
                <Typography variant="h6">Filter Assigned Attribute</Typography>
              </Toolbar>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}>
                <Button
                  variant='contained'
                  onClick={handleAddField}
                >
                  Add
                </Button>
                <Button
                  variant='contained'
                  onClick={() => handleRemoveField()}
                >
                  Remove
                </Button>
              </div>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50px" }}>
                        Select
                      </TableCell>
                      <TableCell>Attribute</TableCell>
                      <TableCell>Operator</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  {filterAttributes?.length > 0 && filterAttributes?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((row, i) => {
                    const findAttribute = refFilterModelAttribute.find(item => item?._id === row.attributes);
                    return (
                      <TableBody>
                        <TableCell>
                          <Checkbox onChange={() => { setSelectedValue(i) }} />
                        </TableCell>
                        <TableCell style={{ width: "300px" }}>
                          <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId={`attributes${i}`}
                            name="attributes"
                            options={refFilterModelAttribute}
                            getOptionLabel={(option) => option.name}
                            value={row?.attributes ?
                              refFilterModelAttribute?.find(option => option._id === row?.attributes)
                              || null : null}
                            onChange={(e, newValue) => {
                              handleStepChangeValue(newValue, "attributes", e, i, row);
                            }}
                            sx={{ fontSize: "12px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Attribute"
                                variant="outlined"
                                fullWidth
                                style={{ width: "300px", fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell style={{ width: "240px" }}>
                          <FormControl style={{ width: "240px" }}>
                            <InputLabel id="operator" sx={{ fontSize: "12px" }}>Operator</InputLabel>
                            <Select
                              labelId="Value"
                              name='operator'
                              id="demo-simple-select-readonly"
                              value={row?.operator}
                              onChange={(e, newValue) => {
                                handleStepChangeValue(newValue, "operator", e, i, row);
                              }}
                              variant="outlined"
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                            >
                              <MenuItem value="Equal To" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Equal To</MenuItem>
                              <MenuItem value="Not Equal To" sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Not Equal To</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              cursor: "pointer",
                              border: "1px solid gray",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              flexWrap: "wrap",
                              gap: "20px",
                              padding: `${!row?.attValues ? "10px" : "25px"}`
                            }}
                            fullWidth
                            onClick={() => {
                              handleClickValue(i, row)
                              dispatch(getAttributeValue({ row }))
                            }}
                          >
                            {Object.entries(row?.attValues).map(([key, value]) => (
                              value === true && (
                                <div
                                  key={key}
                                  style={{ background: "bisque", padding: "6px", borderRadius: "10px", fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                                >
                                  {key}
                                </div>
                              )
                            ))}
                          </Box>
                        </TableCell>
                        {valueModal && (

                          <Dialog
                            open={valueModal && openModalRow === i}
                            onClose={handleCloseValue}
                          >
                            <DialogTitle sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Attribute Values</DialogTitle>
                            <DialogContent sx={{ marginTop: "10px !important", width: '500px' }}>
                              <Search sx={{ marginBottom: "31px" }}>
                                <SearchIconWrapper>
                                  <FaSearch />
                                </SearchIconWrapper>
                                <StyledInputBase
                                  placeholder="Search…"
                                  inputProps={{ 'aria-label': 'search' }}
                                  onChange={(e) => { handleSearch(e) }}
                                />
                              </Search>

                              <TableContainer component={Paper}>
                                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                  <Table aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <Checkbox onChange={(e) => handleHeaderCheckboxChange(e, i)} />
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif" }}>{findAttribute?.name}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {search ? (
                                      attributeValue?.filter((item) => {
                                        if (typeof item === 'string') {
                                          return item?.toLowerCase().includes(
                                            typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                          );
                                        } else if (typeof item === 'number') {
                                          return item?.toString().includes(search?.toString());
                                        }
                                        return false;
                                      })?.length === 0 ? (
                                        <TableBody>
                                          <TableCell colSpan={2} style={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif" }}>No data found</TableCell>
                                        </TableBody>
                                      ) : (
                                        attributeValue?.filter((item) => {
                                          if (typeof item === 'string') {
                                            return item?.toLowerCase().includes(
                                              typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                            );
                                          } else if (typeof item === 'number') {
                                            return item.toString()?.includes(search?.toString());
                                          }
                                          return false;
                                        })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => {
                                          return (
                                            <TableBody key={index}>
                                              <TableCell>
                                                <Checkbox
                                                  checked={attributeValueData?.attValues[item] || false}
                                                  onChange={(e) => {
                                                    handleCheckboxChange(e.target.checked, item);
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell>{item}</TableCell>
                                            </TableBody>
                                          )
                                        })
                                      )
                                    ) : (
                                      attributeValue?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                        <TableBody key={index}>
                                          <TableCell>
                                            <Checkbox
                                              checked={attributeValueData?.attValues[item] || false}
                                              onChange={(e) => {
                                                handleCheckboxChange(e.target.checked, item);
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell>{item}</TableCell>
                                        </TableBody>
                                      ))
                                    )}
                                  </Table>
                                </div>
                                <TablePagination
                                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                  component="div"
                                  count={search ? attributeValue?.filter((item) => {
                                    if (typeof item === 'string') {
                                      return item?.toLowerCase().includes(
                                        typeof search === 'number' ? search?.toString() : search?.toLowerCase()
                                      );
                                    } else if (typeof item === 'number') {
                                      return item?.toString().includes(search?.toString());
                                    }
                                    return false;
                                  })?.length : attributeValue?.length}
                                  rowsPerPage={rowsAttributePerPage}
                                  page={attributePage}
                                  onPageChange={(event, newPage) => handleChangeAttributePage(event, newPage)}
                                  onRowsPerPageChange={handleChangeRowsAttributePerPage}
                                />
                              </TableContainer>

                              <DialogActions >
                                <Button onClick={() => {
                                  handleCloseValue()
                                  setSearch()
                                }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", textTransform: "capitalize" }}>
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleSubmit(i)
                                    setSearch()
                                  }}
                                  sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", textTransform: "capitalize" }}
                                >
                                  Submit
                                </Button>
                              </DialogActions>
                            </DialogContent>
                          </Dialog>
                        )}
                      </TableBody>
                    )
                  })}
                </Table>
              </TableContainer>
            </Container>
          </>
        )
      }

      {isModalOpen && (
        <AttributesListModel
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          formValues={formValues}
          newData={newData}
          isEdit={isEdit}
          modelEnterprise={modelEnterprise}
        />
      )}

      {isCreateUpdateModalOpen && (
        <CreateAttribute
          isCreateUpdateModalOpen={isCreateUpdateModalOpen}
          setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
          isEdit={false}
          isCreate={isCreate}
          newData={newData}
          model={isEdit}
          data={data}
          selectedDimension={selectedDimension}
          selectedRows={[]}
          formValueData={formValues}
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          modelEnterprise={modelEnterprise}
        />
      )}
      {renderModalContent()}
    </>
  );
};

export default AttributesTable;
