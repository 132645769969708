import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createProgressStatus, getExcuteProcess, getProcessList } from '../../store/ApiSlice/processSlice';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';
import moment from 'moment';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import * as ExcelJS from 'exceljs';
import useSocket from '../../socket/SocketComponent';

const ProcessMaterialList = ({
    handleTableRowSelect,
    setUpdatedId,
    handleCreateUpdate,
    setModalDeleteOpen, setModalDeleteOpenTerminate,
    setExcuteProcess,
    setIsRunProcess,
    setIsProgressStatus
}) => {
    const { excuteProcessDatas, measureDataResult, processStatus } = useSocket();
    const { processData } = useSelector((state) => state.process)
    const [openedMenus, setOpenedMenus] = useState({});
    const dispatch = useDispatch()
    const userId = localStorage.getItem("accessToken");
    const user = JSON.parse(userId);
    const sortedProcessData = useMemo(() => {
        return [...(processData || [])].sort((a, b) => {
            const processA = a.name.toLowerCase();
            const processB = b.name.toLowerCase();
            if (processA < processB) return -1;
            if (processA > processB) return 1;
            return 0;
        });
    }, [processData]);

    const handleClick = (rowId) => (event) => {
        event.stopPropagation();
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    useEffect(() => {
        dispatch(getProcessList())
    }, [])


    useEffect(() => {
        if (measureDataResult?.downloadedDatas?.length > 0) {
            generateExcel(measureDataResult);
        }
    }, [measureDataResult]);


    const generateExcel = (datas) => {
        const downloadedDatas = datas?.downloadedDatas || {};
        const workbook = new ExcelJS.Workbook();
        Object.entries(downloadedDatas).forEach(([fieldName, fieldData]) => {
            Object.entries(fieldData).forEach(([sheet, value]) => {
                const worksheet = workbook.addWorksheet(sheet);

                if (value.length > 0) {
                    // Add headers
                    const header = Object.keys(value[0]).filter(key => key !== "_id");
                    worksheet.addRow(header);

                    // Add rows
                    value.forEach(obj => {
                        const row = header.map(col => obj[col] !== undefined ? obj[col] : '');
                        worksheet.addRow(row);
                    });
                }
            });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const fileName = 'measure_data.xlsx';
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const handleTerminate = async (id) => {
        const body = {
            processUID: id,
            whenExecuted: "Immediate",
        }

        const response = await dispatch(getExcuteProcess(body))
        if (response?.meta?.requestStatus === "fulfilled") {
            setIsRunProcess(false);
            // toast.success(response?.payload?.message);
            processStatus(user?.userId);
            dispatch(getProcessList());
        } else if (response?.meta?.requestStatus === "rejected") {
            toast.warn(
                response?.payload?.data?.error
                    ? response?.payload?.data?.error
                    : "Process is not executing."
            );
        }
    }

    const columns = useMemo(() => [
        {
            accessorKey: "name",
            enableColumnOrdering: false,
            header: "Process Name",
            enableSorting: false,
        },
        {
            accessorKey: "enterpriseName",
            enableColumnOrdering: false,
            header: "Enterprise Name",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.enterpriseUID?.enterpriseName}</p>
                </>
            )
        },
        {
            accessorKey: "notes",
            enableColumnOrdering: false,
            header: "Note",
            enableSorting: false,
        },
        {
            accessorKey: "status",
            enableColumnOrdering: false,
            header: "Process Status",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    {/* <p>{excuteProcessDatas?.process === row?.original?._id ? excuteProcessDatas?.status : row?.original?.latestLog?.status ? row?.original?.latestLog?.status : "Not Started"}</p> */}
                    <p>{row?.original?.latestLog?.status ? row?.original?.latestLog?.status : "-"}</p>
                </>
            )
        },
        {
            accessorKey: "createdOn",
            enableColumnOrdering: false,
            header: "Created On",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{moment(row?.original?.createdOn).format(user?.data?.dateFormat ? user?.data?.dateFormat : "DD-MM-YYYY")}</p>
                </>
            )
        },
        {
            accessorKey: "changedOn",
            enableColumnOrdering: false,
            header: "Changed On",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{moment(row?.original?.changedOn).format(user?.data?.dateFormat ? user?.data?.dateFormat : "DD-MM-YYYY")}</p>
                </>
            )
        },
        {
            accessorKey: "view",
            header: "",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                return (
                    <>
                        <IconButton onClick={handleClick(row.original._id)} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={openedMenus[row.original._id]}
                            open={Boolean(openedMenus[row.original._id])}
                            onClose={() => { handleClose(row.original._id) }}
                        // onClick={}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    handleTerminate(row.original._id)
                                }}
                                size="small"
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Run Process Now
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setIsRunProcess(true)
                                    setExcuteProcess("Later")
                                }}
                                size="small"
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Schedule Process Run
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setModalDeleteOpenTerminate(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Terminate Process
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setIsProgressStatus(true);
                                    dispatch(createProgressStatus(row))
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Progress status
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    handleCreateUpdate(true, row?.original?._id);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Edit Process
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setModalDeleteOpen(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Delete Process
                            </MenuItem>

                        </Menu >
                    </>
                )
            }
        }
    ])

    const table = useMaterialReactTable({
        columns: columns.filter(Boolean),
        data: sortedProcessData || [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableColumnPinning: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            }
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                handleClose(row.original._id);
                handleCreateUpdate(true, row?.original?._id);
            }, // Row click event
            sx: {
                cursor: 'pointer', // Add a pointer cursor to indicate clickable rows
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                '&:hover': {
                    backgroundColor: '#f5f5f5', // Optional: Add hover effect for clarity
                },
            },
        }),
    });
    return <MaterialReactTable table={table} />
}

export default ProcessMaterialList
