import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    FaChartLine,
    FaClipboardList,
    FaCog,
    FaBox,
    FaChevronLeft,
    FaChevronDown,
    FaChevronUp,
    FaBars,
    FaCogs,
    FaFilter,
    FaTasks,
    FaUserShield,
    FaUser,
    FaHome,
    FaFileInvoice,
    FaUsersCog,
    FaChartBar,
    FaLayerGroup,
} from "react-icons/fa";
import { LuChevronsUpDown } from "react-icons/lu";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiBuildings } from "react-icons/bi";
import { RiFlowChart } from "react-icons/ri";
import vyanBlackLogo from "../image/Black-vyan-logo.svg";
import user from "../image/User-v3.svg";
import vyanLogo from "../image/vyan-logo.svg";
import { LuSearch } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import useSocket from "../socket/SocketComponent";
import { useDispatch } from "react-redux";
import { EnterpriseModel } from "./enterprise/EnterpriseModel";
import { toast } from "react-toastify";
import { changeDefaultEnterprise } from "../store/ApiSlice/userSlice";
import { setExpandedMenu } from "../store/ApiSlice/authSlice";


function Sidebar() {
    const [expandedMenuItem, setExpandedMenuItem] = useState(null);
    const [open, setOpen] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const userProfile = JSON.parse(localStorage.getItem("accessToken"))
    const [enterpriseModel, setEnterpriseModel] = useState(false);
    const [defaultEnterprise, setDefaultEnterprise] = useState({});
    const dropdownRef = useRef(null);
    const dispatch = useDispatch()
    const { userOffline } = useSocket();

    const menuItems = [
        { to: "/", icon: FaHome, label: "Home" },
        {
            icon: FaClipboardList,
            label: "Planning",
            subItems: [
                { to: "/canvas", label: "Canvas", icon: FaLayerGroup },
                { to: "/scenario", label: "Scenario", icon: FaFileInvoice },
                { to: "/planning-objects", label: "Planning Objects", icon: RiFlowChart },
                { to: "/filter", label: "Global Filter", icon: FaFilter },
                { to: "/process", label: "Process", icon: FaCog },
                { to: "/profile", label: "Forecast Profile", icon: AiOutlineLineChart },
                { to: "/MeasureDataProfile", label: "Measure Data Profile", icon: FaChartBar },
            ],
        },
        { to: "/model", icon: FaChartLine, label: "Models" },
        {
            icon: FaBox,
            label: "System Administration",
            subItems: [
                ...(userProfile?.data?.superAdmin === true ? [
                    { to: "/super-addministrator", label: "Super Administrator", icon: FaUserShield },
                    { to: "/process-run-status", label: "Process Run Status", icon: FaTasks },
                    { to: "/enterprise", label: "Enterprise", icon: BiBuildings },
                    { to: "/multi-enterprise-user", label: "Multi-Enterprise User", icon: FaUsersCog },
                ] : []),
                { to: "/role", label: "Role", icon: FaCogs },
                { to: "/users", label: "User", icon: FaUser },
            ],
        },
    ];

    const settingsItem = { to: "/settings", icon: FaCog, label: "Settings" };

    const getCombinedPermissions = (roles) => {
        const permissionsMap = roles?.reduce((acc, role) => {
            return role?.roleUID?.permissions?.reduce((permAcc, permission) => {
                const object = permission?.object;
                const editAllowed = permission?.editAllowed;

                if (!permAcc[object]) {
                    permAcc[object] = editAllowed;
                } else {
                    permAcc[object] = permAcc[object] || editAllowed;
                }

                return permAcc;
            }, acc);
        }, {});

        return Object.keys(permissionsMap).map(object => ({
            object,
            editAllowed: permissionsMap[object]
        }));
    };

    const hasPermission = (permissions, object) => {
        const permission = permissions?.find(p => p.object === object);
        return permission && permission?.editAllowed;
    };

    const getFilteredMenu = (menu, permissions) => {
        return menu?.reduce((acc, menuItem) => {
            if (menuItem?.subItems) {
                const filteredItems = menuItem?.subItems?.filter(subItem => hasPermission(permissions, subItem.label));

                if (filteredItems?.length > 0) {
                    acc?.push({ ...menuItem, subItems: filteredItems });
                }
            } else {
                acc?.push(menuItem);
            }
            return acc;
        }, []);
    };

    let filteredMenu;
    if (userProfile?.data?.role && userProfile?.data?.role?.length > 0) {
        const combinedPermissions = getCombinedPermissions(userProfile?.data?.role);
        filteredMenu = getFilteredMenu(menuItems, combinedPermissions);
    }

    const sidebarmenu = (user?.data?.role?.length > 0 && user?.data?.role) ? filteredMenu : menuItems

    const handleMenuClick = (label) => {
        setExpandedMenuItem(expandedMenuItem === label ? null : label);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const navigate = useNavigate();

    const handleDefaultEnterprise = () => {
        setEnterpriseModel(!enterpriseModel);
        setOpen(false)
    }
    const handleLogout = () => {
        navigate("/login");
        userOffline(userProfile?.userId)
        localStorage.removeItem("accessToken");
        dispatch(setExpandedMenu("Planning"))
        setOpen(false)
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setDefaultEnterprise({
            ...defaultEnterprise,
            enterpriseUID: userProfile?.data?.enterprise?._id
        })
    }, [enterpriseModel])

    const handleSubmit = async () => {
        const body = {
            enterpriseUID: defaultEnterprise?.enterpriseUID
        }
        const responce = await dispatch(changeDefaultEnterprise(body))
        if (responce?.meta?.requestStatus === "fulfilled") {
            toast.success(responce?.payload?.message)
        } else if (responce?.meta?.requestStatus === "rejected") {
            toast.error(responce?.error?.message);
        }
    }

    return (
        <div className={`${isSidebarOpen ? "w-[296px] justify-between" : "w-[70px] justify-center"
            } flex h-screen shadow-md transition-all duration-300 sticky top-0 z-[50]`}>
            <aside className="w-full">
                <div className={`flex items-center mt-5 px-5 ${isSidebarOpen ? "justify-between" : "justify-center"}`}>
                    {isSidebarOpen ?
                        <img src={vyanBlackLogo} onClick={() => navigate("/")} alt="Vyan Logo" className="w-32 h-auto cursor-pointer" /> :
                        <button className="p-1 rounded-lg  border-[0.5px] border-[#E9EAEB] shadow-[0px_1px_2px_0px_#0A0D120F] bg-white" onClick={toggleSidebar}>
                            <img src={vyanLogo} onClick={() => navigate("/")} alt="Vyan Logo" className="w-auto h-auto cursor-pointer" />
                        </button>
                    }
                    <button onClick={toggleSidebar} className="text-black hover:text-[#FF6C46]">
                        {isSidebarOpen && <FaChevronLeft />}
                    </button>
                </div>
                {isSidebarOpen &&
                    <div className="p-5">
                        <div className="flex px-3 py-2 rounded-md border-2 overflow-hidden max-w-md mx-auto font-[sans-serif]">
                            <LuSearch className="mr-2 text-[#717680]" />
                            <input type="email" placeholder="Search" className="w-full outline-none bg-transparent placeholder:text-[#717680] text-md" />
                        </div>
                    </div>
                }

                {!isSidebarOpen &&
                    <div className="border-[0.5px] border-[#E9EAEB] m-4"></div>
                }

                <div className={`flex flex-col justify-between ${isSidebarOpen ? 'h-[calc(100%-144px)]' : 'h-[calc(100%-106px)]'}`}>
                    <nav className="space-y-3 px-4">
                        {sidebarmenu.map((item) => {
                            const Icon = item.icon;
                            const isExpanded = expandedMenuItem === item.label;

                            return (
                                <div key={item.label}>
                                    <div
                                        className={`flex items-center cursor-pointer p-2 rounded-lg text-sm transition-all ${isSidebarOpen ? "justify-between" : "justify-center"
                                            } group`}
                                        onClick={() => handleMenuClick(item.label)}
                                    >
                                        <NavLink
                                            to={item.to || "#"}
                                            className="flex items-center gap-2 text-black"
                                            data-tooltip-id={isSidebarOpen ? undefined : `tooltip-${item.label}`}
                                        >
                                            <div className="relative">
                                                <Icon className="text-base" />
                                                {!isSidebarOpen && (
                                                    <Tooltip id={`tooltip-${item.label}`} place="right" style={{
                                                        fontSize: "10px",
                                                        padding: "4px 8px",
                                                    }}>
                                                        {item.label}
                                                    </Tooltip>
                                                )}
                                            </div>
                                            {isSidebarOpen && <span>{item.label}</span>}
                                        </NavLink>
                                        {isSidebarOpen && item.subItems && (
                                            <button>
                                                {isExpanded ? <FaChevronUp fontSize={12} /> : <FaChevronDown fontSize={12} />}
                                            </button>
                                        )}
                                    </div>

                                    {item.subItems && isExpanded && (
                                        <div className={`${isSidebarOpen ? 'ml-8' : 'ml-0'} space-y-2 mt-2`}>
                                            {item.subItems.map((subItem) => (
                                                <NavLink
                                                    key={subItem.to}
                                                    to={subItem.to}
                                                    className={`block p-2 text-sm rounded-lg text-black flex items-center gap-2 ${isSidebarOpen ? "justify-start" : "justify-center"
                                                        } group`}
                                                    data-tooltip-id={!isSidebarOpen ? `tooltip-${subItem.to}` : undefined}
                                                >
                                                    <div className="relative">
                                                        <subItem.icon className="text-base" />
                                                        {!isSidebarOpen && (
                                                            <Tooltip id={`tooltip-${subItem.to}`} place="right" style={{
                                                                fontSize: "10px",
                                                                padding: "4px 8px",
                                                            }}>
                                                                {subItem.label}
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                    {isSidebarOpen && <span>{subItem.label}</span>}
                                                </NavLink>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </nav>
                    <nav className="space-y-3 px-4">
                        <NavLink to={settingsItem.to} className={`flex items-center gap-2 p-2 rounded-lg text-sm text-black transition ${isSidebarOpen ? 'justify-start' : 'justify-center'}`}>
                            <settingsItem.icon className="text-base" />
                            {isSidebarOpen && <span>{settingsItem.label}</span>}
                        </NavLink>
                        <div className={`flex ${isSidebarOpen ? 'p-2 rounded-md border border-[#E9EAEB] gap-2' : ''}`} >
                            <div className="relative w-full">
                                <img className="w-10 h-10 rounded-full" src={user} alt="profile-img" />
                                <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                            </div>
                            {isSidebarOpen &&
                                <div className="flex items-start justify-between w-full">
                                    <div className="block">
                                        <div className="text-black font-semibold text-sm leading-5">{userProfile?.data?.userName}</div>
                                        <div className="text-[#535862] text-sm font-normal leading-5">{userProfile?.data?.email}</div>
                                    </div>
                                    <div><LuChevronsUpDown onClick={() => setOpen(!open)} /></div>
                                    {open && (
                                        <div
                                            ref={dropdownRef}
                                            id="dropdownInformation"//-top-[120px]
                                            className="absolute right-[12px] bottom-[80px] z-[11111] w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600"
                                        >
                                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                                <li className="flex flex-col px-4 py-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                    {userProfile?.data?.userName}
                                                    <span className="text-xs text-gray-500 dark:text-gray-400">{userProfile?.data?.email}</span>
                                                </li>
                                                <div className="w-full h-px bg-gray-300 dark:bg-gray-600"></div>
                                                <li className="flex flex-col px-2 pt-2">
                                                    <button
                                                        onClick={() => navigate("/user-account")}
                                                        className="flex items-center px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-300"
                                                    >
                                                        User Data
                                                    </button>

                                                    <button
                                                        onClick={handleDefaultEnterprise}
                                                        className="flex items-center px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-300"
                                                    >
                                                        Default Enterprise
                                                    </button>

                                                    <button
                                                        onClick={handleLogout}
                                                        className="flex items-center px-3 py-2 rounded-md text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700 transition duration-300"
                                                    >
                                                        Sign Out
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </nav>
                </div>
            </aside>
            {enterpriseModel &&
                <EnterpriseModel
                    enterpriseModel={enterpriseModel}
                    handleDefaultEnterprise={handleDefaultEnterprise}
                    userProfile={userProfile}
                    defaultEnterprise={defaultEnterprise}
                    setDefaultEnterprise={setDefaultEnterprise}
                    handleSubmit={handleSubmit}
                />
            }
        </div>
    );
}

export default Sidebar;