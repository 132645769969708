import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteScenario, deleteScenarioList, getAllParentScenarioList, getAllScenarioList } from '../../../store/ApiSlice/scenarioListSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const DeleteScenario = ({
    isModalOpenDelete,
    setModalOpenDelete,
    attriId,
    setAttriId,
    formValues
}) => {
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        setModalOpenDelete(false);
    };

    const handleDelete = async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        const payload = {
            enterpriseUID: formValues?.enterpriseUID,
            modelUID: formValues?.modelUID,
            ids: [attriId]
        }
        const response = await dispatch(deleteScenarioList({ config, payload }))
        if (response?.meta?.requestStatus === "fulfilled") {
            setModalOpenDelete(false);
            dispatch((getAllScenarioList({ formValues })));
            dispatch((getAllParentScenarioList({ formValues })));
        } else if (response?.meta?.requestStatus === "rejected") {
            toast.warn(
                response?.payload?.data?.error
            );
        }
        handleCloseModal()
    }

    const renderModalContent = () => {
        return (
            <>
                <DialogTitle>Delete Scenario</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography>
                        This Is Irreversible. Are You Sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button color="primary" onClick={handleDelete} sx={{ textTransform: "capitalize" }}>
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };

    return (
        <>
            <Dialog open={isModalOpenDelete} onClose={handleCloseModal}>
                {renderModalContent()}
            </Dialog>
        </>
    )
}

export default DeleteScenario
