import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';



const useSocket = () => {
  const [excuteProcessDatas, setExcuteProcessDatas] = useState({});
  const [measureDataResult, setMeasureDataResult] = useState({});
  const socketRef = useRef(null);
  const token = JSON.parse(localStorage.getItem('accessToken'))
  const userID = token?.userId?.toString();

  useEffect(() => {
    socketRef.current = io('https://app.vyan.ai')
    // socketRef.current = io('http://127.0.0.1:4000');
    socketRef.current.on('connect', () => {
      if (userID) {
        socketRef.current.emit('connect_user', { userID: userID });
      }
    });
    socketRef.current.on('process_status', (data) => {
      setExcuteProcessDatas(data);
    });

    socketRef.current.on('measure_data_profile_result', (data) => {

      setMeasureDataResult(data);
      data?.deleteResults?.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          toast.error(value);
        });
      });

    });

    return () => {
      // socketRef.current.disconnect()
      if (socketRef.current) {
        socketRef.current.off("process_status")
        socketRef.current.off("measure_data_profile_result")
        userOffline(userID).then(() => socketRef.current.disconnect())
      }
    };
  }, [userID]);

  const userOffline = (userID) => {
    return new Promise((resolve) => {
      if (socketRef.current) {
        socketRef.current.emit('disconnect_user', { userID: userID }, () => { resolve() })
      } else { resolve() }
    })
  }

  const userOnline = (userID) => {
    if (socketRef.current) {
      socketRef.current.emit('connect_user', { userID: userID })
    }
  }
  const processStatus = (userID) => {
    if (socketRef.current) {
      socketRef.current.emit('connect_user', { userID: userID })
    }
  }

  return { socketRef, userOnline, userOffline, excuteProcessDatas, measureDataResult, processStatus, setExcuteProcessDatas, setMeasureDataResult }
};

export default useSocket;
