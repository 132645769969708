import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../ThemeContext';
import { Box, Button, Tooltip } from '@mui/material';
import MiniDrawer from '../common/drawer/DrawerToggle';
import CreateSuperAdministrator from './CreateSuperAdministrator';
import SuperAdministratorMaterialList from './SuperAdministratorMaterialList';
import { useDispatch } from 'react-redux';
import { getSuperAdministrator } from '../../store/ApiSlice/superAdministratorSlice';
import DeleteSuperAdministrator from './DeleteSuperAdministrator';

const SuperAdministratorList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const dispatch = useDispatch()
    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }
    const handleTableRowSelect = (event, row) => {
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    useEffect(() => {
        dispatch(getSuperAdministrator())
    }, [])
    return (
        <>
            {!isCreate && (
                <Box sx={{ display: "flex" }}>
                    <MiniDrawer />
                    <Box component="main" sx={{
                        flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                        background: isDarkTheme ? "whitesmoke" : "white",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "60px",
                            paddingBottom: "20px",
                            position: "relative"
                        }}>
                            <h3 className="heading">Super Administrator</h3>
                            <div>
                                <Tooltip title="Add Profile">
                                    <Button
                                        onClick={() => {
                                            handleCreateUpdate(false)
                                        }}
                                        className="awesome-button"
                                        sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                    >
                                        +
                                    </Button>
                                </Tooltip>
                                <DeleteSuperAdministrator
                                    setModalDeleteOpen={setModalDeleteOpen}
                                    isModalDeleteOpen={isModalDeleteOpen}
                                    updatedId={updatedId}
                                />
                            </div>
                        </div>
                        <SuperAdministratorMaterialList
                            handleTableRowSelect={handleTableRowSelect}
                            setUpdatedId={setUpdatedId}
                            handleCreateUpdate={handleCreateUpdate}
                            setModalDeleteOpen={setModalDeleteOpen}
                        />
                    </Box>
                </Box>
            )}
            {(isCreate) && (
                <CreateSuperAdministrator
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    selectedRows={updatedId}
                />
            )}
        </>
    )
}

export default SuperAdministratorList
