import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../ThemeContext';
import { Box, Button, Tooltip } from '@mui/material';
import MiniDrawer from '../common/drawer/DrawerToggle';
import DeleteSensitivityProfile from './DeleteSensitivityProfile';
import SensitivityProfileMaterialList from './SensitivityProfileMaterialList';
import CreateSensitivityProfile from './CreateSensitivityProfile';
import { useDispatch } from 'react-redux';
import { getProfileData } from '../../store/ApiSlice/profileSlice';

const SensitivityProfileList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false)
    const dispatch = useDispatch()

    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }

    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    useEffect(() => {
        dispatch(getProfileData())
    }, [])

    return (
        <>
            {!isCreate && !isView && (
                <>
                {/* <Box sx={{ display: "flex" }}>
                    <MiniDrawer />
                    <Box component="main" sx={{
                        flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                        background: isDarkTheme ? "whitesmoke" : "white",
                    }}> */}
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "60px",
                            paddingBottom: "20px",
                            position: "relative"
                        }}>
                            <h3 className="heading">Driver Sensitivity Profiles</h3>
                            <div>
                                <Tooltip title="Add Sensitivity Profile">
                                    <Button
                                        onClick={() => {
                                            handleCreateUpdate(false)
                                        }}
                                        className="awesome-button"
                                        sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                    >
                                        +
                                    </Button>
                                </Tooltip>
                                <DeleteSensitivityProfile
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setModalDeleteOpen={setModalDeleteOpen}
                                    isModalDeleteOpen={isModalDeleteOpen}
                                    updatedId={updatedId}
                                />
                            </div>
                        </div>
                        <SensitivityProfileMaterialList
                            handleTableRowSelect={handleTableRowSelect}
                            setUpdatedId={setUpdatedId}
                            handleCreateUpdate={handleCreateUpdate}
                            setModalDeleteOpen={setModalDeleteOpen}
                        />
                    {/* </Box>
                </Box> */}
                </>
            )}
            {(isCreate || isView) && (
                <CreateSensitivityProfile
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isView={isView}
                    setIsView={setIsView}
                    selectedRows={updatedId}
                />
            )}
        </>
    )
}

export default SensitivityProfileList
