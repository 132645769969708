import React, { useState } from 'react';
import { Toolbar, Typography, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import DimensionListModel from './DimensionListModel';
import { toast } from 'react-toastify';

const SelectedDimensionsTable = ({
    selectedDimensions,
    setSelectedDimensions,
    dimensionAttributes,
    setDimensionAttributes,
    isView,
    isEdit,
    formValues,
    newData
}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] = useState(false)
    const [checkedDimensions, setCheckedDimensions] = useState([]);

    const handleAddClick = () => {
        if (formValues?.modelName && formValues?.enterpriseName) {
            setModalOpen(true)
        } else {
            toast.error("First Select enterprise name and model name")
        }
    }
    const handleDeleteClick = () => {
        setIsDeleteDimensionModalOpen(true);
    }
    const handleTableRowSelect = (event) => {
        let id = event.target.value
        let updatedIds = selectedRows.includes(id)
            ? selectedRows.filter((n) => n !== id)
            : [...selectedRows, id]
        setSelectedRows(updatedIds)
    }

    const handleDeleteSubmit = () => {
        let newFilteredRows = selectedDimensions.filter((item) => {
            return !selectedRows.includes(item._id)
        })

        let removedDimensions = selectedDimensions.filter((item) => {
            return selectedRows.includes(item._id)
        })

        let primaryKeysAttributeOfRemovedDimensions = []
        removedDimensions.forEach(dimension => {
            if (dimension.primaryKey && dimension.primaryKey.length)
                primaryKeysAttributeOfRemovedDimensions = [...primaryKeysAttributeOfRemovedDimensions, ...dimension.primaryKey]
        })
        let idsOfRemovedAttributes = primaryKeysAttributeOfRemovedDimensions.map(item => item._id)

        let leftAttributes = dimensionAttributes.filter(attrib => {
            return !idsOfRemovedAttributes.includes(attrib._id)
        })
        setDimensionAttributes([...leftAttributes])
        setSelectedDimensions(newFilteredRows);
        setIsDeleteDimensionModalOpen(false);
    }

    const handleDeleteDimensionCloseModal = () => {
        setIsDeleteDimensionModalOpen(false);
    }

    const renderModalContent = () => {
        return (
            <Dialog open={isDeleteDimensionModalOpen} onClose={handleDeleteDimensionCloseModal}>
                <DialogTitle>Remove Dimension</DialogTitle>
                <DialogContent style={{ width: '500px', margin: '2px' }}>
                    <Typography variant="h6" component="h2">
                        Are you sure for Remove this dimension?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDimensionCloseModal}>Cancel</Button>
                    <Button onClick={() => { handleDeleteSubmit() }} color="primary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <hr style={{ borderTop: '2px solid #1976d2', width: '100%', margin: '20px auto' }} />
            <Toolbar>
                <Typography variant="h6">Child Planning Dimension</Typography>
            </Toolbar>
            <Grid item>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '26px' }}>
                    <Button disabled={isView} title='Add new dimension' variant="text" onClick={handleAddClick}>Add</Button>
                    <Button disabled={isView} title='Remove dimension' variant="text" onClick={handleDeleteClick}>Remove</Button>
                </Typography>
            </Grid>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {!isView && <TableCell align="left">Select</TableCell>}
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedDimensions.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {!isView &&
                                    <TableCell component="th" scope="row">
                                        <Checkbox checked={selectedRows.includes(row._id)} value={row._id} onChange={handleTableRowSelect} />
                                    </TableCell>
                                }
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">{row.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!selectedDimensions.length && <div className="centeredText">No dimensions selected yet</div>}

            </TableContainer>

            {isModalOpen &&
                <DimensionListModel
                    selectedDimensions={selectedDimensions}
                    setSelectedDimensions={setSelectedDimensions}
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    checkedDimensions={checkedDimensions}
                    setCheckedDimensions={setCheckedDimensions}
                    dimensionAttributes={dimensionAttributes}
                    setDimensionAttributes={setDimensionAttributes}
                    formValues={formValues}
                    newData={newData}
                    isEdit={isEdit}
                />
            }
            {renderModalContent()}
        </>
    )
}

export default SelectedDimensionsTable;
