import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllPlanningObject, getPlanningObjects } from '../../store/ApiSlice/dimensionSlice';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, TablePagination, Typography } from '@mui/material';
import { MdMoreVert } from "react-icons/md";
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';

const LevelPlanningObjects = ({
    planningObjectData,
    setPlanningObjectData,
    setCreatePlanningObjectModel,
    handleCreateUpdatePlanningObject,
    setPlanningObjectID,
    setDeletePlanningObject
}) => {
    const userData = JSON.parse(localStorage.getItem("accessToken"))
    const { planningObjectsData, totalPlanningObject, planningObjectMasterData } = useSelector((state) => state.dimension);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPage, setTotalPage] = useState(0)
    const [loader, setLoader] = useState(true)
    const [openedMenus, setOpenedMenus] = useState({});
    const [deleteMassObject, setDeleteMassObject] = useState(false)
    const userId = localStorage.getItem("accessToken");
    const dispatch = useDispatch()

    const fetchData = async () => {
        await dispatch(getPlanningObjects({ page: page + 1, limit: rowsPerPage, id: planningObjectData?._id }));
        setLoader(false);
    };

    const handleClick = (rowId) => (event) => {
        event.stopPropagation();
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };


    const handleDelete = async () => {
        const payload = {
            enterpriseUID: planningObjectData?.enterpriseUID?._id,
            modelUID: planningObjectData?.entermodelUID.modelUID,
            dimensionUID: planningObjectData?._id,
        }
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` }, data: payload };
        const response = await axios.delete(`${API_BASE_URL}/planning-object/delteAll`, config)
        if (response?.status === 200) {
            setDeleteMassObject(false)
            dispatch(getPlanningObjects({ page: 1, limit: 10, id: planningObjectData?._id }));
        } else {
            toast.error(response?.data?.error);
        }
    };

    const columns = useMemo(() => {
        const fieldColumns = planningObjectMasterData?.requiredFields?.length > 0
            ? planningObjectMasterData.requiredFields
                .filter(item => !["Time Period Name"].includes(item.name))
                .filter(item => !["Valid From"].includes(item))
                .filter(item => !["Valid To"].includes(item))
                .map(field => ({
                    accessorKey: field,
                    enableColumnOrdering: false,
                    header: field,
                    enableSorting: false,
                    Cell: ({ row }) => {
                        return <p>{row.original[field]}</p>
                    }
                }))
            : [];

        const viewColumn = [
            ...(planningObjectMasterData?.schemaInfo?.["Valid From"]?.required
                ? [{
                    accessorKey: 'Valid From',
                    header: 'Valid From',
                    enableSorting: false,
                    enableColumnOrdering: false,
                    Cell: ({ row }) => (
                        <>
                            <p>{moment(row?.original?.["Valid From"]).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
                        </>
                    ),
                }]
                : []
            ),
            ...(planningObjectMasterData?.schemaInfo?.["Valid To"]?.required
                ? [{
                    accessorKey: 'Valid To',
                    header: 'Valid To',
                    enableSorting: false,
                    enableColumnOrdering: false,
                    // Cell: ({ row }) => (
                    //     <>
                    //         <p>{moment(row?.original?.["Valid To"]).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
                    //     </>
                    // ),
                    Cell: ({ row }) => {
                        const validToValue = row?.original?.["Valid To"];
                        const formattedDate = moment(validToValue, "DD-MM-YYYY", true).isValid()
                            ? moment(validToValue, "DD-MM-YYYY").format(userData?.data?.dateFormat || "DD-MM-YYYY")
                            : "Invalid Date";
                        return <p>{formattedDate !== "Invalid Date" ? formattedDate : moment(row?.original?.["Valid To"]).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>;
                    },
                }]
                : []
            ),
            {
                accessorKey: 'view',
                header: 'View',
                enableSorting: false,
                enableColumnOrdering: false,
                Cell: ({ row }) => {
                    return (
                        <>
                            <IconButton onClick={handleClick(row.original._id)} size="small">
                                <MdMoreVert />
                            </IconButton>
                            <Menu
                                anchorEl={openedMenus[row.original._id]}
                                open={Boolean(openedMenus[row.original._id])}
                                onClose={() => { handleClose(row.original._id) }}
                            >
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleCreateUpdatePlanningObject(true, row?.original?._id);
                                        handleClose(row.original._id);
                                        dispatch(getAllPlanningObject({
                                            payload: {
                                                enterpriseUID: planningObjectData?.enterpriseUID._id,
                                                modelUID: planningObjectData?.entermodelUID.modelUID,
                                                dimensionUID: planningObjectData?._id
                                            }
                                        }))
                                    }}
                                    size="small"
                                    sx={{ fontSize: "12px" }}
                                >
                                    Edit Planning Object
                                </MenuItem>
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleClose(row.original._id);
                                        setPlanningObjectID(row.original._id);
                                        setDeletePlanningObject(true);
                                    }}
                                    sx={{ fontSize: "12px" }}
                                >
                                    Delete Planning Object
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
            }
        ];

        return [...fieldColumns, ...viewColumn];
    }, [planningObjectData?.fields, openedMenus, planningObjectMasterData]);


    const table = useMaterialReactTable({
        columns,
        data: planningObjectsData?.length > 0 ? planningObjectsData : [],
        enableColumnOrdering: true,
        enableSelectAll: true,
        enableRowSelection: true,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableColumnPinning: true,
        enablePagination: false,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px"
            }
        },
        state: {
            isLoading: loader,
        },
        muiCircularProgressProps: {
            thickness: 5,
            size: 55,
            sx: {
                color: 'black',
            }
        },
        muiSkeletonProps: {
            animation: 'pulse',
            height: 28,
        },
    });

    const selectedRows = table?.getSelectedRowModel().rows;
    const selectedIds = selectedRows?.map((row) => row?.original._id);

    useEffect(() => {
        fetchData();
    }, [rowsPerPage, page])

    const renderModalContent = () => {
        return (
            <>
                <DialogTitle>Delete All Manage Planning Object</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography>
                        This Is Irreversible. Are You Sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDeleteMassObject(false)
                    }} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button color="primary" onClick={handleDelete} sx={{ textTransform: "capitalize" }}>
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };

    return (
        <>
            {selectedIds?.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", paddingBottom: "20px" }}>
                    <Button variant='contained' onClick={() => {
                        setDeleteMassObject(true)
                    }}>Delete All</Button>
                </Box>
            )}
            <Dialog open={deleteMassObject} onClose={() => {
                setDeleteMassObject(true)
            }}>
                {renderModalContent()}
            </Dialog>
            <MaterialReactTable table={table} />
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    )
}

export default LevelPlanningObjects
