import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

const ProgressStatus = ({
  setIsProgressStatus,
  isProgressStatus
}) => {
  const { progressStatusDetail } = useSelector((state) => state.process)
  const handleCloseModal = () => {
    setIsProgressStatus(false);
  };
  const renderModalContent = () => {
    return (
      <>
        <DialogTitle sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>Progress Status Detail</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <FormControl
            fullWidth
            style={{ paddingBottom: '25px', marginTop: '10px' }}
          >
            <TextField
              id="selectDate"
              label="From"
              variant="outlined"
              fullWidth
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(progressStatusDetail?.startTime)?.format("DD-MM-YYYY")}
            />
          </FormControl>
          <FormControl
            fullWidth
            style={{ paddingBottom: '25px', marginTop: '5px' }}
          >
            <TextField
              id="selectDate"
              label="To"
              variant="outlined"
              fullWidth
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(progressStatusDetail?.endTime)?.format("DD-MM-YYYY")}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button color="primary">
            Progress status
          </Button>
        </DialogActions>
      </>
    )
  }
  return (
    <>
      <Dialog open={isProgressStatus} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default ProgressStatus
