import { IconButton, Menu, MenuItem } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { MdMoreVert } from 'react-icons/md';
import { useSelector } from 'react-redux';

const ScenarioMaterialList = ({
    formValues,
    selectedRows,
    handleCreateUpdate,
    handleTableRowSelect,
    setModalOpenDelete,
    setAttriId,
    setPromoteDeleteScenario,
    setPromoteScenario
}) => {
    const userData = JSON.parse(localStorage.getItem("accessToken"))
    const [openedMenus, setOpenedMenus] = useState({});
    const { scenarioData } = useSelector((state) => state?.scenario);

    const findScenarioType = useMemo(() => {
        return scenarioData?.length > 0 ? scenarioData?.filter((item) => item?.["scenarioType"] === "User") : []
    }, [scenarioData]);

    const handleClick = (rowId) => (event) => {
        event.stopPropagation();
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const buildTree = (data) => {
        const map = {};
        const roots = [];
    
        data.forEach((item) => {
            map[item?.scenarioName] = { ...item, subRows: [] };
        });
    
        data.forEach((item) => {
            const parentName = item?.parentScenario;
            if (parentName && map[parentName]) {
                map[parentName].subRows.push(map[item?.scenarioName]);
            } else {
                roots.push(map[item?.scenarioName]);
            }
        });
    
        return roots;
    };

    const transformedData = useMemo(() => buildTree(findScenarioType), [findScenarioType]);

    const initialExpandedRootRows = useMemo(
        () =>
            transformedData
                ?.map((originalRow) => originalRow?._id)
                .reduce((a, v) => ({ ...a, [v]: true }), {}),
        [],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: "scenarioName",
                enableSorting: false,
                header: "Scenario Name",
                enableColumnOrdering: false,
            },
            {
                accessorKey: "parentScenario",
                enableSorting: false,
                header: "Parent Scenario",
                enableColumnOrdering: false,
            },
            {
                accessorKey: "scenarioType",
                enableSorting: false,
                header: "Scenario Type",
                enableColumnOrdering: false,
            },
            {
                accessorKey: "createdOn",
                enableSorting: false,
                header: "Created On",
                enableColumnOrdering: false,
                Cell: ({ row }) => (
                    <>
                        <p>{moment(row?.original?.createdOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
                    </>
                )
            },
            {
                accessorKey: "createdBy",
                enableSorting: false,
                header: "Created By",
                enableColumnOrdering: false,
                Cell: ({ row }) => (
                    <>
                        <p>{row?.original?.createdBy}</p>
                    </>
                )
            },
            {
                accessorKey: "scenarioNotes",
                enableSorting: false,
                header: "Scenario Notes",
                enableColumnOrdering: false,
                Cell: ({ row }) => (
                    <>
                        <p>{row?.original?.["Scenario Notes"]}</p>
                    </>
                )
            },
            {
                accessorKey: "view",
                header: "",
                enableSorting: false,
                enableColumnOrdering: false,

                Cell: ({ row }) => {
                    const handleCloseMenu = () => {
                        setOpenedMenus(prevState => ({
                            ...prevState,
                            [row?.original?._id]: null
                        }));
                    };

                    return (
                        <>
                            <IconButton onClick={handleClick(row.original._id)} size="small">
                                <MdMoreVert />
                            </IconButton>
                            <Menu
                                anchorEl={openedMenus[row.original._id]}
                                open={Boolean(openedMenus[row.original._id])}
                                onClose={() => handleCloseMenu()}
                            >
                                {/* <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleCreateUpdate(true, row?.original?._id);
                                        handleTableRowSelect("", row?.original);
                                        handleCloseMenu();
                                    }}
                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                >
                                    Edit Details
                                </MenuItem> */}
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setPromoteDeleteScenario(true);
                                        setPromoteScenario(false);
                                        setAttriId(row?.original?._id);
                                        handleCloseMenu();
                                    }}
                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                >
                                    Promote & Delete
                                </MenuItem>
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setPromoteScenario(true);
                                        setPromoteDeleteScenario(false);
                                        setAttriId(row?.original?._id);
                                        handleCloseMenu();
                                    }}
                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                >
                                    Promote
                                </MenuItem>
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setModalOpenDelete(true);
                                        setAttriId(row?.original?._id);
                                        handleCloseMenu();
                                    }}
                                    sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                >
                                    Delete
                                </MenuItem>
                            </Menu>
                        </>
                    );
                }

            },
        ],
        [selectedRows, handleTableRowSelect, openedMenus]
    );

    const table = useMaterialReactTable({
        columns,
        data: transformedData,
        enableExpanding: true,
        getRowId: (originalRow) => originalRow._id,
        initialState: { expanded: initialExpandedRootRows },
        enableColumnOrdering: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            }
        },
    });

    return <MaterialReactTable table={table} />
}

export default ScenarioMaterialList
