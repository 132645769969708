import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { deleteMeasureProfile, getMeasureProfileList } from '../../store/ApiSlice/measuredataslice';

const DeleteMeasureDataProfile = ({
  selectedRows,
  setSelectedRows,
  setModalDeleteOpen,
  isModalDeleteOpen,
  updatedId
}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModalDeleteOpen(false);
  };

  const handleDelete = async () => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    const response = await dispatch(deleteMeasureProfile({ attriId: updatedId, config }))
    if (response?.meta?.requestStatus === "fulfilled") {
      setModalDeleteOpen(false);
      // toast.success(response?.payload?.data?.message);
      dispatch(getMeasureProfileList());
    } else if (response?.meta?.requestStatus === "rejected") {
      toast.warn(
        response?.payload?.data?.error
      );
    }
    handleCloseModal()
  }

  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Delete Measure Data Profile</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography>
            This is irreversible. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button color="primary" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <Dialog open={isModalDeleteOpen} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default DeleteMeasureDataProfile
