import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import { getEnterModelList } from "../../store/ApiSlice/modelSlice";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";
import { toast } from "react-toastify";
import {
  createUpdateMeasureAsync,
  getMeasureList,
  getMeasureListWithEnterprise,
  getMeasurePaginationList,
} from "../../store/ApiSlice/measureSlice";
import { getModelListAsync } from "../../store/ApiSlice/dimensionSlice";
import { evaluate } from "mathjs";

const CreateMeasure = ({
  setIsCreate,
  setIsEdit,
  isEdit,
  isCreate,
  isView,
  setIsView,
  selectedRows,
  setOpenMeasureModal,
  openMeasureModal,
  modelEnterprise
}) => {
  const dispatch = useDispatch();
  const { measureData, measurePaginationList, measureList } = useSelector((state) => state.measure)
  const { dimensionData } = useSelector((state) => state.dimension)
  const { enterpriseData } = useSelector((state) => state?.enterprise)
  const [modalName, setModalName] = useState([])
  const [measureFormValue, setMeasureFormValue] = useState({})
  const [textFieldValue, setTextFieldValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const [calculationModel, setCalculationModel] = useState(false);
  const [finalAnswer, setFinalAnswer] = useState();
  const [isEvaluated, setIsEvaluated] = useState(false);
  const textareaRef = useRef(null);
  const hiddenSpanRef = useRef(null);

  useEffect(() => {
    if ((modelEnterprise?.enterpriseUID && modelEnterprise?.enterpriseUID !== undefined) && (modelEnterprise?.modelUID && modelEnterprise?.modelUID !== undefined)) {
      const formValues = {
        enterpriseUID: modelEnterprise?.enterpriseUID,
        modelUID: modelEnterprise?.modelUID
      }
      const fetchData = async () => {
        await dispatch(getMeasureListWithEnterprise({ formValues }))
      };
      fetchData()
    }
  }, [modelEnterprise?.enterpriseUID, modelEnterprise?.modelUID])

  const findLevelData = dimensionData?.length > 0 &&
    dimensionData?.filter((item) => item?.type === "LEVEL")
      ?.filter((row) => row?.entermodelUID?.modalUID === measureFormValue?.modelUID
        && row?.entermodelUID?.enterpriseUID === measureFormValue?.enterpriseUID);
  const findCalculationDimension = findLevelData?.length > 0 &&
    findLevelData?.find((item) => item?._id === measureFormValue?.baselevelUID)
  const dimensionPeriodValue = [
    findCalculationDimension && findCalculationDimension["Period Type"],
    ...(findCalculationDimension?.higherLevelTimeAttributes?.length > 0 ? findCalculationDimension?.higherLevelTimeAttributes : [])
  ]
  const calculationLevelData = findLevelData?.length > 0 ? findLevelData?.filter((item) =>
    findCalculationDimension?.fields?.some((row) => item?.primaryKey?.some((name) => name?.name === row?.name)))
    .filter((item) => dimensionPeriodValue?.some((time) => time === item?.["Period Type"])) : []
  const [measureValidateMessage, setMeasureValidateMessage] = useState(null);
  let selectedDimension = measureList?.find((item) => item?._id === selectedRows);

  useEffect(() => {
    if (selectedDimension && (isEdit || isView)) {
      setFinalAnswer(selectedDimension?.calculation?.calculationDefination)
      setTextFieldValue(selectedDimension?.calculation?.calculationDefination)
      setMeasureFormValue({
        measureName: selectedDimension.measureName,
        baselevel: selectedDimension.baselevelUID?.name,
        baselevelUID: selectedDimension.baselevelUID?._id,
        notes: selectedDimension.notes,
        type: selectedDimension.type,
        modelName: selectedDimension?.modelName,
        modelUID: selectedDimension?.modelUID,
        enterpriseUID: selectedDimension?.enterpriseUID,
        enterpriseName: selectedDimension?.enterpriseName,
        editable: selectedDimension?.editable,
        aggregationMode: selectedDimension?.aggregationMode,
        disaggregationMode: selectedDimension?.disaggregationMode,
        hashTags: selectedDimension?.hashTags || [],
        calculationNote:
          selectedDimension?.calculation?.calculationNote,
        calculationLevel:
          selectedDimension?.calculation?.calculationLevel,
        decimals: selectedDimension?.decimals,
        displayAsPercentage: selectedDimension?.displayAsPercentage,
        categoricalVariable: selectedDimension?.categoricalVariable,
        disaggregationSourceMeasure: selectedDimension?.disaggregationSourceMeasure
      })
    }
  }, [selectedDimension, isEdit, isView]);

  useEffect(() => {
    if (measureFormValue?.type === "STORED AND CALCULATED") {
      setMeasureFormValue({
        ...measureFormValue,
        editable: "System Editable"
      })
    }
    if (measureFormValue?.categoricalVariable === true) {
      setMeasureFormValue({
        ...measureFormValue,
        disaggregationMode: "COPY"
      })
    }
  }, [measureFormValue?.type, measureFormValue?.categoricalVariable])

  const handleDimensionFieldChange = (newValue, type, e) => {
    if (type === "enterpriseName") {
      const { name, value } = e.target;
      const selectedData = enterpriseData.filter((data) => data._id === value);
      setMeasureFormValue({
        ...measureFormValue,
        [name]: selectedData[0]?.enterpriseName,
        [`enterpriseUID`]: value,
      });
    } else if (type === "modelName") {
      const { name, value } = e.target;
      const selectedData = modalName.filter((data) => data.modalUID === value);
      setMeasureFormValue({
        ...measureFormValue,
        [name]: selectedData[0]?.modelName,
        [`modelUID`]: value,
      });
    } else if (type === 'baselevelUID') {
      const selectedData = findLevelData?.find((data) => data?._id === newValue?._id);
      setMeasureFormValue({
        ...measureFormValue,
        baselevel: selectedData?.name,
        [type]: newValue?._id,
      });
    } else if (type === "calculationLevel") {
      setMeasureFormValue({
        ...measureFormValue,
        [type]: newValue?._id,
      });
    } else if (type === "disaggregationSourceMeasure") {
      setMeasureFormValue({
        ...measureFormValue,
        [type]: newValue?._id,
      });
    } else if (type === "hashTags") {
      const { name, value } = e.target;
      const tags = value.split(",");
      setMeasureFormValue({
        ...measureFormValue,
        [name]: tags,
      });
    } else if (type === "displayAsPercentage" || type === "categoricalVariable") {
      const { name, checked } = e.target;
      setMeasureFormValue({
        ...measureFormValue,
        [name]: checked,
      });
    }
    else {
      const { name, value } = e.target;
      setMeasureFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const getModelList = async () => {
    try {
      const userId = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };

      let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
      let filteredData = isEdit
        ? response.data?.enterpriseList?.filter(
          (item) => item.enterpriseUID?._id === measureFormValue.enterpriseUID
        )
        : response.data?.enterpriseList;

      if (measureFormValue.enterpriseName) {
        filteredData = filteredData?.filter(
          (dime) => dime.enterpriseUID?._id === measureFormValue.enterpriseUID
        );
      }
      setModalName(filteredData);
    } catch (error) {
      console.error("Error fetching model list:", error);
    }
  };

  useEffect(() => {
    dispatch(getEnterpriseList());
    dispatch(getEnterModelList());
    dispatch(getModelListAsync());
    dispatch(getMeasureList());
  }, []);

  useEffect(() => {
    if (measureFormValue.enterpriseName) {
      getModelList();
    }
  }, [measureFormValue?.enterpriseName]);

  const getPageHeading = () => {
    if (isEdit || isView) {
      return measureFormValue.measureName;
    } else {
      return "New Measure";
    }
  };
  const handleViewUpdate = () => {
    setIsEdit(true);
    setIsView(false);
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!measureFormValue.measureName || measureFormValue.measureName == "") {
      toast.error("Please enter measure name");
      return;
    } else if (
      !measureFormValue?.enterpriseName ||
      measureFormValue?.enterpriseName == ""
    ) {
      toast.error("Please select enterprise name");
      return;
    } else if (!measureFormValue?.modelName || measureFormValue?.modelName == "") {
      toast.error("Please select model name");
      return;
    } else if (!measureFormValue?.baselevel || measureFormValue?.baselevel == "") {
      toast.error("Please select base level");
      return;
    } else if (!measureFormValue?.type || measureFormValue?.type == "") {
      toast.error("Please select type");
      return;
    } else if (
      !measureFormValue?.disaggregationMode ||
      measureFormValue?.disaggregationMode == ""
    ) {
      toast.error("Please select disaggregation mode");
      return;
    } else if (!measureFormValue?.categoricalVariable &&
      (!measureFormValue?.aggregationMode ||
        measureFormValue?.aggregationMode === "")
    ) {
      toast.error("Please select aggregation mode");
      return;
    } else if (measureFormValue?.disaggregationMode === "PROPORTIONAL BASED ON REFERENCE MEASURE" &&
      (!measureFormValue?.disaggregationSourceMeasure || measureFormValue?.disaggregationSourceMeasure === "")) {
      toast.error("Please select disaggregation reference measure");
      return;
    }
    await createUpdateMeasure();
  };

  const createUpdateMeasure = async () => {
    const matchesData =
      finalAnswer &&
      finalAnswer?.match(/'([^']+)'/g).map((str) => str?.replace(/'/g, ""));
    const measureEnterprise = measureData?.filter((item) => (item?.enterpriseUID === measureFormValue?.enterpriseUID && item?.modelUID === measureFormValue?.modelUID))
    const finddata = measureEnterprise?.length > 0 && measureEnterprise?.filter((item) =>
      matchesData?.length > 0 && matchesData?.some((row) => row === item?.measureName)
    );
    let formattedExpression = finalAnswer?.trim();
    formattedExpression = formattedExpression
      ?.replace(/(\s*-\s*)/g, '-')
      .replace(/(\s*\+\s*)/g, '+')
      .replace(/(\s*\*\s*)/g, '*')
      .replace(/(\s*\/\s*)/g, '/')
      .replace(/(\s*%\s*)/g, '%')
      .replace(/(\s*\$\s*)/g, '$')
      .replace(/(\s*\^\s*)/g, '^')
      .replace(/(\s*&\s*)/g, '&')
      .replace(/(\s*\?\s*)/g, '?')
      .replace(/(\s*\(\s*)/g, '(')
      .replace(/(\s*\)\s*)/g, ')')
      .replace(/(\s*<\s*)/g, '<')
      .replace(/(\s*>\s*)/g, '>')
      .replace(/(\s*=\s*)/g, '=')
      .replace(/(\s*,\s*)/g, ',')
      .replace(/\b(AND|OR|&&|\|\|)\s*/g, '$1')
      .replace(/\b(IF|ABS|ISNULL|SQRT|RAGGR)\s*\(/g, '$1(')
      .replace(/\b(SUM|AVG|MIN|MAX|COUNT|STDDEV)\s*\(/g, '$1(');
    const payload = {
      measureName: measureFormValue?.measureName,
      baselevel: measureFormValue?.baselevel,
      baselevelUID: measureFormValue?.baselevelUID,
      enterpriseName: measureFormValue?.enterpriseName,
      enterpriseUID: measureFormValue?.enterpriseUID,
      modelName: measureFormValue?.modelName,
      modelUID: measureFormValue?.modelUID,
      fieldType: measureFormValue?.fieldType,
      type: measureFormValue?.type,
      aggregationMode: measureFormValue?.aggregationMode,
      disaggregationMode: measureFormValue?.disaggregationMode,
      disaggregationSourceMeasure: measureFormValue?.disaggregationSourceMeasure,
      editable: measureFormValue?.editable,
      notes: measureFormValue?.notes,
      decimals: Number(measureFormValue.decimals || 0),
      displayAsPercentage: measureFormValue?.displayAsPercentage ? measureFormValue?.displayAsPercentage : false,
      categoricalVariable: measureFormValue?.categoricalVariable ? measureFormValue?.categoricalVariable : false,
      hashTags: measureFormValue?.hashTags?.map((item) => item),
      ...((measureFormValue?.type === "CALCULATED" ||
        measureFormValue?.type === "STORED AND CALCULATED") && {
        calculation: {
          calculationLevel: measureFormValue?.calculationLevel,
          calculationDefination: formattedExpression,
          ...(measureFormValue?.calculationNote &&
            measureFormValue?.aggregationMode === "CUSTOM" && {
            calculationNote: measureFormValue?.calculationNote,
          }),
          usedMeasures:
            finddata?.length > 0 && finddata?.map((item) => item?._id),
        },
      }),
    };
    const responce = await dispatch(
      createUpdateMeasureAsync({ payload, isEdit, selectedRows })
    );
    if (responce?.meta?.requestStatus === "fulfilled") {
      // toast.success(
      //   isEdit ? "Measure Updated Successfully" : "Measure Created Successfully"
      // );
      setIsCreate(false);
      dispatch(getMeasurePaginationList({ page: 1, itemsPerPage: 10, search: "" }));
      dispatch(getMeasureList());
    } else if (responce?.meta?.requestStatus === "rejected") {
      toast.error(responce?.error?.message || "Registration Failed");
    }
  };


  const updateCursorPosition = (selectionStart) => {
    const textarea = textareaRef.current;
    const hiddenSpan = hiddenSpanRef.current;
    if (textarea && hiddenSpan) {
      const textBeforeCursor = textFieldValue && textFieldValue?.substring(0, selectionStart);
      hiddenSpan.textContent = textBeforeCursor?.replace(/\n/g, "\u200b\n"); // Replace newline with zero-width space and newline
      const rect = hiddenSpan.getBoundingClientRect();
      const textareaRect = textarea.getBoundingClientRect();
      setCursorPosition({
        top: rect.top - textareaRect.top + textarea.scrollTop + 20,
        left: rect.left - textareaRect.left + textarea.scrollLeft,
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const strippedValue = value.replace(/"/g, "");
    const selectionStart = e.target.selectionStart;
    const inputValue = strippedValue.substring(0, selectionStart);

    const lastDelimiterIndex = Math.max(
      inputValue.lastIndexOf("+"),
      inputValue.lastIndexOf(" "),
      inputValue.lastIndexOf("IF("),
      inputValue.lastIndexOf("RAGGR("),
      inputValue.lastIndexOf("(")
    );

    const prefix = inputValue.substring(lastDelimiterIndex + 1).toLowerCase();
    const measureEnterprise = measureData?.filter((item) => (
      item?.enterpriseUID === measureFormValue?.enterpriseUID && item?.modelUID === measureFormValue?.modelUID
    ));
    const keywords = measureEnterprise?.length > 0 ? (measureEnterprise.map((item) => item.measureName) || []) : [];
    const filteredOptions = keywords?.length > 0 ? keywords?.filter((keyword) =>
      keyword.toLowerCase().startsWith(prefix)
    ) : [];

    if (prefix.startsWith('raggr')) {
      setDropdownOptions([...filteredOptions, 'RAGGR']);
    } else {
      setDropdownOptions(filteredOptions?.length > 0 ? filteredOptions : ["No data Available"]);
    }

    setShowDropdown(filteredOptions.length > 0 || prefix.startsWith('raggr'));
    setTextFieldValue(strippedValue);
    setTimeout(() => updateCursorPosition(selectionStart), 0);
  };


  const handleClickCalculation = (event) => {
    try {
      const nonNumeric = /[a-zA-Z]+/.test(textFieldValue);
      if (nonNumeric) {
        let expression = textFieldValue?.trim();

        // Preprocess the expression to remove extra spaces
        expression = expression?.replace(/\s+/g, ' ').replace(/\s*([,()])\s*/g, '$1');
        const parts = expression?.split(/(AND|OR|&&|\|\||[+\-*/%$^&?()<>!=,])/)
          .map((part) => part?.trim())
          .filter((item) => item?.length > 0);

        const signs = ['+', '-', '*', '/', '%', '$', '^', '&', '?', '(', ')', '<', '>', '=', 'AND', 'OR', 'ISNULL', 'NULL', 'ABS', 'SQRT', '||', '&&', 'IF', 'RAGGR'];
        const aggregationTypes = ['SUM', 'AVG', 'MIN', 'MAX', 'COUNT', 'STDDEV'];

        let parenthesesBalance = 0;
        let isValid = true;
        let invalidMessage = "";

        for (let part of parts) {
          if (part === '(') parenthesesBalance++;
          if (part === ')') parenthesesBalance--;
          if (parenthesesBalance < 0) {
            isValid = false;
            invalidMessage = "Unbalanced parentheses.";
            break;
          }
        }

        if (parenthesesBalance !== 0) {
          isValid = false;
          invalidMessage = "Unbalanced parentheses.";
        }

        if (isValid) {
          isValid = parts?.length > 0 && parts?.every((part, index) => {
            if (signs?.includes(part.toUpperCase())) {
              if (part.toUpperCase() === 'RAGGR') {
                const regex = /RAGGR\(\s*([^,\)]+(?:\s*,\s*[^,\)]+){5})\s*\)/;
                const match = regex.exec(expression.trim());
                if (!match) {
                  invalidMessage = `Invalid RAGGR function call at index ${index}: ${part}`;
                  return false;
                }

                const paramsString = match[1]?.trim();
                const params = paramsString?.split(/\s*,\s*/)?.map(param => param?.trim()?.replace(/'/g, ""));

                const valid = params?.length === 6 && aggregationTypes?.includes(params[1].toUpperCase());
                if (!valid) {
                  invalidMessage = `RAGGR function requires exactly 6 parameters with a valid aggregation type (SUM, AVG, MIN, MAX, COUNT, STDDEV) at index ${index}: ${part}`;
                }
                return valid;
              } else if (part.toUpperCase() === 'IF') {
                const closingParenIndex = parts?.lastIndexOf(')');
                const hasClosingParen = closingParenIndex > -1;

                if (!hasClosingParen) {
                  invalidMessage = `Invalid IF function call at index ${index}: ${part}`;
                  return false;
                }

                const ifArguments = [];
                let nestedParentheses = 0;
                let argument = "";
                for (let i = index + 2; i < closingParenIndex; i++) {
                  if (parts[i] === '(') nestedParentheses++;
                  if (parts[i] === ')') nestedParentheses--;
                  if (nestedParentheses < 0) break;

                  if (nestedParentheses === 0 && parts[i] === ',') {
                    ifArguments?.push(argument?.trim());
                    argument = "";
                  } else {
                    argument += parts[i] + " ";
                  }
                }
                if (argument.trim().length > 0) {
                  ifArguments.push(argument.trim());
                }
                const valid = ifArguments?.length === 3;
                if (!valid) {
                  invalidMessage = `Invalid IF function call at index ${index}: ${part}`;
                }
                return valid;
              } else if (part?.toUpperCase() === 'SQRT' || part?.toUpperCase() === 'ABS' || part?.toUpperCase() === 'ISNULL') {
                const nextPart = parts[index + 1];
                const valid = nextPart !== undefined && nextPart === '(';
                if (!valid) {
                  invalidMessage = `Invalid ${part?.toUpperCase()} function call at index ${index}: ${part}`;
                }
                return valid;
              } else if (part?.toUpperCase() === 'NULL') {
                // Handle NULL
                return true;
              } else {
                return true;
              }
            } else {
              if (part === ',') {
                return true;
              }
              const measureEnterprise = measureData?.length > 0 && measureData?.filter((item) => (
                item?.enterpriseUID === measureFormValue?.enterpriseUID && item?.modelUID === measureFormValue?.modelUID
              ));
              const removeQuotes = (str) => str?.replace(/'/g, '');
              const cleanedParts = removeQuotes(part);
              const isNumber = !isNaN(parseFloat(cleanedParts)) && isFinite(cleanedParts);
              const checkType = aggregationTypes?.some((row) => row === cleanedParts)
              const validMeasure = isNumber || (measureEnterprise?.length > 0 && measureEnterprise?.some((row) => row.measureName === cleanedParts || checkType));
              if (!validMeasure) {
                invalidMessage = `Invalid measure or operator: ${part}`;
              }
              return validMeasure;
            }
          });
        }

        const formattedExpression = parts?.length > 0 && parts?.map((part) => {
          if (signs?.includes(part) || part?.toUpperCase().startsWith('IF(') || part?.toUpperCase().startsWith('SQRT(') || part?.toUpperCase().startsWith('ABS(') || part?.toUpperCase().startsWith('RAGGR(') || part?.toUpperCase().startsWith('NULL') || part === ',') {
            return part;
          } else {
            return isNaN(part) && !(part?.startsWith("'") && part?.endsWith("'")) ? `'${part}'` : part;
          }
        }).join(' ');

        if (!isValid) {
          setMeasureValidateMessage(invalidMessage || "Invalid Calculation.");
          setCalculationModel(true);
          return;
        }

        const removeQuotes = (str) => str.replace(/'/g, '');
        const newArr = parts?.map(item => removeQuotes(item));
        const datas = newArr?.filter((item) => measureData?.some((row) => row.measureName === item));
        const finddata = datas?.filter((item) => measureData?.some((row) => row.measureName === item));

        if (finddata?.length !== datas?.length) {
          setMeasureValidateMessage("Some measures are not available.");
          setCalculationModel(true);
          return;
        }

        setMeasureValidateMessage("");
        setCalculationModel(false);
        setFinalAnswer(formattedExpression);
      } else {
        const result = evaluate(textFieldValue);
        if (result === "" || result === undefined) {
          setMeasureValidateMessage("Please Enter Calculation.");
        } else if (!isNaN(result)) {
          setFinalAnswer(result.toFixed(2).toString());
          setCalculationModel(false);
          setTextFieldValue('');
        } else {
          setFinalAnswer(textFieldValue);
          setCalculationModel(false);
        }
      }
    } catch (error) {
      setMeasureValidateMessage("Invalid expression");
    } finally {
      setIsEvaluated(true);
    }
  };

  const handleSelectOption = (option) => {
    const selectionStart = textareaRef.current.selectionStart;
    const valueBeforeCursor = textFieldValue?.substring(0, selectionStart);
    const valueAfterCursor = textFieldValue?.substring(selectionStart);
    const lastDelimiterIndex = Math.max(
      valueBeforeCursor.lastIndexOf("+"),
      valueBeforeCursor.lastIndexOf(" "),
      valueBeforeCursor.lastIndexOf("("),
      valueBeforeCursor.lastIndexOf("IF("),
      valueBeforeCursor.lastIndexOf("RAGGR("),
      valueBeforeCursor.lastIndexOf("SQRT("),
      valueBeforeCursor.lastIndexOf("ISNULL("),
      valueBeforeCursor.lastIndexOf("ABS("),
      valueBeforeCursor.lastIndexOf("ISNULL("),
      valueBeforeCursor.lastIndexOf("NULL"),
    );
    const newValue =
      valueBeforeCursor.substring(0, lastDelimiterIndex + 1) +
      option +
      valueAfterCursor;
    setTextFieldValue(newValue);
    setShowDropdown(false);
    setIsEvaluated(false);
    setTimeout(() => {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.selectionEnd =
        newValue.length;
    }, 0);
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        {/* <AppBar position="static" sx={{ backgroundColor: "white" }}> */}
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ color: "black" }}>
            {getPageHeading()}
          </Typography>
          {isView && (
            <Button
              onClick={() => {
                handleViewUpdate();
              }}
              className="awesome-button"
              sx={{ color: "white", margin: "0 4px", float: "right" }}
            >
              Edit
            </Button>
          )}
        </Toolbar>
        {/* </AppBar> */}
        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={6}>
              <TextField
                id="measureName"
                name="measureName"
                variant="outlined"
                fullWidth
                label="Measure name"
                value={measureFormValue?.measureName || ""}
                onChange={(e, newValue) => {
                  handleDimensionFieldChange(newValue, "measureName", e);
                }}
                placeholder="Enter measure name"
                disabled={isView}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                {isEdit || isView ? (
                  <TextField
                    id="enterpriseName"
                    name="enterpriseName"
                    variant="outlined"
                    fullWidth
                    label="Enterprise Name"
                    value={selectedDimension?.enterpriseName}
                    disabled={true}
                  />
                ) : (
                  <>
                    <InputLabel htmlFor="enterpriseName">
                      Enterprise Name
                    </InputLabel>
                    <Select
                      labelId="enterpriseName"
                      id="demo-simple-select-readonly"
                      name="enterpriseName"
                      value={measureFormValue.enterpriseUID}
                      fullWidth
                      onChange={(e, newValue) => {
                        handleDimensionFieldChange(
                          newValue,
                          "enterpriseName",
                          e
                        );
                      }}
                      label="Enterprise Type"
                      variant="outlined"
                    >
                      {enterpriseData?.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.enterpriseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                {isEdit || isView ? (
                  <TextField
                    id="modelName"
                    name="modelName"
                    variant="outlined"
                    fullWidth
                    label="Model Name"
                    value={selectedDimension?.modelName}
                    disabled={true}
                  />
                ) : (
                  <>
                    <InputLabel htmlFor="modelName">Model Name</InputLabel>
                    <Select
                      labelId="modelName"
                      id="demo-simple-select-readonly"
                      name="modelName"
                      value={measureFormValue.modelUID}
                      fullWidth
                      onChange={(e, newValue) => {
                        handleDimensionFieldChange(newValue, "modelName", e);
                      }}
                      label="Model Name"
                      variant="outlined"
                      disabled={measureFormValue.enterpriseName === "" ? true : false}
                    >
                      {modalName?.map((item) => (
                        <MenuItem key={item.modalUID} value={item.modalUID}>
                          {item.modelName}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  labelId="baselevelUID"
                  id="demo-simple-select-readonly"
                  name='baselevelUID'
                  label="Base Level"
                  options={findLevelData?.length > 0 ? findLevelData : []}
                  getOptionLabel={(option) => option.name}
                  value={measureFormValue?.baselevelUID ?
                    findLevelData?.length > 0 && (findLevelData?.find(option => option?._id === measureFormValue?.baselevelUID)
                      || null) : null}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(newValue, "baselevelUID", e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Base Level"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="type">Type</InputLabel>
                <Select
                  labelId="type"
                  id="demo-simple-select-readonly"
                  name="type"
                  label="Type"
                  value={measureFormValue.type || ""}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(newValue, "type", e);
                  }}
                  variant="outlined"
                >
                  <MenuItem value="STORED">STORED</MenuItem>
                  <MenuItem value="STORED AND CALCULATED">
                    STORED AND CALCULATED
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="categoricalVariable"
                    name="categoricalVariable"
                    checked={measureFormValue?.categoricalVariable || false}
                    onChange={(e) => {
                      handleDimensionFieldChange(e.target.checked, "categoricalVariable", e);
                    }}
                    disabled={isView}
                    style={{
                      padding: "0px !important",
                      height: "54px",
                      margin: "0px 19px"
                    }}
                  />
                }
                label="Categorical Variable"
              />
            </Grid>
          </Grid>
        </Container>

        <hr
          style={{
            borderTop: "2px solid #1976d2",
            width: "100%",
            margin: "20px auto",
          }}
        />

        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="editable">Editable</InputLabel>
                <Select
                  labelId="editable"
                  name="editable"
                  id="demo-simple-select-readonly"
                  value={measureFormValue.editable || ""}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(newValue, "editable", e);
                  }}
                  label="Editable"
                  variant="outlined"
                  disabled={measureFormValue?.type === "STORED AND CALCULATED"}
                >
                  <MenuItem value="Not Editable">Not Editable</MenuItem>
                  <MenuItem value="Editable in the Current or Future Period">
                    Editable in the Current or Future Period
                  </MenuItem>
                  <MenuItem value="Editable in the Past">
                    Editable in the Past
                  </MenuItem>
                  <MenuItem value="All Editable">All Editable</MenuItem>
                  <MenuItem value="System Editable">System Editable</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="conversionSourceMeasure">
                  Conversion Source Measure
                </InputLabel>
                <Select
                  labelId="conversionSourceMeasure"
                  name="conversionSourceMeasure"
                  id="demo-simple-select-readonly"
                  value={measureFormValue.conversionSourceMeasure}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(
                      newValue,
                      "conversionSourceMeasure",
                      e
                    );
                  }}
                  label="Conversion Source Measure"
                  variant="outlined"
                >
                  {measureData?.map((item) => {
                    return (
                      <MenuItem value={item?._id}>{item?.measureName}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="aggregationMode">Aggregation Mode</InputLabel>
                <Select
                  labelId="aggregationMode"
                  name="aggregationMode"
                  id="demo-simple-select-readonly"
                  value={measureFormValue?.aggregationMode || ''}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(newValue, "aggregationMode", e);
                  }}
                  label="Aggregation Mode"
                  variant="outlined"
                >
                  <MenuItem value="SUM">SUM</MenuItem>
                  <MenuItem value="AVERAGE">AVERAGE</MenuItem>
                  <MenuItem value="MIN">MIN</MenuItem>
                  <MenuItem value="MAX">MAX</MenuItem>
                  <MenuItem value="COUNT">COUNT</MenuItem>
                  {/* <MenuItem value="STDDEV">STDDEV</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="disaggregationMode">
                  Disaggregation Mode
                </InputLabel>
                <Select
                  labelId="disaggregationMode"
                  name="disaggregationMode"
                  id="demo-simple-select-readonly"
                  value={measureFormValue.disaggregationMode || ''}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(
                      newValue,
                      "disaggregationMode",
                      e
                    );
                  }}
                  label="Disaggregation Mode"
                  variant="outlined"
                  disabled={measureFormValue?.categoricalVariable === true}

                >
                  {(measureFormValue?.aggregationMode === "SUM" ||
                    measureFormValue?.aggregationMode === "MIN" ||
                    measureFormValue?.aggregationMode === "MAX" ||
                    measureFormValue?.aggregationMode === "COUNT") && (
                    <MenuItem value="PROPORTIONAL">PROPORTIONAL</MenuItem>
                  )}
                  {(measureFormValue?.aggregationMode === "AVERAGE" ||
                    measureFormValue?.aggregationMode === "MIN" ||
                    measureFormValue?.aggregationMode === "MAX" ||
                    measureFormValue?.aggregationMode === "COUNT") && (
                    <MenuItem value="COPY">COPY</MenuItem>
                  )}
                  {(measureFormValue?.aggregationMode === "SUM" ||
                    measureFormValue?.aggregationMode === "MIN" ||
                    measureFormValue?.aggregationMode === "MAX" ||
                    measureFormValue?.aggregationMode === "COUNT") && (
                    <MenuItem value="PROPORTIONAL BASED ON REFERENCE MEASURE">PROPORTIONAL BASED ON REFERENCE MEASURE</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="demo-simple-select-readonly"
                  labelId="Disaggregation Reference Measure"
                  name="Disaggregation Reference Measure"
                  options={measureData?.length > 0 ? measureData?.filter((item) => item?.enterpriseUID === measureFormValue?.enterpriseUID && item?.modelUID === measureFormValue?.modelUID) : []}
                  getOptionLabel={(option) => option?.measureName}
                  value={
                    measureFormValue?.disaggregationSourceMeasure
                      ? measureData?.find(
                        (item) =>
                          item?._id === measureFormValue?.disaggregationSourceMeasure
                      ) || null
                      : null
                  }
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(
                      newValue,
                      "disaggregationSourceMeasure",
                      e
                    );
                  }}
                  sx={{ fontSize: "12px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Disaggregation Reference Measure"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="hashTags"
                name="hashTags"
                variant="outlined"
                fullWidth
                label="Tags"
                value={measureFormValue.hashTags?.join(",")}
                onChange={(e, newValue) => {
                  handleDimensionFieldChange(newValue, "hashTags", e);
                }}
                placeholder="Enter tags"
                disabled={isView}
              />
            </Grid>
          </Grid>
        </Container>

        <hr
          style={{
            borderTop: "2px solid #1976d2",
            width: "100%",
            margin: "20px auto",
          }}
        />

        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="decimals">Decimals</InputLabel>
                <Select
                  labelId="decimals"
                  name="decimals"
                  id="demo-simple-select-readonly"
                  value={Number(measureFormValue.decimals || 0)}
                  fullWidth
                  onChange={(e, newValue) => {
                    handleDimensionFieldChange(newValue, "decimals", e);
                  }}
                  label="Decimals"
                  variant="outlined"
                >
                  <MenuItem value="0">0</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="displayAsPercentage"
                    name="displayAsPercentage"
                    checked={measureFormValue?.displayAsPercentage || false}
                    onChange={(e) => {
                      handleDimensionFieldChange(e.target.checked, "displayAsPercentage", e);
                    }}
                    disabled={isView}
                    style={{
                      padding: "0px !important",
                      height: "54px",
                      margin: "0px 19px"
                    }}
                  />
                }
                label="Display Percentage"
              />
            </Grid>
          </Grid>
        </Container>

        {measureFormValue?.aggregationMode === "CUSTOM" && (
          <>
            <hr
              style={{
                borderTop: "2px solid #1976d2",
                width: "100%",
                margin: "20px auto",
              }}
            />
            <Typography
              variant="h6"
              component="h6"
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              Custom Aggregation Mode Definition
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <TextField
                    id="calculationNote"
                    name="calculationNote"
                    variant="outlined"
                    fullWidth
                    label="Calculation Note"
                    value={measureFormValue.calculationNote}
                    onChange={(e, newValue) => {
                      handleDimensionFieldChange(
                        newValue,
                        "calculationNote",
                        e
                      );
                    }}
                    placeholder="Enter calculation note"
                    disabled={isView}
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        {(measureFormValue?.type === "STORED AND CALCULATED") && (
          <>
            <hr
              style={{
                borderTop: "2px solid #1976d2",
                width: "100%",
                margin: "20px auto",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Measure Calculation
                </Typography>{" "}
              </Box>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="demo-simple-select-readonly"
                    labelId="Weight"
                    name="calculationLevel"
                    options={calculationLevelData?.length > 0 ? calculationLevelData : []}
                    getOptionLabel={(option) => option?.name}
                    value={
                      measureFormValue?.calculationLevel
                        ? calculationLevelData?.length > 0 && (calculationLevelData?.find(
                          (item) => item?._id === measureFormValue?.calculationLevel
                        ) || null)
                        : null
                    }
                    onChange={(e, newValue) => {
                      handleDimensionFieldChange(
                        newValue,
                        "calculationLevel",
                        e
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Calculation Level"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: "20px" }}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name='calculation'
                    id='calculation'
                    fullWidth
                    label="Calculation"
                    value={finalAnswer ? finalAnswer.toString() : ""}
                    onClick={() => {
                      setCalculationModel(true)
                      setTextFieldValue(textFieldValue ? textFieldValue : finalAnswer)
                    }}
                    disabled={isView}
                    multiline
                    rows={6}
                  />
                  <Dialog
                    open={calculationModel}
                    onClose={() => {
                      setCalculationModel(false)
                      setTextFieldValue('')
                    }}
                  >
                    <Box sx={{ minWidth: "600px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <DialogTitle>Measure Calculation</DialogTitle>
                      </div>

                      <DialogContent sx={{ marginTop: "10px !important" }}>
                        <div style={{ position: "relative" }}>
                          <TextField
                            inputRef={textareaRef}
                            fullWidth
                            type="text"
                            value={textFieldValue}
                            onChange={handleInputChange}
                            multiline
                            rows={6}
                            placeholder="Enter calculation"
                            style={{
                              position: "relative",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                              display: "flex",
                            }}
                          />
                          {measureValidateMessage && (
                            <Typography sx={{ color: "red", padding: "10px 0 0" }}>
                              {measureValidateMessage}
                            </Typography>
                          )}
                          {/* <Typography sx={{ color: "red", padding: "10px 0 0" }}>{measureValidateMessage?.validationMessage}</Typography> */}
                          <span
                            ref={hiddenSpanRef}
                            style={{
                              position: "absolute",
                              visibility: "hidden",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                              fontSize: "16px", // Match the font size and styles of the TextField
                              fontFamily: "Arial, sans-serif",
                              lineHeight: "1.5", // Match the line height
                              padding: "10px", // Match the padding
                              border: "1px solid transparent",
                            }}
                          />
                          {showDropdown && (
                            <div
                              className="dropdown"
                              style={{
                                position: "absolute",
                                left: cursorPosition.left + 30,
                                top: cursorPosition.top - 120,
                                zIndex: "1000",
                                border: "1px solid #ccc",
                                backgroundColor: "#fff",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              {dropdownOptions.map((option) => {
                                return (<div
                                  style={{
                                    padding: "8px 12px",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s ease",
                                    fontSize: "12px",
                                    fontFamily: "'Poppins', sans-serif"
                                  }}
                                  key={option}
                                  onClick={() => handleSelectOption(option)}
                                >
                                  {option}
                                </div>)
                              })}
                            </div>
                          )}
                        </div>
                        <Box
                          sx={{
                            padding: "14px 0 0",
                            display: "flex",
                            justifyContent: "end",
                            gap: "10px"
                          }}>
                          <Button variant='contained' onClick={handleClickCalculation} sx={{ textTransform: "capitalize" }}>Validate</Button>
                          <Button variant='contained' onClick={() => {
                            setTextFieldValue('');
                            setCalculationModel(false)
                            setMeasureValidateMessage('')
                          }}
                            sx={{ textTransform: "capitalize" }}
                          >Cancel</Button>
                        </Box>
                      </DialogContent>
                    </Box>
                  </Dialog>
                </Grid>
              </Grid>
            </Container>
          </>
        )}

        <hr
          style={{
            borderTop: "2px solid #1976d2",
            width: "100%",
            margin: "20px auto",
          }}
        />

        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <TextField
                id="notes"
                name="notes"
                variant="outlined"
                fullWidth
                label="Notes"
                multiline
                rows={6}
                value={measureFormValue.notes}
                onChange={(e, newValue) => {
                  handleDimensionFieldChange(newValue, "notes", e);
                }}
                placeholder="Enter Notes"
                disabled={isView}
              />
            </Grid>
          </Grid>
        </Container>

        {!isView && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: "30px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              disabled={isView}
              onClick={handleCancel}
              title="Cancel"
              style={{
                padding: "6px 15px",
                borderRadius: "2px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isView}
              onClick={handleSubmit}
              title="Create new dimension with selected attributes"
              style={{
                padding: "7px 15px",
                borderRadius: "2px",
                cursor: "pointer",
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div >
    </>
  );
};

export default CreateMeasure;
