/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TablePagination,
  Menu,
  MenuItem,
  Button,
  Box,
  Modal,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Autocomplete,
  Grid,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";
import { toast } from "react-toastify";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { getAllPlanningObject } from "../../store/ApiSlice/dimensionSlice";
import { useSelector } from "react-redux";

const DimensionsMaterialList = ({
  data,
  selectedRows,
  handleTableRowSelect,
  handleOpenModal,
  handleDownload,
  setModalDeleteOpen,
  handleCreateUpdate,
  setUpdatedId,
  modelEnterprise,
  setManagePlanningObjectModel,
  setCreatePlanningObjectModel,
  setPlanningObjectData,
  formValues,
  setFormValues
}) => {
  const userData = JSON.parse(localStorage.getItem("accessToken"))
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [openedMenus, setOpenedMenus] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [isMasterDataLoading, setIsMasterDataLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const dispatch = useDispatch()

  const findEnterpriseAttribute = ((!modelEnterprise?.enterpriseUID || modelEnterprise?.enterpriseUID === "") && (!modelEnterprise?.modelUID || modelEnterprise?.modelUID === "")) ? data :
    data?.filter((item) => item?.enterpriseUID?._id === modelEnterprise?.enterpriseUID)
      ?.filter((item) => item?.entermodelUID?.modalUID === modelEnterprise?.modelUID)?.filter((item) => item?.type !== "SCENARIO")

  const sortedDimensionData = useMemo(() => {
    return [...(findEnterpriseAttribute || [])].sort((a, b) => {
      const dimensionA = a.name.toLowerCase();
      const dimensionB = b.name.toLowerCase();
      if (dimensionA < dimensionB) return -1;
      if (dimensionA > dimensionB) return 1;
      return 0;
    });
  }, [data, modelEnterprise?.enterpriseUID, modelEnterprise?.modelUID]);

  const handleClick = (rowId) => (event) => {
    event.stopPropagation();
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setOpenedMenus(prevState => ({
      ...prevState,
      [id]: null
    }));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "entermodelUID.enterpriseName",
        enableColumnOrdering: false,
        header: "Enterprise Name",
        enableSorting: false,
      },
      {
        accessorKey: "entermodelUID.modelName",
        enableColumnOrdering: false,
        header: "Model Name",
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: "Dimensions name",
        enableSorting: false,
      },
      {
        accessorKey: "type",
        header: "Type",
        enableSorting: false,
      },
      !expandedRowData && {
        accessorKey: "description",
        header: "Notes",
        enableSorting: false,
      },
      !expandedRowData && {
        accessorKey: "createdOn",
        enableColumnOrdering: false,
        header: "Created On",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <p>{moment(row?.original?.createdOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "MM/DD/YYYY")} </p>
          </>
        ),
      },
      !expandedRowData && {
        accessorKey: "changedOn",
        enableColumnOrdering: false,
        header: "Changed On",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <p>{moment(row?.original?.changedOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "MM/DD/YYYY")} </p>
          </>
        ),
      },

      {
        accessorKey: "view",
        header: "",
        enableSorting: false,
        enableColumnOrdering: false,

        Cell: ({ row }) => {
          return (
            <>
              <IconButton onClick={handleClick(row.original._id)} size="small">
                <MdMoreVert />
              </IconButton>
              <Menu
                anchorEl={openedMenus[row.original._id]}
                open={Boolean(openedMenus[row.original._id])}
                onClose={() => { handleClose(row.original._id) }}
              >
                {(row.original?.type !== "TIME" && row.original?.type !== "LEVEL") && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleRowExpansion(row);
                      handleClose(row.original._id);
                    }}
                    size="small"
                    sx={{ fontSize: "12px" }}
                  >
                    Manage Data
                  </MenuItem>
                )}
                {/* {(row.original?.type === "LEVEL") && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      setManagePlanningObjectModel(true)
                      handleClose(row.original._id);
                      setPlanningObjectData(row?.original)
                      dispatch(getAllPlanningObject({
                        payload: {
                          enterpriseUID: row?.original?.enterpriseUID._id,
                          modelUID: row?.original?.entermodelUID.modelUID,
                          dimensionUID: row?.original?._id
                        }
                      }))
                    }}
                    size="small"
                    sx={{ fontSize: "12px" }}
                  >
                    Manage Planning Objects
                  </MenuItem>
                )} */}
                {(row?.original?.type !== "SCENARIO") &&
                  <>
                    {row.original?.type !== "REFERENCE" && (
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClose(row.original._id);
                          handleOpenModal(row);
                        }}
                        sx={{ fontSize: "12px" }}
                      >
                        Upload
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClose(row.original._id);
                        handleDownload(row);
                      }}
                      sx={{ fontSize: "12px" }}
                    >
                      Download
                    </MenuItem>
                  </>
                }
                {((row.original?.type !== "SCENARIO" && row.original?.type !== "SYSTEM") && (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClose(row.original._id);
                        handleCreateUpdate(true, row?.original?._id);
                      }}
                      sx={{ fontSize: "12px" }}
                    >
                      Edit Dimension
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClose(row.original._id);
                        setUpdatedId(row.original._id);
                        setModalDeleteOpen(true);
                      }}
                      sx={{ fontSize: "12px" }}
                    >
                      Delete Dimension
                    </MenuItem>
                  </>
                ))}

              </Menu>
              {expandedRowData === row?.original?._id}
            </>
          )
        }
      },
    ],
    [
      selectedRows,
      handleTableRowSelect,
      expandedRowData,
      openedMenus,
      isMasterDataLoading,
    ]
  );


  const toggleRowExpansion = (row, type) => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
      },
    };
    setIsMasterDataLoading(true);
    axios
      .get(
        `${API_BASE_URL}/dataload/${type === "edit" ? row : row?.original?._id
        }`,
        config
      )
      .then((response) => {
        const data = response?.data?.data || [];
        const apiDataArray = Object.entries(response?.data?.schemaInfo)?.sort(([, a], [, b]) => a.order - b.order)?.map(
          ([name, type]) => ({ name, type })
        );
        setApiData(apiDataArray);
        setTableData(data);

        if (data.length > 0) {
          const keys = Object.keys(data[0]);
          const headers = keys?.filter((key) => key !== "_id" && key !== "__v");
          setTableHeader(headers);
        }
        setIsMasterDataLoading(false);
      })
      .catch((error) => {
        setIsMasterDataLoading(false);
      });
    setExpandedRowData(row?.original?._id);
  };

  const table = useMaterialReactTable({
    columns: columns.filter(Boolean),
    data: sortedDimensionData || [],
    enableColumnOrdering: true,
    enableSelectAll: false,
    enableRowSelection: false,
    muiSelectCheckboxProps: { color: "secondary" },
    positionToolbarAlertBanner: "head-overlay",
    enableFullScreenToggle: false,
    enableColumnPinning: true,
    muiTableHeadCellProps: {
      sx: {
        fontSize: "12px"
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "12px"
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        handleClose(row.original._id);
        handleCreateUpdate(true, row?.original?._id);
      }, // Row click event
      sx: {
        cursor: 'pointer', // Add a pointer cursor to indicate clickable rows
        fontSize: "12px",
        fontFamily: "'Poppins', sans-serif",
        '&:hover': {
          backgroundColor: '#f5f5f5', // Optional: Add hover effect for clarity
        },
      },
    }),
  });


  return (
    <div style={{ display: "flex", gap: 20 }}>
      <div style={{ flex: 1, maxWidth: expandedRowData ? "50%" : "100%" }}>
        <MaterialReactTable table={table} />
      </div>
      {/* {managePlanningObjectModel && (
        <Dialog
          open={managePlanningObjectModel}
          onClose={() => {
            setManagePlanningObjectModel(false)
          }}
          maxWidth={"xl"}
        >
          <DialogTitle sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography>
              Manage Planning Objects
            </Typography>
            <Box>
              <Button
                onClick={() => {
                  setCreatePlanningObjectModel(true);
                }}
                className={"awesome-button"}
                sx={{
                  color: "white",
                  margin: "0 1px",
                  fontSize: "1.875rem !important",
                }}
              >
                +
              </Button>{" "}
            </Box>
          </DialogTitle>
          <DialogContent>
            <TableContainer
              sx={{
                marginTop: "10px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
              }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {planningObjectData?.fields?.length > 0 && planningObjectData?.fields?.filter((item) =>
                      !["Time Period Name"]?.includes(item?.name))
                      ?.map((item, index) => (
                        <TableCell key={index} sx={{ fontSize: "12px" }}>
                          {item?.name}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100, { label: "All", value: -1 }]} // Added { label: "All", value: -1 }
              component="div"
              count={totalPage}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => handleChangePage(event, newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setManagePlanningObjectModel(false)
            }}
            >Cancel</Button>
          </DialogActions>
        </Dialog>
      )} */}
      {/* {createPlanningObjectModel && (
        <Dialog
          open={createPlanningObjectModel}
          onClose={() => {
            setCreatePlanningObjectModel(false)
          }}
          maxWidth={"md"}
        >
          <DialogTitle>
            <Box sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography>
                Create Planning Object
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    setCreatePlanningObjectModel(false)
                  }}
                  sx={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              {planningObjectData?.fields?.length > 0 && planningObjectData?.fields?.map((item, index) => (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="demo-simple-select-readonly"
                      labelId={item?.name}
                      name={item?.name}
                      options={planningObjectData?.primaryKey}
                      getOptionLabel={(option) => option.name}
                      value={
                        formValues?.[item?.name]
                          ? planningObjectData?.primaryKey.find(
                            (option) =>
                              option._id === formValues?.[item?.name]
                          ) || null
                          : null
                      }
                      onChange={(e, newValue) => {
                        handleChangePlanningObject(newValue, item?.name, e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={item?.name}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              ))}
              <Grid item xs={6}>
                <TextField
                  id="validFrom"
                  label="Valid From"
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formValues.validFrom} // Add value attribute
                  onChange={(e) => handleChangePlanning("validFrom", e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="validTo"
                  label="Valid To"
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: formValues.validFrom,
                  }}
                  value={formValues.validTo}
                  onChange={(e) => handleChangePlanning("validTo", e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setCreatePlanningObjectModel(false)
              setFormValues({})
            }}
            >
              Cancel
            </Button>
            <Button color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
      {expandedRowData && (
        <div style={{ flex: 1, maxWidth: "50%" }}>
          <ExpandedRow
            rowData={data?.length > 0 && data?.find((row) => row?._id === expandedRowData)}
            tableHeader={tableHeader}
            tableData={tableData}
            toggleRowExpansion={toggleRowExpansion}
            apiData={apiData}
            setApiData={setApiData}
            setTableData={setTableData}
            setExpandedRowData={setExpandedRowData}
          />
        </div>
      )}
    </div>
  );
};

const ExpandedRow = ({
  rowData,
  tableHeader,
  tableData,
  apiData,
  setApiData,
  setTableData,
  setExpandedRowData
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [actionMenu, setActionMenu] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [formData, setFormData] = useState({
    "Scenario Name": "",
    "Parent Scenario": "Baseline",
  });
  const [openEditModel, setOpenEditModel] = useState(false);
  const [storeSingleData, setSingleData] = useState({});
  const [selectIndex, setSelectIndex] = useState(null);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChange = (field, value) => {
    if (field === "Scenario Name") {
      setFormData({ ...formData, [field]: value });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  // delete data
  const handleDeleteMenu = async (deletedRowData) => {
    const baseURL = `${API_BASE_URL}/dataload/delete`;
    const userId = localStorage.getItem("accessToken");
    const body = {
      enterpriseName: rowData?.entermodelUID?.enterpriseName,
      modelName: rowData?.entermodelUID?.modelName,
      dimensionName: rowData?.name,
      ids: [deletedRowData._id],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
      data: body,
    };
    try {
      const response = await axios.delete(baseURL, config);
      if (response.data.data) {
        // toast.success("Deleted successfully");
        // toggleRowExpansion(rowData);
        axios
          .get(`${API_BASE_URL}/dataload/${rowData?._id}`, config)
          .then((response) => {
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            setApiData(apiDataArray);
            setTableData(data);
          });
        setOpenAddModel(false);
      }
    } catch (error) { }
    setActionMenu(false);
  };

  const handleEditRowMenu = async (editedRowdata, index) => {
    if (selectIndex === index) {
      setOpenEditModel(true);

      const baseURL = `${API_BASE_URL}/dataload/getSingle`;
      const userId = localStorage.getItem("accessToken");
      const body = {
        enterpriseName: rowData?.entermodelUID?.enterpriseName,
        modelName: rowData?.entermodelUID?.modelName,
        dimensionName: rowData?.name,
        id: rowData?._id,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };
      try {
        const response = await axios.post(`${baseURL}`, body, config);
        setSingleData(response.data?.data);

        axios
          .get(`${API_BASE_URL}/dataload/${rowData?._id}`, config)
          .then((response) => {
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            const datasId = response.data.data?.filter((item) => item?._id == editedRowdata?._id)
            setSingleData(datasId);
            setApiData(apiDataArray);
            setTableData(data);
          });
        // toggleRowExpansion(rowData?._id, "edit")
      } catch (error) {
        console.log(error);
      }
    }
  };
  // create
  const handleAddData = async () => {
    const baseURL = `${API_BASE_URL}/dataload/upload`;
    const userId = localStorage.getItem("accessToken");

    const body = {
      enterpriseName: rowData?.entermodelUID?.enterpriseName,
      modelName: rowData?.entermodelUID?.modelName,
      dimensionName: rowData?.name,
      clearAll: false,
      // documentsArray: [{ ...formData, "Parent Scenario": sceneList[0]?.["Parent Scenario"]}],
      documentsArray: [formData],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    try {
      const response = await axios.post(`${baseURL}`, body, config);
      setApiData({});

      if (response.data.data?.storedItems?.length > 0) {

        // toast.success("Created successfully");
        setOpenAddModel(false);
        axios
          .get(`${API_BASE_URL}/dataload/${rowData?._id}`, config)
          .then((response) => {
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            setApiData(apiDataArray);
            setTableData(data);
          });
      } else {
        toast.error("There is error while trying to add the manage data")
        setOpenAddModel(false);
        axios
          .get(`${API_BASE_URL}/dataload/${rowData?._id}`, config)
          .then((response) => {
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            setApiData(apiDataArray);
            setTableData(data);
          });
      }
    } catch (error) {
      toast.error(error?.response?.data?.data)
    }
  };
  const handleEditModal = async () => {
    const baseURL = `${API_BASE_URL}/dataload/update`;
    const userId = localStorage.getItem("accessToken");
    const body = {
      enterpriseName: rowData?.entermodelUID?.enterpriseName,
      modelName: rowData?.entermodelUID?.modelName,
      dimensionName: rowData?.name,
      documentsArray: storeSingleData,
      clearAll: false,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    try {
      const response = await axios.put(`${baseURL}`, body, config);
      // toast.success("Updated Successfully!");
      setOpenEditModel(false);

      axios
        .get(`${API_BASE_URL}/dataload/${rowData?._id}`, config)
        .then((response) => {
          const data = response.data.data || [];
          const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
            ([name, type]) => ({ name, type })
          );
          setOpenEditModel(false);
          setApiData(apiDataArray);
          setTableData(data);
        });
    } catch (error) {
      toast?.error(error?.response?.data?.data)
    }
  };
  const [sceneList, setSceneList] = useState([]);
  const getScenarioData = async () => {
    const baseURL = `${API_BASE_URL}/dataload/scenarioList`;
    const userId = localStorage.getItem("accessToken");
    const body = {
      enterpriseName: rowData?.entermodelUID?.enterpriseName,
      modelName: rowData?.entermodelUID?.modelName,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    try {
      const response = await axios.post(`${baseURL}`, body, config);
      setSceneList(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScenarioData();
  }, [
    rowData?.entermodelUID?.enterpriseName,
    rowData?.entermodelUID?.modelName,
  ]);

  const handleButtonAction = (event, index) => {
    setSelectIndex(index);
    setActionMenu(event.currentTarget);
  };
  if (!rowData) return null;

  return (
    <>
      <Box sx={{ fontSize: "12px" }}>
        {rowData?.entermodelUID?.enterpriseName} &nbsp; &nbsp; &nbsp; &nbsp; {rowData?.entermodelUID?.modelName} &nbsp; &nbsp; &nbsp; &nbsp; ({rowData?.name}) &nbsp; &nbsp; &nbsp; &nbsp; ({rowData?.type})
      </Box>
      <TableContainer
        sx={{
          marginTop: "30px",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {apiData?.length > 0 && apiData?.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  {header.name}
                </TableCell>
              ))}
              <TableCell>
                <Button
                  onClick={() => {
                    setOpenAddModel(true);
                    setFormData({})
                  }}
                  className={"awesome-button"}
                  sx={{
                    color: "white",
                    margin: "0 1px",
                    fontSize: "1.875rem !important",
                  }}
                >
                  +
                </Button>{" "}
                <Button
                  onClick={() => {
                    setExpandedRowData(null)
                  }}
                  className={"awesome-button"}
                  sx={{
                    color: "white",
                    margin: "0 1px",
                    fontSize: "20px"
                  }}
                >
                  X
                </Button>{" "}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.length > 0 && tableData?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((dataRow, index) => (
              <>
                <TableRow key={index}>
                  {apiData?.length > 0 && apiData?.map((header, headerIndex) => (
                    <TableCell key={headerIndex} sx={{ fontSize: "12px" }}>{dataRow[header.name]}</TableCell>
                  ))}
                  <TableCell>
                    {rowData?.type !== "REFERENCE" && (
                      <IconButton size="small" onClick={(event) => handleButtonAction(event, index)}>
                        <MdMoreVert />
                      </IconButton>
                    )}
                    {selectIndex === index && (
                      <Menu
                        anchorEl={actionMenu}

                        open={selectIndex === index}
                        onClose={() => setSelectIndex(null)}
                      >
                        <MenuItem onClick={() => {
                          handleEditRowMenu(dataRow, index);
                          setSelectIndex(null);
                        }}
                          sx={{ fontSize: "12px" }}
                        >Edit</MenuItem>
                        <MenuItem onClick={() => {
                          handleDeleteMenu(dataRow);
                          setSelectIndex(null);
                        }}
                          sx={{ fontSize: "12px" }}
                        >Delete</MenuItem>
                      </Menu>
                    )}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, { label: "All", value: -1 }]} // Added { label: "All", value: -1 }
          component="div"
          count={tableData?.length || []}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openAddModel || openEditModel}
        onClose={() => {
          setOpenAddModel(false);
          setOpenEditModel(false);
          setSingleData({});
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "400px",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          {apiData?.length > 0 &&
            apiData?.map((field, index) => (

              <Box key={index} marginBottom={2}>
                {(field.name === "Scenario Name" && (rowData?.type === "SIMPLE" || rowData?.type === "COMPOUND")) &&
                  (field.name === "Parent Scenario" && rowData?.type === "SCENARIO") ? (
                  <FormControl fullWidth>
                    <InputLabel>{field.name}</InputLabel>
                    <Select
                      id={field.name}
                      label={field.name}
                      name={field.name}
                      value={formData[field.name] || (sceneList.length > 0 ? sceneList[0]?.scenarioName || sceneList[0]?.parentScenario : "")}
                      onChange={(e) => {
                        if (openEditModel) {
                          setSingleData({
                            ...storeSingleData,
                            [field.name]: e.target.value,
                          });
                        } else {
                          handleChange(field.name, e.target.value);
                        }
                      }}
                      fullWidth
                      variant="outlined"
                    >
                      {sceneList.map((data, index) => (
                        <MenuItem key={index} value={data?.scenarioName}>
                          {data?.scenarioName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    {(field?.name === "Parent Scenario") && (
                      <FormControl fullWidth>
                        <InputLabel>{field.name}</InputLabel>
                        <Select
                          id={field.name}
                          label={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={(e) => {
                            if (openEditModel) {
                              setSingleData({
                                ...storeSingleData,
                                [field.name]: e.target.value,
                              });
                            } else {
                              handleChange(field.name, e.target.value);
                            }
                          }}
                          fullWidth
                          variant="outlined"
                        >
                          {sceneList.map((data, index) => (
                            <MenuItem key={index} value={data?.scenarioName}>
                              {data?.scenarioName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {field?.name !== "Parent Scenario" && (

                      <TextField
                        fullWidth
                        id={field.name}
                        label={field.name}
                        name={field.name}
                        value={
                          openEditModel
                            ? (storeSingleData?.length > 0 && storeSingleData[0]?.[field.name]) || "" // Accessing data from storeSingleData
                            : formData?.[field.name] || ""
                        }
                        onChange={(e) => {
                          if (openEditModel) {
                            // setSingleData({
                            //   ...storeSingleData[0], // Accessing the first object in the array
                            //   [field.name]: e.target.value,
                            // });
                            const updatedData = { ...storeSingleData[0], [field.name]: e.target.value };
                            setSingleData([updatedData, ...storeSingleData.slice(1)]);
                          } else {
                            handleChange(field.name, e.target.value);
                          }
                        }}
                        variant="outlined"
                      />
                    )}
                  </>
                )}
              </Box>

            ))}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              onClick={openEditModel ? handleEditModal : handleAddData}
            >
              {openEditModel ? "Save" : "Add"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenAddModel(false);
                setOpenEditModel(false);
                setSingleData({});
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DimensionsMaterialList;
