//Widget Filter Modal
import React, { useEffect, useState } from 'react';
import TimePeriodFilter from './TimePeriodFilter';
import WidgetFilter from '../../page/WidgetFilter';
import moment from 'moment';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { IoMdClose } from 'react-icons/io';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import { FaRegTrashAlt } from 'react-icons/fa';
import SystemAttributesValues from '../../SystemAttributeValues';
import { FiPlus } from 'react-icons/fi';
import { getCyclePeriodicityList } from '../../../store/ApiSlice/widgetSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const selectStyle = "w-[150px] px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed";

const systemAttributeOptionList = [{
  label: "Scenario ID",
  value: "scenario"
},
{
  label: "Cycle ID",
  value: "cycleIds"
},
{
  label: "Lag ID",
  value: "lagIds"
},
]

const cyclePeriodicity = [
  "Day",
  "SysWeek",
  "Month",
  "Week",
  "Quarter",
  "Year"
];

const PageFilter = ({
  selectedAttributes,
  setSelectedAttributes,
  selectedValue,
  setSelectedValue,
  canvasFindData,
  openModalRow,
  setOpenModalRow,
  attributeValueData,
  setAttributeValueData,
  formValues,
  selectedMeasures,
  setFormValues,
  timeDimensionData,
  setSelectedMeasures,
  query,
  setQuery,
  filteredOptions,
  filteredValidToOptions,
  search,
  setSearch,
  setNewPageFilter,
  setNewFilter,
  onScroll,
  listInnerRef,
  userList,
  scenarioList,
  toggleMeasure,
  systemAttributeList,
  setSystemAttributeList
}) => {

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-2xl flex flex-col relative">
        <div className='flex justify-between items-center'>
          <h1>Widget Filter</h1>
          {/* <button
            onClick={() => {
              // setNewPageFilter(false);
              setNewFilter(false);
            }}
          >
            <IoMdClose size={30} />
          </button> */}
          <div>
            <button
              onClick={() => {
                setNewPageFilter(false);
                setNewFilter(false);
              }}
              className="px-4 py-2 bg-gray-300 rounded-lg mr-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (formValues?.pageTimePeriodName === "" || formValues?.pageTimePeriodName === undefined) {
                  toast.error("Please select widget filter time period type")
                  return
                } else if (selectedMeasures?.pageStartDate === "" || selectedMeasures?.pageStartDate === undefined) {
                  toast.error("Please select widget filter time period from date")
                  return
                } else if (selectedMeasures?.pageEndDate === "" || selectedMeasures?.pageEndDate === undefined) {
                  toast.error("Please select widget filter time period to date")
                  return
                }
                else {
                  setNewPageFilter(false);
                  setNewFilter(false);
                }
                // setNewPageFilter(false);
                // setNewFilter(false);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg">
              Proceed
            </button>

          </div>
        </div>
        <div className='h-[700px] overflow-y-auto'>
          {/* <div className="flex border-b mb-4">
          <button
            className={`px-4 py-2 ${activeTab === 'attributes'
              ? 'border-b-2 text-xs border-blue-600 font-semibold'
              : 'text-gray-500'
              }`}
            onClick={() => setActiveTab('attributes')}
          >
            Attributes
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'timeAttributes'
              ? 'border-b-2 text-xs border-blue-600 font-semibold'
              : 'text-gray-500'
              }`}
            onClick={() => setActiveTab('timeAttributes')}
          >
            Time Attributes
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'systemAttributes'
              ? 'border-b-2 text-xs border-blue-600 font-semibold'
              : 'text-gray-500'
              }`}
            onClick={() => setActiveTab('systemAttributes')}
          >
            System Attributes
          </button>
        </div> */}
          {/* {activeTab === 'attributes' && (
          <div className="flex-grow overflow-y-auto custom-scrollbar mb-12"> */}
          {/* {newPageFilter ? (
                            <WidgetFilter
                                selectedAttributes={selectedPageAttributes}
                                setSelectedAttributes={setSelectedPageAttributes}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                canvasFindData={canvasFindData}
                                openModalRow={openModalRow}
                                setOpenModalRow={setOpenModalRow}
                                attributeValueData={attributeValueData}
                                setAttributeValueData={setAttributeValueData}
                            />
                        ) : ( */}
          <WidgetFilter
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={setSelectedAttributes}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            canvasFindData={canvasFindData}
            openModalRow={openModalRow}
            setOpenModalRow={setOpenModalRow}
            attributeValueData={attributeValueData}
            setAttributeValueData={setAttributeValueData}
          />
          {/* )} */}
          {/* </div>
        )} */}
          {/* {activeTab === 'timeAttributes' && (
          <>
            <div className="flex-grow overflow-y-auto custom-scrollbar mb-12"> */}
          <TimePeriodFilter
            formValues={formValues}
            selectedMeasures={selectedMeasures}
            handleTimePeriod={(e) => {
              setFormValues({
                ...formValues,
                pageTimePeriod: e.target.value,
              });
              const selectedData = timeDimensionData?.timeSchemaFields?.find(
                (data) => data._id === e.target.value
              );
              setFormValues((prevValues) => ({
                ...prevValues,
                pageTimePeriod: e.target.value,
                [`pageTimePeriodName`]: selectedData['Period Type'],
              }));
              setSelectedMeasures((prevState) => ({
                ...prevState,
                pageValidFrom: '',
                pageStartDate: "",
                pageValidTo: "",
                pageEndDate: ""
              }));
            }}
            timePeriod={formValues?.pageTimePeriod}
            handleLogicalOperator={(e) => {
              setFormValues({
                ...formValues,
                pageLogicalOperator: e.target.value,
              });
            }}
            logicalOperator={formValues?.pageLogicalOperator}
            handleValidFrom={(newValue) => {
              const newValueDate = moment(newValue?.target?.value);

              const findStartDate = filteredOptions?.find((item) => {
                const itemDate = moment(item["Period Start"]);

                switch (formValues?.timePeriodNameRows) {
                  case "Day":
                    return itemDate.isSame(newValueDate, "day");

                  case "Week":
                    return itemDate.isoWeek() === newValueDate.isoWeek() &&
                      itemDate.year() === newValueDate.year();

                  case "Month":
                    return itemDate.format("YYYY-MM") === newValueDate.format("YYYY-MM");

                  case "Year":
                    return itemDate.year() === newValueDate.year();

                  case "SysWeek":
                    return itemDate.week() === newValueDate.week() &&
                      itemDate.year() === newValueDate.year();

                  default:
                    return false;
                }
              });
              setSelectedMeasures((prevState) => ({
                ...prevState,
                pageValidFrom: findStartDate ? findStartDate['Time Period Name'] : '',
                pageStartDate: newValue?.target?.value,
              }));
              setFormValues((prev) => ({
                ...prev,
                startDate: newValue?.target?.value,
              }))
            }}
            validFrom={selectedMeasures?.pageValidFrom}
            handleValidTo={(newValue) => {
              const newValueDate = moment(newValue?.target?.value);

              const findStartDate = filteredOptions?.find((item) => {
                const itemDate = moment(item["Period Start"]);

                switch (formValues?.timePeriodNameRows) {
                  case "Day":
                    return itemDate.isSame(newValueDate, "day");

                  case "Week":
                    return itemDate.isoWeek() === newValueDate.isoWeek() &&
                      itemDate.year() === newValueDate.year();

                  case "Month":
                    return itemDate.format("YYYY-MM") === newValueDate.format("YYYY-MM");

                  case "Year":
                    return itemDate.year() === newValueDate.year();

                  case "SysWeek":
                    return itemDate.week() === newValueDate.week() &&
                      itemDate.year() === newValueDate.year();

                  default:
                    return false;
                }
              });
              setSelectedMeasures((prevState) => ({
                ...prevState,
                pageValidTo: findStartDate ? findStartDate['Time Period Name'] : '',
                pageEndDate: newValue?.target?.value,
              }));
              setFormValues((prev) => ({
                ...prev,
                endDate: newValue?.target?.value,
              }))
              // setSelectedMeasures((prevState) => ({
              //   ...prevState,
              //   pageValidTo: newValue ? newValue['Time Period Name'] : '',
              //   pageEndDate: newValue ? newValue['Period End'] : '',
              // }));
            }}
            validTo={selectedMeasures?.pageValidTo}
            timeDimensionData={timeDimensionData}
            query={query}
            setQuery={setQuery}
            filteredOptions={filteredOptions}
            filteredValidToOptions={filteredValidToOptions}
          />
          {/* </div>
          </>
        )} */}
          {/* {activeTab === 'systemAttributes' && 
          <div className="flex-grow overflow-y-auto custom-scrollbar mb-12"> */}
          <SystemAttributeComponent
            canvasFindData={canvasFindData}
            systemAttributeList={systemAttributeList}
            setSystemAttributeList={setSystemAttributeList}
          />
          {/* </div>
        } */}

          {/* <div className="flex justify-end mt-4 p-6 absolute bottom-0 right-0 bg-white w-full">
          <button
            onClick={() => {
              setNewPageFilter(false);
              setNewFilter(false);
            }}
            className="px-4 py-2 bg-gray-300 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (formValues?.pageTimePeriodName === "" || formValues?.pageTimePeriodName === undefined) {
                toast.error("Please select widget filter time period type")
                return
              } else if (selectedMeasures?.pageStartDate === "" || selectedMeasures?.pageStartDate === undefined) {
                toast.error("Please select widget filter time period from date")
                return
              } else if (selectedMeasures?.pageEndDate === "" || selectedMeasures?.pageEndDate === undefined) {
                toast.error("Please select widget filter time period to date")
                return
              }
              else {
                setNewPageFilter(false);
                setNewFilter(false);
              }
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg">
            Proceed
          </button>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default PageFilter;

const SystemAttributeComponent = (props) => {
  const { canvasFindData, systemAttributeList, setSystemAttributeList } = props;
  const { cyclePeriodicityList } = useSelector((state) => state?.widget)
  const dispatch = useDispatch()
  const handleAddSystemAttributes = () => {
  
    const existingAttributes = new Set(systemAttributeList.map(item => item.attributes));
    const filterData = systemAttributeOptionList.filter(item => !existingAttributes.has(item.value));

    const hasScenario = filterData.some(item => item.value === "scenario");

    const newAttribute = hasScenario
      ? {
        id: uuidv4(),
        attributes: "scenario",
        cyclePeriodicity: "",
        operator: "In Between",
        attValues: {
          Baseline: true
        }
      }
      : {
        id: uuidv4(),
        attributes: filterData[0]?.value || "",
        cyclePeriodicity: "",
        operator: "In Between",
        attValues: {}
      };
    // Add the new attribute to the system attribute list
    setSystemAttributeList(prev => [...prev, newAttribute]);
  }

  const handleRemoveSystemAttributes = (removeItemID) => {
    const updatedData = systemAttributeList.filter((item) => item.id !== removeItemID);
    setSystemAttributeList(updatedData);
  }

  const handleSystemAttributeValueChange = (e, ItemId) => {
    const { name, value } = e.target;
    if (name === 'attributes') {
      setSystemAttributeList(prev =>
        prev.map((item) =>
          item.id === ItemId
            ? {
              ...item,
              [name]: value,
              cyclePeriodicity: "",
              operator: "",
              attValues: {}
            }
            : item
        ),
      );
    } else {
      setSystemAttributeList(prev =>
        prev.map((item) =>
          item.id === ItemId
            ? { ...item, [name]: value }
            : item
        ),
      );
    }
  }

  useEffect(() => {

    if (canvasFindData) {

      dispatch(getCyclePeriodicityList({ canvasFindData }))
    }

  }, [canvasFindData])

  const handleOnChangesSystemAttribute = (updatedData) => {

    setSystemAttributeList(updatedData)
  }

  return <>
    <div className='border  mt-3 border-[#E9EAEB] rounded-xl p-4 mb-4'>
      <div className='flex justify-between items-center'>
        <h3>System Attributes</h3>
        <button
          onClick={() => handleAddSystemAttributes()}
          className="font-semibold text-sm p-2 flex items-center gap-2"
          type="button"
          disabled={systemAttributeList?.length === 3}
        >
          <FiPlus className="w-5 h-5 text-black" />
        </button>
      </div>

      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className='font-semibold text-[14px]'>Attribute</TableCell>
            <TableCell className='font-semibold text-[14px]'>Periodicity</TableCell>
            <TableCell className='font-semibold text-[14px]'>value</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {systemAttributeList.map((item, index) => {
          // Create a filtered list of system attribute options excluding already selected values
          const availableOptions = systemAttributeOptionList.filter(option =>
            !systemAttributeList.some(attItem => attItem.attributes === option.value && attItem.id !== item.id),
          );

          return <>
            <TableBody>
              <TableCell className=''>
                <select
                  id='attributes'
                  name='attributes'
                  value={item?.attributes || ""}
                  className={selectStyle}
                  onChange={(e) => handleSystemAttributeValueChange(e, item.id)}
                >
                  <option value="" selected>
                    Select Attribute
                  </option>
                  {availableOptions.map((attributeItem) =>
                    <option value={attributeItem.value} >
                      {attributeItem.label}
                    </option>)}
                </select>
              </TableCell>
              <TableCell>
                {item?.attributes === "cycleIds" ?
                  <select
                    id='cyclePeriodicity'
                    name='cyclePeriodicity'
                    value={item?.cyclePeriodicity || ""}
                    className={selectStyle}
                    onChange={(e) => handleSystemAttributeValueChange(e, item.id)}
                  >
                    <option value="" selected>
                      Select Attribute
                    </option>
                    {(cyclePeriodicityList ?? []).map((periodicityItem) =>
                      <option value={periodicityItem} >
                        {periodicityItem}
                      </option>)}
                  </select>

                  : "-"}
              </TableCell>
              <TableCell>
                <SystemAttributesValues
                  items={item}
                  selectedFilterDetail={canvasFindData}
                  systemAttributeList={systemAttributeList}
                  handleOnChangesSystemAttribute={handleOnChangesSystemAttribute}
                  rowIndex={index}
                />
              </TableCell>
              <TableCell className=''>
                {systemAttributeList.length > 1 &&
                  <FaRegTrashAlt
                    className=' text-[16px] cursor-pointer'
                    onClick={() => handleRemoveSystemAttributes(item.id)}
                  />
                }
              </TableCell>
            </TableBody>
          </>
        })}
      </Table>
    </div>
  </>
}
