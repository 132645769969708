import React, { useEffect, useRef, useState } from 'react';
import MiniDrawer from '../components/common/drawer/DrawerToggle';
import {
  Box,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { getModelListAsync } from '../store/ApiSlice/dimensionSlice';
import { useSelector } from 'react-redux';
import { getMeasureList } from "../store/ApiSlice/measureSlice";
import { createDimensionFilteredmasterData, createViewAggregated, deleteHomeWidget, getFilterDetail, getHomePageWidget } from '../store/ApiSlice/canvasSlice';
import ChartMain from '../components/canvas/shared/Chart-Main';
import MyGridComponent from '../components/page/MyGridComponent';
import { MdMoreVert } from 'react-icons/md';
import DeleteModal from '../components/commonComponent/DeleteComponent/DeleteModal';
import { useParams } from 'react-router-dom';
import 'react-data-grid/lib/styles.css';
import RGL, { WidthProvider } from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { setWidgetLayout } from '../store/ApiSlice/widgetSlice';
import '../../src/components/page/page.css';
import * as ExcelJS from 'exceljs';
import EditWidget from './EditWidget';
import { getScenarios } from '../store/ApiSlice/processSlice';
import { getEnterpriseList } from '../store/ApiSlice/enterpriseSlice';
const ReactGridLayout = WidthProvider(RGL);

export default function Home(props) {
  const profileData = localStorage.getItem("accessToken")
  const userProfile = JSON.parse(profileData)
  const { measureData } = useSelector((state) => state.measure)
  const { enterpriseData } = useSelector((state) => state.enterprise)
  const [newActiveWidgetLoader, setNewActiveWidgetLoader] = useState({})
  const [loader, setLoader] = useState(false);
  const [openWidgetModal, setOpenWidgetModal] = useState({})
  const [deleteWidget, setDeleteWidget] = useState(false)
  const [widgetId, setWidgetId] = useState("")
  const [selectedRows, setSelectedRows] = useState([]);
  const { homePageWidgetData, filterDetail } = useSelector(
    (state) => state.canvas
  );

  const { widgetLayout } = useSelector((state) => state.widget)
  const [widgetGroupData, setWidgetGroupData] = useState([])
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fullScreenWidget, setFullScreenWidget] = useState(null);
  const [editWidgetModal, setEditWidgetModal] = useState(false)
  const { id } = useParams()

  const widgetIdData = id !== undefined ? homePageWidgetData?.filter((item) => item?.widgetUID?._id === id) : homePageWidgetData

  const dispatch = useDispatch();

  useEffect(() => {

    if (widgetGroupData?.length > 0 && (widgetLayout?.length === 0 || widgetGroupData?.length !== widgetLayout?.length)) {
      const widgetsCount = widgetGroupData?.length;
      const layouts = [];
      let defaultY = 0;
      let defaultX = 0;
      const defaultW = widgetsCount > 3 ? 3 : Math.floor(12 / widgetsCount);

      for (let index = 0; index < widgetsCount; index++) {
        if (index % 4 === 0 && index !== 0) {
          defaultY += 5;
          defaultX = 0;
        }

        layouts.push({
          w: defaultW,
          h: 5,
          x: defaultX,
          y: defaultY,
          i: index.toString(),
          moved: false,
          static: false,
        });


        defaultX += defaultW;
      }
      dispatch(setWidgetLayout(layouts));
    }
  }, [widgetGroupData]);

  const handleFullScreenToggle = (groupIndex) => {

    const widgetsCount = widgetIdData?.length;
    const defaultW = widgetsCount > 3 ? 3 : widgetsCount === 1 ? 4 : Math.floor(12 / widgetsCount);

    if (fullScreenWidget === groupIndex) {
      setFullScreenWidget(null);
      setTimeout(() => {
        const newLayout = widgetLayout?.map((l, i) =>
          i === groupIndex ? { ...l, w: defaultW, h: 5 } : l
        );
        dispatch(setWidgetLayout(newLayout));
      }, 0);

    } else {
      setFullScreenWidget(groupIndex);
      setTimeout(() => {
        const newLayout = widgetLayout?.map((l, i) =>
          i === groupIndex ? { ...l, w: 12, h: 6 } : l
        );
        dispatch(setWidgetLayout(newLayout));
      }, 0);
    }
  };

  const onDrop = (ev) => {
    ev.preventDefault();
    // addNewItem();
  };

  const getWidgetLayoutFromLocalStorage = () => {
    const layoutJSON = localStorage.getItem('widgetLayout');
    return layoutJSON ? JSON.parse(layoutJSON) : [];
  };

  const saveWidgetLayoutToLocalStorage = (layout) => {
    localStorage.setItem('widgetLayout', JSON.stringify(layout));
  };
  const gridApi = useRef(null)

  useEffect(() => {
    const savedLayout = getWidgetLayoutFromLocalStorage();
    if (savedLayout?.length > 0) {
      dispatch(setWidgetLayout(savedLayout));
    }
  }, [dispatch]);

  const handleLayoutChange = (newLayout) => {
    if (!isInitialRender) {
      if (JSON.stringify(newLayout) !== JSON.stringify(widgetLayout)) {
        dispatch(setWidgetLayout(newLayout));
        saveWidgetLayoutToLocalStorage(newLayout);
      }

      if (gridApi.current) {
        gridApi.current.sizeColumnsToFit();
      }
    }
    setIsInitialRender(false);
  };

  useEffect(() => {
    dispatch(getHomePageWidget())
    dispatch(getModelListAsync());
    dispatch(getMeasureList());
    dispatch(getEnterpriseList())
    dispatch(getFilterDetail())
  }, []);


  const processPageData = async () => {
    let newPageData = [];

    if (widgetIdData?.length > 0) {

      for (const PageItem of widgetIdData) {

        let pageTab = {};

        if (PageItem?.widgetUID) {
          const findFilterId = filterDetail.find(filter => filter?._id === PageItem?.widgetUID?.filter);
          const widgetType = PageItem?.widgetUID?.widgetType === "Chart" ? PageItem?.widgetUID?.chart : PageItem?.widgetUID?.worksheet;
          const timeName = widgetType?.timePeriods?.periodType;

          let payload, result;
          if (PageItem?.widgetUID?.widgetSubType === "Measure Data" || PageItem?.widgetUID?.widgetType === "Chart") {
            payload = {
              ...(widgetType?.measures?.length > 0 && { measures: widgetType?.measures }),
              timeFields: { [timeName]: widgetType?.timePeriods?.periodValues },
              aggregationFields: widgetType?.attributes?.map(attr => attr?.attributeName),
              ...(PageItem?.widgetUID?.scenario?.length > 0 && { scenario: PageItem?.widgetUID?.scenario }),
              ...(PageItem?.widgetUID?.cycleID?.length > 0 && { cycleID: PageItem?.widgetUID?.cycleID }),
              ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId.attributes.map(attr => ({
                  attribute: attr?.attributeUID?.name,
                  operator: attr?.operator,
                  values: attr?.values,
                }))
              })
            };

            result = await dispatch(createViewAggregated({ payload }));
          } else {
            payload = {
              dimensionUID: PageItem?.widgetUID?.worksheet?.dimension,
              attributes: PageItem?.widgetUID?.worksheet?.attributes?.map(item => item?.attributeName),
              ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId?.attributes?.map(item => ({
                  attribute: item?.attributeUID?.name,
                  operator: item?.operator,
                  values: item?.values,
                }))
              }),
              widgetUID: PageItem?.widgetUID?.worksheet?._id,
              scenario: PageItem?.widgetUID?.scenario?.map(item => item),
            };
            result = await dispatch(createDimensionFilteredmasterData({ payload }));
          }
          pageTab = {
            [PageItem?.widgetUID?.widgetName]: [{
              ...PageItem,
              getResul: result?.payload?.data,
            }]
          };
        }
        newPageData.push(pageTab);
      }
      setWidgetGroupData(newPageData);
    }
  };


  useEffect(() => {
    processPageData()
    if (!widgetIdData?.length) {
      setWidgetGroupData([])
    }
  }, [homePageWidgetData])

  const handleClickWorkSheetMenu = (rowId) => (event) => {
    setOpenWidgetModal((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  }

  const handleCloseWorkSheetMenu = (id) => {
    setOpenWidgetModal(prevState => ({
      ...prevState,
      [id]: null
    }));
  };


  const widgetpageTab = widgetGroupData?.length > 0
    ? widgetGroupData?.reduce((acc, item) => {
      if (item) {
        return {
          ...acc,
          ...item
        };
      }
      return acc;
    }, {})
    : {};
  const groupedWidgets = widgetpageTab

  const generateExcel = (datas) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    if (datas?.length > 0) {
      // Add headers
      const header = Object.keys(datas[0])?.filter(key => key !== "_id");
      worksheet.addRow(header);

      // Add rows
      datas.forEach(obj => {
        const row = header?.map(col => obj[col] !== undefined ? obj[col] : '');
        worksheet.addRow(row);
      });
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const fileName = 'measure_data.xlsx';
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleDelete = () => {
    return (
      <>
        <DeleteModal
          isModalOpenDelete={deleteWidget}
          setModalOpenDelete={setDeleteWidget}
          attriId={widgetId}
          setSelectedRows={setSelectedRows}
          title="Widget"
          deleteAPI={deleteHomeWidget}
          getAPI={getHomePageWidget}
        />
      </>
    )
  }

  const getScenarioData = async (data) => {
    const body = {
      enterpriseName: enterpriseData?.find((item) => item?._id === data?.enterpriseUID)?.enterpriseName,
      modelName: measureData?.find((item) => item?.modelUID === data?.modelUID)?.modelName,
    };
    const result = await dispatch(getScenarios({ body }))
    if (result?.meta?.requestStatus === 'fulfilled') {
      console.log("resultresult", result);
    } else if (result?.meta?.requestStatus === 'rejected') {
      console.log("resultresult", result);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <MiniDrawer />
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto', maxHeight: "100vh", height: "100%" }}>
          <div
            style={{
              marginTop: '68px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <h3>Welcome</h3>
            <h3>
              {userProfile?.data?.firstName},
            </h3>
          </div>
          <div>
            <ReactGridLayout
              {...props}
              onDrop={onDrop}
              className="layout"
              layout={widgetLayout || []}
              cols={12}
              rowHeight={100}
              width={1200}
              onLayoutChange={handleLayoutChange}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            >
              {
                groupedWidgets && typeof groupedWidgets === 'object' && (
                  Object.keys(groupedWidgets).map((groupName, groupIndex) => {
                    const widgets = groupedWidgets[groupName];
                    const activeWidgetsData = widgets?.find(
                      (widgetItem) => widgetItem.getResul
                    );

                    return (
                      <div key={groupIndex}
                        data-grid={widgetLayout[groupIndex]}
                        style={{
                          border: "1px solid white",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          marginBottom: "10px", zIndex: "9", background: "white", padding: "10px", borderRadius: "10px"
                        }}
                      >
                        <Box sx={{
                          overflowY: "auto", height: "100%", position: "relative", paddingRight: "6px",
                          '&::-webkit-scrollbar': {
                            width: '4px',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'none',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: 'rgb(167, 234, 254)',
                            // background: '#918f8f',
                            borderRadius: '6px',
                          },
                        }}>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                            <IconButton
                              size="small"
                              sx={{
                                color: 'black',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)(e);
                              }}
                            >
                              <MdMoreVert
                                cursor="pointer"
                                style={{ zIndex: "9" }}
                              />
                            </IconButton>
                            <Menu
                              anchorEl={openWidgetModal[activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id]}
                              open={Boolean(openWidgetModal[activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id])}
                              onClose={() => handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)}
                            >
                              <MenuItem
                                onClick={() => {
                                  setEditWidgetModal(true)
                                  getScenarioData(activeWidgetsData?.widgetUID)
                                  setWidgetId(activeWidgetsData?.widgetUID)
                                  handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                }}
                                sx={{ fontSize: "12px" }}
                              >
                                Edit Widget
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setWidgetId(activeWidgetsData?.widgetUID?._id)
                                  setDeleteWidget(true)
                                  handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                }}
                                sx={{ fontSize: "12px" }}
                              >
                                Delete Widget
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                  generateExcel(activeWidgetsData?.getResul)
                                }}
                                sx={{ fontSize: "12px" }}
                              >
                                Download Data
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleFullScreenToggle(groupIndex)
                                  handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                }}
                                sx={{ cursor: "pointer", fontSize: "12px" }}
                              >
                                {fullScreenWidget === groupIndex ? "Shrink To Original" : "Full Screen"}
                              </MenuItem>
                            </Menu>
                          </Box>
                          {activeWidgetsData?.widgetUID !== null && activeWidgetsData?.widgetUID?.widgetType === "Chart" ? (
                            <ChartMain
                              loader={newActiveWidgetLoader?.[groupName]}
                              setLoader={setNewActiveWidgetLoader}
                              groupName={groupName}
                              finalData={activeWidgetsData}
                              aggregatedViewData={activeWidgetsData?.getResul}
                            />
                          ) : (
                            <MyGridComponent
                              loader={newActiveWidgetLoader?.[groupName]}
                              setLoader={setLoader}
                              finalData={activeWidgetsData}
                              aggregatedViewData={activeWidgetsData?.getResul}
                            />
                          )}
                        </Box>
                      </div>
                    );
                  })
                )
              }
            </ReactGridLayout>
          </div>
        </Box>
      </Box>
      <Dialog open={deleteWidget} onClose={() => setDeleteWidget(false)}>
        {handleDelete()}
      </Dialog>

      <Dialog
        open={editWidgetModal}
        onClose={() => {
          setEditWidgetModal(false)
          setWidgetId()
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: 'auto',
            width: '600px',
          }}
        >
          <EditWidget
            widgetId={widgetId}
            editWidgetModal={editWidgetModal}
            setEditWidgetModal={setEditWidgetModal}
          />
        </Box>
      </Dialog>
    </>
  );
}
