import { Toolbar, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment/moment';

const ReferenceAttributes = ({ dimenstionData, formValues, modelEnterprise }) => {
    let refDimensionFields = dimenstionData.fields ? dimenstionData.fields : []
    const refFilterModelAttribute = refDimensionFields?.filter((item) => item?.enterpriseUID === modelEnterprise?.enterpriseUID)
        ?.filter((item) => item?.modelUID === modelEnterprise?.modelUID)

    const isPrimaryKey = (id) => {
        let refDimensionPrimaryKeys = dimenstionData.primaryKey ? dimenstionData.primaryKey : []
        let isExist = refDimensionPrimaryKeys.find(item => item._id === id)
        return isExist ? true : false;
    }
    const isRequiredKey = (id) => {
        let refDimensionRequired = dimenstionData.requiredFields ? dimenstionData.requiredFields : []
        let isExist = refDimensionRequired.find(item => item._id === id)
        return isExist ? true : false;
    }

    return (
        <>
            <hr style={{ borderTop: '2px solid #1976d2', width: '100%', margin: '20px auto' }} />
            <Toolbar>
                <Typography variant="h6">Referenced Attributes</Typography>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Length</TableCell>
                            <TableCell align="left">Notes</TableCell>
                            <TableCell align="left">Primary Key</TableCell>
                            <TableCell align="left">Required</TableCell>
                            <TableCell align="left">Created On</TableCell>
                            <TableCell align="left">Changed On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {refFilterModelAttribute?.length > 0 && refFilterModelAttribute.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">{row.length}</TableCell>
                                <TableCell align="left">{row.notes}</TableCell>
                                <TableCell align="left"><Checkbox checked={isPrimaryKey(row._id)} disabled={true} /></TableCell>
                                <TableCell align="left"><Checkbox checked={isRequiredKey(row._id)} disabled={true} /></TableCell>
                                <TableCell align="left">{moment(row.createdOn).format("DD-MM-YYYY")}</TableCell>
                                <TableCell align="left">{moment(row.changedOn).format("DD-MM-YYYY")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!refFilterModelAttribute?.length && (
                    <div className="centeredText" style={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>No attributes available</div>
                )}
            </TableContainer>
        </>
    )
}

export default ReferenceAttributes;