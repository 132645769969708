import { Autocomplete, Button, Dialog, DialogActions, DialogContent, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CommonInput from '../input/CommonInput';
import Loader from '../../loader/Loader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createPageDetail, createUpdateCanvasAsync, getCanvasList, getPageList, getWorkBookDetail } from '../../../store/ApiSlice/canvasSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../../../helper/constants';
import axios from 'axios';
import { getMeasureList } from '../../../store/ApiSlice/measureSlice';
import { getAttributes } from '../../../store/ApiSlice/attributeSlice';
import { loadUsersList } from '../../../store/ApiSlice/authSlice';
import { getEnterModelList } from '../../../store/ApiSlice/modelSlice';

const CanvasModal = ({
    formValues,
    setFormValues,
    updatedId,
    isEdit,
    setCanvasOpenModal,
    CanvasOpenModal,
    setIsEdit,
    isView
}) => {
    const user = JSON.parse(localStorage.getItem("accessToken"))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enterpriseData } = useSelector((state) => state?.enterprise)
    const { modalData } = useSelector((state) => state.modelList)
    const { pageData } = useSelector((state) => state?.canvas)
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setFormValues({
            ...formValues,
            enterpriseName: user?.data?.enterprise?.enterpriseName,
            enterpriseUID: user?.data?.enterprise?._id,
        })
    }, [CanvasOpenModal])


    const canvasFindData = {
        enterpriseUID: {
            _id: formValues?.enterpriseUID
        },
        modelUID: {
            modalUID: formValues?.modelUID
        }
    }

    useEffect(() => {
        if (formValues.enterpriseName && formValues?.modelUID) {
            dispatch(getPageList({ canvasFindData }))
        }
    }, [formValues.enterpriseName, formValues?.modelUID]);

    useEffect(() => {
        dispatch(getMeasureList())
        dispatch(getAttributes())
        dispatch(getCanvasList())
        dispatch(getWorkBookDetail())
        dispatch(getPageList())
        dispatch(getEnterModelList())
    }, [])

    const handleDimensionFieldChange = (newValue, fieldName, e) => {
        if (fieldName === 'enterpriseName') {
            const selectedData = enterpriseData.filter((data) => data._id === newValue?._id);
            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.enterpriseName || user?.data?.enterprise?.enterpriseName,
                [`enterpriseUID`]: newValue?._id
            });
        } else if (fieldName === 'modelName') {
            const selectedData = modalData?.filter((data) => data.modalUID === newValue?.modalUID);
            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID
            });
        } else if (fieldName === 'pageUID') {
            setFormValues({
                ...formValues,
                [fieldName]: newValue?.length > 0 ? newValue?.map((item) => ({
                    name: item?.name,
                    _id: item?._id
                })) : []
            });
        } else {
            const { name, value } = e.target
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    }

    const handleSubmit = async () => {
        setLoader(true)
        if (!isEdit && (formValues?.pageName === "" || formValues?.pageName === undefined || !formValues?.pageName)) {
            toast.error("Please enter page name")
            setLoader(false)
        } else {
            const payload = {
                enterpriseUID: user?.data?.superAdmin ? formValues?.enterpriseUID : user?.data?.enterprise?._id,
                // pageUID: formValues?.pageUID,
                modelUID: formValues?.modelUID,
                name: formValues?.canvasName,
                createdOn: formValues?.createdOn,
                changedOn: formValues?.changedOn,
            }
            const result = await dispatch(createUpdateCanvasAsync({ payload, isEdit, updatedId }))
            if (result?.meta?.requestStatus === 'fulfilled') {
                setLoader(false)
                // toast.success(isEdit ? "Canvas updated successfully" : "Canvas created successfully");
                setCanvasOpenModal(false)
                dispatch(getCanvasList());
                if (!isEdit) {
                    handleSubmitPage(result?.payload?.data)
                    navigate(`/Canvas/${result?.payload?.data._id}?isEdit=true`);
                }
            } else if (result?.meta?.requestStatus === 'rejected') {
                setLoader(false)
                toast.error(result?.error?.message)
            }
        }
    }

    const handleSubmitPage = async (data) => {
        const payload = {
            name: formValues?.pageName,
            canvasUID: data?._id,
            enterpriseUID: data?.enterpriseUID,
            modelUID: data?.modelUID,
        };
        const result = await dispatch(
            createPageDetail({ payload, isEdits: isEdit, updatedId })
        );
        if (result?.meta?.requestStatus === 'fulfilled') {
            // toast.success(
            //     isEdit ? 'Page updated successfully' : 'Page created successfully'
            // );
            setFormValues({});
            dispatch(getPageList({ canvasFindData }));
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message);
        }
    };

    const handleCloseCanvas = () => {
        setFormValues({})
        setCanvasOpenModal(false)
        setIsEdit(false)
    }
    return (
        <>
            <Dialog
                open={CanvasOpenModal}
                onClose={handleCloseCanvas}
            >
                <DialogContent sx={{ marginTop: "10px !important" }}>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <CommonInput
                            name='canvasName'
                            value={formValues?.canvasName || ""}
                            label='Canvas Name'
                            style={{ padding: 0 }}
                            onChange={(e, newValue) => {
                                handleDimensionFieldChange(newValue, "canvas", e);
                            }}
                            disabled={isView}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="enterpriseName"
                            name="enterpriseName"
                            fullWidth
                            options={user?.data?.superAdmin && enterpriseData}
                            getOptionLabel={(option) => option.enterpriseName}
                            value={
                                !user?.data?.superAdmin
                                    ? enterpriseData.find(option => option.enterpriseName === formValues?.enterpriseName) || null
                                    : formValues?.enterpriseName ? enterpriseData?.find(
                                        (option) => option?._id === formValues?.enterpriseUID
                                    ) || null : null
                            }
                            onChange={(event, newValue) => {
                                handleDimensionFieldChange(newValue, "enterpriseName");
                            }}
                            disabled={!user?.data?.superAdmin}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Enterprise Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="modalName"
                            name="modalName"
                            fullWidth
                            options={modalData?.filter((item) => item?.enterpriseUID?._id === formValues?.enterpriseUID)}
                            getOptionLabel={(option) => option.modelName}
                            value={formValues?.modelName ? modalData?.find((item) => item?.modalUID === formValues?.modelUID) || null : null}
                            onChange={(event, newValue) => {
                                handleDimensionFieldChange(newValue, "modelName");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Model Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    {!isEdit && (
                        <>
                            <FormControl fullWidth style={{ paddingBottom: '25px' }}>
                                <TextField
                                    id="pageName"
                                    name="pageName"
                                    variant="outlined"
                                    fullWidth
                                    label="Page Name"
                                    value={formValues?.pageName}
                                    onChange={(e, newValue) => {
                                        handleDimensionFieldChange(newValue, 'pageName', e);
                                    }}
                                    placeholder="Enter page name"
                                    disabled={false}
                                />
                            </FormControl>
                        </>
                    )}
                    <DialogActions>
                        <Button onClick={handleCloseCanvas} sx={{ textTransform: "capitalize" }}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} sx={{ textTransform: "capitalize" }}>
                            Submit &nbsp; &nbsp;
                            {loader && <Loader />}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CanvasModal
