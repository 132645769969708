import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import "react-toastify/dist/ReactToastify.css";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { registerAllModules } from "handsontable/registry";
import CreateAttribute from "./CreateAttribute";
import DeleteAttribute from "./DeleteAttribute";
import { columnNames } from "../../helper/constants";
import "../../style/common.css";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Box, Tooltip } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Shuffle } from "./shuffule";
import AttributrMaterialList from "./attributrMaterialList";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAttributes } from "../../store/ApiSlice/attributeSlice";
import { ThemeContext } from "../../ThemeContext";

const AttributeList = ({ modelEnterprise }) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const { attribute } = useSelector((state) => state?.attribute)
  const [isModalOpen, setModalOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState(columnNames);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditRowData, setEditRowData] = useState({});
  const checkList = columnNames;
  const [attriId, setAttriId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAttributes(""))
    registerAllModules();
  }, []);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    handleCloseModal();
  };
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);

  const handleChange = (event) => {
    setCheckedItems(
      event.target.checked
        ? [...checkedItems, event?.target?.name]
        : checkedItems.filter((item) => item !== event?.target?.name)
    );
  };


  const handleTableRowSelect = (e, row) => {
    setEditRowData(row);
    let updatedIds = selectedRows?.includes(row?.original?._id)
      ? selectedRows?.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    setSelectedRows(updatedIds);
  };
  const [updatedId, setUpdatedId] = useState(null);
  const handleCreateUpdate = (edit, id) => {

    setUpdatedId(id);
    setIsCreateUpdateModalOpen(true);
    setIsEdit(edit);
  };
  return (
    <>
      {/* <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box component="main" sx={{
          flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
          background: isDarkTheme ? "whitesmoke" : "white",
        }}> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="heading"> Attributes</h3>
        <div style={{ display: "flex", alignItems: "center" }}>

          <Tooltip title="Create Attribute">
            <Button
              onClick={() => {
                handleCreateUpdate(false);
              }}
              className="awesome-button"
              sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
            >
              +
            </Button>
          </Tooltip>


          {isCreateUpdateModalOpen && (
            <CreateAttribute
              editRow={isEditRowData}
              setEditRowData={setEditRowData}
              isCreateUpdateModalOpen={isCreateUpdateModalOpen}
              setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
              isEdit={isEdit}
              selectedRows={selectedRows}
              dimensionAttributes={[]}
              setDimensionAttributes={() => { }}
              updatedId={updatedId}
            />
          )}
          <DeleteAttribute
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setModalOpenDelete={setModalOpenDelete}
            isModalOpenDelete={isModalOpenDelete}
            attriId={attriId}
          />
        </div>
      </div>
      <AttributrMaterialList
        modelEnterprise={modelEnterprise}
        setAttriId={setAttriId}
        data={attribute}
        handleTableRowSelect={handleTableRowSelect}
        setModalOpenDelete={setModalOpenDelete}
        handleCreateUpdate={handleCreateUpdate}
      />

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <>
          <DialogTitle>View Setting</DialogTitle>
          <hr />
          <DialogContent>
            <DndProvider backend={HTML5Backend}>
              <Shuffle
                checkList={checkList}
                checkedItems={checkedItems}
                handleChange={handleChange}
              />
            </DndProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
        {/* {renderModalContent()} */}
      </Dialog>
      {/* </Box>
      </Box> */}
    </>
  );
};

export default AttributeList;
