import React, { useState } from "react";
import { Toolbar, Typography, Grid, IconButton, Collapse, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Select, MenuItem, InputLabel, FormControl, Chip } from "@mui/material";
import CreateAttribute from "../../attributes/CreateAttribute";
import AttributesListModel from "./AttributesListModel";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import moment from "moment/moment";
import ReactSelect from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch } from "react-redux";
import { getAttributeOrEnterPrise } from "../../../store/ApiSlice/attributeSlice";

const AttributesTable = ({
  dimensionAttributes,
  setDimensionAttributes,
  isView,
  isEdit,
  isCreate,
  newData,
  formValues,
  setformValues,
  data,
  selectedDimension,
  selectedDimensions,
  refAttributeMapping,
  setRefAttributeMapping,
  dimenstionData,
}) => {
  const dispatch = useDispatch();
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
    useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const enterPriseId = isEdit ? formValues?.enterprisename : formValues?.enterpriseUID
  const modalId = isEdit ? formValues?.modelName : formValues?.modelUID
  const handleKeyChange = (event, row, keyToUpdate) => {
    let newValue = event.target.checked;
    setDimensionAttributes((prevArray) =>
      prevArray.map((obj, index) => {
        if (row._id === obj._id) {
          if (keyToUpdate === "primaryKey") {
            return {
              ...obj,
              [keyToUpdate]: newValue,
              required: newValue ? true : false,
            };
          } else {
            return { ...obj, [keyToUpdate]: newValue };
          }
        } else {
          return obj;
        }
      })
    );
  };
  const handleAddClick = () => {
    setModalOpen(true);
    dispatch(getAttributeOrEnterPrise({ enterPriseId, modalId }))
  };
  const handleNewClick = () => {
    setIsCreateUpdateModalOpen(true);
  };
  const handleDeleteClick = () => {
    setIsDeleteDimensionModalOpen(true);
  };
  const handleTableRowSelect = (event) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(id)
      ? selectedRows.filter((n) => n !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedIds);
  };

  const handleDeleteSubmit = () => {
    let newFilteredRows = dimensionAttributes.filter((item) => {
      return !selectedRows.includes(item._id);
    });
    setDimensionAttributes(newFilteredRows);
    setIsDeleteDimensionModalOpen(false);
  };
  const handleDeleteDimensionCloseModal = () => {
    setIsDeleteDimensionModalOpen(false);
  };

  const renderModalContent = () => {
    return (
      <Dialog
        open={isDeleteDimensionModalOpen}
        onClose={handleDeleteDimensionCloseModal}
      >
        <DialogTitle>Remove Attribute</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
            Are you sure for Remove this attribute?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDimensionCloseModal}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteSubmit();
            }}
            color="primary"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const referenceDimFields =
    dimenstionData && dimenstionData.fields ? dimenstionData.fields : [];

  const handleReferenceAttribute = (event, currentAttribute) => {
    let mappingIndex = refAttributeMapping.findIndex((item) => item.mainAttribute === currentAttribute._id);

    if (mappingIndex !== -1) {
      setRefAttributeMapping((prevMappings) => {
        const updatedMappings = [...prevMappings];
        updatedMappings[mappingIndex] = {
          ...updatedMappings[mappingIndex],
          refAttribute: event.target.value
        };
        return updatedMappings;
      });
    } else {
      setRefAttributeMapping((prevMappings) => [
        ...prevMappings,
        {
          mainAttribute: currentAttribute._id,
          refAttribute: event.target.value
        }
      ]);
    }
  };

  const getRefAttributeId = (row) => {
    let mapping = refAttributeMapping.find(
      (item) => item.mainAttribute?._id === row._id
    );
    return mapping ? mapping.refAttribute?._id : mapping;
  };

  return (
    <>
      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />
      <Toolbar>
        <Typography variant="h6">Assigned Attribute</Typography>
      </Toolbar>
      <Grid item>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "26px",
          }}
        >
          <Button
            disabled={isView}
            title="Add new attribute in dimension"
            variant="text"
            onClick={handleAddClick}
          >
            Add
          </Button>
          <Button
            disabled={isView}
            title="Create new attribute and add in dimension"
            variant="text"
            onClick={handleNewClick}
          >
            New
          </Button>
          <Button
            disabled={isView}
            title="Delete attribute from dimension"
            variant="text"
            onClick={handleDeleteClick}
          >
            Remove
          </Button>
        </Typography>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>

              {!isView && <TableCell align="left">Select</TableCell>}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Length</TableCell>
              <TableCell align="left">Notes</TableCell>
              <TableCell align="left">Primary Key</TableCell>
              <TableCell align="left">Required</TableCell>
              {formValues.type === "REFERENCE" && (
                <TableCell align="left">Reference Attribute</TableCell>
              )}
              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Changed On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dimensionAttributes?.length > 0 && dimensionAttributes?.map((item) => (
              <TableRow>
                {!isView && (
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={selectedRows.includes(item?._id)}
                      value={item?._id}
                      onChange={handleTableRowSelect}
                      disabled={item?.fromCompound ? true : false}
                    />
                  </TableCell>
                )}
                <TableCell align="left">{item?.name}</TableCell>
                <TableCell align="left">{item?.type}</TableCell>
                <TableCell align="left">{item?.length}</TableCell>
                <TableCell align="left">{item?.notes}</TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={item?.primaryKey ? true : false}
                    disabled={isView ? true : false}
                    value={item?.primaryKey ? true : false}
                    onChange={(event) => {
                      handleKeyChange(event, item, "primaryKey");
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={item?.required ? true : false}
                    disabled={item?.primaryKey || isView ? true : false}
                    value={item?.required ? true : false}
                    onChange={(event) => {
                      handleKeyChange(event, item, "required");
                    }}
                  />
                </TableCell>
                {formValues.type === "REFERENCE" && (
                  <TableCell align="left">
                    <Select
                      id="type"
                      name="type"
                      value={getRefAttributeId(item)}
                      onChange={(event) => {
                        handleReferenceAttribute(event, item);
                      }}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      disabled={isView}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      {referenceDimFields.map((item) => (
                        <MenuItem value={item?._id}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                )}
                <TableCell align="left">
                  {moment(item?.createdOn).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="left">
                  {moment(item?.changedOn).format("DD-MM-YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!dimensionAttributes?.length && (
          <div className="centeredText" style={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>No attributes selected yet</div>
        )}
      </TableContainer>
      {
        selectedDimension?.type == "REFERENCE" && (
          <>
            <hr
              style={{
                borderTop: "2px solid #1976d2",
                width: "100%",
                margin: "20px auto",
              }}
            />
            <Toolbar>
              <Typography variant="h6">Filter Assigned Attribute</Typography>
            </Toolbar>
            <div className="w-full" style={{ height: "200px", padding: "16px" }}>
              <label className="text-black  relative py-2 text-base rounded flex items-center ml-1 font-medium leading-6">
                Assign Attribute
              </label>
              <div className="relative w-full" style={{ marginTop: "10px" }}>
                <ReactSelect
                  options={dimensionAttributes?.map(item => ({
                    value: item?._id,
                    label: item?.name
                  }))}
                  onChange={(data) => {
                    data.map((item) => item.value);
                    setSelectedOption(data);
                  }}
                  // onChange={setSelectedOption}
                  defaultValue={selectedOption}
                  isSearchable={true}
                  isMulti
                  className="text-black"
                  style={{
                    width: "100%",
                    height: "500px"
                  }}
                />

              </div>
            </div>
          </>
        )
      }

      {isModalOpen && (
        <AttributesListModel
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          formValues={formValues}
          newData={newData}
          isEdit={isEdit}
        />
      )}

      {isCreateUpdateModalOpen && (
        <CreateAttribute
          isCreateUpdateModalOpen={isCreateUpdateModalOpen}
          setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
          isEdit={false}
          isCreate={isCreate}
          newData={newData}
          model={isEdit}
          data={data}
          selectedDimension={selectedDimension}
          selectedRows={[]}
          formValueData={formValues}
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
        />
      )}
      {renderModalContent()}
    </>
  );
};

export default AttributesTable;
