import { TextField } from '@mui/material'
import Select, { components } from 'react-select';
import React from 'react'

const CommonInput = ({
    name,
    value,
    label,
    onChange,
    style,
    disabled
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                fullWidth
                id={name}
                name={name}
                value={value}
                label={label}
                style={style}
                onChange={onChange}
                disabled={disabled}
            />
        </>
    )
}

export default CommonInput
