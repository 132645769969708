import { Box, Button, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle';
import DeleteMeasureDataProfile from './DeleteMeasureDataProfile';
import MeasureDataProfileMaterialList from './MeasureDataProfileMaterialList';
import CreateMeasureDataProfile from './CreateMeasureDataProfile';
import MeasureDataProfileMock from './MeasureDataProfileMock';
import { useDispatch } from 'react-redux';
import { getMeasureProfileList } from '../../store/ApiSlice/measuredataslice';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../ThemeContext';

const MeasureDataProfileList = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [formValues, setFormValues] = useState({})
  const [dataProfileDetail, setDataProfileDetail] = useState({
    operations: []
  })
  const [attributeLevelData, setAttributeLevelData] = useState([{
    attributeValue: ""
  }])
  const { measureProfileDataList } = useSelector((state) => state.measureprofile)
  const [isCreate, setIsCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedId, setUpdatedId] = useState(null)
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [measureEditProfile, setMeasureEditProfile] = useState(false)
  const [measureCreateProfile, setMeasureCreateProfile] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const measureProfileModel = Boolean(anchorEl);
  const [isDataProfileDetailUpdated, setIsDataProfileDetailUpdated] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [attributeLevelDetail, setAttributeLevelDetail] = useState({})

  const dispatch = useDispatch()
  const selectedProfile = measureProfileDataList?.find((item) => item?._id === selectedRows)

  useEffect(() => {
    dispatch(getMeasureProfileList())
  }, [])

  const handleCreateUpdate = (edit, id) => {
    setUpdatedId(id);
    setIsCreate(true)
    setIsEdit(edit);
  }

  const handleUpdateProfile = (edit) => {
    if (isEdit) {
      setFormValues({
        name: selectedProfile?.name,
        notes: selectedProfile?.notes,
        enterpriseUID: selectedProfile?.enterpriseUID?._id,
        modelUID: selectedProfile?.modelUID?.modalUID,
        action: attributeLevelDetail?.action,
        validFrom: attributeLevelDetail?.fromDate,
        validTo: attributeLevelDetail?.toDate,
        timeAttribute: attributeLevelDetail?.timeAttribute,
        measure: attributeLevelDetail?.measure,
        sourceMeasure: attributeLevelDetail?.sourceMeasure,
        level: attributeLevelDetail?.level,
        filter: attributeLevelDetail?.filter,
        index: selectedIndex
      })
    }
    setFormValues({
      ...formValues,
      action: attributeLevelDetail?.action,
      validFrom: attributeLevelDetail?.fromDate,
      validTo: attributeLevelDetail?.toDate,
      timeAttribute: attributeLevelDetail?.timeAttribute,
      measure: attributeLevelDetail?.measure,
      sourceMeasure: attributeLevelDetail?.sourceMeasure,
      level: attributeLevelDetail?.level,
      periodShift: attributeLevelDetail?.periodShift,
      filter: attributeLevelDetail?.filter,
      index: selectedIndex
    })
    // setIsCreate(false)
    setMeasureCreateProfile(true)
    setMeasureEditProfile(edit)
    setIsDataProfileDetailUpdated(true)
    setAttributeLevelData(attributeLevelDetail?.attributes?.map((item) => ({
      attributeValue: item
    })))
    setIsDataProfileDetailUpdated(true)
  }
  const handleTableRowSelect = (event, row) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    setSelectedRows(updatedIds);
  };

  const handleClickButton = (event, index, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
    setAttributeLevelDetail(data)
  };

  const handleCloseButton = (item) => {
    setAnchorEl(null);
  };


  return (
    <>
      {!isCreate && !isView && !measureCreateProfile && (
        <Box sx={{ display: "flex" }}>
          <MiniDrawer />
          <Box component="main" sx={{
            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
            background: isDarkTheme ? "whitesmoke" : "white",
          }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "60px",
              paddingBottom: "20px",
              position: "relative"
            }}>
              <h3 className="heading">Measure Data Profile</h3>
              <div>
                <Tooltip title="Add Measure Data Profile">
                  <Button
                    onClick={() => {
                      handleCreateUpdate(false)
                    }}
                    className="awesome-button"
                    sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                  >
                    +
                  </Button>
                </Tooltip>
                <DeleteMeasureDataProfile
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  setModalDeleteOpen={setModalDeleteOpen}
                  isModalDeleteOpen={isModalDeleteOpen}
                  updatedId={updatedId}
                />
              </div>
            </div>
            <MeasureDataProfileMaterialList
              handleTableRowSelect={handleTableRowSelect}
              setUpdatedId={setUpdatedId}
              handleCreateUpdate={handleCreateUpdate}
              setModalDeleteOpen={setModalDeleteOpen}
            />
          </Box>
        </Box>
      )}
      {(isCreate || isView) && (
        <CreateMeasureDataProfile
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isView={isView}
          setIsView={setIsView}
          selectedRows={updatedId}
          measureProfileModel={measureProfileModel}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleClickButton={handleClickButton}
          handleCloseButton={handleCloseButton}
          setMeasureEditProfile={setMeasureEditProfile}
          setMeasureCreateProfile={setMeasureCreateProfile}
          handleUpdateProfile={handleUpdateProfile}
          formValues={formValues}
          setFormValues={setFormValues}
          dataProfileDetail={dataProfileDetail}
          setDataProfileDetail={setDataProfileDetail}
          isDataProfileDetailUpdated={isDataProfileDetailUpdated}
          setIsDataProfileDetailUpdated={setIsDataProfileDetailUpdated}
          selectedIndex={selectedIndex}
        />
      )}
      <MeasureDataProfileMock
        measureCreateProfile={measureCreateProfile}
        setMeasureCreateProfile={setMeasureCreateProfile}
        measureEditProfile={measureEditProfile}
        setMeasureEditProfile={setMeasureEditProfile}
        setIsCreate={setIsCreate}
        setAnchorEl={setAnchorEl}
        formValues={formValues}
        setFormValues={setFormValues}
        dataProfileDetail={dataProfileDetail}
        setDataProfileDetail={setDataProfileDetail}
        attributeLevelData={attributeLevelData}
        setAttributeLevelData={setAttributeLevelData}
        isDataProfileDetailUpdated={isDataProfileDetailUpdated}
        setIsDataProfileDetailUpdated={setIsDataProfileDetailUpdated}
        selectedProfile={selectedProfile}
        updatedId={updatedId}
        isEdit={isEdit}
      />
    </>
  )
}

export default MeasureDataProfileList
