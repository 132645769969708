import { AppBar, Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Radio, Select, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createMultiEnterpriseUser, getMultiEnterpriseList } from '../../store/ApiSlice/multiEnterpriseSlice';
import { toast } from 'react-toastify';
import { getUserList } from '../../store/ApiSlice/userSlice';
import { getEnterpriseList } from '../../store/ApiSlice/enterpriseSlice';
import { MdMoreVert } from 'react-icons/md';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CreateMultiEnterpriseUserList = ({
    setIsCreate,
    isEdit,
    selectedRows
}) => {
    const userEnterprise = JSON.parse(localStorage.getItem("accessToken"))
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const { userData } = useSelector((state) => state?.user);
    const { multiEnterpriseData } = useSelector((state) => state?.multiEnterprise)
    const [multiUserListFormValue, setMultiUserListFormValue] = useState({
        enterpriseUID: userEnterprise?.data?.enterprise?._id
    });
    const [multiUserList, setMultiUserList] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataIndex, setDataIndex] = useState();
    const deleteExistingEnterprise = Boolean(anchorEl);
    const dispatch = useDispatch()
    const findUserEnterprise = userData?.filter((item) => item?.enterprise?._id === multiUserListFormValue?.enterpriseUID)

    const selectedData = multiEnterpriseData?.find((item) => item?._id === selectedRows)

    useEffect(() => {
        if (selectedData && isEdit) {
            setMultiUserListFormValue({
                enterpriseUID: selectedData?.enterprise?._id,
                userName: selectedData?.user?._id,
            })
            setMultiUserList(selectedData?.allowedEnterprises?.length > 0 && selectedData?.allowedEnterprises?.map((item) => ({
                sourceEnterprise: item?.sourceEnterprise?._id,
                editAllowed: item?.editAllowed,
            })))
        }
    }, [selectedData, isEdit])

    useEffect(() => {
        dispatch(getUserList())
        dispatch(getEnterpriseList())
        dispatch(getMultiEnterpriseList())
    }, [])
    const handleCancel = () => {
        setIsCreate(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event, index, type) => {
        setAnchorEl(event.currentTarget);
        setDataIndex(index);
    };

    const handleAdd = () => {
        setMultiUserList([...multiUserList, { sourceEnterprise: "", editAllowed: "" }]);
    };


    const handleremove = (type) => {
        const updatedField = [...multiUserList];
        updatedField.splice(dataIndex, 1);
        setMultiUserList(updatedField);
        setAnchorEl(null);
    };

    const handleChangeMultiEnterprise = (newValue, fieldName, e, index) => {
        const updatedFormValues = [...multiUserList];
        if (fieldName === "sourceEnterprise") {
            updatedFormValues[index][fieldName] = newValue?._id;
            setMultiUserList(updatedFormValues);
        } else if (fieldName === "editAllowed") {
            const { checked } = e.target
            updatedFormValues[index][fieldName] = checked;
            setMultiUserList(updatedFormValues);
        }
    }

    const handleChangeProfile = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            const selectedData = enterpriseData?.length > 0 && enterpriseData?.filter(
                (data) => data?._id === newValue?._id
            );

            setMultiUserListFormValue({
                ...multiUserListFormValue,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id,
            });
        } else if (fieldName === "userName") {
            setMultiUserListFormValue({
                ...multiUserListFormValue,
                [fieldName]: newValue?._id,
            });
        }
        else {
            setMultiUserListFormValue({
                ...multiUserListFormValue,
                [fieldName]: e.target.value
            })
        }
    }

    const handleSubmit = async () => {
        const payload = {
            enterprise: multiUserListFormValue?.enterpriseUID,
            user: multiUserListFormValue?.userName,
            allowedEnterprises: multiUserList?.length > 0 && multiUserList?.map((item) => ({
                sourceEnterprise: item?.sourceEnterprise,
                editAllowed: item?.editAllowed === "" ? false : item?.editAllowed,
            }))
        }

        const result = await dispatch(
            createMultiEnterpriseUser({ payload, isEdit, selectedRows })
        );

        if (result?.meta?.requestStatus == "fulfilled") {
            // toast.success(
            //     result?.payload?.message
            // );
            dispatch(getMultiEnterpriseList());
            setMultiUserListFormValue({});
            setMultiUserList([]);
            setIsCreate(false);
        } else if (result?.meta?.requestStatus === "rejected") {
            toast.error(result?.error?.message);
            setIsCreate(true);
        }
    }

    const labelTransparentStyle = {
        '& .MuiInputLabel-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInputLabel-shrink': {
            backgroundColor: 'transparent',
        }
    };

    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <AppBar position="static" sx={{ backgroundColor: "white" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                            {/* {getPageHeading()} */}
                            Assign Enterprises to Users
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Enterprise Name"
                                    name="enterpriseName"
                                    options={enterpriseData}
                                    getOptionLabel={(option) => option.enterpriseName}
                                    value={
                                        multiUserListFormValue?.enterpriseUID
                                            ? enterpriseData.find(
                                                (option) =>
                                                    option._id === multiUserListFormValue.enterpriseUID
                                            ) || null
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "enterpriseName", e);
                                    }}
                                    sx={labelTransparentStyle}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enterprise Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="User Name"
                                    name="userName"
                                    options={findUserEnterprise?.length > 0 ? findUserEnterprise : []}
                                    getOptionLabel={(option) => option?.userName}
                                    value={
                                        multiUserListFormValue?.userName
                                            ? findUserEnterprise.find(
                                                (option) =>
                                                    option?._id === multiUserListFormValue.userName
                                            ) || null
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "userName", e);
                                    }}
                                    sx={labelTransparentStyle}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="User Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />

                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6" component="h6">
                            Enterprises
                        </Typography>{" "}
                        <Button variant="contained" onClick={handleAdd}>
                            +
                        </Button>
                    </Box>
                    {multiUserList?.length > 0 && multiUserList?.map((item, index) => {
                        return (
                            <>
                                <Grid container spacing={3} style={{ marginTop: "10px" }}>
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >
                                        {(multiUserListFormValue?.enterpriseName && item?.editAllowed && !item?.sourceEnterprise) ? (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Enterprise"
                                                type='text'
                                                value={multiUserListFormValue?.enterpriseName}
                                            />
                                        ) : (item?.sourceEnterprise && item?.editAllowed) ? (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Enterprise"
                                                type='text'
                                                value={
                                                    item?.sourceEnterprise
                                                        ? enterpriseData?.find(
                                                            (option) =>
                                                                option?._id === item?.sourceEnterprise
                                                        )?.enterpriseName || ''
                                                        : ''
                                                }
                                            />
                                        ) :
                                            (
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        id="demo-simple-select-readonly"
                                                        labelId="Enterprise"
                                                        name="sourceEnterprise"
                                                        options={enterpriseData}
                                                        getOptionLabel={(option) => option?.enterpriseName}
                                                        value={
                                                            item?.sourceEnterprise
                                                                ? enterpriseData?.find(
                                                                    (option) =>
                                                                        option?._id === item?.sourceEnterprise
                                                                ) || null
                                                                : null
                                                        }
                                                        onChange={(e, newValue) => {
                                                            handleChangeMultiEnterprise(newValue, "sourceEnterprise", e, index);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Enterprise"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="editAllowed"
                                                    name="editAllowed"
                                                    checked={item?.editAllowed || false}
                                                    onChange={(e, newValue) => {
                                                        handleChangeMultiEnterprise(newValue, "editAllowed", e, index);
                                                    }}
                                                    style={{
                                                        padding: "0px !important",
                                                        height: "54px",
                                                        margin: "0px 19px"
                                                    }}
                                                />
                                            }
                                            label="Edit Allowed"
                                        />
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={deleteExistingEnterprise ? "long-menu" : undefined}
                                            aria-expanded={deleteExistingEnterprise ? "true" : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, index)}
                                            size="small"
                                        >
                                            <MdMoreVert />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                "aria-labelledby": "long-button",
                                            }}
                                            anchorEl={anchorEl}
                                            open={deleteExistingEnterprise}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleremove}>Delete</MenuItem>
                                        </Menu>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    })}
                </Container>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: "30px",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        // disabled={isView}
                        onClick={handleCancel}
                        title="Cancel"
                        style={{
                            padding: "6px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            marginRight: "10px",
                            fontFamily: "'Poppins', sans-serif",
                            textTransform: "capitalize"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // disabled={isView}
                        onClick={handleSubmit}
                        title="Create Profile"
                        style={{
                            padding: "7px 15px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            fontFamily: "'Poppins', sans-serif",
                            textTransform: "capitalize"
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CreateMultiEnterpriseUserList
