import { Autocomplete, Box, Button, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, styled, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { CommonAutoComplete } from '../commonComponent/commonSelect/CommonSelect'
import { CommonNumberInput } from '../commonComponent/input/CommonNumberInput'
import { MdMoreVert } from 'react-icons/md'

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    }
}));

const PrescriptiveTab = ({
    formValues,
    selectedProfile,
    handleChangeProfile,
    isView,
    profileMeasure,
    handleAddOptimizationMeasure,
    optimizationMeasure,
    handleChangeOptimizationMeasure,
    anchorOptimizationMeasureEl,
    setAnchorOptimizationMeasureEl,
    deleteOptimization,
    handleClick,
    handleremove,
    deleteConstraintsMeasure,
    setAnchorConstraintsMeasureEl,
    prescriptiveconstraintsMeasure,
    anchorConstraintsMeasureEl,
    handleAddConstraintsMeasure,
    setPrescriptiveConstraintsMeasure,
    prescriptiveOptimal,
    anchorPrescriptiveEnsembleEl,
    setAnchorPrescriptiveEnsembleEl,
    handleChangePrescriptive,
    deletePrescriptiveEnsemble,
    handleAddPrescriptive
}) => {
    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Prescriptive Forecast Optimization: Basic Settings
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={2.4}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Prescriptive Optimal Forecast Measure"
                                name="prescriptiveOptimalForecastMeasureUID"
                                value={formValues?.prescriptiveOptimalForecastMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Prescriptive Optimal Forecast Discrepancy Rank Measure"
                                name="prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID"
                                value={formValues?.prescriptiveOptimalForecastOverallDiscrepancyRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Prescriptive Optimal Forecast Quality Rank Measure"
                                name="prescriptiveOptimalForecastOverallQualityRankMeasureUID"
                                value={formValues?.prescriptiveOptimalForecastOverallQualityRankMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                        <CommonNumberInput
                            name="maxRuntime"
                            label="Max Runtime (minutes)"
                            value={formValues?.maxRuntime}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter max run time"
                            disabled={isView}
                        />
                    </Grid>
                    <Grid item xs={2.4} sx={{

                        '& .MuiFormControlLabel-root': {
                            marginTop: '11px',
                            gap: '10px',
                            paddingLeft: "20px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="explainResults"
                                    name="explainResults"
                                    checked={formValues?.explainResults || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "explainResults", e);
                                    }}
                                />
                            }
                            label="Explain Results"
                        />
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Prescriptive Forecast Optimization: Objectives
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddOptimizationMeasure}>
                    +
                </Button>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>

                    {optimizationMeasure?.length > 0 && optimizationMeasure?.map((item, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="objective" shrink>Optimization Objectives</InputLabel>
                                        <Select
                                            labelId="objective"
                                            name="optimizationObjective"
                                            id="demo-simple-select-readonly"
                                            value={item?.optimizationObjective || ""}
                                            fullWidth
                                            onChange={(e, newValue) => {
                                                handleChangeOptimizationMeasure(index,
                                                    newValue,
                                                    "optimizationObjective",
                                                    e);
                                            }}
                                            label="Error"
                                            variant="outlined"
                                        >
                                            <MenuItem value="Volume">Volume</MenuItem>
                                            <MenuItem value="Revenue">Revenue</MenuItem>
                                            <MenuItem value="Margin">Margin</MenuItem>
                                            <MenuItem value="Cash Flow">Cash Flow</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sx={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Objective Measure"
                                            name="objectiveMeasure"
                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                            getOptionLabel={(option) => option?.measureName}
                                            value={
                                                item?.objectiveMeasure
                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                        (row) =>
                                                            row?._id ==
                                                            item?.objectiveMeasure
                                                    ) || null : [])
                                                    : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleChangeOptimizationMeasure(index,
                                                    newValue,
                                                    "objectiveMeasure",
                                                    e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Objective Measure"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sx={{ width: "100%", flex: 1, display: "flex", alignItems: "center" }}>
                                    <TextField
                                        type="number"
                                        id="weight"
                                        name="weight"
                                        variant="outlined"
                                        fullWidth
                                        label="Objective Weight"
                                        value={item?.weight || ""}
                                        onChange={(e, newValue) => {
                                            handleChangeOptimizationMeasure(index,
                                                newValue,
                                                "weight",
                                                e);
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Enter weight"
                                        // onBlur={validateOne}
                                        disabled={isView}
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === "Backspace" ||
                                                (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                                                (e.key !== "-" &&
                                                    e.key !== "e" &&
                                                    e.keyCode >= 47 &&
                                                    e.keyCode <= 58)
                                            ) {
                                            } else {
                                                e.preventDefault();
                                            }
                                        }}
                                        onFocus={(e) =>
                                            e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                    e.preventDefault();
                                                },
                                                { passive: false }
                                            )
                                        }
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deleteOptimization ? "long-menu" : undefined}
                                        aria-expanded={deleteOptimization ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "optimizationObjective")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorOptimizationMeasureEl}
                                        open={deleteOptimization}
                                        onClose={() => { setAnchorOptimizationMeasureEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("optimizationObjective") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })
                    }
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Prescriptive Forecast Optimization: Decisions
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddPrescriptive}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {prescriptiveOptimal?.length > 0 && prescriptiveOptimal?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={4} sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Desisions"
                                        name="prescriptiveOptimalForecastDecisions"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            item?.prescriptiveOptimalForecastDecisions
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        item?.prescriptiveOptimalForecastDecisions
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangePrescriptive(index, newValue, "prescriptiveOptimalForecastDecisions", e)
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Desisions"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deletePrescriptiveEnsemble ? "long-menu" : undefined}
                                        aria-expanded={deletePrescriptiveEnsemble ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "prescriptiveOptimal")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorPrescriptiveEnsembleEl}
                                        open={deletePrescriptiveEnsemble}
                                        onClose={() => { setAnchorPrescriptiveEnsembleEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("prescriptiveOptimal") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Prescriptive Forecast Optimization: Constraints
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddConstraintsMeasure}>
                    +
                </Button>
            </Box>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {prescriptiveconstraintsMeasure?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={4} sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Constraint Measure"
                                        name="constraintsMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            item?.constraintsMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        item?.constraintsMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            const updatedFormValues = [...prescriptiveconstraintsMeasure];
                                            updatedFormValues[index]["constraintsMeasure"] = newValue?._id;
                                            setPrescriptiveConstraintsMeasure(updatedFormValues);
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Constraint Measure"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deleteConstraintsMeasure ? "long-menu" : undefined}
                                        aria-expanded={deleteConstraintsMeasure ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "prescriptiveConstraints")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorConstraintsMeasureEl}
                                        open={deleteConstraintsMeasure}
                                        onClose={() => { setAnchorConstraintsMeasureEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("prescriptiveConstraints") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Prescriptive Forecast Optimization: Basic KPI Measures
            </Typography>{" "}

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {/* error */}
                    {formValues?.isCheckedbasicKPIError && (
                        <>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="error"
                                        name="error"
                                        variant="outlined"
                                        fullWidth
                                        label="Error"
                                        value={"Error"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Error"
                                        name="prescriptiveOptimalForecastErrorMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastErrorMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastErrorMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastErrorMeasure",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Error"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Moving Average Error"
                                        name="prescriptiveOptimalForecastMovingAverageErrorMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastMovingAverageErrorMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastMovingAverageErrorMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastMovingAverageErrorMeasure",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Moving Average Error"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Relative Error"
                                        name="prescriptiveOptimalForecastRelativeErrorMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastRelativeErrorMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastRelativeErrorMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastRelativeErrorMeasure",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Relative Error"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Error Rank"
                                        name="prescriptiveOptimalForecastErrorRankMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastErrorRankMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastErrorRankMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastErrorRankMeasure",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Error Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Accuracy Rank"
                                        name="prescriptiveOptimalForecastAccuracyRankMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastAccuracyRankMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastAccuracyRankMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastAccuracyRankMeasure",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Accuracy Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Error FVA"
                                        name="prescriptiveOptimalForecastErrorFVAMeasure"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastErrorFVAMeasure
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastErrorFVAMeasure
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastErrorFVAMeasure",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Error FVA"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {/* bias */}
                    {formValues?.isCheckedbasicKPIBias && (
                        <>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Bias"
                                        name="Bias"
                                        variant="outlined"
                                        fullWidth
                                        label="Bias"
                                        value={"Bias"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Bias"
                                        name="prescriptiveOptimalForecastBiasMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastBiasMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastBiasMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastBiasMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Bias"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Moving Average Bias"
                                        name="prescriptiveOptimalForecastMovingAverageBiasMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastMovingAverageBiasMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastMovingAverageBiasMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastMovingAverageBiasMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Moving Average Bias"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Relative Bias"
                                        name="prescriptiveOptimalForecastRelativeBiasMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastRelativeBiasMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastRelativeBiasMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastRelativeBiasMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Relative Bias"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Bias Rank"
                                        name="prescriptiveOptimalForecastBiasRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastBiasRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastBiasRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastBiasRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Bias Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Neutrality Rank"
                                        name="prescriptiveOptimalForecastNeutralityRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastNeutralityRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastNeutralityRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastNeutralityRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Neutrality Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Bias FVA"
                                        name="prescriptiveOptimalForecastBiasFVAMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastBiasFVAMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastBiasFVAMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastBiasFVAMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Bias FVA"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {/* churn */}
                    {formValues?.isCheckedbasicKPIChurn && (
                        <>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Churn"
                                        name="Churn"
                                        variant="outlined"
                                        fullWidth
                                        label="Churn"
                                        value={"Churn"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Churn"
                                        name="prescriptiveOptimalForecastChurnMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastChurnMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastChurnMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastChurnMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Churn"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Moving Average Churn"
                                        name="prescriptiveOptimalForecastMovingAverageChurnMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastMovingAverageChurnMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastMovingAverageChurnMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastMovingAverageChurnMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Moving Average Churn"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Relative Churn"
                                        name="prescriptiveOptimalForecastRelativeChurnMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastRelativeChurnMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastRelativeChurnMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastRelativeChurnMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Relative Churn"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Churn Rank"
                                        name="prescriptiveOptimalForecastChurnRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastChurnRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastChurnRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastChurnRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Churn Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast  Stability Rank"
                                        name="prescriptiveOptimalForecastStabilityRankMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastStabilityRankMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastStabilityRankMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastStabilityRankMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast  Stability Rank"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Prescriptive Optimal Forecast Churn FVA"
                                        name="prescriptiveOptimalForecastChurnFVAMeasureUID"
                                        options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                        getOptionLabel={(option) => option?.measureName}
                                        value={
                                            formValues?.prescriptiveOptimalForecastChurnFVAMeasureUID
                                                ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                    (row) =>
                                                        row?._id ==
                                                        formValues?.prescriptiveOptimalForecastChurnFVAMeasureUID
                                                ) || null : [])
                                                : null
                                        }
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(
                                                newValue,
                                                "prescriptiveOptimalForecastChurnFVAMeasureUID",
                                                e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prescriptive Optimal Forecast Churn FVA"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Container>
        </>
    )
}

export default PrescriptiveTab
