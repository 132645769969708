import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material'
import React from 'react'

export const EnterpriseModel = ({ enterpriseModel, handleDefaultEnterprise, userProfile, defaultEnterprise, setDefaultEnterprise, handleSubmit }) => {
    return (
        <Dialog open={enterpriseModel} onClose={handleDefaultEnterprise}>
            <DialogTitle>Default Enterprise</DialogTitle>
            <DialogContent sx={{ width: "432px" }}>
                <FormControl fullWidth style={{ paddingBottom: "25px", marginTop: "13px" }}>
                    <Autocomplete
                        id="demo-simple-select-readonly"
                        labelId="enterprise"
                        name="enterpriseUID"
                        fullWidth
                        options={userProfile?.data?.multiEnterprise?.length > 0 ? userProfile?.data?.multiEnterprise : []}
                        getOptionLabel={(option) => option.enterpriseName}
                        value={defaultEnterprise?.enterpriseUID ?
                            userProfile?.data?.multiEnterprise?.find
                                ((item) => item?.enterpriseUID === defaultEnterprise?.enterpriseUID)
                            || null : null}
                        onChange={(event, newValue) => {
                            setDefaultEnterprise({
                                ...defaultEnterprise,
                                enterpriseUID: newValue?.enterpriseUID
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Enterprise"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </FormControl>

                <DialogActions>
                    <Button onClick={handleDefaultEnterprise} sx={{ textTransform: "capitalize" }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} sx={{ textTransform: "capitalize" }}>
                        Submit
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
