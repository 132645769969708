import React, { useContext, useEffect, useState } from "react";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import "../../style/common.css";
import { useDispatch } from "react-redux";
import { ThemeContext } from "../../ThemeContext";
import * as XLSX from "xlsx";
import CreateUserList from "./CreateUserList";
import { getUserList } from "../../store/ApiSlice/userSlice";
import UsermaterialList from "./UsermaterialList";
import DeleteUserList from "./DeleteuserList";

const UserList = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [isCreate, setIsCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [uploadUserFile, setUploadUserFile] = useState(false);
  const [updatedId, setUpdatedId] = useState(null)
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserList())
  }, []);

  const handleCreateUpdate = (edit, id) => {
    setUpdatedId(id);
    setIsCreate(true)
    setIsEdit(edit);
  }
  const handleTableRowSelect = (event, row) => {
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    setSelectedRows(updatedIds);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileInput = document.getElementById("file-input");
    const file1 = fileInput.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
    };
  };

  const handleCloseUploadFile = () => {
    setUploadUserFile(false)
  }

  return (
    <>
      {!isCreate && (
        <>
          {/* <Box sx={{ display: "flex" }}>
            <MiniDrawer />
            <Box component="main" sx={{
              flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
              background: isDarkTheme ? "whitesmoke" : "white",
            }}> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "20px",
                  position: "relative",
                  overflow: "hidden"
                }}
              >
                <h3 className="heading"> Users</h3>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Button
                    onClick={() => {
                      handleCreateUpdate(false)
                    }}
                    className="awesome-button"
                    sx={{ color: "white", border: "1px solid", textTransform: "capitalize", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Add User Manually
                  </Button>
                  <Button
                    onClick={() => {
                      setUploadUserFile(true)
                    }}
                    className="awesome-button"
                    sx={{ color: "white", border: "1px solid", textTransform: "capitalize", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Upload From File
                  </Button>
                  <DeleteUserList
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setModalDeleteOpen={setModalDeleteOpen}
                    isModalDeleteOpen={isModalDeleteOpen}
                    updatedId={updatedId}
                  />
                </div>
              </div>
              <UsermaterialList
                handleTableRowSelect={handleTableRowSelect}
                setUpdatedId={setUpdatedId}
                handleCreateUpdate={handleCreateUpdate}
                setModalDeleteOpen={setModalDeleteOpen}
              />
            {/* </Box>
          </Box> */}
        </>
      )}
      {isCreate && (
        <CreateUserList
          setIsCreate={setIsCreate}
          isEdit={isEdit}
          selectedRows={updatedId}
        />
      )}

      {uploadUserFile && (
        <Dialog open={uploadUserFile} onClose={handleCloseUploadFile} sx={{ padding: "20px" }}>
          <DialogTitle>Upload User File</DialogTitle>
          <DialogContent>
            <input
              id="file-input"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
            />
          </DialogContent>
          <DialogActions sx={{ gap: "10px" }}>
            <Button
              onClick={handleCloseUploadFile}
              sx={{
                color: "#8280bd",
                margin: "0 4px",
                border: "1px solid #8280bd",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize"
              }}
            >
              Cancel
            </Button>
            <Button
              // onClick={handleCloseModals}
              // onClick={handleUploadFile}
              variant="contained"
              autoFocus
              className="awesome-button"
              sx={{ textTransform: "capitalize" }}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserList;
