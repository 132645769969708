import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { registerAllModules } from "handsontable/registry";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Box, Tooltip } from "@mui/material";
import CreateEnterprise from "./CreateEnterprise";
import DeleteEnterprise from "./DeleteEnterprise";
import EnterpriseMaterialList from "./EnterpriseMaterialList";
import "react-toastify/dist/ReactToastify.css";
import "../../style/common.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteEnterprise, getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import DeleteModal from "../commonComponent/DeleteComponent/DeleteModal";
import { ThemeContext } from "../../ThemeContext";
import { FiPlus } from "react-icons/fi";


const EnterpriseList = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const { enterpriseData } = useSelector((state) => state?.enterprise)
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [enterpriseName, setEnterpriseName] = React.useState("");
  const [attriId, setAttriId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const dispatch = useDispatch();
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const handleEnterpriseieldChange = (event) => {
    setEnterpriseName(event.target.value);
  };

  React.useEffect(() => {
    dispatch(getEnterpriseList())
    registerAllModules();
  }, []);


  const handleTableRowSelect = (event, row) => {
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    const selectedRow = enterpriseData?.find((row) => row._id === row?.original?._id);
    setSelectedRows(updatedIds);
  };

  const handleCreateUpdate = async (edit, id) => {
    setUpdatedId(id);
    setIsCreateUpdateModalOpen(true);
    setIsEdit(edit);
    await dispatch(getEnterpriseList());
  };


  return (
    <>
      {/* <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box component="main" sx={{
          flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
          background: isDarkTheme ? "whitesmoke" : "white",
        }}> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        <h3 className="heading">Enterprise</h3>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div className="relative group">
            <button
              onClick={() => handleCreateUpdate(false)}
              className="font-semibold rounded-full text-sm p-2 flex items-center gap-2 shadow-md hover:shadow-lg transition-all duration-300 transform active:scale-95"
              type="button"
              style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
            >
              <FiPlus className="w-5 h-5 text-black" />
            </button>
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-max bg-gray-800 text-white text-[10px] font-normal py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition">
              Add Enterprise
            </div>
          </div>

          {isCreateUpdateModalOpen && (
            <CreateEnterprise
              isCreateUpdateModalOpen={isCreateUpdateModalOpen}
              setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
              isEdit={isEdit}
              selectedRows={selectedRows}
              dimensionAttributes={[]}
              setDimensionAttributes={() => { }}
              handleEnterpriseieldChange={handleEnterpriseieldChange}
              enterpriseNameVal={enterpriseName}
              updatedId={updatedId}
            />
          )}
          <DeleteModal
            isModalOpenDelete={isModalOpenDelete}
            setModalOpenDelete={setModalOpenDelete}
            attriId={attriId}
            setSelectedRows={setSelectedRows}
            title="Enterprise"
            deleteAPI={deleteEnterprise}
            getAPI={getEnterpriseList}
          />
        </Box>
      </div>
      <EnterpriseMaterialList
        data={enterpriseData}
        handleCreateUpdate={handleCreateUpdate}
        handleTableRowSelect={handleTableRowSelect}
        setModalOpenDelete={setModalOpenDelete}
        setAttriId={setAttriId}
      />
      {/* </Box>
      </Box> */}
    </>
  );
};

export default EnterpriseList;
