import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { FormControl, Grid } from "@mui/material";
import { createAttribute, getAttributeOrEnterPrise, getAttributes, getAttributesById } from "../../store/ApiSlice/attributeSlice";
import { useDispatch } from "react-redux";
import { getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import { useSelector } from "react-redux";
import { getEnterModelList } from "../../store/ApiSlice/modelSlice";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";

const CreateAttribute = ({
  editRow,
  setEditRowData,
  isCreateUpdateModalOpen,
  setIsCreateUpdateModalOpen,
  isEdit,
  isCreate,
  newData,
  model,
  selectedDimension,
  setDimensionAttributes,
  dimensionAttributes, updatedId,
  dataValues,
  formValueData,
  formData,
  type,
  modelEnterprise
}) => {
  let initialVal = { name: "", type: "", length: "", note: "", CustomAttribute: " ", AttributeUID: "" };
  const { enterpriseData } = useSelector((state) => state.enterprise)
  const { modalData } = useSelector((state) => state.modelList)
  const [validations, setValidations] = React.useState(initialVal);
  const [enterPrizeNameData, setEnterPrizename] = useState()
  const userData = JSON.parse(localStorage.getItem("accessToken"))
  const dispatch = useDispatch();
  const userId = localStorage.getItem("accessToken");

  useEffect(() => {
    if (formData) {
      const enterPrizename = formData?.filter((item) => item.entermodelUID.enterpriseUID === formValueData?.enterpriseName)
      setEnterPrizename(enterPrizename)
    }
  }, [formData])

  let initialFormValues = {
    name: isEdit && editRow?.name ? editRow?.name : "",
    type: isEdit && editRow?.type ? editRow?.type : "VARCHAR",
    length: isEdit && parseInt(editRow?.length) ? parseInt(editRow?.length) : "",
    notes: isEdit && editRow?.notes ? editRow?.notes : "",
  };

  const [formValues, setformValues] = useState(initialFormValues);

  const filteredData = modalData?.filter(
    (item) => item.enterpriseUID?._id === modelEnterprise.enterpriseUID
  );

  const validateAll = () => {
    const { name, type } = formValues;
    const validations = { name: "", type: "", length: "", note: "", CustomAttribute: " " };
    let isValid = true;
    if (!name) {
      validations.name = "Name is required";
      isValid = false;
    }
    if (!type) {
      validations.type = "Type is required";
      isValid = false;
    }
    setValidations(validations);
    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = formValues[name];
    let message = "";
    if (!value) {
      message = `${name} is required`;
    }
    setValidations({ ...validations, [name]: message });
  };

  const handleCloseModal = () => {
    setIsCreateUpdateModalOpen(false);
    setformValues(initialFormValues)
    // setEditRowData({})
  };
  const handleAttributeFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    if (fieldName === 'modelName') {
      const selectedData = filteredData.filter((data) => data.modalUID === value);
      setformValues({
        ...formValues,
        [fieldName]: selectedData[0]?.modelName,
        [`modelUID`]: value
      });
    } else if (fieldName === 'enterpriseName') {
      const selectedData = enterpriseData?.filter((data) => data._id === value);
      setformValues({
        ...formValues,
        [fieldName]: selectedData[0]?.enterpriseName || userData?.data?.enterprise?.enterpriseName,
        [`enterpriseUID`]: value
      });

    } else {
      setformValues({
        ...formValues,
        [fieldName]: value
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateAll();
    if (!isValid) {
      return false;
    }
    await saveNewAttribute();
  };

  const saveNewAttribute = async () => {
    // try {
    if (!formValues.name || !formValues.type) {
      return;
    }
    if (formValues.type === "VARCHAR" && !formValues.length) {
      formValues.length = 1;
    }
    const lengthNumber = (number) => {
      let numLength = Number(number)
      return numLength;
    }
    const updatedValue = {
      ...(formValues?.length && !isNaN(parseInt(formValues?.length)) && {
        length: lengthNumber(parseInt(formValues?.length))
      }),
      name: formValues?.name,
      notes: formValues?.notes,
      type: formValues?.type,
      enterpriseName: modelEnterprise?.enterpriseName,
      enterpriseUID: modelEnterprise?.enterpriseUID,
      modelName: modelEnterprise?.modelName,
      modelUID: modelEnterprise?.modelUID,

    };

    const result = await dispatch(createAttribute({ updatedValue, isEdit, userId, updatedId }))
    if (result?.meta?.requestStatus === 'fulfilled') {
      setDimensionAttributes([...dimensionAttributes, result?.payload?.data]);
      dispatch(getAttributeOrEnterPrise({ enterPriseId: modelEnterprise?.enterpriseUID, modalId: modelEnterprise?.modelUID }))
      handleCloseModal();
      setformValues(initialVal);
    } else if (result?.meta?.requestStatus === 'rejected') {
      toast.error(result?.error?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (formValues.type == "REFRENCE") {
      dispatch(getEnterpriseList());
    }
  }, []);


  const [attDarta, setData] = useState([])
  const getEnterprise = async (id) => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    let response = await axios.get(`${API_BASE_URL}/attributes/${updatedId}`, config);
    // setformValues({
    //   ...formValues,
    //   enterpriseUID: response?.data?.data?.modelUID?.enterpriseUID,
    //   modelUID: response?.data?.data?.modelUID
    //     ?.id
    // })
    setData(response?.data?.data);
  };
  useEffect(() => {
    if (updatedId) {
      getEnterprise()
    }
  }, [updatedId])

  useEffect(() => {
    if (editRow) {
      setformValues({
        ...formValues,
        enterpriseUID: editRow?.enterpriseUID?._id,
        modelUID: editRow?.modelUID && editRow?.modelUID?.id
      });
    }
  }, [editRow]);


  useEffect(() => {
    // getEnterpriseList();
    dispatch(getEnterpriseList())
    dispatch(getEnterModelList())
  }, []);

  useEffect(() => {
    if (modelEnterprise?.enterpriseUID) {
      dispatch(getEnterModelList())
    }
  }, [modelEnterprise?.enterpriseUID]);

  const renderModalContent = () => {
    return (
      <>
        {isEdit ?
          <>
            <DialogTitle sx={{ fontSize: "16px" }}>Edit Attribute</DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important", width: "500px" }}>
              <Grid container spacing={2} sx={{ paddingBottom: "20px" }}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Attribute Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formValues?.name}
                    onChange={handleAttributeFieldChange("name")}
                    placeholder="Attribute Name"
                    onBlur={validateOne}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Attribute Type</InputLabel>
                    <Select
                      id="type"
                      label="Attribute Type"
                      name="type"
                      value={formValues?.type}
                      onChange={handleAttributeFieldChange("type")}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      onBlur={validateOne}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      <MenuItem value="VARCHAR">VARCHAR</MenuItem>
                      <MenuItem value="INTEGER">INTEGER</MenuItem>
                      <MenuItem value="DECIMAL">DECIMAL</MenuItem>
                      <MenuItem value="TIMESTAMP">TIMESTAMP</MenuItem>
                      <MenuItem value="BOOLEAN">BOOLEAN</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formValues?.type === "VARCHAR" && (
                  <Grid item xs={6}>
                    <TextField
                      id="length"
                      name="length"
                      label="Length"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={parseInt(formValues?.length)}
                      placeholder="Attribute Length"
                      onBlur={validateOne}
                      inputProps={{ inputMode: "numeric", min: 1, max: 999 }}
                      onChange={handleAttributeFieldChange("length")}
                    />
                  </Grid>
                )}
              </Grid>
              {formValues?.type === "REFERNCE" && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="enterpriseType">Attribute List</InputLabel>
                    <Select
                      labelId="enterpriseType"
                      id="demo-simple-select-readonly"
                      value={formValues?.attributeList}
                      fullWidth
                      onChange={handleAttributeFieldChange("attributeList")}
                      label="Enterprise Type"
                      variant="outlined"
                      disabled={isEdit ? true : false}
                    >
                      {enterpriseData?.map((data1) => (
                        <MenuItem key={data1?._id} value={data1?.enterpriseName}>
                          {data1?.enterpriseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="notes"
                    name="note"
                    label="Attribute Notes"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    col={6}
                    value={formValues.notes}
                    onChange={handleAttributeFieldChange("notes")}
                    placeholder="Attribute Notes"
                    onBlur={validateOne}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary" sx={{ textTransform: "capitalize" }}>
                Save
              </Button>
            </DialogActions>
          </>
          : <>
            <DialogTitle sx={{ fontSize: "16px" }}>New Attribute</DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important", width: "500px" }}>
              <Grid container spacing={2} sx={{ paddingBottom: "20px" }}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Attribute Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formValues.name}
                    onChange={handleAttributeFieldChange("name")}
                    placeholder="Attribute Name"
                    onBlur={validateOne}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Attribute Type</InputLabel>
                    <Select
                      id="type"
                      label="Attribute Type"
                      name="type"
                      value={formValues.type}
                      onChange={handleAttributeFieldChange("type")}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      onBlur={validateOne}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      <MenuItem value="VARCHAR">VARCHAR</MenuItem>
                      <MenuItem value="INTEGER">INTEGER</MenuItem>
                      <MenuItem value="DECIMAL">DECIMAL</MenuItem>
                      <MenuItem value="TIMESTAMP">TIMESTAMP</MenuItem>
                      <MenuItem value="BOOLEAN">BOOLEAN</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formValues.type === "VARCHAR" && (
                  <Grid item xs={6}>
                    <TextField
                      id="length"
                      name="length"
                      label="Length"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={parseInt(formValues?.length)}
                      placeholder="Attribute Length"
                      onBlur={validateOne}
                      inputProps={{ inputMode: "numeric", min: 1, max: 999 }}
                      onChange={handleAttributeFieldChange("length")}
                    />
                  </Grid>
                )}
              </Grid>
              {/* {formValues.type === "REFERNCE" && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="enterpriseType">Attribute List</InputLabel>
                    <Select
                      labelId="enterpriseType"
                      id="demo-simple-select-readonly"
                      value={formValues.attributeList}
                      fullWidth
                      onChange={handleAttributeFieldChange("attributeList")}
                      label="Enterprise Type"
                      variant="outlined"
                      disabled={isEdit ? true : false}
                    >
                      {enterpriseData?.map((data1) => (
                        <MenuItem key={data1?._id} value={data1?.enterpriseName}>
                          {data1?.enterpriseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )} */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="notes"
                    name="note"
                    label="Attribute Notes"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    col={6}
                    value={formValues.notes}
                    onChange={handleAttributeFieldChange("notes")}
                    placeholder="Attribute Notes"
                    onBlur={validateOne}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary" sx={{ textTransform: "capitalize" }}>
                Save
              </Button>
            </DialogActions>
          </>}

      </>
    );
  };

  return (
    <Dialog open={isCreateUpdateModalOpen} onClose={handleCloseModal}>
      {renderModalContent()}
    </Dialog>
  );
};

export default CreateAttribute;
