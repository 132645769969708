import { Autocomplete, TextField } from '@mui/material';
import React from 'react'

export const CommonSelect = ({
    labelId,
    name,
    options,
    getOptionLabel,
    value,
    setSelectedOptions,
    renderInput
}) => {
    return (
        <>
            <Autocomplete
                id="demo-simple-select-readonly"
                labelId={labelId}
                name={name}
                options={options}
                getOptionLabel={(option) => option.name}
                value={value ?
                    options?.find(option => option?._id === value)
                    || null : null}
                onChange={(e, newValue) => {
                    setSelectedOptions(prevState => ({
                        ...prevState,
                        [name]: newValue?._id
                    }));
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={labelId}
                        variant="outlined"
                        fullWidth
                    />
                )}
            />
        </>
    )
}

export const CommonAutoComplete = ({
    label,
    name,
    value,
    profileMeasure,
    index,
    handleChangeProfile
}) => {

    return (
        <>
            <Autocomplete
                id={`autocomplete-${name}`}
                labelId={label}
                name={name}
                options={Array.isArray(profileMeasure) ? profileMeasure : []}
                getOptionLabel={(option) => option?.measureName || ''}
                value={
                    value
                        ? profileMeasure?.find(
                            (item) => item?._id === value
                        ) || null
                        : null
                }
                onChange={(e, newValue) => handleChangeProfile(newValue, name, e)}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        fullWidth
                    />
                )}
            />
        </>
    )
}
