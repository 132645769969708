import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { registerAllModules } from "handsontable/registry";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Box, Tooltip } from "@mui/material";
import CreateEnterprise from "./CreateEnterprise";
import DeleteEnterprise from "./DeleteEnterprise";
import EnterpriseMaterialList from "./EnterpriseMaterialList";
import "react-toastify/dist/ReactToastify.css";
import "../../style/common.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteEnterprise, getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import DeleteModal from "../commonComponent/DeleteComponent/DeleteModal";
import { ThemeContext } from "../../ThemeContext";



const EnterpriseList = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const { enterpriseData } = useSelector((state) => state?.enterprise)
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [enterpriseName, setEnterpriseName] = React.useState("");
  const [attriId, setAttriId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const dispatch = useDispatch();
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const handleEnterpriseieldChange = (event) => {
    setEnterpriseName(event.target.value);
  };

  React.useEffect(() => {
    dispatch(getEnterpriseList())
    registerAllModules();
  }, []);


  const handleTableRowSelect = (event, row) => {
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    const selectedRow = enterpriseData?.find((row) => row._id === row?.original?._id);
    setSelectedRows(updatedIds);
  };

  const handleCreateUpdate = async (edit, id) => {
    setUpdatedId(id);
    setIsCreateUpdateModalOpen(true);
    setIsEdit(edit);
    await dispatch(getEnterpriseList());
  };


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box component="main" sx={{
          flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
          background: isDarkTheme ? "whitesmoke" : "white",
        }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "60px",
              paddingBottom: "20px",
            }}
          >
            <h3 className="heading">Enterprise</h3>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Add Enterprise">
                <Button
                  onClick={() => {
                    handleCreateUpdate(false);
                  }}
                  className="awesome-button"
                  sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                >
                  +
                </Button>
              </Tooltip>

              {isCreateUpdateModalOpen && (
                <CreateEnterprise
                  isCreateUpdateModalOpen={isCreateUpdateModalOpen}
                  setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
                  isEdit={isEdit}
                  selectedRows={selectedRows}
                  dimensionAttributes={[]}
                  setDimensionAttributes={() => { }}
                  handleEnterpriseieldChange={handleEnterpriseieldChange}
                  enterpriseNameVal={enterpriseName}
                  updatedId={updatedId}
                />
              )}
              <DeleteModal
                isModalOpenDelete={isModalOpenDelete}
                setModalOpenDelete={setModalOpenDelete}
                attriId={attriId}
                setSelectedRows={setSelectedRows}
                title="Enterprise"
                deleteAPI={deleteEnterprise}
                getAPI={getEnterpriseList}
              />
            </Box>
          </div>
          <EnterpriseMaterialList
            data={enterpriseData}
            handleCreateUpdate={handleCreateUpdate}
            handleTableRowSelect={handleTableRowSelect}
            setModalOpenDelete={setModalOpenDelete}
            setAttriId={setAttriId}
          />
        </Box>
      </Box>
    </>
  );
};

export default EnterpriseList;
