// dimensionSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';

export const getModelListAsync = createAsyncThunk(
  'dimension/getModelList',
  async () => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

    const response = await axios.get(`${API_BASE_URL}/dimensions`, config);
    return response.data;
  }
);

export const getLagIdList = createAsyncThunk(
  'dimension/getLagIdList',
  async ({ canvasFindData }) => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

    const response = await axios.get(`${API_BASE_URL}/dimensions/lagIDsList/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
    return response.data;
  }
);

export const getPlanningObjects = createAsyncThunk(
  'dimension/getPlanningObjects',
  async ({ page, limit, id }) => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

    const response = await axios.get(`${API_BASE_URL}/planning-object/getAll?page=${page}&limit=${limit}&dimensionUID=${id}`, config);
    return response.data;
  }
);

export const fetchDimensions = createAsyncThunk(
  'dimension/fetchDimensions',
  async ({ enterpriseId, modelId }) => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };
    try {
      const response = await axios.get(`${API_BASE_URL}/dimensions/withinEnterprise/${enterpriseId}/${modelId}`, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error?.response?.data?.message);
    }
  }
);

export const getAllPlanningObject = createAsyncThunk(
  'dimension/getAllPlanningObject',
  async ({ payload }) => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };
    try {
      const response = await axios.post(`${API_BASE_URL}/planning-object/getPlanningRelatedData`, payload, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error?.response?.data?.message);
    }
  }
)

export const createPlanningObject = createAsyncThunk(
  'dimension/createPlanningObject',
  async ({ payload, isEditPlanningObject, planningObjectID }) => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };
    try {
      let response
      if (!isEditPlanningObject) {
        response = await axios.post(`${API_BASE_URL}/planning-object/upload`, payload, config);
      } else {
        response = await axios.put(`${API_BASE_URL}/planning-object/update`, payload, config);
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error?.response?.data?.message);
    }
  }
);

export const createUpdateDimensionAsync = createAsyncThunk(
  'dimension/createOrUpdate',
  async ({ payload, isEdit, selectedRows, userId }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    try {
      let result;
      if (!isEdit) {
        const baseURLCreate = `${API_BASE_URL}/dimensions`;
        result = await axios.post(baseURLCreate, payload, config);
      } else {
        const baseURLUpdate = `${API_BASE_URL}/dimensions/${selectedRows}`;
        result = await axios.put(baseURLUpdate, payload, config);
      }
      return result.data;
    } catch (error) {
      return Promise.reject(error?.response?.data?.error);
    }
  }
);

export const deleteDimanationList = createAsyncThunk('dimension/deletemodel',
  async ({ attriId, config }) => {
    try {
      const responce = await axios.delete(`${API_BASE_URL}/dimensions/${attriId}`, config)
      return responce
    } catch (error) {
      return Promise.reject(error?.response?.data?.error);
    }
  }
);

export const deletePlanningObject = createAsyncThunk('dimension/deletePlanningObject',
  async ({ payload }) => {
    const userId = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` }, data: payload };
    try {
      const responce = await axios.delete(`${API_BASE_URL}/planning-object/delete`, config)
      return responce
    } catch (error) {
      return Promise.reject(error?.response?.data?.error);
    }
  }
);

const dimensionSlice = createSlice({
  name: 'dimension',
  initialState: {
    loading: false,
    error: null,
    success: false,
    dimensionData: [],
    allDimensions: [],
    dimensionLagIDdata: [],
    planningObjectsData: [],
    planningObjectMasterData: {},
    totalPlanningObject: 0
  },
  reducers: {
    setAllDimensions(state, action) {
      state.allDimensions = [...action.payload]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUpdateDimensionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createUpdateDimensionAsync.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(createUpdateDimensionAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getModelListAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getModelListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.dimensionData = action.payload.data;
      })
      .addCase(getModelListAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(getAllPlanningObject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllPlanningObject.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.planningObjectMasterData = action.payload.data;
      })
      .addCase(getAllPlanningObject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(getPlanningObjects.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getPlanningObjects.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.planningObjectsData = action.payload.data;
        state.totalPlanningObject = action.payload.pagination.total;
      })
      .addCase(getPlanningObjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(getLagIdList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getLagIdList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.dimensionLagIDdata = action.payload.data;
      })
      .addCase(getLagIdList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(fetchDimensions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchDimensions.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.allDimensions = action.payload.data;
      })
      .addCase(fetchDimensions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(createPlanningObject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createPlanningObject.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createPlanningObject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(deleteDimanationList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(deleteDimanationList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(deleteDimanationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(deletePlanningObject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(deletePlanningObject.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(deletePlanningObject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })
  },
});

export const { setAllDimensions } = dimensionSlice.actions
export default dimensionSlice.reducer;
