import React from "react";
import {  Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
const TimePeriodFilter = ({
    formValues,
    handleTimePeriod,
    timePeriod,
    handleLogicalOperator,
    logicalOperator,
    selectedMeasures,
    handleValidFrom,
    validFrom,
    handleValidTo,
    validTo,
    timeDimensionData,
    query,
    setQuery,
    filteredOptions,
    filteredValidToOptions,
}) => {
    return (
        <div className='border  mt-3 border-[#E9EAEB] rounded-xl p-4 my-4'>
            <h3 className="mb-2">Time Attributes</h3>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='font-semibold text-[14px]'> Time Period Type</TableCell>
                        <TableCell className='font-semibold text-[14px]'>Logical Operator</TableCell>
                        <TableCell className='font-semibold text-[14px]'>From</TableCell>
                        <TableCell className='font-semibold text-[14px]'>To</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableCell>
                        <select
                            id="timePeriod"
                            name="timePeriod"
                            value={timePeriod || ""}
                            // value={formValues?.timePeriod || ""}
                            fullWidth
                            onChange={handleTimePeriod}
                            variant="outlined"
                            placeholder="Select Time Period"
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed"
                        >
                            <option selected>Select Time Period Type</option>
                            {timeDimensionData?.timeSchemaFields?.length > 0 &&
                                timeDimensionData?.timeSchemaFields?.map(
                                    (item) =>
                                        item?.enabled === true && (
                                            // <MenuItem value={item?._id}>{item['Period Type']}</MenuItem>
                                            <option key={item?._id} value={item?._id}>
                                                {item['Period Type']}
                                            </option>
                                        )
                                )}
                        </select>
                    </TableCell>
                    <TableCell>
                        <select
                            id="logicalOperator"
                            name="logicalOperator"
                            // value={formValues?.logicalOperatorRows ||formValues?.logicalOperator|| ""}
                            value={logicalOperator || ""}
                            fullWidth
                            onChange={handleLogicalOperator}
                            variant="outlined"
                            placeholder="Select Operator"
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed"
                        >
                            <option selected>Select Operator</option>
                            <option value="In Between">In Between</option>
                        </select>
                    </TableCell>
                    <TableCell>
                        <input
                            type="date"
                            id="pageStartDate"
                            name="pageStartDate"
                            value={selectedMeasures?.pageStartDate}
                            onChange={handleValidFrom}
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            disabled={!timePeriod}
                        />
                    </TableCell>
                    <TableCell>
                        <input
                            type="date"
                            id="pageEndDate"
                            name="pageEndDate"
                            value={selectedMeasures?.pageEndDate}
                            onChange={handleValidTo}
                            min={selectedMeasures?.pageStartDate || ""}
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            disabled={!selectedMeasures?.pageStartDate}
                        />
                    </TableCell>
                </TableBody>
            </Table>
            {/* <div className='flex justify-between items-center'>
                <div className="grid grid-cols-2 gap-3">
                    <div>
                        <label
                            htmlFor="timePeriod"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Time Period Type
                        </label>
                        <select
                            id="timePeriod"
                            name="timePeriod"
                            value={timePeriod || ""}
                            // value={formValues?.timePeriod || ""}
                            fullWidth
                            onChange={handleTimePeriod}
                            variant="outlined"
                            placeholder="Select Time Period"
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed"
                        >
                            <option selected>Select Time Period Type</option>
                            {timeDimensionData?.timeSchemaFields?.length > 0 &&
                                timeDimensionData?.timeSchemaFields?.map(
                                    (item) =>
                                        item?.enabled === true && (
                                            // <MenuItem value={item?._id}>{item['Period Type']}</MenuItem>
                                            <option key={item?._id} value={item?._id}>
                                                {item['Period Type']}
                                            </option>
                                        )
                                )}
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="logicalOperator"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Logical Operator
                        </label>
                        <select
                            id="logicalOperator"
                            name="logicalOperator"
                            // value={formValues?.logicalOperatorRows ||formValues?.logicalOperator|| ""}
                            value={logicalOperator || ""}
                            fullWidth
                            onChange={handleLogicalOperator}
                            variant="outlined"
                            placeholder="Select Operator"
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed"
                        >
                            <option selected>Select Operator</option>
                            <option value="In Between">In Between</option>
                        </select>
                    </div>

                    <div className="relative">
                        <label
                            htmlFor="validFrom"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            From
                        </label>
                        <input
                            type="date"
                            id="pageStartDate"
                            name="pageStartDate"
                            value={selectedMeasures?.pageStartDate}
                            onChange={handleValidFrom}
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            disabled={!timePeriod}
                        />
                    </div>
                    <div className="relative">
                        <label
                            htmlFor="validTo"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            To
                        </label>
                        <input
                            type="date"
                            id="pageEndDate"
                            name="pageEndDate"
                            value={selectedMeasures?.pageEndDate}
                            onChange={handleValidTo}
                            min={selectedMeasures?.pageStartDate || ""}
                            className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                            disabled={!selectedMeasures?.pageStartDate}
                        />
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default TimePeriodFilter;
