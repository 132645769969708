import { Box, Button, Dialog, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle'
import CreateMeasure from './CreateMeasure';
import DeleteMeasure from './DeleteMeasure';
import MeasureMaterialList from './MeasureMaterialList';
import { useDispatch, useSelector } from 'react-redux';
import { createUpdateMeasureAsync, getMeasureList } from '../../store/ApiSlice/measureSlice';
import * as XLSX from "xlsx";
import { API_BASE_URL } from '../../helper/constants';
import axios from "axios";
import { toast } from "react-toastify";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Loader from '../loader/Loader';
import { ThemeContext } from '../../ThemeContext';

const MeasureList = ({ modelEnterprise }) => {
    const { isDarkTheme } = useContext(ThemeContext);
    const { measureData, totalCount, measurePaginationList } = useSelector((state) => state.measure)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [isView, setIsView] = useState(false);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [selectNewData, setSelectNewData] = useState(null)
    const [openModal, setOpenModal] = useState(false);
    const [loader, setLoader] = useState(false)
    const [openMeasureModal, setOpenMeasureModal] = useState(false)
    const [copyMeasure, setCopyMeasure] = useState({
        id: "",
        measureName: ""
    })
    const [selectRadioButton, setSelectRadioButton] = useState('insertUpdate')

    const dispatch = useDispatch();
    const selectedDimension = measureData?.find((item) => item?._id === copyMeasure?.id);

    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    };

    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    // useEffect(() => {
    //     dispatch(getMeasureList())
    // }, [])

    const handleOpenModal = (row) => {
        setSelectNewData(row)
        setOpenModal(true);
    };

    const handleCloseModals = () => {
        setOpenModal(false);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileInput = document.getElementById("file-input");
        const file1 = fileInput.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
        };
    };

    const handleUploadFile = async (selectedData) => {
        if (selectedData !== null) {
            try {
                setLoader(true)
                const fileInput = document.getElementById("file-input");
                const file = fileInput.files[0];
                // Read the content of the Excel file
                const reader = new FileReader();
                reader.onload = function (e) {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    const userId = localStorage.getItem("accessToken");
                    const config = {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
                        },
                    };
                    const updatedData = {
                        enterpriseUID: selectNewData?.original?.enterpriseUID,
                        modelUID: selectNewData?.original?.modelUID,
                        measureName: selectNewData?.original?.measureName,
                        baselevelUID: selectNewData?.original?.baselevelUID?._id,
                        measureUID: selectNewData?.original?._id,
                        dimensionName: selectNewData?.original?.baselevel,
                        documentsArray: jsonData,
                        isInsertUpdateOnly: selectRadioButton === "insertUpdate" ? true : false,
                        isDeleted: selectRadioButton === "delete" ? true : false
                    };
                    axios
                        .post(
                            `${API_BASE_URL}/measure/dataload/upload`,
                            updatedData, // Send the JSON data directly in the request body
                            config
                        )
                        .then((data) => {
                            if (data.status) {
                                if (data?.data?.data?.erroredItems?.length > 0) {
                                    downloadErrorExcel(data?.data?.data.erroredItems);
                                    setLoader(false)
                                } else {
                                    toast.success("data uploaded successfully");
                                    handleCloseModals();
                                    setLoader(false)
                                }
                            }
                            // Handle successful upload
                        })
                        .catch((error) => {
                            setLoader(false)
                            if (
                                error?.response &&
                                error?.response.data.message
                            ) {
                                toast.error(error.response.data.message);
                            } else {
                                toast.error("Failed to upload data. Please try again later.");
                            }
                            // handleCloseModals();
                        });
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                setLoader(false)
            }
        }
    };

    const downloadErrorExcel = (erroredItems) => {
        const transformedData = erroredItems.map(item => {
            const combined = { ...item.item };
            for (const [key, value] of Object.entries(item.errors)) {
                combined[`${key}`] = value;
            }
            return combined;
        });
        const worksheet = XLSX.utils.json_to_sheet(transformedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Errors");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'errored_items.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const handleDownload = async (row) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
            timeout: 60000,
        };
        const payload = {
            measureName: row?.original?.measureName,
            measureUID: row?.original?._id,
            baselevelUID: row?.original?.baselevelUID?._id,
            enterpriseUID: row?.original?.enterpriseUID,
            modelUID: row?.original?.modelUID
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/measure/dataload/download`, payload, config);

            if (!response?.data) {
                throw new Error("Empty response from server");
            }

            const headers = Object.keys(response.data?.schemaInfo)
                .filter((key) => key !== "_id" && key !== "__v")
                .sort((a, b) => response.data?.schemaInfo[a].order - response.data?.schemaInfo[b].order);

            const rows = response.data.data.map((obj) => {
                let temp = {};
                headers.forEach((key) => {
                    temp[key] = obj[key];
                });
                return Object.values(temp);
            });

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            XLSX.utils.book_append_sheet(wb, ws, "Measure");

            const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
            const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
            saveAs(blob, `vyan_${row?.original?.measureName}.xlsx`);

        } catch (error) {
            console.log("🚀 ~ handleDownload ~ error:", error)
        }
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    // Function to trigger file download
    const saveAs = (blob, filename) => {
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const handleCopyModel = (id) => {
        setIsModalOpen(true);
        setCopyMeasure({
            id: id
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCopyMeasure({ ...copyMeasure, [name]: value })
    }

    const createUpdateMeasure = async () => {
        const payload = {
            measureName: copyMeasure?.measureName,
            baselevel: selectedDimension?.baselevel,
            baselevelUID: selectedDimension?.baselevelUID?._id,
            enterpriseName: selectedDimension?.enterpriseName,
            enterpriseUID: selectedDimension?.enterpriseUID,
            modelName: selectedDimension?.modelName,
            modelUID: selectedDimension?.modelUID,
            fieldType: selectedDimension?.fieldType,
            type: selectedDimension?.type,
            aggregationMode: selectedDimension?.aggregationMode,
            disaggregationMode: selectedDimension?.disaggregationMode,
            editable: selectedDimension?.editable,
            notes: selectedDimension?.notes,
            hashTags: selectedDimension?.hashTags?.map((item) => item),
            calculatedField: selectedDimension?.calculatedField
        }
        const responce = await dispatch(createUpdateMeasureAsync({ payload, isEdit, selectedRows }))
        if (responce?.meta?.requestStatus === 'fulfilled') {
            // toast.success("Measure created successfully")
            setIsModalOpen(false);
            dispatch(getMeasureList())
            setCopyMeasure({
                id: "",
                measureName: ""
            })
        } else if (responce?.meta?.requestStatus === 'rejected') {
            toast.error(responce?.error?.message || "Registration Failed")
        }
    }

    return (
        <>
            {!isCreate && !isView && (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h3 className="heading">
                            Measures
                        </h3>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Dialog
                                open={openModal}
                                onClose={handleCloseModals}
                                sx={{ padding: "20px" }}
                            >
                                <DialogTitle>Upload File</DialogTitle>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        sx={{
                                            padding: "0 27px"
                                        }}
                                        value={selectRadioButton}
                                        onChange={(e) => {
                                            setSelectRadioButton(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel value="insertUpdate" control={<Radio />} label="Insert / Update" />
                                        <FormControlLabel value="delete" control={<Radio />} label="Delete" />
                                    </RadioGroup>
                                </FormControl>
                                <DialogContent>
                                    <input
                                        id="file-input"
                                        type="file"
                                        accept=".xlsx, .xls"
                                        onChange={handleFileChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleCloseModals}
                                        sx={{
                                            color: "#8280bd",
                                            margin: "0 4px",
                                            border: "1px solid #8280bd",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {loader ? <Loader /> : (
                                        <Button
                                            // onClick={handleCloseModals}
                                            onClick={handleUploadFile}
                                            variant="contained"
                                            autoFocus
                                            className="awesome-button"
                                            sx={{ textTransform: "capitalize" }}
                                        >
                                            Upload
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>
                            <Tooltip title="Add Measure">
                                <Button
                                    onClick={() => {
                                        handleCreateUpdate(false);
                                    }}
                                    className="awesome-button"
                                    sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                >
                                    +
                                </Button>
                            </Tooltip>

                            <DeleteMeasure
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                setModalDeleteOpen={setModalDeleteOpen}
                                isModalDeleteOpen={isModalDeleteOpen}
                                updatedId={updatedId}
                                modelEnterprise={modelEnterprise}
                            />
                        </div>
                    </div>

                    <MeasureMaterialList
                        modelEnterprise={modelEnterprise}
                        handleTableRowSelect={handleTableRowSelect}
                        setUpdatedId={setUpdatedId}
                        handleCreateUpdate={handleCreateUpdate}
                        setModalDeleteOpen={setModalDeleteOpen}
                        handleDownload={handleDownload}
                        handleOpenModal={handleOpenModal}
                        handleCopyModel={handleCopyModel}
                    />
                </>
            )}
            {
                (isCreate || isView) && (
                    <CreateMeasure
                        setIsCreate={setIsCreate}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        isCreate={isCreate}
                        isView={isView}
                        setIsView={setIsView}
                        selectedRows={updatedId}
                        setOpenMeasureModal={setOpenMeasureModal}
                        openMeasureModal={openMeasureModal}
                        modelEnterprise={modelEnterprise}
                    />
                )
            }
            {isModalOpen && (
                <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <DialogTitle>
                        Copy Measure
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: "10px !important", width: "400px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="measureName"
                                    name="measureName"
                                    variant="outlined"
                                    fullWidth
                                    label="Measure name"
                                    value={copyMeasure?.measureName}
                                    onChange={handleChange}
                                    placeholder="Enter measure name"
                                    disabled={isView}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsModalOpen(false)} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                        <Button onClick={createUpdateMeasure} color="primary" sx={{ textTransform: "capitalize" }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default MeasureList
