import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';

const initialState = {
    loading: false,
    error: null,
    success: false,
    enterpriseData: [],
    userData: {}
};

// getEnterprise
export const getEnterpriseList = createAsyncThunk('enterprise/getEnterpriseList',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        const responce = await axios.get(`${API_BASE_URL}/all/getAllEnterprise`, config);
        return responce.data
    }
);

// userdata
export const fetchData = createAsyncThunk(
    'enterprise/fetchData',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        const response = await axios.get(
            `${API_BASE_URL}/users/${JSON.parse(userId)?.userId}`,
            config
        );
        return response.data
    }
)

// create and update enterprise
export const createUpdateEnterprise = createAsyncThunk(
    'enterprise/createUpdateEnterprise',
    async ({ updatedFormValues, isEdit, userId, updatedId }) => {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };

        let result;
        if (!isEdit) {
            const baseURLCreate = `${API_BASE_URL}/enterprise`;
            result = await axios.post(baseURLCreate, updatedFormValues, config);
        } else {
            const baseURLUpdate = `${API_BASE_URL}/enterprise/${updatedId}`;
            result = await axios.put(baseURLUpdate, updatedFormValues, config);
        }

        return result.data;
    }
);

// delete enterprise
export const deleteEnterprise = createAsyncThunk('enterprise/deleteEnterprise',
    async ({ attriId, config }) => {
        const responce = await axios.delete(`${API_BASE_URL}/enterprise/${attriId}`, config)
        return responce
    }
);

// all api reducer
const enterPriseSlice = createSlice({
    name: 'enterprise',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEnterpriseList.pending, (state) => {
                state.loading = true
            })
            .addCase(getEnterpriseList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.enterpriseData = action?.payload?.enterpriseList;
            })
            .addCase(getEnterpriseList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchData.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.userData = action.payload.user;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createUpdateEnterprise.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createUpdateEnterprise.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createUpdateEnterprise.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.success = false;
            })
            .addCase(deleteEnterprise.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteEnterprise.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deleteEnterprise.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.success = false;
            })
    }
})

export default enterPriseSlice.reducer