// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daterangepicker-control-section {
    margin: 0 auto;
    border: 1.5px solid gray;
    padding: 0 10px;
    border-radius: 4px;
    height: 47px;
}

.e-input-group {
  border: none !important;
  margin: 0px !important;
  height: 47px !important;
}

.e-daterangepicker{
    font-size:  12px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomDateRangePicker/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,wBAAwB;IACxB,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".daterangepicker-control-section {\n    margin: 0 auto;\n    border: 1.5px solid gray;\n    padding: 0 10px;\n    border-radius: 4px;\n    height: 47px;\n}\n\n.e-input-group {\n  border: none !important;\n  margin: 0px !important;\n  height: 47px !important;\n}\n\n.e-daterangepicker{\n    font-size:  12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
