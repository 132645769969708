import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { Select as MuiSelect } from '@mui/material';
import { customStyles } from '../../page/GeneralPageLayout';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getMeasurePaginationList } from '../../../store/ApiSlice/measureSlice';
import { AsyncPaginate } from 'react-select-async-paginate';
import { API_BASE_URL } from '../../../helper/constants';
import MultiSelectDropdown from './MultiSelectDropdown';
import { Combobox } from '@headlessui/react';
import { ChevronRight } from '@mui/icons-material';



export const MeasureDataFirst = ({
  findMeasure,
  selectedOptions,
  handleChangeAttribute,
  widgetType,
  canvasFindData
}) => {
  const { measureData, totalCount, measurePaginationList } = useSelector((state) => state.measure);
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleSelectChange = (data) => {
    handleChangeAttribute(data, "measures");
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      setSearchText(newValue);
    }
    return newValue;
  };

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const fetchedData = await dispatch(getMeasurePaginationList({
        page: page,
        itemsPerPage: 10,
        search: searchQuery,
        enterpriseUID: canvasFindData?.enterpriseUID?._id,
        modelUID: canvasFindData?.modelUID?.modalUID
      }));

      const updatedOptions = fetchedData?.payload?.data?.length > 0 && fetchedData?.payload?.data?.map(item => ({
        value: item?._id,
        label: item?.measureName,
        enterpriseUID: item?.enterpriseUID,
        modelUID: item?.modelUID,
        baselevelUID: item?.baselevelUID?._id
      }));

      return {
        options: updatedOptions?.length > 0 ? updatedOptions : [],
        hasMore: updatedOptions?.length >= 10,
        additional: {
          page: page + 1,
        }
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <FormControl
      fullWidth
      style={{ paddingBottom: '25px', marginTop: '5px' }}
    >
      <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Measures</Typography>
      <AsyncPaginate
        loadOptions={loadOptions}
        value={
          selectedOptions?.measures?.length > 0 &&
          selectedOptions?.measures?.map((option) => ({
            value: option?.measureUID,
            label: option?.measureName,
            enterpriseUID: option?.enterpriseUID,
            modelUID: option?.modelUID,
            baselevelUID: option?.baselevelUID,
          }))
        }
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        inputValue={searchText}
        placeholder="Select Measures"
        isSearchable={true}
        isMulti
        className="text-black"
        styles={customStyles}
        additional={{
          page: 1,
        }}
        menuIsOpen={menuIsOpen}
        onBlur={handleMenuClose}
        onFocus={() => setMenuIsOpen(true)}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
      />
    </FormControl>
  );
};

export const MeasureDataSecond = ({
  findTimePeriod,
  selectedOptions,
  setSelectedOptions,
  handleChangePage,
  timeDimensionData,
  formValues,
  readOnly
}) => {
  const validToOptions = findTimePeriod?.length > 0 && findTimePeriod?.filter(option => {
    if (!selectedOptions?.startDate) return false;
    // if (option["Time Period Name"] === selectedOptions.validFrom) return false;
    const optionStartDate = option["Period Start"];
    return moment(optionStartDate).format("YYYY-MM-DD") >= moment(selectedOptions?.startDate).format("YYYY-MM-DD");
  });

  const [query, setQuery] = useState("");
  const filteredOptions = Array.isArray(findTimePeriod)
    ? findTimePeriod?.filter((option) =>
      option?.["Time Period Name"]?.toLowerCase()?.includes(query.toLowerCase())
    )
    : [];
  const filteredValidToOptions = Array.isArray(validToOptions)
    ? validToOptions?.filter((option) =>
      option?.["Time Period Name"]?.toLowerCase()?.includes(query.toLowerCase())
    )
    : [];

  return (
    <>
      <hr
        style={{
          borderTop: '2px solid #1976d2',
          width: '100%',
          margin: '20px auto',
        }}
      />
      <div className="relative w-full pb-6">
        <label
          htmlFor="timePeriod"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Time Period Type
        </label>
        <select
          id="timePeriod"
          name="timePeriod"
          // value={formValues?.timePeriod || ""}
          defaultValue={formValues?.timePeriod}
          fullWidth
          onChange={handleChangePage}
          variant="outlined"
          placeholder="Select Time Period"
          className={`
            w-full 
            px-3 
            py-2 
            border 
            border-[#EAEAEA] 
            rounded-lg 
            text-sm 
            shadow-sm
            bg-white
            appearance-none
            focus:outline-none 
            focus:shadow-custom-focus
            disabled:bg-gray-50 
            disabled:text-gray-500 
            disabled:cursor-not-allowed
            ${readOnly ? 'bg-gray-100 text-gray-600 cursor-default' : ''}
          `}
        >
          <option selected>Select Time Period Type</option>
          {timeDimensionData?.timeSchemaFields?.length > 0 &&
            timeDimensionData?.timeSchemaFields?.map(
              (item) =>
                item?.enabled === true && (
                  // <MenuItem value={item?._id}>{item['Period Type']}</MenuItem>
                  <option key={item?._id} value={item?._id}>
                    {item['Period Type']}
                  </option>
                )
            )}
        </select>
        <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
          ▼
        </div>
      </div>

      <div className="relative w-full pb-6">
        <label
          htmlFor="validFrom"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          valid From
        </label>
        <Combobox
          value={selectedOptions?.validFrom || ""}
          onChange={(newValue) => {
            setSelectedOptions((prevState) => ({
              ...prevState,
              validFrom: newValue ? newValue["Time Period Name"] : "",
              startDate: newValue ? newValue["Period Start"] : "",
            }));
          }}
        >
          <div className="relative">
            <Combobox.Input
              className={`
                w-full 
                px-3 
                py-2 
                border 
                border-[#EAEAEA] 
                rounded-lg 
                text-sm 
                shadow-sm
                bg-white
                appearance-none
                focus:outline-none 
                focus:shadow-custom-focus
                disabled:bg-gray-50 
                disabled:text-gray-500 
                disabled:cursor-not-allowed
                ${readOnly ? 'bg-gray-100 text-gray-600 cursor-default' : ''}
              `}
              placeholder="Search Valid From..."
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(option) => option || ""}
            />
            <Combobox.Options className="z-10 absolute mt-1 w-full bg-white border border-gray-300 text-sm rounded-md shadow-lg max-h-60 overflow-auto">
              {filteredOptions?.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    value={option}
                    className={({ active }) =>
                      `cursor-pointer text-sm px-4 py-2 ${active ? "bg-blue-500 text-white" : "text-gray-900"
                      }`
                    }
                  >
                    {option["Time Period Name"]}
                  </Combobox.Option>
                ))
              ) : (
                <div className="px-4 py-2 text-gray-500">No results found</div>
              )}
            </Combobox.Options>
          </div>
        </Combobox>
        <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
          ▼
        </div>
      </div>

      <div className="relative w-full pb-6">
        <label
          htmlFor="validTo"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          valid To
        </label>
        <Combobox
          value={selectedOptions?.validTo || ""}
          onChange={(newValue) => {
            setSelectedOptions((prevState) => ({
              ...prevState,
              validTo: newValue ? newValue["Time Period Name"] : "",
              endDate: newValue ? newValue["Period End"] : "",
            }));
          }}
        >
          <div className="relative">
            <Combobox.Input
              className={`
                w-full 
                px-3 
                py-2 
                border 
                border-[#EAEAEA] 
                rounded-lg 
                text-sm 
                shadow-sm
                bg-white
                appearance-none
                focus:outline-none 
                focus:shadow-custom-focus
                disabled:bg-gray-50 
                disabled:text-gray-500 
                disabled:cursor-not-allowed
                ${readOnly ? 'bg-gray-100 text-gray-600 cursor-default' : ''}
              `}
              placeholder="Search Valid To..."
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(option) => option || ""}
            />
            <Combobox.Options className="z-10 text-sm absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
              {filteredValidToOptions?.length > 0 ? (
                filteredValidToOptions.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    value={option}
                    className={({ active }) =>
                      `cursor-pointer text-sm px-4 py-2 ${active ? "bg-blue-500 text-white" : "text-gray-900"
                      }`
                    }
                  >
                    {option["Time Period Name"]}
                  </Combobox.Option>
                ))
              ) : (
                <div className="px-4 py-2 text-gray-500">No results found</div>
              )}
            </Combobox.Options>
          </div>
        </Combobox>
        <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
          ▼
        </div>
      </div>
    </>
  );
};

const customStylesCycleId = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #000" : "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "14px",
    padding: "5px",
    height: "40px",
    alignContent: "center",
    "&:hover": {
      border: "1px solid #000",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 10px",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#000",
    fontWeight: "400",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#000",
    padding: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};
const cycleLogicalOprater = ["Equal To", "Greater Than", "Less Than"];

export const CycleIdData = ({
  formValues,
  findCycleIdMonth,
  selectedOptions,
  handleChangeAttribute,
  handleChangePage,
  timeDimensionData,
  memoizedOptions
}) => {
  const cyclePeriodicity = [
    "Day",
    "SysWeek",
    "Month",
    "Week",
    "Quarter",
    "Year"
  ]
  const { cycleIDList, pending } = useSelector((state) => state.widget)
  const { dimensionLagIDdata } = useSelector((state) => state.dimension)

  return (
    <>
      <hr
        style={{
          borderTop: '2px solid #1976d2',
          width: '100%',
          margin: '20px auto',
        }}
      />
      {/* <FormControl
        fullWidth
        style={{ paddingBottom: '25px', marginTop: '5px' }}
      >
        <InputLabel id="cyclePeriodicity">Cycle Periodicity</InputLabel>
        <MuiSelect
          labelId="cyclePeriodicity"
          id="demo-simple-select-readonly"
          name="cyclePeriodicity"
          label="Cycle Periodicity"
          // value={formValues?.timePeriod || ""}
          defaultValue={formValues?.cyclePeriodicity || ""}
          fullWidth
          onChange={handleChangePage}
          variant="outlined"
          placeholder="Select Cycle Periodicity"
        >
          {cyclePeriodicity?.length > 0 &&
            cyclePeriodicity?.map(
              (item) =>
              (
                <MenuItem value={item}>{item}</MenuItem>
              )
            )}
        </MuiSelect>
      </FormControl> */}
      <div className='flex gap-3'>
        <div className="relative w-full pb-6">
          <label
            htmlFor="cyclePeriodicity"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Cycle Periodicity
          </label>
          <select
            id="cyclePeriodicity"
            name="cyclePeriodicity"
            // value={formValues?.timePeriod || ""}
            defaultValue={formValues?.cyclePeriodicity}
            fullWidth
            onChange={handleChangePage}
            variant="outlined"
            placeholder="Select Cycle Periodicity"
            className={`
            w-full 
            px-3 
            py-2 
            border 
            border-[#EAEAEA] 
            rounded-lg 
            text-sm 
            shadow-sm
            bg-white
            appearance-none
            focus:outline-none 
            focus:shadow-custom-focus
            disabled:bg-gray-50 
            disabled:text-gray-500 
            disabled:cursor-not-allowed
          `}
          >
            <option selected disabled>Select Cycle Periodicity</option>
            {cyclePeriodicity?.length > 0 &&
              cyclePeriodicity?.map(
                (item) =>
                (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              )}
          </select>
          <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
            <ChevronRight className="rotate-90" />
          </div>
        </div>
        <FormControl
          fullWidth
          style={{ paddingBottom: '25px', marginTop: '5px' }}
        >
          <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Cycle ID</Typography>

          <MultiSelectDropdown
            options={memoizedOptions}
            value={
              selectedOptions?.cycleId?.length > 0 &&
              selectedOptions?.cycleId?.map((option) => ({
                value: option?._id,
                label: option?.cyclePeriodicity,
              }))
            }
            onChange={(data) => handleChangeAttribute(data, 'cycleId')}
            isSelectAll={true}
            menuPlacement={"bottom"}
            styles={customStylesCycleId}
          />
        </FormControl>
        <div className="relative w-full pb-6">
          <label
            htmlFor="cycleLogicalOperator"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Cycle Logical Operator
          </label>
          <select
            id="cycleLogicalOperator"
            name="cycleLogicalOperator"
            // value={formValues?.timePeriod || ""}
            defaultValue=""
            // defaultValue={formValues?.cyclePeriodicity}
            fullWidth
            onChange={handleChangePage}
            variant="outlined"
            placeholder="Select Cycle Periodicity"
            className={`
            w-full 
            px-3 
            py-2 
            border 
            border-[#EAEAEA] 
            rounded-lg 
            text-sm 
            shadow-sm
            bg-white
            appearance-none
            focus:outline-none 
            focus:shadow-custom-focus
            disabled:bg-gray-50 
            disabled:text-gray-500 
            disabled:cursor-not-allowed
          `}
          >
            <option disabled value="">Select Cycle Logical Operator</option>
            {cycleLogicalOprater.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
            <ChevronRight className="rotate-90" />
          </div>
        </div>
        {/* <FormControl
          fullWidth
          style={{ paddingBottom: '25px', marginTop: '5px' }}
        >
          <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Lag ID</Typography>

          <MultiSelectDropdown
            options={dimensionLagIDdata?.map((item) => ({
              value: item?._id,
              label: item['Lag ID'],
            }))}
            value={
              selectedOptions?.lagID?.length > 0 &&
              selectedOptions?.lagID?.map((option) => ({
                value: option?._id,
                label: option?.lagID,
              }))
            }
            onChange={(data) => handleChangeAttribute(data, 'lagID')}
            isSelectAll={true}
            menuPlacement={"bottom"}
            styles={customStylesCycleId}
          />
        </FormControl> */}

      </div>
    </>
  );
};

export const CheckboxOption = (props) => {
  return (
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected} // reflects the selected state
        onChange={() => null} // this is handled by react-select itself
        sx={{ padding: '0 5px 0 0' }}
      />
      <label>{props.label}</label>
    </components.Option>
  );
};

const CustomMenu = (props) => {
  const { innerRef, children, ...rest } = props;
  return (
    <components.Menu {...rest} innerRef={innerRef}>
      {children}
    </components.Menu>
  );
};
