import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deletemodelList, getEnterModelList } from "../../store/ApiSlice/modelSlice";

const DeleteEnterModel = ({
  selectedRows,
  setSelectedRows,
  setModalOpenDelete,
  isModalOpenDelete,
  attriId,
}) => {

  const handleCloseModal = () => {
    setModalOpenDelete(false);
    setSelectedRows([]);
  };
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
      },
    };
    try {

      const response = await dispatch(deletemodelList({ attriId, config }));
      if (response?.payload?.status === 200) {
        // toast.success(response?.payload?.data?.message);
        dispatch(getEnterModelList());
      } else {
        toast.warn(
          response?.data?.error
            ? response.data.error
            : "Enterprise Model can not be deleted"
        );
      }
    } catch (error) {
      console.log(error);
    }
    handleCloseModal();
  };
  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Delete Model</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
          This Is Irreversible. Are You Sure? ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            color="primary" sx={{ textTransform: "capitalize" }}>
            Yes
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      {/* <Button
        onClick={handleClick}
        disabled={!selectedRows.length}
        className={
          selectedRows.length ? "awesome-button" : "awesome-button-disabled"
        }
        sx={{ color: "white", margin: "0 4px" }}
      >
        Delete
      </Button> */}
      <Dialog open={isModalOpenDelete} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  );
};

export default DeleteEnterModel;
