// Add Page Filter modal
import React, { useEffect, useState } from 'react'
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { FaEye, FaRegTrashAlt, FaSearch } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { createFilterDetail, createPageDetail, getAttributeValue, getPageList, savePageDetail, savePageFilterDetail } from '../../store/ApiSlice/canvasSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Select as MuiSelect } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi';
import SystemAttributesValues from '../SystemAttributeValues';
import { formatDate, generateFilterPayload, generateSystemAttributeInitialPayload } from '../SystemAttributeValues/generatePayload.utils';
import { isEmpty } from 'lodash';
import { getCyclePeriodicityList } from '../../store/ApiSlice/widgetSlice';
const selectStyle = "w-[150px] px-3 py-2 border border-[#EAEAEA] rounded-lg text-sm shadow-sm bg-white appearance-none focus:outline-none focus:shadow-custom-focus disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed";

const systemAttributeOptionList = [{
    label: "Scenario ID",
    value: "scenario"
},
{
    label: "Cycle ID",
    value: "cycleIds"
},
{
    label: "Lag ID",
    value: "lagIds"
},
]


const cyclePeriodicity = [
    "Day",
    "SysWeek",
    "Month",
    "Week",
    "Quarter",
    "Year"
];


const PageFilter = ({
    pageFilter,
    setPageFilter,
    canvasFindData,
    newPageID,
    processPageData
}) => {
    const { attribute } = useSelector((state) => state.attribute)
    const { cyclePeriodicityList } = useSelector((state) => state?.widget)

    const {
        filterEnterpriseDetail,
        schemaData,
        attributeValue,
        pageFilterDetails
    } = useSelector((state) => state.canvas);

    const [filterFormvalue, setFilterFormValue] = useState({
        filterName: ""
    })
    const [selectedAttributes, setSelectedAttributes] = useState([{
        attributes: "",
        operator: 'Equal To',
        attValues: {},
    }]);

    const [valueModal, setValueModal] = useState(false)
    const [attributeValueData, setAttributeValueData] = useState({
        attValues: {},
    });


    const [search, setSearch] = useState()
    const [attributePage, setAttributePage] = useState(0);
    const [rowsAttributePerPage, setRowsAttributePerPage] = useState(10);
    const [timePeriodData, setTimePeriodData] = useState([]);
    const dispatch = useDispatch()
    const AttributeEnterpriseData = attribute?.filter((item) => item?.enterpriseUID?._id === canvasFindData?.enterpriseUID?._id)
        ?.filter((item) => item?.modelUID?.modalUID === canvasFindData?.modelUID?.modalUID)

    const findAttribute = AttributeEnterpriseData.find(item => item?._id === filterFormvalue?.attributes);

    const [filterData, setFilterData] = useState({
        timePeriodType: "",
        logicalOperator: "In Between",
        startDate: "",
        endDate: ""
    });

    const [savedFilters, setSavedFilters] = useState([]);

    const [selectRadioButton, setSelectRadioButton] = useState('new');
    const [selectedRowId, setSelectedRowId] = useState("");

    const [hierarchyAttributesList, setHierarchyAttributesList] = useState([{
        id: uuidv4(),
        attributes: "",
        operator: "",
        attValues: {}
    }]);

    const [timeAttributeList, setTimeAttributeList] = useState([{
        id: uuidv4(),
        timePeriod: "",
        operator: "",
        startDate: "",
        endDate: ""
    }]);

    const [systemAttributeList, setSystemAttributeList] = useState([{
        id: uuidv4(),
        attributes: "scenario",
        cyclePeriodicity: "",
        operator: "",
        attValues: {
            Baseline: true
        }
    }]);

    const [filterPageDetails, setFilterPageDetails] = useState(null);

    useEffect(() => {
        if (newPageID) {
            dispatch(savePageDetail({ pageId: newPageID }))
        }
    }, [newPageID]);

    useEffect(() => {
        if (!isEmpty(pageFilterDetails)) {
            setFilterPageDetails(pageFilterDetails)
        }
    }, [pageFilterDetails]);

    useEffect(() => {
        if (canvasFindData) {
            const payload = {
                enterpriseUID: { _id: canvasFindData?.enterpriseUID?._id },
                modelUID: { modalUID: canvasFindData?.modelUID?.modelUID },
            }

            dispatch(getCyclePeriodicityList({ canvasFindData: payload }))
        }
    }, [canvasFindData])

    const handleChange = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value });
    };

    const handleSaveFilter = () => {
        if (filterData?.timePeriodType === "") {
            toast.error("please select time period type")
            return
        } else if (filterData?.startDate === "") {
            toast.error("please select start date")
            return
        } else if (filterData?.endDate === "") {
            toast.error("please select end date")
            return
        } else {
            setSavedFilters([
                ...savedFilters,
                {
                    ...filterData,
                    values: timePeriodData?.map((item) => item["Time Period Name"])
                }
            ]);
            setFilterData({ timePeriodType: "", logicalOperator: "In Between", startDate: "", endDate: "" });
        }
    };

    const handleClickValue = (rowIndex, data) => {
        if (selectedAttributes?.length > 0 && selectedAttributes[0]?.attributes) {
            setValueModal(true);
            if (Object.keys(data?.attValues).length > 0) {
                setAttributeValueData({ attValues: data.attValues });
            }
        } else {
            toast.error("Please first select the Attribute.");
        }
        // setOpenModalRow(rowIndex)
    }
    const handleCloseValue = () => {
        setValueModal(false)
        setAttributeValueData({ attValues: {} })
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleHeaderCheckboxChange = (event, index) => {
        const isChecked = event.target.checked;
        const updatedFormValues = { ...attributeValueData };
        attributeValue.forEach((item) => {
            updatedFormValues.attValues[item] = isChecked;
        });
        setAttributeValueData(updatedFormValues)
    };

    const handleCheckboxChange = (newValue, item) => {
        const updatedFormValues = { ...attributeValueData };
        updatedFormValues.attValues[item] = !updatedFormValues.attValues[item];
        setAttributeValueData(updatedFormValues);
    };

    const handleChangeAttributePage = (event, newPage) => {
        setAttributePage(newPage)
    }

    const handleStepChangeValue = (newValue, fieldname, event, index, data) => {
        const { name, value, checked } = event.target;
        if (fieldname == "attributes") {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][fieldname] = newValue;
            setSelectedAttributes(updatedFormValues);
        }
        else {
            const updatedFormValues = [...selectedAttributes];
            updatedFormValues[index][name] = value;
            setSelectedAttributes(updatedFormValues);
        }
    };

    const handleSubmit = (index) => {
        const updatedFormValues = [...selectedAttributes];
        updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
        setSelectedAttributes(updatedFormValues);
        handleCloseValue();
    };

    const handleSubmitHierarchyAttributesValues = (index) => {
        const updatedFormValues = [...hierarchyAttributesList];
        updatedFormValues[index]["attValues"] = { ...attributeValueData.attValues };
        console.log(updatedFormValues);
        setSelectedRowId("")
        setHierarchyAttributesList(updatedFormValues);
        setAttributeValueData({
            attValues: {},
        })
    }

    const findTimePeriod =
        schemaData?.length > 0 &&
        filterData?.timePeriodType &&
        schemaData?.filter((item) => item['Period Base Level'] === filterData?.timePeriodType);


    useEffect(() => {
        if (!isEmpty(filterPageDetails)) {

            const filters = filterPageDetails?.filter;
            if (filters && filters.attributes?.length > 0) {

                const updateData = (filters.attributes ?? []).map((item) => {
                    return {
                        id: item._id,
                        attributes: item.attributeUID?._id,
                        operator: item?.operator,
                        attValues: (item.values ?? []).reduce((acc, att) => {
                            acc[att] = true;
                            return acc;
                        }, {})
                    }
                });
                if (updateData?.length > 0) {
                    setHierarchyAttributesList(updateData)
                }
            }
            if (filters && !isEmpty(filters.systemAttributes)) {
                const data = generateSystemAttributeInitialPayload(filters.systemAttributes);
                if (data.length > 0) {
                    setSystemAttributeList(data)
                }
            }
            if (filters && (filters.timeAttribute)) {
                const mapTimeArr = [{
                    id: filters.timeAttribute._id,
                    timePeriod: filters.timeAttribute.periodType,
                    operator: filters.timeAttribute.operator,
                    startDate: formatDate(filters?.timeAttribute?.from) || filters?.timeAttribute?.from,
                    endDate: formatDate(filters.timeAttribute?.to) || filters.timeAttribute?.to
                }]

                if (mapTimeArr) {
                    setTimeAttributeList(mapTimeArr);
                }
            }

        }
    }, [filterPageDetails, newPageID])


    const dataTimePeriod = () => {
        const data =
            findTimePeriod?.length > 0 &&
            findTimePeriod?.filter((item) => {
                const validFromDate = moment(item['Period Start']).format('YYYY-MM-DD');
                const validToDate = moment(item['Period End']).format('YYYY-MM-DD');
                const startDate = moment(filterData?.startDate).format(
                    'YYYY-MM-DD'
                );
                const endDate = moment(filterData?.endDate).format('YYYY-MM-DD');
                return startDate <= validFromDate && endDate >= validToDate;
            });
        setTimePeriodData(data);
    };

    useEffect(() => {
        if (filterData?.startDate && filterData?.endDate) {
            dataTimePeriod();
        }
    }, [filterData?.startDate, filterData?.endDate]);

    const handleSubmitFilter = async () => {
        const pageName = canvasFindData?.pages?.find((item) => item?.pageUID?._id === newPageID)
        const payload = {
            name: pageName?.name,
            enterpriseUID: canvasFindData?.enterpriseUID?._id,
            modelUID: canvasFindData?.modelUID?.modelUID,
            canvasUID: canvasFindData?._id,
            filter: {
                filterRefID: filterFormvalue?.filter,
                attributes: filterFormvalue?.filterAttributes?.length > 0 ? filterFormvalue?.filterAttributes?.map((item) => ({
                    attributeUID: item?.attributes,
                    operator: item?.operator,
                    values: Object.entries(item?.attValues)
                        .filter(([key, value]) => value === true)
                        .map(([key, value]) => key),
                })) : [],
                timeAttributes: savedFilters?.length > 0 ? savedFilters?.map((item) => ({
                    timeAttribute: item?.timePeriodType,
                    operator: item?.logicalOperator,
                    values: item?.values
                })) : []
            }
        }
        const result = await dispatch(
            createPageDetail({ payload, isEdits: true, updatedId: newPageID })
        );
        if (result?.meta?.requestStatus === 'fulfilled') {
            setFilterFormValue({});
            setFilterData({
                timePeriodType: "",
                logicalOperator: "In Between",
                startDate: "",
                endDate: ""
            })
            setSavedFilters([])
            setPageFilter(false);
            dispatch(getPageList({ canvasFindData }));
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message);
        }
    }

    const handleSubmitPageFilter = async () => {
        if (filterFormvalue?.filterName !== "") {
            const payload = {
                name: filterFormvalue?.filterName,
                enterpriseUID: canvasFindData?.enterpriseUID?._id,
                modelUID: canvasFindData?.modelUID?.modelUID,
                timeAttributes: savedFilters?.length > 0 ? savedFilters?.map((item) => ({
                    timeAttribute: item?.timePeriodType,
                    operator: item?.logicalOperator,
                    values: item?.values
                })) : [],
                attributes: filterFormvalue?.filterAttributes?.length > 0 ? filterFormvalue?.filterAttributes?.map((item) => ({
                    attributeUID: item?.attributes,
                    operator: item?.operator,
                    values: Object.entries(item?.attValues)
                        .filter(([key, value]) => value === true)
                        .map(([key, value]) => key),
                })) : []
            }
            const responce = await dispatch(
                createFilterDetail({
                    payload,
                    isEdit: false,
                    selectedRows: "",
                })
            );
            if (responce?.meta?.requestStatus === 'fulfilled') {
                handleSubmitFilter()
            } else if (responce?.meta?.requestStatus === 'rejected') {
                toast.error(responce?.error?.message)
            }
        } else {
            handleSubmitFilter()
        }
    }

    const handleAddHierarchyAttributes = () => {

        setHierarchyAttributesList((prev) => ([
            ...prev, {
                id: uuidv4(),
                attributes: "",
                cyclePeriodicity: "",
                operator: "",
                attValues: {}
            }
        ]));

    }

    const handleRemoveHierarchyAttributes = (itemID) => {
        const updatedData = hierarchyAttributesList.filter((item) => item.id !== itemID);
        setHierarchyAttributesList(updatedData);
    }


    const handleClickHierarchyAttributesValue = (data) => {
        if (data?.attributes.length > 0) {
            if (Object.keys(data?.attValues).length > 0) {
                setAttributeValueData({
                    attValues: data.attValues,
                })
            }
            setSelectedRowId(data.id);
        } else {
            toast.error("Please first select the Attribute.");
        }
    }

    const handleOpenValueModal = (row) => {
        handleClickHierarchyAttributesValue(row);
        dispatch(getAttributeValue({ row }));
    }

    const handleValueChangeHierarchyAttributes = (e, itemId) => {
        const { name, value } = e.target;
        setHierarchyAttributesList(prev =>
            prev.map((item) =>
                item.id === itemId ? { ...item, [name]: value } : item
            ),
        );
    }


    //--------Function are used for Time Attributes -----------

    const handleValueChangeTimeAttribute = (e, itemId) => {
        const { name, value } = e.target;
        setTimeAttributeList(prev =>
            prev.map((item) =>
                item.id === itemId ? { ...item, [name]: value } : item
            ),
        );
    }

    const handleAddTimeAttribute = () => {
        setTimeAttributeList((prev) => ([
            ...prev, {
                id: uuidv4(),
                timePeriod: "",
                operator: "",
                startDate: "",
                endDate: ""
            }
        ]));
    }

    const handleRemoveTimeAttribute = (itemID) => {
        const updatedData = timeAttributeList.filter((item) => item.id !== itemID);
        setTimeAttributeList(updatedData);
    }

    //--------Function are used for System Attributes -----------
    const handleAddSystemAttributes = () => {

        const existingAttributes = new Set(systemAttributeList.map(item => item.attributes));
        const filterData = systemAttributeOptionList.filter(item => !existingAttributes.has(item.value));

        const hasScenario = filterData.some(item => item.value === "scenario");

        const newAttribute = hasScenario
            ? {
                id: uuidv4(),
                attributes: "scenario",
                cyclePeriodicity: "",
                operator: "",
                attValues: { Baseline: true }
            }
            : {
                id: uuidv4(),
                attributes: filterData[0]?.value || "",
                cyclePeriodicity: "",
                operator: "In Between",
                attValues: {}
            };

        // Add the new attribute to the system attribute list
        setSystemAttributeList(prev => [...prev, newAttribute]);
    }

    const handleRemoveSystemAttributes = (removeItemID) => {
        const updatedData = systemAttributeList.filter((item) => item.id !== removeItemID);
        setSystemAttributeList(updatedData);
    }

    const handleSystemAttributeValueChange = (e, ItemId) => {
        const { name, value } = e.target;
        if (name === 'attributes') {
            setSystemAttributeList(prev =>
                prev.map((item) =>
                    item.id === ItemId
                        ? {
                            ...item,
                            [name]: value,
                            cyclePeriodicity: "",
                            operator: "",
                            attValues: {}
                        }
                        : item
                ),
            );
        } else {
            setSystemAttributeList(prev =>
                prev.map((item) =>
                    item.id === ItemId
                        ? { ...item, [name]: value }
                        : item
                ),
            );
        }
    }


    const handleSavePageFilter = async (isOverwrite = false) => {

        const filterData = generateFilterPayload(systemAttributeList, timeAttributeList);

        const payload = {
            enterpriseUID: canvasFindData?.enterpriseUID?._id,
            modelUID: canvasFindData?.modelUID?.modelUID,
            canvasUID: canvasFindData?._id,
            notes: "",
            filter: {
                filterRefID: filterFormvalue.filter || "",
                attributes: hierarchyAttributesList?.length > 0 ? hierarchyAttributesList?.map((item) => ({
                    attributeUID: item?.attributes,
                    operator: item?.operator,
                    values: Object.entries(item?.attValues)
                        .filter(([key, value]) => value === true)
                        .map(([key, value]) => key),
                })) : [],
                timeAttribute: filterData.timeAttributes[0] || [],
                systemAttributes: filterData.systemAttributes || []
            },

        }

        const result = await dispatch(
            savePageFilterDetail({
                payload,
                pageId: newPageID
            })
        );

        if (isOverwrite) {
            delete payload["canvasUID"];
            const result = await dispatch(createFilterDetail({ payload, isEdit: false, selectedRows: "" }));
        }

        if (result?.meta?.requestStatus === 'fulfilled') {
            setFilterFormValue({});
            setHierarchyAttributesList([]);
            setTimeAttributeList([]);
            setSystemAttributeList([]);
            setSavedFilters([]);
            setPageFilter(false);
            dispatch(getPageList({ canvasFindData }));
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message);
        }
    }


    const handleChangeExistingSelectFilter = (selectedOption) => {
        const findFilter = filterEnterpriseDetail?.find(
            (item) => item?._id === selectedOption.target.value
        );
        if (selectedOption?.target?.value === "Select Filter"){
            setHierarchyAttributesList([{
                id: uuidv4(),
                attributes: "",
                operator: "",
                attValues: { Baseline: true }
            }]);
        }else {
    
        setFilterFormValue((prev) => ({
            ...prev,
            filter: selectedOption?.target?.value || "",
            filterName: findFilter?.name || ""

        }));
        const existingAtt = findFilter?.attributes?.map((item) => ({
            attributes: item?.attributeUID?._id,
            operator: item?.operator,
            attValues: item?.values?.reduce((acc, value) => {
                acc[value] = true;
                return acc;
            }, {}),
        }));

            setHierarchyAttributesList(existingAtt);
        }
    }

    const handleOnChangesSystemAttribute = (updatedData) => {
        setSystemAttributeList(updatedData)
    }

    //Function used to selected values 
    const handleRemoveValue = (key, selectedIndex) => {
        const attributeValueData = hierarchyAttributesList[selectedIndex].attValues
        const filteredAttValues = Object.entries(attributeValueData)
            .filter(([k, v]) => k !== key)
            .reduce((acc, [k, v]) => {
                acc[k] = v;
                return acc;
            }, {});
        const updatedData = hierarchyAttributesList.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    attValues: filteredAttValues
                }
            }
            return item
        })
        setHierarchyAttributesList(updatedData);
    }

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-[750px] h-[677px] overflow-y-auto flex flex-col">
                    <div className="flex justify-between items-center cursor-pointer">
                        <p className="text-lg font-semibold">Add Page Filter</p>
                        <IoMdClose onClick={() => setPageFilter(false)} />
                    </div>
                    <div className="flex justify-between items-center mb-3">
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectRadioButton}
                                onChange={(e) => {
                                    setSelectRadioButton(e.target.value);
                                }}
                            >
                                <FormControlLabel value="new" label={<span style={{ fontSize: '14px' }}>Create New Filter</span>} control={<Radio />} />
                                <FormControlLabel value="existing" label={<span style={{ fontSize: '14px' }}>Use Existing Filter</span>} control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                        <div>
                            {selectRadioButton === "new" && <button
                                className='h-[40px] w-[100px] mt-6 text-base text-gray-700 border border-[#335CFF] rounded-lg hover:bg-gray-50 transition duration-300'
                                // onClick={handleSubmitPageFilter}
                                onClick={() => handleSavePageFilter()}
                            >
                                Save
                            </button>}
                            {selectRadioButton !== "new" && <div className='mt-6'>
                                <button
                                    className='h-[40px] w-[60px] text-base text-gray-700 border border-[#335CFF] rounded-lg hover:bg-gray-50 transition duration-300'
                                    onClick={() => handleSavePageFilter()}
                                >
                                    Save
                                </button>
                                <button
                                    className='h-[40px] w-[100px] ml-2 text-base text-gray-700 border border-[#335CFF] rounded-lg hover:bg-gray-50 transition duration-300'
                                    onClick={() => handleSavePageFilter(true)}
                                >
                                    Overwrite
                                </button>
                            </div>}
                        </div>
                    </div>


                    {selectRadioButton === "existing" && <div className='mt-3'>
                        <div className='flex justify-between items-center gap-3'>
                            <div className='flex-1'>
                                <label
                                    htmlFor="filter"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Filter Name
                                </label>
                                <select
                                    id="filter"
                                    name="filter"
                                    value={filterFormvalue?.filter}
                                    onChange={(selectedOption) => {
                                        handleChangeExistingSelectFilter(selectedOption)
                                    }}
                                    className={`
                                            w-[50%] 
                                            h-[40px]
                                            px-3 
                                            py-2 
                                            border 
                                            border-[#EAEAEA] 
                                            rounded-lg 
                                            text-sm 
                                            shadow-sm
                                            bg-white
                                            focus:outline-none 
                                            focus:shadow-custom-focus
                                            disabled:bg-gray-50 
                                            disabled:text-gray-500 
                                            disabled:cursor-not-allowed
                                        `}
                                // disabled={filterFormvalue?.filterName}
                                >
                                    <option selected>Select Filter</option>
                                    {filterEnterpriseDetail?.map((option) => (
                                        <option key={option._id} value={option._id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* <div className='mt-6'>
                                <button
                                    className='h-[40px] w-[60px] text-base text-gray-700 border border-[#335CFF] rounded-lg hover:bg-gray-50 transition duration-300'
                                    onClick={() => handleSavePageFilter()}
                                >
                                    Save
                                </button>
                                <button
                                    className='h-[40px] w-[100px] ml-2 text-base text-gray-700 border border-[#335CFF] rounded-lg hover:bg-gray-50 transition duration-300'
                                    onClick={() => handleSavePageFilter(true)}
                                >
                                    Overwrite
                                </button>
                            </div> */}
                        </div>
                    </div>}

                    {/* Hierarchy Attributes Section */}
                    <div className='border  mt-3 border-[#E9EAEB] rounded-xl p-4 my-4'>
                        <div className='flex justify-between items-center'>
                            <h3>Hierarchy Attributes</h3>
                            <button
                                onClick={() => handleAddHierarchyAttributes()}
                                className="font-semibold text-sm p-2 flex items-center gap-2"
                                type="button"
                            >
                                <FiPlus className="w-5 h-5 text-black" />
                            </button>
                        </div>
                        <div>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'>Attribute</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Operator</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Value</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {(hierarchyAttributesList ??[]).map((item, index) => <>
                                    <TableBody>
                                        <TableCell className=''>
                                            <select
                                                id='attributes'
                                                name='attributes'
                                                value={item?.attributes || ""}
                                                className={selectStyle}
                                                onChange={(e) => handleValueChangeHierarchyAttributes(e, item.id)}
                                            >
                                                <option value="">Select Attribute</option>
                                                {AttributeEnterpriseData.map((option) => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </TableCell>
                                        <TableCell className=''>
                                            <select
                                                id='operator'
                                                name='operator'
                                                value={item?.operator || ""}
                                                className={selectStyle}
                                                onChange={(e) => handleValueChangeHierarchyAttributes(e, item.id)}
                                            >
                                                <option value="">Select Operator</option>
                                                <option value="Equal To">Equal To</option>
                                                <option value="Not Equal To">Not Equal To</option>
                                            </select>
                                        </TableCell>
                                        <TableCell>
                                            <div className={`
                                                border border-[#EAEAEA] rounded-lg text-sm shadow-smbg-white px-[6px] py-[3px] w-[150px] min-h-[40px] flex flex-wrap items-center
                                                gap-2
                                                ${Object.entries(item.attValues).some(([_, value]) => value === true) ? '' : 'h-[38px]'}
                                            `}
                                                onClick={() => {
                                                    handleOpenValueModal(item);
                                                }}
                                            >
                                                {item?.attValues &&
                                                    Object.entries(item.attValues)
                                                        .filter(([_, value]) => value === true)
                                                        .map(([key]) => (
                                                            <Chip
                                                                label={key}
                                                                variant="outlined"
                                                                onDelete={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRemoveValue(key, index)
                                                                }}
                                                                sx={{ fontSize: '10px' }} 
                                                            />
                                                        ))}
                                            </div>

                                        </TableCell>
                                        <TableCell className=''>
                                            {hierarchyAttributesList.length > 1 &&
                                                <FaRegTrashAlt
                                                    className='text-[16px] cursor-pointer'
                                                    onClick={() => handleRemoveHierarchyAttributes(item.id)}
                                                />
                                            }
                                        </TableCell>
                                        {selectedRowId === item.id && (
                                            <div className="z-10 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                <div className="bg-white rounded-lg shadow-lg w-[500px] p-4">
                                                    <h2 className="text-sm font-semibold font-poppins">Attribute Values</h2>
                                                    <div className="mt-2">
                                                        {/* Search Input */}
                                                        <div className="relative flex items-center border border-gray-300 rounded-lg px-3 py-2 mb-4">
                                                            <FaSearch className="text-gray-400 mr-2" />
                                                            <input
                                                                type="text"
                                                                placeholder="Search..."
                                                                className="w-full outline-none"
                                                                name='search'
                                                                value={search}
                                                                onChange={(e) => handleSearch(e)}
                                                            />
                                                        </div>

                                                        {/* Table Container */}
                                                        <div className="max-h-[500px] overflow-auto border rounded-lg">
                                                            <table className="w-full border-collapse">
                                                                <thead className="border-b">
                                                                    <tr>
                                                                        <th className="px-3 py-2">
                                                                            <input type="checkbox" onChange={(e) => handleHeaderCheckboxChange(e, index)} />
                                                                        </th>
                                                                        <th className="px-3 py-2 text-left text-xs font-semibold font-poppins">{findAttribute?.name}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {search ? (
                                                                        attributeValue?.filter((item) => {
                                                                            if (typeof item === "string") {
                                                                                return item?.toLowerCase().includes(
                                                                                    typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                                );
                                                                            } else if (typeof item === "number") {
                                                                                return item.toString()?.includes(search?.toString());
                                                                            }
                                                                            return false;
                                                                        })?.length === 0 ? (
                                                                            <tr>
                                                                                <td colSpan={2} className="text-center py-4 font-poppins">No data found</td>
                                                                            </tr>
                                                                        ) : (
                                                                            attributeValue?.filter((item) => {
                                                                                if (typeof item === "string") {
                                                                                    return item?.toLowerCase().includes(
                                                                                        typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                                    );
                                                                                } else if (typeof item === "number") {
                                                                                    return item.toString()?.includes(search?.toString());
                                                                                }
                                                                                return false;
                                                                            })?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                                <tr key={index} className="border-t">
                                                                                    <td className="px-3 py-2 text-center">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={attributeValueData?.attValues[item] || false}
                                                                                            onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="px-3 py-2 text-xs">{item}</td>
                                                                                </tr>
                                                                            ))
                                                                        )
                                                                    ) : (
                                                                        attributeValue?.slice(attributePage * rowsAttributePerPage, (attributePage + 1) * rowsAttributePerPage)?.map((item, index) => (
                                                                            <tr key={index} className="border-t">
                                                                                <td className="px-3 py-2 text-center">
                                                                                    <label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={(attributeValueData?.attValues || {})[item] || false}
                                                                                            onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                                                                        />
                                                                                    </label>
                                                                                </td>
                                                                                <td className="px-3 py-2 text-xs">{item}</td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        {/* Pagination */}
                                                        <div className="flex justify-between items-center mt-4 text-sm">
                                                            <span className='text-xs'>Total: {search ? attributeValue?.filter((item) => {
                                                                if (typeof item === "string") {
                                                                    return item?.toLowerCase().includes(
                                                                        typeof search === "number" ? search?.toString() : search?.toLowerCase()
                                                                    );
                                                                } else if (typeof item === "number") {
                                                                    return item?.toString().includes(search?.toString());
                                                                }
                                                                return false;
                                                            })?.length : attributeValue?.length}</span>

                                                            <div className="flex items-center">
                                                                <button className="px-2 text-xs py-1 border rounded-md mr-2" onClick={(e) => handleChangeAttributePage(e, attributePage - 1)} disabled={attributePage === 0}>
                                                                    Prev
                                                                </button>
                                                                <span>{attributePage + 1}</span>
                                                                <button className="px-2 py-1 text-xs border rounded-md ml-2" onClick={(e) => handleChangeAttributePage(e, attributePage + 1)} disabled={(attributePage + 1) * rowsAttributePerPage >= attributeValue?.length}>
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Actions */}
                                                    <div className="flex justify-end space-x-2 mt-4">
                                                        <button
                                                            className="px-4 py-2 border rounded-md text-sm"
                                                            onClick={() => {
                                                                setSelectedRowId("");
                                                                setSearch("");
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm"
                                                            onClick={() => {
                                                                handleSubmitHierarchyAttributesValues(index);
                                                                setSearch("");
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </TableBody>
                                </>)}
                            </Table>
                        </div>
                    </div>

                    {/* Time Attributes section */}
                    <div className='border  mt-3 border-[#E9EAEB] rounded-xl p-4 mb-4'>
                        <div className='flex justify-between items-center'>
                            <h3>Time Attributes</h3>
                            {/* <button
                                onClick={() => handleAddTimeAttribute()}
                                className="font-semibold text-sm p-2 flex items-center gap-2"
                                type="button"
                            >
                                <FiPlus className="w-5 h-5 text-black" />
                            </button> */}
                        </div>

                        <div>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'>Period</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Operator</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>From Date</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>To Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {timeAttributeList.map((item, index) => <>
                                    <TableBody>
                                        <TableCell className=''>
                                            <select
                                                id='timePeriod'
                                                name='timePeriod'
                                                value={item?.timePeriod || ""}
                                                className={selectStyle}
                                                onChange={(e) => handleValueChangeTimeAttribute(e, item.id)}
                                            >
                                                <option value="" selected>
                                                    Select Time Period Type
                                                </option>
                                                {(cyclePeriodicityList ?? []).map((item) => <option value={item}>{item}</option>)}
                                            </select>
                                        </TableCell>
                                        <TableCell>
                                            <select
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                id="Operator"
                                                name="operator"
                                                value={item.operator}
                                                onChange={(e) => handleValueChangeTimeAttribute(e, item.id)}
                                            >
                                                <option value="In Between" selected>
                                                    In Between
                                                </option>
                                                <option value="Equal To">Equal To</option>
                                            </select>
                                        </TableCell>
                                        <TableCell className=''>
                                            <input
                                                type="date"
                                                id="attributeStartDate"
                                                name="startDate"
                                                // value={formatDate(item.startDate)}
                                                value={item.startDate}
                                                onChange={(e) => handleValueChangeTimeAttribute(e, item.id)}
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <input
                                                type="date"
                                                id="attributeEndDate"
                                                name="endDate"
                                                // value={formatDate(item.endDate)}
                                                value={item.endDate}
                                                onChange={(e) => handleValueChangeTimeAttribute(e, item.id)}
                                                min={item.startDate || ""}
                                                disabled={!item.startDate}
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                            />
                                        </TableCell>
                                        <TableCell className=''>
                                            {timeAttributeList.length > 1 &&
                                                <FaRegTrashAlt
                                                    className='text-[16px] cursor-pointer'
                                                    onClick={() => handleRemoveTimeAttribute(item.id)}
                                                />
                                            }
                                        </TableCell>
                                    </TableBody>
                                </>)}
                            </Table>
                        </div>
                    </div>

                    {/* System Attributes section */}
                    <div className='border  mt-3 border-[#E9EAEB] rounded-xl p-4 mb-4'>
                        <div className='flex justify-between items-center'>
                            <h3>System Attributes</h3>

                            <button
                                onClick={() => handleAddSystemAttributes()}
                                className="font-semibold text-sm p-2 flex items-center gap-2"
                                type="button"
                                disabled={systemAttributeList?.length === 3}
                            >
                                <FiPlus className="w-5 h-5 text-black" />
                            </button>
                        </div>

                        <div>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='font-semibold text-[14px]'>Attribute</TableCell>
                                        <TableCell className='font-semibold text-[14px]'>Periodicity</TableCell>
                                        {/* <TableCell className='font-semibold text-[14px]'>Operator</TableCell> */}
                                        <TableCell className='font-semibold text-[14px]'>value</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {(systemAttributeList ?? []).map((item, index) => {
                                    // Create a filtered list of system attribute options excluding already selected values
                                    const availableOptions = systemAttributeOptionList.filter(option =>
                                        !(systemAttributeList ?? []).some(attItem => attItem.attributes === option.value && attItem.id !== item.id),
                                    );

                                    return <>
                                        <TableBody>
                                            <TableCell className=''>
                                                <select
                                                    id='attributes'
                                                    name='attributes'
                                                    value={item?.attributes || ""}
                                                    className={selectStyle}
                                                    onChange={(e) => handleSystemAttributeValueChange(e, item.id)}
                                                >
                                                    <option value="" selected>
                                                        Select Attribute
                                                    </option>
                                                    {availableOptions.map((attributeItem) =>
                                                        <option value={attributeItem.value} >
                                                            {attributeItem.label}
                                                        </option>)}
                                                </select>
                                            </TableCell>
                                            <TableCell>
                                                {item?.attributes === "cycleIds" ?
                                                    <select
                                                        id='cyclePeriodicity'
                                                        name='cyclePeriodicity'
                                                        value={item?.cyclePeriodicity || ""}
                                                        className={selectStyle}
                                                        onChange={(e) => handleSystemAttributeValueChange(e, item.id)}
                                                    >
                                                        <option value="" selected>
                                                            Select Attribute
                                                        </option>
                                                        {cyclePeriodicity.map((periodicityItem) =>
                                                            <option value={periodicityItem} >
                                                                {periodicityItem}
                                                            </option>)}
                                                    </select>

                                                    : "-"}
                                            </TableCell>
                                            {/* <TableCell className=''>
                                                <select
                                                    className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                    id="operator"
                                                    name="operator"
                                                    value={item.operator}
                                                    onChange={(e) => handleSystemAttributeValueChange(e, item.id)}
                                                >
                                                    <option value="" selected>
                                                        In Between
                                                    </option>
                                                    <option value="equals">Equals</option>
                                                </select>
                                            </TableCell> */}
                                            <TableCell>
                                                <SystemAttributesValues
                                                    items={item}
                                                    selectedFilterDetail={canvasFindData}
                                                    systemAttributeList={systemAttributeList}
                                                    handleOnChangesSystemAttribute={handleOnChangesSystemAttribute}
                                                    rowIndex={index}
                                                />
                                            </TableCell>
                                            <TableCell className=''>
                                                {systemAttributeList.length > 1 &&
                                                    <FaRegTrashAlt
                                                        className='text-[16px] cursor-pointer'
                                                        onClick={() => handleRemoveSystemAttributes(item.id)}
                                                    />
                                                }
                                            </TableCell>
                                        </TableBody>
                                    </>
                                })}
                            </Table>
                        </div>
                    </div>

                    <div className="pt-3 mb-5">
                        {/* <div className='border border-[#E9EAEB] rounded-xl p-4 mb-4'>
                            <div className="flex mb-4 border border-[#e9eaeb] rounded-md w-full bg-[#FAFAFA]">
                                <button
                                    className={`text-base font-semibold px-4 py-2 w-full ${activeTab === 'attributes'
                                        ? 'text-[#414651] border border-[#E9EAEB] rounded-md bg-white'
                                        : 'text-[#717680]'
                                        }`}
                                    onClick={() => setActiveTab('attributes')}
                                >
                                    Attributes
                                </button>
                                <button
                                    className={`text-base font-semibold px-4 py-2 w-full ${activeTab === 'timeAttributes'
                                        ? ' text-[#414651] border border-[#E9EAEB] rounded-md bg-white'
                                        : 'text-[#717680]'
                                        }`}
                                    onClick={() => setActiveTab('timeAttributes')}
                                >
                                    Time Attributes
                                </button>
                            </div>

                            {activeTab === 'attributes' && (
                                <div>
                                    {selectedAttributes?.map((row, i) => {
                                        const findAttribute = AttributeEnterpriseData.find(item => item?._id === row.attributes);
                                        return (
                                            <div>
                                                <label
                                                    htmlFor="selectAttribute"
                                                    className="block text-sm font-medium text-gray-700 mb-2"
                                                >
                                                    Select Attribute
                                                </label>
                                                <select
                                                    id='attributes'
                                                    name='attributes'
                                                    value={row?.attributes || ""}
                                                    className={`w-full
                                                                               px-3 
                                                                               py-2 
                                                                               border 
                                                                               border-[#EAEAEA] 
                                                                               rounded-lg 
                                                                               text-sm 
                                                                               shadow-sm
                                                                               bg-white
                                                                               appearance-none
                                                                               focus:outline-none 
                                                                               focus:shadow-custom-focus
                                                                               disabled:bg-gray-50 
                                                                               disabled:text-gray-500 
                                                                               disabled:cursor-not-allowed mb-5
                                                                             `}
                                                    onChange={(e) => handleStepChangeValue(e.target.value, "attributes", e, i, row)}
                                                >
                                                    <option value="">Select Attribute</option>
                                                    {AttributeEnterpriseData.map((option) => (
                                                        <option key={option._id} value={option._id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='w-full flex items-center gap-3'>
                                                    <div className='w-full'>
                                                        <label
                                                            htmlFor="operator"
                                                            className="block text-sm font-medium text-gray-700 mb-2"
                                                        >
                                                            Operator
                                                        </label>
                                                        <select
                                                            id='operator'
                                                            name='operator'
                                                            value={row?.operator || ""}
                                                            className={`w-full
                                                                               px-3 
                                                                               py-2 
                                                                               border 
                                                                               border-[#EAEAEA] 
                                                                               rounded-lg 
                                                                               text-sm 
                                                                               shadow-sm
                                                                               bg-white
                                                                               focus:outline-none 
                                                                               focus:shadow-custom-focus
                                                                               disabled:bg-gray-50 
                                                                               disabled:text-gray-500 
                                                                               disabled:cursor-not-allowed
                                                                             `}
                                                            onChange={(e) => handleStepChangeValue(e.target.value, "operator", e, i, row)}
                                                        >
                                                            <option value="">Select Operator</option>
                                                            <option value="Equal To">Equal To</option>
                                                            <option value="Not Equal To">Not Equal To</option>
                                                        </select>
                                                    </div>
                                                    <div
                                                        className="cursor-pointer h-full w-full"
                                                        onClick={() => {
                                                            handleClickValue(i, row);
                                                            dispatch(getAttributeValue({ row }));
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor="value"
                                                            className="block text-sm font-medium text-gray-700 mb-2"
                                                        >
                                                            value
                                                        </label>
                                                        <div className={`border 
                                                                                border-[#EAEAEA] 
                                                                                rounded-lg 
                                                                                text-sm 
                                                                                shadow-sm
                                                                                bg-white
                                                                                px-[6px] py-[3px]
                                                                                ${Object.entries(row.attValues).some(([_, value]) => value === true) ? '' : 'h-[38px]'}
                                                                            `}>
                                                            {row?.attValues &&
                                                                Object.entries(row.attValues)
                                                                    .filter(([_, value]) => value === true)
                                                                    .map(([key]) => (
                                                                        <span
                                                                            key={key}
                                                                            className="text-xs bg-[#F4F3FF] text-[#5925DC] border border-[#D9D6FE] px-[5px] py-1 m-[3px] rounded-[5px] inline-block"
                                                                        >
                                                                            {key}
                                                                        </span>
                                                                    ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className='text-sm font-semibold flex justify-end items-center pt-3 text-[#335CFF] cursor-pointer'
                                        onClick={() => {
                                            if (selectedAttributes?.[0]?.attributes !== "") {
                                                setFilterFormValue({
                                                    ...filterFormvalue,
                                                    filterAttributes: [...(filterFormvalue?.filterAttributes || []), ...selectedAttributes]
                                                });
                                                setSelectedAttributes([{
                                                    attributes: "",
                                                    operator: 'Equal To',
                                                    attValues: {},
                                                }])
                                            }
                                        }}>
                                        Save Filter
                                    </div>
                                </div>
                            )}

                            {activeTab === 'timeAttributes' && (
                                <div>
                                    <div className='w-full flex items-center gap-3'>
                                        <div className="mb-5 w-full">
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Time Period Type
                                            </label>
                                            <select
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                name="timePeriodType"
                                                value={filterData.timePeriodType}
                                                onChange={handleChange}
                                            >
                                                <option value="" selected>
                                                    Select Time Period Type
                                                </option>
                                                <option value="Day">Day</option>
                                                <option value="SysWeek">SysWeek</option>
                                                <option value="Week">Week</option>
                                                <option value="Month">Month</option>
                                                <option value="Quarter">Quarter</option>
                                                <option value="Year">Year</option>
                                            </select>
                                        </div>
                                        <div className="mb-5 w-full">
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Logical Operator
                                            </label>
                                            <select
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                                id="Operator"
                                                name="logicalOperator"
                                                value={filterData.logicalOperator}
                                                onChange={handleChange}
                                            >
                                                <option value="" selected>
                                                    In Between
                                                </option>
                                                <option value="equals">Equals</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='w-full flex items-center gap-3'>
                                        <div className="mb-5 w-full">
                                            <label
                                                htmlFor="attributeStartDate"
                                                className="block text-sm font-medium text-gray-700 mb-2"
                                            >
                                                From
                                            </label>
                                            <input
                                                type="date"
                                                id="attributeStartDate"
                                                name="startDate"
                                                value={filterData.startDate}
                                                onChange={handleChange}
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                            />
                                        </div>
                                        <div className="mb-5 w-full">
                                            <label
                                                htmlFor="attributeEndDate"
                                                className="block text-sm font-medium text-gray-700 mb-2"
                                            >
                                                To
                                            </label>
                                            <input
                                                type="date"
                                                id="attributeEndDate"
                                                name="endDate"
                                                value={filterData.endDate}
                                                onChange={handleChange}
                                                min={filterData.startDate || ""}
                                                className="w-full px-3 py-2 border border-[#EAEAEA] rounded-lg focus:outline-none focus:shadow-custom-focus text-sm shadow-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className='text-sm font-semibold flex justify-end items-center text-[#335CFF] cursor-pointer'
                                        onClick={handleSaveFilter}
                                    >
                                        Save Filter
                                    </div>
                                </div>
                            )}
                        </div> */}

                        {/* <div className="border-b border-[#E9EAEB] mb-4"></div>
                        {activeTab === 'attributes' && (
                            <div className="overflow-hidden border border-[#E9EAEB] rounded-xl">
                                <table className="w-full rounded-xl p-4 border-collapse">
                                    <thead className="border-b">
                                        <tr>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373] border-r border-[#E9EAEB]">
                                                Attributes
                                            </th>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373] border-r border-[#E9EAEB]">
                                                Logical Operator
                                            </th>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373]">
                                                Values
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filterFormvalue?.filterAttributes?.length > 0 &&
                                            filterFormvalue?.filterAttributes?.map((attr, index) => (
                                                <tr key={index} className="border-t">
                                                    <td className="font-medium text-left text-xs text-[#414651] border-r border-[#E9EAEB] px-2.5 py-4">
                                                        <span className='font-medium text-xs text-[#414651] bg-[#FAFAFA] border border-[#E9EAEB] p-[5px] rounded-md inline-block'>
                                                            {AttributeEnterpriseData?.find((item) => item?._id === attr?.attributes)?.name}
                                                        </span>
                                                    </td>
                                                    <td className="font-medium text-left text-xs text-[#414651] border-r border-[#E9EAEB] px-2.5 py-4">
                                                        {attr?.operator}
                                                    </td>
                                                    <td className='px-2.5 py-4'>
                                                        <div
                                                            className={`font-medium text-xs bg-white appearance-none 
                                                            ${Object.values(attr.attValues).includes(true) ? '' : 'h-[41px]'}`}
                                                        >
                                                            {attr?.attValues &&
                                                                Object.entries(attr.attValues)
                                                                    .filter(([, value]) => value === true)
                                                                    .map(([key]) => (
                                                                        <span
                                                                            key={key}
                                                                            className="font-medium text-xs text-[#5925DC] bg-[#F4F3FF] border border-[#D9D6FE] p-[5px] m-[3px] rounded-md inline-block"
                                                                        >
                                                                            {key}
                                                                        </span>
                                                                    ))}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {activeTab === 'timeAttributes' && (
                            <div className="overflow-hidden border border-[#E9EAEB] rounded-xl">
                                <table className="w-full rounded-xl p-4 border-collapse">
                                    <thead className="border-b">
                                        <tr>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373] border-r border-[#E9EAEB]">
                                                Time Period Type
                                            </th>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373] border-r border-[#E9EAEB]">
                                                Logical Operator
                                            </th>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373] border-r border-[#E9EAEB]">
                                                From
                                            </th>
                                            <th className="p-2.5 text-left font-medium text-xs text-[#737373]">
                                                To
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {savedFilters.map((filter, index) => (
                                            <tr key={index} className="border-t">
                                                <td className="font-medium text-left text-xs text-[#414651] border-r border-[#E9EAEB] px-2.5 py-4">
                                                    {filter.timePeriodType}
                                                </td>
                                                <td className="font-medium text-left text-xs text-[#414651] border-r border-[#E9EAEB] px-2.5 py-4">
                                                    {filter.logicalOperator}
                                                </td>
                                                <td className="font-medium text-left text-xs text-[#414651] border-r border-[#E9EAEB] px-2.5 py-4">
                                                    {filter.startDate}
                                                </td>
                                                <td className="font-medium text-left text-xs text-[#414651] px-2.5 py-4">
                                                    {filter.endDate}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <div className='mt-8 flex gap-3 w-full'>
                            <button className='px-4 w-full py-2.5 text-base text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50 transition duration-300' onClick={() => setPageFilter(false)}>Cancel</button>
                            <button className='px-4 w-full py-2.5 text-base bg-[#335CFF] text-white rounded-lg transition duration-300'
                                onClick={handleSubmitPageFilter}
                            >
                                Proceed</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageFilter
