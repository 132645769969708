import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch } from 'react-redux';
import { createPlanningObject, getPlanningObjects } from '../../../store/ApiSlice/dimensionSlice';
import { toast } from 'react-toastify';

const CreatePlanningObject = ({
    planningObjectID,
    createPlanningObjectModel,
    setCreatePlanningObjectModel,
    isEditPlanningObject,
    setIsEditPlanningObject,
    formValues
}) => {
    const userData = JSON.parse(localStorage.getItem("accessToken"))
    const { planningObjectsData, totalPlanningObject, planningObjectMasterData } = useSelector((state) => state.dimension);
    const [planningFormValue, setPlanningFormValue] = useState({})
    const dispatch = useDispatch()

    const handleChangePlanning = (newValue, fieldName, event) => {
        const value = event?.target?.value;
        if (fieldName === "Valid To" || fieldName === "Valid From") {
            setPlanningFormValue((prevValues) => ({
                ...prevValues,
                [fieldName]: newValue ? moment(newValue).format("DD-MM-YYYY") : null,
            }));
        } else {
            setPlanningFormValue({
                ...planningFormValue,
                [fieldName]: newValue,
            })
        }
    };

    const selectPlanningObject = planningObjectsData?.length > 0 ? planningObjectsData?.find((item) => item?._id === planningObjectID) : {}

    useEffect(() => {
        if (isEditPlanningObject && selectPlanningObject) {
            setPlanningFormValue({ ...selectPlanningObject });
        }
    }, [isEditPlanningObject, selectPlanningObject, planningObjectMasterData]);

    const formattedDate = planningFormValue["Valid To"] && moment(planningFormValue["Valid To"], "DD-MM-YYYY").isValid()
        ? moment(planningFormValue["Valid To"], "DD-MM-YYYY") // Parse with correct format
        : null

    const handlecreatePlanningObject = async () => {
        const payload = {
            enterpriseUID: formValues?.enterpriseUID,
            modelUID: formValues.modelUID,
            dimensionUID: formValues?.dimensionUID,
            documentsArray: isEditPlanningObject
                ? [{
                    ...planningFormValue,
                    "Valid From": moment(planningFormValue["Valid From"]).format("DD-MM-YYYY"),
                    "Valid To": planningFormValue["Valid To"] && moment(planningFormValue["Valid To"], "DD-MM-YYYY").isValid()
                        ? moment(planningFormValue["Valid To"], "DD-MM-YYYY").format("DD-MM-YYYY")
                        : null,
                    _id: selectPlanningObject?._id
                }]
                : [{
                    ...planningFormValue,
                    "Valid From": moment(planningFormValue["Valid From"]).format("DD-MM-YYYY"),
                    "Valid To": planningFormValue["Valid To"] && moment(planningFormValue["Valid To"], "DD-MM-YYYY").isValid()
                        ? moment(planningFormValue["Valid To"], "DD-MM-YYYY").format("DD-MM-YYYY")
                        : null,
                }]
        }
        const result = await dispatch(createPlanningObject({ payload, isEditPlanningObject, planningObjectID }))
        if (result?.meta?.requestStatus === 'fulfilled') {
            setPlanningFormValue({})
            setCreatePlanningObjectModel(false)
            setIsEditPlanningObject(false)
            dispatch(getPlanningObjects({ page: 1, limit: 10, id: formValues?.dimensionUID }));
        } else if (result.meta.requestStatus === 'rejected') {
            toast.error(result?.error?.message)
        }
    }
    return (
        <>
            <Dialog
                open={createPlanningObjectModel}
                onClose={() => {
                    setCreatePlanningObjectModel(false)
                }}
                maxWidth={"md"}
            >
                <DialogTitle>
                    <Box sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography>
                            {isEditPlanningObject ? "Edit Planning Object" : "Create Planning Object"}
                        </Typography>
                        <Box>
                            <CloseIcon
                                onClick={() => {
                                    setCreatePlanningObjectModel(false)
                                }}
                                sx={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                }}
                            />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        {planningObjectMasterData?.requiredFields?.length > 0 && planningObjectMasterData?.requiredFields?.filter((item) =>
                            !["Valid From"]?.includes(item))
                            ?.filter((item) => !["Valid To"]?.includes(item))
                            ?.map((item, index) => (
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId={item}
                                            name={item}
                                            options={planningObjectMasterData?.compoundDimensionData[item]}
                                            getOptionLabel={(option) => option}
                                            value={
                                                planningFormValue?.[item]
                                                    ? planningObjectMasterData?.compoundDimensionData[item]?.find(
                                                        (option) =>
                                                            option === planningFormValue?.[item]
                                                    ) || null
                                                    : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleChangePlanning(newValue, item, e);
                                            }}
                                            disabled={isEditPlanningObject}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={item}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            ))}
                        {planningObjectMasterData?.schemaInfo?.["Valid From"]?.required && (
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Valid From"
                                        value={planningFormValue["Valid From"] ? moment(planningFormValue["Valid From"]) : null}
                                        onChange={(newValue) => handleChangePlanning(newValue, "Valid From", null)}
                                        format={userData?.data?.dateFormat || "DD-MM-YYYY"} // Dynamic date format
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                variant: "outlined",
                                                InputLabelProps: { shrink: true },
                                                placeholder: userData?.data?.dateFormat || "DD-MM-YYYY", // Show the dynamic format as a placeholder
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        )}
                        {planningObjectMasterData?.schemaInfo?.["Valid To"]?.required && (
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Valid To"
                                        value={
                                            formattedDate !== "Invalid Date" ? formattedDate : planningFormValue["Valid To"] ? moment(planningFormValue["Valid To"]) : null // Handle invalid or missing dates
                                        }
                                        onChange={(newValue) => handleChangePlanning(newValue, "Valid To", null)}
                                        format={userData?.data?.dateFormat || "DD-MM-YYYY"} // Dynamic date format
                                        minDate={
                                            planningFormValue["Valid From"] && moment(planningFormValue["Valid From"], "DD-MM-YYYY").isValid()
                                                ? moment(planningFormValue["Valid From"], "DD-MM-YYYY") // Ensure correct parsing for minDate
                                                : null // Handle invalid or missing dates
                                        }
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                variant: "outlined",
                                                InputLabelProps: { shrink: true },
                                                placeholder: userData?.data?.dateFormat || "DD-MM-YYYY", // Show the dynamic format as a placeholder
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>

                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCreatePlanningObjectModel(false)
                        setIsEditPlanningObject(false)
                        setPlanningFormValue({})
                    }} sx={{textTransform: "capitalize"}}
                    >
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handlecreatePlanningObject} sx={{textTransform: "capitalize"}}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreatePlanningObject
