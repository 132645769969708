import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { Select as MuiSelect } from '@mui/material';
import { customStyles } from '../../page/GeneralPageLayout';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getMeasurePaginationList } from '../../../store/ApiSlice/measureSlice';
import { AsyncPaginate } from 'react-select-async-paginate';
import { API_BASE_URL } from '../../../helper/constants';
import MultiSelectDropdown from './MultiSelectDropdown';



export const MeasureDataFirst = ({
  findMeasure,
  selectedOptions,
  handleChangeAttribute,
  widgetType,
  canvasFindData
}) => {
  const { measureData, totalCount, measurePaginationList } = useSelector((state) => state.measure);
  const dispatch = useDispatch();

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const fetchedData = await dispatch(getMeasurePaginationList({ page: page, itemsPerPage: 10, search: searchQuery, enterpriseUID: canvasFindData?.enterpriseUID?._id, modelUID: canvasFindData?.modelUID?._id }));
      const updatedOptions = fetchedData?.payload?.data?.length > 0 && fetchedData?.payload?.data?.map(item => ({
        value: item?._id,
        label: item?.measureName,
        enterpriseUID: item?.enterpriseUID,
        modelUID: item?.modelUID,
        baselevelUID: item?.baselevelUID?._id
      }));

      return {
        options: updatedOptions?.length > 0 ? updatedOptions : [],
        hasMore: updatedOptions?.length >= 10,
        additional: {
          page: page + 1,
        }
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <React.Fragment>
      <hr
        style={{
          borderTop: '2px solid #1976d2',
          width: '100%',
          margin: '20px auto',
        }}
      />
      <FormControl
        fullWidth
        style={{ paddingBottom: '25px', marginTop: '5px' }}
      >
        <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Measures</Typography>
        <AsyncPaginate
          loadOptions={loadOptions}
          value={selectedOptions?.measures?.length > 0 && selectedOptions?.measures?.map((option) => ({
            value: option?.measureUID,
            label: option?.measureName,
            enterpriseUID: option?.enterpriseUID,
            modelUID: option?.modelUID,
            baselevelUID: option?.baselevelUID
          }))}
          onChange={(data) => handleChangeAttribute(data, 'measures')}
          placeholder="Select Measures"
          isSearchable={true}
          isMulti
          className="text-black"
          styles={customStyles}
          additional={{
            page: 1,
          }}
        />
      </FormControl>
    </React.Fragment>
  );
};

export const MeasureDataSecond = ({
  findTimePeriod,
  selectedOptions,
  setSelectedOptions,
  handleChangePage,
  timeDimensionData,
  formValues,
}) => {
  const validToOptions = findTimePeriod?.length > 0 && findTimePeriod?.filter(option => {
    if (!selectedOptions?.startDate) return false;
    // if (option["Time Period Name"] === selectedOptions.validFrom) return false;
    const optionStartDate = option["Period Start"];
    return moment(optionStartDate).format("YYYY-MM-DD") >= moment(selectedOptions?.startDate).format("YYYY-MM-DD");
  });
  return (
    <React.Fragment>
      <hr
        style={{
          borderTop: '2px solid #1976d2',
          width: '100%',
          margin: '20px auto',
        }}
      />
      <Typography sx={{ padding: '0 0 10px 0', fontSize: "12px" }}>Time Period Type</Typography>
      <FormControl
        fullWidth
        style={{ paddingBottom: '25px', marginTop: '5px' }}
      >
        <InputLabel id="timePeriod">Time Period Type</InputLabel>
        <MuiSelect
          labelId="timePeriod"
          id="demo-simple-select-readonly"
          name="timePeriod"
          label="Time Period Type"
          // value={formValues?.timePeriod || ""}
          defaultValue={formValues?.timePeriod}
          fullWidth
          onChange={handleChangePage}
          variant="outlined"
          placeholder="Select Time Period"
        >
          {timeDimensionData?.timeSchemaFields?.length > 0 &&
            timeDimensionData?.timeSchemaFields?.map(
              (item) =>
                item?.enabled === true && (
                  <MenuItem value={item?._id}>{item['Period Type']}</MenuItem>
                )
            )}
        </MuiSelect>
      </FormControl>

      <FormControl
        fullWidth
        style={{ paddingBottom: '25px', marginTop: '5px' }}
      >
        <Autocomplete
          id="demo-simple-select-readonly"
          labelId="validFrom"
          name="validFrom"
          options={Array.isArray(findTimePeriod) ? findTimePeriod : []}
          getOptionLabel={(option) => option['Time Period Name']}
          value={
            selectedOptions?.validFrom
              ? findTimePeriod.find(
                (option) =>
                  option['Time Period Name'] === selectedOptions?.validFrom
              ) || null
              : null
          }
          onChange={(e, newValue) => {
            setSelectedOptions((prevState) => ({
              ...prevState,
              validFrom: newValue && newValue['Time Period Name'], // Potential source of error
              startDate: newValue && newValue['Period Start'], // Potential source of error
            }));
          }}
          sx={{ fontSize: "12px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Valid From"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth style={{ paddingBottom: "25px", marginTop: "5px" }}>
        <Autocomplete
          id="demo-simple-select-readonly"
          labelId="validTo"
          name="validTo"
          options={Array.isArray(validToOptions) ? validToOptions : []}
          getOptionLabel={(option) => option["Time Period Name"]}
          value={
            selectedOptions?.validTo ?
              findTimePeriod.find(
                (option) => option["Time Period Name"] === selectedOptions?.validTo
              )
              : null
          }
          onChange={(e, newValue) => {
            setSelectedOptions(prevState => ({
              ...prevState,
              validTo: newValue && newValue["Time Period Name"],
              endDate: newValue && newValue["Period End"]
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Valid To"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </FormControl>
    </React.Fragment>
  );
};

export const CycleIdData = ({
  findCycleIdMonth,
  selectedOptions,
  handleChangeAttribute,
}) => {
  const { cycleIDList, pending } = useSelector((state) => state.widget)
  const { dimensionLagIDdata } = useSelector((state) => state.dimension)
  return (
    <React.Fragment>
      <hr
        style={{
          borderTop: '2px solid #1976d2',
          width: '100%',
          margin: '20px auto',
        }}
      />
      <FormControl
        fullWidth
        style={{ paddingBottom: '25px', marginTop: '5px' }}
      >
        <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Cycle ID</Typography>

        <MultiSelectDropdown
          options={cycleIDList?.map((item) => ({
            value: item?._id,
            label: item['Cycle ID'],
          }))}
          value={
            selectedOptions?.cycleId?.length > 0 &&
            selectedOptions?.cycleId?.map((option) => ({
              value: option?._id,
              label: option?.cyclePeriodicity,
            }))
          }
          onChange={(data) => handleChangeAttribute(data, 'cycleId')}
          isSelectAll={true}
          menuPlacement={"bottom"}
        />
      </FormControl>
      <FormControl
        fullWidth
        style={{ paddingBottom: '25px', marginTop: '5px' }}
      >
        <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Lag ID</Typography>

        <MultiSelectDropdown
          options={dimensionLagIDdata?.map((item) => ({
            value: item?._id,
            label: item['Lag ID'],
          }))}
          value={
            selectedOptions?.lagID?.length > 0 &&
            selectedOptions?.lagID?.map((option) => ({
              value: option?._id,
              label: option?.lagID,
            }))
          }
          onChange={(data) => handleChangeAttribute(data, 'lagID')}
          isSelectAll={true}
          menuPlacement={"bottom"}
        />
      </FormControl>
    </React.Fragment>
  );
};

export const CheckboxOption = (props) => {
  return (
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected} // reflects the selected state
        onChange={() => null} // this is handled by react-select itself
        sx={{ padding: '0 5px 0 0' }}
      />
      <label>{props.label}</label>
    </components.Option>
  );
};

const CustomMenu = (props) => {
  const { innerRef, children, ...rest } = props;
  return (
    <components.Menu {...rest} innerRef={innerRef}>
      {children}
    </components.Menu>
  );
};
