import React, { useEffect, useState } from 'react'
import { Toolbar, Typography, Grid, Select, IconButton, Collapse, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import PlanningDimensionModel from './PlanningDimensionModel';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import moment from 'moment';
import PlanningKeyAttribute from './PlanningKeyAttribute';
import { API_BASE_URL } from '../../../helper/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch } from 'react-redux';
import { fetchDimensions } from '../../../store/ApiSlice/dimensionSlice';
export default function PlanningLevelDimension({
    dimensionAttributes,
    setDimensionAttributes,
    isView,
    isEdit,
    isCreate,
    newData,
    formValues,
    setformValues,
    data,
    selectedDimension,
    selectedDimensions,
    setSelectedDimensions,
    setRefAttributeMapping,
    refAttributeMapping,
    dimenstionData,
    modelEnterprise
}) {
    const [showfPopup, setShowfPopup] = useState(false);
    const [expandedRow, setExpandedRow] = useState({})
    const [open, setOpen] = useState(false)
    const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
        useState(false);
    const [isDeleteDimensionAttribute, setIsDeleteDimensionAttribute] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [checkedDimensions, setCheckedDimensions] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const dispatch = useDispatch()

    const handleKeyChange = (event, row, keyToUpdate) => {
        let newValue = event.target.checked;
        setDimensionAttributes((prevArray) =>
            prevArray.map((obj, index) => {
                if (row._id === obj._id) {
                    if (keyToUpdate === "primaryKey") {
                        return {
                            ...obj,
                            [keyToUpdate]: newValue,
                            required: newValue ? true : false,
                        };
                    } else {
                        return { ...obj, [keyToUpdate]: newValue };
                    }
                } else {
                    return obj;
                }
            })
        );
    };

    const handlefAddClick = () => {
        const enterpriseId = modelEnterprise?.enterpriseUID
        const modelId = modelEnterprise?.modelUID
        if (modelEnterprise?.enterpriseUID && modelEnterprise?.modelUID) {
            setShowfPopup(true)
            dispatch(fetchDimensions({ enterpriseId, modelId }))
        } else {
            toast.error("First of all Select enterprise name and model name")
        }
    };

    const handleDeleteClick = () => {
        setIsDeleteDimensionModalOpen(true);
    };

    const handleDeleteAttributeClick = () => {
        setIsDeleteDimensionAttribute(true);
    };

    const handleDeleteAttributeSubmit = () => {
        let newFilteredRows = dimensionAttributes.filter((item) => {
            return !selectedRows.includes(item._id);
        });
        setDimensionAttributes(newFilteredRows);
        setIsDeleteDimensionAttribute(false);
    };

    const handleDeleteAttributeCloseModal = () => {
        setIsDeleteDimensionAttribute(false);
    };

    const handleDeleteDimensionCloseModal = () => {
        setIsDeleteDimensionModalOpen(false);
    };

    const handleDeleteSubmit = () => {
        let newFilteredRows = selectedDimensions.filter((item) => {
            return !selectedRows.includes(item._id)
        })

        let removedDimensions = selectedDimensions.filter((item) => {
            return selectedRows.includes(item._id)
        })

        let primaryKeysAttributeOfRemovedDimensions = []
        removedDimensions.forEach(dimension => {
            if (dimension.primaryKey && dimension.primaryKey.length)
                primaryKeysAttributeOfRemovedDimensions = [...primaryKeysAttributeOfRemovedDimensions, ...dimension.primaryKey]
        })
        let idsOfRemovedAttributes = primaryKeysAttributeOfRemovedDimensions.map(item => item._id)

        let leftAttributes = dimensionAttributes.filter(attrib => {
            return !idsOfRemovedAttributes.includes(attrib._id)
        })
        setDimensionAttributes([...leftAttributes])
        setSelectedDimensions(newFilteredRows);
        setIsDeleteDimensionModalOpen(false);
        setSelectedRows([])
    }

    const renderModalContent = () => {
        return (
            <Dialog
                open={isDeleteDimensionModalOpen}
                onClose={handleDeleteDimensionCloseModal}
            >
                <DialogTitle>Remove Attribute</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography variant="h6" component="h2">
                        Are You Sure For Remove This Attribute?
                        {/* This Is Irreversible. Are You Sure? */}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDimensionCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDeleteSubmit();
                        }}
                        color="primary" sx={{ textTransform: "capitalize" }}
                    >
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderModalAttributeContent = () => {
        return (
            <Dialog
                open={isDeleteDimensionAttribute}
                onClose={handleDeleteAttributeCloseModal}
            >
                <DialogTitle>Remove Attribute</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography variant="h6" component="h2">
                        Are You Sure For Remove This Attribute?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteAttributeCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDeleteAttributeSubmit();
                        }}
                        color="primary" sx={{ textTransform: "capitalize" }}
                    >
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleTableRowSelect = (event) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(id)
            ? selectedRows.filter((n) => n !== id)
            : [...selectedRows, id];
        setSelectedRows(updatedIds);
    };

    return (
        <>
            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Toolbar>
                <Typography variant="h6">Key Attribute</Typography>
            </Toolbar>
            <Grid item>
                <Typography
                    variant="h6"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        marginRight: "26px",
                    }}
                >
                    <Button
                        disabled={isView}
                        title="Add new dimension"
                        variant="text"
                        onClick={handlefAddClick}
                    >
                        Add
                    </Button>
                    <Button
                        disabled={isView}
                        title="Remove dimension"
                        variant="text"
                        onClick={handleDeleteClick}
                    >
                        Remove
                    </Button>
                </Typography>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Select</TableCell>
                            <TableCell align='center'>Dimension Name</TableCell>
                            <TableCell>Dimension Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedDimensions?.map((row) => (
                            <RowExpandData
                                key={row.name}
                                row={row}
                                isView={isView}
                                selectedRows={selectedRows}
                                handleTableRowSelect={handleTableRowSelect}
                                setSelectedDimensions={setSelectedDimensions}
                                setIsDeleteDimensionAttribute={setIsDeleteDimensionAttribute}
                                handleDeleteAttributeCloseModal={handleDeleteAttributeCloseModal}
                                isDeleteDimensionAttribute={isDeleteDimensionAttribute}
                                handleDeleteAttributeClick={handleDeleteAttributeClick}
                                selectedDimensions={selectedDimensions}
                                renderModalContent={renderModalContent}
                            />
                        ))}
                    </TableBody>
                </Table>
                {!selectedDimensions.length && <div className="centeredText">No dimensions selected yet</div>}
            </TableContainer>
            {showfPopup && (
                <PlanningDimensionModel
                    showfPopup={showfPopup}
                    setShowfPopup={setShowfPopup}
                    selectedDimensions={selectedDimensions}
                    setSelectedDimensions={setSelectedDimensions}
                    checkedDimensions={checkedDimensions}
                    setCheckedDimensions={setCheckedDimensions}
                    dimensionAttributes={dimensionAttributes}
                    setDimensionAttributes={setDimensionAttributes}
                    formValues={formValues}
                    newData={newData}
                    isEdit={isEdit}
                    isView={isView}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    handleTableRowSelect={handleTableRowSelect}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    modelEnterprise={modelEnterprise}
                />
            )}


        </>
    )
}

const RowExpandData = (props) => {
    const { row,
        isView,
        selectedRows,
        handleTableRowSelect,
        setSelectedDimensions,
        setIsDeleteDimensionAttribute,
        handleDeleteAttributeCloseModal,
        isDeleteDimensionAttribute,
        handleDeleteAttributeClick,
        selectedDimensions,
        renderModalContent } = props;
    const [open, setOpen] = React.useState(false);

    const renderModalAttributeContent = () => {
        return (
            <Dialog
                open={isDeleteDimensionAttribute}
                onClose={handleDeleteAttributeCloseModal}
            >
                <DialogTitle>Remove Attributes</DialogTitle>
                <DialogContent style={{ width: "500px", margin: "2px" }}>
                    <Typography variant="h6" component="h2">
                        Are You Sure For Remove This Attribute?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteAttributeCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDeleteAttributeSubmit();
                        }}
                        color="primary" sx={{ textTransform: "capitalize" }}
                    >
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    const handleDeleteAttributeSubmit = () => {

        const updatedDimensions = selectedDimensions.map(dim => {
            const newFilteredFields = dim.fields.filter(item => !selectedRows.includes(item._id));
            return {
                ...dim,
                fields: newFilteredFields
            };
        });

        setSelectedDimensions(updatedDimensions);
        setIsDeleteDimensionAttribute(false);
    };

    const handleKeyChange = (event, row, keyToUpdate) => {
        const newValue = event.target.checked;
        setSelectedDimensions(prevArray =>
            prevArray.map(obj => ({
                ...obj,
                fields: obj.fields.map(item => {
                    if (row._id === item._id) {
                        if (keyToUpdate === "primaryKey") {
                            return {
                                ...item,
                                [keyToUpdate]: newValue,
                                required: newValue ? true : false,
                            };
                        } else {
                            return { ...item, [keyToUpdate]: newValue };
                        }
                    } else {
                        return item;
                    }
                })
            }))
        );
    };


    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} colSpan={6}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Checkbox
                        checked={selectedRows.includes(row._id)}
                        value={row._id}
                        onChange={handleTableRowSelect}
                    />
                </TableCell>
                <TableCell align='center'>{row.name}</TableCell>
                <TableCell>{row.type}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Attribute
                                </Typography>
                                <Button
                                    title="Delete attribute from dimension"
                                    variant="text"
                                    onClick={handleDeleteAttributeClick}
                                >
                                    Remove
                                </Button>
                            </Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Select</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Type</TableCell>
                                        <TableCell align="right">Key</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.fields?.map((historyRow) => (
                                        <TableRow key={historyRow._id}>
                                            <TableCell component="th" scope="row">
                                                <Checkbox
                                                    checked={selectedRows.includes(historyRow?._id)}
                                                    value={historyRow?._id}
                                                    onChange={handleTableRowSelect}
                                                    disabled={historyRow?.fromCompound ? true : false}
                                                // disabled="true"
                                                />
                                            </TableCell>
                                            <TableCell>{historyRow.name}</TableCell>
                                            <TableCell align="right">{historyRow.type}</TableCell>
                                            <TableCell align="right">
                                                <Checkbox
                                                    checked={historyRow?.primaryKey ? true : false}
                                                    disabled={isView ? true : false}
                                                    value={historyRow?.primaryKey ? true : false}
                                                    onChange={(event) => {
                                                        handleKeyChange(event, historyRow, "primaryKey");
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            {renderModalContent()}
            {isDeleteDimensionAttribute && renderModalAttributeContent()}
        </>
    )
}


