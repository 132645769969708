import React, { useEffect, useState } from "react";
import { Toolbar, Typography, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Select, MenuItem } from "@mui/material";
import CreateTimeAttribute from "../CreateTimeAttribute";
import AttributesListModel from "./AttributesListModel";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  InputLabel
} from "@mui/material";
import moment from "moment/moment";

const CustomTimeAttribute = ({
  dimensionAttributes,
  setDimensionAttributes,
  isView,
  isEdit,
  newData,
  formValues,
  timeDimensionData,
  selectedDimension,
  data,
  formValueData
}) => {
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteDimensionModalOpen, setIsDeleteDimensionModalOpen] =
    useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const handleKeyChange = (event, row, keyToUpdate) => {
    const newValue = event.target.checked;
    setDimensionAttributes(prevArray => {
      return prevArray.map(obj => {
        if (obj._id === row._id) {
          if (keyToUpdate === "required") {
            return { ...obj, [keyToUpdate]: newValue, required: newValue }; // Set required to true or false based on newValue
          } else {
            return { ...obj, [keyToUpdate]: newValue };
          }
        }
        return obj;
      });
    });
  };

  const handleAddClick = () => {
    setModalOpen(true);
  };
  const handleNewClick = () => {
    setIsCreateUpdateModalOpen(true);
  };
  const handleDeleteClick = () => {
    setIsDeleteDimensionModalOpen(true);
  };
  const handleTableRowSelect = (event) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(id)
      ? selectedRows.filter((n) => n !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedIds);
  };

  const handleDeleteSubmit = () => {
    let newFilteredRows = dimensionAttributes.filter((item) => {
      return !selectedRows.includes(item._id);
    });
    setDimensionAttributes(newFilteredRows);
    setIsDeleteDimensionModalOpen(false);
  };
  const handleDeleteDimensionCloseModal = () => {
    setIsDeleteDimensionModalOpen(false);
  };

  const renderModalContent = () => {
    return (
      <Dialog
        open={isDeleteDimensionModalOpen}
        onClose={handleDeleteDimensionCloseModal}
      >
        <DialogTitle>Remove Attribute</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
            Are you sure for Remove this attribute?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDimensionCloseModal}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteSubmit();
            }}
            color="primary"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleModelNameChange = (event, row) => {
    const updatedRow = {
      ...row,
      'Period Type': event.target.value
    };
    const updatedArray = dimensionAttributes.map(item => item._id === row._id ? updatedRow : item);
    setDimensionAttributes(updatedArray)
  };

  const combinedAttributes = [...(selectedDimension?.timeCustomAttributes || []), ...(dimensionAttributes || [])];
  useEffect(() => {
    if (selectedDimension?.timeCustomAttributes) {
      setDimensionAttributes(selectedDimension?.timeCustomAttributes)
    }
  }, [selectedDimension?.timeCustomAttributes])
  return (
    <>
      <hr
        style={{
          borderTop: "2px solid #1976d2",
          width: "100%",
          margin: "20px auto",
        }}
      />
      <Toolbar>
        <Typography variant="h6">Additional Attributes</Typography>
      </Toolbar>
      <Grid item>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "26px",
          }}
        >
          <Button
            disabled={isView}
            title="Add new attribute in dimension"
            variant="text"
            onClick={handleAddClick}
          >
            Add
          </Button>
          <Button
            disabled={isView}
            title="Create new attribute and add in dimension"
            variant="text"
            onClick={handleNewClick}
          >
            New
          </Button>
          <Button
            disabled={isView}
            title="Delete attribute from dimension"
            variant="text"
            onClick={handleDeleteClick}
          >
            Remove
          </Button>
        </Typography>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {!isView && <TableCell align="left">Select</TableCell>}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Required</TableCell>

              <TableCell align="left">Period Type</TableCell>

              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Changed On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              dimensionAttributes
                .map((row) => (
                  <>
                    <TableRow
                      key={row?.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {!isView && (
                        <TableCell align="left">
                          <Checkbox
                            checked={selectedRows.includes(row?._id) || row?.required}
                            value={row._id}
                            onChange={handleTableRowSelect}
                            disabled={row.fromCompound ? true : false}
                          />
                        </TableCell>
                      )}
                      {!isView ? <TableCell align="left">{row?.AttributeUID?.name || row?.name}</TableCell> : <TableCell align="left">{row?.name} </TableCell>}
                      {!isView ? <TableCell align="left">{row?.AttributeUID?.type || row?.type}</TableCell> : <TableCell align="left">{row?.type}</TableCell>}
                      <TableCell align="left">
                        {!isView && (
                          <Checkbox
                            checked={row.required}
                            disabled={row.primaryKey || isView}
                            onChange={(event) => handleKeyChange(event, row, "required")}
                          />
                        )}
                      </TableCell>
                      {!isView && <TableCell align="left">
                        <Select
                          fullWidth
                          id="demo-simple-select-readonly"
                          labelId="PeriodType"
                          label="Period Type"
                          name="Period Type"
                          variant="outlined"
                          value={row["Period Type"]}
                          disabled={isView === "" ? true : false}
                          onChange={(event) => {
                            handleModelNameChange(event, row); // Call a function to handle the change
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Type
                          </MenuItem>
                          {timeDimensionData?.map((data1) => (
                            <MenuItem key={data1.Level} value={data1["Period Type"]}>
                              {data1["Period Type"]}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>}
                      <TableCell align="left">
                        {moment(row.createdOn).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row.changedOn).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
          </TableBody>
        </Table>
        {!dimensionAttributes?.length && (
          <div className="centeredText">No attributes selected yet</div>
        )}
      </TableContainer>

      {isModalOpen && (
        <AttributesListModel
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          formValues={formValues}
          isEdit={isEdit}
          newData={newData}
          formValueData={formValueData}
        />
      )}

      {isCreateUpdateModalOpen &&
        (
          <CreateTimeAttribute
            isCreateUpdateModalOpen={isCreateUpdateModalOpen}
            setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
            isEdit={false}
            model={isEdit}
            data={[]}
            formData={data}
            selectedRows={[]}
            formValueData={formValueData}
            selectedDimension={selectedDimension}
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            type={"dimension"}
          />
        )}
      {renderModalContent()}
    </>
  );
};

export default CustomTimeAttribute;
