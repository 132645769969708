import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createViewAggregated } from '../../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../loader/Loader';
import HighchartsMore from 'highcharts/highcharts-more';
import Heatmap from 'highcharts/modules/heatmap';
import Treemap from 'highcharts/modules/treemap';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  StackingColumnSeries,
  LineSeries,
  ColumnSeries,
  Legend,
  Tooltip,
  Category
} from "@syncfusion/ej2-react-charts";


Heatmap(Highcharts);
Treemap(Highcharts);
HighchartsMore(Highcharts);

const ChartMain = ({ groupName, setLoader, finalData, loader, aggregatedViewData, chartRefs, groupIndex }) => {

  const { filterDetail } = useSelector((state) => state.canvas);
  const { pending, chartPending } = useSelector((state) => state.widget)
  const dispatch = useDispatch();
  const [chartType, setChartType] = useState();

  const formatTimePeriods = (timePeriods) => {
    return (finalData?.widgetUID?.widgetSubType === "Measure Data" || finalData?.widgetUID?.widgetType === "Chart") && `Time Periods (${timePeriods})`;
  };
  const timePeriodsFormatted = formatTimePeriods(finalData?.widgetUID?.chart?.timePeriods?.periodType);
  const getChartWidgetsData = async (findFilterId) => {
    setLoader({
      ...loader,
      [groupName]: true
    });
    const timeName = finalData?.widgetUID?.chart?.timePeriods?.periodType;

    const payload = {
      ...(finalData?.widgetUID?.chart?.measures?.length && {
        measures: finalData?.widgetUID?.chart?.measures,
      }),
      timeFields: {
        [timeName]: finalData?.widgetUID?.chart?.timePeriods?.periodValues,
      },
      aggregationFields: finalData?.widgetUID?.chart?.attributes?.map(item => item?.attributeName),
      scenario: ['Baseline'],
      ...(finalData?.widgetUID?.cycleID?.length && {
        cycleID: finalData?.widgetUID?.cycleID,
      }),
      ...(finalData?.widgetUID?.lagID?.length && {
        lagID: finalData?.widgetUID?.lagID,
      }),
      ...(findFilterId?.attributes?.length && {
        additionalFilters: findFilterId?.attributes?.map(item => ({
          attribute: item?.attributeUID?.name,
          operator: item?.operator,
          values: item?.values,
        })),
      }),
    };  

    console.log("--------->", payload)

    const result = await dispatch(createViewAggregated({ payload }));
    if (result?.meta?.requestStatus === 'fulfilled') {
      setLoader({
        ...loader,
        [groupName]: false
      });
    } else if (result?.meta?.requestStatus === 'rejected') {
      toast.error(result?.error?.message);
      setLoader({
        ...loader,
        [groupName]: false
      });
    }
  };

  // useEffect(() => {
  //   if (filterDetail && (finalData?.widgetUID?.filter || finalData?.widgetUID?.filter?._id)) {

  //     const findFilterId = filterDetail?.find(item =>
  //       item?._id === finalData?.widgetUID?.filter || item?._id === finalData?.widgetUID?.filter?._id
  //     );
  //     if (findFilterId) {
  //       getChartWidgetsData(findFilterId);
  //     }
  //   } else {
  //     getChartWidgetsData("")
  //   }
  // }, [finalData?.widgetUID]);

  useEffect(() => {
    setChartType(finalData?.widgetUID.pivotTable.chartType);
  }, [finalData?.widgetUID.pivotTable.chartType]);

  let seriesData = [];
  let categories = [];
  let chartData = []
  let chartMeasureName = []
  let isPercentageData = false;
  const attributeDataName = finalData?.widgetUID.pivotTable?.attributes?.map((item) => item?.attributeName)
  const extractNumber = (str) => parseInt(str?.split('_')[1], 10);

  // Dynamic sort function
  const dynamicSort = (data, attributes) => {
    // Create a shallow copy of the array to avoid modifying the original
    const dataCopy = [...data];

    return dataCopy?.sort((a, b) => {
      for (const attribute of attributes) {
        const diff = extractNumber(a[attribute]) - extractNumber(b[attribute]);
        if (diff !== 0) {
          return diff;
        }
      }
      return 0; // If all comparisons are equal, they are the same in order
    });
  };

  // Sort the data based on dynamic attributes
  const sortedData = dynamicSort([...aggregatedViewData || []], attributeDataName);

  const state = finalData?.widgetUID.pivotTable?.attributes?.reduce((acc, attr, index) => {
    const attributeName = attr.attributeName;
    const key = `name${index + 1}`;

    const uniqueData = sortedData?.length > 0
      ? Array?.from(sortedData?.map(data => data[attributeName]))
      : [];

    acc[key] = uniqueData;
    return acc;
  }, {});

  if (sortedData?.length) {

    let dataByMeasure = {};

    sortedData.forEach(entry => {
      const measureName = entry["Measure Name"];

      const customerData = Object.keys(entry)
        .filter(key => key !== "Measure Name" && (key?.startsWith("M") || key?.startsWith("Q") || key?.startsWith("Y") || key?.startsWith("D") || key?.startsWith("W")))
        .map(key => {
          const value = entry[key];

          if (typeof value === 'string' && value?.includes('%')) {
            isPercentageData = true;
            return parseFloat(value?.replace('%', ''));
          } else if (value) {
            if (/\d/.test(value) && !value?.includes('%')) {
              isPercentageData = false;
              return Number(value);
            } else {
              return typeof value === 'number' ? value : 0;
            }
          }
          return typeof value === 'number' ? value : 0;
        });

      if (!dataByMeasure[measureName]) {
        dataByMeasure[measureName] = [];
      }
      dataByMeasure[measureName] = dataByMeasure[measureName]?.concat(customerData);

      if (!categories?.length) {
        categories = Object.keys(entry)?.filter(key => key !== "Measure Name" && (key?.startsWith("M") || key?.startsWith("Q") || key?.startsWith("Y") || key?.startsWith("D") || key?.startsWith("w")));
      }
    });

    seriesData = Object.keys(dataByMeasure)?.map(measureName => ({
      name: measureName,
      data: dataByMeasure[measureName]
    }));
  }

  const getDataSlices = () => {
    const columnCount = Math.min(3, seriesData.length);
    const lineCount = Math.min(1, seriesData.length - columnCount);
    const pieCount = Math.max(0, seriesData.length - columnCount - lineCount);
    return {
      columnData: seriesData.slice(0, columnCount),
      lineData: seriesData.slice(columnCount, columnCount + lineCount),
      pieData: seriesData.slice(columnCount + lineCount, columnCount + lineCount + pieCount),
    };
  };

  const { columnData, lineData, pieData } = getDataSlices();
  const splineData = seriesData?.length > 0 ? seriesData?.map((family, index) => {
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[family?.name] : '';
    const color = finalData?.widgetUID.pivotTable?.type === "spline" ? colorKey : 'rgba(0, 100, 200, 0.3)';
    return {
      type: "spline",
      name: family.name,
      data: family.data,
      color: color
    }
  }) : []

  const uniqueSeriesData = seriesData?.filter((family, index, self) =>
    index === self.findIndex(f => f.name === family.name)
  );

  const pendingData = chartPending?.length > 0 ? chartPending?.filter((item) => item?.field !== "Measure Name" && item?.field !== "Scenario" && item?.field !== "Cycle ID") : []

  const categoriesData = [];
  const productData = state?.name1
  for (let product of categories) {
    categoriesData.push(product);
    for (let month of productData) {
      categoriesData.push(month)
    }
  }

  const attributeIndexes = {};
  pendingData?.length > 0 && pendingData?.forEach((item, index) => {
    attributeIndexes[item.headerName] = index;
  });


  // const transformData = (data) => {
  //   const months = finalData?.widgetUID?.chart?.timePeriods?.periodValues;
  //   console.log("🚀 ~ transformData ~ months:", months)

  //   return data.map((entry) => ({
  //     scenario: entry.Scenario,
  //     data: months.map((month) => ({
  //       x: month,
  //       y: entry[month] ? parseFloat(entry[month]) : 0
  //     }))
  //   }));
  // };

  const transformData = (data, dynamicScenarios) => {
    const attributeMap = finalData?.widgetUID?.chart?.attributes?.reduce((acc, attr) => {
      acc[attr.attributeName] = attr.attributeUID;
      return acc;
    }, {});

    let groupedData = {};

    data.forEach((entry) => {
      const productId = entry[Object.keys(attributeMap)[0]]; // Make sure to use the right attribute for the productId.

      if (!groupedData[productId]) {
        groupedData[productId] = {};
      }

      Object.keys(entry).forEach((key) => {
        if (key.startsWith("M")) {  // If it's a month key (e.g., M1 2024)
          if (!groupedData[productId][key]) {
            groupedData[productId][key] = { month: key };
          }

          // Initialize dynamic scenarios only if they aren't already present
          dynamicScenarios.forEach((scenario) => {
            if (!groupedData[productId][key][scenario]) {
              groupedData[productId][key][scenario] = 0;  // Initialize all dynamic scenarios as 0
            }
          });

          const value = entry[key] ? parseFloat(entry[key]) : 0;
          const scenario = entry["Scenario"];  // This should match exactly with "Baseline", "New Customer", "Upside"


          // Ensure the scenario exists in the groupedData object for this month
          if (groupedData[productId][key][scenario] !== undefined) {
            groupedData[productId][key][scenario] += value;  // Add the value to the corresponding scenario
          }
        }
      });
    });


    let stackedData = [];
    let lineData = [];

    Object.keys(groupedData).forEach((productId) => {
      Object.values(groupedData[productId]).forEach((dataPoint) => {
        const row = { productId, month: dataPoint.month };

        // Push each dynamic scenario value into the stackedData
        dynamicScenarios.forEach((scenario) => {
          row[scenario] = dataPoint[scenario];
        });

        stackedData.push(row);

        // Sample line calculation (sum of all scenario values)
        lineData.push({
          x: `${productId} - ${dataPoint.month}`,
          y: dynamicScenarios.reduce((sum, scenario) => sum + dataPoint[scenario], 0),
        });
      });
    });

    return { stackedData, lineData };
  }

  const dynamicScenarios = finalData?.widgetUID?.scenario;
  const { stackedData } = transformData(aggregatedViewData, dynamicScenarios)
  const transformedData = stackedData?.filter((item) => item?.[finalData?.widgetUID?.chart?.timePeriods?.periodType?.toLowerCase()] !== "Measure Name")
  const toCamelCase = (str) => {
    return str
      .split(' ')  // Split the string by spaces
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join('');
  };

  return (
    <>
      {loader ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </Box>
      ) : (
        <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
          <ChartComponent
            ref={(el) => (chartRefs.current[groupIndex] = el)}
            width="100%"
            height="100%"
            primaryXAxis={{
              valueType: "Category",
              labelIntersectAction: "Rotate45",
            }}
            tooltip={{ enable: true }}
            legendSettings={{ visible: false }}
          >
            <Inject services={[StackingColumnSeries, LineSeries, Category, Tooltip, Legend]} />
            <SeriesCollectionDirective>
              {finalData?.widgetUID?.scenario?.map((item, index) => {
                const yField = toCamelCase(item);
                return (
                  <SeriesDirective
                    key={index}
                    dataSource={transformedData}
                    xName={finalData?.widgetUID?.chart?.timePeriods?.periodType.toLowerCase()}
                    yName={item}
                    name={item}
                    type="StackingColumn"
                  />
                )
              })}
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      )}
    </>
  );
};

export default ChartMain;