import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "react-toastify/dist/ReactToastify.css";
import { registerAllModules } from "handsontable/registry";
import DeleteDimension from "./DeleteDimension";
import CreateUpdateDimension from "./CreateDimension/CreateDimension";
import "../../style/common.css";
import { API_BASE_URL } from "../../helper/constants";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Autocomplete, Box, FormControl, Grid, TextField, Tooltip, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Shuffle } from "../../components/attributes/shuffule";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DimensionsMaterialList from "./DimensionsMaterialList";
import { useDispatch } from "react-redux";
import { createPlanningObject, getModelListAsync, getPlanningObjects } from "../../store/ApiSlice/dimensionSlice";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";
import { ThemeContext } from "../../ThemeContext";
import LevelPlanningObjects from "./LevelPlanningObjects";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeletePlanningObject from "./DeletePlanningObject";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const coloumNames = [
  "Attribute name",
  "Type",
  "Notes",
  "ChangedOn",
  "CreatedOn",
  "enterpriseName",
  "modelName",
];

const DimensionList = ({ modelEnterprise }) => {
  const { planningObjectsData, totalPlanningObject, planningObjectMasterData } = useSelector((state) => state.dimension);
  const userData = JSON.parse(localStorage.getItem("accessToken"))
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [checkedItems, setCheckedItems] = useState(coloumNames);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [updatedId, setUpdatedId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])
  const [selectNewData, setSelectNewData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [managePlanningObjectModel, setManagePlanningObjectModel] = useState(false);
  const [createPlanningObjectModel, setCreatePlanningObjectModel] = useState(false);
  const [planningObjectData, setPlanningObjectData] = useState()
  const [formValues, setFormValues] = useState({})
  const [planningObjectID, setPlanningObjectID] = useState(null)
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [isEditPlanningObject, setIsEditPlanningObject] = useState(false);
  const [deletePlanningObject, setDeletePlanningObject] = useState(false);
  const checkList = coloumNames;
  const dispatch = useDispatch();
  // const { loading, error, modelList } = useSelector((state) => state.dimension);
  const { dimensionData } = useSelector((state) => state?.dimension);

  useEffect(() => {
    dispatch(getModelListAsync());
  }, []);

  useEffect(() => {
    registerAllModules();
  }, [isCreate]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    handleCloseModal();
  };

  const handleChange = (event) => {
    setCheckedItems(
      event.target.checked
        ? [...checkedItems, event.target.name]
        : checkedItems.filter((item) => item !== event.target.name)
    );
  };


  const handleTableRowSelect = (event, row) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    setSelectedRows(updatedIds);
  };
  const handleCreateUpdate = (edit, id) => {
    setUpdatedId(id);
    setIsEdit(edit);
    setIsCreate(true);
  };

  const handleCreateUpdatePlanningObject = (edit, id) => {
    setPlanningObjectID(id)
    setIsEditPlanningObject(edit)
    setCreatePlanningObjectModel(true)
  }
  const handleView = (id) => {
    setUpdatedId(id);
    setIsView(true);
  };
  const handleOpenModal = (row) => {
    setSelectNewData(row)
    setOpenModal(true);
  };

  const handleCloseModals = () => {
    setOpenModal(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileInput = document.getElementById("file-input");
    const file1 = fileInput.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
    };
  };

  const handleUploadFile = async (selectedData) => {
    if (selectedData !== null) {
      try {
        setLoader(true)
        const fileInput = document.getElementById("file-input");
        const file = fileInput.files[0];
        // Read the content of the Excel file
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          const userId = localStorage.getItem("accessToken");
          const config = {
            headers: {
              Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
          };
          const updatedData = {
            enterpriseName:
              selectNewData?.original?.entermodelUID?.enterpriseName,
            modelName: selectNewData?.original?.entermodelUID?.modelName,
            dimensionName: selectNewData?.original?.name,
            documentsArray: jsonData,
            clearAll: true,
          };
          axios
            .post(
              `${API_BASE_URL}/dataload/upload`,
              updatedData, // Send the JSON data directly in the request body
              config
            )
            .then((data) => {
              if (data.status) {
                if (data?.data?.data?.erroredItems?.length > 0) {
                  setErrorMessage(data?.data?.data)
                  downloadErrorExcel(data?.data?.data.erroredItems);
                  setLoader(false)
                  setOpenErrorModal(true)
                } else {
                  // toast.success("Successfully data uploaded");
                  handleCloseModals();
                  setLoader(false)
                }
              }
              // Handle successful upload
            })
            .catch((error) => {
              setLoader(false)

              if (
                error?.response &&
                error?.response?.data &&
                error?.response?.data?.data
              ) {
                setLoader(false)
                toast.error(error.response.data.data);
              } else {
                setLoader(false)
                toast.error("Failed to upload data. Please try again later.");
              }
              handleCloseModals();
            });
        };

        reader.readAsArrayBuffer(file);
      } catch (error) {
        setLoader(false)
      }
    }
  };

  // Event handler for the "Download" button
  const handleDownload = (row) => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
      },
    };
    axios
      .get(`${API_BASE_URL}/dataload/${row?.original?._id}`, config)
      .then((items) => {
        const headers = Object.keys(items?.data?.schemaInfo)
          .filter(key => items.data.data[0].hasOwnProperty(key))
          .filter((key) => key !== "_id" && key !== "__v")
          .sort((a, b) => items?.data?.schemaInfo[a].order - items?.data?.schemaInfo[b].order);
        // const headers = keys
        //   .filter((key) => key !== "_id" && key !== "__v")
        //   .sort();
        const rows = items.data.data.map((obj) => {
          let temp = {};
          headers.forEach((key) => {
            temp[key] = obj[key];
          });
          return Object.values(temp);
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        XLSX.utils.book_append_sheet(wb, ws, "Dimensions");

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

        // Trigger file download
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });
        saveAs(blob, `vyan_${row?.original?.name}.xlsx`);
      })
      .catch((error) => {
      });
    // Prepare table data - Replace 'data' with your actual table data array
  };

  const downloadErrorExcel = (erroredItems) => {
    const transformedData = erroredItems.map(item => {
      const combined = { ...item.item };
      for (const [key, value] of Object.entries(item.errors)) {
        combined[`${key}`] = value;
      }
      return combined;
    });
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Errors");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'errored_items.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Function to convert string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  // Function to trigger file download
  const saveAs = (blob, filename) => {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleChangePlanning = (newValue, fieldName, event) => {
    const value = event?.target?.value;
    if (fieldName === "Valid To" || fieldName === "Valid From") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: newValue ? moment(newValue).format("DD-MM-YYYY") : null,
      }));
    } else {
      setFormValues({
        ...formValues,
        [fieldName]: newValue,
      })
    }
  };

  const selectPlanningObject = planningObjectsData?.length > 0 ? planningObjectsData?.find((item) => item?._id === planningObjectID) : {}

  useEffect(() => {
    if (isEditPlanningObject && selectPlanningObject) {
      setFormValues({ ...selectPlanningObject });
    }
  }, [isEditPlanningObject, selectPlanningObject, planningObjectMasterData]);

  const handlecreatePlanningObject = async () => {
    const payload = {
      enterpriseUID: planningObjectData?.enterpriseUID?._id,
      modelUID: planningObjectData?.entermodelUID.modelUID,
      dimensionUID: planningObjectData?._id,
      documentsArray: isEditPlanningObject
        ? [{
          ...formValues,
          "Valid From": moment(formValues["Valid From"]).format("DD-MM-YYYY"),
          "Valid To": formValues["Valid To"] && moment(formValues["Valid To"], "DD-MM-YYYY").isValid()
            ? moment(formValues["Valid To"], "DD-MM-YYYY").format("DD-MM-YYYY")
            : null,
          _id: selectPlanningObject?._id
        }]
        : [{
          ...formValues,
          "Valid From": moment(formValues["Valid From"]).format("DD-MM-YYYY"),
          "Valid To": formValues["Valid To"] && moment(formValues["Valid To"], "DD-MM-YYYY").isValid()
            ? moment(formValues["Valid To"], "DD-MM-YYYY").format("DD-MM-YYYY")
            : null,
        }]
    }
    const result = await dispatch(createPlanningObject({ payload, isEditPlanningObject, planningObjectID }))
    if (result?.meta?.requestStatus === 'fulfilled') {
      setFormValues({})
      setCreatePlanningObjectModel(false)
      setIsEditPlanningObject(false)
      dispatch(getPlanningObjects({ page: 1, limit: 10, id: planningObjectData?._id }));
    } else if (result.meta.requestStatus === 'rejected') {
      toast.error(result?.error?.message)
    }
  }

  const formattedDate = formValues["Valid To"] && moment(formValues["Valid To"], "DD-MM-YYYY").isValid()
    ? moment(formValues["Valid To"], "DD-MM-YYYY") // Parse with correct format
    : null

  return (
    <>
      {!isCreate && !isView && !managePlanningObjectModel && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width: 768px)": {
                flexDirection: "column",
              },
            }}
          >
            <h3 className="heading"> Dimensions</h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Dialog
                open={openModal}
                onClose={handleCloseModals}
                sx={{ padding: "20px" }}
              >
                <DialogTitle>Upload File</DialogTitle>
                <DialogContent>
                  <input
                    id="file-input"
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                  />
                </DialogContent>
                <DialogActions sx={{ gap: "10px" }}>
                  <Button
                    onClick={handleCloseModals}
                    sx={{
                      color: "#8280bd",
                      margin: "0 4px",
                      border: "1px solid #8280bd",
                    }}
                  >
                    Cancel
                  </Button>
                  {loader ? <Loader /> : (
                    <Button
                      onClick={handleUploadFile}
                      variant="contained"
                      autoFocus
                      className="awesome-button"
                    >
                      Upload
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
              <Tooltip title="Add Dimension">
                <Button
                  onClick={() => {
                    handleCreateUpdate(false);
                  }}
                  className="awesome-button"
                  sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                >
                  +
                </Button>
              </Tooltip>
              <DeleteDimension
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setModalDeleteOpen={setModalDeleteOpen}
                isModalDeleteOpen={isModalDeleteOpen}
                updatedId={updatedId}
              // getDimensions={getDimensions}
              />
            </div>
          </Box>
          <DimensionsMaterialList
            modelEnterprise={modelEnterprise}
            data={dimensionData}
            handleTableRowSelect={handleTableRowSelect}
            setModalDeleteOpen={setModalDeleteOpen}
            handleOpenModal={handleOpenModal}
            handleDownload={handleDownload}
            handleCreateUpdate={handleCreateUpdate}
            handleView={handleView}
            setUpdatedId={setUpdatedId}
            setManagePlanningObjectModel={setManagePlanningObjectModel}
            setCreatePlanningObjectModel={setCreatePlanningObjectModel}
            setPlanningObjectData={setPlanningObjectData}
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <>
              <DialogTitle>View Setting</DialogTitle>
              <hr />
              <DialogContent>
                <DndProvider backend={HTML5Backend}>
                  <Shuffle
                    checkList={checkList}
                    checkedItems={checkedItems}
                    handleChange={handleChange}
                  />
                </DndProvider>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </>
          </Dialog>
        </>
      )}
      {managePlanningObjectModel && (
        <>
          <div style={{ color: 'black', cursor: "pointer" }} onClick={() => {
            setManagePlanningObjectModel(false);
          }}>
            <ChevronLeftIcon />
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width: 768px)": {
                flexDirection: "column",
              },
              paddingBottom: "15px"
            }}
          >
            <h3 className="heading">Manage Planning Objects</h3>
            <Box>
              <Button
                onClick={() => {
                  setCreatePlanningObjectModel(true);
                }}
                className={"awesome-button"}
                sx={{
                  color: "white",
                  margin: "0 1px",
                  fontSize: "1.875rem !important",
                }}
              >
                +
              </Button>{" "}
            </Box>
            {deletePlanningObject && (
              <DeletePlanningObject
                planningObjectID={planningObjectID}
                setPlanningObjectID={setPlanningObjectID}
                setDeletePlanningObject={setDeletePlanningObject}
                planningObjectData={planningObjectData}
              // getDimensions={getDimensions}
              />
            )}
          </Box>
          <LevelPlanningObjects
            planningObjectData={planningObjectData}
            setPlanningObjectData={setPlanningObjectData}
            setCreatePlanningObjectModel={setCreatePlanningObjectModel}
            handleCreateUpdatePlanningObject={handleCreateUpdatePlanningObject}
            setPlanningObjectID={setPlanningObjectID}
            setDeletePlanningObject={setDeletePlanningObject}
          />
        </>
      )}
      {createPlanningObjectModel && (
        <Dialog
          open={createPlanningObjectModel}
          onClose={() => {
            setCreatePlanningObjectModel(false)
          }}
          maxWidth={"md"}
        >
          <DialogTitle>
            <Box sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography>
                {isEditPlanningObject ? "Edit Planning Object" : "Create Planning Object"}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    setCreatePlanningObjectModel(false)
                  }}
                  sx={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              {planningObjectMasterData?.requiredFields?.length > 0 && planningObjectMasterData?.requiredFields?.filter((item) =>
                !["Valid From"]?.includes(item))
                ?.filter((item) => !["Valid To"]?.includes(item))
                ?.map((item, index) => (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="demo-simple-select-readonly"
                        labelId={item}
                        name={item}
                        options={planningObjectMasterData?.compoundDimensionData[item]}
                        getOptionLabel={(option) => option}
                        value={
                          formValues?.[item]
                            ? planningObjectMasterData?.compoundDimensionData[item]?.find(
                              (option) =>
                                option === formValues?.[item]
                            ) || null
                            : null
                        }
                        onChange={(e, newValue) => {
                          handleChangePlanning(newValue, item, e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={item}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                ))}
              {planningObjectMasterData?.schemaInfo?.["Valid From"]?.required && (
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Valid From"
                      value={formValues["Valid From"] ? moment(formValues["Valid From"]) : null}
                      onChange={(newValue) => handleChangePlanning(newValue, "Valid From", null)}
                      format={userData?.data?.dateFormat || "DD-MM-YYYY"} // Dynamic date format
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: "outlined",
                          InputLabelProps: { shrink: true },
                          placeholder: userData?.data?.dateFormat || "DD-MM-YYYY", // Show the dynamic format as a placeholder
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
              {planningObjectMasterData?.schemaInfo?.["Valid To"]?.required && (
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Valid To"
                      value={
                        formattedDate !== "Invalid Date" ? formattedDate : formValues["Valid To"] ? moment(formValues["Valid To"]) : null // Handle invalid or missing dates
                      }
                      onChange={(newValue) => handleChangePlanning(newValue, "Valid To", null)}
                      format={userData?.data?.dateFormat || "DD-MM-YYYY"} // Dynamic date format
                      minDate={
                        formValues["Valid From"] && moment(formValues["Valid From"], "DD-MM-YYYY").isValid()
                          ? moment(formValues["Valid From"], "DD-MM-YYYY") // Ensure correct parsing for minDate
                          : null // Handle invalid or missing dates
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: "outlined",
                          InputLabelProps: { shrink: true },
                          placeholder: userData?.data?.dateFormat || "DD-MM-YYYY", // Show the dynamic format as a placeholder
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setCreatePlanningObjectModel(false)
              setIsEditPlanningObject(false)
              setFormValues({})
            }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handlecreatePlanningObject}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {(isCreate || isView) && (
        <CreateUpdateDimension
          setIsCreate={setIsCreate}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          isCreate={isCreate}
          selectedRows={updatedId}
          data={dimensionData}
          isView={isView}
          setUpdatedId={setUpdatedId}
          handleView={handleView}
          setIsView={setIsView}
        />
      )}
    </>
  );
};

export default DimensionList;
