// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-pivotview {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 400px) {
    #pivot-table-section {
        overflow: auto;
    }
}

#PivotViewcontainerwrapper {
    height: auto !important;
}

.e-pivotview .e-hyperlinkcell {
    -webkit-text-decoration: auto;
            text-decoration: auto;
}

.e-pivotview .e-hyperlinkcell:hover {
    text-decoration: underline;
}

.e-pivotview .university-logo {
    border-radius: 50%;
    margin-right: 5px;
}

.urllink {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/component/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,6BAAqB;YAArB,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".e-pivotview {\n    width: 100%;\n    height: 100%;\n}\n\n@media only screen and (max-width: 400px) {\n    #pivot-table-section {\n        overflow: auto;\n    }\n}\n\n#PivotViewcontainerwrapper {\n    height: auto !important;\n}\n\n.e-pivotview .e-hyperlinkcell {\n    text-decoration: auto;\n}\n\n.e-pivotview .e-hyperlinkcell:hover {\n    text-decoration: underline;\n}\n\n.e-pivotview .university-logo {\n    border-radius: 50%;\n    margin-right: 5px;\n}\n\n.urllink {\n    float: right;\n    margin-right: 10px;\n    margin-top: 10px;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
