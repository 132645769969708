import { alignProperty } from "@mui/material/styles/cssUtils";
import { useRef, useState } from "react";
import { default as ReactSelect, components, InputAction } from "react-select";

const MultiSelectDropdown = (props) => {
    const [selectInput, setSelectInput] = useState("");
    const isAllSelected = useRef(false);
    const selectAllLabel = useRef("Select all");
    const allOption = { value: "*", label: selectAllLabel.current };

    const filterOptions = (options, input) =>
        options?.length > 0 && options?.filter(({ label }) =>
            String(label).toLowerCase().includes(String(input)?.toLowerCase()) // Ensure label is a string
        );
    const comparator = (v1, v2) =>
        (v1.value) - (v2.value);

    let filteredOptions = filterOptions(props.options, selectInput);
    let filteredSelectedOptions = filterOptions(props.value, selectInput);

    const Option = (props) => {
        const selectedValues = Array.isArray(props.selectProps?.value)
            ? props.selectProps.value.map((opt) => opt.value)
            : [];
        return (
            <components.Option {...props}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {props.value === "*" &&
                        !isAllSelected?.current &&
                        filteredSelectedOptions?.length > 0 ? (
                        <input
                            key={props.value}
                            type="checkbox"
                            ref={(input) => {
                                if (input) input.indeterminate = true;
                            }}
                        />
                    ) : (
                        <input
                            key={props.value}
                            type="checkbox"
                            checked={props.isSelected || selectedValues?.includes(props?.data?.value)}
                            onChange={() => { }}
                        />
                    )}
                    <label style={{ marginLeft: "5px" }}>{props.label}</label>
                </div>
            </components.Option>
        )
    };

    const Input = (props) => (
        <>
            {selectInput.length === 0 ? (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            ) : (
                <div style={{ border: "1px dotted gray" }}>
                    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                        {props.children}
                    </components.Input>
                </div>
            )}
        </>
    );

    const customFilterOption = ({ value, label }, input) => {
        // Convert label to string and handle cases where it's a number or other types
        const labelStr = String(label);

        return (
            (value !== "*" && labelStr.toLowerCase().includes(String(input)?.toLowerCase())) ||
            (value === "*" && filteredOptions?.length > 0)
        );
    };

    const onInputChange = (
        inputValue,
        event
    ) => {
        if (event.action === "input-change") setSelectInput(inputValue);
        else if (event.action === "menu-close" && selectInput !== "")
            setSelectInput("");
    };

    const onKeyDown = (e) => {
        if ((e.key === " " || e.key === "Enter") && !selectInput)
            e.preventDefault();
    };

    const handleChange = (selected) => {
        // Ensure value and options are arrays
        const value = Array.isArray(props.value) ? props.value : [];
        const options = Array.isArray(props.options) ? props.options : [];

        // Handle clearing all options when "Select All" is deselected
        if (
            Array.isArray(selected) &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value &&
            isAllSelected.current
        ) {
            // Deselect all when "Select All" is clicked again
            isAllSelected.current = false;
            return props.onChange([]);
        }

        // Handle select all or individual select/deselect
        if (Array.isArray(selected) && selected.length > 0) {
            if (
                selected[selected.length - 1].value === allOption.value ||
                JSON.stringify(filteredOptions) === JSON.stringify(selected.sort(comparator))
            ) {
                // Select all when "Select All" is selected
                isAllSelected.current = true;
                return props.onChange(
                    options.filter(
                        ({ label }) =>
                            String(label)?.toLowerCase().includes(String(selectInput)?.toLowerCase()) &&
                            value.filter((opt) => opt.label === label).length === 0
                    ).sort(comparator)
                );
            } else {
                // Handle individual selections/deselections
                isAllSelected.current = false;
                return props.onChange(selected);
            }
        }

        // Handle when user clears all selections manually
        isAllSelected.current = false;
        return props.onChange([]);
    };

    const customStyles = {
        multiValueLabel: (def) => ({
            ...def,
            backgroundColor: "lightgray",
            fontSize: "11px"
        }),
        multiValueRemove: (def) => ({
            ...def,
            backgroundColor: "lightgray",
            fontSize: "11px"
        }),
        valueContainer: (base) => ({
            ...base,
            maxHeight: "65px",
            overflow: "auto",
            fontSize: "11px"
        }),
        option: (styles, { isSelected, isFocused }) => {
            return {
                ...styles,
                backgroundColor:
                    isSelected && !isFocused
                        ? null
                        : isFocused && !isSelected
                            ? styles.backgroundColor
                            : isFocused && isSelected
                                ? "#DEEBFF"
                                : null,
                color: isSelected ? null : null,
                fontSize: "11px",

            };
        },
        menu: (def) => ({ ...def, zIndex: 9999, fontSize: "11px" }),
    };

    if (props.isSelectAll && props.options.length !== 0) {
        isAllSelected.current =
            JSON.stringify(filteredSelectedOptions) ===
            JSON.stringify(filteredOptions);

        if (filteredSelectedOptions?.length > 0) {
            if (filteredSelectedOptions?.length === filteredOptions?.length)
                selectAllLabel.current = `All (${filteredOptions.length}) selected`;
            else
                selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
        } else selectAllLabel.current = "Select all";

        allOption.label = selectAllLabel.current;

        return (
            <ReactSelect
                {...props}
                inputValue={selectInput}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                options={[allOption, ...props.options]}
                onChange={handleChange}
                components={{
                    Option: Option,
                    Input: Input,
                    ...props.components,
                }}
                filterOption={customFilterOption}
                menuPlacement={props.menuPlacement ?? "auto"}
                styles={customStyles}
                isMulti
                closeMenuOnSelect={false}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                isDisabled={!props.cyclePeriodicity}
            />
        );
    }

    return (
        <ReactSelect
            {...props}
            inputValue={selectInput}
            onInputChange={onInputChange}
            filterOption={customFilterOption}
            components={{
                Input: Input,
                ...props.components,
            }}
            menuPlacement={props.menuPlacement ?? "auto"}
            onKeyDown={onKeyDown}
            tabSelectsValue={false}
            hideSelectedOptions={true}
            backspaceRemovesValue={false}
            blurInputOnSelect={true}
            isDisabled={!props.cyclePeriodicity}
            styles={customStyles}
        />
    );
};

export default MultiSelectDropdown;