import { AppBar, Autocomplete, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Toolbar, Typography } from '@mui/material'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProcessList, getProgressStatusList } from '../../store/ApiSlice/processSlice'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getUserList } from '../../store/ApiSlice/userSlice'
import { MaterialReactTable } from 'material-react-table'
import MiniDrawer from '../common/drawer/DrawerToggle'
import { ThemeContext } from '../../ThemeContext'
import { useLocation, useParams } from 'react-router-dom'

const ProgressStatus = () => {
  const progressIDs = localStorage.getItem("processID")
  const currentDate = moment();
  const { isDarkTheme } = useContext(ThemeContext);
  const { processData, progressStatusList } = useSelector((state) => state.process)
  const startOfWeek = currentDate.startOf('week').format('YYYY-MM-DD');
  console.log("🚀 ~ ProgressStatus ~ progressStatusList:", progressStatusList)
  const endOfWeek = currentDate.endOf('week').format('YYYY-MM-DD');
  const [selectedProcess, setSelectedProcess] = useState({
    processUID: progressIDs,
    userUID: "",
    from: startOfWeek,
    to: endOfWeek,
  })
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user)

  // const progressStatusList = [{
  //   "_id": "67a94def4845c9b41a3c880b",
  //   "processUID": "6757070233d472ad97ba8107",
  //   "processName": "M5-1 Process with 10 Combinations",
  //   "requestUserName": "System Admin",
  //   "requestUser": "66faa552e096c43a48e11fc9",
  //   "status": "Terminated",
  //   "startTime": "02/09/2025 16:53:033 PM",
  //   "endTime": "02/09/2025 16:53:5959 PM",
  //   "steps": [
  //     {
  //       "stepOrder": 1,
  //       "stepName": "M5-1 Profile with 10 Combinations",
  //       "status": "In-Progress",
  //       "_id": "67a94def0bae0b58e90275d4"
  //     },
  //     {
  //       "stepOrder": 1,
  //       "stepName": "M5-1 Profile with 9 Combinations",
  //       "status": "In-Progress",
  //       "_id": "67a94def0bae0b58e90275d4"
  //     }
  //   ],
  //   "details": [
  //     {
  //       "no": 0,
  //       "processStep": "M5-1 Profile with 10 Combinations",
  //       "processActivity": "abc",
  //       "processTask": "complete",
  //       "progressStatus": "Scheduled",
  //       "_id": "67a94defadc0bab2b3958952"
  //     },
  //     {
  //       "no": 0,
  //       "processStep": "M5-1 Profile with 9 Combinations",
  //       "processActivity": "def",
  //       "processTask": "complete",
  //       "progressStatus": "Scheduled",
  //       "_id": "67a94defadc0bab2b3958951"
  //     }
  //   ],
  //   "logs": ""
  // }]

  const handleOpenModal = () => {
    const From = moment(selectedProcess?.from)?.format("DD-MM-YYYY")
    const To = moment(selectedProcess?.to)?.format("DD-MM-YYYY")
    const payload = {
      ...(selectedProcess?.processUID && selectedProcess?.userUID && ({
        processUID: selectedProcess?.processUID,
        userUID: selectedProcess?.userUID,
      })),
      ...(From && To && ({
        from: From,
        to: To,
      }))
    };
    dispatch(getProgressStatusList(payload))
      .unwrap()
      .then(() => {
        console.log("Progress status list fetched successfully");
      })
      .catch((error) => {
        console.error("Failed to fetch progress status list:", error);
      });
  }

  useEffect(() => {
    dispatch(getUserList())
    dispatch(getProcessList())
  }, [])

  const handleChangeProcess = (newValue, fieldName, e) => {
    if (fieldName === "from" || fieldName === "to") {
      setSelectedProcess((prevState) => ({
        ...prevState,
        [fieldName]: e.target.value,
      }));
    } else {
      setSelectedProcess({
        ...selectedProcess,
        [fieldName]: newValue?._id
      })
    }
  }

  const columns = [
    { accessorKey: '_id', header: 'Process Run ID' },
    { accessorKey: 'processName', header: 'Process' },
    { accessorKey: 'stepName', header: 'Process Step' },
    { accessorKey: 'processActivity', header: 'Process Activity' },
    { accessorKey: 'processTask', header: 'Process Task' },
    { accessorKey: 'status', header: 'Status' },
    { accessorKey: 'startTime', header: 'Start' },
    { accessorKey: 'endTime', header: 'End' },
    { accessorKey: 'duration', header: 'Duration' },
    { accessorKey: 'runLog', header: 'Run Log' },
    { accessorKey: 'errorLog', header: 'Error Log' }
  ];

  // const additionalColumns = [
  //   { accessorKey: 'stepName', header: 'Process Step' },
  //   { accessorKey: 'activityName', header: 'Process Activity' },
  //   { accessorKey: 'taskName', header: 'Process Task' }
  // ];

  const treeData = progressStatusList?.map(process => ({
    ...process,
    startTime: moment(process?.startTime, "MM/DD/YYYY hh:mm:ssSS A").format("MM/DD/YYYY hh:mm:ss A"),
    endTime: moment(process?.endTime, "MM/DD/YYYY hh:mm:ssSS A").format("MM/DD/YYYY hh:mm:ss A"),
    subRows: process?.steps.map(step => ({
      stepName: step?.stepName,
      status: step?.status,
      subRows: process?.details
        .filter(detail => detail?.processStep === step?.stepName)
        .map(detail => ({
          processActivity: detail?.processActivity || "-",
          progressStatus: detail?.progressStatus || "-",
          processTask: detail?.processTask || "-"
        }))
    }))
  }));

  const TreeTable = () => {
    return (
      <MaterialReactTable
        columns={columns}
        data={treeData}
        enableExpanding
        initialState={{ expanded: {} }}
        rowCount={progressStatusList?.length}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            backgroundColor: row.depth === 1
              ? '#f9f9f9'
              : row.depth === 2
                ? '#e0f7fa'
                : row.depth === 3
                  ? '#ffe0b2'
                  : 'white',
          },
        })}
        muiTableContainerProps={{
          className: "custom-scrollbar"
        }}
      />
    );
  };

  const labelTransparentStyle = {
    '& .MuiInputLabel-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputLabel-shrink': {
      backgroundColor: 'transparent',
    }
  };

  return (
    <>
      {/* <Box>
        <Box sx={{ display: "flex" }}>
          <MiniDrawer />
          <Box component="main" sx={{
            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
            background: isDarkTheme ? "whitesmoke" : "white",
          }}> */}
      <div style={{
        paddingBottom: "20px",
        position: "relative"
      }}>
        <h3 className="heading">Process Run Status</h3>
        <Container style={{ display: "grid", paddingBottom: "20px" }}>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={6}>
              <TextField
                id="from"
                label="From"
                name="from"
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={labelTransparentStyle}
                value={moment(selectedProcess?.from).format("YYYY-MM-DD")}
                onChange={(e, newValue) => {
                  handleChangeProcess(newValue, "from", e);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="to"
                label="To"
                name='to'
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={labelTransparentStyle}
                value={moment(selectedProcess?.to)?.format("YYYY-MM-DD")}
                onChange={(e, newValue) => {
                  handleChangeProcess(newValue, "to", e);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="demo-simple-select-readonly"
                labelId="Run By"
                name="userUID"
                options={userData}
                getOptionLabel={(option) => option.userName}
                value={selectedProcess?.userUID ?
                  userData?.find(option => option?._id === selectedProcess.userUID)
                  || null : null}
                onChange={(e, newValue) => {
                  handleChangeProcess(newValue, "userUID", e);
                }}
                sx={labelTransparentStyle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Run By"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="demo-simple-select-readonly"
                labelId="Process Name"
                name="processUID"
                options={processData}
                getOptionLabel={(option) => option.name}
                value={selectedProcess?.processUID ?
                  processData.find(option => option._id === selectedProcess.processUID)
                  || null : null}
                onChange={(e, newValue) => {
                  handleChangeProcess(newValue, "processUID", e);
                }}
                sx={labelTransparentStyle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Process Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleOpenModal} color="primary" sx={{ textTransform: "capitalize" }}>
                Search
              </Button>
            </Grid>
          </Grid>
          <TreeTable />
        </Container>
      </div>
      {/* </Box>
        </Box>
      </Box> */}
    </>
  )
}

export default ProgressStatus
