import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createUpdateEnterprise, getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import moment from "moment/moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CreateEnterprise = ({
  isCreateUpdateModalOpen,
  setIsCreateUpdateModalOpen,
  isEdit,
  setDimensionAttributes,
  dimensionAttributes, updatedId
}) => {
  const { enterpriseData } = useSelector((state) => state.enterprise)
  const userDatas = localStorage.getItem("accessToken")
  const userProfile = JSON.parse(userDatas)
  let editRow = enterpriseData.find((item) => item._id === updatedId);
  const dispatch = useDispatch();
  const [showEnterpriseKey, setshowEnterpriseKey] = useState(false)

  const [validations, setValidations] = useState({
    enterpriseNameVal: "",
    type: "",
  });
  const userId = localStorage.getItem("accessToken");

  const [enterpriseFormValue, setEnterpriseFormValue] = useState({
    enterpriseKey:
      isEdit && editRow?.enterpriseKey ? editRow?.enterpriseKey : "",
    enterpriseName:
      isEdit && editRow?.enterpriseName ? editRow?.enterpriseName : "",
    type: isEdit && editRow?.type ? editRow?.type : "",
    status: isEdit && editRow?.status ? editRow?.status : "",
    validFrom: isEdit && editRow?.validFrom ? editRow?.validFrom : "",
    validTo: isEdit && editRow?.validTo ? editRow?.validTo : "",
    notes: isEdit && editRow?.notes ? editRow?.notes : "",
  });

  const handleCloseModal = () => {
    setIsCreateUpdateModalOpen(false);
  };

  const handleAttributeFieldChange = (fieldName) => (event) => {
    setEnterpriseFormValue((prevValues) => ({
      ...prevValues,
      [fieldName]: event.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateAll();
    if (!isValid) {
      return false;
    }
    await saveEnterprise();
    handleCloseModal();
  };
  useEffect(() => {
    setEnterpriseFormValue((prevValues) => ({
      ...prevValues,
      createdBy: editRow?.createdBy,
      createdOn: isEdit ? editRow?.createdOn : new Date(),
      lastchangedBy: editRow?.lastchangedBy,
      lastchangedOn: editRow?.lastchangedOn,
    }));
  }, [isEdit, userProfile, editRow]);

  const saveEnterprise = async () => {
    try {
      if (!enterpriseFormValue.enterpriseName || !enterpriseFormValue.type) {
        return;
      }
      const updatedFormValues = {
        ...enterpriseFormValue,
        createdBy: userProfile?.data?.userName,
        createdOn: isEdit ? editRow?.createdOn : enterpriseFormValue.createdOn,
        lastchangedBy: isEdit ? userProfile?.data?.userName : "",
        lastchangedOn: isEdit ? new Date() : "",
      };

      const result = await dispatch(createUpdateEnterprise({ updatedFormValues, isEdit, userId, updatedId }));

      if (result?.payload?.data) {
        setDimensionAttributes([...dimensionAttributes, result?.payload?.data]);
        const successMessage = isEdit
          ? "Enterprise updated successfully"
          : "Enterprise created successfully";
        // toast.success(successMessage);
      }
      dispatch(getEnterpriseList());
    } catch (error) {
      toast.error("Failed to create enterprise")
    }
  };

  const validateAll = () => {
    const { enterpriseName, type } = enterpriseFormValue;
    const validations = { enterpriseName: "", type: "" };
    let isValid = true;
    if (!enterpriseName) {
      validations.enterpriseName = "Enterprise Name is required";
      isValid = false;
    }
    if (!type) {
      validations.type = "Type is required";
      isValid = false;
    }
    setValidations(validations);
    return isValid;
  };

  const { type } = validations;

  return (
    <Dialog open={isCreateUpdateModalOpen} onClose={handleCloseModal}>
      <DialogTitle sx={{ fontSize: "16px" }}>
        {isEdit ? "Edit Enterprise" : "Create Enterprise"}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "10px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="enterpriseName"
              label="Enterprise Name"
              variant="outlined"
              fullWidth
              value={enterpriseFormValue?.enterpriseName}
              onChange={handleAttributeFieldChange("enterpriseName")}
              onBlur={validateAll}
              error={type !== ""}
              helperText={type}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="enterpriseType">Enterprise Type</InputLabel>
              <Select
                labelId="enterpriseType"
                id="demo-simple-select-readonly"
                value={enterpriseFormValue?.type}
                fullWidth
                onChange={handleAttributeFieldChange("type")}
                label="Enterprise Type"
                variant="outlined"
              >
                <MenuItem value="ISV">ISV</MenuItem>
                <MenuItem value="Prospect">Prospect</MenuItem>
                <MenuItem value="Customer">Customer</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <div style={{ position: "relative" }}>
              <TextField
                id="keyEnterprise"
                label="Enterprise key"
                variant="outlined"
                fullWidth
                value={enterpriseFormValue?.enterpriseKey}
                onChange={handleAttributeFieldChange("enterpriseKey")}
                onBlur={validateAll}
                error={type !== ""}
                helperText={type}
                type={showEnterpriseKey ? "text" : "password"}
              />
              {!showEnterpriseKey && <div style={{ position: "absolute", top: "18px", right: "12px", cursor: "pointer" }} onClick={() => setshowEnterpriseKey(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              </div>}
              {showEnterpriseKey && <div style={{ position: "absolute", top: "18px", right: "12px", cursor: "pointer" }} onClick={() => setshowEnterpriseKey(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
              </div>}
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="statusType"> Status</InputLabel>

              <Select
                labelId="statusType"
                id="demo-status"
                value={enterpriseFormValue?.status}
                label="Status"
                fullWidth
                onChange={handleAttributeFieldChange("status")}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="validFrom"
              label="Valid From"
              variant="outlined"
              fullWidth
              type="date"
              value={enterpriseFormValue?.validFrom}
              onChange={handleAttributeFieldChange("validFrom")}
              onBlur={validateAll}
              error={type !== ""}
              helperText={type}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="validTo"
              label="Valid To"
              variant="outlined"
              fullWidth
              type="date"
              value={enterpriseFormValue?.validTo}
              onChange={handleAttributeFieldChange("validTo")}
              onBlur={validateAll}
              error={type !== ""}
              helperText={type}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: enterpriseFormValue?.validFrom,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              label="Notes"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              value={enterpriseFormValue?.notes}
              onChange={handleAttributeFieldChange("notes")}
              onBlur={validateAll}
              error={type !== ""}
              helperText={type}
            />
          </Grid>
          {isEdit && (
            <>
              <Grid item xs={6}>
                <TextField
                  id="createdBy"
                  label="Created By"
                  fullWidth
                  value={enterpriseFormValue?.createdBy}
                  onChange={handleAttributeFieldChange("createdBy")}
                  onBlur={validateAll}
                  error={type !== ""}
                  helperText={type}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="createdOn"
                  label="Created On"
                  variant="outlined"
                  fullWidth
                  value={enterpriseFormValue?.createdOn && moment(enterpriseFormValue?.createdOn).format("DD-MM-YYYY")}
                  onChange={handleAttributeFieldChange("createdOn")}
                  onBlur={validateAll}
                  error={type !== ""}
                  helperText={type}
                  disabled
                />
              </Grid>
            </>
          )}
          {isEdit && (
            <>
              <Grid item xs={6}>
                <TextField
                  id="lastchangedBy"
                  label="Changed By"
                  variant="outlined"
                  fullWidth
                  value={enterpriseFormValue?.lastchangedBy}
                  onChange={handleAttributeFieldChange("lastchangedBy")}
                  onBlur={validateAll}
                  error={type !== ""}
                  helperText={type}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastchangedOn"
                  label="Changed On"
                  variant="outlined"
                  fullWidth
                  value={enterpriseFormValue?.lastchangedOn && moment(enterpriseFormValue?.lastchangedOn).format("DD-MM-YYYY")}
                  onChange={handleAttributeFieldChange("lastchangedOn")}
                  onBlur={validateAll}
                  error={type !== ""}
                  helperText={type}
                  disabled
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseModal} sx={{textTransform: "capitalize"}}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" sx={{textTransform: "capitalize"}}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEnterprise;
