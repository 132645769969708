import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../ThemeContext';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import MiniDrawer from '../../common/drawer/DrawerToggle';
import CreateScenario from './CreateScenario';
import ScenarioMaterialList from './ScenarioMaterialList';
import { getModelListAsync } from '../../../store/ApiSlice/dimensionSlice';
import { getEnterpriseList } from '../../../store/ApiSlice/enterpriseSlice';
import { getEnterModelList } from '../../../store/ApiSlice/modelSlice';
import axios from 'axios';
import { API_BASE_URL } from '../../../helper/constants';
import { getAllParentScenarioList, getAllScenarioList } from '../../../store/ApiSlice/scenarioListSlice';
import { toast } from 'react-toastify';
import DeleteScenario from './DeleteScenario';
import PromoteDeleteScenario from './PromoteDeleteScenario';
import { FiPlus } from "react-icons/fi";

const ScenarioList = () => {
    const userEnterprise = JSON.parse(localStorage.getItem("accessToken"))
    const { scenarioData } = useSelector((state) => state?.scenario);
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const { modalData } = useSelector((state) => state?.modelList);
    const { isDarkTheme } = useContext(ThemeContext);
    const [isEdit, setIsEdit] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalOpenDelete, setModalOpenDelete] = useState(false);
    const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
    const [attriId, setAttriId] = useState(null);
    const [formValues, setFormValues] = useState({
        enterpriseUID: userEnterprise?.data?.enterprise?._id,
        modelUID: "",
        modelName: ""
    })
    const [modalName, setModalName] = useState([]);
    const [selectEnterprise, setSelectEnterprise] = useState(true)
    const [promoteDeleteScenario, setPromoteDeleteScenario] = useState(false)
    const [promoteScenario, setPromoteScenario] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getModelListAsync())
        dispatch(getEnterpriseList())
    }, [])


    const handleTableRowSelect = (event, row) => {
        let updatedIds = selectedRows.includes(row?._id)
            ? selectedRows.filter((n) => n !== row?._id)
            : [...selectedRows, row?._id];
        setSelectedRows(updatedIds);
    };

    const handleCreateUpdate = async (edit, id) => {
        setUpdatedId(id);
        setIsCreateUpdateModalOpen(true);
        setIsEdit(edit);
    };

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            console.log("🚀 ~ getModelList ~ response:", response)
            let filteredData = []
            if (formValues.enterpriseUID) {
                filteredData = response.data?.enterpriseList?.length > 0 && response.data?.enterpriseList?.filter(
                    (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (formValues.enterpriseUID) {
            getModelList();
        }
    }, [formValues.enterpriseUID]);

    const handleClick = () => {
        if (formValues.enterpriseUID === "" || formValues.enterpriseUID === undefined) {
            toast.error("Please select enterprise.")
            return
        } else if (formValues.modelUID === "" || formValues.modelUID === undefined) {
            toast.error("Please select model.")
            return
        } else {
            setSelectEnterprise(false)
            dispatch(getAllScenarioList({ formValues }))
            dispatch(getAllParentScenarioList({ formValues }))
        }
    }

    return (
        <>

            <Dialog open={selectEnterprise} onClose={() => setSelectEnterprise(false)} fullWidth maxWidth={"md"}>
                <DialogTitle sx={{ fontSize: "16px" }}>
                    Scenario
                </DialogTitle>
                <DialogContent sx={{ paddingTop: "10px !important" }}>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Enterprise Name"
                            name="enterpriseUID"
                            options={
                                enterpriseData?.length > 0
                                    ? enterpriseData
                                    : userEnterprise?.data?.enterprise
                                        ? [userEnterprise.data.enterprise]
                                        : [] // Always an array
                            }
                            getOptionLabel={(option) => option.enterpriseName}
                            // value={
                            //     formValues?.enterpriseUID
                            //         ? enterpriseData.find(
                            //             (option) =>
                            //                 option._id === formValues.enterpriseUID
                            //         ) || null
                            //         : null
                            // }
                            value={
                                enterpriseData?.find((option) => option?._id === formValues.enterpriseUID) ||
                                (userEnterprise?.data?.enterprise?._id === formValues.enterpriseUID
                                    ? userEnterprise?.data?.enterprise
                                    : null)
                            }
                            onChange={(e, newValue) => {
                                setFormValues({
                                    ...formValues,
                                    ["enterpriseUID"]: newValue?._id,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Enterprise Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ paddingBottom: "25px" }}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Model Name"
                            name="modelUID"
                            options={modalName}
                            getOptionLabel={(option) => option.modelName}
                            value={
                                formValues?.modelUID
                                    ? modalName.find(
                                        (option) =>
                                            option.modelUID === formValues.modelUID
                                    ) || null
                                    : null
                            }
                            onChange={(e, newValue) => {
                                setFormValues({
                                    ...formValues,
                                    ["modelUID"]: newValue?._id,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Model Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setSelectEnterprise(false)
                    }} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    <Button onClick={() => handleClick()} color="primary" sx={{ textTransform: "capitalize" }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Box sx={{ display: "flex" }}>
                <MiniDrawer />
                <Box component="main" sx={{
                    flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                    background: isDarkTheme ? "whitesmoke" : "white",
                }}> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "20px",
                }}
            >
                <h3 className="heading">Scenario</h3>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div className="relative group">
                        <button
                            onClick={() => handleCreateUpdate(false)}
                            className="font-semibold rounded-full text-sm p-2 flex items-center gap-2 shadow-md hover:shadow-lg transition-all duration-300 transform active:scale-95"
                            type="button"
                            style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
                        >
                            <FiPlus className="w-5 h-5 text-black" />
                        </button>
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-max bg-gray-800 text-white text-[10px] font-normal py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition">
                            Add Scenario
                        </div>
                    </div>
                    {isModalOpenDelete && (
                        <DeleteScenario
                            isModalOpenDelete={isModalOpenDelete}
                            setModalOpenDelete={setModalOpenDelete}
                            attriId={attriId}
                            setAttriId={setAttriId}
                            formValues={formValues}
                        />
                    )}
                    {isCreateUpdateModalOpen && (
                        <CreateScenario
                            isCreateUpdateModalOpen={isCreateUpdateModalOpen}
                            setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
                            isEdit={isEdit}
                            selectedRows={selectedRows}
                            formValues={formValues}
                            updatedId={updatedId}
                        />
                    )}
                    {(promoteDeleteScenario || promoteScenario) && (
                        <PromoteDeleteScenario
                            promoteDeleteScenario={promoteDeleteScenario}
                            setPromoteDeleteScenario={setPromoteDeleteScenario}
                            attriId={attriId}
                            setAttriId={attriId}
                            formValues={formValues}
                            promoteScenario={promoteScenario}
                            setPromoteScenario={setPromoteScenario}
                        />
                    )}
                </Box>

            </div>
            <ScenarioMaterialList
                formValues={formValues}
                selectedRows={selectedRows}
                handleCreateUpdate={handleCreateUpdate}
                handleTableRowSelect={handleTableRowSelect}
                setModalOpenDelete={setModalOpenDelete}
                setAttriId={setAttriId}
                setPromoteDeleteScenario={setPromoteDeleteScenario}
                setPromoteScenario={setPromoteScenario}
            />
            {/* </Box>
            </Box> */}
        </>
    )
}

export default ScenarioList
