import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  createWorkBookDetail,
  getPrimaryTimeDimension,
  getSchemaData,
  getWorkBookDetail,
  setDropped,
  setLayout,
} from '../../store/ApiSlice/canvasSlice';
import { Select as MuiSelect } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import { fetchDimensions, getLagIdList } from '../../store/ApiSlice/dimensionSlice';
import {
  getCycleIdList,
  setChartPending,
  setPending,
} from '../../store/ApiSlice/widgetSlice.js';
import CommonInput from '../commonComponent/input/CommonInput.js';
import {
  CycleIdData,
  MeasureDataFirst,
  MeasureDataSecond,
} from '../canvas/shared/MeasureData.jsx';
import { SketchPicker } from 'react-color';
import { CommonSelect } from '../commonComponent/commonSelect/CommonSelect.js';

let idCounter = 0;

const getId = () => {
  idCounter++;

  return idCounter.toString();
};

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: "12px"
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    zIndex: 9999,
    fontSize: "12px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#0366d6' : 'white',
    color: state.isSelected ? 'white' : 'black',
    fontSize: "12px"
  }),
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'red', fontSize: "12px" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6, fontSize: "12px" }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const GeneralPageLayout = (props) => {
  const {
    newPageID,
    editWorkSheet,
    setEditWorkSheet,
    setLoader,
    isCreateWidget,
    value,
    formValues,
    setFormValues,
    openModal,
    openedWorkbookName,
    setGeneralModel,
    finalData,
    setActiveTab,
    processPageData,
    widgetGroupData,
    setWidgetGroupData,
    newActiveWidgetLoader,
    setNewActiveWidgetLoader,
  } = props;

  const { id } = useParams();
  const dispatch = useDispatch();
  const { scenarioList } = useSelector((state) => state.process);
  const { dimensionLagIDdata } = useSelector((state) => state.dimension)
  const [selectedOptions, setSelectedOptions] = useState({
    attributes: [],
    validFrom: ""
  })
  const [droppedLayout, setDroppedLayout] = useState([])
  const [pendingLayout, setPendingLayout] = useState([])
  const { allDimensions } = useSelector((state) => state.dimension)
  const { measureData, measurePaginationList } = useSelector((state) => state.measure)
  const [timePeriodData, setTimePeriodData] = useState([]);
  const {
    layoutData,
    aggregatedViewData,
    canvasData,
    filterDetail,
    filterEnterpriseDetail,
    layout,
    timeDimensionData,
    schemaData,
    dimensionFilteredData,
  } = useSelector((state) => state.canvas);
  const { cycleIDList, pending } = useSelector((state) => state.widget);
  const viewData =
    finalData?.widgetUID?.widgetSubType === 'Measure Data'
      ? aggregatedViewData?.data
      : dimensionFilteredData?.data;
  const canvasFindData = canvasData?.find((item) => item?._id === id);

  const findMeasure = measurePaginationList?.filter((item) => item?.enterpriseUID === canvasFindData?.enterpriseUID?._id && item?.modelUID === canvasFindData?.modelUID?.modalUID)
  const findTimePeriod = schemaData?.length > 0 && formValues?.timePeriodName && schemaData?.filter((item) => item["Period Base Level"] === formValues?.timePeriodName);
  const findDiemnsion = allDimensions?.find((item) => item?._id === selectedOptions?.dimension)
  const findMeasureLagId = measureData?.filter((item) =>
    Array.isArray(selectedOptions?.measures) &&
    selectedOptions.measures.some((row) => row?.measureUID === item?._id)
  );

  const removeDuplicates = (array) => {
    const map = new Map();
    return (
      array?.length > 0 &&
      array?.reduce((acc, item) => {
        const key = JSON.stringify(item.name);
        if (!map.has(key)) {
          map.set(key, true);
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };

  const uniqueItems = removeDuplicates(
    findMeasureLagId?.flatMap((item) => item?.baselevelUID?.fields || [])
  );

  const findDiemnsionAttribute =
    formValues?.widgetSubType === 'Dimension Data'
      ? findDiemnsion?.fields
      : uniqueItems;
  const findLagId =
    findMeasureLagId?.length > 0 &&
    (formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart') &&
    findMeasureLagId?.filter((row) =>
      row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
    );
  const now = new Date();
  const startOfMonth = moment(
    new Date(now.getFullYear(), now.getMonth(), 1)
  ).format('YYYY-DD-MM');
  const endOfMonth = moment(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  ).format('YYYY-MM-DD');
  const findCycleIdMonth =
    findLagId?.length > 0 && cycleIDList?.length > 0
      ? cycleIDList?.filter((item) => {
        const validFromDate = moment(item['Cycle Start']).format(
          'YYYY-MM-DD'
        );
        const validToDate = moment(item['Cycle End'], 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        );
        return startOfMonth <= validFromDate && endOfMonth >= validToDate;
      })
      : [];
  const dataTimePeriod = () => {
    const data =
      findTimePeriod?.length > 0 &&
      findTimePeriod?.filter((item) => {
        const validFromDate = moment(item['Period Start']).format('YYYY-MM-DD');
        const validToDate = moment(item['Period End']).format('YYYY-MM-DD');
        const startDate = moment(selectedOptions?.startDate).format(
          'YYYY-MM-DD'
        );
        const endDate = moment(selectedOptions?.endDate).format('YYYY-MM-DD');
        return startDate <= validFromDate && endDate >= validToDate;
      });
    setTimePeriodData(data);
  };

  useEffect(() => {
    if (editWorkSheet) {
      const widgetTypeData =
        finalData?.widgetUID?.widgetType === 'Worksheet'
          ? finalData?.widgetUID?.worksheet?.timePeriods
          : finalData?.widgetUID?.chart?.timePeriods;
      const findperiodType =
        schemaData?.length > 0 &&
        schemaData?.filter(
          (item) => item['Period Base Level'] == widgetTypeData?.periodType
        );
      const validFromDate =
        findperiodType &&
        findperiodType?.find(
          (option) =>
            moment(option['Period Start']).format('YYYY-MM-DD') ===
            moment(widgetTypeData?.from).format('YYYY-MM-DD')
        );
      const validToDate =
        findperiodType &&
        findperiodType?.find(
          (option) =>
            moment(option['Period End']).format('YYYY-MM-DD') ===
            moment(widgetTypeData?.to)?.format('YYYY-MM-DD')
        );
      const findTime = timeDimensionData?.timeSchemaFields?.find(
        (data) => data['Period Type'] === widgetTypeData?.periodType
      );
      const findScenario =
        scenarioList?.length > 0 &&
        scenarioList?.filter((item) =>
          finalData?.widgetUID?.scenario?.map(
            (row) => row === item['Scenario Name']
          )
        );
      const findCycleId =
        finalData?.widgetUID?.cycleID?.length > 0 &&
        cycleIDList?.filter((item) =>
          finalData?.widgetUID?.cycleID?.some((row) => row === item['Cycle ID'])
        );
      const findLagId =
        finalData?.widgetUID?.lagID?.length > 0 &&
        dimensionLagIDdata?.filter((item) =>
          finalData?.widgetUID?.lagID?.some((row) => row == item['Lag ID'])
        );

      const initialColors =
        selectedOptions?.measures?.length > 0 &&
        selectedOptions?.measures?.reduce((acc, item) => {
          if (item?.measureName) {
            acc[item.measureName] = finalData?.widgetUID?.chart?.chartSettings
              ? finalData?.widgetUID?.chart?.chartSettings[item.measureName]
              : 'rgba(0, 100, 200, 0.3)';
          }
          return acc;
        }, {});
      const initialMeasure =
        selectedOptions?.measures?.length > 0 &&
        selectedOptions?.measures?.reduce((acc, item) => {
          if (item?.measureName) {
            acc[item.measureName] = finalData?.widgetUID?.chart?.chartSettings
              ?.measure
              ? finalData?.widgetUID?.chart?.chartSettings?.measure[
              item.measureName
              ]
              : '';
          }
          return acc;
        }, {});

      setSelectedOptions({
        attributes: (
          finalData?.widgetUID?.worksheet?.attributes ||
          finalData?.widgetUID?.chart?.attributes
        )?.map((item) => ({
          attributeName: item?.attributeName,
          attributeUID: item?.attributeUID,
        })),
        measures: (
          finalData?.widgetUID?.worksheet?.measures ||
          finalData?.widgetUID?.chart?.measures
        )?.map((item) => ({
          measureName: item?.measureName,
          measureUID: item?.measureUID,
          enterpriseUID: item?.enterpriseUID,
          modelUID: item?.modelUID,
          baselevelUID: item?.baselevelUID,
        })),
        filter:
          finalData?.widgetUID?.filter?._id || finalData?.widgetUID?.filter,
        scenario:
          findScenario?.length > 0 &&
          findScenario?.map((item) => ({
            _id: item?._id,
            scenario: item['Scenario Name'],
          })),
        cycleId:
          findCycleId?.length > 0 &&
          findCycleId?.map((item) => ({
            _id: item?._id,
            cyclePeriodicity: item['Cycle ID'],
          })),
        lagID:
          findLagId?.length > 0 &&
          findLagId?.map((item) => ({
            _id: item?._id,
            lagID: item['Lag ID'],
          })),
        dimension: finalData?.widgetUID?.worksheet?.dimension,
        ...((finalData?.widgetUID?.widgetSubType === 'Measure Data' ||
          finalData?.widgetUID?.widgetType === 'Chart') && {
          validFrom: validFromDate['Time Period Name'],
          validTo: validToDate['Time Period Name'],
          startDate: moment(widgetTypeData?.from).format('YYYY-MM-DD'),
          endDate: moment(widgetTypeData?.to).format('YYYY-MM-DD'),
        }),
      });
      setFormValues({
        widgetType: finalData?.widgetUID?.widgetType,
        widgetName: finalData?.widgetUID?.widgetName,
        workbookNotes: finalData?.widgetUID?.notes,
        widgetGroup: finalData?.widgetUID?.widgetGroup,
        widgetSubType: finalData?.widgetUID?.widgetSubType,
        timePeriod: findTime?._id,
        timePeriodName: widgetTypeData?.periodType,
        ...(finalData?.widgetUID?.widgetType === 'Chart' && {
          chartType: finalData?.widgetUID?.chart?.chartSettings?.type,
          orientation: finalData?.widgetUID?.chart?.chartSettings?.orientation,
          ...((finalData?.widgetUID?.chart?.chartSettings?.type === 'arearange' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'spline' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'bar' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'area' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'scatter' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'areaspline' ||
            finalData?.widgetUID?.chart?.chartSettings?.type === 'combination'
          ) && {
            ...initialColors,
          }),
          ...(finalData?.widgetUID?.chart?.chartSettings?.type === 'arearange' && {
            areaShadeColor:
              finalData?.widgetUID?.chart?.chartSettings?.areaShadeColor,
          }),
          measure: {
            ...initialMeasure,
          },
        }),
      });
    } else if (!editWorkSheet && isCreateWidget && openModal) {
      const baselineScenario = scenarioList.find(
        (item) => item['Scenario Name'] === 'Baseline'
      );
      setSelectedOptions({
        attributes: [],
        measures: [],
        validFrom: '',
        validTo: '',
        scenario: [
          {
            _id: baselineScenario._id,
            scenario: baselineScenario['Scenario Name'],
          },
        ],
      });
      setFormValues({
        widgetName: finalData?.widgetUID?.widgetName,
        workbookNotes: finalData?.widgetUID?.notes,
        widgetGroup: '',
      });
    }
  }, [editWorkSheet, !editWorkSheet, finalData, schemaData, viewData]);

  const attributeNames =
    selectedOptions?.attributes?.length > 0
      ? selectedOptions?.attributes.map((item) => item.attributeName)
      : [];

  const formatTimePeriods = (timePeriods) => {
    return (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      `Time Periods (${timePeriods})`
    );
  };
  const timePeriodsFormatted = formatTimePeriods(formValues?.timePeriodName);

  const mergeLayoutData = [
    ...((formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart') &&
      findLagId?.length > 0
      ? ['Cycle ID']
      : []),
    ...((formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart') &&
      dimensionLagIDdata?.length > 0 && selectedOptions?.lagID?.length > 0
      ? ['Lag ID']
      : []),
    ...attributeNames,
    ...(formValues?.widgetSubType === 'Measure Data' ||
      formValues?.widgetType === 'Chart'
      ? ['Measure Name']
      : []),
    ...(formValues?.widgetType === 'Chart' ? [timePeriodsFormatted] : []),
    'Scenario',
  ];

  const otherColumns =
    mergeLayoutData?.length > 0 &&
    mergeLayoutData
      .filter((key) => key !== 'Aggregation Mode')
      .filter((key) => key !== '_id')
      .map((key, index) => ({
        id: index,
        headerName: key,
        field: key,
        done: false,
        editable: true,
        width: 150,
      }));

  const columnDefs = [];

  if (otherColumns?.length > 0) {
    columnDefs.push(otherColumns[0]);

    columnDefs.push(...otherColumns.slice(1));
  }
  const pendingLayoutData = JSON.parse(localStorage.getItem("pending"));
  const findGroupName = pendingLayoutData?.[finalData?.widgetUID?.widgetName] || [];

  useEffect(() => {
    let columnDefsMap = {};
    columnDefs?.forEach((def) => {
      columnDefsMap[def.id] = def;
    });

    let sortedColumnDefs =
      columnDefs?.length > 0
        ? columnDefs
          ?.map((item) => columnDefsMap[item.id])
          .filter((def) => def !== undefined)
        : columnDefs;

    const defaultItem = {
      id: pendingLayout?.length,
      headerName: timePeriodsFormatted,
      field: timePeriodsFormatted,
      done: timePeriodsFormatted,
      editable: timePeriodsFormatted,
      width: 150,
    };

    const droppedLayoutData = JSON.parse(localStorage.getItem("dropped"));
    const findDroppedGroupName = droppedLayoutData?.[finalData?.widgetUID?.widgetName] || [];
    const abcdata = [...findGroupName, ...sortedColumnDefs]
    const uniqueAbcdata = abcdata?.filter((item, index, self) =>
      index === self.findIndex((t) => t.field === item.field)
    );

    if (!openModal) {
      setPendingLayout(sortedColumnDefs)
      setDroppedLayout([defaultItem])
    } else {
      const layoutCheck = uniqueAbcdata?.filter(
        (item) => item?.headerName === defaultItem?.headerName
      );
      const finalLayoutData = uniqueAbcdata?.length > 0 ? uniqueAbcdata?.filter((item) => sortedColumnDefs?.some((row) => row?.field === item?.field)) : []
      setPendingLayout(finalLayoutData?.length > 0 ? finalLayoutData : sortedColumnDefs)
      setDroppedLayout(findDroppedGroupName?.length > 0 ? findDroppedGroupName : layoutCheck?.length > 0 ? [] : [defaultItem])
    }
  }, [
    formValues,
    selectedOptions?.attributes,
    selectedOptions?.cycleId,
    selectedOptions?.lagID,
    pending,
  ]);

  const handleDragStart = (e, id, from) => {
    e.dataTransfer.setData('text/plain', id);
    e.dataTransfer.setData('from', from);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, target) => {
    e.preventDefault();
    const id = e.dataTransfer.getData('text/plain');
    const from = e.dataTransfer.getData('from');
    const itemId = parseInt(id, 10);

    // Handle move from "pending" to "dropped"
    if (from === 'pending' && target === 'dropped') {
      const item = pendingLayout?.find((item) => item.id === itemId);
      if (item) {
        setPendingLayout((prevPending) => prevPending?.filter((item) => item.id !== itemId)); // Remove from pending
        setDroppedLayout((prevDropped) => {
          // Check if the item is already in the droppedLayout before adding
          if (!prevDropped?.some((droppedItem) => droppedItem.id === itemId)) {
            return [...prevDropped, item]; // Add to droppedLayout if not present
          }
          return prevDropped;
        });
      }
    }

    // Handle move from "dropped" to "pending"
    else if (from === 'dropped' && target === 'pending') {
      const item = droppedLayout?.find((item) => item.id === itemId);
      if (item) {
        setDroppedLayout((prevDropped) => prevDropped?.filter((item) => item?.id !== itemId)); // Remove from dropped
        setPendingLayout((prevPending) => {
          // Check if the item is already in the pendingLayout before adding
          if (!prevPending?.some((pendingItem) => pendingItem?.id === itemId)) {
            return [...prevPending, item]; // Add to pendingLayout if not present
          }
          return prevPending;
        });
      }
    }
  };

  const handleReorder = (e, index, target) => {
    e.preventDefault();

    const id = e.dataTransfer.getData('text/plain');
    const from = e.dataTransfer.getData('from');
    const itemId = parseInt(id, 10);

    let sourceArray, setSourceArray;

    if (from === 'pending') {
      sourceArray = pendingLayout;
      setSourceArray = setPendingLayout;
    } else if (from === 'dropped') {
      sourceArray = droppedLayout;
      setSourceArray = setDroppedLayout;
    } else {
      return;
    }

    const item = sourceArray?.find((item) => item.id === itemId);
    if (item) {
      const updatedArray = [...sourceArray?.filter((item) => item?.id !== itemId)]; // Remove item
      updatedArray?.splice(index, 0, item); // Insert at new index

      setSourceArray(updatedArray);
    }
  };

  useEffect(() => {
    if (selectedOptions?.validFrom && selectedOptions?.validTo) {
      dataTimePeriod();
    }
  }, [selectedOptions?.validFrom, selectedOptions?.validTo]);

  useEffect(() => {
    dispatch(getWorkBookDetail())
    dispatch(getSchemaData({ timeDimensionData }))
    dispatch(getPrimaryTimeDimension({ canvasFindData }))
    dispatch(getLagIdList({ canvasFindData }))
    dispatch(getCycleIdList({ canvasFindData }))
    dispatch(fetchDimensions({ enterpriseId: canvasFindData?.enterpriseUID?._id, modelId: canvasFindData?.modelUID?.modalUID }))
  }, [])

  useEffect(() => {
    if (formValues?.widgetSubType === 'Dimension Data') {
      const defaultSelectedOptions =
        findDiemnsion?.primaryKey?.length > 0 &&
        findDiemnsion?.primaryKey?.map((item) => ({
          value: item?._id,
          label: item?.name,
        }));
      setSelectedOptions({
        ...selectedOptions,
        attributes:
          (defaultSelectedOptions?.length > 0 &&
            defaultSelectedOptions?.map((item) => ({
              attributeName: item?.label,
              attributeUID: item?.value,
              isFixed: true,
            }))) ||
          [],
      });
    }
  }, [findDiemnsion?.primaryKey]);

  useEffect(() => {
    if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      selectedOptions?.measures &&
      (!selectedOptions?.cycleId || selectedOptions?.cycleId.length === 0) &&
      !editWorkSheet
    ) {

      setSelectedOptions({
        ...selectedOptions,
        cycleId:
          findCycleIdMonth?.length > 0 &&
          findCycleIdMonth?.map((item) => ({
            _id: item?._id,
            cyclePeriodicity: item['Cycle ID'],
          })),
      });

    } else if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      selectedOptions?.measures &&
      !selectedOptions?.cycleId &&
      editWorkSheet
    ) {
      const findCycleId = cycleIDList?.filter((item) =>
        finalData?.widgetUID?.cycleID?.some((row) => row === item['Cycle ID'])
      );
      const mergeArrays = (arr1, arr2) => {
        const combinedArray = [...arr1, ...arr2];
        const uniqueArray =
          combinedArray?.length > 0 &&
          combinedArray?.filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t._id === item._id &&
                  t['Scenario Name'] === item['Scenario Name'] &&
                  t['Cycle ID'] === item['Cycle ID'] &&
                  t['Cycle Periodicity'] === item['Cycle Periodicity'] &&
                  t['Cycle Start'] === item['Cycle Start'] &&
                  t['Cycle End'] === item['Cycle End']
              )
          );
        return uniqueArray;
      };

      const mergedArray = mergeArrays(findCycleId, findCycleIdMonth);
      setSelectedOptions({
        ...selectedOptions,
        cycleId:
          mergedArray?.length > 0 &&
          mergedArray?.map((item) => ({
            _id: item?._id,
            cyclePeriodicity: item['Cycle ID'],
          })),
      });
    }
  }, [formValues?.widgetSubType, selectedOptions?.measures, selectedOptions?.cycleId]);

  useEffect(() => {
    const baselineScenario = scenarioList.find(
      (item) => item['Scenario Name'] === 'Baseline'
    );

    if (baselineScenario) {
      setSelectedOptions((prevState) => ({
        ...prevState,
        scenario: [
          {
            _id: baselineScenario._id,
            scenario: baselineScenario['Scenario Name'],
          },
        ],
      }));
    }
  }, [formValues?.widgetSubType, scenarioList]);

  const handleChangeAttribute = (data, type) => {
    if (type === 'filter' || type === 'dimension') {
      setSelectedOptions((prevState) => ({
        ...prevState,
        [type]: data?.value,
      }));
    } else if (type === 'cycleId') {
      const selectedIds = data?.map(item => item.value);
      const selectedLabels = data?.map(item => item.label);
      if (selectedIds.length < selectedOptions?.cycleId?.length) {
        const newRangeSelected = selectedOptions?.cycleId?.filter(item =>
          selectedIds?.includes(item?._id)
        );
        setSelectedOptions(prevState => ({
          ...prevState,
          cycleId: newRangeSelected,
        }));
      } else if (selectedIds?.length >= 2) {
        // Handle multi-selection
        const sortedCycleList = [...cycleIDList]?.sort((a, b) =>
          new Date(a['Cycle Start']) - new Date(b['Cycle Start'])
        );
        const startIndex = sortedCycleList?.findIndex(
          item => item['Cycle ID'] === selectedLabels[0]
        );
        const endIndex = sortedCycleList?.findIndex(
          item => item['Cycle ID'] === selectedLabels[selectedLabels?.length - 1]
        );
        const [start, end] = startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
        const rangeSelected = sortedCycleList?.slice(start, end + 1);

        const selectedCycleData = rangeSelected?.length > 0 && rangeSelected?.map(item => ({
          _id: item?._id,
          cyclePeriodicity: item['Cycle ID'],
          isFixed: true
        }));

        setSelectedOptions(prevState => ({
          ...prevState,
          cycleId: selectedCycleData,
        }));
      } else if (selectedIds.length === 1) {
        // Handle single selection
        const singleSelection = data?.length > 0 && data?.map(item => ({
          _id: item?.value,
          cyclePeriodicity: item?.label,
          isFixed: true,
        }));
        setSelectedOptions(prevState => ({
          ...prevState,
          cycleId: singleSelection,
        }));
      }
    } else if (type === 'lagID') {
      const selectedIds = data?.map(option => option.value);
      const selectedLabels = data?.map(item => item.label);
      if (selectedIds?.length < selectedOptions?.lagID?.length) {
        const newRangeSelected = selectedOptions?.lagID?.filter(item =>
          selectedIds?.includes(item?._id)
        );
        setSelectedOptions(prevState => ({
          ...prevState,
          lagID: newRangeSelected,
        }));
      } else if (selectedIds?.length >= 2) {
        const sortedSelected = [...dimensionLagIDdata]?.sort((a, b) => a - b);
        const startIndex = sortedSelected?.findIndex(
          item => item['Lag ID'] === selectedLabels[0]
        );
        const endIndex = sortedSelected?.findIndex(
          item => item['Lag ID'] === selectedLabels[selectedLabels?.length - 1]
        );
        const [start, end] = startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
        const rangeSelected = sortedSelected?.slice(start, end + 1);

        const selectedCycleData = rangeSelected?.length > 0 && rangeSelected?.map(item => ({
          _id: item?._id,
          lagID: item['Lag ID'],
        }));
        setSelectedOptions(prevState => ({
          ...prevState,
          lagID: selectedCycleData,
        }));
      } else if (selectedIds.length === 1) {
        const singleSelection = data?.length > 0 && data?.map(item => ({
          _id: item?.value,
          lagID: item?.label
        }))
        setSelectedOptions(prevState => ({
          ...prevState,
          lagID: singleSelection
        }))
      }
    }
    else {
      const selectedAttribute =
        data?.length > 0 &&
        data?.map((item) => {
          if (type === 'attributes') {
            const dataItem = findDiemnsion?.primaryKey?.find(
              (row) => row?._id === item?.value
            );
            return {
              attributeName: item?.label,
              attributeUID: item?.value,
              ...(dataItem && {
                isFixed: true,
              }),
            };
          } else if (type === 'scenario') {
            return {
              _id: item?.value,
              scenario: item?.label,
            };
          } else if (type === 'cycleId') {
            return {
              _id: item?.value,
              cyclePeriodicity: item?.label,
              isFixed: true,
            };
          } else if (type === 'lagID') {
            return {
              _id: item?.value,
              lagID: item?.label,
            };
          } else if (type === 'measures') {
            return {
              measureName: item?.label,
              measureUID: item?.value,
              enterpriseUID: item?.enterpriseUID,
              modelUID: item?.modelUID,
              baselevelUID: item?.baselevelUID,
            };
          } else if (type === 'yAxis') {
            return {
              measureName: item?.label,
              measureUID: item?.value,
            };
          } else if (type === 'xAxis') {
            return {
              attributeName: item?.label,
              attributeUID: item?.value,
            };
          }
          return null;
        });
      setSelectedOptions((prevState) => ({
        ...prevState,
        [type]: selectedAttribute,
      }));
    }
  };

  const handleChangePage = (e) => {
    const { name, value } = e.target;
    if (name === 'timePeriod') {
      const selectedData = timeDimensionData?.timeSchemaFields?.find(
        (data) => data._id === value
      );
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        [`timePeriodName`]: selectedData['Period Type'],
      }));
    } else if (name === 'widgetSubType') {
      setFormValues({ ...formValues, [name]: value });
      setSelectedOptions({
        attributes: [],
        validFrom: '',
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };


  const validation = () => {
    const findLagId = findMeasureLagId?.filter((row) =>
      row.baselevelUID?.fields?.some((item) => item?.name === 'Lag ID')
    );
    if (findLagId?.length > 0 && !selectedOptions?.cycleId) {
      toast.error('Please select cycle Id');
      return true;
    } else if (
      formValues?.widgetName === '' ||
      formValues?.widgetName === undefined
    ) {
      toast.error('Please enter widget name');
      return true;
    } else if (
      formValues?.widgetType === 'Worksheet' &&
      (formValues?.widgetGroup === '' || formValues?.widgetGroup === undefined)
    ) {
      toast.error('Please enter widget group');
      return true;
    } else if (
      formValues?.widgetType === 'Worksheet' &&
      (formValues?.widgetSubType === '' ||
        formValues?.widgetSubType === undefined)
    ) {
      toast.error('Please select widget type');
      return true;
    } else if (
      formValues?.widgetSubType === 'Dimension Data' &&
      (selectedOptions?.dimension === '' ||
        selectedOptions?.dimension === undefined)
    ) {
      toast.error('Please select dimension');
      return true;
    } else if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      !selectedOptions?.measures
    ) {
      toast.error('Please select measure');
      return true;
    } else if (!selectedOptions?.attributes) {
      toast.error('Please select attribute');
      return true;
    } else if (!selectedOptions?.filter) {
      toast.error('Please select filter');
      return true;
    } else if (findLagId?.length > 0 && (!selectedOptions?.lagID?.length || !selectedOptions?.lagID)) {
      toast.error('Please select lag ID');
      return true;
    }
    return false;
  };

  function calculateN(index) {
    const remainder = index % 3;
    if (remainder === 1) {
      return 0;
    } else if (remainder === 2) {
      return 1.5;
    } else {
      return 3;
    }
  }
  const addNewItem = () => {
    const newItem = {
      x: (layout?.length % calculateN(layout?.length) || 0),
      y: Math.floor(layout.length / 5),
      w: 3,
      h: 5,
      minW: 3,
      i: getId(),
      moved: false,
      static: false,
    };

    const updatedLayout = [...layout, newItem];
    dispatch(setLayout(updatedLayout));
  };
  const handleSubmitWorkbook = async () => {
    if (validation()) {
      return;
    } else {
      const widgetkey =
        formValues?.widgetType === 'Worksheet' ? 'worksheet' : 'chart';
      const initialColors =
        selectedOptions?.measures?.length > 0 &&
        selectedOptions?.measures?.reduce((acc, item) => {
          if (item?.measureName) {
            acc[item.measureName] = formValues
              ? formValues[item.measureName]
              : 'rgba(0, 100, 200, 0.3)';
          }
          return acc;
        }, {});

      const payload = {
        widgetName: formValues?.widgetName,
        enterpriseUID: canvasFindData?.enterpriseUID?._id,
        modelUID: canvasFindData?.modelUID?.modalUID,
        canvasUID: canvasFindData?._id,
        pageUID: openedWorkbookName?.pageId,
        notes: formValues?.workbookNotes,
        widgetType: formValues?.widgetType,
        widgetGroup: formValues?.widgetType === "Worksheet" ? formValues?.widgetGroup : formValues?.widgetName,
        ...(formValues?.widgetType === 'Worksheet' && {
          widgetSubType: formValues?.widgetSubType,
        }),
        ...(selectedOptions?.filter !== null && {
          filter: selectedOptions?.filter,
        }),
        [widgetkey]: {
          ...(formValues?.widgetSubType === 'Dimension Data' && {
            dimension: selectedOptions?.dimension,
          }),
          ...((formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') &&
            selectedOptions?.measures?.length > 0 && {
            measures: selectedOptions?.measures?.map((item) => ({
              measureName: item?.measureName,
              measureUID: item?.measureUID,
              baselevelUID: item?.baselevelUID,
              enterpriseUID: item?.enterpriseUID,
              modelUID: item?.modelUID,
            })),
          }),
          ...(selectedOptions?.attributes?.length > 0 && {
            attributes: selectedOptions?.attributes?.map((item) => ({
              attributeName: item?.attributeName,
              attributeUID: item?.attributeUID,
            })),
          }),
          ...((formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') &&
            timePeriodData?.length > 0 && {
            timePeriods: {
              periodType: formValues?.timePeriodName,
              periodValues: timePeriodData?.map((item) => {
                return item['Time Period Name'];
              }),
              ...(selectedOptions?.startDate && {
                from: moment(selectedOptions?.startDate)?.format(
                  'YYYY-MM-DD'
                ),
              }),
              ...(selectedOptions?.endDate && {
                to: moment(selectedOptions?.endDate)?.format('YYYY-MM-DD'),
              }),
            },
          }),
          ...(formValues?.widgetType === 'Chart' && {
            chartSettings: {
              type: formValues?.chartType,
              orientation: formValues?.orientation ? formValues?.orientation : "vertical",
              ...((formValues?.chartType === 'arearange' ||
                formValues?.chartType === 'spline' ||
                formValues?.chartType === 'bar' ||
                formValues?.chartType === 'area' ||
                formValues?.chartType === 'scatter' ||
                formValues?.chartType === 'areaspline' ||
                formValues?.chartType === 'combination'
              ) && {
                ...initialColors,
              }),
              ...(formValues?.chartType === 'arearange' && {
                areaShadeColor: formValues?.areaShadeColor,
              }),
              ...(formValues?.chartType === 'combination' && {
                measure: {
                  ...formValues?.measure,
                },
              }),
            },
          }),
        },
        scenario:
          selectedOptions?.scenario?.length > 0 &&
          selectedOptions?.scenario?.map((item) => item?.scenario),
        ...(selectedOptions?.cycleId?.length > 0 &&
          findLagId?.length > 0 && {
          cycleID:
            selectedOptions?.cycleId?.length > 0 &&
            selectedOptions?.cycleId?.map((item) => item?.cyclePeriodicity),
        }),
        ...(selectedOptions?.lagID?.length > 0 &&
          findLagId?.length > 0 && {
          lagID:
            selectedOptions?.lagID?.length > 0 &&
            selectedOptions?.lagID?.map((item) => item?.lagID),
        }),
        startCoordinates: {
          x: 9,
          y: 4,
        },
        endCoordinates: {
          x: 0,
          y: 0,
        },
      };
      const result = await dispatch(
        createWorkBookDetail({
          payload,
          openModal,
          updatedId: finalData?.widgetUID?._id,
        })
      );
      if (result?.meta?.requestStatus === 'fulfilled') {

        if (droppedLayout?.length > 0) {
          dispatch(setDropped({ [result?.payload?.data?.widgetName]: droppedLayout.map((item) => item) }));
        } else {
          dispatch(setDropped({ [result?.payload?.data?.widgetName]: [] }));
        }
        if (result?.payload?.data?.widgetType === "Worksheet") {
          dispatch(setPending({
            [result?.payload?.data?.widgetName]: pendingLayout.map((item) => item)
          }));

        } else if (result?.payload?.data?.widgetType === "Chart") {
          dispatch(setChartPending(pendingLayout))
        }
        if (!openModal) {
          addNewItem()
        }
        setActiveTab({
          pageUID: result?.payload?.data?.pageUID,
          worksheetID: (
            result?.payload?.data?.worksheet || result?.payload?.data
          )?._id,
        });
        dispatch(getWorkBookDetail());
        processPageData(result?.payload?.data);
        setGeneralModel(false);
      } else if (result?.meta?.requestStatus === 'rejected') {
        toast.error(result?.error?.message);
      }
    }
  };

  const droppedFields =
    droppedLayout?.length > 0 && droppedLayout?.map((col) => col?.field);
  const data =
    droppedLayout?.length > 0
      ? pendingLayout?.filter((row) => !droppedFields?.includes(row.field))
      : pendingLayout?.filter((row) => !layoutData?.includes(row.field));

  useEffect(() => {
    const now = new Date();
    let startOfMonths;
    if (
      formValues?.timePeriodName === 'Month' ||
      formValues?.timePeriodName === 'Day' ||
      formValues?.timePeriodName === 'Quarter'
    ) {
      startOfMonths = moment(
        new Date(now.getFullYear(), now.getMonth(), 1)
      ).format('YYYY-MM-DD');
    }
    if (
      (formValues?.widgetSubType === 'Measure Data' ||
        formValues?.widgetType === 'Chart') &&
      formValues?.timePeriod &&
      findTimePeriod?.length > 0
    ) {
      const findvalidFrom =
        findTimePeriod?.length > 0 &&
        findTimePeriod?.find((item) => {
          const validStartFromDate = moment(item['Period Start']).format(
            'YYYY-MM-DD'
          );
          return startOfMonths === validStartFromDate;
        });
      setSelectedOptions({
        ...selectedOptions,
        validFrom: findvalidFrom && findvalidFrom['Time Period Name'],
        startDate: findvalidFrom && findvalidFrom['Period Start'],
      });
    }
  }, [formValues?.timePeriod, startOfMonth, endOfMonth]);

  return (
    <>
      <Box
        sx={{
          boxShadow: 3,
        }}
      >
        <div style={{ padding: '10px 10px' }}>General</div>
        <Box sx={{ padding: '20px' }}>
          <FormControl
            fullWidth
            style={{ paddingBottom: '25px', marginTop: '5px' }}
          >
            <CommonInput
              name="widgetName"
              value={formValues?.widgetName || ''}
              label="Widget Name"
              style={{ padding: 0 }}
              onChange={handleChangePage}
            />
          </FormControl>
          {formValues?.widgetType === 'Worksheet' && (
            <FormControl
              fullWidth
              style={{ paddingBottom: '25px', marginTop: '5px' }}
            >
              <TextField
                variant="outlined"
                fullWidth
                value={formValues?.widgetType || ''}
                label="Widget Type"
                style={{ padding: 0 }}
              />
            </FormControl>
          )}
          {formValues?.widgetType === 'Chart' && (
            <FormControl
              fullWidth
              style={{ paddingBottom: '25px', marginTop: '5px' }}
            >
              <InputLabel id="type">Chart Type</InputLabel>
              <MuiSelect
                labelId="type"
                name="chartType"
                id="demo-simple-select-readonly"
                defaultValue={formValues?.chartType}
                value={formValues?.chartType || ''}
                fullWidth
                onChange={handleChangePage}
                label="Chart Type"
                variant="outlined"
              >
                <MenuItem value="bar">Bar Chart</MenuItem>
                <MenuItem value="spline">Line Chart</MenuItem>
                <MenuItem value="area">Area Chart</MenuItem>
                <MenuItem value="pie">Pie Chart</MenuItem>
                <MenuItem value="scatter">Scatter Plot</MenuItem>
                <MenuItem value="bubble">Bubble Chart</MenuItem>
                <MenuItem value="combination">Combination Chart</MenuItem>
                <MenuItem value="areaspline">Area Spline</MenuItem>
                <MenuItem value="heatmap">Heat Map</MenuItem>
                <MenuItem value="treemap">Tree Map</MenuItem>
                <MenuItem value="arearange">Area Range and Line Chart</MenuItem>
              </MuiSelect>
            </FormControl>
          )}
          {(formValues?.chartType === 'bar' ||
            formValues?.chartType === 'spline' ||
            formValues?.chartType === 'combination') && (
              <>
                <FormControl
                  fullWidth
                  style={{ paddingBottom: '25px', marginTop: '5px' }}
                >
                  <InputLabel id="orientation">Orientation</InputLabel>
                  <MuiSelect
                    labelId="orientation"
                    name="orientation"
                    id="demo-simple-select-readonly"
                    defaultValue={formValues?.orientation || "vertical"}
                    value={formValues?.orientation}
                    fullWidth
                    onChange={handleChangePage}
                    label="Orientation"
                    variant="outlined"
                  >
                    <MenuItem value="vertical">Vertical</MenuItem>
                    <MenuItem value="horizontal">Horizontal</MenuItem>
                  </MuiSelect>
                </FormControl>
              </>
            )}

          {formValues?.widgetType === 'Worksheet' && (
            <FormControl
              fullWidth
              style={{ paddingBottom: '25px', marginTop: '5px' }}
            >
              <CommonInput
                name="widgetGroup"
                value={formValues?.widgetGroup || ''}
                label="Widget Group"
                onChange={handleChangePage}
                style={{ padding: 0 }}
              />
            </FormControl>
          )}

          {formValues?.widgetType === 'Worksheet' && (
            <>
              <FormControl
                fullWidth
                style={{ paddingBottom: '25px', marginTop: '5px' }}
              >
                <InputLabel id="type">Widget Sub-type</InputLabel>
                <MuiSelect
                  labelId="type"
                  name="widgetSubType"
                  id="demo-simple-select-readonly"
                  defaultValue={formValues?.widgetSubType}
                  value={formValues?.widgetSubType || ''}
                  fullWidth
                  onChange={handleChangePage}
                  label="Widget Sub-type"
                  variant="outlined"
                >
                  <MenuItem value="Dimension Data">Dimension Data</MenuItem>
                  <MenuItem value="Measure Data">Measure Data</MenuItem>
                </MuiSelect>
              </FormControl>
            </>
          )}

          {(formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') && (
              <>
                <MeasureDataFirst
                  findMeasure={findMeasure}
                  selectedOptions={selectedOptions}
                  handleChangeAttribute={handleChangeAttribute}
                  widgetType={formValues?.widgetType}
                  canvasFindData={canvasFindData}
                />

                {formValues?.widgetType === 'Chart' &&
                  formValues?.chartType === 'combination' &&
                  selectedOptions?.measures?.length > 0 &&
                  selectedOptions?.measures?.map((item) => {
                    return (
                      <>
                        <FormControl
                          fullWidth
                          style={{ paddingBottom: '25px', marginTop: '5px' }}
                        >
                          <InputLabel id="Select Chart Type">
                            Select {item?.measureName} Chart Type
                          </InputLabel>
                          <MuiSelect
                            labelId="type"
                            name={item?.measureName}
                            id="demo-simple-select-readonly"
                            defaultValue={formValues?.measure?.[item?.measureName] || ""}
                            value={formValues?.measure?.[item?.measureName] || ""}
                            fullWidth
                            onChange={(e) => {
                              setFormValues((prevState) => ({
                                ...prevState,
                                measure: {
                                  ...prevState.measure,
                                  [item?.measureName]: e.target.value,
                                },
                              }));
                            }}
                            label="Select Chart Type"
                            variant="outlined"
                          >
                            <MenuItem value="bar">Bar Chart</MenuItem>
                            <MenuItem value="spline">Line Chart</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </>
                    );
                  })}

                {formValues?.widgetType === 'Chart' &&
                  (formValues?.chartType === 'arearange' ||
                    formValues?.chartType === 'spline' ||
                    formValues?.chartType === 'bar' ||
                    formValues?.chartType === 'area' ||
                    formValues?.chartType === 'scatter' ||
                    formValues?.chartType === 'areaspline' ||
                    formValues?.chartType === 'combination'
                  ) && (
                    <>
                      {formValues?.chartType === 'arearange' && (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '10px',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                          }}
                        >
                          <FormControl
                            fullWidth
                            style={{ paddingBottom: '25px', marginTop: '5px' }}
                          >
                            <Typography id="areaShadeColor">
                              Select Area Shade Color
                            </Typography>
                            <SketchPicker
                              color={formValues?.areaShadeColor}
                              onChangeComplete={(newColor) => {
                                setFormValues((prevState) => ({
                                  ...prevState,
                                  areaShadeColor: newColor.hex,
                                }));
                              }}
                            />
                          </FormControl>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                        }}
                      >
                        {selectedOptions?.measures?.length > 0 &&
                          selectedOptions?.measures?.map((item) => {
                            return (
                              <>
                                <div>
                                  <FormControl
                                    fullWidth
                                    style={{
                                      paddingBottom: '25px',
                                      marginTop: '5px',
                                    }}
                                  >
                                    <Typography id={item?.measureName}>
                                      {item?.measureName}
                                    </Typography>
                                    <SketchPicker
                                      color={formValues?.[item?.measureName]}
                                      onChangeComplete={(newColor) => {
                                        setFormValues((prevState) => ({
                                          ...prevState,
                                          [item?.measureName]: newColor.hex,
                                        }));
                                      }}
                                    />
                                  </FormControl>
                                </div>
                              </>
                            );
                          })}
                      </Box>
                    </>
                  )}
              </>
            )}

          <hr
            style={{
              borderTop: '2px solid #1976d2',
              width: '100%',
              margin: '20px auto',
            }}
          />

          {formValues?.widgetSubType === 'Dimension Data' && (
            <FormControl
              fullWidth
              style={{ paddingBottom: '25px', marginTop: '5px' }}
            >
              <Typography sx={{ padding: '0 0 15px 0', fontFamily: "'Poppins', sans-serif" }}>Dimension</Typography>
              <CommonSelect
                labelId="Dimension"
                name="dimension"
                options={allDimensions}
                value={selectedOptions?.dimension}
                setSelectedOptions={setSelectedOptions}
              />
            </FormControl>
          )}
          <FormControl
            fullWidth
            style={{ paddingBottom: '25px', marginTop: '5px' }}
          >
            <Typography sx={{ padding: '0 0 10px 0', fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>Attributes</Typography>
            <Select
              options={
                findDiemnsionAttribute?.length > 0
                  ? findDiemnsionAttribute?.map((item) => ({
                    value: item?._id,
                    label: item?.name,
                    isFixed: false,
                  }))
                  : []
              }
              value={
                selectedOptions?.attributes?.length > 0 &&
                selectedOptions?.attributes?.map((option) => ({
                  value: option?.attributeUID,
                  label: option?.attributeName,
                  isFixed: option.isFixed,
                }))
              }
              onChange={(data) => handleChangeAttribute(data, 'attributes')}
              placeholder="Select Attribute"
              isClearable={
                selectedOptions.attributes?.length > 0 &&
                selectedOptions?.attributes?.some((v) => v.isFixed)
              }
              isSearchable={true}
              isMulti
              className="text-black"
              styles={customStyles}
            />
          </FormControl>

          {(formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') && (
              <>
                <MeasureDataSecond
                  findTimePeriod={findTimePeriod}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  handleChangePage={handleChangePage}
                  timeDimensionData={timeDimensionData}
                  formValues={formValues}
                />
              </>
            )}

          {(formValues?.widgetSubType === 'Measure Data' ||
            formValues?.widgetType === 'Chart') &&
            findLagId?.length > 0 && (
              <>
                <CycleIdData
                  findCycleIdMonth={findCycleIdMonth}
                  selectedOptions={selectedOptions}
                  handleChangeAttribute={handleChangeAttribute}
                />
              </>
            )}

          <FormControl
            fullWidth
            style={{ paddingBottom: '25px', marginTop: '5px' }}
          >
            <CommonSelect
              labelId="Filter"
              name="filter"
              options={filterEnterpriseDetail}
              value={selectedOptions?.filter}
              setSelectedOptions={setSelectedOptions}
              formValues={formValues}
            />
          </FormControl>

          <hr
            style={{
              borderTop: '2px solid #1976d2',
              width: '100%',
              margin: '20px auto',
            }}
          />
          <FormControl fullWidth sx={{ paddingBottom: '15px' }}>
            <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px" }}>Scenario</Typography>
            <Select
              options={
                scenarioList?.length > 0
                  ? scenarioList?.map((item) => ({
                    value: item?._id,
                    label: item['Scenario Name'],
                  }))
                  : []
              }
              value={
                selectedOptions?.scenario?.length > 0 &&
                selectedOptions?.scenario?.map((option) => ({
                  value: option?._id,
                  label: option?.scenario,
                }))
              }
              onChange={(data) => handleChangeAttribute(data, 'scenario')}
              placeholder="Select Scenario"
              isSearchable={true}
              isMulti
              className="text-black"
              styles={customStyles}
            />
          </FormControl>

          <FormControl
            fullWidth
            style={{ paddingBottom: '25px', marginTop: '5px' }}
          >
            <CommonInput
              name="workbookNotes"
              value={formValues?.workbookNotes || ''}
              label="Note"
              style={{ padding: 0 }}
              onChange={handleChangePage}
            />
          </FormControl>

          <>
            <hr
              style={{
                borderTop: '2px solid #1976d2',
                width: '100%',
                margin: '20px auto',
              }}
            />

            <FormControl
              fullWidth
              style={{ paddingBottom: '25px', marginTop: '5px' }}
            >
              <Typography sx={{ padding: '0 0 10px 0', fontSize: "16px", fontFamily: "'Poppins', sans-serif" }}>Layout</Typography>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Box sx={{ width: '400px' }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: "12px" }}>
                    {formValues?.widgetType === 'Chart'
                      ? 'Attribute Sequence'
                      : 'Row Axis'}{' '}
                    :
                  </Typography>
                  <Box
                    onDrop={(e) => handleDrop(e, 'pending')}
                    onDragOver={(e) => handleDragOver(e)}
                    sx={{
                      border: '1px solid black',
                      overflowY: 'scroll',
                      height: '240px',
                    }}
                  >
                    {pendingLayout?.length > 0 &&
                      (formValues?.widgetType === 'Worksheet'
                        ? data
                        : pendingLayout
                      )?.map((item, index) => (
                        <div
                          key={item.id}
                          draggable
                          onDragStart={(e) =>
                            handleDragStart(e, item.id, 'pending')
                          }
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => handleReorder(e, index, 0)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            gap: '10px',
                            cursor: 'move',
                            fontSize: "12px"
                          }}
                        >
                          {item?.field}
                        </div>
                      ))}
                  </Box>
                </Box>
                {formValues?.widgetType === 'Worksheet' && (
                  <Box sx={{ width: '400px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>
                      Column Axis:
                    </Typography>
                    <Box
                      onDrop={(e) => handleDrop(e, 'dropped')}
                      onDragOver={(e) => handleDragOver(e)}
                      sx={{
                        border: '1px solid black',
                        overflowY: 'scroll',
                        height: '240px',
                      }}
                    >
                      {droppedLayout?.length > 0 &&
                        droppedLayout?.map((item, index) => (
                          <div
                            key={index}
                            draggable
                            onDragStart={(e) =>
                              handleDragStart(e, item.id, 'dropped')
                            }
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleReorder(e, index, 0)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '10px',
                              gap: '10px',
                              cursor: 'move',
                              fontSize: "12px"
                            }}
                          >
                            <div>
                              {item?.field}
                            </div>
                          </div>
                        ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </FormControl>
          </>

          <Box
            sx={{
              paddingBottom: '20px',
              display: 'flex',
              justifyContent: 'end',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setEditWorkSheet(false);
                setGeneralModel(false);
                setFormValues({});
                setSelectedOptions({
                  attributes: [],
                  measure: [],
                });
                dispatch(setDropped([]));
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmitWorkbook}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GeneralPageLayout;
