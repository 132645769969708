import React from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteFilterList, getFilterDetail } from '../../store/ApiSlice/canvasSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const DeleteFilter = ({
  selectedRows,
  setSelectedRows,
  setModalDeleteOpen,
  isModalDeleteOpen,
  updatedId
}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModalDeleteOpen(false);
  };

  const handleDelete = async () => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    const response = await dispatch(deleteFilterList({ attriId: updatedId, config }))
    if (response?.meta?.requestStatus === "fulfilled") {
      setModalDeleteOpen(false);
      // toast.success(response?.payload?.message);
      dispatch(getFilterDetail());
    } else if (response?.meta?.requestStatus === "rejected") {
      toast.warn(
        response?.payload?.data?.error
          ? response?.payload?.data?.error
          : "Attribute can not be deleted"
      );
    }
    handleCloseModal()
  }

  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Delete Filter Detail</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography>
            This Is Irreversible. Are You Sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
          <Button color="primary" onClick={handleDelete} sx={{ textTransform: "capitalize" }}>
            Delete
          </Button>
        </DialogActions>
      </>
    );
  };
  return (
    <>
      <Dialog open={isModalDeleteOpen} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default DeleteFilter
