import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { registerAllModules } from "handsontable/registry";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Box, Tab, Tooltip } from "@mui/material";
import CreateEntermodel from "./CreateEntermodel";
import DeleteEnterModel from "./DeleteEnterModel";
import { Shuffle } from "../../components/attributes/shuffule";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import EntermodalMaterialList from "./EntermodalMaterialList";
import "react-toastify/dist/ReactToastify.css";
import "../../style/common.css";
import { useDispatch } from "react-redux";
import { deletemodelList, getEnterModelList } from "../../store/ApiSlice/modelSlice";
import { useSelector } from "react-redux";
import DeleteModal from "../commonComponent/DeleteComponent/DeleteModal";
import { ThemeContext } from "../../ThemeContext";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Modeltab from "../modeltab/Modeltab";

const columnNames = [
  "Enterprise Name",
  "Model UID",
  "Model Name",
  "User UID",
  "Model Administrator UserName",
  "Notes",
  "CreatedOn",
  "ChangedOn"
];

const EntermodelList = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const { modalData } = useSelector((state) => state?.modelList)
  const [isModalOpen, setModalOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState(columnNames);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const checkList = columnNames;
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [attriId, setAttriId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const [modalType, setModalType] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEnterModelList())
    registerAllModules();
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    handleCloseModal();
  };

  const handleChange = (event) => {
    setCheckedItems(
      event.target.checked
        ? [...checkedItems, event.target.name]
        : checkedItems.filter((item) => item !== event.target.name)
    );
  };


  const handleTableRowSelect = (event, row) => {
    let updatedIds = selectedRows.includes(row?.original?.id)
      ? selectedRows.filter((n) => n !== row?.original?.id)
      : [...selectedRows, row?.original?.id];
    setSelectedRows(updatedIds);
  };

  const handleCreateUpdate = (edit, id) => {
    setUpdatedId(id);
    setIsCreateUpdateModalOpen(true);
    setIsEdit(edit);
  };
  return (
    <>
      {!viewMore && (
        <Box sx={{ display: "flex" }}>
          <MiniDrawer />
          <Box component="main" sx={{
            flexGrow: 1, p: 3, overflow: 'auto', height: "100vh",
            background: isDarkTheme ? "whitesmoke" : "white",
          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "60px",
                paddingBottom: "20px"
              }}
            >
              <h3 className="heading">
                Model
              </h3>
              <div style={{ display: "flex", alignItems: "center" }}>

                <Tooltip title="Create New">
                  <div
                    onClick={() => {
                      handleCreateUpdate(false);
                      setModalType("createModal")
                    }}
                    style={{ color: "black", margin: "0 4px", fontSize: "12px", background: "lightBlue", padding: "12px", borderRadius: "10px", cursor: "pointer", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Create New
                  </div>
                </Tooltip>
                <Tooltip title="Copy Reference">
                  <div
                    onClick={() => {
                      handleCreateUpdate(false);
                      setModalType("copyFromReference")
                    }}
                    style={{ color: "black", margin: "0 4px", fontSize: "12px", background: "lightBlue", padding: "12px", borderRadius: "10px", cursor: "pointer",fontFamily: "'Poppins', sans-serif" }}
                  >
                    Copy Reference
                  </div>
                </Tooltip>
                <Tooltip title="Copy Other">
                  <div
                    onClick={() => {
                      handleCreateUpdate(false);
                      setModalType("copyFrom")
                    }}
                    style={{ color: "black", margin: "0 4px", fontSize: "12px", background: "lightBlue", padding: "12px", borderRadius: "10px", cursor: "pointer", fontFamily: "'Poppins', sans-serif" }}
                  >
                    Copy Other
                  </div>
                </Tooltip>

                {isCreateUpdateModalOpen && (
                  <CreateEntermodel
                    isCreateUpdateModalOpen={isCreateUpdateModalOpen}
                    setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
                    isEdit={isEdit}
                    data={modalData}
                    selectedRows={selectedRows}
                    dimensionAttributes={[]}
                    setDimensionAttributes={() => { }}
                    updatedId={updatedId}
                    modalType={modalType}
                  />
                )}
                <DeleteModal
                  isModalOpenDelete={isModalOpenDelete}
                  setModalOpenDelete={setModalOpenDelete}
                  attriId={attriId}
                  setSelectedRows={setSelectedRows}
                  title="Model"
                  deleteAPI={deletemodelList}
                  getAPI={getEnterModelList}
                />
              </div>
            </div>

            <EntermodalMaterialList setViewMore={setViewMore} setModalType={setModalType} data={modalData} handleTableRowSelect={handleTableRowSelect} handleCreateUpdate={handleCreateUpdate} setModalOpenDelete={setModalOpenDelete} setAttriId={setAttriId} />
            <Dialog open={isModalOpen} onClose={handleCloseModal}>
              <>
                <DialogTitle>View Setting</DialogTitle>
                <hr />
                <DialogContent>
                  <DndProvider backend={HTML5Backend}>
                    <Shuffle checkList={checkList} checkedItems={checkedItems} handleChange={handleChange} />
                  </DndProvider>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal}>Cancel</Button>
                  <Button onClick={handleSubmit} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          </Box>
        </Box>
      )}
      {viewMore && (
        <Modeltab setViewMore={setViewMore} updatedId={attriId} />
      )}
    </>
  );
};

export default EntermodelList;
