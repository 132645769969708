import { IconButton, Menu, MenuItem } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { MdMoreVert } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getMeasureProfileList } from '../../store/ApiSlice/measuredataslice';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

const MeasureDataProfileMaterialList = ({
  handleTableRowSelect,
  setUpdatedId,
  handleCreateUpdate,
  setModalDeleteOpen
}) => {
  const { measureProfileDataList } = useSelector((state) => state.measureprofile)
  const [openedMenus, setOpenedMenus] = useState({});
  const dispatch = useDispatch()
  const sortedMeasureDataProfile = useMemo(() => {
    return [...(measureProfileDataList || [])].sort((a, b) => {
      const measureProfileA = a?.name?.toLowerCase();
      const measureProfileB = b?.name?.toLowerCase();
      if (measureProfileA < measureProfileB) return -1;
      if (measureProfileA > measureProfileB) return 1;
      return 0;
    });
  }, [measureProfileDataList]);

  useEffect(() => {
    dispatch(getMeasureProfileList())
  }, [])

  const handleClick = (rowId) => (event) => {
    event.stopPropagation();
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setOpenedMenus(prevState => ({
      ...prevState,
      [id]: null
    }));
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      enableColumnOrdering: false,
      header: "Data profile",
      enableSorting: false,
    },
    {
      accessorKey: "enterpriseName",
      enableColumnOrdering: false,
      header: "Enterprise Name",
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <p>{row?.original?.enterpriseUID?.enterpriseName}</p>
        </>
      )
    },
    {
      accessorKey: "modelName",
      enableColumnOrdering: false,
      header: "Model Name",
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <p>{row?.original?.modelUID?.modelName}</p>
        </>
      )
    },
    {
      accessorKey: "createdOn",
      enableColumnOrdering: false,
      header: "Created On",
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <p>{moment(row?.original?.createdOn).format("MM/DD/YYYY")}</p>

        </>
      )
    },
    {
      accessorKey: "changedOn",
      enableColumnOrdering: false,
      header: "Changed On",
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <p>{moment(row?.original?.changedOn).format("MM/DD/YYYY")}</p>

        </>
      )
    },
    {
      accessorKey: "view",
      header: "",
      enableSorting: false,
      enableColumnOrdering: false,
      Cell: ({ row }) => {
        return (
          <>
            <IconButton onClick={handleClick(row.original._id)} size="small">
              <MdMoreVert />
            </IconButton>
            <Menu
              anchorEl={openedMenus[row.original._id]}
              open={Boolean(openedMenus[row.original._id])}
              onClose={() => { handleClose(row.original._id) }}
            >
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  handleCreateUpdate(true, row?.original?._id);
                }}
                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
              >
                Edit Profile
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  setUpdatedId(row.original._id);
                  setModalDeleteOpen(true);
                }}
                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
              >
                Delete Profile
              </MenuItem>
            </Menu>
          </>
        )
      }
    }
  ])

  const table = useMaterialReactTable({
    columns: columns.filter(Boolean),
    data: sortedMeasureDataProfile || [],
    enableColumnOrdering: true,
    enableSelectAll: false,
    enableRowSelection: false,
    muiSelectCheckboxProps: { color: "secondary" },
    positionToolbarAlertBanner: "head-overlay",
    enableFullScreenToggle: false,
    enableColumnPinning: true,
    muiTableHeadCellProps: {
      sx: {
        fontSize: "12px"
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "12px",
        fontFamily: "'Poppins', sans-serif"
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        handleClose(row.original._id);
        handleCreateUpdate(true, row?.original?._id);
      }, // Row click event
      sx: {
        cursor: 'pointer', // Add a pointer cursor to indicate clickable rows
        fontSize: "12px",
        fontFamily: "'Poppins', sans-serif",
        '&:hover': {
          backgroundColor: '#f5f5f5', // Optional: Add hover effect for clarity
        },
      },
    }),
  });

  return <MaterialReactTable table={table} />
}

export default MeasureDataProfileMaterialList
