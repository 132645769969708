import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { registerUser } from "../../store/ApiSlice/authSlice";
import BackGroundImage from '../../image/login-background-image.jpg'

const RegisterForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [enterpriseKey, setEnterpriseKey] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await dispatch(registerUser({ name, email, password, enterpriseKey }));
    if (response.meta.requestStatus === 'fulfilled') {
      setTimeout(() => {
        navigate("/login"); // Redirect to login page after 2 seconds
      }, 2000);
    } else if (response.meta.requestStatus === 'rejected') {
      toast.error(response?.error?.message || "Registration Failed")
    }
  };

  return (
    <>
      <div style={{
        backgroundImage: `url(${BackGroundImage})`,
        height: '100vh',
        objectFit: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
                borderRadius: "10px",
                width: "80%",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bolder" }} gutterBottom>
                Vyan
              </Typography>
              <Box sx={{ marginY: "70px" }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bolder", marginTop: "50px" }}
                  gutterBottom
                >
                  Vyan is your <br /> unlimited icon library
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  Welcome In
                  <br />
                  Vyan Platform
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Stack spacing={1}>
                    <Typography sx={{ fontWeight: "bolder" }} gutterBottom>
                      Full Name
                    </Typography>
                    <TextField
                      type="text"
                      variant="outlined"
                      color="secondary"
                      placeholder="Full Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      fullWidth
                      required
                      sx={{ "& input": { padding: "10px" } }}
                    />
                    <Typography sx={{ fontWeight: "bolder" }} gutterBottom>
                      Email
                    </Typography>
                    <TextField
                      type="email"
                      variant="outlined"
                      color="secondary"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      fullWidth
                      required
                      sx={{ "& input": { padding: "10px" } }}
                    />
                    <Typography sx={{ fontWeight: "bolder" }} gutterBottom>
                      Password
                    </Typography>
                    <TextField
                      type="password"
                      variant="outlined"
                      color="secondary"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                      fullWidth
                      sx={{ "& input": { padding: "10px" } }}
                    />
                    <Typography sx={{ fontWeight: "bolder" }} gutterBottom>
                      Confirm Password
                    </Typography>
                    <TextField
                      type="password"
                      variant="outlined"
                      color="secondary"
                      placeholder="Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      required
                      fullWidth
                      sx={{ "& input": { padding: "10px" } }}
                    />
                    <Typography sx={{ fontWeight: "bolder" }} gutterBottom>
                      Enterprise Key
                    </Typography>
                    <TextField
                      type="password"
                      variant="outlined"
                      color="secondary"
                      placeholder="Enterprise Key"
                      onChange={(e) => setEnterpriseKey(e.target.value)}
                      value={enterpriseKey}
                      fullWidth
                      required
                      sx={{ "& input": { padding: "10px" } }}
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      type="submit"
                      fullWidth
                    >
                      Sign-up
                    </Button>
                  </Stack>
                </form>
                <Typography variant="body2" mt={2}>
                  Already have an account? <Link to="/login">Login Here</Link>
                </Typography>
              </Box>
            </Box>

          </Box>
        </Container>
      </div>
    </>
  );
};

export default RegisterForm;
