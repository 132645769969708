import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";

const getWidgetLayoutFromLocalStorage = () => {
    const layoutJSON = localStorage.getItem('widgetLayout');
    return layoutJSON ? JSON.parse(layoutJSON) : [];
};

const saveWidgetLayoutToLocalStorage = (widgetLayout) => {
    localStorage.setItem('widgetLayout', JSON.stringify(widgetLayout));
};

const getPendingDataFromLocalStorage = () => {
    const layoutJSON = localStorage.getItem('pending');
    return layoutJSON ? JSON.parse(layoutJSON) : {};
}

const savePendingDataToLocalStorage = (layout) => {
    localStorage.setItem('pending', JSON.stringify(layout));
}

const initialState = {
    cycleIDList: [],
    cyclePeriodicityList : [],
    screnarisosList: [],
    loading: false,
    error: null,
    success: false,
    widgetMasterData: {},
    pending: getPendingDataFromLocalStorage,
    chartPending: [],
    widgetLayout: getWidgetLayoutFromLocalStorage()
}

export const getCycleIdList = createAsyncThunk(
    'widget/getCycleIdList',
    async ({ canvasFindData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/dimensions/cycleIDsList/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
        return response.data;
    }
);


export const getCyclePeriodicityList = createAsyncThunk(
    'widget/getCyclePeriodicityList/',
    async ({ canvasFindData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/dimensions/cyclePeriodicityList/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
        return response.data;
    }
);

export const getScrenarisoswithEnterpriseAndModel = createAsyncThunk(
    'widget/getScrenarisoswithEnterpriseAndModel/',
    async ({ canvasFindData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/dimensions/scenarios/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
        return response.data;
    }
);


export const createWidgetLayout = createAsyncThunk(
    'canvas/createWidgetLayout',
    async (payload) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            const baseURLCreate = `${API_BASE_URL}/page/updateLayout`;
            const result = await axios.post(baseURLCreate, payload, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createWidgetMasterData = createAsyncThunk(
    'widget/createWidgetMasterData',
    async ({ payload }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/page/widgetMasterData`;
            result = await axios.post(baseURLCreate, payload, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

const widgetSlice = createSlice({
    name: "widget",
    initialState,
    reducers: {
        setWidgetmasterData(state, action) {
            const { widgetUID, data } = action.payload;
            state.widgetMasterData = {
                ...state.widgetMasterData,
                [widgetUID]: data,
            };
        },
        setPending(state, action) {
            // Loop through action.payload (which should be an object with grouped data)
            const newPendingData = action.payload;

            // Merge the new data with existing state.pending
            state.pending = {
                ...state.pending,  // Keep the existing pending data
                ...newPendingData  // Merge new data grouped by widgetName
            };
            savePendingDataToLocalStorage(state.pending)
        },
        setChartPending(state, action) {
            state.chartPending = [...action.payload]
        },
        setWidgetLayout(state, action) {
            state.widgetLayout = [...action.payload];
            saveWidgetLayoutToLocalStorage(state.widgetLayout);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCycleIdList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getCycleIdList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.cycleIDList = action?.payload?.data;
            })
            .addCase(getCycleIdList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getCyclePeriodicityList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getCyclePeriodicityList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.cyclePeriodicityList = action?.payload?.data;
            })
            .addCase(getCyclePeriodicityList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getScrenarisoswithEnterpriseAndModel.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getScrenarisoswithEnterpriseAndModel.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.screnarisosList = action?.payload?.data;
            })
            .addCase(getScrenarisoswithEnterpriseAndModel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createWidgetLayout.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createWidgetLayout.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createWidgetLayout.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createWidgetMasterData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.widgetMasterData = action.payload;
            })
            .addCase(createWidgetMasterData.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createWidgetMasterData.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
    }
})

export const { setWidgetmasterData, setPending, setChartPending, setWidgetLayout } = widgetSlice.actions;
export default widgetSlice.reducer;