import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";

const initialState = {
    loading: false,
    error: null,
    success: false,
    userData: []
};

export const getUserList = createAsyncThunk(
    'user/getUserList',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/user`, config);
        return response.data;
    }
);

export const createUserDetail = createAsyncThunk(
    'user/createUserDetail',
    async ({ payload, isEdit, selectedRows }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/user`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/user/${selectedRows}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const updateUserInfo = createAsyncThunk(
    'user/updateUserInfo',
    async (body) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/user/update-info/${JSON.parse(userId)?.userId}`;
            result = await axios.patch(baseURLCreate, body, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);


export const changeDefaultEnterprise = createAsyncThunk(
    'user/changeDefaultEnterprise',
    async (body) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/user/changeDefaultEnterprise`;
            result = await axios.patch(baseURLCreate, body, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteUser = createAsyncThunk('user/deleteUser',
    async ({ attriId, config }) => {
        const responce = await axios.delete(`${API_BASE_URL}/user/${attriId}`, config)
        return responce
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getUserList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.userData = action?.payload?.data;
            })
            .addCase(getUserList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createUserDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createUserDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(createUserDetail.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(updateUserInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(updateUserInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateUserInfo.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(changeDefaultEnterprise.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(changeDefaultEnterprise.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(changeDefaultEnterprise.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
    }
})

export default userSlice.reducer; 