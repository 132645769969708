import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";

const initialState = {
    loading: false,
    error: null,
    success: false,
    attribute: [],
    attributeById: [],
};

// get attribute
export const getAttributes = createAsyncThunk('attribute/getAttributes',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        // if (updatedId) {
        //     const responce = await axios.get(`${API_BASE_URL}/attributes/${updatedId}`, config);
        //     return responce?.data
        // } else {
        const responce = await axios.get(`${API_BASE_URL}/attributes`, config);
        return responce?.data
        // }
    }
);

export const getAttributesById = createAsyncThunk('attribute/getAttributesById',
    async ({ updatedId }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        try {
            const responce = await axios.get(`${API_BASE_URL}/attributes/${updatedId}`, config);
            return responce?.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }

    }
);

export const getAttributeOrEnterPrise = createAsyncThunk('attribute/getAttributeOrEnterPrise',
    async ({enterPriseId, modalId }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        try {
            const responce = await axios.get(`${API_BASE_URL}/attributes/${enterPriseId}/${modalId}`, config);
            return responce?.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }

    }
);

// create and update attribute
export const createAttribute = createAsyncThunk("attribute/createAttribute",
    async ({ updatedValue, isEdit, userId, updatedId }) => {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/attributes`;
                result = await axios.post(baseURLCreate, updatedValue, config);
            } else {
                const baseURLUpdate = `${API_BASE_URL}/attributes/${updatedId}`;
                result = await axios.put(baseURLUpdate, updatedValue, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
)

// deletwe attribute
export const deleteAttribute = createAsyncThunk('enterprise/deleteAttribute',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/attributes/${attriId}`, config)
            return responce
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

const attributeSlice = createSlice({
    name: 'attribute',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAttributes.pending, (state) => {
                state.loading = true
            })
            .addCase(getAttributes.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.attribute = action?.payload?.data;
            })
            .addCase(getAttributes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getAttributeOrEnterPrise.pending, (state) => {
                state.loading = true
            })
            .addCase(getAttributeOrEnterPrise.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.attribute = action?.payload?.data;
            })
            .addCase(getAttributeOrEnterPrise.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getAttributesById.pending, (state) => {
                state.loading = true
            })
            .addCase(getAttributesById.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.attributeById = action?.payload?.data;
            })
            .addCase(getAttributesById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createAttribute.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createAttribute.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createAttribute.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteAttribute.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteAttribute.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = action?.payload.data.error;
            })
            .addCase(deleteAttribute.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
    }
})

export default attributeSlice.reducer