import { Autocomplete, Box, Button, Container, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { MdMoreVert } from 'react-icons/md'

const ExtrapolativeTab = ({
    formValues,
    handleChangeProfile,
    profileMeasure,
    handleClick,
    handleremove,
    basicKPI,
    handleChangeBasicKPI,
    handleAddbasicKPI,
    setBasicKPI,
    deletebasicKPI,
    anchorBasicKPIl,
    setAnchorBasicKPIEl
}) => {
    const findEnterpriseProfile = profileMeasure?.filter((item) => item?.enterpriseUID === formValues?.enterpriseUID)
        ?.filter((item) => item?.modelUID === formValues?.modelUID)

    const forecastModelOption = [
        "Moving Average",
        "Exponential Smoothing",
        "ARIMA",
        "Croston's",
        "Random Forest",
        "AdaBoost",
        "Neural Networks",
        "Lower Quartile",
        "Upper Quartile"
    ]

    return (
        <>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Statistical Forecasting: Basic Settings
                </Typography>{" "}
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Best Statistical Forecast Measure"
                            name="statisticalForecastMeasureUID"
                            // options={profileMeasure}
                            options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                            getOptionLabel={(option) => option.measureName}
                            value={
                                formValues?.statisticalForecastMeasureUID
                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                        (row) => row?._id == formValues?.statisticalForecastMeasureUID
                                    ) || null : [])
                                    : null
                            }
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "statisticalForecastMeasureUID", e);
                            }}
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Best Statistical Forecast Measure"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Best Statistical Forecast Discrepancy Rank Measure"
                            name="statisticalForecastDiscrepancyRankMeasureUID"
                            // options={profileMeasure}
                            options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                            getOptionLabel={(option) => option.measureName}
                            value={
                                formValues?.statisticalForecastDiscrepancyRankMeasureUID
                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                        (row) => row?._id == formValues?.statisticalForecastDiscrepancyRankMeasureUID
                                    ) || null : [])
                                    : null
                            }
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "statisticalForecastDiscrepancyRankMeasureUID", e);
                            }}
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Best Statistical Forecast Discrepancy Rank Measure"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="demo-simple-select-readonly"
                            labelId="Best Statistical Forecast Quality Rank Measure"
                            name="statisticalForecastQualityRankMeasureUID"
                            // options={profileMeasure}
                            options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                            getOptionLabel={(option) => option.measureName}
                            value={
                                formValues?.statisticalForecastQualityRankMeasureUID
                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                        (row) => row?._id == formValues?.statisticalForecastQualityRankMeasureUID
                                    ) || null : [])
                                    : null
                            }
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "statisticalForecastQualityRankMeasureUID", e);
                            }}
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Best Statistical Forecast Quality Rank Measure"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Statistical Forecasting: Forecast Models
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddbasicKPI}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {basicKPI?.length > 0 ? basicKPI?.map((item, index) => {
                        return (
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Forecast model"
                                    name="StatisticalForecastModels"
                                    // options={profileMeasure}
                                    options={Array.isArray(forecastModelOption) ? forecastModelOption : []}
                                    getOptionLabel={(option) => option}
                                    value={
                                        item?.StatisticalForecastModels
                                            ? (forecastModelOption?.length > 0 ? forecastModelOption?.find(
                                                (row) => row == item?.StatisticalForecastModels
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeBasicKPI(index, newValue, "StatisticalForecastModels", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Forecast Model"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={deletebasicKPI ? "long-menu" : undefined}
                                    aria-expanded={deletebasicKPI ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, index, "basicKPI")}
                                    size="small"
                                >
                                    <MdMoreVert />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorBasicKPIl}
                                    open={deletebasicKPI}
                                    onClose={() => { setAnchorBasicKPIEl(null) }}
                                >
                                    <MenuItem onClick={() => { handleremove("basicKPI") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                </Menu>
                            </Grid>
                        )
                    }) : []}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Statistical Forecasting: Basic KPI Measures.
                </Typography>{" "}
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                {formValues?.isCheckedbasicKPIError && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="error"
                                        name="error"
                                        variant="outlined"
                                        fullWidth
                                        label="Error"
                                        value={"Error"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Error"
                                    name="statisticalForecastErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(findEnterpriseProfile) ? findEnterpriseProfile : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Moving Average Error"
                                    name="statisticalForecastMovingAverageErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastMovingAverageErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastMovingAverageErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastMovingAverageErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Moving Average Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Relative Error"
                                    name="statisticalForecastRelativeErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastRelativeErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastRelativeErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastRelativeErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Relative Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Error Rank"
                                    name="statisticalForecastErrorRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastErrorRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastErrorRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastErrorRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Error Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Accuracy Rank"
                                    name="statisticalForecastAccuracyRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastAccuracyRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastAccuracyRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastAccuracyRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Accuracy Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Error FVA"
                                    name="statisticalForecastErrorFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastErrorFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastErrorFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastErrorFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Error FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIBias && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Bias"
                                        name="Bias"
                                        variant="outlined"
                                        fullWidth
                                        label="Bias"
                                        value={"Bias"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Bias"
                                    name="statisticalForecastBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Moving Average Bias"
                                    name="statisticalForecastMovingAverageBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastMovingAverageBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastMovingAverageBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastMovingAverageBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Moving Average Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Relative Bias"
                                    name="statisticalForecastRelativeBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastRelativeBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastRelativeBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastRelativeBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Relative Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Bias Rank"
                                    name="statisticalForecastBiasRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastBiasRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastBiasRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastBiasRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Bias Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Neutrality Rank"
                                    name="statisticalForecastNeutralityRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastNeutralityRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastNeutralityRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastNeutralityRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Neutrality Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Bias FVA"
                                    name="statisticalForecastBiasFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastBiasFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastBiasFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastBiasFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Bias FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                        </Grid>
                    </>
                )}
                {formValues?.isCheckedbasicKPIChurn && (
                    <>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item
                                xs={3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Churn"
                                        name="Churn"
                                        variant="outlined"
                                        fullWidth
                                        label="Churn"
                                        value={"Churn"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Churn"
                                    name="statisticalForecastChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Moving Average Churn"
                                    name="statisticalForecastMovingAverageChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastMovingAverageChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastMovingAverageChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastMovingAverageChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Moving Average Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Relative Churn"
                                    name="statisticalForecastRelativeChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastRelativeChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastRelativeChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastRelativeChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Relative Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Churn Rank"
                                    name="statisticalForecastChurnRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastChurnRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastChurnRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastChurnRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Churn Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Stability Rank"
                                    name="statisticalForecastStabilityRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastStabilityRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastStabilityRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastStabilityRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Stability Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Best Statistical Forecast Churn FVA"
                                    name="statisticalForecastChurnFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.statisticalForecastChurnFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.statisticalForecastChurnFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "statisticalForecastChurnFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Best Statistical Forecast Churn FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                        </Grid>
                    </>
                )}

            </Container>
        </>
    )
}

export default ExtrapolativeTab
