import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("accessToken");
  const userToken = JSON.parse(isAuthenticated)
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (userToken?.token) {
        const decodedToken = jwtDecode(userToken.token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("accessToken");
          return <Navigate to="/login" />;
        } else {
          const timeout = (decodedToken.exp - currentTime) * 1000;
          setTimeout(() => {
            localStorage.removeItem("accessToken");
            window.location.href = "/login";
          }, timeout);
        }
      } else {
        return <Navigate to="/login" />;
      }
    };

    checkTokenExpiration();
  }, [userToken]);
  return userToken?.token ? <>{children}</> : <Navigate to="/login" />;
};
export default PrivateRoute;