import { ChatUIComponent, MessagesDirective, MessageDirective } from "@syncfusion/ej2-react-interactive-chat";

const currentUserModel = { id: "user1", user: "Albert" };
const botUserModel = { id: "bot", user: "ChatBot" };

const responseMapping = {
  hi: "Hello! How can I assist you today?",
  "how are you": "I'm just a bot, but I'm doing great! How about you?",
  "what's your name": "I'm ChatBot, your virtual assistant.",
  bye: "Goodbye! Have a great day!",
  default: "I'm sorry, I didn't understand that. Can you please rephrase?"
};

const ChatBot = ({ toggleChat }) => {
  const handleSendMessage = (args) => {
    const userMessage = args.message.toLowerCase();
    const botResponse = responseMapping[userMessage] || responseMapping["default"];

    args.addMessage({
      text: botResponse,
      author: botUserModel,
      className: "bg-gray-300 text-black p-3 rounded-xl w-fit mb-2 ml-auto"
    });
  };

  return (
    <div className="fixed bottom-20 right-5 bg-white max-w-3xl p-4 rounded-lg shadow-lg w-96">
      <div className="flex justify-between items-center border-b pb-2 mb-2">
        <h3 className="text-lg font-semibold">Chat</h3>
        <button onClick={toggleChat} className="text-gray-500 hover:text-gray-800">
          ✕
        </button>
      </div>

      <ChatUIComponent user={currentUserModel} sendMessage={handleSendMessage}>
        <MessagesDirective>
          <MessageDirective text="Hi" author={currentUserModel} className="bg-blue-500 text-white p-3 rounded-xl w-fit mb-2" />
          <MessageDirective text="Hello! How can I assist you today?" author={botUserModel} className="bg-gray-300 text-black p-3 rounded-xl w-fit mb-2 ml-auto" />
        </MessagesDirective>
      </ChatUIComponent>
    </div>
  );
};

export default ChatBot;
