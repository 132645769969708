import { useCallback, useState } from "react";
import Card from "./listItem";
import update from "immutability-helper";

export const Shuffle = ({ checkList, checkedItems, handleChange }) => {
  const columns = checkList.map((columnName, index) => ({
    id: index + 1,
    text: columnName,
  }));
  const [cards, setCards] = useState(columns);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <>
        <>
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.text}
            moveCard={moveCard}
            handleChange={handleChange}
            checkedItems={checkedItems}
          />
        </>
      </>
    );
  }, []);

  return (
    <>
      <div
        style={{
          width: "400px",
        }}
      >
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    </>
  );
};
