import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Typography, Box, Dialog, DialogTitle, Autocomplete } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changePassword, makeUserLogin, resetPassword } from "../../store/ApiSlice/authSlice";
import vyanLogo from '../../image/Black-vyan-logo.svg'
import { useSelector } from "react-redux";
import BackGroundImage from '../../image/login-background-image.jpg'
import { getUserList } from "../../store/ApiSlice/userSlice";

const ForgotPassword = () => {
    const [formValue, setFormValue] = useState({})
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formValue?.email === "" || !formValue?.email) {
            toast.error("Please enter Email");
        } else if (!emailRegex.test(formValue?.email)) {
            toast.error("Please enter a valid email address");
        } else {
            const body = {
                email: formValue?.email,
            }
            let response = await dispatch(resetPassword(body));
            if (response.meta.requestStatus === 'fulfilled') {
                toast.success(`${response?.payload.message} Please check your mail.`)
                dispatch(getUserList())
                navigate("/login")
            } else if (response.meta.requestStatus === 'rejected') {
                const errorMessage = response?.error?.message || 'An error occurred';
                toast.error(errorMessage);
            }
        }
    };

    return (
        <>
            <div style={{
                backgroundImage: `url(${BackGroundImage})`,
                height: '100vh',
                objectFit: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>

                <Container>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                        <Box sx={{
                            padding: '40px',
                            width: '80%',
                            height: '300px'
                        }}>
                            <img src={vyanLogo} alt=""
                                style={{
                                    width: '80%',
                                    height: '300px'
                                }} />
                        </Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: '100%',
                        }}>
                            <Box
                                sx={{
                                    width: '70%'
                                }}
                            >
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Typography sx={{ fontWeight: 'bolder', }} gutterBottom>
                                        Email
                                    </Typography>
                                    <TextField
                                        placeholder="Email"
                                        onChange={(e) => {
                                            setFormValue({
                                                ...formValue,
                                                email: e.target.value
                                            })
                                        }}
                                        required
                                        variant="outlined"
                                        color="secondary"
                                        type="email"
                                        sx={{ mb: 2, width: "100%", '& input': { padding: '10px' } }}
                                        value={formValue?.email}
                                    />

                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        type="submit"
                                        fullWidth
                                    >
                                        Login
                                    </Button>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default ForgotPassword;
