import { AppBar, Autocomplete, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createSuperAdministrator, getSuperAdministrator } from '../../store/ApiSlice/superAdministratorSlice';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomButton from '../common/CustomButton';

const CreateSuperAdministrator = ({
    setIsCreate,
    isEdit,
    selectedRows
}) => {
    const { administratordata } = useSelector((state) => state.superAdministrator)
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const [administratorValue, serAdministratorValue] = useState({});
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch()
    const BASE_IMAGE_URL = window.location.hostname === 'app.vyan.ai'
        ? 'https://app.vyan.ai'
        : 'http://localhost:4000';
    const selectedProfile = administratordata?.find(
        (item) => item?._id === selectedRows
    );

    const handleCancel = () => {
        setIsCreate(false);
    };

    useEffect(() => {
        if (selectedProfile && isEdit) {
            const formattedData = {
                phoneNumber: selectedProfile?.workPhone?.countryCode ? selectedProfile?.workPhone?.countryCode?.replace('+', '') + selectedProfile?.workPhone?.phoneNumber.toString() : "",
                countryCode: selectedProfile?.workPhone?.countryCode ? selectedProfile?.workPhone?.countryCode?.replace('+', '') : ""
            };
            const formattedMobileData = {
                phoneNumber: selectedProfile?.mobilePhone?.countryCode ? selectedProfile?.mobilePhone?.countryCode?.replace('+', '') + selectedProfile?.mobilePhone?.phoneNumber.toString() : "",
                countryCode: selectedProfile?.mobilePhone?.countryCode ? selectedProfile?.mobilePhone?.countryCode?.replace('+', '') : ""
            };
            serAdministratorValue({
                enterpriseName: selectedProfile?.enterprise?._id,
                dateFormat: selectedProfile?.dateFormat,
                validFrom: moment(selectedProfile?.validFrom).format("YYYY-MM-DD"),
                validTo: moment(selectedProfile?.validTo, "DD-MM-YYYY").format("YYYY-MM-DD"),
                email: selectedProfile?.email,
                firstName: selectedProfile?.firstName,
                lastName: selectedProfile?.lastName,
                middleName: selectedProfile?.middleName,
                locked: selectedProfile?.locked,
                userId: selectedProfile?.userID,
                suffix: selectedProfile?.suffix,
                workPhone: {
                    countryCode: formattedData?.countryCode,
                    phoneNumber: formattedData?.phoneNumber
                },
                mobilePhone: {
                    countryCode: formattedMobileData?.countryCode,
                    phoneNumber: formattedMobileData?.phoneNumber
                },
                ...(selectedProfile?.photo && ({
                    photo: selectedProfile?.photo,
                })),
                streetAddress: selectedProfile?.streetAddress,
                city: selectedProfile?.city,
                country: selectedProfile?.country,
                title: selectedProfile?.title,
                department: selectedProfile?.department,
                function: selectedProfile?.functions,
                note: selectedProfile?.notes
            })
        }
    }, [selectedProfile, isEdit])

    const handleChange = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            serAdministratorValue({
                ...administratorValue,
                [fieldName]: newValue?._id,
            });
        } else if (fieldName === "locked") {
            const { name, checked } = e.target
            serAdministratorValue({
                ...administratorValue,
                [name]: checked
            })
        } else if (fieldName === "photo") {
            const imageUrl = e.target.files[0];
            serAdministratorValue({
                ...administratorValue,
                [fieldName]: imageUrl
            })
        } else {
            const { name, value } = e.target
            serAdministratorValue({
                ...administratorValue,
                [name]: value
            })
        }
    }

    const handleUploadImage = async () => {
        if (typeof administratorValue?.photo === "object") {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const file = administratorValue.photo;
            const formdata = new FormData()
            formdata.append('file', administratorValue?.photo);
            try {
                const response = await axios.post(`${API_BASE_URL}/upload`, formdata);
                handleSubmit(response?.data?.data?.file_path)
            } catch (error) {
                toast.error('Only .png, .jpeg, and .jpg files are allowed!')
            }
        } else {
            handleSubmit("")
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (validatePassword(value)) {
            setPasswordError(''); // Clear error if valid
        } else {
            setPasswordError('Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.');
        }
        handleChange(value, 'password', e); // Update form state
    };

    const handleSubmit = async (data) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        const countryDialCode = `${administratorValue?.workPhone?.countryCode}`;
        let phoneWithoutCountryCode = administratorValue?.workPhone?.phoneNumber;
        if (administratorValue?.workPhone?.phoneNumber?.startsWith(countryDialCode)) {
            phoneWithoutCountryCode = administratorValue?.workPhone?.phoneNumber?.slice(countryDialCode?.length).trim();
        }
        const mobileCountryCode = `${administratorValue?.mobilePhone?.countryCode}`;
        let numberWithOutCountryCode = administratorValue?.mobilePhone?.phoneNumber;
        if (administratorValue?.mobilePhone?.phoneNumber?.startsWith(mobileCountryCode)) {
            numberWithOutCountryCode = administratorValue?.mobilePhone?.phoneNumber?.slice(mobileCountryCode?.length).trim();
        }
        if (administratorValue?.enterpriseName === "" || !administratorValue?.enterpriseName) {
            toast.error("Please select enterprise");
        } else if (administratorValue?.firstName === "" || !administratorValue?.firstName) {
            toast.error("Please enter first name");
        } else if (administratorValue?.userId === "" || !administratorValue?.userId) {
            toast.error("Please enter userId");
        } else if (administratorValue?.email === "" || !administratorValue?.email) {
            toast.error("Please enter Email");
        } else if (!emailRegex.test(administratorValue?.email)) {
            toast.error("Please enter a valid email address");
        } else if (!isEdit && (administratorValue?.password === "" || !administratorValue?.password)) {
            toast.error("Please enter password");
        } else if (!isEdit && (!passwordRegex.test(administratorValue?.password))) {
            toast.error("Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.");
        } else {
            const payload = {
                enterprise: administratorValue?.enterpriseName,
                email: administratorValue?.email,
                firstName: administratorValue?.firstName,
                middleName: administratorValue?.middleName,
                lastName: administratorValue?.lastName,
                suffix: administratorValue?.suffix,
                userID: administratorValue?.userId,
                userName: `${administratorValue?.firstName || ''}` +
                    (administratorValue?.middleName ? ` ${administratorValue?.middleName}` : '') +
                    (administratorValue?.lastName ? ` ${administratorValue?.lastName}` : ''),
                ...(administratorValue?.password && ({
                    password: administratorValue?.password,
                })),
                locked: administratorValue?.locked,
                ...(administratorValue?.workPhone?.countryCode &&
                    administratorValue?.workPhone?.countryCode !== "+undefined" &&
                    phoneWithoutCountryCode && phoneWithoutCountryCode !== "undefined" && ({
                        workPhone: {
                            countryCode: `+${administratorValue?.workPhone?.countryCode}`,
                            phoneNumber: phoneWithoutCountryCode
                        },
                    })),
                ...(administratorValue?.mobilePhone?.countryCode &&
                    administratorValue?.mobilePhone?.countryCode !== "+undefined" &&
                    numberWithOutCountryCode && numberWithOutCountryCode !== "undefined" && ({
                        mobilePhone: {
                            countryCode: `+${administratorValue?.mobilePhone?.countryCode}`,
                            phoneNumber: numberWithOutCountryCode
                        },
                    })),
                streetAddress: administratorValue?.streetAddress,
                city: administratorValue?.city,
                country: administratorValue?.country,
                ...(data && ({
                    photo: data,
                })),
                title: administratorValue?.title,
                department: administratorValue?.department,
                functions: administratorValue?.function,
                dateFormat: administratorValue?.dateFormat,
                validFrom: moment(administratorValue?.validFrom).format("DD-MM-YYYY"),
                validTo: moment(administratorValue?.validTo).format("DD-MM-YYYY"),
                notes: administratorValue?.note
            }
            const result = await dispatch(
                createSuperAdministrator({ payload, isEdit, selectedRows })
            );
            if (result?.meta?.requestStatus == "fulfilled") {
                // toast.success(
                //     result?.payload?.message
                // );
                dispatch(getSuperAdministrator());
                serAdministratorValue({});
                setIsCreate(false);
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
                setIsCreate(true);
            }
        }
    }

    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <AppBar position="static" sx={{ backgroundColor: "white" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                            {/* {getPageHeading()} */}
                            Super Administrator
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    General Data
                </Typography>{" "}
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Enterprise Name"
                                    name="enterpriseName"
                                    options={enterpriseData}
                                    getOptionLabel={(option) => option.enterpriseName}
                                    value={
                                        administratorValue?.enterpriseName
                                            ? enterpriseData.find(
                                                (option) =>
                                                    option._id === administratorValue.enterpriseName
                                            ) || null
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChange(newValue, "enterpriseName", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enterprise Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Date Format</InputLabel>
                                <Select
                                    id="type"
                                    label="Date Format"
                                    name="dateFormat"
                                    value={administratorValue?.dateFormat}
                                    onChange={(e, newValue) => {
                                        handleChange(newValue, "dateFormat", e);
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    style={{ marginBottom: "16px" }}
                                >
                                    <MenuItem value="" disabled selected>
                                        Select Date Format Type
                                    </MenuItem>
                                    <MenuItem value="MM/DD/YY">MM/DD/YY</MenuItem>
                                    <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                                    <MenuItem value="MM-DD-YY">MM-DD-YY</MenuItem>
                                    <MenuItem value="MM-DD-YYYY">MM-DD-YYYY</MenuItem>
                                    <MenuItem value="DD/MM/YY">DD/MM/YY</MenuItem>
                                    <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                                    <MenuItem value="DD-MM-YY">DD-MM-YY</MenuItem>
                                    <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="User ID"
                                name='userId'
                                value={administratorValue?.userId || ""}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "userId", e);
                                }}
                                placeholder="Enter user Id"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="validFrom"
                                name="validFrom"
                                label="Valid From"
                                variant="outlined"
                                fullWidth
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={administratorValue?.validFrom || ''}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "validFrom", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='firstName'
                                label="First Name"
                                value={administratorValue?.firstName || ""}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "firstName", e);
                                }}
                                placeholder="Enter first name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="validTo"
                                name="validTo"
                                variant="outlined"
                                label="Valid To"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: administratorValue?.validFrom,
                                }}
                                value={administratorValue?.validTo || ''}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "validTo", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='middleName'
                                label="Middle Name"
                                value={administratorValue?.middleName}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "middleName", e);
                                }}
                                placeholder="Enter middle name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="locked"
                                        name="locked"
                                        checked={administratorValue?.locked || false}
                                        onChange={(e, newValue) => {
                                            handleChange(newValue, "locked", e);
                                        }}
                                        style={{
                                            padding: "0px !important",
                                            height: "54px",
                                            margin: "0px 19px"
                                        }}
                                    />
                                }
                                label="Locked"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='lastName'
                                label="Last Name"
                                value={administratorValue?.lastName}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "lastName", e);
                                }}
                                placeholder="Enter last name"
                            />
                        </Grid>
                        {!isEdit && (
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'} // Toggle input type based on state
                                    value={administratorValue?.password}
                                    onChange={handlePasswordChange}
                                    placeholder="Enter password"
                                    error={!!passwordError} // Display error if password does not meet criteria
                                    helperText={passwordError} // Show validation message
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={togglePasswordVisibility}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Suffix"
                                id='suffix'
                                name='suffix'
                                value={administratorValue?.suffix || ''}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "suffix", e);
                                }}
                                placeholder="Enter suffix"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="User Name"
                                value={
                                    `${administratorValue?.firstName || ''}` +
                                    (administratorValue?.middleName ? ` ${administratorValue?.middleName}` : '') +
                                    (administratorValue?.lastName ? ` ${administratorValue?.lastName}` : '')
                                }
                                placeholder="Enter user name"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id='email'
                                name='email'
                                label="E-mail"
                                value={administratorValue?.email}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "email", e);
                                }}
                                placeholder="Enter email"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6} sx={{ width: "100%" }}>
                            <label htmlFor="phoneInput" style={{ display: "block", marginBottom: "0.5rem" }}>
                                Work Phone Number
                            </label>
                            <PhoneInput
                                country={'in'}
                                name="phoneInput"
                                value={administratorValue?.workPhone?.phoneNumber}
                                onChange={(phone, countryData) => {
                                    const countryDialCode = `+${countryData?.dialCode}`;
                                    let phoneWithoutCountryCode = phone;
                                    if (phone.startsWith(countryDialCode)) {
                                        phoneWithoutCountryCode = phone.slice(countryDialCode.length).trim();
                                    }

                                    serAdministratorValue({
                                        ...administratorValue,
                                        workPhone: {
                                            phoneNumber: phoneWithoutCountryCode,
                                            countryCode: countryData?.dialCode
                                        }
                                    });
                                }}
                                inputStyle={{ width: "100%", height: "50px" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <label htmlFor="phoneInput" style={{ display: "block", marginBottom: "0.5rem" }}>
                                Mobile Phone Number
                            </label>
                            <PhoneInput
                                country={'in'}
                                name="phoneInput"
                                value={administratorValue?.mobilePhone?.phoneNumber}
                                onChange={(phone, countryData) => {
                                    const countryDialCode = `+${countryData?.dialCode}`;
                                    let phoneWithoutCountryCode = phone;
                                    if (phone.startsWith(countryDialCode)) {
                                        phoneWithoutCountryCode = phone.slice(countryDialCode.length).trim();
                                    }

                                    serAdministratorValue({
                                        ...administratorValue,
                                        mobilePhone: {
                                            phoneNumber: phoneWithoutCountryCode,
                                            countryCode: countryData?.dialCode
                                        }
                                    });
                                }}
                                inputStyle={{ width: "100%", height: "50px" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='streetAddress'
                                label="Street Address"
                                value={administratorValue?.streetAddress}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "streetAddress", e);
                                }}
                                placeholder="Enter street address"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='city'
                                label="City"
                                value={administratorValue?.city}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "city", e);
                                }}
                                placeholder="Enter city"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='country'
                                label="Country"
                                value={administratorValue?.country}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "country", e);
                                }}
                                placeholder="Enter country"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                name='photo'
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "photo", e);
                                }}
                                style={{ height: "57px" }}
                            />
                        </Grid>
                    </Grid>
                    <img
                        type='file'
                        src={typeof administratorValue?.photo === "object" ?
                            URL.createObjectURL(administratorValue?.photo) :
                            `${BASE_IMAGE_URL}/uploads/${administratorValue?.photo}`}
                        style={{ height: "100px", width: "100px" }}
                        alt='profile'
                    />
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='title'
                                label="Title"
                                value={administratorValue?.title}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "title", e);
                                }}
                                placeholder="Enter title"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='department'
                                label="Department"
                                value={administratorValue?.department}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "department", e);
                                }}
                                placeholder="Enter department"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Function"
                                name='function'
                                value={administratorValue?.function}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "function", e);
                                }}
                                placeholder="Enter function"
                            />
                        </Grid>
                    </Grid>
                </Container>
                <hr
                    style={{
                        borderTop: "2px solid #1976d2",
                        width: "100%",
                        margin: "20px auto",
                    }}
                />
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px"
                }}>
                    Administrative Info
                </Typography>{" "}
                <Container style={{ display: "grid", paddingBottom: "20px" }}>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                id="note"
                                name="note"
                                label="Notes"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={6}
                                value={administratorValue.note}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "note", e);
                                }}
                                placeholder="Enter note"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="createdOn"
                                variant="outlined"
                                name='createdOn'
                                label="Created On"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={administratorValue?.createdOn}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "createdOn", e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="changedOn"
                                variant="outlined"
                                label="Changed On"
                                name='changedOn'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={administratorValue?.changedOn}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "changedOn", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="createdBy"
                                variant="outlined"
                                name='createdBy'
                                label="Created By"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={administratorValue?.createdBy}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "createdBy", e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id="changedBy"
                                variant="outlined"
                                label="Changed By"
                                name='changedBy'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={administratorValue?.changedBy}
                                onChange={(e, newValue) => {
                                    handleChange(newValue, "changedBy", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <CustomButton
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    )
}

export default CreateSuperAdministrator
