import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle'
import CreateProfile from './CreateProfile'
import ProfileMaterialList from './ProfileMaterialList'
import DeleteProfile from './DeleteProfile'
import { ThemeContext } from '../../ThemeContext'
import { useSelector } from 'react-redux'
import { copyProfile, createProfile, getProfileData } from '../../store/ApiSlice/profileSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FiPlus } from "react-icons/fi";

const ProfileDetail = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [copyForecastProfile, setCopyForecastProfile] = useState({})
    const [openCopyProfileModel, setCopyProfileModel] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProfileData())
    }, [])

    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }
    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    const validation = () => {
        if (copyForecastProfile?.name === "" || copyForecastProfile?.name === undefined) {
            toast.error("Please enter profile name");
            return true;
        }
        return false;
    }

    const handleSubmit = async () => {
        if (validation()) {
            return;
        } else {
            const sourceProfileUID = copyForecastProfile?._id;
            const name = `${copyForecastProfile?.name}_Copy`;
            try {
                const res = await dispatch(copyProfile({ sourceProfileUID, name })).unwrap();
                toast.success(res?.message);
                setCopyProfileModel(false);
                dispatch(getProfileData());
            } catch (error) {
                toast.error(error?.message || "Failed to copy profile");
            }
        }
    };


    return (
        <>
            {!isCreate && !isView && (
                <>
                    {/* <Box sx={{ display: "flex" }}>
                        <MiniDrawer />
                        <Box component="main" sx={{
                            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                            background: isDarkTheme ? "whitesmoke" : "white",
                        }}> */}
                    <div className='flex justify-between items-center pb-5 relative'>
                        <h3 className="heading">Forecast Profiles</h3>
                        <div>
                            <div className="relative group">
                                <button
                                    onClick={() => handleCreateUpdate(false)}
                                    className="font-semibold rounded-full text-sm p-2 flex items-center gap-2 shadow-md hover:shadow-lg transition-all duration-300 transform active:scale-95"
                                    type="button"
                                    style={{ background: 'linear-gradient(to right, #b1e5f2, #d1c8f5)' }}
                                >
                                    <FiPlus className="w-5 h-5 text-black" />
                                </button>
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-max bg-gray-800 text-white text-[10px] font-normal py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition">
                                    Add Profile
                                </div>
                            </div>
                            <DeleteProfile
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                setModalDeleteOpen={setModalDeleteOpen}
                                isModalDeleteOpen={isModalDeleteOpen}
                                updatedId={updatedId}
                            />
                        </div>
                    </div>
                    <ProfileMaterialList
                        handleTableRowSelect={handleTableRowSelect}
                        setUpdatedId={setUpdatedId}
                        handleCreateUpdate={handleCreateUpdate}
                        setModalDeleteOpen={setModalDeleteOpen}
                        setCopyForecastProfile={setCopyForecastProfile}
                        setCopyProfileModel={setCopyProfileModel}
                    />
                    {/* </Box>
                    </Box> */}
                </>
            )}
            {(isCreate || isView) && (
                <CreateProfile
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isView={isView}
                    setIsView={setIsView}
                    selectedRows={updatedId}
                />
            )}
            {openCopyProfileModel && (
                <Dialog open={openCopyProfileModel} onClose={() => setCopyProfileModel(false)}>
                    <DialogTitle>Copy Forecast Profile</DialogTitle>
                    <DialogContent style={{ width: "500px", margin: "2px" }}>
                        <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Profile Name"
                            value={copyForecastProfile?.name}
                            onChange={(e) => {
                                setCopyForecastProfile((prev) => ({ ...prev, name: e.target.value }))
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Enter profile name"
                            style={{ marginTop: "10px" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCopyProfileModel(false)} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                        <Button color="primary" onClick={handleSubmit} sx={{ textTransform: "capitalize" }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default ProfileDetail
