// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awesome-button {
  border-radius: 3px;
  font-weight: bold;
  border: 0;
  color: black !important;
  height: 35px;
  padding: 0 10px;

}
.awesome-button:hover {
  height: 40px;
}

.tableHeader {
  background: white; /* Green background color */
  color: black;
  padding: 15px;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
}
.tableHeader th {
  color: black;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.tableRows tr {
  transition: background-color 0.2s; /* Smooth transition for background color change */
}

.tableRows tr:hover {
  background-color: #d5dce2; /* Light gray background on hover */
}
.cancelButton {
  background: #e57373;
  border-radius: 3px;
  border: 0;
  font-weight: bold;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
}

.cancelButton:hover {
  background: #d32f2f;
}
`, "",{"version":3,"sources":["webpack://./src/components/attributes/attribute.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,SAAS;EACT,uBAAuB;EACvB,YAAY;EACZ,eAAe;;AAEjB;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB,EAAE,2BAA2B;EAC9C,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;AACA;EACE,iCAAiC,EAAE,kDAAkD;AACvF;;AAEA;EACE,yBAAyB,EAAE,mCAAmC;AAChE;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kDAAkD;AACpD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".awesome-button {\n  border-radius: 3px;\n  font-weight: bold;\n  border: 0;\n  color: black !important;\n  height: 35px;\n  padding: 0 10px;\n\n}\n.awesome-button:hover {\n  height: 40px;\n}\n\n.tableHeader {\n  background: white; /* Green background color */\n  color: black;\n  padding: 15px;\n  text-align: left;\n  font-size: 18px;\n  cursor: pointer;\n}\n.tableHeader th {\n  color: black;\n  font-size: 16px;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n.tableRows tr {\n  transition: background-color 0.2s; /* Smooth transition for background color change */\n}\n\n.tableRows tr:hover {\n  background-color: #d5dce2; /* Light gray background on hover */\n}\n.cancelButton {\n  background: #e57373;\n  border-radius: 3px;\n  border: 0;\n  font-weight: bold;\n  color: white;\n  height: 48px;\n  padding: 0 30px;\n  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);\n}\n\n.cancelButton:hover {\n  background: #d32f2f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
