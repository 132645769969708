import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';

const initialState = {
    loading: false,
    error: null,
    success: false,
    modalData: [],
    userData: {}
};

// get modal list
export const getEnterModelList = createAsyncThunk('modalData/getmodalDataList',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
        };
        const responce = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
        return responce.data
    }
);

// create and update model
export const createUpdateModel = createAsyncThunk(
    'modalData/createUpdateModel',
    async ({ updatedFormValues, isEdit, userId, updatedId }, { rejectWithValue }) => {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        let result;
        if (!isEdit) {
            const baseURLCreate = `${API_BASE_URL}/model`;
            result = await axios.post(baseURLCreate, updatedFormValues, config);
        } else {
            const baseURLUpdate = `${API_BASE_URL}/model/${updatedId}`;
            result = await axios.put(baseURLUpdate, updatedFormValues, config);
        }
        return result.data;
    }
);

// delete model
export const deletemodelList = createAsyncThunk('model/deletemodel',
    async ({ attriId, config }) => {
        const responce = await axios.delete(`${API_BASE_URL}/model/${attriId}`, config)
        return responce
    }
);

const modelList = createSlice({
    name: 'modelList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEnterModelList.pending, (state) => {
                state.loading = true
            })
            .addCase(getEnterModelList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.modalData = action.payload?.enterpriseList;
            })
            .addCase(getEnterModelList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createUpdateModel.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createUpdateModel.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createUpdateModel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.success = false;
            })
            .addCase(deletemodelList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deletemodelList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deletemodelList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.success = false;
            })
    }
})

export default modelList.reducer