import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteEnterprise, getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";

const DeleteEnterprise = ({
  selectedRows,
  setSelectedRows,
  isModalOpenDelete,
  setModalOpenDelete,
  attriId,
}) => {

  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModalOpenDelete(false);
    setSelectedRows([]);
  };

  const handleSubmit = async () => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
      },
    };
    try {
      const response = dispatch(deleteEnterprise({ attriId, config }));
      if (!response?.data?.error) {
        // toast.success("Enterprise Deleted Successfully ");
        dispatch(getEnterpriseList());
      } else {
        toast.warn(
          response?.data?.error
            ? response.data.error
            : "Enterprise can not be deleted"
        );
      }
    } catch (error) {
      console.log(error);
    }
    handleCloseModal();
  };
  const renderModalContent = () => {
    return (
      <>
        <DialogTitle>Delete Enterprise</DialogTitle>
        <DialogContent style={{ width: "500px", margin: "2px" }}>
          <Typography variant="h6" component="h2">
            {/* Are you sure for delete this Enterprise? */}
            This Is Irreversible. Are You Sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} sx={{ textTransform: "capitalize" }}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            color="primary" sx={{ textTransform: "capitalize" }}
          >
            Delete
          </Button>
        </DialogActions>
      </>
    );
  };
  return (
    <>
      {/* <Button
        onClick={handleClick}
        disabled={!selectedRows.length}
        className={
          selectedRows.length ? "awesome-button" : "awesome-button-disabled"
        }
        sx={{ color: "white", margin: "0 4px" }}
      >
        Delete
      </Button> */}
      <Dialog open={isModalOpenDelete} onClose={handleCloseModal}>
        {renderModalContent()}
      </Dialog>
    </>
  );
};

export default DeleteEnterprise;
