import { Box, Button, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle';
import DeleteProcess from './DeleteProcess';
import ProcessMaterialList from './ProcessMaterialList';
import CreateProcess from './CreateProcess';
import TerminateProcess from './TerminateProcess';
import RunProcess from './RunProcess';
import ProgressStatus from './ProgressStatus';
import { ThemeContext } from '../../ThemeContext';

const ProcessList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [isModalDeleteOpenTerminate, setModalDeleteOpenTerminate] = useState(false);
    const [isExcuteProcess, setExcuteProcess] = useState()
    const [isRunProcess, setIsRunProcess] = useState(false)
    const [isProgressStatus, setIsProgressStatus] = useState(false);

    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }

    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };
    return (
        <>
            {!isCreate && !isView && (
                <Box sx={{ display: "flex" }}>
                    <MiniDrawer />
                    <Box component="main" sx={{
                        flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                        background: isDarkTheme ? "whitesmoke" : "white",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "60px",
                            paddingBottom: "20px",
                            position: "relative"
                        }}>
                            <h3 className="heading">Process</h3>
                            <div>
                                <Tooltip title="Add Process">
                                    <Button
                                        onClick={() => {
                                            handleCreateUpdate(false)
                                        }}
                                        className="awesome-button"
                                        sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                    >
                                        +
                                    </Button>
                                </Tooltip>
                                <DeleteProcess
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setModalDeleteOpen={setModalDeleteOpen}
                                    isModalDeleteOpen={isModalDeleteOpen}
                                    updatedId={updatedId}
                                />
                                <TerminateProcess
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setModalDeleteOpenTerminate={setModalDeleteOpenTerminate}
                                    isModalDeleteOpenTerminate={isModalDeleteOpenTerminate}
                                    updatedId={updatedId}
                                />
                                <RunProcess
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setIsRunProcess={setIsRunProcess}
                                    isRunProcess={isRunProcess}
                                    updatedId={updatedId}
                                    isExcuteProcess={isExcuteProcess}
                                />
                                <ProgressStatus
                                    setIsProgressStatus={setIsProgressStatus}
                                    isProgressStatus={isProgressStatus}
                                />
                            </div>
                        </div>
                        <ProcessMaterialList
                            handleTableRowSelect={handleTableRowSelect}
                            setUpdatedId={setUpdatedId}
                            handleCreateUpdate={handleCreateUpdate}
                            setModalDeleteOpen={setModalDeleteOpen}
                            setModalDeleteOpenTerminate={setModalDeleteOpenTerminate}
                            setExcuteProcess={setExcuteProcess}
                            setIsRunProcess={setIsRunProcess}
                            setIsProgressStatus={setIsProgressStatus}
                        />
                    </Box>
                </Box>
            )}
            {(isCreate || isView) && (
                <CreateProcess
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isView={isView}
                    setIsView={setIsView}
                    selectedRows={updatedId}
                />
            )}
        </>
    )
}

export default ProcessList
